import AutoComplete from '@components/auto-complete/auto-complete';
import { IonImg } from '@ionic/react';
import * as React from 'react';
import './search-bar.scss';
import closeIcon from '@assets/icon/cancel_icon_gray.svg';
import classNames from 'classnames';

const SearchBar = (props) => {

  return (
    <div className={classNames({ 'search-bar-container': true, 'user-ribbon-exists': props.userRibbonExists })} data-testid="QASearchBarSearchContainer">
      <AutoComplete
        noneFoundText={props.noneFoundText}
        placeholder={props.placeholder}
        suggestions={props.suggestions}
        suggestionDisplayFields={props.suggestionDisplayFields}
        commonSuggestionDisplayFields={props.commonSuggestionDisplayFields}
        location='auto'
        searchInputChangedHandler={(searchInput) => props.searchInputChangedHandler(searchInput)}
        suggestionClickHandler={(searchInput) => props.suggestionClickHandler(searchInput)}
        input={props.input}
        onKeyPress={(e) => {
          if(e.key === 'Enter') {
            props.enterPressedHandler();
          }
        }}
        autoFocus={true}
      />
      <IonImg src={closeIcon} class="close-search-bar-icon" onClick={() => props.closeSearchBar()}></IonImg>
    </div>
  );
};

export default SearchBar;