import { ManufacturingSites } from '@constants';
import { RawMaterialCombination } from '@interfaces/raw-material-combination';
import * as React from 'react';
import { getRawMaterialCombination } from './raw-material-combinations-api';

const { createContext, useContext } = React;

const RawMaterialCombinationsContext = createContext(null);

export const RawMaterialCombinationsProvider = (props) => {
  const value = {
    getRawMaterialCombinationForMetalCombo: props.getRawMaterialCombinationForMetalCombo || getRawMaterialCombinationForMetalCombo,
  };

  return (
    <RawMaterialCombinationsContext.Provider value={value}>
      {props.children}
    </RawMaterialCombinationsContext.Provider>
  );
};

export const useRawMaterialCombinations = () => {
  return useContext(RawMaterialCombinationsContext);
};

// Calls api to get the raw material combinations from cosmos for the specified metal combination and manufacturing site
export const getRawMaterialCombinationForMetalCombo = async(baseMetal: string, cladMetal: string, manufacturingSite: ManufacturingSites): Promise<RawMaterialCombination> => {
  try {
    return await getRawMaterialCombination(baseMetal, cladMetal, manufacturingSite);
  } catch (error) {
    console.error(error);
    return null;
  }
};
