import React, { useEffect, useState } from 'react';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import { useDataState } from '@context/data-context';
import { getDefaultVendorId } from '@shared/quote-utils';

const AppDropdownVendors = (props) => {
  const { dataState, } = useDataState();
  const { vendors } = dataState;
  const [ options, setOptions ] = useState([]);
  const { servicesPerformed, areaId, attrLabel, attrValue, excludeValues, value, onChange } = props;
  const oLabel = attrLabel || 'vendorName';
  const oValue = attrValue || 'vendorId';
  const oExcludeValues = excludeValues || [];

  const getOptions = () => {
    if (
      !servicesPerformed
      || !vendors
      || (vendors && vendors.length === 0)
    ) {
      return;
    }

    const items = vendors.filter((option) => {
      if (areaId) {
        return option.servicesPerformed.includes(servicesPerformed) && option.dataAreaId === areaId;
      }

      return option.servicesPerformed.includes(servicesPerformed);
    });

    const _options = (items.length && items?.map((option) => {
        return { label: option[oLabel], value: option[oValue] };
      })) || [];

    if (oExcludeValues.length === 0) {
      setOptions(_options);
    } else {
      setOptions(_options.filter(_o => !oExcludeValues.includes(_o.value)));
    }
  };

  useEffect(() => {
    getOptions();

    if (!value) {
      const defaultValue = getDefaultVendorId(vendors, areaId, servicesPerformed)
      
      if (onChange) {
        onChange(defaultValue);
      }
    }

  }, []);

  useEffect(() => {
    if (!servicesPerformed || !areaId) {
      return;
    }

    getOptions();
  }, [servicesPerformed, excludeValues, vendors])

  return <AppDropdown options={options} {...props}/>
};

export default AppDropdownVendors;