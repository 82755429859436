import React from 'react';
import { IonButton, IonCard, IonCardHeader, IonCol, IonGrid, IonIcon, IonImg, IonLabel, IonRow } from '@ionic/react';
import './add-piece-popover.scss';
import { AddPieceColumnHeadings, AddPieceSuppliers, AllocationOptions, FieldTypes, MeasurementSystems, MetalTypesForAreaCalc, MSTOAssemblyMetals, MSTOSelectionTabs, PanelLocations, QuoteLineItemFields, QuoteLineItemFieldUnitTypes, QuoteLineItemValueTypes, QuoteVariableConfigDescriptions, RawMaterialUsage, Regexes, SeamWeldQuantity, SearchFields, SelectionTypes, ToastMessages, ToastTypes, Units, VendorServices, NCSupplier } from '@constants';
import { AnvilAssemblyDisplayMapValueType, AssemblyDisplayMapValueType, AssemblyPanels, BackerAssemblyDisplayMapValueType, CladderAssemblyDisplayMapValueType, FieldsMapValueType, MetalToAutocombine, MSTOAnvilItemDetailFieldsToUpdate, MSTOQuoteLineItemDetailFieldsToUpdate, Panels, SeamPanels, WeldingDimensionsMapValueType } from '@shared/types';
import SelectionContainer from '@components/selection-container/selection-container';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { calculateRawArea, calculateMetalDensity, calculateUOMValue, filterMSTOPanelFields, getFreightGroup, getMetalPricing, getQuoteVariableConfiguration, getSelectionOptions, getVendors, calculateRawMetalWeight } from '@shared/quote-utils';
import { addCircle, closeCircle } from 'ionicons/icons';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
import EmptyTableRow from '@components/empty-table-row/empty-table-row';
import LineItemField from '@components/line-item-field/line-item-field';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import moment from 'moment';
import { useToast } from '@hooks/useToast';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { SeamWeldItemDetail } from '@interfaces/seam-weld-item-detail';
import { AnvilItemDetail } from '@interfaces/anvil-item-detail';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';
import { useLocalUom } from '@hooks/useLocalUom';

const NCSuppliers: string[] = [
  NCSupplier.Backer,
  NCSupplier.Cladder,
];

const AddPiecePopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
    showPopover: boolean;
    event: any;
    itemsToEdit: QuoteLineItem[];
  }>>,
  quoteLineItemsToEdit: QuoteLineItem[],
  quoteLineItemDetailData: QuoteLineItemDetail,
  weldingDimensions: Map<string, WeldingDimensionsMapValueType>,
  seamWeldPieceNumber: number,
  panelNumber: number,
  panelLocation: PanelLocations,
  editing: boolean
  displayPanelFields: {DisplayWidth: number, DisplayLength: number, DisplayTK: number},
  mstoData: {
    activeTab: MSTOSelectionTabs,
    anvilAssemblyDisplayMap: Map<string, AnvilAssemblyDisplayMapValueType>,
    backerAssemblyDisplayMap: Map<string, BackerAssemblyDisplayMapValueType>,
    cladderAssemblyDisplayMap: Map<string, CladderAssemblyDisplayMapValueType>,
    anvilItemDetailFieldsToUpdate: { [key: string]: MSTOAnvilItemDetailFieldsToUpdate },
    quoteLineItemDetailFieldsToUpdate: { [key: string]: MSTOQuoteLineItemDetailFieldsToUpdate },
    anvilPanelsMap: Map<string, SeamPanels>,
    backerPanelsMap: Map<string, SeamPanels>,
    cladderPanelsMap: Map<string, SeamPanels>
    assemblies: Map<string, AssemblyPanels[]>,
  },
  updateSpecifiedMetalPanelsMap: (updatedMetalPanelsMap: Map<string, SeamPanels | Panels>) => void,
  updateAssembliesDisplayMap: (updatedAssembliesDisplayMap: Map<string, AssemblyDisplayMapValueType>) => void,
  updateQuoteLineItemDetailFieldsToUpdate: (updatedQuoteLineItemDetailFieldsToUpdate: { [key: string]: MSTOQuoteLineItemDetailFieldsToUpdate }) => void,
  updateAnvilItemDetailFieldsToUpdate: (updatedAnvilItemDetailFieldsToUpdate: { [key: string]: MSTOAnvilItemDetailFieldsToUpdate }) => void,
  updateAssemblies: (updatedAssemblies: Map<string, AssemblyPanels[]>) => void,
  buildAutocombinedGroups: (updatedMetalPanelsMap: Map<string, SeamPanels | Panels>) => MetalToAutocombine[][],
  autocombineMetals: (autocombinedGroups?: {
    quoteLineItemData: QuoteLineItem;
    quoteLineItemDetailData: QuoteLineItemDetail;
    metal: MSTOAssemblyMetals;
    panelNumber?: number;
    panelLocation?: PanelLocations;
    seamWeldPieceNumber?: number;
  }[][], assemblies?: any) => Map<string, AssemblyPanels[]>
}) => {
  const { dataState } = useDataState();
  const { selections, quoteVariableConfigurations, vendors, rawMaterials, freight } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;

  const { localUom, isDisplayMetric } = useLocalUom();

  const { darkMode } = useDarkMode();
  const toast = useToast(4000);

  const quoteLineItemData = (props.quoteLineItemsToEdit || [])[0];
  const quoteLineItemDetailData = props.quoteLineItemDetailData;
  const lineItemFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
  const lineItemDetailFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
  const anvilItemDetailFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
  const seamWeldItemDetailFieldsMap = new Map<string, FieldsMapValueType>();
  const lengthWidthTKDisplayFieldsMap = new Map<string, number>();
  const anvilFreightCostPerLB = getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.AnvilFreightCostByWeightLB, manufacturingSite, FieldTypes.Float) as number;
  const anvilFreightCostPerKG = getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.AnvilFreightCostByWeightKG, manufacturingSite, FieldTypes.Float) as number;
  const seamWeld = props.mstoData.cladderAssemblyDisplayMap.get(quoteLineItemData.UUID__c || quoteLineItemData.Id || quoteLineItemData.displayId)?.weld;
  const torchCutting = props.mstoData.backerAssemblyDisplayMap.get(quoteLineItemData.UUID__c || quoteLineItemData.Id || quoteLineItemData.displayId)?.cutting;

  // Returns the suppliers for the metal to order
  const getSupplierSelections = () => {
    switch(props.mstoData.activeTab) {
      case MSTOSelectionTabs.Cladder:
        return getVendors(vendors, manufacturingSite, VendorServices.RawMaterialsCladder);
      case MSTOSelectionTabs.Backer:
        return getVendors(vendors, manufacturingSite, VendorServices.RawMaterialsBacker);
      case MSTOSelectionTabs.Anvil:
        return getVendors(vendors, manufacturingSite, VendorServices.RawMaterialsAnvil);
    }
  };

  // Returns the initial line fields to be updated for the metal to order
  const getLineFieldsToUpdate = () => {
    switch(props.mstoData.activeTab) {
      case MSTOSelectionTabs.Cladder:
        return {
          customerProvided: quoteLineItemData.Customer_Provided_Clad__c,
          pricePerWeight: isDisplayMetric ? quoteLineItemData.Clad_Price_KG__c : quoteLineItemData.Clad_Price_LB__c,
        };
      case MSTOSelectionTabs.Backer:
        return {
          customerProvided: quoteLineItemData.Customer_Provided_Base__c,
          pricePerWeight: isDisplayMetric ? quoteLineItemData.Base_Price_KG__c : quoteLineItemData.Base_Price_LB__c,
        };
        case MSTOSelectionTabs.Anvil:
          return {};
    }
  };

  // Returns the initial detail fields to be updated for the metal to order
  const getDetailFieldsToUpdate = () => {
    switch(props.mstoData.activeTab) {
      case MSTOSelectionTabs.Cladder:
        const cladderWeight = isDisplayMetric
          ? quoteLineItemDetailData?.PurchaseCladderWeight_KG__c
          : quoteLineItemDetailData?.PurchaseCladderWeight_LB__c;
        const cladderPurchaseFreight = isDisplayMetric
          ? quoteLineItemDetailData?.PurchaseCladderFreight_KG__c
          : quoteLineItemDetailData?.PurchaseCladderFreight_LB__c;
        // const cladderFreightPerWeight = (cladderPurchaseFreight / cladderWeight) || null;
        const cladderFreightPerWeight = cladderPurchaseFreight || null;

        return {
          rawMaterialVendor: quoteLineItemDetailData?.RawMaterialVendorCladder__c ?? '0',
          configurationId: quoteLineItemDetailData?.ConfigurationID__c,
          purchase: quoteLineItemDetailData?.PurchaseCL__c,
          pullFromStock: quoteLineItemDetailData?.Pullfromstockcladder__c,
          width: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseCladWidth__c, Units.Millimeters, Units.Inches),
          length: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseCladLength__c, Units.Millimeters, Units.Inches),
          TK: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseCladTK__c, Units.Millimeters, Units.Inches),
          hasTorchCut: quoteLineItemDetailData?.TorchCutCladder__c || torchCutting,
          weight: cladderWeight,
          shipDate: quoteLineItemDetailData?.ShipDateCladder__c || moment(new Date()).format('YYYY-MM-DD'),
          daysForTransport: quoteLineItemDetailData?.CladderDaysForTransport__c,
          preHeatToCut: quoteLineItemDetailData?.PreheattoTorchCutCladder__c,
          seamWeld: quoteLineItemDetailData?.SeamWeld__c || seamWeld,
          pricePerPiece: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseCladderPrice__c, Units.Kilograms, Units.Pounds),
          freightPerWeight: cladderFreightPerWeight
        };
      case MSTOSelectionTabs.Backer:
        const backerWeight = isDisplayMetric
          ? quoteLineItemDetailData?.PurchaseBackerWeight_KG__c
          : quoteLineItemDetailData?.PurchaseBackerWeight_LB__c;
        const backerPurchaseFreight = isDisplayMetric
          ? quoteLineItemDetailData?.PurchaseBackerFreight_KG__c
          : quoteLineItemDetailData?.PurchaseBackerFreight_LB__c;
        // const backerFreightPerWeight = (backerPurchaseFreight / backerWeight) || null;
        const backerFreightPerWeight = backerPurchaseFreight;

        return {
          rawMaterialVendor: quoteLineItemDetailData?.RawMaterialVendorBacker__c ?? '0',
          configurationId: quoteLineItemDetailData?.ConfigurationIDBacker__c,
          purchase: quoteLineItemDetailData?.PurchaseBK__c,
          pullFromStock: quoteLineItemDetailData?.Pullfromstockbacker__c,
          width: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches),
          length: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseBaseLength__c, Units.Millimeters, Units.Inches),
          TK: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseBaseTK__c, Units.Millimeters, Units.Inches),
          hasTorchCut: quoteLineItemDetailData?.TorchCutBacker__c || torchCutting,
          weight: backerWeight,
          shipDate: quoteLineItemDetailData?.ShipDateBase__c || moment(new Date()).format('YYYY-MM-DD'),
          daysForTransport: quoteLineItemDetailData?.BackerDaysForTransport__c,
          preHeatToCut: quoteLineItemDetailData?.PreheattoTorchCutBacker__c,
          pricePerPiece: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseBackerPrice__c, Units.Kilograms, Units.Pounds),
          freightPerWeight: backerFreightPerWeight,
        };
      case MSTOSelectionTabs.Anvil:
        const anvilWeight = isDisplayMetric
          ? quoteLineItemDetailData?.PurchaseAnvilWeight_KG__c
          : quoteLineItemDetailData?.PurchaseAnvilWeight_LB__c;
        const anvilPurchaseFreight = isDisplayMetric
          ? (quoteLineItemDetailData?.PurchaseAnvilFreight_KG__c || anvilFreightCostPerKG)
          : (quoteLineItemDetailData?.PurchaseAnvilFreight_LB__c || anvilFreightCostPerLB);
        // const anvilFreightPerWeight = (anvilPurchaseFreight / anvilWeight) || null;
        const anvilFreightPerWeight = anvilPurchaseFreight;

        return {
          rawMaterialVendor: quoteLineItemDetailData?.VendorAnvil__c ?? '0',
          purchase: quoteLineItemDetailData?.PurchaseBKAnvil__c,
          pullFromStock: quoteLineItemDetailData?.PullfromstockBKAnvil__c,
          width: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches),
          length: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches),
          TK: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches),
          hasTorchCut: quoteLineItemDetailData?.TorchCutAnvil1__c || torchCutting,
          preHeatToCut: quoteLineItemDetailData?.PreheattoCutAnvil__c,
          pricePerWeight: quoteLineItemDetailData?.PurchaseAnvilPrice__c / anvilWeight,
          pricePerPiece: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.PurchaseAnvilPrice__c, Units.Kilograms, Units.Pounds),
          freightPerWeight: anvilFreightPerWeight,
          weight: anvilWeight
        };
    }
  };

  const getDataFromFeldsToUpdate = () => {
    const details = detailFieldsToUpdate.current;
    const line = lineFieldsToUpdate.current;
    const seamweld = seamWeldFieldsToUpdate.current;

    const initialState = {
      quoteLineItem: {},
      quoteLineItemDetail: {
        NC_Seam_Weld_Item_Details__r: {},
      },
    };
    const weight = {
      kg: calculateUOMValue(localUom, MeasurementSystems.Metric, details?.weight, Units.Kilograms, Units.Pounds),
      lb: calculateUOMValue(localUom, MeasurementSystems.Imperial, details?.weight, Units.Kilograms, Units.Pounds)
    };

    const freight = {
      // kg: calculateUOMValue(localUom, MeasurementSystems.Metric, details?.freightPerWeight, Units.Kilograms, Units.Pounds) * weight.kg,
      // lb: calculateUOMValue(localUom, MeasurementSystems.Imperial, details?.freightPerWeight, Units.Kilograms, Units.Pounds) * weight.lb,
      kg: calculateUOMValue(localUom, MeasurementSystems.Metric, details?.freightPerWeight, Units.Kilograms, Units.Pounds),
      lb: calculateUOMValue(localUom, MeasurementSystems.Imperial, details?.freightPerWeight, Units.Kilograms, Units.Pounds),
    };

    const pricePerWeight = {
      kg: calculateUOMValue(localUom, MeasurementSystems.Metric, line.pricePerWeight, Units.PricePerKilogram, Units.PricePerPound),
      lb: calculateUOMValue(localUom, MeasurementSystems.Imperial, line.pricePerWeight, Units.PricePerKilogram, Units.PricePerPound),
    };

    const pricePerPiece = calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, details?.pricePerPiece, Units.Kilograms, Units.Pounds);

    switch(props.mstoData.activeTab) {
      case MSTOSelectionTabs.Cladder:
        let seamWeldAddition = {};
        if (props.seamWeldPieceNumber) {
          seamWeldAddition = {
              [`SWRawMaterialCostSW${props.seamWeldPieceNumber}__c`]: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, seamweld.pricePerPiece, Units.Kilograms, Units.Pounds),
              [`WeightSW${props.seamWeldPieceNumber}__c`]: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, seamweld.weight, Units.Kilograms, Units.Pounds),
              [`EstimatedShippingTotalSW${props.seamWeldPieceNumber}__c`]: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, seamweld.freightPerWeight, Units.Kilograms, Units.Pounds),
          };
        }

        return {
          quoteLineItem: {
            Clad_Price_KG__c: pricePerWeight.kg,
            Clad_Price_LB__c: pricePerWeight.lb,
          },
          quoteLineItemDetail: {
            PurchaseCladderPrice__c: pricePerPiece,
            PurchaseCladderWeight_KG__c: weight.kg,
            PurchaseCladderWeight_LB__c: weight.lb,

            PurchaseCladderFreight_KG__c: freight.kg,
            PurchaseCladderFreight_LB__c: freight.lb,

            NC_Seam_Weld_Item_Details__r: seamWeldAddition,
          },
        };
      case MSTOSelectionTabs.Backer:
        return {
          quoteLineItem: {
            Base_Price_KG__c: pricePerWeight.kg,
            Base_Price_LB__c: pricePerWeight.lb,
          },
          quoteLineItemDetail: {
            PurchaseBackerPrice__c: pricePerPiece,
            PurchaseBackerWeight_KG__c: weight.kg,
            PurchaseBackerWeight_LB__c: weight.lb,

            PurchaseBackerFreight_KG__c: freight.kg,
            PurchaseBackerFreight_LB__c: freight.lb,
            NC_Seam_Weld_Item_Details__r: {},
          }
        };
      case MSTOSelectionTabs.Anvil:
        return {
          quoteLineItem: {},
          quoteLineItemDetail: {
            PurchaseAnvilPrice__c: pricePerPiece,
            PurchaseAnvilWeight_KG__c: weight.kg,
            PurchaseAnvilWeight_LB__c: weight.lb,
            PurchaseAnvilFreight_KG__c: freight.kg,
            PurchaseAnvilFreight_LB__c: freight.lb,
            NC_Seam_Weld_Item_Details__r: {},
          },
        };
      default:
        return initialState;
    }
  };

  const lineFieldsToUpdate = React.useRef(getLineFieldsToUpdate());
  const detailFieldsToUpdate = React.useRef(getDetailFieldsToUpdate());

  const seamWeldFieldsToUpdate = React.useRef({
    rawMaterialVendor: quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`RawMaterialVendorSW${props.seamWeldPieceNumber}__c`],
    configurationId: quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[props.seamWeldPieceNumber === 1 ? 'ConfigurationID__c' : `ConfigurationIDSW${props.seamWeldPieceNumber}__c`],
    purchase: quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`PurchaseSW${props.seamWeldPieceNumber}__c`],
    pullFromStock: quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`PullFromStockSW${props.seamWeldPieceNumber}__c`],
    width: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`CladderWidthSW${props.seamWeldPieceNumber}__c`], Units.Millimeters, Units.Inches),
    length: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`CladderLengthSW${props.seamWeldPieceNumber}__c`], Units.Millimeters, Units.Inches),
    TK: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`NominalThicknessSW${props.seamWeldPieceNumber}__c`], Units.Millimeters, Units.Inches),
    weight: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`WeightSW${props.seamWeldPieceNumber}__c`], Units.Kilograms, Units.Pounds),
    shipDate: quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`ShipDateSW${props.seamWeldPieceNumber}__c`] || moment(new Date()).format('YYYY-MM-DD'),
    daysForTransport: quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`DaysForTransportSW${props.seamWeldPieceNumber}__c`],
    preHeatToCut: quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`PreheatToCutSW${props.seamWeldPieceNumber}__c`],
    pricePerPiece: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`SWRawMaterialCostSW${props.seamWeldPieceNumber}__c`], Units.Kilograms, Units.Pounds) || 0,
    pricePerWeight: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`SWRawMaterialCostSW${props.seamWeldPieceNumber}__c`], Units.Kilograms, Units.Pounds) / calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`WeightSW${props.seamWeldPieceNumber}__c`], Units.Kilograms, Units.Pounds),
    freightPerWeight: calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`EstimatedShippingTotalSW${props.seamWeldPieceNumber}__c`], Units.Kilograms, Units.Pounds)
  });

  const anvilFieldsToUpdate = React.useRef({
    configurationId: quoteLineItemDetailData?.NC_Anvil_Item_Details__r.ConfigurationIDAnvil__c,
    shipDate: quoteLineItemDetailData?.NC_Anvil_Item_Details__r.ShipDateAnvil__c || moment(new Date()).format('YYYY-MM-DD'),
    daysForTransport: quoteLineItemDetailData?.NC_Anvil_Item_Details__r.AnvilDaysForTransport__c
  });
  const panelQuantity = React.useRef(1);

  // Returns the details needed to be shown on the panel
  const getPanel = () => {
    const displayLength = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, props.displayPanelFields?.DisplayLength, Units.Millimeters, Units.Inches);
    const displayWidth = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, props.displayPanelFields?.DisplayWidth, Units.Millimeters, Units.Inches);
    const displayTK = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, localUom, props.displayPanelFields?.DisplayTK, Units.Millimeters, Units.Inches);

    return {
      index: 0,
      width: !props.seamWeldPieceNumber ? detailFieldsToUpdate.current.width : seamWeldFieldsToUpdate.current.width,
      length: !props.seamWeldPieceNumber ? detailFieldsToUpdate.current.length : seamWeldFieldsToUpdate.current.length,
      TK: !props.seamWeldPieceNumber ? detailFieldsToUpdate.current.TK : seamWeldFieldsToUpdate.current.TK,
      hasTorchCut: !props.seamWeldPieceNumber ? detailFieldsToUpdate.current.hasTorchCut : false,
      freightPerWeight: detailFieldsToUpdate.current.freightPerWeight,
      weight: !props.seamWeldPieceNumber ? detailFieldsToUpdate.current.weight : seamWeldFieldsToUpdate.current.weight,
      pricePerWeight: !props.seamWeldPieceNumber ? (props.mstoData.activeTab !== MSTOSelectionTabs.Anvil ? lineFieldsToUpdate.current.pricePerWeight : detailFieldsToUpdate.current.pricePerWeight) : seamWeldFieldsToUpdate.current.pricePerWeight,
      pricePerPiece: !props.seamWeldPieceNumber ? detailFieldsToUpdate.current.pricePerPiece : seamWeldFieldsToUpdate.current.pricePerPiece,
      assemblyGroup: quoteLineItemData.Line__c,
      allocation: AllocationOptions.AssemblyPart,
      location: props.panelLocation,
      panelNumber: props.panelNumber,
      displayLength: displayLength || detailFieldsToUpdate.current.length,
      displayWidth: displayWidth || detailFieldsToUpdate.current.width,
      displayTK: displayTK || detailFieldsToUpdate.current.TK
    };
  };

  // Returns the existing panel if editing an item otherwise returns empty array
  const getPanels = () => {
    if(props.editing) {
      return [getPanel()];
    }

    return [];
  };

  const [panels, setPanels] = React.useState(getPanels());

  // Returns the right nc stock serial number depending on metal used, and whether it is a seam weld panel
  const getNCStockSerialNumber = () => {
    if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil) {
      if(!props.seamWeldPieceNumber) {
        return detailFieldsToUpdate.current.configurationId;
      }

      return seamWeldFieldsToUpdate.current.configurationId;
    }

    return anvilFieldsToUpdate.current.configurationId;
  };

  const [ncStockSerialNumber, setNCStockSerialNumber] = React.useState(getNCStockSerialNumber());

  // Returns the supplier choice depending on the fields populated
  const getSupplierChoice = () => {
    if(!props.seamWeldPieceNumber) {
      if(lineFieldsToUpdate.current.customerProvided) {
        return AddPieceSuppliers.CustomerSuppliedMetal;
      } else if(detailFieldsToUpdate.current.pullFromStock) {
        return AddPieceSuppliers.NCStock;
      } else if(detailFieldsToUpdate.current.rawMaterialVendor) {

        return NCSuppliers.includes(detailFieldsToUpdate.current.rawMaterialVendor as string)
          ? AddPieceSuppliers.NCStock
          : AddPieceSuppliers.SupplierContactFromQuoteRecords;
      }
    } else {
      if(seamWeldFieldsToUpdate.current.pullFromStock) {
        if(!seamWeldFieldsToUpdate.current.configurationId) {
          return AddPieceSuppliers.CustomerSuppliedMetal;
        }

        return AddPieceSuppliers.NCStock;
      } else if(seamWeldFieldsToUpdate.current.rawMaterialVendor) {
        return NCSuppliers.includes(seamWeldFieldsToUpdate.current.rawMaterialVendor)
          ? AddPieceSuppliers.NCStock
          : AddPieceSuppliers.SupplierContactFromQuoteRecords;
      }
    }
  };

  const [supplierChoice, setSupplierChoice] = React.useState(getSupplierChoice());

  // Gets the metal to use for pricing calculations
  const getMetalForCalcs = () => {
    switch(props.mstoData.activeTab) {
      case MSTOSelectionTabs.Cladder:
        return rawMaterials.find(rawMaterial => rawMaterial.name === quoteLineItemData.CladMetal__c && rawMaterial.usage === RawMaterialUsage.Clad && rawMaterial.dataAreaId.includes(manufacturingSite));
      case MSTOSelectionTabs.Backer:
        return rawMaterials.find(rawMaterial => rawMaterial.name === quoteLineItemData.BaseMetal__c && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite));
    }
  };

  const metalForCalcs = getMetalForCalcs();

  // Gets the metal pricing by proposal type for the metal to order
  const getMetalPricingByProposalType = () => {
    switch(props.mstoData.activeTab) {
      case MSTOSelectionTabs.Cladder:
        return metalForCalcs.metalPricing[manufacturingSite].filter(metalPricing => metalPricing.proposalType === quoteLineItemData.Proposal_Type_Clad__c);
      case MSTOSelectionTabs.Backer:
        return metalForCalcs.metalPricing[manufacturingSite].filter(metalPricing => metalPricing.proposalType === quoteLineItemData.Proposal_Type_Base__c);
    }
  };

  const metalPricingByProposalType = getMetalPricingByProposalType();

  const [supplier, setSupplier] = React.useState(!props.seamWeldPieceNumber ? (detailFieldsToUpdate.current.rawMaterialVendor ?? '0') : (seamWeldFieldsToUpdate.current.rawMaterialVendor ?? '0'));

  // Returns the right price per weight depending on metal used, and whether it is a seam weld panel
  const getPricePerWeight = () => {
    if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil) {
      if(!props.seamWeldPieceNumber) {
        return lineFieldsToUpdate.current.pricePerWeight;
      }

      return seamWeldFieldsToUpdate.current.pricePerWeight;
    }

    return detailFieldsToUpdate.current.pricePerWeight;
  };

  // Returns the right ship date depending on metal used, and whether it is a seam weld panel
  const getShipDate = () => {
    if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil) {
      if(!props.seamWeldPieceNumber) {
        return detailFieldsToUpdate.current.shipDate;
      }

      return seamWeldFieldsToUpdate.current.shipDate;
    }

    return anvilFieldsToUpdate.current.shipDate;
  };

  // Returns the right days for transport depending on metal used, and whether it is a seam weld panel
  const getDaysForTransport = () => {
    if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil) {
      if(!props.seamWeldPieceNumber) {
        return detailFieldsToUpdate.current.daysForTransport;
      }

      return seamWeldFieldsToUpdate.current.daysForTransport;
    }

    return anvilFieldsToUpdate.current.daysForTransport;
  };

  React.useEffect(() => {
    switch(supplierChoice) {
      case AddPieceSuppliers.SupplierContactFromQuoteRecords:
        if(!props.seamWeldPieceNumber) {
          detailFieldsToUpdate.current.purchase = true;
          detailFieldsToUpdate.current.pullFromStock = false;
        } else {
          seamWeldFieldsToUpdate.current.purchase = true;
          seamWeldFieldsToUpdate.current.pullFromStock = false;
        }

        if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil && !props.seamWeldPieceNumber) {
          lineFieldsToUpdate.current.customerProvided = false;
        }

        break;
      case AddPieceSuppliers.NCStock:
        if(!props.seamWeldPieceNumber) {
          detailFieldsToUpdate.current.purchase = false;
          detailFieldsToUpdate.current.pullFromStock = true;
          detailFieldsToUpdate.current.rawMaterialVendor = '0';
        } else {
          seamWeldFieldsToUpdate.current.purchase = false;
          seamWeldFieldsToUpdate.current.pullFromStock = true;
          seamWeldFieldsToUpdate.current.rawMaterialVendor = '0';
        }

        if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil && !props.seamWeldPieceNumber) {
          lineFieldsToUpdate.current.customerProvided = false;
        }

        break;
      case AddPieceSuppliers.CustomerSuppliedMetal:
        if(!props.seamWeldPieceNumber) {
          detailFieldsToUpdate.current.purchase = true;
          detailFieldsToUpdate.current.pullFromStock = false;
        } else {
          seamWeldFieldsToUpdate.current.purchase = false;
          seamWeldFieldsToUpdate.current.pullFromStock = true;
          seamWeldFieldsToUpdate.current.rawMaterialVendor = '0';
        }

        if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil && !props.seamWeldPieceNumber) {
          lineFieldsToUpdate.current.customerProvided = true;
        }

        break;
    }
  }, [supplierChoice]);

  React.useEffect(() => {
    if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil) {
      if(!props.seamWeldPieceNumber) {
        detailFieldsToUpdate.current.configurationId = ncStockSerialNumber;
        // set seam weld level to null
        // TODO: not sure yet
        seamWeldFieldsToUpdate.current.configurationId = null;
        seamWeldFieldsToUpdate.current.rawMaterialVendor = null;
      } else {
        seamWeldFieldsToUpdate.current.configurationId = ncStockSerialNumber;
        // set detail level to null
        // TODO: not sure yet
        detailFieldsToUpdate.current.configurationId = null;
        detailFieldsToUpdate.current.rawMaterialVendor = null;
      }
    } else {
      anvilFieldsToUpdate.current.configurationId = ncStockSerialNumber;
    }
  }, [ncStockSerialNumber]);

  React.useEffect(() => {
    if(!props.seamWeldPieceNumber) {
      detailFieldsToUpdate.current.rawMaterialVendor = supplier;
      // set seam weld field raw material vendor to null
      // TODO: not sure yet
      seamWeldFieldsToUpdate.current.configurationId = null;
      seamWeldFieldsToUpdate.current.rawMaterialVendor = null;
    } else {
      seamWeldFieldsToUpdate.current.rawMaterialVendor = supplier;
      // set detail level raw material vendor to null
      // TODO: not sure yet
      detailFieldsToUpdate.current.configurationId = null;
      detailFieldsToUpdate.current.rawMaterialVendor = null;
    }
  }, [supplier]);

  // Closes modal
  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined, itemsToEdit: undefined });
  };

  // Adds the panel to the panels list
  const addPanel = () => {
    const currentPanels = [...panels];

    currentPanels.push(getPanel());
    setPanels(currentPanels);
  };

  // Removes the panel from the panels list
  const removePanel = (index) => {
    const specifiedPanels = [...panels];

    let indexToDelete;
    specifiedPanels.forEach(panel => {
      if (panel.index === index) {
        indexToDelete = specifiedPanels.indexOf(panel);
      }
    });

    specifiedPanels.splice(indexToDelete, 1);
    setPanels(specifiedPanels);
  };

  // Adds fields to the update maps so they can be used in the panel for the metal to order
  const addFieldsToUpdateMaps = () => {
    const panel = panels[0];
    const width = calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.width, Units.Millimeters, Units.Inches);
    const length = calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.length, Units.Millimeters, Units.Inches);
    const TK = calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.TK, Units.Millimeters, Units.Inches);

    switch(props.mstoData.activeTab) {
      case MSTOSelectionTabs.Cladder:
        if(!props.seamWeldPieceNumber) {
          if(lineFieldsToUpdate.current.customerProvided !== quoteLineItemData.Customer_Provided_Clad__c) {
            lineItemFieldsMap.set(QuoteLineItemFields.CustomerProvidedClad, lineFieldsToUpdate.current.customerProvided);
          }

          if(panel.freightPerWeight !== (isDisplayMetric ? quoteLineItemDetailData?.PurchaseCladderFreight_KG__c : quoteLineItemDetailData?.PurchaseCladderFreight_LB__c)) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladderFreightLB, calculateUOMValue(localUom, MeasurementSystems.Imperial, panel.freightPerWeight, Units.PricePerKilogram, Units.PricePerPound));
            lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladderFreightKG, calculateUOMValue(localUom, MeasurementSystems.Metric, panel.freightPerWeight, Units.PricePerKilogram, Units.PricePerPound));
          }

          if(panel.pricePerWeight !== (isDisplayMetric ? quoteLineItemData.Clad_Price_KG__c : quoteLineItemData.Clad_Price_LB__c)) {
            lineItemFieldsMap.set(QuoteLineItemFields.CladPriceLB, calculateUOMValue(localUom, MeasurementSystems.Imperial, panel.pricePerWeight, Units.PricePerKilogram, Units.PricePerPound));
            lineItemFieldsMap.set(QuoteLineItemFields.CladPriceKG, calculateUOMValue(localUom, MeasurementSystems.Metric, panel.pricePerWeight, Units.PricePerKilogram, Units.PricePerPound));
          }

          if(panel.pricePerPiece !== quoteLineItemDetailData?.PurchaseCladderPrice__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladderPrice, panel.pricePerPiece);
          }

          if(detailFieldsToUpdate.current.rawMaterialVendor !== quoteLineItemDetailData?.RawMaterialVendorCladder__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.RawMaterialVendorCladder, detailFieldsToUpdate.current.rawMaterialVendor);
            // assume the first one
            seamWeldItemDetailFieldsMap.set('RawMaterialVendorSW1__c', seamWeldFieldsToUpdate.current.rawMaterialVendor);
          }

          if(detailFieldsToUpdate.current.configurationId !== quoteLineItemDetailData?.ConfigurationID__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.ConfigurationID, detailFieldsToUpdate.current.configurationId);
            // assume the first one
            seamWeldItemDetailFieldsMap.set('ConfigurationID__c', seamWeldFieldsToUpdate.current.configurationId);
          }

          if(detailFieldsToUpdate.current.purchase !== quoteLineItemDetailData?.PurchaseCL__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCL, detailFieldsToUpdate.current.purchase);
          }

          if(detailFieldsToUpdate.current.pullFromStock !== quoteLineItemDetailData?.Pullfromstockcladder__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.Pullfromstockcladder, detailFieldsToUpdate.current.pullFromStock);
          }

          if(width !== quoteLineItemDetailData?.PurchaseCladWidth__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladWidth, width);
          }

          if(length !== quoteLineItemDetailData?.PurchaseCladLength__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladLength, length);
          }

          if(TK !== quoteLineItemDetailData?.PurchaseCladTK__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladTK, TK);
          }

          lengthWidthTKDisplayFieldsMap.set('DisplayWidth', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayWidth, Units.Millimeters, Units.Inches));
          lengthWidthTKDisplayFieldsMap.set('DisplayLength', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayLength, Units.Millimeters, Units.Inches));
          lengthWidthTKDisplayFieldsMap.set('DisplayTK', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayTK, Units.Millimeters, Units.Inches));

          // eslint-disable-next-line no-case-declarations
          const cladWidthDelta = width - (quoteLineItemData.Unit_of_Measure__c === MeasurementSystems.Metric ? quoteLineItemDetailData.Clad_Width_mm__c : quoteLineItemDetailData.Clad_Width_in__c);
          // eslint-disable-next-line no-case-declarations
          const cladLengthDelta = length - (quoteLineItemData.Unit_of_Measure__c === MeasurementSystems.Metric ? quoteLineItemDetailData.Clad_Length_mm__c : quoteLineItemDetailData.Clad_Length_in__c);
          // eslint-disable-next-line no-case-declarations
          const hasCladPurchaseDelta = cladWidthDelta > 0 || cladLengthDelta > 0;
          lineItemDetailFieldsMap.set(QuoteLineItemFields.TorchCutCladder, hasCladPurchaseDelta);

          if(panel.weight !== (isDisplayMetric ? quoteLineItemDetailData?.PurchaseCladderWeight_KG__c : quoteLineItemDetailData?.PurchaseCladderWeight_LB__c)) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladderWeightLB, calculateUOMValue(localUom, MeasurementSystems.Imperial, panel.weight, Units.Kilograms, Units.Pounds));
            lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladderWeightKG, calculateUOMValue(localUom, MeasurementSystems.Metric, panel.weight, Units.Kilograms, Units.Pounds));
          }

          if(detailFieldsToUpdate.current.shipDate !== quoteLineItemDetailData?.ShipDateCladder__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.ShipDateCladder, detailFieldsToUpdate.current.shipDate);
          }

          if(detailFieldsToUpdate.current.preHeatToCut !== quoteLineItemDetailData?.PreheattoTorchCutCladder__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.PreheattoTorchCutCladder, detailFieldsToUpdate.current.preHeatToCut);
          }

          if(detailFieldsToUpdate.current.daysForTransport !== quoteLineItemDetailData?.CladderDaysForTransport__c) {
            lineItemDetailFieldsMap.set(QuoteLineItemFields.CladderDaysForTransport, detailFieldsToUpdate.current.daysForTransport);
          }
        } else {
          const rawMaterialVendorField = `RawMaterialVendorSW${props.seamWeldPieceNumber}__c`;
          const configurationIdField = props.seamWeldPieceNumber === 1 ? 'ConfigurationID__c' : `ConfigurationIDSW${props.seamWeldPieceNumber}__c`;
          const purchaseField = `PurchaseSW${props.seamWeldPieceNumber}__c`;
          const purchaseQuantityField = `PurchaseQuantitySW${props.seamWeldPieceNumber}__c`;
          const pullFromStockField = `PullFromStockSW${props.seamWeldPieceNumber}__c`;
          const pullFromStockQuantityField = `PullFromStockQuantitySW${props.seamWeldPieceNumber}__c`;
          const widthField = `CladderWidthSW${props.seamWeldPieceNumber}__c`;
          const lengthField = `CladderLengthSW${props.seamWeldPieceNumber}__c`;
          const TKField = `NominalThicknessSW${props.seamWeldPieceNumber}__c`;
          const weightField = `WeightSW${props.seamWeldPieceNumber}__c`;
          const shipDateField = `ShipDateSW${props.seamWeldPieceNumber}__c`;
          const daysForTransportField = `DaysForTransportSW${props.seamWeldPieceNumber}__c`;
          const preHeatToCutField = `PreheatToCutSW${props.seamWeldPieceNumber}__c`;
          const pricePerPieceField = `SWRawMaterialCostSW${props.seamWeldPieceNumber}__c`;
          const freightPerWeightField = `EstimatedShippingTotalSW${props.seamWeldPieceNumber}__c`;

          if(panel.freightPerWeight !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[freightPerWeightField]) {
            seamWeldItemDetailFieldsMap.set(freightPerWeightField, panel.freightPerWeight);
          }

          if(panel.pricePerPiece !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[pricePerPieceField]) {
            seamWeldItemDetailFieldsMap.set(pricePerPieceField, panel.pricePerPiece);
          }

          if(seamWeldFieldsToUpdate.current.rawMaterialVendor !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[rawMaterialVendorField]) {
            seamWeldItemDetailFieldsMap.set(rawMaterialVendorField, seamWeldFieldsToUpdate.current.rawMaterialVendor);
            // should reset detail level
            lineItemDetailFieldsMap.set(QuoteLineItemFields.RawMaterialVendorCladder, detailFieldsToUpdate.current.rawMaterialVendor);
          }

          if(seamWeldFieldsToUpdate.current.configurationId !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[configurationIdField]) {
            seamWeldItemDetailFieldsMap.set(configurationIdField, seamWeldFieldsToUpdate.current.configurationId);
            // should reset detail level
            lineItemDetailFieldsMap.set(QuoteLineItemFields.ConfigurationID, detailFieldsToUpdate.current.configurationId);
          }

          if(seamWeldFieldsToUpdate.current.purchase !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[purchaseField]) {
            seamWeldItemDetailFieldsMap.set(purchaseField, seamWeldFieldsToUpdate.current.purchase);
          }

          if(seamWeldFieldsToUpdate.current.purchase) {
            seamWeldItemDetailFieldsMap.set(purchaseQuantityField, SeamWeldQuantity.One);
          }

          if(seamWeldFieldsToUpdate.current.pullFromStock !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[pullFromStockField]) {
            seamWeldItemDetailFieldsMap.set(pullFromStockField, seamWeldFieldsToUpdate.current.pullFromStock);
          }

          if(seamWeldFieldsToUpdate.current.pullFromStock) {
            seamWeldItemDetailFieldsMap.set(pullFromStockQuantityField, SeamWeldQuantity.One);
          }

          if(width !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[widthField]) {
            seamWeldItemDetailFieldsMap.set(widthField, width);
          }

          if(length !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[lengthField]) {
            seamWeldItemDetailFieldsMap.set(lengthField, length);
          }

          if(TK !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[TKField]) {
            seamWeldItemDetailFieldsMap.set(TKField, TK);
          }

          if(panel.weight !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[weightField]) {
            seamWeldItemDetailFieldsMap.set(weightField, panel.weight);
          }

          if(seamWeldFieldsToUpdate.current.shipDate !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[shipDateField]) {
            seamWeldItemDetailFieldsMap.set(shipDateField, seamWeldFieldsToUpdate.current.shipDate);
          }

          if(seamWeldFieldsToUpdate.current.preHeatToCut !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[preHeatToCutField]) {
            seamWeldItemDetailFieldsMap.set(preHeatToCutField, seamWeldFieldsToUpdate.current.preHeatToCut);
          }

          if(seamWeldFieldsToUpdate.current.daysForTransport !== quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[daysForTransportField]) {
            seamWeldItemDetailFieldsMap.set(daysForTransportField, seamWeldFieldsToUpdate.current.daysForTransport);
          }

          // TODO need to verify the fix below
          // should fixed undefined measurements on update of seamweld
          lengthWidthTKDisplayFieldsMap.set('DisplayWidth', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayWidth, Units.Millimeters, Units.Inches));
          lengthWidthTKDisplayFieldsMap.set('DisplayLength', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayLength, Units.Millimeters, Units.Inches));
          lengthWidthTKDisplayFieldsMap.set('DisplayTK', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayTK, Units.Millimeters, Units.Inches));
        }

        if(detailFieldsToUpdate.current.seamWeld !== quoteLineItemDetailData?.SeamWeld__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.SeamWeld, detailFieldsToUpdate.current.seamWeld);
        }

        break;
      case MSTOSelectionTabs.Backer:
        if(lineFieldsToUpdate.current.customerProvided !== quoteLineItemData.Customer_Provided_Base__c) {
          lineItemFieldsMap.set(QuoteLineItemFields.CustomerProvidedBase, lineFieldsToUpdate.current.customerProvided);
        }

        if(panel.freightPerWeight !== (isDisplayMetric ? quoteLineItemDetailData?.PurchaseBackerFreight_KG__c : quoteLineItemDetailData?.PurchaseBackerFreight_LB__c)) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBackerFreightLB, calculateUOMValue(localUom, MeasurementSystems.Imperial, panel.freightPerWeight, Units.PricePerKilogram, Units.PricePerPound));
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBackerFreightKG, calculateUOMValue(localUom, MeasurementSystems.Metric, panel.freightPerWeight, Units.PricePerKilogram, Units.PricePerPound));
        }

        if(panel.pricePerWeight !== (isDisplayMetric ? quoteLineItemData.Base_Price_KG__c : quoteLineItemData.Base_Price_LB__c)) {
          lineItemFieldsMap.set(QuoteLineItemFields.BasePriceLB, calculateUOMValue(localUom, MeasurementSystems.Imperial, panel.pricePerWeight, Units.PricePerKilogram, Units.PricePerPound));
          lineItemFieldsMap.set(QuoteLineItemFields.BasePriceKG, calculateUOMValue(localUom, MeasurementSystems.Metric, panel.pricePerWeight, Units.PricePerKilogram, Units.PricePerPound));
        }

        if(panel.pricePerPiece !== quoteLineItemDetailData?.PurchaseBackerPrice__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBackerPrice, panel.pricePerPiece);
        }

        if(detailFieldsToUpdate.current.rawMaterialVendor !== quoteLineItemDetailData?.RawMaterialVendorBacker__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.RawMaterialVendorBacker, detailFieldsToUpdate.current.rawMaterialVendor);
        }

        if(detailFieldsToUpdate.current.configurationId !== quoteLineItemDetailData?.ConfigurationIDBacker__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.ConfigurationIDBacker, detailFieldsToUpdate.current.configurationId);
        }

        if(detailFieldsToUpdate.current.purchase !== quoteLineItemDetailData?.PurchaseBK__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBK, detailFieldsToUpdate.current.purchase);
        }

        if(detailFieldsToUpdate.current.pullFromStock !== quoteLineItemDetailData?.Pullfromstockbacker__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.Pullfromstockbacker, detailFieldsToUpdate.current.pullFromStock);
        }

        if(width !== quoteLineItemDetailData?.PurchaseBaseWidth__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBaseWidth, width);
        }

        if(length !== quoteLineItemDetailData?.PurchaseBaseLength__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBaseLength, length);
        }

        if(TK !== quoteLineItemDetailData?.PurchaseBaseTK__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBaseTK, TK);
        }

        lengthWidthTKDisplayFieldsMap.set('DisplayWidth', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayWidth, Units.Millimeters, Units.Inches));
        lengthWidthTKDisplayFieldsMap.set('DisplayLength', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayLength, Units.Millimeters, Units.Inches));
        lengthWidthTKDisplayFieldsMap.set('DisplayTK', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayTK, Units.Millimeters, Units.Inches));

        // eslint-disable-next-line no-case-declarations
        const baseWidthDelta = width - (quoteLineItemData.Unit_of_Measure__c === MeasurementSystems.Metric ? quoteLineItemDetailData.Base_Width_mm__c : quoteLineItemDetailData.Base_Width_in__c);
        // eslint-disable-next-line no-case-declarations
        const baseLengthDelta = length - (quoteLineItemData.Unit_of_Measure__c === MeasurementSystems.Metric ? quoteLineItemDetailData.Base_Length_mm__c : quoteLineItemDetailData.Base_Length_in__c);
        // eslint-disable-next-line no-case-declarations
        const hasBasePurchaseDelta = baseWidthDelta > 0 || baseLengthDelta > 0;
        lineItemDetailFieldsMap.set(QuoteLineItemFields.TorchCutBacker, hasBasePurchaseDelta);

        if(panel.weight !== (isDisplayMetric ? quoteLineItemDetailData?.PurchaseBackerWeight_KG__c : quoteLineItemDetailData?.PurchaseBackerWeight_LB__c)) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBackerWeightLB, calculateUOMValue(localUom, MeasurementSystems.Imperial, panel.weight, Units.Kilograms, Units.Pounds));
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBackerWeightKG, calculateUOMValue(localUom, MeasurementSystems.Metric, panel.weight, Units.Kilograms, Units.Pounds));
        }

        if(detailFieldsToUpdate.current.shipDate !== quoteLineItemDetailData?.ShipDateBase__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.ShipDateBase, detailFieldsToUpdate.current.shipDate);
        }

        if(detailFieldsToUpdate.current.preHeatToCut !== quoteLineItemDetailData?.PreheattoTorchCutBacker__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PreheattoTorchCutBacker, detailFieldsToUpdate.current.preHeatToCut);
        }

        if(detailFieldsToUpdate.current.daysForTransport !== quoteLineItemDetailData?.BackerDaysForTransport__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.BackerDaysForTransport, detailFieldsToUpdate.current.daysForTransport);
        }

        break;
      case MSTOSelectionTabs.Anvil:
        if(panel.pricePerPiece !== quoteLineItemDetailData?.PurchaseAnvilPrice__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseAnvilPrice, panel.pricePerPiece);
        }

        if(detailFieldsToUpdate.current.rawMaterialVendor !== quoteLineItemDetailData?.VendorAnvil__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.VendorAnvil, detailFieldsToUpdate.current.rawMaterialVendor);
        }

        if(anvilFieldsToUpdate.current.configurationId !== quoteLineItemDetailData?.NC_Anvil_Item_Details__r.ConfigurationIDAnvil__c) {
          anvilItemDetailFieldsMap.set(QuoteLineItemFields.ConfigurationIDAnvil, anvilFieldsToUpdate.current.configurationId);
        }

        if(detailFieldsToUpdate.current.purchase !== quoteLineItemDetailData?.PurchaseBKAnvil__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBKAnvil, detailFieldsToUpdate.current.purchase);
        }

        if(detailFieldsToUpdate.current.pullFromStock !== quoteLineItemDetailData?.PullfromstockBKAnvil__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PullfromstockBKAnvil, detailFieldsToUpdate.current.pullFromStock);
        }

        if(width !== quoteLineItemDetailData?.PurchaseAnvilWidth__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseAnvilWidth, width);
        }

        if(length !== quoteLineItemDetailData?.PurchaseAnvilLength__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseAnvilLength, length);
        }

        if(TK !== quoteLineItemDetailData?.PurchaseAnvilTK__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseAnvilTK, TK);
        }

        lengthWidthTKDisplayFieldsMap.set('DisplayWidth', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayWidth, Units.Millimeters, Units.Inches));
        lengthWidthTKDisplayFieldsMap.set('DisplayLength', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayLength, Units.Millimeters, Units.Inches));
        lengthWidthTKDisplayFieldsMap.set('DisplayTK', calculateUOMValue(localUom, quoteLineItemData.Unit_of_Measure__c, panel.displayTK, Units.Millimeters, Units.Inches));

        // eslint-disable-next-line no-case-declarations
        const anvilWidthDelta = panel.width - (isDisplayMetric ? quoteLineItemDetailData.Anvil_Width_mm__c : quoteLineItemDetailData.Anvil_Width_in__c);
        // eslint-disable-next-line no-case-declarations
        const anvilLengthDelta = panel.length - (isDisplayMetric ? quoteLineItemDetailData.Anvil_Length_mm__c : quoteLineItemDetailData.Anvil_Length_in__c);
        // eslint-disable-next-line no-case-declarations
        const hasAnvilPurchaseDelta = anvilWidthDelta > 0 || anvilLengthDelta > 0;
        lineItemDetailFieldsMap.set(QuoteLineItemFields.TorchCutAnvil1, hasAnvilPurchaseDelta);

        if(panel.weight !== (isDisplayMetric ? quoteLineItemDetailData?.PurchaseAnvilWeight_KG__c : quoteLineItemDetailData?.PurchaseAnvilWeight_LB__c)) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseAnvilWeightLB, calculateUOMValue(localUom, MeasurementSystems.Imperial, panel.weight, Units.Kilograms, Units.Pounds));
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseAnvilWeightKG, calculateUOMValue(localUom, MeasurementSystems.Metric, panel.weight, Units.Kilograms, Units.Pounds));
        }

        if(anvilFieldsToUpdate.current.shipDate !== quoteLineItemDetailData?.NC_Anvil_Item_Details__r.ShipDateAnvil__c) {
          anvilItemDetailFieldsMap.set(QuoteLineItemFields.ShipDateAnvil, anvilFieldsToUpdate.current.shipDate);
        }

        if(detailFieldsToUpdate.current.preHeatToCut !== quoteLineItemDetailData?.PreheattoCutAnvil__c) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PreheattoCutAnvil, detailFieldsToUpdate.current.preHeatToCut);
        }

        if(anvilFieldsToUpdate.current.daysForTransport !== quoteLineItemDetailData?.NC_Anvil_Item_Details__r.AnvilDaysForTransport__c) {
          anvilItemDetailFieldsMap.set(QuoteLineItemFields.AnvilDaysForTransport, anvilFieldsToUpdate.current.daysForTransport);
        }

        if(panel.freightPerWeight !== (isDisplayMetric ? quoteLineItemDetailData?.PurchaseAnvilFreight_KG__c : quoteLineItemDetailData?.PurchaseAnvilFreight_LB__c)) {
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseAnvilFreightLB, calculateUOMValue(localUom, MeasurementSystems.Imperial, panel.freightPerWeight, Units.PricePerKilogram, Units.PricePerPound));
          lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseAnvilFreightKG, calculateUOMValue(localUom, MeasurementSystems.Metric, panel.freightPerWeight, Units.PricePerKilogram, Units.PricePerPound));
        }

        break;
    }
  };

  const SupplierSelections = () => {

    return (
      <div className="add-piece-supplier-selections">
      <SelectionContainer
        type={SelectionTypes.RadioButtons}
        groupStyle={{ display: 'block' }}
        itemStyle={{ '--inner-padding-end': '7px' }}
        options={
          getSelectionOptions(
            selections,
            manufacturingSite,
            QuoteLineItemValueTypes.AddPieceSupplyChoice
          )?.options
        }
        onIonChange={(supplierChoice) => {
          if(supplierChoice === AddPieceSuppliers.SupplierContactFromQuoteRecords || supplierChoice === AddPieceSuppliers.NCStock || supplierChoice === AddPieceSuppliers.CustomerSuppliedMetal) {
            setSupplierChoice(supplierChoice);
            // set vendor to '0' if supplierChoice === AddPieceSuppliers.NCStock
            if (supplierChoice !== AddPieceSuppliers.SupplierContactFromQuoteRecords) {
              setSupplier('0');
            }

            if (supplierChoice !== AddPieceSuppliers.SupplierContactFromQuoteRecords) {
              setSupplier(undefined);
            }

            if (supplierChoice !== AddPieceSuppliers.NCStock) {
              setNCStockSerialNumber(undefined);

              switch(props.mstoData.activeTab) {
                case MSTOSelectionTabs.Cladder:
                  setSupplier(NCSupplier.Cladder);
                  break;

                case MSTOSelectionTabs.Backer:
                  setSupplier(NCSupplier.Backer);
                  break;
              }
            }
          }
        }}
        value={supplierChoice}
        extraComponents={[
          <SelectionContainer
            type={SelectionTypes.SearchableDropdown}
            noneFoundText='No suppliers found'
            placeholder="Select Supplier"
            containerStyle={{ width: '193px', marginLeft: '85px' }}
            options={getSupplierSelections()}
            isDisabled={
              supplierChoice !== AddPieceSuppliers.SupplierContactFromQuoteRecords
            }
            suggestionDisplayFields={[SearchFields.VendorName]}
            onChange={(vendorSelection) => setSupplier(vendorSelection.vendorId)}
            value={vendors?.find(vendor => vendor.vendorId === supplier && vendor.dataAreaId === manufacturingSite && !NCSuppliers.includes(supplier))?.vendorName}
          />,
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder="Enter Serial Number"
            inputType="text"
            style={{ width: '193px', marginLeft: '70px' }}
            isDisabled={
              supplierChoice !== AddPieceSuppliers.NCStock
            }
            onBlur={(serialNumber) => setNCStockSerialNumber(serialNumber)}
            value={ncStockSerialNumber}
          />,
        ]}
      />
    </div>
    );
  };

  const DimensionsAndPricing = () => {
    const [width, setWidth] = React.useState(!props.seamWeldPieceNumber ? detailFieldsToUpdate.current.width : seamWeldFieldsToUpdate.current.width);
    const [weight, setWeight] = React.useState(!props.seamWeldPieceNumber ? detailFieldsToUpdate.current.weight : seamWeldFieldsToUpdate.current.weight);
    const [length, setLength] = React.useState(!props.seamWeldPieceNumber ? detailFieldsToUpdate.current.length : seamWeldFieldsToUpdate.current.length);
    const [thickness, setThickness] = React.useState(!props.seamWeldPieceNumber ? detailFieldsToUpdate.current.TK : seamWeldFieldsToUpdate.current.TK);
    const [freightPerWeight, setFreightPerWeight] = React.useState(!props.seamWeldPieceNumber ? detailFieldsToUpdate.current.freightPerWeight : seamWeldFieldsToUpdate.current.freightPerWeight);
    const [pricePerWeight, setPricePerWeight] = React.useState(getPricePerWeight());
    const [pricePerPiece, setPricePerPiece] = React.useState(!props.seamWeldPieceNumber ? detailFieldsToUpdate.current.pricePerPiece : seamWeldFieldsToUpdate.current.pricePerPiece);
    const [newPanelQuantity, setNewPanelQuantity] = React.useState(panelQuantity.current);

    React.useEffect(() => {
      if(!props.seamWeldPieceNumber) {
        detailFieldsToUpdate.current.width = width;
      } else {
        seamWeldFieldsToUpdate.current.width = width;
      }
    }, [width]);

    React.useEffect(() => {
      panelQuantity.current = newPanelQuantity;
    }, [newPanelQuantity]);

    React.useEffect(() => {
      if(!props.seamWeldPieceNumber) {
        detailFieldsToUpdate.current.length = length;
      } else {
        seamWeldFieldsToUpdate.current.length = length;
      }
    }, [length]);

    React.useEffect(() => {
      if(!props.seamWeldPieceNumber) {
        detailFieldsToUpdate.current.TK = thickness;
      } else {
        seamWeldFieldsToUpdate.current.TK = thickness;
      }
    }, [thickness]);

    React.useEffect(() => {
      if(!props.seamWeldPieceNumber) {
        detailFieldsToUpdate.current.weight = weight;
      } else {
        seamWeldFieldsToUpdate.current.weight = weight;
      }
    }, [weight]);

    React.useEffect(() => {
      if(!props.seamWeldPieceNumber) {
        detailFieldsToUpdate.current.freightPerWeight = freightPerWeight;
      } else {
        seamWeldFieldsToUpdate.current.freightPerWeight = freightPerWeight;
      }
    }, [freightPerWeight]);

    React.useEffect(() => {
      if(!props.seamWeldPieceNumber) {
        lineFieldsToUpdate.current.pricePerWeight = pricePerWeight;
      } else {
        seamWeldFieldsToUpdate.current.pricePerWeight = pricePerWeight;
      }
    }, [pricePerWeight]);

    React.useEffect(() => {
      if(!props.seamWeldPieceNumber) {
        detailFieldsToUpdate.current.pricePerPiece = pricePerPiece;
      } else {
        seamWeldFieldsToUpdate.current.pricePerPiece = pricePerPiece;
      }
    }, [pricePerPiece]);

    const updatePricePerWeight = (panelPricePerWeight) => {
      setPricePerWeight(panelPricePerWeight);
      setPricePerPiece(panelPricePerWeight * weight);
    };

    const updatePricePerPiece = (panelPricePerPiece) => {
      setPricePerPiece(panelPricePerPiece);
      setPricePerWeight(panelPricePerPiece / weight);
    };

    const updatePricing = (length: number, width: number, thickness: number) => {
      try {
        let metalType;
        switch(props.mstoData.activeTab) {
          case MSTOSelectionTabs.Cladder:
            metalType = MetalTypesForAreaCalc.Clad;
            break;
          case MSTOSelectionTabs.Backer:
            metalType = MetalTypesForAreaCalc.Base;
            break;
          case MSTOSelectionTabs.Anvil:
            metalType = MetalTypesForAreaCalc.Anvil;
            break;
        }

        if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil) {
          let poundsPerPiece;
          let kilogramsPerPiece;
          if(props.mstoData.activeTab === MSTOSelectionTabs.Backer) {
            poundsPerPiece = calculateRawMetalWeight(localUom, MeasurementSystems.Imperial, length, width, thickness, calculateMetalDensity(MeasurementSystems.Imperial, metalForCalcs), metalType, metalForCalcs.dmcClass.includes('/F'));
            kilogramsPerPiece = calculateRawMetalWeight(localUom, MeasurementSystems.Metric, length, width, thickness, calculateMetalDensity(MeasurementSystems.Metric, metalForCalcs), metalType, metalForCalcs.dmcClass.includes('/F'));
          } else {
            poundsPerPiece = calculateRawMetalWeight(localUom, MeasurementSystems.Imperial, length, width, thickness, calculateMetalDensity(MeasurementSystems.Imperial, metalForCalcs), metalType);
            kilogramsPerPiece = calculateRawMetalWeight(localUom, MeasurementSystems.Metric, length, width, thickness, calculateMetalDensity(MeasurementSystems.Metric, metalForCalcs), metalType);
          }

          setWeight(isDisplayMetric ? kilogramsPerPiece : poundsPerPiece);

          const metalPricing = getMetalPricing(metalPricingByProposalType, localUom, thickness);
          const freightGroup = getFreightGroup(freight, metalPricing?.freightZone, poundsPerPiece, kilogramsPerPiece, manufacturingSite, localUom);

          const freightPerPound = freightGroup?.imperial.costPerUnit + freightGroup?.imperial.wideAdderCostPerUnit;
          const freightPerKilogram = freightGroup?.metric.costPerUnit + freightGroup?.metric.wideAdderCostPerUnit;

          setFreightPerWeight(isDisplayMetric ? freightPerKilogram : freightPerPound);

          const pricePerPound = metalPricing?.imperial?.costPerUnit;
          const pricePerKilogram = metalPricing?.metric?.costPerUnit;

          if(pricePerPound) {
            setPricePerWeight(isDisplayMetric ? pricePerKilogram : pricePerPound);
            setPricePerPiece(isDisplayMetric ? kilogramsPerPiece * pricePerKilogram : poundsPerPiece * pricePerPound);
          }
        } else {
          const anvilStandardDensityDescription = isDisplayMetric ?
          QuoteVariableConfigDescriptions.AnvilStandardDensityForWeightCalculationGramsPerCUCM :
          QuoteVariableConfigDescriptions.AnvilStandardDensityForWeightCalculationPoundsPerCUIN;

          const anvilStandardDensity = getQuoteVariableConfiguration(quoteVariableConfigurations, anvilStandardDensityDescription, manufacturingSite, FieldTypes.Float) as number;
          const anvilDensity = isDisplayMetric ? anvilStandardDensity * 1e-6 : anvilStandardDensity;

          const weightPerPiece = calculateRawMetalWeight(localUom, localUom, length, width, thickness, anvilDensity, metalType);

          setWeight(weightPerPiece);
          setPricePerPiece(weightPerPiece * pricePerWeight);
        }
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <div>
        <IonLabel class="add-piece-section-title">
          Dimensions And Pricing
        </IonLabel>
        <hr></hr>
        <div className="selections">
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="flex-row">
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    inputType="number"
                    selectionLabel="Width"
                    placeholder="0.00"
                    regex={Regexes.DecimalNumber}
                    min="0.01"
                    step="0.01"
                    style={{ width: '63px', marginRight: '6px' }}
                    onBlur={(panelWidth) => {
                      setWidth(panelWidth);
                      // dont update pricing when it is seamweld
                      if(!props.seamWeldPieceNumber) {
                        updatePricing(length, panelWidth, thickness);
                      }
                    }}
                    value={width}
                    formatter={(value) => {
                      return value !== '' && value !== null ? (+value).toFixed(3) : '';
                    }}
                  />
                  <IonImg
                    src={!darkMode ? closeIcon : closeIconWhite}
                    class="multiply-icon"
                  ></IonImg>
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    inputType="number"
                    selectionLabel="Length"
                    placeholder="0.00"
                    regex={Regexes.DecimalNumber}
                    min="0.01"
                    step="0.01"
                    style={{ width: '63px', marginRight: '20px' }}
                    onBlur={(panelLength) => {
                      setLength(panelLength);
                      // dont update pricing when it is seamweld
                      if(!props.seamWeldPieceNumber) {
                        updatePricing(panelLength, width, thickness);
                      }
                    }}
                    value={length}
                    formatter={(value) => {
                      return value !== '' && value !== null ? (+(value || 0)).toFixed(3) : '';
                    }}
                  />
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    inputType="number"
                    selectionLabel={`Freight per ${isDisplayMetric ? 'KG' : 'LB'}`}
                    placeholder="0.00"
                    regex={Regexes.DecimalNumber}
                    min="0.01"
                    step="0.01"
                    containerStyle={{ marginLeft: '46px' }}
                    style={{ width: '193px', marginRight: '20px' }}
                    onBlur={(panelFrieght) =>
                      (setFreightPerWeight(panelFrieght))
                    }
                    value={freightPerWeight}
                    formatter={(value) => {
                      return value !== '' && value !== null ? (+(value || 0)).toFixed(3) : '';
                    }}
                  />
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    selectionLabel={`Price Per ${isDisplayMetric ? 'KG' : 'LB'}`}
                    placeholder="0.00"
                    inputType="number"
                    regex={Regexes.DecimalNumber}
                    min="0.00"
                    style={{ width: '193px', marginRight: '20px' }}
                    onBlur={(panelPricePerWeight) =>
                      updatePricePerWeight(panelPricePerWeight)
                    }
                    value={pricePerWeight}
                    formatter={(value) => {
                      return value !== '' && value !== null ? (+ (value || 0)).toFixed(3) : '';
                    }}
                  />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="flex-row">
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    inputType="number"
                    selectionLabel="Thickness"
                    placeholder="0.00"
                    regex={Regexes.DecimalNumber}
                    min="0.01"
                    step="0.01"
                    style={{ width: '193px', marginRight: '20px' }}
                    onBlur={(panelThickness) => {
                      setThickness(panelThickness);
                      // updatePricing(length, width, panelThickness);
                    }}
                    value={thickness}
                    formatter={(value) => {
                      return value !== '' && value !== null ? (value || 0).toFixed(3) : '';
                    }}
                  />
                  <SelectionContainer
                    type={SelectionTypes.ReadOnly}
                    inputType="number"
                    selectionLabel={`Weight ${isDisplayMetric ? '(KG)' : '(LB)'}`}
                    placeholder="0.00"
                    regex={Regexes.DecimalNumber}
                    min="0.01"
                    step="0.01"
                    style={{ width: '193px', marginRight: '20px' }}
                    onBlur={(panelWeight) =>
                      (setWeight(panelWeight))
                    }
                    value={weight}
                    formatter={(value) => {
                      return value !== '' && value !== null ? (value || 0).toFixed(0) : '';
                    }}
                  />
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    selectionLabel="Price Per Piece"
                    placeholder="0.00"
                    inputType="number"
                    regex={Regexes.DecimalNumber}
                    min="0.00"
                    style={{ width: '193px', marginRight: '20px' }}
                    onBlur={(panelPricePerPiece) =>
                      updatePricePerPiece(panelPricePerPiece)
                    }
                    value={pricePerPiece}
                    formatter={(value) => {
                      return value !== '' && value !== null ? (value || 0).toFixed(2) : '';
                    }}
                  />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="flex-row">
                  <div className="flex-container">
                    {!props.editing ? <SelectionContainer
                      type={SelectionTypes.TextInput}
                      inputType="number"
                      selectionLabel="Quantity"
                      placeholder="1"
                      regex={Regexes.IntegerAboveZero}
                      min="1"
                      style={{ width: '193px', marginRight: '20px' }}
                      onBlur={(updatedPanelQuantity) => {
                        setNewPanelQuantity(updatedPanelQuantity);
                      }
                      }
                      value={newPanelQuantity}
                                      /> : null}
                    <CheckboxItem
                      label="PreHeat To Cut Required For Metal"
                      style={{ width: 'fit-content', marginTop: '30px', marginBottom: 'auto', ['--background']: 'transparent',}}
                      onIonChange={(checked) => {
                        if(!props.seamWeldPieceNumber) {
                          detailFieldsToUpdate.current.preHeatToCut = checked;
                        } else {
                          seamWeldFieldsToUpdate.current.preHeatToCut = checked;
                        }
                      }}
                      checked={!props.seamWeldPieceNumber ? detailFieldsToUpdate.current.preHeatToCut : seamWeldFieldsToUpdate.current.preHeatToCut}
                    />
                  </div>
                  <div className="flex-container add-panel-container" onClick={() => addPanel()}>
                    <IonIcon class="button-icon add-panel-icon" src={addCircle}></IonIcon>
                    <IonLabel class="button-label add-panel-label">Add Panel</IonLabel>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    );
  };

  const updateChangedFields = (newValue, valueType: QuoteLineItemValueTypes, index: number) => {
    const updatedPanels = [...panels];
    switch(valueType) {
      case QuoteLineItemValueTypes.Width:
        updatedPanels[index] = {
          ...updatedPanels[index],
          displayWidth: newValue
        };
        break;
      case QuoteLineItemValueTypes.Length:
        updatedPanels[index] = {
          ...updatedPanels[index],
          displayLength: newValue
        };
        break;
      case QuoteLineItemValueTypes.CladTK:
      case QuoteLineItemValueTypes.BaseTK:
      case QuoteLineItemValueTypes.AnvilTK:
        updatedPanels[index] = {
          ...updatedPanels[index],
          displayTK: newValue
        };
        break;
    }

    setPanels(updatedPanels);
  };

  const PanelTable = (props) => {
    return (
      <div>
        <IonCard className="panel-card">
          <IonCardHeader className="panel-card-header">
            <IonGrid className="panel-grid">
              <IonRow  className="panel-row">
                <IonCol size="1.2705" class="column-left">
                  <LineItemField
                    datatestid="QAAddPieceModalPanelWidth"
                    valueType={QuoteLineItemValueTypes.Width}
                    imperialValue={props.panel.displayWidth}
                    metricValue={props.panel.displayWidth}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={false}

                    ignoreRecalculateFields={true}
                    fieldChangedCB={updateChangedFields}
                    tabIndex={props.panelIndex + '.0'}
                    nextTabIndex={props.panelIndex + '.1'}
                    indexToUpdate={props.panel.index}
                  />
                </IonCol>
                <IonCol size="1.2705" class="column-left">
                  <LineItemField
                    datatestid="QAAddPieceModalPanelLength"
                    valueType={QuoteLineItemValueTypes.Length}
                    imperialValue={props.panel.displayLength}
                    metricValue={props.panel.displayLength}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={false}

                    ignoreRecalculateFields={true}
                    fieldChangedCB={updateChangedFields}
                    tabIndex={props.panelIndex + '.0'}
                    nextTabIndex={props.panelIndex + '.1'}
                    indexToUpdate={props.panel.index}
                  />
                </IonCol>
                <IonCol size="1.56" class="column-left">
                  <LineItemField
                    datatestid="QAAddPieceModalPanelTK"
                    valueType={QuoteLineItemValueTypes.CladTK}
                    imperialValue={props.panel.displayTK}
                    metricValue={props.panel.displayTK}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                    readOnly={false}

                    ignoreRecalculateFields={true}
                    fieldChangedCB={updateChangedFields}
                    tabIndex={props.panelIndex + '.0'}
                    nextTabIndex={props.panelIndex + '.1'}
                    indexToUpdate={props.panel.index}
                  />
                </IonCol>
                <IonCol size="1.56" class="column-left">
                  <LineItemField
                    datatestid="QAAddPieceModalPanelAssemblyGroup"
                    displayedValue={props.panel.assemblyGroup}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="1.56" class="column-left">
                  <LineItemField
                    datatestid="QAAddPieceModalPanelAllocation"
                    displayedValue={props.panel.allocation}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="1.73" class="column-left">
                  <LineItemField
                    datatestid="QAAddPieceModalPanelLocation"
                    displayedValue={props.panel.location}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol class="column-left">
                  <LineItemField
                    datatestid="QAAddPieceModalPanelPanelNumber"
                    displayedValue={props.panel.panelNumber}
                    readOnly={true}
                  />
                  <div className='close-line-container' onClick={() => {removePanel(props.panel.index);}}>
                    <IonIcon class="close-line-icon" src={closeCircle}></IonIcon>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </div>
    );
  };

  const PanelList = () => {
    return (panels.length > 0 ?
      <>
        {panels?.map((panel, index) => {
          return <div key={index}><PanelTable panel={panel} panelIndex={index}/></div>;
      })}
      </> :
      <div className="empty-row">
        <EmptyTableRow text={'No panels added.'}/>
      </div>
    );
  };

  const BottomPanelSelections = () => {
    const [metalShipDate, setMetalShipDate] = React.useState(getShipDate());
    const [daysForTransport, setDaysForTransport] = React.useState(getDaysForTransport());

    React.useEffect(() => {
      if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil) {
        if(!props.seamWeldPieceNumber) {
          detailFieldsToUpdate.current.shipDate = metalShipDate;
        } else {
          seamWeldFieldsToUpdate.current.shipDate = metalShipDate;
        }
      } else {
        anvilFieldsToUpdate.current.shipDate = metalShipDate;
      }
    }, [metalShipDate]);

    React.useEffect(() => {
      if(props.mstoData.activeTab !== MSTOSelectionTabs.Anvil) {
        if(!props.seamWeldPieceNumber) {
          detailFieldsToUpdate.current.daysForTransport = daysForTransport;
        } else {
          seamWeldFieldsToUpdate.current.daysForTransport = daysForTransport;
        }
      } else {
        anvilFieldsToUpdate.current.daysForTransport = daysForTransport;
      }
    }, [daysForTransport]);

    return (
      <div>
        <div className="panel-section">
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="flex-row bottom">
                  <SelectionContainer
                    type={SelectionTypes.Date}
                    min={new Date().toISOString().slice(0, 10)}
                    containerStyle={{ width: '193px', height: '26px', marginRight: '20px' }}
                    selectionLabel="Metal Ship Date"
                    onBlur={(metalShipDate) => setMetalShipDate(metalShipDate)}
                    value={metalShipDate}
                  />
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    inputType="number"
                    selectionLabel="Days For Transport To NC"
                    placeholder="0"
                    regex={Regexes.IntegerAboveZero}
                    min="1"
                    style={{ width: '193px' }}
                    onBlur={(daysForTransport) =>
                      (setDaysForTransport(daysForTransport))
                    }
                    value={daysForTransport}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    );
  };

  const PanelsAllocated = () => {
    const tableHeadersPanelsAllocatedTable = [
      { display: AddPieceColumnHeadings.Width, colSize: '1.2705' },
      { display: AddPieceColumnHeadings.Length, colSize: '1.2705' },
      { display: AddPieceColumnHeadings.CladTK, colSize: '1.56' },
      { display: AddPieceColumnHeadings.AssemblyGroup, colSize: '1.56' },
      { display: AddPieceColumnHeadings.Allocation, colSize: '1.56' },
      { display: AddPieceColumnHeadings.Location, colSize: '1.73' },
      { display: AddPieceColumnHeadings.PanelNumber }
    ];

    return (
      <div>
        <IonLabel class="add-piece-section-title">
          Panels Allocated From The Metal Size To Order Record
        </IonLabel>
        <hr></hr>
        <div>
          <TableHeaders labels={tableHeadersPanelsAllocatedTable}/>
          <div>
            <PanelList />
          </div>
          <BottomPanelSelections/>
        </div>
      </div>
    );
  };

  return (
    <div className="popover add-piece">
      <IonImg
        src={!darkMode ? closeIcon : closeIconWhite}
        class="close-icon"
        onClick={() => dismissModal()}
      ></IonImg>
      <div className="title-container">
        <IonLabel
          class="add-piece-title"
          data-testid={`QAAddPiecePopover${!props.editing ? 'Add' : 'Edit'}PieceHeader`}
        >
          {`${!props.editing ? 'Add' : 'Edit'} Piece`}
        </IonLabel>
      </div>
      <hr />
      <div className="add-piece-body">
      <IonGrid>
        <IonRow>
          <IonCol>
           <SupplierSelections/>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
           <DimensionsAndPricing/>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
           <PanelsAllocated/>
          </IonCol>
        </IonRow>
      </IonGrid>
      </div>
      <hr className="bottom-rule" />
      <div className="cta-button-container">
        <IonButton
          color="primary"
          class="save-button"
          data-testid="QAAddPiecePopoverSaveButton"
          onClick={() => {
            try {
              if(panels.length) {
                addFieldsToUpdateMaps();

                const updatedDetailFieldsToUpdate = { ...props.mstoData.quoteLineItemDetailFieldsToUpdate };

                let panelsMap: Map<string, {
                  quoteLineItemData: QuoteLineItem;
                  quoteLineItemDetailData: QuoteLineItemDetail;
                  panelNumber?: number;
                  panelLocation?: PanelLocations;
                  seamWeldPieceNumber?: number;
                  lengthWidthTKDisplayFields?: Record<string, number>;
                }>;

                let assemblyDisplayMap: Map<string, AssemblyDisplayMapValueType>;

                switch(props.mstoData.activeTab) {
                  case MSTOSelectionTabs.Cladder:
                    panelsMap = props.mstoData.cladderPanelsMap;
                    assemblyDisplayMap = props.mstoData.cladderAssemblyDisplayMap;
                    break;
                  case MSTOSelectionTabs.Backer:
                    panelsMap = props.mstoData.backerPanelsMap;
                    assemblyDisplayMap = props.mstoData.backerAssemblyDisplayMap;
                    break;
                  case MSTOSelectionTabs.Anvil:
                    panelsMap = props.mstoData.anvilPanelsMap;
                    assemblyDisplayMap = props.mstoData.anvilAssemblyDisplayMap;
                    break;
                }

                const updatedPanelsMap = new Map(panelsMap);
                const updatedAssemblyDisplayMap = new Map(assemblyDisplayMap);

                let seamWeldRequired = seamWeld;
                const newMetalPanels = new Map();

                props.quoteLineItemsToEdit.forEach(quoteLineItem => {
                  const quoteLineItemDetail = quoteLineItem.quoteLineItemDetail;

                  const mstoPanel = panelsMap?.get(
                    `${quoteLineItem?.Line__c} - ${quoteLineItem?.piece}`
                  );

                  const quoteLineItemToUse = mstoPanel?.quoteLineItemData || quoteLineItem;
                  const quoteLineItemDetailToUse = mstoPanel?.quoteLineItemDetailData || quoteLineItemDetail;

                  const updatedQuoteLineItem = { ...quoteLineItemToUse };
                  const updatedQuoteLineItemDetail = { ...quoteLineItemDetailToUse };
                  const updatedAnvilItemDetail = { ...quoteLineItemDetailToUse.NC_Anvil_Item_Details__r };
                  const updatedSeamWeldItemDetail = { ...quoteLineItemDetailToUse.NC_Seam_Weld_Item_Details__r };
                  const updatedLengthWidthTKDisplays = {};
                  const metalId = quoteLineItemToUse.UUID__c || quoteLineItemToUse.Id || quoteLineItemToUse.displayId;
                  const metalDetailId = updatedQuoteLineItemDetail.UUID__c || updatedQuoteLineItemDetail.Id || updatedQuoteLineItemDetail.associatedLineItem;

                  Array.from(lineItemFieldsMap.keys()).forEach(field => {
                    updatedQuoteLineItem[field] = lineItemFieldsMap.get(field);
                  });

                  Array.from(lineItemDetailFieldsMap.keys()).forEach(field => {
                    updatedQuoteLineItemDetail[field] = lineItemDetailFieldsMap.get(field);
                  });

                  Array.from(anvilItemDetailFieldsMap.keys()).forEach(field => {
                    updatedAnvilItemDetail[field] = anvilItemDetailFieldsMap.get(field);
                  });

                  Array.from(seamWeldItemDetailFieldsMap.keys()).forEach(field => {
                    updatedSeamWeldItemDetail[field] = seamWeldItemDetailFieldsMap.get(field);
                  });

                  Array.from(lengthWidthTKDisplayFieldsMap.keys()).forEach(field => {
                    updatedLengthWidthTKDisplays[field] = lengthWidthTKDisplayFieldsMap.get(field);
                  });

                  let metalArea: number;
                  let panelFields: {
                    lineItemPanelFields: QuoteLineItem,
                    lineItemDetailPanelFields: QuoteLineItemDetail,
                    seamWeldItemDetailPanelFields?: SeamWeldItemDetail,
                    anvilItemDetailPanelFields?: AnvilItemDetail
                  };
                  let metalType: MetalTypesForAreaCalc;

                  switch(props.mstoData.activeTab) {
                    case MSTOSelectionTabs.Cladder:
                      metalArea = isDisplayMetric ? updatedQuoteLineItemDetail.AreaCladder_mm__c : updatedQuoteLineItemDetail.AreaCladder_in__c;
                      panelFields = filterMSTOPanelFields(MSTOSelectionTabs.Cladder, updatedQuoteLineItem, updatedQuoteLineItemDetail, updatedSeamWeldItemDetail);
                      metalType = MetalTypesForAreaCalc.Clad;
                      break;
                    case MSTOSelectionTabs.Backer:
                      metalArea = isDisplayMetric ? updatedQuoteLineItemDetail.AreaBacker_mm__c : updatedQuoteLineItemDetail.AreaBacker_in__c;
                      panelFields = filterMSTOPanelFields(MSTOSelectionTabs.Backer, updatedQuoteLineItem, updatedQuoteLineItemDetail);
                      metalType = MetalTypesForAreaCalc.Base;
                      break;
                    case MSTOSelectionTabs.Anvil:
                      metalArea = isDisplayMetric ? updatedQuoteLineItemDetail.Anvil_Area_mm__c : updatedQuoteLineItemDetail.Anvil_Area_in__c;
                      panelFields = filterMSTOPanelFields(MSTOSelectionTabs.Anvil, updatedQuoteLineItem, updatedQuoteLineItemDetail, undefined, updatedAnvilItemDetail);
                      metalType = MetalTypesForAreaCalc.Anvil;
                      break;
                  }

                  // on create panel
                  const updatedItem = getDataFromFeldsToUpdate();

                  if(!updatedQuoteLineItem.piece) {
                    const currentPanelKeysForAssembly = Array.from(panelsMap.keys()).filter(key => key.split(' - ')[0] === updatedQuoteLineItem.Line__c);

                    if((currentPanelKeysForAssembly.length > 0 || panelQuantity.current > 1) && props.mstoData.activeTab === MSTOSelectionTabs.Cladder) {
                      updatedQuoteLineItemDetail.SeamWeld__c = true;
                      seamWeldRequired = true;
                    }

                    for (let i = panelQuantity.current - 1; i >= 0; i--) {
                      const piece = Array.from(updatedPanelsMap.keys()).length + 1;

                      const weldingAdders = props.weldingDimensions.get(updatedQuoteLineItem.UUID__c) || props.weldingDimensions.get(updatedQuoteLineItem.Id) || props.weldingDimensions.get(updatedQuoteLineItem.displayId);
                      let purchaseWidth = panelFields.lineItemDetailPanelFields.PurchaseCladWidth__c;
                      let purchaseLength = panelFields.lineItemDetailPanelFields.PurchaseCladLength__c;

                      if (weldingAdders) {
                        if (weldingAdders.weldWidth) {
                          purchaseWidth += weldingAdders.weldWidth;
                        }
                        if (weldingAdders.weldLength) {
                          purchaseLength += weldingAdders.weldLength;
                        }
                      }

                      const newMetalPanelKey = `${updatedQuoteLineItem.Line__c} - ${piece}`;
                      const newMetalPanel = {
                        quoteLineItemData: {
                          ...panelFields.lineItemPanelFields,
                          piece
                        },
                        quoteLineItemDetailData: {
                          ...panelFields.lineItemDetailPanelFields,
                          NC_Anvil_Item_Details__r: {
                            ...panelFields.anvilItemDetailPanelFields
                          },
                          NC_Seam_Weld_Item_Details__r: {
                            ...panelFields.seamWeldItemDetailPanelFields
                          },
                          PurchaseCladWidth__c: purchaseWidth,
                          PurchaseCladLength__c: purchaseLength,
                        },
                        lengthWidthTKDisplayFields: {
                          ...updatedLengthWidthTKDisplays
                        }
                      };

                      updatedPanelsMap.set(newMetalPanelKey, newMetalPanel);
                      newMetalPanels.set(newMetalPanelKey, newMetalPanel);
                    }
                  } else {
                    const weldingAdders = props.weldingDimensions.get(panelFields.lineItemPanelFields.UUID__c) || props.weldingDimensions.get(panelFields.lineItemPanelFields.Id) || props.weldingDimensions.get(panelFields.lineItemPanelFields.displayId);
                    let purchaseWidth = panelFields.lineItemDetailPanelFields.PurchaseCladWidth__c;
                    let purchaseLength = panelFields.lineItemDetailPanelFields.PurchaseCladLength__c;

                    if (weldingAdders) {
                      if (weldingAdders.weldWidth) {
                        purchaseWidth += weldingAdders.weldWidth;
                      }
                      if (weldingAdders.weldLength) {
                        purchaseLength += weldingAdders.weldLength;
                      }
                    }

                    const newMetalPanelKey = `${updatedQuoteLineItem.Line__c} - ${updatedQuoteLineItem.piece}`;
                    const newMetalPanel = {
                      quoteLineItemData: {
                        ...panelFields.lineItemPanelFields,
                        ...updatedItem.quoteLineItem,
                      },
                      quoteLineItemDetailData: {
                        ...panelFields.lineItemDetailPanelFields,
                        ...updatedItem.quoteLineItemDetail,
                        NC_Anvil_Item_Details__r: {
                          ...panelFields.anvilItemDetailPanelFields
                        },
                        NC_Seam_Weld_Item_Details__r: {
                          ...panelFields.seamWeldItemDetailPanelFields,
                          ...updatedItem?.quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r
                        },
                        PurchaseCladWidth__c: purchaseWidth,
                        PurchaseCladLength__c: purchaseLength
                      },
                      panelNumber: props.panelNumber,
                      panelLocation: props.panelLocation,
                      seamWeldPieceNumber: props.seamWeldPieceNumber,
                      lengthWidthTKDisplayFields: {
                        ...updatedLengthWidthTKDisplays
                      }
                    };

                    updatedPanelsMap.set(newMetalPanelKey, newMetalPanel);
                    newMetalPanels.set(newMetalPanelKey, newMetalPanel);
                  }

                  const updatedPanelKeysForAssembly = Array.from(updatedPanelsMap.keys()).filter(key => key.split(' - ')[0] === updatedQuoteLineItem.Line__c);

                  let totalAreaOrderedForLine = 0;

                  updatedPanelKeysForAssembly.forEach(key => {
                    const lengthWidthTKDisplayFields = updatedPanelsMap.get(key).lengthWidthTKDisplayFields;
                    const pieceLength = lengthWidthTKDisplayFields.DisplayLength;
                    const pieceWidth = lengthWidthTKDisplayFields.DisplayWidth;

                    if(metalType === MetalTypesForAreaCalc.Base) {
                      const baseMetalName = updatedPanelsMap.get(key).quoteLineItemData.BaseMetal__c;
                      const baseMetal = rawMaterials.find(rawMaterial => rawMaterial.name === baseMetalName && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite));
                      totalAreaOrderedForLine += calculateRawArea(localUom, pieceLength, pieceWidth, metalType, baseMetal.dmcClass.includes('/F'));
                    } else {
                      totalAreaOrderedForLine += calculateRawArea(localUom, pieceLength, pieceWidth, metalType);
                    }
                  });

                  const percentAreaAllocated = 100 * (totalAreaOrderedForLine / (metalArea * updatedQuoteLineItemDetail.Clads__c));

                  const currentAssemblyDisplay = updatedAssemblyDisplayMap.get(metalId);

                  switch(props.mstoData.activeTab) {
                    case MSTOSelectionTabs.Cladder:
                      updatedAssemblyDisplayMap.set(metalId, { ...currentAssemblyDisplay, percent: percentAreaAllocated, weld: seamWeldRequired });
                      break;
                    case MSTOSelectionTabs.Backer:
                      updatedAssemblyDisplayMap.set(metalId, { ...currentAssemblyDisplay, percent: percentAreaAllocated, cutting: updatedQuoteLineItemDetail.TorchCutBacker__c});
                      break;
                    case MSTOSelectionTabs.Anvil:
                      updatedAssemblyDisplayMap.set(metalId, { ...currentAssemblyDisplay, percent: percentAreaAllocated });
                      // eslint-disable-next-line no-case-declarations
                      const updatedAnvilFieldsToUpdate = { ...props.mstoData.anvilItemDetailFieldsToUpdate };
                      updatedAnvilFieldsToUpdate[metalDetailId] = { ...props.mstoData.anvilItemDetailFieldsToUpdate[metalDetailId], ...anvilFieldsToUpdate.current };
                      props.updateAnvilItemDetailFieldsToUpdate(updatedAnvilFieldsToUpdate);
                      break;
                  }

                  updatedDetailFieldsToUpdate[metalDetailId] = { ...props.mstoData.quoteLineItemDetailFieldsToUpdate[metalDetailId], ...detailFieldsToUpdate.current };
                });

                props.updateQuoteLineItemDetailFieldsToUpdate(updatedDetailFieldsToUpdate);

                const TabToMetal = {
                  [MSTOSelectionTabs.Cladder]: MSTOAssemblyMetals.CLAD,
                  [MSTOSelectionTabs.Backer]: MSTOAssemblyMetals.BASE,
                  [MSTOSelectionTabs.Anvil]: MSTOAssemblyMetals.ANVIL
                };

                const currentMetal = TabToMetal[props.mstoData.activeTab];

                const newAssemblies: any = Array.from(props.mstoData.assemblies).map( ([key, values]) => {
                  const newValues = values.map((payload) => {
                    const {
                      metal,
                      quoteLineItemData: _quoteLineItemData,
                      quoteLineItemDetailData: _quoteLineItemDetailData
                    } = payload;
                    if (
                      metal !== currentMetal
                      || !quoteLineItemData?.Line__c
                      || !quoteLineItemData?.piece
                      || _quoteLineItemData.Line__c !== quoteLineItemData?.Line__c
                      || _quoteLineItemData.piece !== quoteLineItemData?.piece
                    ) {
                      return payload;
                    }

                    const pKey = `${_quoteLineItemData.Line__c} - ${_quoteLineItemData.piece}`;
                    const updatedPanel = updatedPanelsMap.get(pKey);

                    return {
                      ...payload,
                      quoteLineItemData: {
                        ..._quoteLineItemData,
                        ...updatedPanel.quoteLineItemData,
                      },
                      quoteLineItemDetailData: {
                        ...updatedPanel.quoteLineItemDetailData,
                        NC_Seam_Weld_Item_Details__r: {
                          // TODO: need to only get the necessary attributes
                          ...updatedPanel.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r
                        }
                      }
                    };
                  });

                  return [key, newValues];
                });
                const newAssembliesMap: Map<string, AssemblyPanels[]> = new Map(newAssemblies);

                props.updateSpecifiedMetalPanelsMap(updatedPanelsMap);
                props.updateAssembliesDisplayMap(updatedAssemblyDisplayMap);
                props.updateAssemblies(newAssembliesMap);

                dismissModal();
              } else {
                toast(ToastTypes.Error, ToastMessages.MSTOAddPiecePanels);
              }
            } catch (error) {
              toast(ToastTypes.Error, ToastMessages.SaveFailed);
              console.error(error);
            }
          }}
        >
          Save
        </IonButton>
        <IonButton
          color="secondary"
          class="cancel-button"
          onClick={() => dismissModal()}
        >
          Cancel
        </IonButton>
      </div>
    </div>
  );
};

export default AddPiecePopover;