import React from 'react';

import './panel-section.scss';

interface Prop {
  title: string;
  children?: JSX.Element | React.ReactNode
  className?: string;
}

const PanelSection = ({ title, children, className }: Prop) => {
  return <div className='panel-section'>
    <h3>{title}</h3>
    <div className={`panel-section--content ${className}`}>
      { children }
    </div>
  </div>
};

export default PanelSection;