import { getContent } from './content-api';
import * as React from 'react';

const { createContext, useContext } = React;

const ContentContext = createContext(null);

export const ContentProvider = (props) => {
  const value = {
    getTermsContent: props.getTermsContent || getTermsContent,
  };

  return (
    <ContentContext.Provider value={value}>
      {props.children}
    </ContentContext.Provider>
  );
};

export const useContent = () => {
  return useContext(ContentContext);
};

// Calls api to get terms content by slug from cosmos
export const getTermsContent = async(slug: string): Promise<string> => {
  try {
    return await getContent(slug);
  } catch (error) {
    console.error(error);
    return null;
  }
};
