import MSTOTab from '@assets/icon/MSTOAction.svg';
import MSTOTabDarkMode from '@assets/icon/MSTOAction_DarkMode.svg';
import MSTOTabHovered from '@assets/icon/MSTOAction_Hovered.svg';
import advSpecsTab from '@assets/icon/Tab_Adv_Specs.svg';
import advSpecsTabDarkMode from '@assets/icon/Tab_Adv_Specs_DarkMode.svg';
import advSpecsTabHovered from '@assets/icon/Tab_Adv_Specs_Hovered.svg';
import quoteTab from '@assets/icon/Tab_Quote.svg';
import quoteTabDarkMode from '@assets/icon/Tab_Quote_DarkMode.svg';
import quoteTabHovered from '@assets/icon/Tab_Quote_Hovered.svg';
import FloatingAction from '@components/floating-action/floating-action';
import LineItemField from '@components/line-item-field/line-item-field';
import Loading from '@components/loading/loading';
import MappedSection from '@components/mapped-section/mapped-section';
import PONumberPopover from '@components/popover/po-number-popover/po-number-popover';
import ProductTagPopover from '@components/popover/product-tag-popover/product-tag-popover';
import ShipToAddressPopover from '@components/popover/ship-to-address-popover/ship-to-address-popover';
import ShipViaPopover from '@components/popover/ship-via-popover/ship-via-popover';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';
import SelectionContainer from '@components/selection-container/selection-container';
import {
  CertificateSentByDefault,
  InspectionsAndWitnessingEvents,
  ManufacturingSites,
  MeasurementSystems,
  OrderEntryColumnHeadings,
  OrderEntryHeaderTabs,
  PageRoutes,
  ProcedureApprovalDateNeededFields,
  ProcedureApprovalDetailsFields,
  ProcedureApprovalOptionsRequiredFields,
  QuoteActions,
  QuoteLineItemFieldUnitTypes,
  QuoteLineItemValueTypes,
  QuoteLineItemsColumnHeadings,
  RadioButtonOptions,
  Regexes,
  SearchFields,
  SelectionTypes,
  ShipToAddressTypes,
  TextAreaMax,
  ToastMessages,
  ToastTypes,
  WitnessingFields,
  maxTextInputLength
} from '@constants';
import { useAdvSpecStateDispatch } from '@context/adv-spec-context';
import { useDataState } from '@context/data-context';
import { QuoteActionTypes, useQuoteDispatch, useQuoteState } from '@context/quote-context';
import { useDisplayUomWithInit } from '@context/useDisplayUomWithInit';
import { environment } from '@environment';
import { useToast } from '@hooks/useToast';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { IonButton, IonCard, IonCardHeader, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRow, useIonAlert } from '@ionic/react';
import { countryCodes, findCountryCode, findStates, usStates } from '@shared/data/countries';
import { buildLineItemFieldsToUpdate } from '@shared/quote-group-utils';
import { buildOrderEntryQuoteFieldsToUpdate, getSelectionOptions, getSelectionOptionsWithNameAndValue, getSelections, runGuardRail } from '@shared/quote-utils';
import { OrderEntryLineItemFieldsToUpdate, OrderEntryQuoteFieldsToUpdate } from '@shared/types';
import { navigateToRoute } from '@shared/utils';
import classNames from 'classnames';
import { chevronDown, chevronUp } from 'ionicons/icons';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './order-entry.scss';

interface ExceptionRowProps {
    line: string;
    rule: string;
}

const OrderEntryPage = (props) => {
  const {
    dataState,
    // Can get rid of most of these once the other sections are pages
    loadRawMaterials,
    loadFinishedGoods,
    loadFreight,
    loadContent,
    loadEdgeAllowances,
    loadSpecificationTestOptions,
    loadBoosterAllowances,
    loadSelections,
    loadQuoteVariableConfigurations,
    loadVendors,
    loadWeldingPatterns
  } = useDataState();

const {
    rawMaterials,
    specificationTestOptions,
    selections,
    quoteVariableConfigurations,
  } = dataState;

  const { dispatchQuoteState } = useQuoteDispatch();
  const {
    quoteState,
    loadQuote,
    loadQuoteLineItemDetails,
    saveQuoteLineItem,
    saveQuote
   } = useQuoteState();
   const { dispatchAdvSpecState } = useAdvSpecStateDispatch();

  const { quote, quoteLineItemDetails } = quoteState;

  const { displayUom } = useDisplayUomWithInit();

  const { quoteLineItems, Manufacturing_Site__c: manufacturingSite } = quote || {};
  const history = useHistory();
  const { id } = useParams<{id: string}>();
  const [confirmAlert] = useIonAlert();

  const jobSpecificationsRef = React.useRef<HTMLDivElement>(null);
  const procedureApprovalsRef = React.useRef<HTMLDivElement>(null);
  const inspectionsAndWitnessingRef = React.useRef<HTMLDivElement>(null);
  const orderNotesRef = React.useRef<HTMLDivElement>(null);
  const shippingRef = React.useRef<HTMLDivElement>(null);
  const exceptionsRef = React.useRef<HTMLDivElement>(null);

  const [quoteFieldsToUpdate, setQuoteFieldsToUpdate] = React.useState<OrderEntryQuoteFieldsToUpdate>(buildOrderEntryQuoteFieldsToUpdate(quote, quoteLineItems, displayUom) as OrderEntryQuoteFieldsToUpdate);
  const optionsRequired = {
    heatProcedure: quoteFieldsToUpdate.heatProcedureDateNeeded || quoteFieldsToUpdate.heatProcedureDetails,
    heatTreatmentProcedure: quoteFieldsToUpdate.heatTreatmentProcedureDateNeeded || quoteFieldsToUpdate.heatTreatmentProcedureDetails,
    inspectionsAndTestPlanProcedure: quoteFieldsToUpdate.inspectionAndTestPlanProcedureDateNeeded || quoteFieldsToUpdate.inspectionAndTestPlanProcedureDetails,
    nonDestructiveEvaluationTestingProcedure: quoteFieldsToUpdate.nonDestructiveEvaluationTestingProcedureDateNeeded || quoteFieldsToUpdate.nonDestructiveEvaluationTestingProcedureDetails,
    otherProcedure: quoteFieldsToUpdate.otherProcedureDateNeeded || quoteFieldsToUpdate.otherProcedureDetails,
    weldingProcedure: quoteFieldsToUpdate.weldingProcedureDateNeeded || quoteFieldsToUpdate.weldingProcedureDetails,
  };

  const toast = useToast(4000);

  const [isLoading, setIsLoading] = React.useState(undefined);
  const [linesLoading, setLinesLoading] = React.useState(undefined);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [quoteLineItemFieldsToUpdate, setQuoteLineItemFieldsToUpdate] = React.useState<{ [key: string]: OrderEntryLineItemFieldsToUpdate; }>(undefined);
  const [mapsLoaded, setMapsLoaded] = React.useState(false);
  const [isQuoteSaving, setIsQuoteSaving] = React.useState(false);

  const [generalCommentsForJobPlanningMaxSurpassed, setGeneralCommentsForJobPlanningMaxSurpassed] = React.useState(false);
  const [additionalPurchaseOrderNotesForBaseMetalSelectionsMaxSurpassed, setAdditionalPurchaseOrderNotesForBaseMetalSelectionsMaxSurpassed] = React.useState(false);
  const [additionalPurchaseOrderNotesForCladMetalSelectionsMaxSurpassed, setAdditionalPurchaseOrderNotesForCladMetalSelectionsMaxSurpassed] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);

    if(!quote) {
      loadQuote(
        id,
        props?.history?.location?.state?.quote,
        true,
        undefined,
        setLinesLoading
      );
    }
    loadRawMaterials();
    loadFinishedGoods();
    loadFreight();
    loadContent();
    loadEdgeAllowances();
    loadSpecificationTestOptions();
    loadBoosterAllowances();
    loadSelections();
    loadQuoteVariableConfigurations();
    loadVendors();
    loadWeldingPatterns();
  }, []);

  React.useEffect(() => {
    if(rawMaterials &&
       selections &&
       specificationTestOptions &&
       quoteVariableConfigurations
    ) {
      setDataLoaded(true);
    }
  }, [dataState]);

  React.useEffect(() => {
    try {
      setQuoteFieldsToUpdate(buildOrderEntryQuoteFieldsToUpdate(quote, quoteLineItems, displayUom) as OrderEntryQuoteFieldsToUpdate);
    } catch (error) {
      console.log(error);
      return;
    }
  }, [quote, quoteLineItems]);

  React.useEffect(() => {
    if(quoteLineItems && linesLoading) {
      try {
        loadQuoteLineItemDetails(setIsQuoteSaving);
        setLinesLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  }, [quoteLineItems]);

  React.useEffect(() => {
    if(quoteLineItemDetails && !isQuoteSaving) {
      setIsLoading(false);
    }
  }, [quoteLineItemDetails, isQuoteSaving]);

  React.useEffect(() => {
    try {
      if(quoteLineItemDetails) {
        setQuoteLineItemFieldsToUpdate(buildLineItemFieldsToUpdate(quoteLineItems));
      }
    } catch (error) {
      console.log(error);
      return;
    }
  }, [quoteLineItemDetails]);

  React.useEffect(() => {
    if(quoteLineItemFieldsToUpdate) {
        setMapsLoaded(true);
      }
  }, [quoteLineItemFieldsToUpdate]);

  React.useEffect(() => {
    if(generalCommentsForJobPlanningMaxSurpassed || additionalPurchaseOrderNotesForBaseMetalSelectionsMaxSurpassed || additionalPurchaseOrderNotesForCladMetalSelectionsMaxSurpassed) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [generalCommentsForJobPlanningMaxSurpassed, additionalPurchaseOrderNotesForBaseMetalSelectionsMaxSurpassed, additionalPurchaseOrderNotesForCladMetalSelectionsMaxSurpassed]);

  // Returns true once all the necessary data is retrieved and the quote is finished loading
  const loadingComplete = () => {
    return dataLoaded &&
    mapsLoaded &&
    !isLoading;
  };

  // Saves the fields to the quote
  const saveOrderEntry = async (saveQuoteOnline) => {

    const updatedQuoteLineItems = quoteLineItems.map(qli=> {
      const id = qli.Id || qli.displayId;
      const data = {
        ...qli,
        CustomerPO__c: quoteLineItemFieldsToUpdate[id].poNumber || undefined,
        ShipVia__c: quoteLineItemFieldsToUpdate[id].shipVia || undefined,
        ShipViaDetails__c: quoteLineItemFieldsToUpdate[id].shipViaDetails || undefined,
        ProductTagging__c: quoteLineItemFieldsToUpdate[id].productTag || undefined,
        ShipDate__c: quoteLineItemFieldsToUpdate[id].shipDate || undefined,
        Shipping_Street__c: quoteFieldsToUpdate.doAllItemsShipToSameAddress ? quoteFieldsToUpdate.shippingStreet : quoteLineItemFieldsToUpdate[id].streetAddress || undefined,
        Shipping_City__c: quoteFieldsToUpdate.doAllItemsShipToSameAddress ? quoteFieldsToUpdate.shippingCity : quoteLineItemFieldsToUpdate[id].city || undefined,
        Shipping_State__c: quoteFieldsToUpdate.doAllItemsShipToSameAddress ? quoteFieldsToUpdate.shippingState : quoteLineItemFieldsToUpdate[id].state || undefined,
        Shipping_Postal_Code__c: quoteFieldsToUpdate.doAllItemsShipToSameAddress ? quoteFieldsToUpdate.shippingPostalCode : quoteLineItemFieldsToUpdate[id].postalCode || undefined,
        Shipping_Country__c: quoteFieldsToUpdate.doAllItemsShipToSameAddress ? quoteFieldsToUpdate.shippingCountry : quoteLineItemFieldsToUpdate[id].country || undefined,
        quoteLineItemDetail: qli.quoteLineItemDetail
      };
      return data;
    });

    const newQuoteLineItemDetails = updatedQuoteLineItems.map((nqlid) => {
      return {
        ...nqlid.quoteLineItemDetail,
      };
    });

    const updatedQuote = {
      ...quote,
      PaymentTermsDescription__c: quoteFieldsToUpdate.paymentTermsNotes,
      AdditionalPONotesForBaseMetalSelections__c: quoteFieldsToUpdate.additionalPurchaseOrderNotesForBaseMetalSelections,
      AdditionalPONotesForCladMetalSelections__c: quoteFieldsToUpdate.additionalPurchaseOrderNotesForCladMetalSelections,
      AllFinishedGoodsOnSamePO__c: quoteFieldsToUpdate.allFinishedGoodsOnSamePO,
      BaseCertificateApprovalatVendor__c: quoteFieldsToUpdate.baseCertificateApprovalatVendor,
      BaseRetestAtDMCBeforeCladding__c: quoteFieldsToUpdate.baseRetestAtDMCBeforeCladding,
      BaseTestCouponCutWitnessatVendor__c: quoteFieldsToUpdate.baseTestCouponCutWitnessAtVendor,
      BaseTestWitnessatVendor__c: quoteFieldsToUpdate.baseTestWitnessatVendor,
      CertificatesToBeSentBy__c: quoteFieldsToUpdate.certificatesToBeSentBy,
      CladCertificateApprovalatVendor__c: quoteFieldsToUpdate.cladCertificateApprovalatVendor,
      CladDestructiveTesting__c: quoteFieldsToUpdate.cladDestructiveTesting,
      CladdingMetalRetestatDMCbeforeCladding__c: quoteFieldsToUpdate.claddingMetalRetestatDMCbeforeCladding,
      CladdingMetalTestWitnessatVendor__c: quoteFieldsToUpdate.claddingMetalTestWitnessatVendor,
      CladNDTAndFinalInspection__c: quoteFieldsToUpdate.cladNDTandFinalInspection,
      CladTestCouponCutWitnessatVendor__c: quoteFieldsToUpdate.cladTestCouponCutWitnessatVendor,
      CladTestSpecimenRemovalAndStamping__c: quoteFieldsToUpdate.cladTestSpecimenRemovalAndStamping,
      CommissionPayTo__c: quoteFieldsToUpdate.commissionPaidTo,
      CustomerContactforJob__c: quoteFieldsToUpdate.customerContactsForJob,
      CustomerPONumber__c: quoteFieldsToUpdate.customerPONumber,
      DocumentUOM__c: quoteFieldsToUpdate.customerDocumentUOM,
      Doallitemsshipthesameway__c: quoteFieldsToUpdate.doAllItemsShipTheSameWay,
      Doallitemsshiptosameaddress__c: quoteFieldsToUpdate.doAllItemsShipToSameAddress,
      DocumentReviewAndApprovalAtDMC__c: quoteFieldsToUpdate.documentReviewApprovalAtDMC,
      GeneralCommentsForJobPlanning__c: quoteFieldsToUpdate.generalCommentsForJobPlanning,
      HeadFormingEvent__c: quoteFieldsToUpdate.headFormingEvent,
      HeadInspectionAtFormer__c: quoteFieldsToUpdate.headInspectionAtFormer,
      HeatProcedureDate__c: quoteFieldsToUpdate.heatProcedureDateNeeded,
      HeatProcedureNotes__c: quoteFieldsToUpdate.heatProcedureDetails,
      HeatTreatmentProcedureDate__c: quoteFieldsToUpdate.heatTreatmentProcedureDateNeeded,
      HeatTreatmentProcedureNotes__c: quoteFieldsToUpdate.heatTreatmentProcedureDetails,
      Incoterms__c: quoteFieldsToUpdate.incoterms,
      IncotermLocation__c: quoteFieldsToUpdate.incotermLocation,
      InspectionAndTestPlanProcedureDate__c: quoteFieldsToUpdate.inspectionAndTestPlanProcedureDateNeeded,
      InspectionAndTestPlanProcedureNotes__c: quoteFieldsToUpdate.inspectionAndTestPlanProcedureDetails,
      InspectionCompany__c: quoteFieldsToUpdate.inspectionCompany,
      InspectionCompanyOption__c: quoteFieldsToUpdate.inspectionCompanyOption,
      NonDestructiveTestingProcedureDate__c: quoteFieldsToUpdate.nonDestructiveEvaluationTestingProcedureDateNeeded,
      NonDestructiveTestingProcedureNotes__c: quoteFieldsToUpdate.nonDestructiveEvaluationTestingProcedureDetails,
      OkToShipEarly__c: quoteFieldsToUpdate.okToShipEarly,
      OrderBookedDate__c: quoteFieldsToUpdate.orderBookedDate,
      OtherProcedureDate__c: quoteFieldsToUpdate.otherProcedureDateNeeded,
      OtherProcedureNotes__c: quoteFieldsToUpdate.otherProcedureDetails,
      PartialShipmentsAllowed__c: quoteFieldsToUpdate.partialShipmentsAllowed,
      PaymentTerms__c: quoteFieldsToUpdate.paymentTerms,
      PEDRequalificationOfBaseMetal__c: quoteFieldsToUpdate.pedRequalificationOfBaseMetal,
      ProcedureApprovalsSendTo__c: quoteFieldsToUpdate.procedureApprovalsSendTo,
      SendCertificatesTo__c: quoteFieldsToUpdate.sendCertificatesTo,
      ShipVia__c: quoteFieldsToUpdate.shipVia,
      Shipping_Street__c : quoteFieldsToUpdate.shippingStreet,
      Shipping_City__c : quoteFieldsToUpdate.shippingCity,
      Shipping_State__c : quoteFieldsToUpdate.shippingState,
      Shipping_Postal_Code__c : quoteFieldsToUpdate.shippingPostalCode,
      Shipping_Country__c : quoteFieldsToUpdate.shippingCountry,
      WeldingProcedureDate__c: quoteFieldsToUpdate.weldingProcedureDateNeeded,
      WeldingProcedureNotes__c: quoteFieldsToUpdate.weldingProcedureDetails,
      quoteLineItems: updatedQuoteLineItems
    };

    dispatchQuoteState({ type: QuoteActionTypes.loadQuote, payload: updatedQuote });
    dispatchQuoteState({ type: QuoteActionTypes.loadQuoteLineItems, payload: updatedQuoteLineItems });
    dispatchQuoteState({ type: QuoteActionTypes.loadQuoteLineItemDetails, payload: newQuoteLineItemDetails });

    if(saveQuoteOnline) {
      setIsQuoteSaving(true);
      saveQuote(id, QuoteActions.Save, props?.history?.location?.state?.quote, setIsLoading, undefined, undefined, setLinesLoading, undefined, setIsQuoteSaving, undefined, updatedQuote).then((query) => {
         setIsQuoteSaving(false);
      });
    }
  };

  const routeChangeAlert = (url: string) => {
    confirmAlert({
      cssClass: 'confirm-alert',
      header: 'Are you sure you want to leave the page? Changes you made may not be saved.',
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: () => history.push(url),
        },
      ],
    });
  };

  const CheckboxItem = props => {
    return (
      <IonItem class='checkbox-item' lines='none' style={props.style}>
        <IonCheckbox
          class='checkbox'
          style={props.checkboxStyle}
          slot='start'
          color='primary'
          checked={props.checked}
          onIonChange={e => props.onIonChange(e.detail.checked)}
          data-testid={'QAOrderEntryCheckbox_' + props.label}
        />
        <IonLabel class='checkbox-label'>{props.label}</IonLabel>
      </IonItem>
    );
  };
 
  const SpecifyButton = (props: {
    isDisabled?: boolean,
    onClick?: React.MouseEventHandler<HTMLIonButtonElement>
  }) => (
    <>
      <IonButton class='specify-button' disabled={props.isDisabled} onClick={props.onClick} data-testid='QAOrderEntrySpecifyButton'>
        Specify
      </IonButton>
    </>
  );

  const SaveCancelButtons = () => (
    <>
      <IonButton
        color='primary'
        class='submit-order-button'
        data-testid={'QAOrderEntrySaveButton'}
        onClick={async () => {
          try {
            if (hasError) {
              toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
            } else {
              saveOrderEntry(true);
            }
          } catch (error) {
            console.log(error);
            return;
          }
        }}
      >
        Save
      </IonButton>
    </>
  );

  const sectionRefMap: Map<OrderEntryHeaderTabs, React.MutableRefObject<HTMLDivElement>> = new Map([
    [OrderEntryHeaderTabs.JobSpecifications, jobSpecificationsRef],
    [OrderEntryHeaderTabs.ProcedureApprovals, procedureApprovalsRef],
    [OrderEntryHeaderTabs.InspectionsAndWitnessing, inspectionsAndWitnessingRef],
    [OrderEntryHeaderTabs.OrderNotes, orderNotesRef],
    [OrderEntryHeaderTabs.Shipping, shippingRef],
    [OrderEntryHeaderTabs.Exceptions, exceptionsRef],
  ]);

  const CustomerPONumberSection = () => {
    const [areAllFinishedGoodsOnSamePO, setAreAllFinishedGoodsOnSamePO] = React.useState(quoteFieldsToUpdate.allFinishedGoodsOnSamePO === RadioButtonOptions.Yes ? RadioButtonOptions.Yes : RadioButtonOptions.No);
    const [poNumber, setPONumber] = React.useState(quoteFieldsToUpdate.customerPONumber);
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

    const updateQuoteLineItems = () => {
      quoteLineItems.forEach(quoteLineItem => {
        const id = quoteLineItem.Id || quoteLineItem.displayId;
        const po = areAllFinishedGoodsOnSamePO === RadioButtonOptions.Yes ? null : quoteLineItem?.CustomerPO__c || null;
        
        if (quoteLineItemFieldsToUpdate && quoteLineItemFieldsToUpdate[id]) {
          quoteLineItemFieldsToUpdate[id].poNumber = po;
        }
      });

      setPONumber(areAllFinishedGoodsOnSamePO === RadioButtonOptions.Yes ? quoteFieldsToUpdate.customerPONumber : null);
    };

    React.useEffect(() => {
      try {
        updateQuoteLineItems();
        quoteFieldsToUpdate.allFinishedGoodsOnSamePO = areAllFinishedGoodsOnSamePO;
      } catch (error) {
        console.error(error);
      }
    }, [areAllFinishedGoodsOnSamePO]);

    React.useEffect(() => {
      quoteFieldsToUpdate.customerPONumber = poNumber;

      quoteLineItems?.forEach((quoteLineItem) => {
        const id = quoteLineItem.Id || quoteLineItem.displayId;
        if (quoteLineItemFieldsToUpdate && quoteLineItemFieldsToUpdate[id]) {
          quoteLineItemFieldsToUpdate[id].poNumber = poNumber;
        }
      });
    }, [poNumber]);

    return (
      <div>
      <IonPopover
          cssClass='order-entry-po-number-popover'
          isOpen={popoverState.showPopover}
          animated={ false }
          onDidDismiss={async () => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
      >
          <PONumberPopover
            setShowPopover={setShowPopover}
            quoteLineItemFieldsToUpdate={quoteLineItemFieldsToUpdate}
            setQuoteLineItemFieldsToUpdate={setQuoteLineItemFieldsToUpdate}
          />
      </IonPopover>
      <IonRow>
        <IonCol size='1.5'>
          <IonLabel class='selection-label top'>
            Customer PO Number:
          </IonLabel>
        </IonCol>
        <IonCol class='flex-column'>
          <IonLabel class='selection-question'>
            Are all finished goods on the same PO?
          </IonLabel>
          <div className='flex-container radio-extra-components'>
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              itemStyle={{ '--inner-padding-end': '7px' }}
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.AreAllFinishedGoodsOnSamePO).options}
              onIonChange={areAllFinishedGoodsOnSamePO => {
                if(areAllFinishedGoodsOnSamePO === RadioButtonOptions.Yes || areAllFinishedGoodsOnSamePO === RadioButtonOptions.No) {
                  setAreAllFinishedGoodsOnSamePO(areAllFinishedGoodsOnSamePO);
                }
              }}
              value={areAllFinishedGoodsOnSamePO}
              extraComponents={[
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  placeholder='Enter PO Number'
                  inputType='text'
                  style={{ width: '172px', marginRight: '15px' }}
                  containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                  isDisabled={areAllFinishedGoodsOnSamePO !== RadioButtonOptions.Yes}
                  onBlur={poNumber =>
                    setPONumber(poNumber)
                  }
                  value={poNumber}
                />,
                <SpecifyButton isDisabled={areAllFinishedGoodsOnSamePO !== RadioButtonOptions.No} onClick={() => setShowPopover({ showPopover: true, event: undefined })}/>
              ]}
            />
          </div>
        </IonCol>
      </IonRow>
      </div>
    );
  };

  const CustomerContactForJobRow = () => {
    const [customerContactsForJob, setCustomerContactsForJob] = React.useState((quoteFieldsToUpdate.customerContactsForJob as string)?.split(';') || []);

    React.useEffect(() => {
      quoteFieldsToUpdate.customerContactsForJob = customerContactsForJob.join(';');
    }, [customerContactsForJob]);

    return (
      <IonRow>
        <IonCol size='1.5'>
          <IonLabel class='selection-label top'>
            Customer Contact for Job:
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextList}
            style={{ width: '775px', height: '86px' }}
            placeholder='Specify Customer Contacts For Job...'
            onChange={customerContactsForJob =>
              setCustomerContactsForJob(customerContactsForJob)
            }
            value={customerContactsForJob}
            textListType='Customer Contacts For Job'
          />
        </IonCol>
      </IonRow>
    );
  };

  const SendCertificatesToRow = () => {
    const [sendCertificatesTo, setSendCertificatesTo] = React.useState((quoteFieldsToUpdate.sendCertificatesTo as string)?.split(';') || []);

    React.useEffect(() => {
      quoteFieldsToUpdate.sendCertificatesTo = sendCertificatesTo.join(';');
    }, [sendCertificatesTo]);

    return (
      <IonRow>
        <IonCol size='1.5'>
          <IonLabel class='selection-label top'>
            Send Certificates To:
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextList}
            style={{ width: '775px', height: '86px' }}
            placeholder='Specify Recipients...'
            onChange={sendCertificatesTo =>
              setSendCertificatesTo(sendCertificatesTo)
            }
            regex={Regexes.Email}
            value={sendCertificatesTo}
            textListType='Send Certificates To'
          />
        </IonCol>
      </IonRow>
    );
  };

  const PaymentTermsRow = () => {
    const [paymentTerms, setPaymentTerms] = React.useState(quoteFieldsToUpdate.paymentTerms);
    const [paymentTermsNotes, setPaymentTermsNotes] = React.useState(quoteFieldsToUpdate.paymentTermsNotes);

    const paymentTermsSelections = getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.PaymentTerms).options;

    React.useEffect(() => {
      try {
        if(paymentTerms && paymentTerms !== quoteFieldsToUpdate.paymentTerms) {
          setPaymentTermsNotes(paymentTermsSelections.find(selection => selection.name === paymentTerms).description);
        }

        quoteFieldsToUpdate.paymentTerms = paymentTerms;
      } catch (error) {
        console.log(error);
        return;
      }
    }, [paymentTerms]);

    React.useEffect(() => {
      quoteFieldsToUpdate.paymentTermsNotes = paymentTermsNotes;
    }, [paymentTermsNotes]);

    return (
      <IonRow>
        <IonCol size='1.5'>
          <IonLabel class='selection-label'>
            Payment Terms
          </IonLabel>
        </IonCol>
        <IonCol class='flex-column'>
          <div className='flex-container'>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select Terms'
              options={paymentTermsSelections.map(option => option.name)}
              style={{ width: '220px', marginRight: '10px', marginTop: '0px', menuListHeight: '125px' }}
              onChange={paymentTerms => setPaymentTerms(paymentTerms.label)}
              defaultValue={paymentTerms}
            />
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='text'
              style={{ width: '193px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={paymentTermsNotes =>
                setPaymentTermsNotes(paymentTermsNotes)
              }
              value={paymentTermsNotes}
            />
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CertificatesToBeSentRow = () => {
    
    const [certificate, setCertificate] = React.useState(quoteFieldsToUpdate.certificatesToBeSentBy === '' ? CertificateSentByDefault.EmailPDF : quoteFieldsToUpdate.certificatesToBeSentBy);
    
    const certificateSelections = getSelectionOptions(
      selections,
      manufacturingSite,
      QuoteLineItemValueTypes.CertificatesToBeSentBy
    ).options;

    const inSelection: boolean = certificateSelections.filter(cert => cert !== 'Other').includes(certificate);
      
    const [otherCertificate, setOtherCertificate] = React.useState(!inSelection);
    
    React.useEffect(() => {
      try {
        if (certificate === 'Other' || certificate === '' || !inSelection) {
          setOtherCertificate(true);
        } else {
          setOtherCertificate(false);
          quoteFieldsToUpdate.certificatesToBeSentBy = certificate;
        }
      } catch (error) {
        console.log(error);
        return;
      }
    }, [certificate]);

    const getDefaultValue = () => {
      if(quoteFieldsToUpdate.certificatesToBeSentBy) {
        return otherCertificate ? 'Other' : quoteFieldsToUpdate.certificatesToBeSentBy;
      }
      
      return CertificateSentByDefault.EmailPDF;
    };

    return (
      <IonRow>
        <IonCol size="1.5">
          <IonLabel className="selection-label">Certificates to be Sent By:</IonLabel>
        </IonCol>
        <IonCol className="flex-column">
          <div className="flex-container">
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder="Select Delivery Method"
              options={certificateSelections}
              style={{ width: '220px', marginRight: '10px', marginTop: '0px', menuListHeight: '125px' }}
              onChange={(certificatesToBeSentBy) => {
                if(certificatesToBeSentBy.label === 'Other') {
                  quoteFieldsToUpdate.certificatesToBeSentBy = '';
                }
                setCertificate(certificatesToBeSentBy.label);
              }}
              defaultValue={getDefaultValue()}
            />
            {otherCertificate && (
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType="text"
                style={{ width: '193px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(certificatesToBeSentBy) => {
                  setCertificate(certificatesToBeSentBy);
                  quoteFieldsToUpdate.certificatesToBeSentBy = certificatesToBeSentBy;
                }}
                value={quoteFieldsToUpdate.certificatesToBeSentBy}
                placeholder="Other Method"
              />
            )}
          </div>
        </IonCol>
      </IonRow>
    );
  };
  
  

  const IncotermsRow = () => {
    const [incoterms, setIncoterms] = React.useState(quoteFieldsToUpdate.incoterms);
    const [incotermLocation, setIncotermLocation] = React.useState(quoteFieldsToUpdate.incotermLocation);

    React.useEffect(() => {
      quoteFieldsToUpdate.incoterms = incoterms;
    }, [incoterms]);

    React.useEffect(() => {
      quoteFieldsToUpdate.incotermLocation = incotermLocation;
    }, [incotermLocation]);

    return (
      <IonRow>
        <IonCol size='1.5'>
          <IonLabel class='selection-label'>
            Incoterms
          </IonLabel>
        </IonCol>
        <IonCol class='flex-column'>
          <div className='flex-container'>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select Incoterms'
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.Incoterms).options}
              style={{ width: '220px', marginRight: '10px', marginTop: '0px' }}
              onChange={incoterms => setIncoterms(incoterms.label)}
              defaultValue={incoterms}
            />
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='text'
              style={{ width: '193px' }}
              containerStyle={{ marginRight: '10px', marginTop: 'auto', marginBottom: 'auto' }}
              value={quoteFieldsToUpdate.incotermsDescription}
              isDisabled={true}
            />
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Incoterm Location'
              inputType='text'
              style={{ width: '193px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              value={incotermLocation}
              onBlur={incotermLocation =>
                setIncotermLocation(incotermLocation)
              }
              maxLength={maxTextInputLength}
            />
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const ShippingSection = () => {
    // ship via section
    const [doAllItemsShipTheSameWayRadioBtns, setDoAllItemsShipTheSameWayRadioBtns] =
      React.useState<RadioButtonOptions>(
        quoteFieldsToUpdate.doAllItemsShipTheSameWay ? RadioButtonOptions.Yes : RadioButtonOptions.No
      );
    const [shipVia, setShipVia] = React.useState(quoteFieldsToUpdate.shipVia);
    const [shipViaDetails, setShipViaDetails] = React.useState(quoteFieldsToUpdate.shipViaDetails);

    // ship to address section
    const defaultShipToAddress = 'Address from d365'; // TODO: Pull from d365 later
    const [doAllItemsShipToSameAddressRadioBtns, setDoAllItemsShipToSameAddressRadioBtns] = React.useState(
      quoteFieldsToUpdate.doAllItemsShipToSameAddress ? RadioButtonOptions.Yes : RadioButtonOptions.No
    );
    const [shipToAddressType, setShipToAddressType] = React.useState(quoteFieldsToUpdate.shipToAddressType);
    const [shipToAddress, setShipToAddress] = React.useState(quoteFieldsToUpdate.shipToAddress);
    
    // address section
    const [shippingAddressFields, setShippingAddressFields] = React.useState<AddressFields>({
      streetAddress: quoteFieldsToUpdate.shippingStreet,
      city: quoteFieldsToUpdate.shippingCity,
      state: quoteFieldsToUpdate.shippingState,
      postalCode: quoteFieldsToUpdate.shippingPostalCode,
      country: quoteFieldsToUpdate.shippingCountry,
    });

    const handleEdit = (property: string, value: string) => {
      // const val = countryCodes.find((country) => country.label === value)?.value || value;
      // setShippingAddressFields({ ...shippingAddressFields, [property]: val });
      // quoteFieldsToUpdate[property] = val;
      setShippingAddressFields({ ...shippingAddressFields, [property]: value });
    };

    // combined effects
    React.useEffect(() => {
      try {
        if (doAllItemsShipTheSameWayRadioBtns === RadioButtonOptions.Yes) {

          //Update the fields to be saved in state or SF
          //THIS IS QUOTE-LEVEL ADDRESS, NOT LINE ITEM
          quoteFieldsToUpdate.shippingStreet = shippingAddressFields.streetAddress;
          quoteFieldsToUpdate.shippingCity = shippingAddressFields.city;
          quoteFieldsToUpdate.shippingState = shippingAddressFields.state;
          quoteFieldsToUpdate.shippingPostalCode = shippingAddressFields.postalCode;
          quoteFieldsToUpdate.shippingCountry = shippingAddressFields.country;

          //TODO: Need to verify if selected yes, should we overwrite every line item's address details?
          quoteLineItems.forEach((quoteLineItem) => {
            const id = quoteLineItem.Id || quoteLineItem.displayId;

            if (quoteLineItemFieldsToUpdate && quoteLineItemFieldsToUpdate[id]) {
              //Update the field values
              quoteLineItemFieldsToUpdate[id].shipVia = null;
              quoteLineItemFieldsToUpdate[id].shipViaDetails = null;
              // quoteLineItemFieldsToUpdate[id].streetAddress = shippingAddressFields.streetAddress;
              // quoteLineItemFieldsToUpdate[id].city = shippingAddressFields.city;
              // quoteLineItemFieldsToUpdate[id].state = shippingAddressFields.state;
              // quoteLineItemFieldsToUpdate[id].postalCode = shippingAddressFields.postalCode;
              // quoteLineItemFieldsToUpdate[id].country = shippingAddressFields.country;

              // update the ship via fields
              // TODO: verify shipVia and shipViaDetails
              // if (shipVia) {
              //   // quoteLineItemFieldsToUpdate[id].shipVia = shipVia;
              //   // quoteLineItemFieldsToUpdate[id].shipViaDetails = shipViaDetails;
              // }
            }
          });
        } else { // No
          setShipVia(null);
        }

        quoteFieldsToUpdate.doAllItemsShipTheSameWay = doAllItemsShipTheSameWayRadioBtns === RadioButtonOptions.Yes;
        quoteFieldsToUpdate.shipVia = shipVia;
        quoteFieldsToUpdate.doAllItemsShipToSameAddress =
          doAllItemsShipToSameAddressRadioBtns === RadioButtonOptions.Yes;
        quoteFieldsToUpdate.shipToAddressType = shipToAddressType;

        if (shipToAddressType === ShipToAddressTypes.AXDefaultAddress) {
          setShipToAddress(defaultShipToAddress);
        }

        quoteFieldsToUpdate.shipToAddress = shipToAddress;
      } catch (error) {
        console.log(error);
        return;
      }
    }, [doAllItemsShipTheSameWayRadioBtns, shippingAddressFields.city, shippingAddressFields.country, shippingAddressFields.postalCode, shippingAddressFields.state, shippingAddressFields.streetAddress, shipVia, doAllItemsShipToSameAddressRadioBtns, defaultShipToAddress, shipToAddressType, shipToAddress, shipViaDetails]);


    return (
      <>
        <ShipViaSection
          doAllItemsShipTheSameWayRadioBtns={doAllItemsShipTheSameWayRadioBtns}
          setDoAllItemsShipTheSameWayRadioBtns={setDoAllItemsShipTheSameWayRadioBtns}
          shipVia={shipVia}
          setShipVia={setShipVia}
        />
        <ShipToAddressSection
          doAllItemsShipToSameAddressRadioBtns={doAllItemsShipToSameAddressRadioBtns}
          setDoAllItemsShipToSameAddressRadioBtns={setDoAllItemsShipToSameAddressRadioBtns}
          shipToAddressType={shipToAddressType}
          setShipToAddressType={setShipToAddressType}
        />
        <Address addressFields={shippingAddressFields} handleEdit={handleEdit} doAllItemsShipToSameAddressRadioBtns={doAllItemsShipToSameAddressRadioBtns} />
      </>
    );
  };

  interface ShipViaSectionProps {
    doAllItemsShipTheSameWayRadioBtns: RadioButtonOptions;
    setDoAllItemsShipTheSameWayRadioBtns: React.Dispatch<React.SetStateAction<RadioButtonOptions>>;
    shipVia: string;
    setShipVia: React.Dispatch<React.SetStateAction<string>>;
  }

  const ShipViaSection = ({
    doAllItemsShipTheSameWayRadioBtns,
    setDoAllItemsShipTheSameWayRadioBtns,
    shipVia,
    setShipVia,
  }: ShipViaSectionProps) => {
    const shipViaOptions = getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.ShipVia).options;
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

    return (
      <div>
        <IonPopover
          cssClass='order-entry-ship-via-popover'
          isOpen={popoverState.showPopover}
          animated={false}
          onDidDismiss={async () => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
        >
          <ShipViaPopover
            setShowPopover={setShowPopover}
            quoteLineItemFieldsToUpdate={quoteLineItemFieldsToUpdate}
            setQuoteLineItemFieldsToUpdate={setQuoteLineItemFieldsToUpdate}
          />
        </IonPopover>
        <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label top'>Ship Via:</IonLabel>
          </IonCol>
          <IonCol class='flex-column'>
            <IonLabel class='selection-question'>Do all items ship the same way?</IonLabel>
            <div className='flex-container radio-extra-components'>
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                itemStyle={{ '--inner-padding-end': '7px' }}
                options={
                  getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.DoAllItemsShipTheSameWay)
                    .options
                }
                onIonChange={(doAllItemsShipTheSameWay) => {
                  if (
                    doAllItemsShipTheSameWay === RadioButtonOptions.Yes ||
                    doAllItemsShipTheSameWay === RadioButtonOptions.No
                  ) {
                    setDoAllItemsShipTheSameWayRadioBtns(doAllItemsShipTheSameWay);
                  }
                }}
                value={doAllItemsShipTheSameWayRadioBtns}
                extraComponents={[
                  <SelectionContainer
                    type={SelectionTypes.DropDown}
                    placeholder='Select Shipping Method'
                    options={shipViaOptions}
                    style={{ width: '172px', marginRight: '15px', marginTop: '4px' }}
                    isDisabled={doAllItemsShipTheSameWayRadioBtns !== RadioButtonOptions.Yes}
                    onChange={(shipVia) => setShipVia(shipVia.label)}
                    defaultValue={shipVia}
                    value={shipVia}
                  />,
                  <SpecifyButton
                    isDisabled={doAllItemsShipTheSameWayRadioBtns !== RadioButtonOptions.No}
                    onClick={() => setShowPopover({ showPopover: true, event: undefined })}
                  />,
                ]}
              />
            </div>
          </IonCol>
        </IonRow>
      </div>
    );
  };

  interface ShipToAddressSectionProps {
    doAllItemsShipToSameAddressRadioBtns: RadioButtonOptions;
    setDoAllItemsShipToSameAddressRadioBtns: React.Dispatch<React.SetStateAction<RadioButtonOptions>>;
    shipToAddressType: string;
    setShipToAddressType: React.Dispatch<React.SetStateAction<string>>;
  }

  const ShipToAddressSection = ({
    doAllItemsShipToSameAddressRadioBtns,
    setDoAllItemsShipToSameAddressRadioBtns,
    shipToAddressType,
    setShipToAddressType,
  }: ShipToAddressSectionProps) => {
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

    return (
      <>
        <IonPopover
          cssClass='order-entry-ship-to-address-popover,'
          isOpen={popoverState.showPopover}
          animated={false}
          onDidDismiss={async () => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
        >
          <ShipToAddressPopover
            setShowPopover={setShowPopover}
            quoteLineItemFieldsToUpdate={quoteLineItemFieldsToUpdate}
            setQuoteLineItemFieldsToUpdate={setQuoteLineItemFieldsToUpdate}
          />
        </IonPopover>
        <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label top'>Ship To Address:</IonLabel>
          </IonCol>
          <IonCol class='flex-column'>
            <IonLabel class='selection-question'>Do all items ship to same address?</IonLabel>
            <div className='flex-container radio-extra-components'>
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                itemStyle={{ '--inner-padding-end': '7px' }}
                options={
                  getSelectionOptions(
                    selections,
                    manufacturingSite,
                    QuoteLineItemValueTypes.DoAllItemsShipToSameAddress
                  ).options
                }
                onIonChange={(doAllItemsShipToSameAddress) => {
                  if (
                    doAllItemsShipToSameAddress === RadioButtonOptions.Yes ||
                    doAllItemsShipToSameAddress === RadioButtonOptions.No
                  ) {
                    setDoAllItemsShipToSameAddressRadioBtns(doAllItemsShipToSameAddress);
                  }
                }}
                value={doAllItemsShipToSameAddressRadioBtns}
                extraComponents={[
                  <SelectionContainer
                    type={SelectionTypes.DropDown}
                    placeholder='Select Shipping Address'
                    options={
                      getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.ShipToAddressType)
                        .options
                    }
                    style={{ width: '172px', marginRight: '15px', marginTop: '4px' }}
                    isDisabled={doAllItemsShipToSameAddressRadioBtns !== RadioButtonOptions.Yes}
                    onChange={(shipToAddressType) => setShipToAddressType(shipToAddressType.label)}
                    defaultValue={shipToAddressType}
                    value={shipToAddressType}
                  />,
                  <SpecifyButton
                    isDisabled={doAllItemsShipToSameAddressRadioBtns !== RadioButtonOptions.No}
                    onClick={() => setShowPopover({ showPopover: true, event: undefined })}
                  />,
                ]}
              />
            </div>
          </IonCol>
        </IonRow>
      </>
    );
  };

  type AddressFields = {
    streetAddress: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
  };
  interface AddressProps {
    addressFields: AddressFields;
    handleEdit: (field: string, value: string) => void;
    doAllItemsShipToSameAddressRadioBtns: string;
  }
  const Address = (
    { addressFields, handleEdit, doAllItemsShipToSameAddressRadioBtns }: AddressProps,
  ) => {
    const isSiteInUS = manufacturingSite === ManufacturingSites.US01;
    const isDisabled = doAllItemsShipToSameAddressRadioBtns !== RadioButtonOptions.Yes;
    const [currentCountry, setCurrentCountry] = React.useState(addressFields.country);
    const [states, setStates] = React.useState([]);

    React.useEffect(() => {
      if(currentCountry) {
        setStates(findStates(currentCountry));
      } else {
        setStates([]);
      }
    }, [currentCountry]);

    const USAddress = () => (
      <>
        <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label-os'>Street Address:</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='text'
              placeholder='Street Address'
              style={{ width: '220px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={(value) => handleEdit('streetAddress', value)}
              value={addressFields.streetAddress}
              isDisabled={isDisabled}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label-os'>City, State, ZIP:</IonLabel>
          </IonCol>
          <IonCol class='flex-column'>
            <div className='flex-container'>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='text'
                placeholder='City'
                style={{ width: '86px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(value) => handleEdit('city', value)}
                value={addressFields.city}
                isDisabled={isDisabled}
              />
              {states && states.length > 0 ? (
                <SelectionContainer
                type={SelectionTypes.SearchableDropdown}
                noneFoundText='No State Code Found'
                placeholder='State'
                containerStyle={{ marginLeft: '10px', marginRight: '-10px', marginTop: '-10px', marginBottom: 'auto' }}
                options={states}
                suggestionDisplayFields={[SearchFields.Value, SearchFields.Label]}
                onChange={(state) => {
                  try {
                    handleEdit('state', state.value);
                  } catch (error) {
                    console.error(error);
                  }
                }}
                value={addressFields.state}
                isDisabled={isDisabled}
                />
              ) : (
                <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='text'
                placeholder='State'
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '10px', marginRight: '10px', }}
                onBlur={(value) => handleEdit('state', value)}
                value={addressFields.state}
                isDisabled={isDisabled}
                />
              )}
             
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='text'
                placeholder='ZIP'
                style={{ width: '57px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(value) => handleEdit('postalCode', value)}
                value={addressFields.postalCode}
                isDisabled={isDisabled}
              />
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label-os'>Country:</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
                type={SelectionTypes.SearchableDropdown}
                noneFoundText='No Country Code Found'
                placeholder='Country'
                options={countryCodes}
                suggestionDisplayFields={[SearchFields.Value, SearchFields.Label]}
                onChange={(country) => {
                  try {
                    setCurrentCountry(country.value);
                    handleEdit('country', country.value);
                  } catch (error) {
                    console.error(error);
                  }
                }}
                value={addressFields.country}
                isDisabled={isDisabled}
            />
          </IonCol>
        </IonRow>
      </>
    );

    const DEAddress = () => (
      <>
        <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label-os'>Street Address:</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='text'
              placeholder='Street Address'
              style={{ width: '220px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={(value) => handleEdit('streetAddress', value)}
              value={addressFields.streetAddress}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label-os'>Postal Code, City:</IonLabel>
          </IonCol>
          <IonCol class='flex-column'>
            <div className='flex-container'>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='text'
                placeholder='Postal Code'
                style={{ width: '68px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(value) => handleEdit('postalCode', value)}
                value={addressFields.postalCode}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='text'
                placeholder='City'
                style={{ width: '141px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '10px' }}
                onBlur={(value) => handleEdit('city', value)}
                value={addressFields.city}
              />
            </div>
          </IonCol>
        </IonRow>
        {(states && states.length > 0) || addressFields.state ? (
          <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label-os'>State:</IonLabel>
          </IonCol>
          <IonCol>
          <SelectionContainer
            type={SelectionTypes.SearchableDropdown}
            noneFoundText='No State Code Found'
            placeholder='State'
            containerStyle={{ marginLeft: '0px', marginRight: '-10px', marginTop: '-10px', marginBottom: 'auto' }}
            options={states}
            suggestionDisplayFields={[SearchFields.Value, SearchFields.Label]}
            onChange={(state) => {
              try {
                handleEdit('state', state.value);
              } catch (error) {
                console.error(error);
              }
            }}
            value={addressFields.state}
            isDisabled={isDisabled}
          />
          </IonCol>
        </IonRow>
        ) : null}
        <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label-os'>Country:</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.SearchableDropdown}
              noneFoundText='No Country Code Found'
              placeholder='Country'
              options={countryCodes}
              suggestionDisplayFields={[SearchFields.Value, SearchFields.Label]}
              onChange={(country) => {
                try {
                  handleEdit('country', country.value);
                } catch (error) {
                  console.error(error);
                }
              }}
              value={addressFields.country}
            />
          </IonCol>
        </IonRow>
      </>
    );

    return isSiteInUS ? <USAddress /> : <DEAddress />;
  };

  const CommissionRow = () => {
    const commissionPaidToOptions = getSelectionOptionsWithNameAndValue(selections, manufacturingSite, QuoteLineItemValueTypes.CommissionPaidTo).options;
    const [commission, setCommission] = React.useState(quoteFieldsToUpdate.commissionPaidTo ? RadioButtonOptions.PayTo : getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.Commission).default);
    const [commissionPaidTo, setCommissionPaidTo] = React.useState(quoteFieldsToUpdate.commissionPaidTo);

    React.useEffect(() => {
      if(commission === RadioButtonOptions.None) {
        setCommissionPaidTo(null);
      }
    }, [commission]);

    React.useEffect(() => {
      quoteFieldsToUpdate.commissionPaidTo = commissionPaidTo;
    }, [commissionPaidTo]);

    return (
      <IonRow>
        <IonCol size='1.5'>
          <IonLabel class='selection-label'>
            Commission:
          </IonLabel>
        </IonCol>
        <IonCol>
          <div className='flex-container radio-extra-components'>
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.Commission).options}
              onIonChange={commission => {
                if(commission === RadioButtonOptions.PayTo || commission === RadioButtonOptions.None) {
                  setCommission(commission);
                }
              }}
              value={commission}
              extraComponents={[
                null,
                <SelectionContainer
                  type={SelectionTypes.DropDown}
                  placeholder='Select Commission Paid To'
                  options={ commissionPaidToOptions.map((o) => o.name) }
                  style={{ width: '220px', marginTop: '4px' }}
                  isDisabled={commission !== RadioButtonOptions.PayTo}
                  onChange={(commissionPaidTo) => setCommissionPaidTo((commissionPaidToOptions.find((o) => o.name === commissionPaidTo.label))?.value)}
                  defaultValue={commissionPaidTo}
                  value={commissionPaidToOptions?.find((o) => o.value === commissionPaidTo)?.name}
                />
              ]}
            />
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const ProductToTagDetails = (props: {
    quoteLineItemDetailIndex: number,
    product: number
    quoteLineItemDetail: QuoteLineItemDetail
    quoteLineItem: QuoteLineItem
  }) => {

    const handleProductTag = (tag: string) => {
      const currentTags = quoteLineItemFieldsToUpdate[props.quoteLineItem.Id || props.quoteLineItem.displayId].productTag;

      const tagObject = {
        'PartNumber': props.product,
        'CustomerTag': tag
      };

      if (!currentTags) {
        const customerTags = [
            tagObject
        ];
        const productTagsObject = {'CustomerTags': customerTags};

        const productTagsJSON = JSON.stringify(productTagsObject);
        quoteLineItemFieldsToUpdate[props.quoteLineItem.Id || props.quoteLineItem.displayId].productTag = productTagsJSON;
      } else {
        const currentTagsObject = JSON.parse(currentTags);

        const currentTagForProduct = currentTagsObject.CustomerTags.find(tag => tag.PartNumber === props.product);

        if (currentTagForProduct) {
          currentTagForProduct.CustomerTag = tag;
        } else {
          currentTagsObject.CustomerTags.push(tagObject);
        }
        const productTagsJSON = JSON.stringify(currentTagsObject);
        quoteLineItemFieldsToUpdate[props.quoteLineItem.Id || props.quoteLineItem.displayId ].productTag = productTagsJSON;
      }
    };

    const findTagValue = () => {
      const currentTags = quoteLineItemFieldsToUpdate[props.quoteLineItem.Id || props.quoteLineItem.displayId].productTag;

      if(currentTags) {
        const currentTagsObject = JSON.parse(currentTags);
        const currentTagForProduct = currentTagsObject.CustomerTags.find(tag => tag.PartNumber === props.product);

        if(currentTagForProduct) {
          return currentTagForProduct.CustomerTag;
        }
      }
    };

    return (
      <>
        <IonCard className="product-to-tag-card detail" data-testid={'QAOrderEntryProductToTagDetails_' + props.quoteLineItemDetailIndex}>
          <IonCardHeader class='product-to-tag-card-header'>
            <IonGrid>
              <IonRow class="product-to-tag-row">
                <IonCol size="1" class="column-left product-tagging">
                  <LineItemField datatestid="QAOrderEntryProductToTagDetailsPiece" displayedValue={props.product}/>
                </IonCol>
                <IonCol class="column-left product-tagging">
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    inputType='text'
                    placeholder='Product Tag'
                    style={{ width: '362px' }}
                    containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                    onBlur={productTag => {
                      try {
                        handleProductTag(productTag);
                      } catch (error) {
                        console.log(error);
                        return;
                      }
                    }}
                    value={findTagValue()}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const ProductToTagDetailsList = (props: {
    quoteLineItem: QuoteLineItem,
    quoteLineItemId: string,
    quoteLineItemDisplayId: string
  }) => {
    const productsToTag = Array.from({length: props.quoteLineItem.Quantity__c}, (_, i) => i + 1);
    const quoteLineItemDetail = props.quoteLineItem.quoteLineItemDetail;
    return (quoteLineItemDetails?.length ?
      <>{productsToTag.map((productToTag, index) => {
          return <ProductToTagDetails product={productToTag} quoteLineItemDetail={quoteLineItemDetail} quoteLineItemDetailIndex={index} quoteLineItem={props.quoteLineItem}/>;
        })}
      </> : null
    );
  };

  const ProductToTagExpandedTable = (props: {
    quoteLineItem: QuoteLineItem,
    quoteLineItemIndex: number,
    expanded: boolean
  }) => {
    const tableHeadersProductToTagExpandedTable = [
      { display: QuoteLineItemsColumnHeadings.Piece, colSize: '1' },
      { display: QuoteLineItemsColumnHeadings.ProductTag }
    ];

    return (props.expanded ?
      <>
        <hr className='detail-divider'/>
        <div className='detail-connector'>
          <hr className='detail-connector-vert-line'/>
        </div>
        <hr className='detail-connector-hor-line'/>
        <TableHeaders labels={tableHeadersProductToTagExpandedTable} details={true}/>
        <ProductToTagDetailsList quoteLineItemId={props.quoteLineItem.Id} quoteLineItemDisplayId={props.quoteLineItem.displayId} quoteLineItem={props.quoteLineItem}/>
      </> : null
    );
  };

  const ProductTagAllOrGroupPopover = (props: {
    popoverState: {
      showPopover: boolean;
      event: any;
    },
    setShowPopover: React.Dispatch<React.SetStateAction<{
      showPopover: boolean;
      event: any;
    }>>,
    quoteLineItem?: QuoteLineItem
  }) => {
    return (
      <IonPopover
        cssClass='product-tag-popover'
        event={props.popoverState.event}
        isOpen={props.popoverState.showPopover}
        animated={ false }
        showBackdrop={ false }
        onDidDismiss={() => {
          props.setShowPopover({ showPopover: false, event: undefined });
        }}
      >
        <ProductTagPopover
          setShowPopover={props.setShowPopover}
          quoteLineItem={props.quoteLineItem}
          quoteLineItemFieldsToUpdate={quoteLineItemFieldsToUpdate}
          setQuoteLineItemFieldsToUpdate={setQuoteLineItemFieldsToUpdate}
        />
      </IonPopover>
    );
  };

  const ProductToTag = (props: {
    quoteLineItem: QuoteLineItem,
    quoteLineItemIndex: number
  }) => {
    const [expanded, setExpanded] = React.useState(false);
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

    return (
      <>
        <ProductTagAllOrGroupPopover popoverState={popoverState} setShowPopover={setShowPopover} quoteLineItem={props.quoteLineItem}/>
        <IonCard className='product-to-tag-card' data-testid={'QAOrderEntryProductToTag_' + props.quoteLineItemIndex}>
          <IonCardHeader class='product-to-tag-card-header'>
            <IonGrid>
              <IonRow class="product-to-tag-row">
                <IonCol size='0.23' class="column-left product-tagging expand-column">
                  <IonIcon className="product-to-tag-expand-button" data-testid="QAOrderEntryProductToTagExpandButton" src={expanded ? chevronUp : chevronDown} onClick={() => setExpanded(!expanded)}></IonIcon>
                </IonCol>
                <IonCol size="0.458" class="column-left product-tagging">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Line}
                    datatestid="QAOrderEntryProductToTagLine"
                    displayedValue={props.quoteLineItem.Line__c}
                  />
                </IonCol>
                <IonCol size="0.6" class="column-left product-tagging">
                  <LineItemField
                    datatestid="QAOrderEntryProductToTagQuantity"
                    valueType={QuoteLineItemValueTypes.SizingQuantity}
                    value={props.quoteLineItem.Quantity__c}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="0.916" class="column-left product-tagging">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.SizingProduct}
                    datatestid="QAOrderEntryProductToTagProduct"
                    displayedValue={props.quoteLineItem.Item_Type__c || '- -'}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="1.2" class="column-left product-tagging">
                  <LineItemField
                    datatestid="QAOrderEntryProductToTagWidth"
                    valueType={QuoteLineItemValueTypes.Width}
                    imperialValue={props.quoteLineItem.Width_in__c}
                    metricValue={props.quoteLineItem.Width_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size="1.2" class="column-left product-tagging">
                  <LineItemField
                    datatestid="QAOrderEntryProductToTagLength"
                    valueType={QuoteLineItemValueTypes.Length}
                    imperialValue={props.quoteLineItem.Length_in__c}
                    metricValue={props.quoteLineItem.Length_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size="1.374" class="column-left product-tagging">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladType}
                    datatestid="QAOrderEntryProductToTagCladType"
                    displayedValue={props.quoteLineItem.Clad_Type__c || '- -'}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="1.2" class="column-left product-tagging">
                  <LineItemField
                    datatestid="QAOrderEntryProductToTagCladTK"
                    valueType={QuoteLineItemValueTypes.FinishedCladTK}
                    imperialValue={props.quoteLineItem.Clad_TK_in__c}
                    metricValue={props.quoteLineItem.Clad_TK_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size="1.374" class="column-left product-tagging">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseType}
                    datatestid="QAOrderEntryProductToTagBaseType"
                    displayedValue={props.quoteLineItem.Base_Type__c || '- -'}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="1.2" class="column-left product-tagging">
                  <LineItemField
                    datatestid="QAOrderEntryProductToTagBaseTK"
                    valueType={QuoteLineItemValueTypes.FinishedBaseTK}
                    imperialValue={props.quoteLineItem.Base_TK_in__c}
                    metricValue={props.quoteLineItem.Base_TK_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol class="column-left">
                  <div className="column-container right-align">
                    <IonButton class='tag-group-button' onClick={(e) => setShowPopover({ showPopover: true, event: e })} data-testid='QAOrderEntryTagGroupButton'>
                        Tag Group
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <ProductToTagExpandedTable expanded={expanded} quoteLineItem={props.quoteLineItem} quoteLineItemIndex={props.quoteLineItemIndex}/>
      </>
    );
  };

  const ProductsToTag = () => {
    return (
      <>
        {quoteLineItems?.orderBy(quoteLineItem => quoteLineItem.Line__c).map((quoteLineItem, index) => {
          return <ProductToTag quoteLineItem={quoteLineItem} quoteLineItemIndex={index} key={index}/>;
        })}
      </>
    );
  };

  const ProductsToTagTable = () => {
    const tableHeadersProductsToTagTable = [
      { display: '', colSize: '0.32' },
      { display: QuoteLineItemsColumnHeadings.Line, colSize: '0.438' },
      { display: QuoteLineItemsColumnHeadings.Quantity, colSize: '0.6' },
      { display: QuoteLineItemsColumnHeadings.Product, colSize: '0.9' },
      { display: QuoteLineItemsColumnHeadings.Width, colSize: '1.2' },
      { display: QuoteLineItemsColumnHeadings.Length, colSize: '1.2' },
      { display: QuoteLineItemsColumnHeadings.CladType, colSize: '1.355' },
      { display: QuoteLineItemsColumnHeadings.CladTK, colSize: '1.2' },
      { display: QuoteLineItemsColumnHeadings.BaseType, colSize: '1.355' },
      { display: QuoteLineItemsColumnHeadings.BaseTK, colSize: '1.2' },
    ];

    return (
      <div>
        <TableHeaders labels={tableHeadersProductsToTagTable}/>
        <ProductsToTag/>
      </div>
    );
  };

  const ProductTaggingSection = () => {
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

    return (
      <>
        <ProductTagAllOrGroupPopover popoverState={popoverState} setShowPopover={setShowPopover}/>
        <IonRow>
          <IonCol class='full-length-col'>
            <IonLabel class='selection-label'>
              Product Tagging:
            </IonLabel>
            <IonButton class='tag-all-button' color='primary' onClick={(e) => setShowPopover({ showPopover: true, event: e })} data-testid='QAOrderEntryTagAllButton'>
              Tag All
            </IonButton>
          </IonCol>
        </IonRow>
        <ProductsToTagTable/>
      </>
    );
 };

  /** Tab Sections */
  // JobSpecifications Section
  const JobSpecifications = () => (
    <>
      <div ref={jobSpecificationsRef} />
      <IonGrid>
        <IonRow>
          <IonCol size="7">
            <div className='quote-info-container'>
              <IonList lines="none" class="ion-no-padding quote-info-label-container">
                <IonItem class="ion-no-padding label-container">
                  <IonLabel class="quote-info-label">Quote Number:</IonLabel>
                </IonItem>
                <IonItem class="ion-no-padding label-container">
                  <IonLabel class="quote-info-label">Company Name:</IonLabel>
                </IonItem>
                <IonItem class="ion-no-padding label-container">
                  <IonLabel class="quote-info-label">Manufacturing Site:</IonLabel>
                </IonItem>
              </IonList>
              <IonList lines="none" class="ion-no-padding quote-info-value-container">
                <IonItem class="ion-no-padding value-container">
                  <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageQuoteNumber">{quote?.Name || '- -'}</IonLabel>
                </IonItem>
                <IonItem class="ion-no-padding value-container">
                  <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageCompanyName">{quote?.Account__r.Name || '- -'}</IonLabel>
                </IonItem>
                <IonItem class="ion-no-padding value-container">
                  <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageManufacturingSite">{manufacturingSite || '- -'}</IonLabel>
                </IonItem>
              </IonList>
            </div>
          </IonCol>
          <IonCol>
            <div className='quote-info-container'>
              <IonList lines="none" class="ion-no-padding quote-info-label-container">
                <IonItem class="ion-no-padding label-container">
                  <IonLabel class="quote-info-label">Salesforce Opportunity:</IonLabel>
                </IonItem>
                <IonItem class="ion-no-padding label-container">
                  <IonLabel class="quote-info-label">Notes:</IonLabel>
                </IonItem>
              </IonList>
              <IonList lines="none" class="ion-no-padding quote-info-value-container">
                <IonItem class="ion-no-padding value-container">
                  <IonLabel class={classNames({ 'quote-info-value': true, 'link': true })} data-testid="QAQuoteDetailPageSalesforceOpportunity" onClick={() => {
                    try {
                      window.open(`${environment.SALESFORCE_URL}lightning/r/Opportunity/${quote?.Opportunity__c}/view`, '_blank');
                    } catch (error) {
                      console.log(error);
                      return;
                    }
                  }}
                  >{ quote?.Opportunity__r?.Name || '- -' }</IonLabel>
                </IonItem>
                <IonItem class="ion-no-padding value-container">
                  <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageRevisionNotes">{quote?.RevisionNotes__c || '- -'}</IonLabel>
                </IonItem>
              </IonList>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label'>
              Customer Document UOM:
            </IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerDocumentUOM).options}
              onIonChange={customerDocumentUOM =>
                (quoteFieldsToUpdate.customerDocumentUOM = customerDocumentUOM)
              }
              value={quoteFieldsToUpdate.customerDocumentUOM}
            />
          </IonCol>
        </IonRow>
        <CustomerPONumberSection/>
        <CustomerContactForJobRow/>
        <SendCertificatesToRow/>
        <PaymentTermsRow/>
        <CertificatesToBeSentRow/>
        <IncotermsRow/>
        <ShippingSection />
        <IonRow>
          <IonCol size='1.5'>
            <IonLabel class='selection-label'>
              Order Booked Date:
            </IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.Date}
              containerStyle={{ width: '220px', height: '26px' }}
              onBlur={orderBookedDate =>
                quoteFieldsToUpdate.orderBookedDate = orderBookedDate
              }
              value={quoteFieldsToUpdate.orderBookedDate}
            />
          </IonCol>
        </IonRow>
        <CommissionRow/>
        <ProductTaggingSection/>
      </IonGrid>
    </>
  );

  const SendToRow = () => {
    const [sendTo, setSendTo] = React.useState((quoteFieldsToUpdate.procedureApprovalsSendTo as string)?.split(';') || []);

    React.useEffect(() => {
      quoteFieldsToUpdate.procedureApprovalsSendTo = sendTo.join(';');
    }, [sendTo]);

    return (
      <IonRow>
        <IonCol size='2.3'>
          <IonLabel class='selection-label top'>
            Send To
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextList}
            style={{ width: '775px', height: '86px' }}
            placeholder='Specify Recipients...'
            onChange={sendTo =>
              setSendTo(sendTo)
            }
            regex={Regexes.Email}
            value={sendTo}
            textListType='Send To'
          />
        </IonCol>
      </IonRow>
    );
  };

  const ProcedureRow = (props: {
    label: string,
    optionRequiredField: ProcedureApprovalOptionsRequiredFields,
    dateNeededFieldToUpdate: ProcedureApprovalDateNeededFields,
    detailsFieldToUpdate: ProcedureApprovalDetailsFields
  }) => {
    const [procedureRequired, setProcedureRequired] = React.useState(optionsRequired[props.optionRequiredField]);
    const [procedureDateNeeded, setProcedureDateNeeded] = React.useState(quoteFieldsToUpdate[props.dateNeededFieldToUpdate]);

    React.useEffect(() => {
      quoteFieldsToUpdate[props.dateNeededFieldToUpdate] = procedureDateNeeded;
    }, [procedureDateNeeded]);

    return (
      <IonRow>
        <IonCol size='2.3'>
          <CheckboxItem
            label={props.label}
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto' }}
            onIonChange={checked => {
              optionsRequired[props.optionRequiredField] = checked;
              setProcedureRequired(checked);
            }}
            checked={optionsRequired[props.optionRequiredField]}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.Date}
            min={new Date().toISOString().slice(0,10)}
            containerStyle={{ width: '118px', height: '26px', marginRight: '10px' }}
            onBlur={procedureDateNeeded =>
              setProcedureDateNeeded(procedureDateNeeded)
            }
            value={procedureDateNeeded}
            isDisabled={!procedureRequired}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Details...'
            inputType='text'
            style={{ width: '647px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={procedureDetails =>
              (quoteFieldsToUpdate[props.detailsFieldToUpdate] = procedureDetails)
            }
            value={quoteFieldsToUpdate[props.detailsFieldToUpdate]}
            isDisabled={!procedureRequired}
          />
        </IonCol>
      </IonRow>
    );
  };

  // ProcedureApprovals Section
  const ProcedureApprovals = () => (
    <>
      <div ref={procedureApprovalsRef} />
      <IonGrid>
        <SendToRow/>
        <ProcedureRow
          label='Inspection and Test Plan Procedure'
          optionRequiredField={ProcedureApprovalOptionsRequiredFields.InspectionsAndTestPlanProcedure}
          dateNeededFieldToUpdate={ProcedureApprovalDateNeededFields.InspectionAndTestPlanProcedureDateNeeded}
          detailsFieldToUpdate={ProcedureApprovalDetailsFields.InspectionAndTestPlanProcedureDetails}
        />
        <ProcedureRow
          label='Heat Treatment Procedure'
          optionRequiredField={ProcedureApprovalOptionsRequiredFields.HeatTreatmentProcedure}
          dateNeededFieldToUpdate={ProcedureApprovalDateNeededFields.HeatTreatmentProcedureDateNeeded}
          detailsFieldToUpdate={ProcedureApprovalDetailsFields.HeatTreatmentProcedureDetails}
        />
        <ProcedureRow
          label='Head Procedure'
          optionRequiredField={ProcedureApprovalOptionsRequiredFields.HeatProcedure}
          dateNeededFieldToUpdate={ProcedureApprovalDateNeededFields.HeatProcedureDateNeeded}
          detailsFieldToUpdate={ProcedureApprovalDetailsFields.HeatProcedureDetails}
        />
        <ProcedureRow
          label='Non-Destructive Evaluation Testing Procedure'
          optionRequiredField={ProcedureApprovalOptionsRequiredFields.NonDestructiveEvaluationTestingProcedure}
          dateNeededFieldToUpdate={ProcedureApprovalDateNeededFields.NonDestructiveEvaluationTestingProcedureDateNeeded}
          detailsFieldToUpdate={ProcedureApprovalDetailsFields.NonDestructiveEvaluationTestingProcedureDetails}
        />
        <ProcedureRow
          label='Welding Procedure'
          optionRequiredField={ProcedureApprovalOptionsRequiredFields.WeldingProcedure}
          dateNeededFieldToUpdate={ProcedureApprovalDateNeededFields.WeldingProcedureDateNeeded}
          detailsFieldToUpdate={ProcedureApprovalDetailsFields.WeldingProcedureDetails}
        />
        <ProcedureRow
          label='Other Procedure'
          optionRequiredField={ProcedureApprovalOptionsRequiredFields.OtherProcedure}
          dateNeededFieldToUpdate={ProcedureApprovalDateNeededFields.OtherProcedureDateNeeded}
          detailsFieldToUpdate={ProcedureApprovalDetailsFields.OtherProcedureDetails}
        />
      </IonGrid>
    </>
  );

  const WitnessingRow = (props: {
    event: string,
    witnessingFieldToUpdate: WitnessingFields
  }) => {
    const [witnessingThirdPartyInspection, setWitnessingThirdPartyInspection] = React.useState(['Third Party Inspection', 'Both'].includes(quoteFieldsToUpdate[props.witnessingFieldToUpdate]));
    const [witnessingCustomerWitness, setWitnessingCustomerWitness] = React.useState(['Customer Witness', 'Both'].includes(quoteFieldsToUpdate[props.witnessingFieldToUpdate]));

    React.useEffect(() => {
      if (witnessingThirdPartyInspection && witnessingCustomerWitness) {
        quoteFieldsToUpdate[props.witnessingFieldToUpdate] = 'Both';
      } else if (witnessingThirdPartyInspection) {
        quoteFieldsToUpdate[props.witnessingFieldToUpdate] = 'Third Party Inspection';
      } else if (witnessingCustomerWitness) {
        quoteFieldsToUpdate[props.witnessingFieldToUpdate] = 'Customer Witness';
      } else {
        quoteFieldsToUpdate[props.witnessingFieldToUpdate] = null;
      }
    }, [witnessingThirdPartyInspection, witnessingCustomerWitness]);

    return (
      <IonCard className='order-entry-card' onClick={(e: any) => {
        e.persist();
      }} data-testid={'QAOrderEntryInspectionsAndWitnessingRow_' + props.event}
      >
        <IonCardHeader class='order-entry-card-header'>
          <IonGrid>
            <IonRow class="order-entry-row">
              <IonCol size="5.6">
                <IonLabel class='order-entry-label'>{props.event}</IonLabel>
              </IonCol>
              <IonCol size="1">
                <CheckboxItem
                  style={{
                    width: 'fit-content',
                    margin: 'auto',
                    '--background': 'transparent',
                    '--inner-padding-end': '0px'
                  }}
                  onIonChange={checked => {
                    setWitnessingThirdPartyInspection(checked);
                  }}
                  checked={witnessingThirdPartyInspection}
                />
              </IonCol>
              <IonCol size="1">
                <CheckboxItem
                  style={{
                    width: 'fit-content',
                    margin: 'auto',
                    '--background': 'transparent',
                    '--inner-padding-end': '0px'
                  }}
                  onIonChange={checked => {
                    setWitnessingCustomerWitness(checked);
                  }}
                  checked={witnessingCustomerWitness}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
      </IonCard>
    );
  };

  const InspectionCompanyRow = () => {
    const [inspectionCompanySpecifiedBy, setInspectionCompanySpecifiedBy] = React.useState(quoteFieldsToUpdate.inspectionCompanyOption === RadioButtonOptions.NobelCladChoice ? RadioButtonOptions.NobelCladChoice : RadioButtonOptions.CustomerSpecified);
    const [inspectionCompany, setInspectionCompany] = React.useState(quoteFieldsToUpdate.inspectionCompanyOption);

    React.useEffect(() => {
      if(inspectionCompanySpecifiedBy === RadioButtonOptions.NobelCladChoice) {
        setInspectionCompany(null);
      }
      quoteFieldsToUpdate.inspectionCompanyOption = inspectionCompanySpecifiedBy;
    }, [inspectionCompanySpecifiedBy]);

    React.useEffect(() => {
      quoteFieldsToUpdate.inspectionCompany = inspectionCompany;
    }, [inspectionCompany]);

    return (
      <IonRow>
        <IonCol size='2.5'>
          <IonLabel class='selection-label bold'>
            Inspection Company:
          </IonLabel>
        </IonCol>
        <IonCol>
          <div className='flex-container radio-extra-components'>
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.InspectionCompany).options}
              onIonChange={inspectionCompanySpecifiedBy => {
                if(inspectionCompanySpecifiedBy === RadioButtonOptions.NobelCladChoice || inspectionCompanySpecifiedBy === RadioButtonOptions.CustomerSpecified) {
                  setInspectionCompanySpecifiedBy(inspectionCompanySpecifiedBy);
                }
              }}
              value={inspectionCompanySpecifiedBy}
              extraComponents={[
                null,
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  placeholder='Specify Customer Inspection Company Details...'
                  inputType='text'
                  style={{ width: '474px' }}
                  containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                  isDisabled={inspectionCompanySpecifiedBy !== RadioButtonOptions.CustomerSpecified}
                  onBlur={inspectionCompany =>
                    setInspectionCompany(inspectionCompany)
                  }
                  value={inspectionCompany}
                />
              ]}
            />
          </div>
        </IonCol>
      </IonRow>
    );
  };

  // InspectionsAndWitnessing Section
  const InspectionsAndWitnessing = () => {
    const tableHeadersInspectionsAndWitnessingTable = [
      { display: OrderEntryColumnHeadings.Event, colSize: '5.55' },
      { display: OrderEntryColumnHeadings.ThirdPartyInspection, colSize: '1.05' },
      { display: OrderEntryColumnHeadings.CustomerWitness, colSize: '1' },
    ];

    return (
      <>
        <div ref={inspectionsAndWitnessingRef} />
        <IonGrid>
          <IonRow>
            <IonCol class='inspections-and-witnessing-container block'>
              <TableHeaders labels={tableHeadersInspectionsAndWitnessingTable}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.BaseMetalTestCouponCuttingToBeWitnessedAtVendors} witnessingFieldToUpdate={WitnessingFields.BaseTestCouponCutWitnessAtVendor}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.BaseMetalTestWitnessAtVendor} witnessingFieldToUpdate={WitnessingFields.BaseTestWitnessAtVendor}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.BaseMetalCertificateApprovalAtVendor} witnessingFieldToUpdate={WitnessingFields.BaseCertificateApprovalatVendor}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.BaseMetalRetestAtDMCBeforeCladding} witnessingFieldToUpdate={WitnessingFields.BaseRetestAtDMCBeforeCladding}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.PEDRequalificationOfBaseMetal} witnessingFieldToUpdate={WitnessingFields.PEDRequalificationOfBaseMetal}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.CladdingMetalTestCouponCuttingToBeWitnessedAtVendor} witnessingFieldToUpdate={WitnessingFields.CladTestCouponCutWitnessAtVendor}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.CladdingMetalTestWitnessAtVendor} witnessingFieldToUpdate={WitnessingFields.CladdingMetalTestWitnessAtVendor}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.CladdingMetalCertificateApprovalAtVendor} witnessingFieldToUpdate={WitnessingFields.CladCertificateApprovalAtVendor}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.CladdingMetalRetestAtDMCBeforeCladding} witnessingFieldToUpdate={WitnessingFields.CladdingMetalRetestAtDMCBeforeCladding}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.CladTestSpecimenRemovalAndStamping} witnessingFieldToUpdate={WitnessingFields.CladTestSpecimenRemovalAndStamping}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.CladDestructiveTesting} witnessingFieldToUpdate={WitnessingFields.CladDestructiveTesting}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.DocumentReviewAndApprovalAtDMC} witnessingFieldToUpdate={WitnessingFields.DocumentReviewApprovalAtDMC}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.CladNDTAndFinalInspection} witnessingFieldToUpdate={WitnessingFields.CladNDTAndFinalInspection}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.HeadInspectionAtFormer} witnessingFieldToUpdate={WitnessingFields.HeadInspectionAtFormer}/>
              <WitnessingRow event={InspectionsAndWitnessingEvents.HeadFormingEvent} witnessingFieldToUpdate={WitnessingFields.HeadFormingEvent}/>
            </IonCol>
          </IonRow>
          <InspectionCompanyRow/>
        </IonGrid>
      </>
    );
  };

  // OrderNotes Section
  const OrderNotes = () => (
    <>
      <div ref={orderNotesRef} />
      <IonGrid>
        <IonRow>
          <IonCol class='flex-column' size='2.5'>
            <IonLabel class='selection-label bold top'>
              General Comments for Job Planning
            </IonLabel>
            <IonLabel class='selection-label sub'>
              • For Internal Use Only<br/>• INCLUDED ON OER OUTPUT ONLY
            </IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextArea}
              placeholder='Comments...'
              style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto' }}
              rows={6}
              onBlur={generalCommentsForJobPlanning => {
                quoteFieldsToUpdate.generalCommentsForJobPlanning = generalCommentsForJobPlanning;
                if (generalCommentsForJobPlanning.length > TextAreaMax.TenThousand) {
                  toast(ToastTypes.Error, ToastMessages.TooManyCharactersTenThousand);
                  setGeneralCommentsForJobPlanningMaxSurpassed(true);
                 } else {
                  setGeneralCommentsForJobPlanningMaxSurpassed(false);
                }
              }}
              value={quoteFieldsToUpdate.generalCommentsForJobPlanning}
              textAreaType='General Comments For Job Planning'
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol class='flex-column' size='2.5'>
            <IonLabel class='selection-label bold top'>
              Additional Purchase Order Notes for Base Metal Selections
            </IonLabel>
            <IonLabel class='selection-label sub'>
              • INCLUDED ON BOM PRINTOUTS
            </IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextArea}
              placeholder='Comments...'
              style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto' }}
              rows={6}
              onBlur={additionalPurchaseOrderNotesForBaseMetalSelections => {
                quoteFieldsToUpdate.additionalPurchaseOrderNotesForBaseMetalSelections = additionalPurchaseOrderNotesForBaseMetalSelections;
                if (additionalPurchaseOrderNotesForBaseMetalSelections.length > TextAreaMax.TenThousand) {
                  toast(ToastTypes.Error, ToastMessages.TooManyCharactersTenThousand);
                  setAdditionalPurchaseOrderNotesForBaseMetalSelectionsMaxSurpassed(true);
                 } else {
                  setAdditionalPurchaseOrderNotesForBaseMetalSelectionsMaxSurpassed(false);
                }
              }}
              value={quoteFieldsToUpdate.additionalPurchaseOrderNotesForBaseMetalSelections}
              textAreaType='Additional Purchase Order Notes For Base Metal Selections'
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol class='flex-column' size='2.5'>
            <IonLabel class='selection-label bold top'>
              Additional Purchase Order Notes for Clad Metal Selections
            </IonLabel>
            <IonLabel class='selection-label sub'>
              • INCLUDED ON BOM PRINTOUTS
            </IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextArea}
              placeholder='Comments...'
              style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto' }}
              rows={6}
              onBlur={additionalPurchaseOrderNotesForCladMetalSelections => {
                quoteFieldsToUpdate.additionalPurchaseOrderNotesForCladMetalSelections = additionalPurchaseOrderNotesForCladMetalSelections;
                if (additionalPurchaseOrderNotesForCladMetalSelections.length > TextAreaMax.TenThousand) {
                  toast(ToastTypes.Error, ToastMessages.TooManyCharactersTenThousand);
                  setAdditionalPurchaseOrderNotesForCladMetalSelectionsMaxSurpassed(true);
                 } else {
                  setAdditionalPurchaseOrderNotesForCladMetalSelectionsMaxSurpassed(false);
                }
              }}
              value={quoteFieldsToUpdate.additionalPurchaseOrderNotesForCladMetalSelections}
              textAreaType='Additional Purchase Order Notes For Clad Metal Selections'
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );

  const ShippingRow = (props: {
    quoteLineItem: QuoteLineItem,
    quoteLineItemIndex: number,
    key: number
  }) => {
    const quoteLineItem = props.quoteLineItem;
    const id = quoteLineItem?.Id || quoteLineItem?.displayId;

    const [shipDate, setShipDate] = React.useState(
      quoteLineItemFieldsToUpdate
        ? quoteLineItemFieldsToUpdate[id]?.shipDate
        : null
    );

    React.useEffect(() => {
      
      if(quoteLineItemFieldsToUpdate && quoteLineItemFieldsToUpdate[id]) {
        quoteLineItemFieldsToUpdate[id].shipDate = shipDate;
      }
    }, [shipDate]);

    return (
      <IonCard className='order-entry-card' onClick={(e: any) => {
        e.persist();
      }} data-testid={'QAOrderEntryShippingRow_' + props.quoteLineItemIndex}
      >
        <IonCardHeader class='order-entry-card-header'>
          <IonGrid>
            <IonRow class="order-entry-row">
              <IonCol size="1">
                <IonLabel class='order-entry-label shipping'>{props.quoteLineItem.Line__c}</IonLabel>
              </IonCol>
              <IonCol size="1">
                <IonLabel class='order-entry-label shipping'>{props.quoteLineItem.Quantity__c}</IonLabel>
              </IonCol>
              <IonCol size="3">
                <IonLabel class='order-entry-label shipping'>{displayUom === MeasurementSystems.Imperial ? props.quoteLineItem.ItemDescriptionImp__c : props.quoteLineItem.ItemDescriptionMetric__c}</IonLabel>
              </IonCol>
              <IonCol size="1.2">
                <SelectionContainer
                  type={SelectionTypes.Date}
                  min={new Date().toISOString().slice(0,10)}
                  containerStyle={{
                    width: '118px',
                    height: '26px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '11px'
                  }}
                  onBlur={shipDate =>
                    setShipDate(shipDate)
                  }
                  value={shipDate}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
      </IonCard>
    );
  };

  const ShippingRows = () => {
    return ( quoteLineItems?.length ?
      <>{quoteLineItems?.map((quoteLineItem, index) => {
        return <ShippingRow quoteLineItem={quoteLineItem} quoteLineItemIndex={index} key={index}/>;
      })}
      </> :
      null
    );
  };

  // Shipping Section
  const Shipping = () => {
    const tableHeadersShippingTable = [
      { display: OrderEntryColumnHeadings.Line, colSize: '1' },
      { display: OrderEntryColumnHeadings.Quantity, colSize: '1' },
      { display: OrderEntryColumnHeadings.Description, colSize: '3' },
      { display: OrderEntryColumnHeadings.ShipDate }
    ];

    return (
      <>
        <div ref={shippingRef} />
        <IonGrid>
          <IonRow>
            <IonCol>
              <CheckboxItem
                label='OK To Ship Early'
                style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto' }}
                onIonChange={checked => {
                  quoteFieldsToUpdate.okToShipEarly = checked;
                }}
                checked={quoteFieldsToUpdate.okToShipEarly}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CheckboxItem
                label='Partial Shipments Allowed'
                style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto' }}
                onIonChange={checked => {
                  quoteFieldsToUpdate.partialShipmentsAllowed = checked;
                }}
                checked={quoteFieldsToUpdate.partialShipmentsAllowed}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class='shipping-container block'>
              <TableHeaders labels={tableHeadersShippingTable}/>
              <ShippingRows/>
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  };

  const ExceptionRow = (props: {
    listOfBrokenRules: ExceptionRowProps,
    key: number
  }) => {

    return (
      <IonCard className='order-entry-card'>
        <IonCardHeader class='order-entry-card-header'>
          <IonGrid>
            <IonRow class="order-entry-row">
              <IonCol size="1">
                <IonLabel class='order-entry-label shipping'>{props.listOfBrokenRules.line}</IonLabel>
              </IonCol>
              <IonCol size="6">
                <IonLabel class='order-entry-label shipping'>{props.listOfBrokenRules.rule}</IonLabel>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
      </IonCard>
    );
  };

  const ExceptionRows = () => {
    const brokenRules: ExceptionRowProps[] = [];
    if (quoteLineItems?.length) {
      quoteLineItems.forEach((quoteLineItem) => {
        const rules = runGuardRail(quoteLineItem, quoteLineItem.quoteLineItemDetail, displayUom, dataState.rawMaterials, quote?.Manufacturing_Site__c);
        rules.forEach((rule) => {
          brokenRules.push({
            line: quoteLineItem.Line__c,
            rule: rule
          });
        });
      });
    }

    return brokenRules?.length ? (
      <>
        {brokenRules.map((rule, index) => (
          <ExceptionRow key={index} listOfBrokenRules={rule} />
        ))}
      </>
    ) : null;

  };

  // Exceptions Section
  const Exceptions  = () => {
    const tableHeadersShippingTable = [
      { display: OrderEntryColumnHeadings.Line, colSize: '1' },
      { display: OrderEntryColumnHeadings.RulesBroken, colSize: '3' }
    ];

    return (
      <>
        <div ref={shippingRef} />
        <IonGrid>

          <IonRow>
            <IonCol class='shipping-container block'>
              <TableHeaders labels={tableHeadersShippingTable}/>
              <ExceptionRows/>
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  };

  // sections - directory of Section components to map over
  const sectionsMap: Map<OrderEntryHeaderTabs, () => JSX.Element> = new Map([
    [OrderEntryHeaderTabs.JobSpecifications, JobSpecifications],
    [OrderEntryHeaderTabs.ProcedureApprovals, ProcedureApprovals],
    [OrderEntryHeaderTabs.InspectionsAndWitnessing, InspectionsAndWitnessing],
    [OrderEntryHeaderTabs.OrderNotes, OrderNotes],
    [OrderEntryHeaderTabs.Shipping, Shipping],
    [OrderEntryHeaderTabs.Exceptions, Exceptions],
  ]);

  const JobWideData = () => {
    return (
      <>
        {Array.from(sectionsMap.keys()).map((section, idx) => (
          <MappedSection component={sectionsMap.get(section)} label={section} sectionRefMap={sectionRefMap} QALabel={'OrderEntryPopover'} index={idx} key={section} />
        ))}
      </>
    );
  };

  return (loadingComplete() ?
    <IonContent className='order-entry-page'>
      <div className={classNames({
          'max-width-container': true,
          'order-entry-wrapper': true,
        })}
      >
        <div className='floating-actions-container'>
          <FloatingAction icon={quoteTab} darkModeIcon={quoteTabDarkMode} hoveredIcon={quoteTabHovered} onClick={() => {
            try {
              if (hasError) {
                toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
              } else {
                saveOrderEntry(false);
                navigateToRoute(PageRoutes.QuoteDetail, history, quote.Id);
              }
            } catch (error) {
              console.log(error);
              return;
            }
          }} action='quote'
          />
          <FloatingAction icon={advSpecsTab} darkModeIcon={advSpecsTabDarkMode} hoveredIcon={advSpecsTabHovered} onClick={() => {
            try {
              if (hasError) {
                toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
              } else {
                saveOrderEntry(false);
                navigateToRoute(PageRoutes.AdvancedSpecifications, history, quote.Id);
              }
            } catch (error) {
              console.log(error);
              return;
            }
          }} action='adv specs'
          />
          <FloatingAction icon={MSTOTab} darkModeIcon={MSTOTabDarkMode} hoveredIcon={MSTOTabHovered} onClick={() => {
            try {
              if (hasError) {
                toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
              } else {
                saveOrderEntry(false);
                navigateToRoute(PageRoutes.MSTO, history, quote.Id);
              }
            } catch (error) {
              console.log(error);
              return;
            }
          }} action='msto'
          />
        </div>
        <div className='title-container'>
          <IonLabel class='title' data-testid={'QAOrderEntryHeader'}>
            <span className="quotes" onClick={() => navigateToRoute(PageRoutes.Quotes, history, quote.Id, routeChangeAlert)} data-testid="QAOrderEntryPageQuotesTitle">Quotes </span>
            /
            <span className='quote-number-title' onClick={() => {
              try {
                if (hasError) {
                  toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
                } else {
                  saveOrderEntry(false);
                  navigateToRoute(PageRoutes.QuoteDetail, history, quote.Id);
                }
              } catch (error) {
                console.log(error);
                return;
              }
            }} data-testid="QAOrderEntryPageQuoteNumberTitle"
            > {quote?.Name} </span>
            / Order Entry
          </IonLabel>
        </div>
        <hr />
        <div className='order-entry-selections'>
          <IonGrid>
            <JobWideData />
          </IonGrid>
        </div>
        <hr className='msto-header-rule' />
        <div className='cta-button-container'>
          <SaveCancelButtons />
        </div>
      </div>
    </IonContent> :
    <div className="order-entry-loading-container">
      <Loading data-testId="'QAOrderEntryLoading'"/>
    </div>
  );
};

export default OrderEntryPage;
