import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';
import AppTextarea from '@components/app-textarea/app-textarea';
import React from 'react';
import { FormKeys } from '../advance-specification-form-util';
import LineItem from '../line-item/line-item';
import Section from './section';

const ExceptionClarification = (prop) => {
  const {
    form,
    updateForm,
    segmentKey,
  } = prop;

  return <Section title="Exceptions & Clarifications" {...prop}>
    <LineItem
      label="Exceptions and Clarifications to Specifications • INCLUDED ON QUOTE OUTPUT"
      specifyKey={`${segmentKey}.exceptions-clarification`}
    >
      <AppLineItemColumns components={[
        {
          key: 'exceptions-clarification-input',
          component: <AppTextarea
            onChange={(value) => updateForm(FormKeys.Exceptions_and_Clarifications, value)}
            value={form[FormKeys.Exceptions_and_Clarifications]}
            rows={5}
            placeholder='Comments...'
          />
        }
      ]}/>
    </LineItem>
  </Section>
}

export default ExceptionClarification;