import { getRawMaterials } from './raw-materials-api';
import * as React from 'react';
import { RawMaterial } from '@interfaces/raw-material';

const { createContext, useContext } = React;

const RawMaterialsContext = createContext(null);

export const RawMaterialsProvider = (props) => {
  const value = {
    getAllRawMaterials: props.getAllRawMaterials || getAllRawMaterials,
  };

  return (
    <RawMaterialsContext.Provider value={value}>
      {props.children}
    </RawMaterialsContext.Provider>
  );
};

export const useRawMaterials = () => {
  return useContext(RawMaterialsContext);
};

// Calls api to get all the raw materials from cosmos
export const getAllRawMaterials = async(): Promise<RawMaterial[]> => {
  try {
    return await getRawMaterials();
  } catch (error) {
    console.error(error);
    return null;
  }
};
