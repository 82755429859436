import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

import { FormKeyResolveHandler } from '@pages/new-advanced-specifications/form-utils/form-key-handler';
import { toDotNotation } from '@pages/new-assembly-groups/assembly-group-form-util';
import _ from 'lodash-es';

interface Props {
  children: ReactNode;
}

interface IContextProps {
  quote: any;
  setQuote: Dispatch<SetStateAction<any[]>>;
  quoteLineItems: any[];
  setQuoteLineItems: Dispatch<SetStateAction<any[]>>;
  onUpdateQuoteLineItemById: (lineItemId: string, payload: any) => void;
  onUpdateQuoteLineItemsByArray: (payloads: any) => void;
  onResolveQuoteLineItems: () => any[];
}

// Context
const QuoteFormContext = createContext({} as IContextProps);

// Provider
export const QuoteFormProvider = ({ children }: Props): JSX.Element => {
  const [quote, setQuote] = useState({});

  const [quoteLineItems, setQuoteLineItems] = useState([]);

  const createOrUpdateLineItem = (lineItemId: string, payload: any) => {
    const quoteLineItem = quoteLineItems.find((qli) => (qli.Id || qli.displayId) === lineItemId);
    const idAttr = lineItemId.length > 3 ? 'Id' : 'displayId';
    const newItem = quoteLineItem
      ? { ...quoteLineItem }
      : {
          [idAttr]: lineItemId,
        };

    Object.keys(payload).forEach((key) => {
      const value = payload[key];
      _.set(newItem, key, value);
    });

    return newItem;
  };

  /**
   * update quote line item by id or displayId
   */
  const onUpdateQuoteLineItemById = (lineItemId: string, payload: any) => {
    setQuoteLineItems((previousQli) => {
      const newItem = createOrUpdateLineItem(lineItemId, payload);
      const filteredQuoteLineItems = previousQli.filter((qli) => (qli.Id || qli.displayId) !== lineItemId) || [];

      // mutate line items
      return [...filteredQuoteLineItems, newItem];
    });
  };

  /**
   * update quote line item by array
   */
  const onUpdateQuoteLineItemsByArray = (payloads: any[]) => {
    setQuoteLineItems((previousQli) => {
      const newItems = payloads.map((payload) => {
        return createOrUpdateLineItem(payload.Id || payload.displayId, payload);
      });

      const filteredQuoteLineItems =
        previousQli.filter((qli) => {
          return !newItems.find((newItem) => (newItem.Id || newItem.displayId) === (qli.Id || qli.displayId));
        }) || [];

      return [...filteredQuoteLineItems, ...newItems];
    });
  };

  /**
   * finalize quote line items, resolve all data
   * @returns DotNotation QuoteLineItems[]
   */
  const onResolveQuoteLineItems = () => {
    const mappedFormQuoteLineItems = quoteLineItems.map((qli) => {
      const _qli: any = toDotNotation(qli);

      const resolved = Object.keys(FormKeyResolveHandler).reduce((prev, key) => {
        const handler = FormKeyResolveHandler[key];
        const value = _.get(_qli, key);

        if (!handler) {
          return prev;
        }

        const newValues = handler(value, _qli);

        return {
          ...prev,
          ...newValues,
        };
      }, {});

      return {
        ..._qli,
        ...resolved,
      };
    });

    return mappedFormQuoteLineItems;
  };

  // context value object
  const value: IContextProps = useMemo(() => {
    return {
      quote,
      setQuote,
      quoteLineItems,
      setQuoteLineItems,
      onUpdateQuoteLineItemById,
      onUpdateQuoteLineItemsByArray,
      onResolveQuoteLineItems,
    };
  }, [
    quote,
    setQuote,
    quoteLineItems,
    setQuoteLineItems,
    onUpdateQuoteLineItemById,
    onUpdateQuoteLineItemsByArray,
    onResolveQuoteLineItems,
  ]);

  return <QuoteFormContext.Provider value={value}>{children}</QuoteFormContext.Provider>;
};

// useQuoteFormContext hook
// only use state when saving or doing form
export const useQuoteFormContext = (): IContextProps => {
  const context: IContextProps = useContext(QuoteFormContext);
  if (context === null || context === undefined) {
    throw new Error('You probably forgot the <QuoteFormProvider> context provider');
  }
  return context;
};
