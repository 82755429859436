import App from './app/app';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { NavigationProvider } from '@services/navigation-service';
import { AuthProvider } from '@services/auth-service';
import { StorageProvider } from '@services/storage-service';
import { SalesforceProvider } from '@services/salesforce/salesforce-service';
import { UserProvider } from '@services/user/user-service';
import { CurrencyConversionProvider } from '@services/currency-conversion-service';
import './app/app.polyfills';
import { CurrencyConversionsProvider } from '@services/currency-conversions/currency-conversions-service';
import { PlatformProvider } from '@services/platform-service';
import { LastViewedProvider } from '@services/last-viewed/last-viewed-service';
import { NotificationsProvider } from '@services/notifications/notifications-service';
import { DocumentsProvider } from '@services/documents/documents-service';
import { EnvironmentProvider } from '@services/environment-service';
import { RawMaterialsProvider } from '@services/raw-materials/raw-materials-service';
import { FinishedGoodsProvider } from '@services/finished-goods/finished-goods-service';
import { FreightProvider } from '@services/freight/freight-service';
import { ContentProvider } from '@services/content/content-service';
import { EdgeAllowancesProvider } from '@services/edge-allowances/edge-allowances-service';
import { SpecificationTestOptionsProvider } from '@services/specification-test-options/specification-test-options-service';
import { BoosterAllowancesProvider } from '@services/booster-allowances/booster-allowances-service';
import { ToastProvider } from '@services/toast-service';
import { SelectionsProvider } from '@services/selections/selections-service';
import { RawMaterialCombinationsProvider } from '@services/raw-material-combinations/raw-material-combinations-service';
import { QuoteVariableConfigurationsProvider } from '@services/quote-variable-configurations/quote-variable-configurations-service';
import { VendorsProvider } from '@services/vendors/vendors-service';
import { WeldingPatternsProvider } from '@services/welding-patterns/welding-patterns-service';
import { QuoteProvider } from '@context/quote-context';
import { DataProvider } from '@context/data-context';
import { AdvSpecStateProvider } from '@context/adv-spec-context';
import { QuotesProvider } from '@services/quotes/quotes-service';
import { MeasurementSystemProvider } from '@context/measurement-system';
import { ComboGroupProvider } from '@context/combo-group-context';
import { SelectionsProvider as NewSelectionsProvider } from '@context/selection-context';
import { SpecificationTestOptionProvider } from '@context/specification-test-options-context';
import { RawMaterialsProvider as NewRawMaterialsProvider } from '@context/raw-materials-context';
import { VendorsProvider as NewVendorsProvider } from '@context/vendors-context';
import { QuoteFormProvider } from '@context/quote-form-context';
import { FinishedGoodsProvider as NewFinishedGoodsProvider } from '@context/finished-goods';
import { OutsideServiceCostsProvider } from '@services/outside-service-costs/outside-service-costs-service';
import { UomProvider } from '@context/useDisplayUom';
import { UomWithInitProvider } from '@context/useDisplayUomWithInit';
import { SyncProvider } from '@context/useSync';

ReactDOM.render(
  <NavigationProvider>
    <AuthProvider>
      <StorageProvider>
        <SalesforceProvider>
          <UserProvider>
            <CurrencyConversionProvider>
              <CurrencyConversionsProvider>
                <PlatformProvider>
                  <LastViewedProvider>
                    <NotificationsProvider>
                      <DocumentsProvider>
                        <EnvironmentProvider>
                          <RawMaterialsProvider>
                            <FinishedGoodsProvider>
                              <FreightProvider>
                                <EdgeAllowancesProvider>
                                  <SpecificationTestOptionsProvider>
                                    <BoosterAllowancesProvider>
                                      <ToastProvider>
                                        <ContentProvider>
                                          <SelectionsProvider>
                                            <QuoteVariableConfigurationsProvider>
                                              <RawMaterialCombinationsProvider>
                                                <VendorsProvider>
                                                  <WeldingPatternsProvider>
                                                    <OutsideServiceCostsProvider>
                                                      <DataProvider>
                                                        <NewSelectionsProvider>
                                                          <SpecificationTestOptionProvider>
                                                            <NewRawMaterialsProvider>
                                                              <NewVendorsProvider>
                                                                <NewFinishedGoodsProvider>
                                                                  <SyncProvider>
                                                                    <ComboGroupProvider>
                                                                      <QuoteFormProvider>
                                                                        <UomProvider>
                                                                          <QuotesProvider>
                                                                            <QuoteProvider>
                                                                              <UomWithInitProvider>
                                                                                <MeasurementSystemProvider>
                                                                                  <AdvSpecStateProvider>
                                                                                    <Router>
                                                                                      <Route
                                                                                        path='/'
                                                                                        component={App}
                                                                                      />
                                                                                    </Router>
                                                                                  </AdvSpecStateProvider>
                                                                                </MeasurementSystemProvider>
                                                                              </UomWithInitProvider>
                                                                            </QuoteProvider>
                                                                          </QuotesProvider>
                                                                        </UomProvider>
                                                                      </QuoteFormProvider>
                                                                    </ComboGroupProvider>
                                                                  </SyncProvider>{' '}
                                                                </NewFinishedGoodsProvider>
                                                              </NewVendorsProvider>
                                                            </NewRawMaterialsProvider>
                                                          </SpecificationTestOptionProvider>
                                                        </NewSelectionsProvider>
                                                      </DataProvider>
                                                    </OutsideServiceCostsProvider>
                                                  </WeldingPatternsProvider>
                                                </VendorsProvider>
                                              </RawMaterialCombinationsProvider>
                                            </QuoteVariableConfigurationsProvider>
                                          </SelectionsProvider>
                                        </ContentProvider>
                                      </ToastProvider>
                                    </BoosterAllowancesProvider>
                                  </SpecificationTestOptionsProvider>
                                </EdgeAllowancesProvider>
                              </FreightProvider>
                            </FinishedGoodsProvider>
                          </RawMaterialsProvider>
                        </EnvironmentProvider>
                      </DocumentsProvider>
                    </NotificationsProvider>
                  </LastViewedProvider>
                </PlatformProvider>
              </CurrencyConversionsProvider>
            </CurrencyConversionProvider>
          </UserProvider>
        </SalesforceProvider>
      </StorageProvider>
    </AuthProvider>
  </NavigationProvider>,
  document.getElementById('root')
);
