import { MeasurementSystems, QuoteItemSelectionTabs } from '@constants';
import { useQuoteDispatch } from '@context/quote-context';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonText } from '@ionic/react';
import { generateUUID } from '@shared/utils';
import * as React from 'react';
import { useMemo } from 'react';
import {
  getBaseCost,
  getBaseFreightCost,
  getBaseLayerCost,
  getBaseWeightPerCost,
  getCladCost,
  getCladFreightCost,
  getCladLayerCost,
  getCladWeightPerCost,
  getLineItemFieldBaseLength,
  getLineItemFieldBaseTK,
  getLineItemFieldBaseWidth,
  getLineItemFieldCladLength,
  getLineItemFieldCladTK,
  getLineItemFieldCladWidth,
  getLineItemFieldLong,
  getLineItemFieldWide,
  getTotalFreightPerWeightCost,
  getWeldingCost
} from './ms-ui-helpers';
import './multi-shot-details-group.scss';
import { MultiShotVirtualGroup } from './multi-shot-utils';

interface MultiShotDetailsLineGroupProps {
  quoteLineItem: QuoteLineItem;
  quoteLineItems: QuoteLineItem[];
  uom: MeasurementSystems;
  activeTab: QuoteItemSelectionTabs;
  // isDoubleSidedClad?: boolean;
  runFrankie?: boolean;
}

type HeaderOption = {
  field: string;
  label: string;
  size: number;
};

const Label = ({ label }: { label: string | number }): React.ReactElement | JSX.Element => {
  const labelStyle = {
    fontSize: '12px',
  };
  return (
    <IonItem>
      <IonLabel style={labelStyle}>
        <IonText>{label}</IonText>
      </IonLabel>
    </IonItem>
  );
};

interface TableHeaderProps {
  headerOptions: HeaderOption[];
  // headerOptionsSizing: HeaderOption[];
  // headerStyle: React.CSSProperties;
  // costBackgroundColor: React.CSSProperties;
  // sizingBackgroundColor: React.CSSProperties;
}

const TableHeader = ({ headerOptions }: TableHeaderProps) => {
  const colStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    paddingLeft: '10px',
  };

  return (
    <IonRow class='ms-header'>
      {headerOptions.map((option) => (
        <IonCol
          key={option.field}
          size={option.size.toString()}
          class='ms-header-text'
          style={{ ...colStyle }}
          // style={{ ...(i > headerOptionsSizing.length ? costBackgroundColor : sizingBackgroundColor), ...colStyle }}
        >
          {option.label}
        </IonCol>
      ))}
    </IonRow>
  );
};

const TableRow = ({
  headerOptions,
  rowData,
  headerOptionsSizing,
  costBackgroundColor,
  sizingBackgroundColor,
}: {
  headerOptions: HeaderOption[];
  rowData: Record<string, any>;
  headerOptionsSizing: HeaderOption[];
  costBackgroundColor: React.CSSProperties;
  sizingBackgroundColor: React.CSSProperties;
}) => {
  const colStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <IonRow>
      {headerOptions.map((option, i) => {
        const label = rowData[option.field];
        const isSimpleLabel = typeof label === 'string' || typeof label === 'number';

        return (
          <IonCol
            key={option.field}
            size={option.size.toString()}
            style={{ ...(i > headerOptionsSizing.length ? costBackgroundColor : sizingBackgroundColor), ...colStyle }}
          >
            {isSimpleLabel ? <Label label={label} /> : label}
          </IonCol>
        );
      })}
    </IonRow>
  );
};

const MultiShotDetailsLineGroup = ({
  quoteLineItem,
  quoteLineItems,
  uom,
  activeTab,
  runFrankie = true,
}: MultiShotDetailsLineGroupProps): React.ReactElement | JSX.Element => {
  const isImperial = uom === MeasurementSystems.Imperial;

  const { dispatchQuoteState } = useQuoteDispatch();

  const vGroup = useMemo(() => {
    return quoteLineItem.quoteLineItemDetail.virtualDetailGroup ?? ({} as MultiShotVirtualGroup);
  }, [quoteLineItem.quoteLineItemDetail.virtualDetailGroup]);

  const virtualItemsForMSdetails = useMemo(() => {
    return (
      vGroup?.virtualShotGroups?.map((vGroup) => {
        return quoteLineItems?.find((qli) => qli.UUID__c === vGroup.virtualLineUUID);
      }) ?? []
    );
  }, [vGroup, quoteLineItems]);

  const isDoubleSidedClad = quoteLineItem.isDoubleSidedClad ?? false;

  // console.group('MultiShotDetailsLineGroup');
  // console.log('isDoubleSidedClad: ', isDoubleSidedClad);
  // console.log('quoteLineItem: ', quoteLineItem.Name);
  console.log('virtualItems for MS details: ', virtualItemsForMSdetails);
  // console.groupEnd();

  const useCostHeaderOptions = activeTab === QuoteItemSelectionTabs.Cost; // TODO: exclude OS Cost
  const sizingBackgroundColor = { background: 'rgba(58, 156, 153, 0.2)' };
  const costBackgroundColor = { background: 'rgba(58, 156, 153, .5)' };

  const gridStyle = { width: useCostHeaderOptions ? '95%' : '65%' };

  const weightAbbreviation = isImperial ? 'LB' : 'KG';

  const headerOptionsSizing = [
    { label: 'Layer', field: 'layer', size: useCostHeaderOptions ? 1 : 2 },
    { label: 'Metal', field: 'metal', size: useCostHeaderOptions ? 1.5 : 2 },
    { label: 'Clads', field: 'clads', size: useCostHeaderOptions ? 0.5 : 0.75 },
    { label: 'Qty', field: 'qty', size: useCostHeaderOptions ? 0.5 : 0.75 },
    { label: 'W', field: 'wide', size: useCostHeaderOptions ? 0.5 : 0.75 },
    { label: 'L', field: 'long', size: useCostHeaderOptions ? 0.5 : 0.75 },
    { label: 'Width', field: 'width', size: useCostHeaderOptions ? 0.75 : 1.5 },
    { label: 'Length', field: 'length', size: useCostHeaderOptions ? 0.75 : 1.5 },
    { label: 'Thickness', field: 'thickness', size: useCostHeaderOptions ? 0.75 : 2 },
  ];

  const headerOptionsCost = [
    { label: `${weightAbbreviation}/PC`, field: 'weight', size: 0.833 },
    { label: `Cost/${weightAbbreviation}`, field: 'cost', size: 0.833 },
    { label: 'Layer Cost', field: 'layerCost', size: 1.085 },
    { label: `Frt/${weightAbbreviation}`, field: 'freight', size: 0.833 },
    { label: 'Total Frt', field: 'totalFreight', size: 0.833 },
    { label: 'Welding', field: 'welding', size: 0.833 },
  ];

  const headerOptions = useCostHeaderOptions ? [...headerOptionsSizing, ...headerOptionsCost] : headerOptionsSizing;

  const tableValuesSizing = useMemo(() => {
    return {
      // grab the ui <LineItemField/> components for the Base
      base: {
        layer: 'Base',
        metal: quoteLineItem.BaseMetal__c,
        clads: quoteLineItem.quoteLineItemDetail.Clads__c,
        qty: quoteLineItem.quoteLineItemDetail.Quantity__c,
        wide: getLineItemFieldWide(quoteLineItem),
        long: getLineItemFieldLong(quoteLineItem),
        width: getLineItemFieldBaseWidth(uom, quoteLineItem, dispatchQuoteState, runFrankie),
        length: getLineItemFieldBaseLength(uom, quoteLineItem, dispatchQuoteState, runFrankie),
        thickness: getLineItemFieldBaseTK(uom, quoteLineItem, dispatchQuoteState, runFrankie),
      },
      // grab the ui <LineItemField/> components for the Clad
      clad: {
        layer: 'Shot 1',
        metal: quoteLineItem.CladMetal__c,
        clads: quoteLineItem.quoteLineItemDetail.Clads__c,
        qty: quoteLineItem.quoteLineItemDetail.Quantity__c,
        wide: getLineItemFieldWide(quoteLineItem),
        long: getLineItemFieldLong(quoteLineItem),
        width: getLineItemFieldCladWidth(uom, quoteLineItem, dispatchQuoteState, runFrankie),
        length: getLineItemFieldCladLength(uom, quoteLineItem, dispatchQuoteState, runFrankie),
        thickness: getLineItemFieldCladTK(uom, quoteLineItem, dispatchQuoteState, runFrankie),
      },
      interlayers: virtualItemsForMSdetails.map((vItem, index) => {
        const { quoteLineItemDetail: vDetail } = vItem;

        return {
          // grab the ui <LineItemField/> components for the interlayers
          layer: `Shot ${index + 2}`,
          metal: vItem.CladMetal__c,
          clads: vDetail.Clads__c,
          qty: vItem.Quantity__c,
          wide: getLineItemFieldWide(vItem),
          long: getLineItemFieldLong(vItem),
          width: getLineItemFieldCladWidth(uom, vItem, dispatchQuoteState, runFrankie),
          length: getLineItemFieldCladLength(uom, vItem, dispatchQuoteState, runFrankie),
          thickness: getLineItemFieldCladTK(uom, vItem, dispatchQuoteState, runFrankie),
          key: generateUUID(),
        };
      }),
    };
  }, [dispatchQuoteState, quoteLineItem, runFrankie, uom, virtualItemsForMSdetails]);

  // get the cost values for the base and clad layers
  // const baseLayerCost =
  //   (isImperial ? quoteLineItem.Base_LB_PC__c : quoteLineItem.Base_KG_PC__c) *
  //   (quoteLineItem.Customer_Provided_Base__c
  //     ? quoteLineItem.Base_Cost_Customer_Supplied__c
  //     : quoteLineItem.Base_Cost__c);

  // const cladLayerCost =
  //   (isImperial ? quoteLineItem.Clad_LB_PC__c : quoteLineItem.Clad_KG_PC__c) *
  //   (quoteLineItem.Customer_Provided_Clad__c
  //     ? quoteLineItem.Clad_Cost_Customer_Supplied__c
  //     : quoteLineItem.Clad_Cost__c);

  console.log('quoteLineItem: ', quoteLineItem);
  const tableValuesCost = useMemo(() => {
    return {
      // grab the ui <LineItemField/> cost components for the base
      base: {
        weight: getBaseWeightPerCost(quoteLineItem, uom),
        cost: getBaseCost(quoteLineItem, activeTab),
        // layerCost: formatCostValue(baseLayerCost, isImperial),
        layerCost: getBaseLayerCost(quoteLineItem, activeTab), //TODO: find me
        freight: getBaseFreightCost(quoteLineItem, activeTab),
        totalFreight: getTotalFreightPerWeightCost(quoteLineItem, uom, activeTab),
        welding: getWeldingCost(quoteLineItem, activeTab),
      },
      clad: {
        // grab the ui <LineItemField/> components for the clad
        weight: getCladWeightPerCost(quoteLineItem, uom),
        cost: getCladCost(quoteLineItem, activeTab),
        layerCost: getCladLayerCost(quoteLineItem, activeTab), //TODO: kf done mar 19
        freight: getCladFreightCost(quoteLineItem, activeTab),
        totalFreight: getTotalFreightPerWeightCost(quoteLineItem, uom, activeTab),
        welding: getWeldingCost(quoteLineItem, activeTab),
      },
      interlayers: virtualItemsForMSdetails.map((vItem) => {
        // grab the ui <LineItemField/> cost components for the interlayers
        const Weight = getCladWeightPerCost(vItem, uom);
        const Cost = getCladCost(vItem, activeTab);
        const Freight = getCladFreightCost(vItem, activeTab);
        const TotalFreight = getTotalFreightPerWeightCost(vItem, uom, activeTab);
        const Welding = getWeldingCost(vItem, activeTab);
        const LayerCost = getCladLayerCost(vItem, activeTab); // TODO: find me

        // const cladLayerCost =
        // (isImperial ? vItem.Clad_LB_PC__c : vItem.Clad_KG_PC__c) *
        // (vItem.Customer_Provided_Clad__c ? vItem.Clad_Cost_Customer_Supplied__c : vItem.Clad_Cost__c);
        
        return {
          weight: Weight,
          cost: Cost,
          // layerCost: formatCostValue(cladLayerCost, isImperial),
          layerCost: LayerCost,
          freight: Freight,
          totalFreight: TotalFreight,
          welding: Welding,
        };
      }),
    };
  }, [quoteLineItem, uom, activeTab, virtualItemsForMSdetails]);

  // the table values are the combination of the sizing and cost values, if the cost header options are being used
  const tableValues = useCostHeaderOptions
    ? {
        ...tableValuesSizing,
        base: {
          ...tableValuesSizing.base,
          ...tableValuesCost.base,
        },
        clad: {
          ...tableValuesSizing.clad,
          ...tableValuesCost.clad,
        },
        interlayers: tableValuesSizing.interlayers.map((interlayer, index) => {
          return {
            ...interlayer,
            ...tableValuesCost.interlayers[index],
          };
        }),
      }
    : tableValuesSizing;

  const reversedInterlayers = [...tableValues.interlayers].reverse();

  return (
    <>
      <IonGrid class='ms-table-grid' style={gridStyle}>
        <TableHeader
          // Header
          headerOptions={headerOptions}
          // headerOptionsSizing={headerOptionsSizing}
          // headerStyle={headerStyle}
          // costBackgroundColor={costBackgroundColor}
          // sizingBackgroundColor={sizingBackgroundColor}
        />
        {isDoubleSidedClad ? (
          <>
            {reversedInterlayers.slice(0, Math.ceil(reversedInterlayers.length / 2)).map((interlayer) => (
              <TableRow
                // Interlayers
                key={interlayer.key}
                headerOptions={headerOptions}
                rowData={interlayer}
                headerOptionsSizing={headerOptionsSizing}
                costBackgroundColor={costBackgroundColor}
                sizingBackgroundColor={sizingBackgroundColor}
              />
            ))}
            <TableRow
              // Base
              headerOptions={headerOptions}
              rowData={tableValues.base}
              headerOptionsSizing={headerOptionsSizing}
              costBackgroundColor={costBackgroundColor}
              sizingBackgroundColor={sizingBackgroundColor}
            />
            <TableRow
              // Clad
              headerOptions={headerOptions}
              rowData={tableValues.clad}
              headerOptionsSizing={headerOptionsSizing}
              costBackgroundColor={costBackgroundColor}
              sizingBackgroundColor={sizingBackgroundColor}
            />
            {reversedInterlayers.slice(Math.ceil(reversedInterlayers.length / 2)).map((interlayer) => (
              <TableRow
                // Interlayers
                key={interlayer.key}
                headerOptions={headerOptions}
                rowData={interlayer}
                headerOptionsSizing={headerOptionsSizing}
                costBackgroundColor={costBackgroundColor}
                sizingBackgroundColor={sizingBackgroundColor}
              />
            ))}
          </>
        ) : (
          <>
            {reversedInterlayers.map((interlayer) => (
              <TableRow
                //  Interlayers
                key={interlayer.key}
                headerOptions={headerOptions}
                rowData={interlayer}
                headerOptionsSizing={headerOptionsSizing}
                costBackgroundColor={costBackgroundColor}
                sizingBackgroundColor={sizingBackgroundColor}
              />
            ))}
            <TableRow
              // Clad
              headerOptions={headerOptions}
              rowData={tableValues.clad}
              headerOptionsSizing={headerOptionsSizing}
              costBackgroundColor={costBackgroundColor}
              sizingBackgroundColor={sizingBackgroundColor}
            />
            <TableRow
              // Base
              headerOptions={headerOptions}
              rowData={tableValues.base}
              headerOptionsSizing={headerOptionsSizing}
              costBackgroundColor={costBackgroundColor}
              sizingBackgroundColor={sizingBackgroundColor}
            />
          </>
        )}
      </IonGrid>
    </>
  );
};

export default MultiShotDetailsLineGroup;
