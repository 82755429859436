import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import simpleCacheStorage from '@services/app/simple-cache-storage';
import { Vendor } from '@interfaces/vendor';
import { getVendors } from '@services/vendors/vendors-api';

const KEY = 'NC_VENDORS';

interface Props {
  children: ReactNode;
}

interface IContextProps {
  vendors: Vendor[];
  setVendors: Dispatch<SetStateAction<Vendor[]>>;
  getVendors: () => Promise<boolean>;
}

// Context
const VendorsContext = createContext({} as IContextProps);

// Provider
export const VendorsProvider = ({ children }: Props): JSX.Element => {
  const [
    vendors,
    setVendors
  ] = useState(simpleCacheStorage.getItem(KEY) || []);

  const onUpdate = (items) => {
    simpleCacheStorage.setItem(KEY, items, 60);
    setVendors(items);
  };

  // dont return the values and instead use state
  const onGet = () => {
    if (vendors.length >= 1) {
      return Promise.resolve(true);
    }

    return getVendors()
      .then((result) => {
        onUpdate(result);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  }

  // context value object
  const value: IContextProps = useMemo(() => {
    return {
      vendors,
      setVendors: onUpdate,
      getVendors: onGet,
    };
  }, [vendors, vendors, onUpdate, simpleCacheStorage, KEY]);

  return (
    <VendorsContext.Provider value={value}>{children}</VendorsContext.Provider>
  );
};

// useVendors hook
export const useVendors = (): IContextProps => {
  const context: IContextProps = useContext(VendorsContext);
  if (context === null || context === undefined) {
    throw new Error(
      'You probably forgot the <VendorsProvider> context provider'
    );
  }
  return context;
};