import { IonIcon, IonPopover } from '@ionic/react';
import * as React from 'react';
import kebab from '@assets/icon/kebab_inactive.svg';
import './line-item-actions.scss';
import ActionsPopover from '@components/popover/actions-popover/actions-popover';

const LineItemActions = (props) => {
  const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

  return (props.visible && props.actions.length ?
    <div className='actions-container'>
      <IonPopover
        cssClass='actions-popover'
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        animated={ false }
        showBackdrop={ false }
        onDidDismiss={() => {
          setShowPopover({ showPopover: false, event: undefined });
        }}
      >
        <ActionsPopover setShowPopover={setShowPopover} actions={props.actions}/>
      </IonPopover>
      <button className="trigger" onClick={(e: any) => {
        e.stopPropagation();
        e.persist();
        setShowPopover({ showPopover: true, event: e });
      }} data-testid={props.dataTestId ?? 'QALineItemActionsMenu'}
      >
        <IonIcon src={kebab} class="kebab"></IonIcon>
      </button>
    </div> : null
  );
};

export default LineItemActions;
