import AutoComplete from '@components/auto-complete/auto-complete';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
import Dropdown from '@components/dropdown/dropdown';
import { SelectionLabelPositions, SelectionTypes } from '@constants';
import { useDarkMode } from '@hooks/useDarkMode';
import { IonInput, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, IonTextarea } from '@ionic/react';
import classNames from 'classnames';
import React, { useRef, useEffect } from 'react';
import Chips from 'react-chips';
import './selection-container.scss';

const SelectionContainer = (props) => {
  const { darkMode } = useDarkMode();
  const ref = useRef(null);
  const chipsRef: React.MutableRefObject<Chips> = useRef();

  // watch for input type number
  // some browser doesn't support wheel attributes, and we also need to
  // pass an option to set passive as false to enable calling preventDefault()
  useEffect(() => {
    if (props.inputType !== 'number' || props.type !== SelectionTypes.TextInput) {
      return;
    }

    // get the element from reference
    const element = ref.current;
    const events = [
      'wheel',
      'mousewheel',
      'DOMMouseScroll'
    ];

    // add event listener for each event listed above
    events.forEach(event => {
      // remove existing event listener
      element.removeEventListener(event, handleWheel);

      // add event listener with option passive set to false to enable preventDefault()
      element.addEventListener(event, handleWheel, { passive: false });
    });

  }, [props.type, props.inputType]);

  const handleWheel = (event) => {
    event.preventDefault();
  };

  const dropdownStyle = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? (darkMode ? '#8b8d8e' : '#ececec')
        : (darkMode ? '#1A1818' : '#ffffff'),
      color: darkMode ? '#ffffff' : '#1A1818',
      ':active': {
        ...provided[':active'],
        backgroundColor: (darkMode ? '#8b8d8e': '#ececec')
      },
      cursor: 'pointer'
    }),
    control: (provided) => ({
      ...provided,
      height: 26,
      flexWrap: 'unset',
      boxShadow: 'unset',
      borderColor: '#c9cac8 !important',
      borderRadius: '0px',
      backgroundColor: props.errorExists ? '#ffc5ba' : (props.isDisabled ? (darkMode ? '#444444' : '#f2f2f2') : (darkMode ? '#1A1818' : '#ffffff')),
      cursor: 'pointer',
      marginTop: props.style.marginTop || '10px',
      marginBottom: props.style.marginBottom || '0px',
      width: props.style.width,
      marginRight: props.style.marginRight,
      marginLeft: props.style.marginLeft
    }),
    placeholder: (provided) => ({
      ...provided,
      color: props.errorExists ? '#e84c3d' : (props.isDisabled ? '#1A1818' : (darkMode ? '#ececec' : '#8b8d8e')),
      opacity: 0.3,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0px',
      marginTop: '0px',
      '> div': {
        paddingTop: 'unset',
        paddingBottom: 'unset',
      },
      boxShadow: darkMode ?
        'rgb(256 256 256 / 10%) 0px 0px 0px 1px, rgb(256 256 256 / 10%) 0px 4px 11px' :
        'rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px',
      width: props.style.width,
      zIndex: 99
    }),
    singleValue: (provided) => ({
      ...provided,
      borderRadius: '0px',
      color: darkMode ? '#ffffff' : '#1A1818',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: props.style.menuListHeight,
      zIndex: 99
    }),
  };

  const chipsContainerStyle = {
    chipsContainer: {
      display: 'flex',
      position: 'relative',
      border: '1px solid var(--nc-cool-gray)',
      backgroundColor: 'var(--nc-white)',
      font: '13.33333px Arial',
      minHeight: 24,
      flexWrap: 'wrap',
      padding: '2.5px',
      borderRadius: 0,
      width: props.style?.width,
      height: props.style?.height,
      alignContent: 'flex-start',
    },
    container:{
      flex: 1,
    },
    input: {
      border: 'none',
      outline: 'none',
      boxSizing: 'border-box',
      padding: 7,
      margin: 2.5,
      fontFamily: 'Maison Neue Light',
      fontSize: '9px',
      backgroundColor: 'var(--nc-white)',
      marginBottom: 'unset',
      marginTop: 'unset',
      marginLeft: '2px',
      minWidth: '172px'
    }
  };

  const chipStyle = {
    chip: {
      padding: 4,
      background: 'var(--nc-white)',
      margin: '2.5px unset 2.5px 2.5px',
      borderRadius: '50px',
      cursor: 'default',
      color: 'var(--nc-gray)',
      border: 'solid 1px var(--nc-cool-gray)',
      fontSize: '9px',
      fontFamily: 'Maison Neue Light',
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'row-reverse',
      height: '20px'
    },
    chipSelected: {
      background: 'var(--nc-primary)',
    },
    chipRemove: {
      fontWeight: 'bold',
      cursor: 'pointer',
      ':hover': {
        color: 'red',
      },
      borderRadius: '50px',
      border: 'solid 1px var(--nc-cool-gray)',
      width: '11px',
      height: '11px',
      marginRight: '5px',
      paddingLeft: '2px'
    }
  };

  const SelectionLabel = () => {
    return (props.selectionLabel ?
      <IonLabel class={classNames({'selection-label selection-container-label': true, 'left': props.labelPosition === SelectionLabelPositions.Left})}>{props.selectionLabel}</IonLabel> : null);
  };


  switch(props.type) {
  case SelectionTypes.TextInput:
    return (
      <div style={props.containerStyle}>
        <SelectionLabel/>
        <div style={props.inputContainerStyle}>
          <IonInput
            className={classNames({
              'text-input': true,
              'error': props.errorExists
            })}
            ref={ref}
            type={props.inputType}
            placeholder={props.placeholder}
            style={props.style}
            min={props.min}
            max={props.max}
            maxlength={props.maxLength}
            step={props.step}
            onKeyPress={(e) => {
              if (props.regex && !props.regex.test((e.currentTarget.value || '') + e.key)) {
                e.preventDefault();
              }
            }}
            onBlur={(e) => props.onBlur(props.inputType === 'number' ? +e.target.value : e.target.value)}
            value={props.formatter ? props.formatter(props.value) : props.value}
            disabled={props.isDisabled}
            data-testid={'QASelectionContainerTextInput_' + props.selectionLabel}
          />
        </div>
      </div>
    );
  case SelectionTypes.RadioButtons:
    return (
      <div style={props.style}>
        <SelectionLabel/>
        <IonRadioGroup
          class='radio-group'
          style={props.groupStyle}
          onIonChange={(e) => props.onIonChange(e.detail.value)}
          value={props.value}
        >
          {props.options?.map(((option, index) => {
            return (
              <div className='radio-text-input' key={index}>
                <IonItem class='radio-button-item' lines="none" style={props.itemStyle} tabIndex={props.skipTab ? -1 : 0} >
                  <IonRadio slot='start' class='radio-button' value={option} disabled={props.isDisabled} data-testid={'QASelectionContainerRadioButton_' + option}/>
                  <IonLabel class='radio-button-label'>{option}</IonLabel>
                </IonItem>
                {props.extraComponents ? props.extraComponents[index] : null}
              </div>
            );
          }))}
        </IonRadioGroup>
      </div>
    );
  case SelectionTypes.DropDown:
    return (
      <div style={props.containerStyle}>
        <SelectionLabel/>
        <div className='dropdown'>
          <Dropdown
            errorExists={props.errorExists}
            options={props.options?.map(option => { return { label: option }; })}
            placeholder={props.placeholder}
            style={dropdownStyle}
            onChange={props.onChange}
            value={props.value ? { label: props.value } : undefined}
            isDisabled={props.isDisabled}
            defaultValue={props.defaultValue ? { label: props.defaultValue }: undefined}
          />
        </div>
      </div>
    );
  case SelectionTypes.SearchableDropdown:
    return (
      <div style={props.containerStyle}>
        <SelectionLabel/>
        <div className='searchable-dropdown'>
          <AutoComplete
            noneFoundText={props.noneFoundText}
            placeholder={props.placeholder}
            suggestions={props.options}
            suggestionDisplayFields={props.suggestionDisplayFields}
            location='auto'
            suggestionClickHandler={(searchInput) => props.onChange(searchInput)}
            input={props.value}
            isDisabled={props.isDisabled}
          />
        </div>
      </div>
    );
  case SelectionTypes.CheckboxList:
    return (
      <div style={props.style}>
        <SelectionLabel/>
        <div className='checkbox-list-container'>
          <IonList>
            {props.options?.slice(0, props.itemsPerColumn).map((option => {
              return (
                <CheckboxItem
                  key={option}
                  label={option}
                  onIonChange={(e) => props.onIonChange(e, option)}
                  checked={props.checked(option)}
                />
              );
            }))}
          </IonList>
          <IonList>
            {props.options?.slice(props.itemsPerColumn, props.options.length).map((option => {
              return (
                <CheckboxItem
                  label={option}
                  onIonChange={(e) => props.onIonChange(e, option)}
                  checked={props.checked(option)}
                  key={option}
                />
              );
            }))}
          </IonList>
        </div>
      </div>
    );
  case SelectionTypes.TextArea:
    return (
      <div style={props.containerStyle}>
        <SelectionLabel/>
        <IonTextarea
          class="text-area"
          placeholder={props.placeholder}
          style={props.style}
          rows={props.rows}
          maxlength={props.maxLength}
          onBlur={(e) => props.onBlur(e.target.value)}
          value={props.value}
          disabled={props.isDisabled}
          data-testid={'QASelectionContainerTextArea_' + props.textAreaType}
        />
      </div>
    );
  case SelectionTypes.TextList:
    return (
      <div onBlur={()=>{ chipsRef.current.addChip(chipsRef.current.state.value); }}>
        <Chips
          value={props.value}
          theme={chipsContainerStyle}
          chipTheme={chipStyle}
          placeholder={props.placeholder}
          createChipKeys={[13, 9]}
          onChange={(values) => {
            if(props.regex && values.length) {
              if (props.regex.test(values[values.length - 1])) {
                props.onChange(values);
              }
            } else {
              props.onChange(values);
            }
          }}
          data-testid={'QASelectionContainerTextList_' + props.textListType}
          ref={chipsRef}
        />
      </div>
    );
  case SelectionTypes.Date:
    return (
      <div style={props.containerStyle}>
        <SelectionLabel/>
        <IonInput
          class={classNames({'text-input': true,  'date': true, 'dark-mode': (darkMode ? true : false)})}
          type='date'
          min={props.min}
          onBlur={(e) => {
            props.onBlur(e.target.value && props.min ? (e.target.value >= props.min ? e.target.value : props.min) : e.target.value);
          }}
          value={props.value}
          disabled={props.isDisabled}
          data-testid={'QASelectionContainerDateInput_' + props.selectionLabel}
        />
      </div>
    );
  case SelectionTypes.ReadOnly:
    return (
      <div style={props.containerStyle}>
        <SelectionLabel/>
        <IonInput
          class="text-input"
          type={props.inputType}
          placeholder={props.placeholder}
          style={props.style}
          min={props.min}
          max={props.max}
          step={props.step}
          value={props.formatter ? props.formatter(props.value) : props.value}
          disabled={props.isDisabled}
          data-testid={'QASelectionContainerTextInput_' + props.selectionLabel}
          readonly
        />
      </div>
    );
  }
};

export default SelectionContainer;