import AppDropdown from '@components/app-dropdown/app-dropdown';
import AppDropdownCladProductionSpecification from '@components/app-dropdown/app-dropdown-clad-production-specification';
import AppDropdownHardnessMetalSurface from '@components/app-dropdown/app-dropdown-hardness-metal-surface';
import AppDropdownSelection from '@components/app-dropdown/app-dropdown-selection';
import AppDropdownTesileTest from '@components/app-dropdown/app-dropdown-tensile-test';
import AppInput from '@components/app-input/app-input';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';
import AppRadioButtons from '@components/app-radio-buttons/app-radio-buttons';
import AppRadioButtonsSelection from '@components/app-radio-buttons/app-radio-buttons-selection';
import AppTextarea from '@components/app-textarea/app-textarea';
import AppCheckboxItem from '@components/app-checkbox-item/app-checkbox-item';
import { FieldTypes, MeasurementSystems, PostCladBaseMetalUTSpecifications, PreBondAutoSelect, QuoteLineItemValueTypes, QuoteVariableConfigDescriptions, RadioButtonOptions, RawMaterialUsage, Regexes, TensileTestLocations, TestingUOMs } from '@constants';
import { RawMaterial } from '@interfaces/raw-material';

import { IonButton } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import LineItem from '../line-item/line-item';
import Section from './section';
import { FormKeys } from '../advance-specification-form-util';
import CustomerSpecifiedSPWHT from '../modals/customer-specified-spwht';
import CustomerSpecifiedImpactTesting from '../modals/customer-specified-impact-testing';
import LeakTest from '../modals/leak-test';
import _ from 'lodash-es';
import { useDataState } from '@context/data-context';
import { useDisplayUom } from '@context/useDisplayUom';
import { getQuoteVariableConfiguration, getQuoteVariableConfigurationUOM } from '@shared/quote-utils';

// AppDropdownCladProductionSpecification
const PostCladBondUTSpecsDropdown = (props) => {
  const { areaId, cladProductionSpecs, onChange, value } = props;
  const { dataState } = useDataState();
  const { specificationTestOptions } = dataState;
  const cladProductSpecsLabel = cladProductionSpecs?.split('&')[0].trim();

  const newOptions = specificationTestOptions
    .filter((specificationTestOption) => {
      return specificationTestOption.specification === cladProductSpecsLabel
        && specificationTestOption.dataAreaId === areaId
    })
    .map(specificationTestOption => {
      const str = `${specificationTestOption.specification}: ${specificationTestOption.option}`;
      return {
        label: str,
        value: str
      };
    })

  useEffect(() => {
    if (onChange && newOptions.length > 0 && !newOptions.find(newOption => newOption.value === value)) {
      onChange(newOptions[0]);
    }
  }, [specificationTestOptions, cladProductionSpecs, value])

  return <>
    <AppDropdown options={newOptions} onChange={onChange} value={value}/>
  </>
}
// Post Clad Base Metal UT Specification
const PostCladBaseMetalUTDropdown = (props) => {
  const { areaId, baseMetal, onChange, value } = props;
  const { dataState } = useDataState();
  const { rawMaterials } = dataState;

  const material = rawMaterials.find((rawMaterial: RawMaterial) =>
    rawMaterial.name === baseMetal &&
    rawMaterial.usage === RawMaterialUsage.Base &&
    rawMaterial.dataAreaId.includes(areaId)
  );

  const strs = material?.baseMetalUTOptions[areaId]?.map(bmUTOptions => bmUTOptions.option) || []
  const newOptions = [
    ...strs,
    PostCladBaseMetalUTSpecifications.Other
  ].map((option) => {
    return {
      label: option,
      value: option,
    }
  })

  useEffect(() => {
    if (onChange && newOptions.length > 0 && !newOptions.find(newOption => newOption.value === value)) {
      onChange(newOptions[0]);
    }
  }, [baseMetal, value]);

  return <>
    <AppDropdown options={newOptions} value={value} onChange={onChange}/>
  </>
}

const ShearTestLineItem = (props) => {
  const {
    form,
    updateForm,
    updateFormByPayload,
    areaId,
    segmentKey,
  } = props;
  const { displayUom } = useDisplayUom();

  const {
    dataState,
  } = useDataState();

  const {
    quoteVariableConfigurations,
  } = dataState;

  const defaultValues = {
    [MeasurementSystems.Metric]: {
      value: getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.OERTestingShearTestingPressureMPA, areaId, FieldTypes.Integer),
      uom: getQuoteVariableConfigurationUOM(quoteVariableConfigurations, QuoteVariableConfigDescriptions.OERTestingShearTestingPressureMPA, areaId),
    },
    [MeasurementSystems.Imperial]: {
      value: getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.OERTestingShearTestingPressurePSI, areaId, FieldTypes.Integer),
      uom: getQuoteVariableConfigurationUOM(quoteVariableConfigurations, QuoteVariableConfigDescriptions.OERTestingShearTestingPressurePSI, areaId),
    }
  };

  useEffect(() => {
    if (!form[FormKeys.ShearTestValue] && !form[FormKeys.ShearTestUOM]) {
      const defaults = defaultValues[displayUom];
      updateFormByPayload({
        [FormKeys.ShearTestValue]: defaults.value,
        [FormKeys.ShearTestUOM]: defaults.uom,
      });
    }
  }, [displayUom]);

  useEffect(() => {
    const values = Object.keys(defaultValues).map((uom: any) => defaultValues[uom].value);

    if (!form[FormKeys.ShearTestValue] || values.includes(+form[FormKeys.ShearTestValue])) {
      const uom: any = Object.keys(defaultValues).find((dUOM) => {
        return defaultValues[dUOM].uom === form[FormKeys.ShearTestUOM];
      });

      if (defaultValues[uom]) {
        updateForm(FormKeys.ShearTestValue, defaultValues[uom].value);
      }
    };
  }, [form[FormKeys.ShearTestUOM]])

  return <LineItem
    label={<AppCheckboxItem
      label="Shear Test"
      checked={form[FormKeys.RequiredShearTest]}
      onChange={(value) => updateForm(FormKeys.RequiredShearTest, value)}
    />}
    specifyKey={`${segmentKey}.shear-test`}
  >
    <AppLineItemColumns components={[
      {
        key: 'shear-test-input',
        size: 2,
        component: <AppInput
          value={form[FormKeys.ShearTestValue]}
          type='number'
          regex={Regexes.IntegerAboveZero}
          min='1'
          disabled={!form[FormKeys.RequiredShearTest]}
          onChange={(value) => updateForm(FormKeys.ShearTestValue, value)}
        />
      },
      {
        key: 'shear-test-radio',
        size: 6,
        component: <AppRadioButtonsSelection
          name={FormKeys.ShearTestUOM}
          areaId={areaId}
          disabled={!form[FormKeys.RequiredShearTest]}
          selected={form[FormKeys.ShearTestUOM]}
          onChange={(value) => updateForm(FormKeys.ShearTestUOM, value)}
          valueType={QuoteLineItemValueTypes.ShearTestUOM}
        />
      },
    ]} />
  </LineItem>
};

const CladProductTesting = (prop) => {
  const {
    form,
    updateForm,
    updateFormByPayload,
    areaId,
    segmentKey,
  } = prop;
  const [ openCustomerSpecifiedSPWHTModal, setOpenCustomerSpecifiedSPWHTModal ] = useState(false);
  const [ openCustomerSpecifiedImpactTesting, setOpenCustomerSpecifiedImpactTesting ] = useState(false);
  const [ openLeakTest, setOpenLeakTest ] = useState(false);

  useEffect(() => {
    const value = form[FormKeys.RequiredTensileTest]
      ? form[FormKeys.CladTensileTestLocation] || TensileTestLocations.PerSpecification
      : null

    updateForm(FormKeys.CladTensileTestLocation, value);
  }, [form[FormKeys.RequiredTensileTest]]);

  useEffect(() => {
    const value = form[FormKeys.PostCladBaseMetalUT];

    if (value === 'None') {
      updateForm(FormKeys.PrecladBaseMetalUT, value);
    }

  }, [form[FormKeys.PostCladBaseMetalUT]]);

  return <Section {...prop}>
    <LineItem label='Clad Production Specification'>
      <AppLineItemColumns components={[
        {
          key: 'clad-prod-spec-dropdown',
          size: 3,
          component: <AppDropdownCladProductionSpecification
            onChange={({value}) => updateForm(FormKeys.CladProductionSpecification, value)}
            value={form[FormKeys.CladProductionSpecification]}
            areaId={areaId}
          />
        },
        {
          key: 'clad-prod-spec-input',
          size: 9,
          component: <AppInput
            placeholder='Additional Note'
            onChange={(value) => updateForm(FormKeys.CladProductionSpecificationNotes, value)}
            value={form[FormKeys.CladProductionSpecificationNotes]}
          />
        }
      ]} />
    </LineItem>
    <LineItem label='Clad Metal'>
      <AppLineItemColumns components={[
        {
          key: 'clad-metal-dropdown',
          size: 6,
          component: <AppInput
            value={form[FormKeys.CladMetal]}
            readonly
          />
        },
      ]} />
    </LineItem>
    <LineItem label='Base Metal'>
      <AppLineItemColumns components={[
        {
          key: 'base-metal-dropdown',
          size: 6,
          component: <AppInput
            value={form[FormKeys.BaseMetal]}
            readonly
          />
        },
      ]} />
    </LineItem>
    <LineItem label='Post Clad Bond UT Specification' specifyKey={`${segmentKey}.post-clad-bond-ut-spec`}>
      <AppLineItemColumns components={[
        {
          key: 'post-clad-bond-ut-spec-dropdown',
          size: 5,
          component: <PostCladBondUTSpecsDropdown
            areaId={areaId}
            cladProductionSpecs={form[FormKeys.CladProductionSpecification]}
            value={form[FormKeys.PostCladBondUTSpecs]}
            onChange={({value}) => updateForm(FormKeys.PostCladBondUTSpecs, value)}
          />
        },
        {
          key: 'post-clad-bond-ut-spec-input',
          size: 7,
          component: <AppInput
            placeholder='Additional Note'
            onChange={(value) => updateForm(FormKeys.PostCladBondUTSpecificationNotes, value)}
            value={form[FormKeys.PostCladBondUTSpecificationNotes]}
          />
        }
      ]} />
    </LineItem>
    <LineItem label='Post Clad Base Metal UT Specification' specifyKey={`${segmentKey}.post-clad-base-bond-ut-spec`}>
      <AppLineItemColumns components={[
        {
          key: 'post-clad-base-bond-ut-spec-dropdown',
          size: 3,
          component: <PostCladBaseMetalUTDropdown
            areaId={areaId}
            baseMetal={form[FormKeys.BaseMetal]}
            value={form[FormKeys.PostCladBaseMetalUT]}
            onChange={({value}) => updateForm(FormKeys.PostCladBaseMetalUT, value)}
          />
        },
        {
          key: 'post-clad-base-bond-ut-spec-input',
          size: 7,
          component: <AppInput
            placeholder='Additional Note'
            value={form[FormKeys.PostCladBaseMetalUTNotes]}
            onChange={(value) => updateForm(FormKeys.PostCladBaseMetalUTNotes, value)}
          />
        }
      ]} />
    </LineItem>
    <LineItem label='Certification Type Required' specifyKey={`${segmentKey}.cert-type-req`}>
      <AppLineItemColumns components={[
        {
          key: 'cert-type-req-dropdown',
          size: 6,
          component: <AppDropdownSelection
            valueType={QuoteLineItemValueTypes.CertificationTypeRequired}
            areaId={areaId}
            value={form[FormKeys.CertificationTypeRequired]}
            onChange={({value}) => updateForm(FormKeys.CertificationTypeRequired, value)}
          />
        },
      ]} />
    </LineItem>
    <LineItem label='Cladding for Corrosion Allowance Only' specifyKey={`${segmentKey}.cladding-corrosion-allowance`}>
      <AppLineItemColumns components={[
        {
          key: 'cladding-corrosion-allowance-radio',
          size: 6,
          component: <AppRadioButtons
            name={FormKeys.CladdingForCorrosionAllowanceOnly}
            options={[RadioButtonOptions.Yes, RadioButtonOptions.No]}
            selected={form[FormKeys.CladdingForCorrosionAllowanceOnly]}
            onChange={(value) => updateForm(FormKeys.CladdingForCorrosionAllowanceOnly, value)}
          />
        },
      ]} />
    </LineItem>
    <LineItem label='Is Weld Repair Allowed' specifyKey={`${segmentKey}.is-weld-repair-allowed`}>
      <AppLineItemColumns components={[
        {
          key: 'is-weld-repair-allowed-radio',
          size: 12,
          component: <AppRadioButtons
            name={FormKeys.IsWeldRepairAllowed}
            options={[RadioButtonOptions.Yes, RadioButtonOptions.No, 'Only with Specific Approval']}
            selected={form[FormKeys.IsWeldRepairAllowed]}
            onChange={(value) => updateForm(FormKeys.IsWeldRepairAllowed, value)}
          />
        },
      ]} />
    </LineItem>
    <LineItem
      label={<AppCheckboxItem
        label="Customer Specified SPWHT of Test Coupons"
        checked={form[FormKeys.CustomerSpecifiedSPWHTTestCoupons]}
        onChange={(value) => updateForm(FormKeys.CustomerSpecifiedSPWHTTestCoupons, value)}
      />}
      specifyKey={`${segmentKey}.customer-specified-spwht`}
    >
      <AppLineItemColumns components={[
        {
          key: 'customer-specified-spwht-button',
          component: <IonButton
            color='secondary'
            size='small'
            className='specify-button'
            disabled={!form[FormKeys.CustomerSpecifiedSPWHTTestCoupons]}
            onClick={() => setOpenCustomerSpecifiedSPWHTModal(true)}
          >
            Specify
          </IonButton>
        }
      ]} />
    </LineItem>
    <LineItem
      label={<AppCheckboxItem
        label="Customer Specified Impact Testing"
        checked={form[FormKeys.CustomerSpecifiedImpactTesting]}
        onChange={(value) => updateForm(FormKeys.CustomerSpecifiedImpactTesting, value)}
      />}
      specifyKey={`${segmentKey}.customer-specified-impact`}
    >
      <AppLineItemColumns components={[
        {
          key: 'customer-specified-impact-button',
          component: <IonButton
            color='secondary'
            size='small'
            className='specify-button'
            onClick={() => setOpenCustomerSpecifiedImpactTesting(true)}
            disabled={!form[FormKeys.CustomerSpecifiedImpactTesting]}
          >
            Specify
          </IonButton>
        }
      ]} />
    </LineItem>
    <ShearTestLineItem {...prop}/>

    <LineItem
      label={<AppCheckboxItem
        label="Tensile Test"
        checked={form[FormKeys.RequiredTensileTest]}
        onChange={(value) => updateForm(FormKeys.RequiredTensileTest, value)}
      />}
      specifyKey={`${segmentKey}.tensile-test`}
    >
      <AppLineItemColumns components={[
        {
          key: 'tensile-test-input',
          size: 4,
          component: <AppDropdownTesileTest
            isDisabled={!form[FormKeys.RequiredTensileTest]}
            value={form[FormKeys.CladTensileTestLocation]}
            onChange={({value}) => updateForm(FormKeys.CladTensileTestLocation, value)}
          />
        },
      ]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="Bond Strength Tensile Test (Ram)"
        checked={form[FormKeys.RequiredBondStrTensileTest]}
        onChange={(value) => updateForm(FormKeys.RequiredBondStrTensileTest, value)}
      />}
      specifyKey={`${segmentKey}.bond-strength-tensile-test`}
    >
      <AppLineItemColumns components={[
        {
          key: 'bond-strength-tensile-test-input',
          size: 2,
          component: <AppInput
            value={form[FormKeys.BondStrengthTensileTestRamValue]}
            disabled={!form[FormKeys.RequiredBondStrTensileTest]}
            onChange={(value) => updateForm(FormKeys.BondStrengthTensileTestRamValue, value)}
          />
        },
        {
          key: 'bond-strength-tensile-test-radio',
          size: 6,
          component: <AppRadioButtons
            name={FormKeys.BondStrengthTensileTestRamUOM}
            disabled={!form[FormKeys.RequiredBondStrTensileTest]}
            options={[TestingUOMs.PSI, TestingUOMs.MPA]}
            selected={form[FormKeys.BondStrengthTensileTestRamUOM]}
            onChange={(value) => updateForm(FormKeys.BondStrengthTensileTestRamUOM, value)}
          />
        },
      ]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="Hardness Cladding Metal Surface"
        checked={form[FormKeys.RequiredHardnessCladdingMetalSurface]}
        onChange={(value) => updateForm(FormKeys.RequiredHardnessCladdingMetalSurface, value)}
      />}
      specifyKey={`${segmentKey}.hardness-cladding-metal-surface`}
    >
      <AppLineItemColumns components={[
        {
          key: 'hardness-cladding-metal-surface-dropdown',
          size: 3,
          component: <AppDropdownHardnessMetalSurface
            disabled={!form[FormKeys.RequiredHardnessCladdingMetalSurface]}
            value={form[FormKeys.HardnessCladdingMetalSurface]}
            onChange={({value}) => updateForm(FormKeys.HardnessCladdingMetalSurface, value)}
          />
        },
        {
          key: 'hardness-cladding-metal-surface-input',
          size: 8,
          component: <AppInput
            placeholder='Rejection Criteria'
            disabled={!form[FormKeys.RequiredHardnessCladdingMetalSurface]}
            value={form[FormKeys.HardnessCladdingRejectionCriteria]}
            onChange={(value) => updateForm(FormKeys.HardnessCladdingRejectionCriteria, value)}
          />
        }
      ]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="Hardness Base Metal Surface"
        checked={form[FormKeys.RequiredHardnessBaseMetalSurface]}
        onChange={(value) => updateForm(FormKeys.RequiredHardnessBaseMetalSurface, value)}
      />}
      specifyKey={`${segmentKey}.hardness-base-metal-surface`}
    >
      <AppLineItemColumns components={[
        {
          key: 'hardness-base-metal-surface-dropdown',
          size: 3,
          component: <AppDropdownHardnessMetalSurface
            value={form[FormKeys.HardnessBaseMetalSurface]}
            disabled={!form[FormKeys.RequiredHardnessBaseMetalSurface]}
            onChange={({value}) => updateForm(FormKeys.HardnessBaseMetalSurface, value)}
          />
        },
        {
          key: 'hardness-base-metal-surface-input',
          size: 8,
          component: <AppInput placeholder='Rejection Criteria'
            value={form[FormKeys.HardnessBaseRejectionCriteria]}
            disabled={!form[FormKeys.RequiredHardnessBaseMetalSurface]}
            onChange={(value) => updateForm(FormKeys.HardnessBaseRejectionCriteria, value)}
          />
        }
      ]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="PMI Confirm Cladding Metal"
        checked={form[FormKeys.RequiredPMIConfirmCladdingMetal]}
        onChange={(value) => updateForm(FormKeys.RequiredPMIConfirmCladdingMetal, value)}
      />}
      specifyKey={`${segmentKey}.pmi-confirm-cladding-metal`}
    >
      <AppLineItemColumns components={[
        {
          key: 'pmi-confirm-cladding-metal-input',
          size: 8,
          component: <AppInput
            placeholder='Additional Note'
            value={form[FormKeys.PMIConfirmCladdingMetalNotes]}
            disabled={!form[FormKeys.RequiredPMIConfirmCladdingMetal]}
            onChange={(value) => updateForm(FormKeys.PMIConfirmCladdingMetalNotes, value)}
          />
        }
      ]} />
      <AppLineItemColumns components={[{
        key: 'pmi-confirm-cladding-metal-radio',
        component: <AppRadioButtons
          name={FormKeys.PMIConfirmCladdingMetal}
          options={['Upon Receipt', 'Upon Receipt and Final Inspection']}
          selected={form[FormKeys.PMIConfirmCladdingMetal]}
          disabled={!form[FormKeys.RequiredPMIConfirmCladdingMetal]}
          onChange={(value) => updateForm(FormKeys.PMIConfirmCladdingMetal, value)}
        />
      }]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="PMI Confirm Base Metal"
        checked={form[FormKeys.RequiredPMIConfirmBaseMetal]}
        onChange={(value) => updateForm(FormKeys.RequiredPMIConfirmBaseMetal, value)}
      />}
      specifyKey={`${segmentKey}.pmi-confirm-base-metal`}
    >
      <AppLineItemColumns components={[
        {
          key: 'pmi-confirm-base-metal-input',
          size: 8,
          component: <AppInput
            placeholder='Additional Note'
            value={form[FormKeys.PMIConfirmBaseMetalNotes]}
            onChange={(value) => updateForm(FormKeys.PMIConfirmBaseMetalNotes, value)}
            disabled={!form[FormKeys.RequiredPMIConfirmBaseMetal]}
          />
        }
      ]} />
      <AppLineItemColumns components={[{
        key: 'pmi-confirm-base-metal-radio',
        component: <AppRadioButtons
          name={FormKeys.PMIConfirmBaseMetal}
          options={['Upon Receipt', 'Upon Receipt and Final Inspection']}
          selected={form[FormKeys.PMIConfirmBaseMetal]}
          disabled={!form[FormKeys.RequiredPMIConfirmBaseMetal]}
          onChange={(value) => updateForm(FormKeys.PMIConfirmBaseMetal, value)}
        />
      }]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="Bond Strength Bend Per SA263/4/5"
        checked={form[FormKeys.MachineBondStrengthBendsperSA26345]}
        onChange={(value) => updateForm(FormKeys.MachineBondStrengthBendsperSA26345, value)}
      />}
      specifyKey={`${segmentKey}.bond-strength-bend`}
    />
    <LineItem
      label={<AppCheckboxItem
        label="Bend B898"
        checked={form[FormKeys.BendB898]}
        onChange={(value) => updateForm(FormKeys.BendB898, value)}
      />}
      specifyKey={`${segmentKey}.bend-b898`}
    />
    <LineItem
      label={<AppCheckboxItem
        label="Side Bend"
        checked={form[FormKeys.MachineSideBends]}
        onChange={(value) => updateForm(FormKeys.MachineSideBends, value)}
      />}
      specifyKey={`${segmentKey}.side-bend`}
    />
    <LineItem
      label={<AppCheckboxItem
        label="AD-W8 Bend"
        checked={form[FormKeys.ADW8Bend]}
        onChange={(value) => updateForm(FormKeys.ADW8Bend, value)}
      />}
      specifyKey={`${segmentKey}.adw8-bend`}
    />
    <LineItem
      label={<AppCheckboxItem
        label="Leak Test"
        checked={form[FormKeys.RequiredLeakTest]}
        onChange={(value) => updateForm(FormKeys.RequiredLeakTest, value)}
      />}
      specifyKey={`${segmentKey}.leak-test`}
    >
      <AppLineItemColumns components={[
        {
          key: 'leak-test-button',
          component: <IonButton
            disabled={!form[FormKeys.RequiredLeakTest]}
            color='secondary'
            size='small'
            className='specify-button'
            onClick={() => setOpenLeakTest(true)}
          >Specify</IonButton>
        }
      ]} />
    </LineItem>

    <LineItem
      label="Additional Requirements"
      specifyKey={`${segmentKey}.additional-requirements`}
    >
      <AppLineItemColumns components={[
        {
          key: 'additional-req-input',
          component: <AppTextarea
            rows={5}
            placeholder='Specify additional requirements...'
            value={form[FormKeys.AdditionalRequirementsCladProductTesting]}
            onChange={(value) => updateForm(FormKeys.AdditionalRequirementsCladProductTesting, value)}
          />
        }
      ]}/>
    </LineItem>

    { openCustomerSpecifiedSPWHTModal && <CustomerSpecifiedSPWHT
        form={form}
        onSave={(payload) => {
          // should update by object
          updateFormByPayload(payload);
          setOpenCustomerSpecifiedSPWHTModal(false)
        }}
        areaId={areaId}
        onDismiss={() => setOpenCustomerSpecifiedSPWHTModal(false)}
    />}
    {openCustomerSpecifiedImpactTesting && <CustomerSpecifiedImpactTesting
      form={form}
      areaId={areaId}
      onSave={(payload) => {
        const data = {
          ...payload,
          [FormKeys.PrebondImpacts]: PreBondAutoSelect.includes(payload[FormKeys.CustomerSpecifiedImpactTestToBePerformed])
        };

        // should fill prebond impact
        const mapper = {
          [FormKeys.CustomerSpecifiedImpactTestToBePerformed]: FormKeys.PreBondImpactsTestToBePerformed,
          [FormKeys.CustomerSpecifiedImpactTestLocation]: FormKeys.PreBondImpactsTestLocation,
          [FormKeys.CustomerSpecifiedImpactTestDirection]: FormKeys.PreBondImpactsTestDirection,
          [FormKeys.CustomerSpecifiedImpactTestTemperature]: FormKeys.PreBondImpactsTestTemperature,
          [FormKeys.CustomerSpecifiedImpactTestTempUOM]: FormKeys.PreBondImpactsTestTemperatureUOM,
          [FormKeys.CustomerSpecifiedImpactTestUOM]: FormKeys.PreBondImpactsTestUOM,
          [FormKeys.CustomerSpecifiedImpactTestAVG]: FormKeys.PreBondImpactsTestAVG,
          [FormKeys.CustomerSpecifiedImpactTestMIN]: FormKeys.PreBondImpactsTestMIN,
        };

        // basic would be enough
        Object.keys(mapper).forEach((fromKey) => {
          const toKey = mapper[fromKey];

          // if key is test direction and does not have pre bond impact test location
          // override prebond test direction
          if (fromKey === FormKeys.CustomerSpecifiedImpactTestDirection && !form[FormKeys.PreBondImpactsTestLocation]) {
            data[toKey] = payload[fromKey];
            return;
          }

          if(form[toKey]) {
            return;
          }

          data[toKey] =   payload[fromKey];
        });

        // should update by object
        updateFormByPayload(data);

        setOpenCustomerSpecifiedImpactTesting(false)
      }}
      onDismiss={() => setOpenCustomerSpecifiedImpactTesting(false)}
    />}

    {openLeakTest && <LeakTest
      form={form}
      onSave={(payload) => {
        // should update by object
        updateFormByPayload(payload);
        setOpenLeakTest(false)
      }}
      onDismiss={() => setOpenLeakTest(false)}
    />}
  </Section>
}

export default CladProductTesting;