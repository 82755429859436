import * as React from 'react';
import { IonCard, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import './quote-line-table-header.scss';
import classNames from 'classnames';
import { chevronDown, chevronUp } from 'ionicons/icons';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
interface TableHeadersProps {
  labels: { display: string; colSize?: string, hidden?: boolean, isCheckbox?: boolean }[];
  details?: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  sortItems?: Function,
  selectedColumnHeader?: string,
  sortAsc?: boolean
  hidden?: boolean
  isChecked?: (isChecked: boolean) => void;
  checked?: boolean
}

const TableHeaders = ({ labels, details, sortItems, selectedColumnHeader, sortAsc, hidden, isChecked, checked }: TableHeadersProps) => {

  const getColHeaderIcon = (column) => {
    if(sortItems && selectedColumnHeader && column) {
      return (column === selectedColumnHeader && sortAsc) ? chevronUp : chevronDown;
    }
  };

  const getColHeaderClass = (column) => {
    if(sortItems && selectedColumnHeader) {
      return (column === selectedColumnHeader) ? 'text-bold pointer' : 'pointer';
    }
  };

  return (
    <>
      <IonCard class={classNames({'table-header-quote-line-items-card': true, 'detail': details})} hidden={hidden}>
          <IonGrid>
            <IonRow class='table-header-quote-line-items-row'>
              {labels.map((label, index) => {
                return (
                  // index as key due to possible blank header labels
                  <IonCol
                    key={index}
                    size={label.colSize}
                    hidden={label.hidden}
                    class='table-header-quote-line-items-col quote-line-items-text'
                    onClick={() => {
                      if(sortItems && selectedColumnHeader && label.display) {
                        sortItems(label.display);
                      }
                    }}
                    data-testid={'QATableHeader_' + label.display}
                  >

                    {label.isCheckbox ? 
                    <CheckboxItem
                      style={{
                        width: 'fit-content',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        marginRight: '-10px',
                        left: '10px',
                        ['--background']: 'transparent',
                      }}
                      onIonChange={(checked: boolean) => {
                        isChecked(checked);
                      }}
                      checked={checked}
                      /> : (
                      <>
                        <span className={getColHeaderClass(label.display)}>{label.display}</span>
                        <IonIcon icon={getColHeaderIcon(label.display)}></IonIcon>
                      </>
                    )}

                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
      </IonCard>
    </>
  );
};
export default TableHeaders;