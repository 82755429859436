import * as React from 'react';
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonImg,
  IonLabel,
  IonRow,
} from '@ionic/react';
import './seam-weld-popover.scss';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import SelectionContainer from '@components/selection-container/selection-container';
import {
  SelectionTypes,
  Regexes,
  PanelLocations,
  SelectionLabelPositions,
  MSTOAssemblyMetals,
  QuoteLineItemFieldUnitTypes,
  QuoteLineItemValueTypes,
  MeasurementSystems,
  ToastMessages,
  QuoteVariableConfigDescriptions,
  FieldTypes,
  ToastTypes,
  SeamWeldQuantity,
  MSTOPopovers,
  MSTOSelectionTabs,
} from '@constants';
import {
  AssemblyPanels,
  SeamPanels
} from '@shared/types';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';
import LineItemField from '@components/line-item-field/line-item-field';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { getQuoteVariableConfiguration, getSelectionOptions } from '@shared/quote-utils';
import { Condition, WeldingPattern } from '@interfaces/welding-patterns';
import moment from 'moment';
import { useToast } from '@hooks/useToast';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { useLocalUom } from '@hooks/useLocalUom';

const SeamWeldPopover = (props: {
  actionHandlerCreateAssembly: (selectedMetalsToAssembleBySeamWeld?: {
    quoteLineItemData: QuoteLineItem;
    quoteLineItemDetailData: QuoteLineItemDetail;
    metal: MSTOAssemblyMetals;
    panelNumber?: number;
    panelLocation?: PanelLocations;
    seamWeldPieceNumber?: number;
  }[]) => void,
  mstoData: {
    selectedMetalsToAssemble: AssemblyPanels[],
    cladderPanelsMap: Map<string, SeamPanels>
  },
  dismissModal: Function,
  updateSelectedMetalsToAssemble: Function,
  updateCladderPanelsMap: Function
}) => {
  const { dataState } = useDataState();
  const { selections, weldingPatterns, quoteVariableConfigurations } = dataState;
  
  
  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  
  const { localUom, isDisplayMetric } = useLocalUom();
  
  const { darkMode } = useDarkMode();
  
  const [locations, setLocations] = React.useState({ left: 0, center: 0, right: 0 });
  const [panelData, setPanelData] = React.useState(
    new Map<number, { line: string, piece: number, panelLocation: PanelLocations; panelNumber: number }>()
  );
  const [weldingPattern, setWeldingPattern] = React.useState<WeldingPattern>(undefined);
  const [showWarningMsg, setShowWarningMsg] = React.useState(false);

  const numAdded = Object.keys(locations).reduce((previous, key) => previous + locations[key], 0);

  const cladPanelsToSeamWeld = props.mstoData.selectedMetalsToAssemble
    .orderBy((allocation: AssemblyPanels) => allocation.quoteLineItemData.Line__c)
    .filter((alloc) => alloc.metal === MSTOAssemblyMetals.CLAD);

  const dismissModal = () => props.dismissModal(MSTOPopovers.SeamWeld);
  const updateSelectedMetalsToAssemble = (metalsToAssemble) => props.updateSelectedMetalsToAssemble(metalsToAssemble);
  const updateCladderPanelsMap = (panelsMap, selectionTab) => props.updateCladderPanelsMap(panelsMap, selectionTab);
  const actionHandlerCreateAssembly = (metalsToAssemble) => props.actionHandlerCreateAssembly(metalsToAssemble);

  const addPanelLocations = () => {
    const updatedCladderPanelsMap = new Map(props.mstoData.cladderPanelsMap);
    const updatedSelectedMetalsToAssemble = [...props.mstoData.selectedMetalsToAssemble];

    Array.from(panelData.keys()).forEach(panelSeamWeldPiece => {
      const panelDataForPiece = panelData.get(panelSeamWeldPiece);
      const seamWeldPieceNumber = panelSeamWeldPiece + 1;

      const panelsMapKey = `${panelDataForPiece.line} - ${panelDataForPiece.piece}`;
      const currentCladderPanel = updatedCladderPanelsMap.get(panelsMapKey);

      const seamWeldFields = {
        [`RawMaterialVendorSW${seamWeldPieceNumber}__c`]: (currentCladderPanel.quoteLineItemDetailData.RawMaterialVendorCladder__c ?? '0'),
        [seamWeldPieceNumber === 1 ? 'ConfigurationID__c' : `ConfigurationIDSW${seamWeldPieceNumber}`]: currentCladderPanel.quoteLineItemDetailData.ConfigurationID__c,
        [`PurchaseSW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.PurchaseCL__c,
        [`PullFromStockSW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.Pullfromstockcladder__c,
        [`PurchaseQuantitySW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.PurchaseCL__c ? SeamWeldQuantity.One : null,
        [`PullFromStockQuantitySW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.Pullfromstockcladder__c ? SeamWeldQuantity.One : null,
        [`ShipDateSW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.ShipDateCladder__c || moment(new Date()).format('YYYY-MM-DD'),
        [`DaysForTransportSW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.CladderDaysForTransport__c,
        [`PreheatToCutSW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.PreheattoTorchCutCladder__c,
        [`CladderWidthSW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.PurchaseCladWidth__c,
        [`CladderLengthSW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.PurchaseCladLength__c,
        [`NominalThicknessSW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.PurchaseCladTK__c,
        [`WeightSW${seamWeldPieceNumber}__c`]: isDisplayMetric ? currentCladderPanel.quoteLineItemDetailData.PurchaseCladderWeight_KG__c : currentCladderPanel.quoteLineItemDetailData.PurchaseCladderWeight_LB__c,
        [`SWRawMaterialCostSW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.PurchaseCladderPrice__c,
        [`EstimatedShippingTotalSW${seamWeldPieceNumber}__c`]: isDisplayMetric ? currentCladderPanel.quoteLineItemDetailData.PurchaseCladderFreight_KG__c : currentCladderPanel.quoteLineItemDetailData.PurchaseCladderFreight_LB__c,
        [`PanelLocationSW${seamWeldPieceNumber}__c`]: panelDataForPiece.panelLocation,
        [`PanelNumberSW${seamWeldPieceNumber}__c`]: panelDataForPiece.panelNumber,
        [`QuantitySW${seamWeldPieceNumber}__c`]: currentCladderPanel.quoteLineItemDetailData.Clads__c,
      };

      updatedCladderPanelsMap.set(panelsMapKey, {
        quoteLineItemData: currentCladderPanel.quoteLineItemData,
        quoteLineItemDetailData: {
          ...currentCladderPanel.quoteLineItemDetailData,
          NC_Seam_Weld_Item_Details__r: {
            ...currentCladderPanel.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r,
            ...seamWeldFields
          }
        },
        panelNumber: panelDataForPiece.panelNumber,
        panelLocation: panelDataForPiece.panelLocation,
        seamWeldPieceNumber,
        lengthWidthTKDisplayFields: currentCladderPanel.lengthWidthTKDisplayFields
      });

      const cladPanelForSeamWeld = cladPanelsToSeamWeld[panelSeamWeldPiece];

      const selectedMetalsToAssembleUpdateIndex = props.mstoData.selectedMetalsToAssemble.findIndex(selectedMetalToAssembleToUpdate => selectedMetalToAssembleToUpdate.metal === MSTOAssemblyMetals.CLAD && selectedMetalToAssembleToUpdate.quoteLineItemData.piece === cladPanelForSeamWeld.quoteLineItemData.piece);

      updatedSelectedMetalsToAssemble[selectedMetalsToAssembleUpdateIndex] = {
        ...updatedSelectedMetalsToAssemble[selectedMetalsToAssembleUpdateIndex],
        seamWeldPieceNumber: seamWeldPieceNumber,
        quoteLineItemDetailData: {
          ...updatedSelectedMetalsToAssemble[selectedMetalsToAssembleUpdateIndex].quoteLineItemDetailData,
          NC_Seam_Weld_Item_Details__r: {
            ...updatedSelectedMetalsToAssemble[selectedMetalsToAssembleUpdateIndex].quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r,
            ...seamWeldFields
          }
        }
      };
    });

    updateSelectedMetalsToAssemble(updatedSelectedMetalsToAssemble);
    updateCladderPanelsMap(updatedCladderPanelsMap, MSTOSelectionTabs.Cladder);
    actionHandlerCreateAssembly(updatedSelectedMetalsToAssemble);
  };

  const validateWeldPattern = (locations: { left: number; center: number; right: number }) => {
    const thicknessRange1 = getQuoteVariableConfiguration(
      quoteVariableConfigurations,
      isDisplayMetric
        ? QuoteVariableConfigDescriptions.OERWeldingRangeOfThicknessForLengthCheck1MM
        : QuoteVariableConfigDescriptions.OERWeldingRangeOfThicknessForLengthCheck1IN,
      manufacturingSite,
      FieldTypes.Float
    ) as number;

    const thicknessRange2 = getQuoteVariableConfiguration(
      quoteVariableConfigurations,
      isDisplayMetric
        ? QuoteVariableConfigDescriptions.OERWeldingRangeOfThicknessForLengthCheck2MM
        : QuoteVariableConfigDescriptions.OERWeldingRangeOfThicknessForLengthCheck2IN,
      manufacturingSite,
      FieldTypes.Float
    ) as number;

    const weldingPattern = weldingPatterns.find(
      (weldingPattern) =>
        weldingPattern.dataAreaId === manufacturingSite &&
        weldingPattern.left === locations.left &&
        weldingPattern.center === locations.center &&
        weldingPattern.right === locations.right
    );

    try {
      cladPanelsToSeamWeld.forEach((cladPanel) => {
        if (!weldingPattern || (locations.left + locations.center + locations.right > cladPanelsToSeamWeld.length)) {
          throw ToastMessages.MSTOInvalidWeldPattern;
        }

        const cladPanelLength = cladPanel.quoteLineItemDetailData.PurchaseCladLength__c;
        const cladPanelWidth = cladPanel.quoteLineItemDetailData.PurchaseCladWidth__c;
        const cladPanelTK = cladPanel.quoteLineItemDetailData.PurchaseCladTK__c;

        if (cladPanelWidth > weldingPattern[localUom.toLowerCase()]['<=width']) {
          throw ToastMessages.MSTOInvalidWeldPattern;
        } else if (cladPanelTK > thicknessRange1) {
          if (cladPanelLength > weldingPattern[localUom.toLowerCase()]['<=range1Length']) {
            throw ToastMessages.MSTOInvalidWeldPattern;
          }
        } else if (cladPanelTK > thicknessRange2 && cladPanelTK < thicknessRange1) {
          if (cladPanelLength > weldingPattern[localUom.toLowerCase()]['<=range2Length']) {
            throw ToastMessages.MSTOInvalidWeldPattern;
          }
        } else if (cladPanelTK < thicknessRange2) {
          if (cladPanelLength > weldingPattern[localUom.toLowerCase()]['<=range3Length']) {
            throw ToastMessages.MSTOInvalidWeldPattern;
          }
        }
      });

      setWeldingPattern(weldingPattern);
      setShowWarningMsg(false);
    } catch (error) {
      // toast(ToastTypes.Error, ToastMessages.MSTOInvalidWeldPattern); // Did I honestly just get Rick Rolled by code?! Wow. I'm so ashamed...and yet thourghly impressed. touché.
      setShowWarningMsg(true);
      setWeldingPattern(undefined);
    }
  };

  React.useEffect(() => {
    try {
      if (locations.left || locations.center || locations.right) {
        validateWeldPattern(locations);
      } else {
        setWeldingPattern(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  }, [locations]);

  const updatePanelLocations = (locationNumbers: { left: number; center: number; right: number; }) => {
    try {
      const updatedPanelData = new Map<number, { line: string, piece: number, panelLocation: PanelLocations; panelNumber: number }>();

      let leftPanelNumber = 1;
      for (let leftIndex = 0; leftIndex < locationNumbers.left; leftIndex++) {
        const cladPanel = cladPanelsToSeamWeld[leftIndex];
        updatedPanelData.set(leftIndex, { line: cladPanel?.quoteLineItemData.Line__c, piece: cladPanel?.quoteLineItemData.piece, panelLocation: PanelLocations.Left, panelNumber: leftPanelNumber });
        leftPanelNumber++;
      }

      const centerIndexToStartAt = Array.from(updatedPanelData.keys()).length;
      let centerPanelNumber = 1;

      for (let centerIndex = centerIndexToStartAt; centerIndex < locationNumbers.center + centerIndexToStartAt; centerIndex++) {
        const cladPanel = cladPanelsToSeamWeld[centerIndex];
        updatedPanelData.set(centerIndex, { line: cladPanel?.quoteLineItemData.Line__c, piece: cladPanel?.quoteLineItemData.piece, panelLocation: PanelLocations.Center, panelNumber: centerPanelNumber });
        centerPanelNumber++;
      }

      const rightIndexToStartAt = Array.from(updatedPanelData.keys()).length;
      let rightPanelNumber = 1;

      for (let rightIndex = rightIndexToStartAt; rightIndex < locationNumbers.right + rightIndexToStartAt; rightIndex++) {
        const cladPanel = cladPanelsToSeamWeld[rightIndex];
        updatedPanelData.set(rightIndex, { line: cladPanel?.quoteLineItemData.Line__c, piece: cladPanel?.quoteLineItemData.piece, panelLocation: PanelLocations.Right, panelNumber: rightPanelNumber });
        rightPanelNumber++;
      }

      setLocations(locationNumbers);
      setPanelData(updatedPanelData);
    } catch (error) {
      console.log(error);
    }
  };

  const updatePanelLocationNumbers = (pieceIndex: number, location: PanelLocations) => {
    try {
      const currentPanelData = panelData.get(pieceIndex);
      const updatedPanelData = new Map<number, { line: string, piece: number, panelLocation: PanelLocations; panelNumber: number }>(panelData);
      updatedPanelData.set(pieceIndex, {...currentPanelData, panelLocation: location});
      
      const updatedLocations = { ...locations };

      switch (location) {
        case PanelLocations.Left:
          updatedLocations.left++;

          if (currentPanelData?.panelLocation === PanelLocations.Center) {
            updatedLocations.center--;
          }

          if (currentPanelData?.panelLocation === PanelLocations.Right) {
            updatedLocations.right--;
          }
          break;
        case PanelLocations.Center:
          updatedLocations.center++;

          if (currentPanelData?.panelLocation === PanelLocations.Left) {
            updatedLocations.left--;
          }

          if (currentPanelData?.panelLocation === PanelLocations.Right) {
            updatedLocations.right--;
          }
          break;
        case PanelLocations.Right:
          updatedLocations.right++;

          if (currentPanelData?.panelLocation === PanelLocations.Left) {
            updatedLocations.left--;
          }

          if (currentPanelData?.panelLocation === PanelLocations.Center) {
            updatedLocations.center--;
          }
          break;
      }

      setPanelData(updatedPanelData);
      setLocations(updatedLocations);
    } catch (error) {
      console.log(error);
    }
  };

  const AllocationsPanelContainer = () => {
    return (
      <>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel class='sublabel-header'>Clad Allocation of Welding:</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className='allocations-panel-container'>
          <IonGrid>
            <IonRow>
              <IonCol>
                <AllocationsPanel />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </>
    );
  };

  const AllocationsPanel = () => {
    const tableHeadersAllocationsPanelsList = [
      { display: 'Piece', colSize: '2' },
      { display: 'Width', colSize: '2' },
      { display: 'Length', colSize: '2' },
      { display: 'TK', colSize: '2' },
      { display: 'Location', colSize: '2' },
      { display: 'Panel #', colSize: '2' },
    ];

    return (
      <div className='allocation-rows-container full-width'>
        <IonGrid>
          {props.mstoData.selectedMetalsToAssemble.length ? (
            <>
              <IonRow>
                <IonCol>
                  <TableHeaders labels={tableHeadersAllocationsPanelsList} />
                </IonCol>
              </IonRow>
              <div>
                {cladPanelsToSeamWeld.map((allocation, idx) => {
                  return (
                    <AllocationsQuoteLineItemRow allocation={allocation} index={idx} key={idx}/>
                  );
                })}
              </div>
            </>
          ) : (
            <div className='assemblies-empty-state'>No Allocations...</div>
          )}
        </IonGrid>
      </div>
    );
  };

  const RulesContainer = () => {
    return (
      <>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel class='sublabel-header'>Rules for Welding Pattern:</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className='rules-container'>
          <IonGrid>
            <IonRow>
              <IonCol>
                <RulesPanel />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </>
    );
  };

  const RulesRow = (props: { condition: Condition }) => {
    return (
      <IonCard className='msto-quote-line-item-card' data-testid={'QAMSTOAllocationsRow'}>
        <IonCardHeader class={'msto-quote-line-item-card-header'}>
          <IonGrid>
            <IonRow class='msto-quote-line-item-row'>
              <IonCol class='msto-quote-line-items-col' size='2'>
                <LineItemField displayedValue={props.condition.conditionCode} />
              </IonCol>
              <IonCol class='msto-quote-line-items-col' size='10'>
                <LineItemField displayedValue={props.condition.reminder} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
      </IonCard>
    );
  };

  const RulesPanel = () => {
    const tableHeadersRulesList = [
      { display: 'Letter', colSize: '2' },
      { display: 'Condition', colSize: '10' },
    ];

    return (
      <div className='rules full-width'>
        <IonGrid>
          {weldingPattern?.conditions.length ? (
            <IonRow>
              <IonCol>
                <TableHeaders labels={tableHeadersRulesList} />
              </IonCol>
            </IonRow>
          ) : null}
          {weldingPattern?.conditions.map((condition, idx) => {
            return (
              <div key={idx}>
                <RulesRow condition={condition} />
              </div>
            );
          })}
        </IonGrid>
      </div>
    );
  };

  const AllocationsQuoteLineItemRow = ({
    allocation,
    index,
  }: {
    allocation: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      metal: MSTOAssemblyMetals;
      panelNumber?: number;
      panelLocation?: PanelLocations;
    };
    index: number;
  }) => {
    const { quoteLineItemData, quoteLineItemDetailData } = allocation;

    const uom: MeasurementSystems =
      (props.mstoData.selectedMetalsToAssemble[0].quoteLineItemData.Unit_of_Measure__c as MeasurementSystems) ??
      MeasurementSystems.Imperial;

    const imperialWidthField = quoteLineItemDetailData.PurchaseCladWidth__c;
    const metricWidthField = quoteLineItemDetailData.PurchaseCladWidth__c;
    const imperialLengthField = quoteLineItemDetailData.PurchaseCladLength__c;
    const metricLengthField = quoteLineItemDetailData.PurchaseCladLength__c;
    const imperialTKField = quoteLineItemDetailData.PurchaseCladTK__c;
    const metricTKField = quoteLineItemDetailData.PurchaseCladTK__c;

    return (
      <>
        <IonCard className='msto-quote-line-item-card seam-weld-card' data-testid={'QAMSTOAllocationsRow'}>
          <IonCardHeader class={'msto-quote-line-item-card-header'}>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol class='msto-quote-line-items-col' size='2'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Piece}
                    displayedValue={`${quoteLineItemData.piece} - 1`}
                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='2'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladWidth}
                    datatestid='QAMSTOAllocationWidth'
                    imperialValue={imperialWidthField}
                    metricValue={metricWidthField}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='2'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladLength}
                    datatestid='QAMSTOAllocationLength'
                    imperialValue={imperialLengthField}
                    metricValue={metricLengthField}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='2'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.CladTK}
                      datatestid='QAMSTOAllocationTK'
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={imperialTKField}
                      displayedMetricValue={metricTKField}
                      imperialValue={imperialTKField || 0}
                      metricValue={metricTKField || 0}
                      readOnly={true}
                    />
                  </div>
                </IonCol>
                <IonCol class='msto-quote-line-items-col location-dropdown' size='2'>
                  <div>
                    <SelectionContainer
                      type={SelectionTypes.DropDown}
                      placeholder='Location'
                      options={
                        getSelectionOptions(
                          selections,
                          manufacturingSite,
                          QuoteLineItemValueTypes.WeldingLocation
                        )?.options
                      }
                      style={{ width: '87px', marginTop: '5px', menuListHeight: '125px' }}
                      onChange={(location) => updatePanelLocationNumbers(index, location.label)}
                      value={panelData.get(index)?.panelLocation}
                    />
                  </div>
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='2'>
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    inputType='number'
                    placeholder='1'
                    regex={Regexes.IntegerAboveZero}
                    min='1'
                    style={{ width: '78px' }}
                    containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                    onBlur={panelNumber => panelData.get(index).panelNumber = panelNumber}
                    value={panelData.get(index)?.panelNumber}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const PatternPanelContainer = ({ numAdded, locations }) => {
    const totalClads = props.mstoData.selectedMetalsToAssemble.filter(assemblyPanel => assemblyPanel.metal === MSTOAssemblyMetals.CLAD).length;

    return (
      <div className='pattern-panel-container centered'>
        <IonGrid>
          <IonRow class='full-width'>
            <IonCol size='4'>
              <IonLabel class='sublabel'>Pattern of Welding Panels:</IonLabel>
              <br />
              <IonLabel class='sublabel'>{`${numAdded} of ${totalClads} used`}</IonLabel>
              <br />
              {showWarningMsg && <IonLabel class='sublabel error'>Invalid Welding Pattern</IonLabel>}
            </IonCol>
            <IonCol size='2'>
              <IonRow class='centered'>
                <SelectionContainer
                  selectionLabel={PanelLocations.Left}
                  labelPosition={SelectionLabelPositions.Left}
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  placeholder='0'
                  regex={Regexes.IntegerAboveZero}
                  min='0'
                  max={totalClads}
                  style={{
                    width: '87px',
                  }}
                  onBlur={(val) => {
                    updatePanelLocations({ ...locations, left: val });
                  }}
                  value={locations.left}
                  defaultValue={0}
                />
              </IonRow>
            </IonCol>
            <IonCol size='2'>
              <IonRow class='centered'>
                <SelectionContainer
                  selectionLabel={PanelLocations.Center}
                  labelPosition={SelectionLabelPositions.Left}
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  placeholder='0'
                  regex={Regexes.IntegerAboveZero}
                  min='0'
                  max={totalClads}
                  style={{
                    width: '87px',
                  }}
                  onBlur={(val) => {
                    updatePanelLocations({ ...locations, center: val });
                  }}
                  value={locations.center}
                />
              </IonRow>
            </IonCol>
            <IonCol size='2'>
              <IonRow class='centered'>
                <SelectionContainer
                  selectionLabel={PanelLocations.Right}
                  labelPosition={SelectionLabelPositions.Left}
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  placeholder='0'
                  regex={Regexes.IntegerAboveZero}
                  min='0'
                  max={totalClads}
                  style={{
                    width: '87px',
                  }}
                  onBlur={(val) => {
                    updatePanelLocations({ ...locations, right: val });
                  }}
                  value={locations.right}
                />
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  };

  return (
    <>
      <div className='popover seam-weld'>
        <TitleHeaderContainer
          darkMode={darkMode}
          closeIcon={closeIcon}
          closeIconWhite={closeIconWhite}
          dismissModal={dismissModal}
        />
        <hr className='mid-rule' />
        <PatternPanelContainer numAdded={numAdded} locations={locations} />
        <AllocationsPanelContainer />
        <RulesContainer />
        <SaveCancelButtonContainer addPanelLocations={addPanelLocations} weldingPattern={weldingPattern} dismissModal={dismissModal} />
      </div>
    </>
  );
};

export default SeamWeldPopover;

const TitleHeaderContainer = ({ darkMode, closeIcon, closeIconWhite, dismissModal }) => {
  return (
    <div>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonImg src={!darkMode ? closeIcon : closeIconWhite} class='close-icon' onClick={() => dismissModal()} />
            <IonGrid className='title-container'>
              <IonLabel class='title' data-testid={'QASeamWeldModalHeader'}>
                Clad Group Welding
              </IonLabel>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

const SaveCancelButtons = ({ addPanelLocations, weldingPattern, dismissModal }) => {
  const toast = useToast(4000);
  
  return (
    <>
      <IonButton
        color='primary'
        class='submit-order-button'
        onClick={() => {
          try {
            if (weldingPattern) {
              addPanelLocations();
              dismissModal();
            } else {
              toast(ToastTypes.Error, ToastMessages.MSTOInvalidWeldPattern);
            }
          } catch (error) {
            toast(ToastTypes.Error, ToastMessages.SaveFailed);
            console.log(error);
          }
        }}
      >
        Save
      </IonButton>
      <IonButton color='secondary' class='cancel-button' onClick={() => dismissModal()}>
        Cancel
      </IonButton>
    </>
  );
};

const SaveCancelButtonContainer = ({ addPanelLocations, weldingPattern, dismissModal }) => {
  return (
    <div className='save-cancel-button-container'>
      <IonGrid>
        <IonRow>
          <IonCol>
            <SaveCancelButtons addPanelLocations={addPanelLocations} weldingPattern={weldingPattern} dismissModal={dismissModal} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};
