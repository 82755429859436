import { OutsideServices } from "@constants";
import { AssemblyGroupFormKeys } from "@pages/new-assembly-groups/assembly-group-form-util";

interface IOutsideServiceFormKeyPair {
  durationEvent: string,
  totalTransitTime: string,
  costEvent: string,
  totalCostEvent?: string,
  outboundShippingCost: string,
  outboundTotalShippingCost: string,
  inboundShippingCost: string,
  inboundTotalShippingCost: string,
  vendor: string,
  notes: string,
  shipToCustomer: string,
  type?: string,
}

interface IOutsideServiceFormKeyMapper {
  [OutsideServices.TestingServicesBacker]: IOutsideServiceFormKeyPair,
  [OutsideServices.TestingServicesCladder]: IOutsideServiceFormKeyPair,
  [OutsideServices.TestingServicesCladProduct]: IOutsideServiceFormKeyPair,
  [OutsideServices.BackerMetalHeatTreatment]: IOutsideServiceFormKeyPair,
  [OutsideServices.CladderMetalHeatTreatment]: IOutsideServiceFormKeyPair,
  [OutsideServices.PostbondHeatTreatment]: IOutsideServiceFormKeyPair,
  [OutsideServices.Welding]: IOutsideServiceFormKeyPair,
  [OutsideServices.XRayServices]: IOutsideServiceFormKeyPair,
  [OutsideServices.FlatteningBeforeCutting]: IOutsideServiceFormKeyPair,
  [OutsideServices.Cutting]: IOutsideServiceFormKeyPair,
  [OutsideServices.WaterjetCutting]: IOutsideServiceFormKeyPair,
  [OutsideServices.FlatteningAfterCutting]: IOutsideServiceFormKeyPair,
  [OutsideServices.Machining]: IOutsideServiceFormKeyPair,
  [OutsideServices.InspectionBacker]: IOutsideServiceFormKeyPair,
  [OutsideServices.InspectionCladder]: IOutsideServiceFormKeyPair,
  [OutsideServices.InspectionCladProduct]: IOutsideServiceFormKeyPair,
  [OutsideServices.Packaging]: IOutsideServiceFormKeyPair,
};

export const OutsideServiceFormKeyMapper: IOutsideServiceFormKeyMapper = {
  [OutsideServices.TestingServicesBacker]: {
    durationEvent: AssemblyGroupFormKeys.TestingBKDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.TestingBKTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.TestingBKCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.TestingBKOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.TestingBKTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.TestingBKInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.TestingBKTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.TestingBKVendor,
    notes: AssemblyGroupFormKeys.TestingBKNotes,
    shipToCustomer: AssemblyGroupFormKeys.TestingBKShipToCustomer,
  },
  [OutsideServices.TestingServicesCladder]: {
    durationEvent: AssemblyGroupFormKeys.TestingCLDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.TestingCLTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.TestingCLCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.TestingCLOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.TestingCLTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.TestingCLInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.TestingCLTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.TestingCLVendor,
    notes: AssemblyGroupFormKeys.TestingCLNotes,
    shipToCustomer: AssemblyGroupFormKeys.TestingCLNotes,
  },
  [OutsideServices.TestingServicesCladProduct]: {
    durationEvent: AssemblyGroupFormKeys.TestingDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.TestingTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.TestingCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.TestingOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.TestingTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.TestingInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.TestingTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.TestingVendor,
    notes: AssemblyGroupFormKeys.TestingNotes,
    shipToCustomer: AssemblyGroupFormKeys.TestingShipToCustomer,
  },
  [OutsideServices.BackerMetalHeatTreatment]: {
    durationEvent: AssemblyGroupFormKeys.BackerMetalHTDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.BackerMetalHTTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.BackerMetalHTCostOfEvent,
    totalCostEvent: AssemblyGroupFormKeys.BackerMetalHTTotalCostOfEvent,
    outboundShippingCost: AssemblyGroupFormKeys.BackerMetalHTOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.BackerMetalHTTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.BackerMetalHTInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.BackerMetalHTTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.BackerMetalHTVendor,
    notes: AssemblyGroupFormKeys.BackerMetalHTNotes,
    shipToCustomer: AssemblyGroupFormKeys.BackerMetalHTShipToCustomer,
    type: AssemblyGroupFormKeys.BackerMetalHTType,
  },
  [OutsideServices.CladderMetalHeatTreatment]: {
    durationEvent: AssemblyGroupFormKeys.CladderMetalHTDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.CladderMetalHTTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.CladderMetalHTCostOfEvent,
    totalCostEvent: AssemblyGroupFormKeys.CladderMetalHTTotalCostOfEvent,
    outboundShippingCost: AssemblyGroupFormKeys.CladderMetalHTOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.CladderMetalHTTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.CladderMetalHTInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.CladderMetalHTTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.CladderMetalHTVendor,
    notes: AssemblyGroupFormKeys.CladderMetalHTNotes,
    shipToCustomer: AssemblyGroupFormKeys.CladderMetalHTShipToCustomer,
    type: AssemblyGroupFormKeys.CladderMetalHTType,
  },
  [OutsideServices.PostbondHeatTreatment]: {
    durationEvent: AssemblyGroupFormKeys.PostBondHTDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.PostBondHTTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.PostBondHTCostOfEvent,
    totalCostEvent: AssemblyGroupFormKeys.PostBondHTTotalCostOfEvent,
    outboundShippingCost: AssemblyGroupFormKeys.PostBondHTOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.PostBondHTTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.PostBondHTInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.PostBondHTTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.PostBondHTVendor,
    notes: AssemblyGroupFormKeys.PostbondHTNotes,
    shipToCustomer: AssemblyGroupFormKeys.PostBondHTShipToCustomer,
    type: AssemblyGroupFormKeys.PostBondHTType,
  },
  [OutsideServices.Welding]: {
    durationEvent: AssemblyGroupFormKeys.WeldingDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.WeldingTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.WeldingCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.WeldingOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.WeldingTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.WeldingInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.WeldingTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.WeldingVendor,
    notes: AssemblyGroupFormKeys.WeldingNotes,
    shipToCustomer: AssemblyGroupFormKeys.WeldingShipToCustomer,
  },
  [OutsideServices.XRayServices]: {
    durationEvent: AssemblyGroupFormKeys.XRayServicesDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.XRayServicesTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.XRayServicesCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.XRayServicesOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.XRayServicesTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.XRayServicesInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.XRayServicesTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.XRayServicesVendor,
    notes: AssemblyGroupFormKeys.XRayServicesNotes,
    shipToCustomer: AssemblyGroupFormKeys.XRayServicesShipToCustomer,
  },
  [OutsideServices.FlatteningBeforeCutting]: {
    durationEvent: AssemblyGroupFormKeys.FlatteningBKDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.FlatteningBKTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.FlatteningBKCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.FlatteningBKOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.FlatteningBKTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.FlatteningBKInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.FlatteningBKTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.FlatteningBKVendor,
    notes: AssemblyGroupFormKeys.FlatteningBKNotes,
    shipToCustomer: AssemblyGroupFormKeys.FlatteningBKShipToCustomer,
  },
  [OutsideServices.Cutting]: {
    durationEvent: AssemblyGroupFormKeys.CuttingDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.CuttingTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.CuttingCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.CuttingOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.CuttingTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.CuttingInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.CuttingTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.CuttingVendor,
    notes: AssemblyGroupFormKeys.CuttingNotes,
    shipToCustomer: AssemblyGroupFormKeys.CuttingShipToCustomer,
  },
  [OutsideServices.WaterjetCutting]: {
    durationEvent: AssemblyGroupFormKeys.WaterJetCuttingDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.WaterJetCuttingTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.WaterJetCuttingCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.WaterJetCuttingOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.WaterJetCuttingTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.WaterJetCuttingInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.WaterJetCuttingTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.WaterJetCuttingVendor,
    notes: AssemblyGroupFormKeys.WaterJetCuttingNotes,
    shipToCustomer: AssemblyGroupFormKeys.WaterJetCuttingShipToCustomer,
  },
  [OutsideServices.FlatteningAfterCutting]: {
    durationEvent: AssemblyGroupFormKeys.FlatteningDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.FlatteningTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.FlatteningCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.FlatteningOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.FlatteningTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.FlatteningInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.FlatteningTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.FlatteningVendor,
    notes: AssemblyGroupFormKeys.OutsideServiceFlatteningNotes,
    shipToCustomer: AssemblyGroupFormKeys.FlatteningShipToCustomer,
  },
  [OutsideServices.Machining]: {
    durationEvent: AssemblyGroupFormKeys.MachiningDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.MachiningTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.MachiningCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.MachiningOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.MachiningTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.MachiningInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.MachiningTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.MachiningVendor,
    notes: AssemblyGroupFormKeys.OutsideServiceMachiningNotes,
    shipToCustomer: AssemblyGroupFormKeys.MachiningShipToCustomer,
  },
  [OutsideServices.InspectionBacker]: {
    durationEvent: AssemblyGroupFormKeys.InspectionBKDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.InspectionBKTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.InspectionBKCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.InspectionBKOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.InspectionBKTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.InspectionBKInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.InspectionBKTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.InspectionBKVendor,
    notes: AssemblyGroupFormKeys.InspectionBKNotes,
    shipToCustomer: AssemblyGroupFormKeys.InspectionBKShipToCustomer,
  },
  [OutsideServices.InspectionCladder]: {
    durationEvent: AssemblyGroupFormKeys.InspectionCLDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.InspectionCLTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.InspectionCLCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.InspectionCLOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.InspectionCLTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.InspectionCLInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.InspectionCLTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.InspectionCLVendor,
    notes: AssemblyGroupFormKeys.InspectionCLNotes,
    shipToCustomer: AssemblyGroupFormKeys.InspectionCLShipToCustomer,
  },
  [OutsideServices.InspectionCladProduct]: {
    durationEvent: AssemblyGroupFormKeys.InspectionDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.InspectionTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.InspectionCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.InspectionOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.InspectionTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.InspectionInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.InspectionTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.InspectionVendor,
    notes: AssemblyGroupFormKeys.InspectionNotes,
    shipToCustomer: AssemblyGroupFormKeys.InspectionShipToCustomer,
  },
  [OutsideServices.Packaging]: {
    durationEvent: AssemblyGroupFormKeys.PackagingDurationOfEventDays,
    totalTransitTime: AssemblyGroupFormKeys.PackagingTotalTransitTimeDays,
    costEvent: AssemblyGroupFormKeys.PackagingCostOfEvent,
    totalCostEvent: null,
    outboundShippingCost: AssemblyGroupFormKeys.PackagingOutboundShippingCost,
    outboundTotalShippingCost: AssemblyGroupFormKeys.PackagingTotalOutboundShippingCost,
    inboundShippingCost: AssemblyGroupFormKeys.PackagingInboundShippingCost,
    inboundTotalShippingCost: AssemblyGroupFormKeys.PackagingTotalInboundShippingCost,
    vendor: AssemblyGroupFormKeys.PackagingVendor,
    notes: AssemblyGroupFormKeys.OutsideServicePackagingNotes,
    shipToCustomer: AssemblyGroupFormKeys.PackagingShipToCustomer,
  },
}