import React from 'react';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import { FerroxylTestOfCladdingSurfacePerformed } from '@constants';

const AppDropdownFerroxylTest = (props) => {
  const options = [
    FerroxylTestOfCladdingSurfacePerformed.PriorToHeatTreatment,
    FerroxylTestOfCladdingSurfacePerformed.PriorToShipment,
    FerroxylTestOfCladdingSurfacePerformed.PriorToBothHeatTreatmentAndShipment,
  ].map((item) => {
    return {
      label: item,
      value: item
    };
  });

  return <AppDropdown options={options} {...props}/>
};

export default AppDropdownFerroxylTest;