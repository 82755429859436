import React from 'react';
import { Subscription } from 'rxjs';
import { UserType } from '@constants';
import { useSalesforce } from '@services/salesforce/salesforce-service';
import { useHistory } from 'react-router-dom';
import { IonList, IonItem, IonLabel } from '@ionic/react';
import { useEnvironment } from '@services/environment-service';
import './my-account-popover.scss';
import { handleLogin } from '../../../guards/auth-guard-service';
import { useAuth } from '@services/auth-service';

const MyAccountPopover = (props) => {
  const salesforce = useSalesforce();
  const environment = useEnvironment();
  const auth = useAuth();
  const history = useHistory();

  const [contact, setContact] = React.useState(undefined);
  const [account, setAccount] = React.useState(undefined);

  const subscriptions: Subscription[] = [];

  React.useEffect(() => {
    subscriptions.push(salesforce.userInfo$().subscribe(async userInfo => {
      setContact(userInfo?.contact);
      setAccount(userInfo?.account);
    }));

    return () => {
      subscriptions.forEach(s => s.unsubscribe());
    };
  }, []);

  // Closes popover and navigates to the my profile page
  const navigateToMyProfile = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
    history.push('/my-profile');
  };

  const login = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
    handleLogin(auth.authConnectService.login());
  };

  const logout = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
    auth.logout(auth.authConnectService.logout());
  };

  const userType = () => {
    return environment.userType();
  };

  const showLogout = () => {
    return userType() !== UserType.Sales && isLoggedIn();
  };

  const isLoggedIn = () => {
    return !!contact && !!account;
  };

  const AccountInfoContainer = () => {
    return (
      isLoggedIn() ?
        <div className="account-info-container">
          <div className="contact-account-container">
            {/* disabling the click for now, until a my-profile page is created (story 11571) */}
            <div className="contact-name" /*onClick={() => navigateToMyProfile() }*/>{contact.FirstName} {contact.LastName}</div>
            <div className="account-name">{account.Name}</div>
          </div>
        </div> : null
    );
  };

  const LoginButtonContainer = () => {
    return (
      !isLoggedIn() ?
        <IonItem class="ion-no-padding" onClick={() => login() }>
          <IonLabel>sign in to your account</IonLabel>
        </IonItem> : null
    );
  };

  const LogoutButtonContainer = () => {
    return (
      showLogout() ?
        <IonItem class="ion-no-padding" onClick={() => logout()}>
          <IonLabel>logout</IonLabel>
        </IonItem> : null
    );
  };

  return (
    <div className="popup-container">
      <div className="account-container">
        <AccountInfoContainer/>
        <IonList lines="none" class="ion-no-padding">
          <LoginButtonContainer/>
          <LogoutButtonContainer/>
        </IonList>
      </div>
    </div>
  );
};

export default MyAccountPopover;