import { getFinishedGoods } from './finished-goods-api';
import * as React from 'react';
import { FinishedGood } from '@interfaces/finished-good';

const { createContext, useContext } = React;

const FinishedGoodsContext = createContext(null);

export const FinishedGoodsProvider = (props) => {
  const value = {
    getAllFinishedGoods: props.getAllFinishedGoods || getAllFinishedGoods,
  };

  return (
    <FinishedGoodsContext.Provider value={value}>
      {props.children}
    </FinishedGoodsContext.Provider>
  );
};

export const useFinishedGoods = () => {
  return useContext(FinishedGoodsContext);
};

// Calls api to get all the finished goods from cosmos
export const getAllFinishedGoods = async(): Promise<FinishedGood[]> => {
  try {
    return await getFinishedGoods();
  } catch (error) {
    console.error(error);
    return null;
  }
};
