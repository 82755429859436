import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import * as React from 'react';
import '../checkbox-item/checkbox-item.scss';
import classNames from 'classnames';

const AppCheckboxItem = (props) => {
  // hack to stop calling the event if the props.checked changes progmatically
  // until ionic fix this issue on their end
  let hasFocus = false;

  return (
    <IonItem class='checkbox-item' lines="none"  style={props.style}>
      <IonCheckbox
        className={classNames({
        'checkbox': true,
        'checkbox-readonly': props.readOnly})}
        key={`${props.label}-${props.checked}`}
        style={props.checkboxStyle}
        slot="start"
        color="primary"
        checked={props.checked}
        onFocus={() => (hasFocus = !hasFocus)}
        onIonChange={(e) => {
          if (!hasFocus) {
            return;
          }

          const value = e.detail.checked;

          if (props.onIonChange) {
            props.onIonChange(value);
          }

          if (props.onChange) {
            props.onChange(value);
          }
        }}
        disabled={props.disabled}
      />
      <IonLabel class='checkbox-label'>{props.label}</IonLabel>
    </IonItem>
  );
};

export default AppCheckboxItem;