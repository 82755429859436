/* eslint-disable @typescript-eslint/no-empty-function */
import { MeasurementSystems } from '@constants';
import React, { createContext, useContext, useMemo, useState } from 'react';

export interface InitUomContextProps {
  displayUom: MeasurementSystems;
  setDisplayUom: (uom: MeasurementSystems) => void;
}

interface UomContextProps {
  displayUom: MeasurementSystems;
  setDisplayUom: (uom: MeasurementSystems) => void;
  isDisplayImperial: boolean;
  isDisplayMetric: boolean;
}

interface UomProviderProps {
  children: React.ReactNode;
  system?: MeasurementSystems;
}

const UomContext = createContext<UomContextProps>({} as UomContextProps);

export const UomProvider = ({ children, system = MeasurementSystems.Imperial }: UomProviderProps) => {
  const [displayUom, setDisplayUom] = useState<MeasurementSystems>(system);

  // context value object
  const value: UomContextProps = useMemo(() => {
    return {
      displayUom,
      setDisplayUom,
      isDisplayImperial: displayUom === MeasurementSystems.Imperial,
      isDisplayMetric: displayUom === MeasurementSystems.Metric,
    };
  }, [displayUom]);
  return <UomContext.Provider value={value}>{children}</UomContext.Provider>;
};

export const useDisplayUom = (): UomContextProps => {
  const context: UomContextProps = useContext(UomContext);
  if (context === null || context === undefined) {
    throw new Error('You probably forgot the <UomProvider> context provider');
  }
  return context;
};

/* usage example:
  const { displayUom, setDisplayUom } = useDisplayUom();
*/