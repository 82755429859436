
export function handleLogin(login) {
  login.catch(e => {
    console.error(e);
  });
}

export function handleLogout(logout) {
  logout.catch(e => {
    console.error(e);
  });
}
