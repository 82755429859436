import React, { useEffect, useState } from 'react';

import Modal from '@components/modal/modal';
import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import AppCheckboxItem from '@components/app-checkbox-item/app-checkbox-item';
import AppLineItem from '@components/app-line-item/app-line-item';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import './customer-specified-spwht.scss';
import AppInput from '@components/app-input/app-input';
import AppRadioButtons from '@components/app-radio-buttons/app-radio-buttons';
import { FormKeys } from '../advance-specification-form-util';
import { QuoteLineItemValueTypes } from '@constants';
import AppDropdownSelection from '@components/app-dropdown/app-dropdown-selection';

interface Props {
  form?: any,
  onSave,
  areaId: any,
  onDismiss: (payload) => void
};

interface GroupPanelProps {
  group: String,
  setForm: any,
  areaId: any,
  form: {
    Cycle1: any,
    Cycle2: any,
    Cycle3: any,
    Performed: any,
  }
}

const extractValues = (value) => {
  const match = value && value.match(/temp: \d+ [fc], hold time: \d+ mins/i);
  const item = {
    value: null,
    min: null,
    uom: null,
  };

  if (!match) {
    return item;
  }

  const [
    temp,
    holdTime
  ] = match[0].split(',');

  const matches = {
    temp: temp.match(/\d+ [fc]/i),
    holdTime: holdTime.match(/\d+/i)
  };

  if (matches.temp) {
    const valueUOM = matches.temp[0].split(' ');
    item.value = valueUOM[0];
    item.uom = valueUOM[1];
  }

  if (matches.holdTime) {
    item.min = matches.holdTime[0];
  }

  return item;
}

const Cycle = (props) => {
  const { group, cycleNumber, value: currentValue, updateForm } = props;
  const extracted = extractValues(currentValue);
  const [value, setValue] = useState(extracted.value);
  const [min, setMin] = useState(extracted.min);
  const [uom, setUOM] = useState(extracted.uom);

  useEffect(() => {
    let cycle = null;

    if(value && uom && min) {
      cycle = `Cycle ${cycleNumber} - Temp: ${value} ${uom}, Hold Time: ${min} mins`;
    }

    updateForm({
      [`Cycle${cycleNumber}`]: cycle,
    });
  }, [value, uom, min])

  return <>
    <IonGrid className='customer-specified-spwht-cycle' key={`${group}-cycle-${cycleNumber}`}>
      <IonRow>
        <IonCol size="1.2">
          <IonLabel> Cycle {cycleNumber} </IonLabel>
        </IonCol>
        <IonCol size="1.8">
          <AppInput value={min} onChange={setMin}/>
        </IonCol>
        <IonCol size="1.5">
          <IonLabel> minutes at </IonLabel>
        </IonCol>
        <IonCol size="1.5">
          <AppInput value={value} onChange={setValue}/>
        </IonCol>
        <IonCol size="6">
          <AppRadioButtons key={`group-${group}-cycle-${cycleNumber}-radio`} selected={uom} onChange={setUOM} options={[ 'F', 'C' ]}/>
        </IonCol>
      </IonRow>
    </IonGrid>
  </>
};

const GroupPanel = ({ group, form, setForm, areaId }: GroupPanelProps) => {
  const cycle = [1, 2, 3];
  const valueType: QuoteLineItemValueTypes = `SPWHTGroup${group}ToBePerformed` as QuoteLineItemValueTypes;

  const onUpdateForm = (key) => {
    return (value) => {
      setForm({
        ...form,
        [key]: value,
      })
    }
  };

  return <>
    <AppLineItemColumns
      className='customer-specified-spwht-group-header'
      components={[
        {
          key: `group-${group}-checkbox`,
          style: {
            display: 'flex',
            alignItems: 'center',
          },
          component: <IonLabel>{`SPWHT Group ${group}`}</IonLabel>
          // component: <AppCheckboxItem label={`SPWHT Group ${group}`} />
        },
        {
          key: 'group-a-dropdown',
          component: <AppLineItem
              label="To Be Performed"
              sizes={['4', '8']}
            >
            <AppDropdownSelection
              label={`SPWHT Group ${group}`}
              placeholder="Select Time to be Performed"
              onChange={({ value }) => onUpdateForm('Performed')(value)}
              value={form.Performed}
              areaId={areaId}
              valueType={valueType}
            />
          </AppLineItem>
        }
      ]}
    />

    {
      cycle.map((c, index) => {
        return <Cycle
          key={`c-group-${group}-cycle-${index}`}
          group={group}
          cycleNumber={c}
          value={form[`Cycle${c}`]}
          updateForm={(newCycleData) => {
            setForm({
              ...form,
              ...newCycleData
            })
          }} />
      })
    }
  </>
};

const CustomerSpecifiedSPWHT = ({onDismiss, onSave, form, areaId}: Props) => {
  const [groupA, setGroupA] = useState({
    Cycle1: form[FormKeys.SPWHTGroupACycle1],
    Cycle2: form[FormKeys.SPWHTGroupACycle2],
    Cycle3: form[FormKeys.SPWHTGroupACycle3],
    Performed: form[FormKeys.SPWHTGroupAToBePerformed],
  });

  const [groupB, setGroupB] = useState({
    Cycle1: form[FormKeys.SPWHTGroupBCycle1],
    Cycle2: form[FormKeys.SPWHTGroupBCycle2],
    Cycle3: form[FormKeys.SPWHTGroupBCycle3],
    Performed: form[FormKeys.SPWHTGroupBToBePerformed],
  });

  const [groupC, setGroupC] = useState({
    Cycle1: form[FormKeys.SPWHTGroupCCycle1],
    Cycle2: form[FormKeys.SPWHTGroupCCycle2],
    Cycle3: form[FormKeys.SPWHTGroupCCycle3],
    Performed: form[FormKeys.SPWHTGroupCToBePerformed],
  });

  const [ heatCoolingRate, setHeatCoolingRate ] = useState({
    value: form[FormKeys.SPWHTHeatingAndCoolingRateAbove],
    uom: form[FormKeys.SPWHTHeatingAndCoolingRateAboveUOM],
  });

  const [ maxCoolingRate, setMaxCoolingRate ] = useState({
    value: form[FormKeys.SPWHTMaximumCoolingRateHour],
    uom: form[FormKeys.SPWHTMaximumCoolingRateHourUOM],
  });

  const [ maxHeatingRate, setMaxHeatingRate ] = useState({
    value: form[FormKeys.SPWHTMaximumHeatingRateHour],
    uom: form[FormKeys.SPWHTMaximumHeatingRateHourUOM],
  });

  const actionsButtons = [
    {
      label: 'Save',
      color: 'primary',
      onClick: () => {
        let payload = {
          [FormKeys.SPWHTGroupACycle1]: groupA.Cycle1,
          [FormKeys.SPWHTGroupACycle2]: groupA.Cycle2,
          [FormKeys.SPWHTGroupACycle3]: groupA.Cycle3,
          [FormKeys.SPWHTGroupAToBePerformed]: groupA.Performed,

          [FormKeys.SPWHTGroupBCycle1]: groupB.Cycle1,
          [FormKeys.SPWHTGroupBCycle2]: groupB.Cycle2,
          [FormKeys.SPWHTGroupBCycle3]: groupB.Cycle3,
          [FormKeys.SPWHTGroupBToBePerformed]: groupB.Performed,

          [FormKeys.SPWHTGroupCCycle1]: groupC.Cycle1,
          [FormKeys.SPWHTGroupCCycle2]: groupC.Cycle2,
          [FormKeys.SPWHTGroupCCycle3]: groupC.Cycle3,
          [FormKeys.SPWHTGroupCToBePerformed]: groupC.Performed,
        };

        if (heatCoolingRate.value && heatCoolingRate.uom) {
          payload = {
            ...payload,
            ...{
              [FormKeys.SPWHTHeatingAndCoolingRateAbove]: heatCoolingRate.value,
              [FormKeys.SPWHTHeatingAndCoolingRateAboveUOM]: heatCoolingRate.uom,
            }
          };
        }

        if (maxCoolingRate.value && maxCoolingRate.uom) {
          payload = {
            ...payload,
            ...{
              [FormKeys.SPWHTMaximumCoolingRateHour]: maxCoolingRate.value,
              [FormKeys.SPWHTMaximumCoolingRateHourUOM]: maxCoolingRate.uom,
            }
          };
        }

        if (maxHeatingRate.value && maxHeatingRate.uom) {
          payload = {
            ...payload,
            ...{
              [FormKeys.SPWHTMaximumHeatingRateHour]: maxHeatingRate.value,
              [FormKeys.SPWHTMaximumHeatingRateHourUOM]: maxHeatingRate.uom,
            }
          };
        }

        onSave(payload)
      }
    },
    {
      label: 'Cancel',
      color: 'secondary',
      onClick: () => {
        onDismiss({})
      }
    }
  ];

  return <>
    <Modal
      title="Customer Specified SPWHT"
      options={{
        isOpen: true,
        size: 'adv-spec'
      }}
      onClickClose={() => onDismiss({})}
      actionButtons={actionsButtons}
    >
      <div className='customer-specified-spwht-container'>
        <GroupPanel group={'A'} form={groupA} setForm={setGroupA} areaId={areaId}/>
        <GroupPanel group={'B'} form={groupB} setForm={setGroupB} areaId={areaId}/>
        <GroupPanel group={'C'} form={groupC} setForm={setGroupC} areaId={areaId}/>

        <AppLineItem
          label="Heating and Cooling Rate Above"
          sizes={['3.5', '8.5']}
        >
          <AppLineItemColumns
            components={[
              {
                size: 2,
                component: <AppInput value={heatCoolingRate.value} onChange={(value) => setHeatCoolingRate({
                  ...heatCoolingRate,
                  value,
                })}/>,
              },
              {
                size: 10,
                component: <AppRadioButtons
                  key={FormKeys.SPWHTHeatingAndCoolingRateAboveUOM}
                  options={['F', 'C']}
                  selected={heatCoolingRate.uom}
                  onChange={(value) => setHeatCoolingRate({
                    ...heatCoolingRate,
                    uom: value,
                  })}
                />,
              }
            ]}
          />
       </AppLineItem>

       <AppLineItem
          label="Maximum Heating Rate/Hour"
          sizes={['3.5', '8.5']}
        >
          <AppLineItemColumns
            components={[
              {
                size: 2,
                component: <AppInput
                  value={maxHeatingRate.value}
                  onChange={(value) => setMaxHeatingRate({
                    ...maxHeatingRate,
                    value,
                  })}
                />,
              },
              {
                size: 10,
                component: <AppRadioButtons
                  key={FormKeys.SPWHTMaximumHeatingRateHourUOM}
                  options={['F', 'C']}
                  selected={maxHeatingRate.uom}
                  onChange={(value) => setMaxHeatingRate({
                    ...maxHeatingRate,
                    uom: value,
                  })}
                />,
              }
            ]}
          />
       </AppLineItem>

       <AppLineItem
          label="Maximum Cooling Rate/Hour"
          sizes={['3.5', '8.5']}
        >
          <AppLineItemColumns
            components={[
              {
                size: 2,
                component: <AppInput
                  value={maxCoolingRate.value}
                  onChange={(value) => setMaxCoolingRate({
                    ...maxCoolingRate,
                    value,
                  })}
                />,
              },
              {
                size: 10,
                component: <AppRadioButtons
                  key={FormKeys.SPWHTMaximumCoolingRateHourUOM}
                  options={['F', 'C']}
                  selected={maxCoolingRate.uom}
                  onChange={(value) => setMaxCoolingRate({
                    ...maxCoolingRate,
                    uom: value,
                  })}
                />,
              }
            ]}
          />
       </AppLineItem>
      </div>
    </Modal>
  </>
};

export default CustomerSpecifiedSPWHT;