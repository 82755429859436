import React, {useState, useEffect} from 'react';

import './app-radio-button.scss';
import AppRadioButtons from './app-radio-buttons';
import { useDataState } from '@context/data-context';

const AppRadioButtonsSelection = (props) => {
  const { dataState } = useDataState();
  const { selections } = dataState;
  const { valueType, areaId, value, onChange } = props;

  const getOptions = () => {
    if (
      !valueType
      || !areaId
      || !selections
      || (selections && selections.length === 0)
    ) {
      return [];
    }

    const item = selections.find((option) => {
      return option.valueType === valueType
        && option.dataAreaId === areaId;
    });

    return item;
  };

  const item: any = getOptions();
  const options = (item && item?.options?.map((option) => {
    return { label: option.name, value: option.value };
  })) || [];

  return <AppRadioButtons options={options} {...props}/>
};

export default AppRadioButtonsSelection;