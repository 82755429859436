import { LastViewed } from '@interfaces/last-viewed';
import { getCall, postCall } from '@services/rest-service';

// Retrieves last viewed for the item from cosmos
export const getLastViewed = (itemId: string): Promise<string> => {
  return getCall<string>(`last-viewed/${itemId}`);
};

// Updates last viewed for the item in cosmos
export const updateLastViewed = (lastViewed: LastViewed): Promise<any> => {
  return postCall('last-viewed', lastViewed);
};
