import DocumentLine from '@components/document-line/document-line';
import EmptyTableRow from '@components/empty-table-row/empty-table-row';
import ViewAllDocumentsPopover from '@components/popover/view-all-documents-popover/view-all-documents-popover';
import { DocumentsColumnHeadings } from '@constants';
import { IonCard, IonCardHeader, IonCol, IonGrid, IonIcon, IonLabel, IonPopover, IonRow } from '@ionic/react';
import * as React from 'react';
import './documents.scss';
import infoIcon from '@assets/icon/info-icon-blue.svg';

const Documents = (props) => {
  const [documentHeadings] = React.useState(DocumentsColumnHeadings);
  const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

  const DocumentsList = () => {
    return ( props.displayedDocuments?.length ?
      <>{props.displayedDocuments?.map((document, index) => (
        <DocumentLine document={document} documentIndex={index} key={index} setDocumentUpdated={props.setDocumentUpdated} showDocumentActions={props.showDocumentActions} />
      ))}</> :
      <EmptyTableRow text={'No Documents Available'}/>
    );
  };

  const ViewAllDocumentsLink = () => {
    return (props.mappedDocuments?.length > 6 ?
      <IonLabel class='view-all-documents-link' onClick={(e: any) => {
        e.persist();
        setShowPopover({ showPopover: true, event: e });
      }} data-testid="QADocumentsViewAllDocuments"
      >View All Documents</IonLabel>
      : null
    );
  };

  const DocumentsInfoText = () => {
    return !!props.mappedDocuments?.length && !props.showDocumentActions ? (
      <>
        <IonIcon class="info-icon" icon={infoIcon}></IonIcon>
        <IonLabel class="documents-info">
          Documents are unavailable for viewing and download until payment is received.
        </IonLabel>
        <hr />
      </>
    ) : null;
  };

  return (
    <>
      <IonPopover
        cssClass='view-all-documents-popover'
        isOpen={popoverState.showPopover}
        animated={ false }
        onDidDismiss={() => {
          setShowPopover({ showPopover: false, event: undefined });
        }}
      >
        <ViewAllDocumentsPopover
          setShowPopover={setShowPopover}
          documents={props.mappedDocuments}
          setDocumentUpdated={props.setDocumentUpdated}
          showDocumentActions={props.showDocumentActions}
        />
      </IonPopover>
      <div className="documents-container">
        <div className='header'>
          <IonLabel class="documents-label">Documents</IonLabel>
          <ViewAllDocumentsLink/>
        </div>
        <hr />
        <DocumentsInfoText />
        <IonCard class="documents-card">
          <IonCardHeader class="documents-card-header">
            <IonGrid>
              <IonRow class="documents-row">
                <IonCol class="documents-col" size="10" data-testid="QADocumentsDocumentNameColumnHeader">
                  <span className="documents-text">{ documentHeadings.Name }</span>
                </IonCol>
                <IonCol class="documents-col" size="2" data-testid="QADocumentsDocumentDateAddedColumnHeader">
                  <span className="documents-text">{ documentHeadings.DateAdded }</span>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <DocumentsList/>
        <hr className='bottom-rule'/>
      </div>
    </>
  );
};

export default Documents;