import * as React from 'react';
import { IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';
import './dashboard.scss';
import Footer from '@components/footer/footer';
import { useHistory } from 'react-router';
import { AccountBalanceTypes, DashboardPageOrdersColumnHeadings, OrderAPIStatuses, SearchFields } from '@constants';
import { useSalesforce } from '@services/salesforce/salesforce-service';
import { formatCurrency } from '@shared/utils';
import { findOrderById, mapOrder } from '@shared/order-utils';
import Loading from '@components/loading/loading';
import classNames from 'classnames';
import { useCurrencyConversion } from '@services/currency-conversion-service';
import { Order } from '@interfaces/order';
import { usePlatform } from '@services/platform-service';
import EmptyTableRow from '@components/empty-table-row/empty-table-row';
import SearchInput from '@components/search-input/search-input';
import Badge from '@components/badge/badge';
import { useLastViewed } from '@services/last-viewed/last-viewed-service';
import NotificationsList from '@components/notifications-list/notifications-list';
import ncSymbolWhite from '@assets/images/symbol-nc-white.png';
import { Doughnut } from 'react-chartjs-2';
import moment from 'moment';
import { useUser } from '@services/user/user-service';
import { useDarkMode } from '@hooks/useDarkMode';
import { useEnvironment } from '@services/environment-service';
import { MappedNotification, MappedOrder } from '@shared/types';
import { Invoice } from '@interfaces/invoice';
import { Notification } from '@interfaces/notification';

const SearchContainer = (props) => {
  const environment = useEnvironment();
  const history = useHistory();

  const [orderSearchInput, setOrderSearchInput] = React.useState(undefined);

  // Returns internal view flag from environment
  const internalView = () => {
    return environment?.internalView();
  };

  // Navigates to the orders page with search value
  const navigateToOrdersPage = (orderAccount?: string) => {
    history.push('/orders', { orderSearchInput: orderAccount || orderSearchInput});
  };

  // Navigates to the order detail page by order id
  const navigateToOrderDetailById = (orderId: string) => {
    history.push(`/orders/order-detail/${orderId}`);
  };

  // Action that occurs when clicking on an order search suggestion
  const suggestionClickHandler = async (suggestionClicked: any) => {
    if(suggestionClicked.matchingDisplayField === SearchFields.AXOrderNumber || suggestionClicked.matchingDisplayField === SearchFields.PoNumber) {
      const order = await findOrderById(props.orders, suggestionClicked.Id);

      if(order) {
        props.navigateToOrderDetail(order);
      } else {
        navigateToOrderDetailById(suggestionClicked.Id);
      }
    } else {
      navigateToOrdersPage(suggestionClicked[SearchFields.AccountName]);
    }
  };

  //Returns order suggestions depending on orderSearchValues or orders
  const getSuggestions = () => {
    return !props.orderSearchValues?.length ?
      props.orders :
      props.orderSearchValues;
  };

  return (
    <SearchInput
      noneFoundText='No orders found'
      placeholder={internalView() ? 'Search...' : 'Search by order number'}
      suggestions={getSuggestions()}
      suggestionDisplayFields={[SearchFields.AXOrderNumber, SearchFields.PoNumber]}
      commonSuggestionDisplayFields={[SearchFields.AccountName]}
      searchInputChangedHandler={(searchInput) => setOrderSearchInput(searchInput)}
      suggestionClickHandler={(suggestionClicked) => suggestionClickHandler(suggestionClicked)}
      searchButtonClickHandler={() => navigateToOrdersPage()}
    />
  );
};

interface Props {
  orders: Order[];
  orderSearchValues: any[];
  invoices: Invoice[];
  unmappedNotifications: Notification[];
  finalizedNotifications: MappedNotification[];
  loadNotifications: () => void;
}

const DashboardPage = (props: Props) => {
  const displayedNotifications = React.useMemo(() => {
    return props.finalizedNotifications.slice(0, 4);
  }, [props.finalizedNotifications]);
    
  const salesforce = useSalesforce();
  const currencyConversion = useCurrencyConversion();
  const platform = usePlatform();
  const lastViewed = useLastViewed();
  
  const user = useUser();
  const { darkMode } = useDarkMode();
  const history = useHistory();
  const [headings] = React.useState(DashboardPageOrdersColumnHeadings);
  const [displayedOrders, setDisplayedOrders] = React.useState<MappedOrder[]>(undefined);
  const [selectedCurrency, setSelectedCurrency] = React.useState(undefined);
  const [loading, setLoading] = React.useState(true);
  const [orderSearchValues] = React.useState(props.orderSearchValues);
  const [accountTeamMembers, setAccountTeamMembers] = React.useState(undefined);
  const [invoices] = React.useState(props.invoices);
  const [dueThisMonthAccountBalance, setDueThisMonthAccountBalance] = React.useState(undefined);
  const [pastDueAccountBalance, setPastDueAccountBalance] = React.useState(undefined);
  const [totalAccountBalance, setTotalAccountBalance] = React.useState(undefined);
  const [ordersLoading, setOrdersLoading] = React.useState(true);
  const [supportLoading, setSupportLoading] = React.useState(true);
  const [accountBalanceLoading, setAccountBalanceLoading] = React.useState(true);
  const [currencyLoading, setCurrencyLoading] = React.useState(true);
 
  React.useEffect(() => {
    const settingsSubscription = user.settings$().subscribe(() => {
      setSelectedCurrency(currencyConversion.selectedCurrency);
    });

    return () => {
      settingsSubscription?.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    if(selectedCurrency) {
      setCurrencyLoading(false);
    }
  }, [selectedCurrency]);

  React.useEffect(() => {
    if(displayedNotifications && props.unmappedNotifications) {
      loadData();
    }
  }, [displayedNotifications, props.unmappedNotifications]);

  React.useEffect(() => {
    if(invoices) {
      loadAccountBalance();
    }
  }, [invoices]);

  React.useEffect(() => {
    if(!ordersLoading && !supportLoading && !accountBalanceLoading && !currencyLoading) {
      setLoading(false);
    }
  }, [ordersLoading, supportLoading, accountBalanceLoading, currencyLoading]);

  // Loads account balance info onto the account balance card
  const loadAccountBalance = () => {
    let totalBalance = 0;
    let dueThisMonthBalance = 0;
    let pastDueBalance = 0;

    invoices.forEach(invoice => {
      if(invoice.Past_Due__c) {
        pastDueBalance += invoice.Invoice_Amount_Open__c;
      } else if(invoice.Due_This_Month__c) {
        dueThisMonthBalance += invoice.Invoice_Amount_Open__c;
      }
    });

    totalBalance = dueThisMonthBalance + pastDueBalance;

    setDueThisMonthAccountBalance(dueThisMonthBalance);
    setPastDueAccountBalance(pastDueBalance);
    setTotalAccountBalance(totalBalance);
    setAccountBalanceLoading(false);
  };

  // Loads all the data onto the dashboard
  const loadData = async () => {
    loadOrders();
    loadSupport();
  };

  // Loads the open orders onto the orders card
  const loadOrders = async() => {
    try {
      if(props.orders) {
        const openOrders = props.orders.filter(order => order.Status__c?.toUpperCase() === OrderAPIStatuses.BackOrder);

        const mappedOrders = await Promise.all(
          (openOrders.length ? openOrders : props.orders)
            .map(async order => await mapOrder(order, lastViewed, darkMode)));

        const finalizedOrders = mappedOrders.orderByDate(item => (item.Last_Line_Updated_Date__c ? moment(item.Last_Line_Updated_Date__c).format('MM/DD/YYYY') : item.Last_Line_Updated_Date__c), false).slice(0, 3);

        setDisplayedOrders(finalizedOrders);
      }

      setOrdersLoading(false);
    } catch (error) {
      console.error(error);
      setOrdersLoading(false);
    }
  };

  // Loads the support lines onto the support card
  const loadSupport = async() => {
    try {
      const accountTeamMembers = await salesforce.getContactAccountTeam();
      setAccountTeamMembers(accountTeamMembers);
      setSupportLoading(false);
    } catch (error) {
      console.error(error);
      setSupportLoading(false);
    }
  };

  // Navigates to the orders page
  const navigateToOrdersPage = () => {
    history.push('/orders');
  };

  // Navigates to the order detail page for the selected order
  const navigateToOrderDetail = (order: Order) => {
    history.push('orders/order-detail', { order });
  };

  // Navigates to the notifications page
  const navigateToNotificationsPage = () => {
    history.push('/notifications');
  };

  // Navigates to the invoices page
  const navigateToInvoicesPage = () => {
    history.push('/invoices');
  };

  const OrderLine = (props) => {
    return (
      <IonCard className="order-card" onClick={() => navigateToOrderDetail(props.order)} data-testid={'QADashboardPageOrderLineItem_' + props.order.PO_Number__c}>
        <IonCardHeader class={classNames({ 'order-card-header': true, 'even': props.orderIndex % 2 === 0, 'odd': props.orderIndex % 2 !== 0 })}>
          <Badge text={props.order.badgeText}/>
          <IonGrid>
            <IonRow class="order-row">
              <IonCol class="column-left" size={ !platform.isMobile() ? '2' : '11' }>
                <span className="orders-text mobile-only status-label">{ headings.Status }</span>
                <img className="status-icon" src={ props.order.statusImgSrc }/>
                <span className="order-summary-text" data-testid="QADashboardPageOrderLineItemStatus">{ props.order.Status }</span>
              </IonCol>
              <IonCol class="column-left" size={ !platform.isMobile() ? '3' : '11' }>
                <span className="orders-text mobile-only po-label">{ headings.PO }</span>
                <span className="order-summary-text" data-testid="QADashboardPageOrderLineItemPurchaseOrder">{ props.order.PO_Number__c }</span>
              </IonCol>
              <IonCol class="column-left nc-sales-order-col" size="2">
                <span className="order-summary-text" data-testid="QADashboardPageOrderLineItemNCSalesOrder">{ props.order.AX_Order_Number__c }</span>
              </IonCol>
              <IonCol class="column-left last-update-col" size="2">
                <span className="order-summary-text" data-testid="QADashboardPageOrderLineItemLastUpdate">{ props.order.Last_Line_Updated_Date__c ? moment(props.order.Last_Line_Updated_Date__c).format('L') : props.order.Last_Line_Updated_Date__c }</span>
              </IonCol>
              <IonCol class="column-left" size={ !platform.isMobile() ? '2' : '11' }>
                <span className="orders-text mobile-only total-label">{ headings.Total }</span>
                <span className="order-summary-text total-price" data-testid="QADashboardPageOrderLineItemTotal">{ formatCurrency(props.order.Order_Total_Amount__c || 0, props.order.CurrencyIsoCode) }</span>
              </IonCol>
              {/*<LineItemActions
                stopPropagation='true'
                data-testid="QAOrdersPageOrderLineItemActions">
              </LineItemActions>*/}
            </IonRow>
          </IonGrid>
        </IonCardHeader>
      </IonCard>
    );
  };

  const OrdersList = () => {
    return ( displayedOrders?.length ?
      <>{displayedOrders.map((order, index) => (
        <OrderLine order={order} orderIndex={index} key={index}/>
      ))}</> :
      <EmptyTableRow text={'No Orders Available'}/>
    );
  };

  const OrdersCard = () => {
    return (
      <IonCard class='orders-card' data-testid="QADashboardPageOrdersCard">
        <IonCardHeader class='card-header-container'>
          <div className='top'>
            <IonLabel class='card-title'>Orders</IonLabel>
            <IonLabel class='card-link' data-testid="QADashboardPageOrdersCardViewAllOrders" onClick={() => navigateToOrdersPage()}>View All Orders</IonLabel>
          </div>
          <SearchContainer
            orders={props.orders}
            orderSearchValues={orderSearchValues}
            navigateToOrderDetail={navigateToOrderDetail}
          />
        </IonCardHeader>
        <hr/>
        <IonCard class="orders">
          <IonCardHeader class="orders-header">
            <IonGrid>
              <IonRow class="orders-row">
                <IonCol class="orders-col" size={ !platform.isMobile() ? '2' : '11' } data-testid="QADashboardPageOrdersStatusColumnHeader">
                  <span className='orders-text'>{ headings.Status }</span>
                </IonCol>
                <IonCol class="orders-col" size={ !platform.isMobile() ? '3' : '11' } data-testid="QADashboardPageOrdersPurchaseOrderColumnHeader">
                  <span className='orders-text'>{ headings.PurchaseOrder }</span>
                </IonCol>
                <IonCol class="orders-col" size="2" data-testid="QADashboardPageOrdersNCSalesOrderColumnHeader">
                  <span className='orders-text'>{ headings.NCSalesOrder }</span>
                </IonCol>
                <IonCol class="orders-col" size="2" data-testid="QADashboardPageOrdersLastUpdateColumnHeader">
                  <span className='orders-text'>{ headings.LastUpdate }</span>
                </IonCol>
                <IonCol class="orders-col" size={ !platform.isMobile() ? '2' : '11' } data-testid="QADashboardPageOrdersTotalColumnHeader">
                  <span className='orders-text'>{ headings.Total }</span>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <OrdersList/>
      </IonCard>
    );
  };

  const NotificationsCard = () => {
    return (
      <IonCard class='notifications-card' data-testid="QADashboardPageNotificationsCard">
        <IonCardHeader class='card-header-container'>
          <div className='top'>
            <IonLabel class='card-title'>Notifications</IonLabel>
            <IonLabel class='card-link' onClick={() => navigateToNotificationsPage()} data-testid="QADashboardPageNotificationsCardViewAllNotifications">View All Notifications</IonLabel>
          </div>
        </IonCardHeader>
        <NotificationsList striping={true}
          unmappedNotifications={props.unmappedNotifications}
          loadNotifications={props.loadNotifications}
          displayedNotifications={displayedNotifications}
        />
      </IonCard>
    );
  };

  const SupportLineImage = (props) => {
    return (props.photoUrl ?
      <img className='support-photo' src={props.photoUrl} data-testid='QADashboardPageSupportLineItemPhoto'></img> :
      <div className='nc-logo-container' data-testid='QADashboardPageSupportLineItemPhoto'>
        <img className='nc-logo' src={ncSymbolWhite}></img>
      </div>
    );
  };

  const PhoneLink = (props) => {
    return (platform.isMobile() ?
      <a className={props.phone ? 'support-line-link' : 'not-available'} href={props.phone ? 'tel:' + props.phone : undefined}>{props.phone || 'Phone not available'}</a> :
      <IonLabel className={props.phone ? 'support-line-link' : 'not-available'} data-testid='QADashboardPageSupportLineItemPhone'>{props.phone || 'Phone not available'}</IonLabel>
    );
  };

  const SupportLine = (props) => {
    return (
      <IonItem data-testid={'QADashboardPageSupportLineItem_' + props.index}>
        <div className='support-line-container'>
          <SupportLineImage photoUrl={props.photoUrl}/>
          <div className='support-info-container'>
            <div className='support-info-header-container'>
              <IonLabel className='support-line-title' data-testid='QADashboardPageSupportLineItemTitle'>{props.title}</IonLabel>
              <IonLabel className='support-line-role' data-testid='QADashboardPageSupportLineItemRole'>{props.role}</IonLabel>
            </div>
            <PhoneLink phone={props.phone}/>
            <IonLabel className={props.email ? 'support-line-link' : 'not-available'} onClick={props.email ? () => window.open('mailto:' + props.email) : undefined} data-testid='QADashboardPageSupportLineItemEmail'>{props.email || 'Email not available'}</IonLabel>
          </div>
        </div>
      </IonItem>
    );
  };

  const SupportList = (props) => {
    return (props.teamMembers?.length ?
      <>{props.teamMembers.map((accountTeamMember, index) => (
        <SupportLine
          title={accountTeamMember.Name}
          role={accountTeamMember.Team_Role__c}
          phone={accountTeamMember.Phone}
          email={accountTeamMember.Email}
          photoUrl={accountTeamMember.PhotoUrl}
          index={index}
          key={index}
        />
      ))}</> :
      <SupportLine
        title={'NobelClad Support'}
        role={'General Inquiries'}
        phone={'(303) 665-5700'}
        email={'info@nobelclad.com'}
      />
    );
  };

  // Doing some creative hackery here because Carl complained about tablet mode :)
  const SupportListContainer = () => {
    const supportListArray: any[] = [];

    if(platform.isTablet()) {
      let i;
      let accountTeamArray = [];
      for(i = 0; i < accountTeamMembers.length; i++) {
        accountTeamArray.push(accountTeamMembers[i]);

        if((i + 1) % 2 === 0 || i === accountTeamMembers.length - 1) {
          supportListArray.push(
            <IonItem>
              <IonList className='support-list-container row'>
                <SupportList teamMembers={[...accountTeamArray]}/>
              </IonList>
            </IonItem>
          );
          accountTeamArray = [];
        }
      }
    }

    return (!platform.isTablet() ?
      <IonList className='support-list-container'>
        <SupportList teamMembers={accountTeamMembers}/>
      </IonList> :
      <IonList className='support-list-container'>
        {supportListArray.map((supportList) =>
          supportList
        )}
      </IonList>
    );
  };

  const SupportCard = () => {
    return (
      <IonCard class='support-card' data-testid="QADashboardPageSupportCard">
        <IonCardHeader class='card-header-container'>
          <div className='top'>
            <IonLabel class='card-title'>Support</IonLabel>
          </div>
        </IonCardHeader>
        <SupportListContainer/>
      </IonCard>
    );
  };

  const AccountBalanceLine = (props) => {
    switch(props.type) {
    case AccountBalanceTypes.DueThisMonth:
      return (
        <div>
          <div className='account-balance-label due-this-month'>{AccountBalanceTypes.DueThisMonth}</div>
          <div className='account-balance due-this-month' data-testid="QADashboardPageAccountBalanceCardDueThisMonth">{formatCurrency(dueThisMonthAccountBalance || 0, selectedCurrency)}</div>
        </div>
      );
    case AccountBalanceTypes.PastDue:
      return (
        <div>
          <div className='account-balance-label past-due'>{AccountBalanceTypes.PastDue}</div>
          <div className='account-balance past-due' data-testid="QADashboardPageAccountBalanceCardPastDue">{formatCurrency(pastDueAccountBalance || 0, selectedCurrency)}</div>
        </div>
      );
    case AccountBalanceTypes.Total:
      return (
        <div>
          <div className='account-balance-label total'>{AccountBalanceTypes.Total}</div>
          <div className='account-balance total' data-testid="QADashboardPageAccountBalanceCardTotal">{formatCurrency(totalAccountBalance || 0, selectedCurrency)}</div>
        </div>
      );
    }
  };

  const AccountBalanceCard = () => {
    let dataSets;
    let colors;

    if(dueThisMonthAccountBalance || pastDueAccountBalance) {
      dataSets = [dueThisMonthAccountBalance, pastDueAccountBalance];
      colors = ['#e89c20', '#d67374'];
    } else {
      dataSets = [1];
      colors = ['#c9cac8'];
    }

    const chartData = {
      datasets: [
        {
          data: dataSets,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
        },
      ],
    };

    return (
      <IonCard class='account-balance-card' data-testid="QADashboardPageAccountBalanceCard">
        <IonCardHeader class='card-header-container'>
          <div className='top'>
            <IonLabel class='card-title'>Account Balance</IonLabel>
            <IonLabel class='card-link' data-testid="QADashboardPageAccountBalanceCardViewAllInvoices" onClick={() => navigateToInvoicesPage()}>View All Invoices</IonLabel>
          </div>
        </IonCardHeader>
        <div className='account-balance-container'>
          <div className='account-balance-lines-container'>
            <AccountBalanceLine type={AccountBalanceTypes.DueThisMonth}/>
            <AccountBalanceLine type={AccountBalanceTypes.PastDue}/>
          </div>
          <div className='chart-container'>
            <Doughnut data={chartData} options={{ cutout: '81%', plugins: { tooltip: { enabled: false } } }}/>
            <div className='total-container'>
              <AccountBalanceLine type={AccountBalanceTypes.Total}/>
            </div>
          </div>
        </div>
      </IonCard>
    );
  };

  return ( !loading ?
    <IonContent class="dashboard-page ion-padding">
      <div className="dashboard-content max-width-container">
        <IonLabel class='myclad-title' data-testid="QADashboardPageTitle">MyClad™</IonLabel>
        <hr className='title-divider'/>
        <div className='card-container'>
          <div className='left-side'>
            <OrdersCard/>
            <div className='bottom-left'>
              <NotificationsCard/>
              <AccountBalanceCard/>
            </div>
          </div>
          <div className='right-side'>
            <SupportCard/>
          </div>
        </div>
      </div>
      <div className="footer" data-testid="QADashboardPageFooterContainer">
        <Footer/>
      </div>
    </IonContent>
    :
    <div className="dashboard-loading-container" data-testid="QADashboardPageLoading">
      <Loading/>
    </div>
  );
};

export default DashboardPage;
