import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { MeasurementSystems } from '@constants';

interface Props {
  children: ReactNode;
  type?: MeasurementSystems;
}

interface IContextProps {
  measurement: MeasurementSystems;
  setMeasurement: Dispatch<SetStateAction<MeasurementSystems>>;
  isMetric: boolean;
  isImperial: boolean;
}

// Context
const MeasurementSystemContext = createContext({} as IContextProps);

// Provider
export const MeasurementSystemProvider = ({ children, type }: Props): JSX.Element => {
  const [
    measurement,
    setMeasurement
  ] = useState(type || MeasurementSystems.Imperial);

  // context value object
  const value: IContextProps = useMemo(() => {
    return {
      measurement,
      isMetric: measurement === MeasurementSystems.Metric,
      isImperial: measurement === MeasurementSystems.Imperial,
      setMeasurement,
    };
  }, [measurement, setMeasurement, MeasurementSystems]);

  return (
    <MeasurementSystemContext.Provider value={value}>{children}</MeasurementSystemContext.Provider>
  );
};

// useMeasurementSystem hook
export const useMeasurementSystem = (): IContextProps => {
  const context: IContextProps = useContext(MeasurementSystemContext);
  if (context === null || context === undefined) {
    throw new Error(
      'You probably forgot the <MeasurementSystemProvider> context provider'
    );
  }
  return context;
};