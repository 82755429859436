import React, { useState } from 'react';
import BreadcrumbNavigation from '@components/breadcrumb-navigation/breadcrumb-navigation';
import ComboGroupLayout from '@components/page/combo-group-layout';
import { PageRoutes, QuoteActions } from '@constants';
import { navigateToRoute } from '@shared/utils';
import { useHistory } from 'react-router-dom';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';

import './advanced-specifications.scss';
import MetalCombo from './metal-combo/metal-combo';
import PageSubHeader from './page-sub-header/page-sub-header';
import { SegmentProvider } from './segments/segment-context';
import PageForm from './page-form/page-form';
import PageFloatingAction from './page-floating-actions/page-floating-actions';
import { useQuoteState } from '@context/quote-context';
import { IonButton } from '@ionic/react';
import { useQuoteFormContext } from '@context/quote-form-context';
import { useDisplayUomWithInit } from '@context/useDisplayUomWithInit';

const AdvancedSpecificationPage = () => {
  const history = useHistory();
  const {
    onResolveQuoteLineItems,
    setQuoteLineItems,
  } = useQuoteFormContext();
  const {
    quoteState,
    saveQuote,
    syncFormQuoteLineItem
  } = useQuoteState();
  const { quote } = quoteState;
  const [
    isSaving,
    setIsSaving
  ] = useState(false);

  useDisplayUomWithInit();

  const breadcrumbsItems = [
    {
      label: 'Quotes',
      goTo: () => {
        syncFormQuoteLineItem(onResolveQuoteLineItems());
        navigateToRoute(PageRoutes.Quotes, history, quote?.Id);
      },
    },
    {
      label: quote?.Name,
      goTo: () => {
        syncFormQuoteLineItem(onResolveQuoteLineItems());
        navigateToRoute(PageRoutes.QuoteDetail, history, quote?.Id);
      },
    },
    { label: 'Advanced Specifications' },
  ];

  const headerComponents = [
    {
      key: 'adv--breadcrumbs',
      size: '8',
      component: <BreadcrumbNavigation breadcrumbItems={breadcrumbsItems}/>
    },
    {
      key: 'adv--metalcombo',
      size: '4',
      component: <MetalCombo/>
    }
  ];

  return <ComboGroupLayout className='adv-spec-page' loading={isSaving}>
    <SegmentProvider>
      <PageFloatingAction/>
      <AppLineItemColumns components={headerComponents} />
      <PageSubHeader/>
      <PageForm/>
      <div className='button-container'>
        <IonButton
          color='primary'
          class='save-button'
          onClick={() => {
            const newQuote = syncFormQuoteLineItem(onResolveQuoteLineItems());

            setIsSaving(true);

            // i know dont look at me
            // just trying to reuse this thing
            saveQuote(quote.Id, QuoteActions.Save, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, newQuote)
              .then(() => {
                setQuoteLineItems(() => {
                  return [];
                });
              })
              .finally(() => {
                setIsSaving(false);
              });
          }}
        >
          Save
        </IonButton>
      </div>
  </SegmentProvider>
  </ComboGroupLayout>;
};

export default AdvancedSpecificationPage;