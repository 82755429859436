import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import simpleCacheStorage from '@services/app/simple-cache-storage';
import { FinishedGood } from '@interfaces/finished-good';
import { getFinishedGoods } from '@services/finished-goods/finished-goods-api';

const KEY = 'NC_FINISHED_GOODS';

interface Props {
  children: ReactNode;
}

interface IContextProps {
  finishedGoods: FinishedGood[];
  setFinishedGoods: Dispatch<SetStateAction<FinishedGood[]>>;
  getFinishedGoods: () => Promise<boolean>;
}

// Context
const FinishedGoodsContext = createContext({} as IContextProps);

// Provider
export const FinishedGoodsProvider = ({ children }: Props): JSX.Element => {
  const [
    finishedGoods,
    setFinishedGoods
  ] = useState(simpleCacheStorage.getItem(KEY) || []);

  const onUpdate = (items) => {
    simpleCacheStorage.setItem(KEY, items, 60);
    setFinishedGoods(items);
  };

  // dont return the values and instead use state
  const onGet = () => {
    if (finishedGoods.length >= 1) {
      return Promise.resolve(true);
    }

    return getFinishedGoods()
      .then((result) => {
        onUpdate(result);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  }

  // context value object
  const value: IContextProps = useMemo(() => {
    return {
      finishedGoods,
      setFinishedGoods: onUpdate,
      getFinishedGoods: onGet,
    };
  }, [finishedGoods, setFinishedGoods, onUpdate, simpleCacheStorage, KEY]);

  return (
    <FinishedGoodsContext.Provider value={value}>{children}</FinishedGoodsContext.Provider>
  );
};

// useFinishedGoods hook
export const useFinishedGoods = (): IContextProps => {
  const context: IContextProps = useContext(FinishedGoodsContext);
  if (context === null || context === undefined) {
    throw new Error(
      'You probably forgot the <FinishedGoodsProvider> context provider'
    );
  }
  return context;
};