import { handleLogin } from '../../guards/auth-guard-service';
import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useAuth } from '@services/auth-service';

const ProtectedRoute = ({ component: Component, isLoggedIn, handleRouteChange, ...rest }) => {
  const auth = useAuth();
  const location = useLocation();
    
  useEffect(() => {
    handleRouteChange(location);
  },[location]);

  return (
    <Route {...rest} render={
      props => {
        if (isLoggedIn) {
          return (
            <Component {...rest} {...props} />
          );
        } else {
          handleLogin(auth.authConnectService.login());
        }
      }
    }
    />
  );
};

export default ProtectedRoute;