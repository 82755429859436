import React, { useEffect } from 'react';

import { IonLabel } from '@ionic/react';
import AppInput from '@components/app-input/app-input';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';

import PanelSection from '../panel-section';
import AppLineItem from '@components/app-line-item/app-line-item';
import { PanelProp } from '@pages/new-assembly-groups/panel-item/panel-item';
import { AssemblyGroupFormKeys } from '@pages/new-assembly-groups/assembly-group-form-util';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import AppRadioButtons from '@components/app-radio-buttons/app-radio-buttons';
import AppTextarea from '@components/app-textarea/app-textarea';

import './cylinder-can-rolling.scss';
import { MeasurementSystems, OutsideServices, QuoteLineItemValueTypes, Regexes, Units } from '@constants';
import AppDropdownVendors from '@components/app-dropdown/app-dropdown-vendors';
import AppDropDownFinishedGoods from '@components/app-dropdown/app-dropdown-finished-goods';
import { useDisplayUom } from '@context/useDisplayUom';
import { calculateUOMValue } from '@shared/quote-utils';
import { useDataState } from '@context/data-context';

const chargeFormat = (value) => {
  return value !== '' ? (+value).toFixed(2) : '';
}

const ChargeInput = ({ quote, value, onChange}) => {
  const { displayUom } = useDisplayUom();
  const quoteUOM = quote.DocumentUOM__c || MeasurementSystems.Imperial;

  const displayValue = displayUom === quoteUOM
    ? value
    : calculateUOMValue(quoteUOM, displayUom, value, Units.PricePerKilogram, Units.PricePerPound);

  const onInputChange = (inputValue) => {
    const newValue = displayUom === quoteUOM
      ? inputValue
      : calculateUOMValue(displayUom, quoteUOM, inputValue, Units.PricePerKilogram, Units.PricePerPound);

    onChange(newValue);
  }

  return <AppInput
    type='number'
    placeholder='0.000'
    regex={Regexes.DecimalNumber}
    min='0.000'
    value={chargeFormat(displayValue)}
    onChange={onInputChange}
  />
}

const AppRadioButtonsFinishedGoods = (props) => {
  const { itemType, areaId, valueType, selected, onChange } = props;
  const { dataState } = useDataState();
  const { finishedGoods } = dataState;

  const type = finishedGoods.find((finishedGood) => {
    return finishedGood.name === itemType && finishedGood.dataAreaId === areaId;
  });

  const selection = type?.selections.find(selection => selection.valueType === valueType) || {}
  const options = selection.options?.map((option) => {
    return {
      label: option,
      value: option,
    };
  }) || [];

  const radioButtons = {
    ...props,
    options,
  };

  useEffect(() => {
    // set default value to the first option if not yet set
    if (!selected && onChange && options.length > 0) {
      onChange(options[0]);
    }
  }, [itemType, valueType, selected]);

  return <AppRadioButtons {...radioButtons}/>
};

const WeightLabelUOM = {
  [MeasurementSystems.Imperial]: 'Lb',
  [MeasurementSystems.Metric]: 'Kg',
};

const PanelCylinderCanRolling = ({ form, updateForm, areaId, quote }: PanelProp) => {
  const { displayUom } = useDisplayUom();

  const onUpdateForm = (key: string) => {
    return (value: any) => {
      updateForm(key, value);
    }
  }

  return <>
    <PanelSection title="Cylinder + Can Rolling">
      <AppLineItem light={true} label='Cylinder Type:' >
        <AppLineItemColumns components={[{
          key: 'cyclinder-type-dropdown',
          size: 6,
          component: <AppDropDownFinishedGoods
            areaId={areaId}
            itemType={form[AssemblyGroupFormKeys.Item_Type]}
            valueType={QuoteLineItemValueTypes.CylinderType}
            value={form[AssemblyGroupFormKeys.CylinderType]}
            onChange={({value}) => onUpdateForm(AssemblyGroupFormKeys.CylinderType)(value)}
          />
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Diameter:' >
        <AppLineItemColumns components={[
          {
            key: 'diameter-input',
            size: 3,
            component: <AppInput
              type='number'
              placeholder='1'
              regex={Regexes.IntegerAboveZero}
              min='1'
              value={form[AssemblyGroupFormKeys.Diameter]}
              onChange={onUpdateForm(AssemblyGroupFormKeys.Diameter)}
            />
          },
          {
            key: 'diameter-radio',
            size: 9,
            className: 'd-flex align-items-center',
            component: <AppRadioButtonsFinishedGoods
              name={QuoteLineItemValueTypes.IDorOD}
              areaId={areaId}
              className='padding-left-5'
              itemType={form[AssemblyGroupFormKeys.Item_Type]}
              valueType={QuoteLineItemValueTypes.IDorOD}
              selected={form[AssemblyGroupFormKeys.IDorOD]}
              onChange={(value) => onUpdateForm(AssemblyGroupFormKeys.IDorOD)(value)}
            />
            },
        ]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Edge Preparation:' >
        <AppLineItemColumns components={[{
          key: 'edge-preparation',
          size: 6,
          component: <AppDropDownFinishedGoods
            areaId={areaId}
            itemType={form[AssemblyGroupFormKeys.Item_Type]}
            valueType={QuoteLineItemValueTypes.EdgePreparation}
            value={form[AssemblyGroupFormKeys.EdgePreparation]}
            onChange={({value}) => onUpdateForm(AssemblyGroupFormKeys.EdgePreparation)(value)}
          />
        }]}/>
      </AppLineItem>

      <AppLineItem label='Cost & Shipping' />
      <AppLineItem light={true} label='Rolling Cost Per Cylinder:' >
        <AppLineItemColumns components={[{
          key: 'rolling-cost-per-cylinder',
          size: 6,
          component: <AppInput
            type='number'
            placeholder='0'
            regex={Regexes.DecimalNumber}
            value={form[AssemblyGroupFormKeys.RollingCostPerCylinder]}
            onChange={onUpdateForm(AssemblyGroupFormKeys.RollingCostPerCylinder)}
          />
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Freight Charge to Customer:' >
        <AppLineItemColumns components={[{
          key: 'freight-charge-customer',
          size: 6,
          component: <AppLineItemColumns components={[
            {
              key: 'freight-charge-customer-input',
              component: <ChargeInput
                quote={quote}
                value={form[AssemblyGroupFormKeys.FreightChargeToCustomerPer]}
                onChange={onUpdateForm(AssemblyGroupFormKeys.FreightChargeToCustomerPer)}
              />
            },
            {
              key: 'freight-charge-customer-label',
              className: 'd-flex align-items-center',
              component: <IonLabel className='padding-left-5'>{WeightLabelUOM[displayUom]}</IonLabel>
            }
          ]}/>
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Freight Charge to Roller:' >
        <AppLineItemColumns components={[{
          key: 'freight-charge-roller',
          size: 6,
          component: <AppLineItemColumns components={[
            {
              key: 'freight-charge-roller-input',
              component: <ChargeInput
                quote={quote}
                value={form[AssemblyGroupFormKeys.FreightChargeToRollerPer]}
                onChange={onUpdateForm(AssemblyGroupFormKeys.FreightChargeToRollerPer)}
              />
            },
            {
              key: 'freight-charge-roller-label',
              className: 'd-flex align-items-center',
              component: <IonLabel className='padding-left-5'>{WeightLabelUOM[displayUom]}</IonLabel>
            }
          ]}/>
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Transit Time to Roller:' >
        <AppLineItemColumns components={[{
          key: 'transit-time-roller',
          size: 4,
          component: <AppLineItemColumns components={[
            {
              key: 'transit-time-roller-input',
              component: <AppInput
                type='number'
                placeholder='0'
                regex={Regexes.PositiveInteger}
                min='0'
                value={form[AssemblyGroupFormKeys.TransitTimeToRollerWeeks]}
                onChange={onUpdateForm(AssemblyGroupFormKeys.TransitTimeToRollerWeeks)}
              />
            },
            {
              key: 'transit-time-roller-label',
              className: 'd-flex align-items-center',
              component: <IonLabel className='padding-left-5'>weeks</IonLabel>
            }
          ]}/>
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Time to Roll:' >
        <AppLineItemColumns components={[{
          key: 'time-roll',
          size: 4,
          component: <AppLineItemColumns components={[
            {
              key: 'time-roll-input',
              component: <AppInput
                type='number'
                placeholder='0'
                regex={Regexes.PositiveInteger}
                min='0'
                value={form[AssemblyGroupFormKeys.TimeToRollWeeks]}
                onChange={onUpdateForm(AssemblyGroupFormKeys.TimeToRollWeeks)}
              />
            },
            {
              key: 'time-roll-label',
              className: 'd-flex align-items-center',
              component: <IonLabel className='padding-left-5'>weeks</IonLabel>
            }
          ]}/>
        }]}/>
      </AppLineItem>

      <AppLineItem label='Plate Sizes Supply Former' />
      <AppLineItem light={true} label='Vendor:' >
        <AppLineItemColumns components={[{
          key: 'vendor-dropdown',
          size: 6,
          component: <AppDropdownVendors
            areaId={areaId}
            servicesPerformed={OutsideServices.CanRolling}
            onChange={(value) => onUpdateForm(AssemblyGroupFormKeys.CylinderVendor)(value)}
          />
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Quantity Per Cylinder:' >
        <AppLineItemColumns components={[{
          key: 'quantity-per-cylinder',
          size: 6,
          component: <AppInput
            value={form[AssemblyGroupFormKeys.QuantityPerCylinder]}
            onChange={onUpdateForm(AssemblyGroupFormKeys.QuantityPerCylinder)}
          />
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Width, Length:' >
        <AppLineItemColumns components={[{
          key: 'quantity-per-cylinder',
          size: 5,
          component: <AppLineItemColumns components={[
            {
              key: 'quantity-per-cylinder-input1',
              size: 5.5,
              component: <AppInput
                value={form[AssemblyGroupFormKeys.PlateWidthSupplyToFormer]}
                onChange={onUpdateForm(AssemblyGroupFormKeys.PlateWidthSupplyToFormer)}
              />
            },
            {
              key: 'quantity-per-cylinder-label',
              size: 1,
              className: 'd-flex align-items-center justify-content-center',
              component: <IonLabel className='padding'>X</IonLabel>
            },
            {
              key: 'quantity-per-cylinder-input2',
              size: 5.5,
              component: <AppInput
                value={form[AssemblyGroupFormKeys.PlateLengthSupplyToFormer]}
                onChange={onUpdateForm(AssemblyGroupFormKeys.PlateLengthSupplyToFormer)}
              />
            },
          ]}/>
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Additional Notes:' >
        <AppLineItemColumns components={[{
          key: 'cyclinder-additional-notes',
          component: <AppTextarea
            rows={5}
            placeholder="Comments..."
            value={form[AssemblyGroupFormKeys.CylinderNotes]}
            onChange={onUpdateForm(AssemblyGroupFormKeys.CylinderNotes)}
          />
        }]}/>
      </AppLineItem>

    </PanelSection>
  </>
};

export default PanelCylinderCanRolling;