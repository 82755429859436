import { IonButton, IonFab, IonFabList, IonIcon, IonList } from '@ionic/react';
import * as React from 'react';
import { caretDownSharp } from 'ionicons/icons';
import classNames from 'classnames';
import './multi-action-button.scss';

const MultiActionButton = (props) => {
  const [isMenuExpanded, setIsMenuExpanded] = React.useState(false);

  const setMenuExpandedToFalse = React.useCallback(() => {
    setIsMenuExpanded(false);
  }, []);

  React.useEffect(() => {
      window.addEventListener('click', setMenuExpandedToFalse);

      return () => window.removeEventListener('click', setMenuExpandedToFalse);
  }, []);

  const toggleMenu = (event: any) => {
    setIsMenuExpanded(!isMenuExpanded);
    event.stopPropagation();
  };

  const execute = (action: string, event: any) => {
    event.stopPropagation();
    props.actionHandler(action);
  };

  const DropdownOptions = () => {
    return (
      <>{props.dropdownOptions.map((option) => (
        <IonButton class='option' key={option} color='secondary' tabIndex={0} onClick={(event) => execute(option, event)} data-testid={`QAMultiActionButtonDropdownOption_${option}`}>{option}</IonButton>
      ))}</>
    );
  };

  return (
    <div className={props.containerStyle}>
      <div className={props.style}>
        <IonFabList side="bottom" activated={isMenuExpanded} class={props.buttonPosition === 'start' ? 'left' : 'right'}>
          <IonList class="options" lines="none">
            <DropdownOptions/>
          </IonList>
        </IonFabList>
        <IonFab
          horizontal={props.buttonPosition}
          activated={isMenuExpanded}
          class={classNames({ 'expand-button': true, 'left': props.buttonPosition === 'start', 'right': props.buttonPosition !== 'start' })}
        >
          <IonButton class="ion-no-margin dropdown-button" color="primary" onClick={(event) => toggleMenu(event)} data-testid={'QAMultiActionButtonDropdownButton'}>
            <IonIcon src={caretDownSharp}></IonIcon>
          </IonButton>
        </IonFab>
        <IonButton
          class="ion-no-margin default-option"
          onClick={(event) => execute(props.defaultOption, event)}
          color="primary"
          data-testid={`QAMenuDefaultOption_${props.defaultOption}`}
        >
          {props.defaultOption}
        </IonButton>
      </div>
    </div>
  );
};

export default MultiActionButton;