import * as React from 'react';
import DocumentInfo from '@interfaces/document-info';
import { deleteDocument, downloadDocument, uploadDocument } from './documents-api';

const { createContext, useContext } = React;

const DocumentsContext = createContext(null);

export const DocumentsProvider = (props) => {
  const value = {
    uploadDropboxDocument: props.uploadDropboxDocument || uploadDropboxDocument,
    downloadDropboxDocument: props.downloadDropboxDocument || downloadDropboxDocument,
    deleteDropboxDocument: props.deleteDropboxDocument || deleteDropboxDocument
  };

  return (
    <DocumentsContext.Provider value={value}>
      {props.children}
    </DocumentsContext.Provider>
  );
};

export const useDocuments = () => {
  return useContext(DocumentsContext);
};

// Uploads the document
export const uploadDropboxDocument = (documentInfo: DocumentInfo): Promise<{ success: boolean }> => {
  return uploadDocument(documentInfo);
};

// Downloads the document
export const downloadDropboxDocument = (fileId: string): Promise<any> => {
  return downloadDocument(fileId);
};

// Deletes the document
export const deleteDropboxDocument = (fileId: string): Promise<any> => {
  return deleteDocument(fileId);
};