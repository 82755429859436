import React, { useEffect } from 'react';
import { useAuth } from '@services/auth-service';

// tslint:disable-next-line:no-empty-interface
interface LogoutProps {}

const Logout: React.FC<LogoutProps> = () => {
  const auth = useAuth();

  useEffect(() => {
    (async () => {
      await auth.authConnectService.handleLogoutCallback();
    })();
  }, []);
  return null;
};

export default Logout;