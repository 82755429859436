import { useState } from 'react';

export const useDarkMode = () => {
  const [darkMode, setDarkMode] = useState<boolean>(window.matchMedia('(prefers-color-scheme: dark)').matches);

  const toggleDarkMode = async (darkMode: boolean): Promise<void> => {
    document.body.classList.toggle('dark', darkMode);
    setDarkMode(darkMode);
  };

  const initializeDarkMode = () => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    toggleDarkMode(prefersDark.matches);
  
    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => toggleDarkMode(mediaQuery.matches));
  };

  return { initializeDarkMode, darkMode };
};