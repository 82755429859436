import LineItemActions from '@components/line-item-actions/line-item-actions';
import NotificationPopover from '@components/popover/notification-popover/notification-popover';
import UnreadIndicator from '@components/unread-indicator/unread-indicator';
import { NotificationActions } from '@constants';
import { IonCard, IonCardHeader, IonLabel, IonPopover } from '@ionic/react';
import { useNotifications } from '@services/notifications/notifications-service';
import classNames from 'classnames';
import * as React from 'react';
import './notification-line.scss';
import { Notification } from '@interfaces/notification';

interface Props {
  notification: Notification;
  reloadNotifications: () => void;
  striping: any;
  notificationIndex: number;
  numNotifications: number;
  unmappedNotification: Notification;
  actionsVisible: any;
}


const NotificationLine = (props: Props) => {
  const notifications = useNotifications();

  const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

  const deleteNotification = () => {
    notifications.deleteContactNotification(props.notification.id);
    props.reloadNotifications();
  };

  const Divider = () => {
    return (!props.striping && props.notificationIndex !== props.numNotifications - 1 ?
      <hr/> : null
    );
  };

  return (
    <>
      <IonPopover
        cssClass='notification-popover'
        isOpen={popoverState.showPopover}
        animated={ false }
        onDidPresent={() => {
          notifications.updateContactNotification({ ...props.unmappedNotification, lastViewedDateTime: new Date().toLocaleString() });
          notifications.notificationViewed.next(props.unmappedNotification.id);
        }}
        onDidDismiss={() => {
          setShowPopover({ showPopover: false, event: undefined });
        }}
      >
        <NotificationPopover setShowPopover={setShowPopover} notification={props.unmappedNotification}/>
      </IonPopover>
      <IonCard className="notification-card" onClick={(e: any) => {
        e.persist();
        setShowPopover({ showPopover: true, event: e });
      }} data-testid={'QANotificationLineItem_' + props.notificationIndex}
      >
        <IonCardHeader class={classNames({
          'notification-card-header': true,
          'even': props.notificationIndex % 2 === 0 && props.striping,
          'odd': props.notificationIndex % 2 !== 0 && props.striping,
          'hover-indicator': !props.striping})}
        >
          <UnreadIndicator notification={props.notification}/>
          <div className="notification-info-container">
            <IonLabel class="notification-title" data-testid="QANotificationLineTitle">{props.notification.title}</IonLabel>
            <IonLabel class="notification-timestamp" data-testid="QANotificationLineTimestamp">{props.notification.timestamp}</IonLabel>
          </div>
          <LineItemActions
            visible={props.actionsVisible}
            actions={[{
              label: NotificationActions.Delete,
              actionToPerform: deleteNotification
            }]}
            data-testid="QANotificationLineLineItemActions"
          >
          </LineItemActions>
        </IonCardHeader>
        <Divider/>
      </IonCard>
    </>
  );
};

export default NotificationLine;