import React from 'react';
import { IonButton, IonCol, IonImg, IonLabel, IonRow } from '@ionic/react';
import './ship-via-popover.scss';
import { SelectionTypes, QuoteLineItemValueTypes, QuoteLineItemsColumnHeadings, QuoteLineItemFieldUnitTypes } from '@constants';
import SelectionContainer from '@components/selection-container/selection-container';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { getSelections } from '@shared/quote-utils';
import LineItemField from '@components/line-item-field/line-item-field';
import { createLineNumber } from '@shared/quote-group-utils';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { OrderEntryLineItemFieldsToUpdate } from '@shared/types';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';

const ShipViaPopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
    showPopover: boolean;
    event: any;
  }>>,
  quoteLineItemFieldsToUpdate: { [key: string]: OrderEntryLineItemFieldsToUpdate; },
  setQuoteLineItemFieldsToUpdate: React.Dispatch<React.SetStateAction<{ [key: string]: OrderEntryLineItemFieldsToUpdate; }>>
}) => {
  const { dataState } = useDataState();
  const { selections } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const {
    quoteLineItems
  } = quote || {};
  
  const { darkMode } = useDarkMode();
  const [ shippingSelections, setShippingSelections ] = React.useState([]);
  const [quoteLineItemFieldsToUpdate] = React.useState(props.quoteLineItemFieldsToUpdate);

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const saveShipVia = () => {
    props.setQuoteLineItemFieldsToUpdate(quoteLineItemFieldsToUpdate);
  };

  const cancelShipVia = () => {
    quoteLineItems.forEach(quoteLineItem => {
      const id = quoteLineItem.Id || quoteLineItem.displayId;
      quoteLineItemFieldsToUpdate[id].shipVia = quoteLineItem.ShipVia__c;
      quoteLineItemFieldsToUpdate[id].shipViaDetails = quoteLineItem.ShipViaDetails__c;
    });
  };

  React.useEffect(() => {
    const shipViaSelections = [];
    const shipViaSelectionPull = getSelections(selections, quote.Manufacturing_Site__c, QuoteLineItemValueTypes.ShipVia).options;
    shipViaSelectionPull.forEach(selection => shipViaSelections.push(selection.name));
    setShippingSelections(shipViaSelections);
  }, []);

  const LineItemDetails = (props: {
    lineItem: QuoteLineItem
  }) => {
    const id = props.lineItem.Id || props.lineItem.displayId;
    
    const [ shipViaDetails, setShipViaDetails ] = React.useState(quoteLineItemFieldsToUpdate[id].shipViaDetails);
    const [ shipVia, setShipVia ] = React.useState(quoteLineItemFieldsToUpdate[id].shipVia);

    const updateShipVia = (shipVia) => {
      setShipVia(shipVia);
      quoteLineItemFieldsToUpdate[id].shipVia = shipVia;
    };

    const updateShipViaDetails = (details) => {
      setShipViaDetails(details);
      quoteLineItemFieldsToUpdate[id].shipViaDetails = details;
    };

    return (
      <IonRow class='ship-via-line-items-row ship-via-line-item-card-header'>
        <IonCol size='0.55' class='column-left'>
          <div className='column-container right-align'>
            <LineItemField
              valueType={QuoteLineItemValueTypes.Line}
              datatestid='QAShipViaAssemblyGroupNumber'
              displayedValue={createLineNumber(props.lineItem)}
            />
          </div>
        </IonCol>
        <IonCol size='5' class='column-left right-align'>
          <LineItemField
            valueType={QuoteLineItemValueTypes.ItemDescription}
            unitType={QuoteLineItemFieldUnitTypes.ItemDescription}
            datatestid='QAShipViaPopoverAssemblyGroupDescription'
            imperialValue={props.lineItem.ItemDescriptionImp__c}
            metricValue={props.lineItem.ItemDescriptionMetric__c}
          />
        </IonCol>
        <IonCol size='3' class='column-left'>
         <SelectionContainer
            type={SelectionTypes.DropDown}
            datatestid='QAShipViaAssemblyGroupShipVia'
            placeholder="Motor Freight (DMC Choice)"
            options={shippingSelections}
            style={{ width: '153px', marginTop: '0px !important', menuListHeight: '125px'}}
            onChange={(shipVia) => updateShipVia(shipVia.label)}
            value={shipVia}
         />
        </IonCol>
        <IonCol size='3' class='column-left'>
          <SelectionContainer
            placeholder="Add Details"
            type={SelectionTypes.TextInput}
            style={{ width: '153px', marginTop: '0', borderBottom: 'solid 1px var(--nc-hyperlink)'}}
            onBlur={details => updateShipViaDetails(details)}
            value={shipViaDetails}
          />
        </IonCol>
      </IonRow>
    );
  };

  const LineItemList = () => {
    return (
      <>
        {quoteLineItems?.orderBy(lineItem => lineItem.Line__c).map((shipViaLineItem) => {
          return <LineItemDetails key={`shipToAddress${shipViaLineItem.Id}`} lineItem={shipViaLineItem}/>;
        })}
      </>
    );
  };

  const ShipViaTable = () => {
    const tableHeadersShipViaTable = [
      { display: QuoteLineItemsColumnHeadings.Hashtag, colSize: '0.55' },
      { display: QuoteLineItemsColumnHeadings.Description, colSize: '5.04' },
      { display: QuoteLineItemsColumnHeadings.ShipVia, colSize: '3' },
      { display: QuoteLineItemsColumnHeadings.AddDetails, colSize: '3' },
    ];

    return (
      <div className='ship-via-items-container'>
        <TableHeaders labels={tableHeadersShipViaTable}/>
        <LineItemList/>
      </div>
    );
  };

  return (
    <div className='popover ship-via'>
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class='close-icon' onClick={() => {
        dismissModal();
      }}
      ></IonImg>
      <div className='title-container'>
        <IonLabel class='ship-via-title' data-testid={'QAShipViaPopoverHeader'}>
          Ship Via
        </IonLabel>
      </div>
      <hr />
      <ShipViaTable/>
      <hr className='bottom-rule' />
      <div className='cta-button-container'>
        <IonButton color='primary' class='save-button' data-testid='QAShipViaPopoverSaveButton' onClick={() => {
          saveShipVia();
          dismissModal();
        }}
        >
          Save
        </IonButton>
        <IonButton color='secondary' class='cancel-button' onClick={() => {
          cancelShipVia();
          dismissModal();
        }}
        >
          Cancel
        </IonButton>
      </div>
    </div>
  );
};

export default ShipViaPopover;