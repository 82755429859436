
import { IonicAuthOptions } from '@ionic-enterprise/auth';

/**
 * Do Not Modifify
 *
 * This file is automatically generated by env.config.js
 * using the appropriate .env file or the Heroku config
 * values.
 */

 export const azureWebConfig : IonicAuthOptions = {
  authConfig: 'azure',
  clientID: '00f576ad-c0c6-42a8-9255-dfd8a42634eb',
  discoveryUrl: 'https://nobelclad.b2clogin.com/nobelclad.onmicrosoft.com/B2C_1A_TOU_SUSI/v2.0/.well-known/openid-configuration',
  implicitLogin: 'CURRENT',
  scope: 'openid profile https://nobelclad.onmicrosoft.com/00f576ad-c0c6-42a8-9255-dfd8a42634eb/user',
  webAuthFlow: 'PKCE',
  logoutUrl: 'https://ncportal.dev/logout',
  platform: 'web',
  redirectUri: 'https://ncportal.dev/callback',
  
};

export const azureNativeConfig  : IonicAuthOptions = {
  authConfig: 'azure',
  clientID: '00f576ad-c0c6-42a8-9255-dfd8a42634eb',
  discoveryUrl: 'https://nobelclad.b2clogin.com/nobelclad.onmicrosoft.com/B2C_1A_TOU_SUSI/v2.0/.well-known/openid-configuration',
  implicitLogin: 'CURRENT',
  scope: 'openid profile https://nobelclad.onmicrosoft.com/00f576ad-c0c6-42a8-9255-dfd8a42634eb/user',
  webAuthFlow: 'PKCE',
  iosWebView: 'private',
  logoutUrl: 'com.nc.app://logout',
  platform: 'capacitor',
  redirectUri: 'com.nc.app://callback',
  
};

export const environment = {
  API_BASE_URL: 'https://nc-wus-dev-97c10a-apim.azure-api.net',
  CERTIFICATIONS_BASE_URL: 'https://www.nobelclad.com/-/media/Project/DMC/NobelClad-V2/certificates',
  DMC_WEB_ROOT: 'https://www.nobelclad.com',
  ENV: 'dev',
  FEEDBACK_URL: 'https://ncfeedback.z19.web.core.windows.net/feedback-dev.html',
  GTM_ID: '',
  IMAGE_BASE_URL: 'https://www.nobelclad.com/-/media/Project/DMC/NobelClad-V2/promo-exports',
  JWT_PRIVATE_KEY: 'MIIBOwIBAAJBAN5JkP0JTFlwkaFnCIQyKXpupd0DpjmOUqe53s7KSToYz0ck8To324sTiH8+TH8Lo8ekeh1htfhGtfQvroAQ0lMCAwEAAQJActC25iw2VoBRiHfKq1WpnQan/4fSBSqhQOc90bwkpK+TXk9re/8m0KeUIiU1Ulji2SF+Xk3O8ml8HbK5L6OCUQIhAPW5h2+JHWM02JPcNpJHkczmPDrnwUb4JEHbEoB/HO7LAiEA55UkQAuyrtlkakZB/Fy2Xlsfuc68n/lVJCz3htDL8ZkCIGN+g8A9nJCWzTrRZ6dDI137u1d+j1vVDZk8wvkUfvqRAiEApncRgKwn/AGuaaopIoNuX/L2ojSWaBPs/L0KDLGn9/kCIQCdYV4G0e55b/sm1iYhKlupr02i/nkO8RtBOoynTTPybA==',
  MOCK: false,
  MULTI_SHOT_FEATURE_FLAG: true,
  ONESIGNAL_APP_ID: 'ca07e8cc-1d20-40a0-9375-e011eef7ab64',
  PRODUCTION: false,
  SALESFORCE_URL: 'https://dmcglobal--fulldev.lightning.force.com/',
  SITE_URL: 'https://stage.sc.nobelclad.com/',
  
};