import {
  ManufacturingSites,
  MeasurementSystems,
  QuoteLineItemDependentUnwrittenFields,
  QuoteLineItemValueTypes,
  RawMaterialUsage
} from '@constants';
import { Action, QuoteActionTypes } from '@context/quote-context';
import { MultiShotItemDetail } from '@interfaces/multi-shot-item-detail';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { RawMaterial } from '@interfaces/raw-material';
import { Currency } from '@interfaces/salesforce';
import { DependentUnwrittenFieldsMapValueType } from '@shared/types';
import { createNewEmptyQuoteLineItemPair } from '../../../../../quote-utils';
import { LineFieldsToUpdate } from '../../add-quote-item-popover';
import { buildMapFromState } from '../multi-shot';
import { MultiShotItemStateDetail, MultiShotState } from '../useMultiShotReducer';

export type QuoteLinePair = {
  quoteLineItem: QuoteLineItem;
  quoteLineItemDetail: QuoteLineItemDetail;
};

export type VirtualGroup = {
  Id: string;
  virtualLineUUID: string;
  name: string;
  shotNum: number;
  // isDoubleSidedClad?: boolean;
};

export type MultiShotVirtualGroup = {
  parentLineUUID: string;
  virtualShotGroups: VirtualGroup[];
};

// this function is used to create the virtual lines for the multishot details
export const createVirtualLinesForMSDetail = (
  multiShotMetalsStateRef: React.MutableRefObject<MultiShotState>,
  rawMaterials: RawMaterial[],
  currencyIsoCode: Currency,
  manufacturingSite: ManufacturingSites,
  exportPackaging: boolean,
  updateQuoteLineData: (
    quoteLineItemToEdit: QuoteLineItem,
    updatedFields?: LineFieldsToUpdate,
    clad?: RawMaterial
  ) => QuoteLineItem,
  dispatchQuoteState: React.Dispatch<Action>,
  saveQuoteLineItem: (
    dependentUnwrittenFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>,
    fieldsUpdated: QuoteLineItemValueTypes[],
    quoteLineItemToEdit: QuoteLineItem,
    duplicatingFlag?: boolean,
    saveMultipleFlag?: boolean,
    leftoverLineCreation?: boolean,
    reset?: boolean,
    preventDependencyRecalcs?: boolean
  ) => { lineUpdating: boolean; quoteLineItems: QuoteLineItem[] },
  dependentUnwrittenFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>,
  fieldsUpdated: any[],
  handleLeftoverQuantitiesForAutocombine: (
    dependentUnwrittenFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>,
    quoteLineItemToCreate: QuoteLineItem,
    quoteLineItemDetailToCreate: QuoteLineItemDetail,
    quoteLineItems: QuoteLineItem[],
    keepPopoverVisible?: boolean
  ) => void,
  qli: QuoteLineItem,
): MultiShotVirtualGroup => {
  const virtualShotGroups = [] as VirtualGroup[];
  const multiShotMap: Map<number, MultiShotItemStateDetail> = buildMapFromState(multiShotMetalsStateRef.current);
  console.log('multiShotMap: ', multiShotMap);

  const line: string = qli.Line__c;
  const parentLineUUID: string = qli.UUID__c ?? '';

  const baseShot: MultiShotItemStateDetail = multiShotMap.get(0);

  // the key is the shot number, the value is the multishot item state detail
  for (const [key, cladShot] of multiShotMap.entries()) {
    if (key <= 1) { // this (1) is the base shot, so we skip it
      continue;
    }

    const cladMetal = rawMaterials.find(
      (rm) =>
        rm.name === cladShot.metal && rm.usage === RawMaterialUsage.Clad && rm.dataAreaId.includes(manufacturingSite)
    );

    const previousMultiShotsUpUntilThisShot: [number, MultiShotItemStateDetail][] = [...multiShotMap.entries()].filter(([i]) => i > 0 && i <= key);
    
    // add up all the thicknesses for the clads for every key before this key, and add it to the base shot thickness
    const updatedBaseTK = previousMultiShotsUpUntilThisShot.reduce(
      (acc, [, value]) => acc + value.thickness,
      baseShot.thickness // lastItem.Unit_of_Measure__c === MeasurementSystems.Imperial ? lastItem.quoteLineItemDetail.Base_TK_in__c: lastItem.quoteLineItemDetail.Base_TK_mm__c
    );

    const msData: LineFieldsToUpdate = {
      baseMetalType: baseShot?.metalType,
      baseMetalProposalType: baseShot?.proposalType,
      finishedBaseMetalThickness: updatedBaseTK,
      cladMetalType: cladShot.metalType,
      cladMetalProposalType: cladShot.proposalType,
      finishedCladMetalThickness: cladShot.thickness,
      baseMetalCustomerProvided: baseShot?.customerProvided,
      cladMetalCustomerProvided: cladShot?.customerProvided,
    } as LineFieldsToUpdate;

    const linePrefix = `${line.split('').reverse().join('')}${key}`; // TODO: kf - this needs to be addressed. it goes from line 005 to 008 if there are 2 ms details on line 5. Also delete needs to be addressed.
    const displayId: number = parseInt(`${linePrefix}`);

    const [qli] = createNewEmptyQuoteLineItemPair(displayId, currencyIsoCode, manufacturingSite, exportPackaging);

    updateQuoteLineData(qli, msData, cladMetal); // from add quote item popover

    dispatchQuoteState({ type: QuoteActionTypes.updateLineToDuplicate, payload: undefined });

    qli.isVirtualLineItem = true;
    
    if (manufacturingSite === ManufacturingSites.DE01) {
      qli.quoteLineItemDetail.StressReliefHT__c = false;
      qli.quoteLineItemDetail.SubcontractHeatTreatment__c = true;
    }

    const { quoteLineItems: _quoteLineItems } = saveQuoteLineItem( // frankee from the save function
      dependentUnwrittenFieldsMap,
      fieldsUpdated,
      qli,
      false
    );

    handleLeftoverQuantitiesForAutocombine(
      dependentUnwrittenFieldsMap,
      qli,
      qli.quoteLineItemDetail,
      _quoteLineItems,
      true
    );

    // get the UUID of the last line item created
    const lineUUID = _quoteLineItems[_quoteLineItems.length - 1]?.UUID__c ?? '';

    const msvg: VirtualGroup = {
      Id: '',
      virtualLineUUID: lineUUID,
      name: `${line} - ${key}`,
      shotNum: key,
    };

    virtualShotGroups.push({ ...msvg });
  }
  return {
    parentLineUUID: parentLineUUID,
    virtualShotGroups: virtualShotGroups,
  } as MultiShotVirtualGroup;
};

export const createMultiShot = (
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail,
  shot: VirtualGroup,
  parentLineItem: QuoteLineItem,
): MultiShotItemDetail => {
  const isImperial = (qlid.Unit_of_Measure__c ?? qli.Unit_of_Measure__c) === MeasurementSystems.Imperial;
  
  const multiShot = {
    AdditionalGrind__c: true,
    AdditionalSawCut__c: true,
    AnvilCost__c: qlid.PurchaseAnvilPrice__c,
    AnvilMaterial__c: '516-70N-ST',
    Anvilneeded__c: qlid.Anvil_Needed__c,
    Area__c: isImperial ? qlid.Area_Sqft__c : qlid.Area_Sqm__c,
    AreaAnvil__c: isImperial ? qlid.Anvil_Area_in__c : qlid.Anvil_Area_mm__c,
    Areabacker__c: isImperial ? qlid.AreaBacker_in__c : qlid.AreaBacker_mm__c,
    Areacladder__c: isImperial ? qlid.AreaCladder_in__c : qlid.AreaCladder_mm__c,
    AreaFlyer__c: isImperial ? qlid.Flyer_Area_in__c : qlid.Flyer_Area_mm__c,
    AreasqftAS__c: parentLineItem.quoteLineItemDetail.Area_Sqft__c, // TODO: AS field : qlid.AreaCladder_in__c X qlid.AreaBacker_in__c : (Clad_Length_in__c * Clad_Width_in__c) / 144 X (Base_Length_in__c * Base_Width_in__c) / 144 - DEPENDS ON FORGING OF THE BASE, baseMetal.dmcClass.includes('/F')
    AreasqftBK__c: qlid.AreaBacker_in__c,
    AreasqftCL__c: qlid.AreaCladder_in__c,
    AreaSqMAS__c: parentLineItem.quoteLineItemDetail.Area_Sqm__c, // TODO: AS field : qlid.AreaCladder_mm__c X qlid.AreaBacker_mm__c : (Clad_Length_mm__c * Clad_Width_mm__c) / 1000000 X (Base_Length_mm__c * Base_Width_mm__c) / 1000000 - DEPENDS ON FORGING OF THE BASE, baseMetal.dmcClass.includes('/F')
    AreaSqMBK__c: qlid.AreaBacker_mm__c,
    AreaSqMCL__c: qlid.AreaCladder_mm__c,
    Assembly__c: true,
    AutoUT__c: true,
    BaseHTSubcontracting__c: qlid.NC_Outside_Service_Item_Details__r?.BackerMetalHTCostOfEvent__c > 0,
    BaseHTSubcontractingCost__c: qlid.NC_Outside_Service_Item_Details__r?.BackerMetalHTCostOfEvent__c,
    BaseHTSubcontractingFreightEstimate__c:
      qlid.NC_Outside_Service_Item_Details__r?.BackerMetalHTTotalInboundShippingCost__c +
      qlid.NC_Outside_Service_Item_Details__r?.BackerMetalHTTotalOutboundShippingCost__c,
    BaseMetal__c: qli.BaseMetal__c,
    Clad_Length_in__c: qlid.Clad_Length_in__c,
    Clad_Length_mm__c: qlid.Clad_Length_mm__c,
    Clad_TK_in__c: qlid.Clad_TK_in__c,
    Clad_TK_mm__c: qlid.Clad_TK_mm__c,
    Clad_Weight_Kg__c: qlid.Clad_Weight_Kg__c, // TODO: NOTE: set in rollingMSValues
    Clad_Weight_Lbs__c: qlid.Clad_Weight_Lbs__c, // TODO: NOTE: set in rollingMSValues
    Clad_Width_in__c: qlid.Clad_Width_in__c,
    Clad_Width_mm__c: qlid.Clad_Width_mm__c,
    CladderHTSubcontracting__c: qlid.NC_Outside_Service_Item_Details__r?.CladderMetalHTCostOfEvent__c > 0,
    CladderHTSubcontractingCost__c: qlid.NC_Outside_Service_Item_Details__r?.CladderMetalHTCostOfEvent__c,
    CladderHTSubcontractingFreightEstimate__c:
      qlid.NC_Outside_Service_Item_Details__r?.CladderMetalHTTotalInboundShippingCost__c +
      qlid.NC_Outside_Service_Item_Details__r?.CladderMetalHTTotalOutboundShippingCost__c,
    CladderLengthSW1__c: qlid.NC_Seam_Weld_Item_Details__r.CladderLengthSW1__c,
    CladderLengthSW2__c: qlid.NC_Seam_Weld_Item_Details__r.CladderLengthSW2__c,
    CladderLengthSW3__c: qlid.NC_Seam_Weld_Item_Details__r.CladderLengthSW3__c,
    CladderWidthSW1__c: qlid.NC_Seam_Weld_Item_Details__r.CladderWidthSW1__c,
    CladderWidthSW2__c: qlid.NC_Seam_Weld_Item_Details__r.CladderWidthSW2__c,
    CladderWidthSW3__c: qlid.NC_Seam_Weld_Item_Details__r.CladderWidthSW3__c,
    CladMetal__c: qli.CladMetal__c,
    ConfigurationID__c: qlid.ConfigurationID__c,
    ConfigurationIDAnvil__c: qlid.NC_Anvil_Item_Details__r.ConfigurationIDAnvil__c,
    ConfigurationIDBacker__c: qlid.ConfigurationIDBacker__c,
    ConfigurationIDCladder__c: qlid.ConfigurationID__c,
    ConfigurationIDSW2__c: qlid.NC_Seam_Weld_Item_Details__r.ConfigurationIDSW2__c,
    ConfigurationIDSW3__c: qlid.NC_Seam_Weld_Item_Details__r.ConfigurationIDSW3__c,
    CurrencyIsoCode: qlid.CurrencyIsoCode,
    EstimatedShippingRatebacker__c: isImperial ? qlid.PurchaseBackerFreight_LB__c : qlid.PurchaseBackerFreight_KG__c,
    EstimatedShippingRateCladder__c: isImperial ? qlid.PurchaseCladderFreight_LB__c : qlid.PurchaseCladderFreight_KG__c,
    EstimatedShippingTotalAnvil__c: isImperial
      ? qlid.PurchaseAnvilFreight_LB__c * qlid.PurchaseAnvilWeight_LB__c
      : qlid.PurchaseAnvilFreight_KG__c * qlid.PurchaseAnvilWeight_KG__c,
    EstimatedShippingTotalbacker__c: isImperial
      ? qlid.PurchaseBackerFreight_LB__c * qlid.PurchaseBackerWeight_LB__c
      : qlid.PurchaseBackerFreight_KG__c * qlid.PurchaseBackerWeight_KG__c,
    EstimatedShippingTotalcladder__c: isImperial
      ? qlid.PurchaseCladderFreight_LB__c * qlid.PurchaseCladderWeight_LB__c
      : qlid.PurchaseCladderFreight_KG__c * qlid.PurchaseCladderWeight_KG__c,
    EstimatedShippingTotalSW1__c: qlid.NC_Seam_Weld_Item_Details__r.EstimatedShippingTotalSW1__c,
    EstimatedShippingTotalSW2__c: qlid.NC_Seam_Weld_Item_Details__r.EstimatedShippingTotalSW2__c,
    EstimatedShippingTotalSW3__c: qlid.NC_Seam_Weld_Item_Details__r.EstimatedShippingTotalSW3__c,
    ExplosiveItem__c: qlid.ExplosiveItem__c,
    ExplosiveItemEU__c: qlid.ExplosiveItemEU__c,
    ExplosiveQuantity__c: qlid.Explosive_Load_lb_sq_ft__c,
    ExplosiveQuantityEU__c: qlid.Explosive_Load_kg_sq_m__c,
    FerritetestofWeld__c: qlid.FerriteNumberRequired__c ?? 0,
    FGQuantity__c: qli.Quantity__c,
    FlatteningSubcontracting__c: qlid.NC_Outside_Service_Item_Details__r?.FlatteningCostOfEvent__c > 0,
    FlatteningSubcontractingCost__c: qlid.NC_Outside_Service_Item_Details__r?.FlatteningCostOfEvent__c,
    FlatteningSubcontractingFreightEstimate__c:
      qlid.NC_Outside_Service_Item_Details__r?.FlatteningTotalInboundShippingCost__c +
      qlid.NC_Outside_Service_Item_Details__r?.FlatteningTotalOutboundShippingCost__c,
    FlyerMaterial__c: '209-1100-AL',
    Flyerneeded__c: qlid.Flyer_Needed__c,
    HeatTreatSubcontractingCost__c: qlid.NC_Outside_Service_Item_Details__r?.PostbondHTTotalCost__c,
    HeatTreatSubcontractingFreightEstimate__c:
      qlid.NC_Outside_Service_Item_Details__r?.PostbondHTTotalInboundShippingCost__c +
      qlid.NC_Outside_Service_Item_Details__r?.PostbondHTTotalOutboundShippingCost__c,
    Inspection__c: true,
    InspectionBK__c: qlid.NC_Outside_Service_Item_Details__r?.InspectionBKCostOfEvent__c > 0,
    // InspectionCL__c: qlid.NC_Outside_Service_Item_Details__r?.InspectionCLCostOfEvent__c > 0,
    InspectionBKSubcontractingCost__c: qlid.NC_Outside_Service_Item_Details__r?.InspectionBKCostOfEvent__c,
    InspectionBKSubcontractingFreightEstim__c:
      qlid.NC_Outside_Service_Item_Details__r?.InspectionBKTotalInboundShippingCost__c +
      qlid.NC_Outside_Service_Item_Details__r?.InspectionBKTotalOutboundShippingCost__c,
    InspectionCLSubcontractingCost__c: qlid.NC_Outside_Service_Item_Details__r?.InspectionCLCostOfEvent__c,
    InspectionCLSubcontractingFreightEstimat__c:
      qlid.NC_Outside_Service_Item_Details__r?.InspectionCLTotalInboundShippingCost__c +
      qlid.NC_Outside_Service_Item_Details__r?.InspectionCLTotalOutboundShippingCost__c,
    Item__c: qli.Item__c ?? 0, // this is the inverse of PeggedSupply__c, for MS it should be 0
    ItemDescriptionAnvilim__c: qlid.NC_Anvil_Item_Details__r.ItemDescriptionAnvilim__c,
    ItemDescriptionAnvilmm__c: qlid.NC_Anvil_Item_Details__r.ItemDescriptionAnvilmm__c,
    ItemDescriptionCladderim__c: qlid.ItemDescriptionCladderim__c,
    ItemDescriptionCladdermm__c: qlid.ItemDescriptionCladdermm__c,
    ItemDescriptionSeamWeld1im__c: qlid.NC_Seam_Weld_Item_Details__r.ItemDescriptionSeamWeld1im__c,
    ItemDescriptionSeamWeld1mm__c: qlid.NC_Seam_Weld_Item_Details__r.ItemDescriptionSeamWeld1mm__c,
    ItemDescriptionSeamWeld2im__c: qlid.NC_Seam_Weld_Item_Details__r.ItemDescriptionSeamWeld2im__c,
    ItemDescriptionSeamWeld2mm__c: qlid.NC_Seam_Weld_Item_Details__r.ItemDescriptionSeamWeld2mm__c,
    ItemDescriptionSeamWeld3im__c: qlid.NC_Seam_Weld_Item_Details__r.ItemDescriptionSeamWeld3im__c,
    ItemDescriptionSeamWeld3mm__c: qlid.NC_Seam_Weld_Item_Details__r.ItemDescriptionSeamWeld3mm__c,
    Length__c: isImperial ? qlid.Base_Length_in__c : qlid.Base_Length_mm__c,
    LengthAnvil__c: isImperial ? qlid.Anvil_Length_in__c : qlid.Anvil_Length_mm__c,
    LengthAS__c: isImperial ? parentLineItem.quoteLineItemDetail.Base_Length_in__c : parentLineItem.quoteLineItemDetail.Base_Length_mm__c, // TODO: AS field
    LengthFlyer__c: isImperial ? qlid.Flyer_Length_in__c : qlid.Flyer_Length_mm__c,
    LengthimAS__c: parentLineItem.quoteLineItemDetail.Base_Length_in__c, // TODO: AS field
    LengthimBK__c: qlid.Base_Length_mm__c,
    LengthimCL__c: qlid.Clad_Length_in__c,
    LengthmmAS__c: parentLineItem.quoteLineItemDetail.Base_Length_mm__c, // TODO: AS field
    LengthmmBK__c: qlid.Base_Length_mm__c,
    LengthmmCL__c: qlid.Clad_Length_mm__c,
    MasterPlateWeight__c: (isImperial ? qlid.Raw_Clad_Weight_Lbs__c : qlid.Raw_Clad_Weight_Kg__c) + (isImperial ? qlid.Raw_Base_Weight_Lbs__c : qlid.Raw_Base_Weight_Kg__c), // TODO: NOTE: getting set in rollingMSValues
    MetalClassAnvil__c: 1,
    MetalClassbacker__c: qlid.MetalClassBacker__c,
    MetalClasscladder__c: qlid.MetalClassCladder__c,
    MetalClassFlyer__c: 8,
    Name: `${qli.Line__c} - ${shot.shotNum}`,
    NC_Quote_Line_Item_Detail__c: qlid.Id ?? qlid.associatedLineItem ?? qlid.UUID__c, // TODO: revisit this
    NetWeightKgAS__c: qlid.Clad_Weight_Kg__c + parentLineItem.quoteLineItemDetail.Base_Weight_Kg__c, // TODO: AS field
    NetWeightKgBK__c: qlid.Base_Weight_Kg__c,
    NetWeightKgCL__c: qlid.Clad_Weight_Kg__c,
    NetWeightlbsAS__c: qlid.Clad_Weight_Lbs__c + parentLineItem.quoteLineItemDetail.Base_Weight_Lbs__c, // TODO: AS field
    NetWeightlbsBK__c: qlid.Base_Weight_Lbs__c,
    NetWeightlbsCL__c: qlid.Clad_Weight_Lbs__c,
    NominalThicknessAnvil__c: isImperial ? qlid.Anvil_TK_in__c : qlid.Anvil_TK_mm__c,
    NominalThicknessAS__c: (isImperial ? qli.Clad_Nom_in__c : qli.Clad_Nom_mm__c) + (isImperial ? parentLineItem.Base_Nom_in__c : parentLineItem.Base_Nom_mm__c), // TODO: AS field NOTE: getting set in rollingMSValues
    NominalThicknessBK__c: (isImperial ? qli.Base_Nom_in__c : qli.Base_Nom_mm__c),
    NominalThicknessCladder__c: isImperial ? qlid.Clad_TK_in__c : qlid.Clad_TK_mm__c,
    NominalThicknessFlyer__c: isImperial ? qlid.Flyer_TK_IN__c : qlid.Flyer_TK_MM__c,
    NominalThicknessSW1__c: qlid.NC_Seam_Weld_Item_Details__r.NominalThicknessSW1__c,
    NominalThicknessSW2__c: qlid.NC_Seam_Weld_Item_Details__r.NominalThicknessSW2__c,
    NominalThicknessSW3__c: qlid.NC_Seam_Weld_Item_Details__r.NominalThicknessSW3__c,
    PeggedSupply__c: qli.PeggedSupply__c ?? 1, // this is the inverse of Item__c, for MS it should be 1
    PreheattoCut__c: qlid.PreheattoCut__c,
    PreheattoCutAnvil__c: qlid.PreheattoCutAnvil__c,
    PreheattoTorchCutBacker__c: qlid.PreheattoTorchCutBacker__c,
    PreheattoTorchCutCladder__c: qlid.PreheattoTorchCutCladder__c,
    // Proposal_Type_Clad__c: qli.Proposal_Type_Clad__c,
    Pullfromstockbacker__c: qlid.Pullfromstockbacker__c,
    PullfromstockBKAnvil__c: qlid.PullfromstockBKAnvil__c,
    Pullfromstockcladder__c: qlid.Pullfromstockcladder__c,
    PullFromStockQuantitySW1__c: qlid.NC_Seam_Weld_Item_Details__r.PullFromStockQuantitySW1__c,
    PullFromStockQuantitySW2__c: qlid.NC_Seam_Weld_Item_Details__r.PullFromStockQuantitySW2__c,
    PullFromStockQuantitySW3__c: qlid.NC_Seam_Weld_Item_Details__r.PullFromStockQuantitySW3__c,
    PullFromStockSW1__c: qlid.NC_Seam_Weld_Item_Details__r.PullFromStockSW1__c,
    PullFromStockSW2__c: qlid.NC_Seam_Weld_Item_Details__r.PullFromStockSW2__c,
    PullFromStockSW3__c: qlid.NC_Seam_Weld_Item_Details__r.PullFromStockSW3__c,
    PurchaseBK__c: qlid.PurchaseBK__c,
    PurchaseBKAnvil__c: qlid.PurchaseBKAnvil__c,
    PurchaseCL__c: qlid.PurchaseCL__c,
    PurchaseSW1__c: qlid.NC_Seam_Weld_Item_Details__r.PurchaseSW1__c,
    PurchaseSW2__c: qlid.NC_Seam_Weld_Item_Details__r.PurchaseSW2__c,
    PurchaseSW3__c: qlid.NC_Seam_Weld_Item_Details__r.PurchaseSW3__c,
    QuantityAnvil__c: qlid.NC_Anvil_Item_Details__r.QuantityAnvil__c,
    QuantityFlyer__c: qlid.NC_Flyer_Item_Details__r.QuantityFlyer__c,
    QuantitySW1__c: qlid.NC_Seam_Weld_Item_Details__r.QuantitySW1__c,
    QuantitySW2__c: qlid.NC_Seam_Weld_Item_Details__r.QuantitySW2__c,
    QuantitySW3__c: qlid.NC_Seam_Weld_Item_Details__r.QuantitySW3__c,
    Radiography__c: qlid.Radiography__c,
    RawBaseCost__c: qlid.PurchaseBackerPrice__c,
    RawBaseLength__c: isImperial ? qlid.Base_Length_in__c : qlid.Base_Length_mm__c,
    RawBaseWeight__c: isImperial ? qlid.Base_Weight_Lbs__c : qlid.Base_Weight_Kg__c,
    RawBaseWidth__c: isImperial ? qlid.Base_Width_in__c : qlid.Base_Width_mm__c,
    RawCladderCost__c: qlid.PurchaseCladderPrice__c,
    RawCladderWeight__c: isImperial ? qlid.Clad_Weight_Lbs__c : qlid.Clad_Weight_Kg__c, // TODO: NOTE: in rollingMSValues
    RawcladLength__c: isImperial ? qlid.Clad_Length_in__c : qlid.Clad_Length_mm__c,
    rawcladWidth__c: isImperial ? qlid.Clad_Width_in__c : qlid.Clad_Width_mm__c,
    RawMaterialVendorBacker__c: qlid.RawMaterialVendorBacker__c ?? '0',
    RawMaterialVendorCladder__c: qlid.RawMaterialVendorCladder__c ?? '0',
    RawMaterialVendorSW1__c: qlid.NC_Seam_Weld_Item_Details__r.RawMaterialVendorSW1__c ?? '0',
    RawMaterialVendorSW2__c: qlid.NC_Seam_Weld_Item_Details__r.RawMaterialVendorSW2__c ?? '0',
    RawMaterialVendorSW3__c: qlid.NC_Seam_Weld_Item_Details__r.RawMaterialVendorSW3__c ?? '0',
    SalesOrderQuantityDividingfactor__c: qli.Quantity__c / qlid.Clads__c,
    SeamWeld__c: qlid.SeamWeld__c, // TODO: we need a story made for this field
    SeamWeldPMI__c: qlid.SeamWeld__c,
    ShipDateAnvil__c: qlid.ShipDateAnvil__c,
    ShipDateBase__c: qlid.ShipDateBase__c,
    ShipDateCladder__c: qlid.ShipDateCladder__c,
    ShipDateSW1__c: qlid.NC_Seam_Weld_Item_Details__r.ShipDateSW1__c,
    ShipDateSW2__c: qlid.NC_Seam_Weld_Item_Details__r.ShipDateSW2__c,
    ShipDateSW3__c: qlid.NC_Seam_Weld_Item_Details__r.ShipDateSW3__c,
    Shot_Number__c: shot.shotNum,
    StressReliefHT__c: qlid.StressReliefHT__c,
    SubcontractHeatTreatment__c: qlid.SubcontractHeatTreatment__c,
    SubcontractHeatTreatmentBacker__c: qlid.NC_Outside_Service_Item_Details__r?.BackerMetalHTCostOfEvent__c > 0,
    SubcontractInspectionBK__c: qlid.NC_Outside_Service_Item_Details__r?.InspectionBKCostOfEvent__c > 0,
    SubcontractInspectionCL__c: qlid.NC_Outside_Service_Item_Details__r?.InspectionCLCostOfEvent__c > 0,
    SubcontractTestingServicesBK__c: qlid.NC_Outside_Service_Item_Details__r?.TestingBKCostOfEvent__c > 0,
    SubcontractTestingServicesCL__c: qlid.NC_Outside_Service_Item_Details__r?.TestingCLCostOfEvent__c > 0,
    SWRawMaterialCostSW1__c: qlid.NC_Seam_Weld_Item_Details__r.SWRawMaterialCostSW1__c,
    SWRawMaterialCostSW2__c: qlid.NC_Seam_Weld_Item_Details__r.SWRawMaterialCostSW2__c,
    SWRawMaterialCostSW3__: qlid.NC_Seam_Weld_Item_Details__r.SWRawMaterialCostSW3__c,
    TestingServicesBKSubcontractingCost__c: qlid.NC_Outside_Service_Item_Details__r?.TestingBKCostOfEvent__c,
    TestingServicesBKSubcontractingFreight__c:
      qlid.NC_Outside_Service_Item_Details__r?.TestingBKTotalInboundShippingCost__c +
      qlid.NC_Outside_Service_Item_Details__r?.TestingBKTotalOutboundShippingCost__c,
    TestingServicesCLSubcontractingCost__c: qlid.NC_Outside_Service_Item_Details__r?.TestingCLCostOfEvent__c,
    TestingServicesCLSubcontractingFreightEs__c:
      qlid.NC_Outside_Service_Item_Details__r?.TestingCLTotalInboundShippingCost__c +
      qlid.NC_Outside_Service_Item_Details__r?.TestingCLTotalOutboundShippingCost__c,
    ThicknessminimAS__c: qli.Clad_Min_in__c + parentLineItem.Base_Min_in__c, // TODO: AS field
    ThicknessminimBK__c: qli.Base_Min_in__c,
    ThicknessminimCL__c: qli.Clad_Min_in__c,
    ThicknessminmmAS__c: qli.Clad_Min_mm__c + parentLineItem.Base_Min_mm__c, // TODO: AS field
    ThicknessminmmBK__c: qli.Base_Min_mm__c,
    ThicknessminmmCL__c: qli.Clad_Min_mm__c,
    ThicknessnomimAS__c: qli.Clad_Nom_in__c + parentLineItem.Base_Nom_in__c, // TODO: AS field
    ThicknessnomimBK__c: qli.Base_Nom_in__c,
    ThicknessnomimCL__c: qlid.Clad_TK_in__c,
    ThicknessnommmAS__c: qli.Clad_Nom_mm__c + parentLineItem.Base_Nom_mm__c, // TODO: AS field
    ThicknessnommmBK__c: qli.Base_Nom_mm__c,
    ThicknessnommmCL__c: qlid.Clad_TK_mm__c,
    TorchCutAnvil__c: qlid.PullfromstockBKAnvil__c && !qlid.SeamWeld__c,
    TorchCutBacker__c: qlid.Pullfromstockbacker__c && !qlid.SeamWeld__c,
    TorchCutCladder__c: qlid.Pullfromstockcladder__c && !qlid.SeamWeld__c,
    UnitofMeasure__c: qli.Unit_of_Measure__c,
    UnitofMeasureanvil__c: qli.Unit_of_Measure__c,
    UnitofMeasurebacker__c: qli.Unit_of_Measure__c,
    UnitofMeasureCladder__c: qli.Unit_of_Measure__c,
    UnitofMeasureflyer__c: qli.Unit_of_Measure__c,
    VendorAnvil__c: qlid.VendorAnvil__c ?? '0',
    VendorFlyer__c: qlid.VendorFlyer__c ?? '0',
    WeightAnvil__c: qlid.NC_Anvil_Item_Details__r.WeightAnvil__c,
    WeightSW1__c: qlid.NC_Seam_Weld_Item_Details__r.WeightSW1__c,
    WeightSW2__c: qlid.NC_Seam_Weld_Item_Details__r.WeightSW2__c,
    WeightSW3__c: qlid.NC_Seam_Weld_Item_Details__r.WeightSW3__c,
    WeldingSubcontracting__c: qlid.NC_Outside_Service_Item_Details__r?.WeldingCostOfEvent__c > 0,
    WeldingSubcontractingCost__c: qlid.NC_Outside_Service_Item_Details__r?.WeldingCostOfEvent__c,
    WeldingSubcontractingFreightEstimate__c:
      qlid.NC_Outside_Service_Item_Details__r?.WeldingTotalInboundShippingCost__c +
      qlid.NC_Outside_Service_Item_Details__r?.WeldingTotalOutboundShippingCost__c,
    Width__c: isImperial ? qlid.Base_Width_in__c : qlid.Base_Width_mm__c,
    WidthAnvil__c: isImperial ? qlid.Anvil_Width_in__c : qlid.Anvil_Width_mm__c,
    WidthFlyer__c: isImperial ? qlid.Flyer_Width_in__c : qlid.Flyer_Width_mm__c,
    WidthimAS__c: parentLineItem.quoteLineItemDetail.Base_Width_in__c, // TODO: AS field
    WidthimBK__c: qlid.Base_Width_in__c,
    WidthimCL__c: qlid.Clad_Width_in__c,
    WidthmmAS__c: parentLineItem.quoteLineItemDetail.Base_Width_mm__c, // TODO: AS field
    WidthmmBK__c: qlid.Base_Width_mm__c,
    WidthmmCL__c: qlid.Clad_Width_mm__c,
    XRayServiceSubcontracting__c: qlid.NC_Outside_Service_Item_Details__r?.XRayServicesCostOfEvent__c > 0,
    XRayServiceSubcontractingCost__c: qlid.NC_Outside_Service_Item_Details__r?.XRayServicesCostOfEvent__c,
    XRayServiceSubcontractingFreightEstimate__c:
      qlid.NC_Outside_Service_Item_Details__r?.XRayServicesTotalInboundShippingCost__c +
      qlid.NC_Outside_Service_Item_Details__r?.XRayServicesTotalOutboundShippingCost__c,
    // T200Item__c:  // TODO: we need a story made for this field
    // T200Quantity__c:  // TODO: we need a story made for this field
    // InHouseUTRequired__c:  // TODO: we need a story made for this field
    // Numberofwelds__c:  // TODO: we need a story made for this field
    // NumberofLoadsbacker__c: // TODO: we need a story made for this field
    // NumberofLoadscladder__c: // TODO: we need a story made for this field
    // PMIinspectionbacker__c:  // TODO: we need a story made for this field
    // PMIinspectioncladder__c:  // TODO: we need a story made for this field
    // ThicknessminimSW1__c: qlid.NC_Seam_Weld_Item_Details__r.ThicknessminimSW1__c, // TODO: we need a story made for this field
    // ThicknessminimSW2__c: qlid.NC_Seam_Weld_Item_Details__r.ThicknessminimSW2__c, // TODO: we need a story made for this field
    // ThicknessminimSW3__c: qlid.NC_Seam_Weld_Item_Details__r.ThicknessminimSW3__c, // TODO: we need a story made for this field
    // ThicknessminmmSW1__c: qlid.NC_Seam_Weld_Item_Details__r.ThicknessminmmSW1__c, // TODO: we need a story made for this field
    // ThicknessminmmSW2__c: qlid.NC_Seam_Weld_Item_Details__r.ThicknessminmmSW2__c, // TODO: we need a story made for this field
    // ThicknessminmmSW3__c: qlid.NC_Seam_Weld_Item_Details__r.ThicknessminmmSW3__c, // TODO: we need a story made for this field
    // CuttingRequiredAnvil__c: qlid.NC_Anvil_Item_Details__r.CuttingRequiredAnvil__c, // TODO: Circle back with Tony for these fields
    // CuttingRequiredFlyer__c: qlid.NC_Flyer_Item_Details__r.CuttingRequiredFlyer__c, // TODO: Circle back with Tony for these fields
    // DeslagPrepStampAnvil__c: qlid.DeslagPrepStampAnvil__c, // TODO: Circle back with Tony for these fields
    // DeslagPrepStampFlyer__c: qlid.DeslagPrepStampFlyer__c, // TODO: Circle back with Tony for these fields
    // Entity__c: qlid.Entity__c, // TODO: Circle back with Tony for these fields
    // TorchCladderStock__c: qlid.TorchCladderStock__c, // TODO: Circle back with Tony for these fields
    // PreheattoCutFlyer__c: qlid.NC_Flyer_Item_Details__r.PreheattoCutFlyer__c,  // TODO: Circle back with Tony for these fields
    // PreheattoTorchBackerStock__c: qlid.PreheattoTorchBackerStock__c, // TODO: Circle back with Tony for these fields
    // PreheattoTorchCladderStock__c: qlid.PreheattoTorchCladderStock__c, // TODO: Circle back with Tony for these fields
    // InspectionReviewRelease__c: qlid.NC_Outside_Service_Item_Details__r?.InspectionReviewReleaseCostOfEvent__c, // TODO: Circle back with Tony for these fields
    // ManualUTInspection__c: qlid.ManualUTInspection__c, // NC_Outside_Service_Item_Details__r?.        // TODO: Circle back with Tony for these fields
  } as unknown as MultiShotItemDetail;

  return multiShot;
};

export const createMultiShotDetailsFromVirtualGroup = (
  virtualGroup: MultiShotVirtualGroup,
  qlis: QuoteLineItem[],
  parentLineItem: QuoteLineItem
  ): MultiShotItemStateDetail[] => {
    const multiShots = [] as MultiShotItemStateDetail[];
    console.log('virtualGroup: ', virtualGroup);
    
  for (const shot of virtualGroup.virtualShotGroups) {
    if (shot.virtualLineUUID === '' && shot.Id){
      multiShots.push({Id: shot.Id, Shot_Number__c: shot.shotNum} as MultiShotItemStateDetail);
      // continue;
    }

    const qli = qlis.find((qli) => qli.UUID__c === shot.virtualLineUUID);
    const qlid = qli?.quoteLineItemDetail;

    if (!qli || !qlid) {
      continue;
    }
    
    const mappedMultiShot: MultiShotItemDetail = createMultiShot(qli, qlid, shot, parentLineItem);

    multiShots.push(mappedMultiShot);
  }

  console.log('created multiShots: ', multiShots);
  return multiShots;
};

export const getVirtualGroupFromMultiShotDetails = (
  detailWithMSs: QuoteLineItemDetail,
  quoteLineItemsWithVirtualLines: QuoteLineItem[]
): MultiShotVirtualGroup => {
  const parentLineUUID = detailWithMSs.itemUUID;
  const virtualGroup = (Array.isArray(detailWithMSs.NC_Multi_Shot_Item_Details__r) ? detailWithMSs.NC_Multi_Shot_Item_Details__r : [detailWithMSs.NC_Multi_Shot_Item_Details__r]).reduce((acc: VirtualGroup[], ms: MultiShotItemDetail) => {
    const { Id = '', Name = '', NC_Quote_Line_Item_Detail__c = '', Shot_Number__c = 2 } = ms ?? {};
    
    const vItemId = quoteLineItemsWithVirtualLines.reduce((acc, qli) => {
      const qlid = qli.quoteLineItemDetail;
      if (qlid?.Id === NC_Quote_Line_Item_Detail__c || qlid?.associatedLineItem === NC_Quote_Line_Item_Detail__c) {
        return qli.UUID__c;
      }
      if (qlid.UUID__c === NC_Quote_Line_Item_Detail__c) {
        return qli.UUID__c;
      }
      return acc;
    }, '');

    if (vItemId) {
      const _virtualGroup: VirtualGroup = {
        Id: Id,
        name: Name,
        shotNum: Shot_Number__c,
        virtualLineUUID: vItemId,
      };
      acc.push(_virtualGroup);
    }

    return acc;
  }, []);
  
  if (!virtualGroup.length) {
    return null;
  }

  const group = {
    parentLineUUID,
    virtualShotGroups: virtualGroup,
  };
  // TODO: kf fix this, use group, build the virtual group from the MS details
  // if it has a virtual group, use that, otherwise use the new group
  return detailWithMSs.virtualDetailGroup ?? group;
};
