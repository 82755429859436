import { Dispatch, SetStateAction, useEffect, useState } from 'react';

// useMapSetState is a hook that behaves like useState with added functionallity to set the field in the Map provided.
// It returns a tuple of the value and a setter function, like useState.
// The state value is initialized from value in the map.
// When the setter function is called, the field is updated in the Map provided.
export function useMapSetState<T, S extends string | number | boolean = string>(
  map: Map<T, any>,
  key: T,
  field: string,
  defaultVal: any = ''
): readonly [S, Dispatch<SetStateAction<S>>] {
  const [val, setVal] = useState<S>((map.get(key) || {})[field] ?? defaultVal); // find a better default value

  useEffect(() => {
    (map.get(key) || {})[field] = val;
  }, [val]);

  return [val, setVal];
}

// Usage example:
// const [additionalNotes, setAdditionalNotes] = useMapSetState(assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItemDetail, 'testingBackerAdditionalNotes' );
