import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface Props {
  children: ReactNode;
  defaultValue?: string | string[];
  isMultiple?: boolean;
  items: string[];
}

interface IContextProps {
  value: string[];
  multiple?: boolean;
  isAllExpanded: boolean;
  toggleValue: (value: string) => void;
  setMultiple: Dispatch<SetStateAction<boolean>>;
  expandAll: () => void;
  removeAll: () => void;
}

// Context
const AppAccordionContext = createContext({} as IContextProps);

// Provider
export const AppAccordionGroupProvider = ({ children, defaultValue, isMultiple, items }: Props): JSX.Element => {
  const def = defaultValue || [];
  const [
    value,
    setValue,
  ] = useState(
    Array.isArray(def)
      ? def
      : [def]
    );

  const [
    multiple,
    setMultiple,
  ] = useState(typeof isMultiple === 'boolean' ? isMultiple : true);

  // context value object
  const contextValue: IContextProps = {
    value,
    multiple,
    // for now just check the length
    isAllExpanded: value.length === items.length,
    toggleValue: (v) => {
      const isExist = value.includes(v);
      if (isExist) {
        return setValue(value.filter(a => a !== v))
      }

      if (multiple) {
        return setValue([...value, v]);
      }

      setValue([v]);
    },
    expandAll: () => {
      setValue(items);
    },
    removeAll: () => {
      setValue([]);
    },
    setMultiple,
  };

  return (
    <AppAccordionContext.Provider value={contextValue}>{children}</AppAccordionContext.Provider>
  );
};

// useAppAccordionGroup hook
export const useAppAccordionGroup = (): IContextProps => {
  const context: IContextProps = useContext(AppAccordionContext);
  if (context === null || context === undefined) {
    throw new Error(
      'You probably forgot the <AppAccordionGroupProvider> context provider'
    );
  }
  return context;
};