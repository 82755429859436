import { IonInput } from '@ionic/react';
import * as React from 'react';
import { useState, useCallback } from 'react';
import './auto-complete.scss';

const AutoComplete = (props) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(undefined);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState(props.input);
  const [suggestionClicked, setSuggestionClicked] = useState(false);
  const autoCompleteRef = React.useRef(null);
  
  React.useEffect(() => {
    window.addEventListener('click', _documentClickHandler);

    return () => window.removeEventListener('click', _documentClickHandler);
  }, []);

  const _documentClickHandler = useCallback((event) => {
    const inputRef = document.getElementById('SearchInput');

    if (
      (inputRef && !inputRef.contains(event.target))
    ) {
      setShowSuggestions(false);
    }
  }, []);

  const transformSuggestion = (suggestion: any, userInput: any) => {
    const matchingFields = props.suggestionDisplayFields.filter(displayField => suggestion[displayField]?.toLowerCase().indexOf(userInput?.toLowerCase()) > -1);

    const matchingSuggestions = [];
    matchingFields.forEach((field) => {
      matchingSuggestions.push({ ...suggestion, matchingDisplayField: field });
    });

    return matchingSuggestions;
  };

  const onChange = (e) => {
    const userInput = e.target.value;

    // Filter our suggestions that don't contain the user's input
    const unLinked = [];
    props.suggestions.forEach(suggestion => {
      unLinked.push(...transformSuggestion(suggestion, userInput));

      props.commonSuggestionDisplayFields?.forEach((displayField) => {
        const suggestionToAdd: any = { [displayField]: suggestion[displayField], matchingDisplayField: displayField };

        if(suggestion[displayField]?.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        && !unLinked.some(suggestionToCheck => suggestionToCheck[displayField] === suggestion[displayField] && suggestionToCheck.matchingDisplayField === displayField)) {
          unLinked.push(suggestionToAdd);
        }
      });
    });

    setInput(e.target.value);

    setFilteredSuggestions(unLinked);

    if(!suggestionClicked) {
      setShowSuggestions(true);
    } else {
      setSuggestionClicked(false);
      autoCompleteRef.current.setFocus();
    }

    if(props.searchInputChangedHandler) {
      props.searchInputChangedHandler(e.target.value);
    }
  };

  const onClick = (suggestion) => {
    setSuggestionClicked(true);
    setFilteredSuggestions([]);
    setInput(suggestion[suggestion.matchingDisplayField]);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    props.suggestionClickHandler(suggestion);
  };

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className;
          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = 'suggestion-active';
          }

          return (
            <li className={className} key={`autocomplete-${suggestion[suggestion.matchingDisplayField]}-${index}`} onClick={() => onClick(suggestion)} data-testid={'QAAutocompleteSuggestion_' + suggestion[suggestion.matchingDisplayField]}>
              {suggestion[suggestion.matchingDisplayField]}
            </li>
          );
        })}
      </ul>
    ) : (
      <ul className="suggestions">
        <li className="none-found">{props.noneFoundText}</li>
      </ul>
    );
  };

  return (
    <>
      <IonInput ref={autoCompleteRef} id='SearchInput' autofocus={props.autoFocus} class='search' placeholder={props.placeholder} onClick={(e) => onChange(e)} onIonChange={(e) => onChange(e)} onKeyPress={props.onKeyPress} value={input} disabled={props.isDisabled} data-testid="QAAutocompleteSearchInput"></IonInput>
      {showSuggestions && input && <SuggestionsListComponent />}
    </>
  );
};

export default AutoComplete;