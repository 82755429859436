import { useDarkMode } from '@hooks/useDarkMode';
import classNames from 'classnames';
import React from 'react';
import './floating-action.scss';

const FloatingAction = (props) => {
  const { darkMode } = useDarkMode();
  const [hovered, setHovered] = React.useState(false);

  const actionIcon = () => {
    if(props.disabled) {
      return props.disabledIcon;
    } else if(hovered) {
      return props.hoveredIcon;
    } else if(darkMode) {
      return props.darkModeIcon;
    }

    return props.icon;
  };

  return (
    <img className={classNames({'floating-action': true, 'disabled': props.disabled})}
      id={props.elId}
      src={actionIcon()}
      onClick={!props.disabled ? props.onClick : undefined}
      alt={props.action}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      data-testid={'QAFloatingAction_' + props.action}
    />
  );
};

export default FloatingAction;