import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { IonButton, IonCheckbox, IonCol, IonGrid, IonItem, IonLabel, IonPopover, IonRow, IonToggle, useIonAlert } from '@ionic/react';
import './advanced-specifications.scss';
import {
  AdvancedSpecHeaderTabs,
  CladProductionSpecifications,
  FerroxylTestOfCladdingSurfacePerformed,
  HardnessMetalSurfaces,
  MeasurementSystems,
  MetalPurchaseRequirementsGroups,
  MetalComboScrollerStyles,
  PostCladBaseMetalUTSpecifications as BaseMetalUTSpecifications,
  QuoteLineItemValueTypes,
  RadioButtonOptions,
  RawMaterialUsage,
  SelectionTypes,
  TensileTestLocations,
  TestingUOMs,
  QuoteVariableConfigDescriptions,
  FieldTypes,
  Regexes,
  ToastTypes,
  ToastMessages,
  PageRoutes,
  MetalSpecDefault,
  ManufacturingSites,
  QuoteActions,
  TextAreaMax,
} from '@constants';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import SelectionContainer from '@components/selection-container/selection-container';
import { getDefaultMetalPurchaseRequirement, getDistinctMetalPurchasingRequirements, getQuoteVariableConfiguration, getQuoteVariableConfigurationUOM, getSelectionOptions, getSelectionOptionsDescriptions, getSelections } from '@shared/quote-utils';
import classNames from 'classnames';
import { RawMaterial } from '@interfaces/raw-material';
import LeakTestPopover from '@components/popover/leak-test-popover/leak-test-popover';
import CustomerSpecifiedImpactTestingPopover from '@components/popover/customer-specified-impact-testing-popover/customer-specified-impact-testing-popover';
import PrebondImpactsPopover from '@components/popover/pre-bond-impacts-popover/pre-bond-impacts-popover';
import { buildMetalComboSelections, DisplayedQuoteGroup, saveQuoteLineItemsAdvSpecs } from '@shared/quote-group-utils';
import { MetalComboScroller } from '@components/metal-combo-scroller/metal-combo-scroller';
import HeaderTab from '@components/header-tab/header-tab';
import MappedSection from '@components/mapped-section/mapped-section';
import CustomerSpecifiedSPWHTPopover from '@components/popover/customer-specified-spwht-popover/customer-specified-spwht-popover';
import { useToast } from '@hooks/useToast';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { AdvSpecActionTypes, useAdvSpecState, useAdvSpecStateDispatch } from '@context/adv-spec-context';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '@components/loading/loading';
import PageLayout from '@components/page/page-layout';
import FloatingAction from '@components/floating-action/floating-action';
import quoteTab from '@assets/icon/Tab_Quote.svg';
import quoteTabDarkMode from '@assets/icon/Tab_Quote_DarkMode.svg';
import quoteTabHovered from '@assets/icon/Tab_Quote_Hovered.svg';
import orderTab from '@assets/icon/Tab_Order.svg';
import orderTabDarkMode from '@assets/icon/Tab_Order_DarkMode.svg';
import orderTabHovered from '@assets/icon/Tab_Order_Hovered.svg';
import MSTOTab from '@assets/icon/MSTOAction.svg';
import MSTOTabDarkMode from '@assets/icon/MSTOAction_DarkMode.svg';
import MSTOTabHovered from '@assets/icon/MSTOAction_Hovered.svg';
import AssGroups from '@assets/icon/AssGroupsAction.svg';
import AssGroupsDarkMode from '@assets/icon/AssGroupsAction_DarkMode.svg';
import AssGroupsHovered from '@assets/icon/AssGroupsAction_Hovered.svg';
import { navigateToRoute } from '@shared/utils';
import BreadcrumbNavigation from '@components/breadcrumb-navigation/breadcrumb-navigation';
import { useQuoteGroup } from '@hooks/useQuoteGroup';
import { AdvSpecMetalComboSelections, AdvSpecsAssemblyGroupDetailFieldsToUpdate, AdvSpecsAssemblyGroupLineFieldsToUpdate } from '@shared/types';
import UomDisplayToggle from '@components/uom-toggle/uom-toggle';

const AdvSpecsPage = () => {
  const {
    dataState,
    loadRawMaterials,
    loadFinishedGoods,
    loadFreight,
    loadContent,
    loadEdgeAllowances,
    loadSpecificationTestOptions,
    loadBoosterAllowances,
    loadSelections,
    loadQuoteVariableConfigurations,
    loadVendors,
    loadWeldingPatterns
  } = useDataState();

  const {
    rawMaterials,
    specificationTestOptions,
    selections,
    quoteVariableConfigurations,
  } = dataState;

  const {
    quoteState,
    loadQuote,
    loadQuoteLineItemDetails,
    saveQuoteLineItem,
    saveQuote
   } = useQuoteState();
  
  const { quote, quoteLineItemDetails } = quoteState;
  const { quoteLineItems, Manufacturing_Site__c: manufacturingSite } = quote || {};
  const { id } = useParams<{id: string}>();
  const [confirmAlert] = useIonAlert();
  const history = useHistory();
  
  // advanced specifications state
  const { dispatchAdvSpecState } = useAdvSpecStateDispatch();
  const { advSpecState } = useAdvSpecState();
  const {
    assemblyGroupCylinderDetailFieldsToUpdate,
    assemblyGroupDetailFieldsToUpdate,
    assemblyGroupHeadFieldsToUpdate,
    assemblyGroupLineFieldsToUpdate,
    assemblyGroupOptionsRequired,
    assemblyGroupOutsideServiceItemFieldsToUpdate,
    specifyPerAssemblyMap,
    metalComboDetailFieldsToUpdate,
    metalComboOptionsRequired,
    metalComboLineFieldsToUpdate,
  } = advSpecState;

  const cladTestingRef = React.useRef<HTMLDivElement>(null);
  const cladMetalRef = React.useRef<HTMLDivElement>(null);
  const preweldingRef = React.useRef<HTMLDivElement>(null);
  const baseMetalRef = React.useRef<HTMLDivElement>(null);
  const exceptionsClarificationsRef = React.useRef<HTMLDivElement>(null);

  const toast = useToast(4000);

  const [isLoading, setIsLoading] = React.useState(true);
  const [areLinesLoading, setAreLinesLoading] = React.useState(undefined);
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [baseMetal, setBaseMetal] = React.useState<RawMaterial>(undefined);
  const [cladMetal, setCladMetal] = React.useState<RawMaterial>(undefined);
  const [lineItemId, setLineItemId] = React.useState<string>(advSpecState.selectedLineItemId || '');
  const [isQuoteSaving, setIsQuoteSaving] = React.useState(false);

  const [exceptionsAndClarificationsMaxSurpassed, setExceptionsAndClarificationsMaxSurpassed] = React.useState(false);
  const [addReqsCladProductTestingMaxSurpassed, setAddReqsCladProductTestingMaxSurpassed] = React.useState(false);
  const [addReqsCladdingMetalMaxSurpassed, setAddReqsCladdingMetalMaxSurpassed] = React.useState(false);
  const [addReqsPreweldingMaxSurpassed, setAddReqsPreweldingMaxSurpassed] = React.useState(false);
  const [addReqsBaseMetalMaxSurpassed, setAddReqsBaseMetalMaxSurpassed] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const displayedQuoteGroup: DisplayedQuoteGroup = useQuoteGroup(quoteLineItems, quoteLineItemDetails, lineItemId);

  // load the quote if it doesnt already exist in state, and load the cosmos data
  React.useEffect(() => {
    if(!quote) {
      loadQuote(
        id,
        undefined,
        true,
        undefined,
        setAreLinesLoading
      );
    }
    loadRawMaterials();
    loadFinishedGoods();
    loadFreight();
    loadContent();
    loadEdgeAllowances();
    loadSpecificationTestOptions();
    loadBoosterAllowances();
    loadSelections();
    loadQuoteVariableConfigurations();
    loadVendors();
    loadWeldingPatterns();
  }, []);

  React.useEffect(() => {
    if(rawMaterials &&
    specificationTestOptions &&
    selections &&
    quoteVariableConfigurations
    ) {
      setIsDataLoading(false);
    }
  }, [dataState]);

  React.useEffect(() => {
    if(quoteLineItems && areLinesLoading) {
      try {
        loadQuoteLineItemDetails(setIsQuoteSaving);
        setAreLinesLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  }, [quoteLineItems]);

  React.useEffect(() => {
    if(quoteLineItemDetails && !isQuoteSaving) {
      dispatchAdvSpecState({ type: AdvSpecActionTypes.setHasInitialized, payload: false });
      setIsLoading(false);
    }
  }, [quoteLineItemDetails, isQuoteSaving]);

  // load the AdvSpecState if it hasnt been initialized yet, via a displayedQuoteGroup
  React.useEffect(() => {
    if (displayedQuoteGroup && !advSpecState.hasInitialized) {
      // load the AdvSpecState via a DisplayedQuoteGroup, because the build maps are currently implmented using them, (quoteGroup should be tombstoned 🪦 in the future)
      dispatchAdvSpecState({ type: AdvSpecActionTypes.initStateByQuoteGroup, payload: displayedQuoteGroup });
    }
  }, [displayedQuoteGroup, advSpecState.hasInitialized]);

  // set the metals when quoteLineItemDisplayed changes
  React.useEffect(() => {
    try {
      if(displayedQuoteGroup?.qliDisplayed) {
        setBaseMetal(
          rawMaterials.find(
            (rawMaterial: RawMaterial) =>
              rawMaterial.name === displayedQuoteGroup.qliDisplayed?.BaseMetal__c &&
              rawMaterial.usage === RawMaterialUsage.Base &&
              rawMaterial.dataAreaId.includes(manufacturingSite)
          )
        );
        setCladMetal(
          rawMaterials.find(
            (rawMaterial: RawMaterial) =>
              rawMaterial.name === displayedQuoteGroup.qliDisplayed?.CladMetal__c &&
              rawMaterial.usage === RawMaterialUsage.Clad &&
              rawMaterial.dataAreaId.includes(manufacturingSite)
          )
        );
      }
    } catch (error) {
        console.log(error);
        return;
    }
  }, [manufacturingSite, displayedQuoteGroup?.qliDisplayed, rawMaterials]);

  const metalComboSelections = useMemo<{ [key: string]: AdvSpecMetalComboSelections }>(() => {
    if (displayedQuoteGroup && manufacturingSite && metalComboDetailFieldsToUpdate && specificationTestOptions) {
      return buildMetalComboSelections(
        displayedQuoteGroup,
        metalComboDetailFieldsToUpdate,
        manufacturingSite,
        specificationTestOptions
      );
    }
  }, [displayedQuoteGroup, manufacturingSite, metalComboDetailFieldsToUpdate, specificationTestOptions]);

  const [specifyPerAssemblyToggled, setSpecifyPerAssemblyToggled] = React.useState(undefined);
  useEffect(() => {
    if (specifyPerAssemblyMap && displayedQuoteGroup?.metalComboKey){
      setSpecifyPerAssemblyToggled(Object.values(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey] ?? {}).some(fieldSpecifiedPerAssembly => { return fieldSpecifiedPerAssembly; }));
    }
  }, [displayedQuoteGroup?.metalComboKey, specifyPerAssemblyMap]);

  const [postCladBondUTSpecificationSelections, setPostCladBondUTSpecificationSelections] = React.useState<string[]>(undefined);
  useEffect(() => {
    if (displayedQuoteGroup?.metalComboKey && metalComboSelections){
      setPostCladBondUTSpecificationSelections((metalComboSelections[displayedQuoteGroup.metalComboKey].postCladBondUTSpecification));
    }
  }, [displayedQuoteGroup?.metalComboKey, metalComboSelections]);

  const [postCladBaseMetalUTSpecification, setPostCladBaseMetalUTSpecification] = React.useState<string>(undefined);

  // when the metal combo key for the displayed quote line item group changes, check and set the specify per assembly toggled
  useEffect(() => {
    try {
      if (specifyPerAssemblyMap && displayedQuoteGroup) {
        setSpecifyPerAssemblyToggled(Object.values(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey] ?? {}).some(fieldSpecifiedPerAssembly => { return fieldSpecifiedPerAssembly; }));
      }
    } catch(err) {
      console.log(err);
      return;
    }
  }, [displayedQuoteGroup, specifyPerAssemblyMap]);

  useEffect(() => {
    try {
      if (metalComboSelections && displayedQuoteGroup && metalComboDetailFieldsToUpdate) {
        setPostCladBondUTSpecificationSelections(metalComboSelections[displayedQuoteGroup.metalComboKey].postCladBondUTSpecification);
      }
    } catch(err) {
      console.log(err);
    }
  }, [displayedQuoteGroup, metalComboDetailFieldsToUpdate, metalComboSelections]);

  useEffect(() => {
    if(exceptionsAndClarificationsMaxSurpassed || addReqsCladProductTestingMaxSurpassed || addReqsCladdingMetalMaxSurpassed || addReqsPreweldingMaxSurpassed || addReqsBaseMetalMaxSurpassed) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [exceptionsAndClarificationsMaxSurpassed, addReqsCladProductTestingMaxSurpassed, addReqsCladdingMetalMaxSurpassed, addReqsPreweldingMaxSurpassed, addReqsBaseMetalMaxSurpassed]);

  // Returns true once all the necessary data is retrieved and the quote and advanced specs state has finished loading
  const isLoadingComplete = () => {
    return !isDataLoading && !isLoading && !!displayedQuoteGroup && advSpecState.hasInitialized;
  };

  // Returns the proper options required map to use based on whether a quote line item detail was provided
  const optionsRequired = (assemblyGroupOptionsRequired: Map<string, {[key: string]: boolean}>, quoteDetailForRow?: QuoteLineItemDetail) => {
    if (!quoteDetailForRow) {
      if (!metalComboOptionsRequired || !metalComboOptionsRequired[displayedQuoteGroup.metalComboKey]) {
        console.log(`options required for the metal combo: ${displayedQuoteGroup.metalComboKey} could not be found.`);
        return;
      }

      return metalComboOptionsRequired[displayedQuoteGroup.metalComboKey];
    } else {
      const quoteDetailForRowId = quoteDetailForRow?.Id || quoteDetailForRow?.associatedLineItem;
      if (!assemblyGroupOptionsRequired || !assemblyGroupOptionsRequired.get(quoteDetailForRowId)) {
        console.log(`assemblyGroupOptionsRequired for quoteDetailForRow: ${quoteDetailForRow} could not be found.`);
        return;
      }

      return assemblyGroupOptionsRequired.get(quoteDetailForRowId);
    }
  };

  // Returns the proper line fields to update map to use based on whether a quote line item id was provided
  const lineFieldsToUpdate = (assemblyGroupLineFieldsToUpdate: {[key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate}, metalCombo: string, quoteLineItemId?: string) => {
    if (!quoteLineItemId) {
      if (!metalComboLineFieldsToUpdate || !metalComboLineFieldsToUpdate[metalCombo]) {
        console.log(`line fields for the metal combo: ${metalCombo} could not be found.`);
        return;
      }

      return metalComboLineFieldsToUpdate[metalCombo];
    } else {
      if (!assemblyGroupLineFieldsToUpdate || !assemblyGroupLineFieldsToUpdate[quoteLineItemId]) {
        console.log(`assemblyGroupLineFieldsToUpdate for quoteLineItemId: ${quoteLineItemId} could not be found.`);
        return;
      }

      return assemblyGroupLineFieldsToUpdate[quoteLineItemId];
    }
  };

  // Returns the proper detail fields to update map to use based on whether a quote line item detail was provided
  const detailFieldsToUpdate = (assemblyGroupDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>, metalCombo: string, quoteDetailForRow?: QuoteLineItemDetail) => {
    if (!quoteDetailForRow) {
      if (!metalComboDetailFieldsToUpdate || !metalComboDetailFieldsToUpdate[metalCombo]) {
        console.log(`fields to update for the metal combo: ${metalCombo} could not be found.`);
        return;
      }

      return metalComboDetailFieldsToUpdate[metalCombo];
    } else {
      const quoteDetailForRowId = quoteDetailForRow?.Id || quoteDetailForRow?.associatedLineItem;
      if (!assemblyGroupDetailFieldsToUpdate || !assemblyGroupDetailFieldsToUpdate.get(quoteDetailForRowId)) {
        console.log(`assemblyGroupDetailFieldsToUpdate for quoteDetailForRow: ${quoteDetailForRow} could not be found.`);
        return;
      }

      return assemblyGroupDetailFieldsToUpdate.get(quoteDetailForRowId);
    }
  };

  const saveAdvSpecs = (saveQuoteToSf = false) => {
    try {
      saveQuoteLineItemsAdvSpecs(
        displayedQuoteGroup,
        assemblyGroupLineFieldsToUpdate,
        assemblyGroupDetailFieldsToUpdate,
        assemblyGroupHeadFieldsToUpdate,
        assemblyGroupOutsideServiceItemFieldsToUpdate,
        assemblyGroupCylinderDetailFieldsToUpdate,
        metalComboDetailFieldsToUpdate,
        metalComboOptionsRequired,
        assemblyGroupOptionsRequired,
        quoteLineItems,
        saveQuoteLineItem,
        specifyPerAssemblyMap,
        metalComboLineFieldsToUpdate,
        baseMetal,
        cladMetal
      );
    } catch (error) {
      console.log(error);
    }

    if(saveQuoteToSf) {
      setIsQuoteSaving(true);
      saveQuote(id, QuoteActions.Save, undefined, setIsLoading, undefined, undefined, setAreLinesLoading, undefined, setIsQuoteSaving);
    }
  };

  const UpdateButton = () => {
    return (
      <>
        <IonButton
          color='primary'
          class='update-button'
          data-testid={'QAAdvSpecsSaveButton'}
          onClick={() => {
            if (hasError) {
              toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
            } else {
              saveAdvSpecs(true);
            }
          }}
        >
          Save
        </IonButton>
      </>
    );
  };

  const SpecifyPerAssemblyCheckbox = (props) => {
    React.useEffect(() => {
      if(!specifyPerAssemblyToggled) {
        props.setFieldSpecifiedPerAssembly(false);
      }
    }, [specifyPerAssemblyToggled]);

    return (specifyPerAssemblyToggled && !props.hideSpecifyPerAssembly ?
      <CheckboxItem
        label='Specify Per Assembly'
        style={{
          width: 'fit-content',
          marginTop: props.style?.marginTop || 'auto',
          marginBottom: props.style?.marginBottom || 'auto',
          ['--inner-padding-end']: '15px',
          ['--padding-start']: '9px',
          ['--background']: 'transparent'
        }}
        checkboxStyle={{ marginLeft: '0px' }}
        onIonChange={checked => {
          props.setFieldSpecifiedPerAssembly(checked);
        }}
        checked={props.fieldSpecifiedPerAssembly}
      /> : null
    );
  };

  const CheckboxItem = props => {
    return (
      <IonItem class='checkbox-item' lines='none' style={props.style}>
        <IonCheckbox
          class='checkbox'
          style={props.checkboxStyle}
          slot='start'
          color='primary'
          checked={props.checked}
          onIonChange={e => props.onIonChange(e.detail.checked)}
          data-testid={'QAAdvancedSpecificationsPopoverCheckbox_' + props.label}
        />
        <IonLabel class='checkbox-label'>{props.label}</IonLabel>
      </IonItem>
    );
  };

  const SpecifyButton = (props: {
    isDisabled?: boolean,
    onClick?: React.MouseEventHandler<HTMLIonButtonElement>
  }) => (
    <>
      <IonButton class='specify-button' disabled={props.isDisabled} onClick={props.onClick} data-testid='QAAdvancedSpecificationsPopoverSpecifyButton'>
        Specify
      </IonButton>
    </>
  );

  const addRowToSectionsMap = (rowSpecifiedPerAssembly: boolean, sectionsMap: { [key: string]: JSX.Element[] }, sectionHeader: AdvancedSpecHeaderTabs, rowComponent: any) => {
    if(rowSpecifiedPerAssembly) {
      sectionsMap[sectionHeader] = [...(sectionsMap[sectionHeader] || []), rowComponent];
    }
  };

  const buildAssemblyGroupSectionsMap = () => {
    const sectionsMap = {};

    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].postCladBondUTSpecification, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, PostCladBondUTSpecificationRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].postCladBaseMetalUTSpecification, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, PostCladBaseMetalUTSpecificationRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].certificationTypeRequired, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, CertificationTypeRequiredRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].claddingForCorrosionAllowanceOnly, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, CladdingForCorrosionAllowanceOnlyRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].isWeldRepairAllowed, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, IsWeldRepairAllowedRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerSpecifiedSPWHTTestCoupons, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, CustomerSpecifiedSPWHTOfTestCouponsRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerSpecifiedImpactTesting, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, CustomerSpecifiedImpactTestingRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].shearTest, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, ShearTestRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].tensileTest, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, TensileTestRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].ramTensileTest, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, RamTensileTestRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hardnessCladdingMetalSurface, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, HardnessCladdingMetalSurfaceRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hardnessBaseMetalSurface, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, HardnessBaseMetalSurfaceRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIConfirmCladdingMetal, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, PMIConfirmCladdingMetalRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIConfirmBaseMetal, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, PMIConfirmBaseMetalRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].sa26345BondStrength, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, BondStrengthBendPerSA26345Row);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].b898Bend, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, BendB898Row);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].machineSideBends, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, SideBendRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].adw8Bend, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, ADW8BendRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].leakTest, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, LeakTestRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsCladProductTesting, sectionsMap, AdvancedSpecHeaderTabs.CladTesting, AdditionalRequirementsCladProductTestingRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCCladHeatTreatmentasSupplied, sectionsMap, AdvancedSpecHeaderTabs.CladMetal, NCCladHeatTreatmentAsSuppliedRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCCladChemistryRestrictionsforBonding, sectionsMap, AdvancedSpecHeaderTabs.CladMetal, NCCladChemistryRestrictionsForBondingRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].dyePenetrantNotes, sectionsMap, AdvancedSpecHeaderTabs.CladMetal, DyePenetrantRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerCladChemistryRestrictionsNotes, sectionsMap, AdvancedSpecHeaderTabs.CladMetal, CustomerCladChemistryRestrictionsRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].corrosionTesting, sectionsMap, AdvancedSpecHeaderTabs.CladMetal, CorrosionTestingRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].cladMetalSpecEdAndAd, sectionsMap, AdvancedSpecHeaderTabs.CladMetal, CladMetalSpecEdAndAdRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerCladMetalSupplierRestrictions, sectionsMap, AdvancedSpecHeaderTabs.CladMetal, CladCustomerMetalSupplierRestrictionsRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].cladMetalProductAnalysisRequired, sectionsMap, AdvancedSpecHeaderTabs.CladMetal, CladMetalProductAnalysisRequiredSection);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].ferroxylTestPerformed, sectionsMap, AdvancedSpecHeaderTabs.CladMetal, FerroxylTestOfCladdingSurfacePerformedRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsCladdingMetal, sectionsMap, AdvancedSpecHeaderTabs.CladMetal, AdditionalRequirementsCladdingMetalRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].radiography, sectionsMap, AdvancedSpecHeaderTabs.Prewelding, RadiographyRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIofWeldWire, sectionsMap, AdvancedSpecHeaderTabs.Prewelding, PMIOfWeldWireRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIPreweldsRequired, sectionsMap, AdvancedSpecHeaderTabs.Prewelding, PMIPreweldsRequiredRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].ferriteNumberRequired, sectionsMap, AdvancedSpecHeaderTabs.Prewelding, FerriteNumberRequiredRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsPrewelding, sectionsMap, AdvancedSpecHeaderTabs.Prewelding, AdditionalRequirementsPreweldingRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCBaseHeatTreatmentasSupplied, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, NCBaseHeatTreatmentAsSuppliedRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].finishedGoodHeatTreatment, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, FinishedGoodHeatTreatmentRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCBaseChemistryRestrictionsforBonding, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, NCBaseChemistryRestrictionsForBondingRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].precladBaseMetalUT, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, PrecladBaseMetalUTSection);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].prebondImpacts, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, PrebondImpactsRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].carbonPercent, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, CarbonPercentRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].sulphur, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, SulphurPercentRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].phosphorusPercent, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, PhosphorousPercentRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].carbonEquivalencyPercent, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, CarbonEquivalencyPercentRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].jFactorSA387S62, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, JFactorSA387S62Row);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].xFactorSA387S62, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, XFactorSA387S62Row);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerBaseChemistryRestrictions, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, BaseCustomerChemistryRestrictionsRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].vacuumDegassed, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, VacuumDegassedRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].calciumTreatedSulfideShapeControl, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, CalciumTreatedSulfideShapeControlRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].throughThicknessTensileSA770YN, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, ThroughThicknessTensileSA770YNRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].baseTensileTestLocation, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, BaseTensileTestRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hotTensileTestPerSA20S7, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, HotTensileTestPerSA20S7Row);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hicTestNotes, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, HICTestingRequiredRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].austeniticGrainSizeFinerThanValue, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, AusteniticGrainSizeFinerThanRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].baseMetalSpecEdAndAd, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, BaseMetalSpecEdAndAdRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerBaseMetalSupplierRestrictions, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, BaseCustomerMetalSupplierRestrictionsRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].baseMetalProductAnalysisRequired, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, BaseMetalProductAnalysisRequiredSection);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsBaseMetal, sectionsMap, AdvancedSpecHeaderTabs.BaseMetal, AdditionalRequirementsBaseMetalRow);
    addRowToSectionsMap(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].exceptionsAndClarifications, sectionsMap, AdvancedSpecHeaderTabs.ExceptionsClarifications, ExceptionsAndClarificationsRow);

    return sectionsMap;
  };

  const sectionRefMap: Map<AdvancedSpecHeaderTabs, React.MutableRefObject<HTMLDivElement>> = new Map([
    [AdvancedSpecHeaderTabs.CladTesting, cladTestingRef],
    [AdvancedSpecHeaderTabs.CladMetal, cladMetalRef],
    [AdvancedSpecHeaderTabs.Prewelding, preweldingRef],
    [AdvancedSpecHeaderTabs.BaseMetal, baseMetalRef],
    [AdvancedSpecHeaderTabs.ExceptionsClarifications, exceptionsClarificationsRef],
  ]);

  const PostCladBondUTSpecificationRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].postCladBondUTSpecification
    );

    const [postCladBondUTSpecificationSelection, setPostCladBondUTSpecificationSelection] = React.useState<string>(detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).postCladBondUTSpecification);

    React.useEffect(() => {
      if (postCladBondUTSpecificationSelections){
        if(!postCladBondUTSpecificationSelections.includes(postCladBondUTSpecificationSelection)) {
          if (postCladBondUTSpecificationSelections.length === 0) {
            // if the postCladBondUTSpecificationSelections list is empty, undefined is passed to the postCladSelection setter and the state of postCladBondUTSpecificationSelection is set by detailFieldsToUpdate.postCladBondUTSpecification
            console.log('the list of postCladBondUTSpecificationSelections is empty, postCladBondUTSpecificationSelection is being read by detailFieldsToUpdate');
          }
          setPostCladBondUTSpecificationSelection(postCladBondUTSpecificationSelections[0]);
        }
      }
    }, [postCladBondUTSpecificationSelections]);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].postCladBondUTSpecification = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            Post Clad Bond UT Specification
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={postCladBondUTSpecificationSelections}
            style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
            onChange={postCladBondUTSpecification =>
              (setPostCladBondUTSpecificationSelection(detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).postCladBondUTSpecification = postCladBondUTSpecification.label))
            }
            defaultValue={postCladBondUTSpecificationSelection}
            value={postCladBondUTSpecificationSelection}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Additional Notes...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={postCladBondUTSpecificationNotes =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).postCladBondUTSpecificationNotes = postCladBondUTSpecificationNotes)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).postCladBondUTSpecificationNotes}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const PostCladBaseMetalUTSpecificationRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].postCladBaseMetalUTSpecification
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].postCladBaseMetalUTSpecification = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            Post Clad Base Metal UT Specification
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={[
              ...(baseMetal?.baseMetalUTOptions[manufacturingSite]?.map(
                baseMetalUTOption => baseMetalUTOption.option
              ) || []),
              BaseMetalUTSpecifications.Other,
            ]}
            style={{ width: '200px', marginRight: '10px', marginTop: '0px' }}
            onChange={postCladBaseMetalUTSpecification => {
              detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).postCladBaseMetalUTSpecification = postCladBaseMetalUTSpecification.label;

              if(!props.quoteDetailForRow) {
                setPostCladBaseMetalUTSpecification(postCladBaseMetalUTSpecification.label);
              }
            }}
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).postCladBaseMetalUTSpecification}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Additional Notes...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={postCladBaseMetalUTSpecificationNotes =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).postCladBaseMetalUTSpecificationNotes = postCladBaseMetalUTSpecificationNotes)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).postCladBaseMetalUTSpecificationNotes}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CertificationTypeRequiredRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].certificationTypeRequired);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].certificationTypeRequired = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            Certification Type Required
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CertificationTypeRequired).options}
            style={{ width: '374px', marginRight: '10px', marginTop: '0px' }}
            onChange={certificationTypeRequired =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).certificationTypeRequired = certificationTypeRequired.label)
            }
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).certificationTypeRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CladdingForCorrosionAllowanceOnlyRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].claddingForCorrosionAllowanceOnly);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].claddingForCorrosionAllowanceOnly = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            Cladding for Corrosion Allowance Only
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.RadioButtons}
            itemStyle={{ ['--background']: 'transparent' }}
            options={[RadioButtonOptions.Yes, RadioButtonOptions.No]}
            onIonChange={claddingForCorrosionAllowanceOnly =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).claddingForCorrosionAllowanceOnly = claddingForCorrosionAllowanceOnly)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).claddingForCorrosionAllowanceOnly}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const IsWeldRepairAllowedRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].isWeldRepairAllowed);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].isWeldRepairAllowed = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            Is Weld Repair Allowed
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.RadioButtons}
            itemStyle={{ ['--background']: 'transparent' }}
            options={[RadioButtonOptions.Yes, RadioButtonOptions.No, 'Only with Specific Approval']}
            onIonChange={isWeldRepairAllowed => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).isWeldRepairAllowed = isWeldRepairAllowed)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).isWeldRepairAllowed}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CustomerSpecifiedSPWHTOfTestCouponsRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [customerSpecifiedSPWHTOfTestCouponsRequired, setCustomerSpecifiedSPWHTOfTestCouponsRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).customerSpecifiedSPWHTOfTestCouponsRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerSpecifiedSPWHTTestCoupons);
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerSpecifiedSPWHTTestCoupons = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <>
        <IonPopover
          cssClass='customer-specified-spwht-popover'
          isOpen={popoverState.showPopover}
          animated={ false }
          onDidDismiss={async () => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
        >
          <CustomerSpecifiedSPWHTPopover
            setShowPopover={setShowPopover}
            detailFieldsToUpdate={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow)}
          />
        </IonPopover>
        <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
          <IonCol size='2.8'>
            <CheckboxItem
              label='Customer Specified SPWHT of Test Coupons'
              style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
              onIonChange={customerSpecifiedSPWHTofTestCoupons => {
                optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).customerSpecifiedSPWHTOfTestCouponsRequired = customerSpecifiedSPWHTofTestCoupons;
                detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).customerSpecifiedSPWHTTestCoupons = customerSpecifiedSPWHTofTestCoupons;
                setCustomerSpecifiedSPWHTOfTestCouponsRequired(customerSpecifiedSPWHTofTestCoupons);
              }}
              checked={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).customerSpecifiedSPWHTTestCoupons}
            />
          </IonCol>
          <IonCol>
            <SpecifyButton isDisabled={!customerSpecifiedSPWHTOfTestCouponsRequired} onClick={() => setShowPopover({ showPopover: true, event: undefined })}/>
          </IonCol>
          <IonCol className="specify-per-assembly-column" size="1">
            <div className="specify-per-assembly-container">
              <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
            </div>
          </IonCol>
        </IonRow>
      </>
    );
  };

  const CustomerSpecifiedImpactTestingRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteLineIdForRow?: string,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupLineFieldsToUpdate?: {[key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate},
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>,
  }) => {
    const [customerSpecifiedImpactTestingRequired, setCustomerSpecifiedImpactTestingRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).customerSpecifiedImpactTestingRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerSpecifiedImpactTesting);
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerSpecifiedImpactTesting = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <>
        <IonPopover
          cssClass='customer-specified-impact-testing-popover'
          isOpen={popoverState.showPopover}
          animated={ false }
          onDidDismiss={async () => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
        >
          <CustomerSpecifiedImpactTestingPopover
            setShowPopover={setShowPopover}
            lineFieldsToUpdate={lineFieldsToUpdate(props.assemblyGroupLineFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteLineIdForRow)}
            detailFieldsToUpdate={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow)}
            optionsRequired={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow)}
          />
        </IonPopover>
        <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
          <IonCol size='2.8'>
            <CheckboxItem
              label='Customer Specified Impact Testing'
              style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
              onIonChange={checked => {
                optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).customerSpecifiedImpactTestingRequired = checked;
                detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).customerSpecifiedImpactTesting = checked;
                setCustomerSpecifiedImpactTestingRequired(checked);
              }}
              checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).customerSpecifiedImpactTestingRequired}
            />
          </IonCol>
          <IonCol>
            <SpecifyButton isDisabled={!customerSpecifiedImpactTestingRequired} onClick={() => setShowPopover({ showPopover: true, event: undefined })}/>
          </IonCol>
          <IonCol className="specify-per-assembly-column" size="1">
            <div className="specify-per-assembly-container">
              <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
            </div>
          </IonCol>
        </IonRow>
      </>
    );
  };

  const ShearTestRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>,
  }) => {
    const [shearTestRequired, setShearTestRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).shearTestRequired);
    const [shearTestValue, setShearTestValue] = React.useState(detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).shearTestValue);
    const [shearTestUOM, setShearTestUOM] = React.useState(detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).shearTestUOM);

    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].shearTest
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].shearTest = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    React.useEffect(() => {
      if(shearTestRequired) {
        if(!shearTestValue || !shearTestUOM) {
          const configDescription = displayedQuoteGroup.qliDisplayed.Unit_of_Measure__c === MeasurementSystems.Metric
          ? QuoteVariableConfigDescriptions.OERTestingShearTestingPressureMPA
          : QuoteVariableConfigDescriptions.OERTestingShearTestingPressurePSI;

          setShearTestValue(getQuoteVariableConfiguration(quoteVariableConfigurations, configDescription, manufacturingSite, FieldTypes.Integer) as number);
          setShearTestUOM(getQuoteVariableConfigurationUOM(quoteVariableConfigurations, configDescription, manufacturingSite));
        }
      } else {
        setShearTestUOM(null);
        setShearTestValue(null);
      }
    }, [shearTestRequired]);

    React.useEffect(() => {
      try {
        detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).shearTestValue = shearTestValue;
      } catch (err) {
        console.log(err);
      }
    }, [shearTestValue]);

    React.useEffect(() => {
      try {
        detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).shearTestUOM = shearTestUOM;
      } catch (err) {
        console.log(err);
      }
    }, [shearTestUOM]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Shear Test'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).shearTestRequired = checked;
              setShearTestRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).shearTestRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='1'
            regex={Regexes.IntegerAboveZero}
            min='1'
            style={{ width: '78px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={shearTestValue => setShearTestValue(shearTestValue)}
            value={shearTestValue}
            isDisabled={!shearTestRequired}
          />
          <SelectionContainer
            type={SelectionTypes.RadioButtons}
            itemStyle={{ ['--background']: 'transparent' }}
            options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.ShearTestUOM).options}
            onIonChange={shearTestUOM => setShearTestUOM(shearTestUOM)}
            value={shearTestUOM}
            isDisabled={!shearTestRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

    //Sets cladTensile default value if there were no selected options
  const setCladTensileDefault = (checked, assemblyGroupDetailFieldsToUpdate, quoteDetailForRow) => {
    const current = detailFieldsToUpdate(assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, quoteDetailForRow).cladTensileTestLocation;
      if(!current) {
        checked ? detailFieldsToUpdate(assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, quoteDetailForRow).cladTensileTestLocation = TensileTestLocations.PerSpecification  : detailFieldsToUpdate(assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, quoteDetailForRow).cladTensileTestLocation == null;
      }
  };

  const TensileTestRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [tensileTestRequired, setTensileTestRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).tensileTestRequired);

    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].tensileTest
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].tensileTest = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Tensile Test'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).tensileTestRequired = checked;
              setTensileTestRequired(checked);
              setCladTensileDefault(checked, props.assemblyGroupDetailFieldsToUpdate, props.quoteDetailForRow);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).tensileTestRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select Test Location...'
            options={[
              TensileTestLocations.PerSpecification,
              TensileTestLocations.SpecificationCenterline,
              TensileTestLocations.CenterlineOnly12T,
            ]}
            style={{ width: '200px', marginRight: '10px', marginTop: '0px' }}
            onChange={cladTensileTestLocation =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).cladTensileTestLocation = cladTensileTestLocation.label)
            }
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).cladTensileTestLocation || TensileTestLocations.PerSpecification}
            isDisabled={!tensileTestRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const RamTensileTestRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [ramTensileTestRequired, setRamTensileTestRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).ramTensileTestRequired);

    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].ramTensileTest
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].ramTensileTest = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Bond Strength Tensile Test (Ram)'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).ramTensileTestRequired = checked;
              setRamTensileTestRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).ramTensileTestRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='1'
            regex={Regexes.IntegerAboveZero}
            min='1'
            style={{ width: '78px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={bondStrengthTensileTestRamValue =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).bondStrengthTensileTestRamValue = bondStrengthTensileTestRamValue)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).bondStrengthTensileTestRamValue}
            isDisabled={!ramTensileTestRequired}
          />
          <SelectionContainer
            type={SelectionTypes.RadioButtons}
            itemStyle={{ ['--background']: 'transparent' }}
            options={[TestingUOMs.PSI, TestingUOMs.MPA]}
            onIonChange={bondStrengthTensileTestRamUOM =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).bondStrengthTensileTestRamUOM = bondStrengthTensileTestRamUOM)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).bondStrengthTensileTestRamUOM}
            isDisabled={!ramTensileTestRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const HardnessCladdingMetalSurfaceRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [hardnessCladdingMetalSurfaceRequired, setHardnessCladdingMetalSurfaceRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hardnessCladdingMetalSurfaceRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hardnessCladdingMetalSurface
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hardnessCladdingMetalSurface = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Hardness Cladding Metal Surface'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hardnessCladdingMetalSurfaceRequired = checked;
              setHardnessCladdingMetalSurfaceRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hardnessCladdingMetalSurfaceRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={[
              HardnessMetalSurfaces.None,
              HardnessMetalSurfaces.RBRockwellB,
              HardnessMetalSurfaces.RCRockwellC,
              HardnessMetalSurfaces.Brinell,
              HardnessMetalSurfaces.HVVickers,
              HardnessMetalSurfaces.Other,
            ]}
            style={{ width: '200px', marginRight: '10px', marginTop: '0px' }}
            onChange={hardnessCladdingMetalSurface =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hardnessCladdingMetalSurface = hardnessCladdingMetalSurface.label)
            }
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hardnessCladdingMetalSurface}
            isDisabled={!hardnessCladdingMetalSurfaceRequired}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Rejection Criteria...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={hardnessCladdingRejectionCriteria =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hardnessCladdingRejectionCriteria = hardnessCladdingRejectionCriteria)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hardnessCladdingRejectionCriteria}
            isDisabled={!hardnessCladdingMetalSurfaceRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const HardnessBaseMetalSurfaceRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [hardnessBaseMetalSurfaceRequired, setHardnessBaseMetalSurfaceRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hardnessBaseMetalSurfaceRequired);

    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hardnessBaseMetalSurface
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hardnessBaseMetalSurface = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Hardness Base Metal Surface'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hardnessBaseMetalSurfaceRequired = checked;
              setHardnessBaseMetalSurfaceRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hardnessBaseMetalSurfaceRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={[
              HardnessMetalSurfaces.None,
              HardnessMetalSurfaces.RBRockwellB,
              HardnessMetalSurfaces.RCRockwellC,
              HardnessMetalSurfaces.Brinell,
              HardnessMetalSurfaces.HVVickers,
              HardnessMetalSurfaces.Other,
            ]}
            style={{ width: '200px', marginRight: '10px', marginTop: '0px' }}
            onChange={hardnessBaseMetalSurface =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hardnessBaseMetalSurface = hardnessBaseMetalSurface.label)
            }
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hardnessBaseMetalSurface}
            isDisabled={!hardnessBaseMetalSurfaceRequired}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Rejection Criteria...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={hardnessBaseRejectionCriteria =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hardnessBaseRejectionCriteria = hardnessBaseRejectionCriteria)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hardnessBaseRejectionCriteria}
            isDisabled={!hardnessBaseMetalSurfaceRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const PMIConfirmCladdingMetalRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [pmiConfirmCladdingMetalRequired, setPmiConfirmCladdingMetalRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).pmiConfirmCladdingMetalRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIConfirmCladdingMetal);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIConfirmCladdingMetal = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='PMI Confirm Cladding Metal'
            style={{ width: 'fit-content', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).pmiConfirmCladdingMetalRequired = checked;
              setPmiConfirmCladdingMetalRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).pmiConfirmCladdingMetalRequired}
          />
        </IonCol>
        <IonCol class='flex-column'>
          <div className='flex-container'>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Specify Other Details...'
              inputType='text'
              style={{ width: '448px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={pmiConfirmCladdingMetalNotes => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIConfirmCladdingMetalNotes = pmiConfirmCladdingMetalNotes)}
              value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIConfirmCladdingMetalNotes}
              isDisabled={!pmiConfirmCladdingMetalRequired}
            />
          </div>
          <SelectionContainer
            type={SelectionTypes.RadioButtons}
            itemStyle={{ ['--background']: 'transparent' }}
            options={['Upon Receipt', 'Upon Receipt and Final Inspection']}
            onIonChange={PMIConfirmCladdingMetalReceipt =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIConfirmCladdingMetal = PMIConfirmCladdingMetalReceipt)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIConfirmCladdingMetal}
            isDisabled={!pmiConfirmCladdingMetalRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const PMIConfirmBaseMetalRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [pmiConfirmBaseMetalRequired, setPmiConfirmBaseMetalRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).pmiConfirmBaseMetalRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIConfirmBaseMetal);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIConfirmBaseMetal = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='PMI Confirm Base Metal'
            style={{ width: 'fit-content', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).pmiConfirmBaseMetalRequired = checked;
              setPmiConfirmBaseMetalRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).pmiConfirmBaseMetalRequired}
          />
        </IonCol>
        <IonCol class='flex-column'>
          <div className='flex-container'>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Specify Other Details...'
              inputType='text'
              style={{ width: '448px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={pmiConfirmBaseMetalNotes => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIConfirmBaseMetalNotes = pmiConfirmBaseMetalNotes)}
              value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIConfirmBaseMetalNotes}
              isDisabled={!pmiConfirmBaseMetalRequired}
            />
          </div>
          <SelectionContainer
            type={SelectionTypes.RadioButtons}
            itemStyle={{ ['--background']: 'transparent' }}
            options={['Upon Receipt', 'Upon Receipt and Final Inspection']}
            onIonChange={PMIConfirmBaseMetalReceipt =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIConfirmBaseMetal = PMIConfirmBaseMetalReceipt)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIConfirmBaseMetal}
            isDisabled={!pmiConfirmBaseMetalRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const BondStrengthBendPerSA26345Row = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].sa26345BondStrength);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].sa26345BondStrength = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol>
          <CheckboxItem
            label='Bond Strength Bend Per SA263/4/5'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={machineBondStrengthBendsperSA26345 => {
              detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).machineBondStrengthBendsperSA26345 = machineBondStrengthBendsperSA26345;
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).sa26345BondStrengthRequired = machineBondStrengthBendsperSA26345;
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).sa26345BondStrengthRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const BendB898Row = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].b898Bend);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].b898Bend = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol>
          <CheckboxItem
            label='Bend B898'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={bendB898 => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).b898BendRequired = bendB898;
              detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).bendB898 = bendB898;
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).b898BendRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const SideBendRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].machineSideBends);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].machineSideBends = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol>
          <CheckboxItem
            label='Side Bend'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={machineSideBends => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).machineSideBends = machineSideBends)}
            checked={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).machineSideBends}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const ADW8BendRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].adw8Bend);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].adw8Bend = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol>
          <CheckboxItem
            label='AD-W8 Bend'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={ADW8Bend => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).adw8BendRequired = ADW8Bend;
              detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).ADW8Bend = ADW8Bend;
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).adw8BendRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const LeakTestRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].leakTest);
    const [leakTestingRequired, setLeakTestingRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).leakTestRequired);
    const [leakTestPopoverState, setShowLeakTestPopover] = React.useState({ showPopover: false, event: undefined });

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].leakTest = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <>
        <IonPopover
          cssClass='leak-test-popover'
          isOpen={leakTestPopoverState.showPopover}
          animated={ false }
          onDidDismiss={async () => {
          setShowLeakTestPopover({ showPopover: false, event: undefined });
          }}
        >
          <LeakTestPopover
          setShowPopover={setShowLeakTestPopover}
          detailFieldsToUpdate={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow)}
          />
        </IonPopover>
        <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
          <IonCol size='2.8'>
            <CheckboxItem
              label='Leak Test'
              style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
              onIonChange={checked => {
                optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).leakTestRequired = checked;
                setLeakTestingRequired(checked);
              }}
              checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).leakTestRequired}
            />
          </IonCol>
          <IonCol>
            <SpecifyButton isDisabled={!leakTestingRequired} onClick={() => setShowLeakTestPopover({ showPopover: true, event: undefined })}/>
          </IonCol>
          <IonCol className="specify-per-assembly-column" size="1">
            <div className="specify-per-assembly-container">
              <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
            </div>
          </IonCol>
        </IonRow>
      </>
    );
  };

  const DyePenetrantRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [dyePenetrantRequired, setDyePenetrantRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).dyePenetrantRequired);

    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].dyePenetrantNotes);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].dyePenetrantNotes = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='100% Dye Penetrant'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).dyePenetrantRequired = checked;
              setDyePenetrantRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).dyePenetrantRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Dye Penetrant...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={dyePenetrantNotes =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).dyePenetrantNotes = dyePenetrantNotes)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).dyePenetrantNotes}
            isDisabled={!dyePenetrantRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const AdditionalRequirementsCladProductTestingRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsCladProductTesting);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsCladProductTesting = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label' style={{ marginTop: '10px' }}>
            Additional Requirements
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextArea}
            placeholder='Specify Additional Requirements...'
            style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto' }}
            rows={6}
            onBlur={additionalRequirements => {
              detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).additionalRequirementsCladProductTesting = additionalRequirements;
              if (additionalRequirements.length > TextAreaMax.TenThousand) {
                toast(ToastTypes.Error, ToastMessages.TooManyCharactersTenThousand);
                setAddReqsCladProductTestingMaxSurpassed(true);
               } else {
                setAddReqsCladProductTestingMaxSurpassed(false);
              }
            }}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).additionalRequirementsCladProductTesting}
            textAreaType='Additional Requirements'
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } style={{ marginTop: 'unset', marginBottom: 'unset' }} fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  /** Tab Sections */
  // CladTesting Section
  const CladTesting = () => (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>
              Clad Production Specification
            </IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select specification'
              options={[
                CladProductionSpecifications.OtherManualEntry,
                CladProductionSpecifications.ADW8DMC100,
                CladProductionSpecifications.B432DMC100,
                CladProductionSpecifications.B898DMC100,
                CladProductionSpecifications.DMC100,
                CladProductionSpecifications.SA263DMC100,
                CladProductionSpecifications.SA264DMC100,
                CladProductionSpecifications.SA265DMC100,
                CladProductionSpecifications.CODAPDMC100,
              ]}
              style={{ width: '200px', marginRight: '10px', marginTop: '0px' }}
              onChange={cladProductionSpecification => {
                metalComboDetailFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladProductionSpecification = cladProductionSpecification.label;

                const postCladBondUTSpecificationSelections = [...specificationTestOptions.filter((specificationTestOption) => specificationTestOption.specification === cladProductionSpecification.label.split('&')[0].trim() && specificationTestOption.dataAreaId === manufacturingSite).map(specificationTestOption => `${specificationTestOption.specification}: ${specificationTestOption.option}`)];
                metalComboSelections[displayedQuoteGroup.metalComboKey].postCladBondUTSpecification = postCladBondUTSpecificationSelections;
                setPostCladBondUTSpecificationSelections(postCladBondUTSpecificationSelections);
              }}
              defaultValue={metalComboDetailFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladProductionSpecification}
            />
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Additional Notes...'
              inputType='text'
              style={{ width: '548px' }}
              onBlur={cladProductionSpecificationNotes =>
                (metalComboDetailFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladProductionSpecificationNotes = cladProductionSpecificationNotes)
              }
              value={metalComboDetailFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladProductionSpecificationNotes}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>
              Clad Metal
            </IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Clad Metal'
              inputType='text'
              style={{ width: '374px' }}
              isDisabled={true}
              value={cladMetal?.metalLongName}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>
              Base Metal
            </IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Base Metal'
              inputType='text'
              style={{ width: '374px' }}
              isDisabled={true}
              value={baseMetal?.metalLongName}
            />
          </IonCol>
        </IonRow>
        <PostCladBondUTSpecificationRow/>
        <PostCladBaseMetalUTSpecificationRow/>
        <CertificationTypeRequiredRow/>
        <CladdingForCorrosionAllowanceOnlyRow/>
        <IsWeldRepairAllowedRow/>
        <CustomerSpecifiedSPWHTOfTestCouponsRow/>
        <CustomerSpecifiedImpactTestingRow/>
        <ShearTestRow/>
        <TensileTestRow/>
        <RamTensileTestRow/>
        <HardnessCladdingMetalSurfaceRow/>
        <HardnessBaseMetalSurfaceRow/>
        <PMIConfirmCladdingMetalRow/>
        <PMIConfirmBaseMetalRow/>
        <BondStrengthBendPerSA26345Row/>
        <BendB898Row/>
        <SideBendRow/>
        <ADW8BendRow/>
        <LeakTestRow/>
        { manufacturingSite === ManufacturingSites.DE01 && <DyePenetrantRow/> }
        <AdditionalRequirementsCladProductTestingRow/>
      </IonGrid>
    </>
  );

  const NCCladHeatTreatmentAsSuppliedRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCCladHeatTreatmentasSupplied
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCCladHeatTreatmentasSupplied = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            NC Heat Treatment as Supplied
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NCCladHeatTreatmentasSupplied).options}
            style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
            onChange={NCCladHeatTreatmentasSupplied =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCCladHeatTreatmentasSupplied = NCCladHeatTreatmentasSupplied.label)
            }
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCCladHeatTreatmentasSupplied}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Details...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={NCCladHeatTreatmentasSuppliedNotes =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCCladHeatTreatmentasSuppliedNotes = NCCladHeatTreatmentasSuppliedNotes)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCCladHeatTreatmentasSuppliedNotes}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const NCCladChemistryRestrictionsForBondingRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCCladChemistryRestrictionsforBonding
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCCladChemistryRestrictionsforBonding = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            NC Chemistry Restrictions for Bonding
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Specification Requirements Only'
            options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NCCladChemistryRestrictionsforBonding).options}
            style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
            onChange={NCCladChemistryRestrictionsforBonding =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCCladChemistryRestrictionsforBonding = NCCladChemistryRestrictionsforBonding.label)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCCladChemistryRestrictionsforBonding}
            defaultValue={(getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NCCladChemistryRestrictionsforBonding).options)[0]}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Chemistry Restrictions...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={NCCladChemistryRestForBondingNotes =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCCladChemistryRestForBondingNotes = NCCladChemistryRestForBondingNotes)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCCladChemistryRestForBondingNotes}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CustomerCladChemistryRestrictionsRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [cladCustomerChemistryRestrictionsRequired, setCladCustomerChemistryRestrictionsRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).cladCustomerChemistryRestrictionsRequired);

    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerCladChemistryRestrictionsNotes);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerCladChemistryRestrictionsNotes = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Customer Chemistry Restrictions'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).cladCustomerChemistryRestrictionsRequired = checked;
              setCladCustomerChemistryRestrictionsRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).cladCustomerChemistryRestrictionsRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Customer Chemistry Restrictions...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={customerCladChemistryRestrictionsNotes =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).customerCladChemistryRestrictionsNotes = customerCladChemistryRestrictionsNotes)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).customerCladChemistryRestrictionsNotes}
            isDisabled={!cladCustomerChemistryRestrictionsRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CorrosionTestingRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [corrosionTestingRequired, setCorrosionTestingRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).corrosionTestingRequired);

    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].corrosionTesting
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].corrosionTesting = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Corrosion Testing'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).corrosionTestingRequired = checked;
              setCorrosionTestingRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).corrosionTestingRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CorrosionTesting).options}
            style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
            onChange={corrosionTestingValue => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).corrosionTesting = corrosionTestingValue.label)}
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).corrosionTesting}
            isDisabled={!corrosionTestingRequired}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Details...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={corrosionTestingNotes => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).corrosionTestingNotes = corrosionTestingNotes)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).corrosionTestingNotes}
            isDisabled={!corrosionTestingRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CladMetalSpecEdAndAdRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const requiredOptionsMap = optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow);
    const detailToUpdate = detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow);

    const [cladMetalSpecEdAndAdRequired, setCladMetalSpecEdAndAdRequired] = React.useState(!!requiredOptionsMap.cladMetalSpecEdAndAdRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].cladMetalSpecEdAndAd
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].cladMetalSpecEdAndAd = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    React.useEffect(() => {
      if (!detailToUpdate.cladMetalSpecEdition) {
        detailToUpdate.cladMetalSpecEdition = MetalSpecDefault.Current;
      }

      if (!detailToUpdate.cladMetalSpecAddendum) {
        detailToUpdate.cladMetalSpecAddendum = MetalSpecDefault.Current;
      }
    }, [detailToUpdate.cladMetalSpecEdition, detailToUpdate.cladMetalSpecAddendum]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Metal Spec Ed and Ad (if not latest)'
            style={{ width: 'fit-content', marginTop: '35px', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              requiredOptionsMap.cladMetalSpecEdAndAdRequired = checked;
              setCladMetalSpecEdAndAdRequired(checked);
            }}
            checked={cladMetalSpecEdAndAdRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='text'
            selectionLabel={'Edition'}
            placeholder='Current'
            style={{ width: '320px', marginRight: '10px', marginTop: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={cladMetalSpecEdition => (detailToUpdate.cladMetalSpecEdition = cladMetalSpecEdition)}
            value={detailToUpdate.cladMetalSpecEdition}
            isDisabled={!cladMetalSpecEdAndAdRequired}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='text'
            selectionLabel={'Addendum'}
            placeholder='Current'
            style={{ width: '320px', marginRight: '10px', marginTop: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={cladMetalSpecAddendum => (detailToUpdate.cladMetalSpecAddendum = cladMetalSpecAddendum)}
            value={detailToUpdate.cladMetalSpecAddendum}
            isDisabled={!cladMetalSpecEdAndAdRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CladCustomerMetalSupplierRestrictionsRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const requiredOptionsMap = optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow);
    const detailToUpdate = detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow);
    const defaultMetalSupplierRestrictions = getDefaultMetalPurchaseRequirement(cladMetal, manufacturingSite, MetalPurchaseRequirementsGroups.SupplierRestrictions);
    const metalSupplierRestrictionsDescriptions =  getSelectionOptionsDescriptions(selections, manufacturingSite, QuoteLineItemValueTypes.CladMetalPurchaseRequirements);
    const metalSupplierRestrictionsOptions =  metalSupplierRestrictionsDescriptions.filter((o)=> o !== defaultMetalSupplierRestrictions);

    const [cladCustomerMetalSupplierRestrictionsRequired, setCladCustomerMetalSupplierRestrictionsRequired] = React.useState(!!requiredOptionsMap.cladCustomerMetalSupplierRestrictionsRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerCladMetalSupplierRestrictions
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerCladMetalSupplierRestrictions = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Customer Metal Supplier Restrictions'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              requiredOptionsMap.cladCustomerMetalSupplierRestrictionsRequired = checked;
              setCladCustomerMetalSupplierRestrictionsRequired(checked);
            }}
            checked={cladCustomerMetalSupplierRestrictionsRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={metalSupplierRestrictionsOptions}
            style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
            onChange={customerCladMetalSupplierRestrictions =>
              (detailToUpdate.customerCladMetalSupplierRestrictions = customerCladMetalSupplierRestrictions.label)
            }
            defaultValue={detailToUpdate.customerCladMetalSupplierRestrictions}
            isDisabled={!cladCustomerMetalSupplierRestrictionsRequired}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Supplier Restrictions...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={custCladMetalSupplierRestrictionsNotes =>
              (detailToUpdate.custCladMetalSupplierRestrictionsNotes = custCladMetalSupplierRestrictionsNotes)
            }
            value={detailToUpdate.custCladMetalSupplierRestrictionsNotes}
            isDisabled={!cladCustomerMetalSupplierRestrictionsRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CladMetalProductAnalysisRequiredSection = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [cladMetalProductAnalysisRequired, setCladMetalProductAnalysisRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).cladMetalProductAnalysisRequired);

    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].cladMetalProductAnalysisRequired
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].cladMetalProductAnalysisRequired = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <>
        <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
          <IonCol size='2.8'>
            <CheckboxItem
              label='Metal Product Analysis Required'
              style={{ width: 'fit-content', ['--background']: 'transparent' }}
              onIonChange={checked => {
                optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).cladMetalProductAnalysisRequired = checked;
                setCladMetalProductAnalysisRequired(checked);
              }}
              checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).cladMetalProductAnalysisRequired}
            />
          </IonCol>
          <IonCol class='flex-column'>
            <div className='flex-container'>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select specification'
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CladMetalProductAnalysisRequired).options}
                style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
                onChange={cladMetalProductAnalysisRequired =>
                  (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).cladMetalProductAnalysisRequired = cladMetalProductAnalysisRequired.label)
                }
                defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).cladMetalProductAnalysisRequired}
                isDisabled={!cladMetalProductAnalysisRequired}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                placeholder='Specify Other Details...'
                inputType='text'
                style={{ width: '448px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={cladMetalProductAnalysisRequiredNotes =>
                  (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).cladMetalProductAnalysisRequiredNotes = cladMetalProductAnalysisRequiredNotes)
                }
                value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).cladMetalProductAnalysisRequiredNotes}
                isDisabled={!cladMetalProductAnalysisRequired}
              />
            </div>
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              itemStyle={{ ['--background']: 'transparent' }}
              options={['Performed by Vendor', 'Performed by Independent Test Lab']}
              onIonChange={cladMetalProductAnalysisType =>
                (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).cladMetalProductAnalysisType = cladMetalProductAnalysisType)
              }
              value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).cladMetalProductAnalysisType}
              isDisabled={!cladMetalProductAnalysisRequired}
            />
          </IonCol>
          <IonCol className="specify-per-assembly-column" size="1">
            <div className="specify-per-assembly-container">
              <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } style={{ marginTop: 'unset', marginBottom: 'unset' }} fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
            </div>
          </IonCol>
        </IonRow>
      </>
    );
  };

  const FerroxylTestOfCladdingSurfacePerformedRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [ferroxylTestOfCladdingSurfacePerformedRequired, setFerroxylTestOfCladdingSurfacePerformedRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).ferroxylTestOfCladdingSurfacePerformedRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].ferroxylTestPerformed);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].ferroxylTestPerformed = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Ferroxyl Test of Cladding Surface Performed'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).ferroxylTestOfCladdingSurfacePerformedRequired = checked;
              setFerroxylTestOfCladdingSurfacePerformedRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).ferroxylTestOfCladdingSurfacePerformedRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={[
              FerroxylTestOfCladdingSurfacePerformed.PriorToHeatTreatment,
              FerroxylTestOfCladdingSurfacePerformed.PriorToShipment,
              FerroxylTestOfCladdingSurfacePerformed.PriorToBothHeatTreatmentAndShipment,
            ]}
            style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
            onChange={ferroxylTestPerformed => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).ferroxylTestPerformed = ferroxylTestPerformed.label)}
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).ferroxylTestPerformed}
            isDisabled={!ferroxylTestOfCladdingSurfacePerformedRequired}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Details...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={ferroxylNotes => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).ferroxylNotes = ferroxylNotes)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).ferroxylNotes}
            isDisabled={!ferroxylTestOfCladdingSurfacePerformedRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const AdditionalRequirementsCladdingMetalRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsCladdingMetal);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsCladdingMetal = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label' style={{ marginTop: '10px' }}>
            Additional Requirements
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextArea}
            placeholder='Specify Additional Requirements...'
            style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto' }}
            rows={6}
            onBlur={additionalRequirements => {
              detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).additionalRequirementsCladdingMetal = additionalRequirements;
              if (additionalRequirements.length > TextAreaMax.TenThousand) {
                toast(ToastTypes.Error, ToastMessages.TooManyCharactersTenThousand);
                setAddReqsCladdingMetalMaxSurpassed(true);
               } else {
                setAddReqsCladdingMetalMaxSurpassed(false);
              }
            }}
            textAreaType='Additional Requirements'
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).additionalRequirementsCladdingMetal}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } style={{ marginTop: 'unset', marginBottom: 'unset' }} fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  // CladMetal Section
  const CladMetal = () => (
    <>
      <IonGrid>
        <NCCladHeatTreatmentAsSuppliedRow/>
        <NCCladChemistryRestrictionsForBondingRow/>
        <CustomerCladChemistryRestrictionsRow/>
        <CorrosionTestingRow/>
        <CladMetalSpecEdAndAdRow/>
        <CladCustomerMetalSupplierRestrictionsRow/>
        <CladMetalProductAnalysisRequiredSection/>
        <FerroxylTestOfCladdingSurfacePerformedRow/>
        <AdditionalRequirementsCladdingMetalRow/>
      </IonGrid>
    </>
  );

  const RadiographyRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].radiography);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].radiography = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            Radiography
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.RadioButtons}
            itemStyle={{ ['--background']: 'transparent' }}
            options={['Spot', '100%']}
            onIonChange={radiography => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).radiographyClad = radiography)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).radiographyClad}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const PMIOfWeldWireRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIofWeldWire);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIofWeldWire = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol>
          <CheckboxItem
            label='PMI of Weld Wire, Performed on Button'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={PMIofWeldWire => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIofWeldWire = PMIofWeldWire)}
            checked={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIofWeldWire}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const PMIPreweldsRequiredRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIPreweldsRequired);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].PMIPreweldsRequired = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol>
          <CheckboxItem
            label='PMI of Prewelds Required'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={PMIPreweldsRequired => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIPreweldsRequired = PMIPreweldsRequired)}
            checked={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).PMIPreweldsRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const FerriteNumberRequiredRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [ferriteNumberRequired, setFerriteNumberRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).ferriteNumberRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].ferriteNumberRequired);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].ferriteNumberRequired = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Ferrite Number Required'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).ferriteNumberRequired = checked;
              setFerriteNumberRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).ferriteNumberRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='Ferrite Number Required...'
            regex={Regexes.IntegerAboveZero}
            style={{ width: '758px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={ferriteNumberRequired => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).ferriteNumberRequired = ferriteNumberRequired)}
            min='1'
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).ferriteNumberRequired}
            isDisabled={!ferriteNumberRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const AdditionalRequirementsPreweldingRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsPrewelding);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsPrewelding = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label' style={{ marginTop: '10px' }}>
            Additional Requirements
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextArea}
            placeholder='Specify Additional Requirements...'
            style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto' }}
            rows={6}
            onBlur={additionalRequirements => {
              detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).additionalRequirementsPrewelding = additionalRequirements;
              if (additionalRequirements.length > TextAreaMax.TenThousand) {
                toast(ToastTypes.Error, ToastMessages.TooManyCharactersTenThousand);
                setAddReqsPreweldingMaxSurpassed(true);
               } else {
                setAddReqsPreweldingMaxSurpassed(false);
              }
            }}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).additionalRequirementsPrewelding}
            textAreaType='Additional Requirements'
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } style={{ marginTop: 'unset', marginBottom: 'unset' }} fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  // Prewelding Section
  const Prewelding = () => (
    <>
      <IonGrid>
        <RadiographyRow/>
        <PMIOfWeldWireRow/>
        <PMIPreweldsRequiredRow/>
        <FerriteNumberRequiredRow/>
        <AdditionalRequirementsPreweldingRow/>
      </IonGrid>
    </>
  );

  const NCBaseHeatTreatmentAsSuppliedRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCBaseHeatTreatmentasSupplied
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCBaseHeatTreatmentasSupplied = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            NC Heat Treatment as Supplied
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NCBaseHeatTreatmentasSupplied).options}
            style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
            onChange={NCBaseHeatTreatmentasSupplied =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCBaseHeatTreatmentasSupplied = NCBaseHeatTreatmentasSupplied.label)
            }
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCBaseHeatTreatmentasSupplied}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Heat Treatment Details...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={NCBaseHeatTreatmentasSuppliedNotes =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCBaseHeatTreatmentasSuppliedNotes = NCBaseHeatTreatmentasSuppliedNotes)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCBaseHeatTreatmentasSuppliedNotes}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const FinishedGoodHeatTreatmentRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].finishedGoodHeatTreatment
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].finishedGoodHeatTreatment = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            Finished Good Heat Treatment
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.FinishedGoodHeatTreatment).options}
            style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
            onChange={finishedGoodHeatTreatment =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).finishedGoodHeatTreatment = finishedGoodHeatTreatment.label)
            }
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).finishedGoodHeatTreatment}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Heat Treatment Details...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={finishedGoodHeatTreatmentNotes =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).finishedGoodHeatTreatmentNotes = finishedGoodHeatTreatmentNotes)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).finishedGoodHeatTreatmentNotes}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const NCBaseChemistryRestrictionsForBondingRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCBaseChemistryRestrictionsforBonding
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].NCBaseChemistryRestrictionsforBonding = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label'>
            NC Chemistry Restrictions for Bonding
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NCBaseChemistryRestrictionsforBonding).options}
            style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
            onChange={NCBaseChemistryRestrictionsforBonding =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCBaseChemistryRestrictionsforBonding = NCBaseChemistryRestrictionsforBonding.label)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCBaseChemistryRestrictionsforBonding}
            defaultValue={(getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NCBaseChemistryRestrictionsforBonding).options)[0]}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Additional Requirements...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={NCBaseChemistryRestForBondingNotes =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCBaseChemistryRestForBondingNotes = NCBaseChemistryRestForBondingNotes)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCBaseChemistryRestForBondingNotes}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const PrecladBaseMetalUTSection = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [precladBaseMetalUTRequired, setPrecladBaseMetalUTRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).precladBaseMetalUTRequired);
    const [precladBaseMetalUT, setPrecladBaseMetalUT] = React.useState(detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).precladBaseMetalUT);

    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].precladBaseMetalUT
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].precladBaseMetalUT = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <>
        <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
          <IonCol size='2.8'>
            <CheckboxItem
              label='Preclad Base Metal UT'
              style={{ width: 'fit-content', ['--background']: 'transparent' }}
              onIonChange={checked => {
                optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).precladBaseMetalUTRequired = checked;
                setPrecladBaseMetalUTRequired(checked);
              }}
              checked={precladBaseMetalUTRequired}
            />
          </IonCol>
          <IonCol class='flex-column'>
            <div className='flex-container'>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select specification'
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.PrecladBaseMetalUTOptions).options}
                style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
                onChange={precladBaseMetalUT => {
                  const metalPurchaseRequirementsUT = [
                    ...new Set([
                      ...getDistinctMetalPurchasingRequirements(
                        rawMaterials,
                        RawMaterialUsage.Base,
                        manufacturingSite,
                        MetalPurchaseRequirementsGroups.UltrasonicInspection
                      )])
                    ];

                  const metalPurchaseRequirementForSelection = metalPurchaseRequirementsUT.find(mpr => mpr.defaultValue === precladBaseMetalUT.label)?.content;

                  detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).NCBaseUTRequirement = metalPurchaseRequirementForSelection;
                  detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).precladBaseMetalUT = precladBaseMetalUT.label;
                  setPrecladBaseMetalUT(precladBaseMetalUT.label);
                }}
                value={precladBaseMetalUT}
                isDisabled={!precladBaseMetalUTRequired}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                placeholder='Specify Other Details...'
                inputType='text'
                style={{ width: '448px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={precladBaseMetalUTNotes => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).precladBaseMetalUTNotes = precladBaseMetalUTNotes)}
                value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).precladBaseMetalUTNotes}
                isDisabled={!precladBaseMetalUTRequired}
              />
            </div>
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              itemStyle={{ ['--background']: 'transparent' }}
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.PrecladBaseMetalUTType).options}
              onIonChange={precladBaseMetalUTType => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).precladBaseMetalUTType = precladBaseMetalUTType)}
              value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).precladBaseMetalUTType || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.PrecladBaseMetalUTType)?.default}
              isDisabled={!precladBaseMetalUTRequired}
            />
          </IonCol>
          <IonCol className="specify-per-assembly-column" size="1">
            <div className="specify-per-assembly-container">
              <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } style={{ marginTop: 'unset', marginBottom: 'unset' }} fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
            </div>
          </IonCol>
        </IonRow>
      </>
    );
  };

  const PrebondImpactsRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [prebondImpactsRequired, setPrebondImpactsRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).prebondImpactsRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].prebondImpacts);
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].prebondImpacts = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <>
        <IonPopover
          cssClass='pre-bond-impacts-popover'
          isOpen={popoverState.showPopover}
          animated={ false }
          onDidDismiss={async () => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
        >
          <PrebondImpactsPopover
            setShowPopover={setShowPopover}
            detailFieldsToUpdate={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow)}
          />
        </IonPopover>
        <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
          <IonCol size='2.8'>
            <CheckboxItem
              label='Prebond Impacts'
              style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
              onIonChange={checked => {
                optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).prebondImpactsRequired = checked;
                setPrebondImpactsRequired(checked);
              }}
              checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).prebondImpactsRequired}
            />
          </IonCol>
          <IonCol>
            <SpecifyButton isDisabled={!prebondImpactsRequired} onClick={() => setShowPopover({ showPopover: true, event: undefined })}/>
          </IonCol>
          <IonCol className="specify-per-assembly-column" size="1">
            <div className="specify-per-assembly-container">
              <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
            </div>
          </IonCol>
        </IonRow>
      </>
    );
  };

  const CarbonPercentRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [carbonPercentRequired, setCarbonPercentRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).carbonPercentRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].carbonPercent);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].carbonPercent = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Carbon &lt;='
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).carbonPercentRequired = checked;
              setCarbonPercentRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).carbonPercentRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='1.00'
            regex={Regexes.DecimalNumber}
            min='0.01'
            step='0.01'
            style={{ width: '78px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={carbonPercent => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).carbonPercent = carbonPercent)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).carbonPercent}
            isDisabled={!carbonPercentRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const SulphurPercentRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [sulphurPercentRequired, setSulphurPercentRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).sulphurPercentRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].sulphur);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].sulphur = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Sulphur &lt;='
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).sulphurPercentRequired = checked;
              setSulphurPercentRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).sulphurPercentRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='1.00'
            regex={Regexes.DecimalNumber}
            min='0.01'
            step='0.01'
            style={{ width: '78px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={sulphurPercent => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).sulphur = sulphurPercent)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).sulphur}
            isDisabled={!sulphurPercentRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const PhosphorousPercentRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [phosphorousPercentRequired, setPhosphorousPercentRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).phosphorousPercentRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].phosphorusPercent);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].phosphorusPercent = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Phosphorus &lt;='
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).phosphorousPercentRequired = checked;
              setPhosphorousPercentRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).phosphorousPercentRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='1.00'
            regex={Regexes.DecimalNumber}
            min='0.01'
            step='0.01'
            style={{ width: '78px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={phosphorusPercent => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).phosphorusPercent = phosphorusPercent)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).phosphorusPercent}
            isDisabled={!phosphorousPercentRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CarbonEquivalencyPercentRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [carbonEquivalencyPercentRequired, setCarbonEquivalencyPercentRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).carbonEquivalencyPercentRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].carbonEquivalencyPercent);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].carbonEquivalencyPercent = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Carbon Equivalency per SA-20 S20 &lt;='
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).carbonEquivalencyPercentRequired = checked;
              setCarbonEquivalencyPercentRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).carbonEquivalencyPercentRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='1.00'
            regex={Regexes.DecimalNumber}
            min='0.01'
            step='0.01'
            style={{ width: '78px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={carbonEquivalencyPercent => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).carbonEquivalencyPercent = carbonEquivalencyPercent)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).carbonEquivalencyPercent}
            isDisabled={!carbonEquivalencyPercentRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const JFactorSA387S62Row = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [jFactorSA387S62Required, setJFactorSA387S62Required] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).jFactorSA387S62Required);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].jFactorSA387S62);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].jFactorSA387S62 = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='J-Factor — SA-387 S62 &lt;='
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).jFactorSA387S62Required = checked;
              setJFactorSA387S62Required(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).jFactorSA387S62Required}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='1'
            regex={Regexes.IntegerAboveZero}
            min='1'
            style={{ width: '78px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={jFactorSA387S62 => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).jFactorSA387S62 = jFactorSA387S62)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).jFactorSA387S62}
            isDisabled={!jFactorSA387S62Required}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const XFactorSA387S62Row = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [xFactorSA387S62Required, setXFactorSA387S62Required] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).xFactorSA387S62Required);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].xFactorSA387S62);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].xFactorSA387S62 = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='X-Factor — SA-387 S62 (1.4 &amp; 1.5) &lt;='
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).xFactorSA387S62Required = checked;
              setXFactorSA387S62Required(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).xFactorSA387S62Required}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='1'
            regex={Regexes.IntegerAboveZero}
            min='1'
            style={{ width: '78px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={xFactorSA387S62 => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).xFactorSA387S62 = xFactorSA387S62)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).xFactorSA387S62}
            isDisabled={!xFactorSA387S62Required}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const BaseCustomerChemistryRestrictionsRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [baseCustomerChemistryRestrictionsRequired, setBaseCustomerChemistryRestrictionsRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).baseCustomerChemistryRestrictionsRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerBaseChemistryRestrictions);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerBaseChemistryRestrictions = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Customer Chemistry Restrictions'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).baseCustomerChemistryRestrictionsRequired = checked;
              setBaseCustomerChemistryRestrictionsRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).baseCustomerChemistryRestrictionsRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Customer Chemistry Restriction Details...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={customerBaseChemistryRestrictions =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).customerBaseChemistryRestrictions = customerBaseChemistryRestrictions)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).customerBaseChemistryRestrictions}
            isDisabled={!baseCustomerChemistryRestrictionsRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const VacuumDegassedRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].vacuumDegassed);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].vacuumDegassed = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol>
          <CheckboxItem
            label='Vacuum Degassed'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={vacuumDegassedYN => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).vacuumDegassed = vacuumDegassedYN)}
            checked={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).vacuumDegassed}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const CalciumTreatedSulfideShapeControlRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].calciumTreatedSulfideShapeControl);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].calciumTreatedSulfideShapeControl = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol>
          <CheckboxItem
            label='Calcium Treated for Sulfide Shape Control'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={calciumTreatedSulfideShapeControl =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).calciumTreatedSulfideShapeControl = calciumTreatedSulfideShapeControl)
            }
            checked={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).calciumTreatedSulfideShapeControl}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const ThroughThicknessTensileSA770YNRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].throughThicknessTensileSA770YN);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].throughThicknessTensileSA770YN = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol>
          <CheckboxItem
            label='Through Thickness Tensile per SA-770'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={throughThicknessTensileSA770YN =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).throughThicknessTensileSA770YN = throughThicknessTensileSA770YN)
            }
            checked={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).throughThicknessTensileSA770YN}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const BaseTensileTestRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [baseTensileTestRequired, setBaseTensileTestRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).baseTensileTestRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].baseTensileTestLocation);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].baseTensileTestLocation = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Centerline Tensile'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).baseTensileTestRequired = checked;
              setBaseTensileTestRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).baseTensileTestRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select Test Location...'
            options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.BaseTensileTestLocation).options}
            style={{ width: '200px', marginRight: '10px', marginTop: '0px' }}
            onChange={baseTensileTestLocation =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).baseTensileTestLocation = baseTensileTestLocation.label)
            }
            defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).baseTensileTestLocation}
            isDisabled={!baseTensileTestRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const HotTensileTestPerSA20S7Row = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [hotTensileTestPerSA20S7Required, setHotTensileTestPerSA20S7Required] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hotTensileTestPerSA20S7Required);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hotTensileTestPerSA20S7
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hotTensileTestPerSA20S7 = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Hot Tensile Test per SA-20 S7'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hotTensileTestPerSA20S7Required = checked;
              setHotTensileTestPerSA20S7Required(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hotTensileTestPerSA20S7Required}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='1'
            regex={Regexes.Temperature}
            min='1'
            style={{ width: '78px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={hotTensileTestPerSA20S7Degrees =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hotTensileTestPerSA20S7Degrees = hotTensileTestPerSA20S7Degrees)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hotTensileTestPerSA20S7Degrees}
            isDisabled={!hotTensileTestPerSA20S7Required}
          />
          <SelectionContainer
            type={SelectionTypes.RadioButtons}
            itemStyle={{ ['--background']: 'transparent' }}
            options={['F', 'C']}
            onIonChange={hotTensileTestperSA20S7UOM =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hotTensileTestPerSA20S7UOM = hotTensileTestperSA20S7UOM)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hotTensileTestPerSA20S7UOM}
            isDisabled={!hotTensileTestPerSA20S7Required}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Details...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={hotTensileTestPerSA20S7Notes =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hotTensileTestPerSA20S7Notes = hotTensileTestPerSA20S7Notes)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hotTensileTestPerSA20S7Notes}
            isDisabled={!hotTensileTestPerSA20S7Required}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const HICTestingRequiredRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [hicTestingRequired, setHICTestingRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hicTestingRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hicTestNotes);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].hicTestNotes = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='HIC Testing Required'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hicTestingRequired = checked;
              setHICTestingRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).hicTestingRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Details...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={hicTestNotes => (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hicTestNotes = hicTestNotes)}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).hicTestNotes}
            isDisabled={!hicTestingRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const AusteniticGrainSizeFinerThanRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [austeniticGrainSizeFinerThanRequired, setAusteniticGrainSizeFinerThanRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).austeniticGrainSizeFinerThanRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].austeniticGrainSizeFinerThanValue);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].austeniticGrainSizeFinerThanValue = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Austenitic Grain Size Finer Than'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).austeniticGrainSizeFinerThanRequired = checked;
              setAusteniticGrainSizeFinerThanRequired(checked);
            }}
            checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).austeniticGrainSizeFinerThanRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='number'
            placeholder='1'
            regex={Regexes.IntegerAboveZero}
            min='1'
            style={{ width: '78px', marginRight: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={austeniticGrainSizeFinerThanValue =>
              (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).austeniticGrainSizeFinerThanValue = austeniticGrainSizeFinerThanValue)
            }
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).austeniticGrainSizeFinerThanValue}
            isDisabled={!austeniticGrainSizeFinerThanRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const BaseMetalSpecEdAndAdRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const requiredOptionsMap = optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow);
    const detailToUpdate = detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow);

    const [baseMetalSpecAndAdRequired, setBaseMetalSpecAndAdRequired] = React.useState(!!requiredOptionsMap.baseMetalSpecAndAdRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].baseMetalSpecEdAndAd
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].baseMetalSpecEdAndAd = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    React.useEffect(() => {
      if (!detailToUpdate.baseMetalSpecEdition) {
        detailToUpdate.baseMetalSpecEdition = MetalSpecDefault.Current;
      }

      if (!detailToUpdate.baseMetalSpecAddendum) {
        detailToUpdate.baseMetalSpecAddendum = MetalSpecDefault.Current;
      }
    }, [detailToUpdate.baseMetalSpecEdition, detailToUpdate.baseMetalSpecAddendum]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Metal Spec Ed and Ad (if not latest)'
            style={{ width: 'fit-content', marginTop: '35px', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              requiredOptionsMap.baseMetalSpecAndAdRequired = checked;
              setBaseMetalSpecAndAdRequired(checked);
            }}
            checked={baseMetalSpecAndAdRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='text'
            selectionLabel={'Edition'}
            placeholder='Current'
            style={{ width: '320px', marginRight: '10px', marginTop: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={baseMetalSpecEdition => (detailToUpdate.baseMetalSpecEdition = baseMetalSpecEdition)}
            value={detailToUpdate.baseMetalSpecEdition}
            isDisabled={!baseMetalSpecAndAdRequired}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            inputType='text'
            selectionLabel={'Addendum'}
            placeholder='Current'
            style={{ width: '320px', marginRight: '10px', marginTop: '10px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={baseMetalSpecAddendum => (detailToUpdate.baseMetalSpecAddendum = baseMetalSpecAddendum)}
            value={detailToUpdate.baseMetalSpecAddendum}
            isDisabled={!baseMetalSpecAndAdRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const BaseCustomerMetalSupplierRestrictionsRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const requiredOptionsMap = optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow);
    const detailToUpdate = detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow);
    const defaultMetalSupplierRestrictions = getDefaultMetalPurchaseRequirement(baseMetal, manufacturingSite, MetalPurchaseRequirementsGroups.SupplierRestrictions);
    const metalSupplierRestrictionsDescriptions =  getSelectionOptionsDescriptions(selections, manufacturingSite, QuoteLineItemValueTypes.BaseMetalPurchaseRequirements);
    const metalSupplierRestrictionsOptions =  metalSupplierRestrictionsDescriptions.filter((o)=> o !== defaultMetalSupplierRestrictions);

    const [baseCustomerMetalSupplierRestrictionsRequired, setBaseCustomerMetalSupplierRestrictionsRequired] = React.useState(!!requiredOptionsMap.baseCustomerMetalSupplierRestrictionsRequired);
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerBaseMetalSupplierRestrictions
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].customerBaseMetalSupplierRestrictions = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <CheckboxItem
            label='Customer Metal Supplier Restrictions'
            style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
            onIonChange={checked => {
              requiredOptionsMap.baseCustomerMetalSupplierRestrictionsRequired = checked;
              setBaseCustomerMetalSupplierRestrictionsRequired(checked);
            }}
            checked={baseCustomerMetalSupplierRestrictionsRequired}
          />
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select specification'
            options={metalSupplierRestrictionsOptions}
            style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
            onChange={customerBaseMetalSupplierRestrictions =>
              (detailToUpdate.customerBaseMetalSupplierRestrictions = customerBaseMetalSupplierRestrictions.label)
            }
            defaultValue={detailToUpdate.customerBaseMetalSupplierRestrictions}
            isDisabled={!baseCustomerMetalSupplierRestrictionsRequired}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            placeholder='Specify Other Supplier Retrictions...'
            inputType='text'
            style={{ width: '448px' }}
            containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
            onBlur={custBaseMetalSupplierRestrictionsNotes =>
              (detailToUpdate.custBaseMetalSupplierRestrictionsNotes = custBaseMetalSupplierRestrictionsNotes)
            }
            value={detailToUpdate.custBaseMetalSupplierRestrictionsNotes}
            isDisabled={!baseCustomerMetalSupplierRestrictionsRequired}
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  const BaseMetalProductAnalysisRequiredSection = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [baseMetalProductAnalysisRequired, setBaseMetalProductAnalysisRequired] = React.useState(optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).baseMetalProductAnalysisRequired);

    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].baseMetalProductAnalysisRequired
    );

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].baseMetalProductAnalysisRequired = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <>
        <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
          <IonCol size='2.8'>
            <CheckboxItem
              label='Metal Product Analysis Required'
              style={{ width: 'fit-content', ['--background']: 'transparent' }}
              onIonChange={checked => {
                optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).baseMetalProductAnalysisRequired = checked;
                setBaseMetalProductAnalysisRequired(checked);
              }}
              checked={optionsRequired(props.assemblyGroupOptionsRequired, props.quoteDetailForRow).baseMetalProductAnalysisRequired}
            />
          </IonCol>
          <IonCol class='flex-column'>
            <div className='flex-container'>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select specification'
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.BaseMetalProductAnalysisRequired).options}
                style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
                onChange={baseMetalProductAnalysisRequired =>
                  (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).baseMetalProductAnalysisRequired = baseMetalProductAnalysisRequired.label)
                }
                defaultValue={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).baseMetalProductAnalysisRequired}
                isDisabled={!baseMetalProductAnalysisRequired}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                placeholder='Specify Other Details...'
                inputType='text'
                style={{ width: '448px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={baseMetalProductAnalysisRequiredNotes =>
                  (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).baseMetalProductAnalysisRequiredNotes = baseMetalProductAnalysisRequiredNotes)
                }
                value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).baseMetalProductAnalysisRequiredNotes}
                isDisabled={!baseMetalProductAnalysisRequired}
              />
            </div>
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              itemStyle={{ ['--background']: 'transparent' }}
              options={['Performed by Vendor', 'Performed by Independent Test Lab']}
              onIonChange={baseMetalProductAnalysisType =>
                (detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).baseMetalProductAnalysisType = baseMetalProductAnalysisType)
              }
              value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).baseMetalProductAnalysisType}
              isDisabled={!baseMetalProductAnalysisRequired}
            />
          </IonCol>
          <IonCol className="specify-per-assembly-column" size="1">
            <div className="specify-per-assembly-container">
              <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } style={{ marginTop: 'unset', marginBottom: 'unset' }} fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
            </div>
          </IonCol>
        </IonRow>
      </>
    );
  };

  const AdditionalRequirementsBaseMetalRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsBaseMetal);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].additionalRequirementsBaseMetal = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label' style={{ marginTop: '10px' }}>
            Additional Requirements
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextArea}
            placeholder='Specify Additional Requirements...'
            style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto' }}
            rows={6}
            onBlur={additionalRequirements => {
              detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).additionalRequirementsBaseMetal = additionalRequirements;
              if (additionalRequirements.length > TextAreaMax.TenThousand) {
                toast(ToastTypes.Error, ToastMessages.TooManyCharactersTenThousand);
                setAddReqsBaseMetalMaxSurpassed(true);
               } else {
                setAddReqsBaseMetalMaxSurpassed(false);
              }
            }}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).additionalRequirementsBaseMetal}
            textAreaType='Additional Requirements'
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } style={{ marginTop: 'unset', marginBottom: 'unset' }} fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  // BaseMetal Section
  const BaseMetal = () => (
    <>
      <IonGrid>
        <NCBaseHeatTreatmentAsSuppliedRow/>
        <FinishedGoodHeatTreatmentRow/>
        <NCBaseChemistryRestrictionsForBondingRow/>
        <PrecladBaseMetalUTSection/>
        <PrebondImpactsRow/>
        <CarbonPercentRow/>
        <SulphurPercentRow/>
        <PhosphorousPercentRow/>
        <CarbonEquivalencyPercentRow/>
        <JFactorSA387S62Row/>
        <XFactorSA387S62Row/>
        <BaseCustomerChemistryRestrictionsRow/>
        <VacuumDegassedRow/>
        <CalciumTreatedSulfideShapeControlRow/>
        <ThroughThicknessTensileSA770YNRow/>
        <BaseTensileTestRow/>
        <HotTensileTestPerSA20S7Row/>
        <HICTestingRequiredRow/>
        <AusteniticGrainSizeFinerThanRow/>
        <BaseMetalSpecEdAndAdRow/>
        <BaseCustomerMetalSupplierRestrictionsRow/>
        <BaseMetalProductAnalysisRequiredSection/>
        <AdditionalRequirementsBaseMetalRow/>
      </IonGrid>
    </>
  );

  const ExceptionsAndClarificationsRow = (props: {
    hideSpecifyPerAssembly?: boolean,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
  }) => {
    const [specifiedPerAssembly, setSpecifiedPerAssembly] = React.useState(specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].exceptionsAndClarifications);

    React.useEffect(() => {
      specifyPerAssemblyMap[displayedQuoteGroup.metalComboKey].exceptionsAndClarifications = specifiedPerAssembly;
    }, [specifiedPerAssembly]);

    return (
      <IonRow className={classNames({ 'specified-per-assembly': specifiedPerAssembly && specifyPerAssemblyToggled})}>
        <IonCol size='2.8'>
          <IonLabel class='selection-label' style={{ marginTop: '10px' }}>
            Exceptions and Clarifications to Specifications • INCLUDED ON QUOTE OUTPUT
          </IonLabel>
        </IonCol>
        <IonCol>
          <SelectionContainer
            type={SelectionTypes.TextArea}
            placeholder='Comments...'
            style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto'}}
            rows={6}
            onBlur={exceptionsAndClarifications => {
              detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).exceptionsAndClarifications = exceptionsAndClarifications;
              if (exceptionsAndClarifications.length > TextAreaMax.TenThousand) {
                toast(ToastTypes.Error, ToastMessages.TooManyCharactersTenThousand);
                setExceptionsAndClarificationsMaxSurpassed(true);
               } else {
                setExceptionsAndClarificationsMaxSurpassed(false);
              }
            }}
            value={detailFieldsToUpdate(props.assemblyGroupDetailFieldsToUpdate, displayedQuoteGroup.metalComboKey, props.quoteDetailForRow).exceptionsAndClarifications}
            textAreaType='Additional Requirements'
          />
        </IonCol>
        <IonCol className="specify-per-assembly-column" size="1">
          <div className="specify-per-assembly-container">
            <SpecifyPerAssemblyCheckbox hideSpecifyPerAssembly={ props.hideSpecifyPerAssembly } style={{ marginTop: 'unset', marginBottom: 'unset' }} fieldSpecifiedPerAssembly={specifiedPerAssembly} setFieldSpecifiedPerAssembly={(checked) => { setSpecifiedPerAssembly(checked); }}/>
          </div>
        </IonCol>
      </IonRow>
    );
  };

  // ExceptionsClarifications Section
  const ExceptionsClarifications = () => (
    <>
      <IonGrid>
        <ExceptionsAndClarificationsRow/>
      </IonGrid>
    </>
  );
  /** end Tab Sections */

  const routeChangeAlert = (url: string) => {
    confirmAlert({
      cssClass: 'confirm-alert',
      header: 'Are you sure you want to leave the page? Changes you made may not be saved.',
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: () => {
            dispatchAdvSpecState({ type: AdvSpecActionTypes.reset });
            history.push(url);
          }
        },
      ],
    });
  };

    // sections - directory of Section components to map over
    const sectionsMap: Map<AdvancedSpecHeaderTabs, () => JSX.Element> = new Map([
      [AdvancedSpecHeaderTabs.CladTesting, CladTesting],
      [AdvancedSpecHeaderTabs.CladMetal, CladMetal],
      [AdvancedSpecHeaderTabs.Prewelding, Prewelding],
      [AdvancedSpecHeaderTabs.BaseMetal, BaseMetal],
      [AdvancedSpecHeaderTabs.ExceptionsClarifications, ExceptionsClarifications],
    ]);

    return (
      <PageLayout>
        {isLoadingComplete() ? (
          <div className='adv-spec'>
            <div className='floating-actions-container'>
              <FloatingAction
                icon={quoteTab}
                darkModeIcon={quoteTabDarkMode}
                hoveredIcon={quoteTabHovered}
                onClick={() => {
                  if (hasError) {
                    toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
                  } else {
                    saveAdvSpecs();
                    navigateToRoute(PageRoutes.QuoteDetail, history, quote.Id);
                  }
                }}
                action='quote'
              />
              <FloatingAction
                icon={orderTab}
                darkModeIcon={orderTabDarkMode}
                hoveredIcon={orderTabHovered}
                onClick={() => {
                  if (hasError) {
                    toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
                  } else {
                    saveAdvSpecs();
                    navigateToRoute(PageRoutes.OrderEntry, history, quote.Id);
                  }
                }}
                action='order'
              />
              <FloatingAction
                icon={MSTOTab}
                darkModeIcon={MSTOTabDarkMode}
                hoveredIcon={MSTOTabHovered}
                onClick={() => {
                  if (hasError) {
                    toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
                  } else {
                    saveAdvSpecs();
                    navigateToRoute(PageRoutes.MSTO, history, quote.Id);
                  }
                }}
                action='msto'
              />
              <FloatingAction
                icon={AssGroups}
                darkModeIcon={AssGroupsDarkMode}
                hoveredIcon={AssGroupsHovered}
                onClick={() => {
                  if (hasError) {
                    toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
                  } else {
                    saveAdvSpecs();
                    dispatchAdvSpecState({ type: AdvSpecActionTypes.setAssemblyGroupSectionsMap, payload: buildAssemblyGroupSectionsMap() });
                    navigateToRoute(PageRoutes.AssemblyGroups, history, quote.Id, undefined, lineItemId);
                  }
                }}
                action='assembly-groups'
              />
            </div>
            <div className='title-container'>
              <BreadcrumbNavigation
                breadcrumbItems={[
                  {
                    label: 'Quotes',
                    goTo: () => navigateToRoute(PageRoutes.Quotes, history, quote?.Id, routeChangeAlert),
                  },
                  {
                    label: quote?.Name,
                    goTo: () => {
                      if (hasError) {
                        toast(ToastTypes.Error, ToastMessages.TooManyCharactersUpdate);
                      } else {
                        saveAdvSpecs();
                        navigateToRoute(PageRoutes.QuoteDetail, history, quote.Id);
                      }
                    }
                  },
                  { label: 'Advanced Specifications' },
                ]}
              />
              <div className='metals-scroller-container'>
                <MetalComboScroller
                  displayedQuoteGroup={displayedQuoteGroup}
                  setLineItemId={setLineItemId}
                  style={MetalComboScrollerStyles.Horizontal}
                />
              </div>
            </div>
            <hr />
            <div className='line-items-container'>
              <div className='header-container'>
                <div className='adv-spec-tabs-container'>
                  {/* Map over the section headers and render the tabs */}
                  {Array.from(sectionsMap.keys()).map((tab, index) => (
                    <HeaderTab tab={tab} sectionRefMap={sectionRefMap} QALabel={'AdvSpecPopover'} key={index} />
                  ))}
                </div>
                <div className='header-right-side'>
                  <div className='assembly-toggle-container'>
                    <IonLabel class='assembly-toggle-label'>Specify Per Assembly</IonLabel>
                    <IonToggle
                      class='assembly-toggle'
                      checked={specifyPerAssemblyToggled}
                      onIonChange={(e) => setSpecifyPerAssemblyToggled(e.detail.checked)}
                    />
                  </div>
                  <hr className='header-vert-divider' />
                  {/* adjustment container for uom toggler */}
                  <div style={{margin: '11px 0 0 0'}}>
                    <UomDisplayToggle />
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <div className='adv-spec-selections'>
              <IonGrid>
                {/* Map over the section headers and render the section's component */}
                {Array.from(sectionsMap.keys()).map((tab, idx) => (
                  <MappedSection
                    component={sectionsMap.get(tab)}
                    label={tab}
                    sectionRefMap={sectionRefMap}
                    QALabel={'AdvancedSpecPopover'}
                    index={idx}
                    key={tab}
                  />
                ))}
              </IonGrid>
            </div>
            <div className='cta-button-container'>
              <UpdateButton />
            </div>
            <div />
          </div>
        ) : (
          <div className='adv-specs-loading-container'>
            <Loading message='Loading Advanced Specifications...' data-testId='QAAdvSpecsLoading' />
          </div>
        )}
      </PageLayout>
    );
};

export default AdvSpecsPage;