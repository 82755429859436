import React from 'react';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import { CladProductionSpecifications } from '@constants';

const AppDropdownCladProductionSpecification = (props) => {
  const options = [
    CladProductionSpecifications.OtherManualEntry,
    CladProductionSpecifications.ADW8DMC100,
    CladProductionSpecifications.B432DMC100,
    CladProductionSpecifications.B898DMC100,
    CladProductionSpecifications.DMC100,
    CladProductionSpecifications.SA263DMC100,
    CladProductionSpecifications.SA264DMC100,
    CladProductionSpecifications.SA265DMC100,
    CladProductionSpecifications.CODAPDMC100,
  ].map((item) => {
    return {
      label: item,
      value: item
    };
  });

  return <AppDropdown options={options} {...props}/>
};

export default AppDropdownCladProductionSpecification;