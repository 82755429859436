import { Notification } from '@interfaces/notification';
import { getCall, putCall, deleteCall } from '@services/rest-service';

// Retrieves notifications for the contact from cosmos
export const getNotifications = (): Promise<Notification[]> => {
  return getCall<Notification[]>('notifications');
};

// Updates the notification by id
export const updateNotification = (notification: Notification): Promise<Notification> => {
  return putCall<Notification>(`notifications/${notification.id}`, notification);
};

// Deletes the notification by id
export const deleteNotification = (notificationId: string): Promise<{ success: boolean }> => {
  return deleteCall<{ success: boolean }>(`notifications/${notificationId}`);
};
