import { getBoosterAllowances } from './booster-allowances-api';
import * as React from 'react';
import { BoosterAllowances } from '@interfaces/booster-allowances';

const { createContext, useContext } = React;

const BoosterAllowancesContext = createContext(null);

export const BoosterAllowancesProvider = (props) => {
  const value = {
    getAllBoosterAllowances: props.getAllBoosterAllowances || getAllBoosterAllowances,
  };

  return (
    <BoosterAllowancesContext.Provider value={value}>
      {props.children}
    </BoosterAllowancesContext.Provider>
  );
};

export const useBoosterAllowances = () => {
  return useContext(BoosterAllowancesContext);
};

// Calls api to get all the booster allowances from cosmos
export const getAllBoosterAllowances = async(): Promise<BoosterAllowances[]> => {
  try {
    return await getBoosterAllowances();
  } catch (error) {
    console.error(error);
    return null;
  }
};
