import { CertificationsPageColumnHeadings, ToastMessages, ToastTypes } from '@constants';
import { IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import classNames from 'classnames';
import { chevronDown, chevronUp } from 'ionicons/icons';
import * as React from 'react';
import './certifications.scss';
import Footer from '@components/footer/footer';
import { usePlatform } from '@services/platform-service';
import EmptyTableRow from '@components/empty-table-row/empty-table-row';
import pdfWhite from '@assets/icon/filetype_PDF_white.svg';
import pdfBlack from '@assets/icon/filetype_PDF.svg';
import download from '@assets/icon/pdf-download.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { environment } from '@environment';
import { useToast } from '@hooks/useToast';

const CertificationsPage = () => {
  const platform = usePlatform();
  const { darkMode } = useDarkMode();
  const toast = useToast(4000);

  const certificationsList = [
    {
      Name: 'Liebenscheid_AD2000-W0 Manufacturer Certificate',
      Location: 'Liebenscheid',
      url: `${environment.CERTIFICATIONS_BASE_URL}/Liebenscheid_AD2000-W0 Manufacturer Certificate`
    },
    {
      Name: 'Liebenscheid_Al to Steel TJ Certificate Bureau Veritas',
      Location: 'Liebenscheid',
      url: `${environment.CERTIFICATIONS_BASE_URL}/Liebenscheid_Al to Steel TJ Certificate Bureau Veritas`
    },
    {
      Name: 'Liebenscheid_Al to Steel TJ Certificate Lloyds Register',
      Location: 'Liebenscheid',
      url: `${environment.CERTIFICATIONS_BASE_URL}/Liebenscheid_Al to Steel TJ Certificate Lloyds Register`
    },
    {
      Name: 'Liebenscheid_EN15085-2 Certificate',
      Location: 'Liebenscheid',
      url: `${environment.CERTIFICATIONS_BASE_URL}/Liebenscheid_EN15085-2 Certificate`
    },
    {
      Name: 'Liebenscheid_ISO3834-2 Certificate',
      Location: 'Liebenscheid',
      url: `${environment.CERTIFICATIONS_BASE_URL}/Liebenscheid_ISO3834-2 Certificate`
    },
    {
      Name: 'Liebenscheid_ISO9001 Certificate',
      Location: 'Liebenscheid',
      url: `${environment.CERTIFICATIONS_BASE_URL}/Liebenscheid_ISO9001 Certificate`
    },
    {
      Name: 'Mt Braddock_ABS MA Exp 2024',
      Location: 'Mount Braddock',
      url: `${environment.CERTIFICATIONS_BASE_URL}/Mt Braddock_ABS MA Exp 2024`
    },
    {
      Name: 'Mt Braddock_ABS PDA 21',
      Location: 'Mount Braddock',
      url: `${environment.CERTIFICATIONS_BASE_URL}/Mt Braddock_ABS PDA 21`
    },
    {
      Name: 'Mt Braddock_ASME PRT',
      Location: 'Mount Braddock',
      url: `${environment.CERTIFICATIONS_BASE_URL}/Mt Braddock_ASME PRT`
    },
    {
      Name: 'Mt Braddock_ISO 9001',
      Location: 'Mount Braddock',
      url: `${environment.CERTIFICATIONS_BASE_URL}/Mt Braddock_Lloyds Register`
    }
  ];

  const [sortColumnHeading, setSortColumnHeading] = React.useState(CertificationsPageColumnHeadings.Location);
  const [sortColumnId, setSortColumnId] = React.useState(undefined);
  const [sortAsc, setSortAsc] = React.useState(true);
  const [certifications, setCertifications] = React.useState(certificationsList);

  React.useEffect(() => {
    sortItems();
  }, [sortColumnId, sortAsc]);

  // Sorts the certificiations according to the sort column
  const sortItems = async () => {
    if(certifications) {
      let updatedItems = [...certifications];
      updatedItems = updatedItems?.orderBy(item => item[sortColumnId], sortAsc);

      setCertifications(updatedItems);
    }
  };

  // Handler for when a certification header column is selected
  const selectColumnHandler = (column: CertificationsPageColumnHeadings) => {
    setSortColumnHeading(column);
    
    if(sortColumnId === column) {
      setSortAsc(!sortAsc);
    } else {
      setSortColumnId(column);
    }
  };

  // Returns proper column header class based on whether the column is sorted or not
  const getColHeaderClass = (column: CertificationsPageColumnHeadings) => {
    return (column === sortColumnHeading) ? 'certifications-text text-bold' : 'certifications-text';
  };

  // Returns proper column header icon based on whether the column is sorted or not
  const getColHeaderIcon = (column: CertificationsPageColumnHeadings) => {
    return (column === sortColumnHeading && sortAsc) ? chevronUp : chevronDown;
  };

  const CertificationsLine = (props) => {
    // Gets back the blob from the pdf source and creates a link to allow for download from page
    const downloadPDF = async (certification) => {
      try {
        const response = await fetch(certification.url);
        const blob = await response.blob();
        const dataUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
  
        link.href = dataUrl;
        link.download = `${certification.Name}.pdf`;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch(err) {
        toast(ToastTypes.Error, ToastMessages.DownloadFailed);
        console.log(err);
      }
    };

    return (
      <IonCard className="certification-card" data-testid={'QACertificationsPageCertificationsLineItem_' + props.certification.Name}>
        <IonCardHeader class={classNames({ 'certification-card-header': true, 'even': props.certificationIndex % 2 === 0, 'odd': props.certificationIndex % 2 !== 0 })}>
          <IonGrid>
            <IonRow class="certification-row">
              <IonCol class="column-left name-col" size={ !platform.isMobile() ? (!platform.isTablet() ? '10' : '2') : '11' }>
                <span className="certifications-text mobile-only name-label">Name</span>
                <img className="pdf-icon" src={ darkMode ? pdfWhite : pdfBlack }/>
                <span className="certification-text" data-testid="QACertificationsPageCertificationsLineItemName">{ props.certification.Name }</span>
              </IonCol>
              <IonCol class="column-left location-col" size={ !platform.isMobile() ? (!platform.isTablet() ? '2' : '2') : '11' }>
                <span className="certifications-text mobile-only location-label">Location</span>
                <span className="certification-text" data-testid="QACertificationsPageCertificationsLineItemLocation">{ props.certification.Location }</span>
              </IonCol>
              <div className="download-container" data-testid="QACertificationsPageCertificationsLineItemDownloadIcon">
                <IonIcon className='download-icon' icon={download} onClick={() => downloadPDF(props.certification)}>
                </IonIcon>
              </div>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
      </IonCard>
    );
  };

  const CertificationsList = () => {
    return ( certifications?.length ?
      <>{certifications?.map((certification, index) => (
        <CertificationsLine certification={certification} certificationIndex={index} key={index}/>
      ))}</> :
      <EmptyTableRow text={'No Certifications Available'}/>
    );
  };

  return (
    <IonContent class="certifications-page">
      <div className={classNames({
          'max-width-container': true,
          'certifications-wrapper': true
        })}
      >
        <IonLabel class="certifications-title" data-testid="QACertificationsPageTitle">Certifications</IonLabel>
        <hr/>
        <IonCard class="certifications-card">
          <IonCardHeader class="certifications-card-header">
            <IonGrid>
              <IonRow class="certifications-row">
                <IonCol class="certifications-col name-col" data-testid="QACertificationsPageNameColumnHeader" size={ !platform.isMobile() ? (!platform.isTablet() ? '10' : '2') : '11' }>
                  <span className={getColHeaderClass(CertificationsPageColumnHeadings.Name)}>{ CertificationsPageColumnHeadings.Name }</span>
                </IonCol>
                <IonCol class="certifications-col location-col" onClick={() => selectColumnHandler(CertificationsPageColumnHeadings.Location)} data-testid="QACertificationsPageLocationColumnHeader" size={ !platform.isMobile() ? (!platform.isTablet() ? '2' : '2') : '11' }>
                  <span className={getColHeaderClass(CertificationsPageColumnHeadings.Location)}>{ CertificationsPageColumnHeadings.Location }</span>
                  <IonIcon icon={getColHeaderIcon(CertificationsPageColumnHeadings.Location)}></IonIcon>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <CertificationsList/>
        <hr className='bottom-rule'/>
      </div>
      <div className="footer" data-testid="QACertificationsPageFooterContainer">
        <Footer/>
      </div>
    </IonContent>
  );
};

export default CertificationsPage;