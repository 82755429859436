import { TimeIntervals } from '@constants';
import { timeBetweenDates } from '@shared/utils';
import { Notification } from '@interfaces/notification';
import moment from 'moment';

// Maps the notification so that the UI can consume it
export const mapNotification = (notification: Notification) => {
  const currentDate = new Date();
  const createdDate = new Date(notification.createdDateTime);

  let timestamp: string;

  if (timeBetweenDates(createdDate, currentDate, TimeIntervals.Hours) === 0) {
    const minutesDifference = timeBetweenDates(createdDate, currentDate, TimeIntervals.Minutes);
    timestamp = minutesDifference + (minutesDifference > 1 ? ' minutes ' : ' minute ') + 'ago';
  } else if (timeBetweenDates(createdDate, currentDate, TimeIntervals.Days) === 0) {
    const hoursDifference = timeBetweenDates(createdDate, currentDate, TimeIntervals.Hours);
    timestamp = hoursDifference + (hoursDifference > 1 ? ' hours ' : ' hour ') + 'ago';
  } else if (timeBetweenDates(createdDate, currentDate, TimeIntervals.Weeks) === 0) {
    const daysDifference = timeBetweenDates(createdDate, currentDate, TimeIntervals.Days);
    timestamp = daysDifference + (daysDifference > 1 ? ' days ' : ' day ') + 'ago';
  } else {
    timestamp = moment(notification.createdDateTime).format('L');
  }

  return {
    ...notification,
    timestamp,
  };
};
