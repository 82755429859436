import { deleteNotification, getNotifications, updateNotification } from './notifications-api';
import * as React from 'react';
import { Notification } from '@interfaces/notification';
import { BehaviorSubject, Observable } from 'rxjs';

const { createContext, useContext } = React;

const NotificationsContext = createContext(null);

export const NotificationsProvider = (props) => {
  const value = {
    getContactNotifications: props.getContactNotifications || getContactNotifications,
    updateContactNotification: props.updateContactNotification || updateContactNotification,
    deleteContactNotification: props.deleteContactNotification || deleteContactNotification,
    notificationViewed: props.notificationViewed || notificationViewed,
    notificationViewed$: props.notificationViewed$ || notificationViewed$
  };

  return (
    <NotificationsContext.Provider value={value}>
      {props.children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  return useContext(NotificationsContext);
};

export const notificationViewed = new BehaviorSubject<string>(null);

export const notificationViewed$ = (): Observable<string> => {
  return notificationViewed.asObservable();
};

// Calls api to get notifications from cosmos
export const getContactNotifications = async(): Promise<Notification[]> => {
  try {
    return await getNotifications();
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Calls api to update notification for contact in cosmos
export const updateContactNotification = async(notification: Notification): Promise<Notification> => {
  try {
    return await updateNotification(notification);
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Calls api to delete notification for contact in cosmos
export const deleteContactNotification = async(notificationId: string): Promise<{ success: boolean }> => {
  try {
    return await deleteNotification(notificationId);
  } catch (error) {
    console.error(error);
    return null;
  }
};
