import React from 'react';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import { HardnessMetalSurfaces } from '@constants';

const AppDropdownHardnessMetalSurface = (props) => {
  const options = [
    HardnessMetalSurfaces.None,
    HardnessMetalSurfaces.RBRockwellB,
    HardnessMetalSurfaces.RCRockwellC,
    HardnessMetalSurfaces.Brinell,
    HardnessMetalSurfaces.HVVickers,
    HardnessMetalSurfaces.Other,
  ].map((item) => {
    return {
      label: item,
      value: item
    };
  });

  return <AppDropdown options={options} {...props}/>
};

export default AppDropdownHardnessMetalSurface;