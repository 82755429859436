import { useToastDispatchContext } from '@services/toast-service';

export const useToast = (delay) => {
  const dispatch = useToastDispatchContext();

  const toast = (type, message) => {
    const id = Math.random().toString(36).substring(2, 9);
    dispatch({
      type: 'ADD_TOAST',
      toast: {
        type,
        message,
        id,
      },
    });

    setTimeout(() => {
      dispatch({ type: 'DELETE_TOAST', id });
    }, delay);
  };

  return toast;
};