import Topbar from '@components/topbar/topbar';
import { Invoice } from '@interfaces/invoice';
import { Order } from '@interfaces/order';
import { IonHeader } from '@ionic/react';
import * as React from 'react';
import { Notification } from '@interfaces/notification';
import { MappedNotification } from '@shared/types';
interface Props {
  orders: Order[];
  orderSearchValues: any[];
  invoices: Invoice[];
  invoiceSearchValues: any[];
  unmappedNotifications: Notification[];
  finalizedNotifications: MappedNotification[];
  loadNotifications: () => void;
}

export const HeaderContainer = (props: Props) => {
  return (
    <IonHeader data-testid='QAAppHeader'>
      <Topbar
        orders={props.orders}
        orderSearchValues={props.orderSearchValues}
        invoices={props.invoices}
        invoiceSearchValues={props.invoiceSearchValues}
        unmappedNotifications={props.unmappedNotifications}
        finalizedNotifications={props.finalizedNotifications}
        loadNotifications={props.loadNotifications}
      />
    </IonHeader>
  );
};
