import { ManufacturingSites, MeasurementSystems, MetalProposalTypes, RawMaterialUsage, Units } from '@constants';
import { RawMaterial } from '@interfaces/raw-material';
import { calculateUOMValue } from '@shared/quote-utils';
import { MultiShotState } from './useMultiShotReducer';

// getReverseIndex - this is used to get the reverse index
export const getReverseIndex = <T extends unknown>(arr: T[], index: number): number => {
  return arr.length - 1 - index;
};

export const getImperialValue = (value: number, uom: MeasurementSystems) => {
  return calculateUOMValue(uom, MeasurementSystems.Imperial, value, Units.Millimeters, Units.Inches);
};

export const getMetricValue = (value: number, uom: MeasurementSystems) => {
  return calculateUOMValue(uom, MeasurementSystems.Metric, value, Units.Millimeters, Units.Inches);
};

const findMetalByName = (metalName: string, rawMaterials: RawMaterial[]): RawMaterial => {
  return rawMaterials?.find((metal) => metal.name === metalName);
};

export const getProposalTypeOptionsByMetalName = (
  metalName: string,
  rawMaterials: RawMaterial[],
  manufacturingSite: ManufacturingSites
): MetalProposalTypes[] => {
  const metal = findMetalByName(metalName, rawMaterials);
  return metal?.metalPricing?.[manufacturingSite]?.map(
    (metalPricing) => metalPricing.proposalType
  ) as MetalProposalTypes[];
};

export const getBaseOrCladMetalOptions = (
  rawMaterials: RawMaterial[],
  baseOrClad: RawMaterialUsage,
  manufacturingSite: ManufacturingSites
) => {
  return rawMaterials.filter(
    (rawMaterial) => rawMaterial.usage === baseOrClad && rawMaterial.dataAreaId.includes(manufacturingSite)
  );
};

const hasData = (obj: any): boolean => {
  return Object.values(obj).every((val) => {
    if (Array.isArray(val)) {
      return val.every((arrVal) => hasData(arrVal));
    }
    return val !== null && val !== undefined && val !== 0 && val !== '';
  });
}

export const isMultiShotStateValid = (msState: MultiShotState): boolean => {
  return hasData(msState);
};
