import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';
import BreadcrumbNavigation from '@components/breadcrumb-navigation/breadcrumb-navigation';
import ComboGroupLayout from '@components/page/combo-group-layout';
import UOMToggle from '@components/uom-toggle/uom-toggle';
import PageFloatingAction from './page-floating-actions/page-floating-actions';
import { PageRoutes, QuoteActions } from '@constants';
import { navigateToRoute } from '@shared/utils';

import './assembly-groups.scss';
import PanelList from './panel-list/panel-list';
import { useQuoteState } from '@context/quote-context';
import { useQuoteFormContext } from '@context/quote-form-context';
import { IonButton } from '@ionic/react';

const NewAssemblyGroupsPage = () => {
  const history = useHistory();
  const {
    quoteState,
    saveQuote,
    syncFormQuoteLineItem
  } = useQuoteState();
  const {
    onResolveQuoteLineItems,
    setQuoteLineItems,
  } = useQuoteFormContext();
  const { quote } = quoteState;
  const [
    isSaving,
    setIsSaving
  ] = useState(false);

  const breadcrumbsItems = [
    {
      label: 'Quotes',
      goTo: () => {
        syncFormQuoteLineItem(onResolveQuoteLineItems());
        navigateToRoute(PageRoutes.Quotes, history, quote.Id);
        setQuoteLineItems([]);
      },
    },
    {
      label: quote?.Name,
      goTo: () => {
        syncFormQuoteLineItem(onResolveQuoteLineItems());
        navigateToRoute(PageRoutes.QuoteDetail, history, quote.Id);
      },
    },
    { label: 'Advanced Specifications' ,
      goTo: () => {
        syncFormQuoteLineItem(onResolveQuoteLineItems());
        navigateToRoute(PageRoutes.AdvancedSpecifications, history, quote.Id);
      },
    },
    { label: 'Assembly Groups' }
  ];

  const headerComponents = [
    {
      key: 'ass-grp--breadcrumbs',
      size: '10',
      component: <BreadcrumbNavigation breadcrumbItems={breadcrumbsItems}/>
    },
    {
      key: 'ass-grp--uom',
      size: '2',
      style: { display: 'flex', justifyContent: 'right', alignItems: 'center' },
      component: <UOMToggle/>
    }
  ];

  return <ComboGroupLayout className='assembly-groups-page' loading={isSaving}>
    <PageFloatingAction/>
    <AppLineItemColumns
      className='assembly-group--header'
      components={headerComponents}
    />
    <PanelList/>
    <div className='button-container'>
      <IonButton
        color='primary'
        class='save-button'
        onClick={() => {
          const newQuote = syncFormQuoteLineItem(onResolveQuoteLineItems());

          setIsSaving(true);

          // i know dont look at me
          // just trying to reuse this thing
          saveQuote(quote.Id, QuoteActions.Save, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, newQuote )
            .finally(() => {
              setIsSaving(false);
            });
        }}
      >
        Save
      </IonButton>
    </div>
  </ComboGroupLayout>;
};

export default NewAssemblyGroupsPage;