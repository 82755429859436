import { MutableRefObject, useEffect, useRef } from 'react';

// a custom React hook called useLockOnWindowEvents that takes in two parameters, `setLocked` and `eventThreshold`.
// It keeps track of the number of mouse clicks and/or keydown events on the window, with a default threshold number of allowable events.
// The user has threshold number of events before the functionality is removed.
const useLockOnWindowEvents = (setLocked: (isLocked: boolean) => void, eventThreshhold = 5) => {
  const clickCountRef: MutableRefObject<number> = useRef(0);
  
  useEffect(() => {
    const onLock = () => {
      clickCountRef.current += 1;
      if (clickCountRef.current > eventThreshhold) {
        setLocked(true);
        removeListeners(onLock);
        clickCountRef.current = 0;
      }
    };
    
      addListeners(onLock);
    return () => {
      removeListeners(onLock);
    };
  }, [setLocked]);
};

const addListeners = (onLock: () => void) => {
  window.addEventListener('mousedown', onLock);
  window.addEventListener('keydown', onLock);
};

const removeListeners = (onLock: () => void) => {
  window.removeEventListener('mousedown', onLock);
  window.removeEventListener('keydown', onLock);
};

export default useLockOnWindowEvents;
