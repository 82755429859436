import Account from '@interfaces/account';
import { getCall as get, putCall as put, postCall as post } from '@services/rest-service';
import Contact from '@interfaces/contact';
import { Order } from '@interfaces/order';
import { NCLineItem } from '@interfaces/nc-line-item';
import { ProjectTeam } from '@interfaces/project-team';
import { Invoice } from '@interfaces/invoice';
import { DocumentData } from '@interfaces/document-data';
import { Quote } from '@interfaces/quote';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';

// Gets the salesforce contact based on specified contact id
export async function getContact(contactId: string): Promise<Contact> {
  return get<Contact>(`salesforce/contact/${contactId}`);
}

// Gets the salesforce account based on specified account id
export async function getAccount(id: string): Promise<Account> {
  return get<Account>(`salesforce/accounts/${id}`);
}

// Gets the salesforce orders
export async function getOrders(limit?: number): Promise<Order[]> {
  return get<Order[]>(limit ? `salesforce/orders?limit=${limit}` : 'salesforce/orders');
}

// Gets the salesforce order search values
export async function getOrderSearchValues(): Promise<Order[]> {
  return get<Order[]>('salesforce/search/orders');
}

// Gets the salesforce order for the specified order id
export async function getOrderById(orderId: string): Promise<Order> {
  return get<Order>(`salesforce/orders/${orderId}`);
}

// Saves the order
export async function updateOrderById(orderId: string, order: Order): Promise<any> {
  return put<any>(`salesforce/orders/${orderId}`, order);
}

// Gets the salesforce order line items for the specified order
export async function getOrderLineItems(orderId: string): Promise<NCLineItem[]> {
  return get<NCLineItem[]>(`salesforce/orders/items/${orderId}`);
}

// Gets the salesforce documents for the specified order
export async function getOrderDocuments(orderId: string): Promise<DocumentData[]> {
  return get<DocumentData[]>(`salesforce/orders/documents/${orderId}`);
}

// Gets the salesforce account team
export async function getAccountTeam(): Promise<ProjectTeam[]> {
  return get<ProjectTeam[]>('salesforce/account-team');
}

// Gets the salesforce invoices
export async function getInvoices(limit?: number): Promise<Invoice[]> {
  return get<Invoice[]>(limit ? `salesforce/invoices?limit=${limit}` : 'salesforce/invoices');
}

// Gets the salesforce invoices search values
export async function getInvoiceSearchValues(): Promise<Invoice[]> {
  return get<Invoice[]>('salesforce/search/invoices');
}

// Gets the salesforce invoice for the specified invoice id
export async function getInvoiceById(invoiceId: string): Promise<Invoice> {
  return get<Invoice>(`salesforce/invoices/${invoiceId}`);
}

// Gets the salesforce invoice line items for the specified invoice
export async function getInvoiceLineItems(invoiceId: string): Promise<NCLineItem[]> {
  return get<NCLineItem[]>(`salesforce/invoices/items/${invoiceId}`);
}

// Gets the salesforce documents for the specified invoice
export async function getInvoiceDocuments(invoiceId: string): Promise<DocumentData[]> {
  return get<DocumentData[]>(`salesforce/invoices/documents/${invoiceId}`);
}

// Gets the salesforce quote group for the specified quote id
export async function getQuoteGroupByQuoteId(quoteId: string): Promise<Quote> {
  return get<Quote>(`salesforce/quotegroup/${quoteId}`);//TODO: use a Tyoe for this
}

// Gets the salesforce quote group details for the specified array of quote item ids
export async function getQuoteGroupDetailsByItemIds(itemIds: string): Promise<Quote> { //TODO: learn how to send an array of strings
  return get<Quote>(`salesforce/quotegroup/details/${itemIds}`);//TODO: use a Tyoe for this
}

// Gets the salesforce quote for the specified quote id
export async function getQuoteById(quoteId: string): Promise<Quote> {
  return get<Quote>(`salesforce/quotes/${quoteId}`);
}

// Gets the salesforce quote line items for the specified quote
export async function getQuoteLineItems(quoteId: string): Promise<QuoteLineItem[]> {
  return get<QuoteLineItem[]>(`salesforce/quotes/items/${quoteId}`);
}

// Gets the salesforce quote line item details for the specified quote line item
export async function getQuoteLineItemDetails(quoteLineItemId: string): Promise<QuoteLineItemDetail[]> {
  return get<any[]>(`salesforce/quotes/items/details/${quoteLineItemId}`);
}

// Saves the quote
export async function updateQuoteById(quoteId: string, quoteMap: any): Promise<any> {
  return put<any>(`salesforce/quotes/${quoteId}`, quoteMap);
}

// Clones the quote
export async function cloneQuoteById(quoteId: string): Promise<any> {
  return post<any>(`salesforce/quotes/clone/${quoteId}`);
}

// Saves the full { quote, items, and details } to quoteData graph composite api call
export async function updateQuoteDataById(quoteId: string, quoteData: any): Promise<any> {
  return post<any>(`quotedata/${quoteId}`, quoteData);
}

// Saves the full { quote, items, and details } using salesforce clone endpoint api call
export async function updateFullQuoteDataById(quoteId: string, quoteData: any): Promise<any> {
  return put<any>(`salesforce/quotegroup/full/${quoteId}`, quoteData);
}

// update / sync status from salesforce to cosmos
export async function updateQuoteStatus(quoteId: string, quoteStatus: any): Promise<any> {
  return put<any>(`salesforce/quotes/${quoteId}/status`, {
    status: quoteStatus
  });
}