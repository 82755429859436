import { AdvSpecOptions, CladProductionSpecifications, CustomerSpecifiedImpactTestDirections, ManufacturingSites, MeasurementSystems, PostCladTestingOptions, QuoteLineItemDependentUnwrittenFields, QuoteLineItemFields, QuoteLineItemValueTypes } from '@constants';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { RawMaterial } from '@interfaces/raw-material';
import { SpecificationTestOptions } from '@interfaces/specification-test-options';
import { addFieldToMapAndUpdatedList, addFieldToMapAndUpdatedListPerAssembly, detailFieldIsGlobal, postCladTestingOptionIncluded, postCladTestingOptionIsGlobal, postCladTestingOptionSelected } from '@shared/quote-utils';
import { AdvSpecMetalComboSelections, AdvSpecsAssemblyGroupDetailFieldsToUpdate, AdvSpecsAssemblyGroupLineFieldsToUpdate, AdvSpecsAssemblyGroupsCylinderItemDetailFieldsToUpdate, AdvSpecsAssemblyGroupsHeadFieldsToUpdate, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate, AdvSpecsMetalComboDetailFieldsToUpdate, AdvSpecsMetalComboLineFieldsToUpdate, DependentUnwrittenFieldsMapValueType, FieldsMapValueType, GCAMetalComboLineFieldsToUpdate, MetalComboTotalWeights, OrderEntryLineItemFieldsToUpdate } from './types';

// QuoteGroup is a collection of QuoteLineItems and QuoteLineItemDetails that are related to a single quote.
// It has a map of Quote Line Items to Quote Line Item Details, and a map of metal combinations to Quote Line Items.
export class QuoteGroup {
  public quoteLineItems: QuoteLineItem[];
  public quoteLineItemDetails: QuoteLineItemDetail[];

  // map for mapping a quote line item detail to its correlating quote line item
  public qlid2qliMap: Map<QuoteLineItemDetail, QuoteLineItem>;
  // map of metal combos with the keys as the metal combo strings and values of their correlating Quote Line Items array that share that metal combo as values
  public metalComboToQlisMap: Map<string, QuoteLineItem[]>;
  // map of metal combos with the keys as the metal combo strings and values of their correlating Quote Line Item Details array that share that metal combo as values
  public metalComboToQlidsMap: Map<string, QuoteLineItemDetail[]>;

  constructor(quoteLineItems: QuoteLineItem[], quoteLineItemDetails: QuoteLineItemDetail[]) {
    this.quoteLineItems = quoteLineItems;
    this.quoteLineItemDetails = quoteLineItemDetails;

    this.qlid2qliMap = buildQuoteLineItemDetailToQuoteLineItemMap(this.quoteLineItems);
    this.metalComboToQlisMap = buildMetalComboToQuoteLineItemMap(this.quoteLineItems);
    this.metalComboToQlidsMap = buildMetalComboToQuoteLineItemDetailsMap(this.metalComboToQlisMap);
  }
}

/* DisplayedQuoteGroup is a collection of QuoteLineItems and QuoteLineItemDetails that are related to a single quote id
 * that are represented and displayed on the screen. It contains a mapping of related quote line items to their correlating quote line item details,
 * and a mapping of metal combos with the keys as the metal combo strings and values of their correlating Quote Line Items array that share that metal combo as values.
 */
export class DisplayedQuoteGroup extends QuoteGroup {
  // these are the quote line item and quote line item detail pair being displayed on the screen
  public qliDisplayed: QuoteLineItem;
  public qlidDisplayed: QuoteLineItemDetail;
  public displayedLineItemId: string;
  // this is an array of quote line items that share the same metal combo as the quote line item displayed on the screen
  public allQlisMappedToMetalCombo: QuoteLineItem[];
  // this is an array of quote line item details that share the same metal combo as the quote line item detail displayed on the screen
  public allQlidsMappedToMetalCombo: QuoteLineItemDetail[];
  // this is an array of quote ids/display ids (possibly used for saving instead of using QuoteLineItems)
  public allQlisIdsMappedToMetalCombo: string[];

  // this is the json string used as a key for metal combos in the metal combo to quote line items map
  public metalComboKey: string;
  public metalGroupIndex: number;
  public baseMetalShortName: string;
  public cladMetalShortName: string;
  public baseMetalLongName: string;
  public cladMetalLongName: string;
  public baseMetalMaterial: RawMaterial;
  public cladMetalMaterial: RawMaterial;

  constructor(
    quoteLineItems: QuoteLineItem[],
    quoteLineItemDetails: QuoteLineItemDetail[],
    displayedLineItemId: string
  ) {
    super(quoteLineItems, quoteLineItemDetails);
    // displayed line item by supplied id
    this.displayedLineItemId = displayedLineItemId;
    this.qliDisplayed =
      this.quoteLineItems?.find((qli: QuoteLineItem) =>
        qli.Id ? qli.Id === this.displayedLineItemId : qli.displayId === this.displayedLineItemId
      ) || (this.quoteLineItems || [])[0]; // default case
    this.qlidDisplayed = this.qliDisplayed?.quoteLineItemDetail;

    this.baseMetalShortName = this.qliDisplayed?.BaseMetal__c;
    this.cladMetalShortName = this.qliDisplayed?.CladMetal__c;
    this.baseMetalLongName = this.qlidDisplayed?.BaseMetal__c;
    this.cladMetalLongName = this.qlidDisplayed?.CladMetal__c;
    this.metalComboKey = JSON.stringify({ base: this.baseMetalShortName, clad: this.cladMetalShortName });

    this.allQlisMappedToMetalCombo = this.metalComboToQlisMap.get(this.metalComboKey);
    this.allQlisIdsMappedToMetalCombo = this.allQlisMappedToMetalCombo?.map((qli: QuoteLineItem) =>
      qli.Id ? qli.Id : qli.displayId
    );

    this.allQlidsMappedToMetalCombo = this.metalComboToQlidsMap.get(this.metalComboKey);
    this.metalGroupIndex =
      [...this.metalComboToQlisMap.keys()].findIndex((metalsString) => metalsString === this.metalComboKey) + 1;
  }

  /**
   * Return an Object of a Quote Line Item and a Quote Line Item Detail that are the pair being displayed,
   * from a supplied Quote Line Item id (or display id)
   * @return { { qli: QuoteLineItem; qlid: QuoteLineItemDetail }>}
   */
  getDisplayedQuoteLines(): { qli: QuoteLineItem; qlid: QuoteLineItemDetail } {
    return { qli: this.qliDisplayed, qlid: this.qlidDisplayed };
  }

  // set the new displays when changing the displayed line item id (or display id)
  // used to change the group display instead of creating a new group with the new id
  setDisplayedQuoteLines(displayedLineItemId: string) {
    this.displayedLineItemId = displayedLineItemId;
    this.qliDisplayed =
      this.quoteLineItems?.find((qli: QuoteLineItem) =>
        qli.Id ? qli.Id === this.displayedLineItemId : qli.displayId === this.displayedLineItemId
      ) || (this.quoteLineItems || [])[0]; // default case
    this.qlidDisplayed = this.qliDisplayed?.quoteLineItemDetail;

    this.baseMetalShortName = this.qliDisplayed?.BaseMetal__c;
    this.cladMetalShortName = this.qliDisplayed?.CladMetal__c;
    this.baseMetalLongName = this.qlidDisplayed?.BaseMetal__c;
    this.cladMetalLongName = this.qlidDisplayed?.CladMetal__c;
    this.metalComboKey = JSON.stringify({ base: this.baseMetalShortName, clad: this.cladMetalShortName });

    this.allQlisMappedToMetalCombo = this.metalComboToQlisMap.get(this.metalComboKey);
    this.allQlisIdsMappedToMetalCombo = this.allQlisMappedToMetalCombo?.map((qli: QuoteLineItem) =>
      qli.Id ? qli.Id : qli.displayId
    );

    this.allQlidsMappedToMetalCombo = this.metalComboToQlidsMap.get(this.metalComboKey);
  }
}

/**
 * Build a Map for the quote line item detail to quote line item
 * from an array of QuoteLineItems and QuoteLineItemDetails
 * Used to get the correlating QuoteLineItem for a given QuoteLineItemDetail
 * One to One relationship
 * alternative to this: see the QuoteLineItemDetail's NC_Quote_Line_Item__c?: string;
 * @return {Map<QuoteLineItemDetail, QuoteLineItem>}
 */
export const buildQuoteLineItemDetailToQuoteLineItemMap = (
  qlis: QuoteLineItem[],
): Map<QuoteLineItemDetail, QuoteLineItem> => {
  const qlid2qliMap = new Map<QuoteLineItemDetail, QuoteLineItem>();
  qlis?.forEach((qli: QuoteLineItem) => {
    qlid2qliMap.set(
      qli.quoteLineItemDetail,
      qli
    );
  });
  return qlid2qliMap as Map<QuoteLineItemDetail, QuoteLineItem>;
};

/**
 * Gets a QuoteLineItem by the line number from an array of QuoteLineItems
 * @return {QuoteLineItem}
 */
export const getQuoteLineItemByLineNumber = (lineNumber: string, qlis: QuoteLineItem[]): QuoteLineItem => {
  return qlis.find((qli) => qli.Line__c === lineNumber);
};

/**
 * Gets a QuoteLineItemDetail from an array of correlating QuoteLineItemDetails
 * by looking for a matching displayId
 * TODO: Need to add displayId to type QuoteLineItemDetail, defaulting to first item in array
 * need to add displayId to sizing-statistics-popover.tsx to state init and useEffect
 * sizing stats uses this and should be updated eventually
 * @return {QuoteLineItemDetail}
 */
//prettier-ignore
export const getQLIDToDisplayByDisplayId = (qlids: QuoteLineItemDetail[] /*, displayId:string*/): QuoteLineItemDetail => {
  // const possibleReturn = qlids.find(qlid => qlid.displayId ? qlid.displayId === displayId : false);
  return (qlids || [])[0];
};

// build map of metal combos with keys as the metal combo and values as the quote line items that have that metal combo
export const buildMetalComboToQuoteLineItemMap = (
  qlis: QuoteLineItem[]
): Map<string, QuoteLineItem[]> => {
  const metalComboToQuoteItemMap = new Map<string, QuoteLineItem[]>();
  qlis.forEach((qli: QuoteLineItem) => {
    const metalCombo = { base: qli[QuoteLineItemFields.BaseMetal], clad: qli[QuoteLineItemFields.CladMetal] };
    const jsonMetalCombo = JSON.stringify(metalCombo);
    if (!metalComboToQuoteItemMap.has(jsonMetalCombo)) {
      //metal combo does not already exist, set it
      metalComboToQuoteItemMap.set(jsonMetalCombo, [qli]);
    } else {
      //metal combo already exists, add the qli to any existing qli's
      metalComboToQuoteItemMap.set(jsonMetalCombo, [...metalComboToQuoteItemMap.get(jsonMetalCombo), ...[qli]]);
    }
  });
  return metalComboToQuoteItemMap;
};

// build map of metal combos with keys as the metal combo and values as the quote line item details that have that metal combo
export const buildMetalComboToQuoteLineItemDetailsMap = (
  metalComboToQlisMap: Map<string, QuoteLineItem[]>
): Map<string, QuoteLineItemDetail[]> => {
  const metalComboToQuoteItemDetailMap = new Map<string, QuoteLineItemDetail[]>();
  Array.from(metalComboToQlisMap.keys()).forEach((metalCombo) => {
    const qlisForMetalCombo = metalComboToQlisMap.get(metalCombo);

    const qlidsForMetalCombo: QuoteLineItemDetail[] = [];

    qlisForMetalCombo.forEach((qli) => {
      qlidsForMetalCombo.push(qli.quoteLineItemDetail);
    });

    metalComboToQuoteItemDetailMap.set(metalCombo, qlidsForMetalCombo);
  });

  return metalComboToQuoteItemDetailMap;
};

//Builds the specify per assembly map for the metal combos
export const buildSpecifyPerAssemblyMap = (
  displayedQuoteGroup: DisplayedQuoteGroup
): { [key: string]: { [key: string]: boolean } } => {
  const specifyPerAssemblyMap: { [key: string]: { [key: string]: boolean } } = {};

  Array.from(displayedQuoteGroup.metalComboToQlisMap.keys()).forEach((metalCombo) => {
    // prettier-ignore
    specifyPerAssemblyMap[metalCombo] = {
      additionalRequirementsBaseMetal: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.AdditionalRequirementsBaseMetal),
      additionalRequirementsCladdingMetal: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.AdditionalRequirementsCladdingMetal),
      additionalRequirementsCladProductTesting: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.AdditionalRequirementsCladProductTesting),
      additionalRequirementsPrewelding: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.AdditionalRequirementsPrewelding),
      austeniticGrainSizeFinerThanValue: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.AusteniticGrainSizeFinerThanValue),
      baseMetalProductAnalysisRequired: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.BaseMetalProductAnalysisRequired) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.BaseMetalProductAnalysisRequiredNotes) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.BaseMetalProductAnalysisType),
      baseMetalSpecEdAndAd: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.BaseMetalSpecAddendum) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.BaseMetalSpecEdition),
      baseTensileTestLocation: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.BaseTensileTestLocation),
      calciumTreatedSulfideShapeControl: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CalciumTreatedSulfideShapeControl),
      carbonEquivalencyPercent: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CarbonEquivalencyPercent),
      carbonPercent: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CarbonPercent),
      certificationTypeRequired: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CertificationTypeRequired),
      claddingForCorrosionAllowanceOnly: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CladdingForCorrosionAllowanceOnly),
      cladMetalProductAnalysisRequired: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CladMetalProductAnalysisRequired) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CladMetalProductAnalysisRequiredNotes) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CladMetalProductAnalysisType),
      cladMetalSpecEdAndAd: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CladMetalSpecAddendum) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CladMetalSpecEdition),
      corrosionTesting: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CorrosionTesting) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CorrosionTestingNotes),
      customerBaseChemistryRestrictions: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerBaseChemistryRestrictions),
      customerBaseMetalSupplierRestrictions: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerBaseMetalSupplierRestrictions) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustBaseMetalSupplierRestrictionsNotes),
      customerCladChemistryRestrictionsNotes: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerCladChemistryRestrictionsNotes),
      customerCladMetalSupplierRestrictions: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerCladMetalSupplierRestrictions) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustCladMetalSupplierRestrictionsNotes),
      dyePenetrantNotes: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.DyePenetrantNotes),
      exceptionsAndClarifications: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.ExceptionsAndClarifications),
      ferriteNumberRequired: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.FerriteNumberRequired),
      ferroxylTestPerformed: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.FerroxylTestPerformed) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.FerroxylNotes),
      finishedGoodHeatTreatment: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.FinishedGoodHeatTreatment) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.FinishedGoodHeatTreatmentNotes),
      hardnessBaseMetalSurface: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.HardnessBaseMetalSurface) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.HardnessBaseRejectionCriteria),
      hardnessCladdingMetalSurface: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.HardnessCladdingMetalSurface) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.HardnessCladdingRejectionCriteria),
      leakTest: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.LeakTest) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.LeakTestGas) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.LeakTestReqt) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.LeakTestValue) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.LeakTestPressure),
      hicTestNotes: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.HicTestNotes),
      hotTensileTestPerSA20S7: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.HotTensileTestPerSA20S7Degrees) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.HotTensileTestPerSA20S7Notes) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.HotTensileTestPerSA20S7UOM),
      customerSpecifiedSPWHTTestCoupons: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedSPWHTTestCoupons) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupACycle1) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupACycle2) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupACycle3) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupAToBePerformed) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupBCycle1) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupBCycle2) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupBCycle3) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupBToBePerformed) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupCCycle1) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupCCycle2) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupCCycle3) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTGroupCToBePerformed) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTHeatingAndCoolingRateAbove) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTHeatingAndCoolingRateAboveUOM) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTMaximumCoolingRateHour) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTMaximumCoolingRateHourUOM) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTMaximumHeatingRateHour) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.SPWHTMaximumHeatingRateHourUOM),
      isWeldRepairAllowed: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.IsWeldRepairAllowed),
      jFactorSA387S62: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.JFactorSA387S62),
      NCBaseChemistryRestrictionsforBonding: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.NCBaseChemistryRestrictionsforBonding) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.NCBaseChemistryRestForBondingNotes),
      NCBaseHeatTreatmentasSupplied: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.NCBaseHeatTreatmentasSupplied) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.NCBaseHeatTreatmentasSuppliedNotes),
      NCCladChemistryRestrictionsforBonding: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.NCCladChemistryRestrictionsforBonding) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.NCCladChemistryRestForBondingNotes),
      NCCladHeatTreatmentasSupplied: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.NCCladHeatTreatmentasSupplied) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.NCCladHeatTreatmentasSuppliedNotes),
      phosphorusPercent: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PhosphorusPercent),
      PMIConfirmBaseMetal: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PMIConfirmBaseMetal) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PMIConfirmBaseMetalNotes),
      PMIConfirmCladdingMetal: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PMIConfirmCladdingMetal) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PMIConfirmCladdingMetalNotes),
      PMIofWeldWire: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PMIofWeldWire),
      PMIPreweldsRequired: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PMIPreweldsRequired),
      postCladBaseMetalUTSpecification: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PostCladBaseMetalUTSpecification) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PostCladBaseMetalUTSpecificationNotes),
      postCladBondUTSpecification: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PostCladBondUTSpecification) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PostCladBondUTSpecificationNotes),
      prebondImpacts: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PrebondImpacts) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PreBondImpactsTestLocation) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PreBondImpactsTestDirection) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PreBondImpactsTestUOM) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PreBondImpactsTestAVG) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedImpactTestMIN) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PreBondImpactsTestTemperature) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PreBondImpactsTestTemperatureUOM),
      precladBaseMetalUT: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PrecladBaseMetalUT) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PrecladBaseMetalUTNotes) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.PrecladBaseMetalUTType),
      radiography: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.RadiographyClad),
      machineSideBends: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.MachineSideBends),
      sulphur: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.Sulphur),
      throughThicknessTensileSA770YN: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.ThroughThicknessTensilePerSA770),
      vacuumDegassed: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.VacuumDegassed),
      xFactorSA387S62: !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.XFactorSA387S62),
      shearTest: (!postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.Shear) && !postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.Shear, true)) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.ShearTestUOM) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.ShearTestValue),
      tensileTest: (!postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.Tensile) && !postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.Tensile, true)) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CladTensileTestLocation),
      ramTensileTest: (!postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.RamTensile) && !postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.RamTensile, true)) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.BondStrengthTensileTestRamUOM) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.BondStrengthTensileTestRamValue),
      sa26345BondStrength: (!postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.SA26345BondStrength) && !postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.SA26345BondStrength, true)) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.MachineBondStrengthBendsperSA26345),
      b898Bend: (!postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.B898Bend) && !postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.B898Bend, true)) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.BendB898),
      adw8Bend: (!postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.ADW8Bend) && !postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.ADW8Bend, true)) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.ADW8Bend),
      customerSpecifiedImpactTesting: (!postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.ImpactLCVN) && !postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.ImpactLCVN, true)) ||
        (!postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.ImpactTCVN) && !postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.ImpactTCVN, true)) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedImpactTesting) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedImpactTestToBePerformed) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedImpactTestLocation) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedImpactTestDirection) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedImpactTestUOM) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedImpactTestAVG) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedImpactTestMIN) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedImpactTestTemperature) ||
        !detailFieldIsGlobal(displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo), QuoteLineItemFields.CustomerSpecifiedImpactTestTempUOM)
    };
  });

  return specifyPerAssemblyMap;
};

// Builds the line fields to update for the metal combos
export const buildMetalComboLineFieldsToUpdate = (
  displayedQuoteGroup: DisplayedQuoteGroup,
  optionsRequired: { [key: string]: { [key: string]: boolean } }
): { [key: string]: AdvSpecsMetalComboLineFieldsToUpdate } => {
  const metalComboLineFieldsToUpdate: { [key: string]: AdvSpecsMetalComboLineFieldsToUpdate } = {};

  Array.from(displayedQuoteGroup.metalComboToQlisMap.keys()).forEach((metalCombo) => {
    const postCladTestingOptionsList = [];

    if (optionsRequired[metalCombo].shearTestRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.Shear);
    }

    if (optionsRequired[metalCombo].tensileTestRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.Tensile);
    }

    if (optionsRequired[metalCombo].customerSpecifiedImpactTestingRequired) {
      switch (displayedQuoteGroup.allQlidsMappedToMetalCombo[0]?.CustomerSpecifiedImpactTestDirection__c) {
        case CustomerSpecifiedImpactTestDirections.Longitudinal:
          if (!postCladTestingOptionsList.includes(PostCladTestingOptions.ImpactLCVN)) {
            postCladTestingOptionsList.push(PostCladTestingOptions.ImpactLCVN);
          }
          break;
        case CustomerSpecifiedImpactTestDirections.Transverse:
          if (!postCladTestingOptionsList.includes(PostCladTestingOptions.ImpactTCVN)) {
            postCladTestingOptionsList.push(PostCladTestingOptions.ImpactTCVN);
          }
          break;
        case CustomerSpecifiedImpactTestDirections.Both:
          if (!postCladTestingOptionsList.includes(PostCladTestingOptions.ImpactLCVN)) {
            postCladTestingOptionsList.push(PostCladTestingOptions.ImpactLCVN);
          }

          if (!postCladTestingOptionsList.includes(PostCladTestingOptions.ImpactTCVN)) {
            postCladTestingOptionsList.push(PostCladTestingOptions.ImpactTCVN);
          }
      }
    }

    if (optionsRequired[metalCombo].ramTensileTestRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.RamTensile);
    }

    if (optionsRequired[metalCombo].adw8BendRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.ADW8Bend);
    }

    if (optionsRequired[metalCombo].b898BendRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.B898Bend);
    }

    if (optionsRequired[metalCombo].sa26345BondStrengthRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.SA26345BondStrength);
    }

    metalComboLineFieldsToUpdate[metalCombo] = {
      postCladTestingOptions: postCladTestingOptionsList.join(';'),
    };
  });

  return metalComboLineFieldsToUpdate;
};

// Builds the line fields to update for the metal combos in the global cost adjustments
export const buildGCAMetalComboLineFieldsToUpdate = (displayedQuoteGroup: DisplayedQuoteGroup) => {
  const metalComboLineFieldsToUpdate: { [key: string]: GCAMetalComboLineFieldsToUpdate } = {};

  Array.from(displayedQuoteGroup.metalComboToQlisMap.keys()).forEach(metalCombo => {
    metalComboLineFieldsToUpdate[metalCombo] = {
       cladCostPerWeightUnit: 0.00,
       baseCostPerWeightUnit: 0.00,
       cladFreightPerWeightUnit: 0.00,
       baseFreightPerWeightUnit: 0.00,
       freightOutPerWeightUnit: 0.00,
       baseShipTime: '',
       cladShipTime: ''
    };
  });

  return metalComboLineFieldsToUpdate;
};

// Builds the total weights map for the metal combos in the global cost adjustments
export const buildMetalComboTotalWeights = (displayedQuoteGroup: DisplayedQuoteGroup) => {
  const metalComboTotalWeights: { [key: string]: MetalComboTotalWeights } = {};

  Array.from(displayedQuoteGroup.metalComboToQlisMap.keys()).forEach(metalCombo => {
 
    const shipWeights = {
      lbs: [],
      kg: []
    };

    const items = displayedQuoteGroup.metalComboToQlisMap.get(metalCombo);

    const uncombinedLines = items.filter(qli => !qli.PeggedSupply__c && qli.Parent_Line__c === null);
    const combinedLines = items.filter(qli => qli.PeggedSupply__c).map(masterPlate => [masterPlate, ...items.filter(qli => qli.Parent_Line__c === masterPlate.Line__c)]);

    [...uncombinedLines, ...combinedLines.map(lineItems => lineItems[0])]
    .map(({ Ship_Weight_LB__c, Ship_Weight_KG__c, quoteLineItemDetail }) => {
      const qlidQty = quoteLineItemDetail.Quantity__c || 1;
      shipWeights.lbs.push(Ship_Weight_LB__c * qlidQty);
      shipWeights.kg.push(Ship_Weight_KG__c * qlidQty);
    });

    const totalShipWeightLb = shipWeights.lbs.reduce((acc, weight) => acc + weight, 0);
    const totalShipWeightKg = shipWeights.kg.reduce((acc, weight) => acc + weight, 0);

    const totalBaseWeightLb = displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo).map(qlid => qlid.Raw_Base_Weight_Lbs__c * qlid.Clads__c).sum();
    const totalBaseWeightKg = displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo).map(qlid => qlid.Raw_Base_Weight_Kg__c * qlid.Clads__c).sum();
    const totalCladWeightLb = displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo).map(qlid => qlid.Raw_Clad_Weight_Lbs__c * qlid.Clads__c).sum();
    const totalCladWeightKg = displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo).map(qlid => qlid.Raw_Clad_Weight_Kg__c * qlid.Clads__c).sum();

    metalComboTotalWeights[metalCombo] = {
       totalShipWeightLb,
       totalShipWeightKg,
       totalBaseWeightLb,
       totalBaseWeightKg,
       totalCladWeightLb,
       totalCladWeightKg
    };
  });

  return metalComboTotalWeights;
};

// Builds the line fields to update for assembly groups
export const buildAssemblyGroupsLineFieldsToUpdate = (
  displayedQuoteGroup: DisplayedQuoteGroup,
  optionsRequired: Map<string, { [key: string]: boolean }>
): { [key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate } => {
  const assemblyGroupLineFieldsToUpdate: { [key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate } = {};

  displayedQuoteGroup.quoteLineItems.forEach((qli) => {
    const assemblyGroup = qli.quoteLineItemDetail;
    const assemblyGroupId = assemblyGroup?.Id || assemblyGroup?.associatedLineItem;

    const postCladTestingOptionsList = [];
    if (optionsRequired.get(assemblyGroupId).shearTestRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.Shear);
    }

    if (optionsRequired.get(assemblyGroupId).tensileTestRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.Tensile);
    }

    if (optionsRequired.get(assemblyGroupId).customerSpecifiedImpactTestingRequired) {
      switch (assemblyGroup?.CustomerSpecifiedImpactTestDirection__c) {
        case CustomerSpecifiedImpactTestDirections.Longitudinal:
          if (!postCladTestingOptionsList.includes(PostCladTestingOptions.ImpactLCVN)) {
            postCladTestingOptionsList.push(PostCladTestingOptions.ImpactLCVN);
          }
          break;
        case CustomerSpecifiedImpactTestDirections.Transverse:
          if (!postCladTestingOptionsList.includes(PostCladTestingOptions.ImpactTCVN)) {
            postCladTestingOptionsList.push(PostCladTestingOptions.ImpactTCVN);
          }
          break;
        case CustomerSpecifiedImpactTestDirections.Both:
          if (!postCladTestingOptionsList.includes(PostCladTestingOptions.ImpactLCVN)) {
            postCladTestingOptionsList.push(PostCladTestingOptions.ImpactLCVN);
          }

          if (!postCladTestingOptionsList.includes(PostCladTestingOptions.ImpactTCVN)) {
            postCladTestingOptionsList.push(PostCladTestingOptions.ImpactTCVN);
          }
      }
    }

    if (optionsRequired.get(assemblyGroupId).ramTensileTestRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.RamTensile);
    }

    if (optionsRequired.get(assemblyGroupId).adw8BendRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.ADW8Bend);
    }

    if (optionsRequired.get(assemblyGroupId).b898BendRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.B898Bend);
    }

    if (optionsRequired.get(assemblyGroupId).sa26345BondStrengthRequired) {
      postCladTestingOptionsList.push(PostCladTestingOptions.SA26345BondStrength);
    }

    assemblyGroupLineFieldsToUpdate[qli.Id || qli.displayId] = {
      postCladTestingOptions: postCladTestingOptionsList.length > 0 ? postCladTestingOptionsList.join(';') : undefined,
      freightCostPerLB: qli.Total_Freight_LB__c,
      freightCostPerKG: qli.Total_Freight_KG__c,
      postCladProcessingCutting: qli.Cut_Method__c,
    };
  });

  return assemblyGroupLineFieldsToUpdate;
};

//Builds the detail fields to update for the metal combos
export const buildMetalComboDetailFieldsToUpdate = (
  displayedQuoteGroup: DisplayedQuoteGroup,
  specifyPerAssemblyMap: { [key: string]: { [key: string]: boolean } }
): { [key: string]: AdvSpecsMetalComboDetailFieldsToUpdate } => {
  const detailFieldsToUpdate: { [key: string]: AdvSpecsMetalComboDetailFieldsToUpdate } = {};

  Array.from(displayedQuoteGroup.metalComboToQlisMap.keys()).forEach((metalCombo) => {
    const firstLineInMetalCombo = displayedQuoteGroup.metalComboToQlisMap.get(metalCombo)[0];

    // prettier-ignore
    detailFieldsToUpdate[metalCombo] = {
      advancedSpecUOM: (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.AdvancedSpecUOM] || MeasurementSystems.Imperial,
      additionalRequirementsBaseMetal: !specifyPerAssemblyMap[metalCombo].additionalRequirementsBaseMetal ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.AdditionalRequirementsBaseMetal] : undefined,
      additionalRequirementsCladdingMetal: !specifyPerAssemblyMap[metalCombo].additionalRequirementsCladdingMetal ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.AdditionalRequirementsCladdingMetal] : undefined,
      additionalRequirementsCladProductTesting: !specifyPerAssemblyMap[metalCombo].additionalRequirementsCladProductTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.AdditionalRequirementsCladProductTesting] : undefined,
      additionalRequirementsPrewelding: !specifyPerAssemblyMap[metalCombo].additionalRequirementsPrewelding ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.AdditionalRequirementsPrewelding] : undefined,
      ADW8Bend: !specifyPerAssemblyMap[metalCombo].adw8Bend ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.ADW8Bend] : undefined,
      austeniticGrainSizeFinerThanValue: !specifyPerAssemblyMap[metalCombo].austeniticGrainSizeFinerThanValue ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.AusteniticGrainSizeFinerThanValue] || '' : '',
      baseMetal: (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.BaseMetal],
      baseMetalProductAnalysisRequired: !specifyPerAssemblyMap[metalCombo].baseMetalProductAnalysisRequired ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.BaseMetalProductAnalysisRequired] : undefined,
      baseMetalProductAnalysisRequiredNotes: !specifyPerAssemblyMap[metalCombo].baseMetalProductAnalysisRequired ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.BaseMetalProductAnalysisRequiredNotes] : undefined,
      baseMetalProductAnalysisType: !specifyPerAssemblyMap[metalCombo].baseMetalProductAnalysisRequired ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.BaseMetalProductAnalysisType] : undefined,
      baseMetalSpecAddendum: !specifyPerAssemblyMap[metalCombo].baseMetalSpecEdAndAd ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.BaseMetalSpecAddendum] : undefined,
      baseMetalSpecEdition: !specifyPerAssemblyMap[metalCombo].baseMetalSpecEdAndAd ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.BaseMetalSpecEdition] : undefined,
      baseTensileTestLocation: !specifyPerAssemblyMap[metalCombo].baseTensileTestLocation ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.BaseTensileTestLocation] : undefined,
      bendB898: !specifyPerAssemblyMap[metalCombo].bendB898 ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.BendB898] : undefined,
      machineBondStrengthBendsperSA26345: !specifyPerAssemblyMap[metalCombo].sa26345BondStrength ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.MachineBondStrengthBendsperSA26345] : undefined,
      bondStrengthTensileTestRamUOM: !specifyPerAssemblyMap[metalCombo].ramTensileTest ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.BondStrengthTensileTestRamUOM] : undefined,
      bondStrengthTensileTestRamValue: !specifyPerAssemblyMap[metalCombo].ramTensileTest ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.BondStrengthTensileTestRamValue] || '' : '',
      calciumTreatedSulfideShapeControl: !specifyPerAssemblyMap[metalCombo].calciumTreatedSulfideShapeControl ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CalciumTreatedSulfideShapeControl] : undefined,
      carbonEquivalencyPercent: !specifyPerAssemblyMap[metalCombo].carbonEquivalencyPercent ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CarbonEquivalencyPercent] || '' : '',
      carbonPercent: !specifyPerAssemblyMap[metalCombo].carbonPercent ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CarbonPercent] || '' : '',
      certificationTypeRequired: !specifyPerAssemblyMap[metalCombo].certificationTypeRequired ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CertificationTypeRequired] || firstLineInMetalCombo.Certificate__c : undefined,
      claddingForCorrosionAllowanceOnly: !specifyPerAssemblyMap[metalCombo].claddingForCorrosionAllowanceOnly ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CladdingForCorrosionAllowanceOnly] : undefined,
      cladMetalProductAnalysisRequired: !specifyPerAssemblyMap[metalCombo].cladMetalProductAnalysisRequired ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CladMetalProductAnalysisRequired] : undefined,
      cladMetal: (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CladMetal],
      cladMetalProductAnalysisRequiredNotes: !specifyPerAssemblyMap[metalCombo].cladMetalProductAnalysisRequired ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CladMetalProductAnalysisRequiredNotes] : undefined,
      cladMetalProductAnalysisType: !specifyPerAssemblyMap[metalCombo].cladMetalProductAnalysisRequired ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CladMetalProductAnalysisType] : undefined,
      cladMetalSpecAddendum: !specifyPerAssemblyMap[metalCombo].cladMetalSpecEdAndAd ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CladMetalSpecAddendum] : undefined,
      cladMetalSpecEdition: !specifyPerAssemblyMap[metalCombo].cladMetalSpecEdAndAd ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CladMetalSpecEdition] : undefined,
      cladProductionSpecification: (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CladProductSpecification] || `${firstLineInMetalCombo.Specification__c}${firstLineInMetalCombo.Specification__c !== CladProductionSpecifications.DMC100 ? ' & DMC100' : ''}`,
      cladProductionSpecificationNotes: (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CladProductionSpecificationNotes],
      cladTensileTestLocation: !specifyPerAssemblyMap[metalCombo].tensileTest ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CladTensileTestLocation] : undefined,
      corrosionTesting: !specifyPerAssemblyMap[metalCombo].corrosionTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CorrosionTesting] : undefined,
      corrosionTestingNotes: !specifyPerAssemblyMap[metalCombo].corrosionTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CorrosionTestingNotes] : undefined,
      custBaseMetalSupplierRestrictionsNotes: !specifyPerAssemblyMap[metalCombo].customerBaseMetalSupplierRestrictions ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustBaseMetalSupplierRestrictionsNotes] : undefined,
      custCladMetalSupplierRestrictionsNotes: !specifyPerAssemblyMap[metalCombo].customerCladMetalSupplierRestrictions ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustCladMetalSupplierRestrictionsNotes] : undefined,
      customerBaseChemistryRestrictions: !specifyPerAssemblyMap[metalCombo].customerBaseChemistryRestrictions ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerBaseChemistryRestrictions] : undefined,
      customerBaseMetalSupplierRestrictions: !specifyPerAssemblyMap[metalCombo].customerBaseMetalSupplierRestrictions ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerBaseMetalSupplierRestrictions] : undefined,
      customerCladChemistryRestrictionsNotes: !specifyPerAssemblyMap[metalCombo].customerCladChemistryRestrictionsNotes ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerCladChemistryRestrictionsNotes] : undefined,
      customerCladMetalSupplierRestrictions: !specifyPerAssemblyMap[metalCombo].customerCladMetalSupplierRestrictions ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerCladMetalSupplierRestrictions] : undefined,
      dyePenetrantNotes: !specifyPerAssemblyMap[metalCombo].dyePenetrantNotes ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.DyePenetrantNotes] : undefined,
      exceptionsAndClarifications: !specifyPerAssemblyMap[metalCombo].exceptionsAndClarifications ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.ExceptionsAndClarifications] : undefined,
      ferriteNumberRequired: !specifyPerAssemblyMap[metalCombo].ferriteNumberRequired ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.FerriteNumberRequired] || '' : '',
      ferroxylTestPerformed: !specifyPerAssemblyMap[metalCombo].ferroxylTestPerformed ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.FerroxylTestPerformed] : undefined,
      ferroxylNotes: !specifyPerAssemblyMap[metalCombo].ferroxylTestPerformed ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.FerroxylNotes] : undefined,
      finishedGoodHeatTreatment: !specifyPerAssemblyMap[metalCombo].finishedGoodHeatTreatment ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.FinishedGoodHeatTreatment] : undefined,
      finishedGoodHeatTreatmentNotes: !specifyPerAssemblyMap[metalCombo].finishedGoodHeatTreatment ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.FinishedGoodHeatTreatmentNotes] : undefined,
      hardnessBaseMetalSurface: !specifyPerAssemblyMap[metalCombo].hardnessBaseMetalSurface ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.HardnessBaseMetalSurface] : undefined,
      hardnessBaseRejectionCriteria: !specifyPerAssemblyMap[metalCombo].hardnessBaseMetalSurface ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.HardnessBaseRejectionCriteria] : undefined,
      hardnessCladdingMetalSurface: !specifyPerAssemblyMap[metalCombo].hardnessCladdingMetalSurface ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.HardnessCladdingMetalSurface] : undefined,
      hardnessCladdingRejectionCriteria: !specifyPerAssemblyMap[metalCombo].hardnessCladdingMetalSurface ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.HardnessCladdingRejectionCriteria] : undefined,
      leakTest: !specifyPerAssemblyMap[metalCombo].leakTest ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.LeakTest] : undefined,
      leakTestGas: !specifyPerAssemblyMap[metalCombo].leakTest ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.LeakTestGas] : undefined,
      leakTestReqt: !specifyPerAssemblyMap[metalCombo].leakTest ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.LeakTestReqt] : undefined,
      leakTestValue: !specifyPerAssemblyMap[metalCombo].leakTest ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.LeakTestValue] : undefined,
      leakTestPressure: !specifyPerAssemblyMap[metalCombo].leakTest ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.LeakTestPressure] : undefined,
      hicTestNotes: !specifyPerAssemblyMap[metalCombo].hicTestNotes ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.HicTestNotes] : undefined,
      hotTensileTestPerSA20S7Degrees: !specifyPerAssemblyMap[metalCombo].hotTensileTestPerSA20S7 ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.HotTensileTestPerSA20S7Degrees] || '' : '',
      hotTensileTestPerSA20S7Notes: !specifyPerAssemblyMap[metalCombo].hotTensileTestPerSA20S7 ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.HotTensileTestPerSA20S7Notes] : undefined,
      hotTensileTestPerSA20S7UOM: !specifyPerAssemblyMap[metalCombo].hotTensileTestPerSA20S7 ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.HotTensileTestPerSA20S7UOM]?.replace('°', '') : undefined,
      customerSpecifiedImpactTesting: !specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerSpecifiedImpactTesting] : undefined,
      customerSpecifiedSPWHTTestCoupons: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerSpecifiedSPWHTTestCoupons] : undefined,
      isWeldRepairAllowed: !specifyPerAssemblyMap[metalCombo].isWeldRepairAllowed ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.IsWeldRepairAllowed] : undefined,
      jFactorSA387S62: !specifyPerAssemblyMap[metalCombo].jFactorSA387S62 ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.JFactorSA387S62] || '' : '',
      NCBaseChemistryRestForBondingNotes: !specifyPerAssemblyMap[metalCombo].NCBaseChemistryRestrictionsforBonding ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.NCBaseChemistryRestForBondingNotes] : undefined,
      NCBaseChemistryRestrictionsforBonding: !specifyPerAssemblyMap[metalCombo].NCBaseChemistryRestrictionsforBonding ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.NCBaseChemistryRestrictionsforBonding] : undefined,
      NCBaseHeatTreatmentasSupplied: !specifyPerAssemblyMap[metalCombo].NCBaseHeatTreatmentasSupplied ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.NCBaseHeatTreatmentasSupplied] : undefined,
      NCBaseHeatTreatmentasSuppliedNotes: !specifyPerAssemblyMap[metalCombo].NCBaseHeatTreatmentasSupplied ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.NCBaseHeatTreatmentasSuppliedNotes] : undefined,
      NCBaseUTRequirement: !specifyPerAssemblyMap[metalCombo].precladBaseMetalUT ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.NCBaseUTRequirement] : undefined,
      NCCladChemistryRestForBondingNotes: !specifyPerAssemblyMap[metalCombo].NCCladChemistryRestrictionsforBonding ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.NCCladChemistryRestForBondingNotes] : undefined,
      NCCladChemistryRestrictionsforBonding: !specifyPerAssemblyMap[metalCombo].NCCladChemistryRestrictionsforBonding ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.NCCladChemistryRestrictionsforBonding] : undefined,
      NCCladHeatTreatmentasSupplied: !specifyPerAssemblyMap[metalCombo].NCCladHeatTreatmentasSupplied ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.NCCladHeatTreatmentasSupplied] : undefined,
      NCCladHeatTreatmentasSuppliedNotes: !specifyPerAssemblyMap[metalCombo].NCCladHeatTreatmentasSupplied ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.NCCladHeatTreatmentasSuppliedNotes] : undefined,
      phosphorusPercent: !specifyPerAssemblyMap[metalCombo].phosphorusPercent ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PhosphorusPercent] || '' : '',
      PMIConfirmBaseMetal: !specifyPerAssemblyMap[metalCombo].PMIConfirmBaseMetal ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PMIConfirmBaseMetal] : undefined,
      PMIConfirmBaseMetalNotes: !specifyPerAssemblyMap[metalCombo].PMIConfirmBaseMetal ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PMIConfirmBaseMetalNotes] : undefined,
      PMIConfirmCladdingMetal: !specifyPerAssemblyMap[metalCombo].PMIConfirmCladdingMetal ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PMIConfirmCladdingMetal] : undefined,
      PMIConfirmCladdingMetalNotes: !specifyPerAssemblyMap[metalCombo].PMIConfirmCladdingMetal ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PMIConfirmCladdingMetalNotes] : undefined,
      PMIofWeldWire: !specifyPerAssemblyMap[metalCombo].PMIofWeldWire ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PMIofWeldWire] : undefined,
      PMIPreweldsRequired: !specifyPerAssemblyMap[metalCombo].PMIPreweldsRequired ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PMIPreweldsRequired] : undefined,
      postCladBaseMetalUTSpecification: !specifyPerAssemblyMap[metalCombo].postCladBaseMetalUTSpecification ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PostCladBaseMetalUTSpecification] || firstLineInMetalCombo.Base_Metal_UT__c : undefined,
      postCladBaseMetalUTSpecificationNotes: !specifyPerAssemblyMap[metalCombo].postCladBaseMetalUTSpecification ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PostCladBaseMetalUTSpecificationNotes] : undefined,
      postCladBondUTSpecification: !specifyPerAssemblyMap[metalCombo].postCladBondUTSpecification ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PostCladBondUTSpecification] || `${firstLineInMetalCombo.Specification__c}: ${firstLineInMetalCombo.Bond_Metal_UT__c}` : undefined,
      postCladBondUTSpecificationNotes: !specifyPerAssemblyMap[metalCombo].postCladBondUTSpecification ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PostCladBondUTSpecificationNotes] : undefined,
      prebondImpacts: !specifyPerAssemblyMap[metalCombo].prebondImpacts ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PrebondImpacts] : undefined,
      precladBaseMetalUT: !specifyPerAssemblyMap[metalCombo].precladBaseMetalUT ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PrecladBaseMetalUT] : undefined,
      precladBaseMetalUTNotes: !specifyPerAssemblyMap[metalCombo].precladBaseMetalUT ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PrecladBaseMetalUTNotes] : undefined,
      precladBaseMetalUTType: !specifyPerAssemblyMap[metalCombo].precladBaseMetalUT ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PrecladBaseMetalUTType] : undefined,
      radiographyClad: !specifyPerAssemblyMap[metalCombo].radiography ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.RadiographyClad] : undefined,
      shearTestUOM: !specifyPerAssemblyMap[metalCombo].shearTest ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.ShearTestUOM] : undefined,
      shearTestValue: !specifyPerAssemblyMap[metalCombo].shearTest ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.ShearTestValue] || '' : '',
      machineSideBends: !specifyPerAssemblyMap[metalCombo].machineSideBends ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.MachineSideBends] : undefined,
      sulphur: !specifyPerAssemblyMap[metalCombo].sulphur ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.Sulphur] || '' : '',
      throughThicknessTensileSA770YN: !specifyPerAssemblyMap[metalCombo].throughThicknessTensileSA770YN ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.ThroughThicknessTensilePerSA770] : undefined,
      vacuumDegassed: !specifyPerAssemblyMap[metalCombo].vacuumDegassed ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.VacuumDegassed] : undefined,
      xFactorSA387S62: !specifyPerAssemblyMap[metalCombo].xFactorSA387S62 ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.XFactorSA387S62] || '' : '',
      customerSpecifiedImpactTestToBePerformed: !specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerSpecifiedImpactTestToBePerformed] : undefined,
      customerSpecifiedImpactTestLocation: !specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerSpecifiedImpactTestLocation] : undefined,
      customerSpecifiedImpactTestDirection: !specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerSpecifiedImpactTestDirection] : undefined,
      customerSpecifiedImpactTestUOM: !specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerSpecifiedImpactTestUOM] : undefined,
      customerSpecifiedImpactTestAVG: !specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerSpecifiedImpactTestAVG] || '' : '',
      customerSpecifiedImpactTestMIN: !specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerSpecifiedImpactTestMIN] || '' : '',
      customerSpecifiedImpactTestTemperature: !specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerSpecifiedImpactTestTemperature] || '' : '',
      customerSpecifiedImpactTestTempUOM: !specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.CustomerSpecifiedImpactTestTempUOM]?.replace('°', '') : undefined,
      preBondImpactsTestLocation: !specifyPerAssemblyMap[metalCombo].prebondImpacts ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PreBondImpactsTestLocation] : undefined,
      preBondImpactsTestDirection: !specifyPerAssemblyMap[metalCombo].prebondImpacts ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PreBondImpactsTestDirection] : undefined,
      preBondImpactsTestUOM: !specifyPerAssemblyMap[metalCombo].prebondImpacts ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PreBondImpactsTestUOM] : undefined,
      preBondImpactsTestAVG: !specifyPerAssemblyMap[metalCombo].prebondImpacts ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PreBondImpactsTestAVG] || '' : '',
      preBondImpactsTestMIN: !specifyPerAssemblyMap[metalCombo].prebondImpacts ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PreBondImpactsTestMIN] || '' : '',
      preBondImpactsTestTemperature: !specifyPerAssemblyMap[metalCombo].prebondImpacts ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PreBondImpactsTestTemperature] || '' : '',
      preBondImpactsTestTemperatureUOM: !specifyPerAssemblyMap[metalCombo].prebondImpacts ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.PreBondImpactsTestTemperatureUOM]?.replace('°', '') : undefined,
      SPWHTGroupACycle1: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupACycle1] : undefined,
      SPWHTGroupACycle2: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupACycle2] : undefined,
      SPWHTGroupACycle3: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupACycle3] : undefined,
      SPWHTGroupAToBePerformed: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupAToBePerformed] : undefined,
      SPWHTGroupBCycle1: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupBCycle1] : undefined,
      SPWHTGroupBCycle2: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupBCycle2] : undefined,
      SPWHTGroupBCycle3: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupBCycle3] : undefined,
      SPWHTGroupBToBePerformed: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupBToBePerformed] : undefined,
      SPWHTGroupCCycle1: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupCCycle1] : undefined,
      SPWHTGroupCCycle2: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupCCycle2] : undefined,
      SPWHTGroupCCycle3: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupCCycle3] : undefined,
      SPWHTGroupCToBePerformed: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTGroupCToBePerformed] : undefined,
      SPWHTHeatingAndCoolingRateAbove: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTHeatingAndCoolingRateAbove] : undefined,
      SPWHTHeatingAndCoolingRateAboveUOM: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTHeatingAndCoolingRateAboveUOM] : undefined,
      SPWHTMaximumCoolingRateHour: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTMaximumCoolingRateHour] : undefined,
      SPWHTMaximumCoolingRateHourUOM: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTMaximumCoolingRateHourUOM] : undefined,
      SPWHTMaximumHeatingRateHour: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTMaximumHeatingRateHour] : undefined,
      SPWHTMaximumHeatingRateHourUOM: !specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons ? (displayedQuoteGroup.metalComboToQlidsMap.get(metalCombo)[0] || {})[QuoteLineItemFields.SPWHTMaximumHeatingRateHourUOM] : undefined,
    };
  });

  return detailFieldsToUpdate;
};

//Builds the detail fields to update for the assembly groups
export const buildAssemblyGroupDetailFieldsToUpdate = (
  quoteLineItemDetails: QuoteLineItemDetail[]
): Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate> => {
  const detailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate> = new Map();

  quoteLineItemDetails?.forEach((quoteLineItemDetail) => {
    // prettier-ignore
    detailFieldsToUpdate.set(quoteLineItemDetail.Id || quoteLineItemDetail.associatedLineItem, {
      additionalRequirementsBaseMetal: quoteLineItemDetail.AdditionalRequirementsBaseMetal__c,
      additionalRequirementsCladdingMetal: quoteLineItemDetail.AdditionalRequirementsCladdingMetal__c,
      additionalRequirementsCladProductTesting: quoteLineItemDetail.AdditionalRequirementsCladProductTesting__c,
      additionalRequirementsPrewelding: quoteLineItemDetail.AdditionalRequirementsPrewelding__c,
      ADW8Bend: quoteLineItemDetail.ADW8Bend__c,
      austeniticGrainSizeFinerThanValue: quoteLineItemDetail.AusteniticGrainSizeFinerThanValue__c || '',
      baseMetalProductAnalysisRequired: quoteLineItemDetail.BaseMetalProductAnalysisRequired__c,
      baseMetalProductAnalysisRequiredNotes: quoteLineItemDetail.BaseMetalProductAnalysisRequiredNotes__c,
      baseMetalProductAnalysisType: quoteLineItemDetail.BaseMetalProductAnalysisType__c,
      baseMetalSpecAddendum: quoteLineItemDetail.BaseMetalSpecAddendum__c,
      baseMetalSpecEdition: quoteLineItemDetail.BaseMetalSpecEdition__c,
      baseTensileTestLocation: quoteLineItemDetail.BaseTensileTestLocation__c,
      bendB898: quoteLineItemDetail.BendB898__c,
      machineBondStrengthBendsperSA26345: quoteLineItemDetail.MachineBondStrengthBendsperSA26345__c,
      bondStrengthTensileTestRamUOM: quoteLineItemDetail.BondStrengthTensileTestRamUOM__c,
      bondStrengthTensileTestRamValue: quoteLineItemDetail.BondStrengthTensileTestRamValue__c || '',
      calciumTreatedSulfideShapeControl: quoteLineItemDetail.CalciumTreatedSulfideShapeControl__c,
      carbonEquivalencyPercent: quoteLineItemDetail.Carbon_Equivalency_Percent__c || '',
      carbonPercent: quoteLineItemDetail.Carbon_Percent__c || '',
      certificationTypeRequired: quoteLineItemDetail.CertificationTypeRequired__c,
      claddingForCorrosionAllowanceOnly: quoteLineItemDetail.CladdingForCorrosionAllowanceOnly__c,
      cladMetalProductAnalysisRequired: quoteLineItemDetail.CladMetalProductAnalysisRequired__c,
      cladMetalProductAnalysisRequiredNotes: quoteLineItemDetail.CladMetalProductAnalysisRequiredNotes__c,
      cladMetalProductAnalysisType: quoteLineItemDetail.CladMetalProductAnalysisType__c,
      cladMetalSpecAddendum: quoteLineItemDetail.CladMetalSpecAddendum__c,
      cladMetalSpecEdition: quoteLineItemDetail.CladMetalSpecEdition__c,
      cladTensileTestLocation: quoteLineItemDetail.CladTensileTestLocation__c,
      corrosionTesting: quoteLineItemDetail.CorrosionTesting__c,
      corrosionTestingNotes: quoteLineItemDetail.CorrosionTestingNotes__c,
      custBaseMetalSupplierRestrictionsNotes: quoteLineItemDetail.CustBaseMetalSupplierRestrictionsNotes__c,
      custCladMetalSupplierRestrictionsNotes: quoteLineItemDetail.CustCladMetalSupplierRestrictionsNotes__c,
      customerBaseChemistryRestrictions: quoteLineItemDetail.CustomerBaseChemistryRestrictions__c,
      customerBaseMetalSupplierRestrictions: quoteLineItemDetail.CustomerBaseMetalSupplierRestrictions__c,
      customerCladChemistryRestrictionsNotes: quoteLineItemDetail.CustomerCladChemistryRestrictionsNotes__c,
      customerCladMetalSupplierRestrictions: quoteLineItemDetail.CustomerCladMetalSupplierRestrictions__c,
      dyePenetrantNotes: quoteLineItemDetail.DyePenetrantNotes__c,
      exceptionsAndClarifications: quoteLineItemDetail.Exceptions_and_Clarifications__c,
      ferriteNumberRequired: quoteLineItemDetail.FerriteNumberRequired__c || '',
      ferroxylTestPerformed: quoteLineItemDetail.FerroxylTestPerformed__c,
      ferroxylNotes: quoteLineItemDetail.FerroxylNotes__c,
      finishedGoodHeatTreatment: quoteLineItemDetail.FinishedGoodHeatTreatment__c,
      finishedGoodHeatTreatmentNotes: quoteLineItemDetail.FinishedGoodHeatTreatmentNotes__c,
      hardnessBaseMetalSurface: quoteLineItemDetail.HardnessBaseMetalSurface__c,
      hardnessBaseRejectionCriteria: quoteLineItemDetail.HardnessBaseRejectionCriteria__c,
      hardnessCladdingMetalSurface: quoteLineItemDetail.HardnessCladdingMetalSurface__c,
      hardnessCladdingRejectionCriteria: quoteLineItemDetail.HardnessCladdingRejectionCriteria__c,
      leakTest: quoteLineItemDetail.LeakTest__c,
      leakTestGas: quoteLineItemDetail.LeakTestGas__c,
      leakTestReqt: quoteLineItemDetail.LeakTestReqt__c,
      leakTestValue: quoteLineItemDetail.LeakTestValue__c,
      leakTestPressure: quoteLineItemDetail.LeakTestPressure__c,
      hicTestNotes: quoteLineItemDetail.HicTestNotes__c,
      hotTensileTestPerSA20S7Degrees: quoteLineItemDetail.HotTensileTestPerSA20S7Degrees__c || '',
      hotTensileTestPerSA20S7Notes: quoteLineItemDetail.HotTensileTestPerSA20S7Notes__c,
      hotTensileTestPerSA20S7UOM: quoteLineItemDetail.HotTensileTestPerSA20S7UOM__c?.replace('°', ''),
      customerSpecifiedImpactTesting: quoteLineItemDetail.CustomerSpecifiedImpactTesting__c,
      customerSpecifiedSPWHTTestCoupons: quoteLineItemDetail.CustomerSpecifiedSPWHTTestCoupons__c,
      isWeldRepairAllowed: quoteLineItemDetail.IsWeldRepairAllowed__c,
      jFactorSA387S62: quoteLineItemDetail.JFactorSA387S62__c || '',
      NCBaseChemistryRestForBondingNotes: quoteLineItemDetail.NCBaseChemistryRestForBondingNotes__c,
      NCBaseChemistryRestrictionsforBonding: quoteLineItemDetail.NCBaseChemistryRestrictionsforBonding__c,
      NCBaseHeatTreatmentasSupplied: quoteLineItemDetail.NCBaseHeatTreatmentasSupplied__c,
      NCBaseHeatTreatmentasSuppliedNotes: quoteLineItemDetail.NCBaseHeatTreatmentasSuppliedNotes__c,
      NCBaseUTRequirement: quoteLineItemDetail.NCBaseUTRequirement__c,
      NCCladChemistryRestForBondingNotes: quoteLineItemDetail.NCCladChemistryRestForBondingNotes__c,
      NCCladChemistryRestrictionsforBonding: quoteLineItemDetail.NCCladChemistryRestrictionsforBonding__c,
      NCCladHeatTreatmentasSupplied: quoteLineItemDetail.NCCladHeatTreatmentasSupplied__c,
      NCCladHeatTreatmentasSuppliedNotes: quoteLineItemDetail.NCCladHeatTreatmentasSuppliedNotes__c,
      phosphorusPercent: quoteLineItemDetail.Phosphorus_Percent__c || '',
      PMIConfirmBaseMetal: quoteLineItemDetail.PMIConfirmBaseMetal__c,
      PMIConfirmBaseMetalNotes: quoteLineItemDetail.PMIConfirmBaseMetalNotes__c,
      PMIConfirmCladdingMetal: quoteLineItemDetail.PMIConfirmCladdingMetal__c,
      PMIConfirmCladdingMetalNotes: quoteLineItemDetail.PMIConfirmCladdingMetalNotes__c,
      PMIofWeldWire: quoteLineItemDetail.PMIofWeldWire__c,
      PMIPreweldsRequired: quoteLineItemDetail.PMIPreweldsRequired__c,
      postCladBaseMetalUTSpecification: quoteLineItemDetail.PostCladBaseMetalUTSpecification__c,
      postCladBaseMetalUTSpecificationNotes: quoteLineItemDetail.PostCladBaseMetalUTSpecificationNotes__c,
      postCladBondUTSpecification: quoteLineItemDetail.PostCladBondUTSpecification__c,
      postCladBondUTSpecificationNotes: quoteLineItemDetail.PostCladBondUTSpecificationNotes__c,
      prebondImpacts: quoteLineItemDetail.PrebondImpacts__c,
      precladBaseMetalUT: quoteLineItemDetail.PrecladBaseMetalUT__c,
      precladBaseMetalUTNotes: quoteLineItemDetail.PrecladBaseMetalUTNotes__c,
      precladBaseMetalUTType: quoteLineItemDetail.PrecladBaseMetalUTType__c,
      radiographyClad: quoteLineItemDetail.Radiography_Clad__c,
      shearTestUOM: quoteLineItemDetail.ShearTestUOM__c,
      shearTestValue: quoteLineItemDetail.ShearTestValue__c || '',
      machineSideBends: quoteLineItemDetail.MachineSideBends__c,
      sulphur: quoteLineItemDetail.Sulphur__c || '',
      throughThicknessTensileSA770YN: quoteLineItemDetail.ThroughThicknessTensilePerSA770__c,
      vacuumDegassed: quoteLineItemDetail.VacuumDegassed__c,
      xFactorSA387S62: quoteLineItemDetail.XFactorSA387S62__c || '',
      customerSpecifiedImpactTestToBePerformed: quoteLineItemDetail.CustomerSpecifiedImpactTestToBePerformed__c,
      customerSpecifiedImpactTestLocation: quoteLineItemDetail.CustomerSpecifiedImpactTestLocation__c,
      customerSpecifiedImpactTestDirection: quoteLineItemDetail.CustomerSpecifiedImpactTestDirection__c,
      customerSpecifiedImpactTestUOM:quoteLineItemDetail.CustomerSpecifiedImpactTestUOM__c,
      customerSpecifiedImpactTestAVG: quoteLineItemDetail.CustomerSpecifiedImpactTestAVG__c || '',
      customerSpecifiedImpactTestMIN: quoteLineItemDetail.CustomerSpecifiedImpactTestMIN__c || '',
      customerSpecifiedImpactTestTemperature: quoteLineItemDetail.CustomerSpecifiedImpactTestTemperature__c || '',
      customerSpecifiedImpactTestTempUOM: quoteLineItemDetail.CustomerSpecifiedImpactTestTempUOM__c?.replace('°', ''),
      preBondImpactsTestLocation: quoteLineItemDetail.PreBondImpactsTestLocation__c,
      preBondImpactsTestDirection: quoteLineItemDetail.PreBondImpactsTestDirection__c,
      preBondImpactsTestUOM: quoteLineItemDetail.PreBondImpactsTestUOM__c,
      preBondImpactsTestAVG: quoteLineItemDetail.PreBondImpactsTestAVG__c || '',
      preBondImpactsTestMIN: quoteLineItemDetail.PreBondImpactsTestMIN__c || '',
      preBondImpactsTestTemperature: quoteLineItemDetail.PreBondImpactsTestTemperature__c || '',
      preBondImpactsTestTemperatureUOM: quoteLineItemDetail.PreBondImpactsTestTemperatureUOM__c?.replace('°', ''),
      postCladProcessingIsNCHouseStressReliefChecked: quoteLineItemDetail.StressReliefHT__c || false,
      postCladProcessingHeadFormingHeatTreat: quoteLineItemDetail.SimulateHeatTreatmentHeadForming__c,
      postCladProcessingNCHouseStressReliefMin: quoteLineItemDetail.NCInhouseStressRefliefTimeMin__c,
      postCladProcessingNCHouseStressReliefMax: quoteLineItemDetail.NCInHouseStressReliefTimeMax__c,
      postCladProcessingNCHouseStressReliefTemp: quoteLineItemDetail.NCInhouseStressReliefTemp__c,
      postCladProcessingNCHouseStressReliefDetails: quoteLineItemDetail.NCInhouseStressReliefDetails__c,
      postCladProcessingIsNCHouseStressReliefVendorChecked: quoteLineItemDetail.SimulateHeatTreatmentAtVendor__c || false,
      postCladProcessingIsPreHeatReqForCladdingChecked: quoteLineItemDetail.PreHeatRequiredforCladding__c || false,
      postCladProcessingPreHeatReqForCladdingMin: quoteLineItemDetail.PreHeatReqforcladdingMinTempforPreheat__c,
      postCladProcessingIsPreHeatReqForCuttingChecked: quoteLineItemDetail.PreheattoCut__c || false,
      postCladProcessingPreHeatReqForCuttingMin: quoteLineItemDetail.PreHeatReqforcuttingmintempforpreheat__c,
      postCladProcessingFlattening: quoteLineItemDetail.PostCladFlattening__c,
      postCladProcessingFlatteningNotes: quoteLineItemDetail.FlatteningNotes__c,
      postCladProcessingCuttingNotes: quoteLineItemDetail.PostCladCuttingTolerance__c,
      postCladProcessingMachining: quoteLineItemDetail.Machining__c,
      postCladProcessingMachiningNotes: quoteLineItemDetail.MachiningNotes__c,
      postCladProcessingSurfaceTreatment: quoteLineItemDetail.SurfaceTreatment__c,
      postCladProcessingSurfaceTreatmentNotes: quoteLineItemDetail.SurfaceTreatmentNotes__c,
      postCladProcessingInhousePackaging: quoteLineItemDetail.NCInHousePackaging__c,
      postCladProcessingInhousePackagingNotes: quoteLineItemDetail.PackagingNotes__c,
      SPWHTGroupACycle1: quoteLineItemDetail.SPWHTGroupACycle1__c,
      SPWHTGroupACycle2: quoteLineItemDetail.SPWHTGroupACycle2__c,
      SPWHTGroupACycle3: quoteLineItemDetail.SPWHTGroupACycle3__c,
      SPWHTGroupAToBePerformed: quoteLineItemDetail.SPWHTGroupAToBePerformed__c,
      SPWHTGroupBCycle1: quoteLineItemDetail.SPWHTGroupBCycle1__c,
      SPWHTGroupBCycle2: quoteLineItemDetail.SPWHTGroupBCycle2__c,
      SPWHTGroupBCycle3: quoteLineItemDetail.SPWHTGroupBCycle3__c,
      SPWHTGroupBToBePerformed: quoteLineItemDetail.SPWHTGroupBToBePerformed__c,
      SPWHTGroupCCycle1: quoteLineItemDetail.SPWHTGroupCCycle1__c,
      SPWHTGroupCCycle2: quoteLineItemDetail.SPWHTGroupCCycle2__c,
      SPWHTGroupCCycle3: quoteLineItemDetail.SPWHTGroupCCycle3__c,
      SPWHTGroupCToBePerformed: quoteLineItemDetail.SPWHTGroupCToBePerformed__c,
      SPWHTHeatingAndCoolingRateAbove: quoteLineItemDetail.SPWHTHeatingAndCoolingRateAbove__c,
      SPWHTHeatingAndCoolingRateAboveUOM: quoteLineItemDetail.SPWHTHeatingAndCoolingRateAboveUOM__c,
      SPWHTMaximumCoolingRateHour: quoteLineItemDetail.SPWHTMaximumCoolingRateHour__c,
      SPWHTMaximumCoolingRateHourUOM: quoteLineItemDetail.SPWHTMaximumCoolingRateHourUOM__c,
      SPWHTMaximumHeatingRateHour: quoteLineItemDetail.SPWHTMaximumHeatingRateHour__c,
      SPWHTMaximumHeatingRateHourUOM: quoteLineItemDetail.SPWHTMaximumHeatingRateHourUOM__c,
    });
  });

  return detailFieldsToUpdate;
};

//Builds the head fields to update for the assembly groups
export const buildAssemblyGroupsHeadFieldsToUpdate = (
  quoteLineItemDetails: QuoteLineItemDetail[]
): Map<string, AdvSpecsAssemblyGroupsHeadFieldsToUpdate> => {
  const headItemFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsHeadFieldsToUpdate> = new Map();

  quoteLineItemDetails?.forEach((quoteLineItemDetail) => {
    const headItemDetail = quoteLineItemDetail.NC_Head_Item_Detail__r;

    // prettier-ignore
    headItemFieldsToUpdate.set(quoteLineItemDetail.Id || quoteLineItemDetail.associatedLineItem, {
      headDiameter: headItemDetail?.HeadDiameter__c,
      headQuantity: headItemDetail?.HeadQuantity__c,
      blankSize: headItemDetail?.BlankSize__c,
      headFormingType: headItemDetail?.HeadFormingType__c,
      straightFlange: headItemDetail?.StraightFlange__c,
      headMaxCenterHole: headItemDetail?.HeadMaxCenterHole__c,
      insideDishRadius: headItemDetail?.InsideDishRadius__c,
      cutback: headItemDetail?.Cutback__c,
      insideKnuckleRadius: headItemDetail?.InsideKnuckleRadius__c,
      openEnd: headItemDetail?.OpenEnd__c,
      openEndNotes: headItemDetail?.OpenEndNotes__c,
      petalToPetal: headItemDetail?.PetalToPetal__c,
      petalToPetalNotes: headItemDetail?.PetalToPetalNotes__c,
      crownToPetals: headItemDetail?.CrownToPetals__c,
      crownToPetalsNotes: headItemDetail?.CrownToPetalsNotes__c,
      headNumberOfPieces: headItemDetail?.HeadNumberOfPieces__c,
      blankCrownCutByNC: headItemDetail?.BlankCrownCutByNC__c,
      petalsCutByNC: headItemDetail?.PetalsCutByNC__c,
      otherCladGroupsCombinedInThisHead: headItemDetail?.OtherCladGroupsCombinedInThisHead__c,
      nobelCladProcedure: headItemDetail?.NobelCladProcedure__c,
      ncChemistryRestrictionsForBonding: headItemDetail?.NCChemistryRestrictionsForBonding__c,
      ncChemistryRestrictionsForBondingNotes: headItemDetail?.NCChemistryRestrictionsForBondingNotes__c,
      headFormingCostPerHead: headItemDetail?.HeadFormingCostPerHead__c,
      headTimeToFormer: headItemDetail?.HeadTimeToFormer__c,
      headTimeToFormHead: headItemDetail?.HeadTimeToFormHead__c,
      poNotes: headItemDetail?.HeadFormingNotes1__c,
      numberOfPiecesInHead: headItemDetail?.NumberOfPiecesInHead__c
    });
  });

  return headItemFieldsToUpdate;
};

//Builds the cylinder fields to update for the assembly groups
export const buildAssemblyGroupsCylinderFieldsToUpdate = (
  quoteLineItemDetails: QuoteLineItemDetail[]
): Map<string, AdvSpecsAssemblyGroupsCylinderItemDetailFieldsToUpdate> => {
  const cylinderItemDetailFieldsToUpdateMap: Map<string, AdvSpecsAssemblyGroupsCylinderItemDetailFieldsToUpdate> = new Map();

  quoteLineItemDetails?.forEach((quoteLineItemDetail) => {
    const cylinderItemDetail = quoteLineItemDetail.NC_Cylinder_Item_Details__r;

    cylinderItemDetailFieldsToUpdateMap.set(quoteLineItemDetail.Id || quoteLineItemDetail.associatedLineItem, {
      cylinderNotes: cylinderItemDetail?.CylinderNotes__c,
      cylinderType: cylinderItemDetail?.CylinderType__c,
      cylinderVendor: cylinderItemDetail?.CylinderVendor__c,
      diameter: cylinderItemDetail?.Diameter__c,
      edgePreparation: cylinderItemDetail?.EdgePreparation__c,
      freightChargeToCustomerPer: cylinderItemDetail?.FreightChargeToCustomerPer__c,
      freightChargeToRollerPer: cylinderItemDetail?.FreightChargeToRollerPer__c,
      innerDiameterOrOuterDiameter: cylinderItemDetail?.IDorOD__c,
      plateLengthSupplyToFormer: cylinderItemDetail?.PlateLengthSupplyToFormer__c,
      plateWidthSupplyToFormer: cylinderItemDetail?.PlateWidthSupplyToFormer__c,
      quantityPerCylinder: cylinderItemDetail?.QuantityPerCylinder__c,
      rollingCostPerCylinder: cylinderItemDetail?.RollingCostPerCylinder__c,
      timeToRollWeeks: cylinderItemDetail?.TimeToRollWeeks__c,
      transitTimeToRollerWeeks: cylinderItemDetail?.TransitTimeToRollerWeeks__c,
    });
  });

  return cylinderItemDetailFieldsToUpdateMap;
};

//Builds the outside service item fields to update for the assembly groups
export const buildAssemblyGroupsOutsideServiceItemFieldsToUpdate = (
  quoteLineItemDetails: QuoteLineItemDetail[]
): Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate> => {
  const outsideServiceFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate> = new Map();

  quoteLineItemDetails?.forEach((quoteLineItemDetail) => {
    const outsideServicesItemDetail = quoteLineItemDetail.NC_Outside_Service_Item_Details__r;

    // prettier-ignore
    outsideServiceFieldsToUpdate.set(quoteLineItemDetail.Id || quoteLineItemDetail.associatedLineItem, {
      headFormingVendor: outsideServicesItemDetail?.HeadFormingVendor__c,
      headFormingIDvsOD: outsideServicesItemDetail?.HeadFormingIDvsOD__c,
      simulateHeatTreatmentAtVendor: outsideServicesItemDetail?.SimulateHeatTreatmentAtVendor__c,
      simulationHTCycle1: outsideServicesItemDetail?.SimulationHTCycle1__c,
      simulationHTCycle2: outsideServicesItemDetail?.SimulationHTCycle2__c,
      simulationHTCycle3: outsideServicesItemDetail?.SimulationHTCycle3__c,
      simulationHTCycle4: outsideServicesItemDetail?.SimulationHTCycle4__c,
      simulationHTCycle5: outsideServicesItemDetail?.SimulationHTCycle5__c,
      headFreightCostToFormer: outsideServicesItemDetail?.HeadFreightCostToFormer__c,
      headContourToleranceHTOptions: outsideServicesItemDetail?.HeadContourToleranceHTOptions__c,
      additionalHeadInformation: outsideServicesItemDetail?.AdditionalHeadInformation__c,
      // TestingBacker
      testingBackerEventDescription: outsideServicesItemDetail?.TestingBKDescriptionOfEvent__c,
      testingBackerEventDuration: outsideServicesItemDetail?.TestingBKDurationOfEventDays__c,
      testingBackerTransitTime: outsideServicesItemDetail?.TestingBKTotalTransitTimeDays__c,
      testingBackerEventCost: outsideServicesItemDetail?.TestingBKCostOfEvent__c,
      testingBackerOutboundShippingCost: outsideServicesItemDetail?.TestingBKOutboundShippingCost__c,
      testingBackerTotalOutboundShippingCost: outsideServicesItemDetail?.TestingBKTotalOutboundShippingCost__c,
      testingBackerInboundShippingCost: outsideServicesItemDetail?.TestingBKInboundShippingCost__c,
      testingBackerTotalInboundShippingCost: outsideServicesItemDetail?.TestingBKTotalInboundShippingCost__c,
      testingBackerVendor: outsideServicesItemDetail?.TestingBKVendor__c,
      testingBackerShouldShipToCustomer: outsideServicesItemDetail?.TestingBKShipToCustomer__c ?? false,
      testingBackerAdditionalNotes: outsideServicesItemDetail?.TestingBKNotes__c,
      // TestingCladder
      testingCladderEventDescription: outsideServicesItemDetail?.TestingCLDescriptionOfEvent__c,
      testingCladderEventDuration: outsideServicesItemDetail?.TestingCLDurationOfEventDays__c,
      testingCladderTransitTime: outsideServicesItemDetail?.TestingCLTotalTransitTimeDays__c,
      testingCladderEventCost: outsideServicesItemDetail?.TestingCLCostOfEvent__c,
      testingCladderOutboundShippingCost: outsideServicesItemDetail?.TestingCLOutboundShippingCost__c,
      testingCladderTotalOutboundShippingCost: outsideServicesItemDetail?.TestingCLTotalOutboundShippingCost__c,
      testingCladderInboundShippingCost: outsideServicesItemDetail?.TestingCLInboundShippingCost__c,
      testingCladderTotalInboundShippingCost: outsideServicesItemDetail?.TestingCLTotalInboundShippingCost__c,
      testingCladderVendor: outsideServicesItemDetail?.TestingCLVendor__c,
      testingCladderShouldShipToCustomer: outsideServicesItemDetail?.TestingCLShipToCustomer__c ?? false,
      testingCladderAdditionalNotes: outsideServicesItemDetail?.TestingCLNotes__c,
      // TestingCladProduct
      testingCladProductEventDescription: outsideServicesItemDetail?.TestingDescriptionOfEvent__c,
      testingCladProductEventDuration: outsideServicesItemDetail?.TestingDurationOfEventDays__c,
      testingCladProductTransitTime: outsideServicesItemDetail?.TestingTotalTransitTimeDays__c,
      testingCladProductEventCost: outsideServicesItemDetail?.TestingCostOfEvent__c,
      testingCladProductOutboundShippingCost: outsideServicesItemDetail?.TestingOutboundShippingCost__c,
      testingCladProductTotalOutboundShippingCost: outsideServicesItemDetail?.TestingTotalOutboundShippingCost__c,
      testingCladProductInboundShippingCost: outsideServicesItemDetail?.TestingInboundShippingCost__c,
      testingCladProductTotalInboundShippingCost: outsideServicesItemDetail?.TestingTotalInboundShippingCost__c,
      testingCladProductVendor: outsideServicesItemDetail?.TestingVendor__c,
      testingCladProductShouldShipToCustomer: outsideServicesItemDetail?.TestingShipToCustomer__c ?? false,
      testingCladProductAdditionalNotes: outsideServicesItemDetail?.TestingNotes__c,
      // HeatTreatBacker
      heatTreatBackerEventDescription: outsideServicesItemDetail?.BackerMetalHTDescriptionOfEvent__c,
      heatTreatBackerTime: outsideServicesItemDetail?.BackerMetalHTDurationOfEventDays__c,
      heatTreatBackerTransitTime: outsideServicesItemDetail?.BackerMetalHTTotalTransitTimeDays__c,
      heatTreatBackerEventCost: outsideServicesItemDetail?.BackerMetalHTCostOfEvent__c,
      heatTreatBackerTotalEventCost: outsideServicesItemDetail?.BackerMetalHTTotalCostOfEvent__c,
      heatTreatBackerOutboundShippingCost: outsideServicesItemDetail?.BackerMetalHTOutboundShippingCost__c,
      heatTreatBackerTotalOutboundShippingCost: outsideServicesItemDetail?.BackerMetalHTTotalOutboundShippingCost__c,
      heatTreatBackerInboundShippingCost: outsideServicesItemDetail?.BackerMetalHTInboundShippingCost__c,
      heatTreatBackerTotalInboundShippingCost: outsideServicesItemDetail?.BackerMetalHTTotalInboundShippingCost__c,
      heatTreatBackerVendor: outsideServicesItemDetail?.BackerMetalHTVendor__c,
      heatTreatBackerShouldShipToCustomer: outsideServicesItemDetail?.BackerMetalHTShipToCustomer__c ?? false,
      heatTreatBackerHeatTreatmentType: outsideServicesItemDetail?.BackerMetalHTType__c,
      heatTreatBackerAdditionalNotes: outsideServicesItemDetail?.BackerMetalHTNotes__c,
      // HeatTreatCladder
      heatTreatCladderEventDescription: outsideServicesItemDetail?.CladderMetalHTDescriptionOfEvent__c,
      heatTreatCladderTime: outsideServicesItemDetail?.CladderMetalHTDurationOfEventDays__c,
      heatTreatCladderTransitTime: outsideServicesItemDetail?.CladderMetalHTTotalTransitTimeDays__c,
      heatTreatCladderEventCost: outsideServicesItemDetail?.CladderMetalHTCostOfEvent__c,
      heatTreatCladderTotalEventCost: outsideServicesItemDetail?.CladderMetalHTTotalCostOfEvent__c,
      heatTreatCladderOutboundShippingCost: outsideServicesItemDetail?.CladderMetalHTOutboundShippingCost__c,
      heatTreatCladderTotalOutboundShippingCost: outsideServicesItemDetail?.CladderMetalHTTotalOutboundShippingCost__c,
      heatTreatCladderInboundShippingCost: outsideServicesItemDetail?.CladderMetalHTInboundShippingCost__c,
      heatTreatCladderTotalInboundShippingCost: outsideServicesItemDetail?.CladderMetalHTTotalInboundShippingCost__c,
      heatTreatCladderVendor: outsideServicesItemDetail?.CladderMetalHTVendor__c,
      heatTreatCladderShouldShipToCustomer: outsideServicesItemDetail?.CladderMetalHTShipToCustomer__c ?? false,
      heatTreatCladderHeatTreatmentType: outsideServicesItemDetail?.CladderMetalHTType__c,
      heatTreatCladderAdditionalNotes: outsideServicesItemDetail?.CladderMetalHTNotes__c,
      // HeatTreatCladProduct
      heatTreatCladProductEventDescription: outsideServicesItemDetail?.PostbondHTDescriptionOfEvent__c,
      heatTreatCladProductTime: outsideServicesItemDetail?.PostbondHTDurationOfEventDays__c,
      heatTreatCladProductTransitTime: outsideServicesItemDetail?.PostbondHTTotalTransitTimeDays__c,
      heatTreatCladProductEventCost: outsideServicesItemDetail?.PostbondHTCostOfEvent__c,
      heatTreatCladProductTotalEventCost: outsideServicesItemDetail?.PostbondHTTotalCostOfEvent__c,
      heatTreatCladProductOutboundShippingCost: outsideServicesItemDetail?.PostbondHTOutboundShippingCost__c,
      heatTreatCladProductTotalOutboundShippingCost: outsideServicesItemDetail?.PostbondHTTotalOutboundShippingCost__c,
      heatTreatCladProductInboundShippingCost: outsideServicesItemDetail?.PostbondHTInboundShippingCost__c,
      heatTreatCladProductTotalInboundShippingCost: outsideServicesItemDetail?.PostbondHTTotalInboundShippingCost__c,
      heatTreatCladProductVendor: outsideServicesItemDetail?.PostbondHTVendor__c,
      heatTreatCladProductShouldShipToCustomer: outsideServicesItemDetail?.PostbondHTShipToCustomer__c ?? false,
      heatTreatCladProductHeatTreatmentType: outsideServicesItemDetail?.PostbondHTType__c,
      heatTreatCladProductAdditionalNotes: outsideServicesItemDetail?.PostbondHTNotes__c,
      // Welding
      weldingEventDescription: outsideServicesItemDetail?.WeldingDescriptionOfEvent__c,
      weldingTime: outsideServicesItemDetail?.WeldingDurationOfEventDays__c,
      weldingTransitTime: outsideServicesItemDetail?.WeldingTotalTransitTimeDays__c,
      weldingEventCost: outsideServicesItemDetail?.WeldingCostOfEvent__c,
      weldingOutboundShippingCost: outsideServicesItemDetail?.WeldingOutboundShippingCost__c,
      weldingTotalOutboundShippingCost: outsideServicesItemDetail?.WeldingTotalOutboundShippingCost__c,
      weldingInboundShippingCost: outsideServicesItemDetail?.WeldingInboundShippingCost__c,
      weldingTotalInboundShippingCost: outsideServicesItemDetail?.WeldingTotalInboundShippingCost__c,
      weldingVendor: outsideServicesItemDetail?.WeldingVendor__c,
      weldingShouldShipToCustomer: outsideServicesItemDetail?.WeldingShipToCustomer__c ?? false,
      weldingAdditionalNotes: outsideServicesItemDetail?.WeldingNotes__c,
      // X-Ray
      xRayEventDescription: outsideServicesItemDetail?.XRayServicesDescriptionOfEvent__c,
      xRayTime: outsideServicesItemDetail?.XRayServicesDurationOfEventDays__c,
      xRayTransitTime: outsideServicesItemDetail?.XRayServicesTotalTransitTimeDays__c,
      xRayEventCost: outsideServicesItemDetail?.XRayServicesCostOfEvent__c,
      xRayOutboundShippingCost: outsideServicesItemDetail?.XRayServicesOutboundShippingCost__c,
      xRayTotalOutboundShippingCost: outsideServicesItemDetail?.XRayServicesTotalOutboundShippingCost__c,
      xRayInboundShippingCost: outsideServicesItemDetail?.XRayServicesInboundShippingCost__c,
      xRayTotalInboundShippingCost: outsideServicesItemDetail?.XRayServicesTotalInboundShippingCost__c,
      xRayVendor: outsideServicesItemDetail?.XRayServicesVendor__c,
      xRayShouldShipToCustomer: outsideServicesItemDetail?.XRayServicesShipToCustomer__c ?? false,
      xRayAdditionalNotes: outsideServicesItemDetail?.XRayServicesNotes__c,
      // PreCutFlattening
      preCutFlatteningEventDescription: outsideServicesItemDetail?.FlatteningBKDescriptionOfEvent__c,
      preCutFlatteningTime: outsideServicesItemDetail?.FlatteningBKDurationOfEventDays__c,
      preCutFlatteningTransitTime: outsideServicesItemDetail?.FlatteningBKTotalTransitTimeDays__c,
      preCutFlatteningEventCost: outsideServicesItemDetail?.FlatteningBKCostOfEvent__c,
      preCutFlatteningOutboundShippingCost: outsideServicesItemDetail?.FlatteningBKOutboundShippingCost__c,
      preCutFlatteningTotalOutboundShippingCost: outsideServicesItemDetail?.FlatteningBKTotalOutboundShippingCost__c,
      preCutFlatteningInboundShippingCost: outsideServicesItemDetail?.FlatteningBKInboundShippingCost__c,
      preCutFlatteningTotalInboundShippingCost: outsideServicesItemDetail?.FlatteningBKTotalInboundShippingCost__c,
      preCutFlatteningVendor: outsideServicesItemDetail?.FlatteningBKVendor__c,
      preCutFlatteningShouldShipToCustomer: outsideServicesItemDetail?.FlatteningBKShipToCustomer__c ?? false,
      preCutFlatteningAdditionalNotes: outsideServicesItemDetail?.FlatteningBKNotes__c,
      // Cutting
      cuttingEventDescription: outsideServicesItemDetail?.CuttingDescriptionOfEvent__c,
      cuttingTime: outsideServicesItemDetail?.CuttingDurationOfEventDays__c,
      cuttingTransitTime: outsideServicesItemDetail?.CuttingTotalTransitTimeDays__c,
      cuttingEventCost: outsideServicesItemDetail?.CuttingCostOfEvent__c,
      cuttingOutboundShippingCost: outsideServicesItemDetail?.CuttingOutboundShippingCost__c,
      cuttingTotalOutboundShippingCost: outsideServicesItemDetail?.CuttingTotalOutboundShippingCost__c,
      cuttingInboundShippingCost: outsideServicesItemDetail?.CuttingInboundShippingCost__c,
      cuttingTotalInboundShippingCost: outsideServicesItemDetail?.CuttingTotalInboundShippingCost__c,
      cuttingVendor: outsideServicesItemDetail?.CuttingVendor__c,
      cuttingShouldShipToCustomer: outsideServicesItemDetail?.CuttingShipToCustomer__c ?? false,
      cuttingAdditionalNotes: outsideServicesItemDetail?.CuttingNotes__c,
      // WaterjetCutting
      waterjetCuttingEventDescription: outsideServicesItemDetail?.WaterJetCuttingDescriptionOfEvent__c,
      waterjetCuttingTime: outsideServicesItemDetail?.WaterJetCuttingDurationOfEventDays__c,
      waterjetCuttingTransitTime: outsideServicesItemDetail?.WaterJetCuttingTotalTransitTimeDays__c,
      waterjetCuttingEventCost: outsideServicesItemDetail?.WaterJetCuttingCostOfEvent__c,
      waterjetCuttingOutboundShippingCost: outsideServicesItemDetail?.WaterJetCuttingOutboundShippingCost__c,
      waterjetCuttingTotalOutboundShippingCost: outsideServicesItemDetail?.WaterJetCuttingTotalOutboundShippingCost__c,
      waterjetCuttingInboundShippingCost: outsideServicesItemDetail?.WaterJetCuttingInboundShippingCost__c,
      waterjetCuttingTotalInboundShippingCost: outsideServicesItemDetail?.WaterJetCuttingTotalInboundShippingCost__c,
      waterjetCuttingVendor: outsideServicesItemDetail?.WaterJetCuttingVendor__c,
      waterjetCuttingShouldShipToCustomer: outsideServicesItemDetail?.WaterJetCuttingShipToCustomer__c ?? false,
      waterjetCuttingAdditionalNotes: outsideServicesItemDetail?.WaterJetCuttingNotes__c,
      // PostCutFlattening
      postCutFlatteningEventDescription: outsideServicesItemDetail?.FlatteningDescriptionOfEvent__c,
      postCutFlatteningTime: outsideServicesItemDetail?.FlatteningDurationOfEventDays__c,
      postCutFlatteningTransitTime: outsideServicesItemDetail?.FlatteningTotalTransitTimeDays__c,
      postCutFlatteningEventCost: outsideServicesItemDetail?.FlatteningCostOfEvent__c,
      postCutFlatteningOutboundShippingCost: outsideServicesItemDetail?.FlatteningOutboundShippingCost__c,
      postCutFlatteningTotalOutboundShippingCost: outsideServicesItemDetail?.FlatteningTotalOutboundShippingCost__c,
      postCutFlatteningInboundShippingCost: outsideServicesItemDetail?.FlatteningInboundShippingCost__c,
      postCutFlatteningTotalInboundShippingCost: outsideServicesItemDetail?.FlatteningTotalInboundShippingCost__c,
      postCutFlatteningVendor: outsideServicesItemDetail?.FlatteningVendor__c,
      postCutFlatteningShouldShipToCustomer: outsideServicesItemDetail?.FlatteningShipToCustomer__c ?? false,
      postCutFlatteningAdditionalNotes: outsideServicesItemDetail?.FlatteningNotes__c,
      // Machining
      machiningEventDescription: outsideServicesItemDetail?.MachiningDescriptionOfEvent__c,
      machiningTime: outsideServicesItemDetail?.MachiningDurationOfEventDays__c,
      machiningTransitTime: outsideServicesItemDetail?.MachiningTotalTransitTimeDays__c,
      machiningEventCost: outsideServicesItemDetail?.MachiningCostOfEvent__c,
      machiningOutboundShippingCost: outsideServicesItemDetail?.MachiningOutboundShippingCost__c,
      machiningTotalOutboundShippingCost: outsideServicesItemDetail?.MachiningTotalOutboundShippingCost__c,
      machiningInboundShippingCost: outsideServicesItemDetail?.MachiningInboundShippingCost__c,
      machiningTotalInboundShippingCost: outsideServicesItemDetail?.MachiningTotalInboundShippingCost__c,
      machiningVendor: outsideServicesItemDetail?.MachiningVendor__c,
      machiningShouldShipToCustomer: outsideServicesItemDetail?.MachiningShipToCustomer__c ?? false,
      machiningAdditionalNotes: outsideServicesItemDetail?.MachiningNotes__c,
      // InspectionBacker
      inspectionBackerEventDescription: outsideServicesItemDetail?.InspectionBKDescriptionOfEvent__c,
      inspectionBackerInspectionTime: outsideServicesItemDetail?.InspectionBKDurationOfEventDays__c,
      inspectionBackerTransitTime: outsideServicesItemDetail?.InspectionBKTotalTransitTimeDays__c,
      inspectionBackerEventCost: outsideServicesItemDetail?.InspectionBKCostOfEvent__c,
      inspectionBackerOutboundShippingCost: outsideServicesItemDetail?.InspectionBKOutboundShippingCost__c,
      inspectionBackerTotalOutboundShippingCost: outsideServicesItemDetail?.InspectionBKTotalOutboundShippingCost__c,
      inspectionBackerInboundShippingCost: outsideServicesItemDetail?.InspectionBKInboundShippingCost__c,
      inspectionBackerTotalInboundShippingCost: outsideServicesItemDetail?.InspectionBKTotalInboundShippingCost__c,
      inspectionBackerVendor: outsideServicesItemDetail?.InspectionBKVendor__c,
      inspectionBackerShouldShipToCustomer: outsideServicesItemDetail?.InspectionBKShipToCustomer__c ?? false,
      inspectionBackerAdditionalNotes: outsideServicesItemDetail?.InspectionBKNotes__c,
      // InspectionCladder
      inspectionCladderEventDescription: outsideServicesItemDetail?.InspectionCLDescriptionOfEvent__c,
      inspectionCladderInspectionTime: outsideServicesItemDetail?.InspectionCLDurationOfEventDays__c,
      inspectionCladderTransitTime: outsideServicesItemDetail?.InspectionCLTotalTransitTimeDays__c,
      inspectionCladderEventCost: outsideServicesItemDetail?.InspectionCLCostOfEvent__c,
      inspectionCladderOutboundShippingCost: outsideServicesItemDetail?.InspectionCLOutboundShippingCost__c,
      inspectionCladderTotalOutboundShippingCost: outsideServicesItemDetail?.InspectionCLTotalOutboundShippingCost__c,
      inspectionCladderInboundShippingCost: outsideServicesItemDetail?.InspectionCLInboundShippingCost__c,
      inspectionCladderTotalInboundShippingCost: outsideServicesItemDetail?.InspectionCLTotalInboundShippingCost__c,
      inspectionCladderVendor: outsideServicesItemDetail?.InspectionCLVendor__c,
      inspectionCladderShouldShipToCustomer: outsideServicesItemDetail?.InspectionCLShipToCustomer__c ?? false,
      inspectionCladderAdditionalNotes: outsideServicesItemDetail?.InspectionCLNotes__c,
      // InspectionCladProduct
      inspectionCladProductEventDescription: outsideServicesItemDetail?.InspectionDescriptionOfEvent__c,
      inspectionCladProductInspectionTime: outsideServicesItemDetail?.InspectionDurationOfEventDays__c,
      inspectionCladProductTransitTime: outsideServicesItemDetail?.InspectionTotalTransitTimeDays__c,
      inspectionCladProductEventCost: outsideServicesItemDetail?.InspectionCostOfEvent__c,
      inspectionCladProductOutboundShippingCost: outsideServicesItemDetail?.InspectionOutboundShippingCost__c,
      inspectionCladProductTotalOutboundShippingCost: outsideServicesItemDetail?.InspectionTotalOutboundShippingCost__c,
      inspectionCladProductInboundShippingCost: outsideServicesItemDetail?.InspectionInboundShippingCost__c,
      inspectionCladProductTotalInboundShippingCost: outsideServicesItemDetail?.InspectionTotalInboundShippingCost__c,
      inspectionCladProductVendor: outsideServicesItemDetail?.InspectionVendor__c,
      inspectionCladProductShouldShipToCustomer: outsideServicesItemDetail?.InspectionShipToCustomer__c ?? false,
      inspectionCladProductAdditionalNotes: outsideServicesItemDetail?.InspectionNotes__c,
      // Packaging
      packagingEventDescription: outsideServicesItemDetail?.PackagingDescriptionOfEvent__c,
      packagingTime: outsideServicesItemDetail?.PackagingDurationOfEventDays__c,
      packagingTransitTime: outsideServicesItemDetail?.PackagingTotalTransitTimeDays__c,
      packagingEventCost: outsideServicesItemDetail?.PackagingCostOfEvent__c,
      packagingOutboundShippingCost: outsideServicesItemDetail?.PackagingOutboundShippingCost__c,
      packagingTotalOutboundShippingCost: outsideServicesItemDetail?.PackagingTotalOutboundShippingCost__c,
      packagingInboundShippingCost: outsideServicesItemDetail?.PackagingInboundShippingCost__c,
      packagingTotalInboundShippingCost: outsideServicesItemDetail?.PackagingTotalInboundShippingCost__c,
      packagingVendor: outsideServicesItemDetail?.PackagingVendor__c,
      packagingShouldShipToCustomer: outsideServicesItemDetail?.PackagingShipToCustomer__c ?? false,
      packagingAdditionalNotes: outsideServicesItemDetail?.PackagingNotes__c,
      // PostcladCanRolling
      postcladCanRollingEventDescription: outsideServicesItemDetail?.CanRollingDescriptionOfEvent__c,
      postcladCanRollingTime: outsideServicesItemDetail?.CanRollingDurationOfEventDays__c,
      postcladCanRollingTransitTime: outsideServicesItemDetail?.CanRollingTotalTransitTimeDays__c,
      postcladCanRollingEventCost: outsideServicesItemDetail?.CanRollingCostOfEvent__c,
      postcladCanRollingOutboundShippingCost: outsideServicesItemDetail?.CanRollingOutboundShippingCost__c,
      postcladCanRollingTotalOutboundShippingCost: outsideServicesItemDetail?.CanRollingTotalOutboundShippingCost__c,
      postcladCanRollingInboundShippingCost: outsideServicesItemDetail?.CanRollingInboundShippingCost__c,
      postcladCanRollingTotalInboundShippingCost: outsideServicesItemDetail?.CanRollingTotalInboundShippingCost__c,
      postcladCanRollingVendor: outsideServicesItemDetail?.CanRollingVendor__c,
      postcladCanRollingShouldShipToCustomer: outsideServicesItemDetail?.CanRollingShipToCustomer__c ?? false,
      postcladCanRollingAdditionalNotes: outsideServicesItemDetail?.CanRollingNotes__c,
    });
  });

  return outsideServiceFieldsToUpdate;
};


//Builds the options required for the metal combos
export const buildMetalComboOptionsRequired = (
  displayedQuoteGroup: DisplayedQuoteGroup,
  detailFieldsToUpdate: { [key: string]: AdvSpecsMetalComboDetailFieldsToUpdate }
): { [key: string]: { [key: string]: boolean } } => {
  const optionsRequired: { [key: string]: { [key: string]: boolean } } = {};

  Array.from(displayedQuoteGroup.metalComboToQlisMap.keys()).forEach((metalCombo) => {
    // prettier-ignore
    optionsRequired[metalCombo] = {
      customerSpecifiedImpactTestingRequired: !!(postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.ImpactLCVN) ||
        postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.ImpactTCVN) ||
        detailFieldsToUpdate[metalCombo].customerSpecifiedImpactTesting ||
        detailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestToBePerformed ||
        detailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestLocation ||
        detailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestDirection ||
        detailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestUOM ||
        detailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestAVG != '' ||
        detailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestMIN != '' ||
        detailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestTemperature != '' ||
        detailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestTempUOM),
      customerSpecifiedSPWHTOfTestCouponsRequired: !!(detailFieldsToUpdate[metalCombo].customerSpecifiedSPWHTTestCoupons ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupACycle1 ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupACycle2 ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupACycle3 ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupAToBePerformed ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupBCycle1 ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupBCycle2 ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupBCycle3 ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupBToBePerformed ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupCCycle1 ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupCCycle2 ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupCCycle3 ||
        detailFieldsToUpdate[metalCombo].SPWHTGroupCToBePerformed ||
        detailFieldsToUpdate[metalCombo].SPWHTHeatingAndCoolingRateAbove ||
        detailFieldsToUpdate[metalCombo].SPWHTHeatingAndCoolingRateAboveUOM ||
        detailFieldsToUpdate[metalCombo].SPWHTMaximumCoolingRateHour ||
        detailFieldsToUpdate[metalCombo].SPWHTMaximumCoolingRateHourUOM ||
        detailFieldsToUpdate[metalCombo].SPWHTMaximumHeatingRateHour ||
        detailFieldsToUpdate[metalCombo].SPWHTMaximumHeatingRateHourUOM),
      shearTestRequired: !!(postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.Shear) ||
        detailFieldsToUpdate[metalCombo].shearTestValue != '' ||
        detailFieldsToUpdate[metalCombo].shearTestUOM),
      tensileTestRequired: !!(postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.Tensile) ||
        detailFieldsToUpdate[metalCombo].cladTensileTestLocation),
      ramTensileTestRequired: !!(postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.RamTensile) ||
        detailFieldsToUpdate[metalCombo].bondStrengthTensileTestRamValue != '' ||
        detailFieldsToUpdate[metalCombo].bondStrengthTensileTestRamUOM),
      hardnessCladdingMetalSurfaceRequired: !!(detailFieldsToUpdate[metalCombo].hardnessCladdingMetalSurface || detailFieldsToUpdate[metalCombo].hardnessCladdingRejectionCriteria),
      hardnessBaseMetalSurfaceRequired: !!(detailFieldsToUpdate[metalCombo].hardnessBaseMetalSurface || detailFieldsToUpdate[metalCombo].hardnessBaseRejectionCriteria),
      pmiConfirmCladdingMetalRequired: !!(detailFieldsToUpdate[metalCombo].PMIConfirmCladdingMetal || detailFieldsToUpdate[metalCombo].PMIConfirmCladdingMetalNotes),
      pmiConfirmBaseMetalRequired: !!(detailFieldsToUpdate[metalCombo].PMIConfirmBaseMetal || detailFieldsToUpdate[metalCombo].PMIConfirmBaseMetalNotes),
      sa26345BondStrengthRequired: postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.SA26345BondStrength),
      b898BendRequired: postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.B898Bend),
      adw8BendRequired: postCladTestingOptionIsGlobal(displayedQuoteGroup.metalComboToQlisMap.get(metalCombo), PostCladTestingOptions.ADW8Bend),
      dyePenetrantRequired: !!detailFieldsToUpdate[metalCombo].dyePenetrantNotes,
      cladCustomerChemistryRestrictionsRequired: !!detailFieldsToUpdate[metalCombo].customerCladChemistryRestrictionsNotes,
      corrosionTestingRequired: !!(detailFieldsToUpdate[metalCombo].corrosionTesting || detailFieldsToUpdate[metalCombo].corrosionTestingNotes),
      cladMetalSpecEdAndAdRequired: !!(detailFieldsToUpdate[metalCombo].cladMetalSpecEdition || detailFieldsToUpdate[metalCombo].cladMetalSpecAddendum),
      cladCustomerMetalSupplierRestrictionsRequired: !!(detailFieldsToUpdate[metalCombo].customerCladMetalSupplierRestrictions || detailFieldsToUpdate[metalCombo].custCladMetalSupplierRestrictionsNotes),
      cladMetalProductAnalysisRequired: !!(detailFieldsToUpdate[metalCombo].cladMetalProductAnalysisRequired ||
        detailFieldsToUpdate[metalCombo].cladMetalProductAnalysisRequiredNotes ||
        detailFieldsToUpdate[metalCombo].cladMetalProductAnalysisType),
      ferroxylTestOfCladdingSurfacePerformedRequired: !!(detailFieldsToUpdate[metalCombo].ferroxylTestPerformed || detailFieldsToUpdate[metalCombo].ferroxylNotes),
      ferriteNumberRequired: detailFieldsToUpdate[metalCombo].ferriteNumberRequired != '',
      precladBaseMetalUTRequired: !!(detailFieldsToUpdate[metalCombo].precladBaseMetalUT ||
        detailFieldsToUpdate[metalCombo].precladBaseMetalUTNotes ||
        detailFieldsToUpdate[metalCombo].precladBaseMetalUTType),
      carbonPercentRequired: detailFieldsToUpdate[metalCombo].carbonPercent != '',
      sulphurPercentRequired: detailFieldsToUpdate[metalCombo].sulphur != '',
      phosphorousPercentRequired: detailFieldsToUpdate[metalCombo].phosphorusPercent != '',
      carbonEquivalencyPercentRequired: detailFieldsToUpdate[metalCombo].carbonEquivalencyPercent != '',
      jFactorSA387S62Required: detailFieldsToUpdate[metalCombo].jFactorSA387S62 != '',
      xFactorSA387S62Required: detailFieldsToUpdate[metalCombo].xFactorSA387S62 != '',
      baseCustomerChemistryRestrictionsRequired: !!detailFieldsToUpdate[metalCombo].customerBaseChemistryRestrictions,
      baseTensileTestRequired: !!detailFieldsToUpdate[metalCombo].baseTensileTestLocation,
      hotTensileTestPerSA20S7Required: !!(detailFieldsToUpdate[metalCombo].hotTensileTestPerSA20S7Degrees != '' ||
        detailFieldsToUpdate[metalCombo].hotTensileTestPerSA20S7UOM ||
        detailFieldsToUpdate[metalCombo].hotTensileTestPerSA20S7Notes),
      hicTestingRequired: !!detailFieldsToUpdate[metalCombo].hicTestNotes,
      austeniticGrainSizeFinerThanRequired: detailFieldsToUpdate[metalCombo].austeniticGrainSizeFinerThanValue != '',
      baseMetalSpecAndAdRequired: !!(detailFieldsToUpdate[metalCombo].baseMetalSpecEdition || detailFieldsToUpdate[metalCombo].baseMetalSpecAddendum),
      baseCustomerMetalSupplierRestrictionsRequired: !!(detailFieldsToUpdate[metalCombo].customerBaseMetalSupplierRestrictions || detailFieldsToUpdate[metalCombo].custBaseMetalSupplierRestrictionsNotes),
      baseMetalProductAnalysisRequired: !!(detailFieldsToUpdate[metalCombo].baseMetalProductAnalysisRequired ||
        detailFieldsToUpdate[metalCombo].baseMetalProductAnalysisRequiredNotes ||
        detailFieldsToUpdate[metalCombo].baseMetalProductAnalysisType),
      prebondImpactsRequired: !!(detailFieldsToUpdate[metalCombo].prebondImpacts ||
        detailFieldsToUpdate[metalCombo].preBondImpactsTestLocation ||
        detailFieldsToUpdate[metalCombo].preBondImpactsTestDirection ||
        detailFieldsToUpdate[metalCombo].preBondImpactsTestUOM ||
        detailFieldsToUpdate[metalCombo].preBondImpactsTestAVG != '' ||
        detailFieldsToUpdate[metalCombo].preBondImpactsTestMIN != '' ||
        detailFieldsToUpdate[metalCombo].preBondImpactsTestTemperature != '' ||
        detailFieldsToUpdate[metalCombo].preBondImpactsTestTemperatureUOM),
      leakTestRequired: !!(detailFieldsToUpdate[metalCombo].leakTest ||
        detailFieldsToUpdate[metalCombo].leakTestGas ||
        detailFieldsToUpdate[metalCombo].leakTestReqt ||
        detailFieldsToUpdate[metalCombo].leakTestValue ||
        detailFieldsToUpdate[metalCombo].leakTestPressure),
    };
  });

  return optionsRequired;
};

//Builds the options required for the assembly groups
export const buildAssemblyGroupOptionsRequired = (
  displayedQuoteGroup: DisplayedQuoteGroup,
  assemblyGroupDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>
): Map<string, { [key: string]: boolean }> => {
  const optionsRequired: Map<string, { [key: string]: boolean }> = new Map();

  displayedQuoteGroup.quoteLineItemDetails.forEach((quoteLineItemDetail) => {
    const quoteLineItemDetailId = quoteLineItemDetail.Id || quoteLineItemDetail.associatedLineItem;
    // prettier-ignore
    optionsRequired.set(quoteLineItemDetailId, {
      customerSpecifiedImpactTestingRequired: !!(postCladTestingOptionIncluded(displayedQuoteGroup.qlid2qliMap.get(quoteLineItemDetail), PostCladTestingOptions.ImpactLCVN) ||
        postCladTestingOptionIncluded(displayedQuoteGroup.qlid2qliMap.get(quoteLineItemDetail), PostCladTestingOptions.ImpactTCVN) ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTesting ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTestToBePerformed ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTestLocation ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTestDirection ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTestUOM ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTestAVG != '' ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTestMIN != '' ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTestTemperature != '' ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTestTempUOM),
      customerSpecifiedSPWHTOfTestCouponsRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedSPWHTTestCoupons ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupACycle1 ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupACycle2 ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupACycle3 ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupAToBePerformed ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupBCycle1 ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupBCycle2 ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupBCycle3 ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupBToBePerformed ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupCCycle1 ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupCCycle2 ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupCCycle3 ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTGroupCToBePerformed ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTHeatingAndCoolingRateAbove ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTHeatingAndCoolingRateAboveUOM ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTMaximumCoolingRateHour ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTMaximumCoolingRateHourUOM ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTMaximumHeatingRateHour ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).SPWHTMaximumHeatingRateHourUOM),
      shearTestRequired: !!(postCladTestingOptionIncluded(displayedQuoteGroup.qlid2qliMap.get(quoteLineItemDetail), PostCladTestingOptions.Shear) ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).shearTestValue != '' ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).shearTestUOM),
      tensileTestRequired: !!(postCladTestingOptionIncluded(displayedQuoteGroup.qlid2qliMap.get(quoteLineItemDetail), PostCladTestingOptions.Tensile) ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).cladTensileTestLocation),
      ramTensileTestRequired: !!(postCladTestingOptionIncluded(displayedQuoteGroup.qlid2qliMap.get(quoteLineItemDetail), PostCladTestingOptions.RamTensile) ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).bondStrengthTensileTestRamValue != '' ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).bondStrengthTensileTestRamUOM),
      hardnessCladdingMetalSurfaceRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).hardnessCladdingMetalSurface || assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).hardnessCladdingRejectionCriteria),
      hardnessBaseMetalSurfaceRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).hardnessBaseMetalSurface || assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).hardnessBaseRejectionCriteria),
      pmiConfirmCladdingMetalRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).PMIConfirmCladdingMetal || assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).PMIConfirmCladdingMetalNotes),
      pmiConfirmBaseMetalRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).PMIConfirmBaseMetal || assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).PMIConfirmBaseMetalNotes),
      sa26345BondStrengthRequired: postCladTestingOptionIncluded(displayedQuoteGroup.qlid2qliMap.get(quoteLineItemDetail), PostCladTestingOptions.SA26345BondStrength),
      b898BendRequired: postCladTestingOptionIncluded(displayedQuoteGroup.qlid2qliMap.get(quoteLineItemDetail), PostCladTestingOptions.B898Bend),
      adw8BendRequired: postCladTestingOptionIncluded(displayedQuoteGroup.qlid2qliMap.get(quoteLineItemDetail), PostCladTestingOptions.ADW8Bend),
      dyePenetrantRequired: !!assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).dyePenetrantNotes,
      cladCustomerChemistryRestrictionsRequired: !!assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerCladChemistryRestrictionsNotes,
      corrosionTestingRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).corrosionTesting || assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).corrosionTestingNotes),
      cladMetalSpecEdAndAdRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).cladMetalSpecEdition || assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).cladMetalSpecAddendum),
      cladCustomerMetalSupplierRestrictionsRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerCladMetalSupplierRestrictions || assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).custCladMetalSupplierRestrictionsNotes),
      cladMetalProductAnalysisRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).cladMetalProductAnalysisRequired ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).cladMetalProductAnalysisRequiredNotes ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).cladMetalProductAnalysisType),
      ferroxylTestOfCladdingSurfacePerformedRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).ferroxylTestPerformed || assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).ferroxylNotes),
      ferriteNumberRequired: assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).ferriteNumberRequired != '',
      precladBaseMetalUTRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).precladBaseMetalUT ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).precladBaseMetalUTNotes ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).precladBaseMetalUTType),
      carbonPercentRequired: assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).carbonPercent != '',
      sulphurPercentRequired: assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).sulphur != '',
      phosphorousPercentRequired: assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).phosphorusPercent != '',
      carbonEquivalencyPercentRequired: assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).carbonEquivalencyPercent != '',
      jFactorSA387S62Required: assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).jFactorSA387S62 != '',
      xFactorSA387S62Required: assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).xFactorSA387S62 != '',
      baseCustomerChemistryRestrictionsRequired: !!assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerBaseChemistryRestrictions,
      baseTensileTestRequired: !!assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).baseTensileTestLocation,
      hotTensileTestPerSA20S7Required: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).hotTensileTestPerSA20S7Degrees != '' ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).hotTensileTestPerSA20S7UOM ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).hotTensileTestPerSA20S7Notes),
      hicTestingRequired: !!assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).hicTestNotes,
      austeniticGrainSizeFinerThanRequired: assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).austeniticGrainSizeFinerThanValue != '',
      baseMetalSpecAndAdRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).baseMetalSpecEdition || assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).baseMetalSpecAddendum),
      baseCustomerMetalSupplierRestrictionsRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerBaseMetalSupplierRestrictions || assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).custBaseMetalSupplierRestrictionsNotes),
      baseMetalProductAnalysisRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).baseMetalProductAnalysisRequired ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).baseMetalProductAnalysisRequiredNotes ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).baseMetalProductAnalysisType),
      prebondImpactsRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).prebondImpacts ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).preBondImpactsTestLocation ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).preBondImpactsTestDirection ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).preBondImpactsTestUOM ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).preBondImpactsTestAVG != '' ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).preBondImpactsTestMIN != '' ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).preBondImpactsTestTemperature != '' ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).preBondImpactsTestTemperatureUOM),
      leakTestRequired: !!(assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).leakTest ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).leakTestGas ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).leakTestReqt ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).leakTestValue ||
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).leakTestPressure),
    });
  });

  return optionsRequired;
};

//Builds the selections for the metal combos
export const buildMetalComboSelections = (
  displayedQuoteGroup: DisplayedQuoteGroup,
  detailFieldsToUpdate: { [key: string]: AdvSpecsMetalComboDetailFieldsToUpdate },
  manufacturingSite: ManufacturingSites,
  specificationTestOptions: SpecificationTestOptions[]
): { [key: string]: AdvSpecMetalComboSelections } => {
  const selections: { [key: string]: AdvSpecMetalComboSelections } = {};
  try{

    Array.from(displayedQuoteGroup.metalComboToQlisMap.keys()).forEach((metalCombo) => {
      selections[metalCombo] = {
        postCladBondUTSpecification: [...specificationTestOptions.filter((specificationTestOption) => specificationTestOption.specification === detailFieldsToUpdate[metalCombo]?.cladProductionSpecification.split('&')[0].trim() && specificationTestOption.dataAreaId === manufacturingSite).map(specificationTestOption => `${specificationTestOption.specification}: ${specificationTestOption.option}`)]
      };
    });
  }
  catch(error){
    console.log(error);
  }

  return selections;
};

// Updates post clad testing options for metal combo based on what is required
export const updateMetalComboPostCladTestingOptions = (testRequired: boolean, postCladTestingOption: PostCladTestingOptions, metalCombo: string, metalComboLineFieldsToUpdate: {[key: string]: AdvSpecsMetalComboLineFieldsToUpdate}, metalComboDetailFieldsToUpdate: {[key: string]: AdvSpecsMetalComboDetailFieldsToUpdate}) => {
  let updatedPostCladTestingOptions = metalComboLineFieldsToUpdate[metalCombo]?.postCladTestingOptions?.split(';') || [];

  if(testRequired) {
    if(!updatedPostCladTestingOptions.includes(postCladTestingOption) &&
    postCladTestingOption != PostCladTestingOptions.ImpactLCVN &&
    postCladTestingOption != PostCladTestingOptions.ImpactTCVN) {
      updatedPostCladTestingOptions.push(postCladTestingOption);
      metalComboLineFieldsToUpdate[metalCombo].postCladTestingOptions = [...updatedPostCladTestingOptions].join(';');
    }
  } else if(updatedPostCladTestingOptions.includes(postCladTestingOption)) {
    updatedPostCladTestingOptions = updatedPostCladTestingOptions.filter(option => option != postCladTestingOption);

    switch(postCladTestingOption) {
    case PostCladTestingOptions.Shear:
      metalComboDetailFieldsToUpdate[metalCombo].shearTestValue = null;
      metalComboDetailFieldsToUpdate[metalCombo].shearTestUOM = null;
      break;
    case PostCladTestingOptions.Tensile:
      metalComboDetailFieldsToUpdate[metalCombo].cladTensileTestLocation = null;
      break;
    case PostCladTestingOptions.RamTensile:
      metalComboDetailFieldsToUpdate[metalCombo].bondStrengthTensileTestRamValue = null;
      metalComboDetailFieldsToUpdate[metalCombo].bondStrengthTensileTestRamUOM = null;
      break;
    case PostCladTestingOptions.ImpactLCVN:
      if(!updatedPostCladTestingOptions.includes(PostCladTestingOptions.ImpactTCVN)) {
        metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTesting = false;
      }
      break;
    case PostCladTestingOptions.ImpactTCVN:
      if(!updatedPostCladTestingOptions.includes(PostCladTestingOptions.ImpactLCVN)) {
        metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTesting = false;
      }
      break;
    case PostCladTestingOptions.SA26345BondStrength:
      metalComboDetailFieldsToUpdate[metalCombo].machineBondStrengthBendsperSA26345 = false;
      break;
    case PostCladTestingOptions.B898Bend:
      metalComboDetailFieldsToUpdate[metalCombo].bendB898 = false;
      break;
    case PostCladTestingOptions.ADW8Bend:
      metalComboDetailFieldsToUpdate[metalCombo].ADW8Bend = false;
      break;
    }

    metalComboLineFieldsToUpdate[metalCombo].postCladTestingOptions = [...updatedPostCladTestingOptions].join(';');
  }
};

// Updates assembly group post clad testing options based on what is required
export const updateAssemblyGroupPostCladTestingOptions = (assemblyGroupLineFieldsToUpdate: {[key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate}, assemblyGroupDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>, testRequired: boolean, postCladTestingOption: PostCladTestingOptions, quoteLineItemId: string, quoteLineItemDetail: QuoteLineItemDetail) => {

  let updatedPostCladTestingOptions = assemblyGroupLineFieldsToUpdate[quoteLineItemId]?.postCladTestingOptions?.split(';') || [];

  if(!testRequired && updatedPostCladTestingOptions.includes(postCladTestingOption)) {
    updatedPostCladTestingOptions = updatedPostCladTestingOptions.filter(option => option != postCladTestingOption);

    const quoteLineItemDetailId = quoteLineItemDetail.Id || quoteLineItemDetail.associatedLineItem;
    switch(postCladTestingOption) {
    case PostCladTestingOptions.Shear:
      assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).shearTestValue = null;
      assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).shearTestUOM = null;
      break;
    case PostCladTestingOptions.Tensile:
      assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).cladTensileTestLocation = null;
      break;
    case PostCladTestingOptions.RamTensile:
      assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).bondStrengthTensileTestRamValue = null;
      assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).bondStrengthTensileTestRamUOM = null;
      break;
    case PostCladTestingOptions.ImpactLCVN:
      if(!updatedPostCladTestingOptions.includes(PostCladTestingOptions.ImpactTCVN)) {
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTesting = false;
      }
      break;
    case PostCladTestingOptions.ImpactTCVN:
      if(!updatedPostCladTestingOptions.includes(PostCladTestingOptions.ImpactLCVN)) {
        assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).customerSpecifiedImpactTesting = false;
      }
      break;
    case PostCladTestingOptions.SA26345BondStrength:
      assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).machineBondStrengthBendsperSA26345 = false;
      break;
    case PostCladTestingOptions.B898Bend:
      assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).bendB898 = false;
      break;
    case PostCladTestingOptions.ADW8Bend:
      assemblyGroupDetailFieldsToUpdate.get(quoteLineItemDetailId).ADW8Bend = false;
      break;
    }
  }
};

// Updates assembly group line post clad testing options based on what is required
export const updateAssemblyGroupLinePostCladTestingOptions = (assemblyGroupLineFieldsToUpdate: {[key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate}, testRequired: boolean, postCladTestingOption: PostCladTestingOptions, quoteLineItemId: string) => {

  let updatedPostCladTestingOptions = assemblyGroupLineFieldsToUpdate[quoteLineItemId]?.postCladTestingOptions?.split(';') || [];

  if(testRequired) {
    if(!updatedPostCladTestingOptions.includes(postCladTestingOption) &&
    postCladTestingOption != PostCladTestingOptions.ImpactLCVN &&
    postCladTestingOption != PostCladTestingOptions.ImpactTCVN) {
      updatedPostCladTestingOptions.push(postCladTestingOption);
      assemblyGroupLineFieldsToUpdate[quoteLineItemId].postCladTestingOptions = [...updatedPostCladTestingOptions].join(';');
    }
  } else if(updatedPostCladTestingOptions.includes(postCladTestingOption)) {
    updatedPostCladTestingOptions = updatedPostCladTestingOptions.filter(option => option != postCladTestingOption);

    assemblyGroupLineFieldsToUpdate[quoteLineItemId].postCladTestingOptions = [...updatedPostCladTestingOptions].join(';');
  }
};

// Returns the proper detail fields to update map to use based on whether a quote line item detail was provided
export const detailFieldsToUpdate = (metalComboDetailFieldsToUpdate: {[key: string]: AdvSpecsMetalComboDetailFieldsToUpdate}, assemblyGroupDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>, metalCombo: string, quoteDetailForRow?: QuoteLineItemDetail) => {
  return !quoteDetailForRow ? metalComboDetailFieldsToUpdate[metalCombo] : assemblyGroupDetailFieldsToUpdate.get(quoteDetailForRow?.Id || quoteDetailForRow?.associatedLineItem);
};

// Updates other options if they are no longer required
export const updateOtherOption = (metalComboDetailFieldsToUpdate: {[key: string]: AdvSpecsMetalComboDetailFieldsToUpdate}, assemblyGroupDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>, optionRequired: boolean, option: AdvSpecOptions, metalCombo: string, quoteLineItemDetail?: QuoteLineItemDetail) => {
  if(optionRequired) {
    switch(option) {
      case AdvSpecOptions.CustomerSpecifiedImpactTesting:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedImpactTesting = true;
      break;
    case AdvSpecOptions.PrebondImpacts:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).prebondImpacts = true;
      break;
    case AdvSpecOptions.LeakTest:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).leakTest = true;
      break;
    case AdvSpecOptions.CustomerSpecifiedSPWHTOfTestCoupons:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedSPWHTTestCoupons = true;
      break;
    }
  } else if (!optionRequired) {
    switch(option) {
    case AdvSpecOptions.HardnessCladdingMetalSurface:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).hardnessCladdingMetalSurface = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).hardnessCladdingRejectionCriteria = null;
      break;
    case AdvSpecOptions.HardnessBaseMetalSurface:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).hardnessBaseMetalSurface = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).hardnessBaseRejectionCriteria = null;
      break;
    case AdvSpecOptions.PMIConfirmCladdingMetal:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).PMIConfirmCladdingMetal = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).PMIConfirmCladdingMetalNotes = null;
      break;
    case AdvSpecOptions.PMIConfirmBaseMetal:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).PMIConfirmBaseMetal = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).PMIConfirmBaseMetalNotes = null;
      break;
    case AdvSpecOptions.CladCustomerChemistryRestrictions:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerCladChemistryRestrictionsNotes = null;
      break;
    case AdvSpecOptions.CorrosionTesting:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).corrosionTesting = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).corrosionTestingNotes = null;
      break;
    case AdvSpecOptions.CladMetalSpecEdAndAd:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).cladMetalSpecEdition = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).cladMetalSpecAddendum = null;
      break;
    case AdvSpecOptions.CladCustomerMetalSupplierRestrictions:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerCladMetalSupplierRestrictions = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).custCladMetalSupplierRestrictionsNotes = null;
      break;
    case AdvSpecOptions.CladMetalProductAnalysisRequired:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).cladMetalProductAnalysisRequired = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).cladMetalProductAnalysisRequiredNotes = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).cladMetalProductAnalysisType = null;
      break;
    case AdvSpecOptions.DyePenetrant:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).dyePenetrantNotes = null;
      break;
    case AdvSpecOptions.FerroxylTestOfCladdingSurfacePerformed:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).ferroxylTestPerformed = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).ferroxylNotes = null;
      break;
    case AdvSpecOptions.FerriteNumberRequired:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).ferriteNumberRequired = null;
      break;
    case AdvSpecOptions.PrecladBaseMetalUT:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).precladBaseMetalUT = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).precladBaseMetalUTNotes = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).precladBaseMetalUTType = null;
      break;
    case AdvSpecOptions.CarbonPercent:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).carbonPercent = null;
      break;
    case AdvSpecOptions.SulphurPercent:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).sulphur = null;
      break;
    case AdvSpecOptions.PhosphorousPercent:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).phosphorusPercent = null;
      break;
    case AdvSpecOptions.CarbonEquivalencyPercent:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).carbonEquivalencyPercent = null;
      break;
    case AdvSpecOptions.JFactorSA387S62:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).jFactorSA387S62 = null;
      break;
    case AdvSpecOptions.XFactorSA387S62:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).xFactorSA387S62 = null;
      break;
    case AdvSpecOptions.BaseCustomerChemistryRestrictions:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerBaseChemistryRestrictions = null;
      break;
    case AdvSpecOptions.BaseTensileTest:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).baseTensileTestLocation = null;
      break;
    case AdvSpecOptions.HotTensileTestPerSA20S7:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).hotTensileTestPerSA20S7Degrees = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).hotTensileTestPerSA20S7UOM = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).hotTensileTestPerSA20S7Notes = null;
      break;
    case AdvSpecOptions.HICTestingRequired:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).hicTestNotes = null;
      break;
    case AdvSpecOptions.AusteniticGrainSizeFinerThan:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).austeniticGrainSizeFinerThanValue = null;
      break;
    case AdvSpecOptions.BaseMetalSpecEdAndAd:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).baseMetalSpecEdition = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).baseMetalSpecAddendum = null;
      break;
    case AdvSpecOptions.BaseCustomerMetalSupplierRestrictions:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerBaseMetalSupplierRestrictions = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).custBaseMetalSupplierRestrictionsNotes = null;
      break;
    case AdvSpecOptions.BaseMetalProductAnalysisRequired:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).baseMetalProductAnalysisRequired = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).baseMetalProductAnalysisRequiredNotes = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).baseMetalProductAnalysisType = null;
      break;
    case AdvSpecOptions.CustomerSpecifiedImpactTesting:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedImpactTesting = false;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedImpactTestToBePerformed = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedImpactTestLocation = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedImpactTestDirection = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedImpactTestUOM = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedImpactTestAVG = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedImpactTestMIN = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedImpactTestTemperature = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).customerSpecifiedImpactTestTempUOM = null;
      break;
    case AdvSpecOptions.PrebondImpacts:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).prebondImpacts = false;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).preBondImpactsTestLocation = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).preBondImpactsTestDirection = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).preBondImpactsTestUOM = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).preBondImpactsTestAVG = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).preBondImpactsTestMIN = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).preBondImpactsTestTemperature = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).preBondImpactsTestTemperatureUOM = null;
      break;
    case AdvSpecOptions.LeakTest:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).leakTest = false;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).leakTestGas = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).leakTestReqt = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).leakTestValue = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).leakTestPressure = null;
      break;
    case AdvSpecOptions.CustomerSpecifiedSPWHTOfTestCoupons:
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupACycle1 = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupACycle2 = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupACycle3 = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupAToBePerformed = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupBCycle1 = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupBCycle2 = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupBCycle3 = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupBToBePerformed = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupCCycle1 = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupCCycle2 = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupCCycle3 = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTGroupCToBePerformed = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTHeatingAndCoolingRateAbove = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTHeatingAndCoolingRateAboveUOM = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTMaximumHeatingRateHour = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTMaximumHeatingRateHourUOM = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTMaximumCoolingRateHour = null;
      detailFieldsToUpdate(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalCombo, quoteLineItemDetail).SPWHTMaximumCoolingRateHourUOM = null;
      break;
    }
  }
};

export const addFieldsToUpdateMaps = (
    assemblyGroupLineFieldsToUpdate: {[key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate},
    assemblyGroupDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>,
    assemblyGroupHeadFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsHeadFieldsToUpdate>,
    assemblyGroupOutsideServiceItemFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
    assemblyGroupCylinderDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsCylinderItemDetailFieldsToUpdate>,
    metalComboDetailFieldsToUpdate: {[key: string]: AdvSpecsMetalComboDetailFieldsToUpdate},
    lineItemFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>,
    lineItemDetailFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>,
    lineItemHeadFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>,
    lineItemOutsideServicesFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>,
    lineItemCylinderFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>,
    fieldsUpdated: QuoteLineItemValueTypes[],
    quoteLineItemData: QuoteLineItem,
    metalCombo: string,
    specifyPerAssemblyMap: {[key: string]: {[key: string]: boolean}},
    metalComboLineFieldsToUpdate: {[key: string]: AdvSpecsMetalComboLineFieldsToUpdate},
    baseMetal: RawMaterial,
    cladMetal: RawMaterial
  ) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const quoteLineItemDetailData = quoteLineItemData.quoteLineItemDetail;
  const quoteLineItemDetailDataId = quoteLineItemDetailData.Id || quoteLineItemDetailData.associatedLineItem;
  const assemblyFieldsMapLine = (assemblyGroupLineFieldsToUpdate || {})[quoteLineItemData.Id || quoteLineItemData.displayId];
  const assemblyFieldsMapDetail = assemblyGroupDetailFieldsToUpdate?.get(quoteLineItemDetailDataId);
  const assemblyFieldsMapHead = assemblyGroupHeadFieldsToUpdate?.get(quoteLineItemDetailDataId);
  const assemblyFieldsMapOutsideServices = assemblyGroupOutsideServiceItemFieldsToUpdate?.get(quoteLineItemDetailDataId);
  const assemblyFieldsMapCylinder = assemblyGroupCylinderDetailFieldsToUpdate?.get(quoteLineItemDetailDataId);

  const headRecord = quoteLineItemDetailData?.NC_Head_Item_Detail__r;
  const outsideServicesRecord = quoteLineItemDetailData?.NC_Outside_Service_Item_Details__r;
  const cylinderRecord = quoteLineItemDetailData?.NC_Cylinder_Item_Details__r;

  addFieldToMapAndUpdatedList(metalComboDetailFieldsToUpdate[metalCombo].advancedSpecUOM, quoteLineItemDetailData?.AdvancedSpecUOM__c, QuoteLineItemFields.AdvancedSpecUOM, QuoteLineItemValueTypes.AdvancedSpecUOM, lineItemDetailFieldsMap, fieldsUpdated);

  const postCladTestingOptions = [];

  if(postCladTestingOptionSelected(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, PostCladTestingOptions.ImpactLCVN, assemblyFieldsMapLine?.postCladTestingOptions, metalComboLineFieldsToUpdate[metalCombo]?.postCladTestingOptions)) {
    postCladTestingOptions.push(PostCladTestingOptions.ImpactLCVN);
  }

  if(postCladTestingOptionSelected(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, PostCladTestingOptions.ImpactTCVN, assemblyFieldsMapLine?.postCladTestingOptions, metalComboLineFieldsToUpdate[metalCombo]?.postCladTestingOptions)) {
    postCladTestingOptions.push(PostCladTestingOptions.ImpactTCVN);
  }

  if(postCladTestingOptionSelected(specifyPerAssemblyMap[metalCombo].shearTest, PostCladTestingOptions.Shear, assemblyFieldsMapLine?.postCladTestingOptions, metalComboLineFieldsToUpdate[metalCombo]?.postCladTestingOptions)) {
    postCladTestingOptions.push(PostCladTestingOptions.Shear);
  }

  if(postCladTestingOptionSelected(specifyPerAssemblyMap[metalCombo].tensileTest, PostCladTestingOptions.Tensile, assemblyFieldsMapLine?.postCladTestingOptions, metalComboLineFieldsToUpdate[metalCombo]?.postCladTestingOptions)) {
    postCladTestingOptions.push(PostCladTestingOptions.Tensile);
  }

  if(postCladTestingOptionSelected(specifyPerAssemblyMap[metalCombo].ramTensileTest, PostCladTestingOptions.RamTensile, assemblyFieldsMapLine?.postCladTestingOptions, metalComboLineFieldsToUpdate[metalCombo]?.postCladTestingOptions)) {
    postCladTestingOptions.push(PostCladTestingOptions.RamTensile);
  }

  if(postCladTestingOptionSelected(specifyPerAssemblyMap[metalCombo].sa26345BondStrength, PostCladTestingOptions.SA26345BondStrength, assemblyFieldsMapLine?.postCladTestingOptions, metalComboLineFieldsToUpdate[metalCombo]?.postCladTestingOptions)) {
    postCladTestingOptions.push(PostCladTestingOptions.SA26345BondStrength);
  }

  if(postCladTestingOptionSelected(specifyPerAssemblyMap[metalCombo].b898Bend, PostCladTestingOptions.B898Bend, assemblyFieldsMapLine?.postCladTestingOptions, metalComboLineFieldsToUpdate[metalCombo]?.postCladTestingOptions)) {
    postCladTestingOptions.push(PostCladTestingOptions.B898Bend);
  }

  if(postCladTestingOptionSelected(specifyPerAssemblyMap[metalCombo].adw8Bend, PostCladTestingOptions.ADW8Bend, assemblyFieldsMapLine?.postCladTestingOptions, metalComboLineFieldsToUpdate[metalCombo]?.postCladTestingOptions)) {
    postCladTestingOptions.push(PostCladTestingOptions.ADW8Bend);
  }

  addFieldToMapAndUpdatedList(postCladTestingOptions.join(';'), quoteLineItemData?.Post_Clad_Testing_Options__c || '', QuoteLineItemFields.PostCladTestingOptions, QuoteLineItemValueTypes.PostCladTestingOptions, lineItemFieldsMap, fieldsUpdated);

  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].additionalRequirementsBaseMetal, assemblyFieldsMapDetail?.additionalRequirementsBaseMetal, metalComboDetailFieldsToUpdate[metalCombo].additionalRequirementsBaseMetal || null, quoteLineItemDetailData?.AdditionalRequirementsBaseMetal__c, QuoteLineItemFields.AdditionalRequirementsBaseMetal, QuoteLineItemValueTypes.AdditionalRequirementsBaseMetal, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].additionalRequirementsCladdingMetal, assemblyFieldsMapDetail?.additionalRequirementsCladdingMetal, metalComboDetailFieldsToUpdate[metalCombo].additionalRequirementsCladdingMetal || null, quoteLineItemDetailData?.AdditionalRequirementsCladdingMetal__c, QuoteLineItemFields.AdditionalRequirementsCladdingMetal, QuoteLineItemValueTypes.AdditionalRequirementsCladdingMetal, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].additionalRequirementsCladProductTesting, assemblyFieldsMapDetail?.additionalRequirementsCladProductTesting, metalComboDetailFieldsToUpdate[metalCombo].additionalRequirementsCladProductTesting || null, quoteLineItemDetailData?.AdditionalRequirementsCladProductTesting__c, QuoteLineItemFields.AdditionalRequirementsCladProductTesting, QuoteLineItemValueTypes.AdditionalRequirementsCladProductTesting, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].additionalRequirementsPrewelding, assemblyFieldsMapDetail?.additionalRequirementsPrewelding, metalComboDetailFieldsToUpdate[metalCombo].additionalRequirementsPrewelding || null, quoteLineItemDetailData?.AdditionalRequirementsPrewelding__c, QuoteLineItemFields.AdditionalRequirementsPrewelding, QuoteLineItemValueTypes.AdditionalRequirementsPrewelding, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].adw8Bend, assemblyFieldsMapDetail?.ADW8Bend || false, metalComboDetailFieldsToUpdate[metalCombo].ADW8Bend || false, quoteLineItemDetailData?.ADW8Bend__c, QuoteLineItemFields.ADW8Bend, QuoteLineItemValueTypes.ADW8Bend, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].austeniticGrainSizeFinerThanValue, assemblyFieldsMapDetail?.austeniticGrainSizeFinerThanValue, metalComboDetailFieldsToUpdate[metalCombo].austeniticGrainSizeFinerThanValue || null, quoteLineItemDetailData?.AusteniticGrainSizeFinerThanValue__c, QuoteLineItemFields.AusteniticGrainSizeFinerThanValue, QuoteLineItemValueTypes.AusteniticGrainSizeFinerThanValue, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(baseMetal?.metalLongName || null, quoteLineItemDetailData?.BaseMetal__c, QuoteLineItemFields.BaseMetal, QuoteLineItemValueTypes.BaseMetalLongName, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].baseMetalProductAnalysisRequired, assemblyFieldsMapDetail?.baseMetalProductAnalysisRequired, metalComboDetailFieldsToUpdate[metalCombo].baseMetalProductAnalysisRequired || null, quoteLineItemDetailData?.BaseMetalProductAnalysisRequired__c, QuoteLineItemFields.BaseMetalProductAnalysisRequired, QuoteLineItemValueTypes.BaseMetalProductAnalysisRequired, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].baseMetalProductAnalysisRequired, assemblyFieldsMapDetail?.baseMetalProductAnalysisRequiredNotes, metalComboDetailFieldsToUpdate[metalCombo].baseMetalProductAnalysisRequiredNotes || null, quoteLineItemDetailData?.BaseMetalProductAnalysisRequiredNotes__c, QuoteLineItemFields.BaseMetalProductAnalysisRequiredNotes, QuoteLineItemValueTypes.BaseMetalProductAnalysisRequiredNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].baseMetalProductAnalysisRequired, assemblyFieldsMapDetail?.baseMetalProductAnalysisType, metalComboDetailFieldsToUpdate[metalCombo].baseMetalProductAnalysisType || null, quoteLineItemDetailData?.BaseMetalProductAnalysisType__c, QuoteLineItemFields.BaseMetalProductAnalysisType, QuoteLineItemValueTypes.BaseMetalProductAnalysisType, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].baseMetalSpecEdAndAd, assemblyFieldsMapDetail?.baseMetalSpecAddendum, metalComboDetailFieldsToUpdate[metalCombo].baseMetalSpecAddendum || null, quoteLineItemDetailData?.BaseMetalSpecAddendum__c, QuoteLineItemFields.BaseMetalSpecAddendum, QuoteLineItemValueTypes.BaseMetalSpecAddendum, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].baseMetalSpecEdAndAd, assemblyFieldsMapDetail?.baseMetalSpecEdition, metalComboDetailFieldsToUpdate[metalCombo].baseMetalSpecEdition || null, quoteLineItemDetailData?.BaseMetalSpecEdition__c, QuoteLineItemFields.BaseMetalSpecEdition, QuoteLineItemValueTypes.BaseMetalSpecEdition, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].baseTensileTestLocation, assemblyFieldsMapDetail?.baseTensileTestLocation, metalComboDetailFieldsToUpdate[metalCombo].baseTensileTestLocation || null, quoteLineItemDetailData?.BaseTensileTestLocation__c, QuoteLineItemFields.BaseTensileTestLocation, QuoteLineItemValueTypes.BaseTensileTestLocation, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].b898Bend, assemblyFieldsMapDetail?.bendB898 || false, metalComboDetailFieldsToUpdate[metalCombo].bendB898 || false, quoteLineItemDetailData?.BendB898__c, QuoteLineItemFields.BendB898, QuoteLineItemValueTypes.BendB898, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].ramTensileTest, assemblyFieldsMapDetail?.bondStrengthTensileTestRamUOM, metalComboDetailFieldsToUpdate[metalCombo].bondStrengthTensileTestRamUOM || null, quoteLineItemDetailData?.BondStrengthTensileTestRamUOM__c, QuoteLineItemFields.BondStrengthTensileTestRamUOM, QuoteLineItemValueTypes.BondStrengthTensileTestRamUOM, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].ramTensileTest, assemblyFieldsMapDetail?.bondStrengthTensileTestRamValue, metalComboDetailFieldsToUpdate[metalCombo].bondStrengthTensileTestRamValue || null, quoteLineItemDetailData?.BondStrengthTensileTestRamValue__c, QuoteLineItemFields.BondStrengthTensileTestRamValue, QuoteLineItemValueTypes.BondStrengthTensileTestRamValue, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].calciumTreatedSulfideShapeControl, assemblyFieldsMapDetail?.calciumTreatedSulfideShapeControl || false, metalComboDetailFieldsToUpdate[metalCombo].calciumTreatedSulfideShapeControl || false, quoteLineItemDetailData?.CalciumTreatedSulfideShapeControl__c, QuoteLineItemFields.CalciumTreatedSulfideShapeControl, QuoteLineItemValueTypes.CalciumTreatedSulfideShapeControl, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].carbonEquivalencyPercent, assemblyFieldsMapDetail?.carbonEquivalencyPercent, metalComboDetailFieldsToUpdate[metalCombo].carbonEquivalencyPercent || null, quoteLineItemDetailData?.Carbon_Equivalency_Percent__c, QuoteLineItemFields.CarbonEquivalencyPercent, QuoteLineItemValueTypes.CarbonEquivalencyPercent, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].carbonPercent, assemblyFieldsMapDetail?.carbonPercent, metalComboDetailFieldsToUpdate[metalCombo].carbonPercent || null, quoteLineItemDetailData?.Carbon_Percent__c, QuoteLineItemFields.CarbonPercent, QuoteLineItemValueTypes.CarbonPercent, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].certificationTypeRequired, assemblyFieldsMapDetail?.certificationTypeRequired, metalComboDetailFieldsToUpdate[metalCombo].certificationTypeRequired || null, quoteLineItemDetailData?.CertificationTypeRequired__c, QuoteLineItemFields.CertificationTypeRequired, QuoteLineItemValueTypes.CertificationTypeRequired, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].claddingForCorrosionAllowanceOnly, assemblyFieldsMapDetail?.claddingForCorrosionAllowanceOnly, metalComboDetailFieldsToUpdate[metalCombo].claddingForCorrosionAllowanceOnly || null, quoteLineItemDetailData?.CladdingForCorrosionAllowanceOnly__c, QuoteLineItemFields.CladdingForCorrosionAllowanceOnly, QuoteLineItemValueTypes.CladdingForCorrosionAllowanceOnly, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(cladMetal.metalLongName || null, quoteLineItemDetailData?.CladMetal__c, QuoteLineItemFields.CladMetal, QuoteLineItemValueTypes.CladMetalLongName, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].cladMetalProductAnalysisRequired, assemblyFieldsMapDetail?.cladMetalProductAnalysisRequired, metalComboDetailFieldsToUpdate[metalCombo].cladMetalProductAnalysisRequired || null, quoteLineItemDetailData?.CladMetalProductAnalysisRequired__c, QuoteLineItemFields.CladMetalProductAnalysisRequired, QuoteLineItemValueTypes.CladMetalProductAnalysisRequired, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].cladMetalProductAnalysisRequired, assemblyFieldsMapDetail?.cladMetalProductAnalysisRequiredNotes, metalComboDetailFieldsToUpdate[metalCombo].cladMetalProductAnalysisRequiredNotes || null, quoteLineItemDetailData?.CladMetalProductAnalysisRequiredNotes__c, QuoteLineItemFields.CladMetalProductAnalysisRequiredNotes, QuoteLineItemValueTypes.CladMetalProductAnalysisRequiredNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].cladMetalProductAnalysisRequired, assemblyFieldsMapDetail?.cladMetalProductAnalysisType, metalComboDetailFieldsToUpdate[metalCombo].cladMetalProductAnalysisType || null, quoteLineItemDetailData?.CladMetalProductAnalysisType__c, QuoteLineItemFields.CladMetalProductAnalysisType, QuoteLineItemValueTypes.CladMetalProductAnalysisType, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].cladMetalSpecEdAndAd, assemblyFieldsMapDetail?.cladMetalSpecAddendum, metalComboDetailFieldsToUpdate[metalCombo].cladMetalSpecAddendum || null, quoteLineItemDetailData?.CladMetalSpecAddendum__c, QuoteLineItemFields.CladMetalSpecAddendum, QuoteLineItemValueTypes.CladMetalSpecAddendum, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].cladMetalSpecEdAndAd, assemblyFieldsMapDetail?.cladMetalSpecEdition, metalComboDetailFieldsToUpdate[metalCombo].cladMetalSpecEdition || null, quoteLineItemDetailData?.CladMetalSpecEdition__c, QuoteLineItemFields.CladMetalSpecEdition, QuoteLineItemValueTypes.CladMetalSpecEdition, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(metalComboDetailFieldsToUpdate[metalCombo].cladProductionSpecificationNotes || null, quoteLineItemDetailData?.CladProductionSpecificationNotes__c, QuoteLineItemFields.CladProductionSpecificationNotes, QuoteLineItemValueTypes.CladProductionSpecificationNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(metalComboDetailFieldsToUpdate[metalCombo].cladProductionSpecification || null, quoteLineItemDetailData?.CladProductSpecification__c, QuoteLineItemFields.CladProductSpecification, QuoteLineItemValueTypes.CladProductSpecification, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].tensileTest, assemblyFieldsMapDetail?.cladTensileTestLocation, metalComboDetailFieldsToUpdate[metalCombo].cladTensileTestLocation || null, quoteLineItemDetailData?.CladTensileTestLocation__c, QuoteLineItemFields.CladTensileTestLocation, QuoteLineItemValueTypes.CladTensileTestLocation, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].corrosionTesting, assemblyFieldsMapDetail?.corrosionTesting, metalComboDetailFieldsToUpdate[metalCombo].corrosionTesting || null, quoteLineItemDetailData?.CorrosionTesting__c, QuoteLineItemFields.CorrosionTesting, QuoteLineItemValueTypes.CorrosionTesting, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].corrosionTestingNotes, assemblyFieldsMapDetail?.corrosionTestingNotes, metalComboDetailFieldsToUpdate[metalCombo].corrosionTestingNotes || null, quoteLineItemDetailData?.CorrosionTestingNotes__c, QuoteLineItemFields.CorrosionTestingNotes, QuoteLineItemValueTypes.CorrosionTestingNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerBaseMetalSupplierRestrictions, assemblyFieldsMapDetail?.custBaseMetalSupplierRestrictionsNotes, metalComboDetailFieldsToUpdate[metalCombo].custBaseMetalSupplierRestrictionsNotes || null, quoteLineItemDetailData?.CustBaseMetalSupplierRestrictionsNotes__c, QuoteLineItemFields.CustBaseMetalSupplierRestrictionsNotes, QuoteLineItemValueTypes.CustBaseMetalSupplierRestrictionsNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerCladMetalSupplierRestrictions, assemblyFieldsMapDetail?.custCladMetalSupplierRestrictionsNotes, metalComboDetailFieldsToUpdate[metalCombo].custCladMetalSupplierRestrictionsNotes || null, quoteLineItemDetailData?.CustCladMetalSupplierRestrictionsNotes__c, QuoteLineItemFields.CustCladMetalSupplierRestrictionsNotes, QuoteLineItemValueTypes.CustCladMetalSupplierRestrictionsNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerBaseChemistryRestrictions, assemblyFieldsMapDetail?.customerBaseChemistryRestrictions, metalComboDetailFieldsToUpdate[metalCombo].customerBaseChemistryRestrictions || null, quoteLineItemDetailData?.CustomerBaseChemistryRestrictions__c, QuoteLineItemFields.CustomerBaseChemistryRestrictions, QuoteLineItemValueTypes.CustomerBaseChemistryRestrictions, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerBaseMetalSupplierRestrictions, assemblyFieldsMapDetail?.customerBaseMetalSupplierRestrictions, metalComboDetailFieldsToUpdate[metalCombo].customerBaseMetalSupplierRestrictions || null, quoteLineItemDetailData?.CustomerBaseMetalSupplierRestrictions__c, QuoteLineItemFields.CustomerBaseMetalSupplierRestrictions, QuoteLineItemValueTypes.CustomerBaseMetalSupplierRestrictions, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerCladChemistryRestrictionsNotes, assemblyFieldsMapDetail?.customerCladChemistryRestrictionsNotes, metalComboDetailFieldsToUpdate[metalCombo].customerCladChemistryRestrictionsNotes || null, quoteLineItemDetailData?.CustomerCladChemistryRestrictionsNotes__c, QuoteLineItemFields.CustomerCladChemistryRestrictionsNotes, QuoteLineItemValueTypes.CustomerCladChemistryRestrictionsNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerCladMetalSupplierRestrictions, assemblyFieldsMapDetail?.customerCladMetalSupplierRestrictions, metalComboDetailFieldsToUpdate[metalCombo].customerCladMetalSupplierRestrictions || null, quoteLineItemDetailData?.CustomerCladMetalSupplierRestrictions__c, QuoteLineItemFields.CustomerCladMetalSupplierRestrictions, QuoteLineItemValueTypes.CustomerCladMetalSupplierRestrictions, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, assemblyFieldsMapDetail?.customerSpecifiedImpactTesting || false, metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTesting || false, quoteLineItemDetailData?.CustomerSpecifiedImpactTesting__c, QuoteLineItemFields.CustomerSpecifiedImpactTesting, QuoteLineItemValueTypes.CustomerSpecifiedImpactTesting, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, assemblyFieldsMapDetail?.customerSpecifiedImpactTestToBePerformed, metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestToBePerformed || null, quoteLineItemDetailData?.CustomerSpecifiedImpactTestToBePerformed__c, QuoteLineItemFields.CustomerSpecifiedImpactTestToBePerformed, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestToBePerformed, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, assemblyFieldsMapDetail?.customerSpecifiedImpactTestLocation, metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestLocation || null, quoteLineItemDetailData?.CustomerSpecifiedImpactTestLocation__c, QuoteLineItemFields.CustomerSpecifiedImpactTestLocation, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestLocation, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, assemblyFieldsMapDetail?.customerSpecifiedImpactTestDirection, metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestDirection || null, quoteLineItemDetailData?.CustomerSpecifiedImpactTestDirection__c, QuoteLineItemFields.CustomerSpecifiedImpactTestDirection, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestDirection, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, assemblyFieldsMapDetail?.customerSpecifiedImpactTestUOM, metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestUOM || null, quoteLineItemDetailData?.CustomerSpecifiedImpactTestUOM__c, QuoteLineItemFields.CustomerSpecifiedImpactTestUOM, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestUOM, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, assemblyFieldsMapDetail?.customerSpecifiedImpactTestAVG, metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestAVG || null, quoteLineItemDetailData?.CustomerSpecifiedImpactTestAVG__c, QuoteLineItemFields.CustomerSpecifiedImpactTestAVG, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestAVG, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, assemblyFieldsMapDetail?.customerSpecifiedImpactTestMIN, metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestMIN || null, quoteLineItemDetailData?.CustomerSpecifiedImpactTestMIN__c, QuoteLineItemFields.CustomerSpecifiedImpactTestMIN, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestMIN, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, assemblyFieldsMapDetail?.customerSpecifiedImpactTestTemperature, metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestTemperature || null, quoteLineItemDetailData?.CustomerSpecifiedImpactTestTemperature__c, QuoteLineItemFields.CustomerSpecifiedImpactTestTemperature, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestTemperature, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting, assemblyFieldsMapDetail?.customerSpecifiedImpactTestTempUOM ? '°' + assemblyFieldsMapDetail?.customerSpecifiedImpactTestTempUOM : null, metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestTempUOM ? '°' + metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedImpactTestTempUOM : null, quoteLineItemDetailData?.CustomerSpecifiedImpactTestTempUOM__c, QuoteLineItemFields.CustomerSpecifiedImpactTestTempUOM, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestTempUOM, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.customerSpecifiedSPWHTTestCoupons || false, metalComboDetailFieldsToUpdate[metalCombo].customerSpecifiedSPWHTTestCoupons || false, quoteLineItemDetailData?.CustomerSpecifiedSPWHTTestCoupons__c, QuoteLineItemFields.CustomerSpecifiedSPWHTTestCoupons, QuoteLineItemValueTypes.CustomerSpecifiedSPWHTTestCoupons, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupACycle1, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupACycle1, quoteLineItemDetailData?.SPWHTGroupACycle1__c, QuoteLineItemFields.SPWHTGroupACycle1, QuoteLineItemValueTypes.SPWHTGroupACycle1, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupACycle2, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupACycle2, quoteLineItemDetailData?.SPWHTGroupACycle2__c, QuoteLineItemFields.SPWHTGroupACycle2, QuoteLineItemValueTypes.SPWHTGroupACycle2, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupACycle3, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupACycle3, quoteLineItemDetailData?.SPWHTGroupACycle3__c, QuoteLineItemFields.SPWHTGroupACycle3, QuoteLineItemValueTypes.SPWHTGroupACycle3, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupAToBePerformed, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupAToBePerformed, quoteLineItemDetailData?.SPWHTGroupAToBePerformed__c, QuoteLineItemFields.SPWHTGroupAToBePerformed, QuoteLineItemValueTypes.SPWHTGroupAToBePerformed, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupBCycle1, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupBCycle1, quoteLineItemDetailData?.SPWHTGroupBCycle1__c, QuoteLineItemFields.SPWHTGroupBCycle1, QuoteLineItemValueTypes.SPWHTGroupBCycle1, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupBCycle2, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupBCycle2, quoteLineItemDetailData?.SPWHTGroupBCycle2__c, QuoteLineItemFields.SPWHTGroupBCycle2, QuoteLineItemValueTypes.SPWHTGroupBCycle2, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupBCycle3, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupBCycle3, quoteLineItemDetailData?.SPWHTGroupBCycle3__c, QuoteLineItemFields.SPWHTGroupBCycle3, QuoteLineItemValueTypes.SPWHTGroupBCycle3, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupBToBePerformed, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupBToBePerformed, quoteLineItemDetailData?.SPWHTGroupBToBePerformed__c, QuoteLineItemFields.SPWHTGroupBToBePerformed, QuoteLineItemValueTypes.SPWHTGroupBToBePerformed, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupCCycle1, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupCCycle1, quoteLineItemDetailData?.SPWHTGroupCCycle1__c, QuoteLineItemFields.SPWHTGroupCCycle1, QuoteLineItemValueTypes.SPWHTGroupCCycle1, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupCCycle2, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupCCycle2, quoteLineItemDetailData?.SPWHTGroupCCycle2__c, QuoteLineItemFields.SPWHTGroupCCycle2, QuoteLineItemValueTypes.SPWHTGroupCCycle2, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupCCycle3, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupCCycle3, quoteLineItemDetailData?.SPWHTGroupCCycle3__c, QuoteLineItemFields.SPWHTGroupCCycle3, QuoteLineItemValueTypes.SPWHTGroupCCycle3, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTGroupCToBePerformed, metalComboDetailFieldsToUpdate[metalCombo].SPWHTGroupCToBePerformed, quoteLineItemDetailData?.SPWHTGroupCToBePerformed__c, QuoteLineItemFields.SPWHTGroupCToBePerformed, QuoteLineItemValueTypes.SPWHTGroupCToBePerformed, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTHeatingAndCoolingRateAbove, metalComboDetailFieldsToUpdate[metalCombo].SPWHTHeatingAndCoolingRateAbove, quoteLineItemDetailData?.SPWHTHeatingAndCoolingRateAbove__c, QuoteLineItemFields.SPWHTHeatingAndCoolingRateAbove, QuoteLineItemValueTypes.SPWHTHeatingAndCoolingRateAbove, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTHeatingAndCoolingRateAboveUOM, metalComboDetailFieldsToUpdate[metalCombo].SPWHTHeatingAndCoolingRateAboveUOM, quoteLineItemDetailData?.SPWHTHeatingAndCoolingRateAboveUOM__c, QuoteLineItemFields.SPWHTHeatingAndCoolingRateAboveUOM, QuoteLineItemValueTypes.SPWHTHeatingAndCoolingRateAboveUOM, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTMaximumCoolingRateHour, metalComboDetailFieldsToUpdate[metalCombo].SPWHTMaximumCoolingRateHour, quoteLineItemDetailData?.SPWHTMaximumCoolingRateHour__c, QuoteLineItemFields.SPWHTMaximumCoolingRateHour, QuoteLineItemValueTypes.SPWHTMaximumCoolingRateHour, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTMaximumCoolingRateHourUOM, metalComboDetailFieldsToUpdate[metalCombo].SPWHTMaximumCoolingRateHourUOM, quoteLineItemDetailData?.SPWHTMaximumCoolingRateHourUOM__c, QuoteLineItemFields.SPWHTMaximumCoolingRateHourUOM, QuoteLineItemValueTypes.SPWHTMaximumCoolingRateHourUOM, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTMaximumHeatingRateHour, metalComboDetailFieldsToUpdate[metalCombo].SPWHTMaximumHeatingRateHour, quoteLineItemDetailData?.SPWHTMaximumHeatingRateHour__c, QuoteLineItemFields.SPWHTMaximumHeatingRateHour, QuoteLineItemValueTypes.SPWHTMaximumHeatingRateHour, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].customerSpecifiedSPWHTTestCoupons, assemblyFieldsMapDetail?.SPWHTMaximumHeatingRateHourUOM, metalComboDetailFieldsToUpdate[metalCombo].SPWHTMaximumHeatingRateHourUOM, quoteLineItemDetailData?.SPWHTMaximumHeatingRateHourUOM__c, QuoteLineItemFields.SPWHTMaximumHeatingRateHourUOM, QuoteLineItemValueTypes.SPWHTMaximumHeatingRateHourUOM, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].dyePenetrantNotes, assemblyFieldsMapDetail?.dyePenetrantNotes, metalComboDetailFieldsToUpdate[metalCombo].dyePenetrantNotes || null, quoteLineItemDetailData?.DyePenetrantNotes__c, QuoteLineItemFields.DyePenetrantNotes, QuoteLineItemValueTypes.DyePenetrantNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].exceptionsAndClarifications, assemblyFieldsMapDetail?.exceptionsAndClarifications, metalComboDetailFieldsToUpdate[metalCombo].exceptionsAndClarifications || null, quoteLineItemDetailData?.Exceptions_and_Clarifications__c, QuoteLineItemFields.ExceptionsAndClarifications, QuoteLineItemValueTypes.ExceptionsAndClarifications, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].ferriteNumberRequired, assemblyFieldsMapDetail?.ferriteNumberRequired, metalComboDetailFieldsToUpdate[metalCombo].ferriteNumberRequired || null, quoteLineItemDetailData?.FerriteNumberRequired__c, QuoteLineItemFields.FerriteNumberRequired, QuoteLineItemValueTypes.FerriteNumberRequired, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].ferroxylTestPerformed, assemblyFieldsMapDetail?.ferroxylTestPerformed, metalComboDetailFieldsToUpdate[metalCombo].ferroxylTestPerformed || null, quoteLineItemDetailData?.FerroxylTestPerformed__c, QuoteLineItemFields.FerroxylTestPerformed, QuoteLineItemValueTypes.FerroxylTestPerformed, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].ferroxylTestPerformed, assemblyFieldsMapDetail?.ferroxylNotes, metalComboDetailFieldsToUpdate[metalCombo].ferroxylNotes || null, quoteLineItemDetailData?.FerroxylNotes__c, QuoteLineItemFields.FerroxylNotes, QuoteLineItemValueTypes.FerroxylNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].finishedGoodHeatTreatment, assemblyFieldsMapDetail?.finishedGoodHeatTreatment, metalComboDetailFieldsToUpdate[metalCombo].finishedGoodHeatTreatment || null, quoteLineItemDetailData?.FinishedGoodHeatTreatment__c, QuoteLineItemFields.FinishedGoodHeatTreatment, QuoteLineItemValueTypes.FinishedGoodHeatTreatment, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].finishedGoodHeatTreatment, assemblyFieldsMapDetail?.finishedGoodHeatTreatmentNotes, metalComboDetailFieldsToUpdate[metalCombo].finishedGoodHeatTreatmentNotes || null, quoteLineItemDetailData?.FinishedGoodHeatTreatmentNotes__c, QuoteLineItemFields.FinishedGoodHeatTreatmentNotes, QuoteLineItemValueTypes.FinishedGoodHeatTreatmentNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].hardnessBaseMetalSurface, assemblyFieldsMapDetail?.hardnessBaseMetalSurface, metalComboDetailFieldsToUpdate[metalCombo].hardnessBaseMetalSurface || null, quoteLineItemDetailData?.HardnessBaseMetalSurface__c, QuoteLineItemFields.HardnessBaseMetalSurface, QuoteLineItemValueTypes.HardnessBaseMetalSurface, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].hardnessBaseMetalSurface, assemblyFieldsMapDetail?.hardnessBaseRejectionCriteria, metalComboDetailFieldsToUpdate[metalCombo].hardnessBaseRejectionCriteria || null, quoteLineItemDetailData?.HardnessBaseRejectionCriteria__c, QuoteLineItemFields.HardnessBaseRejectionCriteria, QuoteLineItemValueTypes.HardnessBaseRejectionCriteria, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].hardnessCladdingMetalSurface, assemblyFieldsMapDetail?.hardnessCladdingMetalSurface, metalComboDetailFieldsToUpdate[metalCombo].hardnessCladdingMetalSurface || null, quoteLineItemDetailData?.HardnessCladdingMetalSurface__c, QuoteLineItemFields.HardnessCladdingMetalSurface, QuoteLineItemValueTypes.HardnessCladdingMetalSurface, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].hardnessCladdingMetalSurface, assemblyFieldsMapDetail?.hardnessCladdingRejectionCriteria, metalComboDetailFieldsToUpdate[metalCombo].hardnessCladdingRejectionCriteria || null, quoteLineItemDetailData?.HardnessCladdingRejectionCriteria__c, QuoteLineItemFields.HardnessCladdingRejectionCriteria, QuoteLineItemValueTypes.HardnessCladdingRejectionCriteria, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].hicTestNotes, assemblyFieldsMapDetail?.hicTestNotes, metalComboDetailFieldsToUpdate[metalCombo].hicTestNotes || null, quoteLineItemDetailData?.HicTestNotes__c, QuoteLineItemFields.HicTestNotes, QuoteLineItemValueTypes.HicTestNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].hotTensileTestPerSA20S7, assemblyFieldsMapDetail?.hotTensileTestPerSA20S7Degrees, metalComboDetailFieldsToUpdate[metalCombo].hotTensileTestPerSA20S7Degrees || null, quoteLineItemDetailData?.HotTensileTestPerSA20S7Degrees__c, QuoteLineItemFields.HotTensileTestPerSA20S7Degrees, QuoteLineItemValueTypes.HotTensileTestPerSA20S7Degrees, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].hotTensileTestPerSA20S7, assemblyFieldsMapDetail?.hotTensileTestPerSA20S7Notes, metalComboDetailFieldsToUpdate[metalCombo].hotTensileTestPerSA20S7Notes || null, quoteLineItemDetailData?.HotTensileTestPerSA20S7Notes__c, QuoteLineItemFields.HotTensileTestPerSA20S7Notes, QuoteLineItemValueTypes.HotTensileTestPerSA20S7Notes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].hotTensileTestPerSA20S7, assemblyFieldsMapDetail?.hotTensileTestPerSA20S7UOM ? '°' + assemblyFieldsMapDetail?.hotTensileTestPerSA20S7UOM : null, metalComboDetailFieldsToUpdate[metalCombo].hotTensileTestPerSA20S7UOM ? '°' + metalComboDetailFieldsToUpdate[metalCombo].hotTensileTestPerSA20S7UOM : null, quoteLineItemDetailData?.HotTensileTestPerSA20S7UOM__c, QuoteLineItemFields.HotTensileTestPerSA20S7UOM, QuoteLineItemValueTypes.HotTensileTestPerSA20S7UOM, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].isWeldRepairAllowed, assemblyFieldsMapDetail?.isWeldRepairAllowed, metalComboDetailFieldsToUpdate[metalCombo].isWeldRepairAllowed || null, quoteLineItemDetailData?.IsWeldRepairAllowed__c, QuoteLineItemFields.IsWeldRepairAllowed, QuoteLineItemValueTypes.IsWeldRepairAllowed, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].jFactorSA387S62, assemblyFieldsMapDetail?.jFactorSA387S62, metalComboDetailFieldsToUpdate[metalCombo].jFactorSA387S62 || null, quoteLineItemDetailData?.JFactorSA387S62__c, QuoteLineItemFields.JFactorSA387S62, QuoteLineItemValueTypes.JFactorSA387S62, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].leakTest, assemblyFieldsMapDetail?.leakTest || false, metalComboDetailFieldsToUpdate[metalCombo].leakTest || false, quoteLineItemDetailData?.LeakTest__c, QuoteLineItemFields.LeakTest, QuoteLineItemValueTypes.LeakTest, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].leakTest, assemblyFieldsMapDetail?.leakTestReqt, metalComboDetailFieldsToUpdate[metalCombo].leakTestReqt || null, quoteLineItemDetailData?.LeakTestReqt__c, QuoteLineItemFields.LeakTestReqt, QuoteLineItemValueTypes.LeakTestReqt, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].leakTest, assemblyFieldsMapDetail?.leakTestValue, metalComboDetailFieldsToUpdate[metalCombo].leakTestValue || null, quoteLineItemDetailData?.LeakTestValue__c, QuoteLineItemFields.LeakTestValue, QuoteLineItemValueTypes.LeakTestValue, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].leakTest, assemblyFieldsMapDetail?.leakTestPressure, metalComboDetailFieldsToUpdate[metalCombo].leakTestPressure || null, quoteLineItemDetailData?.LeakTestPressure__c, QuoteLineItemFields.LeakTestPressure, QuoteLineItemValueTypes.LeakTestPressure, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].leakTest, assemblyFieldsMapDetail?.leakTestGas, metalComboDetailFieldsToUpdate[metalCombo].leakTestGas || null, quoteLineItemDetailData?.LeakTestGas__c, QuoteLineItemFields.LeakTestGas, QuoteLineItemValueTypes.LeakTestGas, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].sa26345BondStrength, assemblyFieldsMapDetail?.machineBondStrengthBendsperSA26345 || false, metalComboDetailFieldsToUpdate[metalCombo].machineBondStrengthBendsperSA26345 || false, quoteLineItemDetailData?.MachineBondStrengthBendsperSA26345__c, QuoteLineItemFields.MachineBondStrengthBendsperSA26345, QuoteLineItemValueTypes.MachineBondStrengthBendsperSA26345, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].machineSideBends, assemblyFieldsMapDetail?.machineSideBends || false, metalComboDetailFieldsToUpdate[metalCombo].machineSideBends || false, quoteLineItemDetailData?.MachineSideBends__c, QuoteLineItemFields.MachineSideBends, QuoteLineItemValueTypes.MachineSideBends, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].NCBaseChemistryRestrictionsforBonding, assemblyFieldsMapDetail?.NCBaseChemistryRestForBondingNotes, metalComboDetailFieldsToUpdate[metalCombo].NCBaseChemistryRestForBondingNotes || null, quoteLineItemDetailData?.NCBaseChemistryRestForBondingNotes__c, QuoteLineItemFields.NCBaseChemistryRestForBondingNotes, QuoteLineItemValueTypes.NCBaseChemistryRestForBondingNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].NCBaseChemistryRestrictionsforBonding, assemblyFieldsMapDetail?.NCBaseChemistryRestrictionsforBonding, metalComboDetailFieldsToUpdate[metalCombo].NCBaseChemistryRestrictionsforBonding || null, quoteLineItemDetailData?.NCBaseChemistryRestrictionsforBonding__c, QuoteLineItemFields.NCBaseChemistryRestrictionsforBonding, QuoteLineItemValueTypes.NCBaseChemistryRestrictionsforBonding, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].NCBaseHeatTreatmentasSupplied, assemblyFieldsMapDetail?.NCBaseHeatTreatmentasSupplied, metalComboDetailFieldsToUpdate[metalCombo].NCBaseHeatTreatmentasSupplied || null, quoteLineItemDetailData?.NCBaseHeatTreatmentasSupplied__c, QuoteLineItemFields.NCBaseHeatTreatmentasSupplied, QuoteLineItemValueTypes.NCBaseHeatTreatmentasSupplied, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].NCBaseHeatTreatmentasSupplied, assemblyFieldsMapDetail?.NCBaseHeatTreatmentasSuppliedNotes, metalComboDetailFieldsToUpdate[metalCombo].NCBaseHeatTreatmentasSuppliedNotes || null, quoteLineItemDetailData?.NCBaseHeatTreatmentasSuppliedNotes__c, QuoteLineItemFields.NCBaseHeatTreatmentasSuppliedNotes, QuoteLineItemValueTypes.NCBaseHeatTreatmentasSuppliedNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].NCCladChemistryRestrictionsforBonding, assemblyFieldsMapDetail?.NCCladChemistryRestForBondingNotes, metalComboDetailFieldsToUpdate[metalCombo].NCCladChemistryRestForBondingNotes || null, quoteLineItemDetailData?.NCCladChemistryRestForBondingNotes__c, QuoteLineItemFields.NCCladChemistryRestForBondingNotes, QuoteLineItemValueTypes.NCCladChemistryRestForBondingNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].NCCladChemistryRestrictionsforBonding, assemblyFieldsMapDetail?.NCCladChemistryRestrictionsforBonding, metalComboDetailFieldsToUpdate[metalCombo].NCCladChemistryRestrictionsforBonding || null, quoteLineItemDetailData?.NCCladChemistryRestrictionsforBonding__c, QuoteLineItemFields.NCCladChemistryRestrictionsforBonding, QuoteLineItemValueTypes.NCCladChemistryRestrictionsforBonding, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].NCCladHeatTreatmentasSupplied, assemblyFieldsMapDetail?.NCCladHeatTreatmentasSupplied, metalComboDetailFieldsToUpdate[metalCombo].NCCladHeatTreatmentasSupplied || null, quoteLineItemDetailData?.NCCladHeatTreatmentasSupplied__c, QuoteLineItemFields.NCCladHeatTreatmentasSupplied, QuoteLineItemValueTypes.NCCladHeatTreatmentasSupplied, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].NCCladHeatTreatmentasSupplied, assemblyFieldsMapDetail?.NCCladHeatTreatmentasSuppliedNotes, metalComboDetailFieldsToUpdate[metalCombo].NCCladHeatTreatmentasSuppliedNotes || null, quoteLineItemDetailData?.NCCladHeatTreatmentasSuppliedNotes__c, QuoteLineItemFields.NCCladHeatTreatmentasSuppliedNotes, QuoteLineItemValueTypes.NCCladHeatTreatmentasSuppliedNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].phosphorusPercent, assemblyFieldsMapDetail?.phosphorusPercent, metalComboDetailFieldsToUpdate[metalCombo].phosphorusPercent || null, quoteLineItemDetailData?.Phosphorus_Percent__c, QuoteLineItemFields.PhosphorusPercent, QuoteLineItemValueTypes.PhosphorusPercent, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].PMIConfirmBaseMetal, assemblyFieldsMapDetail?.PMIConfirmBaseMetal, metalComboDetailFieldsToUpdate[metalCombo].PMIConfirmBaseMetal || null, quoteLineItemDetailData?.PMIConfirmBaseMetal__c, QuoteLineItemFields.PMIConfirmBaseMetal, QuoteLineItemValueTypes.PMIConfirmBaseMetal, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].PMIConfirmBaseMetal, assemblyFieldsMapDetail?.PMIConfirmBaseMetalNotes, metalComboDetailFieldsToUpdate[metalCombo].PMIConfirmBaseMetalNotes || null, quoteLineItemDetailData?.PMIConfirmBaseMetalNotes__c, QuoteLineItemFields.PMIConfirmBaseMetalNotes, QuoteLineItemValueTypes.PMIConfirmBaseMetalNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].PMIConfirmCladdingMetal, assemblyFieldsMapDetail?.PMIConfirmCladdingMetal, metalComboDetailFieldsToUpdate[metalCombo].PMIConfirmCladdingMetal || null, quoteLineItemDetailData?.PMIConfirmCladdingMetal__c, QuoteLineItemFields.PMIConfirmCladdingMetal, QuoteLineItemValueTypes.PMIConfirmCladdingMetal, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].PMIConfirmCladdingMetal, assemblyFieldsMapDetail?.PMIConfirmCladdingMetalNotes, metalComboDetailFieldsToUpdate[metalCombo].PMIConfirmCladdingMetalNotes || null, quoteLineItemDetailData?.PMIConfirmCladdingMetalNotes__c, QuoteLineItemFields.PMIConfirmCladdingMetalNotes, QuoteLineItemValueTypes.PMIConfirmCladdingMetalNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].PMIofWeldWire, assemblyFieldsMapDetail?.PMIofWeldWire || false, metalComboDetailFieldsToUpdate[metalCombo].PMIofWeldWire || false, quoteLineItemDetailData?.PMIofWeldWire__c, QuoteLineItemFields.PMIofWeldWire, QuoteLineItemValueTypes.PMIofWeldWire, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].PMIPreweldsRequired, assemblyFieldsMapDetail?.PMIPreweldsRequired || false, metalComboDetailFieldsToUpdate[metalCombo].PMIPreweldsRequired || false, quoteLineItemDetailData?.PMIPreweldsRequired__c, QuoteLineItemFields.PMIPreweldsRequired, QuoteLineItemValueTypes.PMIPreweldsRequired, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].postCladBaseMetalUTSpecification, assemblyFieldsMapDetail?.postCladBaseMetalUTSpecification, metalComboDetailFieldsToUpdate[metalCombo].postCladBaseMetalUTSpecification || null, quoteLineItemDetailData?.PostCladBaseMetalUTSpecification__c, QuoteLineItemFields.PostCladBaseMetalUTSpecification, QuoteLineItemValueTypes.PostCladBaseMetalUTSpecification, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].postCladBaseMetalUTSpecification, assemblyFieldsMapDetail?.postCladBaseMetalUTSpecificationNotes, metalComboDetailFieldsToUpdate[metalCombo].postCladBaseMetalUTSpecificationNotes || null, quoteLineItemDetailData?.PostCladBaseMetalUTSpecificationNotes__c, QuoteLineItemFields.PostCladBaseMetalUTSpecificationNotes, QuoteLineItemValueTypes.PostCladBaseMetalUTSpecificationNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].postCladBondUTSpecification, assemblyFieldsMapDetail?.postCladBondUTSpecification, metalComboDetailFieldsToUpdate[metalCombo].postCladBondUTSpecification || null, quoteLineItemDetailData?.PostCladBondUTSpecification__c, QuoteLineItemFields.PostCladBondUTSpecification, QuoteLineItemValueTypes.PostCladBondUTSpecification, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].postCladBondUTSpecification, assemblyFieldsMapDetail?.postCladBondUTSpecificationNotes, metalComboDetailFieldsToUpdate[metalCombo].postCladBondUTSpecificationNotes || null, quoteLineItemDetailData?.PostCladBondUTSpecificationNotes__c, QuoteLineItemFields.PostCladBondUTSpecificationNotes, QuoteLineItemValueTypes.PostCladBondUTSpecificationNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].prebondImpacts, assemblyFieldsMapDetail?.prebondImpacts || false, metalComboDetailFieldsToUpdate[metalCombo].prebondImpacts || false, quoteLineItemDetailData?.PrebondImpacts__c, QuoteLineItemFields.PrebondImpacts, QuoteLineItemValueTypes.PrebondImpacts, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].prebondImpacts, assemblyFieldsMapDetail?.preBondImpactsTestLocation, metalComboDetailFieldsToUpdate[metalCombo].preBondImpactsTestLocation || null, quoteLineItemDetailData?.PreBondImpactsTestLocation__c, QuoteLineItemFields.PreBondImpactsTestLocation, QuoteLineItemValueTypes.PreBondImpactsTestLocation, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].prebondImpacts, assemblyFieldsMapDetail?.preBondImpactsTestDirection, metalComboDetailFieldsToUpdate[metalCombo].preBondImpactsTestDirection || null, quoteLineItemDetailData?.PreBondImpactsTestDirection__c, QuoteLineItemFields.PreBondImpactsTestDirection, QuoteLineItemValueTypes.PreBondImpactsTestDirection, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].prebondImpacts, assemblyFieldsMapDetail?.preBondImpactsTestUOM, metalComboDetailFieldsToUpdate[metalCombo].preBondImpactsTestUOM || null, quoteLineItemDetailData?.PreBondImpactsTestUOM__c, QuoteLineItemFields.PreBondImpactsTestUOM, QuoteLineItemValueTypes.PreBondImpactsTestUOM, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].prebondImpacts, assemblyFieldsMapDetail?.preBondImpactsTestAVG, metalComboDetailFieldsToUpdate[metalCombo].preBondImpactsTestAVG || null, quoteLineItemDetailData?.PreBondImpactsTestAVG__c, QuoteLineItemFields.PreBondImpactsTestAVG, QuoteLineItemValueTypes.PreBondImpactsTestAVG, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].prebondImpacts, assemblyFieldsMapDetail?.preBondImpactsTestMIN, metalComboDetailFieldsToUpdate[metalCombo].preBondImpactsTestMIN || null, quoteLineItemDetailData?.PreBondImpactsTestMIN__c, QuoteLineItemFields.PreBondImpactsTestMIN, QuoteLineItemValueTypes.PreBondImpactsTestMIN, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].prebondImpacts, assemblyFieldsMapDetail?.preBondImpactsTestTemperature, metalComboDetailFieldsToUpdate[metalCombo].preBondImpactsTestTemperature || null, quoteLineItemDetailData?.PreBondImpactsTestTemperature__c, QuoteLineItemFields.PreBondImpactsTestTemperature, QuoteLineItemValueTypes.PreBondImpactsTestTemperature, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].prebondImpacts, assemblyFieldsMapDetail?.preBondImpactsTestTemperatureUOM ? '°' + assemblyFieldsMapDetail?.preBondImpactsTestTemperatureUOM : null, metalComboDetailFieldsToUpdate[metalCombo].preBondImpactsTestTemperatureUOM ? '°' + metalComboDetailFieldsToUpdate[metalCombo].preBondImpactsTestTemperatureUOM : null, quoteLineItemDetailData?.PreBondImpactsTestTemperatureUOM__c, QuoteLineItemFields.PreBondImpactsTestTemperatureUOM, QuoteLineItemValueTypes.PreBondImpactsTestTemperatureUOM, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].precladBaseMetalUT, assemblyFieldsMapDetail?.precladBaseMetalUT, metalComboDetailFieldsToUpdate[metalCombo].precladBaseMetalUT || null, quoteLineItemDetailData?.PrecladBaseMetalUT__c, QuoteLineItemFields.PrecladBaseMetalUT, QuoteLineItemValueTypes.PrecladBaseMetalUT, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].precladBaseMetalUT, assemblyFieldsMapDetail?.NCBaseUTRequirement, metalComboDetailFieldsToUpdate[metalCombo].NCBaseUTRequirement || null, quoteLineItemDetailData?.NCBaseUTRequirement__c, QuoteLineItemFields.NCBaseUTRequirement, QuoteLineItemValueTypes.NCBaseUTRequirement, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].precladBaseMetalUT, assemblyFieldsMapDetail?.precladBaseMetalUTNotes, metalComboDetailFieldsToUpdate[metalCombo].precladBaseMetalUTNotes || null, quoteLineItemDetailData?.PrecladBaseMetalUTNotes__c, QuoteLineItemFields.PrecladBaseMetalUTNotes, QuoteLineItemValueTypes.PrecladBaseMetalUTNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].precladBaseMetalUT, assemblyFieldsMapDetail?.precladBaseMetalUTType, metalComboDetailFieldsToUpdate[metalCombo].precladBaseMetalUTType || null, quoteLineItemDetailData?.PrecladBaseMetalUTType__c, QuoteLineItemFields.PrecladBaseMetalUTType, QuoteLineItemValueTypes.PrecladBaseMetalUTType, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].radiography, assemblyFieldsMapDetail?.radiographyClad, metalComboDetailFieldsToUpdate[metalCombo].radiographyClad || null, quoteLineItemDetailData?.Radiography_Clad__c, QuoteLineItemFields.RadiographyClad, QuoteLineItemValueTypes.RadiographyClad, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].shearTest, assemblyFieldsMapDetail?.shearTestUOM, metalComboDetailFieldsToUpdate[metalCombo]?.shearTestUOM || null, quoteLineItemDetailData?.ShearTestUOM__c, QuoteLineItemFields.ShearTestUOM, QuoteLineItemValueTypes.ShearTestUOM, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].shearTest, assemblyFieldsMapDetail?.shearTestValue, metalComboDetailFieldsToUpdate[metalCombo]?.shearTestValue || null, quoteLineItemDetailData?.ShearTestValue__c, QuoteLineItemFields.ShearTestValue, QuoteLineItemValueTypes.ShearTestValue, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].sulphur, assemblyFieldsMapDetail?.sulphur, metalComboDetailFieldsToUpdate[metalCombo].sulphur || null, quoteLineItemDetailData?.Sulphur__c, QuoteLineItemFields.Sulphur, QuoteLineItemValueTypes.Sulphur, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].throughThicknessTensileSA770YN, assemblyFieldsMapDetail?.throughThicknessTensileSA770YN || false, metalComboDetailFieldsToUpdate[metalCombo].throughThicknessTensileSA770YN || false, quoteLineItemDetailData?.ThroughThicknessTensilePerSA770__c, QuoteLineItemFields.ThroughThicknessTensilePerSA770, QuoteLineItemValueTypes.ThroughThicknessTensilePerSA770, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].vacuumDegassed, assemblyFieldsMapDetail?.vacuumDegassed || false, metalComboDetailFieldsToUpdate[metalCombo].vacuumDegassed || false, quoteLineItemDetailData?.VacuumDegassed__c, QuoteLineItemFields.VacuumDegassed, QuoteLineItemValueTypes.VacuumDegassed, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedListPerAssembly(specifyPerAssemblyMap[metalCombo].xFactorSA387S62, assemblyFieldsMapDetail?.xFactorSA387S62, metalComboDetailFieldsToUpdate[metalCombo].xFactorSA387S62 || null, quoteLineItemDetailData?.XFactorSA387S62__c, QuoteLineItemFields.XFactorSA387S62, QuoteLineItemValueTypes.XFactorSA387S62, lineItemDetailFieldsMap, fieldsUpdated);

  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.headQuantity || null, headRecord?.HeadQuantity__c, QuoteLineItemFields.HeadQuantity, QuoteLineItemValueTypes.HeadQuantity, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.blankSize || null, headRecord?.BlankSize__c, QuoteLineItemFields.BlankSize, QuoteLineItemValueTypes.BlankSize, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.headDiameter || null, headRecord?.HeadDiameter__c, QuoteLineItemFields.HeadDiameter, QuoteLineItemValueTypes.HeadDiameter, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.headFormingType || null, headRecord?.HeadFormingType__c, QuoteLineItemFields.HeadFormingType, QuoteLineItemValueTypes.HeadFormingType, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.straightFlange || null, headRecord?.StraightFlange__c, QuoteLineItemFields.StraightFlange, QuoteLineItemValueTypes.StraightFlange, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.headMaxCenterHole || null, headRecord?.HeadMaxCenterHole__c, QuoteLineItemFields.HeadMaxCenterHole, QuoteLineItemValueTypes.HeadMaxCenterHole, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.insideDishRadius || null, headRecord?.InsideDishRadius__c, QuoteLineItemFields.InsideDishRadius, QuoteLineItemValueTypes.InsideDishRadius, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.cutback || null, headRecord?.Cutback__c, QuoteLineItemFields.Cutback, QuoteLineItemValueTypes.Cutback, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.insideKnuckleRadius || null, headRecord?.InsideKnuckleRadius__c, QuoteLineItemFields.InsideKnuckleRadius, QuoteLineItemValueTypes.InsideKnuckleRadius, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.openEnd || null, headRecord?.OpenEnd__c, QuoteLineItemFields.OpenEnd, QuoteLineItemValueTypes.OpenEnd, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.openEndNotes || null, headRecord?.OpenEndNotes__c, QuoteLineItemFields.OpenEndNotes, QuoteLineItemValueTypes.OpenEndNotes, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.petalToPetal || null, headRecord?.PetalToPetal__c, QuoteLineItemFields.PetalToPetal, QuoteLineItemValueTypes.PetalToPetal, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.petalToPetalNotes || null, headRecord?.PetalToPetalNotes__c, QuoteLineItemFields.PetalToPetalNotes, QuoteLineItemValueTypes.PetalToPetalNotes, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.crownToPetals || null, headRecord?.CrownToPetals__c, QuoteLineItemFields.CrownToPetals, QuoteLineItemValueTypes.CrownToPetals, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.crownToPetalsNotes || null, headRecord?.CrownToPetalsNotes__c, QuoteLineItemFields.CrownToPetalsNotes, QuoteLineItemValueTypes.CrownToPetalsNotes, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.headNumberOfPieces || null, headRecord?.HeadNumberOfPieces__c, QuoteLineItemFields.HeadNumberOfPieces, QuoteLineItemValueTypes.HeadNumberOfPieces, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.numberOfPiecesInHead || null, headRecord?.NumberOfPiecesInHead__c, QuoteLineItemFields.NumberOfPiecesInHead, QuoteLineItemValueTypes.NumberOfPiecesInHead, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.blankCrownCutByNC || false, headRecord?.BlankCrownCutByNC__c, QuoteLineItemFields.BlankCrownCutByNC, QuoteLineItemValueTypes.BlankCrownCutByNC, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.petalsCutByNC || false, headRecord?.PetalsCutByNC__c, QuoteLineItemFields.PetalsCutByNC, QuoteLineItemValueTypes.PetalsCutByNC, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.otherCladGroupsCombinedInThisHead || null, headRecord?.OtherCladGroupsCombinedInThisHead__c, QuoteLineItemFields.OtherCladGroupsCombinedInThisHead, QuoteLineItemValueTypes.OtherCladGroupsCombinedInThisHead, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.nobelCladProcedure || null, headRecord?.NobelCladProcedure__c, QuoteLineItemFields.NobelCladProcedure, QuoteLineItemValueTypes.NobelCladProcedure, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.ncChemistryRestrictionsForBonding || null, headRecord?.NCChemistryRestrictionsForBonding__c, QuoteLineItemFields.NCChemistryRestrictionsForBonding, QuoteLineItemValueTypes.NCChemistryRestrictionsForBonding, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.ncChemistryRestrictionsForBondingNotes || null, headRecord?.NCChemistryRestrictionsForBondingNotes__c, QuoteLineItemFields.NCChemistryRestrictionsForBondingNotes, QuoteLineItemValueTypes.NCChemistryRestrictionsForBondingNotes, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.headFormingCostPerHead || null, headRecord?.HeadFormingCostPerHead__c, QuoteLineItemFields.HeadFormingCostPerHead, QuoteLineItemValueTypes.HeadFormingCostPerHead, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.headTimeToFormer || null, headRecord?.HeadTimeToFormer__c, QuoteLineItemFields.HeadTimeToFormer, QuoteLineItemValueTypes.HeadTimeToFormer, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.headTimeToFormHead || null, headRecord?.HeadTimeToFormHead__c, QuoteLineItemFields.HeadTimeToFormHead, QuoteLineItemValueTypes.HeadTimeToFormHead, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapHead?.poNotes || null, headRecord?.HeadFormingNotes1__c, QuoteLineItemFields.HeadFormingNotes1, QuoteLineItemValueTypes.HeadFormingNotes1, lineItemHeadFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.headFormingVendor || null, outsideServicesRecord?.HeadFormingVendor__c, QuoteLineItemFields.HeadFormingVendor, QuoteLineItemValueTypes.HeadFormingVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.headFormingIDvsOD || null, outsideServicesRecord?.HeadFormingIDvsOD__c, QuoteLineItemFields.HeadFormingIDvsOD, QuoteLineItemValueTypes.HeadFormingIDvsOD, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.simulateHeatTreatmentAtVendor || false, outsideServicesRecord?.SimulateHeatTreatmentAtVendor__c, QuoteLineItemFields.SimulateHeatTreatmentAtVendor, QuoteLineItemValueTypes.SimulateHeatTreatmentAtVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.simulationHTCycle1 || null, outsideServicesRecord?.SimulationHTCycle1__c, QuoteLineItemFields.SimulationHTCycle1, QuoteLineItemValueTypes.SimulationHTCycle1, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.simulationHTCycle2 || null, outsideServicesRecord?.SimulationHTCycle2__c, QuoteLineItemFields.SimulationHTCycle2, QuoteLineItemValueTypes.SimulationHTCycle2, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.simulationHTCycle3 || null, outsideServicesRecord?.SimulationHTCycle3__c, QuoteLineItemFields.SimulationHTCycle3, QuoteLineItemValueTypes.SimulationHTCycle3, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.simulationHTCycle4 || null, outsideServicesRecord?.SimulationHTCycle4__c, QuoteLineItemFields.SimulationHTCycle4, QuoteLineItemValueTypes.SimulationHTCycle4, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.simulationHTCycle5 || null, outsideServicesRecord?.SimulationHTCycle5__c, QuoteLineItemFields.SimulationHTCycle5, QuoteLineItemValueTypes.SimulationHTCycle5, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.headFreightCostToFormer || null, outsideServicesRecord?.HeadFreightCostToFormer__c, QuoteLineItemFields.HeadFreightCostToFormer, QuoteLineItemValueTypes.HeadFreightCostToFormer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.headContourToleranceHTOptions || null, outsideServicesRecord?.HeadContourToleranceHTOptions__c, QuoteLineItemFields.HeadContourToleranceHTOptions, QuoteLineItemValueTypes.HeadContourToleranceHTOptions, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.additionalHeadInformation || null, outsideServicesRecord?.AdditionalHeadInformation__c, QuoteLineItemFields.AdditionalHeadInformation, QuoteLineItemValueTypes.AdditionalHeadInformation, lineItemOutsideServicesFieldsMap, fieldsUpdated);

  // TestingBacker
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerEventDescription || null, outsideServicesRecord?.TestingBKDescriptionOfEvent__c, QuoteLineItemFields.TestingBKDescriptionOfEvent, QuoteLineItemValueTypes.TestingBKDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerEventDuration || null, outsideServicesRecord?.TestingBKDurationOfEventDays__c, QuoteLineItemFields.TestingBKDurationOfEventDays, QuoteLineItemValueTypes.TestingBKDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerTransitTime || null, outsideServicesRecord?.TestingBKTotalTransitTimeDays__c, QuoteLineItemFields.TestingBKTotalTransitTimeDays, QuoteLineItemValueTypes.TestingBKTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerEventCost || null, outsideServicesRecord?.TestingBKCostOfEvent__c, QuoteLineItemFields.TestingBKCostOfEvent, QuoteLineItemValueTypes.TestingBKCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerOutboundShippingCost || null, outsideServicesRecord?.TestingBKOutboundShippingCost__c, QuoteLineItemFields.TestingBKOutboundShippingCost, QuoteLineItemValueTypes.TestingBKOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerTotalOutboundShippingCost || null, outsideServicesRecord?.TestingBKTotalOutboundShippingCost__c, QuoteLineItemFields.TestingBKTotalOutboundShippingCost, QuoteLineItemValueTypes.TestingBKTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerInboundShippingCost || null, outsideServicesRecord?.TestingBKInboundShippingCost__c, QuoteLineItemFields.TestingBKInboundShippingCost, QuoteLineItemValueTypes.TestingBKInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerTotalInboundShippingCost || null, outsideServicesRecord?.TestingBKTotalInboundShippingCost__c, QuoteLineItemFields.TestingBKTotalInboundShippingCost, QuoteLineItemValueTypes.TestingBKTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerVendor || null, outsideServicesRecord?.TestingBKVendor__c, QuoteLineItemFields.TestingBKVendor, QuoteLineItemValueTypes.TestingBKVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerShouldShipToCustomer || false, outsideServicesRecord?.TestingBKShipToCustomer__c, QuoteLineItemFields.TestingBKShipToCustomer, QuoteLineItemValueTypes.TestingBKShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingBackerAdditionalNotes || null, outsideServicesRecord?.TestingBKNotes__c, QuoteLineItemFields.TestingBKNotes, QuoteLineItemValueTypes.TestingBKNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // TestingCladder
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderEventDescription || null, outsideServicesRecord?.TestingCLDescriptionOfEvent__c, QuoteLineItemFields.TestingCLDescriptionOfEvent, QuoteLineItemValueTypes.TestingCLDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderEventDuration || null, outsideServicesRecord?.TestingCLDurationOfEventDays__c, QuoteLineItemFields.TestingCLDurationOfEventDays, QuoteLineItemValueTypes.TestingCLDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderTransitTime || null, outsideServicesRecord?.TestingCLTotalTransitTimeDays__c, QuoteLineItemFields.TestingCLTotalTransitTimeDays, QuoteLineItemValueTypes.TestingCLTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderEventCost || null, outsideServicesRecord?.TestingCLCostOfEvent__c, QuoteLineItemFields.TestingCLCostOfEvent, QuoteLineItemValueTypes.TestingCLCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderOutboundShippingCost || null, outsideServicesRecord?.TestingCLOutboundShippingCost__c, QuoteLineItemFields.TestingCLOutboundShippingCost, QuoteLineItemValueTypes.TestingCLOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderTotalOutboundShippingCost || null, outsideServicesRecord?.TestingCLTotalOutboundShippingCost__c, QuoteLineItemFields.TestingCLTotalOutboundShippingCost, QuoteLineItemValueTypes.TestingCLTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderInboundShippingCost || null, outsideServicesRecord?.TestingCLInboundShippingCost__c, QuoteLineItemFields.TestingCLInboundShippingCost, QuoteLineItemValueTypes.TestingCLInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderTotalInboundShippingCost || null, outsideServicesRecord?.TestingCLTotalInboundShippingCost__c, QuoteLineItemFields.TestingCLTotalInboundShippingCost, QuoteLineItemValueTypes.TestingCLTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderVendor || null, outsideServicesRecord?.TestingCLVendor__c, QuoteLineItemFields.TestingCLVendor, QuoteLineItemValueTypes.TestingCLVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderShouldShipToCustomer || false, outsideServicesRecord?.TestingCLShipToCustomer__c, QuoteLineItemFields.TestingCLShipToCustomer, QuoteLineItemValueTypes.TestingCLShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladderAdditionalNotes || null, outsideServicesRecord?.TestingCLNotes__c, QuoteLineItemFields.TestingCLNotes, QuoteLineItemValueTypes.TestingCLNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // TestingCladProduct
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductEventDescription || null, outsideServicesRecord?.TestingDescriptionOfEvent__c, QuoteLineItemFields.TestingDescriptionOfEvent, QuoteLineItemValueTypes.TestingDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductEventDuration || null, outsideServicesRecord?.TestingDurationOfEventDays__c, QuoteLineItemFields.TestingDurationOfEventDays, QuoteLineItemValueTypes.TestingDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductTransitTime || null, outsideServicesRecord?.TestingTotalTransitTimeDays__c, QuoteLineItemFields.TestingTotalTransitTimeDays, QuoteLineItemValueTypes.TestingTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductEventCost || null, outsideServicesRecord?.TestingCostOfEvent__c, QuoteLineItemFields.TestingCostOfEvent, QuoteLineItemValueTypes.TestingCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductOutboundShippingCost || null, outsideServicesRecord?.TestingOutboundShippingCost__c, QuoteLineItemFields.TestingOutboundShippingCost, QuoteLineItemValueTypes.TestingOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductTotalOutboundShippingCost || null, outsideServicesRecord?.TestingTotalOutboundShippingCost__c, QuoteLineItemFields.TestingTotalOutboundShippingCost, QuoteLineItemValueTypes.TestingTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductInboundShippingCost || null, outsideServicesRecord?.TestingInboundShippingCost__c, QuoteLineItemFields.TestingInboundShippingCost, QuoteLineItemValueTypes.TestingInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductTotalInboundShippingCost || null, outsideServicesRecord?.TestingTotalInboundShippingCost__c, QuoteLineItemFields.TestingTotalInboundShippingCost, QuoteLineItemValueTypes.TestingTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductVendor || null, outsideServicesRecord?.TestingVendor__c, QuoteLineItemFields.TestingVendor, QuoteLineItemValueTypes.TestingVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductShouldShipToCustomer || false, outsideServicesRecord?.TestingShipToCustomer__c, QuoteLineItemFields.TestingShipToCustomer, QuoteLineItemValueTypes.TestingShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.testingCladProductAdditionalNotes || null, outsideServicesRecord?.TestingNotes__c, QuoteLineItemFields.TestingNotes, QuoteLineItemValueTypes.TestingNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // HeatTreatBacker
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerEventDescription || null, outsideServicesRecord?.BackerMetalHTDescriptionOfEvent__c, QuoteLineItemFields.BackerMetalHTDescriptionOfEvent, QuoteLineItemValueTypes.BackerMetalHTDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerTime || null, outsideServicesRecord?.BackerMetalHTDurationOfEventDays__c, QuoteLineItemFields.BackerMetalHTDurationOfEventDays, QuoteLineItemValueTypes.BackerMetalHTDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerTransitTime || null, outsideServicesRecord?.BackerMetalHTTotalTransitTimeDays__c, QuoteLineItemFields.BackerMetalHTTotalTransitTimeDays, QuoteLineItemValueTypes.BackerMetalHTTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerEventCost || null, outsideServicesRecord?.BackerMetalHTCostOfEvent__c, QuoteLineItemFields.BackerMetalHTCostOfEvent, QuoteLineItemValueTypes.BackerMetalHTCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerTotalEventCost || null, outsideServicesRecord?.BackerMetalHTTotalCostOfEvent__c, QuoteLineItemFields.BackerMetalHTTotalCostOfEvent, QuoteLineItemValueTypes.BackerMetalHTTotalCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerOutboundShippingCost || null, outsideServicesRecord?.BackerMetalHTOutboundShippingCost__c, QuoteLineItemFields.BackerMetalHTOutboundShippingCost, QuoteLineItemValueTypes.BackerMetalHTOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerTotalOutboundShippingCost || null, outsideServicesRecord?.BackerMetalHTTotalOutboundShippingCost__c, QuoteLineItemFields.BackerMetalHTTotalOutboundShippingCost, QuoteLineItemValueTypes.BackerMetalHTTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerInboundShippingCost || null, outsideServicesRecord?.BackerMetalHTInboundShippingCost__c, QuoteLineItemFields.BackerMetalHTInboundShippingCost, QuoteLineItemValueTypes.BackerMetalHTInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerTotalInboundShippingCost || null, outsideServicesRecord?.BackerMetalHTTotalInboundShippingCost__c, QuoteLineItemFields.BackerMetalHTTotalInboundShippingCost, QuoteLineItemValueTypes.BackerMetalHTTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerVendor || null, outsideServicesRecord?.BackerMetalHTVendor__c, QuoteLineItemFields.BackerMetalHTVendor, QuoteLineItemValueTypes.BackerMetalHTVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerShouldShipToCustomer || false, outsideServicesRecord?.BackerMetalHTShipToCustomer__c, QuoteLineItemFields.BackerMetalHTShipToCustomer, QuoteLineItemValueTypes.BackerMetalHTShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerHeatTreatmentType || null, outsideServicesRecord?.BackerMetalHTType__c, QuoteLineItemFields.BackerMetalHTType, QuoteLineItemValueTypes.BackerMetalHTType, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatBackerAdditionalNotes || null, outsideServicesRecord?.BackerMetalHTNotes__c, QuoteLineItemFields.BackerMetalHTNotes, QuoteLineItemValueTypes.BackerMetalHTNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // HeatTreatCladder
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderEventDescription || null, outsideServicesRecord?.CladderMetalHTDescriptionOfEvent__c, QuoteLineItemFields.CladderMetalHTDescriptionOfEvent, QuoteLineItemValueTypes.CladderMetalHTDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderTime || null, outsideServicesRecord?.CladderMetalHTDurationOfEventDays__c, QuoteLineItemFields.CladderMetalHTDurationOfEventDays, QuoteLineItemValueTypes.CladderMetalHTDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderTransitTime || null, outsideServicesRecord?.CladderMetalHTTotalTransitTimeDays__c, QuoteLineItemFields.CladderMetalHTTotalTransitTimeDays, QuoteLineItemValueTypes.CladderMetalHTTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderEventCost || null, outsideServicesRecord?.CladderMetalHTCostOfEvent__c, QuoteLineItemFields.CladderMetalHTCostOfEvent, QuoteLineItemValueTypes.CladderMetalHTCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderTotalEventCost || null, outsideServicesRecord?.CladderMetalHTTotalCostOfEvent__c, QuoteLineItemFields.CladderMetalHTTotalCostOfEvent, QuoteLineItemValueTypes.CladderMetalHTTotalCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderOutboundShippingCost || null, outsideServicesRecord?.CladderMetalHTOutboundShippingCost__c, QuoteLineItemFields.CladderMetalHTOutboundShippingCost, QuoteLineItemValueTypes.CladderMetalHTOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderTotalOutboundShippingCost || null, outsideServicesRecord?.CladderMetalHTTotalOutboundShippingCost__c, QuoteLineItemFields.CladderMetalHTTotalOutboundShippingCost, QuoteLineItemValueTypes.CladderMetalHTTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderInboundShippingCost || null, outsideServicesRecord?.CladderMetalHTInboundShippingCost__c, QuoteLineItemFields.CladderMetalHTInboundShippingCost, QuoteLineItemValueTypes.CladderMetalHTInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderTotalInboundShippingCost || null, outsideServicesRecord?.CladderMetalHTTotalInboundShippingCost__c, QuoteLineItemFields.CladderMetalHTTotalInboundShippingCost, QuoteLineItemValueTypes.CladderMetalHTTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderVendor || null, outsideServicesRecord?.CladderMetalHTVendor__c, QuoteLineItemFields.CladderMetalHTVendor, QuoteLineItemValueTypes.CladderMetalHTVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderShouldShipToCustomer || false, outsideServicesRecord?.CladderMetalHTShipToCustomer__c, QuoteLineItemFields.CladderMetalHTShipToCustomer, QuoteLineItemValueTypes.CladderMetalHTShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderHeatTreatmentType || null, outsideServicesRecord?.CladderMetalHTType__c, QuoteLineItemFields.CladderMetalHTType, QuoteLineItemValueTypes.CladderMetalHTType, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladderAdditionalNotes || null, outsideServicesRecord?.CladderMetalHTNotes__c, QuoteLineItemFields.CladderMetalHTNotes, QuoteLineItemValueTypes.CladderMetalHTNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // HeatTreatCladProduct
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductEventDescription || null, outsideServicesRecord?.PostbondHTDescriptionOfEvent__c, QuoteLineItemFields.PostbondHTDescriptionOfEvent, QuoteLineItemValueTypes.PostbondHTDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductTime || null, outsideServicesRecord?.PostbondHTDurationOfEventDays__c, QuoteLineItemFields.PostbondHTDurationOfEventDays, QuoteLineItemValueTypes.PostbondHTDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductTransitTime || null, outsideServicesRecord?.PostbondHTTotalTransitTimeDays__c, QuoteLineItemFields.PostbondHTTotalTransitTimeDays, QuoteLineItemValueTypes.PostbondHTTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductEventCost || null, outsideServicesRecord?.PostbondHTCostOfEvent__c, QuoteLineItemFields.PostbondHTCostOfEvent, QuoteLineItemValueTypes.PostbondHTCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductTotalEventCost || null, outsideServicesRecord?.PostbondHTTotalCostOfEvent__c, QuoteLineItemFields.PostbondHTTotalCostOfEvent, QuoteLineItemValueTypes.PostbondHTTotalCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductOutboundShippingCost || null, outsideServicesRecord?.PostbondHTOutboundShippingCost__c, QuoteLineItemFields.PostbondHTOutboundShippingCost, QuoteLineItemValueTypes.PostbondHTOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductTotalOutboundShippingCost || null, outsideServicesRecord?.PostbondHTTotalOutboundShippingCost__c, QuoteLineItemFields.PostbondHTTotalOutboundShippingCost, QuoteLineItemValueTypes.PostbondHTTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductInboundShippingCost || null, outsideServicesRecord?.PostbondHTInboundShippingCost__c, QuoteLineItemFields.PostbondHTInboundShippingCost, QuoteLineItemValueTypes.PostbondHTInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductTotalInboundShippingCost || null, outsideServicesRecord?.PostbondHTTotalInboundShippingCost__c, QuoteLineItemFields.PostbondHTTotalInboundShippingCost, QuoteLineItemValueTypes.PostbondHTTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductVendor || null, outsideServicesRecord?.PostbondHTVendor__c, QuoteLineItemFields.PostbondHTVendor, QuoteLineItemValueTypes.PostbondHTVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductShouldShipToCustomer || false, outsideServicesRecord?.PostbondHTShipToCustomer__c, QuoteLineItemFields.PostbondHTShipToCustomer, QuoteLineItemValueTypes.PostbondHTShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductHeatTreatmentType || null, outsideServicesRecord?.PostbondHTType__c, QuoteLineItemFields.PostbondHTType, QuoteLineItemValueTypes.PostbondHTType, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.heatTreatCladProductAdditionalNotes || null, outsideServicesRecord?.PostbondHTNotes__c, QuoteLineItemFields.PostbondHTNotes, QuoteLineItemValueTypes.PostbondHTNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // Welding
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingEventDescription || null, outsideServicesRecord?.WeldingDescriptionOfEvent__c, QuoteLineItemFields.WeldingDescriptionOfEvent, QuoteLineItemValueTypes.WeldingDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingTime || null, outsideServicesRecord?.WeldingDurationOfEventDays__c, QuoteLineItemFields.WeldingDurationOfEventDays, QuoteLineItemValueTypes.WeldingDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingTransitTime || null, outsideServicesRecord?.WeldingTotalTransitTimeDays__c, QuoteLineItemFields.WeldingTotalTransitTimeDays, QuoteLineItemValueTypes.WeldingTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingEventCost || null, outsideServicesRecord?.WeldingCostOfEvent__c, QuoteLineItemFields.WeldingCostOfEvent, QuoteLineItemValueTypes.WeldingCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingOutboundShippingCost || null, outsideServicesRecord?.WeldingOutboundShippingCost__c, QuoteLineItemFields.WeldingOutboundShippingCost, QuoteLineItemValueTypes.WeldingOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingTotalOutboundShippingCost || null, outsideServicesRecord?.WeldingTotalOutboundShippingCost__c, QuoteLineItemFields.WeldingTotalOutboundShippingCost, QuoteLineItemValueTypes.WeldingTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingInboundShippingCost || null, outsideServicesRecord?.WeldingInboundShippingCost__c, QuoteLineItemFields.WeldingInboundShippingCost, QuoteLineItemValueTypes.WeldingInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingTotalInboundShippingCost || null, outsideServicesRecord?.WeldingTotalInboundShippingCost__c, QuoteLineItemFields.WeldingTotalInboundShippingCost, QuoteLineItemValueTypes.WeldingTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingVendor || null, outsideServicesRecord?.WeldingVendor__c, QuoteLineItemFields.WeldingVendor, QuoteLineItemValueTypes.WeldingVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingShouldShipToCustomer || false, outsideServicesRecord?.WeldingShipToCustomer__c, QuoteLineItemFields.WeldingShipToCustomer, QuoteLineItemValueTypes.WeldingShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.weldingAdditionalNotes || null, outsideServicesRecord?.WeldingNotes__c, QuoteLineItemFields.WeldingNotes, QuoteLineItemValueTypes.WeldingNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // X-Ray
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayEventDescription || null, outsideServicesRecord?.XRayServicesDescriptionOfEvent__c, QuoteLineItemFields.XRayServicesDescriptionOfEvent, QuoteLineItemValueTypes.XRayServicesDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayTime || null, outsideServicesRecord?.XRayServicesDurationOfEventDays__c, QuoteLineItemFields.XRayServicesDurationOfEventDays, QuoteLineItemValueTypes.XRayServicesDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayTransitTime || null, outsideServicesRecord?.XRayServicesTotalTransitTimeDays__c, QuoteLineItemFields.XRayServicesTotalTransitTimeDays, QuoteLineItemValueTypes.XRayServicesTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayEventCost || null, outsideServicesRecord?.XRayServicesCostOfEvent__c, QuoteLineItemFields.XRayServicesCostOfEvent, QuoteLineItemValueTypes.XRayServicesCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayOutboundShippingCost || null, outsideServicesRecord?.XRayServicesOutboundShippingCost__c, QuoteLineItemFields.XRayServicesOutboundShippingCost, QuoteLineItemValueTypes.XRayServicesOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayTotalOutboundShippingCost || null, outsideServicesRecord?.XRayServicesTotalOutboundShippingCost__c, QuoteLineItemFields.XRayServicesTotalOutboundShippingCost, QuoteLineItemValueTypes.XRayServicesTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayInboundShippingCost || null, outsideServicesRecord?.XRayServicesInboundShippingCost__c, QuoteLineItemFields.XRayServicesInboundShippingCost, QuoteLineItemValueTypes.XRayServicesInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayTotalInboundShippingCost || null, outsideServicesRecord?.XRayServicesTotalInboundShippingCost__c, QuoteLineItemFields.XRayServicesTotalInboundShippingCost, QuoteLineItemValueTypes.XRayServicesTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayVendor || null, outsideServicesRecord?.XRayServicesVendor__c, QuoteLineItemFields.XRayServicesVendor, QuoteLineItemValueTypes.XRayServicesVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayShouldShipToCustomer || false, outsideServicesRecord?.XRayServicesShipToCustomer__c, QuoteLineItemFields.XRayServicesShipToCustomer, QuoteLineItemValueTypes.XRayServicesShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.xRayAdditionalNotes || null, outsideServicesRecord?.XRayServicesNotes__c, QuoteLineItemFields.XRayServicesNotes, QuoteLineItemValueTypes.XRayServicesNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // PreCutFlattening
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningEventDescription || null, outsideServicesRecord?.FlatteningBKDescriptionOfEvent__c, QuoteLineItemFields.FlatteningBKDescriptionOfEvent, QuoteLineItemValueTypes.FlatteningBKDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningTime || null, outsideServicesRecord?.FlatteningBKDurationOfEventDays__c, QuoteLineItemFields.FlatteningBKDurationOfEventDays, QuoteLineItemValueTypes.FlatteningBKDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningTransitTime || null, outsideServicesRecord?.FlatteningBKTotalTransitTimeDays__c, QuoteLineItemFields.FlatteningBKTotalTransitTimeDays, QuoteLineItemValueTypes.FlatteningBKTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningEventCost || null, outsideServicesRecord?.FlatteningBKCostOfEvent__c, QuoteLineItemFields.FlatteningBKCostOfEvent, QuoteLineItemValueTypes.FlatteningBKCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningOutboundShippingCost || null, outsideServicesRecord?.FlatteningBKOutboundShippingCost__c, QuoteLineItemFields.FlatteningBKOutboundShippingCost, QuoteLineItemValueTypes.FlatteningBKOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningTotalOutboundShippingCost || null, outsideServicesRecord?.FlatteningBKTotalOutboundShippingCost__c, QuoteLineItemFields.FlatteningBKTotalOutboundShippingCost, QuoteLineItemValueTypes.FlatteningBKTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningInboundShippingCost || null, outsideServicesRecord?.FlatteningBKInboundShippingCost__c, QuoteLineItemFields.FlatteningBKInboundShippingCost, QuoteLineItemValueTypes.FlatteningBKInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningTotalInboundShippingCost || null, outsideServicesRecord?.FlatteningBKTotalInboundShippingCost__c, QuoteLineItemFields.FlatteningBKTotalInboundShippingCost, QuoteLineItemValueTypes.FlatteningBKTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningVendor || null, outsideServicesRecord?.FlatteningBKVendor__c, QuoteLineItemFields.FlatteningBKVendor, QuoteLineItemValueTypes.FlatteningBKVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningShouldShipToCustomer || false, outsideServicesRecord?.FlatteningBKShipToCustomer__c, QuoteLineItemFields.FlatteningBKShipToCustomer, QuoteLineItemValueTypes.FlatteningBKShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.preCutFlatteningAdditionalNotes || null, outsideServicesRecord?.FlatteningBKNotes__c, QuoteLineItemFields.FlatteningBKNotes, QuoteLineItemValueTypes.FlatteningBKNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // Cutting
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingEventDescription || null, outsideServicesRecord?.CuttingDescriptionOfEvent__c, QuoteLineItemFields.CuttingDescriptionOfEvent, QuoteLineItemValueTypes.CuttingDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingTime || null, outsideServicesRecord?.CuttingDurationOfEventDays__c, QuoteLineItemFields.CuttingDurationOfEventDays, QuoteLineItemValueTypes.CuttingDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingTransitTime || null, outsideServicesRecord?.CuttingTotalTransitTimeDays__c, QuoteLineItemFields.CuttingTotalTransitTimeDays, QuoteLineItemValueTypes.CuttingTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingEventCost || null, outsideServicesRecord?.CuttingCostOfEvent__c, QuoteLineItemFields.CuttingCostOfEvent, QuoteLineItemValueTypes.CuttingCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingOutboundShippingCost || null, outsideServicesRecord?.CuttingOutboundShippingCost__c, QuoteLineItemFields.CuttingOutboundShippingCost, QuoteLineItemValueTypes.CuttingOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingTotalOutboundShippingCost || null, outsideServicesRecord?.CuttingTotalOutboundShippingCost__c, QuoteLineItemFields.CuttingTotalOutboundShippingCost, QuoteLineItemValueTypes.CuttingTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingInboundShippingCost || null, outsideServicesRecord?.CuttingInboundShippingCost__c, QuoteLineItemFields.CuttingInboundShippingCost, QuoteLineItemValueTypes.CuttingInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingTotalInboundShippingCost || null, outsideServicesRecord?.CuttingTotalInboundShippingCost__c, QuoteLineItemFields.CuttingTotalInboundShippingCost, QuoteLineItemValueTypes.CuttingTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingVendor || null, outsideServicesRecord?.CuttingVendor__c, QuoteLineItemFields.CuttingVendor, QuoteLineItemValueTypes.CuttingVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingShouldShipToCustomer || false, outsideServicesRecord?.CuttingShipToCustomer__c, QuoteLineItemFields.CuttingShipToCustomer, QuoteLineItemValueTypes.CuttingShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.cuttingAdditionalNotes || null, outsideServicesRecord?.CuttingNotes__c, QuoteLineItemFields.CuttingNotes, QuoteLineItemValueTypes.CuttingNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // WaterjetCutting
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingEventDescription || null, outsideServicesRecord?.WaterJetCuttingDescriptionOfEvent__c, QuoteLineItemFields.WaterJetCuttingDescriptionOfEvent, QuoteLineItemValueTypes.WaterJetCuttingDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingTime || null, outsideServicesRecord?.WaterJetCuttingDurationOfEventDays__c, QuoteLineItemFields.WaterJetCuttingDurationOfEventDays, QuoteLineItemValueTypes.WaterJetCuttingDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingTransitTime || null, outsideServicesRecord?.WaterJetCuttingTotalTransitTimeDays__c, QuoteLineItemFields.WaterJetCuttingTotalTransitTimeDays, QuoteLineItemValueTypes.WaterJetCuttingTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingEventCost || null, outsideServicesRecord?.WaterJetCuttingCostOfEvent__c, QuoteLineItemFields.WaterJetCuttingCostOfEvent, QuoteLineItemValueTypes.WaterJetCuttingCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingOutboundShippingCost || null, outsideServicesRecord?.WaterJetCuttingOutboundShippingCost__c, QuoteLineItemFields.WaterJetCuttingOutboundShippingCost, QuoteLineItemValueTypes.WaterJetCuttingOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingTotalOutboundShippingCost || null, outsideServicesRecord?.WaterJetCuttingTotalOutboundShippingCost__c, QuoteLineItemFields.WaterJetCuttingTotalOutboundShippingCost, QuoteLineItemValueTypes.WaterJetCuttingTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingInboundShippingCost || null, outsideServicesRecord?.WaterJetCuttingInboundShippingCost__c, QuoteLineItemFields.WaterJetCuttingInboundShippingCost, QuoteLineItemValueTypes.WaterJetCuttingInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingTotalInboundShippingCost || null, outsideServicesRecord?.WaterJetCuttingTotalInboundShippingCost__c, QuoteLineItemFields.WaterJetCuttingTotalInboundShippingCost, QuoteLineItemValueTypes.WaterJetCuttingTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingVendor || null, outsideServicesRecord?.WaterJetCuttingVendor__c, QuoteLineItemFields.WaterJetCuttingVendor, QuoteLineItemValueTypes.WaterJetCuttingVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingShouldShipToCustomer || false, outsideServicesRecord?.WaterJetCuttingShipToCustomer__c, QuoteLineItemFields.WaterJetCuttingShipToCustomer, QuoteLineItemValueTypes.WaterJetCuttingShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.waterjetCuttingAdditionalNotes || null, outsideServicesRecord?.WaterJetCuttingNotes__c, QuoteLineItemFields.WaterJetCuttingNotes, QuoteLineItemValueTypes.WaterJetCuttingNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // PostCutFlattening
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningEventDescription || null, outsideServicesRecord?.FlatteningDescriptionOfEvent__c, QuoteLineItemFields.FlatteningDescriptionOfEvent, QuoteLineItemValueTypes.FlatteningDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningTime || null, outsideServicesRecord?.FlatteningDurationOfEventDays__c, QuoteLineItemFields.FlatteningDurationOfEventDays, QuoteLineItemValueTypes.FlatteningDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningTransitTime || null, outsideServicesRecord?.FlatteningTotalTransitTimeDays__c, QuoteLineItemFields.FlatteningTotalTransitTimeDays, QuoteLineItemValueTypes.FlatteningTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningEventCost || null, outsideServicesRecord?.FlatteningCostOfEvent__c, QuoteLineItemFields.FlatteningCostOfEvent, QuoteLineItemValueTypes.FlatteningCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningOutboundShippingCost || null, outsideServicesRecord?.FlatteningOutboundShippingCost__c, QuoteLineItemFields.FlatteningOutboundShippingCost, QuoteLineItemValueTypes.FlatteningOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningTotalOutboundShippingCost || null, outsideServicesRecord?.FlatteningTotalOutboundShippingCost__c, QuoteLineItemFields.FlatteningTotalOutboundShippingCost, QuoteLineItemValueTypes.FlatteningTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningInboundShippingCost || null, outsideServicesRecord?.FlatteningInboundShippingCost__c, QuoteLineItemFields.FlatteningInboundShippingCost, QuoteLineItemValueTypes.FlatteningInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningTotalInboundShippingCost || null, outsideServicesRecord?.FlatteningTotalInboundShippingCost__c, QuoteLineItemFields.FlatteningTotalInboundShippingCost, QuoteLineItemValueTypes.FlatteningTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningVendor || null, outsideServicesRecord?.FlatteningVendor__c, QuoteLineItemFields.FlatteningVendor, QuoteLineItemValueTypes.FlatteningVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningShouldShipToCustomer || false, outsideServicesRecord?.FlatteningShipToCustomer__c, QuoteLineItemFields.FlatteningShipToCustomer, QuoteLineItemValueTypes.FlatteningShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postCutFlatteningAdditionalNotes || null, outsideServicesRecord?.FlatteningNotes__c, QuoteLineItemFields.FlatteningNotes, QuoteLineItemValueTypes.FlatteningNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // Machining
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningEventDescription || null, outsideServicesRecord?.MachiningDescriptionOfEvent__c, QuoteLineItemFields.MachiningDescriptionOfEvent, QuoteLineItemValueTypes.MachiningDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningTime || null, outsideServicesRecord?.MachiningDurationOfEventDays__c, QuoteLineItemFields.MachiningDurationOfEventDays, QuoteLineItemValueTypes.MachiningDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningTransitTime || null, outsideServicesRecord?.MachiningTotalTransitTimeDays__c, QuoteLineItemFields.MachiningTotalTransitTimeDays, QuoteLineItemValueTypes.MachiningTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningEventCost || null, outsideServicesRecord?.MachiningCostOfEvent__c, QuoteLineItemFields.MachiningCostOfEvent, QuoteLineItemValueTypes.MachiningCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningOutboundShippingCost || null, outsideServicesRecord?.MachiningOutboundShippingCost__c, QuoteLineItemFields.MachiningOutboundShippingCost, QuoteLineItemValueTypes.MachiningOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningTotalOutboundShippingCost || null, outsideServicesRecord?.MachiningTotalOutboundShippingCost__c, QuoteLineItemFields.MachiningTotalOutboundShippingCost, QuoteLineItemValueTypes.MachiningTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningInboundShippingCost || null, outsideServicesRecord?.MachiningInboundShippingCost__c, QuoteLineItemFields.MachiningInboundShippingCost, QuoteLineItemValueTypes.MachiningInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningTotalInboundShippingCost || null, outsideServicesRecord?.MachiningTotalInboundShippingCost__c, QuoteLineItemFields.MachiningTotalInboundShippingCost, QuoteLineItemValueTypes.MachiningTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningVendor || null, outsideServicesRecord?.MachiningVendor__c, QuoteLineItemFields.MachiningVendor, QuoteLineItemValueTypes.MachiningVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningShouldShipToCustomer || false, outsideServicesRecord?.MachiningShipToCustomer__c, QuoteLineItemFields.MachiningShipToCustomer, QuoteLineItemValueTypes.MachiningShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.machiningAdditionalNotes || null, outsideServicesRecord?.MachiningNotes__c, QuoteLineItemFields.MachiningNotes, QuoteLineItemValueTypes.MachiningNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // InspectionBacker
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerEventDescription || null, outsideServicesRecord?.InspectionBKDescriptionOfEvent__c, QuoteLineItemFields.InspectionBKDescriptionOfEvent, QuoteLineItemValueTypes.InspectionBKDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerInspectionTime || null, outsideServicesRecord?.InspectionBKDurationOfEventDays__c, QuoteLineItemFields.InspectionBKDurationOfEventDays, QuoteLineItemValueTypes.InspectionBKDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerTransitTime || null, outsideServicesRecord?.InspectionBKTotalTransitTimeDays__c, QuoteLineItemFields.InspectionBKTotalTransitTimeDays, QuoteLineItemValueTypes.InspectionBKTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerEventCost || null, outsideServicesRecord?.InspectionBKCostOfEvent__c, QuoteLineItemFields.InspectionBKCostOfEvent, QuoteLineItemValueTypes.InspectionBKCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerOutboundShippingCost || null, outsideServicesRecord?.InspectionBKOutboundShippingCost__c, QuoteLineItemFields.InspectionBKOutboundShippingCost, QuoteLineItemValueTypes.InspectionBKOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerTotalOutboundShippingCost || null, outsideServicesRecord?.InspectionBKTotalOutboundShippingCost__c, QuoteLineItemFields.InspectionBKTotalOutboundShippingCost, QuoteLineItemValueTypes.InspectionBKTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerInboundShippingCost || null, outsideServicesRecord?.InspectionBKInboundShippingCost__c, QuoteLineItemFields.InspectionBKInboundShippingCost, QuoteLineItemValueTypes.InspectionBKInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerTotalInboundShippingCost || null, outsideServicesRecord?.InspectionBKTotalInboundShippingCost__c, QuoteLineItemFields.InspectionBKTotalInboundShippingCost, QuoteLineItemValueTypes.InspectionBKTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerVendor || null, outsideServicesRecord?.InspectionBKVendor__c, QuoteLineItemFields.InspectionBKVendor, QuoteLineItemValueTypes.InspectionBKVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerShouldShipToCustomer || false, outsideServicesRecord?.InspectionBKShipToCustomer__c, QuoteLineItemFields.InspectionBKShipToCustomer, QuoteLineItemValueTypes.InspectionBKShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionBackerAdditionalNotes || null, outsideServicesRecord?.InspectionBKNotes__c, QuoteLineItemFields.InspectionBKNotes, QuoteLineItemValueTypes.InspectionBKNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // InspectionCladder
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderEventDescription || null, outsideServicesRecord?.InspectionCLDescriptionOfEvent__c, QuoteLineItemFields.InspectionCLDescriptionOfEvent, QuoteLineItemValueTypes.InspectionCLDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderInspectionTime || null, outsideServicesRecord?.InspectionCLDurationOfEventDays__c, QuoteLineItemFields.InspectionCLDurationOfEventDays, QuoteLineItemValueTypes.InspectionCLDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderTransitTime || null, outsideServicesRecord?.InspectionCLTotalTransitTimeDays__c, QuoteLineItemFields.InspectionCLTotalTransitTimeDays, QuoteLineItemValueTypes.InspectionCLTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderEventCost || null, outsideServicesRecord?.InspectionCLCostOfEvent__c, QuoteLineItemFields.InspectionCLCostOfEvent, QuoteLineItemValueTypes.InspectionCLCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderOutboundShippingCost || null, outsideServicesRecord?.InspectionCLOutboundShippingCost__c, QuoteLineItemFields.InspectionCLOutboundShippingCost, QuoteLineItemValueTypes.InspectionCLOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderTotalOutboundShippingCost || null, outsideServicesRecord?.InspectionCLTotalOutboundShippingCost__c, QuoteLineItemFields.InspectionCLTotalOutboundShippingCost, QuoteLineItemValueTypes.InspectionCLTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderInboundShippingCost || null, outsideServicesRecord?.InspectionCLInboundShippingCost__c, QuoteLineItemFields.InspectionCLInboundShippingCost, QuoteLineItemValueTypes.InspectionCLInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderTotalInboundShippingCost || null, outsideServicesRecord?.InspectionCLTotalInboundShippingCost__c, QuoteLineItemFields.InspectionCLTotalInboundShippingCost, QuoteLineItemValueTypes.InspectionCLTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderVendor || null, outsideServicesRecord?.InspectionCLVendor__c, QuoteLineItemFields.InspectionCLVendor, QuoteLineItemValueTypes.InspectionCLVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderShouldShipToCustomer || false, outsideServicesRecord?.InspectionCLShipToCustomer__c, QuoteLineItemFields.InspectionCLShipToCustomer, QuoteLineItemValueTypes.InspectionCLShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladderAdditionalNotes || null, outsideServicesRecord?.InspectionCLNotes__c, QuoteLineItemFields.InspectionCLNotes, QuoteLineItemValueTypes.InspectionCLNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // InspectionCladProduct
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductEventDescription || null, outsideServicesRecord?.InspectionDescriptionOfEvent__c, QuoteLineItemFields.InspectionDescriptionOfEvent, QuoteLineItemValueTypes.InspectionDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductInspectionTime || null, outsideServicesRecord?.InspectionDurationOfEventDays__c, QuoteLineItemFields.InspectionDurationOfEventDays, QuoteLineItemValueTypes.InspectionDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductTransitTime || null, outsideServicesRecord?.InspectionTotalTransitTimeDays__c, QuoteLineItemFields.InspectionTotalTransitTimeDays, QuoteLineItemValueTypes.InspectionTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductEventCost || null, outsideServicesRecord?.InspectionCostOfEvent__c, QuoteLineItemFields.InspectionCostOfEvent, QuoteLineItemValueTypes.InspectionCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductOutboundShippingCost || null, outsideServicesRecord?.InspectionOutboundShippingCost__c, QuoteLineItemFields.InspectionOutboundShippingCost, QuoteLineItemValueTypes.InspectionOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductTotalOutboundShippingCost || null, outsideServicesRecord?.InspectionTotalOutboundShippingCost__c, QuoteLineItemFields.InspectionTotalOutboundShippingCost, QuoteLineItemValueTypes.InspectionTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductInboundShippingCost || null, outsideServicesRecord?.InspectionInboundShippingCost__c, QuoteLineItemFields.InspectionInboundShippingCost, QuoteLineItemValueTypes.InspectionInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductTotalInboundShippingCost || null, outsideServicesRecord?.InspectionTotalInboundShippingCost__c, QuoteLineItemFields.InspectionTotalInboundShippingCost, QuoteLineItemValueTypes.InspectionTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductVendor || null, outsideServicesRecord?.InspectionVendor__c, QuoteLineItemFields.InspectionVendor, QuoteLineItemValueTypes.InspectionVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductShouldShipToCustomer || false, outsideServicesRecord?.InspectionShipToCustomer__c, QuoteLineItemFields.InspectionShipToCustomer, QuoteLineItemValueTypes.InspectionShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.inspectionCladProductAdditionalNotes || null, outsideServicesRecord?.InspectionNotes__c, QuoteLineItemFields.InspectionNotes, QuoteLineItemValueTypes.InspectionNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // Packaging
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingEventDescription || null, outsideServicesRecord?.PackagingDescriptionOfEvent__c, QuoteLineItemFields.PackagingDescriptionOfEvent, QuoteLineItemValueTypes.PackagingDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingTime || null, outsideServicesRecord?.PackagingDurationOfEventDays__c, QuoteLineItemFields.PackagingDurationOfEventDays, QuoteLineItemValueTypes.PackagingDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingTransitTime || null, outsideServicesRecord?.PackagingTotalTransitTimeDays__c, QuoteLineItemFields.PackagingTotalTransitTimeDays, QuoteLineItemValueTypes.PackagingTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingEventCost || null, outsideServicesRecord?.PackagingCostOfEvent__c, QuoteLineItemFields.PackagingCostOfEvent, QuoteLineItemValueTypes.PackagingCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingOutboundShippingCost || null, outsideServicesRecord?.PackagingOutboundShippingCost__c, QuoteLineItemFields.PackagingOutboundShippingCost, QuoteLineItemValueTypes.PackagingOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingTotalOutboundShippingCost || null, outsideServicesRecord?.PackagingTotalOutboundShippingCost__c, QuoteLineItemFields.PackagingTotalOutboundShippingCost, QuoteLineItemValueTypes.PackagingTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingInboundShippingCost || null, outsideServicesRecord?.PackagingInboundShippingCost__c, QuoteLineItemFields.PackagingInboundShippingCost, QuoteLineItemValueTypes.PackagingInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingTotalInboundShippingCost || null, outsideServicesRecord?.PackagingTotalInboundShippingCost__c, QuoteLineItemFields.PackagingTotalInboundShippingCost, QuoteLineItemValueTypes.PackagingTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingVendor || null, outsideServicesRecord?.PackagingVendor__c, QuoteLineItemFields.PackagingVendor, QuoteLineItemValueTypes.PackagingVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingShouldShipToCustomer || false, outsideServicesRecord?.PackagingShipToCustomer__c, QuoteLineItemFields.PackagingShipToCustomer, QuoteLineItemValueTypes.PackagingShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.packagingAdditionalNotes || null, outsideServicesRecord?.PackagingNotes__c, QuoteLineItemFields.PackagingNotes, QuoteLineItemValueTypes.PackagingNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // PostcladCanRolling
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingEventDescription || null, outsideServicesRecord?.CanRollingDescriptionOfEvent__c, QuoteLineItemFields.CanRollingDescriptionOfEvent, QuoteLineItemValueTypes.CanRollingDescriptionOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingTime || null, outsideServicesRecord?.CanRollingDurationOfEventDays__c, QuoteLineItemFields.CanRollingDurationOfEventDays, QuoteLineItemValueTypes.CanRollingDurationOfEventDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingTransitTime || null, outsideServicesRecord?.CanRollingTotalTransitTimeDays__c, QuoteLineItemFields.CanRollingTotalTransitTimeDays, QuoteLineItemValueTypes.CanRollingTotalTransitTimeDays, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingEventCost || null, outsideServicesRecord?.CanRollingCostOfEvent__c, QuoteLineItemFields.CanRollingCostOfEvent, QuoteLineItemValueTypes.CanRollingCostOfEvent, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingOutboundShippingCost || null, outsideServicesRecord?.CanRollingOutboundShippingCost__c, QuoteLineItemFields.CanRollingOutboundShippingCost, QuoteLineItemValueTypes.CanRollingOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingTotalOutboundShippingCost || null, outsideServicesRecord?.CanRollingTotalOutboundShippingCost__c, QuoteLineItemFields.CanRollingTotalOutboundShippingCost, QuoteLineItemValueTypes.CanRollingTotalOutboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingInboundShippingCost || null, outsideServicesRecord?.CanRollingInboundShippingCost__c, QuoteLineItemFields.CanRollingInboundShippingCost, QuoteLineItemValueTypes.CanRollingInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingTotalInboundShippingCost || null, outsideServicesRecord?.CanRollingTotalInboundShippingCost__c, QuoteLineItemFields.CanRollingTotalInboundShippingCost, QuoteLineItemValueTypes.CanRollingTotalInboundShippingCost, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingVendor || null, outsideServicesRecord?.CanRollingVendor__c, QuoteLineItemFields.CanRollingVendor, QuoteLineItemValueTypes.CanRollingVendor, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingShouldShipToCustomer || false, outsideServicesRecord?.CanRollingShipToCustomer__c, QuoteLineItemFields.CanRollingShipToCustomer, QuoteLineItemValueTypes.CanRollingShipToCustomer, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapOutsideServices?.postcladCanRollingAdditionalNotes || null, outsideServicesRecord?.CanRollingNotes__c, QuoteLineItemFields.CanRollingNotes, QuoteLineItemValueTypes.CanRollingNotes, lineItemOutsideServicesFieldsMap, fieldsUpdated);
  // Post Clad Processing
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingHeadFormingHeatTreat || null, quoteLineItemDetailData?.SimulateHeatTreatmentHeadForming__c, QuoteLineItemFields.SimulateHeatTreatmentHeadForming, QuoteLineItemValueTypes.SimulateHeatTreatmentHeadForming, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingIsNCHouseStressReliefChecked || false, quoteLineItemDetailData?.StressReliefHT__c, QuoteLineItemFields.StressReliefHT, QuoteLineItemValueTypes.StressReliefHT, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingNCHouseStressReliefMin || null, quoteLineItemDetailData?.NCInhouseStressRefliefTimeMin__c, QuoteLineItemFields.NCInhouseStressRefliefTimeMin, QuoteLineItemValueTypes.NCInhouseStressReliefTimeMin, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingNCHouseStressReliefMax || null, quoteLineItemDetailData?.NCInHouseStressReliefTimeMax__c, QuoteLineItemFields.NCInHouseStressReliefTimeMax, QuoteLineItemValueTypes.NCInHouseStressReliefTimeMax, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingNCHouseStressReliefTemp || null, quoteLineItemDetailData?.NCInhouseStressReliefTemp__c, QuoteLineItemFields.NCInhouseStressReliefTemp, QuoteLineItemValueTypes.NCInhouseStressReliefTemp, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingNCHouseStressReliefDetails || null, quoteLineItemDetailData?.NCInhouseStressReliefDetails__c, QuoteLineItemFields.NCInhouseStressReliefDetails, QuoteLineItemValueTypes.NCInhouseStressReliefDetails, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingIsNCHouseStressReliefVendorChecked || false, quoteLineItemDetailData?.SimulateHeatTreatmentAtVendor__c, QuoteLineItemFields.SimulateHeatTreatmentAtVendor, QuoteLineItemValueTypes.SimulateHeatTreatmentAtVendor, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingIsPreHeatReqForCladdingChecked || false, quoteLineItemDetailData?.PreHeatRequiredforCladding__c, QuoteLineItemFields.PreHeatRequiredforCladding, QuoteLineItemValueTypes.PreHeatRequiredforCladding, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingPreHeatReqForCladdingMin || null, quoteLineItemDetailData?.PreHeatReqforcladdingMinTempforPreheat__c, QuoteLineItemFields.PreHeatReqforcladdingMinTempforPreheat, QuoteLineItemValueTypes.PreHeatReqforcladdingMinTempforPreheat, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingIsPreHeatReqForCuttingChecked || false, quoteLineItemDetailData?.PreheattoCut__c, QuoteLineItemFields.PreheattoCut, QuoteLineItemValueTypes.PreheattoCut, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingPreHeatReqForCuttingMin || null, quoteLineItemDetailData?.PreHeatReqforcuttingmintempforpreheat__c, QuoteLineItemFields.PreHeatReqforcuttingmintempforpreheat, QuoteLineItemValueTypes.PreHeatReqforcuttingmintempforpreheat, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingFlattening || null, quoteLineItemDetailData?.PostCladFlattening__c, QuoteLineItemFields.PostCladFlattening, QuoteLineItemValueTypes.PostCladFlattening, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingFlatteningNotes || null, quoteLineItemDetailData?.FlatteningNotes__c, QuoteLineItemFields.FlatteningNotes, QuoteLineItemValueTypes.FlatteningNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingCuttingNotes || null, quoteLineItemDetailData?.PostCladCuttingTolerance__c, QuoteLineItemFields.PostCladCuttingTolerance, QuoteLineItemValueTypes.PostCladCuttingTolerance, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingMachining || null, quoteLineItemDetailData?.Machining__c, QuoteLineItemFields.Machining, QuoteLineItemValueTypes.Machining, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingMachiningNotes || null, quoteLineItemDetailData?.MachiningNotes__c, QuoteLineItemFields.MachiningNotes, QuoteLineItemValueTypes.MachiningNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingSurfaceTreatment || null, quoteLineItemDetailData?.SurfaceTreatment__c, QuoteLineItemFields.SurfaceTreatment, QuoteLineItemValueTypes.SurfaceTreatment, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingSurfaceTreatmentNotes || null, quoteLineItemDetailData?.SurfaceTreatmentNotes__c, QuoteLineItemFields.SurfaceTreatmentNotes, QuoteLineItemValueTypes.SurfaceTreatmentNotes, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingInhousePackaging || null, quoteLineItemDetailData?.NCInHousePackaging__c, QuoteLineItemFields.NCInHousePackaging, QuoteLineItemValueTypes.NCInHousePackaging, lineItemDetailFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapDetail.postCladProcessingInhousePackagingNotes || null, quoteLineItemDetailData?.PackagingNotes__c, QuoteLineItemFields.PackagingNotes, QuoteLineItemValueTypes.PackagingNotes, lineItemDetailFieldsMap, fieldsUpdated);
  // QuoteLineItems: Freight Out and Post Clad Processing CutMethod
  addFieldToMapAndUpdatedList(assemblyFieldsMapLine?.freightCostPerLB || null, quoteLineItemData?.Total_Freight_LB__c, QuoteLineItemFields.TotalFreightLB, QuoteLineItemValueTypes.TotalFreightPerWeight, lineItemFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapLine?.freightCostPerKG || null, quoteLineItemData?.Total_Freight_KG__c, QuoteLineItemFields.TotalFreightKG, QuoteLineItemValueTypes.TotalFreightPerWeight, lineItemFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapLine?.postCladProcessingCutting || null, quoteLineItemData?.Cut_Method__c, QuoteLineItemFields.CutMethod, QuoteLineItemValueTypes.CutMethod, lineItemFieldsMap, fieldsUpdated);
  // Cylinder & Can Rolling
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.cylinderNotes || null, cylinderRecord?.CylinderNotes__c, QuoteLineItemFields.CylinderNotes, QuoteLineItemValueTypes.CylinderNotes, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.cylinderType || null, cylinderRecord?.CylinderType__c, QuoteLineItemFields.CylinderType, QuoteLineItemValueTypes.CylinderType, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.cylinderVendor || null, cylinderRecord?.CylinderVendor__c, QuoteLineItemFields.CylinderVendor, QuoteLineItemValueTypes.CylinderVendor, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.diameter || null, cylinderRecord?.Diameter__c, QuoteLineItemFields.Diameter, QuoteLineItemValueTypes.Diameter, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.edgePreparation || null, cylinderRecord?.EdgePreparation__c, QuoteLineItemFields.EdgePreparation, QuoteLineItemValueTypes.EdgePreparation, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.freightChargeToCustomerPer || null, cylinderRecord?.FreightChargeToCustomerPer__c, QuoteLineItemFields.FreightChargeToCustomerPer, QuoteLineItemValueTypes.FreightChargeToCustomerPer, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.freightChargeToRollerPer || null, cylinderRecord?.FreightChargeToRollerPer__c, QuoteLineItemFields.FreightChargeToRollerPer, QuoteLineItemValueTypes.FreightChargeToRollerPer, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.innerOorOutter || null, cylinderRecord?.IDorOD__c, QuoteLineItemFields.IDorOD, QuoteLineItemValueTypes.IDorOD, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.plateLengthSupplyToFormer || null, cylinderRecord?.PlateLengthSupplyToFormer__c, QuoteLineItemFields.PlateLengthSupplyToFormer, QuoteLineItemValueTypes.PlateLengthSupplyToFormer, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.plateWidthSupplyToFormer || null, cylinderRecord?.PlateWidthSupplyToFormer__c, QuoteLineItemFields.PlateWidthSupplyToFormer, QuoteLineItemValueTypes.PlateWidthSupplyToFormer, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.quantityPerCylinder || null, cylinderRecord?.QuantityPerCylinder__c, QuoteLineItemFields.QuantityPerCylinder, QuoteLineItemValueTypes.QuantityPerCylinder, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.rollingCostPerCylinder || null, cylinderRecord?.RollingCostPerCylinder__c, QuoteLineItemFields.RollingCostPerCylinder, QuoteLineItemValueTypes.RollingCostPerCylinder, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.timeToRollWeeks || null, cylinderRecord?.TimeToRollWeeks__c, QuoteLineItemFields.TimeToRollWeeks, QuoteLineItemValueTypes.TimeToRollWeeks, lineItemCylinderFieldsMap, fieldsUpdated);
  addFieldToMapAndUpdatedList(assemblyFieldsMapCylinder?.transitTimeToRollerWeeks || null, cylinderRecord?.TransitTimeToRollerWeeks__c, QuoteLineItemFields.TransitTimeToRollerWeeks, QuoteLineItemValueTypes.TransitTimeToRollerWeeks, lineItemCylinderFieldsMap, fieldsUpdated);
};

// saveQuoteLineItemsAdvSpecs - saves the quote line items and details adv spec data to the quote on UI
export const saveQuoteLineItemsAdvSpecs = (
  displayedQuoteGroup: DisplayedQuoteGroup,
  assemblyGroupLineFieldsToUpdate: {[key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate},
  assemblyGroupDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>,
  assemblyGroupHeadFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsHeadFieldsToUpdate>,
  assemblyGroupOutsideServiceItemFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  assemblyGroupCylinderDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsCylinderItemDetailFieldsToUpdate>,
  metalComboDetailFieldsToUpdate: {[key: string]: AdvSpecsMetalComboDetailFieldsToUpdate},
  metalComboOptionsRequired: {[key: string]: {[key: string]: boolean}},
  assemblyGroupOptionsRequired: Map<string, {[key: string]: boolean}>,
  quoteLineItems: QuoteLineItem[],
  saveQuoteLineItem: (
    dependentUnwrittenFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>,
    fieldsUpdated: QuoteLineItemValueTypes[],
    quoteLineItemToEdit: QuoteLineItem,
    duplicatingFlag?: boolean,
    saveMultipleFlag?: boolean,
    leftoverLineCreation?: boolean,
    reset?: boolean,
    preventDependencyRecalcs?: boolean
  ) => { lineUpdating: boolean, quoteLineItems: QuoteLineItem[] },
  specifyPerAssemblyMap: {[key: string]: {[key: string]: boolean}},
  metalComboLineFieldsToUpdate: {[key: string]: AdvSpecsMetalComboLineFieldsToUpdate},
  baseMetal: RawMaterial,
  cladMetal: RawMaterial
) => {

  // Saves the saveQuoteLineItem for each Quote Line Item in the metalComboToQlisMap that share that metal combo as values
  Array.from(displayedQuoteGroup.metalComboToQlisMap.keys()).forEach(metalCombo => {
    if(!specifyPerAssemblyMap[metalCombo].shearTest) {
      updateMetalComboPostCladTestingOptions(metalComboOptionsRequired[metalCombo].shearTestRequired, PostCladTestingOptions.Shear, metalCombo, metalComboLineFieldsToUpdate, metalComboDetailFieldsToUpdate);
    }

    if(!specifyPerAssemblyMap[metalCombo].tensileTest) {
      updateMetalComboPostCladTestingOptions(metalComboOptionsRequired[metalCombo].tensileTestRequired, PostCladTestingOptions.Tensile, metalCombo, metalComboLineFieldsToUpdate, metalComboDetailFieldsToUpdate);
    }

    if(!specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting) {
      updateMetalComboPostCladTestingOptions(metalComboOptionsRequired[metalCombo].customerSpecifiedImpactTestingRequired, PostCladTestingOptions.ImpactLCVN, metalCombo, metalComboLineFieldsToUpdate, metalComboDetailFieldsToUpdate);
      updateMetalComboPostCladTestingOptions(metalComboOptionsRequired[metalCombo].customerSpecifiedImpactTestingRequired, PostCladTestingOptions.ImpactTCVN, metalCombo, metalComboLineFieldsToUpdate, metalComboDetailFieldsToUpdate);
    }

    if(!specifyPerAssemblyMap[metalCombo].ramTensileTest) {
      updateMetalComboPostCladTestingOptions(metalComboOptionsRequired[metalCombo].ramTensileTestRequired, PostCladTestingOptions.RamTensile, metalCombo, metalComboLineFieldsToUpdate, metalComboDetailFieldsToUpdate);
    }

    if(!specifyPerAssemblyMap[metalCombo].sa26345BondStrength) {
      updateMetalComboPostCladTestingOptions(metalComboOptionsRequired[metalCombo].sa26345BondStrengthRequired, PostCladTestingOptions.SA26345BondStrength, metalCombo, metalComboLineFieldsToUpdate, metalComboDetailFieldsToUpdate);
    }

    if(!specifyPerAssemblyMap[metalCombo].b898Bend) {
      updateMetalComboPostCladTestingOptions(metalComboOptionsRequired[metalCombo].b898BendRequired, PostCladTestingOptions.B898Bend, metalCombo, metalComboLineFieldsToUpdate, metalComboDetailFieldsToUpdate);
    }

    if(!specifyPerAssemblyMap[metalCombo].adw8Bend) {
      updateMetalComboPostCladTestingOptions(metalComboOptionsRequired[metalCombo].adw8BendRequired, PostCladTestingOptions.ADW8Bend, metalCombo, metalComboLineFieldsToUpdate, metalComboDetailFieldsToUpdate);
    }

    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].customerSpecifiedImpactTestingRequired, AdvSpecOptions.CustomerSpecifiedImpactTesting, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].hardnessCladdingMetalSurfaceRequired, AdvSpecOptions.HardnessCladdingMetalSurface, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].hardnessBaseMetalSurfaceRequired, AdvSpecOptions.HardnessBaseMetalSurface, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].pmiConfirmCladdingMetalRequired, AdvSpecOptions.PMIConfirmCladdingMetal, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].pmiConfirmBaseMetalRequired, AdvSpecOptions.PMIConfirmBaseMetal, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].dyePenetrantRequired, AdvSpecOptions.DyePenetrant, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].cladCustomerChemistryRestrictionsRequired, AdvSpecOptions.CladCustomerChemistryRestrictions, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].corrosionTestingRequired, AdvSpecOptions.CorrosionTesting, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].cladMetalSpecEdAndAdRequired, AdvSpecOptions.CladMetalSpecEdAndAd, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].cladCustomerMetalSupplierRestrictionsRequired, AdvSpecOptions.CladCustomerMetalSupplierRestrictions, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].cladMetalProductAnalysisRequired, AdvSpecOptions.CladMetalProductAnalysisRequired, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].ferroxylTestOfCladdingSurfacePerformedRequired, AdvSpecOptions.FerroxylTestOfCladdingSurfacePerformed, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].ferriteNumberRequired, AdvSpecOptions.FerriteNumberRequired, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].precladBaseMetalUTRequired, AdvSpecOptions.PrecladBaseMetalUT, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].carbonPercentRequired, AdvSpecOptions.CarbonPercent, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].sulphurPercentRequired, AdvSpecOptions.SulphurPercent, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].phosphorousPercentRequired, AdvSpecOptions.PhosphorousPercent, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].carbonEquivalencyPercentRequired, AdvSpecOptions.CarbonEquivalencyPercent, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].jFactorSA387S62Required, AdvSpecOptions.JFactorSA387S62, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].xFactorSA387S62Required, AdvSpecOptions.XFactorSA387S62, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].baseCustomerChemistryRestrictionsRequired, AdvSpecOptions.BaseCustomerChemistryRestrictions, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].baseTensileTestRequired, AdvSpecOptions.BaseTensileTest, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].hotTensileTestPerSA20S7Required, AdvSpecOptions.HotTensileTestPerSA20S7, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].hicTestingRequired, AdvSpecOptions.HICTestingRequired, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].austeniticGrainSizeFinerThanRequired, AdvSpecOptions.AusteniticGrainSizeFinerThan, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].baseMetalSpecAndAdRequired, AdvSpecOptions.BaseMetalSpecEdAndAd, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].baseCustomerMetalSupplierRestrictionsRequired, AdvSpecOptions.BaseCustomerMetalSupplierRestrictions, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].baseMetalProductAnalysisRequired, AdvSpecOptions.BaseMetalProductAnalysisRequired, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].prebondImpactsRequired, AdvSpecOptions.PrebondImpacts, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].leakTestRequired, AdvSpecOptions.LeakTest, metalCombo);
    updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, metalComboOptionsRequired[metalCombo].customerSpecifiedSPWHTOfTestCouponsRequired, AdvSpecOptions.CustomerSpecifiedSPWHTOfTestCoupons, metalCombo);

    displayedQuoteGroup.metalComboToQlisMap.get(metalCombo).forEach(qli => {
      const assemblyGroup = qli.quoteLineItemDetail;
      const assemblyGroupId = assemblyGroup.Id || assemblyGroup.associatedLineItem;
      const quoteLineItemId = qli.Id || qli.displayId;

      updateAssemblyGroupPostCladTestingOptions(assemblyGroupLineFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).shearTestRequired, PostCladTestingOptions.Shear, quoteLineItemId, assemblyGroup);
      updateAssemblyGroupPostCladTestingOptions(assemblyGroupLineFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).tensileTestRequired, PostCladTestingOptions.Tensile, quoteLineItemId, assemblyGroup);
      updateAssemblyGroupPostCladTestingOptions(assemblyGroupLineFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).customerSpecifiedImpactTestingRequired, PostCladTestingOptions.ImpactLCVN, quoteLineItemId, assemblyGroup);
      updateAssemblyGroupPostCladTestingOptions(assemblyGroupLineFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).customerSpecifiedImpactTestingRequired, PostCladTestingOptions.ImpactTCVN, quoteLineItemId, assemblyGroup);
      updateAssemblyGroupPostCladTestingOptions(assemblyGroupLineFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).ramTensileTestRequired, PostCladTestingOptions.RamTensile, quoteLineItemId, assemblyGroup);
      updateAssemblyGroupPostCladTestingOptions(assemblyGroupLineFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).sa26345BondStrengthRequired, PostCladTestingOptions.SA26345BondStrength, quoteLineItemId, assemblyGroup);
      updateAssemblyGroupPostCladTestingOptions(assemblyGroupLineFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).b898BendRequired, PostCladTestingOptions.B898Bend, quoteLineItemId, assemblyGroup);
      updateAssemblyGroupPostCladTestingOptions(assemblyGroupLineFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).adw8BendRequired, PostCladTestingOptions.ADW8Bend, quoteLineItemId, assemblyGroup);

      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).customerSpecifiedImpactTestingRequired, AdvSpecOptions.CustomerSpecifiedImpactTesting, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).hardnessCladdingMetalSurfaceRequired, AdvSpecOptions.HardnessCladdingMetalSurface, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).hardnessBaseMetalSurfaceRequired, AdvSpecOptions.HardnessBaseMetalSurface, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).pmiConfirmCladdingMetalRequired, AdvSpecOptions.PMIConfirmCladdingMetal, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).pmiConfirmBaseMetalRequired, AdvSpecOptions.PMIConfirmBaseMetal, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).dyePenetrantRequired, AdvSpecOptions.DyePenetrant, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).cladCustomerChemistryRestrictionsRequired, AdvSpecOptions.CladCustomerChemistryRestrictions, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).corrosionTestingRequired, AdvSpecOptions.CorrosionTesting, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).cladMetalSpecEdAndAdRequired, AdvSpecOptions.CladMetalSpecEdAndAd, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).cladCustomerMetalSupplierRestrictionsRequired, AdvSpecOptions.CladCustomerMetalSupplierRestrictions, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).cladMetalProductAnalysisRequired, AdvSpecOptions.CladMetalProductAnalysisRequired, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).ferroxylTestOfCladdingSurfacePerformedRequired, AdvSpecOptions.FerroxylTestOfCladdingSurfacePerformed, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).ferriteNumberRequired, AdvSpecOptions.FerriteNumberRequired, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).precladBaseMetalUTRequired, AdvSpecOptions.PrecladBaseMetalUT, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).carbonPercentRequired, AdvSpecOptions.CarbonPercent, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).sulphurPercentRequired, AdvSpecOptions.SulphurPercent, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).phosphorousPercentRequired, AdvSpecOptions.PhosphorousPercent, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).carbonEquivalencyPercentRequired, AdvSpecOptions.CarbonEquivalencyPercent, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).jFactorSA387S62Required, AdvSpecOptions.JFactorSA387S62, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).xFactorSA387S62Required, AdvSpecOptions.XFactorSA387S62, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).baseCustomerChemistryRestrictionsRequired, AdvSpecOptions.BaseCustomerChemistryRestrictions, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).baseTensileTestRequired, AdvSpecOptions.BaseTensileTest, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).hotTensileTestPerSA20S7Required, AdvSpecOptions.HotTensileTestPerSA20S7, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).hicTestingRequired, AdvSpecOptions.HICTestingRequired, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).austeniticGrainSizeFinerThanRequired, AdvSpecOptions.AusteniticGrainSizeFinerThan, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).baseMetalSpecAndAdRequired, AdvSpecOptions.BaseMetalSpecEdAndAd, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).baseCustomerMetalSupplierRestrictionsRequired, AdvSpecOptions.BaseCustomerMetalSupplierRestrictions, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).baseMetalProductAnalysisRequired, AdvSpecOptions.BaseMetalProductAnalysisRequired, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).prebondImpactsRequired, AdvSpecOptions.PrebondImpacts, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).leakTestRequired, AdvSpecOptions.LeakTest, metalCombo, assemblyGroup);
      updateOtherOption(metalComboDetailFieldsToUpdate, assemblyGroupDetailFieldsToUpdate, assemblyGroupOptionsRequired.get(assemblyGroupId).customerSpecifiedSPWHTOfTestCouponsRequired, AdvSpecOptions.CustomerSpecifiedSPWHTOfTestCoupons, metalCombo, assemblyGroup);

      if(specifyPerAssemblyMap[metalCombo].shearTest) {
        const shearTestRequiredInAssembly = assemblyGroupOptionsRequired.get(assemblyGroupId).shearTestRequired;
        updateAssemblyGroupLinePostCladTestingOptions(assemblyGroupLineFieldsToUpdate, shearTestRequiredInAssembly, PostCladTestingOptions.Shear, quoteLineItemId);
      }

      if(specifyPerAssemblyMap[metalCombo].tensileTest) {
        const tensileTestRequiredInAssembly = assemblyGroupOptionsRequired.get(assemblyGroupId).tensileTestRequired;
        updateAssemblyGroupLinePostCladTestingOptions(assemblyGroupLineFieldsToUpdate, tensileTestRequiredInAssembly, PostCladTestingOptions.Tensile, quoteLineItemId);
      }

      if(specifyPerAssemblyMap[metalCombo].customerSpecifiedImpactTesting) {
        const customerSpecifiedImpactTestingRequiredInAssembly = assemblyGroupOptionsRequired.get(assemblyGroupId).customerSpecifiedImpactTestingRequired;
        updateAssemblyGroupLinePostCladTestingOptions(assemblyGroupLineFieldsToUpdate, customerSpecifiedImpactTestingRequiredInAssembly, PostCladTestingOptions.ImpactLCVN, quoteLineItemId);
        updateAssemblyGroupLinePostCladTestingOptions(assemblyGroupLineFieldsToUpdate, customerSpecifiedImpactTestingRequiredInAssembly, PostCladTestingOptions.ImpactTCVN, quoteLineItemId);
      }

      if(specifyPerAssemblyMap[metalCombo].ramTensileTest) {
        const ramTensileTestRequiredInAssembly = assemblyGroupOptionsRequired.get(assemblyGroupId).ramTensileTestRequired;
        updateAssemblyGroupLinePostCladTestingOptions(assemblyGroupLineFieldsToUpdate, ramTensileTestRequiredInAssembly, PostCladTestingOptions.RamTensile, quoteLineItemId);
      }

      if(specifyPerAssemblyMap[metalCombo].sa26345BondStrength) {
        const sa26345BondStrengthRequiredInAssembly = assemblyGroupOptionsRequired.get(assemblyGroupId).sa26345BondStrengthRequired;
        updateAssemblyGroupLinePostCladTestingOptions(assemblyGroupLineFieldsToUpdate, sa26345BondStrengthRequiredInAssembly, PostCladTestingOptions.SA26345BondStrength, quoteLineItemId);
      }

      if(specifyPerAssemblyMap[metalCombo].b898Bend) {
        const b898BendRequiredInAssembly = assemblyGroupOptionsRequired.get(assemblyGroupId).b898BendRequired;
        updateAssemblyGroupLinePostCladTestingOptions(assemblyGroupLineFieldsToUpdate, b898BendRequiredInAssembly, PostCladTestingOptions.B898Bend, quoteLineItemId);
      }

      if(specifyPerAssemblyMap[metalCombo].adw8Bend) {
        const adw8BendRequiredInAssembly = assemblyGroupOptionsRequired.get(assemblyGroupId).adw8BendRequired;
        updateAssemblyGroupLinePostCladTestingOptions(assemblyGroupLineFieldsToUpdate, adw8BendRequiredInAssembly, PostCladTestingOptions.ADW8Bend, quoteLineItemId);
      }
    });
  });

  quoteLineItems.filter(quoteLineItem => !quoteLineItem.Parent_Line__c).forEach(quoteLineItem => {
    const fieldsUpdated = [];
    const lineItemFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType> = new Map<QuoteLineItemFields, FieldsMapValueType>();
    const lineItemDetailFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType> = new Map<QuoteLineItemFields, FieldsMapValueType>();
    const lineItemHeadFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType> = new Map<QuoteLineItemFields, FieldsMapValueType>();
    const lineItemOutsideServicesFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType> = new Map<QuoteLineItemFields, FieldsMapValueType>();
    const lineItemCylinderFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType> = new Map<QuoteLineItemFields, FieldsMapValueType>();

    const metalCombo = { base: quoteLineItem[QuoteLineItemFields.BaseMetal], clad: quoteLineItem[QuoteLineItemFields.CladMetal] };
    const jsonMetalCombo = JSON.stringify(metalCombo);

    // currently addFieldsToUpdateMaps is only needed for updating on advanced spec
    addFieldsToUpdateMaps(
      assemblyGroupLineFieldsToUpdate,
      assemblyGroupDetailFieldsToUpdate,
      assemblyGroupHeadFieldsToUpdate,
      assemblyGroupOutsideServiceItemFieldsToUpdate,
      assemblyGroupCylinderDetailFieldsToUpdate,
      metalComboDetailFieldsToUpdate,
      lineItemFieldsMap,
      lineItemDetailFieldsMap,
      lineItemHeadFieldsMap,
      lineItemOutsideServicesFieldsMap,
      lineItemCylinderFieldsMap,
      fieldsUpdated,
      quoteLineItem,
      jsonMetalCombo,
      specifyPerAssemblyMap,
      metalComboLineFieldsToUpdate,
      baseMetal,
      cladMetal
    );

    const quoteLineItemToEdit = { ...quoteLineItem };

    Array.from(lineItemFieldsMap.keys()).forEach(field => {
      quoteLineItemToEdit[field] = lineItemFieldsMap.get(field);
    });

    Array.from(lineItemDetailFieldsMap.keys()).forEach(field => {
      quoteLineItemToEdit.quoteLineItemDetail[field] = lineItemDetailFieldsMap.get(field);
    });

    Array.from(lineItemHeadFieldsMap.keys()).forEach(field => {
      quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r[field] = lineItemHeadFieldsMap.get(field);
    });

    Array.from(lineItemOutsideServicesFieldsMap.keys()).forEach(field => {
      quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[field] = lineItemOutsideServicesFieldsMap.get(field);
    });

    Array.from(lineItemCylinderFieldsMap.keys()).forEach(field => {
      quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r[field] = lineItemCylinderFieldsMap.get(field);
    });

    saveQuoteLineItem(new Map(), fieldsUpdated, quoteLineItemToEdit, false, false, false, false, true);

    const childLinesToUpdate = quoteLineItems.filter(quoteLineItemToCheck => quoteLineItemToCheck.Parent_Line__c === quoteLineItem.Line__c);

    childLinesToUpdate.forEach(childLineToUpdate => {
      const quoteLineItemToEdit = { ...childLineToUpdate };

      Array.from(lineItemFieldsMap.keys()).forEach(field => {
        quoteLineItemToEdit[field] = lineItemFieldsMap.get(field);
      });

      Array.from(lineItemDetailFieldsMap.keys()).forEach(field => {
        quoteLineItemToEdit.quoteLineItemDetail[field] = lineItemDetailFieldsMap.get(field);
      });

      Array.from(lineItemHeadFieldsMap.keys()).forEach(field => {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r[field] = lineItemHeadFieldsMap.get(field);
      });

      Array.from(lineItemOutsideServicesFieldsMap.keys()).forEach(field => {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[field] = lineItemOutsideServicesFieldsMap.get(field);
      });

      Array.from(lineItemCylinderFieldsMap.keys()).forEach(field => {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r[field] = lineItemCylinderFieldsMap.get(field);
      });

      saveQuoteLineItem(new Map(), fieldsUpdated, quoteLineItemToEdit, false, false, false, false, true);
    });
  });
};

export const buildLineItemFieldsToUpdate = (
  displayedQuoteLineItems: QuoteLineItem[],

 ): { [key: string]: OrderEntryLineItemFieldsToUpdate } => {
   const lineFieldsToUpdate: { [key: string]: OrderEntryLineItemFieldsToUpdate } = {};

   displayedQuoteLineItems?.forEach((qli) => {

     lineFieldsToUpdate[qli.Id || qli.displayId] = {
       poNumber: qli.CustomerPO__c,
       productTag: qli.ProductTagging__c,
       shipVia: qli.ShipVia__c,
       shipViaDetails: qli.ShipViaDetails__c,
       shipDate: qli.ShipDate__c,
       streetAddress: qli.Shipping_Street__c,
       city: qli.Shipping_City__c,
       state: qli.Shipping_State__c,
       postalCode: qli.Shipping_Postal_Code__c,
       country: qli.Shipping_Country__c
     };
 });
   return lineFieldsToUpdate;
 };

 export const createLineNumber = (quoteLineItem) => {
  const lineNumber = Number(quoteLineItem.Line__c).toFixed(0);
  return lineNumber;
};

//Calculates direct cost for Cost Details
export const calculateDirectCost = (qliDisplayed: QuoteLineItem, osCost: number, isQuoteDE: boolean): number => {

  const outsideService = qliDisplayed.quoteLineItemDetail?.NC_Outside_Service_Item_Details__r;

  const cutMethod = isQuoteDE ? (outsideService.CuttingTotalCost__c || 0) + (outsideService.WaterJetCuttingTotalCost__c || 0) : qliDisplayed.quoteLineItemDetail.Cut_Method_Cost__c || 0;
  const specTestCost = qliDisplayed.quoteLineItemDetail.Specification_Test_Cost__c || 0;
  const consumableCost = qliDisplayed.quoteLineItemDetail?.Consumable_Cost__c || 0;
  const flatteningCost = qliDisplayed.quoteLineItemDetail?.FlatteningLaborCost__c || 0;
  const genLaborCost = qliDisplayed?.GeneralLaborCost__c || 0;
  const weldingCost = isQuoteDE ? (qliDisplayed.Welding__c || 0) + (outsideService.XRayServicesTotalCost__c || 0) : qliDisplayed.Welding__c || 0;
  const explosiveLoadCost = qliDisplayed.quoteLineItemDetail?.Explosive_Load_Cost__c || 0;
  const exportPkgCost = qliDisplayed.ExportPackagingCost__c || 0;
  const cladCost = qliDisplayed.Clad_Cost__c || 0;
  const baseCost = qliDisplayed.Base_Cost__c || 0;
  const cladFreight = qliDisplayed.Clad_Freight__c || 0;
  const baseFreight = qliDisplayed.Base_Freight__c || 0;
  const anvil = qliDisplayed.Anvil__c || 0;
  const heatTreatmentCost = isQuoteDE ? (outsideService.BackerMetalHTTotalCost__c || 0) + (outsideService.CladderMetalHTTotalCost__c || 0) + (outsideService.PostbondHTTotalCost__c || 0) : qliDisplayed.quoteLineItemDetail?.Heat_Treatment_Cost__c || 0;

  //These are the 5 fields that is being added to fully match how KQ computes Direct Cost
  const machiningCost = outsideService.MachiningTotalCost__c || 0;
  const headFormingCost = qliDisplayed.Head_Forming__c || 0;
  const headFreightCost = qliDisplayed.Head_Freight__c || 0;
  const cosmeticGrindingCost = qliDisplayed.CosmeticPrepCost__c || 0;
  const certificateTypeCost = qliDisplayed.CertificateCost__c || 0;

  return [cutMethod, specTestCost, consumableCost, flatteningCost, genLaborCost, weldingCost, explosiveLoadCost, exportPkgCost, cladCost, baseCost, cladFreight, baseFreight, anvil, heatTreatmentCost, osCost,
    machiningCost,
    headFormingCost,
    headFreightCost,
    cosmeticGrindingCost,
    certificateTypeCost
  ].sum();
};

//Calculates commission per line item
export const calculateCommissionLineItem = (qliDisplayed: QuoteLineItem, commissionRate: number): number => {

  const commission = (commissionRate * .01) * qliDisplayed.TotalAdjPrice__c;

  return commission;
};