import { useDarkMode } from '@hooks/useDarkMode';
import { IonIcon } from '@ionic/react';
import classNames from 'classnames';
import * as React from 'react';
import Select from 'react-select';
import carrotIcon from '@assets/icon/carrot_field.svg';
import carrotIconWhite from '@assets/icon/carrot_field_white.svg';

import './app-dropdown.scss';

const AppDropdown = (props) => {
  const { darkMode } = useDarkMode();

  const dropdownStyle = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? (darkMode ? '#8b8d8e' : '#ececec')
        : (darkMode ? '#1A1818' : '#ffffff'),
      color: darkMode ? '#ffffff' : '#1A1818',
      ':active': {
        ...provided[':active'],
        backgroundColor: (darkMode ? '#8b8d8e': '#ececec')
      },
      cursor: 'pointer'
    }),
    control: (provided) => ({
      ...provided,
      height: 26,
      flexWrap: 'unset',
      boxShadow: 'unset',
      borderColor: '#c9cac8 !important',
      borderRadius: '0px',
      backgroundColor: props.errorExists ? '#ffc5ba' : (props.isDisabled ? '#F2F2F2' : (darkMode ? '#1A1818' : '#ffffff')),
      cursor: 'pointer',
      marginTop: props.style?.marginTop || '0px',
      marginBottom: props.style?.marginBottom || '0px',
      width: props.style?.width,
      marginRight: props.style?.marginRight,
      marginLeft: props.style?.marginLeft
    }),
    placeholder: (provided) => ({
      ...provided,
      color: props.errorExists ? '#e84c3d' : (props.isDisabled ? '#1A1818' : (darkMode ? '#ececec' : '#8b8d8e')),
      opacity: 0.3,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0px',
      marginTop: '0px',
      '> div': {
        paddingTop: 'unset',
        paddingBottom: 'unset',
      },
      boxShadow: darkMode ?
        'rgb(256 256 256 / 10%) 0px 0px 0px 1px, rgb(256 256 256 / 10%) 0px 4px 11px' :
        'rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px',
      width: props.style?.width || '100%',
      zIndex: 99
    }),
    singleValue: (provided) => ({
      ...provided,
      borderRadius: '0px',
      color: darkMode ? '#ffffff' : '#1A1818',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: props.style?.menuListHeight,
      zIndex: 99
    }),
  };

  const DropdownIndicator = () => {
    return (
      <IonIcon class='dropdown-indicator' icon={!darkMode ? carrotIcon : carrotIconWhite}></IonIcon>
    );
  };

  const _value = props.value && typeof props.value === 'object' && Object.keys(props.value).length
    ? props.value
    : props.options && props.options.find(o => o.value === props.value || o.label === props.value);

  return (
    <Select
      className={classNames({ 'error': props.errorExists})}
      options={props.options}
      placeholder={props.placeholder}
      styles={dropdownStyle}
      isSearchable={false}
      defaultValue={props.defaultValue}
      value={_value || props.value}
      onChange={props.onChange}
      isDisabled={props.isDisabled || props.disabled  }
      theme={(theme) => ({
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 26,
        }
      })}
      components={{
        DropdownIndicator
      }}
    />
  );
};

export default AppDropdown;