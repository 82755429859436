import { CladProductionSpecifications, ManufacturingSites, PostCladTestingOptions, TensileTestLocations } from '@constants';
import _ from 'lodash-es';
import { FormKeys } from "./form-keys";

export const FormKeyMapper = {
  [FormKeys.CladProductionSpecification]: ({lineItem, key}) => {
    if (_.get(lineItem, key)) {
      return _.get(lineItem, key);
    }

    if (lineItem.Specification__c && lineItem.Specification__c !== CladProductionSpecifications.DMC100) {
      return `${lineItem.Specification__c} & DMC100`;
    }

    return lineItem.Specification__c;
  },
  [FormKeys.HotTensileTestPerSA20S7UOM]: (({ value }) => value?.replace('°', '') ),
  [FormKeys.CustomerSpecifiedImpactTestTempUOM]: (({ value }) => value?.replace('°', '') ),
  [FormKeys.PreBondImpactsTestTemperatureUOM]: (({ value }) => value?.replace('°', '') ),
  [FormKeys.CorrosionTesting]: (({ value }) => {
    return (typeof value === 'string' ? value.trim() : value) || undefined;
  }),
  [FormKeys.NCBaseChemistryRestrictionsforBonding]: (({ value }) => {
    return (typeof value === 'string' ? value.trim() : value) || undefined;
  }),
  [FormKeys.CladTensileTestLocation]: (({ lineItem, value }) => {
    const options = _.get(lineItem, FormKeys.Post_Clad_Testing_Options, undefined);
    const split = options && typeof options === 'string' ? options.split(';') : [];

    if (typeof options === 'undefined') {
      return undefined;
    }

    if (split.includes(PostCladTestingOptions.Tensile) && !value) {
      return TensileTestLocations.PerSpecification;
    }

    return value;
  }),
  // _requiredField is not necessarily part of the NC_QuoteLineItem data
  [FormKeys.RequiredShearTest]: (({ lineItem }) => {
    const options = _.get(lineItem, FormKeys.Post_Clad_Testing_Options, undefined);
    const split = options && typeof options === 'string' ? options.split(';') : [];

    if (typeof options === 'undefined') {
      return undefined;
    }

    if (split.includes(PostCladTestingOptions.Shear)) {
      return true;
    }

    return false;
  }),
  [FormKeys.RequiredTensileTest]: (({ lineItem, value }) => {
    const options = _.get(lineItem, FormKeys.Post_Clad_Testing_Options, undefined);
    const split = options && typeof options === 'string' ? options.split(';') : [];

    if (typeof options === 'undefined') {
      return undefined;
    }

    if (split.includes(PostCladTestingOptions.Tensile)) {
      return true;
    }

    return false;
  }),
  [FormKeys.RequiredBondStrTensileTest]: (({ lineItem }) => {
    const options = _.get(lineItem, FormKeys.Post_Clad_Testing_Options, undefined);
    const split = options && typeof options === 'string' ? options.split(';') : [];

    if (typeof options === 'undefined') {
      return undefined;
    }

    if (split.includes(PostCladTestingOptions.RamTensile)) {
      return true;
    }

    return false;
  }),
  [FormKeys.RequiredHardnessBaseMetalSurface]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.HardnessBaseMetalSurface, undefined);
  }),
  [FormKeys.RequiredHardnessCladdingMetalSurface]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.HardnessCladdingMetalSurface, undefined);
  }),
  [FormKeys.RequiredPMIConfirmBaseMetal]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.PMIConfirmBaseMetal, undefined);
  }),
  [FormKeys.RequiredPMIConfirmCladdingMetal]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.PMIConfirmCladdingMetal, undefined);
  }),
  [FormKeys.RequiredLeakTest]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.LeakTestGas, undefined)
      && _.get(lineItem, FormKeys.LeakTestReqt, undefined)
      && _.get(lineItem, FormKeys.LeakTestValue, undefined)
      && _.get(lineItem, FormKeys.LeakTestPressure, undefined);
  }),
  [FormKeys.RequiredCladdingCustomerChemRestriction]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.CustomerCladChemistryRestrictionsNotes, undefined);
  }),
  [FormKeys.RequiredCorrosionTesting]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.CorrosionTesting, undefined);
  }),
  [FormKeys.RequiredCladdingMetalSpecEdAd]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.CladMetalSpecEdition, undefined) || _.get(lineItem, FormKeys.CladMetalSpecAddendum, undefined);
  }),
  [FormKeys.RequiredCladdingCustomerMetalSupplierRestriction]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.CustomerCladMetalSupplierRestrictions, undefined);
  }),
  [FormKeys.RequiredCladdingMetalProdAnalysis]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.CladMetalProductAnalysisRequired, undefined);
  }),
  [FormKeys.RequiredFerroxlyTestOfCladdingSurface]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.FerroxylTestPerformed, undefined);
  }),
  [FormKeys.RequiredFerriteNumber]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.FerriteNumberRequired, undefined)
  }),
  [FormKeys.RequiredPrecladBaseMetalUT]: (({ lineItem }) => {
    const value = _.get(lineItem, FormKeys.PrecladBaseMetalUT, undefined);

    return (value && value !== 'None') || undefined;
  }),
  [FormKeys.RequiredCarbon]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.Carbon_Percent, undefined);
  }),
  [FormKeys.RequiredSulphur]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.Sulphur, undefined);
  }),
  [FormKeys.RequiredPhosphorus]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.Phosphorus_Percent, undefined);
  }),
  [FormKeys.RequiredCarbonEquivalency]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.Carbon_Equivalency_Percent, undefined);
  }),
  [FormKeys.RequiredJFactor]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.JFactorSA387S62, undefined);
  }),
  [FormKeys.RequiredXFactor]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.XFactorSA387S62, undefined);
  }),
  [FormKeys.RequiredBaseCustomerChemRestriction]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.CustomerBaseChemistryRestrictions, undefined);
  }),
  [FormKeys.RequiredCenterlineTensile]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.BaseTensileTestLocation, undefined);
  }),
  [FormKeys.RequiredHICTesting]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.HicTestNotes, undefined);
  }),
  [FormKeys.RequiredAusteniticGrainSizeFiner]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.AusteniticGrainSizeFinerThanValue, undefined);
  }),
  [FormKeys.RequiredBaseMetalSpecEdAd]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.BaseMetalSpecEdition, undefined) || _.get(lineItem, FormKeys.BaseMetalSpecAddendum, undefined);
  }),
  [FormKeys.RequiredBaseCustomerMetalSupplierRestriction]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.CustomerBaseMetalSupplierRestrictions, undefined);
  }),
  [FormKeys.RequiredBaseMetalProdAnalysis]: (({ lineItem }) => {
    return _.get(lineItem, FormKeys.BaseMetalProductAnalysisRequired, undefined);
  }),
};