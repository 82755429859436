import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import simpleCacheStorage from '@services/app/simple-cache-storage';
import { SpecificationTestOptions } from '@interfaces/specification-test-options';
import { getSpecificationTestOptions } from '@services/specification-test-options/specification-test-options-api';

const KEY = 'NC_SPECIFICATION_TEST_OPTIONS';

interface Props {
  children: ReactNode;
}

interface IContextProps {
  specificationTestOptions: SpecificationTestOptions[];
  setSpecificationTestOptions: Dispatch<SetStateAction<SpecificationTestOptions[]>>;
  getSpecificationTestOptions: () => Promise<boolean>;
}

// Context
const SpecificationTestOptionContext = createContext({} as IContextProps);

// Provider
export const SpecificationTestOptionProvider = ({ children }: Props): JSX.Element => {
  const [
    specificationTestOptions,
    setSpecificationTestOptions
  ] = useState(simpleCacheStorage.getItem(KEY) || []);

  const onUpdate = (items) => {
    simpleCacheStorage.setItem(KEY, items, 60);
    setSpecificationTestOptions(items);
  };

  // dont return the values and instead use state
  const onGet = () => {
    if (specificationTestOptions.length >= 1) {
      return Promise.resolve(true);
    }

    return getSpecificationTestOptions()
      .then((result) => {
        onUpdate(result);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  }

  // context value object
  const value: IContextProps = useMemo(() => {
    return {
      specificationTestOptions,
      setSpecificationTestOptions: onUpdate,
      getSpecificationTestOptions: onGet,
    };
  }, [specificationTestOptions, setSpecificationTestOptions, onUpdate, simpleCacheStorage, KEY]);

  return (
    <SpecificationTestOptionContext.Provider value={value}>{children}</SpecificationTestOptionContext.Provider>
  );
};

// useSpecificationTestOption hook
export const useSpecificationTestOption = (): IContextProps => {
  const context: IContextProps = useContext(SpecificationTestOptionContext);
  if (context === null || context === undefined) {
    throw new Error(
      'You probably forgot the <SpecificationTestOptionProvider> context provider'
    );
  }
  return context;
};