import * as React from 'react';

import { MeasurementSystems } from '@constants';
import { IonButton } from '@ionic/react';
import classNames from 'classnames';

import './uom-toggle.scss';
import { useDisplayUom } from '@context/useDisplayUom';

const UomButton = ({ uom, setUom, system }) => (
  <IonButton class={classNames({ 'uom-button': true, toggled: uom === system })} onClick={() => setUom(system)}>
    {system}
  </IonButton>
);

interface LocalUomDisplayToggleProps {
  uom: MeasurementSystems;
  setUom: (uom: MeasurementSystems) => void;
  vert?: boolean;
}

// LocalUomDisplayToggle is a component that displays the unit of measure of a product
// `uom` is the unit of measure of the product
// `setUom` is a function to change the unit of measure of the product
// `vert` is a boolean that determines whether the buttons are displayed vertically or horizontally
export const LocalUomDisplayToggle = ({ uom, setUom, vert = false }: LocalUomDisplayToggleProps) => {
  return (
    <>
      <div className='uom-buttons-container' style={{ display: 'flex', flexDirection: vert ? 'column' : 'row' }}>
        <UomButton uom={uom} setUom={setUom} system={MeasurementSystems.Imperial} />
        <UomButton uom={uom} setUom={setUom} system={MeasurementSystems.Metric} />
      </div>
    </>
  );
};

interface UomDisplayToggleProps {
  vert?: boolean;
  localOnly?: boolean;
}

// UomDisplayToggle is a component that allows the user to toggle between imperial and metric units of measure
// `vert` is a boolean that determines whether the buttons are displayed vertically or horizontally
const UomDisplayToggle = ({ vert = false }: UomDisplayToggleProps) => {
  const { displayUom, setDisplayUom } = useDisplayUom();

  return <LocalUomDisplayToggle uom={displayUom} setUom={setDisplayUom} vert={vert} />;
};

export default UomDisplayToggle;
