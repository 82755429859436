import { OrderAPIStatuses, OrderDisplayStatuses } from '@constants';
import { Order } from '@interfaces/order';
import moment from 'moment';
import { getBadgeText, getStatusImageFromAPIStatus } from '@shared/utils';

// Gets the proper order status to display based on the order status received from salesforce API
export const getStatusfromAPIStatus = (status: string) => {
  if (!status || status === '') {
    return OrderDisplayStatuses.Canceled;
  }

  return {
    [OrderAPIStatuses.BackOrder]: OrderDisplayStatuses.Open,
    [OrderAPIStatuses.Invoiced]: OrderDisplayStatuses.Complete,
    [OrderAPIStatuses.Canceled]: OrderDisplayStatuses.Canceled,
    [OrderAPIStatuses.Delivered]: OrderDisplayStatuses.Complete,
  }[status.toUpperCase()];
};

// Maps order properties so that the UI can consume the order
export const mapOrder = async (order: Order, lastViewed?: any, darkMode?: boolean) => {
  return {
    ...order,
    Status: getStatusfromAPIStatus(order.Status__c),
    statusImgSrc: getStatusImageFromAPIStatus(order.Status__c, darkMode),
    Last_Line_Updated_Date__c: order.Last_Line_Updated_Date__c || '',
    Order_Booking_Date__c: order.Order_Booking_Date__c ? moment(order.Order_Booking_Date__c).format('L') : '',
    Notes_Updated_Date_Time__c: order.Notes_Updated_Date_Time__c ? moment(order.Notes_Updated_Date_Time__c).format('L') : '',
    badgeText: await getBadgeText(order.Id, new Date(order.CreatedDate), new Date(order.LastModifiedDate), lastViewed)
  };
};

// Finds the order based on the order id
export const findOrderById = async (orders: Order[], id: string) => {
  const order = orders.find(order => order.Id === id);
  return  order ? await mapOrder(order) : null;
};