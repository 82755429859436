import * as React from 'react';
import { createContext, Dispatch, useContext, useReducer } from 'react';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import {
  buildAssemblyGroupDetailFieldsToUpdate,
  buildAssemblyGroupOptionsRequired,
  buildAssemblyGroupsCylinderFieldsToUpdate,
  buildAssemblyGroupsHeadFieldsToUpdate,
  buildAssemblyGroupsLineFieldsToUpdate,
  buildAssemblyGroupsOutsideServiceItemFieldsToUpdate,
  buildMetalComboDetailFieldsToUpdate,
  buildMetalComboLineFieldsToUpdate,
  buildMetalComboOptionsRequired,
  buildSpecifyPerAssemblyMap,
  DisplayedQuoteGroup,
} from '@shared/quote-group-utils';
import { AdvSpecActionPayloadTypes, AdvSpecsAssemblyGroupDetailFieldsToUpdate, AdvSpecsAssemblyGroupLineFieldsToUpdate, AdvSpecsAssemblyGroupsCylinderItemDetailFieldsToUpdate, AdvSpecsAssemblyGroupsHeadFieldsToUpdate, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate, AdvSpecsMetalComboDetailFieldsToUpdate, AdvSpecsMetalComboLineFieldsToUpdate } from '@shared/types';

interface InitContextProps {
  // hasInitialized used to determine if the context has been initialized via quoteGroup, (quoteGroup may be removed in the future and should be tombstoned 🪦)
  // another option was to check if there were any undefined properties, ie: Object.values(advSpecState).some((prop) => prop === undefined)
  hasInitialized: boolean;
  assemblyGroupCylinderDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsCylinderItemDetailFieldsToUpdate>;
  assemblyGroupDetailFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>;
  assemblyGroupHeadFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsHeadFieldsToUpdate>;
  assemblyGroupLineFieldsToUpdate: { [key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate };
  assemblyGroupOptionsRequired: Map<string, { [key: string]: boolean }>;
  assemblyGroupOutsideServiceItemFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>;
  specifyPerAssemblyMap: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
  metalComboDetailFieldsToUpdate: {
    [key: string]: AdvSpecsMetalComboDetailFieldsToUpdate;
  };
  metalComboOptionsRequired: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
  metalComboLineFieldsToUpdate: {
    [key: string]: AdvSpecsMetalComboLineFieldsToUpdate;
  };
  assemblyGroupSectionsMap: { [key: string]: ((props: {
    hideSpecifyPerAssembly?: boolean,
    quoteLineIdForRow?: string,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupLineFieldsToUpdate?:  {[key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate},
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>}) => JSX.Element)[]
  };
  selectedLineItemId: string;
}

const INITIAL_LOAD_STATE: InitContextProps = {
  hasInitialized: false,
  assemblyGroupCylinderDetailFieldsToUpdate: undefined,
  assemblyGroupDetailFieldsToUpdate: undefined,
  assemblyGroupHeadFieldsToUpdate: undefined,
  assemblyGroupLineFieldsToUpdate: undefined,
  assemblyGroupOptionsRequired: undefined,
  assemblyGroupOutsideServiceItemFieldsToUpdate: undefined,
  specifyPerAssemblyMap: undefined,
  metalComboDetailFieldsToUpdate: undefined,
  metalComboOptionsRequired: undefined,
  metalComboLineFieldsToUpdate: undefined,
  assemblyGroupSectionsMap: {},
  selectedLineItemId: undefined
};

interface InitStateContextProps {
  advSpecState: InitContextProps;
}

interface InitDispatchContextProps {
  dispatchAdvSpecState: Dispatch<Action>;
}

interface ProviderProps {
  children: React.ReactNode;
}

const AdvSpecContext = createContext<InitStateContextProps>({
  advSpecState: null,
});

const AdvSpecDispatchContext = createContext<InitDispatchContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatchAdvSpecState() {},
});

export enum AdvSpecActionTypes {
  initStateByQuoteGroup = 'initStateByQuoteGroup',
  setAssemblyGroupCylinderDetailFieldsToUpdate = 'assemblyGroupCylinderDetailFieldsToUpdate',
  setAssemblyGroupDetailFieldsToUpdate = 'assemblyGroupDetailFieldsToUpdate',
  setAssemblyGroupHeadFieldsToUpdate = 'assemblyGroupHeadFieldsToUpdate',
  setAssemblyGroupLineFieldsToUpdate = 'assemblyGroupLineFieldsToUpdate',
  setAssemblyGroupOptionsRequired = 'assemblyGroupOptionsRequired',
  setAssemblyGroupOutsideServiceItemFieldsToUpdate = 'assemblyGroupOutsideServiceItemFieldsToUpdate',
  setAssemblyGroupSectionsMap = 'assemblyGroupSectionsMap',
  setHasInitialized = 'setHasInitialized',
  setSelectedLineItemId = 'setSelectedLineItemId',
  reset = 'reset',
  setItem = 'setItem',
}

export type Action =
  | { type: AdvSpecActionTypes.initStateByQuoteGroup; payload: DisplayedQuoteGroup }
  | { type: AdvSpecActionTypes.setAssemblyGroupCylinderDetailFieldsToUpdate; payload: Map<string, AdvSpecsAssemblyGroupsCylinderItemDetailFieldsToUpdate> }
  | { type: AdvSpecActionTypes.setAssemblyGroupDetailFieldsToUpdate; payload: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate> }
  | { type: AdvSpecActionTypes.setAssemblyGroupHeadFieldsToUpdate; payload: Map<string, AdvSpecsAssemblyGroupsHeadFieldsToUpdate> }
  | { type: AdvSpecActionTypes.setAssemblyGroupLineFieldsToUpdate; payload: { [key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate } }
  | {
      type: AdvSpecActionTypes.setAssemblyGroupOptionsRequired;
      payload: Map<string, { [key: string]: boolean }>;
    }
  | {
      type: AdvSpecActionTypes.setAssemblyGroupOutsideServiceItemFieldsToUpdate;
      payload: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>;
    }
  | { type: AdvSpecActionTypes.setAssemblyGroupSectionsMap; payload: { [key: string]: ((props: {
        hideSpecifyPerAssembly?: boolean,
        quoteLineIdForRow?: string,
        quoteDetailForRow?: QuoteLineItemDetail,
        assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
        assemblyGroupLineFieldsToUpdate?:  {[key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate},
        assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>}) => JSX.Element)[]
      }
    }
  | { type: AdvSpecActionTypes.setHasInitialized; payload: boolean }
  | { type: AdvSpecActionTypes.setSelectedLineItemId; payload: string }
  | { type: AdvSpecActionTypes.reset; }
  | { type: AdvSpecActionTypes.setItem; payload: { field: string; value: AdvSpecActionPayloadTypes } } // generic way to set a field with any value
  | Record<string, never>; // fallback for any other action

export const AdvSpecStateProvider = ({ children }: ProviderProps) => {
  const advSpecReducer = (state = INITIAL_LOAD_STATE, action: Action) => {
    switch (action.type) {
      case AdvSpecActionTypes.setAssemblyGroupCylinderDetailFieldsToUpdate:
        return { ...state, assemblyGroupCylinderDetailFieldsToUpdate: action.payload };
      case AdvSpecActionTypes.setAssemblyGroupDetailFieldsToUpdate:
        return { ...state, assemblyGroupDetailFieldsToUpdate: action.payload };
      case AdvSpecActionTypes.setAssemblyGroupHeadFieldsToUpdate:
        return { ...state, assemblyGroupHeadFieldsToUpdate: action.payload };
      case AdvSpecActionTypes.setAssemblyGroupLineFieldsToUpdate:
        return { ...state, assemblyGroupLineFieldsToUpdate: action.payload };
      case AdvSpecActionTypes.setAssemblyGroupOptionsRequired:
        return { ...state, assemblyGroupOptionsRequired: action.payload };
      case AdvSpecActionTypes.setAssemblyGroupOutsideServiceItemFieldsToUpdate:
        return { ...state, assemblyGroupOutsideServiceItemFieldsToUpdate: action.payload };
      case AdvSpecActionTypes.initStateByQuoteGroup:
        return { ...state, hasInitialized: true, ...initBuildMaps(action.payload) };
      case AdvSpecActionTypes.setAssemblyGroupSectionsMap:
        return { ...state, assemblyGroupSectionsMap: action.payload };
      case AdvSpecActionTypes.setHasInitialized:
        return { ...state, hasInitialized: action.payload };
      case AdvSpecActionTypes.setSelectedLineItemId:
        return { ...state, selectedLineItemId: action.payload };
      case AdvSpecActionTypes.reset:
        return { ...INITIAL_LOAD_STATE };
      case AdvSpecActionTypes.setItem: // top level field setter, not typed
        return { ...state, [action.payload.field]: action.payload.value };
      /** Return state as a fallback */
      default:
        return state;
    }
  };

  const [advSpecState, dispatchAdvSpecState] = useReducer(advSpecReducer, INITIAL_LOAD_STATE);

  return (
    <AdvSpecDispatchContext.Provider value={{ dispatchAdvSpecState: dispatchAdvSpecState }}>
      <AdvSpecContext.Provider value={{ advSpecState: advSpecState }}>{children}</AdvSpecContext.Provider>
    </AdvSpecDispatchContext.Provider>
  );
};

// export state hook
export const useAdvSpecState = () => {
  const context: InitStateContextProps = useContext(AdvSpecContext);
  if (!context) {
    throw new Error('You probably forgot the <AdvSpecStateProvider> context provider');
  }
  return context;
};

// export dispatch hook
export const useAdvSpecStateDispatch = () => {
  const dispatch: InitDispatchContextProps = useContext(AdvSpecDispatchContext);
  if (!dispatch) {
    throw new Error('You probably forgot the <AdvSpecStateProvider> context provider');
  }
  return dispatch;
};

/**  Usage examples:
const { advSpecState } = useAdvSpecState();
const { dispatchAdvSpecState } = useAdvSpecStateDispatch();
dispatchAdvSpecState({ type: AdvSpecActionTypes.initStateByQuoteGroup, payload: displayedQuoteGroup });
*/

// initBuildMaps initializes the advSpecState with the data from the quoteGroup, as the build functions use the quoteGroup
const initBuildMaps = (quoteGroup: DisplayedQuoteGroup) => {
  const assemblyGroupCylinderDetailFieldsToUpdate = buildAssemblyGroupsCylinderFieldsToUpdate(
    quoteGroup.quoteLineItemDetails
  );
  const assemblyGroupDetailFieldsToUpdate = buildAssemblyGroupDetailFieldsToUpdate(quoteGroup.quoteLineItemDetails);
  const assemblyGroupHeadFieldsToUpdate = buildAssemblyGroupsHeadFieldsToUpdate(quoteGroup.quoteLineItemDetails);
  const assemblyGroupOptionsRequired = buildAssemblyGroupOptionsRequired(quoteGroup, assemblyGroupDetailFieldsToUpdate);
  const assemblyGroupLineFieldsToUpdate = buildAssemblyGroupsLineFieldsToUpdate(
    quoteGroup,
    assemblyGroupOptionsRequired
  );
  const assemblyGroupOutsideServiceItemFieldsToUpdate = buildAssemblyGroupsOutsideServiceItemFieldsToUpdate(
    quoteGroup.quoteLineItemDetails
  );
  const specifyPerAssemblyMap = buildSpecifyPerAssemblyMap(quoteGroup);
  const metalComboDetailFieldsToUpdate = buildMetalComboDetailFieldsToUpdate(quoteGroup, specifyPerAssemblyMap);
  const metalComboOptionsRequired = buildMetalComboOptionsRequired(quoteGroup, metalComboDetailFieldsToUpdate);
  const metalComboLineFieldsToUpdate = buildMetalComboLineFieldsToUpdate(quoteGroup, metalComboOptionsRequired);

  return {
    assemblyGroupCylinderDetailFieldsToUpdate: assemblyGroupCylinderDetailFieldsToUpdate,
    assemblyGroupDetailFieldsToUpdate: assemblyGroupDetailFieldsToUpdate,
    assemblyGroupHeadFieldsToUpdate: assemblyGroupHeadFieldsToUpdate,
    assemblyGroupLineFieldsToUpdate: assemblyGroupLineFieldsToUpdate,
    assemblyGroupOptionsRequired: assemblyGroupOptionsRequired,
    assemblyGroupOutsideServiceItemFieldsToUpdate: assemblyGroupOutsideServiceItemFieldsToUpdate,
    specifyPerAssemblyMap: specifyPerAssemblyMap,
    metalComboDetailFieldsToUpdate: metalComboDetailFieldsToUpdate,
    metalComboOptionsRequired: metalComboOptionsRequired,
    metalComboLineFieldsToUpdate: metalComboLineFieldsToUpdate,
  };
};