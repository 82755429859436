import { IonRow, IonCol, IonItem, IonCheckbox, IonLabel, IonList } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useComboGroup, ComboGroupActionTypes } from '@context/combo-group-context';

import './line-item.scss';

interface Props {
  label: string | React.ReactNode | JSX.Element;
  children?: React.ReactNode;
  specifyKey?: string;
  onChange?: (value: boolean) => void
}

interface LineItemLabelProps {
  label: string | React.ReactNode;
}

const LineItemLabel = ({ label }: LineItemLabelProps) => {
  if (typeof label === 'string') {
    return <span className='line-item-label--text'>
      { label }
    </span>
  }

  return <div className='line-item-label--container'>{label}</div>;
};

const LineItem = ({ label, children, specifyKey, onChange }: Props) => {
  const {
    state: {
      specifyPerAssembly,
      enableSpecifyPerAssembly,
      selectedKey,
      onlyShowSpecifyPerAssemblyField,
    },
    dispatch: comboGroupDispatch
  } = useComboGroup();

  const comboGroupSpecify = specifyPerAssembly[selectedKey] || {};
  const isEnabled = enableSpecifyPerAssembly[selectedKey] || false;
  const value = comboGroupSpecify[specifyKey] || false;

  const onToggleCheckbox = (e): void => {
    const value = e.detail.checked;

    if (typeof value === 'undefined') {
      return;
    }

    if (onChange) {
      onChange(value)
    }

    comboGroupDispatch({
      type: ComboGroupActionTypes.updateSpecifyPerAssembly,
      payload: {
        [specifyKey]: value
      }
    });
  }

  if (onlyShowSpecifyPerAssemblyField) {
    return <>
      {
        value && specifyKey && isEnabled && <IonRow className='line-item'>
          <IonCol sizeSm="12" sizeMd="2"><LineItemLabel label={label}/></IonCol>
          <IonCol sizeSm='12' sizeMd='8'>
            {children}
          </IonCol>
        </IonRow>
      }
    </>
  }

  return <>
    <IonRow className={classNames({ 'line-item': true, 'line-item--active': value})}>
      <IonCol sizeSm="12" sizeMd="2"><LineItemLabel label={label}/></IonCol>
      <IonCol sizeSm='12' sizeMd='8'>
        {children}
      </IonCol>
      {
        !onlyShowSpecifyPerAssemblyField && isEnabled && specifyKey && 
          <IonCol sizeSm='12' sizeMd='2' className="line-item--specify-per-assembly">
            <IonList>
              <IonItem lines='none'>
                <IonCheckbox
                  class='checkbox'
                  slot='start'
                  color='primary'
                  checked={value}
                  onIonChange={onToggleCheckbox}
                />
                <IonLabel> Specify Per Assembly </IonLabel>
              </IonItem>
            </IonList>
          </IonCol>
      }
    </IonRow>
  </>
}

export default LineItem;