import Footer from '@components/footer/footer';
import LineItemActions from '@components/line-item-actions/line-item-actions';
import Loading from '@components/loading/loading';
import { IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRow } from '@ionic/react';
import { useSalesforce } from '@services/salesforce/salesforce-service';
import { formatCurrency } from '@shared/utils';
import classNames from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router';
import './invoice-detail.scss';
import { InvoiceActions, InvoiceDisplayStatuses, NCLineItemsColumnHeadings, UserType } from '@constants';
import { chevronDown, chevronUp, attachOutline } from 'ionicons/icons';
import { usePlatform } from '@services/platform-service';
import { useLastViewed } from '@services/last-viewed/last-viewed-service';
import { useParams } from 'react-router-dom';
import { mapInvoice } from '@shared/invoice-utils';
import { getColumnIdFromName, mapNCLineItem } from '@shared/nc-line-item-utils';
import { useEnvironment } from '@services/environment-service';
import moment from 'moment';
import { useDarkMode } from '@hooks/useDarkMode';
import AddDocumentsPopover from '@components/popover/add-documents-popover/add-documents-popover';
import Documents from '@components/documents/documents';
import { mapDocumentData, uniqueDocuments } from '@shared/document-utils';
import InvoiceLineItemDetailsPopover from '@components/popover/invoice-line-item-details-popover/invoice-line-item-details-popover';
import InvoiceLineItemDocUploadPopover from '@components/popover/invoice-line-item-doc-upload-popover/invoice-line-item-doc-upload-popover';
import { MappedDocumentData, MappedNCLineItem } from '@shared/types';

const InvoiceDetailPage = (props) => {
  const salesforce = useSalesforce();
  const platform = usePlatform();
  const lastViewed = useLastViewed();
  const environment = useEnvironment();
  const { darkMode } = useDarkMode();
  const history = useHistory();
  const { id } = useParams<{id: string}>();

  const [displayedInvoiceLineItems, setDisplayedInvoiceLineItems] = React.useState<MappedNCLineItem[]>(undefined);
  const [invoice, setInvoice] = React.useState(undefined);
  const [loading, setLoading] = React.useState(undefined);
  const [totalDue, setTotalDue] = React.useState(undefined);
  const [invoiceAmount, setInvoiceAmount] = React.useState(undefined);
  const [headings] = React.useState(NCLineItemsColumnHeadings);
  const [sortColumnHeading, setSortColumnHeading] = React.useState(undefined);
  const [sortColumnId, setSortColumnId] = React.useState(undefined);
  const [sortAsc, setSortAsc] = React.useState(true);
  const [displayedDocuments, setDisplayedDocuments] = React.useState<MappedDocumentData[]>(undefined);
  const [mappedDocuments, setMappedDocuments] = React.useState<MappedDocumentData[]>(undefined);
  const [documentUpdated, setDocumentUpdated] = React.useState(false);
  const [showDocumentActions, setShowDocumentActions] = React.useState<boolean>(false);
  const [accountName, setAccountName] = React.useState(undefined);

  React.useEffect(() => {
    loadInvoice();
  }, []);

  React.useEffect(() => {
    if (invoice) {
      lastViewed.updateItemLastViewed({ id: invoice.Id, lastViewed: new Date().toLocaleString() });
      loadData();
      const { Order_Payment_Terms__c: terms, Invoice_Status__c: paidStatus } = invoice;
      // restrict document access if the payment terms are LOC or CAD, and the invoice status is not equal to 'Paid'
      // also dont restrict if user is sales or internally viewed
      const hasRestrictedAccess =
        terms === ('LOC' || 'CAD') && paidStatus !== 'Paid' && !(userType() === UserType.Sales || internalView());
      setShowDocumentActions(!hasRestrictedAccess);
    }
  }, [invoice]);

  React.useEffect(() => {
    sortInvoiceLineItems();
  }, [sortColumnId, sortAsc]);

  React.useEffect(() => {
    if(documentUpdated) {
      loadDocuments();
      setDocumentUpdated(false);
    }
  }, [documentUpdated]);

  React.useEffect(() => {
    if(mappedDocuments) {
      setDisplayedDocuments(uniqueDocuments(mappedDocuments).slice(0, 6));
    }
  }, [mappedDocuments]);

  const [invoiceDetailPopover, setInvoiceDetailModalPopover] = React.useState({
    payload: {}, show: false,
  });

  const onDismissInvoiceDetailPopover = () => {
    setInvoiceDetailModalPopover({
      payload: {},
      show: false,
    });
  };

  const onClickInvoiceDetailPopover = (payload) => {
    const { invoice, invoiceLineItem } = payload || {};
    setInvoiceDetailModalPopover({
      payload: {
          invoice, invoiceLineItem
      },
      show: true,
    });
  };

  // Loads the invoice from invoice state or from the invoice id in the route parameters
  const loadInvoice = async () => {
    const invoiceState = props?.history?.location?.state?.invoice;

    if(invoiceState) {
      setInvoice(invoiceState);
    } else {
      const invoice = await mapInvoice(await salesforce.getAccountInvoiceById(id), undefined, darkMode);
      setInvoice(invoice);
    }
  };

  const userType = () => {
    return environment?.userType();
  };

  const internalView = () => {
    return environment?.internalView();
  };

  // Loads the invoice line item data
  const loadData = async () => {
    try {
      await loadInvoiceLineItems();
      await loadDocuments();
      const account = await salesforce.getAccountById(invoice.Invoice_Account__c);

      setLoading(false);
      setTotalDue(formatCurrency(invoice.Invoice_Amount_Open__c || 0, invoice.CurrencyIsoCode));
      setInvoiceAmount(formatCurrency(invoice.Invoice_Amount__c || 0, invoice.CurrencyIsoCode));
      setAccountName(account.Name);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Loads the invoice line items
  const loadInvoiceLineItems = async () => {
    const invoiceLineItems = await salesforce.getAccountInvoiceLineItems(invoice.Id);
    const mappedInvoiceLineItems = await Promise.all(
      invoiceLineItems.map(async invoiceLineItem => await mapNCLineItem(invoiceLineItem, lastViewed, darkMode))
    );

    setDisplayedInvoiceLineItems(mappedInvoiceLineItems);

    const databaseColumnId = getColumnIdFromName(NCLineItemsColumnHeadings.Line);
    setSortColumnId(databaseColumnId);
  };

  // Loads the documents
  const loadDocuments = async () => {
    const documentData = await salesforce.getAccountInvoiceDocuments(invoice.Id);

    const mappedDocuments: MappedDocumentData[] = await Promise.all(
      documentData.map(async documentData => await mapDocumentData(documentData, lastViewed))
    );

    setMappedDocuments(mappedDocuments.orderByDate(document => document.CreatedDate ? moment(document.CreatedDate).format('MM/DD/YYYY') : document.CreatedDate, false));
  };

  // Handler for when an invoice line item column is selected
  const selectColumnHandler = (column: NCLineItemsColumnHeadings) => {
    setSortColumnHeading(column);

    const databaseColumnId = getColumnIdFromName(column as NCLineItemsColumnHeadings);

    if(sortColumnId === databaseColumnId) {
      setSortAsc(!sortAsc);
    } else {
      setSortColumnId(databaseColumnId);
    }
  };

  // Returns proper column header class based on whether the column is sorted or not
  const getInvoiceLineItemsColHeaderClass = (column: NCLineItemsColumnHeadings) => {
    return (column === sortColumnHeading) ? 'invoice-line-items-text text-bold' : 'invoice-line-items-text';
  };


  // Returns proper column header icon based on whether the column is sorted or not
  const getInvoiceLineItemsColHeaderIcon = (column: NCLineItemsColumnHeadings) => {
    return (column === sortColumnHeading && sortAsc) ? chevronUp : chevronDown;
  };

  // Navigates back to the invoices page
  const navigateToInvoicesPage = () => {
    history.push('/invoices');
  };

  // Sorts the invoice line items according to the sort column
  const sortInvoiceLineItems = async () => {
    let updatedItems;

    if(displayedInvoiceLineItems) {
      updatedItems = [...displayedInvoiceLineItems];
    }

    switch(sortColumnId) {
    case getColumnIdFromName(NCLineItemsColumnHeadings.Status):
      updatedItems = updatedItems?.orderBy(item => +item.statusSortingOrder, sortAsc);
      break;
    case getColumnIdFromName(NCLineItemsColumnHeadings.Line):
    case getColumnIdFromName(NCLineItemsColumnHeadings.Quantity):
      updatedItems = updatedItems?.orderBy(item => +item[sortColumnId], sortAsc);
      break;
    default:
      updatedItems = updatedItems?.orderBy(item => item[sortColumnId], sortAsc);
      break;
    }

    setDisplayedInvoiceLineItems(updatedItems);
  };

  // Navigates to the order detail page for the selected po number
  const navigateToOrderDetail = (orderId: string) => {
    history.push(`/orders/order-detail/${orderId}`);
  };

  const InvoiceLineItem = (props) => {
    const [invoiceLineItemDocUploadPayload, setInvoiceLineItemDocUploadPayload] = React.useState({
      payload: {}, show: false
    });

    const onClickAddDocumentInLineItemAction = () => {
      const { invoice, invoiceLineItem, displayedInvoiceLineItems } = props || {};
      setInvoiceLineItemDocUploadPayload({
        payload: {
          invoice, invoiceLineItem, mappedInvoiceLineItems: displayedInvoiceLineItems
        },
        show: true
      });
    };

    const onDismissDocUploadPopover = ({ documentAdded }) => {
      setInvoiceLineItemDocUploadPayload({ show: false, payload: {}});

      if (documentAdded) {
        loadDocuments();
      }
    };

    const LineItemAttachmentIndicator = () => {
      return props.mappedDocuments.some(document => document.Name.contains(props.invoiceLineItem.item))
        && <IonIcon data-testid="QAOrderLineItemDetailsPopoverAttachmentIndicator" class='paperclip-icon' icon={attachOutline}></IonIcon>;
    };

    return (
      <>
        <InvoiceLineItemDocUploadPopover
          payload={invoiceLineItemDocUploadPayload.payload}
          isOpen={invoiceLineItemDocUploadPayload.show}
          onDismiss={onDismissDocUploadPopover}
        />

        <IonCard
          className="invoice-line-item-card"
          data-testid={'QAInvoiceDetailPageInvoiceLineItem_' + props.invoiceLineItemIndex}
          onClick={(e: any) => {
            e.persist();
            onClickInvoiceDetailPopover(props);
          }}
        >
          <IonCardHeader class={classNames({ 'invoice-line-item-card-header': true, 'even': props.invoiceLineItemIndex % 2 === 0, 'odd': props.invoiceLineItemIndex % 2 !== 0 })}>
            <IonGrid>
              <IonRow class="invoice-line-item-row">
                <IonCol class="column-left" size={ !platform.isMobile() ? (!platform.isTablet() ? '0.5' : '1') : '11' }>
                  <span className="invoice-line-items-text mobile-only line-label">{ headings.Line }</span>
                  <span className="invoice-line-item-summary-text" data-testid="QAInvoiceDetailPageLineItemLine">{ props.invoiceLineItem.Line_Number__c }</span>
                </IonCol>
                <IonCol class="column-left" size={ !platform.isMobile() ? '2' : '11' }>
                  <span className="invoice-line-items-text mobile-only status-label">{ headings.Status }</span>
                  <img className="status-icon" src={ props.invoiceLineItem.statusImgSrc }/>
                  <span className="invoice-line-item-summary-text" data-testid="QAInvoiceDetailPageLineItemStatus">{ props.invoiceLineItem.Status__c }</span>
                </IonCol>
                <IonCol class="column-left" size={ !platform.isMobile() ? (!platform.isTablet() ? '4.5' : '4') : '11' }>
                  <span className="invoice-line-items-text mobile-only description-label">{ headings.Description }</span>
                  <span className="invoice-line-item-summary-text" data-testid="QAInvoiceDetailPageLineItemDescription">{ props.invoiceLineItem.Description__c }</span>
                </IonCol>
                <IonCol class="column-left" size={ !platform.isMobile() ? '1.5' : '11' }>
                  <span className="invoice-line-items-text mobile-only quantity-label">{ headings.Quantity }</span>
                  <span className="invoice-line-item-summary-text" data-testid="QAInvoiceDetailPageLineItemQuantity">{ props.invoiceLineItem.Portal_Quantity__c }</span>
                </IonCol>
                <IonCol class="column-left" size={ !platform.isMobile() ? '1.5' : '11' }>
                  <span className="invoice-line-items-text mobile-only unit-price-label">{ headings.UnitPrice }</span>
                  <span className="invoice-line-item-summary-text" data-testid="QAInvoiceDetailPageLineItemUnitPrice">{ formatCurrency(props.invoiceLineItem.Unit_Price__c || 0, props.invoiceLineItem.CurrencyIsoCode) }</span>
                </IonCol>
                <IonCol class="column-left" size={ !platform.isMobile() ? '2' : '11' }>
                  <span className="invoice-line-items-text mobile-only total-label">{ headings.Total }</span>
                  <span className="invoice-line-item-summary-text total-price" data-testid="QAInvoiceDetailPageLineItemTotal">{  formatCurrency(props.invoiceLineItem.Unit_Price_Quantity__c || 0, props.invoiceLineItem.CurrencyIsoCode) }</span>
                </IonCol>
                <LineItemAttachmentIndicator/>
                <LineItemActions
                  visible={true}
                  actions={[{
                    label: InvoiceActions.AddDocuments,
                    actionToPerform: onClickAddDocumentInLineItemAction,
                  }]}
                >
                </LineItemActions>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const InvoiceTitleBreak = () => {
    return (invoice?.Name?.length > 10 && platform.isMobile() ?
      <br/> : null
    );
  };

  const InvoiceDetailTitleCard = () => {
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });
    const [documentsAdded, setDocumentsAdded] = React.useState(false);

    const setShowPopoverState = () => {
      setShowPopover({ showPopover: true, event: undefined });
    };

    const linesMap = new Map<string, string[]>();
    displayedInvoiceLineItems?.forEach(invoiceLineItem => {
      linesMap.set(invoiceLineItem.Line_Number__c,
        displayedInvoiceLineItems?.filter(mappedInvoiceLineItem => mappedInvoiceLineItem.Line_Number__c === invoiceLineItem.Line_Number__c)
          .orderBy(lineItemDetail => lineItemDetail.Line_Item_Detail_Number__c, true)
          .map(lineItemDetail => lineItemDetail.item));
    });

    return (
      <>
        {/* invoice level documnet upload */}
        <IonPopover
          cssClass='add-documents-popover'
          isOpen={popoverState.showPopover}
          animated={ false }
          onDidDismiss={async () => {
            setShowPopover({ showPopover: false, event: undefined });

            if(documentsAdded) {
              loadDocuments();
            }
          }}
        >
          <AddDocumentsPopover
            setShowPopover={setShowPopover}
            setDocumentsAdded={setDocumentsAdded}
            salesOrderNumber={invoice?.NC_Order__r?.AX_Order_Number__c}
            poNumber={invoice?.PO_Number__c}
            invoiceNumber={invoice?.Name}
            linesMap={linesMap}
          />
        </IonPopover>
        <IonCard class='invoice-detail-title-card'>
          <IonCardHeader class='invoice-detail-title-card-header'>
            <IonGrid>
              <IonRow>
                <IonLabel class='invoices-title'><span className="invoices" onClick={() => navigateToInvoicesPage()} data-testid="QAInvoiceDetailPageTitle">Invoices</span><IonLabel class='invoice-number-title' data-testid="QAInvoiceDetailPageInvoiceNumberTitle"> / <InvoiceTitleBreak/>{invoice?.Name}</IonLabel></IonLabel>
                <LineItemActions
                  visible={true}
                  actions={(userType() === UserType.Sales || internalView()) && !platform.isMobile() && !platform.isTablet() ? [{
                    label: InvoiceActions.AddDocuments,
                    actionToPerform: setShowPopoverState
                  }] : []}
                >
                </LineItemActions>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const AccountLabel = () => {
    return (internalView() ?
      <IonItem class="ion-no-padding label-container">
        <IonLabel class="invoice-info-label">Account Name:</IonLabel>
      </IonItem>
      : null
    );
  };

  const AccountValue = () => {
    return (internalView() ?
      <IonItem class="ion-no-padding value-container">
        <IonLabel class="invoice-info-value" data-testid="QAInvoiceDetailPageAccount">{accountName || '--'}</IonLabel>
      </IonItem>
      : null
    );
  };

  return ( displayedInvoiceLineItems  && displayedDocuments ?
    <IonContent class="invoice-detail-page">
      <div className={classNames({
        'max-width-container': true,
        'invoice-detail-wrapper': true,
        'loading': loading
      })}
      >
        <InvoiceDetailTitleCard/>
        <hr/>
        <div className='invoice-details-container'>
          <div className='left-side'>
            <div className={classNames({
              'invoice-status-container': true,
              'past-due': invoice?.Portal_Status__c === InvoiceDisplayStatuses.PastDue,
              'due': invoice?.Portal_Status__c === InvoiceDisplayStatuses.Due,
              'paid': invoice?.Portal_Status__c === InvoiceDisplayStatuses.Paid,
            })}
            >
              <IonLabel class='invoice-status-title'>Invoice Status</IonLabel>
              <IonLabel class='invoice-status' data-testid="QAInvoiceDetailPageInvoiceStatus">{invoice?.Portal_Status__c}</IonLabel>
            </div>
          </div>
          <div className='invoice-info-container'>
            <IonList lines="none" class="ion-no-padding invoice-info-label-container">
              <IonItem class="ion-no-padding label-container total-due">
                <IonLabel class="invoice-info-label total-due">Total Due</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding label-container">
                <IonLabel class="invoice-info-label">Due Date:</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding label-container">
                <IonLabel class="invoice-info-label">Invoice Amount:</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding label-container">
                <IonLabel class="invoice-info-label">Invoice Date:</IonLabel>
              </IonItem>
              <AccountLabel/>
              <IonItem class="ion-no-padding label-container">
                <IonLabel class="invoice-info-label">PO Number:</IonLabel>
              </IonItem>
            </IonList>
            <IonList lines="none" class="ion-no-padding invoice-info-value-container">
              <IonItem class="ion-no-padding value-container total-due">
                <IonLabel class="invoice-info-value total-due" data-testid="QAInvoiceDetailPageTotalDue">{totalDue || '--'}</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding value-container">
                <IonLabel class="invoice-info-value" data-testid="QAInvoiceDetailPageDueDate">{invoice?.Invoice_Due_Date__c ? moment(invoice?.Invoice_Due_Date__c).format('L') : '--'}</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding value-container">
                <IonLabel class="invoice-info-value" data-testid="QAInvoiceDetailPageInvoiceAmount">{invoiceAmount || '--'}</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding value-container">
                <IonLabel class="invoice-info-value" data-testid="QAInvoiceDetailPageInvoiceDate">{invoice?.Invoice_Date__c || '--'}</IonLabel>
              </IonItem>
              <AccountValue/>
              <IonItem class="ion-no-padding value-container">
                <IonLabel class={classNames({ 'invoice-info-value': true, 'link': invoice?.PO_Number__c })} data-testid="QAInvoiceDetailPagePONumber" onClick={() => invoice?.PO_Number__c ? navigateToOrderDetail(invoice?.NC_Order__c) : undefined }>{invoice?.PO_Number__c || '--'}</IonLabel>
              </IonItem>
            </IonList>
          </div>
        </div>
        <div className="line-items-container">
          <IonLabel class="line-items-label">Line Items</IonLabel>
          <hr/>
          <IonCard class="invoice-line-items-card">
            <IonCardHeader class="invoice-line-items-card-header">
              <IonGrid>
                <IonRow class="invoice-line-items-row">
                  <IonCol class="invoice-line-items-col"  size={(!platform.isTablet() ? '0.5' : '1')} onClick={() => selectColumnHandler(headings.Line)} data-testid="QAInvoiceDetailPageLineItemLineColumnHeader">
                    <span className={getInvoiceLineItemsColHeaderClass(headings.Line)}>{ headings.Line }</span>
                    <IonIcon icon={getInvoiceLineItemsColHeaderIcon(headings.Line)}></IonIcon>
                  </IonCol>
                  <IonCol class="invoice-line-items-col"  size="2" onClick={() => selectColumnHandler(headings.Status)} data-testid="QAInvoiceDetailPageLineItemStatusColumnHeader">
                    <span className={getInvoiceLineItemsColHeaderClass(headings.Status)}>{ headings.Status }</span>
                    <IonIcon icon={getInvoiceLineItemsColHeaderIcon(headings.Status)}></IonIcon>
                  </IonCol>
                  <IonCol class="invoice-line-items-col non-sorting" size={(!platform.isTablet() ? '4.5' : '4')} data-testid="QAInvoiceDetailPageLineItemDescriptionColumnHeader">
                    <span className="invoice-line-items-text">{ headings.Description }</span>
                  </IonCol>
                  <IonCol class="invoice-line-items-col" size="1.5" onClick={() => selectColumnHandler(headings.Quantity)} data-testid="QAInvoiceDetailPageLineItemQuantityColumnHeader">
                    <span className={getInvoiceLineItemsColHeaderClass(headings.Quantity)}>{ headings.Quantity }</span>
                    <IonIcon icon={getInvoiceLineItemsColHeaderIcon(headings.Quantity)}></IonIcon>
                  </IonCol>
                  <IonCol class="invoice-line-items-col" size="1.5" onClick={() => selectColumnHandler(headings.UnitPrice)} data-testid="QAInvoiceDetailPageLineItemUnitPriceColumnHeader">
                    <span className={getInvoiceLineItemsColHeaderClass(headings.UnitPrice)}>{ headings.UnitPrice }</span>
                    <IonIcon icon={getInvoiceLineItemsColHeaderIcon(headings.UnitPrice)}></IonIcon>
                  </IonCol>
                  <IonCol class="invoice-line-items-col" size="2" onClick={() => selectColumnHandler(headings.Total)} data-testid="QAInvoiceDetailPageLineItemTotalColumnHeader">
                    <span className={getInvoiceLineItemsColHeaderClass(headings.Total)}>{ headings.Total }</span>
                    <IonIcon icon={getInvoiceLineItemsColHeaderIcon(headings.Total)}></IonIcon>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardHeader>
          </IonCard>
          {displayedInvoiceLineItems?.map((invoiceLineItem, index) => (
            <InvoiceLineItem
              invoice={invoice}
              displayedInvoiceLineItems={displayedInvoiceLineItems}
              invoiceLineItem={invoiceLineItem}
              invoiceLineItemIndex={index}
              displayedDocuments={displayedDocuments}
              mappedDocuments={uniqueDocuments(mappedDocuments)}
              key={index}
            />
          ))}
          <hr className='bottom-rule'/>
        </div>
        <Documents
          displayedDocuments={displayedDocuments}
          mappedDocuments={uniqueDocuments(mappedDocuments)}
          setDocumentUpdated={setDocumentUpdated}
          showDocumentActions={showDocumentActions}
        />
      </div>

      <InvoiceLineItemDetailsPopover
        isOpen={invoiceDetailPopover.show}
        payload={invoiceDetailPopover.payload}
        mappedInvoiceLineItems={displayedInvoiceLineItems}
        mappedDocuments={mappedDocuments}
        onDismiss={onDismissInvoiceDetailPopover}
        loadDocuments={() => loadDocuments()}
        showDocumentActions={showDocumentActions}
      />

      <Footer/>
    </IonContent> :
    <div className="invoice-detail-loading-container">
      <Loading data-testId="'QAInvoiceDetailLoading'"/>
    </div>
  );
};

export default InvoiceDetailPage;