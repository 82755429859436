import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import infoIcon from '@assets/icon/info-icon.svg';
import LineItemField from '@components/line-item-field/line-item-field';
import { initialMultiShotState, MultiShotState, MultiShotVariants } from '@components/popover/add-quote-item-popover/multi-shot/useMultiShotReducer';
import SelectionContainer from '@components/selection-container/selection-container';
import Tooltip from '@components/tooltip/tooltip';
import { BaseMetalUTDefault, CladProductionSpecifications, CustomerSpecifiedImpactTestDirections, CutMethods, DefaultSurfaceTreatmentSelections, FieldTypes, FinishedProductTypes, GuardRailsLongDescription, HeadFormingTypes, HeatTreatCheckCladTypes, InnerOrOuterDimensions, ManufacturingSites, MeasurementSystems, MetalClasses, MetalPurchaseRequirementsGroups, MetalTypes, NumberOfPiecesInHeadOptions, OutsideServices, PlateMethods, PostCladTestingOptions, PrecladBaseMetalUTDefaults, QuoteAddItemSelectionTabs, QuoteLineItemAdditionalOptions, QuoteLineItemDependentUnwrittenFields, QuoteLineItemFieldUnitTypes, QuoteLineItemValueTypes, QuoteVariableConfigDescriptions, RadioButtonOptions, RawMaterialUsage, Regexes, SearchFields, SelectionLabelPositions, SelectionTypes, Shapes, TensileTestLocations, ToastMessages, ToastTypes, TooltipPositions, Units } from '@constants';
import { useAddEditLineItemPopover } from '@context/add-edit-popover-context';
import { useDataState } from '@context/data-context';
import { QuoteActionTypes, useQuoteDispatch, useQuoteState } from '@context/quote-context';
import { useDarkMode } from '@hooks/useDarkMode';
import { useToast } from '@hooks/useToast';
import { FinishedGood } from '@interfaces/finished-good';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { BaseMetalUTOption, RawMaterial } from '@interfaces/raw-material';
import { IonButton, IonCard, IonCardHeader, IonCheckbox, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonRow } from '@ionic/react';
import { calculateUOMValue, getFreightZones, getMetalPurchasingRequirement, getMetalPurchasingRequirements, getProposalTypeOptions, getQuoteVariableConfiguration, getQuoteVariableConfigurationUOM, getSelectionOptions, getSelections, runGuardRail } from '@shared/quote-utils';
import { DependentUnwrittenFieldsMapValueType } from '@shared/types';
import { arraysEqual, generateUUID } from '@shared/utils';
import classNames from 'classnames';
import { addCircle, closeCircle } from 'ionicons/icons';
import * as React from 'react';
import { useRef, useState } from 'react';
import { createNewEmptyQuoteLineItemPair } from '../../../quote-utils';
import './add-quote-item-popover.scss';
import { createVirtualLinesForMSDetail, MultiShotVirtualGroup } from './multi-shot/multi-shot-details-group/multi-shot-utils';
import MultiShotSection from './multi-shot/multi-shot-section';
import { isMultiShotStateValid } from './multi-shot/multi-shot-utils';

export type LineFieldsToUpdate = {
  additionalOptions: string[];
  baseMetalCustomerProvided: boolean;
  baseMetalProposalType: string;
  baseMetalType: string;
  blankSize: number;
  bondUT: string;
  certificate: string;
  cladMetalCustomerProvided: boolean;
  cladMetalProposalType: string;
  cladMetalType: string;
  cosmeticGrindingSpecifications: string;
  crownPlateDiameter: number;
  cutback: number;
  cutMethod: string;
  cylinderDiameter: number;
  cylinderEdgePreparation: string;
  cylinderFreightChargeToCustomerPerUOM: number;
  cylinderIdOrOd: InnerOrOuterDimensions;
  cylinderLength: number;
  cylinderType: string;
  diameter: number;
  finishedBaseMetalThickness: number;
  finishedCladMetalThickness: number;
  finishedLength: number;
  finishedWidth: number;
  formingCostPerHead: number;
  freightChargeToRollerPerUOM: number;
  headDiameter: number;
  headEdgePreparation: string;
  headFormingType: HeadFormingTypes;
  headFreightChargeToCustomerPerUOM: number;
  headFreightZone: string;
  idOrOd: InnerOrOuterDimensions;
  insideDishRadius: number;
  insideKnuckleRadius: number;
  isDncSelected: boolean;
  isPiecesSelected: boolean;
  isTwoPieceHead: boolean;
  lengthOfWelds: number;
  machining: string;
  notesPrintQuoteToForm: string;
  numberOfPetals: number;
  numberOfPiecesInHead: string;
  openDiameter: number;
  openEnd: string;
  plateSizesSupplyFormerLength: number;
  plateSizesSupplyFormerWidth: number;
  postCladTestingOptions: string[];
  quantity: number;
  quantityPerCylinder: number;
  rollingCostPerCylinder: number;
  shipHeadBlankToFormer: number;
  specification: string;
  straightFlange: number;
  subcontractCylinderRolling: boolean;
  subcontractHeadForming: boolean;
  timeNeededToFormHead: number;
  timeToRoll: string;
  transitTimeToRoller: string;
  unitOfMeasure: MeasurementSystems;
  MultiShotCount__c?: number;
};

const AddQuoteItemPopover = (props: {
  quoteLineItemToEdit: QuoteLineItem;
  quoteLineItemDetailToEdit: QuoteLineItemDetail;
  setShowPopover: (arg0: { showPopover: boolean; event: undefined; showGuardrailsPopover?: any; builtQuote?: QuoteLineItem }) => void;
  hasMultiShotFeatureFlag?: boolean;
}) => {
  // TODO: FF Note: there is also a Feature Flag for Multi-Shot  that makes these useless if FF is off
  const { isMultiShot, setIsMultiShot } = useAddEditLineItemPopover();
  
  const title = `${!props.quoteLineItemToEdit ? 'Add' : 'Edit'} ${isMultiShot ? 'Multi-Shot' : ''} Item`;
  
  const { dataState, loadRawMaterialCombinations } = useDataState();
  const { finishedGoods, freight, rawMaterials, specificationTestOptions, selections, quoteVariableConfigurations } = dataState;
  
  const { dispatchQuoteState } = useQuoteDispatch();
  const { quoteState, saveQuoteLineItem, handleLeftoverQuantitiesForAutocombine } = useQuoteState();
  const { quote, lineToDuplicate } = quoteState;
  
  const { quoteLineItems, Manufacturing_Site__c: manufacturingSite, CurrencyIsoCode: currencyIsoCode, Export_Packaging__c: exportPackaging } = quote;
  // Multi Shot State
  const multiShotMetalsStateRef = useRef(initialMultiShotState);
  // This is the state of the dropdown that allows the user to select the type of multi-shot
  const [multiShotType, setMultiShotType] = useState<MultiShotVariants>(null);

  // run cleanup on unmount to reset the state of the multi-shot, and clear the type dropdown selection
  React.useEffect(() => {
    return () => {
      console.log('running cleanup for multi-shot');
      if (isMultiShot) {
        setIsMultiShot(false);
      }
      setMultiShotType(undefined);
      multiShotMetalsStateRef.current = /*initialMultiShotState*/ undefined;
    };
  }, []);

  React.useEffect(() => {
    const setDocumentUOM = () => {
        let documentUOM: string = quote.DocumentUOM__c;

        if (documentUOM === undefined || documentUOM === null && quoteLineItems.length > 0) {
            documentUOM = documentUOM ?? quoteLineItems[0].Unit_of_Measure__c;
            if (documentUOM !== quoteLineItems[0].Unit_of_Measure__c) {
                documentUOM = quoteLineItems[0].Unit_of_Measure__c;
            }
            const updatedQuote = {
              ...quote,
              DocumentUOM__c: documentUOM,
            };
            dispatchQuoteState({type: QuoteActionTypes.loadQuote, payload: updatedQuote});
        }
      };
      setDocumentUOM();
  }
  , [dispatchQuoteState, quote, quoteLineItems]);

  const { darkMode } = useDarkMode();
  const toast = useToast(4000);
  const longerToast = useToast(6000);

  const quoteLineItemData = React.useMemo<QuoteLineItem>(
    () =>
      props.quoteLineItemToEdit || lineToDuplicate,
    [lineToDuplicate, props.quoteLineItemToEdit]
  );
  const quoteLineItemDetailData = React.useMemo<QuoteLineItemDetail>(
    () =>
    props.quoteLineItemDetailToEdit ||
    (lineToDuplicate
      ? lineToDuplicate.quoteLineItemDetail
      : undefined),
      [lineToDuplicate, props.quoteLineItemDetailToEdit]
      );

  // Quote Line Item Data State Variables
  const [unitOfMeasure, setUnitOfMeasure] = React.useState<MeasurementSystems>(quoteLineItemData?.Unit_of_Measure__c as MeasurementSystems || (manufacturingSite === ManufacturingSites.DE01 ? MeasurementSystems.Metric : MeasurementSystems.Imperial));
  const isMetricUOM = unitOfMeasure === MeasurementSystems.Metric;
  const [baseMetalProposalType, setBaseMetalProposalType] = React.useState(quoteLineItemData?.Proposal_Type_Base__c);
  const [cladMetalProposalType, setCladMetalProposalType] = React.useState(quoteLineItemData?.Proposal_Type_Clad__c);

  const lineFieldsToUpdate: LineFieldsToUpdate = {
    additionalOptions: quoteLineItemData?.Additional_Options__c?.split(';') || [],
    baseMetalCustomerProvided: quoteLineItemData?.Customer_Provided_Base__c,
    baseMetalProposalType: quoteLineItemData?.Proposal_Type_Base__c,
    baseMetalType: quoteLineItemData?.Base_Type__c,
    blankSize: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.BlankSize__c,
    bondUT: quoteLineItemData?.Bond_Metal_UT__c,
    certificate: quoteLineItemData?.Certificate__c || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.Certificate)?.default,
    cladMetalCustomerProvided: quoteLineItemData?.Customer_Provided_Clad__c,
    cladMetalProposalType: quoteLineItemData?.Proposal_Type_Clad__c,
    cladMetalType: quoteLineItemData?.Clad_Type__c,
    cosmeticGrindingSpecifications: quoteLineItemData?.Cosmetic_Grinding_Specifications__c,
    crownPlateDiameter: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.CrownPlateDiameter__c,
    cutback: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.Cutback__c,
    cutMethod: quoteLineItemData?.Cut_Method__c,
    cylinderDiameter: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.Diameter__c,
    cylinderEdgePreparation: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.EdgePreparation__c,
    cylinderFreightChargeToCustomerPerUOM: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.FreightChargeToCustomerPer__c,
    cylinderIdOrOd: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.IDorOD__c,
    cylinderLength: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r?.CylinderLength__c,
    cylinderType: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.CylinderType__c,
    diameter: quoteLineItemDetailData?.Diameter__c,
    finishedBaseMetalThickness: unitOfMeasure === MeasurementSystems.Metric ? quoteLineItemData?.Base_TK_mm__c : quoteLineItemData?.Base_TK_in__c,
    finishedCladMetalThickness: unitOfMeasure === MeasurementSystems.Metric ? quoteLineItemData?.Clad_TK_mm__c : quoteLineItemData?.Clad_TK_in__c,
    finishedLength: unitOfMeasure === MeasurementSystems.Metric ? quoteLineItemData?.Length_mm__c : quoteLineItemData?.Length_in__c,
    finishedWidth: unitOfMeasure === MeasurementSystems.Metric ? quoteLineItemData?.Width_mm__c : quoteLineItemData?.Width_in__c,
    formingCostPerHead: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.HeadFormingCostPerHead__c,
    freightChargeToRollerPerUOM: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.FreightChargeToRollerPer__c,
    headDiameter: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.HeadDiameter__c,
    headEdgePreparation: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.HeadEdgePreparation__c,
    headFormingType: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.HeadFormingType__c,
    headFreightChargeToCustomerPerUOM: unitOfMeasure === MeasurementSystems.Metric ? quoteLineItemData?.Total_Freight_KG__c : quoteLineItemData?.Total_Freight_LB__c,
    headFreightZone: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.HeadFreightZone__c?.replace('_', ' '),
    idOrOd: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.IDorOD__c,
    insideDishRadius: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.InsideDishRadius__c,
    insideKnuckleRadius: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.InsideKnuckleRadius__c,
    isDncSelected: quoteLineItemData?.DNC__c,
    isPiecesSelected: quoteLineItemData?.Pieces__c,
    isTwoPieceHead: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.CreateTwoPieceHead__c,
    lengthOfWelds: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.LengthOfWelds__c,
    machining: quoteLineItemData?.TubesheetMachining__c,
    MultiShotCount__c: isMultiShot ? quoteLineItemDetailData?.MultiShotCount__c : null,
    notesPrintQuoteToForm: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.CylinderNotes__c,
    numberOfPetals: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.NumberOfPetals__c,
    numberOfPiecesInHead: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.NumberOfPiecesInHead__c,
    openDiameter: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.OpenEndDiameter__c,
    openEnd: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.OpenEnd__c, // TODO: verify with Tony, this never gets set.
    plateSizesSupplyFormerLength: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.PlateLengthSupplyToFormer__c,
    plateSizesSupplyFormerWidth: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.PlateWidthSupplyToFormer__c,
    postCladTestingOptions: (quoteLineItemData?.Post_Clad_Testing_Options__c?.split(';') || []).concat(quoteLineItemData?.quoteLineItemDetail?.MachineSideBends__c ? ['Side Bend'] : []),
    quantity: quoteLineItemData?.Quantity__c ?? 1,
    quantityPerCylinder: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.QuantityPerCylinder__c,
    rollingCostPerCylinder: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.RollingCostPerCylinder__c,
    shipHeadBlankToFormer: quoteLineItemDetailData?.NC_Head_Item_Detail__r.HeadTimeToFormer__c || 1,
    specification: quoteLineItemData?.Specification__c,
    straightFlange: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.StraightFlange__c,
    subcontractCylinderRolling: quoteLineItemDetailData?.SubcontractCylinderrolling__c,
    subcontractHeadForming: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.SubcontractHeadForming__c ?? false,
    timeNeededToFormHead: quoteLineItemDetailData?.NC_Head_Item_Detail__r?.HeadTimeToFormHead__c || 6,
    timeToRoll: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.TimeToRollWeeks__c,
    transitTimeToRoller: quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.TransitTimeToRollerWeeks__c, unitOfMeasure: unitOfMeasure,
  };

  const [baseMetal, setBaseMetal] = React.useState<RawMaterial>(rawMaterials.find(rawMaterial => rawMaterial.name === quoteLineItemData?.BaseMetal__c && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite)));
  const [cladMetal, setCladMetal] = React.useState(rawMaterials.find(rawMaterial => rawMaterial.name === quoteLineItemData?.CladMetal__c && rawMaterial.usage === RawMaterialUsage.Clad && rawMaterial.dataAreaId.includes(manufacturingSite)));

  const [itemType, setItemType] = React.useState(finishedGoods.find(finishedGood => finishedGood.name === quoteLineItemData?.Item_Type__c && finishedGood.dataAreaId === manufacturingSite));

  const dependentUnwrittenFieldsMap = new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>();
  const fieldsUpdated = [];

  const [shouldUseDefaultBondUT, setShouldUseDefaultBondUT] = React.useState(!quoteLineItemData);
  const [shouldUseDefaultPostCladTestingOptions, setShouldUseDefaultPostCladTestingOptions] = React.useState(!quoteLineItemData);

  const [updatingPostCladTestingOptions, setUpdatingPostCladTestingOptions] = React.useState(false);

  const [disableTwoPieceHeadCheckbox, setDisableTwoPieceHeadCheckbox] = React.useState(false);

  const [shape, setShape] = React.useState(quoteLineItemData?.Shape__c);

  const [activeTab, setActiveTab] = React.useState(QuoteAddItemSelectionTabs.Description);
  const [baseMetalUT, setBaseMetalUT] = React.useState(baseMetal?.baseMetalUTOptions[manufacturingSite]?.find(baseMetalUTOption => baseMetalUTOption.option === (quoteLineItemData?.Base_Metal_UT__c || BaseMetalUTDefault.None)));

  /** LineFieldsToUpdate */
  const [additionalOptions, setAdditionalOptions] = React.useState(lineFieldsToUpdate.additionalOptions);
  const [baseMetalCustomerProvided, setBaseMetalCustomerProvided] = React.useState(lineFieldsToUpdate.cladMetalCustomerProvided);
  const [baseMetalType, setBaseMetalType] = React.useState(lineFieldsToUpdate.baseMetalType);
  const [blankSize, setBlankSize] = React.useState(lineFieldsToUpdate.blankSize);
  const [bondUT, setBondUT] = React.useState(lineFieldsToUpdate.bondUT);
  const [certificate, setCertificate] = React.useState(lineFieldsToUpdate.certificate);
  const [cladMetalCustomerProvided, setCladMetalCustomerProvided] = React.useState(lineFieldsToUpdate.cladMetalCustomerProvided);
  const [cladMetalType, setCladMetalType] = React.useState(lineFieldsToUpdate.cladMetalType);
  const [cosmeticGrindingSpecifications, setCosmeticGrindingSpecifications] = React.useState(lineFieldsToUpdate.cosmeticGrindingSpecifications);
  const [crownPlateDiameter, setCrownPlateDiameter] = React.useState(lineFieldsToUpdate.crownPlateDiameter);
  const [cutback, setCutback] = React.useState(lineFieldsToUpdate.cutback);
  const [cutMethod, setCutMethod] = React.useState(lineFieldsToUpdate.cutMethod);
  const [cylinderDiameter, setCylinderDiameter] = React.useState(lineFieldsToUpdate.cylinderDiameter);
  const [cylinderEdgePreparation, setCylinderEdgePreparation] = React.useState(lineFieldsToUpdate.cylinderEdgePreparation);
  const [cylinderFreightChargeToCustomerPerUOM, setCylinderFreightChargeToCustomerPerUOM] = React.useState(lineFieldsToUpdate.cylinderFreightChargeToCustomerPerUOM);
  const [cylinderIdOrOd, setCylinderIdOrOd] = React.useState(lineFieldsToUpdate.cylinderIdOrOd);
  const [cylinderLength, setCylinderLength] = React.useState(lineFieldsToUpdate.cylinderLength);
  const [cylinderType, setCylinderType] = React.useState(lineFieldsToUpdate.cylinderType);
  const [diameter, setDiameter] = React.useState(lineFieldsToUpdate.diameter);
  const [finishedBaseMetalThickness, setFinishedBaseMetalThickness] = React.useState(lineFieldsToUpdate.finishedBaseMetalThickness);
  const [finishedCladMetalThickness, setFinishedCladMetalThickness] = React.useState(lineFieldsToUpdate.finishedCladMetalThickness);
  const [finishedLength, setFinishedLength] = React.useState(lineFieldsToUpdate.finishedLength);
  const [finishedWidth, setFinishedWidth] = React.useState(lineFieldsToUpdate.finishedWidth);
  const [formingCostPerHead, setFormingCostPerHead] = React.useState(lineFieldsToUpdate.formingCostPerHead);
  const [freightChargeToRollerPerUOM, setFreightChargeToRollerPerUOM] = React.useState(lineFieldsToUpdate.freightChargeToRollerPerUOM);
  const [headDiameter, setHeadDiameter] = React.useState(lineFieldsToUpdate.headDiameter);
  const [headEdgePreparation, setHeadEdgePreparation] = React.useState(lineFieldsToUpdate.headEdgePreparation);
  const [headFormingType, setHeadFormingType] = React.useState<HeadFormingTypes>(lineFieldsToUpdate.headFormingType);
  const [headFreightChargeToCustomerPerUOM, setHeadFreightChargeToCustomerPerUOM] = React.useState(lineFieldsToUpdate.headFreightChargeToCustomerPerUOM);
  const [headFreightZone, setHeadFreightZone] = React.useState(lineFieldsToUpdate.headFreightZone);
  const [idOrOd, setIdOrOd] = React.useState(lineFieldsToUpdate.idOrOd);
  const [insideDishRadius, setInsideDishRadius] = React.useState(lineFieldsToUpdate.insideDishRadius);
  const [insideKnuckleRadius, setInsideKnuckleRadius] = React.useState(lineFieldsToUpdate.insideKnuckleRadius);
  const [isDncSelected, setIsDncSelected] = React.useState(lineFieldsToUpdate.isDncSelected);
  const [isPiecesSelected, setIsPiecesSelected] = React.useState(lineFieldsToUpdate.isPiecesSelected);
  const [isTwoPieceHead, setIsTwoPieceHead] = React.useState(lineFieldsToUpdate.isTwoPieceHead);
  const [lengthOfWelds, setLengthOfWelds] = React.useState(lineFieldsToUpdate.lengthOfWelds);
  const [machining, setMachining] = React.useState(quoteLineItemData?.TubesheetMachining__c);
  const [notesPrintQuoteToForm, setNotesPrintQuoteToForm] = React.useState(lineFieldsToUpdate.notesPrintQuoteToForm);
  const [numberOfPetals, setNumberOfPetals] = React.useState(lineFieldsToUpdate.numberOfPetals);
  const [numberOfPiecesInHead, setNumberOfPiecesInHead] = React.useState(lineFieldsToUpdate.numberOfPiecesInHead);
  const [openDiameter, setOpenDiameter] = React.useState(lineFieldsToUpdate.openDiameter);
  const [plateSizesSupplyFormerLength, setPlateSizesSupplyFormerLength] = React.useState(lineFieldsToUpdate.plateSizesSupplyFormerLength);
  const [plateSizesSupplyFormerWidth, setPlateSizesSupplyFormerWidth] = React.useState(lineFieldsToUpdate.plateSizesSupplyFormerWidth);
  const [postCladTestingOptions, setPostCladTestingOptions] = React.useState(lineFieldsToUpdate.postCladTestingOptions);
  const [quantity, setQuantity] = React.useState(lineFieldsToUpdate.quantity);
  const [quantityPerCylinder, setQuantityPerCylinder] = React.useState(lineFieldsToUpdate.quantityPerCylinder);
  const [rollingCostPerCylinder, setRollingCostPerCylinder] = React.useState(lineFieldsToUpdate.rollingCostPerCylinder);
  const [shipHeadBlankToFormer, setShipHeadBlankToFormer] = React.useState(lineFieldsToUpdate.shipHeadBlankToFormer);
  const [specification, setSpecification] = React.useState(lineFieldsToUpdate.specification);
  const [specificationTestOption, setSpecificationTestOption] = React.useState(undefined);
  const [straightFlange, setStraightFlange] = React.useState(lineFieldsToUpdate.straightFlange);
  const [timeNeededToFormHead, setTimeNeededToFormHead] = React.useState(lineFieldsToUpdate.timeNeededToFormHead);
  const [timeToRoll, setTimeToRoll] = React.useState(lineFieldsToUpdate.timeToRoll);
  const [transitTimeToRoller, setTransitTimeToRoller] = React.useState(lineFieldsToUpdate.transitTimeToRoller);
  const [subcontractCylinderRolling, setSubcontractCylinderRolling] = React.useState(lineFieldsToUpdate.subcontractCylinderRolling);
  const [subcontractHeadForming, setSubcontractHeadForming] = React.useState(lineFieldsToUpdate.subcontractHeadForming);
  
  // NOTE: restricting the option for Head Segmental from being selected if the hasMultiShotFeatureFlag Feature Flag is false
  // so that Head - Segmental can't be selected in the dropdown, disabling the Head - Segmental parts in the Add Quote Item Popover

  // TODO: kf put back options OG after FF is removed
  // const itemTypeOptions = React.useMemo(() => {
  //   return finishedGoods.filter(finishedGood => finishedGood.dataAreaId === manufacturingSite).map(finishedGood => finishedGood.name);
  // }, [finishedGoods, manufacturingSite]);
  const itemTypeOptions = React.useMemo(() => {
      return props.hasMultiShotFeatureFlag
        ? finishedGoods
            .filter((finishedGood) => finishedGood.dataAreaId === manufacturingSite)
            .map((finishedGood) => finishedGood.name)
        : finishedGoods
            .filter((finishedGood) => finishedGood.dataAreaId === manufacturingSite)
            .map((finishedGood) => finishedGood.name)
            .filter((item) => item !== FinishedProductTypes.HeadSegmental);
    }, [props.hasMultiShotFeatureFlag, finishedGoods, manufacturingSite]);

  /** Selection Options */
  const cutMethodInputOptions = React.useMemo(() => {
      return itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.CutMethod)?.options
        .filter(opt => (shape === Shapes.Circle ? opt !== CutMethods.SawCut : true));
    }, [itemType, shape]);

  const cylinderDiameterOptions = React.useMemo(() => {
      return itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.CylinderType)?.options;
    }, [itemType]);

    const baseMetalTypeOptions = React.useMemo(() => {
      return itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.BaseType)?.options;
    }, [itemType]);

    const cladMetalTypeOptions = React.useMemo(() => {
      return itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.CladType)?.options;
    }, [itemType]);

    const shapeOptions = React.useMemo(() => {
      return itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.Shape)?.options;
    }, [itemType]);

    const freightOptions = React.useMemo(() => {
      return getFreightZones(freight, manufacturingSite);
    }, [freight, manufacturingSite]);

    const specTestSelectionOptions: string[] = React.useMemo(() => {
      return [...new Set(specificationTestOptions.filter(specificationTestOption => specificationTestOption.dataAreaId === manufacturingSite).map((specificationTestOption) => specificationTestOption.specification))];
    }, [manufacturingSite, specificationTestOptions]);

    const certSelectionOptions = React.useMemo(() => {
      return getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.Certificate).options;
    }, [selections, manufacturingSite]);

    const bondUTSelectionOptions = React.useMemo(() => {
      return [...specificationTestOptions.filter((specificationTestOption) => specificationTestOption.specification === specification && specificationTestOption.dataAreaId === manufacturingSite).map(specificationTestOption => specificationTestOption.option)];
    }, [specificationTestOptions, specification, manufacturingSite]);

    const isCosmeticGrindingSpecDisabled = React.useMemo(() => {
      return !additionalOptions.includes(QuoteLineItemAdditionalOptions.CosmeticGrinding);
    }, [additionalOptions]);

    const postCladTestingSelectionOptions = React.useMemo(() => {
      return specificationTestOption?.postCladTestingOptions.map(option => option.name);
    }, [specificationTestOption]);

    const baseUTSelectionOptions: BaseMetalUTOption[] = React.useMemo(() => {
      return [...(baseMetal?.baseMetalUTOptions[manufacturingSite]?.map((bmutoption) => bmutoption.option as BaseMetalUTOption) || [])];
    }, [baseMetal, manufacturingSite]);

    /** Handlers */
    const specChangeHandler = (specification: string) => {
      setShouldUseDefaultPostCladTestingOptions(true);
      setShouldUseDefaultBondUT(true);
      setSpecification(specification);
    };

    const twoPieceHandler = (checked: boolean) => {
       setNumberOfPiecesInHead(checked ? NumberOfPiecesInHeadOptions.TwoPieceHead : NumberOfPiecesInHeadOptions.SinglePieceHead);
       setIsTwoPieceHead(checked);
    };

    const itemTypeHandler = (itemTypeName: string) => {
      const _itemType = finishedGoods.find(
        (finishedGood) => finishedGood.name === itemTypeName && finishedGood.dataAreaId === manufacturingSite
      );

      setItemType(_itemType);

      if(!machining) {
        const machining = (
          _itemType.name === FinishedProductTypes.Tubesheet
          && _itemType.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.Machining)?.default
        ) || null;

        setMachining(machining);
      }

      const baseMetalType = _itemType.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.BaseType)?.default;
      const cladMetalType = _itemType.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.CladType)?.default;
      const shape = _itemType.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.Shape)?.default as Shapes;
            
      setSubcontractHeadForming(itemTypeName === FinishedProductTypes.Head || itemTypeName === FinishedProductTypes.HeadSegmental);
      setSubcontractCylinderRolling(itemTypeName === FinishedProductTypes.Cylinder);

      setShape(shape);
      setBaseMetalType(baseMetalType);
      setCladMetalType(cladMetalType);
    };

    const additionaOptionsSelectionHandler = (checked: boolean, additionalOption: string) => {
      setAdditionalOptions((prevAdditionalOptions) => {
        let updatedAdditionalOptions = [...prevAdditionalOptions];

        if (checked) {
          updatedAdditionalOptions.push(additionalOption);
        } else {
          updatedAdditionalOptions = updatedAdditionalOptions.filter((option) => option !== additionalOption);
        }

        return updatedAdditionalOptions;
      });
    };

    const baseMetalUTChangeHandler = (bmUTOption: string) => {
      try {
        const option = baseMetal?.baseMetalUTOptions?.[manufacturingSite]?.find((option) => option.option === bmUTOption);
        setBaseMetalUT(option);
      } catch (error) {
        console.error(error);
      }
    };

    const postCladTestingOptionsChangeHandler = (checked: boolean, postCladTestingOption: string) => {
      setShouldUseDefaultPostCladTestingOptions(false);

      let updatedPostCladTestingOptions = [
        ...postCladTestingOptions,
      ];

      if (checked) {
        updatedPostCladTestingOptions.push(postCladTestingOption);
      } else {
        updatedPostCladTestingOptions = updatedPostCladTestingOptions.filter(
          (option) => option != postCladTestingOption
        );
      }

      setPostCladTestingOptions([...updatedPostCladTestingOptions]);
    };

    const bondUTChangeHandler = (bondUT: string) => {
      setShouldUseDefaultBondUT(false);
      setBondUT(bondUT);
    };

  React.useEffect(() => {
    setUpdatingPostCladTestingOptions(false);
  }, [postCladTestingOptions]);

  React.useEffect(() => {
    setShouldUseDefaultBondUT(!quoteLineItemData);
    setShouldUseDefaultPostCladTestingOptions(!quoteLineItemData);
  }, [quoteLineItemData]);

  React.useEffect(() => {
    try {
      if(specification) {
        if(shouldUseDefaultBondUT) {
          setBondUT(specificationTestOptions.find(specificationTestOption => specificationTestOption.specification === specification && specificationTestOption.dataAreaId === manufacturingSite && specificationTestOption.default).option);

          if(shouldUseDefaultPostCladTestingOptions) {
            setUpdatingPostCladTestingOptions(true);
          }
        }
      }
   } catch (error) {
    console.error(error);
   }
  }, [specification, shouldUseDefaultPostCladTestingOptions, shouldUseDefaultBondUT]);

  React.useEffect(() => {
    try {
      if(specification && bondUT) {
        setSpecificationTestOption(specificationTestOptions.find((specificationTestOption) => specificationTestOption.specification === specification && specificationTestOption.option === bondUT && specificationTestOption.dataAreaId === manufacturingSite));
      }
    } catch (error) {
      console.error(error);
   }
  }, [specification, bondUT]);

  React.useEffect(() => {
    try {
      if(specificationTestOption && shouldUseDefaultPostCladTestingOptions) {
        setPostCladTestingOptions(specificationTestOption.postCladTestingOptions.filter(postCladTestingOption => postCladTestingOption.selected).map(postCladTestingOption => postCladTestingOption.name));
      }
    } catch (error) {
      console.error(error);
    }
  }, [specificationTestOption]);

  React.useEffect(() => {
    setShouldUseDefaultBondUT(!quoteLineItemData);
    setShouldUseDefaultPostCladTestingOptions(!quoteLineItemData);
  }, [quoteLineItemData]);
  
  React.useEffect(() => {
    if(cladMetal) {
      setShouldUseDefaultBondUT(true);
      setShouldUseDefaultPostCladTestingOptions(true);
    }
  }, [cladMetal]);

  React.useEffect(() => {
    // TODO: kf - do these on all metal combos in the MS
    if(baseMetal && cladMetal) {
      loadRawMaterialCombinations(baseMetal.name, cladMetal.name, manufacturingSite);

      // only set when theres no specification
      if (!specification) {
        const newSpec = manufacturingSite === ManufacturingSites.US01
        && baseMetal?.dmcClass.includes('/F')
          ? CladProductionSpecifications.DMC100
          : cladMetal?.specification[manufacturingSite];

        setSpecification(newSpec);
      }
    }
  }, [cladMetal, baseMetal]);

  React.useEffect(() => {
    if (cladMetal && itemType && !cutMethod) {
      const cutMethod = itemType.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.CutMethod)?.default;

      setCutMethod(cutMethod);
    }
  }, [cladMetal, itemType]);

  const dismissModal = (showGuardrailsPopover?:any, builtQuote?: QuoteLineItem) => {
    props.setShowPopover({ showPopover: false, event: undefined, showGuardrailsPopover: showGuardrailsPopover, builtQuote: builtQuote });
    dispatchQuoteState({type: QuoteActionTypes.updateLineToDuplicate, payload: undefined});
  };

  const handleMetalChange = (raw: RawMaterial, usage: RawMaterialUsage) => {
    const metal = rawMaterials.find(
      (rm) => rm.name === raw.name && rm.usage === usage && rm.dataAreaId.includes(manufacturingSite)
    );
    const proposalTypeOptions = getProposalTypeOptions(metal, manufacturingSite);
    switch (usage) {
      case RawMaterialUsage.Base:
        // setShouldUseDefaultSpecification(true);
        setBaseMetal(metal);

        // if the proposalType is not in the list, set to first element
        if (!proposalTypeOptions.includes(baseMetalProposalType)) {
          setBaseMetalProposalType(proposalTypeOptions[0]);
        }

        if (!baseMetalUT) {
          setBaseMetalUT(metal?.baseMetalUTOptions[manufacturingSite]?.find(baseMetalUTOption => baseMetalUTOption.option === (BaseMetalUTDefault.None)));
        }

        if (metal.dmcClass.includes('/F')) {
          setIsDncSelected(true);
          setIsPiecesSelected(true);
          setIsTwoPieceHead(false);

          setDisableTwoPieceHeadCheckbox(true);
        } else {
          setIsDncSelected(false);
          setIsPiecesSelected(false);

          setDisableTwoPieceHeadCheckbox(false);
        }
        break;
      case RawMaterialUsage.Clad:
        setCladMetal(metal);

        // if the proposalType is not in the list, set to first element
        if (!proposalTypeOptions.includes(cladMetalProposalType)) {
          setCladMetalProposalType(proposalTypeOptions[0]);
        }

        if (
          metal.type === HeatTreatCheckCladTypes.Titanium ||
          metal.type === HeatTreatCheckCladTypes.Zirconium ||
          metal.type === HeatTreatCheckCladTypes.FerriticStainless
        ) {
          if (!additionalOptions.includes(QuoteLineItemAdditionalOptions.HeatTreatment)) {
            setAdditionalOptions((prevOptions) => [...prevOptions, QuoteLineItemAdditionalOptions.HeatTreatment]);
          }
        } else {
          if (additionalOptions.includes(QuoteLineItemAdditionalOptions.HeatTreatment)) {
            setAdditionalOptions((prevOptions) =>
              prevOptions.filter((option) => option !== QuoteLineItemAdditionalOptions.HeatTreatment)
            );
          }
        }
        break;
    }
  };

  /**
   * should remove line fields to update
   */
  const updateQuoteLineData = (quoteLineItemToEdit: QuoteLineItem, updatedFields?: LineFieldsToUpdate, clad?: RawMaterial) => {
    
    const states = {
      additionalOptions: additionalOptions,
      baseMetalCustomerProvided: baseMetalCustomerProvided,
      baseMetalType: baseMetalType,
      blankSize: blankSize,
      bondUT: bondUT,
      certificate: certificate,
      cladMetalCustomerProvided: cladMetalCustomerProvided,
      cladMetalType: cladMetalType,
      cosmeticGrindingSpecifications: additionalOptions.includes(QuoteLineItemAdditionalOptions.CosmeticGrinding) ? cosmeticGrindingSpecifications : null,
      crownPlateDiameter: crownPlateDiameter,
      cutback: cutback,
      cutMethod: shape === Shapes.Circle && cutMethod === CutMethods.SawCut ? null : cutMethod,
      cylinderDiameter: cylinderDiameter,
      cylinderEdgePreparation: cylinderEdgePreparation,
      cylinderFreightChargeToCustomerPerUOM: cylinderFreightChargeToCustomerPerUOM,
      cylinderIdOrOd: cylinderIdOrOd,
      cylinderLength: cylinderLength,
      cylinderType: cylinderType,
      diameter: shape === Shapes.Circle ? finishedWidth : diameter,
      finishedBaseMetalThickness: finishedBaseMetalThickness,
      finishedCladMetalThickness: finishedCladMetalThickness,
      finishedLength: shape === Shapes.Circle ? finishedWidth : finishedLength,
      finishedWidth: finishedWidth,
      formingCostPerHead: formingCostPerHead,
      freightChargeToRollerPerUOM: freightChargeToRollerPerUOM,
      headDiameter: headDiameter,
      headEdgePreparation: headEdgePreparation,
      headFormingType: headFormingType,
      headFreightChargeToCustomerPerUOM: headFreightChargeToCustomerPerUOM,
      headFreightZone: headFreightZone,
      idOrOd: idOrOd,
      insideDishRadius: insideDishRadius,
      insideKnuckleRadius: insideKnuckleRadius,
      isDncSelected: isDncSelected,
      isPiecesSelected: isPiecesSelected,
      isTwoPieceHead: isTwoPieceHead,
      lengthOfWelds: lengthOfWelds,
      machining: machining,
      MultiShotCount__c: isMultiShot,
      notesPrintQuoteToForm: notesPrintQuoteToForm,
      numberOfPetals: numberOfPetals,
      numberOfPiecesInHead: numberOfPiecesInHead,
      openDiameter: openDiameter,
      openEnd: quoteLineItemToEdit?.quoteLineItemDetail?.NC_Head_Item_Detail__r?.OpenEnd__c,
      plateSizesSupplyFormerLength: plateSizesSupplyFormerLength,
      plateSizesSupplyFormerWidth: plateSizesSupplyFormerWidth,
      postCladTestingOptions: postCladTestingOptions,
      quantity: quantity || 1,
      quantityPerCylinder: quantityPerCylinder,
      rollingCostPerCylinder: rollingCostPerCylinder,
      shipHeadBlankToFormer: shipHeadBlankToFormer,
      specification: specification,
      straightFlange: straightFlange,
      subcontractHeadForming: subcontractHeadForming,
      subcontractCylinderRolling: subcontractCylinderRolling,
      timeNeededToFormHead: timeNeededToFormHead,
      timeToRoll: timeToRoll,
      transitTimeToRoller: transitTimeToRoller,
      cladMetalProposalType: cladMetalProposalType,
      baseMetalProposalType: baseMetalProposalType,
    };

    const updatedQLD = {
      ...states,
      ...(updatedFields || {}),
    };

    const _cladMetal = clad || cladMetal;

    console.log('updateQuoteLineData', _cladMetal.name);

    // this was in the add item button
    const defaultTensileValue = updatedQLD.postCladTestingOptions.filter(option => option == PostCladTestingOptions.Tensile).length === 1
    ? TensileTestLocations.PerSpecification
    : null;
    quoteLineItemToEdit.quoteLineItemDetail.CladTensileTestLocation__c = defaultTensileValue;

    // existing code that builds out the quoteLineItemToEdit and quoteLineItemToEdit.quoteLineItemDetail fields
    if(updatedQLD.quantity && (updatedQLD.quantity !== quoteLineItemData?.Quantity__c || !props.quoteLineItemToEdit) || updatedQLD.isPiecesSelected === false && (updatedQLD.isPiecesSelected !== quoteLineItemData?.Pieces__c || !props.quoteLineItemToEdit)) {

      quoteLineItemToEdit.Quantity__c = updatedQLD.quantity || 1;
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.QuantityForLeftoverCalculation, updatedQLD.quantity);

      quoteLineItemToEdit.quoteLineItemDetail.Clads__c = null;

      fieldsUpdated.push(QuoteLineItemValueTypes.SizingQuantity);
      fieldsUpdated.push(QuoteLineItemValueTypes.QuantityForLeftoverCalculation);
    }

    if(unitOfMeasure && (unitOfMeasure !== quoteLineItemData?.Unit_of_Measure__c || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Unit_of_Measure__c = unitOfMeasure;
      quoteLineItemToEdit.quoteLineItemDetail.Unit_of_Measure__c = unitOfMeasure;
      fieldsUpdated.push(QuoteLineItemValueTypes.UnitOfMeasure);
    }

    if(_cladMetal && (_cladMetal.name !== quoteLineItemData?.CladMetal__c || !props.quoteLineItemToEdit)) {
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.CladMetal, cladMetal);
      quoteLineItemToEdit.CladMetal__c = _cladMetal.name;
      quoteLineItemToEdit.quoteLineItemDetail.Flyer_Needed__c = !!_cladMetal.flyerRequirements;
      quoteLineItemToEdit.quoteLineItemDetail.Flyer_TK_IN__c = _cladMetal.flyerRequirements?.imperial?.flyerTK || 1;
      quoteLineItemToEdit.quoteLineItemDetail.Flyer_TK_MM__c = _cladMetal.flyerRequirements?.metric?.flyerTK || 1;
      quoteLineItemToEdit.quoteLineItemDetail.MetalClassAnvil__c = (_cladMetal.anvilRequirements[manufacturingSite] || [])[0]?.anvilClass;
      quoteLineItemToEdit.quoteLineItemDetail.MetalClassCladder__c = _cladMetal.classId;
      quoteLineItemToEdit.quoteLineItemDetail.MetalClassFlyer__c = _cladMetal.flyerRequirements?.flyerClass || 8;
      quoteLineItemToEdit.quoteLineItemDetail.NCCladHeatTreatmentasSupplied__c = getMetalPurchasingRequirements(cladMetal, MetalPurchaseRequirementsGroups.HeatTreatment, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCCladChemistryRestrictionsforBonding__c = getMetalPurchasingRequirements(cladMetal, MetalPurchaseRequirementsGroups.ManufacturingRestrictions, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.CorrosionTesting__c = getMetalPurchasingRequirements(cladMetal, MetalPurchaseRequirementsGroups.CorrosionTesting, manufacturingSite) || null;
      quoteLineItemToEdit.quoteLineItemDetail.NCCladFlatnessRequirement__c = getMetalPurchasingRequirements(cladMetal, MetalPurchaseRequirementsGroups.FlatnessRequirements, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCCladCamberRequirement__c = getMetalPurchasingRequirements(cladMetal, MetalPurchaseRequirementsGroups.Camber, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.CladManufacturingRestrictions__c = getMetalPurchasingRequirements(cladMetal, MetalPurchaseRequirementsGroups.AdditionalRequirements, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCCladCertificateRequired__c = getMetalPurchasingRequirements(cladMetal, MetalPurchaseRequirementsGroups.Certification, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCCladIsWeldRepairAllowed__c = getMetalPurchasingRequirements(cladMetal, MetalPurchaseRequirementsGroups.WeldRepair, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCCladMarkingRequired__c = getMetalPurchasingRequirements(cladMetal, MetalPurchaseRequirementsGroups.Marking, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.CladMetalProductAnalysisRequired__c = getMetalPurchasingRequirements(cladMetal, MetalPurchaseRequirementsGroups.MetalProductAnalysis, manufacturingSite) || null;
      quoteLineItemToEdit.quoteLineItemDetail.IsWeldRepairAllowed__c = RadioButtonOptions.Yes;
      quoteLineItemToEdit.quoteLineItemDetail.CladdingForCorrosionAllowanceOnly__c = RadioButtonOptions.Yes;
      fieldsUpdated.push(QuoteLineItemValueTypes.CladMetal);

      if (_cladMetal.preHeatToBondAndCut) {
        quoteLineItemToEdit.quoteLineItemDetail.PreHeatRequiredforCladding__c = _cladMetal.preHeatToBondAndCut;
        quoteLineItemToEdit.quoteLineItemDetail.PreheattoCut__c = _cladMetal.preHeatToBondAndCut;
      }
    }

    if(updatedQLD.cladMetalProposalType && (updatedQLD.cladMetalProposalType !== quoteLineItemData?.Proposal_Type_Clad__c || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Proposal_Type_Clad__c = updatedQLD.cladMetalProposalType;
      fieldsUpdated.push(QuoteLineItemValueTypes.CladMetalProposalType);
    }

    // base metal
    if(baseMetal && (baseMetal.name !== quoteLineItemData?.BaseMetal__c || !props.quoteLineItemToEdit)) {
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.BaseMetal, baseMetal);
      quoteLineItemToEdit.BaseMetal__c = baseMetal.name;
      quoteLineItemToEdit.quoteLineItemDetail.MetalClassBacker__c = baseMetal.classId;
      quoteLineItemToEdit.quoteLineItemDetail.NCBaseHeatTreatmentasSupplied__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.HeatTreatment, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.FinishedGoodHeatTreatment__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.HeatTreatment, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCBaseChemistryRestrictionsforBonding__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.ChemistryRequirements, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCMetallurgicReqForBase__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.MetallurgicalRequirements, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCBaseTestingRequirements__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.TestingRequirements, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCBaseSimulatedHeatTreatment__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.SimulatedHeatTreatment, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCBaseUTRequirement__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.UltrasonicInspection, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.PrecladBaseMetalUT__c = getMetalPurchasingRequirement(baseMetal, MetalPurchaseRequirementsGroups.UltrasonicInspection, manufacturingSite)?.defaultValue || PrecladBaseMetalUTDefaults.None;
      quoteLineItemToEdit.quoteLineItemDetail.PrecladBaseMetalUTType__c = getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.PrecladBaseMetalUTType)?.default || null;
      quoteLineItemToEdit.quoteLineItemDetail.NCEdgeSurfaceRequirement__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.EdgeSurfaceCondition, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCBaseCertificateRequired__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.Certification, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCBaseIsWeldRepairAllowed__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.WeldRepair, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.NCBaseMarkingRequired__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.Marking, manufacturingSite);
      quoteLineItemToEdit.quoteLineItemDetail.BaseMetalProductAnalysisRequired__c = getMetalPurchasingRequirements(baseMetal, MetalPurchaseRequirementsGroups.MetalProductAnalysis, manufacturingSite) || null;

      fieldsUpdated.push(QuoteLineItemValueTypes.BaseMetal);

      if (baseMetal.preHeatToBondAndCut) {
        quoteLineItemToEdit.quoteLineItemDetail.PreHeatRequiredforCladding__c = baseMetal.preHeatToBondAndCut;
        quoteLineItemToEdit.quoteLineItemDetail.PreheattoCut__c = baseMetal.preHeatToBondAndCut;
      }
    }

    if(updatedQLD.baseMetalProposalType && (updatedQLD.baseMetalProposalType !== quoteLineItemData?.Proposal_Type_Base__c || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Proposal_Type_Base__c = updatedQLD.baseMetalProposalType;
      fieldsUpdated.push(QuoteLineItemValueTypes.BaseMetalProposalType);
    }

    if(updatedQLD.baseMetalCustomerProvided !== quoteLineItemData?.Customer_Provided_Base__c || !props.quoteLineItemToEdit) {
      quoteLineItemToEdit.Customer_Provided_Base__c = updatedQLD.baseMetalCustomerProvided;
      fieldsUpdated.push(QuoteLineItemValueTypes.CustomerProvidedBase);

      quoteLineItemToEdit.quoteLineItemDetail.PurchaseBK__c = updatedQLD.baseMetalCustomerProvided;
    }

    if(updatedQLD.baseMetalCustomerProvided === true) {
      const baseCost = quoteLineItemData?.Base_Cost__c || quoteLineItemData?.Base_Cost_Customer_Supplied__c;
      const baseFreight = quoteLineItemData?.Base_Freight__c || quoteLineItemData?.Base_Freight_Customer_Supplied__c;

      quoteLineItemToEdit.Base_Cost_Customer_Supplied__c = baseCost;
      quoteLineItemToEdit.Base_Freight_Customer_Supplied__c = baseFreight;
    }

    if(updatedQLD.cladMetalCustomerProvided !== quoteLineItemData?.Customer_Provided_Clad__c || !props.quoteLineItemToEdit) {
      quoteLineItemToEdit.Customer_Provided_Clad__c = updatedQLD.cladMetalCustomerProvided;
      fieldsUpdated.push(QuoteLineItemValueTypes.CustomerProvidedClad);

      quoteLineItemToEdit.quoteLineItemDetail.PurchaseCL__c = updatedQLD.cladMetalCustomerProvided;
    }

    if(updatedQLD.cladMetalCustomerProvided === true) {
      const cladCost = quoteLineItemData?.Clad_Cost__c || quoteLineItemData?.Clad_Cost_Customer_Supplied__c;
      const cladFreight = quoteLineItemData?.Clad_Freight__c || quoteLineItemData?.Clad_Freight_Customer_Supplied__c;

      quoteLineItemToEdit.Clad_Cost_Customer_Supplied__c = cladCost;
      quoteLineItemToEdit.Clad_Freight_Customer_Supplied__c = cladFreight;
    }

    if(itemType && (itemType.name !== quoteLineItemData?.Item_Type__c || !props.quoteLineItemToEdit)) {
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.ItemType, itemType);
      quoteLineItemToEdit.Item_Type__c = itemType.name;
      quoteLineItemToEdit.Line_Item__c = itemType.lineItem;
      quoteLineItemToEdit.Product__c = itemType.product;

      if(itemType.name === FinishedProductTypes.Tubesheet) {
        quoteLineItemToEdit.quoteLineItemDetail.Pressflatten_1_c__c = false;
        quoteLineItemToEdit.quoteLineItemDetail.Presstubesheet__c = true;
      } else {
        quoteLineItemToEdit.quoteLineItemDetail.Pressflatten_1_c__c = true;
        quoteLineItemToEdit.quoteLineItemDetail.Presstubesheet__c = false;
      }

      const isHead = itemType.name === FinishedProductTypes.Head;
      const isCylinder = itemType.name === FinishedProductTypes.Cylinder;
      if(isHead || isCylinder) {
        const shapeToUse = isHead ? Shapes.Circle : Shapes.Rectangle;

        if((shapeToUse !== quoteLineItemData?.Shape__c || !props.quoteLineItemToEdit)) {
          quoteLineItemToEdit.Shape__c = shapeToUse;

          if(quoteLineItemData?.PlateMethod__c !== PlateMethods.ManuallySized) {
            quoteLineItemToEdit.PlateMethod__c = shapeToUse;
          }

          fieldsUpdated.push(QuoteLineItemValueTypes.Shape);
        }
      }

      fieldsUpdated.push(QuoteLineItemValueTypes.ItemType);
    }

    if(shape && (shape !== quoteLineItemData?.Shape__c || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Shape__c = shape;

      if(quoteLineItemData?.PlateMethod__c !== PlateMethods.ManuallySized) {
        quoteLineItemToEdit.PlateMethod__c = shape;
      }

      fieldsUpdated.push(QuoteLineItemValueTypes.Shape);
    }

    if(updatedQLD.finishedWidth && unitOfMeasure && (updatedQLD.finishedWidth !== (unitOfMeasure === MeasurementSystems.Metric ? quoteLineItemData?.Width_mm__c : quoteLineItemData?.Width_in__c) || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Width_in__c = calculateUOMValue(unitOfMeasure, MeasurementSystems.Imperial, updatedQLD.finishedWidth,  Units.Millimeters, Units.Inches);
      quoteLineItemToEdit.Width_mm__c = calculateUOMValue(unitOfMeasure, MeasurementSystems.Metric, updatedQLD.finishedWidth,  Units.Millimeters, Units.Inches);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.QuantityForLeftoverCalculation, updatedQLD.quantity);
      quoteLineItemToEdit.quoteLineItemDetail.Clads__c = null;

      fieldsUpdated.push(QuoteLineItemValueTypes.Width);
    }

    if(updatedQLD.finishedLength && unitOfMeasure && (updatedQLD.finishedLength !== (unitOfMeasure === MeasurementSystems.Metric ? quoteLineItemData?.Length_mm__c : quoteLineItemData?.Length_in__c) || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Length_in__c = calculateUOMValue(unitOfMeasure, MeasurementSystems.Imperial, updatedQLD.finishedLength,  Units.Millimeters, Units.Inches);
      quoteLineItemToEdit.Length_mm__c = calculateUOMValue(unitOfMeasure, MeasurementSystems.Metric, updatedQLD.finishedLength,  Units.Millimeters, Units.Inches);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.QuantityForLeftoverCalculation, updatedQLD.quantity);
      quoteLineItemToEdit.quoteLineItemDetail.Clads__c = null;

      fieldsUpdated.push(QuoteLineItemValueTypes.Length);
    }

    if(itemType?.name === FinishedProductTypes.Tubesheet && (updatedQLD.machining !== quoteLineItemData?.TubesheetMachining__c || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.TubesheetMachining__c = updatedQLD.machining;
      fieldsUpdated.push(QuoteLineItemValueTypes.TubesheetMachining);
    }

    if(updatedQLD.cutMethod && updatedQLD.cutMethod !== quoteLineItemData?.Cut_Method__c || !props.quoteLineItemToEdit) {
      quoteLineItemToEdit.Cut_Method__c = updatedQLD.cutMethod;
      fieldsUpdated.push(QuoteLineItemValueTypes.CutMethod);

      if(itemType?.name === FinishedProductTypes.Head) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.CutMethodHeadBlank__c = updatedQLD.cutMethod;
      }
    }

    if((itemType.name === FinishedProductTypes.Tubesheet || itemType.name === FinishedProductTypes.Head)
      && (updatedQLD.diameter !== quoteLineItemDetailData?.Diameter__c || !props.quoteLineItemToEdit)) {
        quoteLineItemToEdit.quoteLineItemDetail.Diameter__c = updatedQLD.diameter;
      fieldsUpdated.push(QuoteLineItemValueTypes.Diameter);
    }

    const qvcdescPreHeatMinThickness = unitOfMeasure === MeasurementSystems.Metric
      ? QuoteVariableConfigDescriptions.PreHeatReqMinThicknessMM
      : QuoteVariableConfigDescriptions.PreHeatReqMinThicknessIN;
    const qvcdescPreHeatMinTemp = unitOfMeasure === MeasurementSystems.Metric
      ? QuoteVariableConfigDescriptions.PreHeatReqMinTempC
      : QuoteVariableConfigDescriptions.PreHeatReqMinTempF;
    const minThicknessForPreHeat = getQuoteVariableConfiguration(quoteVariableConfigurations, qvcdescPreHeatMinThickness, manufacturingSite, FieldTypes.Integer) as number || 0;
    const minTemperatureForPreHeat = getQuoteVariableConfiguration(quoteVariableConfigurations, qvcdescPreHeatMinTemp, manufacturingSite, FieldTypes.Float) as number || 0;

    if(updatedQLD.finishedCladMetalThickness && unitOfMeasure && (updatedQLD.finishedCladMetalThickness !== (unitOfMeasure === MeasurementSystems.Metric ? quoteLineItemData?.Clad_TK_mm__c : quoteLineItemData?.Clad_TK_in__c) || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Clad_TK_in__c = calculateUOMValue(unitOfMeasure, MeasurementSystems.Imperial, updatedQLD.finishedCladMetalThickness,  Units.Millimeters, Units.Inches);
      quoteLineItemToEdit.Clad_TK_mm__c = calculateUOMValue(unitOfMeasure, MeasurementSystems.Metric, updatedQLD.finishedCladMetalThickness,  Units.Millimeters, Units.Inches);
      fieldsUpdated.push(QuoteLineItemValueTypes.FinishedCladTK);

      const thicknessCompareFrom = unitOfMeasure === MeasurementSystems.Metric
        ? quoteLineItemToEdit.Clad_TK_mm__c
        : quoteLineItemToEdit.Clad_TK_in__c;

      // if metal class is 1 AND dmc class is 1A/P AND thickness > 4
      if (
        _cladMetal.classId === MetalClasses.CarbonSteels
        && _cladMetal.dmcClass.match(/1A\/P/i)
        && thicknessCompareFrom >= minThicknessForPreHeat
        && !quoteLineItemData?.quoteLineItemDetail.PreHeatReqforcladdingMinTempforPreheat__c
      ) {
        quoteLineItemToEdit.quoteLineItemDetail.PreHeatRequiredforCladding__c = true;
        quoteLineItemToEdit.quoteLineItemDetail.PreHeatReqforcladdingMinTempforPreheat__c = minTemperatureForPreHeat;
      }
    }

    if(updatedQLD.cladMetalType && (updatedQLD.cladMetalType !== quoteLineItemData?.Clad_Type__c || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Clad_Type__c = updatedQLD.cladMetalType;
      fieldsUpdated.push(QuoteLineItemValueTypes.CladType);
    }

    if(updatedQLD.finishedBaseMetalThickness && unitOfMeasure && (updatedQLD.finishedBaseMetalThickness !== (unitOfMeasure === MeasurementSystems.Metric ? quoteLineItemData?.Base_TK_mm__c : quoteLineItemData?.Base_TK_in__c) || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Base_TK_in__c = calculateUOMValue(unitOfMeasure, MeasurementSystems.Imperial, updatedQLD.finishedBaseMetalThickness,  Units.Millimeters, Units.Inches);
      quoteLineItemToEdit.Base_TK_mm__c = calculateUOMValue(unitOfMeasure, MeasurementSystems.Metric, updatedQLD.finishedBaseMetalThickness,  Units.Millimeters, Units.Inches);
      fieldsUpdated.push(QuoteLineItemValueTypes.FinishedBaseTK);

      const thicknessCompareFrom = unitOfMeasure === MeasurementSystems.Metric
        ? quoteLineItemToEdit.Base_TK_mm__c
        : quoteLineItemToEdit.Base_TK_in__c;

      // if metal class is 1 AND dmc class is 1A/P AND thickness > 4
      if (
        baseMetal
        && baseMetal.classId === MetalClasses.CarbonSteels
        && baseMetal.dmcClass.match(/1A\/P/i)
        && thicknessCompareFrom >= minThicknessForPreHeat
        && !quoteLineItemData?.quoteLineItemDetail.PreHeatReqforcladdingMinTempforPreheat__c
      ) {
        quoteLineItemToEdit.quoteLineItemDetail.PreHeatRequiredforCladding__c = true;
        quoteLineItemToEdit.quoteLineItemDetail.PreHeatReqforcladdingMinTempforPreheat__c = minTemperatureForPreHeat;
      }
    }

    if(updatedQLD.baseMetalType && (updatedQLD.baseMetalType !== quoteLineItemData?.Base_Type__c || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Base_Type__c = updatedQLD.baseMetalType;
      fieldsUpdated.push(QuoteLineItemValueTypes.BaseType);
    }

    if(updatedQLD.specification && (updatedQLD.specification !== quoteLineItemData?.Specification__c || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Specification__c = updatedQLD.specification;

      const cladProductionSpecificationDefaultValue = quoteLineItemToEdit.Specification__c && quoteLineItemToEdit.Specification__c !== CladProductionSpecifications.DMC100
      ? `${quoteLineItemToEdit.Specification__c} & DMC100`
      : quoteLineItemToEdit.Specification__c;

      quoteLineItemToEdit.quoteLineItemDetail.CladProductSpecification__c = cladProductionSpecificationDefaultValue;

      fieldsUpdated.push(QuoteLineItemValueTypes.Specification);
    }

    if(updatedQLD.certificate && (updatedQLD.certificate !== quoteLineItemData?.Certificate__c || !props.quoteLineItemToEdit)) {
      quoteLineItemToEdit.Certificate__c = updatedQLD.certificate;
      fieldsUpdated.push(QuoteLineItemValueTypes.Certificate);
    }

    if(baseMetalUT && (baseMetalUT.option !== quoteLineItemData?.Base_Metal_UT__c || !props.quoteLineItemToEdit)) {
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.BaseMetalUT, baseMetalUT);
      quoteLineItemToEdit.Base_Metal_UT__c = baseMetalUT.option;
      quoteLineItemToEdit.quoteLineItemDetail.PostCladBaseMetalUTSpecification__c = baseMetalUT.option;
      quoteLineItemToEdit.quoteLineItemDetail.PrecladBaseMetalUT__c = baseMetalUT.option;

      // if base metal ut is none set preclad base metal ut to none as well
      if (baseMetalUT.option === 'None') {
        quoteLineItemToEdit.quoteLineItemDetail.PrecladBaseMetalUTType__c = null;
      }

      fieldsUpdated.push(QuoteLineItemValueTypes.BaseMetalUT);
    }

    if(updatedQLD.bondUT && (updatedQLD.bondUT !== quoteLineItemData?.Bond_Metal_UT__c || !props.quoteLineItemToEdit)) {
      const cladProductionSpecs = quoteLineItemToEdit.quoteLineItemDetail.CladProductSpecification__c;
      const cladProductSpecsLabel = cladProductionSpecs?.split('&')[0].trim();

      quoteLineItemToEdit.Bond_Metal_UT__c = updatedQLD.bondUT;
      quoteLineItemToEdit.quoteLineItemDetail.PostCladBondUTSpecification__c = `${cladProductSpecsLabel}: ${updatedQLD.bondUT}`;
      fieldsUpdated.push(QuoteLineItemValueTypes.BondMetalUT);
    }

    if(!arraysEqual(updatedQLD.postCladTestingOptions, quoteLineItemData?.Post_Clad_Testing_Options__c?.split(';') || []) || !props.quoteLineItemToEdit) {

      if(updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.Shear)) {
        const configDescription = isMetricUOM
        ? QuoteVariableConfigDescriptions.OERTestingShearTestingPressureMPA
        : QuoteVariableConfigDescriptions.OERTestingShearTestingPressurePSI;

        quoteLineItemToEdit.quoteLineItemDetail.ShearTestValue__c = getQuoteVariableConfiguration(quoteVariableConfigurations, configDescription, manufacturingSite, FieldTypes.Integer) as number;
        quoteLineItemToEdit.quoteLineItemDetail.ShearTestUOM__c = getQuoteVariableConfigurationUOM(quoteVariableConfigurations, configDescription, manufacturingSite);
      } else {
        quoteLineItemToEdit.quoteLineItemDetail.ShearTestValue__c = null;
        quoteLineItemToEdit.quoteLineItemDetail.ShearTestUOM__c = null;
      }

      if(updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.SA26345BondStrength)) {
        quoteLineItemToEdit.quoteLineItemDetail.MachineBondStrengthBendsperSA26345__c = true;
      } else {
        quoteLineItemToEdit.quoteLineItemDetail.MachineBondStrengthBendsperSA26345__c = false;
      }

      if(updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.ImpactLCVN) || updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.ImpactTCVN)) {
        quoteLineItemToEdit.quoteLineItemDetail.CustomerSpecifiedImpactTesting__c = true;
      } else {
        quoteLineItemToEdit.quoteLineItemDetail.CustomerSpecifiedImpactTesting__c = false;
      }

      if(updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.ImpactLCVN) && !updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.ImpactTCVN)) {
        quoteLineItemToEdit.quoteLineItemDetail.CustomerSpecifiedImpactTestDirection__c = CustomerSpecifiedImpactTestDirections.Longitudinal;
      } else if(!updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.ImpactLCVN) && updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.ImpactTCVN)) {
        quoteLineItemToEdit.quoteLineItemDetail.CustomerSpecifiedImpactTestDirection__c = CustomerSpecifiedImpactTestDirections.Transverse;
      } else if(updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.ImpactLCVN) && updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.ImpactTCVN)) {
        quoteLineItemToEdit.quoteLineItemDetail.CustomerSpecifiedImpactTestDirection__c = CustomerSpecifiedImpactTestDirections.Both;
      } else {
        quoteLineItemToEdit.quoteLineItemDetail.CustomerSpecifiedImpactTestDirection__c = null;
      }

      if(updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.B898Bend)) {
        quoteLineItemToEdit.quoteLineItemDetail.BendB898__c = true;
      } else {
        quoteLineItemToEdit.quoteLineItemDetail.BendB898__c = false;
      }

      if(updatedQLD.postCladTestingOptions.includes(PostCladTestingOptions.ADW8Bend)) {
        quoteLineItemToEdit.quoteLineItemDetail.ADW8Bend__c = true;
      } else {
        quoteLineItemToEdit.quoteLineItemDetail.ADW8Bend__c = false;
      }

      if (updatedQLD.postCladTestingOptions.includes('Side Bend')) {
        updatedQLD.postCladTestingOptions = updatedQLD.postCladTestingOptions.filter(a => a !== 'Side Bend');
        quoteLineItemToEdit.quoteLineItemDetail.MachineSideBends__c = true;
      } else {
        quoteLineItemToEdit.quoteLineItemDetail.MachineSideBends__c = false;
      }

      quoteLineItemToEdit.Post_Clad_Testing_Options__c = updatedQLD.postCladTestingOptions.length ? updatedQLD.postCladTestingOptions.join(';') : null;
      fieldsUpdated.push(QuoteLineItemValueTypes.PostCladTestingOptions);
    }

    if(!arraysEqual(updatedQLD.additionalOptions, quoteLineItemData?.Additional_Options__c?.split(';') || []) || !props.quoteLineItemToEdit) {
      quoteLineItemToEdit.Additional_Options__c = updatedQLD.additionalOptions.join(';');
      fieldsUpdated.push(QuoteLineItemValueTypes.AdditionalOptions);

      if(((updatedQLD.additionalOptions || []).includes(QuoteLineItemAdditionalOptions.CosmeticGrinding) && !quoteLineItemData?.Additional_Options__c?.split(';').includes(QuoteLineItemAdditionalOptions.CosmeticGrinding)) ||
      (!(updatedQLD.additionalOptions || []).includes(QuoteLineItemAdditionalOptions.CosmeticGrinding) && quoteLineItemData?.Additional_Options__c?.split(';').includes(QuoteLineItemAdditionalOptions.CosmeticGrinding))) {

        dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.CosmeticGrinding, (updatedQLD.additionalOptions || []).includes(QuoteLineItemAdditionalOptions.CosmeticGrinding));
        fieldsUpdated.push(QuoteLineItemValueTypes.CosmeticGrinding);
      }
    }

    if(updatedQLD.cosmeticGrindingSpecifications !== quoteLineItemData?.Cosmetic_Grinding_Specifications__c || !props.quoteLineItemToEdit) {
      quoteLineItemToEdit.Cosmetic_Grinding_Specifications__c = updatedQLD.cosmeticGrindingSpecifications;
      quoteLineItemToEdit.quoteLineItemDetail.SurfaceTreatmentNotes__c = updatedQLD.cosmeticGrindingSpecifications;

      fieldsUpdated.push(QuoteLineItemValueTypes.CosmeticGrindingSpecifications);
    }

    if(itemType.name === FinishedProductTypes.Head || itemType.name === FinishedProductTypes.HeadSegmental) {
      if (updatedQLD.subcontractHeadForming !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.SubcontractHeadForming__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.SubcontractHeadForming__c = updatedQLD.subcontractHeadForming;
        fieldsUpdated.push(QuoteLineItemValueTypes.SubcontractHeadForming);
      }

      if(updatedQLD.headFormingType !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.HeadFormingType__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.HeadFormingType__c = updatedQLD.headFormingType;
        fieldsUpdated.push(QuoteLineItemValueTypes.HeadFormingType);
      }

      if(updatedQLD.headDiameter !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.HeadDiameter__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.HeadDiameter__c = updatedQLD.headDiameter;
        fieldsUpdated.push(QuoteLineItemValueTypes.HeadDiameter);
      }

      if(updatedQLD.idOrOd !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.IDorOD__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.IDorOD__c = updatedQLD.idOrOd;
        fieldsUpdated.push(QuoteLineItemValueTypes.IDorOD);
      }

      if(updatedQLD.straightFlange !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.StraightFlange__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.StraightFlange__c = updatedQLD.straightFlange;
        fieldsUpdated.push(QuoteLineItemValueTypes.StraightFlange);
      }

      if(updatedQLD.headEdgePreparation !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.HeadEdgePreparation__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.HeadEdgePreparation__c = updatedQLD.headEdgePreparation;
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.OpenEnd__c = updatedQLD.headEdgePreparation;
        fieldsUpdated.push(QuoteLineItemValueTypes.HeadEdgePreparation);
      }

      if(updatedQLD.formingCostPerHead !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.HeadFormingCostPerHead__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.HeadFormingCostPerHead__c = updatedQLD.formingCostPerHead;
        fieldsUpdated.push(QuoteLineItemValueTypes.HeadFormingCostPerHead);
      }

      if(updatedQLD.headFreightZone !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.HeadFreightZone__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.HeadFreightZone__c = updatedQLD.headFreightZone;
        fieldsUpdated.push(QuoteLineItemValueTypes.HeadFreightZone);
      }

      if(updatedQLD.headFreightChargeToCustomerPerUOM && unitOfMeasure && (updatedQLD.headFreightChargeToCustomerPerUOM !== (unitOfMeasure === MeasurementSystems.Metric ? quoteLineItemData?.Total_Freight_KG__c : quoteLineItemData?.Total_Freight_LB__c) || !props.quoteLineItemToEdit)) {
        quoteLineItemToEdit.Total_Freight_LB__c = calculateUOMValue(unitOfMeasure, MeasurementSystems.Imperial, updatedQLD.headFreightChargeToCustomerPerUOM,  Units.PricePerKilogram, Units.PricePerPound);
        quoteLineItemToEdit.Total_Freight_KG__c = calculateUOMValue(unitOfMeasure, MeasurementSystems.Metric, updatedQLD.headFreightChargeToCustomerPerUOM,  Units.PricePerKilogram, Units.PricePerPound);
        fieldsUpdated.push(QuoteLineItemValueTypes.TotalFreightPerWeight);
      }

      if(updatedQLD.shipHeadBlankToFormer !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.HeadTimeToFormer__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.HeadTimeToFormer__c = updatedQLD.shipHeadBlankToFormer;
        fieldsUpdated.push(QuoteLineItemValueTypes.HeadTimeToFormer);
      }

      if(updatedQLD.timeNeededToFormHead !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.HeadTimeToFormHead__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.HeadTimeToFormHead__c = updatedQLD.timeNeededToFormHead;
        fieldsUpdated.push(QuoteLineItemValueTypes.HeadTimeToFormHead);
      }

      if(updatedQLD.blankSize !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.BlankSize__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.BlankSize__c = updatedQLD.blankSize;
        fieldsUpdated.push(QuoteLineItemValueTypes.BlankSize);
        dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.QuantityForLeftoverCalculation, updatedQLD.quantity);
        quoteLineItemToEdit.quoteLineItemDetail.Clads__c = null;
      }

      if(updatedQLD.isTwoPieceHead !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.CreateTwoPieceHead__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.CreateTwoPieceHead__c = updatedQLD.isTwoPieceHead;
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.HeadNumberOfPieces__c = !updatedQLD.isTwoPieceHead ? 1 : 2;
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.NumberOfPiecesInHead__c = updatedQLD.isTwoPieceHead ? NumberOfPiecesInHeadOptions.TwoPieceHead : NumberOfPiecesInHeadOptions.SinglePieceHead;

        fieldsUpdated.push(QuoteLineItemValueTypes.CreateTwoPieceHead);
        dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.QuantityForLeftoverCalculation, updatedQLD.quantity);
      }

      if(updatedQLD.openDiameter !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.OpenEndDiameter__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.OpenEndDiameter__c = updatedQLD.openDiameter;
        fieldsUpdated.push(QuoteLineItemValueTypes.OpenEndDiameter);
      }

      if(updatedQLD.insideDishRadius !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.InsideDishRadius__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.InsideDishRadius__c = updatedQLD.insideDishRadius;
        fieldsUpdated.push(QuoteLineItemValueTypes.InsideDishRadius);
      }

      if(updatedQLD.cutback !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.Cutback__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.Cutback__c = updatedQLD.cutback;
        fieldsUpdated.push(QuoteLineItemValueTypes.Cutback);
      }

      if(updatedQLD.insideKnuckleRadius !== quoteLineItemDetailData?.NC_Head_Item_Detail__r.InsideKnuckleRadius__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.InsideKnuckleRadius__c = updatedQLD.insideKnuckleRadius;
        fieldsUpdated.push(QuoteLineItemValueTypes.InsideKnuckleRadius);
      }

      if (!quoteLineItemDetailData?.NC_Head_Item_Detail__r.NobelCladProcedure__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.NobelCladProcedure__c = getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NobelCladProcedure)?.options[0];
        fieldsUpdated.push(QuoteLineItemValueTypes.NobelCladProcedure);
      }

      if (!quoteLineItemDetailData?.NC_Head_Item_Detail__r.NCChemistryRestrictionsForBonding__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Head_Item_Detail__r.NCChemistryRestrictionsForBonding__c = getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NCChemistryRestrictionsForBonding)?.options[0];
        fieldsUpdated.push(QuoteLineItemValueTypes.NobelCladProcedure);
      }
    }

    if(itemType.name === FinishedProductTypes.Cylinder) {
      if(updatedQLD.cylinderType !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.CylinderType__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.CylinderType__c = updatedQLD.cylinderType;
        fieldsUpdated.push(QuoteLineItemValueTypes.CylinderType);
      }

      if(updatedQLD.cylinderIdOrOd !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.IDorOD__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.IDorOD__c = updatedQLD.cylinderIdOrOd;
        fieldsUpdated.push(QuoteLineItemValueTypes.CylinderIDorOD);
      }

      if(updatedQLD.cylinderDiameter !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.Diameter__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.Diameter__c = updatedQLD.cylinderDiameter;
        fieldsUpdated.push(QuoteLineItemValueTypes.CylinderDiameter);
      }

      if(updatedQLD.cylinderLength !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.CylinderLength__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.CylinderLength__c = updatedQLD.cylinderLength;
        fieldsUpdated.push(QuoteLineItemValueTypes.CylinderLength);
      }

      if(updatedQLD.cylinderEdgePreparation !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.EdgePreparation__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.EdgePreparation__c = updatedQLD.cylinderEdgePreparation;
        fieldsUpdated.push(QuoteLineItemValueTypes.CylinderEdgePreparation);
      }

      if(updatedQLD.rollingCostPerCylinder !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.RollingCostPerCylinder__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.RollingCostPerCylinder__c = updatedQLD.rollingCostPerCylinder;
        fieldsUpdated.push(QuoteLineItemValueTypes.RollingCostPerCylinder);
      }

      if(updatedQLD.cylinderFreightChargeToCustomerPerUOM !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.FreightChargeToCustomerPer__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.FreightChargeToCustomerPer__c = updatedQLD.cylinderFreightChargeToCustomerPerUOM;
        fieldsUpdated.push(QuoteLineItemValueTypes.FreightChargeToCustomerPer);
      }

      if(updatedQLD.freightChargeToRollerPerUOM !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.FreightChargeToRollerPer__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.FreightChargeToRollerPer__c = updatedQLD.freightChargeToRollerPerUOM;
        fieldsUpdated.push(QuoteLineItemValueTypes.FreightChargeToRollerPer);
      }

      if(updatedQLD.transitTimeToRoller !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.TransitTimeToRollerWeeks__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.TransitTimeToRollerWeeks__c = updatedQLD.transitTimeToRoller;
        fieldsUpdated.push(QuoteLineItemValueTypes.TransitTimeToRollerWeeks);
      }

      if(updatedQLD.timeToRoll !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.TimeToRollWeeks__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.TimeToRollWeeks__c = updatedQLD.timeToRoll;
        fieldsUpdated.push(QuoteLineItemValueTypes.TimeToRollWeeks);
      }

      if(updatedQLD.notesPrintQuoteToForm !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.CylinderNotes__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.CylinderNotes__c = updatedQLD.notesPrintQuoteToForm;
        fieldsUpdated.push(QuoteLineItemValueTypes.CylinderNotes);
      }

      if(updatedQLD.quantityPerCylinder !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.QuantityPerCylinder__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.QuantityPerCylinder__c = updatedQLD.quantityPerCylinder;
        fieldsUpdated.push(QuoteLineItemValueTypes.QuantityPerCylinder);
      }

      if(updatedQLD.plateSizesSupplyFormerWidth !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.PlateWidthSupplyToFormer__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.PlateWidthSupplyToFormer__c = updatedQLD.plateSizesSupplyFormerWidth;
        fieldsUpdated.push(QuoteLineItemValueTypes.PlateWidthSupplyToFormer);
      }

      if(updatedQLD.plateSizesSupplyFormerLength !== quoteLineItemDetailData?.NC_Cylinder_Item_Details__r.PlateLengthSupplyToFormer__c || !props.quoteLineItemToEdit) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Cylinder_Item_Details__r.PlateLengthSupplyToFormer__c = updatedQLD.plateSizesSupplyFormerLength;
        fieldsUpdated.push(QuoteLineItemValueTypes.PlateLengthSupplyToFormer);
      }
    }

    if(updatedQLD.isDncSelected !== quoteLineItemData?.DNC__c || !props.quoteLineItemToEdit) {
      quoteLineItemToEdit.DNC__c = updatedQLD.isDncSelected;
      fieldsUpdated.push(QuoteLineItemValueTypes.DNC);
    }

    if(updatedQLD.isPiecesSelected !== quoteLineItemData?.Pieces__c || !props.quoteLineItemToEdit) {
      quoteLineItemToEdit.Pieces__c = updatedQLD.isPiecesSelected;
      fieldsUpdated.push(QuoteLineItemValueTypes.Pieces);
    }

    //reruns heat treatment dependencies after add and duplicate
    if(lineToDuplicate && quoteLineItemData?.Additional_Options__c.includes(QuoteLineItemAdditionalOptions.HeatTreatment)) {
      fieldsUpdated.push(QuoteLineItemValueTypes.HeatTreatment);
    }

    // if edit, it should get it from the dependent unwritten fields map
    if (
      updatedQLD.additionalOptions.includes(QuoteLineItemAdditionalOptions.HeatTreatment)
      !== quoteLineItemData?.Additional_Options__c?.includes(QuoteLineItemAdditionalOptions.HeatTreatment)
      && props.quoteLineItemToEdit
    ) {
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.HeatTreatment, updatedQLD.additionalOptions.includes(QuoteLineItemAdditionalOptions.HeatTreatment));

      // trigger dependency update
      fieldsUpdated.push(QuoteLineItemValueTypes.HeatTreatment);
    }

    if(manufacturingSite === ManufacturingSites.DE01) {
      const heatTreatmentRequired = (updatedQLD.additionalOptions || []).includes(QuoteLineItemAdditionalOptions.HeatTreatment);

      if(heatTreatmentRequired) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTDescriptionOfEvent__c = OutsideServices.PostbondHeatTreatment;
        quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTSort__c = 1;

      } else {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTDescriptionOfEvent__c = null;
        quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTSort__c = null;
      }

      if(updatedQLD.cutMethod === CutMethods.WaterJetCut) {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingDescriptionOfEvent__c = OutsideServices.WaterjetCutting;
        quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingSort__c = !heatTreatmentRequired ? 1 : 2;
      } else {
        quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingDescriptionOfEvent__c = null;
        quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingSort__c = null;
      }

      quoteLineItemToEdit.quoteLineItemDetail.StressReliefHT__c = false;
    }

    //Removing this, but keeping in case
    // const isSubContractHeatTreatment = (updatedQLD.additionalOptions || []).includes(QuoteLineItemAdditionalOptions.HeatTreatment);
    // isSubContractHeatTreatment ? quoteLineItemToEdit.quoteLineItemDetail.SubcontractHeatTreatment__c = true : null;

    if(quoteLineItemData?.quoteLineItemDetail?.CertificationTypeRequired__c || !props.quoteLineItemToEdit) {
      quoteLineItemToEdit.quoteLineItemDetail.CertificationTypeRequired__c = getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CertificationTypeRequired).options[0];
      fieldsUpdated.push(QuoteLineItemValueTypes.CertificationTypeRequired);
    }

    if(updatedQLD.certificate && !props.quoteLineItemToEdit) {
      quoteLineItemToEdit.quoteLineItemDetail.CertificationTypeRequired__c = updatedQLD.certificate;
      fieldsUpdated.push(QuoteLineItemValueTypes.CertificationTypeRequired);
    }

    if(lineFieldsToUpdate.subcontractCylinderRolling !== quoteLineItemDetailData?.SubcontractCylinderrolling__c || !props.quoteLineItemToEdit) {
      quoteLineItemToEdit.quoteLineItemDetail.SubcontractCylinderrolling__c = lineFieldsToUpdate.subcontractCylinderRolling;
    }

    const hasCosmeticGrinding = updatedQLD.additionalOptions.includes(QuoteLineItemAdditionalOptions.CosmeticGrinding);

    if (!quoteLineItemDetailData?.SurfaceTreatment__c || !props.quoteLineItemToEdit) {
      const defaultSurfaceTreatment = hasCosmeticGrinding
        ? DefaultSurfaceTreatmentSelections.CosmeticGrinding
        : DefaultSurfaceTreatmentSelections.Standard;

      quoteLineItemToEdit.quoteLineItemDetail.SurfaceTreatment__c = defaultSurfaceTreatment;
    }

    if (!quoteLineItemDetailData?.Machining__c || !props.quoteLineItemToEdit) {
      const defaultMachining = getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.Machining)?.options?.[0];
      quoteLineItemToEdit.quoteLineItemDetail.Machining__c = defaultMachining;
    }

    if (updatedQLD.MultiShotCount__c && !props.quoteLineItemToEdit) {
      const msDetails = quoteLineItemToEdit.quoteLineItemDetail.NC_Multi_Shot_Item_Details__r;
      msDetails.length > 0 &&  console.log('msDetails: ',msDetails);
    }

    return quoteLineItemToEdit;
  };

  return (
    <div className="popover add-quote-item">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='title' data-testid={`QAAddQuoteItemPopover${!props.quoteLineItemToEdit ? 'Add' : 'Edit'}ItemHeader`}>{title}</IonLabel>
      </div>
      <hr/>
      <div className='selections'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                selectionLabel='Quantity'
                placeholder='Quantity'
                regex={Regexes.IntegerAboveZero}
                min='1'
                style={{ width: '53px', marginRight: '25px' }}
                onBlur={(quantity) => {
                  setQuantity(+quantity);
                }}
                value={quantity}
              />
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                selectionLabel='Units of Measure'
                options={[MeasurementSystems.Imperial, MeasurementSystems.Metric]}
                onIonChange={(unitOfMeasure) => {
                  setUnitOfMeasure(unitOfMeasure);
                }}
                value={unitOfMeasure}
                skipTab={true}
              />
            </IonCol>
          </IonRow>
          {!isMultiShot && (<>
           <IonRow>
            <IonCol class='flex-column'>
              <SelectionContainer
                type={SelectionTypes.SearchableDropdown}
                selectionLabel='Clad Metal'
                noneFoundText='No clad metals found'
                placeholder='Select clad metal'
                options={rawMaterials.filter(rawMaterial => rawMaterial.usage === RawMaterialUsage.Clad && rawMaterial.dataAreaId.includes(manufacturingSite))}
                suggestionDisplayFields={[SearchFields.MetalName]}
                onChange={(cladMetal) => {
                  try {
                    handleMetalChange(cladMetal, RawMaterialUsage.Clad);
                  } catch (error) {
                    console.error(error);
                  }
                }}
                value={cladMetal?.name}
              />
              <CheckboxItem
                label='Customer Provided'
                style={{ width: 'fit-content', marginTop: '4px' }}
                onIonChange={(cladMetalCustomerProvided) => setCladMetalCustomerProvided(cladMetalCustomerProvided)}
                checked={cladMetalCustomerProvided}
                skipTab={true}
              />
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                selectionLabel='Proposal Type'
                placeholder='Select type'
                options={[...new Set(cladMetal?.metalPricing[manufacturingSite]?.map((metalPricing) => metalPricing.proposalType))]}
                style={{ width: '124px' }}
                onChange={(cladMetalProposalType) => setCladMetalProposalType(cladMetalProposalType.label)}
                value={cladMetalProposalType}
                isDisabled={!cladMetal}
              />
            </IonCol>
            <IonCol class='flex-column border-left'>
              <SelectionContainer
                type={SelectionTypes.SearchableDropdown}
                selectionLabel='Base Metal'
                noneFoundText='No base metals found'
                placeholder='Select base metal'
                options={rawMaterials.filter(rawMaterial => rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite))}
                suggestionDisplayFields={[SearchFields.MetalName]}
                onChange={(baseMetal) => {
                  try {
                    handleMetalChange(baseMetal, RawMaterialUsage.Base);
                  } catch (error) {
                    console.error(error);
                  }
                }}
                value={baseMetal?.name}
              />
              <CheckboxItem
                label='Customer Provided'
                style={{ width: 'fit-content', marginTop: '4px' }}
                onIonChange={(baseMetalCustomerProvided) => setBaseMetalCustomerProvided(baseMetalCustomerProvided)}
                checked={baseMetalCustomerProvided}
                skipTab={true}
              />
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                selectionLabel='Proposal Type'
                placeholder='Select type'
                options={[...new Set(baseMetal?.metalPricing[manufacturingSite]?.map((metalPricing) => metalPricing.proposalType))]}
                style={{ width: '124px' }}
                onChange={(baseMetalProposalType) => setBaseMetalProposalType(baseMetalProposalType.label)}
                value={baseMetalProposalType}
                isDisabled={!baseMetal}
              />
            </IonCol>
          </IonRow>
          </>)}
        </IonGrid>
      </div>
      <BottomSelections
        activeTab={activeTab}
        additionalOptions={additionalOptions}
        additionaOptionsSelectionHandler={additionaOptionsSelectionHandler}
        baseMetalType={baseMetalType}
        baseMetalTypeOptions={baseMetalTypeOptions}
        baseMetalUT={baseMetalUT}
        baseMetalUTChangeHandler={baseMetalUTChangeHandler}
        baseUTSelectionOptions={baseUTSelectionOptions}
        blankSize={blankSize}
        bondUT={bondUT}
        bondUTChangeHandler={bondUTChangeHandler}
        bondUTSelectionOptions={bondUTSelectionOptions}
        certificate={certificate}
        certSelectionOptions={certSelectionOptions}
        cladMetalType={cladMetalType}
        cladMetalTypeOptions={cladMetalTypeOptions}
        cosmeticGrindingSpecifications={cosmeticGrindingSpecifications}
        crownPlateDiameter={crownPlateDiameter}
        cutback={cutback}
        cutMethod={cutMethod}
        cutMethodInputOptions={cutMethodInputOptions}
        cylinderDiameter={cylinderDiameter}
        cylinderDiameterOptions={cylinderDiameterOptions}
        cylinderEdgePreparation={cylinderEdgePreparation}
        cylinderFreightChargeToCustomerPerUOM={cylinderFreightChargeToCustomerPerUOM}
        cylinderIdOrOd={cylinderIdOrOd}
        cylinderLength={cylinderLength}
        cylinderType={cylinderType}
        disableTwoPieceHeadCheckbox={disableTwoPieceHeadCheckbox}
        finishedBaseMetalThickness={finishedBaseMetalThickness}
        finishedCladMetalThickness={finishedCladMetalThickness}
        finishedLength={finishedLength}
        finishedWidth={finishedWidth}
        formingCostPerHead={formingCostPerHead}
        freightChargeToRollerPerUOM={freightChargeToRollerPerUOM}
        freightOptions={freightOptions}
        headDiameter={headDiameter}
        headEdgePreparation={headEdgePreparation}
        headFormingType={headFormingType}
        headFreightZone={headFreightZone}
        idOrOd={idOrOd}
        insideDishRadius={insideDishRadius}
        insideKnuckleRadius={insideKnuckleRadius}
        cladMetal={cladMetal}
        baseMetal={baseMetal}
        isCosmeticGrindingSpecDisabled={isCosmeticGrindingSpecDisabled}
        isDarkMode={darkMode}
        isDncSelected={isDncSelected}
        isMultiShot={isMultiShot}
        isPiecesSelected={isPiecesSelected}
        isTwoPieceHead={isTwoPieceHead}
        itemType={itemType}
        itemTypeHandler={itemTypeHandler}
        lengthOfWelds={lengthOfWelds}
        machining={machining}
        manufacturingSite={manufacturingSite}
        multiShotMetalsStateRef={multiShotMetalsStateRef}
        multiShotType={multiShotType}
        notesPrintQuoteToForm={notesPrintQuoteToForm}
        numberOfPetals={numberOfPetals}
        openDiameter={openDiameter}
        plateSizesSupplyFormerLength={plateSizesSupplyFormerLength}
        plateSizesSupplyFormerWidth={plateSizesSupplyFormerWidth}
        postCladTestingOptions={postCladTestingOptions}
        postCladTestingOptionsChangeHandler={postCladTestingOptionsChangeHandler}
        postCladTestingSelectionOptions={postCladTestingSelectionOptions}
        quantityPerCylinder={quantityPerCylinder}
        rollingCostPerCylinder={rollingCostPerCylinder}
        setActiveTab={setActiveTab}
        setBaseMetalType={setBaseMetalType}
        setBlankSize={setBlankSize}
        setCertificate={setCertificate}
        setCladMetalType={setCladMetalType}
        setCosmeticGrindingSpecifications={setCosmeticGrindingSpecifications}
        setCrownPlateDiameter={setCrownPlateDiameter}
        setCutback={setCutback}
        setCutMethod={setCutMethod}
        setCylinderDiameter={setCylinderDiameter}
        setCylinderEdgePreparation={setCylinderEdgePreparation}
        setCylinderFreightChargeToCustomerPerUOM={setCylinderFreightChargeToCustomerPerUOM}
        setCylinderIdOrOd={setCylinderIdOrOd}
        setCylinderLength={setCylinderLength}
        setCylinderType={setCylinderType}
        setDiameter={setDiameter}
        setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
        setFinishedLength={setFinishedLength}
        setFinishedWidth={setFinishedWidth}
        setFinishedCladMetalThickness={setFinishedCladMetalThickness}
        setFormingCostPerHead={setFormingCostPerHead}
        setFreightChargeToRollerPerUOM={setFreightChargeToRollerPerUOM}
        setHeadDiameter={setHeadDiameter}
        setHeadEdgePreparation={setHeadEdgePreparation}
        setHeadFormingType={setHeadFormingType}
        setHeadFreightZone={setHeadFreightZone}
        setIdOrOd={setIdOrOd}
        setInsideDishRadius={setInsideDishRadius}
        setInsideKnuckleRadius={setInsideKnuckleRadius}
        setIsDncSelected={setIsDncSelected}
        setIsPiecesSelected={setIsPiecesSelected}
        setLengthOfWelds={setLengthOfWelds}
        setMachining={setMachining}
        setMultiShotType={setMultiShotType}
        setNotesPrintQuoteToForm={setNotesPrintQuoteToForm}
        setNumberOfPetals={setNumberOfPetals}
        setOpenDiameter={setOpenDiameter}
        setPlateSizesSupplyFormerLength={setPlateSizesSupplyFormerLength}
        setPlateSizesSupplyFormerWidth={setPlateSizesSupplyFormerWidth}
        setQuantityPerCylinder={setQuantityPerCylinder}
        setRollingCostPerCylinder={setRollingCostPerCylinder}
        setShape={setShape}
        setShipHeadBlankToFormer={setShipHeadBlankToFormer}
        setStraightFlange={setStraightFlange}
        setTimeNeededToFormHead={setTimeNeededToFormHead}
        setTimeToRoll={setTimeToRoll}
        setTransitTimeToRoller={setTransitTimeToRoller}
        shape={shape}
        handleMetalChange={handleMetalChange}
        setBaseMetalProposalType={setBaseMetalProposalType}
        setCladMetalProposalType={setCladMetalProposalType}
        shapeOptions={shapeOptions}
        shipHeadBlankToFormer={shipHeadBlankToFormer}
        specChangeHandler={specChangeHandler}
        specification={specification}
        specTestSelectionOptions={specTestSelectionOptions}
        straightFlange={straightFlange}
        itemTypeOptions={itemTypeOptions}
        timeNeededToFormHead={timeNeededToFormHead}
        timeToRoll={timeToRoll}
        transitTimeToRoller={transitTimeToRoller}
        twoPieceHandler={twoPieceHandler}
        unitOfMeasure={unitOfMeasure}
        updatingPostCladTestingOptions={updatingPostCladTestingOptions}
        headFreightChargeToCustomerPerUOM={headFreightChargeToCustomerPerUOM}
        setHeadFreightChargeToCustomerPerUOM={setHeadFreightChargeToCustomerPerUOM}
        setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
        setCladMetalCustomerProvided={setCladMetalCustomerProvided}
      />
      <hr className='bottom-rule'/>
      <div className="cta-button-container">
      {!props.quoteLineItemToEdit && !isMultiShot && (
          <IonButton
            color="primary"
            class="add-button"
            data-testid={`QAAddQuoteItemPopover${!props.quoteLineItemToEdit ? 'AddItem' : 'Save'}Button`}
            onClick={() => {
              try {
                const newDisplayId = quoteLineItems.length + 1;
                const [_quoteLineItemToEdit] = createNewEmptyQuoteLineItemPair(newDisplayId, currencyIsoCode, manufacturingSite, exportPackaging);
                updateQuoteLineData(_quoteLineItemToEdit);
                // dispatch new item to quote state and handle leftover quantities
                dispatchQuoteState({ type: QuoteActionTypes.updateLineToDuplicate, payload: undefined });
                const { quoteLineItems: _quoteLineItems } = saveQuoteLineItem(dependentUnwrittenFieldsMap, fieldsUpdated, _quoteLineItemToEdit, true);
                handleLeftoverQuantitiesForAutocombine( dependentUnwrittenFieldsMap, _quoteLineItemToEdit, _quoteLineItemToEdit.quoteLineItemDetail, _quoteLineItems, true);

                toast(ToastTypes.Success, 'Successfully added item');
              } catch (error) {
                console.error(error);
                toast(ToastTypes.Error, ToastMessages.General);
              }
            }}
          >
              {'Add & Duplicate'}
            </IonButton>

        )}
        <IonButton color="primary" class="add-button" data-testid={`QAAddQuoteItemPopover${!props.quoteLineItemToEdit ? 'AddItem' : 'Save'}Button`} onClick={ async () => {
          try {
            if (isMultiShot && !isMultiShotStateValid(multiShotMetalsStateRef.current)){
                toast(ToastTypes.Error, 'Please fill out all fields in the Multi Shot section');
                return;
            }
            let quoteLineItemToEdit: QuoteLineItem;
            let leftoverQuantityDetail;

            if (props.quoteLineItemToEdit && props.quoteLineItemDetailToEdit) {
              quoteLineItemToEdit = {
                ...props.quoteLineItemToEdit,
                quoteLineItemDetail: { ...props.quoteLineItemDetailToEdit },
              };

              leftoverQuantityDetail = {
                ...props.quoteLineItemDetailToEdit,
                NC_Anvil_Item_Details__r: { ...props.quoteLineItemDetailToEdit.NC_Anvil_Item_Details__r, Id: undefined },
                NC_Cylinder_Item_Details__r: { ...props.quoteLineItemDetailToEdit.NC_Cylinder_Item_Details__r, Id: undefined },
                NC_Flyer_Item_Details__r: { ...props.quoteLineItemDetailToEdit.NC_Flyer_Item_Details__r, Id: undefined },
                NC_Head_Item_Detail__r: { ...props.quoteLineItemDetailToEdit.NC_Head_Item_Detail__r, Id: undefined },
                NC_Multi_Shot_Item_Details__r: { ...props.quoteLineItemDetailToEdit.NC_Multi_Shot_Item_Details__r, Id: undefined },
                NC_Outside_Service_Item_Details__r: { ...props.quoteLineItemDetailToEdit.NC_Outside_Service_Item_Details__r, Id: undefined },
                NC_Seam_Weld_Item_Details__r: { ...props.quoteLineItemDetailToEdit.NC_Seam_Weld_Item_Details__r, Id: undefined },
                NC_Tubesheet_Item_Details__r: { ...props.quoteLineItemDetailToEdit.NC_Tubesheet_Item_Details__r, Id: undefined }
              };
            } else {

            const newDisplayId = quoteLineItems.length + 1;
            const [newQuoteLineItem] = createNewEmptyQuoteLineItemPair(newDisplayId, currencyIsoCode, manufacturingSite, exportPackaging);

            quoteLineItemToEdit = {...newQuoteLineItem};
            leftoverQuantityDetail = {...newQuoteLineItem.quoteLineItemDetail};
          }
          updateQuoteLineData(quoteLineItemToEdit);

          dispatchQuoteState({type: QuoteActionTypes.updateLineToDuplicate, payload: undefined});

          const { quoteLineItems: _quoteLineItems } = saveQuoteLineItem(dependentUnwrittenFieldsMap, fieldsUpdated, quoteLineItemToEdit);

          handleLeftoverQuantitiesForAutocombine(dependentUnwrittenFieldsMap, quoteLineItemToEdit, leftoverQuantityDetail, _quoteLineItems);

          // KICK-OFF ⚽
          if (isMultiShot) {
            // TODO:Find a way to somehow fire off a spinner? This locks up the UI. I am not sure how to do this with the current setup.
            // startSpinner();
            const lastSavedItem: QuoteLineItem = _quoteLineItems[_quoteLineItems.length - 1];

            const shotCount =
              multiShotMetalsStateRef.current.cladMetals.length +
              multiShotMetalsStateRef.current.interlayerMetals.length -
              1;

            const updatedItemTypeName = `${lastSavedItem.Line_Item__c?.slice(0, -1) ?? ''}${(shotCount + 1).toString()}`; // PL1000 -> PL1002

            lastSavedItem.Line_Item__c = updatedItemTypeName; // this kicks off integration for multi shots
            lastSavedItem.quoteLineItemDetail.MultiShotCount__c = shotCount + 1;
            
            const isDoubleSidedClad = multiShotMetalsStateRef.current.cladMetals.length > 1;
            lastSavedItem.isDoubleSidedClad = isDoubleSidedClad;

            const msVirtualGroup: MultiShotVirtualGroup = createVirtualLinesForMSDetail( multiShotMetalsStateRef, rawMaterials, currencyIsoCode, manufacturingSite, exportPackaging, updateQuoteLineData, dispatchQuoteState, saveQuoteLineItem, dependentUnwrittenFieldsMap, fieldsUpdated, handleLeftoverQuantitiesForAutocombine, lastSavedItem);

            // add virtual lines to quoteLineItemDetail for the on save conversion into multi shot details
            lastSavedItem.quoteLineItemDetail.virtualDetailGroup = msVirtualGroup;
            // stopSpinner();
          }

          const builtQuote = _quoteLineItems.find(qli => qli.Line__c === quoteLineItemToEdit.Line__c);
          const rules = runGuardRail(builtQuote, builtQuote.quoteLineItemDetail, unitOfMeasure, rawMaterials, manufacturingSite);
          let showGuardrailsPopover: boolean;

          if (rules.length > 0) {
              longerToast(ToastTypes.Warning, rules);
              showGuardrailsPopover = rules.includes(GuardRailsLongDescription.MaxThickness);
          }

          dismissModal(showGuardrailsPopover, builtQuote);
          } catch (error) {
            console.error(error);
            toast(ToastTypes.Error, ToastMessages.General);
          }
        }}
        >
          {!props.quoteLineItemToEdit ? 'Add Item' : 'Save'}
        </IonButton>
        <IonButton color="secondary" class="cancel-button" onClick={() => {dismissModal(); dispatchQuoteState({type: QuoteActionTypes.updateLineToDuplicate, payload: undefined});}}>Cancel</IonButton>
      </div>
    </div>
  );
};

export default AddQuoteItemPopover;

interface CheckBoxItemProps {
  label: string;
  checked: boolean;
  onIonChange: (checked: boolean) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  skipTab?: boolean;
}

const CheckboxItem = (props: CheckBoxItemProps) => {
  return (
    <IonItem tabIndex={props.skipTab ? -1 : 0} class='checkbox-item' lines="none"  style={props.style}>
      <IonCheckbox
        class='checkbox'
        slot="start"
        color="primary"
        checked={props.checked}
        onIonChange={(e) => props.onIonChange(e.detail.checked)}
        data-testid={'QAAddQuoteItemPopoverCheckbox_' + props.label}
        disabled={props.disabled}
      />
      <IonLabel class='checkbox-label'>{props.label}</IonLabel>
    </IonItem>
  );
};

interface OpenEndDiameterInputProps {
  openDiameter: number;
  setOpenDiameter: React.Dispatch<React.SetStateAction<number>>;
}

const OpenEndDiameterInput = (props : OpenEndDiameterInputProps) => {
  return (
    <SelectionContainer
      type={SelectionTypes.TextInput}
      selectionLabel='Open End Diameter'
      placeholder='0.001'
      inputType='number'
      regex={Regexes.DecimalNumber}
      min='0.001'
      step='0.001'
      style={{ width: '193px', marginRight: '20px' }}
      onBlur={diameter => props.setOpenDiameter(diameter)}
      value={props.openDiameter}
    />
  );
};

interface InsideDishRadiusInputProps {
  insideDishRadius: number;
  setInsideDishRadius: React.Dispatch<React.SetStateAction<number>>;
}

const InsideDishRadiusInput = (props: InsideDishRadiusInputProps) => {

  return (
    <SelectionContainer
      type={SelectionTypes.TextInput}
      selectionLabel='Inside Dish Radius'
      placeholder='0.001'
      inputType='number'
      regex={Regexes.DecimalNumber}
      min='0.001'
      step='0.001'
      style={{ width: '193px', marginRight: '20px' }}
      onBlur={dishRadius => props.setInsideDishRadius(dishRadius)}
      value={props.insideDishRadius}
    />
  );
};

interface TubesheetMachiningFieldProps {
  machining: string;
  setMachining: React.Dispatch<React.SetStateAction<string>>;
  itemType: FinishedGood;
}
const TubesheetMachiningField = (props:TubesheetMachiningFieldProps) => {
  const { machining, setMachining, itemType } = props;
  const options = React.useMemo(
    () => itemType?.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.Machining)?.options,
    [itemType]
  );

  return (
    <SelectionContainer
      type={SelectionTypes.DropDown}
      selectionLabel='Machining'
      placeholder='Select Machining'
      options={options}
      style={{ width: '193px', marginRight: '6px' }}
      onChange={machining => setMachining(machining.label)}
      value={machining}
      defaultValue={machining}
      isDisabled={!itemType}
    />
  );
};

interface LengthFieldProps {
  finishedLength: number;
  setFinishedLength: React.Dispatch<React.SetStateAction<number>>;
}

const LengthField = (props: LengthFieldProps) => {
  const { finishedLength, setFinishedLength } = props;
  return (
    <SelectionContainer
      type={SelectionTypes.TextInput}
      inputType='number'
      selectionLabel='Length'
      placeholder='0.00'
      regex={Regexes.DecimalNumber}
      min='0.01'
      step='0.01'
      style={{ width: '63px', marginRight: '20px' }}
      onBlur={(finishedBaseMetalLength) => setFinishedLength(+finishedBaseMetalLength)}
      value={finishedLength}
    />
  );
};

interface StraightFlangeInputProps {
  straightFlange: number;
  setStraightFlange: React.Dispatch<React.SetStateAction<number>>;
}

const StraightFlangeInput = (props: StraightFlangeInputProps) => {
  return (
    <SelectionContainer
      type={SelectionTypes.TextInput}
      selectionLabel='Straight Flange'
      placeholder='0.001'
      inputType='number'
      regex={Regexes.DecimalNumber}
      min='0.001'
      step='0.001'
      style={{ width: '193px', marginRight: '20px' }}
      onBlur={flange => props.setStraightFlange(flange)}
      value={props.straightFlange}
    />
  );
};

interface HeadEdgePreparationInputProps {
  headEdgePreparation: string;
  setHeadEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
  itemType: FinishedGood;
}

const HeadEdgePreparationInput = (props: HeadEdgePreparationInputProps) => {
  const options = React.useMemo(
    () => props.itemType?.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.EdgePreparation)?.options,
    [props.itemType]
  );

  return (
    <SelectionContainer
      type={SelectionTypes.DropDown}
      selectionLabel='Edge Preparation'
      placeholder='Select specification'
      options={options}
      style={{ width: '193px', marginRight: '20px' }}
      onChange={edgePrep => props.setHeadEdgePreparation(edgePrep.label)}
      value={props.headEdgePreparation}
    />
  );
};

interface CylinderEdgePreparationInputProps {
  cylinderEdgePreparation: string;
  setCylinderEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
  itemType: FinishedGood;
}

const CylinderEdgePreparationInput = (props: CylinderEdgePreparationInputProps) => {
  const options = React.useMemo(
    () => props.itemType?.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.EdgePreparation) ?.options,
    [props.itemType]
  );

  return (
    <SelectionContainer
      type={SelectionTypes.DropDown}
      selectionLabel='Edge Preparation'
      placeholder='Select specification'
      options={options}
      style={{ width: '193px', marginRight: '20px' }}
      onChange={edgePrep => props.setCylinderEdgePreparation(edgePrep.label)}
      value={props.cylinderEdgePreparation}
    />
  );
};

interface TwoToOneSelectionsProps {
  straightFlange: number;
  setStraightFlange: React.Dispatch<React.SetStateAction<number>>;
  headEdgePreparation: string;
  setHeadEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
  itemType: FinishedGood;
}

const TwoToOneSelections = (props: TwoToOneSelectionsProps) => {
  return (
    <IonRow>
      <IonCol>
        <StraightFlangeInput
          straightFlange={props.straightFlange}
          setStraightFlange={props.setStraightFlange}
        />
        <HeadEdgePreparationInput
          headEdgePreparation={props.headEdgePreparation}
          setHeadEdgePreparation={props.setHeadEdgePreparation}
          itemType={props.itemType}
        />
      </IonCol>
    </IonRow>
);};

interface HemiSelectionsProps {
  openDiameter: number;
  setOpenDiameter: React.Dispatch<React.SetStateAction<number>>;
  insideDishRadius: number;
  setInsideDishRadius: React.Dispatch<React.SetStateAction<number>>;
  headEdgePreparation: string;
  setHeadEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
  cutback: number;
  setCutback: React.Dispatch<React.SetStateAction<number>>;
  itemType: FinishedGood;
}

const HemiSelections = (props: HemiSelectionsProps) => {
  const {
    openDiameter,
    setOpenDiameter,
    insideDishRadius,
    setInsideDishRadius,
    headEdgePreparation,
    setHeadEdgePreparation,
    setCutback,
    cutback,
    itemType,
  } = props;

  return (
    <div>
      <IonRow>
        <IonCol>
          <OpenEndDiameterInput
          openDiameter={openDiameter}
          setOpenDiameter={setOpenDiameter}
          />
          <HeadEdgePreparationInput
            headEdgePreparation={headEdgePreparation}
            setHeadEdgePreparation={setHeadEdgePreparation}
            itemType={itemType}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <InsideDishRadiusInput
            insideDishRadius={insideDishRadius}
            setInsideDishRadius={setInsideDishRadius}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            selectionLabel='Cutback'
            placeholder='0.001'
            inputType='number'
            regex={Regexes.DecimalNumber}
            min='0.001'
            step='0.001'
            style={{ width: '193px', marginRight: '20px' }}
            onBlur={cutback => setCutback(cutback)}
            value={cutback}
          />
        </IonCol>
      </IonRow>
    </div>
);};

interface FandDSelectionsProps {
  straightFlange: number;
  setStraightFlange: React.Dispatch<React.SetStateAction<number>>;
  headEdgePreparation: string;
  setHeadEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
  insideDishRadius: number;
  setInsideDishRadius: React.Dispatch<React.SetStateAction<number>>;
  insideKnuckleRadius: number;
  setInsideKnuckleRadius: React.Dispatch<React.SetStateAction<number>>;
  itemType: FinishedGood;
}

const FandDSelections = (props: FandDSelectionsProps) => {
  const {
    straightFlange,
    setStraightFlange,
    headEdgePreparation,
    setHeadEdgePreparation,
    insideDishRadius,
    setInsideDishRadius,
    insideKnuckleRadius,
    setInsideKnuckleRadius,
    itemType,
  } = props;

  return(
    <div>
      <TwoToOneSelections
        straightFlange={straightFlange}
        setStraightFlange={setStraightFlange}
        headEdgePreparation={headEdgePreparation}
        setHeadEdgePreparation={setHeadEdgePreparation}
        itemType={itemType}
      />
      <IonRow>
        <IonCol>
          <InsideDishRadiusInput
            insideDishRadius={insideDishRadius}
            setInsideDishRadius={setInsideDishRadius}
          />
          <SelectionContainer
            type={SelectionTypes.TextInput}
            selectionLabel='Inside Knuckle Radius'
            placeholder='0.001'
            inputType='number'
            regex={Regexes.DecimalNumber}
            min='0.001'
            step='0.001'
            style={{ width: '193px', marginRight: '20px' }}
            onBlur={knuckleRadius => setInsideKnuckleRadius(knuckleRadius)}
            value={insideKnuckleRadius}
          />
        </IonCol>
      </IonRow>
    </div>
  );
};

interface ItemTypeDropdownProps {
  itemTypeHandler: (itemTypeName: string) => void;
  itemType: FinishedGood;
  options: string[];
  // subcontractHeadForming: boolean;
  // setSubcontractHeadForming: React.Dispatch<React.SetStateAction<boolean>>;

}

const ItemTypeDropdown = (props: ItemTypeDropdownProps) => {
    return (
      <SelectionContainer
        type={SelectionTypes.DropDown}
        selectionLabel='Item Type'
        placeholder='Select item type'
        options={props.options}
        style={{ width: '193px', marginRight: '20px' }}
        onChange={(itemType) => {
          try {
            props.itemTypeHandler(itemType.label);

          } catch (error) {
            console.error(error);
          }
        }}
        value={props.itemType?.name}
      />
    );
  };


  interface DOHSelectionsProps {
    openDiameter: number;
    setOpenDiameter: React.Dispatch<React.SetStateAction<number>>;
    headEdgePreparation: string;
    setHeadEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
    insideDishRadius: number;
    setInsideDishRadius: React.Dispatch<React.SetStateAction<number>>;
    itemType: FinishedGood;
  }

  const DOHSelections = (props: DOHSelectionsProps) => {
    const {
      openDiameter,
      setOpenDiameter,
      headEdgePreparation,
      setHeadEdgePreparation,
      insideDishRadius,
      setInsideDishRadius,
      itemType,
    } = props;

    return (
      <div>
        <IonRow>
          <IonCol>
            <OpenEndDiameterInput
             openDiameter={openDiameter}
             setOpenDiameter={setOpenDiameter}
            />
            <HeadEdgePreparationInput
              headEdgePreparation={headEdgePreparation}
              setHeadEdgePreparation={setHeadEdgePreparation}
              itemType={itemType}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <InsideDishRadiusInput
              insideDishRadius={insideDishRadius}
              setInsideDishRadius={setInsideDishRadius}
            />
          </IonCol>
        </IonRow>
      </div>
  );};

  interface HeadSegmentalSelectionsProps {
    crownPlateDiameter: number;
    setCrownPlateDiameter: React.Dispatch<React.SetStateAction<number>>;
    numberOfPetals: number;
    setNumberOfPetals: React.Dispatch<React.SetStateAction<number>>;
    lengthOfWelds: number;
    setLengthOfWelds: React.Dispatch<React.SetStateAction<number>>;
  }

  const HeadSegmentalSelections = (props: HeadSegmentalSelectionsProps) => {
    return (
      <>
        <IonRow>
          <IonCol class='flex-column'>
            <div className='flex-row'>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                selectionLabel='Crown Plate Diameter'
                placeholder='0.00'
                inputType='number'
                regex={Regexes.DecimalNumber}
                min='0.00'
                style={{ width: '193px', marginRight: '20px' }}
                onBlur={(diameter) => (props.setCrownPlateDiameter(diameter))}
                value={props.crownPlateDiameter}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                selectionLabel='Number of Petals'
                placeholder='#'
                inputType='number'
                regex={Regexes.IntegerNumber}
                min='0.00'
                style={{ width: '90px', marginRight: '20px' }}
                onBlur={(petals) => (props.setNumberOfPetals(petals))}
                value={props.numberOfPetals}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                selectionLabel={'Length of Welds'}
                placeholder='0.00'
                regex={Regexes.DecimalNumber}
                min='0.01'
                step='0.01'
                style={{ width: '193px', marginRight: '20px' }}
                onBlur={(length) => (props.setLengthOfWelds(length))}
                value={props.lengthOfWelds}
              />
            </div>
          </IonCol>
        </IonRow>
      </>
    );
  };

  interface CutMethodInputProps {
    cutMethodInputOptions: string[];
    cutMethod: string;
    setCutMethod: React.Dispatch<React.SetStateAction<string>>;
    isDisabled: boolean;
  }
  const CutMethodInput = (props: CutMethodInputProps) => {

    return (
      <SelectionContainer
        type={SelectionTypes.DropDown}
        selectionLabel='Cut Method'
        placeholder='Select method'
        options={props.cutMethodInputOptions}
        style={{ width: '193px' }}
        value={props.cutMethod}
        defaultValue={props.cutMethod}
        onChange={cutMethod => props.setCutMethod(cutMethod.label)}
        isDisabled={props.isDisabled}
      />
    );
  };

  interface HeadSegmentalPlate {
    id: number;
    qty: number;
    shape: string;
    width: number;
    length: number;
    cladTK: number;
    cladType: string;
    baseTK: number;
    baseType: string;
  }
  interface HeadSegmentalAddPlatesProps {
    unitOfMeasure: MeasurementSystems
  }
  const HeadSegmentalAddPlates = (props: HeadSegmentalAddPlatesProps) => {
    const { unitOfMeasure } = props;

    // TODO: init this from the Quote line item header detail, when wiring up the Salesforce fields
    // new segmental fields: CrownPlateDiameter__c, NumberOfPetals__c, LengthOfWelds__c
    const [plates, setPlates] = useState<HeadSegmentalPlate[]>([]);

    const handleAddPlate = () => {
      // create a new plate object
      const newPlate: HeadSegmentalPlate = {
        // generate a unique id for the plate so we have a key for react
        id: generateUUID(),
        qty: 0,
        shape: '',
        width: 0,
        length: 0,
        cladTK: 0.0,
        cladType: '',
        baseTK: 0.0,
        baseType: '',
      };
      setPlates(prev => [...prev, newPlate]);
    };

    return (
      <>
      {/* show the PlatesTable if there are any plates */}
        {plates?.length > 0 && <PlatesTable plates={plates} setPlates={setPlates} unitOfMeasure={unitOfMeasure}/>}
        <div className={'add-plate-container'} onClick={handleAddPlate}>
          <IonIcon class='add-plate-icon' src={addCircle}></IonIcon>
          <IonLabel class='add-plate-label'>Add Plate</IonLabel>
        </div>
      </>
    );
  };

  interface PlatesTableProps {
    plates: HeadSegmentalPlate[],
    setPlates: React.Dispatch<React.SetStateAction<HeadSegmentalPlate[]>>,
    unitOfMeasure: MeasurementSystems,
  }


  const PlatesTable = (props: PlatesTableProps) => {
    const { plates, setPlates, unitOfMeasure } = props;

    const quantityHeader = (
      <>
        <>{'Qty'}</>
        <Tooltip
          position={TooltipPositions.Bottom}
          text={<div className='tool-tip-text'>Specify quantity needed per head.</div>}
          offset={{ x: '3px', y: '-4px' }}
          style={{ marginLeft: '5px'}}
        >
          <IonIcon class='info-icon' icon={infoIcon} />
        </Tooltip>
      </>
    );

    // shapeHedaer is used to move the col header away from the tooltip icon
    const shapeHeader = <span style={{marginLeft: '12px'}}>{'Shape'}</span>;

    const tableHeaders = [
      { display: quantityHeader, colSize: '0.5' },
      { display: shapeHeader, colSize: '2' },
      { display: 'Width', colSize: '1.25' },
      { display: 'Length', colSize: '1.25' },
      { display: 'Clad TK', colSize: '1.25' },
      { display: 'Clad Type', colSize: '2.25' },
      { display: 'Base TK', colSize: '1.25' },
      { display: 'Base Type', colSize: '2.25' },
    ];

    const updatePlates = (newPlate: HeadSegmentalPlate, index: number) => {
      // update the plates in state using setPlates, replacing the plate at index with the newPlate
      setPlates((prev) => {
        const newPlates = [...prev];
        newPlates[index] = newPlate;
        return newPlates;
      });
    };

    const handleSetShape = (shape: string, index: number): void => {
      const newPlate = { ...plates[index], shape };
      // circles don't have a length, so set it to 0
      if (shape === Shapes.Circle) {
        newPlate.length = 0;
      }

      // update the plates in state using setPlates, replacing the plate at index with newPlate
      updatePlates(newPlate, index);
    };

    const handleRemovePlate = (index: number) => {
      // remove the plate at the index and update state
      setPlates((prev) => {
        const newPlates = [...prev];
        newPlates.splice(index, 1);
        return newPlates;
      });
    };

    const handleSetCladType = (type: string, index: number): void => {
      const newPlate = { ...plates[index], cladType: type };

      // update the plates in state using setPlates, replacing the plate at index with newPlate
      updatePlates(newPlate, index);
    };

    const handleSetBaseType = (type: string, index: number): void => {
      const newPlate = { ...plates[index], baseType: type };

      // update the plates in state using setPlates, replacing the plate at index with newPlate
      updatePlates(newPlate, index);
    };

    const getImperialValue = (value: number, uom: MeasurementSystems) => {
      return calculateUOMValue(uom, MeasurementSystems.Imperial, value, Units.Millimeters, Units.Inches);
    };

    const getMetricValue = (value: number, uom: MeasurementSystems) => {
      return calculateUOMValue(uom, MeasurementSystems.Metric, value, Units.Millimeters, Units.Inches);
    };

    return (
      <>
        <IonCard class='table-header-quote-line-items-card'>
          <IonGrid>
            <IonRow class='table-header-quote-line-items-row'>
              {tableHeaders.map((header, index) => {
                return (
                  <IonCol
                  // using index for key due to quantity header as a component
                    key={index}
                    size={header.colSize}
                    class='table-header-quote-line-items-col quote-line-items-text'
                  >
                    <>{header.display}</>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
        </IonCard>
        {plates.map((plate, index) => {
          // check if the plate is a circle, to make length readonly
          const isCircle = plate.shape === Shapes.Circle;

          return (
            <IonCard key={plate.id} className='add-item-quote-line-item-card'>
              <IonCardHeader class={'add-item-quote-line-item-card-header'}>
                <IonGrid>
                  <IonRow class='add-item-quote-line-item-row'>
                    {/* Qty */}
                    <IonCol size='0.5' class={'add-item-quote-line-items-col'}>
                      <LineItemField
                        valueType={QuoteLineItemValueTypes.SizingQuantity}
                        value={plate.qty}
                        readOnly={false}
                        ignoreRecalculateFields
                        fieldChangedCB={(value) => {
                          const newPlate = { ...plate, qty: value };
                          updatePlates(newPlate, index);
                        }}
                      />
                    </IonCol>
                    {/* Shape */}
                    <IonCol size='2' class={'add-item-quote-line-items-col'}>
                      <SelectionContainer
                        type={SelectionTypes.DropDown}
                        options={Object.values(Shapes)}
                        style={{ width: '100%' }}
                        onChange={(shape) => handleSetShape(shape.label, index)}
                        value={plate.shape}
                      />
                    </IonCol>
                    {/* Width */}
                    <IonCol size='1.25' class={'add-item-quote-line-items-col'}>
                      <LineItemField
                        valueType={QuoteLineItemValueTypes.Width}
                        imperialValue={getImperialValue(plate.width, unitOfMeasure)}
                        metricValue={getMetricValue(plate.width, unitOfMeasure)}
                        unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                        readOnly={false}
                        ignoreRecalculateFields
                        fieldChangedCB={(value) => {
                          const newPlate = { ...plate, width: value };
                          updatePlates(newPlate, index);
                        }}
                        uom={unitOfMeasure}
                      />
                    </IonCol>
                    {/* Length */}
                    <IonCol class={'add-item-quote-line-items-col'} size='1.25'>
                      <LineItemField
                        valueType={QuoteLineItemValueTypes.Length}
                        imperialValue={getImperialValue(plate.length, unitOfMeasure)}
                        metricValue={getMetricValue(plate.length, unitOfMeasure)}
                        unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                        readOnly={isCircle}
                        ignoreRecalculateFields
                        fieldChangedCB={(value) => {
                          const newPlate = { ...plate, length: value };
                          updatePlates(newPlate, index);
                        }}
                        uom={unitOfMeasure}
                      />
                    </IonCol>
                    {/* Clad TK */}
                    <IonCol class={'add-item-quote-line-items-col'} size='1.25'>
                      <LineItemField
                        valueType={QuoteLineItemValueTypes.CladTK}
                        imperialValue={getImperialValue(plate.cladTK, unitOfMeasure)}
                        metricValue={getMetricValue(plate.cladTK, unitOfMeasure)}
                        unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                        readOnly={false}
                        ignoreRecalculateFields
                        fieldChangedCB={(value) => {
                          const newPlate = { ...plate, cladTK: value };
                          updatePlates(newPlate, index);
                        }}
                        uom={unitOfMeasure}
                      />
                    </IonCol>
                    {/* Clade Type */}
                    <IonCol size='2' class={'add-item-quote-line-items-col'}>
                      <div className='column-container right-align'>
                        <SelectionContainer
                          valueType={QuoteLineItemValueTypes.CladType}
                          type={SelectionTypes.DropDown}
                          options={Object.values(MetalTypes)}
                          style={{ width: '100%' }}
                          onChange={(type) => handleSetCladType(type.label, index)}
                          value={plate.cladType}
                        />
                      </div>
                    </IonCol>
                    {/* Base TK */}
                    <IonCol size='1' class={'add-item-quote-line-items-col'}>
                      <LineItemField
                        valueType={QuoteLineItemValueTypes.BaseTK}
                        imperialValue={getImperialValue(plate.baseTK, unitOfMeasure)}
                        metricValue={getMetricValue(plate.baseTK, unitOfMeasure)}
                        unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                        readOnly={false}
                        ignoreRecalculateFields
                        fieldChangedCB={(value) => {
                          const newPlate = { ...plate, baseTK: value };
                          updatePlates(newPlate, index);
                        }}
                        uom={unitOfMeasure}
                      />
                    </IonCol>
                    {/* Base Type */}
                    <IonCol size='2' class={'add-item-quote-line-items-col'}>
                      <SelectionContainer
                        valueType={QuoteLineItemValueTypes.BaseType}
                        type={SelectionTypes.DropDown}
                        options={Object.values(MetalTypes)}
                        style={{ width: '100%' }}
                        onChange={(type) => handleSetBaseType(type.label, index)}
                        value={plate.baseType}
                      />
                    </IonCol>
                    {/* Remove */}
                    <IonCol size='0.5' class='add-item-quote-line-items-col'>
                      <div className='close-line-container' onClick={() => handleRemovePlate(index)}>
                        <IonIcon class='close-line-icon' src={closeCircle}></IonIcon>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardHeader>
            </IonCard>
          );
        })}
      </>
    );
  };

  interface MetalThicknessSectionProps {
    baseMetalType: string;
    setBaseMetalType: React.Dispatch<React.SetStateAction<string>>;
    baseMetalTypeOptions: string[];
    cladMetalType: string;
    setCladMetalType: React.Dispatch<React.SetStateAction<string>>;
    isMultiShot: boolean;
    finishedCladMetalThickness: number;
    setFinishedCladMetalThickness: React.Dispatch<React.SetStateAction<number>>;
    cladMetalTypeOptions: string[];
    isDisabled: boolean;
    isDncSelected: boolean;
    setIsDncSelected: React.Dispatch<React.SetStateAction<boolean>>;
    isPiecesSelected: boolean;
    setIsPiecesSelected: React.Dispatch<React.SetStateAction<boolean>>;
    finishedBaseMetalThickness: number;
    setFinishedBaseMetalThickness: React.Dispatch<React.SetStateAction<number>>;
  }
  const MetalThicknessSection = (props: MetalThicknessSectionProps) => {
    const {
      baseMetalType,
      setBaseMetalType,
      baseMetalTypeOptions,
      cladMetalType,
      setCladMetalType,
      finishedCladMetalThickness,
      setFinishedCladMetalThickness,
      cladMetalTypeOptions,
      isDisabled,
      isDncSelected,
      setIsDncSelected,
      isMultiShot,
      isPiecesSelected,
      setIsPiecesSelected,
      finishedBaseMetalThickness,
      setFinishedBaseMetalThickness,
    } = props;

    return (
      <IonRow>
        <IonCol class='flex-column'>
        {!props.isMultiShot && (<>
        <IonLabel class='metal-subheader'>Clad Metal</IonLabel>
          <hr className='subheader-rule' />
            <div className='flex-row'>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                selectionLabel='Thickness'
                placeholder='0.00'
                regex={Regexes.DecimalNumber}
                min='0.01'
                step='0.01'
                style={{ width: '63px', marginRight: '20px' }}
                onBlur={finishedCladMetalThickness => setFinishedCladMetalThickness(+finishedCladMetalThickness)}
                value={finishedCladMetalThickness}
              />
              <SelectionContainer
                type={SelectionTypes.DropDown}
                selectionLabel='Type'
                placeholder='Select type'
                options={cladMetalTypeOptions}
                style={{ width: '193px' }}
                defaultValue={cladMetalType}
                onChange={cladMetalType => setCladMetalType(cladMetalType.label)}
                value={cladMetalType}
                isDisabled={isDisabled}
              />
            </div>
          </>)}
          <CheckboxItem
            label='Do not combine with any other Finished Goods for this quote.'
            style={{ width: 'fit-content', marginTop: '4px' }}
            onIonChange={(isDncSelected) => setIsDncSelected(isDncSelected)}
            checked={isDncSelected  || isMultiShot}
            skipTab={true}
          />
          <CheckboxItem
            label='Create individual pieces of the quantity specified for this request.'
            style={{ width: 'fit-content', marginTop: '4px' }}
            onIonChange={(isPiecesSelected) => setIsPiecesSelected(isPiecesSelected)}
            checked={isPiecesSelected  || isMultiShot}
            skipTab={true}
          />
        </IonCol>
        {!isMultiShot && (
          <IonCol class='flex-column'>
            <IonLabel class='metal-subheader'>Base Metal</IonLabel>
            <hr className='subheader-rule' />
            <div className='flex-row'>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                selectionLabel='Thickness'
                placeholder='0.00'
                regex={Regexes.DecimalNumber}
                min='0.01'
                step='0.01'
                style={{ width: '63px', marginRight: '20px' }}
                onBlur={finishedBaseMetalThickness => setFinishedBaseMetalThickness(+finishedBaseMetalThickness)}
                value={finishedBaseMetalThickness}
              />
              <SelectionContainer
                type={SelectionTypes.DropDown}
                selectionLabel='Type'
                placeholder='Select type'
                options={baseMetalTypeOptions}
                style={{ width: '193px' }}
                defaultValue={baseMetalType}
                onChange={baseMetalType => setBaseMetalType(baseMetalType.label)}
                value={baseMetalType}
                isDisabled={isDisabled}
              />
            </div>
          </IonCol>
        )}
      </IonRow>
    );
  };

  interface CylinderSelectionsProps {
    baseMetalType: string;
    baseMetalTypeOptions: string[];
    cladMetalType: string;
    cladMetalTypeOptions: string[];
    cutMethod: string;
    cutMethodInputOptions: string[];
    cylinderDiameter: number;
    cylinderDiameterOptions: string[];
    cylinderEdgePreparation: string;
    cylinderFreightChargeToCustomerPerUOM: number;
    cylinderIdOrOd: string;
    cylinderLength: number;
    cylinderType: string;
    finishedBaseMetalThickness: number;
    finishedCladMetalThickness: number;
    freightChargeToRollerPerUOM: number;
    isDarkMode: boolean;
    isDisabled: boolean;
    isDncSelected: boolean;
    isMultiShot: boolean;
    isPiecesSelected: boolean;
    itemType: FinishedGood;
    itemTypeHandler: (itemTypeName: string) => void;
    notesPrintQuoteToForm: string;
    plateSizesSupplyFormerLength: number;
    plateSizesSupplyFormerWidth: number;
    quantityPerCylinder: number;
    rollingCostPerCylinder: number;
    setBaseMetalType: React.Dispatch<React.SetStateAction<string>>;
    setCladMetalType: React.Dispatch<React.SetStateAction<string>>;
    setCutMethod: React.Dispatch<React.SetStateAction<string>>;
    setCylinderDiameter: React.Dispatch<React.SetStateAction<number>>;
    setCylinderEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
    setCylinderFreightChargeToCustomerPerUOM: React.Dispatch<React.SetStateAction<number>>;
    setCylinderIdOrOd: React.Dispatch<React.SetStateAction<string>>;
    setCylinderLength: React.Dispatch<React.SetStateAction<number>>;
    setCylinderType: React.Dispatch<React.SetStateAction<string>>;
    setFinishedBaseMetalThickness: React.Dispatch<React.SetStateAction<number>>;
    setFinishedCladMetalThickness: React.Dispatch<React.SetStateAction<number>>;
    setFreightChargeToRollerPerUOM: React.Dispatch<React.SetStateAction<number>>;
    setIsDncSelected: React.Dispatch<React.SetStateAction<boolean>>;
    setIsPiecesSelected: React.Dispatch<React.SetStateAction<boolean>>;
    setNotesPrintQuoteToForm: React.Dispatch<React.SetStateAction<string>>;
    setPlateSizesSupplyFormerLength: React.Dispatch<React.SetStateAction<number>>;
    setPlateSizesSupplyFormerWidth: React.Dispatch<React.SetStateAction<number>>;
    setQuantityPerCylinder: React.Dispatch<React.SetStateAction<number>>;
    setRollingCostPerCylinder: React.Dispatch<React.SetStateAction<number>>;
    setTimeToRoll: React.Dispatch<React.SetStateAction<string>>;
    setTransitTimeToRoller: React.Dispatch<React.SetStateAction<string>>;
    itemTypeOptions: string[];
    timeToRoll: string;
    transitTimeToRoller: string;
    unitOfMeasure: MeasurementSystems;
  }

  const CylinderSelections = (props: CylinderSelectionsProps) => {
    const {
      unitOfMeasure,
      isDarkMode,
      itemTypeHandler,
      itemType,
      cylinderType,
      setCylinderType,
      cylinderEdgePreparation,
      setCylinderEdgePreparation,
      cylinderIdOrOd,
      setCylinderIdOrOd,
      itemTypeOptions,
      cylinderDiameterOptions,
      cylinderDiameter,
      setCylinderDiameter,
      cylinderFreightChargeToCustomerPerUOM,
      setCylinderFreightChargeToCustomerPerUOM,
      cylinderLength,
      setCylinderLength,
      rollingCostPerCylinder,
      setRollingCostPerCylinder,
      freightChargeToRollerPerUOM,
      setFreightChargeToRollerPerUOM,
      transitTimeToRoller,
      setTransitTimeToRoller,
      timeToRoll,
      setTimeToRoll,
      notesPrintQuoteToForm,
      setNotesPrintQuoteToForm,
      quantityPerCylinder,
      setQuantityPerCylinder,
      plateSizesSupplyFormerWidth,
      setPlateSizesSupplyFormerWidth,
      plateSizesSupplyFormerLength,
      setPlateSizesSupplyFormerLength,
      cutMethodInputOptions,
      cutMethod,
      setCutMethod,
      baseMetalType,
      setBaseMetalType,
      baseMetalTypeOptions,
      cladMetalType,
      setCladMetalType,
      finishedCladMetalThickness,
      setFinishedCladMetalThickness,
      cladMetalTypeOptions,
      isDncSelected,
      setIsDncSelected,
      isMultiShot,
      isPiecesSelected,
      setIsPiecesSelected,
      finishedBaseMetalThickness,
      setFinishedBaseMetalThickness,
    } = props;

    const isMetricUOM = unitOfMeasure === MeasurementSystems.Metric;

    return (
      <div className='selections'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <ItemTypeDropdown
                options={itemTypeOptions}
                itemType={itemType}
                // subcontractHeadForming={subcontractHeadForming}
                // setSubcontractHeadForming={setSubcontractHeadForming}
                itemTypeHandler={itemTypeHandler}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.DropDown}
                  selectionLabel='Cylinder Type'
                  placeholder='Select type'
                  options={cylinderDiameterOptions}
                  style={{ width: '193px', marginRight: '20px' }}
                  onChange={cylinderType => setCylinderType(cylinderType.label)}
                  value={cylinderType}
                />
                <div>
                  <SelectionContainer
                    type={SelectionTypes.RadioButtons}
                    labelPosition={SelectionLabelPositions.Left}
                    style={{ display: 'flex' }}
                    groupStyle={{ paddingTop: 'unset', marginTop: '-4px' }}
                    selectionLabel='Diameter'
                    options={itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.IDorOD)?.options}
                    onIonChange={cylinderIdOrOd => setCylinderIdOrOd(cylinderIdOrOd)}
                    value={cylinderIdOrOd}
                    skipTab={true}
                  />
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    inputType='number'
                    placeholder='0.00'
                    regex={Regexes.DecimalNumber}
                    min='0.01'
                    step='0.01'
                    style={{ width: '193px', marginTop: 'unset' }}
                    onBlur={cylinderDiameter => setCylinderDiameter(+cylinderDiameter)}
                    value={cylinderDiameter}
                  />
                </div>
                <div className='-mt-1 ml-15'>
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    inputType='number'
                    selectionLabel='Length'
                    placeholder='0.00'
                    regex={Regexes.DecimalNumber}
                    min='0.01'
                    step='0.01'
                    style={{ width: '63px', marginRight: '20px' }}
                    onBlur={(cylinderLength) => setCylinderLength(cylinderLength)}
                    value={cylinderLength}
                  />
                </div>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <CylinderEdgePreparationInput
               cylinderEdgePreparation={cylinderEdgePreparation}
               setCylinderEdgePreparation={setCylinderEdgePreparation}
               itemType={itemType}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class='flex-column'>
              <IonLabel class='metal-subheader'>{'Costs & Shipping'}</IonLabel>
              <hr className='subheader-rule' />
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel='Rolling Cost Per Cylinder'
                  placeholder='0.00'
                  regex={Regexes.DecimalNumber}
                  min='0.01'
                  step='0.01'
                  containerStyle={{ marginTop: '20px' }}
                  style={{ width: '193px', marginRight: '20px' }}
                  onBlur={rollingCostPerCylinder => setRollingCostPerCylinder(+rollingCostPerCylinder)}
                  value={rollingCostPerCylinder}
                />
                <CylinderFreightChargeToCustomerPerUOMField
                  containerStyle={{ marginTop: '20px' }}
                  unitOfMeasure={unitOfMeasure}
                  cylinderFreightChargeToCustomerPerUOM={cylinderFreightChargeToCustomerPerUOM}
                  setCylinderFreightChargeToCustomerPerUOM={setCylinderFreightChargeToCustomerPerUOM}
                />
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel={`Freight Charge to Roller Per ${!isMetricUOM ? 'lb' : 'kg'}`}
                  placeholder='0.00'
                  regex={Regexes.DecimalNumber}
                  min='0.01'
                  step='0.01'
                  containerStyle={{ marginTop: '20px' }}
                  style={{ width: '193px' }}
                  onBlur={freightChargeToRollerPerUOM => setFreightChargeToRollerPerUOM(+freightChargeToRollerPerUOM)}
                  value={freightChargeToRollerPerUOM}
                />
              </div>
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel='Transit Time to Roller (weeks)'
                  placeholder='1'
                  regex={Regexes.IntegerAboveZero}
                  min='1'
                  step='1'
                  containerStyle={{ marginTop: '20px' }}
                  style={{ width: '193px', marginRight: '20px' }}
                  onBlur={transitTimeToRoller => setTransitTimeToRoller(transitTimeToRoller)}
                  value={transitTimeToRoller}
                />
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel='Time to Roll (weeks)'
                  placeholder='1'
                  regex={Regexes.IntegerAboveZero}
                  min='1'
                  step='1'
                  containerStyle={{ marginTop: '20px' }}
                  style={{ width: '193px' }}
                  onBlur={timeToRoll => setTimeToRoll(timeToRoll)}
                  value={timeToRoll}
                />
              </div>
              <SelectionContainer
                type={SelectionTypes.TextArea}
                selectionLabel='Notes (print quote to form):'
                placeholder='Notes...'
                containerStyle={{ marginTop: '20px' }}
                style={{ width: '337px' }}
                rows={4}
                onBlur={(notesPrintQuoteToForm) => setNotesPrintQuoteToForm(notesPrintQuoteToForm)}
                value={notesPrintQuoteToForm}
                textAreaType='Notes Print Quote To Form'
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class='flex-column'>
              <IonLabel class='metal-subheader'>Plate Sizes Supply Former</IonLabel>
              <hr className='subheader-rule' />
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel='Quantity Per Cylinder'
                  placeholder='Quantity'
                  regex={Regexes.IntegerAboveZero}
                  min='1'
                  style={{ width: '193px', marginRight: '20px' }}
                  onBlur={(quantityPerCylinder) => setQuantityPerCylinder(+quantityPerCylinder)}
                  value={quantityPerCylinder}
                />
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel='Width'
                  placeholder='0.00'
                  regex={Regexes.DecimalNumber}
                  min='0.01'
                  step='0.01'
                  style={{ width: '63px', marginRight: '6px' }}
                  onBlur={plateSizesSupplyFormerWidth => setPlateSizesSupplyFormerWidth(+plateSizesSupplyFormerWidth)}
                  value={plateSizesSupplyFormerWidth}
                />
                <IonImg src={!isDarkMode ? closeIcon : closeIconWhite} class='multiply-icon'></IonImg>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel='Length'
                  placeholder='0.00'
                  regex={Regexes.DecimalNumber}
                  min='0.01'
                  step='0.01'
                  style={{ width: '63px', marginRight: '20px' }}
                  onBlur={(plateSizesSupplyFormerLength) => setPlateSizesSupplyFormerLength(+plateSizesSupplyFormerLength)}
                  value={plateSizesSupplyFormerLength}
                />

                {itemType?.name === FinishedProductTypes.Cylinder && (
                  <CutMethodInput
                    isDisabled={!itemType}
                    cutMethodInputOptions={cutMethodInputOptions}
                    cutMethod={cutMethod}
                    setCutMethod={setCutMethod}
                  />
                )}

              </div>
            </IonCol>
          </IonRow>
          <MetalThicknessSection
            isDisabled={!itemType}
            baseMetalType={baseMetalType}
            setBaseMetalType={setBaseMetalType}
            baseMetalTypeOptions={baseMetalTypeOptions}
            cladMetalType={cladMetalType}
            setCladMetalType={setCladMetalType}
            finishedCladMetalThickness={finishedCladMetalThickness}
            setFinishedCladMetalThickness={setFinishedCladMetalThickness}
            cladMetalTypeOptions={cladMetalTypeOptions}
            isDncSelected={isDncSelected}
            setIsDncSelected={setIsDncSelected}
            isMultiShot={isMultiShot}
            isPiecesSelected={isPiecesSelected}
            setIsPiecesSelected={setIsPiecesSelected}
            finishedBaseMetalThickness={finishedBaseMetalThickness}
            setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
          />
        </IonGrid>
      </div>
    );
  };

  interface CylinderFreightChargeToCustomerPerUOMFieldProps {
    containerStyle?: React.CSSProperties;
    unitOfMeasure: MeasurementSystems;
    cylinderFreightChargeToCustomerPerUOM: number;
    setCylinderFreightChargeToCustomerPerUOM: React.Dispatch<React.SetStateAction<number>>;
  }

  const CylinderFreightChargeToCustomerPerUOMField = (props: CylinderFreightChargeToCustomerPerUOMFieldProps) => {
    const { unitOfMeasure, containerStyle, cylinderFreightChargeToCustomerPerUOM, setCylinderFreightChargeToCustomerPerUOM } = props;

    const isMetricUOM = unitOfMeasure === MeasurementSystems.Metric;

    return (
      <SelectionContainer
        type={SelectionTypes.TextInput}
        inputType='number'
        selectionLabel={`Freight Charge to Customer Per ${!isMetricUOM ? 'lb' : 'kg'}`}
        placeholder='0.00'
        regex={Regexes.DecimalNumber}
        min='0.01'
        step='0.01'
        containerStyle={containerStyle}
        style={{ width: '193px', marginRight: '20px' }}
        onBlur={cost => setCylinderFreightChargeToCustomerPerUOM(cost)}
        value={cylinderFreightChargeToCustomerPerUOM}
      />
    );
  };

  interface PlateAndTubeSelectionsProps {
    baseMetalType: string;
    baseMetalTypeOptions: string[];
    cladMetalType: string;
    cladMetalTypeOptions: string[];
    cutMethod: string;
    cutMethodInputOptions: string[];
    finishedBaseMetalThickness: number;
    finishedCladMetalThickness: number;
    finishedLength: number;
    finishedWidth: number;
    isDarkMode: boolean;
    isDncSelected: boolean;
    isMultiShot: boolean;
    isPiecesSelected: boolean;
    itemType: FinishedGood;
    itemTypeHandler: (itemTypeName: string) => void;
    machining: string;
    manufacturingSite: ManufacturingSites;
    multiShotMetalsStateRef: React.MutableRefObject<MultiShotState>;
    multiShotType: MultiShotVariants;
    setBaseMetalType: React.Dispatch<React.SetStateAction<string>>;
    setCladMetalType: React.Dispatch<React.SetStateAction<string>>;
    setCutMethod: React.Dispatch<React.SetStateAction<string>>;
    setFinishedBaseMetalThickness: React.Dispatch<React.SetStateAction<number>>;
    setFinishedLength: React.Dispatch<React.SetStateAction<number>>;
    setFinishedWidth: React.Dispatch<React.SetStateAction<number>>;
    setFinishedCladMetalThickness: React.Dispatch<React.SetStateAction<number>>;
    setIsDncSelected: React.Dispatch<React.SetStateAction<boolean>>;
    setIsPiecesSelected: React.Dispatch<React.SetStateAction<boolean>>;
    setMachining: React.Dispatch<React.SetStateAction<string>>;
    setMultiShotType: React.Dispatch<React.SetStateAction<string>>;
    setShape: React.Dispatch<React.SetStateAction<string>>;
    shape: string;
    shapeOptions: string[];
    itemTypeOptions: string[];
    unitOfMeasure: MeasurementSystems;
    handleMetalChange: (metal: RawMaterial, usage: RawMaterialUsage) => void;
    setBaseMetalProposalType: React.Dispatch<React.SetStateAction<string>>;
    setCladMetalProposalType: React.Dispatch<React.SetStateAction<string>>;
    setBaseMetalCustomerProvided: (customerProvided: boolean) => void;
    setCladMetalCustomerProvided: (customerProvided: boolean) => void;
  }

  const PlateAndTubeSelections = (props: PlateAndTubeSelectionsProps) => {
    const {
      baseMetalType,
      baseMetalTypeOptions,
      cladMetalType,
      cladMetalTypeOptions,
      cutMethod,
      cutMethodInputOptions,
      finishedBaseMetalThickness,
      finishedCladMetalThickness,
      finishedLength,
      finishedWidth,
      isDarkMode,
      isDncSelected,
      isMultiShot,
      isPiecesSelected,
      itemType,
      itemTypeHandler,
      machining,
      manufacturingSite,
      multiShotMetalsStateRef,
      multiShotType,
      setBaseMetalType,
      setCladMetalType,
      setCutMethod,
      setFinishedBaseMetalThickness,
      setFinishedLength,
      setFinishedWidth,
      setFinishedCladMetalThickness,
      setIsDncSelected,
      setIsPiecesSelected,
      setMachining,
      setMultiShotType,
      setShape,
      shape,
      shapeOptions,
      itemTypeOptions,
      unitOfMeasure,
      handleMetalChange,
      setBaseMetalProposalType,
      setCladMetalProposalType,
      setBaseMetalCustomerProvided,
      setCladMetalCustomerProvided,
    } = props;

    return (
      <div className='selections'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <ItemTypeDropdown
                options={itemTypeOptions}
                itemType={itemType}
                itemTypeHandler={itemTypeHandler}
              />
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                selectionLabel='Shape'
                options={ shapeOptions }
                onIonChange={(shape) => setShape(shape)}
                value={shape}
                skipTab={true}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel='Width'
                  placeholder='0.00'
                  regex={Regexes.DecimalNumber}
                  min='0.01'
                  step='0.01'
                  style={{ width: '63px', marginRight: '6px' }}
                  onBlur={(finishedWidth) => {
                    setFinishedWidth(+finishedWidth);                  }}
                  value={finishedWidth}
                />
                {(shape !== Shapes.Circle) && (
                  <>
                    <IonImg src={!isDarkMode ? closeIcon : closeIconWhite} class='multiply-icon'></IonImg>
                    <LengthField
                      finishedLength={finishedLength}
                      setFinishedLength={setFinishedLength}
                    />
                  </>
                )}
                {itemType?.name === FinishedProductTypes.Tubesheet && (
                <TubesheetMachiningField machining={machining} setMachining={setMachining} itemType={itemType} />)}
                <CutMethodInput
                  isDisabled={!itemType}
                  cutMethodInputOptions={cutMethodInputOptions}
                  cutMethod={cutMethod}
                  setCutMethod={setCutMethod}
                />
              </div>
            </IonCol>
          </IonRow>
          {isMultiShot && (
              <MultiShotSection
                multiShotType={multiShotType}
                setMultiShotType={setMultiShotType}
                uom={unitOfMeasure}
                manufacturingSite={manufacturingSite}
                multiShotMetalsStateRef={multiShotMetalsStateRef}
                handleMetalChange={handleMetalChange}
                setBaseMetalProposalType={setBaseMetalProposalType}
                setCladMetalProposalType={setCladMetalProposalType}
                setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
                setFinishedCladMetalThickness={setFinishedCladMetalThickness}
                setBaseMetalType={setBaseMetalType}
                setCladMetalType={setCladMetalType}
                setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
                setCladMetalCustomerProvided={setCladMetalCustomerProvided}
              />
          )}
          <MetalThicknessSection
            isDisabled={!itemType}
            baseMetalType={baseMetalType}
            setBaseMetalType={setBaseMetalType}
            baseMetalTypeOptions={baseMetalTypeOptions}
            cladMetalType={cladMetalType}
            setCladMetalType={setCladMetalType}
            finishedCladMetalThickness={finishedCladMetalThickness}
            setFinishedCladMetalThickness={setFinishedCladMetalThickness}
            cladMetalTypeOptions={cladMetalTypeOptions}
            isDncSelected={isDncSelected}
            setIsDncSelected={setIsDncSelected}
            isMultiShot={isMultiShot}
            isPiecesSelected={isPiecesSelected}
            setIsPiecesSelected={setIsPiecesSelected}
            finishedBaseMetalThickness={finishedBaseMetalThickness}
            setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
          />
        </IonGrid>
      </div>
    );
  };

  interface HeadSelectionsProps {
    baseMetalType: string;
    baseMetalTypeOptions: string[];
    blankSize: number;
    cladMetalType: string;
    cladMetalTypeOptions: string[];
    crownPlateDiameter: number;
    cutback: number;
    cutMethod: string;
    cutMethodInputOptions: string[];
    disableTwoPieceHeadCheckbox: boolean;
    finishedBaseMetalThickness: number;
    finishedCladMetalThickness: number;
    formingCostPerHead: number;
    freightOptions: string[];
    headDiameter: number;
    headEdgePreparation: string;
    headFormingType: HeadFormingTypes;
    headFreightZone: string;
    idOrOd: string;
    insideDishRadius: number;
    insideKnuckleRadius: number;
    isDncSelected: boolean;
    isMultiShot: boolean;
    isPiecesSelected: boolean;
    isTwoPieceHead: boolean;
    itemType: FinishedGood;
    itemTypeHandler: (itemTypeName: string) => void;
    lengthOfWelds: number;
    manufacturingSite: ManufacturingSites;
    multiShotMetalsStateRef: React.MutableRefObject<MultiShotState>;
    multiShotType: MultiShotVariants;
    numberOfPetals: number;
    openDiameter: number;
    setBaseMetalType: React.Dispatch<React.SetStateAction<string>>;
    setBlankSize: React.Dispatch<React.SetStateAction<number>>;
    setCladMetalType: React.Dispatch<React.SetStateAction<string>>;
    setCrownPlateDiameter: React.Dispatch<React.SetStateAction<number>>;
    setCutback: React.Dispatch<React.SetStateAction<number>>;
    setCutMethod: React.Dispatch<React.SetStateAction<string>>;
    setDiameter: React.Dispatch<React.SetStateAction<number>>;
    setFinishedBaseMetalThickness: React.Dispatch<React.SetStateAction<number>>;
    setFinishedCladMetalThickness: React.Dispatch<React.SetStateAction<number>>;
    setFormingCostPerHead: React.Dispatch<React.SetStateAction<number>>;
    setHeadDiameter: React.Dispatch<React.SetStateAction<number>>;
    setHeadEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
    setHeadFormingType: React.Dispatch<React.SetStateAction<string>>;
    setHeadFreightZone: React.Dispatch<React.SetStateAction<string>>;
    setIdOrOd: React.Dispatch<React.SetStateAction<string>>;
    setInsideDishRadius: React.Dispatch<React.SetStateAction<number>>;
    setInsideKnuckleRadius: React.Dispatch<React.SetStateAction<number>>;
    setIsDncSelected: React.Dispatch<React.SetStateAction<boolean>>;
    setIsPiecesSelected: React.Dispatch<React.SetStateAction<boolean>>;
    setLengthOfWelds: React.Dispatch<React.SetStateAction<number>>;
    setMultiShotType: React.Dispatch<React.SetStateAction<string>>;
    setNumberOfPetals: React.Dispatch<React.SetStateAction<number>>;
    setOpenDiameter: React.Dispatch<React.SetStateAction<number>>;
    setShipHeadBlankToFormer: React.Dispatch<React.SetStateAction<number>>;
    setStraightFlange: React.Dispatch<React.SetStateAction<number>>;
    setTimeNeededToFormHead: React.Dispatch<React.SetStateAction<number>>;
    shipHeadBlankToFormer: number;
    straightFlange: number;
    itemTypeOptions: string[];
    timeNeededToFormHead: number;
    twoPieceHandler: (isTwoPieceHead: boolean) => void;
    unitOfMeasure: MeasurementSystems;
    handleMetalChange: (metal: RawMaterial, usage: RawMaterialUsage) => void;
    setBaseMetalProposalType: React.Dispatch<React.SetStateAction<string>>;
    setCladMetalProposalType: React.Dispatch<React.SetStateAction<string>>;
    headFreightChargeToCustomerPerUOM: number;
    setHeadFreightChargeToCustomerPerUOM: React.Dispatch<React.SetStateAction<number>>;
    setBaseMetalCustomerProvided: (customerProvided: boolean) => void;
    setCladMetalCustomerProvided: (customerProvided: boolean) => void;
  }

  const HeadSelections = (props: HeadSelectionsProps) => {
    const {
      baseMetalType,
      baseMetalTypeOptions,
      blankSize,
      cladMetalType,
      cladMetalTypeOptions,
      crownPlateDiameter,
      cutback,
      cutMethod,
      cutMethodInputOptions,
      disableTwoPieceHeadCheckbox,
      finishedBaseMetalThickness,
      finishedCladMetalThickness,
      formingCostPerHead,
      freightOptions,
      headDiameter,
      headEdgePreparation,
      headFormingType,
      headFreightZone,
      idOrOd,
      insideDishRadius,
      insideKnuckleRadius,
      isDncSelected,
      isMultiShot,
      isPiecesSelected,
      isTwoPieceHead,
      itemType,
      itemTypeHandler,
      lengthOfWelds,
      manufacturingSite,
      multiShotMetalsStateRef,
      multiShotType,
      numberOfPetals,
      openDiameter,
      setBaseMetalType,
      setBlankSize,
      setCladMetalType,
      setCrownPlateDiameter,
      setCutback,
      setCutMethod,
      setDiameter,
      setFinishedBaseMetalThickness,
      setFinishedCladMetalThickness,
      setFormingCostPerHead,
      setHeadDiameter,
      setHeadEdgePreparation,
      setHeadFormingType,
      setHeadFreightZone,
      setIdOrOd,
      setInsideDishRadius,
      setInsideKnuckleRadius,
      setIsDncSelected,
      setIsPiecesSelected,
      setLengthOfWelds,
      setMultiShotType,
      setNumberOfPetals,
      setOpenDiameter,
      setShipHeadBlankToFormer,
      setStraightFlange,
      setTimeNeededToFormHead,
      shipHeadBlankToFormer,
      straightFlange,
      itemTypeOptions,
      timeNeededToFormHead,
      twoPieceHandler,
      unitOfMeasure,
      // setBaseMetal,
      // setCladMetal,
      handleMetalChange,
      setBaseMetalProposalType,
      setCladMetalProposalType,
      headFreightChargeToCustomerPerUOM,
      setHeadFreightChargeToCustomerPerUOM,
      setBaseMetalCustomerProvided,
      setCladMetalCustomerProvided,
    } = props;

    const isSegmentalHead = itemType?.name === FinishedProductTypes.HeadSegmental;

        return (
      <div className='selections'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <ItemTypeDropdown
                options={itemTypeOptions}
                itemType={itemType}
                itemTypeHandler={itemTypeHandler}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.DropDown}
                  selectionLabel='Head Forming Type'
                  placeholder='Select type'
                  options={
                    itemType?.selections.find(
                      (selection) => selection.valueType === QuoteLineItemValueTypes.HeadFormingType
                    )?.options
                  }
                  style={{ width: '193px', marginRight: '20px' }}
                  onChange={(headType) => setHeadFormingType(headType.label)}
                  value={headFormingType}
                />
                <div>
                  <SelectionContainer
                    type={SelectionTypes.RadioButtons}
                    labelPosition={SelectionLabelPositions.Left}
                    style={{ display: 'flex' }}
                    groupStyle={{ paddingTop: 'unset', marginTop: '-4px' }}
                    selectionLabel='Diameter'
                    options={
                      itemType?.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.IDorOD)
                        ?.options
                    }
                    onIonChange={(idOrOd) => (setIdOrOd(idOrOd))}
                    value={idOrOd}
                    skipTab={true}
                  />
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    inputType='number'
                    placeholder='0.00'
                    regex={Regexes.DecimalNumber}
                    min='0.01'
                    step='0.01'
                    style={{ width: '193px', marginTop: 'unset' }}
                    onBlur={(diameter) => {
                      setHeadDiameter(diameter);
                      setDiameter(diameter);
                    }}
                    value={headDiameter}
                  />
                </div>
              </div>
            </IonCol>
          </IonRow>
          <HeadFormingSelections
            headFormingType={headFormingType}
            openDiameter={openDiameter}
            setOpenDiameter={setOpenDiameter}
            insideDishRadius={insideDishRadius}
            setInsideDishRadius={setInsideDishRadius}
            headEdgePreparation={headEdgePreparation}
            setHeadEdgePreparation={setHeadEdgePreparation}
            cutback={cutback}
            setCutback={setCutback}
            itemType={itemType}
            straightFlange={straightFlange}
            setStraightFlange={setStraightFlange}
            insideKnuckleRadius={insideKnuckleRadius}
            setInsideKnuckleRadius={setInsideKnuckleRadius}
          />
          {isSegmentalHead && (
              <HeadSegmentalSelections
                crownPlateDiameter={crownPlateDiameter}
                setCrownPlateDiameter={setCrownPlateDiameter}
                numberOfPetals={numberOfPetals}
                setNumberOfPetals={setNumberOfPetals}
                lengthOfWelds={lengthOfWelds}
                setLengthOfWelds={setLengthOfWelds}
              />
          )}
          <IonRow>
            <IonCol class='flex-column'>
              <IonLabel class='metal-subheader'>Costs &amp; Shipping</IonLabel>
              <hr className='subheader-rule' />
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  selectionLabel='Forming Cost Per Head'
                  placeholder='0.00'
                  inputType='number'
                  regex={Regexes.DecimalNumber}
                  min='0.00'
                  style={{ width: '193px', marginRight: '20px' }}
                  onBlur={(cost) => (setFormingCostPerHead(cost))}
                  value={formingCostPerHead}
                />
                <SelectionContainer
                  type={SelectionTypes.DropDown}
                  selectionLabel='Former Freight Zone'
                  placeholder='Zone'
                  options={freightOptions}
                  style={{ width: '90px', marginRight: '20px' }}
                  onChange={(zone) => setHeadFreightZone(zone.label)}
                  value={headFreightZone}
                />
                <HeadFreightChargeToCustomerPerUOMField
                  unitOfMeasure={unitOfMeasure}
                  containerStyle={{ marginTop: '20px' }}
                  headFreightChargeToCustomerPerUOM={headFreightChargeToCustomerPerUOM}
                  setHeadFreightChargeToCustomerPerUOM={setHeadFreightChargeToCustomerPerUOM}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                selectionLabel='Transit time to former'
                placeholder='Weeks'
                inputType='number'
                regex={Regexes.IntegerAboveZero}
                min='1'
                style={{ width: '193px', marginRight: '20px' }}
                onBlur={(weeks) => (setShipHeadBlankToFormer(weeks))}
                value={shipHeadBlankToFormer}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                selectionLabel='Time Needed to Form Head'
                placeholder='Weeks'
                inputType='number'
                regex={Regexes.IntegerAboveZero}
                min='1'
                style={{ width: '193px', marginRight: '20px' }}
                onBlur={(weeks) => (setTimeNeededToFormHead(weeks))}
                value={timeNeededToFormHead}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class='flex-column'>
              <IonLabel class='metal-subheader'>Supply To Former</IonLabel>
              <hr className='subheader-rule' />
              <div className='flex-row'>
                {!isSegmentalHead && (
                  <SelectionContainer
                    type={SelectionTypes.TextInput}
                    selectionLabel='Blank Size'
                    placeholder='0.00'
                    inputType='number'
                    regex={Regexes.DecimalNumber}
                    min='0.01'
                    style={{ width: '193px', marginRight: '20px' }}
                    onBlur={(blankSize) => (setBlankSize(blankSize))}
                    value={blankSize}
                  />
                )}
                <CutMethodInput
                  isDisabled={!itemType}
                  cutMethodInputOptions={cutMethodInputOptions}
                  cutMethod={cutMethod}
                  setCutMethod={setCutMethod}
                />
              </div>
              {/* show if this is not a segmentalHead or multishot */}
              {!(isSegmentalHead || isMultiShot) && (
                <CheckboxItem
                  label='Create a Two Piece Head Out of These Measurements'
                  style={{ width: 'fit-content', marginTop: '10px' }}
                  onIonChange={(isTwoPieceHead) => {
                   twoPieceHandler(isTwoPieceHead);
                  }}
                  checked={isTwoPieceHead}
                  disabled={disableTwoPieceHeadCheckbox}
                  skipTab={true}
                />
              )}
              {isSegmentalHead && (
                  <HeadSegmentalAddPlates
                    unitOfMeasure={unitOfMeasure}
                  />
              )}
            </IonCol>
          </IonRow>
          {isMultiShot && (
            <MultiShotSection
              multiShotType={multiShotType}
              setMultiShotType={setMultiShotType}
              uom={unitOfMeasure}
              manufacturingSite={manufacturingSite}
              multiShotMetalsStateRef={multiShotMetalsStateRef}
              handleMetalChange={handleMetalChange}
              setBaseMetalProposalType={setBaseMetalProposalType}
              setCladMetalProposalType={setCladMetalProposalType}
              setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
              setFinishedCladMetalThickness={setFinishedCladMetalThickness}
              setBaseMetalType={setBaseMetalType}
              setCladMetalType={setCladMetalType}
              setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
              setCladMetalCustomerProvided={setCladMetalCustomerProvided}
            />
          )}
          {!isSegmentalHead && (
          <MetalThicknessSection
            baseMetalType={baseMetalType}
            setBaseMetalType={setBaseMetalType}
            baseMetalTypeOptions={baseMetalTypeOptions}
            cladMetalType={cladMetalType}
            setCladMetalType={setCladMetalType}
            isMultiShot={isMultiShot}
            finishedCladMetalThickness={finishedCladMetalThickness}
            setFinishedCladMetalThickness={setFinishedCladMetalThickness}
            cladMetalTypeOptions={cladMetalTypeOptions}
            isDisabled={!itemType}
            isDncSelected={isDncSelected}
            setIsDncSelected={setIsDncSelected}
            isPiecesSelected={isPiecesSelected}
            setIsPiecesSelected={setIsPiecesSelected}
            finishedBaseMetalThickness={finishedBaseMetalThickness}
            setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
          />)
          }
        </IonGrid>
      </div>
    );
  };

  interface BottomSelectionsProps{
    activeTab: QuoteAddItemSelectionTabs;
    additionalOptions: string[];
    additionaOptionsSelectionHandler: (checked: boolean, option: string) => void;
    baseMetal: RawMaterial;
    baseMetalType: string;
    baseMetalTypeOptions: string[];
    baseMetalUT: BaseMetalUTOption;
    baseMetalUTChangeHandler: (baseMetalUT: string) => void;
    baseUTSelectionOptions: BaseMetalUTOption[];
    blankSize: number;
    bondUT: string;
    bondUTChangeHandler: (bondUT: string) => void;
    bondUTSelectionOptions: string[];
    certificate: string;
    certSelectionOptions: string[];
    cladMetal: RawMaterial;
    cladMetalType: string;
    cladMetalTypeOptions: string[];
    cosmeticGrindingSpecifications: string;
    crownPlateDiameter: number;
    cutback: number;
    cutMethod: string;
    cutMethodInputOptions: string[];
    cylinderDiameter: number;
    cylinderDiameterOptions: string[];
    cylinderEdgePreparation: string;
    cylinderFreightChargeToCustomerPerUOM: number;
    cylinderIdOrOd: string;
    cylinderLength: number;
    cylinderType: string;
    disableTwoPieceHeadCheckbox: boolean;
    finishedBaseMetalThickness: number;
    finishedCladMetalThickness: number;
    finishedLength: number
    finishedWidth: number
    formingCostPerHead: number;
    freightChargeToRollerPerUOM: number;
    freightOptions: string[];
    headDiameter: number;
    headEdgePreparation: string;
    headFormingType: HeadFormingTypes;
    headFreightChargeToCustomerPerUOM: number;
    headFreightZone: string;
    idOrOd: string;
    insideDishRadius: number;
    insideKnuckleRadius: number;
    isCosmeticGrindingSpecDisabled: boolean;
    isDarkMode: boolean;
    isDncSelected: boolean;
    isMultiShot: boolean;
    isPiecesSelected: boolean;
    isTwoPieceHead: boolean;
    itemType: FinishedGood;
    itemTypeOptions: string[];
    itemTypeHandler: (itemTypeName: string) => void;
    lengthOfWelds: number;
    machining: string
    manufacturingSite: ManufacturingSites;
    multiShotMetalsStateRef: React.MutableRefObject<MultiShotState>;
    multiShotType: MultiShotVariants;
    notesPrintQuoteToForm: string;
    numberOfPetals: number;
    openDiameter: number;
    plateSizesSupplyFormerLength: number;
    plateSizesSupplyFormerWidth: number;
    postCladTestingOptions: string[];
    postCladTestingOptionsChangeHandler: (checked: boolean, option: string) => void;
    postCladTestingSelectionOptions: string[];
    quantityPerCylinder: number;
    rollingCostPerCylinder: number;
    setActiveTab: React.Dispatch<React.SetStateAction<QuoteAddItemSelectionTabs>>
    setBaseMetalType: React.Dispatch<React.SetStateAction<string>>;
    setBlankSize: React.Dispatch<React.SetStateAction<number>>;
    setCertificate: React.Dispatch<React.SetStateAction<string>>;
    setCladMetalType: React.Dispatch<React.SetStateAction<string>>;
    setCosmeticGrindingSpecifications: React.Dispatch<React.SetStateAction<string>>;
    setCrownPlateDiameter: React.Dispatch<React.SetStateAction<number>>;
    setCutback: React.Dispatch<React.SetStateAction<number>>;
    setCutMethod: React.Dispatch<React.SetStateAction<string>>;
    setCylinderDiameter: React.Dispatch<React.SetStateAction<number>>;
    setCylinderEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
    setCylinderFreightChargeToCustomerPerUOM: React.Dispatch<React.SetStateAction<number>>;
    setCylinderIdOrOd: React.Dispatch<React.SetStateAction<string>>;
    setCylinderLength: React.Dispatch<React.SetStateAction<number>>;
    setCylinderType: React.Dispatch<React.SetStateAction<string>>;
    setDiameter:  React.Dispatch<React.SetStateAction<number>>;
    setFinishedBaseMetalThickness: React.Dispatch<React.SetStateAction<number>>;
    setFinishedLength: React.Dispatch<React.SetStateAction<number>>
    setFinishedWidth: React.Dispatch<React.SetStateAction<number>>
    setFinishedCladMetalThickness: React.Dispatch<React.SetStateAction<number>>;
    setFormingCostPerHead: React.Dispatch<React.SetStateAction<number>>;
    setFreightChargeToRollerPerUOM: React.Dispatch<React.SetStateAction<number>>;
    setHeadDiameter: React.Dispatch<React.SetStateAction<number>>;
    setHeadEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
    setHeadFormingType: React.Dispatch<React.SetStateAction<string>>;
    setHeadFreightChargeToCustomerPerUOM: React.Dispatch<React.SetStateAction<number>>;
    setHeadFreightZone: React.Dispatch<React.SetStateAction<string>>;
    setIdOrOd: React.Dispatch<React.SetStateAction<string>>;
    setInsideDishRadius: React.Dispatch<React.SetStateAction<number>>;
    setInsideKnuckleRadius: React.Dispatch<React.SetStateAction<number>>;
    setIsDncSelected: React.Dispatch<React.SetStateAction<boolean>>;
    setIsPiecesSelected: React.Dispatch<React.SetStateAction<boolean>>;
    setLengthOfWelds: React.Dispatch<React.SetStateAction<number>>;
    setMachining: React.Dispatch<React.SetStateAction<string>>
    setMultiShotType: React.Dispatch<React.SetStateAction<string>>;
    setNotesPrintQuoteToForm: React.Dispatch<React.SetStateAction<string>>;
    setNumberOfPetals: React.Dispatch<React.SetStateAction<number>>;
    setOpenDiameter: React.Dispatch<React.SetStateAction<number>>;
    setPlateSizesSupplyFormerLength: React.Dispatch<React.SetStateAction<number>>;
    setPlateSizesSupplyFormerWidth: React.Dispatch<React.SetStateAction<number>>;
    setQuantityPerCylinder: React.Dispatch<React.SetStateAction<number>>;
    setRollingCostPerCylinder: React.Dispatch<React.SetStateAction<number>>;
    setShape: React.Dispatch<React.SetStateAction<string>>
    setShipHeadBlankToFormer: React.Dispatch<React.SetStateAction<number>>;
    setStraightFlange: React.Dispatch<React.SetStateAction<number>>;
    setTimeNeededToFormHead: React.Dispatch<React.SetStateAction<number>>;
    setTimeToRoll: React.Dispatch<React.SetStateAction<string>>;
    setTransitTimeToRoller: React.Dispatch<React.SetStateAction<string>>;
    shape: string
    shapeOptions: string[],
    shipHeadBlankToFormer: number;
    specChangeHandler: (specification: string) => void;
    specification: string;
    specTestSelectionOptions: string[];
    straightFlange: number;
    timeNeededToFormHead: number;
    timeToRoll: string;
    transitTimeToRoller: string;
    twoPieceHandler: (isTwoPieceHead: boolean) => void;
    unitOfMeasure: MeasurementSystems;
    handleMetalChange: (metal: RawMaterial, usage: RawMaterialUsage) => void;
    setBaseMetalProposalType: React.Dispatch<React.SetStateAction<string>>;
    setCladMetalProposalType: React.Dispatch<React.SetStateAction<string>>;
    updatingPostCladTestingOptions: boolean;
    setBaseMetalCustomerProvided: (customerProvided: boolean) => void;
    setCladMetalCustomerProvided: (customerProvided: boolean) => void;
  }

  const BottomSelections = (props: BottomSelectionsProps) => {
    const {
      activeTab,
      additionalOptions,
      additionaOptionsSelectionHandler,
      baseMetal,
      baseMetalType,
      baseMetalTypeOptions,
      baseMetalUT,
      baseMetalUTChangeHandler,
      baseUTSelectionOptions,
      blankSize,
      bondUT,
      bondUTChangeHandler,
      bondUTSelectionOptions,
      certificate,
      certSelectionOptions,
      cladMetal,
      cladMetalType,
      cladMetalTypeOptions,
      cosmeticGrindingSpecifications,
      crownPlateDiameter,
      cutback,
      cutMethod,
      cutMethodInputOptions,
      cylinderDiameter,
      cylinderDiameterOptions,
      cylinderEdgePreparation,
      cylinderFreightChargeToCustomerPerUOM,
      cylinderIdOrOd,
      cylinderLength,
      cylinderType,
      disableTwoPieceHeadCheckbox,
      finishedBaseMetalThickness,
      finishedCladMetalThickness,
      finishedLength,
      finishedWidth,
      formingCostPerHead,
      freightChargeToRollerPerUOM,
      freightOptions,
      headDiameter,
      headEdgePreparation,
      headFormingType,
      headFreightChargeToCustomerPerUOM,
      headFreightZone,
      idOrOd,
      insideDishRadius,
      insideKnuckleRadius,
      isCosmeticGrindingSpecDisabled,
      isDarkMode,
      isDncSelected,
      isMultiShot,
      isPiecesSelected,
      isTwoPieceHead,
      itemType,
      itemTypeHandler,
      lengthOfWelds,
      machining,
      manufacturingSite,
      multiShotMetalsStateRef,
      multiShotType,
      notesPrintQuoteToForm,
      numberOfPetals,
      openDiameter,
      plateSizesSupplyFormerLength,
      plateSizesSupplyFormerWidth,
      postCladTestingOptions,
      postCladTestingOptionsChangeHandler,
      postCladTestingSelectionOptions,
      quantityPerCylinder,
      rollingCostPerCylinder,
      setActiveTab,
      setBaseMetalType,
      setBlankSize,
      setCertificate,
      setCladMetalType,
      setCosmeticGrindingSpecifications,
      setCrownPlateDiameter,
      setCutback,
      setCutMethod,
      setCylinderDiameter,
      setCylinderEdgePreparation,
      setCylinderFreightChargeToCustomerPerUOM,
      setCylinderIdOrOd,
      setCylinderLength,
      setCylinderType,
      setDiameter,
      setFinishedBaseMetalThickness,
      setFinishedLength,
      setFinishedWidth,
      setFinishedCladMetalThickness,
      setFormingCostPerHead,
      setFreightChargeToRollerPerUOM,
      setHeadDiameter,
      setHeadEdgePreparation,
      setHeadFormingType,
      setHeadFreightChargeToCustomerPerUOM,
      setHeadFreightZone,
      setIdOrOd,
      setInsideDishRadius,
      setInsideKnuckleRadius,
      setIsDncSelected,
      setIsPiecesSelected,
      setLengthOfWelds,
      setMachining,
      setMultiShotType,
      setNotesPrintQuoteToForm,
      setNumberOfPetals,
      setOpenDiameter,
      setPlateSizesSupplyFormerLength,
      setPlateSizesSupplyFormerWidth,
      setQuantityPerCylinder,
      setRollingCostPerCylinder,
      setShape,
      setShipHeadBlankToFormer,
      setStraightFlange,
      setTimeNeededToFormHead,
      setTimeToRoll,
      setTransitTimeToRoller,
      shape,
      shapeOptions,
      shipHeadBlankToFormer,
      specChangeHandler,
      specification,
      specTestSelectionOptions,
      straightFlange,
      itemTypeOptions,
      timeNeededToFormHead,
      timeToRoll,
      transitTimeToRoller,
      twoPieceHandler,
      unitOfMeasure,
      handleMetalChange,
      setBaseMetalProposalType,
      setCladMetalProposalType,
      updatingPostCladTestingOptions,
      setBaseMetalCustomerProvided,
      setCladMetalCustomerProvided,
    } = props;

    const Tab = (props: { tab: QuoteAddItemSelectionTabs; }) => {
      return (
        <IonLabel class={classNames({
          'tab': true,
          'active': activeTab === props.tab
        })} data-testid={'QAAddQuoteItemPopoverTab_' + props.tab} onClick={() => setActiveTab(props.tab)}
        >{props.tab}</IonLabel>
      );
    };

    const TabsContainer = () => {
      const tabs = [QuoteAddItemSelectionTabs.Description, QuoteAddItemSelectionTabs.Specifications];
      return (
        <>
          <div className='tabs-container'>
            {tabs.map((tab, index) => (
              <Tab tab={tab} key={index}/>
            ))}
          </div>
          <hr className='mid-rule'/>
        </>
      );
    };

    switch(activeTab) {
      case QuoteAddItemSelectionTabs.Description:
        switch (itemType?.name) {
        case FinishedProductTypes.Head:
        case FinishedProductTypes.HeadSegmental:
          return (
            <>
              <TabsContainer />
              <HeadSelections
                baseMetalType={baseMetalType}
                baseMetalTypeOptions={baseMetalTypeOptions}
                blankSize={blankSize}
                cladMetalType={cladMetalType}
                cladMetalTypeOptions={cladMetalTypeOptions}
                crownPlateDiameter={crownPlateDiameter}
                cutback={cutback}
                cutMethod={cutMethod}
                cutMethodInputOptions={cutMethodInputOptions}
                disableTwoPieceHeadCheckbox={disableTwoPieceHeadCheckbox}
                finishedBaseMetalThickness={finishedBaseMetalThickness}
                finishedCladMetalThickness={finishedCladMetalThickness}
                formingCostPerHead={formingCostPerHead}
                freightOptions={freightOptions}
                headDiameter={headDiameter}
                headEdgePreparation={headEdgePreparation}
                headFormingType={headFormingType}
                headFreightChargeToCustomerPerUOM={headFreightChargeToCustomerPerUOM}
                headFreightZone={headFreightZone}
                idOrOd={idOrOd}
                insideDishRadius={insideDishRadius}
                insideKnuckleRadius={insideKnuckleRadius}
                isDncSelected={isDncSelected}
                isMultiShot={isMultiShot}
                isPiecesSelected={isPiecesSelected}
                isTwoPieceHead={isTwoPieceHead}
                itemType={itemType}
                itemTypeHandler={itemTypeHandler}
                lengthOfWelds={lengthOfWelds}
                manufacturingSite={manufacturingSite}
                multiShotMetalsStateRef={multiShotMetalsStateRef}
                multiShotType={multiShotType}
                numberOfPetals={numberOfPetals}
                openDiameter={openDiameter}
                setBaseMetalType={setBaseMetalType}
                setBlankSize={setBlankSize}
                setCladMetalType={setCladMetalType}
                setCrownPlateDiameter={setCrownPlateDiameter}
                setCutback={setCutback}
                setCutMethod={setCutMethod}
                setDiameter={setDiameter}
                setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
                setFinishedCladMetalThickness={setFinishedCladMetalThickness}
                setFormingCostPerHead={setFormingCostPerHead}
                setHeadDiameter={setHeadDiameter}
                setHeadEdgePreparation={setHeadEdgePreparation}
                setHeadFormingType={setHeadFormingType}
                setHeadFreightChargeToCustomerPerUOM={setHeadFreightChargeToCustomerPerUOM}
                setHeadFreightZone={setHeadFreightZone}
                setIdOrOd={setIdOrOd}
                setInsideDishRadius={setInsideDishRadius}
                setInsideKnuckleRadius={setInsideKnuckleRadius}
                setIsDncSelected={setIsDncSelected}
                setIsPiecesSelected={setIsPiecesSelected}
                setLengthOfWelds={setLengthOfWelds}
                setMultiShotType={setMultiShotType}
                setNumberOfPetals={setNumberOfPetals}
                setOpenDiameter={setOpenDiameter}
                setShipHeadBlankToFormer={setShipHeadBlankToFormer}
                setStraightFlange={setStraightFlange}
                setTimeNeededToFormHead={setTimeNeededToFormHead}
                shipHeadBlankToFormer={shipHeadBlankToFormer}
                straightFlange={straightFlange}
                itemTypeOptions={itemTypeOptions}
                timeNeededToFormHead={timeNeededToFormHead}
                twoPieceHandler={twoPieceHandler}
                unitOfMeasure={unitOfMeasure}
                handleMetalChange={handleMetalChange}
                setBaseMetalProposalType={setBaseMetalProposalType}
                setCladMetalProposalType={setCladMetalProposalType}
                setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
                setCladMetalCustomerProvided={setCladMetalCustomerProvided}
              />
            </>
          );
        case FinishedProductTypes.Cylinder:
          return <>
            <TabsContainer/>
            <CylinderSelections
              isDisabled={!itemType}
              unitOfMeasure={unitOfMeasure}
              isDarkMode={isDarkMode}
              itemTypeHandler={itemTypeHandler}
              itemType={itemType}
              cylinderType={cylinderType}
              setCylinderType={setCylinderType}
              cylinderEdgePreparation={cylinderEdgePreparation}
              setCylinderEdgePreparation={setCylinderEdgePreparation}
              cylinderIdOrOd={cylinderIdOrOd}
              setCylinderIdOrOd={setCylinderIdOrOd}
              itemTypeOptions={itemTypeOptions}
              cylinderDiameterOptions={cylinderDiameterOptions}
              cylinderDiameter={cylinderDiameter}
              setCylinderDiameter={setCylinderDiameter}
              cylinderFreightChargeToCustomerPerUOM={cylinderFreightChargeToCustomerPerUOM}
              setCylinderFreightChargeToCustomerPerUOM={setCylinderFreightChargeToCustomerPerUOM}
              cylinderLength={cylinderLength}
              setCylinderLength={setCylinderLength}
              rollingCostPerCylinder={rollingCostPerCylinder}
              setRollingCostPerCylinder={setRollingCostPerCylinder}
              freightChargeToRollerPerUOM={freightChargeToRollerPerUOM}
              setFreightChargeToRollerPerUOM={setFreightChargeToRollerPerUOM}
              transitTimeToRoller={transitTimeToRoller}
              setTransitTimeToRoller={setTransitTimeToRoller}
              timeToRoll={timeToRoll}
              setTimeToRoll={setTimeToRoll}
              notesPrintQuoteToForm={notesPrintQuoteToForm}
              setNotesPrintQuoteToForm={setNotesPrintQuoteToForm}
              quantityPerCylinder={quantityPerCylinder}
              setQuantityPerCylinder={setQuantityPerCylinder}
              plateSizesSupplyFormerWidth={plateSizesSupplyFormerWidth}
              setPlateSizesSupplyFormerWidth={setPlateSizesSupplyFormerWidth}
              plateSizesSupplyFormerLength={plateSizesSupplyFormerLength}
              setPlateSizesSupplyFormerLength={setPlateSizesSupplyFormerLength}
              cutMethodInputOptions={cutMethodInputOptions}
              cutMethod={cutMethod}
              setCutMethod={setCutMethod}
              baseMetalType={baseMetalType}
              setBaseMetalType={setBaseMetalType}
              baseMetalTypeOptions={baseMetalTypeOptions}
              cladMetalType={cladMetalType}
              setCladMetalType={setCladMetalType}
              finishedCladMetalThickness={finishedCladMetalThickness}
              setFinishedCladMetalThickness={setFinishedCladMetalThickness}
              cladMetalTypeOptions={cladMetalTypeOptions}
              isDncSelected={isDncSelected}
              setIsDncSelected={setIsDncSelected}
              isMultiShot={isMultiShot}
              isPiecesSelected={isPiecesSelected}
              setIsPiecesSelected={setIsPiecesSelected}
              finishedBaseMetalThickness={finishedBaseMetalThickness}
              setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
            />
          </>;
        case FinishedProductTypes.Plate:
        case FinishedProductTypes.Tubesheet:
        default:
          return <>
            <TabsContainer/>
            <PlateAndTubeSelections
              baseMetalType={baseMetalType}
              baseMetalTypeOptions={baseMetalTypeOptions}
              cladMetalType={cladMetalType}
              cladMetalTypeOptions={cladMetalTypeOptions}
              cutMethod={cutMethod}
              cutMethodInputOptions={cutMethodInputOptions}
              finishedBaseMetalThickness={finishedBaseMetalThickness}
              finishedCladMetalThickness={finishedCladMetalThickness}
              finishedLength={finishedLength}
              finishedWidth={finishedWidth}
              isDarkMode={isDarkMode}
              isDncSelected={isDncSelected}
              isMultiShot={isMultiShot}
              isPiecesSelected={isPiecesSelected}
              itemType={itemType}
              itemTypeHandler={itemTypeHandler}
              machining={machining}
              manufacturingSite={manufacturingSite}
              multiShotMetalsStateRef={multiShotMetalsStateRef}
              multiShotType={multiShotType}
              setBaseMetalType={setBaseMetalType}
              setCladMetalType={setCladMetalType}
              setCutMethod={setCutMethod}
              setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
              setFinishedLength={setFinishedLength}
              setFinishedWidth={setFinishedWidth}
              setFinishedCladMetalThickness={setFinishedCladMetalThickness}
              setIsDncSelected={setIsDncSelected}
              setIsPiecesSelected={setIsPiecesSelected}
              setMachining={setMachining}
              setMultiShotType={setMultiShotType}
              setShape={setShape}
              shape={shape}
              shapeOptions={shapeOptions}
              itemTypeOptions={itemTypeOptions}
              unitOfMeasure={unitOfMeasure}
              handleMetalChange={handleMetalChange}
              setBaseMetalProposalType={setBaseMetalProposalType}
              setCladMetalProposalType={setCladMetalProposalType}
              setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
              setCladMetalCustomerProvided={setCladMetalCustomerProvided}
            />
          </>;
        }
      case QuoteAddItemSelectionTabs.Specifications:
        return (
          <>
            <TabsContainer/>
            <div className='selections'>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <div className='flex-row'>
                      <SelectionContainer
                        type={SelectionTypes.DropDown}
                        selectionLabel='Specification'
                        placeholder='Select specification'
                        options={specTestSelectionOptions}
                        style={{ width: '193px', marginRight: '20px' }}
                        containerStyle={{ marginBottom: '5px' }}
                        defaultValue={specification}
                        value={specification}
                        onChange={(specification) => {
                          specChangeHandler(specification.label);
                        }}
                        isDisabled={!cladMetal}
                      />
                      <SelectionContainer
                        type={SelectionTypes.DropDown}
                        selectionLabel='Certificate'
                        placeholder='Select cert'
                        options={certSelectionOptions}
                        style={{ width: '513px' }}
                        containerStyle={{ marginBottom: '5px' }}
                        defaultValue={certificate}
                        value={certificate}
                        onChange={(certificate) => setCertificate(certificate.label)}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className='flex-row'>
                      <SelectionContainer
                        type={SelectionTypes.DropDown}
                        selectionLabel='Base Metal UT'
                        placeholder='Select base metal UT'
                        options={baseUTSelectionOptions}
                        style={{ width: '337px', marginRight: '52px' }}
                        containerStyle={{ marginBottom: '10px' }}
                        defaultValue={baseMetalUT?.option}
                        value={baseMetalUT?.option}
                        onChange={(baseMetalUT) => baseMetalUTChangeHandler(baseMetalUT.label)}
                        isDisabled={!baseMetal}
                      />
                      <SelectionContainer
                        type={SelectionTypes.DropDown}
                        selectionLabel='Bond UT'
                        placeholder='Select bond UT'
                        options={bondUTSelectionOptions}
                        style={{ width: '337px' }}
                        containerStyle={{ marginBottom: '10px' }}
                        value={bondUT}
                        onChange={(bondUT) => {
                          bondUTChangeHandler(bondUT.label);
                        }}
                        isDisabled={!specification}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {!updatingPostCladTestingOptions &&
                      <SelectionContainer
                        type={SelectionTypes.CheckboxList}
                        selectionLabel='Post-Clad Testing Options'
                        options={postCladTestingSelectionOptions}
                        style={{ marginBottom: '10px' }}
                        itemsPerColumn={5}
                        onIonChange={(checked, postCladTestingOption) => {
                          try {
                            postCladTestingOptionsChangeHandler(checked, postCladTestingOption);
                          } catch (error) {
                            console.error(error);
                          }
                        }}
                        checked={(postCladTestingOption) => postCladTestingOptions.includes(postCladTestingOption)}
                      />
                    }
                  </IonCol>
                  <IonCol class='flex-column additional-options'>
                    <SelectionContainer
                      type={SelectionTypes.CheckboxList}
                      selectionLabel='Additional Options'
                      options={[ QuoteLineItemAdditionalOptions.HeatTreatment, QuoteLineItemAdditionalOptions.CosmeticGrinding ]}
                      itemsPerColumn={3}
                      onIonChange={(checked, additionalOption) => {
                        try {
                          additionaOptionsSelectionHandler(checked, additionalOption);
                        } catch (error) {
                          console.error(error);
                        }
                      }}
                      checked={(additionalOption) => additionalOptions.includes(additionalOption)}
                    />
                    <SelectionContainer
                      type={SelectionTypes.TextArea}
                      placeholder='Cosmetic grinding specifications (optional)...'
                      style={{ width: '337px' }}
                      rows={4}
                      onBlur={(cosmeticGrindingSpecifications) => setCosmeticGrindingSpecifications(cosmeticGrindingSpecifications)}
                      value={cosmeticGrindingSpecifications}
                      isDisabled={isCosmeticGrindingSpecDisabled}
                      textAreaType='Cosmetic Grinding Specs'
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </>
        );
    }
  };

  interface HeadFormingSelectionsProps {
    headFormingType: HeadFormingTypes
    openDiameter: number;
    setOpenDiameter: React.Dispatch<React.SetStateAction<number>>;
    insideDishRadius: number;
    setInsideDishRadius: React.Dispatch<React.SetStateAction<number>>;
    headEdgePreparation: string;
    setHeadEdgePreparation: React.Dispatch<React.SetStateAction<string>>;
    cutback: number;
    setCutback: React.Dispatch<React.SetStateAction<number>>;
    itemType: FinishedGood;
    straightFlange: number;
    setStraightFlange: React.Dispatch<React.SetStateAction<number>>;
    insideKnuckleRadius: number;
    setInsideKnuckleRadius: React.Dispatch<React.SetStateAction<number>>;
  }

  const HeadFormingSelections = (props: HeadFormingSelectionsProps) => {
    const {
      headFormingType,
      openDiameter,
      setOpenDiameter,
      insideDishRadius,
      setInsideDishRadius,
      headEdgePreparation,
      setHeadEdgePreparation,
      cutback,
      setCutback,
      itemType,
      straightFlange,
      setStraightFlange,
      insideKnuckleRadius,
      setInsideKnuckleRadius,
    } = props;
    switch (headFormingType) {
      case HeadFormingTypes.Hemi:
        return <HemiSelections
                  openDiameter={openDiameter}
                  setOpenDiameter={setOpenDiameter}
                  insideDishRadius={insideDishRadius}
                  setInsideDishRadius={setInsideDishRadius}
                  headEdgePreparation={headEdgePreparation}
                  setHeadEdgePreparation={setHeadEdgePreparation}
                  cutback={cutback}
                  setCutback={setCutback}
                  itemType={itemType}
               />;
      case HeadFormingTypes.FandD:
        return <FandDSelections
                straightFlange={straightFlange}
                setStraightFlange={setStraightFlange}
                headEdgePreparation={headEdgePreparation}
                setHeadEdgePreparation={setHeadEdgePreparation}
                insideDishRadius={insideDishRadius}
                setInsideDishRadius={setInsideDishRadius}
                insideKnuckleRadius={insideKnuckleRadius}
                setInsideKnuckleRadius={setInsideKnuckleRadius}
                itemType={itemType}
               />;
      case HeadFormingTypes.DOH:
        return (<DOHSelections
                  openDiameter={openDiameter}
                  setOpenDiameter={setOpenDiameter}
                  headEdgePreparation={headEdgePreparation}
                  setHeadEdgePreparation={setHeadEdgePreparation}
                  insideDishRadius={insideDishRadius}
                  setInsideDishRadius={setInsideDishRadius}
                  itemType={itemType}
                />);
      case HeadFormingTypes.TwoToOne:
        return <TwoToOneSelections
                  straightFlange={straightFlange}
                  setStraightFlange={setStraightFlange}
                  headEdgePreparation={headEdgePreparation}
                  setHeadEdgePreparation={setHeadEdgePreparation}
                  itemType={itemType}
               />;
      default:
        return <TwoToOneSelections
                  straightFlange={straightFlange}
                  setStraightFlange={setStraightFlange}
                  headEdgePreparation={headEdgePreparation}
                  setHeadEdgePreparation={setHeadEdgePreparation}
                  itemType={itemType}
               />;
    }
  };

  interface HeadFreightChargeToCustomerPerUOMFieldProps {
    unitOfMeasure: MeasurementSystems;
    containerStyle: React.CSSProperties;
    headFreightChargeToCustomerPerUOM: number;
    setHeadFreightChargeToCustomerPerUOM: React.Dispatch<React.SetStateAction<number>>;
  }
  const HeadFreightChargeToCustomerPerUOMField = (props: HeadFreightChargeToCustomerPerUOMFieldProps) => {
    const { unitOfMeasure, containerStyle, headFreightChargeToCustomerPerUOM, setHeadFreightChargeToCustomerPerUOM } = props;
    const isMetricUOM = unitOfMeasure === MeasurementSystems.Metric;

    return (
      <SelectionContainer
        type={SelectionTypes.TextInput}
        inputType='number'
        selectionLabel={`Freight Charge to Customer Per ${!isMetricUOM ? 'lb' : 'kg'}`}
        placeholder='0.00'
        regex={Regexes.DecimalNumber}
        min='0.01'
        step='0.01'
        containerStyle={containerStyle}
        style={{ width: '193px', marginRight: '20px' }}
        onBlur={cost => setHeadFreightChargeToCustomerPerUOM(cost)}
        value={headFreightChargeToCustomerPerUOM}
      />
    );
  };
