import { Invoice } from '@interfaces/invoice';
import moment from 'moment';
import { getBadgeText, getStatusImageFromAPIStatus, getStatusSortingOrderFromAPIStatus } from '@shared/utils';

// Maps invoice properties so that the UI can consume the invoice
export const mapInvoice = async (invoice: Invoice, lastViewed?: any, darkMode?: boolean) => {
  return {
    ...invoice,
    statusImgSrc: getStatusImageFromAPIStatus(invoice.Portal_Status__c, darkMode),
    statusSortingOrder: getStatusSortingOrderFromAPIStatus(invoice.Portal_Status__c),
    Invoice_Date__c: invoice.Invoice_Date__c ? moment(invoice.Invoice_Date__c).format('L') : '',
    Invoice_Due_Date__c: invoice.Invoice_Due_Date__c || '',
    badgeText: await getBadgeText(invoice.Id, new Date(invoice.CreatedDate), new Date(invoice.LastModifiedDate), lastViewed)
  };
};

// Finds the invoice based on the invoice id
export const findInvoiceById = async (invoices: Invoice[], id: string) => {
  const invoice = invoices.find(invoice => invoice.Id === id);
  return  invoice ? await mapInvoice(invoice) : null;
};