import React, { useEffect } from 'react';
import { IonList, IonRadioGroup, IonListHeader, IonLabel, IonItem, IonRadio, IonItemDivider } from '@ionic/react';

import './app-radio-button.scss';

interface Option {
  value: any;
  label: string;
  children?: React.ReactNode | JSX.Element;
};

interface Props {
  selected?: any;
  onChange?: (value) => void;
  options: Option[] | string[];
  disabled?: boolean;
  name?: string;
  defaultValue?: string;
  className?: string;
}

const mapStringToOption = (str: string): Option => {
  return {
    label: str,
    value: str,
  }
};

const AppRadioButtons = ({ options, selected, onChange, disabled, name = '', defaultValue, className }: Props) => {
  const _options: any = options.length > 0 && typeof options[0] === 'string'
    ? (options as string[]).map(mapStringToOption)
    : options;

  useEffect(() => {
    if (defaultValue && !selected && onChange) {
      onChange(defaultValue);
    }
  }, [selected, defaultValue]);

  return <IonList className={`app-radio-buttons ${className}`}>
    <IonRadioGroup key={selected} allowEmptySelection={true} value={selected} onIonChange={(e) => onChange && onChange(e.detail.value)}>
      {
        _options.map((option: Option, index: Number) => {
          const k = `${name.replace(' ', '-')}-radio-buttons-${index}-${option.label.replace(' ', '-')}`;
          return <IonItem lines="none" key={k}>
            <IonLabel>{option.label}</IonLabel>
            <IonRadio slot="start" value={option.value} disabled={disabled} />
          </IonItem>
        })
      }
    </IonRadioGroup>
  </IonList>
};

export default AppRadioButtons;