import { ManufacturingSites, MeasurementSystems, NobelcladInhousePackaging } from '@constants';
import { MultiShotItemDetail } from '@interfaces/multi-shot-item-detail';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { Currency } from '@interfaces/salesforce';
import { generateUUID } from '@shared/utils';

// this returns a tuple of [QuoteLineItem, QuoteLineItemDetail]
export const createNewEmptyQuoteLineItemPair = (
  displayId: number | string,
  currencyIsoCode: Currency,
  manufacturingSite: ManufacturingSites,
  exportPackaging: boolean
): [QuoteLineItem, QuoteLineItemDetail] => {
  const itemUUID = generateUUID();
  const detailUUID = generateUUID();
  const newItemDisplayId: string = `${displayId}`; // `${qlis.length + 1}` // the displayId is the line number

  const getEmptyQuoteLineItem = (
    itemUUID: string,
    detailUUID: string,
    newQuoteLineItemDetail: QuoteLineItemDetail
  ): QuoteLineItem => {
    return {
      Line__c: newQuoteLineItemDetail.associatedLineItem.padStart(3, '0'),
      CurrencyIsoCode: newQuoteLineItemDetail.CurrencyIsoCode,
      displayId: newQuoteLineItemDetail.associatedLineItem,
      quoteLineItemDetail: newQuoteLineItemDetail,
      UUID__c: itemUUID,
      detailUUID: detailUUID,
    };
  };

  const getEmptyQuoteLineItemDetail = (
    itemUUID: string,
    detailUUID: string,
    displayId: string,
    currencyIsoCode: Currency,
    manufacturingSite: ManufacturingSites,
    exportPackaging: boolean
  ): QuoteLineItemDetail => {
    return {
      CurrencyIsoCode: currencyIsoCode,
      AdvancedSpecUOM__c:
        manufacturingSite === ManufacturingSites.DE01 ? MeasurementSystems.Metric : MeasurementSystems.Imperial,
      NCInHousePackaging__c: exportPackaging
        ? NobelcladInhousePackaging.Export
        : NobelcladInhousePackaging.StandardDomestic,
      NC_Head_Item_Detail__r: {},
      NC_Outside_Service_Item_Details__r: {},
      NC_Anvil_Item_Details__r: {},
      NC_Flyer_Item_Details__r: {},
      NC_Multi_Shot_Item_Details__r: [{} as MultiShotItemDetail],
      NC_Seam_Weld_Item_Details__r: {},
      NC_Tubesheet_Item_Details__r: {},
      NC_Cylinder_Item_Details__r: {},
      associatedLineItem: displayId,
      UUID__c: detailUUID,
      itemUUID: itemUUID,
    };
  };

  const emptyDetail: QuoteLineItemDetail = getEmptyQuoteLineItemDetail(
    itemUUID,
    detailUUID,
    newItemDisplayId,
    currencyIsoCode,
    manufacturingSite,
    exportPackaging
  );
  const emptyItem: QuoteLineItem = getEmptyQuoteLineItem(itemUUID, detailUUID, emptyDetail);

  // *Note that the QuoteLineItemDetail is already nested within the QuoteLineItem, `emptyItem.emptyDetail`, but is returned separately for convenience
  return [emptyItem, emptyDetail];
};

// usage example:
// const [newQuoteLineItem] = createNewEmptyQuoteLineItem(1, 'USD', ManufacturingSites.DE01, false);
// const [newQuoteLineItem, newQLID] = createNewEmptyQuoteLineItem(1, 'USD', ManufacturingSites.DE01, false);