import { getSelections } from './selections-api';
import * as React from 'react';
import { Selection } from '@interfaces/selection';

const { createContext, useContext } = React;

const SelectionsContext = createContext(null);

export const SelectionsProvider = (props) => {
  const value = {
    getAllSelections: props.getAllSelections || getAllSelections,
  };

  return (
    <SelectionsContext.Provider value={value}>
      {props.children}
    </SelectionsContext.Provider>
  );
};

export const useSelections = () => {
  return useContext(SelectionsContext);
};

// Calls api to get all the selections from cosmos
export const getAllSelections = async(): Promise<Selection[]> => {
  try {
    return await getSelections();
  } catch (error) {
    console.error(error);
    return null;
  }
};
