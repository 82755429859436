import * as React from 'react';
import { IonButton, IonCol, IonGrid, IonImg, IonLabel, IonRow } from '@ionic/react';
import './manual-weld-entry-popover.scss';
import { SelectionTypes, RadiographyTypes, RawMaterialUsage, MeasurementSystems, QuoteLineItemValueTypes, Units, Regexes, ManufacturingSites } from '@constants';
import SelectionContainer from '@components/selection-container/selection-container';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { RawMaterial } from '@interfaces/raw-material';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { calculateUOMValue } from '@shared/quote-utils';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { useLocalUom } from '@hooks/useLocalUom';
import { useEffect } from 'react';

const ManualWeldEntryPopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
    showPopover: boolean;
    event: any;
  }>>,
  lineToEdit: QuoteLineItem,
  detailToEdit: QuoteLineItemDetail,
}) => {
  const { darkMode } = useDarkMode();
  const { dataState } = useDataState();
  const { rawMaterials } = dataState;

  const { quoteState, saveQuoteLineItem } = useQuoteState();
  const {  quote } = quoteState;

  const { localUom, isDisplayMetric } = useLocalUom();

  const hasRadiographyTypeBeenSetRef = React.useRef(false);

  const mmOrInch = isDisplayMetric ? 'mm' : 'inches';

  const cladMetal: RawMaterial = rawMaterials.find(rawMaterial => rawMaterial.name === props.lineToEdit?.CladMetal__c && rawMaterial.usage === RawMaterialUsage.Clad && rawMaterial.dataAreaId.includes(quote.Manufacturing_Site__c));
  const [radiographyType, setRadiographyType] = React.useState(props.detailToEdit.Radiography_Clad__c || cladMetal.radiographyType);
  const [weldingLength, setWeldingLength] = React.useState(localUom === MeasurementSystems.Imperial ? props.lineToEdit.Welding_Length_in__c : props.lineToEdit.Welding_Length_mm__c);
  const cladWidth: number = localUom === MeasurementSystems.Imperial ? props.detailToEdit.Clad_Width_in__c : props.detailToEdit.Clad_Width_mm__c;
  const cladLength: number = localUom === MeasurementSystems.Imperial ? props.detailToEdit.Clad_Length_in__c : props.detailToEdit.Clad_Length_mm__c;
  const fieldsUpdated = [];
  
  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  // Updates the quote line item data
  const updateQuoteLineData = (quoteLineItemToEdit: QuoteLineItem) => {
    if(radiographyType && (radiographyType !== (props.detailToEdit?.Radiography_Clad__c))) {
      quoteLineItemToEdit.quoteLineItemDetail.Radiography_Clad__c = radiographyType as RadiographyTypes;
      fieldsUpdated.push(QuoteLineItemValueTypes.RadiographyClad);
    }
    
    if(weldingLength !== (localUom === MeasurementSystems.Metric ? props.lineToEdit?.Welding_Length_mm__c : props.lineToEdit?.Welding_Length_in__c)) {
      quoteLineItemToEdit.Welding_Length_in__c = calculateUOMValue(localUom, MeasurementSystems.Imperial, weldingLength,  Units.Millimeters, Units.Inches);
      quoteLineItemToEdit.Welding_Length_mm__c = calculateUOMValue(localUom, MeasurementSystems.Metric, weldingLength,  Units.Millimeters, Units.Inches);
      fieldsUpdated.push(QuoteLineItemValueTypes.WeldingLength);
    }
  };

  // This useEffect is for DE quotes for TI clad with prewelding, there should be 100% RT as standard.
  useEffect(() => {
    if (!Object.keys(props.lineToEdit).length) {
      return;
    }
    
    //for DE quotes for TI clad with prewelding, there should be 100% RT as standard.
    const isDE = quote.Manufacturing_Site__c === ManufacturingSites.DE01;
    const isCladMetalTI = !!props.lineToEdit?.CladMetal__c?.match(/-TI$/); // truthy if found; matching regex ending in TI, like this: 265-1-TI // !!RegExp(/-TI$/).exec(props.lineToEdit?.CladMetal__c)

    // current msto logic for weld check: quoteLineItem.Welding__c > 0 || quoteLineItemDetail.SeamWeld__c
    const hasPrewelding =
      (weldingLength > 0 || props.lineToEdit.Welding__c > 0 || props.detailToEdit.SeamWeld__c) ?? false;
    
    const meetsDESpot100 = isDE && isCladMetalTI && hasPrewelding;

    if (meetsDESpot100) {
      if (!radiographyType || !hasRadiographyTypeBeenSetRef.current) {
        setRadiographyType(RadiographyTypes.OneHundredPercent);
      }
     
    }
  }, [props.detailToEdit, props.lineToEdit, quote.Manufacturing_Site__c, radiographyType, weldingLength]);

  

  return (
    <div className="popover manual-weld-entry">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='manual-weld-entry-title' data-testid={'QAManualWeldEntryPopoverManualWeldEntryHeader'}>Manual Weld Entry</IonLabel>
      </div>
      <hr/>
      <div className='selections'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                selectionLabel='Radiography Selection'
                options={[RadiographyTypes.Spot, RadiographyTypes.OneHundredPercent]}
                onIonChange={(radiographyType) => {
                  setRadiographyType(radiographyType);
                  hasRadiographyTypeBeenSetRef.current = true;
                }}
                value={radiographyType}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                selectionLabel={`Total Length of Weld(s) Per Clad (${mmOrInch})`}
                placeholder='0.00'
                regex={Regexes.DecimalNumber}
                min='0.01'
                step='0.01'
                style={{ width: '193px' }}
                onBlur={(weldingLength) => setWeldingLength(+weldingLength)}
                formatter={(value) => {
                  return value?.toFixed(3);
                }}
                value={weldingLength}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                selectionLabel={`Requesting Width (${mmOrInch})`}
                placeholder='0.00'
                style={{ width: '193px' }}
                formatter={(value) => {
                  return value?.toFixed(3);
                }}
                value={cladWidth}
                isDisabled={true}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                selectionLabel={`Requesting Length (${mmOrInch})`}
                placeholder='0.00'
                style={{ width: '193px' }}
                formatter={(value) => {
                  return value?.toFixed(3);
                }}
                value={cladLength}
                isDisabled={true}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <hr className='bottom-rule'/>
      <div className="cta-button-container">
        <IonButton color="primary" class="save-button" data-testid="QAManualWeldEntryPopoverSaveButton" onClick={() => {
          const quoteLineItemToEdit = { ...props.lineToEdit };
          updateQuoteLineData(quoteLineItemToEdit);
          
          saveQuoteLineItem(new Map(), fieldsUpdated, quoteLineItemToEdit);

          dismissModal();
        }}
        >
          Save
        </IonButton>
        <IonButton color="secondary" class="cancel-button" onClick={() => dismissModal()}>Cancel</IonButton>
      </div>
    </div>
  );
};

export default ManualWeldEntryPopover;