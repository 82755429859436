import { getQuote, getQuoteData, updateQuote } from './quotes-api';
import * as React from 'react';
import { Quote } from '@interfaces/quote';
import { useMemo } from 'react';
import { QuoteDataType } from '@context/quote-context';

const { createContext, useContext } = React;

const QuotesContext = createContext(null);

export const QuotesProvider = (props) => {
  const value = useMemo(() => ({
    getQuoteById: props.getQuoteById || getQuoteById,
    updateFullQuote: props.updateFullQuote || updateFullQuote,
    getQuoteDataById: props.getQuoteDataById || getQuoteDataById,
  }), [props.getQuoteById, props.updateFullQuote, props.getQuoteDataById]);

  return (
    <QuotesContext.Provider value={value}>
      {props.children}
    </QuotesContext.Provider>
  );
};

export const useQuotes = () => {
  return useContext(QuotesContext);
};

// Calls api to get quote from cosmos
export const getQuoteById = async(quoteId: string): Promise<Quote> => {
  try {
    return await getQuote(quoteId);
  } catch (error) {
    console.error(error);
    throw Error(error);
  }
};

// Calls api to update quote in cosmos
export const updateFullQuote = async(quote: QuoteDataType, id: string): Promise<any> => {
  try {
    console.log('saving to cosmos: ', quote);
    return await updateQuote(quote, id);
  } catch (error) {
    console.error(error);
    throw Error(error);
  }
};

// Calls api to get quote data from cosmos or salesforce
export const getQuoteDataById = async (id: string): Promise<any> => {
  try {
    console.log('retrieving quote data from the api');
    return await getQuoteData(id);
  } catch (error) {
    console.error(error);
    throw Error(error);
  }
};
