import { Observable, BehaviorSubject } from 'rxjs';
import Account from '@interfaces/account';
import Contact from '@interfaces/contact';
import User from '@interfaces/user';

const user = new BehaviorSubject<User>(null);
const contact = new BehaviorSubject<Contact>(null);
const account = new BehaviorSubject<Account>(null);

export const user$ = (): Observable<User> => {
  return user.asObservable();
};

export const contact$ = (): Observable<Contact> => {
  return contact.asObservable();
};

export const account$ = (): Observable<Account> => {
  return account.asObservable();
};

export function setContact(contactToSet: Contact) {
  contact.next(contactToSet);
}

export function setAccount(accountToSet: Account) {
  account.next(accountToSet);
}

export function setUser(userToSet: User) {
  user.next(userToSet);
}