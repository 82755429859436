import * as React from 'react';
import { useNavigation } from '@services/navigation-service';
import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import './footer.scss';
import dmcLogo from '@assets/icon/dmc.png';
import dmcLogoGray from '@assets/icon/dmc_gray.png';
import logoLinkedin from '@assets/icon/LinkedIn.svg';
import logoYoutube from '@assets/icon/youtube.svg';
import { usePlatform } from '@services/platform-service';
import { useDarkMode } from '@hooks/useDarkMode';
 
const Footer = () => {
  const platform = usePlatform();

  const [linkMap, setLinkMap] = React.useState(undefined);
  const [year, setYear] = React.useState(undefined);
  const { darkMode } = useDarkMode();

  React.useEffect(() => {
    setLinkMap(navigation.getFooterNavOptions());
    setYear(new Date().getFullYear());
  }, []);

  const navigation = useNavigation();

  const navTo = (tag: string) => {
    const url = () => {
      switch (tag) {
      case 'linkedin': return 'https://www.linkedin.com/company/nobelclad/';
      case 'youtube': return 'https://www.youtube.com/channel/UCWHIEHr0G98H-LYOowWIRCA/featured';
      default: return tag;
      }
    };
    window.open(url(), '_blank');
  };

  const linkGroups = () => {
    if(linkMap) {
      return Array.from(linkMap.keys()) as string[];
    }
    return [];
  };

  const links = (key: string) => {
    return linkMap.get(key);
  };

  return (
    <>
      <section className="footer-container">
        <IonGrid class="footer-grid max-width-container">
          <IonRow class="footer-link-row">
            {linkGroups().map((linkCol, index) => (
              <IonCol size={!platform.isMobile() ? '2.6' : '11'} key={index}>
                <h5 className="link-group-name">{linkCol}</h5>
                {links(linkCol).map((link, index) => (
                  <div className="link-cell" key={index}>
                    <a onClick={() => navTo(link.href)} className="link">{link.displayName}</a>
                  </div>
                ))}
              </IonCol>
            ))}
            <IonCol size={!platform.isMobile() ? '2.6' : '11'} class="social-links">
              <div className="exolinks">
                <h5 className="link-group-name follow-us">Follow Us</h5>
                <div className="link-buttons">
                  <IonButton slot="icon-only" shape="round" color={!darkMode ? 'dark' : 'light'} onClick={() => navTo('linkedin')} data-testid="QAFooterLinkedInLink">
                    <img src={logoLinkedin} />
                  </IonButton>
                  <IonButton slot="icon-only" shape="round" color={!darkMode ? 'dark' : 'light'} onClick={() => navTo('youtube')} data-testid="QAFooterYoutubeLink">
                    <img src={logoYoutube} />
                  </IonButton>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </section>
      <section className="sub footer-container">
        <div className="company-info">
          <img className="icon" src={!darkMode ? dmcLogo : dmcLogoGray} />
          <div className="copy-right">© {year} NobelClad, All Rights Reserved</div>
        </div>
      </section>
    </>
  );
};

export default Footer;