import { getOutsideServiceCosts } from './outside-service-costs-api';
import * as React from 'react';
import { OutsideServiceCosts } from '@interfaces/outside-service-costs';

const { createContext, useContext } = React;

const OutsideServiceCostsContext = createContext(null);

export const OutsideServiceCostsProvider = (props) => {
  const value = {
    getAllOutsideServiceCosts: props.getAllOutsideServiceCosts || getAllOutsideServiceCosts,
  };

  return (
    <OutsideServiceCostsContext.Provider value={value}>
      {props.children}
    </OutsideServiceCostsContext.Provider>
  );
};

export const useOutsideServiceCosts = () => {
  return useContext(OutsideServiceCostsContext);
};

// Calls api to get all the OutsideServiceCosts from cosmos
export const getAllOutsideServiceCosts = async(): Promise<OutsideServiceCosts[]> => {
  try {
    return await getOutsideServiceCosts();
  } catch (error) {
    console.error(error);
    return null;
  }
};
