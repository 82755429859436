import { getEdgeAllowances } from './edge-allowances-api';
import * as React from 'react';
import { EdgeAllowances } from '@interfaces/edge-allowances';

const { createContext, useContext } = React;

const EdgeAllowancesContext = createContext(null);

export const EdgeAllowancesProvider = (props) => {
  const value = {
    getAllEdgeAllowances: props.getAllEdgeAllowances || getAllEdgeAllowances,
  };

  return (
    <EdgeAllowancesContext.Provider value={value}>
      {props.children}
    </EdgeAllowancesContext.Provider>
  );
};

export const useEdgeAllowances = () => {
  return useContext(EdgeAllowancesContext);
};

// Calls api to get all the edge allowances from cosmos
export const getAllEdgeAllowances = async(): Promise<EdgeAllowances[]> => {
  try {
    return await getEdgeAllowances();
  } catch (error) {
    console.error(error);
    return null;
  }
};
