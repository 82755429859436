import { MeasurementSystems } from '@constants';
import { IonLabel } from '@ionic/react';
import { useLocalUom } from '@hooks/useLocalUom';
import { Types, TextUOMConversion } from '@shared/app-helpers';
import React from 'react';


interface Props {
  children?: React.ReactNode | JSX.Element;
  uom?: MeasurementSystems
  imperial?: any,
  metric?: any,
  type?: Types,
}

export const AppLabel = ({
  children,
  uom,
  imperial,
  metric,
  type
}: Props) => {
  const {localUom, isDisplayImperial} = useLocalUom(uom);

  if (type && typeof imperial !== 'undefined' && typeof metric !== 'undefined') {
    const value = isDisplayImperial ? imperial : metric;
    return <IonLabel className="app-label">
      { TextUOMConversion[type][localUom](value) }
    </IonLabel>
  }

  return <IonLabel className="app-label">
    {children}
  </IonLabel>
};