import React from 'react';
import Modal from '@components/modal/modal';
import HeadFormingPONotesPopover from '@components/popover/head-forming-po-notes-popover/head-forming-po-notes-popover';
import { AssemblyGroupFormKeys } from '@pages/new-assembly-groups/assembly-group-form-util';

const PONotes = ({ onDismiss, form }) => {
  return <Modal
    title="PO Notes"
    options={{
      isOpen: true,
      size: 'adv-spec'
    }}
    frame={true}
  >
    <HeadFormingPONotesPopover
      poNotes={form[AssemblyGroupFormKeys.HeadFormingNotes1]}
      setShowPopover={({showPopover}) => onDismiss && !showPopover && onDismiss()}
    />
  </Modal>
};

export default PONotes;