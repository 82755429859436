import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IonButton, IonCol, IonGrid, IonImg, IonInput, IonLabel, IonRow, IonSearchbar } from '@ionic/react';
import './cost-details-popover.scss';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { ManufacturingSites, QuoteLineItemDependentUnwrittenFields, QuoteLineItemFields, QuoteLineItemValueTypes } from '@constants';
import { calculateCommissionLineItem, calculateDirectCost } from '@shared/quote-group-utils';
import classNames from 'classnames';
import { useQuoteState } from '@context/quote-context';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { DependentUnwrittenFieldsMapValueType } from '@shared/types';
import { useLocalUom } from '@hooks/useLocalUom';

type Props = {
  setShowModal: (showme: boolean) => void;
  lineItemId?: string;
};

interface CostDetail {
  field: string;
  isEditable?: boolean;
  label: string;
  value: number | string;
  valueType?: string;
  noPrefix?: boolean;
}

const CostDetailsPopover = ({
  lineItemId,
  setShowModal,
}: Props) => {
  const { darkMode } = useDarkMode();
  const { quoteState, saveQuoteLineItem } = useQuoteState();
  const { quote, isQuoteLocked } = quoteState;
  const {
    quoteLineItems
  } = quote || {};
  
  const { isDisplayMetric } = useLocalUom();

  const qliDisplayed = quoteLineItems?.find((qli: QuoteLineItem) =>
    qli.Id ? qli.Id === lineItemId : qli.displayId === lineItemId
  ) || (quoteLineItems || [])[0];

  const isQuoteDE = quote.Manufacturing_Site__c === ManufacturingSites.DE01 ? true : false;
  const currencyCode = qliDisplayed.CurrencyIsoCode ?? 'USD';
  const assemblyGroupIndex = Number(qliDisplayed.Line__c);
  const noMatch = 'No matches. Clear the filter and try again.';

  const outsideService = qliDisplayed.quoteLineItemDetail?.NC_Outside_Service_Item_Details__r;
  const heatTreatOSCost = (outsideService.BackerMetalHTTotalCost__c || 0) + (outsideService.CladderMetalHTTotalCost__c || 0) + (outsideService.PostbondHTTotalCost__c || 0);
  const cuttingOSCost = (outsideService.CuttingTotalCost__c || 0) + (outsideService.WaterJetCuttingTotalCost__c || 0);
  const xRayOSCost = outsideService.XRayServicesTotalCost__c || 0;
  const totalOSCost = isQuoteDE ? qliDisplayed.OS_Cost__c - heatTreatOSCost - cuttingOSCost - xRayOSCost : qliDisplayed.OS_Cost__c;
  const missingValue = '--';

  const directCost: number = calculateDirectCost(qliDisplayed, totalOSCost, isQuoteDE) || 0;
  const commission: number = calculateCommissionLineItem(qliDisplayed, quote.Agent_Com_Rate__c) || 0;

  const fieldsUpdated = useRef<QuoteLineItemValueTypes[]>([]);
  const lineItemFieldsMap = useRef<Map<string, number>>(new Map<string, number>());
  
  const initialCostDetailsList = (qliDisplayed: QuoteLineItem, isMetric = false, isQuoteLocked, directCost: number, commission: number, isQuoteDE: boolean): CostDetail[] => {
    
    return [
      {
        field: QuoteLineItemFields.CladCost,
        valueType: QuoteLineItemValueTypes.CladCost,
        label: 'Clad Cost',
        isEditable: !isQuoteLocked,
        value: qliDisplayed?.Clad_Cost__c ?? 0,
      },
      {
        field: isMetric ? QuoteLineItemFields.CladKgPc : QuoteLineItemFields.CladLbPc,
        valueType: QuoteLineItemValueTypes.CladWeightPerPC,
        label: isMetric ? 'Clad KG/PC' : 'Clad LB/PC',
        value: isMetric
          ? qliDisplayed?.Clad_KG_PC__c ?? 0
          : qliDisplayed?.Clad_LB_PC__c ?? 0,
        noPrefix: true,
      },
      {
        field: QuoteLineItemFields.CladFreight,
        valueType: QuoteLineItemValueTypes.CladFreight,
        label: 'Clad Freight',
        isEditable: !isQuoteLocked,
        value: qliDisplayed?.Clad_Freight__c ?? 0,
      },
      {
        field: QuoteLineItemFields.BaseCost,
        valueType: QuoteLineItemValueTypes.BaseCost,
        label: 'Base Cost',
        isEditable: !isQuoteLocked,
        value: qliDisplayed?.Base_Cost__c ?? 0,
      },
      {
        field: isMetric ? QuoteLineItemFields.BaseKgPc : QuoteLineItemFields.BaseLbPc,
        label: isMetric ? 'Base KG/PC' : 'Base LB/PC',
        value: isMetric
          ? qliDisplayed?.Base_KG_PC__c ?? 0
          : qliDisplayed?.Base_LB_PC__c ?? 0,
        noPrefix: true,
      },
      {
        field: QuoteLineItemFields.BaseFreight,
        valueType: QuoteLineItemValueTypes.BaseFreight,
        label: 'Base Freight',
        isEditable: !isQuoteLocked,
        value: qliDisplayed?.Base_Freight__c ?? 0,
      },
      {
        field: QuoteLineItemFields.ExplosiveLoadCost,
        label: 'Explosive Cost',
        value: qliDisplayed?.quoteLineItemDetail?.Explosive_Load_Cost__c ?? 0,
      },
      {
        field: QuoteLineItemFields.Anvil,
        label: 'Anvil',
        value: qliDisplayed?.Anvil__c ?? 0,
      },
      {
        field: QuoteLineItemFields.Welding,
        label: 'Welding',
        value: isQuoteDE ? (qliDisplayed?.Welding__c || 0) + xRayOSCost : qliDisplayed?.Welding__c || 0,
      },
      {
        field: QuoteLineItemFields.GeneralLaborCost,
        label: 'General Labor',
        value: qliDisplayed?.GeneralLaborCost__c ?? 0,
      },
      {
        field: QuoteLineItemFields.FlatteningLaborCost,
        label: 'Flattening',
        value: qliDisplayed?.quoteLineItemDetail?.FlatteningLaborCost__c ?? 0,
      },
      {
        field: QuoteLineItemFields.HeatTreatmentCost,
        label: 'Heat Treat',
        value: isQuoteDE ? (qliDisplayed?.quoteLineItemDetail?.Heat_Treatment_Cost__c || 0) + heatTreatOSCost : qliDisplayed?.quoteLineItemDetail?.Heat_Treatment_Cost__c || 0,
      },
      {
        field: QuoteLineItemFields.ConsumableCost,
        label: 'Consumable Cost',
        value: qliDisplayed?.quoteLineItemDetail?.Consumable_Cost__c ?? 0,
      },
      {
        field: QuoteLineItemFields.SpecificationTestCost,
        label: 'Specification Test Cost',
        value: qliDisplayed?.quoteLineItemDetail?.Specification_Test_Cost__c ?? 0,
      },
      {
        field: QuoteLineItemFields.CutMethodCost,
        label: 'Cut Method',
        value: isQuoteDE ? (qliDisplayed?.quoteLineItemDetail?.Cut_Method_Cost__c || 0) + cuttingOSCost : qliDisplayed?.quoteLineItemDetail?.Cut_Method_Cost__c || 0,
      },
      {
        field: QuoteLineItemFields.MachiningTotalCost,
        label: 'Machining Cost',
        value: isQuoteDE ? qliDisplayed?.quoteLineItemDetail?.NC_Outside_Service_Item_Details__r?.MachiningTotalCost__c ?? 0 : qliDisplayed?.quoteLineItemDetail?.NC_Tubesheet_Item_Details__r?.TubesheetMachiningTotalCost__c || 0,
      },
      {
        field: QuoteLineItemFields.HeadForming,
        valueType: QuoteLineItemValueTypes.HeadForming,
        label: 'Head Forming',
        isEditable: !isQuoteLocked,
        value: qliDisplayed?.Head_Forming__c ?? 0,
      },
      {
        field: QuoteLineItemFields.HeadFreight,
        valueType: QuoteLineItemValueTypes.HeadFreight,
        label: 'Head Freight',
        isEditable: !isQuoteLocked,
        value: qliDisplayed?.Head_Freight__c ?? 0,
      },
      {
        field: QuoteLineItemFields.CosmeticPrepCost,
        label: 'Cosmetic Grinding Cost',
        value: qliDisplayed?.CosmeticPrepCost__c ?? 0,
      },
      {
        field: QuoteLineItemFields.ExportPackagingCost,
        label: 'Export Packaging',
        value: qliDisplayed?.ExportPackagingCost__c ?? 0,
      },
      {
        field: QuoteLineItemFields.OSCost,
        label: 'OS Cost',
        value: totalOSCost,
      },
      {
        field: QuoteLineItemFields.CertificateCost,
        label: 'Certificate Cost',
        value: qliDisplayed?.CertificateCost__c ?? 0,
      },
      {
        field: 'Drop Cost',
        label: 'Drop Cost',
        value: missingValue,
      },
      {
        field: 'Direct Cost',
        label: 'Direct Cost',
        value: directCost,
      },
      {
        field: QuoteLineItemFields.FreightOut,
        valueType: QuoteLineItemValueTypes.FreightOut,
        label: 'Freight Out',
        isEditable: !isQuoteLocked,
        value: qliDisplayed?.Freight_Out__c ?? 0,
      },
      {
        field: 'Commission',
        label: 'Commission',
        value: commission,
      },
    ];
  };

  const [costValuesList, setCostValuesList] = useState([...initialCostDetailsList(qliDisplayed, isDisplayMetric, isQuoteLocked, directCost, commission, isQuoteDE)]);
  const [filteredList, setFilteredList] = useState(costValuesList);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    try {
      updateFilteredList();
    } catch (error) {
      console.log(error);
    }
  }, [costValuesList]);

  /** functions */
  // saves the fields to the quote
  const saveQuote = async () => {
    const quoteLineItemToEdit = { ...qliDisplayed };

    Array.from(lineItemFieldsMap.current.keys()).forEach(field => {
      quoteLineItemToEdit[field] = lineItemFieldsMap.current.get(field);
    });

    saveQuoteLineItem(new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>(), fieldsUpdated.current, quoteLineItemToEdit, false, true);
  };

  const financial = (x: number): number => Number(x.toFixed(2));

  const dismissModal = () => {

    setShowModal(false);
  };

  const updateFilteredList = (val = searchText) => {
    setFilteredList(costValuesList.filter((entry) => entry.label?.toLowerCase().includes(val.toLowerCase())));
  };

  const handleSearch = (search: string) => {
    setSearchText(search);
    updateFilteredList(search);
  };

  const handleSyncStateChange = () => {
    setCostValuesList(
      costValuesList.map((entry) => {
        for (const [key, value] of lineItemFieldsMap.current.entries()) {
          if (key === entry.field) {
            // format the value as 2 digit currency
            entry.value = financial(Number(value));
          }
        }
        return entry;
      })
    );
  };

  /** components */
  const SaveCancelButtons = () => (
    <>
      <IonButton
        color='primary'
        class='submit-order-button'
        data-testid={'QACostDetailsSaveButton'}
        onClick={() => {
          saveQuote();
          dismissModal();
        }}
        disabled={isQuoteLocked}
      >
        Save
      </IonButton>
      <IonButton color='secondary' class='cancel-button' onClick={() => dismissModal()}>
        Cancel
      </IonButton>
    </>
  );

  const CostDetailValue = ({ costDetail }: { costDetail: CostDetail }) => {
    // show the currency prefix, $/€, if the value is a number, or if the noPrefix flag is not present
    const showPrefix = typeof costDetail.value === 'number' && costDetail.noPrefix !== true;

    const [value, setValue] = useState<number | string>(
      typeof costDetail.value === 'number' ? financial(costDetail.value) : costDetail.value
    );

    const handleValueChange = (costDtl: CostDetail, val: number) => {
      lineItemFieldsMap.current.set(costDtl.field, Math.abs(val));
      if (!fieldsUpdated.current.includes(costDtl.valueType as QuoteLineItemValueTypes)) {
        fieldsUpdated.current.push(costDtl.valueType as QuoteLineItemValueTypes);
      }
      // positive values
      setValue(Math.abs(val));
    };

    return (
      <>
        <span
          className={classNames({
            prefix: showPrefix,
            euro: currencyCode === 'EUR',
          })}
        />
        {costDetail.isEditable ? (
          <IonInput
            class='section-input'
            value={value}
            min='0'
            type='number'
            placeholder='Enter Value'
            onIonChange={(e) => handleValueChange(costDetail, Number(e.detail.value))}
            onIonBlur={() => handleSyncStateChange()}
          />
        ) : (
          <IonLabel
          class={classNames({
            'section-label': true,
            'no-prefix': !showPrefix,
          })}
          >{value}</IonLabel>
        )}
      </>
    );
  };

  const CostDetailsList = () => {
    return (
      <>
        <div className='list-wrapper'>
          <IonGrid>
            <div className='fields-container'>
              {filteredList.length ? (
                filteredList.map((costDetail) => {
                  return (
                    <IonRow key={costDetail.label}>
                      <IonCol size='6'>
                        <IonLabel class='section-label'>{costDetail.label}</IonLabel>
                      </IonCol>
                      <IonCol size='5.5'>
                        <CostDetailValue costDetail={costDetail} />
                      </IonCol>
                    </IonRow>
                  );
                })
              ) : (
                <IonLabel>{noMatch}</IonLabel>
              )}
            </div>
          </IonGrid>
        </div>
      </>
    );
  };

  return (
    <div className='popover cost-details'>
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class='close-icon' onClick={() => dismissModal()} />
      <div className='title-container'>
        <IonLabel class='title' data-testid={'QACostDetailsModalHeader'}>
          Cost Details
        </IonLabel>
      </div>
      <hr className='mid-rule' />
      <div className='sublabel-header'>Assembly Group {assemblyGroupIndex}</div>
      <IonRow>
        <IonSearchbar
          className='searchbar'
          value={searchText}
          onIonChange={(e) => handleSearch(e.detail.value)}
          placeholder='Search by Cost or Service'
        ></IonSearchbar>
      </IonRow>
      <div className='cost-list-container'>
        <CostDetailsList />
      </div>
      <div className='save-cancel-button-container'>
        <SaveCancelButtons />
      </div>
    </div>
  );
};

export default CostDetailsPopover;