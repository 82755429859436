import React from 'react';
import { IonButton, IonCol, IonGrid, IonImg, IonLabel, IonRow } from '@ionic/react';
import './customer-specified-impact-testing-popover.scss';
import { SelectionTypes, QuoteLineItemValueTypes, CustomerSpecifiedImpactTestDirections, PostCladTestingOptions, Regexes, ToBePerformed } from '@constants';
import SelectionContainer from '@components/selection-container/selection-container';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { getSelectionOptions, getSelections } from '@shared/quote-utils';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { AdvSpecsAssemblyGroupDetailFieldsToUpdate, AdvSpecsAssemblyGroupLineFieldsToUpdate, AdvSpecsMetalComboDetailFieldsToUpdate, AdvSpecsMetalComboLineFieldsToUpdate } from '@shared/types';

const CustomerSpecifiedImpactTestingPopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
    showPopover: boolean;
    event: any;
  }>>,
  lineFieldsToUpdate: AdvSpecsMetalComboLineFieldsToUpdate | AdvSpecsAssemblyGroupLineFieldsToUpdate,
  detailFieldsToUpdate: AdvSpecsMetalComboDetailFieldsToUpdate | AdvSpecsAssemblyGroupDetailFieldsToUpdate,
  optionsRequired: { [key: string]: boolean; }
}) => {
  const { dataState } = useDataState();
  const { selections } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;

  const { darkMode } = useDarkMode();

  const [lineFieldsToUpdate] = React.useState({
    postCladTestingOptions: props.lineFieldsToUpdate.postCladTestingOptions
  });

  const [detailFieldsToUpdate] = React.useState({
    customerSpecifiedImpactTestToBePerformed: props.detailFieldsToUpdate.customerSpecifiedImpactTestToBePerformed || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestToBePerformed).default,
    customerSpecifiedImpactTestLocation: props.detailFieldsToUpdate.customerSpecifiedImpactTestLocation || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestLocation).default,
    customerSpecifiedImpactTestDirection: props.detailFieldsToUpdate.customerSpecifiedImpactTestDirection || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestDirection).default,
    customerSpecifiedImpactTestUOM: props.detailFieldsToUpdate.customerSpecifiedImpactTestUOM || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestUOM).default,
    customerSpecifiedImpactTestAVG: props.detailFieldsToUpdate.customerSpecifiedImpactTestAVG,
    customerSpecifiedImpactTestMIN: props.detailFieldsToUpdate.customerSpecifiedImpactTestMIN,
    customerSpecifiedImpactTestTemperature: props.detailFieldsToUpdate.customerSpecifiedImpactTestTemperature,
    customerSpecifiedImpactTestTempUOM: props.detailFieldsToUpdate.customerSpecifiedImpactTestTempUOM || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestTempUOM).default,
  });

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  // Updates post clad testing options for the details depending on which testing direction was selected
  const updatePostCladTestingOptions = (testingDirection: CustomerSpecifiedImpactTestDirections) => {
    let updatedPostCladTestingOptions = lineFieldsToUpdate?.postCladTestingOptions?.split(';') || [];

    switch(testingDirection) {
    case CustomerSpecifiedImpactTestDirections.Longitudinal:
      updatedPostCladTestingOptions = updatedPostCladTestingOptions.filter(option => option != PostCladTestingOptions.ImpactTCVN);
      
      if(!updatedPostCladTestingOptions.includes(PostCladTestingOptions.ImpactLCVN)) {
        updatedPostCladTestingOptions.push(PostCladTestingOptions.ImpactLCVN);
      }
      break;
    case CustomerSpecifiedImpactTestDirections.Transverse:
      updatedPostCladTestingOptions = updatedPostCladTestingOptions.filter(option => option != PostCladTestingOptions.ImpactLCVN);
      
      if(!updatedPostCladTestingOptions.includes(PostCladTestingOptions.ImpactTCVN)) {
        updatedPostCladTestingOptions.push(PostCladTestingOptions.ImpactTCVN);
      }
      break;
    case CustomerSpecifiedImpactTestDirections.Both:
      if(!updatedPostCladTestingOptions.includes(PostCladTestingOptions.ImpactLCVN)) {
        updatedPostCladTestingOptions.push(PostCladTestingOptions.ImpactLCVN);
      }

      if(!updatedPostCladTestingOptions.includes(PostCladTestingOptions.ImpactTCVN)) {
        updatedPostCladTestingOptions.push(PostCladTestingOptions.ImpactTCVN);
      }
      break;
    }

    lineFieldsToUpdate.postCladTestingOptions = [...updatedPostCladTestingOptions].join(';');
  };

  const updateLines = () => {
    props.lineFieldsToUpdate.postCladTestingOptions = lineFieldsToUpdate.postCladTestingOptions;
  };

  const updateDetails = () => {
    props.detailFieldsToUpdate.customerSpecifiedImpactTestToBePerformed = detailFieldsToUpdate.customerSpecifiedImpactTestToBePerformed;
    props.detailFieldsToUpdate.customerSpecifiedImpactTestLocation = detailFieldsToUpdate.customerSpecifiedImpactTestLocation;
    props.detailFieldsToUpdate.customerSpecifiedImpactTestDirection = detailFieldsToUpdate.customerSpecifiedImpactTestDirection;
    props.detailFieldsToUpdate.customerSpecifiedImpactTestUOM = detailFieldsToUpdate.customerSpecifiedImpactTestUOM;
    props.detailFieldsToUpdate.customerSpecifiedImpactTestAVG = detailFieldsToUpdate.customerSpecifiedImpactTestAVG;
    props.detailFieldsToUpdate.customerSpecifiedImpactTestMIN = detailFieldsToUpdate.customerSpecifiedImpactTestMIN;
    props.detailFieldsToUpdate.customerSpecifiedImpactTestTemperature = detailFieldsToUpdate.customerSpecifiedImpactTestTemperature;
    props.detailFieldsToUpdate.customerSpecifiedImpactTestTempUOM = detailFieldsToUpdate.customerSpecifiedImpactTestTempUOM;

    if(detailFieldsToUpdate.customerSpecifiedImpactTestToBePerformed === ToBePerformed.PreBond || detailFieldsToUpdate.customerSpecifiedImpactTestToBePerformed === ToBePerformed.BothPreAndPostBond) {
      props.optionsRequired.prebondImpactsRequired = true;
      props.detailFieldsToUpdate.preBondImpactsTestLocation = detailFieldsToUpdate.customerSpecifiedImpactTestLocation;
      props.detailFieldsToUpdate.preBondImpactsTestDirection = detailFieldsToUpdate.customerSpecifiedImpactTestDirection;
      props.detailFieldsToUpdate.preBondImpactsTestUOM = detailFieldsToUpdate.customerSpecifiedImpactTestUOM;
      props.detailFieldsToUpdate.preBondImpactsTestAVG = detailFieldsToUpdate.customerSpecifiedImpactTestAVG;
      props.detailFieldsToUpdate.preBondImpactsTestMIN = detailFieldsToUpdate.customerSpecifiedImpactTestMIN;
      props.detailFieldsToUpdate.preBondImpactsTestTemperature = detailFieldsToUpdate.customerSpecifiedImpactTestTemperature;
      props.detailFieldsToUpdate.preBondImpactsTestTemperatureUOM = detailFieldsToUpdate.customerSpecifiedImpactTestTempUOM;
    } else {
      props.optionsRequired.prebondImpactsRequired = false;
      props.detailFieldsToUpdate.preBondImpactsTestLocation = null;
      props.detailFieldsToUpdate.preBondImpactsTestDirection = null;
      props.detailFieldsToUpdate.preBondImpactsTestUOM = null;
      props.detailFieldsToUpdate.preBondImpactsTestAVG = null;
      props.detailFieldsToUpdate.preBondImpactsTestMIN = null;
      props.detailFieldsToUpdate.preBondImpactsTestTemperature = null;
      props.detailFieldsToUpdate.preBondImpactsTestTemperatureUOM = null;
    }
  };

  return (
    <div className="popover customer-specified-impact-testing">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='customer-specified-impact-testing-title' data-testid={'QACustomerSpecifiedImpactTestingPopoverCustomerSpecifiedImpactTestingHeader'}>Customer Specified Impact Testing</IonLabel>
      </div>
      <hr/>
      <div className='selections'>
        <IonGrid>
          <IonRow>
            <IonCol size="2.8">
              <IonLabel class='selection-label'>
                To Be Performed
              </IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select Test Time to be Performed...'
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestToBePerformed).options}
                style={{ width: '200px', marginTop: 'unset' }}
                onChange={customerSpecifiedImpactTestToBePerformed =>
                  (detailFieldsToUpdate.customerSpecifiedImpactTestToBePerformed = customerSpecifiedImpactTestToBePerformed.label)
                }
                defaultValue={detailFieldsToUpdate.customerSpecifiedImpactTestToBePerformed}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="2.8">
              <IonLabel class='selection-label'>
                Location
              </IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select Test Location...'
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestLocation).options}
                style={{ width: '200px', marginTop: 'unset' }}
                onChange={customerSpecifiedImpactTestLocation =>
                  (detailFieldsToUpdate.customerSpecifiedImpactTestLocation = customerSpecifiedImpactTestLocation.label)
                }
                defaultValue={detailFieldsToUpdate.customerSpecifiedImpactTestLocation}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="2.8">
              <IonLabel class='selection-label section'>
                Direction
              </IonLabel>
            </IonCol>
            <IonCol class='flex-column'>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select Test Direction...'
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestDirection).options}
                style={{ width: '200px', marginTop: 'unset' }}
                onChange={customerSpecifiedImpactTestDirection =>
                  (detailFieldsToUpdate.customerSpecifiedImpactTestDirection = customerSpecifiedImpactTestDirection.label)
                }
                defaultValue={detailFieldsToUpdate.customerSpecifiedImpactTestDirection}
              />
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestUOM).options}
                onIonChange={customerSpecifiedImpactTestUOM =>
                  (detailFieldsToUpdate.customerSpecifiedImpactTestUOM = customerSpecifiedImpactTestUOM)
                }
                value={detailFieldsToUpdate.customerSpecifiedImpactTestUOM}
              />
              <div className='flex-container'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  placeholder='0'
                  regex={Regexes.IntegerAboveZero}
                  min='0'
                  style={{ width: '48px', marginRight: '10px', marginTop: 'auto' }}
                  onBlur={customerSpecifiedImpactTestAVG =>
                    (detailFieldsToUpdate.customerSpecifiedImpactTestAVG = customerSpecifiedImpactTestAVG)
                  }
                  value={detailFieldsToUpdate.customerSpecifiedImpactTestAVG}
                />
                <IonLabel class='unit-label'>AVG/</IonLabel>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  placeholder='0'
                  regex={Regexes.IntegerAboveZero}
                  min='0'
                  style={{ width: '48px', marginRight: '10px', marginTop: 'auto' }}
                  onBlur={customerSpecifiedImpactTestMIN =>
                    (detailFieldsToUpdate.customerSpecifiedImpactTestMIN = customerSpecifiedImpactTestMIN)
                  }
                  value={detailFieldsToUpdate.customerSpecifiedImpactTestMIN}
                />
                <IonLabel class='unit-label'>MIN</IonLabel>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="2.8">
              <IonLabel class='selection-label'>
                Temperature
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='flex-container'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  placeholder='0'
                  regex={Regexes.Temperature}
                  min='0'
                  style={{ width: '48px', marginRight: '19px' }}
                  containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                  onBlur={customerSpecifiedImpactTestTemperature =>
                    (detailFieldsToUpdate.customerSpecifiedImpactTestTemperature = customerSpecifiedImpactTestTemperature)
                  }
                  value={detailFieldsToUpdate.customerSpecifiedImpactTestTemperature}
                />
                <SelectionContainer
                  type={SelectionTypes.RadioButtons}
                  options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CustomerSpecifiedImpactTestTempUOM).options}
                  onIonChange={customerSpecifiedImpactTestTempUOM =>
                    (detailFieldsToUpdate.customerSpecifiedImpactTestTempUOM = customerSpecifiedImpactTestTempUOM)
                  }
                  value={detailFieldsToUpdate.customerSpecifiedImpactTestTempUOM}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <hr className='bottom-rule'/>
      <div className="cta-button-container">
        <IonButton color="primary" class="save-button" data-testid="QACustomerSpecifiedImpactTestingPopoverSaveButton" onClick={() => {
          updatePostCladTestingOptions(detailFieldsToUpdate.customerSpecifiedImpactTestDirection as CustomerSpecifiedImpactTestDirections);
          updateLines();
          updateDetails();
          dismissModal();
        }}
        >
          Save
        </IonButton>
        <IonButton color="secondary" class="cancel-button" onClick={() => dismissModal()}>Cancel</IonButton>
      </div>
    </div>
  );
};

export default CustomerSpecifiedImpactTestingPopover;