import { IconColors } from '@components/topbar/sync-indicator/icon';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface SyncContextProps {
  isSyncing: boolean;
  sync: (callback?: () => Promise<void>) => void;
  setIsSyncing: (syncing: boolean) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  color: string;
  setColor: (color: IconColors) => void;
  setHasSyncError: (hasSyncErr: boolean) => void;
  endSyncWithError: (msg: SyncMessages) => void;
  syncMsg: string;
}

interface SyncProviderProps {
  children: React.ReactNode;
}

export enum SyncMessages {
  Syncing = 'Syncing...',
  Synced = 'Sync successful',
  SyncError = 'Sync error',
  CosmosError = 'Cosmos save issue',
  SalesforceError = 'Salesforce sync issue',
  TimeoutError = 'Sync timed out',
}

const SyncContext = createContext<SyncContextProps>({} as SyncContextProps);

export const SyncProvider = ({ children }: SyncProviderProps) => {
  const SYNC_TIMOUT = 120000; // 2 mins
  
  const [isSyncing, setIsSyncing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [color, setColor] = useState<IconColors>(IconColors.Success);
  const [syncMsg, setSyncMsg] = useState<SyncMessages>(isSyncing ? SyncMessages.Syncing : SyncMessages.Synced);
  const [hasSyncError, setHasSyncError] = useState(false);
  
  const endSyncWithError = (msg: SyncMessages, useWarningColor = false) => {
    setIsSyncing(false);
    setHasSyncError(true);
    setSyncMsg(msg);
    setColor(useWarningColor ? IconColors.Warning : IconColors.Danger);
  };

  const handleSyncing = (
    color: IconColors,
    syncMsg: SyncMessages,
    setSyncMsg: (msg: SyncMessages) => void,
    setColor: (color: IconColors) => void
  ) => {
    // turn yellow, and set message to 'Syncing...'
    if (color !== IconColors.Warning) {
      setColor(IconColors.Warning);
    }
    if (syncMsg !== SyncMessages.Syncing) {
      setSyncMsg(SyncMessages.Syncing);
    }
  };

  const handleSyncError = (
    color: IconColors,
    syncMsg: SyncMessages,
    setSyncMsg: (msg: SyncMessages) => void,
    setColor: (color: IconColors) => void
  ) => {
    // If syncMsg is TimeoutError and color is not already Warning
    if (syncMsg === SyncMessages.TimeoutError && color !== IconColors.Warning) {
      // Set color to Warning
      setColor(IconColors.Warning);
    }
    // Otherwise, if syncMsg is not TimeoutError and color is not already Danger
    else if (syncMsg !== SyncMessages.TimeoutError && color !== IconColors.Danger) {
      // Set color to Danger
      setColor(IconColors.Danger);
    }
  };

  const handleSynced = (
    color: IconColors,
    syncMsg: SyncMessages,
    setSyncMsg: (msg: SyncMessages) => void,
    setColor: (color: IconColors) => void
  ) => {
    // turn green, and set message to 'Synced'
    if (color !== IconColors.Success) {
      setColor(IconColors.Success);
    }
    if (syncMsg !== SyncMessages.Synced) {
      setSyncMsg(SyncMessages.Synced);
    }
  };

  const  useSyncEffect = (
    color: IconColors,
    hasSyncError: boolean,
    isSyncing: boolean,
    syncMsg: SyncMessages,
    setSyncMsg: (msg: SyncMessages) => void,
    setColor: (color: IconColors) => void
  ) => {
    useEffect(() => {
      if (isSyncing) {
        console.debug('Syncing in context');
        handleSyncing(color, syncMsg, setSyncMsg, setColor);
      } else if (hasSyncError) {
        console.debug('Sync error in context');
        handleSyncError(color, syncMsg, setSyncMsg, setColor);
      } else {
        console.debug('Synced in context');
        handleSynced(color, syncMsg, setSyncMsg, setColor);
      }
    }, [color, hasSyncError, isSyncing, syncMsg, setSyncMsg, setColor]);
  };

  useSyncEffect(color, hasSyncError, isSyncing, syncMsg, setSyncMsg, setColor);
    
  useEffect(() => {
    if (!isSyncing) {
      if (syncMsg !== SyncMessages.Synced && !hasSyncError) {
        setSyncMsg(SyncMessages.Synced);
      } else if (
        syncMsg !== SyncMessages.Synced &&
        syncMsg !== SyncMessages.SyncError &&
        syncMsg !== SyncMessages.CosmosError &&
        syncMsg !== SyncMessages.SalesforceError &&
        syncMsg !== SyncMessages.TimeoutError
      ) {
        setSyncMsg(SyncMessages.SyncError);
      }
    }
  }, [hasSyncError, isSyncing, syncMsg]);

  useEffect(() => {
    if (isLoading) {
      console.debug('Loading in context');
    } else {
      console.debug('Loaded in context');
    }
  }, [isLoading]);

  const sync = useCallback(async (callback?: () => Promise<void>) => {
    setIsSyncing(true);
    const timeoutPromise = new Promise<void | Error>(
      (resolve) => setTimeout(() => resolve(new Error(SyncMessages.TimeoutError)), SYNC_TIMOUT)
    );
    try {
      const result: void | Error | undefined = await Promise.race([callback?.(), timeoutPromise]);
      if (result instanceof Error && result.message === SyncMessages.TimeoutError) {
        // timeout occurred
        endSyncWithError(SyncMessages.TimeoutError, true);
      } else {
        // sync completed successfully
        console.debug(`${SyncMessages.Synced}: `, callback?.toString());
      }
    } catch (error) {
      console.error(SyncMessages.SyncError, error);
      setColor(IconColors.Danger);
    } finally {
      setIsSyncing(false);
    }
  }, []);

  // context value object
  const value: SyncContextProps = useMemo(() => {
    return {
      isSyncing,
      setIsSyncing,
      sync,
      isLoading,
      setIsLoading,
      color,
      setColor,
      setHasSyncError,
      endSyncWithError,
      syncMsg,
    };
  }, [color, isLoading, isSyncing, sync, syncMsg]);

  return <SyncContext.Provider value={value}>{children}</SyncContext.Provider>;
};

export const useSync = (): SyncContextProps => {
  const context: SyncContextProps = useContext(SyncContext);
  if (context === null || context === undefined) {
    throw new Error('You probably forgot the <SyncProvider> context provider');
  }
  return context;
};

/* usage example:
const { isSyncing, sync, isLoading } = useSync();
sync(async () => {
  // perform sync, save or clone actions
});
*/
