import buffer from 'buffer';
import CryptoJS from 'crypto-js';

const base64urlDecode = (str) => {
  let output = str.replace('-', '+').replace('_', '/');
  switch (output.length % 4) {
    case 0: { break; }
    case 2: {
      output += '==';
      break;
    }
    case 3: {
      output += '=';
      break;
    }
    default: {
      throw new Error('Illegal base64url string!');
    }
  }

  return buffer.Buffer.from(output, 'base64').toString('ascii');
};

const base64urlEncode = (source: any) => {
  // Encode in classical base64
  let encodedSource = CryptoJS.enc.Base64.stringify(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, '');

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
};

const decode = (value) => {
  const parts = value.split('.');
  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts');
  }

  const decoded = base64urlDecode(parts[1]);
  if (!decoded) {
    throw new Error('Cannot decode the token');
  }

  return JSON.parse(decoded);
};

const encode = (payload: any, secret: string) => {
  // header
  const header = { alg: 'HS512' };
  const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  const encodedHeader = base64urlEncode(stringifiedHeader);

  // payload
  const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
  const encodedData = base64urlEncode(stringifiedData);
  const encodedToken = `${encodedHeader}.${encodedData}`;

  // signature
  // const secret = process.env.JWT_PRIVATE_KEY;
  const signature = base64urlEncode(CryptoJS.HmacSHA512(encodedToken, secret));

  return `${encodedToken}.${signature}`;
};

export default {
  encode,
  decode,
};
