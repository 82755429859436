import { Currency } from '@interfaces/salesforce';
import { Subject } from 'rxjs';
import { ProfileSettings, Currencies } from '@constants';
import CurrencyConversions from '@interfaces/currency-conversions';
import * as React from 'react';
import { settings$ } from '@services/user/user-service';
import { transformCurrency } from '@shared/utils';
import { currencyConversionsCache } from '@services/currency-conversions/currency-conversions-service';

const { createContext, useContext } = React;

const CurrencyConversionContext = createContext(null);

export const CurrencyConversionProvider = (props) => {
  const value = {
    selectedCurrency: props.selectedCurrency || selectedCurrency,
    selectedCurrencySymbol: props.selectedCurrencySymbol || selectedCurrencySymbol,
    setSelectedCurrency: props.setSelectedCurrency || setSelectedCurrency,
    getPriceConvertedToUsd: props.getPriceConvertedToUsd || getPriceConvertedToUsd,
    getPriceConvertedToUsdNoRounding: props.getPriceConvertedToUsdNoRounding || getPriceConvertedToUsdNoRounding,
    getPriceFromAnotherConversion: props.getPriceFromAnotherConversion || getPriceFromAnotherConversion,
    convertCurrency: props.convertCurrency || convertCurrency,
    convertCurrencyNoRounding: props.convertCurrencyNoRounding || convertCurrencyNoRounding,
    transformSelectedCurrency: props.transformSelectedCurrency || transformSelectedCurrency,
    conversionRates: props.conversionRates || conversionRates
  };

  return (
    <CurrencyConversionContext.Provider value={value}>
      {props.children}
    </CurrencyConversionContext.Provider>
  );
};

export const useCurrencyConversion = () => {
  return useContext(CurrencyConversionContext);
};

let _selectedCurrency: Currency;
export const setSelectedCurrencySubject: Subject<Currency> = new Subject<Currency>();

export const selectedCurrency = () => {
  return _selectedCurrency;
};

export const selectedCurrencySymbol = () => {
  const range = _selectedCurrency === 'CAD' ? 3 : 1;
  return transformCurrency(0, _selectedCurrency).substr(0, range);
};

export const setSelectedCurrency = (currency: Currency) => {
  _selectedCurrency = currency;
  setSelectedCurrencySubject.next(currency);
};

export const getPriceConvertedToUsd = (value, currency = selectedCurrency()) => {
  const rate = conversionRates()[currency];
  return (value / rate).toFixed(2);
};

export const getPriceConvertedToUsdNoRounding = (value, currency = selectedCurrency()) => {
  const rate = conversionRates()[currency];
  return (value / rate);
};

export const getPriceFromAnotherConversion = (value, fromCurrency: Currency, toCurrency = selectedCurrency()) => {
  return convertCurrency(+getPriceConvertedToUsd(value, fromCurrency), toCurrency);
};

export const convertCurrency = (value: number, convertToCurrency: Currency = selectedCurrency()) => {
  const rate = conversionRates()[convertToCurrency];
  return (value * rate).toFixed(2);
};

export const convertCurrencyNoRounding = (value: number, convertToCurrency: Currency = selectedCurrency()) => {
  const rate = conversionRates()[convertToCurrency];
  return (value * rate);
};

export const transformSelectedCurrency = (selectedCurrency) => {
  switch (selectedCurrency) {
  case Currencies.USD:
    return 'USD' as Currency;
  case Currencies.CAN:
    return 'CAD' as Currency;
  case Currencies.EUR:
    return 'EUR' as Currency;
  }
};

export const conversionRates = (): CurrencyConversions => {
  return currencyConversionsCache;
};

settings$().subscribe(settings => {
  setSelectedCurrency(settings
    ? transformSelectedCurrency(settings.get(ProfileSettings.Currency))
    : 'USD');
});
