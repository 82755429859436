interface ICombineOptions {
  arrayBehavior: 'merge' | 'replace';
}

interface IGroup<T> {
  key: any;
  values: T[];
}

interface Array<T> {
  flatten<T = any>(): T[];
  groupBy<T = any>(expression: (element: T) => any): IGroup<T>[];
  orderBy(expression: any, ascending?: boolean): T[];
  orderByDescending(expression: any): T[];
  orderByDate(expression: any, ascending?: boolean): T[];
  orderByDateTime(expression: any, ascending?: boolean): T[];
  pushUnique(...items: T[]);
  skip(count: number): T[];
  take(count: number | undefined): T[];
  sum(): T;
}

interface ObjectConstructor {
  combine<T>(input: any, ...sources: any): T;
  toGroup<T = any>(target: Record<string, unknown>): IGroup<T>[];
}

interface String {
  contains(value: string, caseSensitive?: boolean);
  toFloat(): number;
  toSlug(lowerCase?: boolean): string;
}

interface StringConstructor {
  isNullOrEmpty(value: any): boolean;
}

interface URLSearchParams {
  toObject(): Record<string, unknown>;
}

const deepCopy = (obj: any) => {
  return obj ? JSON.parse(JSON.stringify(obj)) : null;
};

const getType = (value: any) => {
  if (value === null) {
    return 'null';
  }
  if (typeof value === 'undefined') {
    return 'undefined';
  }
  if (typeof value === 'object') {
    return Array.isArray(value) ? 'array' : 'object';
  }

  return typeof value;
};

const clone = (input: any) => {
  function cloneValue(value: any) {
    // The value is an object so lets clone it.
    if (getType(value) === 'object') {
      return cloneObject(value);
    }
    // The value is an array so lets clone it.
    if (getType(value) === 'array') {
      return cloneArray(value);
    }

    // Any other value can just be copied.
    return value;
  }

  function cloneArray(target: any) {
    return target.map(cloneValue);
  }

  function cloneObject(target: any) {
    const result: any = {};

    Object.keys(target).forEach(key => {
      if (target.hasOwnProperty(key)) { result[key] = cloneValue(target[key]); }
    });

    return result;
  }

  const inputType = getType(input);
  switch (inputType) {
  case 'object':
    return cloneObject(input);
  case 'array':
    return cloneArray(input);
  default:
    return input;
  }
};

Array.prototype.flatten = function() {
  return [].concat(...this);
};

Array.prototype.groupBy = function<T>(expression): IGroup<T>[] {
  const groups = {};

  this.forEach(function(el) {
    const key = expression(el);
    if (!(key in groups)) {
      groups[key] = [];
    }
    groups[key].push(el);
  });

  return Object.keys(groups).reduce((result: any[], key: string) => {
    result.push({
      key,
      values: groups[key]
    });
    return result;
  }, []);
};

Array.prototype.orderBy = function<T>(expression, ascending = true): T[] {
  const that = this as T[];
  return that.sort((a: any, b: any) => {
    const x = expression(a) || '';
    const y = expression(b) || '';
    if (ascending) {
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    }
    else {
      return ((x < y) ? 1 : ((x > y) ? -1 : 0));
    }
  });
};

Array.prototype.orderByDescending = function<T>(expression): T[] {
  return this.orderBy(expression, false);
};

// creates Date from string in MM/DD/YYYY format
const dateFromString = (value: string) => {
  const parts = value.split('/');
  return new Date(+parts[2], +parts[0] - 1, +parts[1]);
};

Array.prototype.orderByDate = function<T>(expression, ascending = true): T[] {
  const that = this as T[];
  return that.sort((a: any, b: any) => {
    const x = expression(a);
    const y = expression(b);
    const xDate = dateFromString(x);
    const yDate = dateFromString(y);

    if (ascending) {
      return +(x.length === 0) - +(y.length === 0) || +(xDate > yDate) || -(xDate < yDate);
    } else {
      return +(x.length === 0) - +(y.length === 0) || -(xDate > yDate) || +(xDate < yDate);
    }
  });
};

Array.prototype.orderByDateTime = function<T>(expression, ascending = true): T[] {
  const that = this as T[];
  return that.sort((a: any, b: any) => {
    const x = new Date(expression(a));
    const y = new Date(expression(b));
    const xMilliseconds = x.getTime();
    const yMilliseconds = y.getTime();

    if (ascending) {
      return +(!x) - +(!y) || +(xMilliseconds > yMilliseconds) || -(xMilliseconds < yMilliseconds);
    } else {
      return +(!x) - +(!y) || -(xMilliseconds > yMilliseconds) || +(xMilliseconds < yMilliseconds);
    }
  });
};

Array.prototype.pushUnique = function(...items) {
  if (Array.isArray(items)) {
    items.forEach(item => {
      if (!this.includes(item)) {
        this.push(item);
      }
    });
  } else {
    if (!this.includes(items)) {
      this.push(items);
    }
  }
};

Array.prototype.skip = function (count) {
  return this && this.length
    ? this.slice(count)
    : [];
};

Array.prototype.take = function (count) {
  return this.slice(0, !count ? this.length : count);
};

Array.prototype.sum = function () {
  return this
    .reduce((total, current) => {
      return total + current;
    }, 0);
};

Object.toGroup = function<T>(target) {
  const result: IGroup<T>[] = [];

  Object.keys(target).forEach(key => {
    result.push({
      key,
      values: target[key]
    });
  });

  return result;
};

Object.combine = (input, ...sources) => {
  const options: ICombineOptions = {
    arrayBehavior: 'merge'
  };

  // Ensure we have actual sources for each.
  const output = deepCopy(input || {});

  if (sources) {
    // Enumerate the objects and their keys.
    for (let i = 0; i < sources.length; i++) {
      const object = sources[i] || {};
      const keys = Object.keys(object);

      for (let idx = 0; idx < keys.length; idx++) {
        const key = keys[idx];
        const value = object[key];
        const existingValueType = getType(output[key]);
        const type = getType(value);

        switch (type) {
        case 'object':
          if (existingValueType) {
            const existingValue = existingValueType === 'object' ? output[key] : {};
            output[key] = Object.combine({}, existingValue, clone(value));
          } else {
            output[key] = clone(value);
          }
          break;
        case 'array':
          if (existingValueType === 'array') {
            const newValue = clone(value);
            output[key] = options.arrayBehavior === 'merge' ? output[key].concat(newValue) : newValue;
          } else {
            output[key] = clone(value);
          }
          break;
        default:
          output[key] = value;
          break;
        }
      }
    }
  }

  return output;
};

String.isNullOrEmpty = (value: any) => {
  if (value === null || value === undefined) {
    return true;
  }
  return value === '';
};

String.prototype.contains = function (value: string, caseSensitive?: boolean) {
  const val = this || '';
  if (caseSensitive) {
    return val.indexOf(value) !== -1;
  }
  return val.toLowerCase().indexOf((value || '').toLowerCase()) !== -1;
};

String.prototype.toFloat = function() {
  const toFloat = (target: string) => {
    if (target.indexOf('/') > -1) {
      const [numerator, denominator] = target.trim().split('/');
      return parseInt(numerator, 10) / parseInt(denominator, 10);
    }
    return parseFloat(target);
  };

  const parseFraction = (target: string) => {
    const parts = target.trim().split(' ');
    return parts.reduce((res, num) => {
      return res + toFloat(num);
    }, 0);
  };

  return parseFraction(this);
};

String.prototype.toSlug = function(lowerCase?) {
  const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  const s = lowerCase ? this.toString().toLowerCase() : this.toString();

  return s
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

URLSearchParams.prototype.toObject = function() {
  const obj = {};
  const pairs = this.toString().split('&');
  pairs.forEach(item => {
    const [key, value] = item.split('=');
    if (key) {
      obj[key] = value;
    }
  });

  return obj;
};
