import React, { Component } from 'react';
import { components } from 'react-select';
import Select, { SelectInstance }  from 'react-select';
import { ReactSelectActionMeta } from '@constants';
import carrotIcon from '@assets/icon/carrot_field.svg';
import carrotIconWhite from '@assets/icon/carrot_field_white.svg';
import { IonIcon } from '@ionic/react';
import { useDarkMode } from '@hooks/useDarkMode';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
      <hr className='items-dropdown-divider'/>
    </div>
  );
};

const MultiValueContainer = ({ selectProps, data }) => {
  if(data.parent) {
    const values = (selectProps.value).filter(value => value?.parent);
    if (values) {
      return values[values.length - 1].label === data.label
        ? data.label
        : data.label + ', ';
    } else return '';
  } else {
    return '';
  }
};

const DropdownIndicator = () => {
  const { darkMode } = useDarkMode();

  return (
    <IonIcon class='dropdown-indicator' icon={!darkMode ? carrotIcon : carrotIconWhite}></IonIcon>
  );
};

export default class MultiselectDropdown extends Component<{ itemOptions, itemsDropdownStyle, setSelectedItems, selectedItems, disabled? }, { selectedItems }> {
  selectRef: React.RefObject<SelectInstance>;

  constructor(props) {
    super(props);
    this.state = {
      selectedItems: this.props.selectedItems
    };

    this.selectRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', (event) => {
      if (this.selectRef.current?.controlRef && !this.selectRef.current?.controlRef?.contains(event.target as Node)
      || this.selectRef.current?.menuListRef && !this.selectRef.current?.menuListRef?.contains(event.target as Node)) {
        this.props.setSelectedItems(this.state.selectedItems);
      }
    });
  }

  // Determines selections based on parent/child groupings
  handleChange = (selected, event) => {
    let selectedItems = selected;

    if (event.action === ReactSelectActionMeta.SelectOption) {
      if(event.option.value === '*') {
        selectedItems = this.props.itemOptions;
      } else {
        if(!event.option.parent) {
          selectedItems = [...selectedItems, ...this.props.itemOptions.filter(itemOption => itemOption.parent === event.option.value)];
        } else if(this.props.itemOptions.filter(itemOption => itemOption.parent === event.option.parent)
          .map(itemOption => itemOption.value)
          .every(itemOption => (selectedItems.map(item => item.value)).includes(itemOption))) {
          selectedItems = [...selectedItems, ...this.props.itemOptions.filter(itemOption => itemOption.value === event.option.parent)];
        }

        if(this.props.itemOptions.filter(itemOption => itemOption.value !== '*')
          .map(itemOption => itemOption.value)
          .every(itemOption => (selectedItems.map(item => item.value)).includes(itemOption))) {
          selectedItems = [...selectedItems, ...this.props.itemOptions.filter(itemOption => itemOption.value === '*')];
        }
      }
    } else if (event.action === ReactSelectActionMeta.DeselectOption) {
      if(event.option.value === '*') {
        selectedItems = [];
      } else {
        selectedItems = selectedItems.filter(selectedItem => selectedItem.value !== '*');

        if(!event.option.parent) {
          selectedItems = selectedItems.filter(selectedItem => selectedItem.parent !== event.option.value);
        } else if(!selectedItems.some(selectedItem => selectedItem.parent === event.option.value)) {
          selectedItems =  selectedItems.filter(selectedItem => selectedItem.value !== event.option.parent);
        }
      }
    }

    this.setState({ selectedItems });
  };

  render() {
    return (
      <>
        <Select
          isMulti
          ref={this.selectRef}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          className='select-items-dropdown'
          placeholder="Select Items"
          options={this.props.itemOptions}
          styles={this.props.itemsDropdownStyle}
          isSearchable={false}
          defaultValue={this.state.selectedItems}
          value={this.state.selectedItems}
          onChange={this.handleChange}
          theme={(theme) => ({
            ...theme,
            spacing: {
              ...theme.spacing,
              controlHeight: 26,
            }
          })}
          components={{
            Option,
            MultiValueContainer,
            DropdownIndicator
          }}
          hideSelectedOptions={false}
          isClearable={false}
          menuPlacement="auto"
          maxMenuHeight={242}
          isDisabled={this.props.disabled}
        />
      </>
    );
  }
}