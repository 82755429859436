import * as React from 'react';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import FeatureFlag from '@interfaces/feature-flag';

const { createContext, useContext } = React;

const StorageContext = createContext(null);

export const StorageProvider = (props) => {
  const value = {
    createStore: props.createStore || createStore,
    token: props.token || token,
    getToken: props.getToken || getToken,
    setToken: props.setToken || setToken,
    removeToken: props.removeToken || removeToken,
    setRefresh: props.setRefresh || setRefresh,
    getRefresh: props.getRefresh || getRefresh,
    removeRefresh: props.removeRefresh || removeRefresh,
    getFeatureFlags: props.getFeatureFlags || getFeatureFlags,
    setFeatureFlags: props.getFeatureFlags || setFeatureFlags
  };

  return (
    <StorageContext.Provider value={value}>
      {props.children}
    </StorageContext.Provider>
  );
};

export const useStorage = () => {
  return useContext(StorageContext);
};

let storage: Storage;
const token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

export async function createStore(name = 'nc-app') {
  storage = new Storage({
    name,
  }, 'localstorage');

  await storage.ready();
}

export const token = () => {
  return token$.getValue();
};

export async function getToken(): Promise<any> {
  const value = await storage.get('token');
  token$.next(value);
  return value;
}

export async function setToken(value: string) {
  await storage.set('token', value);
  token$.next(value);
}

export async function removeToken() {
  await storage.remove('token');
}

export async function setRefresh(value: boolean) {
  await storage.set('refresh', value);
}

export async function getRefresh(): Promise<boolean> {
  return storage.get('refresh');
}

export async function removeRefresh() {
  await storage.remove('refresh');
}

export async function getFeatureFlags() {
  return await storage.get('featureFlags');
}

export async function setFeatureFlags(value: FeatureFlag) {
  await storage.set('featureFlags', value);
}
