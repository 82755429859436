import { BoosterAllowanceGroups, CustomerSuppliedVendors, CutMethods, CylinderTypes, DefaultFlatteningSelections, DiameterUOMTypes, FieldTypes, FinishedProductTypes, GuardRailsLongDescription, HeadFormingTypes, HeatTreatCheckCladTypes, Incoterms, InnerOrOuterDimensions, LaborTypes, MSTOAssemblyMetals, MSTOSelectionTabs, MachiningTypes, ManufacturingSites, MeasurementSystems, MetalClasses, MetalProposalTypes, MetalPurchaseRequirementsGroups, MetalTypes, MetalTypesForAreaCalc, OutsideServices, PanelLocations, PlateMethods, PostCladBaseMetalUTSpecifications, PostCladTestingOptions, PostCladTestingOptionsAdderTypes, QuoteLineItemAdditionalOptions, QuoteLineItemCertificates, QuoteLineItemDependentUnwrittenFields, QuoteLineItemFields, QuoteLineItemValueTypes, QuoteLineItemsColumnHeadings, QuoteVariableConfigDescriptions, RadiographyTypes, RawMaterialUsage, Regexes, Shapes, StandardLeadTimes, Units, VendorServices, mmToInDivider, sixtyMinutes, sqInToSqFtDivider, sqMmToSqMDivider, twoPieceHeadMultiplier } from '@constants';
import { InitDataContextProps } from '@context/data-context';
import { AnvilItemDetail } from '@interfaces/anvil-item-detail';
import { BoosterAllowance, BoosterAllowances } from '@interfaces/booster-allowances';
import { CylinderItemDetail } from '@interfaces/cylinder-item-detail';
import { EdgeAllowance } from '@interfaces/edge-allowances';
import { FinishedGood, FlatteningLabor } from '@interfaces/finished-good';
import { Freight } from '@interfaces/freight';
import { OutsideServiceCosts } from '@interfaces/outside-service-costs';
import { Quote } from '@interfaces/quote';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { QuoteVariableConfiguration } from '@interfaces/quote-variable-configurations';
import { AnvilRequirements, AnvilRequirementsMeasurements, BaseMetalUTOption, CosmeticGrindingAllowances, CuttingKerfs, ExplosiveGroupMeasurements, FlatteningAndThinningValuesMeasurements, MetalPricingMeasurements, MetalSizeLimits, RawMaterial, ThinningGroup } from '@interfaces/raw-material';
import { HeatTreatMeasurements, PreHeatTiming, PreHeatTimingMeasurements, RawMaterialCombination } from '@interfaces/raw-material-combination';
import { Currency } from '@interfaces/salesforce';
import { SeamWeldItemDetail } from '@interfaces/seam-weld-item-detail';
import { Selection, SelectionOption } from '@interfaces/selection';
import { AdderMeasurements, SpecificationAdderGroup, SpecificationTestOptions } from '@interfaces/specification-test-options';
import { Vendor } from '@interfaces/vendor';
import { checkForUSASpelling, findCountryCode, findStateCode } from '@shared/data/countries';
import { quoteFieldDependencyMap } from './dependency-map';
import { CutMethodLaborCosts, DependentUnwrittenFieldsMapValueType, FieldsMapValueType, PONotesVariables, Panels, SeamPanels } from './types';
import { convertUnits, formatCurrency, getBadgeText, stringToFloat } from './utils';

// Maps quote properties so that the UI can consume the quote
export const mapQuote = async (quote: Quote, lastViewed?: any) => {

  return {
    ...quote,
    badgeText: await getBadgeText(quote.Id, new Date(quote.CreatedDate), new Date(quote.LastModifiedDate), lastViewed)
  };
};

// Returns the proper specification adder group based on finished product type, clad shape and finished good width
export const getSpecificationAdderGroup = (specificationAdderGroups: SpecificationAdderGroup[], finishedWidth: number, shape: Shapes, isForging: boolean, inputUOMType: MeasurementSystems) => {
  return specificationAdderGroups?.find(specificationAdderGroup => {
    return (isForging ? isForging === specificationAdderGroup.forging : !specificationAdderGroup.forging) &&
    (specificationAdderGroup.shape === shape) &&
    (!specificationAdderGroup['<fgWidth'] || finishedWidth < specificationAdderGroup['<fgWidth'][inputUOMType.toLowerCase()]) &&
    (!specificationAdderGroup['>=fgWidth'] || finishedWidth >= specificationAdderGroup['>=fgWidth'][inputUOMType.toLowerCase()]);
  });
};

//Returns specification adder based on the specification selected
export const getSpecificationAdderBySpecification = (inputUOMType: MeasurementSystems, specificationTestOption: SpecificationTestOptions, specification: string, finishedWidth: number, shape: Shapes, isForging: boolean, boosterAllowance: number, qvcs: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites) => {
  let specificationAdder = 0;
  if(specificationTestOption.specificationAdder) {
    const specificationAdderGroup = getSpecificationAdderGroup(specificationTestOption.specificationAdder, finishedWidth, shape, isForging, inputUOMType)[specification] || {};
    specificationAdder = calculateSpecificationAdder(inputUOMType, specificationAdderGroup, finishedWidth, boosterAllowance, null, isForging, qvcs, manufacturingSite, shape);
  }

  return specificationAdder;
};

//Returns specification adder based on the post clad testing options selected
export const getSpecificationAdderByPostCladTestingOptions = (inputUOMType: MeasurementSystems, postCladTestingOptions: string[], specificationTestOption: SpecificationTestOptions, finishedWidth: number, shape: Shapes, isForging: boolean, boosterAllowance: number, qvcs: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites, itemType?: FinishedGood) => {
  const firstOption = postCladTestingOptions[0];
  const optionAdders = getSpecificationAdderGroup(specificationTestOption.postCladTestingOptions.find(option => option.name === firstOption)?.adders, finishedWidth, shape, isForging, inputUOMType) || {};
  const optionNumbersSelected = specificationTestOption.postCladTestingOptions
    .filter(option => postCladTestingOptions.includes(option.name))
    .map(option => option.optionNumber);

  let newSpecificationAdder = 0;

  if(optionNumbersSelected.length >= 1) {
    let adderGroupKey;

    if(optionAdders[PostCladTestingOptionsAdderTypes.ShearTestOnly] && optionNumbersSelected.length === 1 && optionNumbersSelected[0] === '1') {
      adderGroupKey = PostCladTestingOptionsAdderTypes.ShearTestOnly;
    } else if (optionAdders[PostCladTestingOptionsAdderTypes.AnyOrNoneOf12345And6] && optionNumbersSelected.includes('6')) {
      adderGroupKey = PostCladTestingOptionsAdderTypes.AnyOrNoneOf12345And6;
    } else if (optionAdders[PostCladTestingOptionsAdderTypes.AnyOrNoneOf12345And7] && optionNumbersSelected.includes('7') && !optionNumbersSelected.includes('8')) {
      adderGroupKey = PostCladTestingOptionsAdderTypes.AnyOrNoneOf12345And7;
    } else if (optionAdders[PostCladTestingOptionsAdderTypes.AnyOrNoneOf12345And8] && optionNumbersSelected.includes('8') && !optionNumbersSelected.includes('7')) {
      adderGroupKey = PostCladTestingOptionsAdderTypes.AnyOrNoneOf12345And8;
    } else if (optionAdders[PostCladTestingOptionsAdderTypes.AnyOrNoneOf12345And7And8] && optionNumbersSelected.includes('7') && optionNumbersSelected.includes('8')) {
      adderGroupKey = PostCladTestingOptionsAdderTypes.AnyOrNoneOf12345And7And8;
    } else if (optionAdders[PostCladTestingOptionsAdderTypes.AnyCombinationOf12345]) {
      adderGroupKey = PostCladTestingOptionsAdderTypes.AnyCombinationOf12345;
    }
    const adderToUse = optionAdders[adderGroupKey];

    newSpecificationAdder = calculateSpecificationAdder(inputUOMType, adderToUse, finishedWidth, boosterAllowance, adderGroupKey, isForging, qvcs, manufacturingSite, shape, itemType);
  }

  return newSpecificationAdder;
};

export const calculateSpecialTestingManHours = (UOMType: MeasurementSystems, cladArea: number, qvcs: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites) => {
  const specialSpecTestingManHoursPerSQLengthUnitDescription = UOMType === MeasurementSystems.Imperial ?
  QuoteVariableConfigDescriptions.SpecificationSpecialTestingManHoursSQFT :
  QuoteVariableConfigDescriptions.SpecificationSpecialTestingManHoursSQM;

  const specialSpecTestingManHoursPerSQLengthUnit = getQuoteVariableConfiguration(qvcs, specialSpecTestingManHoursPerSQLengthUnitDescription, manufacturingSite, FieldTypes.Float) as number;

  return UOMType === MeasurementSystems.Imperial ?
    convertUnits(cladArea, Units.Inches, Units.Feet) * specialSpecTestingManHoursPerSQLengthUnit :
    convertUnits(cladArea, Units.Millimeters, Units.Meters) * specialSpecTestingManHoursPerSQLengthUnit;
};

export const getSpecificationTestManHours = (specificationTestOption: SpecificationTestOptions, postCladTestingOptions: string, ferroxylTestOfCladdingSurfacePerformed: string, UOMType: MeasurementSystems, cladArea: number, baseMetalUT: BaseMetalUTOption, qvcs: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites) => {
  let ferroxylTestManHoursPerQuantity = 0;
  if(ferroxylTestOfCladdingSurfacePerformed) {
    ferroxylTestManHoursPerQuantity = calculateSpecialTestingManHours(UOMType, cladArea, qvcs, manufacturingSite);
  }

  let angleUTManHoursPerQuantity = 0;
  if(baseMetalUT.angleUT) {
    angleUTManHoursPerQuantity = calculateSpecialTestingManHours(UOMType, cladArea, qvcs, manufacturingSite);
  }

  const specificationManHoursPerQuantity = specificationTestOption.manHoursPerQuantity;

  if(specificationManHoursPerQuantity) {
    return specificationManHoursPerQuantity + ferroxylTestManHoursPerQuantity + angleUTManHoursPerQuantity;
  }

  const optionsSelected = specificationTestOption.postCladTestingOptions.filter(option => postCladTestingOptions?.includes(option.name));

  return [...optionsSelected.map(option => option.manHoursPerQuantity), ferroxylTestManHoursPerQuantity, angleUTManHoursPerQuantity].reduce((totalManHours, currentManHours) => totalManHours + currentManHours, 0);
};

export const getShearBoosterAllowanceDiff = (uom: MeasurementSystems, boosterAllowance: number) => {
  const shearAllowance = calculateUOMValue(MeasurementSystems.Imperial, uom, 2, Units.Millimeters, Units.Inches);

  return shearAllowance - boosterAllowance;
};

// Calculates the specification adder based on the clad width
export const calculateSpecificationAdder = (inputUOMType: MeasurementSystems, specificationAdderMeasurements: AdderMeasurements, finishedWidth: number, boosterAllowance: number, adderGroupKey: PostCladTestingOptionsAdderTypes, isForging: boolean, qvcs: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites, shape: Shapes, itemType?: FinishedGood) => {
  const isShearTestOnly = adderGroupKey === PostCladTestingOptionsAdderTypes.ShearTestOnly;
  const isOneToFive = adderGroupKey === PostCladTestingOptionsAdderTypes.AnyCombinationOf12345;

  const smallCircleSpecAdderVariable = inputUOMType === MeasurementSystems.Imperial ? QuoteVariableConfigDescriptions.BoosterAllowanceExceptionForSmallCircleDiametersIN : QuoteVariableConfigDescriptions.BoosterAllowanceExceptionForSmallCircleDiametersMM;
  const circleSpecAdderThreshold = getQuoteVariableConfiguration(qvcs, smallCircleSpecAdderVariable, manufacturingSite, FieldTypes.Float) as number;

  const specificationTestingRange1CladWidthDescription = inputUOMType === MeasurementSystems.Imperial ?
  QuoteVariableConfigDescriptions.SpecificationTestingRange1CladWidthforAdditionIN :
  QuoteVariableConfigDescriptions.SpecificationTestingRange1CladWidthforAdditionMM;

  const specificationTestingRange2CladWidthDescription = inputUOMType === MeasurementSystems.Imperial ?
  QuoteVariableConfigDescriptions.SpecificationTestingRange2CladWidthforAdditionIN :
  QuoteVariableConfigDescriptions.SpecificationTestingRange2CladWidthforAdditionMM;

  const specificationAdderForSmallCirclesDescription = inputUOMType === MeasurementSystems.Imperial ?
  QuoteVariableConfigDescriptions.SpecificationAdderForSmallCirclesIN :
  QuoteVariableConfigDescriptions.SpecificationAdderForSmallCirclesMM;

  const specificationTestingRange1CladWidth = getQuoteVariableConfiguration(qvcs, specificationTestingRange1CladWidthDescription, manufacturingSite, FieldTypes.Float) as number;
  const specificationTestingRange2CladWidth = getQuoteVariableConfiguration(qvcs, specificationTestingRange2CladWidthDescription, manufacturingSite, FieldTypes.Float) as number;
  const specificationAdderForSmallCircles = getQuoteVariableConfiguration(qvcs, specificationAdderForSmallCirclesDescription, manufacturingSite, FieldTypes.Float) as number;

  if(finishedWidth < specificationTestingRange1CladWidth) {
    if (isShearTestOnly) {
      if (shape === Shapes.Circle && finishedWidth < circleSpecAdderThreshold && !isForging) {
        return specificationAdderForSmallCircles;
      } else {
        const uomType = inputUOMType.toLowerCase();
        return specificationAdderMeasurements[uomType].widthRange1Adder;
      }
    } else if (isOneToFive) {
      if (shape === Shapes.Circle && finishedWidth < circleSpecAdderThreshold && !isForging) {
        return specificationAdderForSmallCircles;
      } else {
        const uomType = inputUOMType.toLowerCase();
        return specificationAdderMeasurements[uomType].widthRange1Adder;
      }
    }
    return 0;
  } else if(finishedWidth >= specificationTestingRange1CladWidth && finishedWidth < specificationTestingRange2CladWidth) {
    return isShearTestOnly && isForging ? Math.max(getShearBoosterAllowanceDiff(inputUOMType, boosterAllowance), 0) : specificationAdderMeasurements[inputUOMType.toLowerCase()].widthRange2Adder;
  }

  return isShearTestOnly && isForging ? Math.max(getShearBoosterAllowanceDiff(inputUOMType, boosterAllowance), 0) : specificationAdderMeasurements[inputUOMType.toLowerCase()].widthRange3Adder;
};

export const calculateBaseMetalLengthAddition = (baseMetal: RawMaterial, manufacturingSite: ManufacturingSites, unitsOfMeasure: MeasurementSystems, finishedLength: number, finishedWidth: number, rawBaseMetalThickness: number, edgeAllowance: number, kerfAllowance: number, wide: number, long: number, anvilEdgeAllowanceAddition: number, specificationAdder: number, isTwoPieceHead: boolean, headDiameterThreshold: number, isForging: boolean) => {
  const metalSizeLimitsByUOM: MetalSizeLimits[] = baseMetal.metalSizeLimits[manufacturingSite].map(sizeLimit => sizeLimit[unitsOfMeasure.toLowerCase()]);

  const rawLengthForLengthaddition = calculateBaseLength(long, finishedLength, edgeAllowance, anvilEdgeAllowanceAddition, specificationAdder, 0, kerfAllowance, isForging, 0, isTwoPieceHead, headDiameterThreshold);
  const rawWidthForLengthAddition = calculateBaseWidth(wide, finishedWidth, edgeAllowance, anvilEdgeAllowanceAddition, 0, kerfAllowance, isForging, specificationAdder, isTwoPieceHead);
  const rawSQUnitsForLengthAddition = rawLengthForLengthaddition * rawWidthForLengthAddition;

  const metalSizeLimit = metalSizeLimitsByUOM.find(sizeLimit => {
    return rawBaseMetalThickness >= sizeLimit['>=TK']
    && rawBaseMetalThickness < sizeLimit['<TK']
    && rawSQUnitsForLengthAddition <= sizeLimit.maxSQUnit
    && rawLengthForLengthaddition > sizeLimit.lMax;
  });

  return metalSizeLimit?.lMaxAddUnit || 0;
};

// Calculates the clad dimension for the line item details
export const calculateCladDimension = (baseDimension: number, cladOverhang: number, cladOverhangThin: number) => {
  return baseDimension + (cladOverhangThin !== 0 ? cladOverhangThin : cladOverhang);
};

// Calculates the base width for the line item details
export const calculateBaseWidth = (wide: number, finishedWidth: number, edgeAllowance: number, anvilEdgeAllowanceAddition: number, boosterAllowance: number, kerfAllowance: number, isForging: boolean, specificationAdder: number, isTwoPieceHead: boolean) => {
  let baseWidth: number;

  if (isTwoPieceHead) {
    baseWidth = (finishedWidth/2) + (edgeAllowance * 2) + (2 * anvilEdgeAllowanceAddition);
  } else {
    baseWidth = (wide * finishedWidth) + (kerfAllowance * (wide - 1)) + (2 * edgeAllowance) + (2 * anvilEdgeAllowanceAddition) + boosterAllowance;
  }

  if(isForging) {
    baseWidth += specificationAdder;
  }

  return baseWidth;
};

// Calculates the base length for the line item details
export const calculateBaseLength = (long: number, finishedLength: number, edgeAllowance: number, anvilEdgeAllowanceAddition: number, specificationAdder: number, baseMetalLengthAddition: number, kerfAllowance: number, isForging: boolean, boosterAllowance: number, isTwoPieceHead: boolean, headDiameterThreshold: number) => {
  let baseLength: number;

  if (isTwoPieceHead && finishedLength <= headDiameterThreshold) {
    baseLength = (twoPieceHeadMultiplier * finishedLength) + kerfAllowance + (edgeAllowance * 2) + (2 * anvilEdgeAllowanceAddition);
  } else if (isTwoPieceHead && finishedLength > headDiameterThreshold) {
    baseLength = finishedLength + (edgeAllowance * 2) + (2 * anvilEdgeAllowanceAddition);
  } else {
    baseLength = (long * finishedLength) + (kerfAllowance * (long - 1)) + (2 * edgeAllowance) + (2 * anvilEdgeAllowanceAddition) + specificationAdder + baseMetalLengthAddition;
  }

  if(isForging) {
    baseLength += boosterAllowance;
  }

  return baseLength;
};

// Calculates the weight for a finished metal, based on returned UOM, Length, Width, Thickness, Density, item type and shape
export const calculateFinishedMetalWeight = (iutputUOMType: MeasurementSystems, outputUOMType: MeasurementSystems, length: number, width: number, thickness: number, density: number, shape?: Shapes) => {
  const calculatedWidth = calculateUOMValue(iutputUOMType, outputUOMType, width, Units.Millimeters, Units.Inches);
  const calculatedLength = calculateUOMValue(iutputUOMType, outputUOMType, length, Units.Millimeters, Units.Inches);
  const calculatedThickness = calculateUOMValue(iutputUOMType, outputUOMType, thickness, Units.Millimeters, Units.Inches);

  const area: number = shape === Shapes.Circle
    ? Math.PI * Math.pow((calculatedWidth/2), 2)
    : calculatedWidth * calculatedLength;

  return area * calculatedThickness * density;
};

// Calculates the weight for a raw metal, based on returned UOM, Length, Width, Thickness, Density and base metal forging
export const calculateRawMetalWeight = (iutputUOMType: MeasurementSystems, outputUOMType: MeasurementSystems, length: number, width: number, thickness: number, density: number, metalType: MetalTypesForAreaCalc, isForging?: boolean) => {
  const calculatedWidth = calculateUOMValue(iutputUOMType, outputUOMType, width, Units.Millimeters, Units.Inches);
  const calculatedLength = calculateUOMValue(iutputUOMType, outputUOMType, length, Units.Millimeters, Units.Inches);
  const calculatedThickness = calculateUOMValue(iutputUOMType, outputUOMType, thickness, Units.Millimeters, Units.Inches);

  switch(metalType) {
    case MetalTypesForAreaCalc.Base:
      if(isForging) {
        return Math.PI * Math.pow((calculatedWidth/2), 2) * calculatedThickness * density;
      } else {
        return calculatedWidth * calculatedLength * calculatedThickness * density;
      }
    case MetalTypesForAreaCalc.Clad:
    case MetalTypesForAreaCalc.Flyer:
    case MetalTypesForAreaCalc.Anvil:
    default:
      return calculatedWidth * calculatedLength * calculatedThickness * density;
  }
};

// Calculates the metal density
export const calculateMetalDensity = (UOMType: MeasurementSystems, metal: RawMaterial) => {
  return UOMType === MeasurementSystems.Imperial ? metal.nomDensity[UOMType.toLowerCase()] : metal.nomDensity[UOMType.toLowerCase()] * 1e-6;
};

// Calculates the anvil length
export const calculateAnvilLength = (baseLength: number, anvilDimensionAdder: number) => {
  return baseLength + anvilDimensionAdder;
};

// Calculates the anvil width
export const calculateAnvilWidth = (baseWidth: number, anvilDimensionAdder: number) => {
  return baseWidth + anvilDimensionAdder;
};

// Calculates the edge allowance
export const calculateEdgeAllowance = (edgeAllowanceGroup: EdgeAllowance[], thickness: number, unitsOfMeasure: MeasurementSystems) => {
  const edgeAllowancesByUOM = edgeAllowanceGroup.map(edgeAllowance => edgeAllowance[unitsOfMeasure.toLowerCase()]);

  return edgeAllowancesByUOM?.find(edgeAllowance => thickness > edgeAllowance['>cladTKMin'] && thickness <= edgeAllowance['<=cladTKMax'])?.edgeAllowance || 0;
};

// Calculates the booster allowance
export const calculateBoosterAllowance = (boosterAllowanceGroup: BoosterAllowance[], cladThickness: number, unitsOfMeasure: MeasurementSystems, circleBoosterThreshold: number, isForging: boolean, finishedWidth: number, shape: string) => {
  if (shape === Shapes.Circle && finishedWidth >= circleBoosterThreshold && !isForging) {
    return 0;
  } else {
    const boosterAllowancesByUOM = boosterAllowanceGroup?.map(boosterAllowance => boosterAllowance[unitsOfMeasure.toLowerCase()]);
    return boosterAllowancesByUOM?.find(boosterAllowance => cladThickness > boosterAllowance['>cladTKMin'] && cladThickness <= boosterAllowance['<=cladTKMax'])?.boosterAllowance || 0;
}};

// Calculates the kerf allowance
export const calculateKerfAllowance = (cladMetal: RawMaterial, manufacturingSite: ManufacturingSites, cutMethod: string, unitsOfMeasure: MeasurementSystems, thickness: number) => {
  const cuttingKerfs = cladMetal.cuttingKerfs[manufacturingSite].filter(cuttingKerfsGroup => cuttingKerfsGroup.cuttingType === cutMethod);
  const cuttingKerfsGroupsByUOM: CuttingKerfs[] = cuttingKerfs.map(cuttingKerfsGroup => cuttingKerfsGroup[unitsOfMeasure.toLowerCase()]);

  const cuttingKerfsGroup = cuttingKerfsGroupsByUOM.find(cuttingKerfGroup => {
    return thickness > cuttingKerfGroup['>minThickness']
    && thickness <= cuttingKerfGroup['<=maxThickness'];
  });

  return cuttingKerfsGroup?.kerfAllowance || 0;
};

// Calculates the thickness addition for a base or clad metal
export const calculateThicknessAddition = (metal: RawMaterial, manufacturingSite: ManufacturingSites, unitsOfMeasure: MeasurementSystems, finishedMetalThickness: number) => {
  const thinningGroupsByUOM: ThinningGroup[] = metal?.thinningGroup[manufacturingSite].map(thinningGroup => thinningGroup[unitsOfMeasure.toLowerCase()]);

  const metalThinningGroup = thinningGroupsByUOM?.find(thinningGroup => {
    return finishedMetalThickness > thinningGroup['>TK']
    && finishedMetalThickness <= thinningGroup['<=TK'];
  });

  return metalThinningGroup?.TKAddition || 0;
};

// Calculates the clad thickness for the line item details
export const calculateCladThickness = (finishedCladMetalThickness: number, cladFlatteningThinningValue: number, cladThicknessAddition: number, cosmeticGrindingCladThicknessAddition: number) => {
  return finishedCladMetalThickness + cladFlatteningThinningValue + cladThicknessAddition + cosmeticGrindingCladThicknessAddition;
};

// Calculates the cosmetic grinding clad thickness addition
export const calculateCosmeticGrindingCladThicknessAddition = (cladMetal: RawMaterial,  manufacturingSite: ManufacturingSites, unitsOfMeasure: MeasurementSystems) => {
  const cosmeticGrindingAllowancesByUOM: CosmeticGrindingAllowances = cladMetal.cosmeticGrindingAllowances[manufacturingSite][unitsOfMeasure.toLowerCase()];
  return cosmeticGrindingAllowancesByUOM.cosmeticGrindingAllowance;
};

// Calculates the base thickness for the line item details
export const calculateBaseThickness = (finishedBaseMetalThickness: number, baseFlatteningThinningValue: number, baseThicknessAddition: number) => {
  return finishedBaseMetalThickness + baseFlatteningThinningValue + baseThicknessAddition;
};

// Calculates the anvil thickness for specified measurement system
export const calculateAnvilThickness = (anvilRequirementsGroup: AnvilRequirementsMeasurements, UOMType: MeasurementSystems, baseThickness: number, cladThickness: number) => {
  let calculatedAnvilThickness = 0;
  let newAnvilThickness = 0;
  const anvilFactor = anvilRequirementsGroup?.anvilFactor || 0;

  if(baseThickness < (cladThickness * anvilFactor)) {
    calculatedAnvilThickness = cladThickness * anvilFactor - baseThickness;
  }

  //only runs max tk calc if calc anvil thickness !== 0
  if (calculatedAnvilThickness !== 0) {
    const possibleAnvilThicknesses = (anvilRequirementsGroup[UOMType.toLowerCase()] as AnvilRequirements).anvilThicknesses;
    newAnvilThickness = possibleAnvilThicknesses?.sort().find((anvilThickness, index) => anvilThickness >= calculatedAnvilThickness && typeof possibleAnvilThicknesses[index - 1] === 'number' && (possibleAnvilThicknesses[index - 1] || 0) < calculatedAnvilThickness) || 0;
  }

  return newAnvilThickness;
};

// Calculates default welding cost
export const calculateDefaultWelding = (cladCost: number, weldCostPercentage: number) => {
  //quantity is accounted for in cladCost calc
  return (cladCost * weldCostPercentage);
};

// Calculates manual welding cost
export const calculateManualWelding = (qvcs: QuoteVariableConfiguration[], UOMType: MeasurementSystems, weldingLength: number, cladThickness: number, weldingConsumableCost: number, cladMetal: RawMaterial, clads: number, radiographyClad: RadiographyTypes, manufacturingSite: ManufacturingSites, quantity:  number) => {
  const weldingLaborRate = getLaborRate(cladMetal, manufacturingSite, LaborTypes.WeldingLabor);

  const weldingManHoursPerSQLengthUnitDescription = UOMType === MeasurementSystems.Imperial ?
    QuoteVariableConfigDescriptions.WeldingLaborManHoursSQIN :
    QuoteVariableConfigDescriptions.WeldingLaborManHoursSQMM;

  const welding100PercentCostPerLengthUnitDescription = UOMType === MeasurementSystems.Imperial ?
    QuoteVariableConfigDescriptions.WeldingRadiography100PercentCostF :
    QuoteVariableConfigDescriptions.WeldingRadiography100PercentCostM;

  const weldingManHoursPerSQLengthUnit = getQuoteVariableConfiguration(qvcs, weldingManHoursPerSQLengthUnitDescription, manufacturingSite, FieldTypes.Float) as number;
  const weldingSpotCostPerClad = getQuoteVariableConfiguration(qvcs, QuoteVariableConfigDescriptions.WeldingRadiographySpotCostPerClad, manufacturingSite, FieldTypes.Float) as number;
  const welding100PercentCostPerLengthUnit = getQuoteVariableConfiguration(qvcs, welding100PercentCostPerLengthUnitDescription, manufacturingSite, FieldTypes.Float) as number;

  const weldingLaborCost = weldingManHoursPerSQLengthUnit * weldingLaborRate * weldingLength * cladThickness;
  const radiographyCost = ((radiographyClad || cladMetal.radiographyType) === RadiographyTypes.Spot
    ? (weldingSpotCostPerClad)
    : (welding100PercentCostPerLengthUnit * convertUnits(weldingLength, UOMType === MeasurementSystems.Imperial ? Units.Inches: Units.Millimeters, UOMType === MeasurementSystems.Imperial ? Units.Feet: Units.Meters)));

  return (radiographyCost + weldingConsumableCost + weldingLaborCost) * clads;
};

// Calculates welding consumable cost
export const calculateWeldingConsumableCost = (weldingLength: number, cladThickness: number, cladDensity: number, cladPricePerWeight: number, qvcs: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites) => {
  const weldingMetalCostMultiplier = getQuoteVariableConfiguration(qvcs, QuoteVariableConfigDescriptions.WeldingMetalCostMultiplier, manufacturingSite, FieldTypes.Float) as number;

  return weldingLength * Math.pow(cladThickness, 2) * cladDensity * cladPricePerWeight * weldingMetalCostMultiplier;
};

// Calculates the ship weight
export const calculateShipWeight = (totalWeightPerPC: number, quantity: number) => {
  return totalWeightPerPC * quantity;
};

// Calculates total weight per piece
export const calculateTotalWeightPerPiece = (cladWeight: number, baseWeight: number) => {
  return cladWeight + baseWeight;
};

// getFreightZones - Gets the freight zones, sorted by Zone number
export const getFreightZones = (freight: Freight[], manufacturingSite = ManufacturingSites.US01) => {
  return [
    ...new Set(
      freight
        .filter((freight: Freight) => freight.dataAreaId === manufacturingSite)
        .orderBy((freight: { zone: string }) => +freight.zone.split(' ').pop())
        .map(freight => freight.zone)
    ),
  ];
};

// Gets freight group by weight
export const getFreightGroup = (freight: Freight[], freightZone: string, weightPounds: number, weightKilograms: number, manufacturingSite: ManufacturingSites, UOMType: MeasurementSystems) => {
  const metalFreightOptions = freight.filter(freight => freight.zone === freightZone && freight.dataAreaId === manufacturingSite);
  return metalFreightOptions.find(freight => {
    const freightByUOM = freight[UOMType.toLowerCase()];
    const weight = UOMType === MeasurementSystems.Imperial ? weightPounds : weightKilograms;

    return weight > freightByUOM['>minWeight']
    && weight <= freightByUOM['<=maxWeight'];
  });
};

// Gets the metal pricing
export const getMetalPricing = (metalPricingByProposalType: MetalPricingMeasurements[], UOMType: MeasurementSystems, metalThicknessForPricing: number, finishedWidth?: number) => {
  return metalPricingByProposalType?.find(metalPricing => {
    const metalPricingByUOM = metalPricing[UOMType.toLowerCase()];

    return metalThicknessForPricing > metalPricingByUOM.minTK
    && metalThicknessForPricing <= metalPricingByUOM.maxTK
    && (finishedWidth ? finishedWidth <= metalPricingByUOM.maxWidth : true);
  });
};

// Gets the explosive load groups
export const getExplosiveLoadGroups = (cladMetal: RawMaterial, manufacturingSite: ManufacturingSites, UOMType: MeasurementSystems, cladThickness: number) => {
  const explosiveGroup = cladMetal?.explosiveGroup[manufacturingSite].filter(egm => {
    const explosiveByUOM = egm[UOMType.toLowerCase()];

    return cladThickness > explosiveByUOM.cladTKMin
    && cladThickness <= explosiveByUOM.cladTKMax;
  });

  return explosiveGroup;
};

// Gets the anvil requirements group
export const getAnvilRequirementsGroup = (cladMetal: RawMaterial, manufacturingSite: ManufacturingSites, UOMType: MeasurementSystems, baseThickness: number, cladThickness: number, cladArea: number) => {
  const baseCladRatio = baseThickness / cladThickness;
  const anvilRequirementsGroup = cladMetal?.anvilRequirements[manufacturingSite].find(anvilRequirementsMeasurements => {
    const anvilRequirementsByUOM = anvilRequirementsMeasurements[UOMType.toLowerCase()] as AnvilRequirements;

    return baseThickness >= anvilRequirementsByUOM['>=baseTK'] && baseThickness < anvilRequirementsByUOM['<baseTK']
    && baseCladRatio >= anvilRequirementsMeasurements['>=base/cladTK'] && baseCladRatio < anvilRequirementsMeasurements['<base/cladTK']
    && cladArea >= anvilRequirementsByUOM['>=cladArea'] && cladArea < anvilRequirementsByUOM['<cladArea'];
  });

  return anvilRequirementsGroup;
};

// Gets the preheat timing group
export const getPreHeatTimingGroup = (heatTreatCombinationGroup: HeatTreatMeasurements, UOMType: MeasurementSystems, totalTk: number) => {
  const preHeatTimingGroup = heatTreatCombinationGroup?.preHeatTiming.find(preHeatTiming => {
    const preHeatTimingByUOM: PreHeatTiming = preHeatTiming[UOMType.toLowerCase()];

    return totalTk > preHeatTimingByUOM['>TK']
    && totalTk <= preHeatTimingByUOM['<=TK'];
  });

  return preHeatTimingGroup;
};

// Gets the flattening/thinning group
export const getFlatteningThinningGroup = (metal: RawMaterial, manufacturingSite: ManufacturingSites, UOMType: MeasurementSystems, thickness: number, width: number) => {
  const flatteningThinningGroup = metal?.flatteningAndThinningValues[manufacturingSite].find(ftgm => {
    const flatteningThinningByUOM = ftgm[UOMType.toLowerCase()];

    return thickness > flatteningThinningByUOM['>minThickness']
    && thickness <= flatteningThinningByUOM['<=maxThickness']
    && width > flatteningThinningByUOM['>minWidth']
    && width <= flatteningThinningByUOM['<=maxWidth'];
  });

  return flatteningThinningGroup;
};

// getLaborRate - Gets the labor rate by labor type from the raw material and manufacturing site
export const getLaborRate = (
  metal: RawMaterial,
  manufacturingSite: ManufacturingSites,
  laborType = LaborTypes.GeneralLabor
) => metal.laborRates[manufacturingSite].find((l) => l.laborType === laborType).rate;

// Calculates the explosive load cost
export const calculateExplosiveLoadCost = (qvcs: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites, UOMType: MeasurementSystems, explosiveLoadWeight: number, clads: number) => {
  const explosiveLoadCostForMaterialDescription = UOMType === MeasurementSystems.Imperial ?
    QuoteVariableConfigDescriptions.ExplosiveLoadCostForMaterialPerLB :
    QuoteVariableConfigDescriptions.ExplosiveLoadCostForMaterialPerKG;

  const explosiveLoadCostPerWeight = getQuoteVariableConfiguration(qvcs, explosiveLoadCostForMaterialDescription, manufacturingSite, FieldTypes.Float) as number;

  return (explosiveLoadWeight * explosiveLoadCostPerWeight) * clads;
};

// Retrieves the metal purchasing requirements used in the raw materials
export const getDistinctMetalPurchasingRequirements = (rawMaterials: RawMaterial[], rawMaterialUsage: RawMaterialUsage, manufacturingSite: ManufacturingSites, mprGroup: MetalPurchaseRequirementsGroups) => {
  return rawMaterials
    .filter(rawMaterial => rawMaterial.usage === rawMaterialUsage && rawMaterial.dataAreaId.includes(manufacturingSite))
    .map(rawMaterial => rawMaterial.metalPurchaseRequirements[manufacturingSite])
    .flat()
    .filter(metalPurchaseRequirement => metalPurchaseRequirement?.group === mprGroup);
};

// multiplyByQuantity - multiplies the key's values by the clads
const multiplyByClads = (obj: CutMethodLaborCosts, clads: number) => {
  for (const key in obj) {
    if (typeof obj[key] === 'number') {
      obj[key] *= clads;
    }
  }
};

// Calculates the cutting costs for cut methods
export const calculateCuttingCosts = (
  cutMethod: string,
  baseMetal: RawMaterial,
  isImperialUOM: boolean,
  manufacturingSite: ManufacturingSites,
  finishedLength: number,
  finishedWidth: number,
  clads: number,
  totalRawTK: number,
  qvcs: QuoteVariableConfiguration[],
  detailQuantity: number,
  shape: Shapes,
) => {
  let cutMethodLaborCosts!: CutMethodLaborCosts;
  const cutPerimeter = shape === Shapes.Rectangle ? 2 * finishedWidth + 2 * finishedLength : finishedWidth * Math.PI;

  const costCutMethodLabor = getLaborRate(baseMetal, manufacturingSite, LaborTypes.CutMethodLabor);

  switch (cutMethod) {
  case CutMethods.WaterJetCut: {
    cutMethodLaborCosts = calculateWaterJetCutLaborCosts(isImperialUOM, totalRawTK, cutPerimeter, detailQuantity, costCutMethodLabor, qvcs, manufacturingSite);
    break;
  }
  case CutMethods.TorchCut: {
    cutMethodLaborCosts = calculateTorchCutLaborCosts(isImperialUOM, baseMetal, cutPerimeter, detailQuantity, costCutMethodLabor, qvcs, manufacturingSite);
    break;
  }
  case CutMethods.SawCut: {
    cutMethodLaborCosts = calculateSawCutLaborCosts(isImperialUOM, totalRawTK, finishedWidth, cutPerimeter, detailQuantity, costCutMethodLabor, qvcs, manufacturingSite);
    break;
  }
  default: { // Uncut - cutHours = 0
    cutMethodLaborCosts = {
      hours: 0.0,
      cutCost: 0.0,
      consumableCost: 0.0,
      totalCost: 0.0,
    };
    break;
  }
  }

  // take the hours and costs and multiply them by the clads
  multiplyByClads(cutMethodLaborCosts, clads);
  return cutMethodLaborCosts;
};

// Calculates the cutting costs for water jet cut method
export const calculateWaterJetCutLaborCosts = (
  isImperialUOM: boolean,
  totalRawTK: number,
  cutPerimeter: number,
  pieceCount: number,
  costCutMethodLabor: number,
  qvcs: QuoteVariableConfiguration[],
  manufacturingSite: ManufacturingSites
) => {
  const consumableCostWaterJetCutDollarsPerHr = getQuoteVariableConfiguration(qvcs, QuoteVariableConfigDescriptions.ConsumableCostWaterJetCutPerLaborHour, manufacturingSite, FieldTypes.Float) as number;
  const waterJetConstA = getQuoteVariableConfiguration(qvcs, QuoteVariableConfigDescriptions.WaterJetConstantAUsedInJBFormula, manufacturingSite, FieldTypes.Float) as number;
  const waterJetConstB = getQuoteVariableConfiguration(qvcs, QuoteVariableConfigDescriptions.WaterJetConstantBUsedInJBFormula, manufacturingSite, FieldTypes.Float) as number;

  const cutMethodLaborCosts: CutMethodLaborCosts = {
    hours: 0.0,
    cutCost: 0.0,
    consumableCost: 0.0,
    totalCost: 0.0,
  };

  if(manufacturingSite !== ManufacturingSites.DE01) {
    const waterJetThicknessValue = isImperialUOM ? 1 : mmToInDivider;
    // FOR: Water Jet Cut
    // Imperial Cut Hours = '(L)/(A*60*((t) to the power of B))'
    // Metric   Cut Hours = '(L/25.4)/(A*60*((t/25.4) to the power of B))'
    // L	= total length of cut in mm / inches
    // t	= total thickness of plate to be cut in mm / inches
    // A	a constant = 2.02
    // B	a constant = -1.35
    cutMethodLaborCosts.hours =
    (((cutPerimeter * pieceCount) /
    waterJetThicknessValue) /
    (waterJetConstA * sixtyMinutes * Math.pow(totalRawTK / waterJetThicknessValue, waterJetConstB)));

    // Cut Method Labor Cost = (Cut Method Hours) * (Cost for Cutting Method Labor ($60 per hour))
    cutMethodLaborCosts.consumableCost = cutMethodLaborCosts.hours * consumableCostWaterJetCutDollarsPerHr;
    cutMethodLaborCosts.cutCost = cutMethodLaborCosts.hours * costCutMethodLabor;
    cutMethodLaborCosts.totalCost = cutMethodLaborCosts.cutCost + cutMethodLaborCosts.consumableCost;
  }

  return cutMethodLaborCosts;
};

export const calculateWaterJetCutConsumableCost = (unitOfMeasure: string, totalRawTK: number, cutPerimeter: number, pieceCount: number, loadedQuoteVariableConfigurations: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites
) => {
  const consumableCostWaterJetCutDollarsPerHr = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.ConsumableCostWaterJetCutPerLaborHour, manufacturingSite, FieldTypes.Float) as number;
  const waterJetConstA = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.WaterJetConstantAUsedInJBFormula, manufacturingSite, FieldTypes.Float) as number;
  const waterJetConstB = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.WaterJetConstantBUsedInJBFormula, manufacturingSite, FieldTypes.Float) as number;

  const waterJetThicknessValue = unitOfMeasure === MeasurementSystems.Imperial ? 1 : mmToInDivider;
  const cutMethodHours = (((cutPerimeter * pieceCount) / waterJetThicknessValue) / (waterJetConstA * sixtyMinutes * Math.pow(totalRawTK / waterJetThicknessValue, waterJetConstB)));
  const cutMethodConsumableCost = cutMethodHours * consumableCostWaterJetCutDollarsPerHr;

  return cutMethodConsumableCost;
};

// Calculates the cutting costs for torch cut method
export const calculateTorchCutLaborCosts = (
  isImperialUOM: boolean,
  baseMetal: RawMaterial,
  piecePerimeter: number,
  pieceCount: number,
  costCutMethodLabor: number,
  qvcs: QuoteVariableConfiguration[],
  manufacturingSite: ManufacturingSites
) => {

  const torchCutAlloySteelHoursPerLengthUnitDescription = isImperialUOM ?
    QuoteVariableConfigDescriptions.TorchCutAlloySteelGroupHoursFT :
    QuoteVariableConfigDescriptions.TorchCutAlloySteelGroupHoursM;
  const torchCutCarbonSteelHoursPerLengthUnitDescription = isImperialUOM ?
    QuoteVariableConfigDescriptions.TorchCutCarbonSteelGroupHoursFT :
    QuoteVariableConfigDescriptions.TorchCutCarbonSteelGroupHoursM;

  const torchCutAlloySteelHoursPerLengthUnit = getQuoteVariableConfiguration(qvcs, torchCutAlloySteelHoursPerLengthUnitDescription, manufacturingSite, FieldTypes.Float) as number;
  const torchCutCarbonSteelHoursPerLengthUnit = getQuoteVariableConfiguration(qvcs, torchCutCarbonSteelHoursPerLengthUnitDescription, manufacturingSite, FieldTypes.Float) as number;

  const cutMethodLaborCosts: CutMethodLaborCosts = {
    hours: 0.0,
    cutCost: 0.0,
    consumableCost: 0.0,
    totalCost: 0.0,
  };

  const { classId } = baseMetal;
  let metalClassMultiplier: number;
  // 0.394 m-hr/meter of cut when the base metal is carbon steel (0.0100 mhr/inch)
  // 0.571 m-hr/meter of cut when the base metal is alloy steel (0.0145 mhr/inch)
  if (classId === MetalClasses.CarbonSteels) {
    metalClassMultiplier = isImperialUOM ?
      convertUnits(torchCutCarbonSteelHoursPerLengthUnit, Units.HoursPerFoot, Units.HoursPerInch) :
      convertUnits(torchCutCarbonSteelHoursPerLengthUnit, Units.HoursPerMeter, Units.HoursPerMillimeter);
  } else if (classId === MetalClasses.AlloySteels) {
    metalClassMultiplier = isImperialUOM ?
      convertUnits(torchCutAlloySteelHoursPerLengthUnit, Units.HoursPerFoot, Units.HoursPerInch) :
      convertUnits(torchCutAlloySteelHoursPerLengthUnit, Units.HoursPerMeter, Units.HoursPerMillimeter);
  }
  // FOR: Torch Cut
  // Cut Method Labor Hours = (Total Perimeter of Requested Pieces) * (metalClassMultiplier)
  cutMethodLaborCosts.hours = ((piecePerimeter * pieceCount) * metalClassMultiplier);
  cutMethodLaborCosts.cutCost = cutMethodLaborCosts.hours * costCutMethodLabor;
  //TODO: note there is not a consumable cost for torch cut.
  cutMethodLaborCosts.totalCost = cutMethodLaborCosts.cutCost + cutMethodLaborCosts.consumableCost;
  return cutMethodLaborCosts;
};

// Calculates the cutting costs for saw cut method
export const calculateSawCutLaborCosts = (
  isImperialUOM: boolean,
  totalTK: number,
  width: number,
  piecePerimeter: number,
  pieceCount: number,
  costCutMethodLabor: number,
  qvcs: QuoteVariableConfiguration[],
  manufacturingSite: ManufacturingSites
) => {
  const sawCutGangFactorMaxPartWidthDescription = isImperialUOM ?
    QuoteVariableConfigDescriptions.SawCutGangingFactorWidthMinimumIN :
    QuoteVariableConfigDescriptions.SawCutGangingFactorWidthMinimumMM;

  const sawCutHoursPerAreaDescription = isImperialUOM ?
    QuoteVariableConfigDescriptions.SawCutHoursPerAreaSQIN :
    QuoteVariableConfigDescriptions.SawCutHoursPerAreaSQMM;

  const consumableCostSawCutBladesPerAreaDescription = isImperialUOM ?
    QuoteVariableConfigDescriptions.ConsumableCostSawCutBladesSQIN :
    QuoteVariableConfigDescriptions.ConsumableCostSawCutBladesSQMM;

  const sawCutSetupTimePerPart = getQuoteVariableConfiguration(qvcs, QuoteVariableConfigDescriptions.SawCutSetupPerPart, manufacturingSite, FieldTypes.Float) as number;

  const cutMethodLaborCosts: CutMethodLaborCosts = {
    hours: 0.0,
    cutCost: 0.0,
    consumableCost: 0.0,
    totalCost: 0.0,
  };

  // FOR: Saw Cut
  // A = the cutting set up time for Each Requested Item Part = 0.1 mhr /part
  // B = The cross section area of the cut for any Requested Item part is calculated as follows:  (2 * width + 2 * length) * total thickness (actual cladding and base metal thickness)
  // C = a part Ganging factor, put these numbers in Control
  // D = Labor time per unit area of cut = 0.00000403 m-hr/sq mm (0.0025 mhr/square inch)
  // E = consumeable cost (cost of saw blades)  = $0.00003875/sqmm ($0.025/sq in)

  // Sawing cost is based upon the number of identical parts cut and the cross-section area of the cut.

  // Cut Method Hours = [(A + (D * total area of cut in  mm)/C]
  // Consumable Material Cost ($) = [B * E / C]
  // Cut Method Labor Cost = (Cut Method Hours) * standard labor rate ($/mhr)

  // A: the cutting set uptime for each requested part = 0.1 mhr /part
  const setupTime = pieceCount * sawCutSetupTimePerPart;
  // B: The cross section area of the cut piece - calculated as follows:  (2 * width + 2 * length) * total thickness (actual cladding and base metal thickness)
  const crossSection = piecePerimeter * (totalTK);
  // C: a part ganging factor
  const maxWidthForGangFactor = getQuoteVariableConfiguration(qvcs, sawCutGangFactorMaxPartWidthDescription, manufacturingSite, FieldTypes.Float) as number;
  const pieceRequestedWidth = width; // TODO: using pieces = 1, determine what to do with width of pieces
  // If the number of identical pieces to be cut is more than 1, and the part width is less than maxWidthForGangFactor (1000 mm /39.4 inches), C = 2; otherwise C = 1
  const gangingFactor = ((pieceCount > 1) && pieceRequestedWidth < maxWidthForGangFactor) ? 2 : 1;
  // D: labor time in number of hours per unit area of cut = 0.00000403 m-hr/sq mm (0.0025 mhr/square inch)
  const sawCutHoursPerArea = getQuoteVariableConfiguration(qvcs, sawCutHoursPerAreaDescription, manufacturingSite, FieldTypes.Float) as number;

  cutMethodLaborCosts.hours = (setupTime + sawCutHoursPerArea * crossSection) / gangingFactor;
  // consumeable cost (cost of saw blades)  = $0.00003875/sqmm ($0.025/sq in)
  const consumableCostSawCutBladesPerArea = getQuoteVariableConfiguration(qvcs, consumableCostSawCutBladesPerAreaDescription, manufacturingSite, FieldTypes.Float) as number;
  // E: set the additional consumable cost (cost of saw blades)
  cutMethodLaborCosts.consumableCost = (consumableCostSawCutBladesPerArea * crossSection) / gangingFactor;
  // Cut Method Labor Cost = (Cut Method Hours) * (Cost for Cutting Method Labor)
  cutMethodLaborCosts.cutCost = cutMethodLaborCosts.hours * costCutMethodLabor;
  cutMethodLaborCosts.totalCost = cutMethodLaborCosts.cutCost + cutMethodLaborCosts.consumableCost;

  return cutMethodLaborCosts;
};

export const calculateSawCutConsumableCost = (
  unitOfMeasure: string,
  totalTK: number,
  width: number,
  piecePerimeter: number,
  pieceCount: number,
  loadedQuoteVariableConfigurations: QuoteVariableConfiguration[],
  manufacturingSite: ManufacturingSites
) => {
  const sawCutGangFactorMaxPartWidthDescription = unitOfMeasure === MeasurementSystems.Imperial ?
    QuoteVariableConfigDescriptions.SawCutGangingFactorWidthMinimumIN :
    QuoteVariableConfigDescriptions.SawCutGangingFactorWidthMinimumMM;

  const consumableCostSawCutBladesPerAreaDescription = unitOfMeasure === MeasurementSystems.Imperial ?
    QuoteVariableConfigDescriptions.ConsumableCostSawCutBladesSQIN :
    QuoteVariableConfigDescriptions.ConsumableCostSawCutBladesSQMM;

  const crossSection = piecePerimeter * (totalTK);
  const maxWidthForGangFactor = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, sawCutGangFactorMaxPartWidthDescription, manufacturingSite, FieldTypes.Float) as number;
  const pieceRequestedWidth = width; // TODO: using pieces = 1, determine what to do with width of pieces
  // If the number of identical pieces to be cut is more than 1, and the part width is less than maxWidthForGangFactor (1000 mm /39.4 inches), C = 2; otherwise C = 1
  const gangingFactor = ((pieceCount > 1) && pieceRequestedWidth < maxWidthForGangFactor) ? 2 : 1;
  const consumableCostSawCutBladesPerArea = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, consumableCostSawCutBladesPerAreaDescription, manufacturingSite, FieldTypes.Float) as number;
  const sawCutConsumableCost = (consumableCostSawCutBladesPerArea * crossSection) / gangingFactor;

  return sawCutConsumableCost;
};

// Calculates the finished area in sq ft or m
export const calculateFinishedArea = (UOMType: MeasurementSystems, length: number, width: number, shape: string) => {
  let radius: number;

  if (shape === Shapes.Circle) {
    radius = width/2;
    return (Math.PI * Math.pow(radius, 2)) / (UOMType === MeasurementSystems.Imperial ? sqInToSqFtDivider : sqMmToSqMDivider);
  } else {
    return (width * length) / (UOMType === MeasurementSystems.Imperial ? sqInToSqFtDivider : sqMmToSqMDivider);
  }
};

// Calculates the square finished area in sq ft or m
export const calculateSquareFinishedArea = (UOMType: MeasurementSystems, length: number, width: number, shape: string) => {
  return (width * (shape === Shapes.Circle ? width : length)) / (UOMType === MeasurementSystems.Imperial ? sqInToSqFtDivider : sqMmToSqMDivider);
};

// Calculates the raw area in sq ft or m
export const calculateRawArea = (UOMType: MeasurementSystems, length: number, width: number, metalType: MetalTypesForAreaCalc, isForging?: boolean) => {
  const divider = (UOMType === MeasurementSystems.Imperial ? sqInToSqFtDivider : sqMmToSqMDivider);

  switch(metalType) {
    case MetalTypesForAreaCalc.Base:
      if(isForging) {
        return Math.pow(width, 2) * (Math.PI/4) / divider;
      } else {
        return (width * length) / divider;
      }
    case MetalTypesForAreaCalc.Clad:
    case MetalTypesForAreaCalc.Flyer:
    case MetalTypesForAreaCalc.Anvil:
    default:
      return (width * length) / divider;
  }
};

// Calculates the heat treatment consumable cost
export const calculateHeatTreatmentConsumableCost = (qvcs: QuoteVariableConfiguration[], UOMType: MeasurementSystems, clads: number, cladPoundsPerPiece: number, cladKilogramsPerPiece: number, basePoundsPerPiece: number, baseKilogramsPerPiece: number, manufacturingSite: ManufacturingSites) => {
  const heatTreatmentConsumableCostPerWeightUnitDescription = UOMType === MeasurementSystems.Imperial ?
    QuoteVariableConfigDescriptions.HeatTreatmentComsumableCostLB :
    QuoteVariableConfigDescriptions.HeatTreatmentComsumableCostKG;

  const heatTreatmentConsumableCostPerWeightUnit = getQuoteVariableConfiguration(qvcs, heatTreatmentConsumableCostPerWeightUnitDescription, manufacturingSite, FieldTypes.Float) as number;
  const heatTreatmentConsumableCostTotalWeight = UOMType === MeasurementSystems.Imperial
    ? cladPoundsPerPiece + basePoundsPerPiece
    : cladKilogramsPerPiece + baseKilogramsPerPiece;

  return (heatTreatmentConsumableCostTotalWeight * heatTreatmentConsumableCostPerWeightUnit) * clads;
};

// Calculates labor cost based on the labor type and man hours per clad
export const calculateLaborCost = (clads: number, cladMetal: RawMaterial, manufacturingSite: ManufacturingSites, laborType: LaborTypes, manHoursPerClad: number) => {
  const laborRate = cladMetal?.laborRates[manufacturingSite].find(labor => labor.laborType === laborType).rate;

  return (manHoursPerClad * laborRate) * clads;
};

// Calculates the general labor cost for the assembly group
export const calculateGeneralLaborCost = (UOMType: MeasurementSystems, manufacturingSite: ManufacturingSites, cladMetal: RawMaterial, cladArea: number, itemType: FinishedGood, clads: number) => {
  const laborFactorForArea = itemType.laborFactors.find(laborFactor => {
    const laborFactorsByUOM = laborFactor[UOMType.toLowerCase()];

    return cladArea > laborFactorsByUOM['>minArea']
    && cladArea <= laborFactorsByUOM['<=maxArea'];
  });

  const laborManHoursPerArea = (laborFactorForArea || {})[UOMType.toLowerCase()]?.generalMHPerArea;
  const laborManHoursPerClad = laborManHoursPerArea * cladArea;

  return calculateLaborCost(clads, cladMetal, manufacturingSite, LaborTypes.GeneralLabor, laborManHoursPerClad);
};

// calculateExportPackingCost - returns the calculated export packing cost
export const calculateExportPackingCost = (isMetricUOM: boolean, weight: number, qvcs: QuoteVariableConfiguration[], metal: RawMaterial, manufacturingSite: ManufacturingSites, quantity: number): number => {
  const generalLaborRate = getLaborRate(metal, manufacturingSite);
  //prettier-ignore
  const laborCostDescription = isMetricUOM ? QuoteVariableConfigDescriptions.ExportPackagingLaborCostKG : QuoteVariableConfigDescriptions.ExportPackagingLaborCostLB;
  //prettier-ignore
  const consumableCostDescription = isMetricUOM ? QuoteVariableConfigDescriptions.ExportPackagingConsumableCostKG : QuoteVariableConfigDescriptions.ExportPackagingConsumableCostLB;

  const laborCost = getQuoteVariableConfiguration(qvcs, laborCostDescription, manufacturingSite, FieldTypes.Float) as number;
  const consumableCost = getQuoteVariableConfiguration(qvcs, consumableCostDescription, manufacturingSite, FieldTypes.Float) as number;

  const laborCostPerWeight = laborCost * weight;
  const consumableCostPerWeight = consumableCost * weight;
  let exportPackingCost = laborCostPerWeight * generalLaborRate + consumableCostPerWeight;


  //prettier-ignore
  const priceFloor = getQuoteVariableConfiguration(qvcs, QuoteVariableConfigDescriptions.ExportPackagingMinPartCost, manufacturingSite, FieldTypes.Float) as number;
  //prettier-ignore
  const priceCeiling = getQuoteVariableConfiguration(qvcs, QuoteVariableConfigDescriptions.ExportPackagingMaxPartCost, manufacturingSite, FieldTypes.Float) as number;

  if (exportPackingCost < priceFloor) {
    exportPackingCost = priceFloor;
  }
  if (exportPackingCost > priceCeiling) {
    exportPackingCost = priceCeiling;
  }

  return exportPackingCost * quantity;
};

// calculateCosmeticGrindingCost - calculates the cost of cosmetic grinding cost
const calculateCosmeticGrindingCost = (isMetricUOM: boolean, cladMetal: RawMaterial, cladArea: number, qvcs: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites, clads: number): number => {
  // class ids for Ti and Zi, clad metal only
  //prettier-ignore
  const isMetalClass7or10 =  cladMetal.classId === 7 || cladMetal.classId === 10;

  const cosmeticGrindingDescription = isMetricUOM
    ? isMetalClass7or10
      ? QuoteVariableConfigDescriptions.CosmeticGrindingClass7or10Metric
      : QuoteVariableConfigDescriptions.CosmeticGrindingOtherMetalsMetric
    : isMetalClass7or10
    ? QuoteVariableConfigDescriptions.CosmeticGrindingClass7or10Imperial
    : QuoteVariableConfigDescriptions.CosmeticGrindingOtherMetalsImperial;

  const laborPerUnit = getQuoteVariableConfiguration(qvcs, cosmeticGrindingDescription, manufacturingSite, FieldTypes.Float) as number;
  const generalLaborRate = getLaborRate(cladMetal, manufacturingSite);
  const cosmeticGrindingCost = laborPerUnit * cladArea * generalLaborRate;

  return cosmeticGrindingCost * clads;
};

export const calculateConsumableCostsToRemoveFromTotal = (length: number, width: number, unitOfMeasure: string, loadedQuoteVariableConfigurations: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites, cutMethod: string, totalRawTK: number, shape: Shapes, detailQuantity: number, clads: number) => {
  //varialbles for cutting consumables
  let waterJetCutConsumableCost = 0;
  let sawCutConsumableCost = 0;
  const cutPerimeter = shape === Shapes.Rectangle ? 2 * width + 2 * length : width * Math.PI;

   //get water jet cutting consumable cost - adapted from calculateWaterJetCutLaborCosts
   if(cutMethod === CutMethods.WaterJetCut) {
     waterJetCutConsumableCost = manufacturingSite !== ManufacturingSites.DE01 ? calculateWaterJetCutConsumableCost(unitOfMeasure, totalRawTK, cutPerimeter, detailQuantity, loadedQuoteVariableConfigurations, manufacturingSite) : 0;
   }

   //get saw cut cutting consumable cost - adapted from calculateWaterJetCutLaborCosts
   if(cutMethod === CutMethods.SawCut) {
     sawCutConsumableCost = calculateSawCutConsumableCost(unitOfMeasure, totalRawTK, width, cutPerimeter, detailQuantity, loadedQuoteVariableConfigurations, manufacturingSite);
   }

  const consumablesToRemove = (sawCutConsumableCost + waterJetCutConsumableCost) * clads;

  return consumablesToRemove;
};

// Calculates total consumable cost
export const calculateConsumableCost = (consumableCosts: number[]) => {
  return consumableCosts.reduce((totalConsumableCost, currentConsumableCost) => totalConsumableCost + currentConsumableCost, 0);
};

// calculateMachiningHours - Returns tubesheet machining hours for the given machining selection
export const calculateMachiningHours = (isMetricUOM: boolean, quantity: number, width: number, machining: string, qvcs: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites): number => {
  const pcMachinedTubesheetFace = getQuoteVariableConfiguration(qvcs, QuoteVariableConfigDescriptions.MachiningFaceDiameterManHoursPartMachined, manufacturingSite, FieldTypes.Float) as number;
  const pcMachinedTubesheetOD = getQuoteVariableConfiguration(qvcs, QuoteVariableConfigDescriptions.MachiningOutsideDiameterManHoursPartMachined, manufacturingSite, FieldTypes.Float) as number;
  const machiningTubeSheetsOD = getQuoteVariableConfiguration(qvcs, isMetricUOM ? QuoteVariableConfigDescriptions.MachiningOutsideDiameterManHoursPerSQMM : QuoteVariableConfigDescriptions.MachiningOutsideDiameterManHoursPerSQIN, manufacturingSite, FieldTypes.Float) as number;
  const machiningTubesheetsFace = getQuoteVariableConfiguration(qvcs, isMetricUOM ? QuoteVariableConfigDescriptions.MachiningFaceDiameterManHoursPerSQMM : QuoteVariableConfigDescriptions.MachiningFaceDiameterManHoursPerSQIN, manufacturingSite, FieldTypes.Float) as number;

  const machineODCalc = pcMachinedTubesheetOD + machiningTubeSheetsOD * Math.pow(width, 2);
  const machineODPlusFaceCalc = pcMachinedTubesheetFace + machiningTubesheetsFace * width;

  switch (machining) {
    case MachiningTypes.MachineOD:
      return machineODCalc * quantity;
    case MachiningTypes.ODBaseFace:
    case MachiningTypes.ODCladFace:
      return (machineODCalc + machineODPlusFaceCalc) * quantity;
    case MachiningTypes.AllSurfaces:
      return (machineODCalc + 2 * machineODPlusFaceCalc) * quantity;
    default:
      // Other or None
      return 0;
  }
};

export const calculateTotalCost = (cladCost: number, cladFreight: number, baseCost: number, baseFreight: number, anvil: number, welding: number, headForming: number, headFreight: number, osCost: number, explosiveCost: number, consumableCost: number, cutMethodCost: number, totalLaborCost: number, weldingConsumableCost: number, certificateCost: number, quantity: number, clads: number, updatedConsumableCost: number, freightOut: number) => {

  if(weldingConsumableCost) {
    consumableCost -= (weldingConsumableCost * quantity);
  }

  if (updatedConsumableCost) {
    consumableCost -= updatedConsumableCost;
  }

  const allCosts = [cladCost, cladFreight, baseCost, baseFreight, anvil, welding, headForming, headFreight, osCost, explosiveCost, consumableCost, cutMethodCost, totalLaborCost, certificateCost * clads, freightOut];
  return allCosts.sum();
};

export const calculateOverallCost = (unitOfMeasure: string, loadedQuoteVariableConfigurations: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites, area: number, clads: number ) => {
 const overallCostVariable = unitOfMeasure === MeasurementSystems.Metric ? QuoteVariableConfigDescriptions.ConsumableCostOverallCostSQM : QuoteVariableConfigDescriptions.ConsumableCostOverallCostSQFT;
 const overallConsumable = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, overallCostVariable, manufacturingSite, FieldTypes.Float) as number;

 return (area * clads) * overallConsumable;
};

export const calculateExportPackagingConsumable = (hasExportPackaging: boolean, unitOfMeasure: string, loadedQuoteVariableConfigurations: QuoteVariableConfiguration[], manufacturingSite: ManufacturingSites, weight: number) => {
  if (hasExportPackaging) {
    const exportPackagingVariableUOM = unitOfMeasure === MeasurementSystems.Metric ? QuoteVariableConfigDescriptions.ExportPackagingConsumableCostKG : QuoteVariableConfigDescriptions.ExportPackagingConsumableCostLB;
    const exportPackagingConsumable = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, exportPackagingVariableUOM, manufacturingSite, FieldTypes.Float) as number;

    return exportPackagingConsumable * weight;
  } else {
    return 0;
  }
};

export const calculateUnitCost = (plateMethod: PlateMethods | Shapes, finishedArea: number, totalCost: number, quantity: number, totalFinishedGoodAreaOnClad: number) => {
  let totalCostOfLine;

  if(plateMethod !== PlateMethods.ManuallySized) {
    totalCostOfLine = totalCost;
  } else {
    const finishedAreaOfLine = finishedArea * quantity;
    const percentLineAreaOnClad = finishedAreaOfLine / totalFinishedGoodAreaOnClad;
    totalCostOfLine = totalCost * percentLineAreaOnClad;
  }

  return totalCostOfLine/quantity;
};

export const calculateAdjPrice = (unitCost: number, contPercentage: number, agentComRate: number) => {
  const agentComNumber = agentComRate * .01;
  const contPercentNumber = contPercentage * .01;
  const total = unitCost/(1-(Math.min(contPercentNumber + agentComNumber, 0.99)));

  return total;
};

export const calculateContPercent = (unitCost: number, agentComRate: number, adjPrice: number) => {
  const agentComAmount = (adjPrice) * (agentComRate * .01);
  const contPercentage = 1-((unitCost + agentComAmount)/adjPrice);

  return (contPercentage * 100);
};

export const calculateContSqm = (contPercentage: number, adjPrice: number, areaSQM: number) => {

  return ((contPercentage * .01) * adjPrice) / areaSQM;
};
// Gets the proper metal purchasing requirements based on the group, metal and manufacturing site
export const getMetalPurchasingRequirements = (metal: RawMaterial, group: MetalPurchaseRequirementsGroups, manufacturingSite: ManufacturingSites) => {
  return metal.metalPurchaseRequirements[manufacturingSite].filter(requirement => requirement.group === group).map(requirement => requirement.content).join('\n');
};

// Gets the proper metal purchasing requirement based on the group, metal and manufacturing site
export const getMetalPurchasingRequirement = (metal: RawMaterial, group: MetalPurchaseRequirementsGroups, manufacturingSite: ManufacturingSites) => {
  return metal.metalPurchaseRequirements[manufacturingSite].find(requirement => requirement.group === group);
};

// Gets the formatted dimensions of the finished product
export const getDimensions = (inputUOMType: MeasurementSystems, outputUOMType: MeasurementSystems, finishedWidth: number, finishedLength: number, itemType: string, headDiameter: number, headIdOd: InnerOrOuterDimensions, headFormingType: HeadFormingTypes, cylinder: CylinderItemDetail, shape?: string) => {
  const isMetricUOM = outputUOMType === MeasurementSystems.Metric;
  const convertedFinishedLength = calculateUOMValue(inputUOMType, outputUOMType, finishedLength, Units.Millimeters, Units.Inches);
  const convertedFinishedWidth = calculateUOMValue(inputUOMType, outputUOMType, finishedWidth, Units.Millimeters, Units.Inches);
  let dimensions = shape === 'Circle' ? stringToFloat(convertedFinishedWidth?.toString(), isMetricUOM ? 0 : 3) : stringToFloat(convertedFinishedWidth?.toString(), isMetricUOM ? 0 : 3) + ' X ' + stringToFloat(convertedFinishedLength?.toString(), isMetricUOM ? 0 : 3);

  switch(itemType) {
  case FinishedProductTypes.Cylinder:
    // eslint-disable-next-line no-case-declarations
    const cylinderDiameter = calculateUOMValue(inputUOMType, outputUOMType, cylinder.Diameter__c, Units.Millimeters, Units.Inches);
    // eslint-disable-next-line no-case-declarations
    const cylinderLength = calculateUOMValue(inputUOMType, outputUOMType, cylinder.CylinderLength__c, Units.Millimeters, Units.Inches);
    dimensions = `${stringToFloat(cylinderDiameter.toString(), isMetricUOM ? 0 : 3)} ${cylinder.IDorOD__c} X ${stringToFloat(cylinderLength.toString(), isMetricUOM ? 0 : 3)}`;
    break;
  case FinishedProductTypes.Plate:
  case FinishedProductTypes.Tubesheet:
    if(shape === 'Circle') {
      dimensions += ` ${DiameterUOMTypes.OD}`;
    }
    break;
  case FinishedProductTypes.Head:
    // eslint-disable-next-line no-case-declarations
    const headDiameterUOM = calculateUOMValue(inputUOMType, outputUOMType, headDiameter, Units.Millimeters, Units.Inches) || 0;
    dimensions = `${stringToFloat(headDiameterUOM.toString(), isMetricUOM ? 0 : 3)} ${headIdOd} - ${headFormingType} - Head`;
  }

  return dimensions;
};

export const getIdOdAbbreviation = (idOd: InnerOrOuterDimensions) => {
  switch(idOd) {
  case InnerOrOuterDimensions.Inner:
    return DiameterUOMTypes.ID;
  case InnerOrOuterDimensions.Outer:
    return DiameterUOMTypes.OD;
  default:
    return '';
  }
};

export const getCylinderTypes = (cylinderType) => {
  switch(cylinderType) {
  case 'Rolled & Tacked':
    return CylinderTypes.RolledTacked;
  case 'Rolled & Welded':
    return CylinderTypes.RolledWelded;
  default:
    return '';
  }
};


// Gets the formatted item description
export const getItemDescription = (dimensions: string, itemType: string, cylinderType?: string) => {
  let itemDescription = dimensions;

  switch(itemType) {
    case FinishedProductTypes.Cylinder:
      itemDescription += ` - ${getCylinderTypes(cylinderType)} ${itemType}`;
      break;
    case FinishedProductTypes.Plate:
    case FinishedProductTypes.Tubesheet:
      itemDescription += ` - ${itemType}`;
      break;
  }

  return itemDescription;
};

// Calculates the flattening labor
export const calculateFlatteningLabor = (itemType: FinishedGood, unitsOfMeasure: MeasurementSystems, squareFinishedArea: number, totalRawThickness: number) => {

  const flatteningLaborGroupsByUOM: FlatteningLabor[] = itemType.flatteningLabor.map(flatteningLaborGroup => flatteningLaborGroup[unitsOfMeasure.toLowerCase()]);

  const flatteningLaborGroupsByThickness = flatteningLaborGroupsByUOM.filter(flatteningLaborGroup => {
    return squareFinishedArea > flatteningLaborGroup['>minFinishedArea']
    && squareFinishedArea <= flatteningLaborGroup['<=maxFinishedArea']
    && totalRawThickness >= flatteningLaborGroup['>=minTotalRawTK'];
  });

  const flatteningLaborGroup = flatteningLaborGroupsByThickness.find(flatteningLaborGroup => {
    return flatteningLaborGroup['>=minTotalRawTK'] === Math.max(...flatteningLaborGroupsByThickness.map(flatteningLaborGroupsByThickness => flatteningLaborGroupsByThickness['>=minTotalRawTK']));
  });

  return flatteningLaborGroup?.manHoursPerSQUnit * squareFinishedArea;
};

//Unified Calculator for All Cost Events totals
export const calculateTotalCostOfEvent = (unitValue: number, osType: string, loadedOutsideServiceCost: OutsideServiceCosts[], additionalOptions?: { weldType?: string, clads?: number }) => {
  if(osType === OutsideServices.PostbondHeatTreatment) {
    const costGroup = loadedOutsideServiceCost.find(costs => costs.costName === osType && unitValue >= costs['>=minKGWeight'] && unitValue <= costs['<=maxKGWeight']);
    return (unitValue / 1000) * (costGroup.costPerKG ?? 0);
  } else if(osType === OutsideServices.XRayServices) {
    const costGroup = loadedOutsideServiceCost.find(costs => costs.costName === osType && unitValue >= costs['>=minMMLength'] && unitValue <= costs['<=maxMMLength']);
    const { weldType = RadiographyTypes.Spot, clads } = additionalOptions;
    const rate = costGroup[weldType];
    return ((unitValue / 1000) * rate) * clads;
  } else if (osType === OutsideServices.TestingServicesCladProduct) {
    return 1000;
  }
};

// Calculates cost per weight unit
export const calculateCostPerWeightUnit = (metalWeight: number, quantity: number, totalCost: number) => {
  return totalCost / (metalWeight * quantity);
};

// Calculates total shipping cost
export const calculateTotalShippingCost = (metalWeight: number, quantity: number, costPerUnit: number) => {
  return costPerUnit * metalWeight * quantity;
};

// Calculates the number of finished goods that can fit into the width of the raw clad
export const calculateNumberOfItemsWideThatFit = (maximumWidth: number, finishedWidth: number, kerfAllowance: number, edgeAllowance: number, boosterAllowance: number, cladOverhang: number, cladOverhangThin: number, anvilEdgeAllowanceAddition: number) => {
  let extraQuantity = 0;
  let numberOfItemsThatFit;

  let quantityToTest = 1;
  while(extraQuantity === 0) {
    numberOfItemsThatFit = Math.min(quantityToTest, Math.floor(+((maximumWidth - (cladOverhangThin !== 0 ? cladOverhangThin : cladOverhang) - kerfAllowance * (quantityToTest - 1) - 2 * edgeAllowance - (2 * anvilEdgeAllowanceAddition) - boosterAllowance) / finishedWidth)) || 1);
    extraQuantity = quantityToTest - numberOfItemsThatFit;
    quantityToTest++;
  }

  return numberOfItemsThatFit;
};

// Calculates the number of finished goods that can fit into the length of the raw clad
export const calculateNumberOfItemsLongThatFit = (maximumLength: number, finishedLength: number, kerfAllowance: number, edgeAllowance: number, specificationAdder: number, baseMetalLengthAddition: number, cladOverhang: number, cladOverhangThin: number, anvilEdgeAllowanceAddition: number) => {
  let extraQuantity = 0;
  let numberOfItemsThatFit;

  let quantityToTest = 1;
  while(extraQuantity === 0) {
    numberOfItemsThatFit = Math.min(quantityToTest, Math.floor(+((maximumLength - (cladOverhangThin !== 0 ? cladOverhangThin : cladOverhang) - kerfAllowance * (quantityToTest - 1) - 2 * edgeAllowance - (2 * anvilEdgeAllowanceAddition) - specificationAdder - baseMetalLengthAddition) / finishedLength)) || 1);
    extraQuantity = quantityToTest - numberOfItemsThatFit;
    quantityToTest++;
  }

  return numberOfItemsThatFit;
};

// Calculates the maximum quantity of finished goods that can fit on the raw clad
export const calculateMaxQuantityOnClad = (numberOfItemsWideThatFit: number, numberOfItemsLongThatFit: number) => {
  return numberOfItemsWideThatFit * numberOfItemsLongThatFit;
};

// Determines if an extra clad is required
export const determineExtraCladRequired = (long: number, numberOfItemsLongThatFit: number, detailQuantity: number, clads: number, quantity: number) => {
  return long > numberOfItemsLongThatFit || ((detailQuantity * clads) < quantity);
};

// Checks if every line item has or does not have the specified post clad testing option
export const postCladTestingOptionIsGlobal = (quoteLineItems: QuoteLineItem[], testingOption: string, excluded = false) => {
  const postCladTestingOptions = quoteLineItems.map(quoteLineItem => quoteLineItem.Post_Clad_Testing_Options__c);

  return postCladTestingOptions.every(postCladTestingOptionsList =>
    (excluded ? !postCladTestingOptionsList?.split(';').includes(testingOption) : postCladTestingOptionsList?.split(';').includes(testingOption)));
};

// Checks if a line item has the specified post clad testing option
export const postCladTestingOptionIncluded = (quoteLineItem: QuoteLineItem, testingOption: string) => {
  return quoteLineItem?.Post_Clad_Testing_Options__c?.split(';').includes(testingOption);
};

// Checks if a field is the same on all line items
export const lineFieldIsGlobal = (quoteLineItems: QuoteLineItem[], fieldName: string) => {
  const fieldOnLine = quoteLineItems.map(quoteLineItem => quoteLineItem[fieldName]);
  return new Set(fieldOnLine).size === 1;
};

// Checks if a field is the same on all line item details
export const detailFieldIsGlobal = (quoteLineItemDetails: QuoteLineItemDetail[], fieldName: string) => {
  const fieldOnDetails = quoteLineItemDetails.map(quoteLineItemDetail => quoteLineItemDetail[fieldName]);
  return new Set(fieldOnDetails).size === 1;
};

// Returns the correct field value depending on whether it was updated or not
const chooseFieldValue = (updatedFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>, field: QuoteLineItemDependentUnwrittenFields, currentFieldValue: DependentUnwrittenFieldsMapValueType) => {
  return updatedFieldsMap.has(field) ? updatedFieldsMap.get(field) : currentFieldValue;
};

// Returns true if the field was manually updated
const fieldManuallyUpdated = (fieldsUpdated: QuoteLineItemValueTypes[], fieldValueType: QuoteLineItemValueTypes) => {
  return fieldsUpdated.indexOf(fieldValueType) === 0;
};

// Finds the quote line item from the id or displayId
export const getQuoteLineItemFromId = (quoteLineItems: QuoteLineItem[], id: string) => {
  return quoteLineItems.find(quoteLineItem => (quoteLineItem.Id || quoteLineItem.displayId) === id);
};

// Finds the first quote line item detail associated with the quote line item
export const getQuoteLineItemDetailFromQuoteLineItem = (quoteLineItemDetails: QuoteLineItemDetail[], quoteLineItem: QuoteLineItem) => {
  return quoteLineItem ? quoteLineItemDetails?.find(quoteLineItemDetail => quoteLineItem.Id ? quoteLineItemDetail.NC_Quote_Line_Item__c === quoteLineItem.Id : quoteLineItemDetail.associatedLineItem === quoteLineItem.displayId) : undefined;
};

// Checks if the quote line item exists in the provided array by id or display id
export const quoteLineItemExistsInArray = (quoteLineItems: QuoteLineItem[], quoteLineItemToFind: QuoteLineItem) => {
  return quoteLineItems.some(quoteLineItem => quoteLineItem.Id ? quoteLineItem.Id === quoteLineItemToFind.Id : quoteLineItem.displayId === quoteLineItemToFind.displayId);
};

// Checks if the quote line item detail exists in the provided array by id or associated line item (will fix later to accommodate multiple details)
export const quoteLineItemDetailExistsInArray = (quoteLineItemDetails: QuoteLineItemDetail[], quoteLineItemDetailToFind: QuoteLineItemDetail) => {
  return quoteLineItemDetails.some(quoteLineItemDetail => quoteLineItemDetail.Id ? quoteLineItemDetail.Id === quoteLineItemDetailToFind.Id : quoteLineItemDetail.associatedLineItem === quoteLineItemDetailToFind.associatedLineItem);
};

// Adds the field to the updated field map and fields updated list if the value changed
export const addFieldToMapAndUpdatedList = (updatedFieldValue: FieldsMapValueType, currentFieldValue: FieldsMapValueType, field: QuoteLineItemFields, fieldValueType: QuoteLineItemValueTypes, fieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>, fieldsUpdatedList: QuoteLineItemValueTypes[]) => {
  if(updatedFieldValue !== currentFieldValue) {
    fieldsMap.set(field, updatedFieldValue);

    if(!fieldsUpdatedList.includes(fieldValueType)) {
      fieldsUpdatedList.push(fieldValueType);
    }
  }
};

// Determines if the post clad testing option was selected based on assembly or metal combo
export const postCladTestingOptionSelected = (specifiedPerAssembly: boolean, option: PostCladTestingOptions, assemblyMapFieldValue: string, metalComboFieldValue: string) => {
  if(specifiedPerAssembly) {
    if(assemblyMapFieldValue?.split(';').includes(option)) {
      return true;
    }
  } else if(metalComboFieldValue?.split(';').includes(option)) {
    return true;
  }

  return false;
};

// Adds the field to the updated field map and fields updated list if the value changed depending on if the row was specified by assembly
export const addFieldToMapAndUpdatedListPerAssembly = (
  specifiedPerAssembly: boolean,
  assemblyMapFieldValue: FieldsMapValueType,
  metalComboFieldValue: FieldsMapValueType,
  currentFieldValue: FieldsMapValueType,
  field: QuoteLineItemFields,
  fieldValueType: QuoteLineItemValueTypes,
  fieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>,
  fieldsUpdatedList: QuoteLineItemValueTypes[]
) => {
  if (specifiedPerAssembly) {
    if (assemblyMapFieldValue !== currentFieldValue) {
      fieldsMap.set(field, assemblyMapFieldValue);
      fieldsUpdatedList.push(fieldValueType);
    }
  } else if (metalComboFieldValue !== currentFieldValue) {
    fieldsMap.set(field, metalComboFieldValue);
    fieldsUpdatedList.push(fieldValueType);
  }
};

// Returns the selection options based on manufacturing site and value type
export const getSelectionOptions = (selections: Selection[], manufacturingSite: ManufacturingSites, valueType: QuoteLineItemValueTypes) => {
  return { options: selections?.find(selection => selection.dataAreaId === manufacturingSite && selection.valueType === valueType)?.options.map(option => option.name) };
};

// Returns the selection options based on manufacturing site and value type by options of name and value
export const getSelectionOptionsWithNameAndValue = (
  selections: Selection[],
  manufacturingSite: ManufacturingSites,
  valueType: QuoteLineItemValueTypes
) => {
  return {
    options: selections
      ?.find((selection) => selection.dataAreaId === manufacturingSite && selection.valueType === valueType)
      ?.options.map((option) => {
        return { name: option.name, value: option.value };
      }),
  };
};

// Returns the names and descriptions of selection options based on manufacturing site and value type
export const getSelectionOptionsWithDescriptions = (selections: Selection[], manufacturingSite: ManufacturingSites, valueType: QuoteLineItemValueTypes): SelectionOption[] =>
  selections.find((s) => s.dataAreaId === manufacturingSite && s.valueType === valueType)?.options;

// Returns the descriptions of selection options based on manufacturing site and value type
export const getSelectionOptionsDescriptions = (selections: Selection[], manufacturingSite: ManufacturingSites, valueType: QuoteLineItemValueTypes): SelectionOption['description'][] =>
  getSelectionOptionsWithDescriptions(selections, manufacturingSite, valueType).map(selectionOption => selectionOption.description);

// Returns the names of selection options based on manufacturing site and value type
export const getSelectionOptionsNames = (selections: Selection[], manufacturingSite: ManufacturingSites, valueType: QuoteLineItemValueTypes): SelectionOption['name'][] =>
  getSelectionOptionsWithDescriptions(selections, manufacturingSite, valueType).map(selectionOption => selectionOption.name);

// Returns the dafualt purchase requirement value for a metal based on manufacturing site and metalPurchaseRequirementGroup
export const getDefaultMetalPurchaseRequirement = (metal: RawMaterial, manufacturingSite: ManufacturingSites, metalPurchaseRequirementGroup: string) =>
  metal?.metalPurchaseRequirements[manufacturingSite].find(metalPurchaseRequirement => metalPurchaseRequirement?.group === metalPurchaseRequirementGroup)?.content;

// returns the default cutting notes based on uom and cut method
export const getDefaultCuttingToleranceNotes = (
  itemType: FinishedGood,
  cutMethod: string,
  thickness: number,
  length: number,
  uom: MeasurementSystems,
  valuePhrase: string
): string => {
  if (itemType.cuttingTolerances && cutMethod && cutMethod !== CutMethods.Uncut) {
    const imperialOrMetric = uom.toLowerCase();

    // saw cut is based on FG length, all other cut methods are based on FG thickness
    const basedOnValue = cutMethod === CutMethods.SawCut ? 'Length' : 'TK';
    const comparativeDimension = cutMethod === CutMethods.SawCut ? length : thickness;

    if(itemType.name !== FinishedProductTypes.Cylinder) {
      const toleranceValues = itemType.cuttingTolerances.find(
        (ct) =>
          ct.cuttingType === cutMethod &&
          ct[imperialOrMetric][`Min${basedOnValue}`] <= comparativeDimension &&
          ct[imperialOrMetric][`Max${basedOnValue}`] > comparativeDimension
      )[imperialOrMetric];

      return toleranceValues.CuttingTolerance !== 0
        ? valuePhrase.replace(Regexes.Value, `${toleranceValues.CuttingTolerance}`)
        : '+0';
    }
    // values are based on the cut method, <= to the min value, > to the max value

  }
  return '';
};

// returns the default machining notes based on uom
export const getDefaultMachiningNotes = (
  itemType: FinishedGood,
  uom: MeasurementSystems,
  length: number,
  valuePhrase: string
  ) => {
  if (itemType.machiningTolerances && itemType.name !== FinishedProductTypes.Cylinder) {
    const imperialOrMetric = uom.toLowerCase();
    let machiningTolerance = itemType.machiningTolerances[0];

    if(itemType.machiningTolerances.length > 1) {
      machiningTolerance = itemType.machiningTolerances.find((tolerance) => {
        return tolerance[imperialOrMetric].MinLength <= length && tolerance[imperialOrMetric].MaxLength >= length;
      });
    }

    const { MachiningValueFrom: machiningValueFrom, MachiningValueTo: machiningValueTo } = machiningTolerance[imperialOrMetric];

    return valuePhrase.replace(Regexes.Value, `${machiningValueFrom}`).replace(Regexes.Value2, `${machiningValueTo}`);
  }
};

// returns the default flatness tolerance notes based on uom
export const getDefaultFlatteningNotes = (
  itemType: FinishedGood,
  uom: MeasurementSystems,
  thickness: number,
  valuePhrase: string
  ): string => {
    switch (itemType?.name) {
      case FinishedProductTypes.Head:
      case FinishedProductTypes.Cylinder:
      case FinishedProductTypes.Plate:
        if (itemType.flatnessTolerances && itemType.flatnessTolerances.length) {
          const imperialOrMetric = uom.toLowerCase();
          const toleranceValues = itemType.flatnessTolerances.find(
            (ft) => ft[imperialOrMetric].MinThickness <= thickness && ft[imperialOrMetric].MaxThickness > thickness
          )[imperialOrMetric];

          return valuePhrase
            .replace(Regexes.Value, `${toleranceValues.FlatnessFrom}`)
            .replace(Regexes.Value2, `${toleranceValues.FlatnessTo}`);
        }
        break;
      case FinishedProductTypes.Tubesheet:
        return valuePhrase
            .replace(Regexes.Value, `${thickness}`)
            .replace(Regexes.Value2, `${thickness}`);
    }
};

// return the default flattening selection based on finished good
export const getDefaultFlatteningSelection = (itemType: FinishedGood): string => {
  switch (itemType?.name) {
    case FinishedProductTypes.Tubesheet:
      return DefaultFlatteningSelections.Tubesheet;
    case FinishedProductTypes.Plate:
    case FinishedProductTypes.Head:
    case FinishedProductTypes.Cylinder:
      return DefaultFlatteningSelections.Plate;
    default:
      break;
  }
};

// Returns the selection based on manufacturing site and value type
export const getSelections = (selections: Selection[], manufacturingSite: ManufacturingSites, valueType: QuoteLineItemValueTypes) => {
  return selections.find(selection => selection.dataAreaId === manufacturingSite && selection.valueType === valueType);
};

// Returns the vendor options based on manufacturing site and service
export const getVendors = (vendors: Vendor[], manufacturingSite: ManufacturingSites, service: OutsideServices | VendorServices) => {
  return vendors.filter(vendor => vendor.dataAreaId === manufacturingSite && vendor.servicesPerformed.includes(service));
};

// Returns the default vendor id for the manufacturing site and service
export const getDefaultVendorId = (vendors: Vendor[], manufacturingSite: ManufacturingSites, service: OutsideServices) => {
  return vendors?.find(vendor => vendor.dataAreaId === manufacturingSite && vendor.defaultForServices.includes(service))?.vendorId;
};

// Returns the proposalTypeOptions for a given metal and manufacturing site
export const getProposalTypeOptions = (metal: RawMaterial, manufacturingSite: ManufacturingSites) => {
  return [...new Set(metal.metalPricing[manufacturingSite]?.map((metalPricing) => metalPricing.proposalType))];
};

// Formats the length measurement value based on measurement system
export const formatLengthWidthMeasurement = (isMetricUOM: boolean, metricValue: string | number, imperialValue: string | number, hideUnits?: boolean) => {
  return isMetricUOM ?
    (metricValue ? stringToFloat(metricValue as string, 0) : '0') + (hideUnits ? '' : 'mm') :
    (imperialValue ? stringToFloat(imperialValue as string, 3) : '0.000') + (hideUnits ? '' : '"');
};

// Formats the thickness measurement value based on measurement system
export const formatThicknessMeasurement = (isMetricUOM: boolean, metricValue: string | number, imperialValue: string | number, hideUnits?: boolean) => {
  return isMetricUOM ?
    (metricValue ? stringToFloat(metricValue as string, 2) : '0.00') + (hideUnits ? '' : 'mm') :
    (imperialValue ? stringToFloat(imperialValue as string, 3) : '0.000') + (hideUnits ? '' : '"');
};

// Formats the weight value based on measurement system
export const formatWeight = (uom: MeasurementSystems, metricValue: string | number, imperialValue: string | number) => {
  return uom === MeasurementSystems.Metric ?
    (metricValue ? (metricValue as number).toFixed(0) : '0') + ' KG' :
    (imperialValue ? (imperialValue as number).toFixed(0) : '0') + ' LB';
};

// Formats the price per weight value based on measurement system
export const formatPricePerWeight = (uom: MeasurementSystems, currencyCode: Currency, metricValue: number, imperialValue: number, hideUnits?: boolean) => {
  return uom === MeasurementSystems.Metric ?
    formatCurrency(metricValue, currencyCode, 3) + (hideUnits ? '' : '/KG') :
    formatCurrency(imperialValue, currencyCode, 3) + (hideUnits ? '' : '/LB');
};

// Formats the percentage value
export const formatPercentage = (value: string | number) => {
  return (value ? stringToFloat(value as string, 2) : '0.00') + '%';
};

// Gets the proper quote variable based on desription, field type and manufacturing site
export const getQuoteVariableConfiguration = (qvcs: QuoteVariableConfiguration[], description: QuoteVariableConfigDescriptions, manufacturingSite: ManufacturingSites, fieldType: FieldTypes): string | number => {
  const quoteVariable = qvcs.find((qvc) => qvc.description === description && qvc.dataAreaId === manufacturingSite)?.value;

  return (fieldType === FieldTypes.Float || fieldType === FieldTypes.Integer) ? +quoteVariable : quoteVariable;
};

// Gets the proper quote variable uom based on desription, field type and manufacturing site
export const getQuoteVariableConfigurationUOM = (qvcs: QuoteVariableConfiguration[], description: QuoteVariableConfigDescriptions, manufacturingSite: ManufacturingSites): string => {
  return qvcs.find((qvc) => qvc.description === description && qvc.dataAreaId === manufacturingSite).uom;
};

// Gets the proper booster allowance group
export const getBoosterAllowanceGroup = (boosterAllowances: BoosterAllowances[], baseMetal: RawMaterial, cladMetal: RawMaterial, manufacturingSite: ManufacturingSites) => {
  const boosterAllowanceGroupsByManufacturingSite = boosterAllowances.filter(boosterAllowanceGroup => boosterAllowanceGroup.dataAreaId === manufacturingSite);
  const boosterAllowanceGroupByMetalClasses = boosterAllowanceGroupsByManufacturingSite.find(boosterAllowanceGroup => boosterAllowanceGroup.baseMetalClass === baseMetal.dmcClass && boosterAllowanceGroup.cladMetalClass === cladMetal.dmcClass);

  if(!boosterAllowanceGroupByMetalClasses) {
    return boosterAllowanceGroupsByManufacturingSite.find(boosterAllowanceGroup => boosterAllowanceGroup.group === BoosterAllowanceGroups.Group1)?.boosterAllowances;
  }

  return boosterAllowanceGroupByMetalClasses?.boosterAllowances;
};

// Gets the proper heat treat combination group
export const getHeatTreatCombinationGroup = (rawMaterialCombinations: RawMaterialCombination[], baseMetal: RawMaterial, cladMetal: RawMaterial, manufacturingSite: ManufacturingSites) => {
  return (rawMaterialCombinations || []).find(rawMaterialCombination => rawMaterialCombination.base === baseMetal?.name && rawMaterialCombination.clad === cladMetal?.name && rawMaterialCombination.dataAreaId === manufacturingSite)?.heatTreatCombinations;
};

// Gets the proper stress relief detail string
export const getStressReliefDetails = (uom: MeasurementSystems, minTime: number, maxTime: number, temperature: number) => {
  const tempUOM = uom === MeasurementSystems.Imperial ? 'F' : 'C';
  return `${minTime} Minimum Minutes, ${maxTime} Maximum Minutes at ${temperature} Temp (°${tempUOM})`;
};

export const getHeatTreatmentRequired = (additionalOptions: string[], rawMaterialCombinations: RawMaterialCombination[], base: RawMaterial, clad: RawMaterial, manufacturingSite: ManufacturingSites, itemType: FinishedGood, uom: MeasurementSystems, qvcs: QuoteVariableConfiguration[], totalRawTK: number, finishedWidth: number, baseTK: number) => {
  const heatTreatmentSelected = additionalOptions.includes(QuoteLineItemAdditionalOptions.HeatTreatment);

  if (clad?.neverHeatTreatMetal === true || base?.neverHeatTreatMetal === true) {
    return false;
  } else if (heatTreatmentSelected) {
    return true;
  } else {
    let heatTreatmentAllowed = true;

    const heatTreatCombinationGroup = getHeatTreatCombinationGroup(rawMaterialCombinations, base, clad, manufacturingSite);
    if (!heatTreatCombinationGroup || heatTreatCombinationGroup?.imperial.temperature === 0) {
      heatTreatmentAllowed = false;
    }

    if(heatTreatmentAllowed) {
      const heatTreatmentBaseThicknessMaxDescription = uom === MeasurementSystems.Imperial
      ? QuoteVariableConfigDescriptions.HeatTreatmentRequiredBaseThicknessMaximumIN
      : QuoteVariableConfigDescriptions.HeatTreatmentRequiredBaseThicknessMaximumMM;

      const heatTreatmentBaseThicknessMax = getQuoteVariableConfiguration(qvcs, heatTreatmentBaseThicknessMaxDescription, manufacturingSite, FieldTypes.Float) as number;

      if(manufacturingSite === ManufacturingSites.DE01) {
        if (clad?.type === HeatTreatCheckCladTypes.Titanium ||
          clad?.type === HeatTreatCheckCladTypes.Zirconium) {
          return true;
        }
      }

      if(manufacturingSite === ManufacturingSites.US01) {
        if (baseTK <= heatTreatmentBaseThicknessMax ||
          base?.classId === MetalClasses.AlloySteels ||
          clad?.type === HeatTreatCheckCladTypes.FerriticStainless ||
          clad?.type === HeatTreatCheckCladTypes.Titanium ||
          clad?.type === HeatTreatCheckCladTypes.Zirconium) {
          return true;
        }

        if(itemType?.name === FinishedProductTypes.Tubesheet) {
          const configDescriptionTubesheetThickness = uom === MeasurementSystems.Imperial
          ? QuoteVariableConfigDescriptions.HeatTreatmentRequiredTubesheetThicknessMaximumIN
          : QuoteVariableConfigDescriptions.HeatTreatmentRequiredTubesheetThicknessMaximumMM;

          const configDescriptionTubesheetDiameter = uom === MeasurementSystems.Imperial
          ? QuoteVariableConfigDescriptions.HeatTreatmentRequiredTubesheetDiameterMinimumIN
          : QuoteVariableConfigDescriptions.HeatTreatmentRequiredTubesheetDiameterMinimumMM;

          const requiredTubesheetThicknessMaximum = getQuoteVariableConfiguration(qvcs, configDescriptionTubesheetThickness, manufacturingSite, FieldTypes.Float) as number;
          const requiredTubesheetDiameterMinimum = getQuoteVariableConfiguration(qvcs, configDescriptionTubesheetDiameter, manufacturingSite, FieldTypes.Float) as number;

          if((clad.classId === MetalClasses.CarbonSteels ||
            clad.classId === MetalClasses.AlloySteels ||
            clad.classId === MetalClasses.AusteniticStainlessSteels ||
            clad.classId === MetalClasses.FerriticDuplexStainlessSteels ||
            clad.classId === MetalClasses.CopperCopperAlloys ||
            clad.classId === MetalClasses.NickelNickelAlloysHigherMolyAusteniticStainlessSteels) &&
            totalRawTK < requiredTubesheetThicknessMaximum &&
            finishedWidth > requiredTubesheetDiameterMinimum) {
              return true;
            }
        } else {
          const heatTreatmentBaseThicknessExceptionDescription = uom === MeasurementSystems.Imperial
          ? QuoteVariableConfigDescriptions.HeatTreatmentBaseThicknessExceptionIN
          : QuoteVariableConfigDescriptions.HeatTreatmentBaseThicknessExceptionMM;

          const heatTreatmentBaseThicknessException = getQuoteVariableConfiguration(qvcs, heatTreatmentBaseThicknessExceptionDescription, manufacturingSite, FieldTypes.Float) as number;

          if (base?.classId === MetalClasses.CarbonSteels && totalRawTK > heatTreatmentBaseThicknessException) {
            return true;
          }
        }
      }
    }
    return false;
  }
};

export const getPONotesForSalesforce = (poNotesVariables: PONotesVariables) => {
  const {
    headQuantity,
    headFormingCostPerHead,
    headContour,
    quoteName,
    cladMetal,
    baseMetal,
    cladNom,
    cladMin,
    baseNom,
    baseMin,
    baseMetalFinishedGoodHeatTreatment,
    postBondUTSpecification,
    shipWeight,
    blankOD,
    headDescription,
    numPiecesDescription,
    straightFlange,
    openEnd,
    postCladBaseMetalUTSpecification,
    cladProductionSpecification,
    nobelCladProcedure,
    heatTreatCycles,
    maxCenterHole,
    cladMetalPreference,
    accountName,
    po,
    tag
  } = poNotesVariables;

  let heatTreatCyclesString = '';
  heatTreatCycles?.forEach((heatTreatCycle, index) => {
    heatTreatCyclesString+= `${heatTreatCycle}`;
    if(index !== heatTreatCycles.length - 1) {
      heatTreatCyclesString+= '<br/>';
    }
  });

  const notes = `TOTAL QUANTITY: ${headQuantity}<br/>FORMING PRICE: ${headFormingCostPerHead}
    <br/>Forming per Document 23003, current revision.<br/>To be in compliance ${headContour}.
    <br/>Ref Quote: ${quoteName}
    <br/>Forming per -FP${nobelCladProcedure}<br/>${heatTreatCyclesString}
    <br/>Clad Metal: ${cladMetal}; ${cladNom} Nom; ${cladMin} Min<br/>Base Metal: ${baseMetal}; ${baseNom} Nom; ${baseMin} Min<br/>Heat Treatment: ${baseMetalFinishedGoodHeatTreatment}<br/>Part Weight: ${shipWeight}
    <br/>Blank OD: ${blankOD}<br/>NobelClad Part Serial Number (stamped and marked on by NC on blanks)<br/>+++Key in FG serial number+++
    <br/>Center Hole: ${maxCenterHole} Max<br/>${headDescription}<br/>${numPiecesDescription}, Clad ${cladMetalPreference}<br/>Straight Flange: ${straightFlange}<br/>Open End: ${openEnd}
    <br/>WELDING RESTRICTIONS:<br/>Welding On This Material Is Not Allowed Without Written Nobelclad Approval<br/>Welding, Other Than Tack Welds And Temporary Attachments, Shall Be Performed Only By Companies Holding An ASME U Stamp.<br/>Cosmetic Repair, Tack Welds and Temporary Attachments Shall Only Be Performed With NobelClad Written Approval, Welding Shall Be Performed In Accordance With ASME Section IX<br/>Cosmetic Repair Of Base Metal May Require ASME U Stamp Welding With Partial Data Reports And MT/PT Testing �As Applicable�<br/>Filler Metal Test Reports And NDE Inspection Reports Shall Be Provided For Any Welding Performed On This Material
    <br/>TESTING AND INSPECTION: Standard<br/>Base Metal UT: ${postCladBaseMetalUTSpecification}<br/>Clad UT: ${postBondUTSpecification}
    <br/>FINISHED SURFACE PREP: Standard<br/>PACKAGING: Standard NAFTA<br/>DOCUMENT SUBMITTAL: Standard<br/>SHIPMENT: Standard** DO NOT SHIP HEAD UNTIL NC HAS PROVIDED INSTRUCTIONS AND WRITTEN AUTHORIZATION FOR SHIPMENT **
    <br/>MARKING:<br/>Note: Images of stamping required with inspection documents<br/>Stamp or Re-stamp (in accordance with ${cladProductionSpecification})<br/>NobelClad S/N (as received on head blank)<br/>+++Paste in Stamping Instructions+++
    <br/>Mark heads on outside with paint, and include on the Packing List and Bill of Lading:<br/>NobelClad S/N (as received on head blank)
    <br/>${accountName}<br/>PO: ${po}<br/>Tag: ${tag}`;

    return notes;
};

export const checkGuardRail_WidthLengthRatio = (width: number, length: number): boolean => {
  const ratio = (width !== 0 && length !== 0) && (width >= length ? width / length : length / width) >= 6;
  return ratio;
};
export const checkGuardRail_MaxLength = (length: number, uom): boolean => {
  const ImperialLengthLimit = 500;
  const MetricLengthLimit = 12700;
  if (uom === MeasurementSystems.Imperial) {
    return length >= ImperialLengthLimit;
  } else {
    return length >= MetricLengthLimit;
  }
};
export const checkGuardRail_MaxWidth = (width: number, uom): boolean => {
  const ImperialLengthLimit = 500;
  const MetricLengthLimit = 12700;
  if (uom === MeasurementSystems.Imperial) {
    return width >= ImperialLengthLimit;
  } else {
    return width >= MetricLengthLimit;
  }
};
export const checkGuardRail_MaxWeight = (weight: number, uom): boolean => {
  const LBLimit = 40084;
  const KGLimit = 18144;
  if (uom === MeasurementSystems.Imperial) {
    return weight >= LBLimit;
  } else {
    return weight >= KGLimit;
  }
};

export const checkGuardRail_FandTThickness = (rawMaterials, lineItem: QuoteLineItem, manufacturingSite, uom): [boolean, string] => {

  const isBaseOnly: boolean = lineItem.Base_Type__c === MetalTypes.FandT && lineItem.Clad_Type__c !== MetalTypes.FandT;
  const isCladOnly: boolean = lineItem.Base_Type__c !== MetalTypes.FandT && lineItem.Clad_Type__c === MetalTypes.FandT;
  const isBoth: boolean = lineItem.Base_Type__c === MetalTypes.FandT && lineItem.Clad_Type__c === MetalTypes.FandT;
  const { Width_mm__c, Width_in__c, CladMetal__c, Clad_TK_mm__c, Clad_TK_in__c, BaseMetal__c, Base_TK_mm__c, Base_TK_in__c, Base_FT_Value_in__c, Base_FT_Value_mm__c } = lineItem;
  const finishedWidth = uom === MeasurementSystems.Metric ? Width_mm__c : Width_in__c;
  const totalTK = uom === MeasurementSystems.Metric ? Clad_TK_mm__c + Base_TK_mm__c : Clad_TK_in__c + Base_TK_in__c;

  if(isBaseOnly) {
    const ftValue = uom === MeasurementSystems.Metric ? Base_FT_Value_mm__c : Base_FT_Value_in__c;
    const baseMetal = rawMaterials?.find(({ name, usage, dataAreaId }) => name === BaseMetal__c && usage === RawMaterialUsage.Base && dataAreaId.includes(manufacturingSite));

    if(!baseMetal || ftValue) {
      return [false, null];
    }

    const flatteningThinningGroup = baseMetal?.flatteningAndThinningValues[manufacturingSite]?.find(ftgm => {
      const values = ftgm[uom.toLowerCase()];
      return totalTK > values['>minThickness'] && totalTK <= values['<=maxThickness'] && finishedWidth > values['>minWidth'] && finishedWidth <= values['<=maxWidth'];
    });

    return flatteningThinningGroup ? [flatteningThinningGroup[uom.toLowerCase()].ftFactorWidth === 0, GuardRailsLongDescription.FTValueBase] : [false, null];
  }

  if(isCladOnly) {
    const ftValue = uom === MeasurementSystems.Metric ? Base_FT_Value_mm__c : Base_FT_Value_in__c;
    const cladMetal = rawMaterials?.find(({ name, usage, dataAreaId }) => name === CladMetal__c && usage === RawMaterialUsage.Clad && dataAreaId.includes(manufacturingSite));

    if(!cladMetal || ftValue) {
      return [false, null];
    }

    const flatteningThinningGroup = cladMetal?.flatteningAndThinningValues[manufacturingSite]?.find(ftgm => {
      const values = ftgm[uom.toLowerCase()];
      return totalTK > values['>minThickness'] && totalTK <= values['<=maxThickness'] && finishedWidth > values['>minWidth'] && finishedWidth <= values['<=maxWidth'];
    });

    return flatteningThinningGroup ? [flatteningThinningGroup[uom.toLowerCase()].ftFactorWidth === 0, GuardRailsLongDescription.FTValueClad] : [false, null];
  }

  if(isBoth) {
    const ftValue = uom === MeasurementSystems.Metric ? Base_FT_Value_mm__c : Base_FT_Value_in__c;
    const baseMetal = rawMaterials?.find(({ name, usage, dataAreaId }) => name === BaseMetal__c && usage === RawMaterialUsage.Base && dataAreaId.includes(manufacturingSite));

    if(!baseMetal || ftValue) {
      return [false, null];
    }

    const flatteningThinningGroup = baseMetal?.flatteningAndThinningValues[manufacturingSite]?.find(ftgm => {
      const values = ftgm[uom.toLowerCase()];
      return totalTK > values['>minThickness'] && totalTK <= values['<=maxThickness'] && finishedWidth > values['>minWidth'] && finishedWidth <= values['<=maxWidth'];
    });

    return flatteningThinningGroup ? [flatteningThinningGroup[uom.toLowerCase()].ftFactorWidth === 0, GuardRailsLongDescription.FTValue] : [false, null];
  }

  return [false, null];
};


export const checkGuardRail_MaxWeightCutType = (thickness: number, cutMethod: string, uom): boolean => {
  const InThickLimitSawCut = 12;
  const MMThickLimitSawCut = 304.8;
  const InThickLimitWaterJetCut = 10;
  const MMThickLimitWaterJetCut = 254;
  const InThickLimitTorchCut = 11.5;
  const MMThickLimitTorchCut = 292.1;

  switch(cutMethod) {
    case CutMethods.SawCut:
      if (uom === MeasurementSystems.Imperial) {
        return thickness > InThickLimitSawCut;
      } else {
        return thickness > MMThickLimitSawCut;
      }
    case CutMethods.WaterJetCut:
      if (uom === MeasurementSystems.Imperial) {
        return thickness > InThickLimitWaterJetCut;
      } else {
        return thickness > MMThickLimitWaterJetCut;
      }
    case CutMethods.TorchCut: {
      if (uom === MeasurementSystems.Imperial) {
        return thickness > InThickLimitTorchCut;
      } else {
        return thickness > MMThickLimitTorchCut;
      }
    }
    case CutMethods.Uncut: {
      return false;
    }

  }
};

export const runGuardRail = (quoteLineItem: QuoteLineItem, quoteLineItemDetail: QuoteLineItemDetail, unitsOfMeasure, rawMaterials, manufacturingSite): string[] => {

  const rules = [];
  const width = unitsOfMeasure === MeasurementSystems.Imperial ? quoteLineItemDetail.Clad_Width_in__c : quoteLineItemDetail.Clad_Width_mm__c;
  const length = unitsOfMeasure === MeasurementSystems.Imperial ? quoteLineItemDetail.Clad_Length_in__c : quoteLineItemDetail.Clad_Length_mm__c;
  const weightPerPC = unitsOfMeasure === MeasurementSystems.Imperial ? quoteLineItem.Ship_Weight_LB__c : quoteLineItem.Ship_Weight_KG__c;
  const cladTK = unitsOfMeasure === MeasurementSystems.Imperial ? quoteLineItemDetail.Clad_TK_in__c : quoteLineItemDetail.Clad_TK_mm__c;
  const cutMethod = quoteLineItem.Cut_Method__c;
  const WLRatio = checkGuardRail_WidthLengthRatio(width, length);
  const MaxLength = checkGuardRail_MaxLength(length, unitsOfMeasure);
  const MaxWidth = checkGuardRail_MaxWidth(width, unitsOfMeasure);
  const MaxWeight = checkGuardRail_MaxWeight(weightPerPC, unitsOfMeasure);
  const MaxThickness = checkGuardRail_FandTThickness(rawMaterials, quoteLineItem, manufacturingSite, unitsOfMeasure);
  const MaxThicknessCutType = checkGuardRail_MaxWeightCutType(cladTK, cutMethod, unitsOfMeasure);
  if(WLRatio) rules.push(GuardRailsLongDescription.WidthLengthRatio);
  if(MaxLength) rules.push(GuardRailsLongDescription.MaxLength);
  if(MaxWidth) rules.push(GuardRailsLongDescription.MaxWidth);
  if(MaxWeight) rules.push(GuardRailsLongDescription.MaxWeight);
  if(MaxThickness[0]) rules.push(MaxThickness[1]);
  if(MaxThicknessCutType) rules.push(GuardRailsLongDescription.MaxThicknessCutType);
  return rules;
};

// Recalculates the dependent fields based on the dependency map
export const recalculateDependentFields = (
  dataState: InitDataContextProps,
  quote: Quote,
  associatedLine: QuoteLineItem,
  calculatedFieldsEditedMap: Map<string, string[]>,
  inputFieldsEditedMap: Map<string, string[]>,
  dependentUnwrittenFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>,
  valueType: QuoteLineItemValueTypes,
  dependencyFilter?: QuoteLineItemValueTypes,
  saveMultipleFlag = false,
  reset = false,
  fieldsUpdated?: QuoteLineItemValueTypes[]) =>
{
  const {
    rawMaterials: loadedRawMaterials,
    edgeAllowances: loadedEdgeAllowances,
    boosterAllowances: loadedBoosterAllowances,
    freight: loadedFreight,
    specificationTestOptions: loadedSpecificationTestOptions,
    finishedGoods: loadedFinishedGoods,
    quoteVariableConfigurations: loadedQuoteVariableConfigurations,
    rawMaterialCombinations: loadedRawMaterialCombinations,
    outsideServiceCosts: loadedOutsideServiceCost
  } = dataState;

  const {
    Freight_Zone__c,
    Manufacturing_Site__c: manufacturingSite,
    Incoterms__c: incoterms,
    Agent_Com_Rate__c: agentComRate,
    Export_Packaging__c: hasExportPackaging
  } = quote;

  const freightZone = Freight_Zone__c?.replace('_', ' ');

  const associatedDetail = associatedLine.quoteLineItemDetail;

  const lineItemId = associatedLine.Id || associatedLine.displayId;
  const detailId = associatedDetail.Id || associatedDetail.associatedLineItem;
  const dependentFields = quoteFieldDependencyMap.get(valueType);
  const dependentFieldsUpdated = [...(fieldsUpdated || [])];

  const combinedLines = quote.quoteLineItems.filter(qli => qli.PeggedSupply__c).map(masterPlate => {
    return [masterPlate, ...quote.quoteLineItems.filter(qli => qli.Parent_Line__c === masterPlate.Line__c)];
  });

  dependentFields?.filter(dependentField => dependencyFilter ? dependentField !== dependencyFilter : true).forEach(dependentField => {
    const baseMetal: RawMaterial = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.BaseMetal, loadedRawMaterials.find(rawMaterial => rawMaterial.name === associatedLine.BaseMetal__c && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite))) as RawMaterial;
    const cladMetal: RawMaterial = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.CladMetal, loadedRawMaterials.find(rawMaterial => rawMaterial.name === associatedLine.CladMetal__c && rawMaterial.usage === RawMaterialUsage.Clad && rawMaterial.dataAreaId.includes(manufacturingSite))) as RawMaterial;
    // 4-13-2023 - cladOverhangThreshold is not being used in any calculations, so it is commented out
    // const cladOverhangThreshold = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.CladOverhangThreshold, cladMetal?.overHangThinThreshold[manufacturingSite][associatedLine.Unit_of_Measure__c.toLowerCase()]);
    const baseMetalPricingByProposalType = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.BaseMetalPricingByProposalType, baseMetal?.metalPricing[manufacturingSite].filter(metalPricing => metalPricing.proposalType === associatedLine.Proposal_Type_Base__c)) as MetalPricingMeasurements[];
    const cladMetalPricingByProposalType = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.CladMetalPricingByProposalType, cladMetal?.metalPricing[manufacturingSite].filter(metalPricing => metalPricing.proposalType === associatedLine.Proposal_Type_Clad__c)) as MetalPricingMeasurements[];
    const edgeAllowanceGroup = chooseFieldValue(
      dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.EdgeAllowanceGroup, loadedEdgeAllowances.find(edgeAllowanceGroup => edgeAllowanceGroup.baseMetalClass === baseMetal?.dmcClass && edgeAllowanceGroup.cladMetalClass === cladMetal?.dmcClass && edgeAllowanceGroup.dataAreaId === manufacturingSite)?.edgeAllowances || []
    ) as EdgeAllowance[];
    const boosterAllowanceGroup = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.BoosterAllowanceGroup, getBoosterAllowanceGroup(loadedBoosterAllowances, baseMetal, cladMetal, manufacturingSite)) as BoosterAllowance[];
    const cosmeticGrinding = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.CosmeticGrinding, (associatedLine.Additional_Options__c?.split(';') || []).includes(QuoteLineItemAdditionalOptions.CosmeticGrinding));
    const cosmeticGrindingCladThicknessAddition = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.CosmeticGrindingCladThicknessAddition, cosmeticGrinding ? calculateCosmeticGrindingCladThicknessAddition(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c) : 0) as number;
    const finishedWidth = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedLine.Width_mm__c : associatedLine.Width_in__c;
    const finishedLength = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedLine.Length_mm__c : associatedLine.Length_in__c;
    const finishedBaseMetalThickness = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedLine.Base_TK_mm__c : associatedLine.Base_TK_in__c;
    const finishedCladMetalThickness = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedLine.Clad_TK_mm__c : associatedLine.Clad_TK_in__c;
    const edgeAllowance = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Edge_Allowance_MM__c : associatedDetail.Edge_Allowance_IN__c;
    const cladOverhang = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Clad_Overhang_MM__c : associatedDetail.Clad_Overhang_IN__c;
    const cladOverhangThin = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Clad_Overhang_Thin_MM__c : associatedDetail.Clad_Overhang_Thin_IN__c;
    const boosterAllowance = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Booster_Allowance_MM__c : associatedDetail.Booster_Allowance_IN__c;
    const anvilThickness = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedLine.Anvil_TK_mm__c : associatedLine.Anvil_TK_in__c;
    const anvilEdgeAllowanceAddition = (associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Anvil_Edge_Allowance_Adder_MM__c : associatedDetail.Anvil_Edge_Allowance_Adder_IN__c) || 0;
    const specificationAdder = (associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Specification_Adder_MM__c : associatedDetail.Specification_Adder_IN__c) || 0;
    const baseMetalLengthAddition = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Base_Metal_Length_Adder_MM__c : associatedDetail.Base_Metal_Length_Adder_IN__c;
    const cladLength = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Clad_Length_mm__c : associatedDetail.Clad_Length_in__c;
    const cladWidth = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Clad_Width_mm__c : associatedDetail.Clad_Width_in__c;
    const cladThickness = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Clad_TK_mm__c : associatedDetail.Clad_TK_in__c;
    const baseLength = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Base_Length_mm__c : associatedDetail.Base_Length_in__c;
    const baseWidth = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Base_Width_mm__c : associatedDetail.Base_Width_in__c;
    const baseThickness = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Base_TK_mm__c : associatedDetail.Base_TK_in__c;
    const anvilWidth = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Anvil_Width_mm__c : associatedDetail.Anvil_Width_in__c;
    const anvilLength = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Anvil_Length_mm__c : associatedDetail.Anvil_Length_in__c;
    const cladArea = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.AreaCladder_mm__c : associatedDetail.AreaCladder_in__c;
    const baseArea = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.AreaBacker_mm__c : associatedDetail.AreaBacker_in__c;
    const flyerWidth = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Flyer_Width_mm__c : associatedDetail.Flyer_Width_in__c;
    const flyerLength = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Flyer_Length_mm__c : associatedDetail.Flyer_Length_in__c;
    const cladCost = associatedLine.Customer_Provided_Clad__c ? 0 : associatedLine.Clad_Cost__c || 0;
    const baseCost = associatedLine.Customer_Provided_Base__c ? 0 : associatedLine.Base_Cost__c || 0;
    const cladThicknessAddition = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.CladThicknessAddition, calculateThicknessAddition(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, finishedCladMetalThickness)) as number;
    const totalTK = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedLine.Total_TK_MM__c : associatedLine.Total_TK_IN__c;
    const baseFlatteningThinningGroup: FlatteningAndThinningValuesMeasurements = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.BaseFlatteningThinningGroup, getFlatteningThinningGroup(baseMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, totalTK, finishedWidth)) as FlatteningAndThinningValuesMeasurements;
    const cladFlatteningThinningGroup: FlatteningAndThinningValuesMeasurements = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.CladFlatteningThinningGroup, getFlatteningThinningGroup(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, totalTK, finishedWidth)) as FlatteningAndThinningValuesMeasurements;
    const baseFlatteningThinningValue: number = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.BaseFlatteningThinningValue, associatedLine.Base_Type__c === MetalTypes.FandT && baseFlatteningThinningGroup ? baseFlatteningThinningGroup[associatedLine.Unit_of_Measure__c.toLowerCase()].ftFactorWidth : 0) as number;
    const cladFlatteningThinningValue: number = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.CladFlatteningThinningValue, associatedLine.Clad_Type__c === MetalTypes.FandT && cladFlatteningThinningGroup ? cladFlatteningThinningGroup[associatedLine.Unit_of_Measure__c.toLowerCase()].ftFactorWidth : 0) as number;
    const cladThicknessForPricing = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.CladThicknessForPricing, calculateCladThickness(finishedCladMetalThickness, cladFlatteningThinningValue, cladThicknessAddition, cosmeticGrindingCladThicknessAddition)) as number;
    const itemType: FinishedGood = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.ItemType, loadedFinishedGoods.find(finishedGood => finishedGood.name === associatedLine.Item_Type__c && finishedGood.dataAreaId === manufacturingSite)) as FinishedGood;
    const blankSize = associatedDetail.NC_Head_Item_Detail__r?.BlankSize__c ?? 0;
    const finalWidth = itemType.name === FinishedProductTypes.Head ? blankSize : finishedWidth;
    const cladMetalPricing = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.CladMetalPricing, getMetalPricing(cladMetalPricingByProposalType, associatedLine.Unit_of_Measure__c, cladThicknessForPricing)) as MetalPricingMeasurements;
    const baseThicknessAddition =  chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.BaseThicknessAddition, calculateThicknessAddition(baseMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, finishedBaseMetalThickness)) as number;
    const baseThicknessForPricing = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.BaseThicknessForPricing, calculateBaseThickness(finishedBaseMetalThickness, baseFlatteningThinningValue, baseThicknessAddition)) as number;
    const baseMetalPricing = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.BaseMetalPricing, getMetalPricing(baseMetalPricingByProposalType, associatedLine.Unit_of_Measure__c, baseThicknessForPricing, finalWidth)) as MetalPricingMeasurements;
    const cladFreight = associatedLine.Customer_Provided_Clad__c ? 0 : associatedLine.Clad_Freight__c || 0;
    const baseFreight = associatedLine.Customer_Provided_Base__c ? 0 : associatedLine.Base_Freight__c || 0;
    const freightOut = associatedLine.Freight_Out__c || 0;
    const explosiveLoadGroups = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.ExplosiveLoadGroups, getExplosiveLoadGroups(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, cladThickness) || []) as ExplosiveGroupMeasurements[];
    const explosiveLoadWeight = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Explosive_Load_kg_sq_m__c : associatedDetail.Explosive_Load_lb_sq_ft__c;
    const weldingLength = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedLine.Welding_Length_mm__c : associatedLine.Welding_Length_in__c;
    const finishedArea = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedLine.Area_Sqm__c : associatedLine.Area_Sqft__c;
    const baseMetalUT = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.BaseMetalUT, baseMetal?.baseMetalUTOptions[manufacturingSite]?.find(baseMetalUTOption => baseMetalUTOption.option === associatedLine?.Base_Metal_UT__c)) as BaseMetalUTOption;
    const totalRawTK = (associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.TotalRawTkMm, baseThickness + cladThickness) : chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.TotalRawTkIn, baseThickness + cladThickness)) as number;
    const welding = associatedLine.Welding__c || 0;
    const headForming = associatedLine.Head_Forming__c || 0;
    const headFreight = associatedLine.Head_Freight__c || 0;
    const osCost = associatedLine.OS_Cost__c || 0;
    const contributionPercent = associatedLine.Contribution__c || 40;
    const explosiveCost = associatedDetail.Explosive_Load_Cost__c || 0;
    const heatTreatmentCost = associatedDetail.Heat_Treatment_Cost__c || 0;
    const consumableCost = associatedDetail.Consumable_Cost__c || 0;
    const specificationTestCost = associatedDetail.Specification_Test_Cost__c || 0;
    const cutMethodCost = associatedDetail.Cut_Method_Cost__c || 0;
    const testingBKCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingBKCostOfEvent__c || 0;
    const testingBKTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingBKTotalInboundShippingCost__c || 0;
    const testingBKTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingBKTotalOutboundShippingCost__c || 0;
    const testingBKTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingBKTotalCost__c || 0;
    const testingCLCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingCLCostOfEvent__c || 0;
    const testingCLTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingCLTotalInboundShippingCost__c || 0;
    const testingCLTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingCLTotalOutboundShippingCost__c || 0;
    const testingCLTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingCLTotalCost__c || 0;
    const testingCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingCostOfEvent__c || 0;
    const testingTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingTotalInboundShippingCost__c || 0;
    const testingTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingTotalOutboundShippingCost__c || 0;
    const testingTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.TestingTotalCost__c || 0;
    const backerMetalHTTotalCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.BackerMetalHTTotalCostOfEvent__c || 0;
    const backerMetalHTTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.BackerMetalHTTotalInboundShippingCost__c || 0;
    const backerMetalHTTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.BackerMetalHTTotalOutboundShippingCost__c || 0;
    const backerMetalHTTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.BackerMetalHTTotalCost__c || 0;
    const cladderMetalHTTotalCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.CladderMetalHTTotalCostOfEvent__c || 0;
    const cladderMetalHTTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.CladderMetalHTTotalInboundShippingCost__c || 0;
    const cladderMetalHTTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.CladderMetalHTTotalOutboundShippingCost__c || 0;
    const cladderMetalHTTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.CladderMetalHTTotalCost__c || 0;
    const postbondHTTotalCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.PostbondHTTotalCostOfEvent__c || 0;
    const postbondHTTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.PostbondHTTotalInboundShippingCost__c || 0;
    const postbondHTTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.PostbondHTTotalOutboundShippingCost__c || 0;
    const postbondHTTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.PostbondHTTotalCost__c || 0;
    const weldingCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.WeldingCostOfEvent__c || 0;
    const weldingTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.WeldingTotalInboundShippingCost__c || 0;
    const weldingTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.WeldingTotalOutboundShippingCost__c || 0;
    const weldingTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.WeldingTotalCost__c || 0;
    const xRayServicesCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.XRayServicesCostOfEvent__c || 0;
    const xRayServicesTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.XRayServicesTotalInboundShippingCost__c || 0;
    const xRayServicesTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.XRayServicesTotalOutboundShippingCost__c || 0;
    const xRayServicesTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.XRayServicesTotalCost__c || 0;
    const flatteningBKCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.FlatteningBKCostOfEvent__c || 0;
    const flatteningBKTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.FlatteningBKTotalInboundShippingCost__c || 0;
    const flatteningBKTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.FlatteningBKTotalOutboundShippingCost__c || 0;
    const flatteningBKTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.FlatteningBKTotalCost__c || 0;
    const cuttingCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.CuttingCostOfEvent__c || 0;
    const cuttingTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.CuttingTotalInboundShippingCost__c || 0;
    const cuttingTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.CuttingTotalOutboundShippingCost__c || 0;
    const cuttingTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.CuttingTotalCost__c || 0;
    const waterJetCuttingCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.WaterJetCuttingCostOfEvent__c || 0;
    const waterJetCuttingTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.WaterJetCuttingTotalInboundShippingCost__c || 0;
    const waterJetCuttingTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.WaterJetCuttingTotalOutboundShippingCost__c || 0;
    const waterJetCuttingTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.WaterJetCuttingTotalCost__c || 0;
    const flatteningCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.FlatteningCostOfEvent__c || 0;
    const flatteningTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.FlatteningTotalInboundShippingCost__c || 0;
    const flatteningTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.FlatteningTotalOutboundShippingCost__c || 0;
    const flatteningTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.FlatteningTotalCost__c || 0;
    const machiningCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.MachiningCostOfEvent__c || 0;
    const machiningTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.MachiningTotalInboundShippingCost__c || 0;
    const machiningTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.MachiningTotalOutboundShippingCost__c || 0;
    const machiningTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.MachiningTotalCost__c || 0;
    const inspectionBKCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionBKCostOfEvent__c || 0;
    const inspectionBKTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionBKTotalInboundShippingCost__c || 0;
    const inspectionBKTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionBKTotalOutboundShippingCost__c || 0;
    const inspectionBKTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionBKTotalCost__c || 0;
    const inspectionCLCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionCLCostOfEvent__c || 0;
    const inspectionCLTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionCLTotalInboundShippingCost__c || 0;
    const inspectionCLTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionCLTotalOutboundShippingCost__c || 0;
    const inspectionCLTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionCLTotalCost__c || 0;
    const inspectionCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionCostOfEvent__c || 0;
    const inspectionTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionTotalInboundShippingCost__c || 0;
    const inspectionTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionTotalOutboundShippingCost__c || 0;
    const inspectionTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.InspectionTotalCost__c || 0;
    const packagingCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.PackagingCostOfEvent__c || 0;
    const packagingTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.PackagingTotalInboundShippingCost__c || 0;
    const packagingTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.PackagingTotalOutboundShippingCost__c || 0;
    const packagingTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.PackagingTotalCost__c || 0;
    const canRollingCostOfEvent = associatedDetail.NC_Outside_Service_Item_Details__r?.CanRollingCostOfEvent__c || 0;
    const canRollingTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.CanRollingTotalInboundShippingCost__c || 0;
    const canRollingTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.CanRollingTotalOutboundShippingCost__c || 0;
    const canRollingTotalCost = associatedDetail.NC_Outside_Service_Item_Details__r?.CanRollingTotalCost__c || 0;
    const headFormingCostPerHead = associatedDetail.NC_Head_Item_Detail__r?.HeadFormingCostPerHead__c || 0;
    const headFormingTotalInboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.HeadFormingTotalInboundShippingCost__c || 0;
    const headFormingTotalOutboundShippingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.HeadFormingTotalOutboundShippingCost__c || 0;
    const headFormingSubcontractingCost = associatedDetail.NC_Outside_Service_Item_Details__r?.HeadFormingSubcontractingCost__c || 0;
    const flatteningCost = associatedDetail.FlatteningLaborCost__c || 0;
    const machiningCost: number = associatedDetail.NC_Tubesheet_Item_Details__r?.TubesheetMachiningTotalCost__c || 0;
    const heatTreatCombinationGroup: HeatTreatMeasurements = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.HeatTreatCombinationGroup, getHeatTreatCombinationGroup(loadedRawMaterialCombinations, baseMetal, cladMetal, manufacturingSite)) as HeatTreatMeasurements;
    const preHeatTimingGroup: PreHeatTimingMeasurements = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.PreHeatTimingGroup, getPreHeatTimingGroup(heatTreatCombinationGroup, associatedLine.Unit_of_Measure__c, totalTK)) as PreHeatTimingMeasurements;
    const plateLengthSupplyToFormer = associatedDetail.NC_Cylinder_Item_Details__r?.PlateLengthSupplyToFormer__c ?? 0;
    const plateWidthSupplyToFormer = associatedDetail.NC_Cylinder_Item_Details__r?.PlateWidthSupplyToFormer__c ?? 0;
    const kerfAllowance = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedDetail.Kerf_Allowance_MM__c : associatedDetail.Kerf_Allowance_IN__c;
    const maxWidthdescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? QuoteVariableConfigDescriptions.StandardSizeMaximumWidthMM : QuoteVariableConfigDescriptions.StandardSizeMaximumWidthIN;
    const maximumWidth = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, maxWidthdescription, manufacturingSite, FieldTypes.Float) as number;
    const maxLengthdescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? QuoteVariableConfigDescriptions.StandardSizeMaximumLengthMM : QuoteVariableConfigDescriptions.StandardSizeMaximumLengthIN;
    const maximumLength = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, maxLengthdescription, manufacturingSite, FieldTypes.Float) as number;
    const finalLength = itemType.name === FinishedProductTypes.Head ? blankSize : finishedLength;
    const numberOfItemsWideThatFit = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.NumberOfItemsWideThatFit, calculateNumberOfItemsWideThatFit(maximumWidth, finalWidth, kerfAllowance, edgeAllowance, boosterAllowance, cladOverhang, cladOverhangThin, anvilEdgeAllowanceAddition)) as number;
    const numberOfItemsLongThatFit = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.NumberOfItemsLongThatFit, calculateNumberOfItemsLongThatFit(maximumLength, finalLength, kerfAllowance, edgeAllowance, specificationAdder, baseMetalLengthAddition, cladOverhang, cladOverhangThin, anvilEdgeAllowanceAddition)) as number;
    const maxQuantityOnClad = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.MaxQuantityOnClad, calculateMaxQuantityOnClad(numberOfItemsWideThatFit, numberOfItemsLongThatFit)) as number;
    const quantityForLeftoverCalculation = dependentUnwrittenFieldsMap.get(QuoteLineItemDependentUnwrittenFields.QuantityForLeftoverCalculation) as number;
    const extraCladRequired = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.ExtraCladRequired,  determineExtraCladRequired(associatedDetail.Long__c, numberOfItemsLongThatFit, associatedDetail.Quantity__c, associatedDetail.Clads__c, quantityForLeftoverCalculation));
    const headDiameter = associatedDetail.NC_Head_Item_Detail__r?.HeadDiameter__c ?? 0;
    const isTwoPieceHead = associatedDetail.NC_Head_Item_Detail__r?.CreateTwoPieceHead__c ?? false;
    const consumableCostsToReconcile = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.ConsumableCostsToReconcile, calculateConsumableCostsToRemoveFromTotal(finalLength, finalWidth, associatedLine.Unit_of_Measure__c, loadedQuoteVariableConfigurations, manufacturingSite, associatedLine.Cut_Method__c, totalRawTK, associatedLine.Shape__c, associatedDetail.Quantity__c, associatedDetail.Clads__c)) as number;
    const headQuantity = associatedDetail.NC_Head_Item_Detail__r?.HeadQuantity__c || 0;
    const overallCost = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.OverallCost, calculateOverallCost(associatedLine.Unit_of_Measure__c, loadedQuoteVariableConfigurations, manufacturingSite, cladArea, associatedDetail.Clads__c)) as number;
    const isForging = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.IsForging, baseMetal.dmcClass.includes('/F')) as boolean;
    const generalLaborCost = associatedLine.GeneralLaborCost__c ?? 0;
    const totalLaborCost = associatedLine.TotalLaborCost__c ?? 0;
    const squareFinishedArea = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.SquareFinishedArea, calculateSquareFinishedArea(associatedLine.Unit_of_Measure__c, finishedLength, finalWidth, associatedLine.Shape__c)) as number;
    const anvilDimensionAdder = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.AnvilDimensionAdder, anvilThickness ? baseMetal.anvilDimensionAdders[manufacturingSite][associatedLine.Unit_of_Measure__c.toLowerCase()]?.dimensionsAdder : 0) as number;
    const additionalOptions = associatedLine.Additional_Options__c?.split(';') || [];
    const heatTreatment = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.HeatTreatment, getHeatTreatmentRequired(additionalOptions, loadedRawMaterialCombinations, baseMetal, cladMetal, manufacturingSite, itemType, associatedLine.Unit_of_Measure__c, loadedQuoteVariableConfigurations, totalRawTK, finishedWidth, baseThickness)) as boolean;
    const anvilRequirementsGroup = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.AnvilRequirementsGroup, getAnvilRequirementsGroup(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, baseThickness, cladThickness, cladArea)) as AnvilRequirementsMeasurements;
    const anvilEdgeAllowanceRequirementsGroup = chooseFieldValue(dependentUnwrittenFieldsMap, QuoteLineItemDependentUnwrittenFields.AnvilEdgeAllowanceRequirementsGroup, getAnvilRequirementsGroup(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, baseThickness, cladThickness, squareFinishedArea)) as AnvilRequirementsMeasurements;

    switch(dependentField) {
    case QuoteLineItemValueTypes.OverallCost: {
      const overallCost = calculateOverallCost(associatedLine.Unit_of_Measure__c, loadedQuoteVariableConfigurations, manufacturingSite, cladArea, associatedDetail.Clads__c);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.OverallCost, overallCost);

      dependentFieldsUpdated.push(QuoteLineItemValueTypes.OverallCost);
      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.OverallCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ConsumableCostsToReconcile: {
      const consumableCostsToReconcile = calculateConsumableCostsToRemoveFromTotal(finalLength, finalWidth, associatedLine.Unit_of_Measure__c, loadedQuoteVariableConfigurations, manufacturingSite, associatedLine.Cut_Method__c, totalRawTK, associatedLine.Shape__c, associatedDetail.Quantity__c, associatedDetail.Clads__c);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.ConsumableCostsToReconcile, consumableCostsToReconcile);

      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ConsumableCostsToReconcile);
      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ConsumableCostsToReconcile, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ShipWeight: {
      const isHead = itemType.name === FinishedProductTypes.Head;
      const clad = associatedLine.quoteLineItemDetail.Clads__c || 1;

      const notHeadMultiplier = associatedLine.Cut_Method__c === CutMethods.Uncut
        ? clad
        : clad * associatedLine.quoteLineItemDetail.Quantity__c;

      // if item is head multiply by quantity
      // if not should be clads
      const multiplier = isHead
        ? associatedLine.Quantity__c
        : notHeadMultiplier;

      const shipWeightLb = associatedLine.Total_LB_PC__c * multiplier;
      const shipWeightKg = associatedLine.Total_KG_PC__c * multiplier;

      associatedLine.Ship_Weight_LB__c = shipWeightLb;
      associatedLine.Ship_Weight_KG__c = shipWeightKg;
      associatedLine.Weight_LB__c = shipWeightLb;
      associatedLine.Weight_KG__c = shipWeightKg;
      associatedDetail.Finish_Product_Weight_Lbs__c = shipWeightLb;
      associatedDetail.Finish_Product_Weight_Kg__c = shipWeightKg;

      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ShipWeight);
      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ShipWeight, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TotalFreightPerWeight: {

      if((incoterms === Incoterms.EXW || incoterms === Incoterms.EXWParentheses) && !fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.FreightOut) && !fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.TotalFreightPerWeight)) {

        const freightPerWeight = 0;

        associatedLine.Total_Freight_LB__c = freightPerWeight;
        associatedLine.Total_Freight_KG__c = freightPerWeight;

        dependentFieldsUpdated.push(QuoteLineItemValueTypes.TotalFreightPerWeight);
        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TotalFreightPerWeight, fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.FreightOut) ? QuoteLineItemValueTypes.FreightOut : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      } else {
        let totalFreightPerPound;
        let totalFreightPerKilogram;
        const isHead = itemType.name === FinishedProductTypes.Head;
        const overrideTotalFreight = fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.FreightOut) || saveMultipleFlag;

        if(overrideTotalFreight) {
          totalFreightPerPound = freightOut / associatedLine.Ship_Weight_LB__c;
          totalFreightPerKilogram = freightOut / associatedLine.Ship_Weight_KG__c;
        } else if (!isHead) {
          totalFreightPerPound = associatedLine.Total_Freight_LB__c;
          totalFreightPerKilogram = associatedLine.Total_Freight_KG__c;

          if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.TotalFreightPerWeight) || reset) {
            const totalFreightGroup = getFreightGroup(loadedFreight, freightZone, associatedLine.Ship_Weight_LB__c, associatedLine.Ship_Weight_KG__c, manufacturingSite, associatedLine.Unit_of_Measure__c);

            const freightWideAdderMinimumWidthDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
            QuoteVariableConfigDescriptions.FreightWideAdderMinimumWidthIN :
            QuoteVariableConfigDescriptions.FreightWideAdderMinimumWidthMM;

            const freightWideAdderMaximumWidthDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
            QuoteVariableConfigDescriptions.FreightWideAdderMaximumWidthIN :
            QuoteVariableConfigDescriptions.FreightWideAdderMaximumWidthMM;

            const freightWideAdderMinimumWidth = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, freightWideAdderMinimumWidthDescription, manufacturingSite, FieldTypes.Float) as number;
            const freightWideAdderMaximumWidth = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, freightWideAdderMaximumWidthDescription, manufacturingSite, FieldTypes.Float) as number;

            const wideAdderCostRequired = cladWidth >= freightWideAdderMinimumWidth && cladWidth < freightWideAdderMaximumWidth;

            totalFreightPerPound = totalFreightGroup?.imperial?.costPerUnit + (wideAdderCostRequired ? totalFreightGroup?.imperial?.wideAdderCostPerUnit : 0);
            totalFreightPerKilogram = totalFreightGroup?.metric?.costPerUnit + (wideAdderCostRequired ? totalFreightGroup?.metric?.wideAdderCostPerUnit : 0);
          }
        }

        if(!isHead || overrideTotalFreight) {
          associatedLine.Total_Freight_LB__c = totalFreightPerPound;
          associatedLine.Total_Freight_KG__c = totalFreightPerKilogram;

          dependentFieldsUpdated.push(QuoteLineItemValueTypes.TotalFreightPerWeight);
          recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TotalFreightPerWeight, fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.FreightOut) ? QuoteLineItemValueTypes.FreightOut : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
        }
      }

      break;
    }
    case QuoteLineItemValueTypes.FreightOut: {
      if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.FreightOut) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.TotalFreightPerWeight) || reset) {

        const freightOut = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial
          ? associatedLine.Total_Freight_LB__c * associatedLine.Ship_Weight_LB__c
          : associatedLine.Total_Freight_KG__c * associatedLine.Ship_Weight_KG__c;

        associatedLine.Freight_Out__c = freightOut;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.FreightOut);
      }
      if(((incoterms === Incoterms.EXW || incoterms === Incoterms.EXWParentheses) && !fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.FreightOut)) && ((incoterms === Incoterms.EXW || incoterms === Incoterms.EXWParentheses) && !fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.TotalFreightPerWeight))) {

        const freightOut = 0;

        associatedLine.Freight_Out__c = freightOut;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.FreightOut);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FreightOut, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.TotalFreightPerWeight) ? QuoteLineItemValueTypes.TotalFreightPerWeight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.SpecificationAdder: {
      if(associatedLine.PlateMethod__c !== PlateMethods.ManuallySized) {
        const specificationTestOption = loadedSpecificationTestOptions.find((specificationTestOption) => specificationTestOption.specification === associatedLine.Specification__c && specificationTestOption.option === associatedLine.Bond_Metal_UT__c && specificationTestOption.dataAreaId === manufacturingSite);
        const specificationAdderBySpecification = getSpecificationAdderBySpecification(associatedLine.Unit_of_Measure__c, specificationTestOption, associatedLine.Specification__c, finalWidth, associatedLine.Shape__c, isForging, boosterAllowance, loadedQuoteVariableConfigurations, manufacturingSite);
        const specificationAdderByPostCladTestingOptions = getSpecificationAdderByPostCladTestingOptions(associatedLine.Unit_of_Measure__c, associatedLine.Post_Clad_Testing_Options__c?.split(';') || [], specificationTestOption, finalWidth, associatedLine.Shape__c, isForging, boosterAllowance, loadedQuoteVariableConfigurations, manufacturingSite, itemType);
        const specificationAdder = specificationAdderBySpecification || specificationAdderByPostCladTestingOptions || 0;
        associatedDetail.Specification_Adder_IN__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, specificationAdder, Units.Millimeters, Units.Inches);
        associatedDetail.Specification_Adder_MM__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, specificationAdder, Units.Millimeters, Units.Inches);

        dependentFieldsUpdated.push(QuoteLineItemValueTypes.SpecificationAdder);
        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.SpecificationAdder, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.BaseMetalLengthAdder: {
      // Does not run when long/wide get updated to prevent infinite loops
      const headDiameterThresholdDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? QuoteVariableConfigDescriptions.TwoPieceHeadDiameterIN : QuoteVariableConfigDescriptions.TwoPieceHeadDiameterMM;
      const headDiameterThreshold = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, headDiameterThresholdDescription, manufacturingSite, FieldTypes.Float) as number;
      const baseMetalLengthAddition = calculateBaseMetalLengthAddition(baseMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, finalLength, finalWidth, baseThickness, edgeAllowance, kerfAllowance, associatedDetail.Wide__c, associatedDetail.Long__c, anvilEdgeAllowanceAddition, specificationAdder, isTwoPieceHead, headDiameterThreshold, isForging);

      associatedDetail.Base_Metal_Length_Adder_IN__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, baseMetalLengthAddition, Units.Millimeters, Units.Inches);
      associatedDetail.Base_Metal_Length_Adder_MM__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, baseMetalLengthAddition, Units.Millimeters, Units.Inches);

      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseMetalLengthAdder);
      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseMetalLengthAdder, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseWidth: {
      if(associatedLine.PlateMethod__c !== PlateMethods.ManuallySized) {
        const baseWidth = calculateBaseWidth(associatedDetail.Wide__c, finalWidth, edgeAllowance, anvilEdgeAllowanceAddition, boosterAllowance, kerfAllowance, isForging, specificationAdder, isTwoPieceHead);

        associatedDetail.Base_Width_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, baseWidth, Units.Millimeters, Units.Inches);
        associatedDetail.Base_Width_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, baseWidth, Units.Millimeters, Units.Inches);

        //setting/updating AS Width along with raw base
        associatedDetail.AS_Width__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, baseWidth, Units.Millimeters, Units.Inches);
        //setting/updating Purchase Width along with raw base
        associatedDetail.PurchaseBaseWidth__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, baseWidth, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseWidth);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseWidth, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladWeight: {
      const finishedCladWeightLb = calculateFinishedMetalWeight(associatedLine.Unit_of_Measure__c,
        MeasurementSystems.Imperial,
        finishedLength,
        finishedWidth,
        cladThickness,
        calculateMetalDensity(MeasurementSystems.Imperial, cladMetal),
        associatedLine.Shape__c
      );
      const finishedCladWeightKg = calculateFinishedMetalWeight(
        associatedLine.Unit_of_Measure__c,
        MeasurementSystems.Metric,
        finishedLength,
        finishedWidth,
        cladThickness,
        calculateMetalDensity(MeasurementSystems.Metric, cladMetal),
        associatedLine.Shape__c
      );

      associatedDetail.Clad_Weight_Lbs__c = finishedCladWeightLb;
      associatedDetail.Clad_Weight_Kg__c = finishedCladWeightKg;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladWeight);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladWeight, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseWeight: {
      const finishedBaseWeightLb = calculateFinishedMetalWeight(
        associatedLine.Unit_of_Measure__c,
        MeasurementSystems.Imperial,
        finishedLength,
        finishedWidth,
        baseThickness,
        calculateMetalDensity(MeasurementSystems.Imperial, baseMetal),
        associatedLine.Shape__c
      );
      const finishedBaseWeightKg = calculateFinishedMetalWeight(
        associatedLine.Unit_of_Measure__c,
        MeasurementSystems.Metric,
        finishedLength,
        finishedWidth,
        baseThickness,
        calculateMetalDensity(MeasurementSystems.Metric, baseMetal),
        associatedLine.Shape__c
      );

      associatedDetail.Base_Weight_Lbs__c = finishedBaseWeightLb;
      associatedDetail.Base_Weight_Kg__c = finishedBaseWeightKg;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseWeight);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseWeight, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.AnvilWidth: {
      const anvilWidth = anvilThickness ? calculateAnvilWidth(baseWidth, anvilDimensionAdder) : 0;

      associatedDetail.Anvil_Width_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, anvilWidth,  Units.Millimeters, Units.Inches) || 1;
      associatedDetail.Anvil_Width_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, anvilWidth,  Units.Millimeters, Units.Inches) || 1;

      //setting/updating Purchase Anvil Width along with raw base
      associatedDetail.PurchaseAnvilWidth__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, anvilWidth, Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AnvilWidth);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AnvilWidth, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseLength: {
      if(associatedLine.PlateMethod__c !== PlateMethods.ManuallySized) {
        const headDiameterThresholdDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? QuoteVariableConfigDescriptions.TwoPieceHeadDiameterIN : QuoteVariableConfigDescriptions.TwoPieceHeadDiameterMM;
        const headDiameterThreshold = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, headDiameterThresholdDescription, manufacturingSite, FieldTypes.Float) as number;
        const baseLength = calculateBaseLength(associatedDetail.Long__c, finalLength, edgeAllowance, anvilEdgeAllowanceAddition, specificationAdder, baseMetalLengthAddition, kerfAllowance, isForging, boosterAllowance, isTwoPieceHead, headDiameterThreshold);

        if(calculatedFieldsEditedMap.get(detailId)?.includes(QuoteLineItemValueTypes.BaseLength)) {
          if((associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedDetail.Specification_Adder_IN__c : associatedDetail.Specification_Adder_MM__c) !== specificationAdder
          && (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Length_in__c : associatedLine.Length_mm__c) === finishedLength
          && (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedDetail.Edge_Allowance_IN__c : associatedDetail.Edge_Allowance_MM__c) === edgeAllowance
          && (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedDetail.Base_Metal_Length_Adder_IN__c : associatedDetail.Base_Metal_Length_Adder_MM__c) === baseMetalLengthAddition)
          {
            if((associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedDetail.Base_Length_in__c : associatedDetail.Base_Length_mm__c) > baseLength) {
              recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseLength, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
              break;
            }
          }
        }

        associatedDetail.Base_Length_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, baseLength, Units.Millimeters, Units.Inches);
        associatedDetail.Base_Length_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, baseLength, Units.Millimeters, Units.Inches);

        //setting/updating AS Length along with raw base
        associatedDetail.AS_Length__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, baseLength, Units.Millimeters, Units.Inches);
        //setting/updating Purchase Base Length along with raw base
        associatedDetail.PurchaseBaseLength__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, baseLength, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseLength);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseLength, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladWeightPerPC: {
      const cladPoundsPerPiece = calculateRawMetalWeight(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, cladLength, cladWidth, cladThickness, calculateMetalDensity(MeasurementSystems.Imperial, cladMetal), MetalTypesForAreaCalc.Clad);
      const cladKilogramsPerPiece = calculateRawMetalWeight(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, cladLength, cladWidth, cladThickness, calculateMetalDensity(MeasurementSystems.Metric, cladMetal), MetalTypesForAreaCalc.Clad);

      associatedLine.Clad_LB_PC__c = cladPoundsPerPiece;
      associatedLine.Clad_KG_PC__c = cladKilogramsPerPiece;
      associatedDetail.Raw_Clad_Weight_Lbs__c = cladPoundsPerPiece;
      associatedDetail.Raw_Clad_Weight_Kg__c = cladKilogramsPerPiece;
      associatedDetail.PurchaseCladderWeight_LB__c = cladPoundsPerPiece;
      associatedDetail.PurchaseCladderWeight_KG__c = cladKilogramsPerPiece;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladWeightPerPC);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladWeightPerPC, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.AreaCladder: {
      const cladArea = calculateRawArea(associatedLine.Unit_of_Measure__c, cladLength, cladWidth, MetalTypesForAreaCalc.Clad);

      associatedDetail.AreaCladder_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, cladArea,  Units.SquareMeters, Units.SquareFeet);
      associatedDetail.AreaCladder_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, cladArea,  Units.SquareMeters, Units.SquareFeet);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AreaCladder);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AreaCladder, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseWeightPerPC: {
      const basePoundsPerPiece = calculateRawMetalWeight(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, baseLength, baseWidth, baseThickness, calculateMetalDensity(MeasurementSystems.Imperial, baseMetal), MetalTypesForAreaCalc.Base, isForging);
      const baseKilogramsPerPiece = calculateRawMetalWeight(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, baseLength, baseWidth, baseThickness, calculateMetalDensity(MeasurementSystems.Metric, baseMetal), MetalTypesForAreaCalc.Base, isForging);

      associatedLine.Base_LB_PC__c = basePoundsPerPiece;
      associatedLine.Base_KG_PC__c = baseKilogramsPerPiece;
      associatedDetail.Raw_Base_Weight_Lbs__c = basePoundsPerPiece;
      associatedDetail.Raw_Base_Weight_Kg__c = baseKilogramsPerPiece;
      associatedDetail.PurchaseBackerWeight_LB__c = basePoundsPerPiece;
      associatedDetail.PurchaseBackerWeight_KG__c = baseKilogramsPerPiece;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseWeightPerPC);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseWeightPerPC, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.AreaBacker: {
      const baseArea = calculateRawArea(associatedLine.Unit_of_Measure__c, baseLength, baseWidth, MetalTypesForAreaCalc.Base, isForging);

      associatedDetail.AreaBacker_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, baseArea,  Units.SquareMeters, Units.SquareFeet);
      associatedDetail.AreaBacker_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, baseArea,  Units.SquareMeters, Units.SquareFeet);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AreaBacker);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AreaBacker, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FlyerWidth: {
      let flyerWidth = 0;
      if(cladMetal.flyerRequirements) {
        flyerWidth = (baseWidth + cladMetal.flyerRequirements[associatedLine.Unit_of_Measure__c.toLowerCase()].baseWidthAddition);
      }

      associatedDetail.Flyer_Width_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, flyerWidth,  Units.Millimeters, Units.Inches) || 1;
      associatedDetail.Flyer_Width_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, flyerWidth,  Units.Millimeters, Units.Inches) || 1;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlyerWidth);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlyerWidth, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.Anvil: {

      const anvilCostPerWeightUnitDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
        QuoteVariableConfigDescriptions.AnvilCostByWeightLB :
        QuoteVariableConfigDescriptions.AnvilCostByWeightKG;

      const anvilCostPerWeightUnit = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, anvilCostPerWeightUnitDescription, manufacturingSite, FieldTypes.Float) as number;

      const anvilCost = (associatedDetail.NC_Anvil_Item_Details__r?.WeightAnvil__c * anvilCostPerWeightUnit) * associatedDetail.Clads__c;

      const anvilFreightPerWeightUnitDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
      QuoteVariableConfigDescriptions.AnvilFreightCostByWeightLB :
      QuoteVariableConfigDescriptions.AnvilFreightCostByWeightKG;

      const anvilFreightPerWeightUnit = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, anvilFreightPerWeightUnitDescription, manufacturingSite, FieldTypes.Float) as number;

      const anvilFreightCost = (associatedDetail.NC_Anvil_Item_Details__r?.WeightAnvil__c * anvilFreightPerWeightUnit) * associatedDetail.Clads__c;

      if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.Anvil) || reset) {
        associatedLine.Anvil__c = anvilCost + anvilFreightCost;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.Anvil);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.Anvil, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.AnvilArea: {
      let anvilArea = 0;
      if((anvilWidth !== undefined) && (anvilLength !== undefined) && associatedDetail.Anvil_Needed__c) {
        anvilArea = calculateRawArea(associatedLine.Unit_of_Measure__c, anvilLength, anvilWidth, MetalTypesForAreaCalc.Anvil);
      }

      associatedDetail.Anvil_Area_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, anvilArea,  Units.SquareMeters, Units.SquareFeet) || 1;
      associatedDetail.Anvil_Area_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, anvilArea,  Units.SquareMeters, Units.SquareFeet) || 1;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AnvilArea);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AnvilArea, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FlyerLength: {
      let flyerLength = 0;
      if(cladMetal.flyerRequirements) {
        flyerLength = baseLength + cladMetal.flyerRequirements[associatedLine.Unit_of_Measure__c.toLowerCase()].baseLengthAddition;
      }

      associatedDetail.Flyer_Length_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, flyerLength,  Units.Millimeters, Units.Inches) || 1;
      associatedDetail.Flyer_Length_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, flyerLength,  Units.Millimeters, Units.Inches) || 1;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlyerLength);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlyerLength, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladFreightPerWeight: {
      let cladFreightPerPound;
      let cladFreightPerKilogram;

      const totalCladWeightLb = associatedLine.Clad_LB_PC__c * associatedDetail.Clads__c;
      const totalCladWeightKg = associatedLine.Clad_KG_PC__c * associatedDetail.Clads__c;

      if(fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CladFreight) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CustomerProvidedClad) || saveMultipleFlag) {
        const cladFreightToUse = associatedLine.Customer_Provided_Clad__c ? associatedLine.Clad_Freight_Customer_Supplied__c : cladFreight;
        cladFreightPerPound = cladFreightToUse / totalCladWeightLb;
        cladFreightPerKilogram = cladFreightToUse / totalCladWeightKg;
      } else {
        cladFreightPerPound = associatedLine.Clad_Freight_LB__c;
        cladFreightPerKilogram = associatedLine.Clad_Freight_KG__c;

        if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.CladFreightPerWeight) || reset) {
          if(manufacturingSite === ManufacturingSites.US01) {
            const cladFreightGroup = getFreightGroup(loadedFreight, cladMetalPricing?.freightZone, totalCladWeightLb, totalCladWeightKg, manufacturingSite, associatedLine.Unit_of_Measure__c);

            const freightWideAdderMinimumWidthDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
            QuoteVariableConfigDescriptions.FreightWideAdderMinimumWidthIN :
            QuoteVariableConfigDescriptions.FreightWideAdderMinimumWidthMM;

            const freightWideAdderMaximumWidthDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
            QuoteVariableConfigDescriptions.FreightWideAdderMaximumWidthIN :
            QuoteVariableConfigDescriptions.FreightWideAdderMaximumWidthMM;

            const freightWideAdderMinimumWidth = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, freightWideAdderMinimumWidthDescription, manufacturingSite, FieldTypes.Float) as number;
            const freightWideAdderMaximumWidth = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, freightWideAdderMaximumWidthDescription, manufacturingSite, FieldTypes.Float) as number;

            const wideAdderCostRequired = cladWidth >= freightWideAdderMinimumWidth && cladWidth < freightWideAdderMaximumWidth;

            cladFreightPerPound = cladFreightGroup?.imperial.costPerUnit + (wideAdderCostRequired ? cladFreightGroup?.imperial.wideAdderCostPerUnit : 0);
            cladFreightPerKilogram = cladFreightGroup?.metric.costPerUnit + (wideAdderCostRequired ? cladFreightGroup?.metric.wideAdderCostPerUnit : 0);
          } else if (reset) {
            cladFreightPerPound = 0;
            cladFreightPerKilogram = 0;
          }
        }
      }

      associatedLine.Clad_Freight_LB__c = cladFreightPerPound;
      associatedLine.Clad_Freight_KG__c = cladFreightPerKilogram;
      associatedDetail.PurchaseCladderFreight_LB__c = cladFreightPerPound;
      associatedDetail.PurchaseCladderFreight_KG__c = cladFreightPerKilogram;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladFreightPerWeight);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladFreightPerWeight, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.CladFreight) ? QuoteLineItemValueTypes.CladFreight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladCost: {
      const cladCost = associatedDetail.PurchaseCladderPrice__c * associatedDetail.Clads__c;

      if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.CladCost) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CladPricePerWeight) || reset) {
        associatedLine.Clad_Cost__c = cladCost;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladCost);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladCost, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.CladPricePerWeight) ? QuoteLineItemValueTypes.CladPricePerWeight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.CladCostCustomerSupplied: {
      let cladCostValue = cladCost || associatedLine.Clad_Cost_Customer_Supplied__c;

      if(!fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CladCost) && !cladCostValue || (fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CladPricePerWeight))) {
        cladCostValue = ((associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c) * (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_Price_LB__c : associatedLine.Clad_Price_KG__c)) * associatedDetail.Clads__c;
      }

      associatedLine.Clad_Cost_Customer_Supplied__c = cladCostValue;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladCostCustomerSupplied);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladCostCustomerSupplied, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.CladPricePerWeight) ? QuoteLineItemValueTypes.CladPricePerWeight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.RawMaterialVendorCladder: {
      let vendorCladder = null;

      if(!associatedDetail.Pullfromstockcladder__c) {
        vendorCladder = associatedLine.Customer_Provided_Clad__c ? CustomerSuppliedVendors.CladVendor : (associatedDetail.RawMaterialVendorCladder__c ?? '0');
      }

      associatedDetail.RawMaterialVendorCladder__c = vendorCladder;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.RawMaterialVendorCladder);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.RawMaterialVendorCladder, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TotalWeightPerPC: {
      let totalWeightPerPCItems = {
        lb: {
          base: 0,
          clad: 0
        },
        kg: {
          base: 0,
          clad: 0
        }
      };

      if (itemType.name !== FinishedProductTypes.Head) {
        if (associatedLine.Cut_Method__c === CutMethods.Uncut) {
          totalWeightPerPCItems = {
            lb: {
              clad: associatedLine.Clad_LB_PC__c,
              base: associatedLine.Base_LB_PC__c,
            },
            kg: {
              clad: associatedLine.Clad_KG_PC__c,
              base: associatedLine.Base_KG_PC__c,
            }
          };
        } else {
          totalWeightPerPCItems = {
            lb: {
              clad: associatedDetail.Clad_Weight_Lbs__c,
              base: associatedDetail.Base_Weight_Lbs__c,
            },
            kg: {
              clad: associatedDetail.Clad_Weight_Kg__c,
              base: associatedDetail.Base_Weight_Kg__c,
            }
          };
        }
      } else {
        const size = blankSize;

        const blankSizeBase = {
          kg: calculateFinishedMetalWeight(
            associatedLine.Unit_of_Measure__c,
            MeasurementSystems.Metric,
            size,
            size,
            baseThickness,
            calculateMetalDensity(MeasurementSystems.Metric, baseMetal),
            associatedLine.Shape__c
          ),
          lb: calculateFinishedMetalWeight(
            associatedLine.Unit_of_Measure__c,
            MeasurementSystems.Imperial,
            size,
            size,
            baseThickness,
            calculateMetalDensity(MeasurementSystems.Imperial, baseMetal),
            associatedLine.Shape__c
          )
        };
        const blankSizeClad = {
          kg: calculateFinishedMetalWeight(
            associatedLine.Unit_of_Measure__c,
            MeasurementSystems.Metric,
            size,
            size,
            cladThickness,
            calculateMetalDensity(MeasurementSystems.Metric, cladMetal),
            associatedLine.Shape__c
          ),
          lb: calculateFinishedMetalWeight(
            associatedLine.Unit_of_Measure__c,
            MeasurementSystems.Imperial,
            size,
            size,
            cladThickness,
            calculateMetalDensity(MeasurementSystems.Imperial, cladMetal),
            associatedLine.Shape__c
          )
        };

        totalWeightPerPCItems = {
          lb: {
            base: blankSizeBase.lb,
            clad: blankSizeClad.lb
          },
          kg: {
            base: blankSizeBase.kg,
            clad: blankSizeClad.kg
          }
        };
      }
      const totalPoundsPerPiece = calculateTotalWeightPerPiece(totalWeightPerPCItems.lb.clad, totalWeightPerPCItems.lb.base);
      const totalKilogramsPerPiece = calculateTotalWeightPerPiece(totalWeightPerPCItems.kg.clad, totalWeightPerPCItems.kg.base);

      associatedLine.Total_LB_PC__c = totalPoundsPerPiece;
      associatedLine.Total_KG_PC__c = totalKilogramsPerPiece;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TotalWeightPerPC);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TotalWeightPerPC, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.DetailArea: {
      const area = Math.max(baseArea, cladArea);

      associatedDetail.Area_Sqft__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, area,  Units.SquareMeters, Units.SquareFeet);
      associatedDetail.Area_Sqm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, area,  Units.SquareMeters, Units.SquareFeet);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.DetailArea);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.DetailArea, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseFreightPerWeight: {
      let baseFreightPerPound;
      let baseFreightPerKilogram;

      const totalBaseWeightLb = associatedLine.Base_LB_PC__c * associatedDetail.Clads__c;
      const totalBaseWeightKg = associatedLine.Base_KG_PC__c * associatedDetail.Clads__c;

      if(fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.BaseFreight) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CustomerProvidedBase) || saveMultipleFlag) {
        const baseFreightToUse = associatedLine.Customer_Provided_Base__c ? associatedLine.Base_Freight_Customer_Supplied__c : baseFreight;
        baseFreightPerPound = baseFreightToUse / totalBaseWeightLb;
        baseFreightPerKilogram = baseFreightToUse / totalBaseWeightKg;
      } else {
        baseFreightPerPound = associatedLine.Base_Freight_LB__c;
        baseFreightPerKilogram = associatedLine.Base_Freight_KG__c;

        if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.BaseFreightPerWeight) || reset) {
          if(manufacturingSite === ManufacturingSites.US01) {
            const baseFreightGroup = getFreightGroup(loadedFreight, baseMetalPricing?.freightZone, totalBaseWeightLb, totalBaseWeightKg, manufacturingSite, associatedLine.Unit_of_Measure__c);

            const freightWideAdderMinimumWidthDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
            QuoteVariableConfigDescriptions.FreightWideAdderMinimumWidthIN :
            QuoteVariableConfigDescriptions.FreightWideAdderMinimumWidthMM;

            const freightWideAdderMaximumWidthDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
            QuoteVariableConfigDescriptions.FreightWideAdderMaximumWidthIN :
            QuoteVariableConfigDescriptions.FreightWideAdderMaximumWidthMM;

            const freightWideAdderMinimumWidth = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, freightWideAdderMinimumWidthDescription, manufacturingSite, FieldTypes.Float) as number;
            const freightWideAdderMaximumWidth = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, freightWideAdderMaximumWidthDescription, manufacturingSite, FieldTypes.Float) as number;

            const wideAdderCostRequired = baseWidth >= freightWideAdderMinimumWidth && baseWidth < freightWideAdderMaximumWidth;

            baseFreightPerPound = baseFreightGroup?.imperial.costPerUnit + (wideAdderCostRequired ? baseFreightGroup?.imperial.wideAdderCostPerUnit : 0);
            baseFreightPerKilogram = baseFreightGroup?.metric.costPerUnit + (wideAdderCostRequired ? baseFreightGroup?.metric.wideAdderCostPerUnit : 0);
          } else if (reset) {
            baseFreightPerPound = 0;
            baseFreightPerKilogram = 0;
          }
        }
      }

      associatedLine.Base_Freight_LB__c = baseFreightPerPound;
      associatedLine.Base_Freight_KG__c = baseFreightPerKilogram;
      associatedDetail.PurchaseBackerFreight_LB__c = baseFreightPerPound;
      associatedDetail.PurchaseBackerFreight_KG__c = baseFreightPerKilogram;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseFreightPerWeight);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseFreightPerWeight, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.BaseFreight) ? QuoteLineItemValueTypes.BaseFreight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseCost: {
      const baseCost = associatedDetail.PurchaseBackerPrice__c * associatedDetail.Clads__c;

      if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.BaseCost) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.BasePricePerWeight) || reset) {
        associatedLine.Base_Cost__c = baseCost;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseCost);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseCost, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.BasePricePerWeight) ? QuoteLineItemValueTypes.BasePricePerWeight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseCostCustomerSupplied: {
      let baseCostValue = baseCost || associatedLine.Base_Cost_Customer_Supplied__c;

      if(!fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.BaseCost) && !baseCostValue || (fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.BasePricePerWeight))) {
        baseCostValue = ((associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c) * (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_Price_LB__c : associatedLine.Base_Price_KG__c)) * associatedDetail.Clads__c;
      }

      associatedLine.Base_Cost_Customer_Supplied__c = baseCostValue;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseCostCustomerSupplied);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseCostCustomerSupplied, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.CladPricePerWeight) ? QuoteLineItemValueTypes.CladPricePerWeight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.RawMaterialVendorBacker: {
      let vendorBacker = null;

      if(!associatedDetail.Pullfromstockbacker__c) {
        vendorBacker = associatedLine.Customer_Provided_Base__c ? CustomerSuppliedVendors.BaseVendor : (associatedDetail.RawMaterialVendorBacker__c ?? '0');
      }

      associatedDetail.RawMaterialVendorBacker__c = vendorBacker;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.RawMaterialVendorBacker);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.RawMaterialVendorBacker, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.HeadFormingCostPerHead: {
      if(fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.PriceHead) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.HeadForming)) {
        associatedDetail.NC_Head_Item_Detail__r.HeadFormingCostPerHead__c = headForming / headQuantity;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeadFormingCostPerHead);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeadFormingCostPerHead, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.PriceHead) ? QuoteLineItemValueTypes.PriceHead : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.HeadQuantity: {
      associatedDetail.NC_Head_Item_Detail__r.HeadQuantity__c = associatedLine.Quantity__c;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeadQuantity);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeadQuantity, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.SizingQuantity) ? QuoteLineItemValueTypes.SizingQuantity : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FlyerArea: {
      let flyerArea = 0;
      if((flyerWidth !== undefined) && (flyerLength !== undefined) && associatedDetail.Flyer_Needed__c) {
        flyerArea = calculateRawArea(associatedLine.Unit_of_Measure__c, flyerLength, flyerWidth, MetalTypesForAreaCalc.Flyer);
      }

      associatedDetail.Flyer_Area_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, flyerArea,  Units.SquareMeters, Units.SquareFeet) || 1;
      associatedDetail.Flyer_Area_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, flyerArea,  Units.SquareMeters, Units.SquareFeet) || 1;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlyerArea);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlyerArea, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladFreight: {
      const cladFreight = associatedLine.Customer_Provided_Clad__c ? 0 : (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_Freight_LB__c * associatedLine.Clad_LB_PC__c : associatedLine.Clad_Freight_KG__c * associatedLine.Clad_KG_PC__c) * associatedDetail.Clads__c;

      if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.CladFreight) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CladFreightPerWeight) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CustomerProvidedClad) || reset) {
        associatedLine.Clad_Freight__c = cladFreight;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladFreight);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladFreight, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.CladFreightPerWeight) ? QuoteLineItemValueTypes.CladFreightPerWeight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladFreightCustomerSupplied: {
      let cladFreightValue = cladFreight || associatedLine.Clad_Freight_Customer_Supplied__c;

      if(!fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CladFreight) && !cladFreightValue || (fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CladFreightPerWeight))) {
        cladFreightValue = (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_Freight_LB__c * associatedLine.Clad_LB_PC__c : associatedLine.Clad_Freight_KG__c * associatedLine.Clad_KG_PC__c) * associatedDetail.Clads__c;
      }

      associatedLine.Clad_Freight_Customer_Supplied__c = cladFreightValue;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladFreightCustomerSupplied);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladFreightCustomerSupplied, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.CladFreightPerWeight) ? QuoteLineItemValueTypes.CladFreightPerWeight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.Welding: {
      const weldingStandardWidthDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
        QuoteVariableConfigDescriptions.StandardSizeMaximumWidthIN :
        QuoteVariableConfigDescriptions.StandardSizeMaximumWidthMM;

      const weldingThreshold = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, weldingStandardWidthDescription, manufacturingSite, FieldTypes.Float);

      let welding = cladWidth >= (weldingThreshold as number) ? calculateDefaultWelding(cladCost, cladMetal.weldCostPercentage[manufacturingSite]) : 0;

      if(fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.RadiographyClad) ||
        !!associatedDetail.Radiography_Clad__c ||
        fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.WeldingLength) ||
        !!weldingLength ||
        inputFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.WeldingLength)) {
        welding = calculateManualWelding(loadedQuoteVariableConfigurations, associatedLine.Unit_of_Measure__c, weldingLength, cladThickness, associatedDetail.Welding_Consumable_Cost__c, cladMetal, associatedDetail.Clads__c, associatedDetail.Radiography_Clad__c, manufacturingSite, associatedLine.Quantity__c);
      }

      if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.Welding) || reset) {
        associatedLine.Welding__c = welding;

        if(manufacturingSite === ManufacturingSites.DE01) {
          const isCladMetalTI = !!associatedLine.CladMetal__c?.match(/-TI$/); // truthy if found; matching regex ending in TI, like this: 265-1-TI
          const hasPrewelding = (welding > 0 || associatedDetail?.SeamWeld__c) ?? false;
          associatedDetail.Radiography_Clad__c = isCladMetalTI && hasPrewelding ? RadiographyTypes.OneHundredPercent : null;
        }

        dependentFieldsUpdated.push(QuoteLineItemValueTypes.Welding);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.Welding, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.WeldingConsumableCost: {
      const cladPricePerWeight = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_Price_LB__c : associatedLine.Clad_Price_KG__c;
      const weldingConsumableCost = calculateWeldingConsumableCost(weldingLength, cladThickness, calculateMetalDensity(associatedLine.Unit_of_Measure__c, cladMetal), cladPricePerWeight, loadedQuoteVariableConfigurations, manufacturingSite);

      associatedDetail.Welding_Consumable_Cost__c = weldingConsumableCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.WeldingConsumableCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.WeldingConsumableCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseFreight: {
      const baseFreight = associatedLine.Customer_Provided_Base__c ? 0 : (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_Freight_LB__c * associatedLine.Base_LB_PC__c : associatedLine.Base_Freight_KG__c * associatedLine.Base_KG_PC__c) * associatedDetail.Clads__c;

      if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.BaseFreight) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.BaseFreightPerWeight) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CustomerProvidedBase) || reset) {
        associatedLine.Base_Freight__c = baseFreight;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseFreight);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseFreight, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.BaseFreightPerWeight) ? QuoteLineItemValueTypes.BaseFreightPerWeight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseFreightCustomerSupplied: {
      let baseFreightValue = baseFreight || associatedLine.Base_Freight_Customer_Supplied__c;

      if(!fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.BaseFreight) && !baseFreightValue || (fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.BaseFreightPerWeight))) {
        baseFreightValue = (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_Freight_LB__c * associatedLine.Base_LB_PC__c : associatedLine.Base_Freight_KG__c * associatedLine.Base_KG_PC__c) * associatedDetail.Clads__c;
      }

      associatedLine.Base_Freight_Customer_Supplied__c = baseFreightValue;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseFreightCustomerSupplied);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseFreightCustomerSupplied, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.BaseFreightPerWeight) ? QuoteLineItemValueTypes.BaseFreightPerWeight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.AnvilLength: {
      const anvilLength = anvilThickness ? calculateAnvilLength(baseLength, anvilDimensionAdder) : 0;

      associatedDetail.Anvil_Length_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, anvilLength,  Units.Millimeters, Units.Inches) || 1;
      associatedDetail.Anvil_Length_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, anvilLength,  Units.Millimeters, Units.Inches) || 1;

      //setting/updating Purchase Anvil Length along with raw base
      associatedDetail.PurchaseAnvilLength__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, anvilLength, Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AnvilLength);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AnvilLength, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FinishedArea: {

      const finishedArea = calculateFinishedArea(associatedLine.Unit_of_Measure__c, finishedLength, finalWidth, associatedLine.Shape__c);

      associatedLine.Area_Sqft__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, finishedArea,  Units.SquareMeters, Units.SquareFeet);
      associatedLine.Area_Sqm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, finishedArea,  Units.SquareMeters, Units.SquareFeet);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.FinishedArea);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FinishedArea, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.SquareFinishedArea: {
      const squareFinishedArea = calculateSquareFinishedArea(associatedLine.Unit_of_Measure__c, finishedLength, finalWidth, associatedLine.Shape__c);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.SquareFinishedArea, squareFinishedArea);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.SquareFinishedArea);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.SquareFinishedArea, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.EdgeAllowance: {
      const edgeAllowance = calculateEdgeAllowance(edgeAllowanceGroup, cladThickness, associatedLine.Unit_of_Measure__c);

      associatedDetail.Edge_Allowance_IN__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, edgeAllowance, Units.Millimeters, Units.Inches);
      associatedDetail.Edge_Allowance_MM__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, edgeAllowance, Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.EdgeAllowance);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.EdgeAllowance, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BoosterAllowance: {
      if(associatedLine.PlateMethod__c !== PlateMethods.ManuallySized) {
        const smallCircleBoosterVariable = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? QuoteVariableConfigDescriptions.BoosterAllowanceExceptionForSmallCircleDiametersIN : QuoteVariableConfigDescriptions.BoosterAllowanceExceptionForSmallCircleDiametersMM;
        const circleBoosterThreshold = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, smallCircleBoosterVariable, manufacturingSite, FieldTypes.Float) as number;

        const boosterAllowance = calculateBoosterAllowance(boosterAllowanceGroup, cladThickness, associatedLine.Unit_of_Measure__c, circleBoosterThreshold, isForging, finalWidth, associatedLine.Shape__c);

        associatedDetail.Booster_Allowance_IN__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, boosterAllowance, Units.Millimeters, Units.Inches);
        associatedDetail.Booster_Allowance_MM__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, boosterAllowance, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.BoosterAllowance);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BoosterAllowance, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.KerfAllowance: {
      const kerfAllowance = calculateKerfAllowance(cladMetal, manufacturingSite, associatedLine.Cut_Method__c, associatedLine.Unit_of_Measure__c, totalRawTK);

      associatedDetail.Kerf_Allowance_IN__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, kerfAllowance, Units.Millimeters, Units.Inches);
      associatedDetail.Kerf_Allowance_MM__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, kerfAllowance, Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.KerfAllowance);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.KerfAllowance, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladThicknessAddition: {
      const cladThicknessAddition = calculateThicknessAddition(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, finishedCladMetalThickness);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.CladThicknessAddition, cladThicknessAddition);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladThicknessAddition);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladThicknessAddition, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladTKForPricing: {
      const cladThicknessForPricing = associatedLine.Clad_Type__c === MetalTypes.NomToBuy ? finishedCladMetalThickness : calculateCladThickness(finishedCladMetalThickness, cladFlatteningThinningValue, cladThicknessAddition, cosmeticGrindingCladThicknessAddition);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.CladThicknessForPricing, cladThicknessForPricing);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladTKForPricing);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladTKForPricing, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.AnvilEdgeAllowanceRequirementsGroup: {
      const anvilEdgeAllowanceRequirementsGroup = getAnvilRequirementsGroup(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, baseThickness, cladThickness, squareFinishedArea);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.AnvilEdgeAllowanceRequirementsGroup, anvilEdgeAllowanceRequirementsGroup);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AnvilEdgeAllowanceRequirementsGroup);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AnvilEdgeAllowanceRequirementsGroup, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.AnvilRequirementsGroup: {
      const anvilRequirementsGroup = getAnvilRequirementsGroup(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, baseThickness, cladThickness, cladArea);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.AnvilRequirementsGroup, anvilRequirementsGroup);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AnvilRequirementsGroup);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AnvilRequirementsGroup, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.AnvilTK: {
      const anvilThickness = calculateAnvilThickness(anvilRequirementsGroup, associatedLine.Unit_of_Measure__c, baseThickness, cladThickness);

      if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.AnvilTK) || reset) {

        associatedLine.Anvil_TK_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, anvilThickness, Units.Millimeters, Units.Inches);
        associatedLine.Anvil_TK_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, anvilThickness, Units.Millimeters, Units.Inches);

        associatedDetail.Anvil_TK_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, anvilThickness, Units.Millimeters, Units.Inches) || 1;
        associatedDetail.Anvil_TK_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, anvilThickness, Units.Millimeters, Units.Inches) || 1;

        //setting/updating Purchase Anvil TK along with raw base
        associatedDetail.PurchaseAnvilTK__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, anvilThickness, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.AnvilTK);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AnvilTK, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TotalTK: {
      const totalTK = finishedBaseMetalThickness + finishedCladMetalThickness;

      associatedLine.Total_TK_IN__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, totalTK, Units.Millimeters, Units.Inches);
      associatedLine.Total_TK_MM__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, totalTK, Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TotalTK);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TotalTK, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladMetalPricing: {
      const cladMetalPricing = getMetalPricing(cladMetalPricingByProposalType, associatedLine.Unit_of_Measure__c, cladThicknessForPricing);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.CladMetalPricing, cladMetalPricing);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladMetalPricing);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladMetalPricing, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.AnvilEdgeAllowanceAddition: {
      const anvilEdgeAllowanceAddition = (anvilEdgeAllowanceRequirementsGroup || {})[associatedLine.Unit_of_Measure__c.toLowerCase()]?.edgeAdder || 0;

      associatedDetail.Anvil_Edge_Allowance_Adder_IN__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, anvilEdgeAllowanceAddition, Units.Millimeters, Units.Inches);
      associatedDetail.Anvil_Edge_Allowance_Adder_MM__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, anvilEdgeAllowanceAddition, Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AnvilEdgeAllowanceAddition);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AnvilEdgeAllowanceAddition, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.AnvilDimensionAdder: {
      const anvilDimensionAdder = anvilThickness ? baseMetal.anvilDimensionAdders[manufacturingSite][associatedLine.Unit_of_Measure__c.toLowerCase()]?.dimensionsAdder : 0;

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.AnvilDimensionAdder, anvilDimensionAdder);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AnvilDimensionAdder);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AnvilDimensionAdder, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladPricePerWeight: {
      let cladPricePerPound;
      let cladPricePerKilogram;
      if(fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CladCost) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CustomerProvidedClad) || saveMultipleFlag) {
        const cladCostToUse = associatedLine.Customer_Provided_Clad__c ? associatedLine.Clad_Cost_Customer_Supplied__c : cladCost;
        cladPricePerPound = (cladCostToUse / associatedDetail.Clads__c) / associatedLine.Clad_LB_PC__c;
        cladPricePerKilogram = (cladCostToUse / associatedDetail.Clads__c) / associatedLine.Clad_KG_PC__c;
      } else {
        cladPricePerPound = (!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.CladPricePerWeight) || reset) ?
          cladMetalPricing?.imperial?.costPerUnit :
          associatedLine.Clad_Price_LB__c;

        cladPricePerKilogram = (!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.CladPricePerWeight) || reset) ?
          cladMetalPricing?.metric?.costPerUnit :
          associatedLine.Clad_Price_KG__c;
      }

      associatedLine.Clad_Price_LB__c = cladPricePerPound;
      associatedLine.Clad_Price_KG__c = cladPricePerKilogram;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladPricePerWeight);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladPricePerWeight, fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CladCost) ? QuoteLineItemValueTypes.CladCost : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.CladTK: {
      if (associatedLine.Clad_Type__c === MetalTypes.NomToBuy) {
        associatedDetail.Clad_TK_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, finishedCladMetalThickness, Units.Millimeters, Units.Inches);
        associatedDetail.Clad_TK_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, finishedCladMetalThickness, Units.Millimeters, Units.Inches);

        //setting/updating Purchase Clad TK along with raw base
        associatedDetail.PurchaseCladTK__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, finishedCladMetalThickness, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladTK);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladTK, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      } else {
        const cladThickness = cladMetalPricing?.proposalType === MetalProposalTypes.DeliveryBest ? cladMetalPricing[associatedLine.Unit_of_Measure__c.toLowerCase()]?.maxTK : cladThicknessForPricing;

        associatedDetail.Clad_TK_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, cladThickness, Units.Millimeters, Units.Inches);
        associatedDetail.Clad_TK_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, cladThickness, Units.Millimeters, Units.Inches);

        //setting/updating Purchase Clad TK along with raw base
        associatedDetail.PurchaseCladTK__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, cladThickness, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladTK);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladTK, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.CladOverhangThin: {
      if(associatedLine.PlateMethod__c !== PlateMethods.ManuallySized) {
        let cladOverhangThin = 0;

        const cladOverhangForForgingsDescription =
          associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial
            ? QuoteVariableConfigDescriptions.OverhangThinIN
            : QuoteVariableConfigDescriptions.OverhangThinMM;

        const threshold = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, cladOverhangForForgingsDescription, manufacturingSite, FieldTypes.Float) as number;

        cladOverhangThin = threshold > cladThickness ? cladOverhangThin = threshold : 0;

        associatedDetail.Clad_Overhang_Thin_IN__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, cladOverhangThin, Units.Millimeters, Units.Inches);
        associatedDetail.Clad_Overhang_Thin_MM__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, cladOverhangThin, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladOverhangThin);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladOverhangThin, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.BaseThicknessAddition: {
      const baseThicknessAddition = calculateThicknessAddition(baseMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, finishedBaseMetalThickness);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.BaseThicknessAddition, baseThicknessAddition);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseThicknessAddition);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseThicknessAddition, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseTKForPricing: {
      const baseThicknessForPricing = associatedLine.Base_Type__c === MetalTypes.NomToBuy ? finishedBaseMetalThickness : calculateBaseThickness(finishedBaseMetalThickness, baseFlatteningThinningValue, baseThicknessAddition);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.BaseThicknessForPricing, baseThicknessForPricing);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseTKForPricing);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseTKForPricing, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.WeightAnvil: {
      const anvilStandardDensityDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
        QuoteVariableConfigDescriptions.AnvilStandardDensityForWeightCalculationPoundsPerCUIN :
        QuoteVariableConfigDescriptions.AnvilStandardDensityForWeightCalculationGramsPerCUCM;

      const anvilStandardDensity = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, anvilStandardDensityDescription, manufacturingSite, FieldTypes.Float) as number;
      const anvilDensity = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? anvilStandardDensity : anvilStandardDensity * 1e-6;

      const weightAnvil = calculateFinishedMetalWeight(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, anvilLength, anvilWidth, anvilThickness, anvilDensity);

      associatedDetail.NC_Anvil_Item_Details__r.WeightAnvil__c = weightAnvil;
      associatedDetail.PurchaseAnvilWeight_LB__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, weightAnvil, Units.Kilograms, Units.Pounds);
      associatedDetail.PurchaseAnvilWeight_KG__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, weightAnvil, Units.Kilograms, Units.Pounds);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.WeightAnvil);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.WeightAnvil, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseMetalPricing: {
      const baseMetalPricing = getMetalPricing(baseMetalPricingByProposalType, associatedLine.Unit_of_Measure__c, baseThicknessForPricing, finalWidth);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.BaseMetalPricing, baseMetalPricing);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseMetalPricing);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseMetalPricing, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BasePricePerWeight: {
      let basePricePerPound;
      let basePricePerKilogram;
      if(fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.BaseCost) || fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.CustomerProvidedBase) || saveMultipleFlag) {
        const baseCostToUse = associatedLine.Customer_Provided_Base__c ? associatedLine.Base_Cost_Customer_Supplied__c : baseCost;
        basePricePerPound = (baseCostToUse / associatedDetail.Clads__c) / associatedLine.Base_LB_PC__c;
        basePricePerKilogram = (baseCostToUse / associatedDetail.Clads__c) / associatedLine.Base_KG_PC__c;
      } else {
        basePricePerPound = (!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.BasePricePerWeight) || reset) ?
          baseMetalPricing?.imperial.costPerUnit :
          associatedLine.Base_Price_LB__c;

        basePricePerKilogram = (!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.BasePricePerWeight) || reset) ?
          baseMetalPricing?.metric.costPerUnit :
          associatedLine.Base_Price_KG__c;
      }

      associatedLine.Base_Price_LB__c = basePricePerPound;
      associatedLine.Base_Price_KG__c = basePricePerKilogram;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BasePricePerWeight);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BasePricePerWeight, fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.BaseCost) ? QuoteLineItemValueTypes.BaseCost : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseTK: {
      if (associatedLine.Base_Type__c === MetalTypes.NomToBuy) {
        associatedDetail.Base_TK_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, finishedBaseMetalThickness, Units.Millimeters, Units.Inches);
        associatedDetail.Base_TK_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, finishedBaseMetalThickness, Units.Millimeters, Units.Inches);

        //setting/updating Purchase Base TK along with raw base
        associatedDetail.PurchaseBaseTK__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, finishedBaseMetalThickness, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseTK);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseTK, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      } else {
        const baseThickness = baseMetalPricing?.proposalType === MetalProposalTypes.DeliveryBest && baseMetalPricing[associatedLine.Unit_of_Measure__c.toLowerCase()]?.costPerUnit !== 0 ? baseMetalPricing[associatedLine.Unit_of_Measure__c.toLowerCase()]?.maxTK || baseThicknessForPricing : baseThicknessForPricing;

        associatedDetail.Base_TK_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, baseThickness, Units.Millimeters, Units.Inches);
        associatedDetail.Base_TK_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, baseThickness, Units.Millimeters, Units.Inches);

        //setting/updating Purchase Base TK along with raw base
        associatedDetail.PurchaseBaseTK__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, baseThickness, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseTK);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseTK, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.AnvilNeeded: {
      const anvilNeeded = !!anvilThickness;

      associatedDetail.Anvil_Needed__c = anvilNeeded;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AnvilNeeded);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AnvilNeeded, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseMetalPricingByProposalType: {
      const baseMetalPricingByProposalType = baseMetal.metalPricing[manufacturingSite].filter(metalPricing => metalPricing.proposalType === associatedLine.Proposal_Type_Base__c);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.BaseMetalPricingByProposalType, baseMetalPricingByProposalType);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseMetalPricingByProposalType);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseMetalPricingByProposalType, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.EdgeAllowanceGroup: {
      const edgeAllowanceGroup = loadedEdgeAllowances.find(edgeAllowanceGroup => edgeAllowanceGroup.baseMetalClass === baseMetal.dmcClass && edgeAllowanceGroup.cladMetalClass === cladMetal.dmcClass && edgeAllowanceGroup.dataAreaId === manufacturingSite)?.edgeAllowances || [];

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.EdgeAllowanceGroup, edgeAllowanceGroup);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.EdgeAllowanceGroup);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.EdgeAllowanceGroup, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BoosterAllowanceGroup: {
      const boosterAllowanceGroup = getBoosterAllowanceGroup(loadedBoosterAllowances, baseMetal, cladMetal, manufacturingSite);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.BoosterAllowanceGroup, boosterAllowanceGroup);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BoosterAllowanceGroup);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BoosterAllowanceGroup, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladOverhangThreshold: {
      const cladOverhangThreshold = cladMetal.overHangThinThreshold[manufacturingSite][associatedLine.Unit_of_Measure__c.toLowerCase()];

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.CladOverhangThreshold, cladOverhangThreshold);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladOverhangThreshold);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladOverhangThreshold, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladOverhang: {
      let cladOverhang = 0;
      if(isForging) {
        const cladOverhangForForgingsDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
        QuoteVariableConfigDescriptions.OverhangSizeOfCladEdgeWBaseForgingIN :
        QuoteVariableConfigDescriptions.OverhangSizeOfCladEdgeWBaseForgingMM;

        cladOverhang = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, cladOverhangForForgingsDescription, manufacturingSite, FieldTypes.Float) as number;
      } else {
        cladOverhang = cladMetal.overhang[manufacturingSite][associatedLine.Unit_of_Measure__c.toLowerCase()];
      }

      associatedDetail.Clad_Overhang_IN__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, cladOverhang, Units.Millimeters, Units.Inches);
      associatedDetail.Clad_Overhang_MM__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, cladOverhang, Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladOverhang);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladOverhang, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladMetalPricingByProposalType: {
      const cladMetalPricingByProposalType = cladMetal.metalPricing[manufacturingSite].filter(metalPricing => metalPricing.proposalType === associatedLine.Proposal_Type_Clad__c);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.CladMetalPricingByProposalType, cladMetalPricingByProposalType);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladMetalPricingByProposalType);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladMetalPricingByProposalType, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CosmeticGrindingCladThicknessAddition: {
      const cosmeticGrindingCladThicknessAddition = cosmeticGrinding ? calculateCosmeticGrindingCladThicknessAddition(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c) : 0;

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.CosmeticGrindingCladThicknessAddition, cosmeticGrindingCladThicknessAddition);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CosmeticGrindingCladThicknessAddition);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CosmeticGrindingCladThicknessAddition, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ExplosiveLoadGroups: {
      const explosiveLoadGroups = getExplosiveLoadGroups(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, cladThickness) || [];

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.ExplosiveLoadGroups, explosiveLoadGroups);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ExplosiveLoadGroups);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ExplosiveLoadGroups, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ExplosiveLoadWeight: {
      let explosiveLoadLbPerSqFt = explosiveLoadGroups[0]?.imperial.explosiveLoad;
      let explosiveLoadKgPerSqM = explosiveLoadGroups[0]?.metric.explosiveLoad;

      if(manufacturingSite === ManufacturingSites.DE01) {
        const ssTiZrextraLoadMultiplier = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.ExplosiveLoadSSTIZRExtraLoadMultiplier, manufacturingSite, FieldTypes.Float) as number;
        const cladAreaextraLoadMultiplier = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.ExplosiveLoadCladAreaExtraLoadMultiplier, manufacturingSite, FieldTypes.Float) as number;

        const minCladAreaThresholdForExtraLoadDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ?
        QuoteVariableConfigDescriptions.ExplosiveLoadMinCladAreaThresholdForExtraLoadSQFT :
        QuoteVariableConfigDescriptions.ExplosiveLoadMinCladAreaThresholdForExtraLoadSQM;

        const minCladAreaThresholdForExtraLoad = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, minCladAreaThresholdForExtraLoadDescription, manufacturingSite, FieldTypes.Float) as number;

        if(baseMetal.classId === MetalClasses.AusteniticStainlessSteels &&
          (cladMetal.classId === MetalClasses.Titanium || cladMetal.classId === MetalClasses.Zirconium)) {
          explosiveLoadLbPerSqFt *= ssTiZrextraLoadMultiplier;
          explosiveLoadKgPerSqM *= ssTiZrextraLoadMultiplier;
        }

        if(cladArea > minCladAreaThresholdForExtraLoad) {
          explosiveLoadLbPerSqFt *= cladAreaextraLoadMultiplier;
          explosiveLoadKgPerSqM *= cladAreaextraLoadMultiplier;
        }
      }

      associatedDetail.Explosive_Load_lb_sq_ft__c = explosiveLoadLbPerSqFt * associatedDetail.AreaCladder_in__c;
      associatedDetail.Explosive_Load_kg_sq_m__c = explosiveLoadKgPerSqM * associatedDetail.AreaCladder_mm__c;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ExplosiveLoadWeight);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ExplosiveLoadWeight, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ExplosiveLoadCost: {
      const explosiveLoadCost = calculateExplosiveLoadCost(loadedQuoteVariableConfigurations, manufacturingSite, associatedLine.Unit_of_Measure__c, explosiveLoadWeight, associatedDetail.Clads__c);

      associatedDetail.Explosive_Load_Cost__c = explosiveLoadCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ExplosiveLoadCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ExplosiveLoadCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ExplosiveItem: {
      const defaultExplosiveTypeUS01 = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.ExplosiveLoadDefaultExplosiveType, ManufacturingSites.US01, FieldTypes.Text) as string;
      const defaultExplosiveTypeDE01 = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.ExplosiveLoadDefaultExplosiveType, ManufacturingSites.DE01, FieldTypes.Text) as string;
      if(manufacturingSite === ManufacturingSites.US01) {
        associatedDetail.ExplosiveItem__c = explosiveLoadGroups[0]?.explosiveType || defaultExplosiveTypeUS01;
        associatedDetail.ExplosiveItemEU__c = defaultExplosiveTypeDE01;
      } else {
        associatedDetail.ExplosiveItem__c = defaultExplosiveTypeUS01;

        const explosiveLoadGroupForType = explosiveLoadGroups?.find(explosiveLoadGroups => !explosiveLoadGroups.baseMetalType || explosiveLoadGroups.baseMetalType === baseMetal.type);

        associatedDetail.ExplosiveItemEU__c = explosiveLoadGroupForType?.explosiveType || defaultExplosiveTypeDE01;
      }

      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ExplosiveItem);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ExplosiveItem, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.HeatTreatmentConsumableCost: {
      const heatTreatmentConsumableCost = heatTreatment
        ? calculateHeatTreatmentConsumableCost(loadedQuoteVariableConfigurations, associatedLine.Unit_of_Measure__c, associatedDetail.Clads__c, associatedLine.Clad_LB_PC__c, associatedLine.Clad_KG_PC__c, associatedLine.Base_LB_PC__c, associatedLine.Base_KG_PC__c, manufacturingSite)
        : 0;

      associatedDetail.Heat_Treatment_Consumable_Cost__c = heatTreatmentConsumableCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeatTreatmentConsumableCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeatTreatmentConsumableCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.HeatTreatmentCost: {
      const heatTreatmentManHoursPerClad = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.HeatTreatmentHoursRequiredPerClad, manufacturingSite, FieldTypes.Float) as number;
      const heatTreatmentCost = heatTreatment && manufacturingSite !== ManufacturingSites.DE01 ? calculateLaborCost(associatedDetail.Clads__c, cladMetal, manufacturingSite, LaborTypes.HeatTreatment, heatTreatmentManHoursPerClad) : 0;

      associatedDetail.Heat_Treatment_Cost__c = heatTreatmentCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeatTreatmentCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeatTreatmentCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.SpecificationTestLaborHours: {
      const specificationTestManHoursPerClad = getSpecificationTestManHours(loadedSpecificationTestOptions.find((specificationTestOption) => specificationTestOption.specification === associatedLine.Specification__c && specificationTestOption.option === associatedLine.Bond_Metal_UT__c && specificationTestOption.dataAreaId === manufacturingSite), associatedLine.Post_Clad_Testing_Options__c, associatedDetail.FerroxylTestPerformed__c, associatedLine.Unit_of_Measure__c, cladArea, baseMetalUT, loadedQuoteVariableConfigurations, manufacturingSite);

      associatedDetail.SpecificationTestLaborHours__c = specificationTestManHoursPerClad;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.SpecificationTestLaborHours);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.SpecificationTestLaborHours, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.SpecificationTestCost: {
      const specificationTestCost = calculateLaborCost(associatedDetail.Clads__c, cladMetal, manufacturingSite, LaborTypes.SpecificationTesting, associatedDetail.SpecificationTestLaborHours__c);

      associatedDetail.Specification_Test_Cost__c = specificationTestCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.SpecificationTestCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.SpecificationTestCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ConsumableCost: {
      const consumableCost = calculateConsumableCost([associatedDetail.Heat_Treatment_Consumable_Cost__c, overallCost, consumableCostsToReconcile]);

      associatedDetail.Consumable_Cost__c = consumableCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ConsumableCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ConsumableCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CutMethodCost: {
      const isImperialUOM = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial;

      let costConsumables: CutMethodLaborCosts;

      if(associatedLine.PlateMethod__c !== PlateMethods.ManuallySized) {
        costConsumables  = calculateCuttingCosts(associatedLine.Cut_Method__c, baseMetal, isImperialUOM, manufacturingSite, finishedLength, finishedWidth, associatedDetail.Clads__c, totalRawTK, loadedQuoteVariableConfigurations, associatedDetail.Quantity__c, associatedLine.Shape__c);
      } else {
        costConsumables  = calculateCuttingCosts(associatedLine.Cut_Method__c, baseMetal, isImperialUOM, manufacturingSite, baseLength, baseWidth, associatedDetail.Clads__c, totalRawTK, loadedQuoteVariableConfigurations, associatedDetail.Quantity__c, associatedLine.Shape__c);
      }

      associatedDetail.Cut_Method_Labor_hrs__c = costConsumables.hours;
      associatedDetail.Cut_Method_Cost__c = costConsumables.cutCost;
      associatedDetail.Total_Cutting_Cost__c = costConsumables.totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CutMethodCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CutMethodCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PostCladCuttingTolerance: {
      const fieldToRecalculate = QuoteLineItemValueTypes.PostCladCuttingTolerance;
      const description = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? QuoteVariableConfigDescriptions.CuttingValuePhraseMetric : QuoteVariableConfigDescriptions.CuttingValuePhraseImperial;

      const valuePhrase = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, description, manufacturingSite, FieldTypes.Text) as string;
      const notes = getDefaultCuttingToleranceNotes(itemType, associatedLine.Cut_Method__c, totalTK, finishedLength, associatedLine.Unit_of_Measure__c, valuePhrase);

      associatedDetail.PostCladCuttingTolerance__c = notes;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PostCladCuttingTolerance);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, fieldToRecalculate, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PricePerWeight: {
      const fieldToRecalculate = QuoteLineItemValueTypes.PricePerWeight;

      let weightKg;
      let weightLb;
      if(associatedLine.PlateMethod__c !== PlateMethods.ManuallySized) {
        weightKg = associatedLine.Total_KG_PC__c * associatedLine.Quantity__c;
        weightLb = associatedLine.Total_LB_PC__c * associatedLine.Quantity__c;
      } else {
        weightKg = calculateTotalWeightPerPiece(associatedDetail.Clad_Weight_Kg__c, associatedDetail.Base_Weight_Kg__c) * associatedLine.Quantity__c;
        weightLb = calculateTotalWeightPerPiece(associatedDetail.Clad_Weight_Lbs__c, associatedDetail.Base_Weight_Lbs__c) * associatedLine.Quantity__c;
      }

      const pricePerKg = associatedLine.TotalAdjPrice__c / weightKg;
      const pricePerLb = associatedLine.TotalAdjPrice__c / weightLb;

      associatedLine.Price_LB__c = pricePerLb;
      associatedLine.Price_KG__c = pricePerKg;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PricePerWeight);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, fieldToRecalculate, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FlatteningNotes: {
      if (itemType.name === FinishedProductTypes.Plate || itemType.name === FinishedProductTypes.Tubesheet) {
        let description: QuoteVariableConfigDescriptions;
        let thickness: number = totalTK;

        if (itemType.name === FinishedProductTypes.Plate) {
          description =
            associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric
              ? QuoteVariableConfigDescriptions.FlatteningPlateMetric
              : QuoteVariableConfigDescriptions.FlatteningPlateImperial;
        } else {
          description =
            associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric
              ? QuoteVariableConfigDescriptions.FlatteningTubesheetMetric
              : QuoteVariableConfigDescriptions.FlatteningTubesheetImperial;

          thickness = baseFlatteningThinningValue;
        }

        const fieldToRecalculate = QuoteLineItemValueTypes.FlatteningNotes;

        const valuePhrase = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, description, manufacturingSite, FieldTypes.Text) as string;
        const notes = getDefaultFlatteningNotes(itemType, associatedLine.Unit_of_Measure__c, thickness, valuePhrase);

        associatedDetail.FlatteningNotes__c = notes;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlatteningNotes);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, fieldToRecalculate, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
    }
      break;
    }
    case QuoteLineItemValueTypes.MachiningNotes: {
      if (itemType.name !== FinishedProductTypes.Cylinder && finishedLength) {
        const fieldToRecalculate = QuoteLineItemValueTypes.MachiningNotes;
        const description = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? QuoteVariableConfigDescriptions.MachiningTubesheetMetric : QuoteVariableConfigDescriptions.MachiningTubesheetImperial;

        const valuePhrase = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, description, manufacturingSite, FieldTypes.Text) as string;
        const notes = getDefaultMachiningNotes(itemType, associatedLine.Unit_of_Measure__c, finishedLength, valuePhrase);

        associatedDetail.MachiningNotes__c = notes;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.MachiningNotes);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, fieldToRecalculate, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.PostCladFlattening: {
      const fieldToRecalculate = QuoteLineItemValueTypes.PostCladFlattening;
      const selection = getDefaultFlatteningSelection(itemType);

      associatedDetail.PostCladFlattening__c = selection;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PostCladFlattening);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, fieldToRecalculate, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.Dimensions: {
      const dimensionsIn = getDimensions(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, finishedWidth, finishedLength, itemType.name, headDiameter, associatedDetail.NC_Head_Item_Detail__r?.IDorOD__c, associatedDetail.NC_Head_Item_Detail__r?.HeadFormingType__c, associatedDetail?.NC_Cylinder_Item_Details__r, associatedLine.Shape__c);
      const dimensionsMm = getDimensions(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, finishedWidth, finishedLength, itemType.name, headDiameter, associatedDetail.NC_Head_Item_Detail__r?.IDorOD__c, associatedDetail.NC_Head_Item_Detail__r?.HeadFormingType__c, associatedDetail?.NC_Cylinder_Item_Details__r, associatedLine.Shape__c);

      associatedLine.Dimensions_in__c = dimensionsIn;
      associatedLine.Dimensions_mm__c = dimensionsMm;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.Dimensions);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.Dimensions, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ItemDescription: {
      const itemDescriptionIn = getItemDescription(associatedLine.Dimensions_in__c, itemType.name, associatedDetail.NC_Cylinder_Item_Details__r?.CylinderType__c);
      const itemDescriptionMm = getItemDescription(associatedLine.Dimensions_mm__c, itemType.name, associatedDetail.NC_Cylinder_Item_Details__r?.CylinderType__c);

      associatedLine.ItemDescriptionImp__c = itemDescriptionIn;
      associatedLine.ItemDescriptionMetric__c = itemDescriptionMm;

      // set uncut item descriptions
      if (associatedLine.Cut_Method__c === CutMethods.Uncut) {
        const baseWidthImperial = associatedDetail.Base_Width_in__c.toFixed(3);
        const baseWidthMetric = associatedDetail.Base_Width_mm__c.toFixed(0);

        // Remove dash and anything that follows it
        const cleanedItemDescriptionIn = isForging ? `${baseWidthImperial} - ${DiameterUOMTypes.OD}` : `${baseWidthImperial} X ${associatedDetail.Base_Length_in__c.toFixed(3)}`;
        const cleanedItemDescriptionMm = isForging ? `${baseWidthMetric} - ${DiameterUOMTypes.OD}` : `${baseWidthMetric} X ${associatedDetail.Base_Length_mm__c.toFixed(0)}`;

        associatedLine.UncutItemDescriptionImperial__c = cleanedItemDescriptionIn;
        associatedLine.UncutItemDescriptionMetric__c = cleanedItemDescriptionMm;
      }

      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ItemDescription);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ItemDescription, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.DetailQuantity: {
      associatedDetail.Quantity__c = associatedDetail.Wide__c * associatedDetail.Long__c;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.DetailQuantity);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.DetailQuantity, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TotalRawTK: {
      const totalRawTK = baseThickness + cladThickness;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TotalRawTK);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.TotalRawTkIn, calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, totalRawTK, Units.Millimeters, Units.Inches));
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.TotalRawTkMm, calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, totalRawTK, Units.Millimeters, Units.Inches));

      associatedLine.TotalThickness__c = associatedDetail.Base_TK_in__c + associatedDetail.Clad_TK_in__c;
      associatedLine.TotalThicknessMM__c = associatedDetail.Base_TK_mm__c + associatedDetail.Clad_TK_mm__c;

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TotalRawTK, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FlatteningLaborHours: {
        const flatteningLabor = calculateFlatteningLabor(itemType, associatedLine.Unit_of_Measure__c, squareFinishedArea, totalRawTK);

        associatedDetail.FlatteningLaborHours__c = flatteningLabor;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlatteningLaborHours);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlatteningLaborHours, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);


      break;
    }
    case QuoteLineItemValueTypes.FlatteningLaborCost: {
      if (associatedLine.PlateMethod__c !== PlateMethods.ManuallySized) {
        const flatteningCost = calculateLaborCost(associatedLine.Quantity__c, cladMetal, manufacturingSite, LaborTypes.FlatteningLabor, associatedDetail.FlatteningLaborHours__c);

        associatedDetail.FlatteningLaborCost__c = flatteningCost;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlatteningLaborCost);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlatteningLaborCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      } else {
        const flatteningCost = combinedLines.reduce((acc, combo) =>
          acc + combo.reduce((subtotal, lineItem) =>
          subtotal + calculateLaborCost(lineItem.Quantity__c, cladMetal, manufacturingSite, LaborTypes.FlatteningLabor, lineItem.quoteLineItemDetail.FlatteningLaborHours__c), 0), 0);

        associatedDetail.FlatteningLaborCost__c = flatteningCost;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlatteningLaborCost);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlatteningLaborCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }

      break;
    }

    case QuoteLineItemValueTypes.TotalCost: {
      const totalCost = calculateTotalCost(cladCost, cladFreight, baseCost, baseFreight, associatedLine.Anvil__c, welding, headForming, headFreight, osCost, explosiveCost, consumableCost, cutMethodCost, totalLaborCost, associatedDetail.Welding_Consumable_Cost__c, associatedLine.CertificateCost__c, associatedLine.Quantity__c, associatedDetail.Clads__c, consumableCostsToReconcile, freightOut);

      associatedLine.TotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.UnitCost: {
      const unitCost = calculateUnitCost(associatedLine.PlateMethod__c, finishedArea, associatedLine.TotalCost__c, associatedLine.Quantity__c, associatedDetail.TotalFinishedGoodArea__c);

      associatedLine.UnitCost__c = unitCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.UnitCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.UnitCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TotalAdjPrice: {
      associatedLine.TotalAdjPrice__c = associatedLine.Adj_Price__c * associatedLine.Quantity__c;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TotalAdjPrice);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TotalAdjPrice, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.AdjPrice: {
      const adjustedPrice = calculateAdjPrice(associatedLine.UnitCost__c, contributionPercent, agentComRate);

      associatedLine.Adj_Price__c = Math.round(adjustedPrice);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AdjPrice);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AdjPrice, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ContPercentage: {
      const fieldToRecalculate = QuoteLineItemValueTypes.ContPercentage;

      const updatedContPercentage = calculateContPercent(associatedLine.UnitCost__c, agentComRate, associatedLine.Adj_Price__c);

      associatedLine.Contribution__c = updatedContPercentage;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ContPercentage);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, fieldToRecalculate, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.AdjPrice) ? QuoteLineItemValueTypes.AdjPrice : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ContPerSQM: {
      const contPerSQM = calculateContSqm(contributionPercent, associatedLine.Adj_Price__c, associatedDetail.Area_Sqm__c);

      associatedLine.Cont_SQM__c = contPerSQM;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ContPerSQM);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ContPerSQM, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TestingBKTotalCost: {
      const totalCost = [testingBKCostOfEvent, testingBKTotalInboundShippingCost, testingBKTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.TestingBKTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TestingBKTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TestingBKTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TestingBKInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c, associatedLine.Quantity__c, testingBKTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.TestingBKInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TestingBKInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TestingBKInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TestingBKOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c, associatedLine.Quantity__c, testingBKTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.TestingBKOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TestingBKOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TestingBKOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TestingCLTotalCost: {
      const totalCost = [testingCLCostOfEvent, testingCLTotalInboundShippingCost, testingCLTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.TestingCLTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TestingCLTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TestingCLTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TestingCLInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, testingCLTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.TestingCLInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TestingCLInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TestingCLInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TestingCLOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, testingCLTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.TestingCLOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TestingCLOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TestingCLOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TestingTotalCost: {
      const totalCost = [testingCostOfEvent, testingTotalInboundShippingCost, testingTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.TestingTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TestingTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TestingTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TestingInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, testingTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.TestingInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TestingInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TestingInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TestingOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, testingTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.TestingOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TestingOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TestingOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BackerMetalHTTotalCost: {
      const totalCost = [backerMetalHTTotalCostOfEvent, backerMetalHTTotalInboundShippingCost, backerMetalHTTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BackerMetalHTTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BackerMetalHTTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BackerMetalHTCostOfEvent: {
      const costOfEventPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c, associatedLine.Quantity__c, backerMetalHTTotalCostOfEvent);

      associatedDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTCostOfEvent__c = costOfEventPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BackerMetalHTCostOfEvent);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BackerMetalHTCostOfEvent, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BackerMetalHTInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c, associatedLine.Quantity__c, backerMetalHTTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BackerMetalHTInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BackerMetalHTInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BackerMetalHTOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c, associatedLine.Quantity__c, backerMetalHTTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BackerMetalHTOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BackerMetalHTOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladderMetalHTTotalCost: {
      const totalCost = [cladderMetalHTTotalCostOfEvent, cladderMetalHTTotalInboundShippingCost, cladderMetalHTTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladderMetalHTTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladderMetalHTTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladderMetalHTCostOfEvent: {
      const costOfEventPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, cladderMetalHTTotalCostOfEvent);

      associatedDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTCostOfEvent__c = costOfEventPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladderMetalHTCostOfEvent);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladderMetalHTCostOfEvent, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladderMetalHTInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, cladderMetalHTTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladderMetalHTInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladderMetalHTInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladderMetalHTOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, cladderMetalHTTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladderMetalHTOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladderMetalHTOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PostbondHTTotalCostOfEvent: {
      if(manufacturingSite === ManufacturingSites.DE01) {
        const hasPostbondOS = someOsServicesFieldExists(OutsideServices.PostbondHeatTreatment, associatedDetail);
        if(hasPostbondOS) {
          const postBondHTTotalCostofEvent = calculateTotalCostOfEvent(associatedLine.Total_KG_PC__c * associatedLine.Quantity__c || 0, OutsideServices.PostbondHeatTreatment, loadedOutsideServiceCost, undefined);
          associatedDetail.NC_Outside_Service_Item_Details__r.PostbondHTTotalCostOfEvent__c = postBondHTTotalCostofEvent;
          associatedDetail.NC_Outside_Service_Item_Details__r.PostbondHTTotalCost__c = postBondHTTotalCostofEvent;
          dependentFieldsUpdated.push(QuoteLineItemValueTypes.PostbondHTTotalCostOfEvent);
          recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PostbondHTTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
        }
      }
      break;
    }

    case QuoteLineItemValueTypes.XRayServicesCostOfEvent: {
      if(manufacturingSite === ManufacturingSites.DE01) {
        const hasXrayOS = someOsServicesFieldExists(OutsideServices.XRayServices, associatedDetail);
        if(hasXrayOS) {
          const xrayCostOfEvent = calculateTotalCostOfEvent(associatedLine.Welding_Length_mm__c || 0, OutsideServices.XRayServices, loadedOutsideServiceCost, {weldType: associatedDetail.Radiography_Clad__c, clads: associatedDetail.Clads__c});
          associatedDetail.NC_Outside_Service_Item_Details__r.XRayServicesCostOfEvent__c = xrayCostOfEvent;
          associatedDetail.NC_Outside_Service_Item_Details__r.XRayServicesTotalCost__c = xrayCostOfEvent;
          dependentFieldsUpdated.push(QuoteLineItemValueTypes.XRayServicesCostOfEvent);
          recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.XRayServicesCostOfEvent, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
        }
      }
      break;
    }

    case QuoteLineItemValueTypes.PostbondHTTotalCost: {
      const totalCost = manufacturingSite === ManufacturingSites.DE01
      ? associatedDetail.NC_Outside_Service_Item_Details__r.PostbondHTTotalCostOfEvent__c
      : [postbondHTTotalCostOfEvent, postbondHTTotalInboundShippingCost, postbondHTTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.PostbondHTTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PostbondHTTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PostbondHTTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PostbondHTCostOfEvent: {
      const costOfEventPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, postbondHTTotalCostOfEvent);
      associatedDetail.NC_Outside_Service_Item_Details__r.PostbondHTCostOfEvent__c = costOfEventPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PostbondHTCostOfEvent);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PostbondHTCostOfEvent, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PostbondHTInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, postbondHTTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.PostbondHTInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PostbondHTInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PostbondHTInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PostbondHTOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, postbondHTTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.PostbondHTOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PostbondHTOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PostbondHTOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.WeldingTotalCost: {
      const totalCost = [weldingCostOfEvent, weldingTotalInboundShippingCost, weldingTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.WeldingTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.WeldingTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.WeldingTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.WeldingInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, weldingTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.WeldingInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.WeldingInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.WeldingInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.WeldingOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, weldingTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.WeldingOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.WeldingOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.WeldingOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.XRayServicesTotalCost: {
      const totalCost = [xRayServicesCostOfEvent, xRayServicesTotalInboundShippingCost, xRayServicesTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.XRayServicesTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.XRayServicesTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.XRayServicesTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.XRayServicesInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, xRayServicesTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.XRayServicesInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.XRayServicesInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.XRayServicesInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.XRayServicesOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, xRayServicesTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.XRayServicesOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.XRayServicesOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.XRayServicesOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.XRayServicesDescriptionOfEvent: {
      if(manufacturingSite === ManufacturingSites.DE01) {
        if(welding) {
          associatedDetail.NC_Outside_Service_Item_Details__r.XRayServicesDescriptionOfEvent__c = OutsideServices.XRayServices;
        } else {
          associatedDetail.NC_Outside_Service_Item_Details__r.XRayServicesDescriptionOfEvent__c = null;
        }

        dependentFieldsUpdated.push(QuoteLineItemValueTypes.XRayServicesDescriptionOfEvent);
        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.XRayServicesDescriptionOfEvent, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.XRayServicesSort: {
      if(manufacturingSite === ManufacturingSites.DE01) {
        if(welding) {
          associatedDetail.NC_Outside_Service_Item_Details__r.XRayServicesSort__c = 1;
        } else {
          associatedDetail.NC_Outside_Service_Item_Details__r.XRayServicesSort__c = null;
        }

        dependentFieldsUpdated.push(QuoteLineItemValueTypes.XRayServicesSort);
        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.XRayServicesSort, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.FlatteningBKTotalCost: {
      const totalCost = [flatteningBKCostOfEvent, flatteningBKTotalInboundShippingCost, flatteningBKTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.FlatteningBKTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlatteningBKTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlatteningBKTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FlatteningBKInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c, associatedLine.Quantity__c, flatteningBKTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.FlatteningBKInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlatteningBKInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlatteningBKInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FlatteningBKOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c, associatedLine.Quantity__c, flatteningBKTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.FlatteningBKOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlatteningBKOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlatteningBKOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CuttingTotalCost: {
      const totalCost = [cuttingCostOfEvent, cuttingTotalInboundShippingCost, cuttingTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.CuttingTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CuttingTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CuttingTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CuttingInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, cuttingTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.CuttingInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CuttingInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CuttingInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CuttingOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, cuttingTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.CuttingOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CuttingOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CuttingOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.WaterJetCuttingTotalCost: {
      const totalCost = [waterJetCuttingCostOfEvent, waterJetCuttingTotalInboundShippingCost, waterJetCuttingTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.WaterJetCuttingTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.WaterJetCuttingTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.WaterJetCuttingInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, waterJetCuttingTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.WaterJetCuttingInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.WaterJetCuttingInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.WaterJetCuttingOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, waterJetCuttingTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.WaterJetCuttingOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.WaterJetCuttingOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FlatteningTotalCost: {
      const totalCost = [flatteningCostOfEvent, flatteningTotalInboundShippingCost, flatteningTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.FlatteningTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlatteningTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlatteningTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FlatteningInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, flatteningTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.FlatteningInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlatteningInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlatteningInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.FlatteningOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, flatteningTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.FlatteningOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.FlatteningOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.FlatteningOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.MachiningTotalCost: {
      const totalCost = [machiningCostOfEvent, machiningTotalInboundShippingCost, machiningTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.MachiningTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.MachiningTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.MachiningTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.MachiningInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, machiningTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.MachiningInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.MachiningInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.MachiningInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.MachiningOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, machiningTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.MachiningOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.MachiningOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.MachiningOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.InspectionBKTotalCost: {
      const totalCost = [inspectionBKCostOfEvent, inspectionBKTotalInboundShippingCost, inspectionBKTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.InspectionBKTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.InspectionBKTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.InspectionBKTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.InspectionBKInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c, associatedLine.Quantity__c, inspectionBKTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.InspectionBKInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.InspectionBKInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.InspectionBKInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.InspectionBKOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c, associatedLine.Quantity__c, inspectionBKTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.InspectionBKOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.InspectionBKOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.InspectionBKOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.InspectionCLTotalCost: {
      const totalCost = [inspectionCLCostOfEvent, inspectionCLTotalInboundShippingCost, inspectionCLTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.InspectionCLTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.InspectionCLTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.InspectionCLTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.InspectionCLInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, inspectionCLTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.InspectionCLInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.InspectionCLInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.InspectionCLInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.InspectionCLOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c, associatedLine.Quantity__c, inspectionCLTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.InspectionCLOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.InspectionCLOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.InspectionCLOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.InspectionTotalCost: {
      const totalCost = [inspectionCostOfEvent, inspectionTotalInboundShippingCost, inspectionTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.InspectionTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.InspectionTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.InspectionTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.InspectionInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, inspectionTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.InspectionInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.InspectionInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.InspectionInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.InspectionOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, inspectionTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.InspectionOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.InspectionOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.InspectionOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PackagingTotalCost: {
      const totalCost = [packagingCostOfEvent, packagingTotalInboundShippingCost, packagingTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.PackagingTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PackagingTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PackagingTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PackagingInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, packagingTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.PackagingInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PackagingInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PackagingInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PackagingOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, packagingTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.PackagingOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PackagingOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PackagingOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CanRollingTotalCost: {
      const totalCost = [canRollingCostOfEvent, canRollingTotalInboundShippingCost, canRollingTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.CanRollingTotalCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CanRollingTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CanRollingTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CanRollingInboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, canRollingTotalInboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.CanRollingInboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CanRollingInboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CanRollingInboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CanRollingOutboundShippingCost: {
      const shippingCostPerWeightUnit = calculateCostPerWeightUnit(associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Total_LB_PC__c : associatedLine.Total_KG_PC__c, associatedLine.Quantity__c, canRollingTotalOutboundShippingCost);

      associatedDetail.NC_Outside_Service_Item_Details__r.CanRollingOutboundShippingCost__c = shippingCostPerWeightUnit;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CanRollingOutboundShippingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CanRollingOutboundShippingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.HeadFormingSubcontractingCost: {
      const totalCost = [headFormingCostPerHead, headFormingTotalInboundShippingCost, headFormingTotalOutboundShippingCost].sum();

      associatedDetail.NC_Outside_Service_Item_Details__r.HeadFormingSubcontractingCost__c = totalCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeadFormingSubcontractingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeadFormingSubcontractingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.OSCost: {
      const shipWeight = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedLine.Ship_Weight_KG__c : associatedLine.Ship_Weight_LB__c;

      let allOsCosts = [
        testingBKTotalCost,
        testingCLTotalCost,
        testingTotalCost,
        backerMetalHTTotalCost,
        cladderMetalHTTotalCost,
        postbondHTTotalCost,
        weldingTotalCost,
        xRayServicesTotalCost,
        flatteningBKTotalCost,
        cuttingTotalCost,
        waterJetCuttingTotalCost,
        flatteningTotalCost,
        machiningTotalCost,
        inspectionBKTotalCost,
        inspectionCLTotalCost,
        inspectionTotalCost,
        packagingTotalCost,
        canRollingTotalCost,
        headFormingSubcontractingCost
      ].sum();

      if(itemType.name === FinishedProductTypes.Cylinder) {
        const cylinderCosts = (associatedDetail.NC_Cylinder_Item_Details__r?.RollingCostPerCylinder__c * associatedLine.Quantity__c) + (associatedDetail.NC_Cylinder_Item_Details__r?.FreightChargeToCustomerPer__c * shipWeight) + (associatedDetail.NC_Cylinder_Item_Details__r?.FreightChargeToRollerPer__c * shipWeight);
        allOsCosts += cylinderCosts;
      }

      associatedLine.OS_Cost__c = allOsCosts;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.OSCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.OSCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TotalManHours: {
      let manHoursArray = [];
      if (heatTreatmentCost > 0) {
        const heatTreatmentManHoursPerClad = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.HeatTreatmentHoursRequiredPerClad, manufacturingSite, FieldTypes.Float) as number;
        manHoursArray = [heatTreatmentManHoursPerClad, associatedDetail.SpecificationTestLaborHours__c, associatedDetail.FlatteningLaborHours__c, associatedDetail.NC_Tubesheet_Item_Details__r?.TubesheetMachiningManHours__c];
      } else {
        manHoursArray = [associatedDetail.SpecificationTestLaborHours__c, associatedDetail.FlatteningLaborHours__c, associatedDetail.NC_Tubesheet_Item_Details__r?.TubesheetMachiningManHours__c];
      }

      associatedLine.TotalManHours__c = manHoursArray.sum();
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TotalManHours);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TotalManHours, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TotalLaborCost: {
      //each indvidual labor cost accounts for quantity
      const laborCostsArray = [heatTreatmentCost, specificationTestCost, flatteningCost, machiningCost, associatedLine.CosmeticPrepCost__c, associatedLine.ExportPackagingCost__c, generalLaborCost];

      associatedLine.TotalLaborCost__c = laborCostsArray.sum();
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TotalLaborCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TotalLaborCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.TubesheetMachiningManHours: {
      // machining only exists for tubesheets
      if (itemType.name === FinishedProductTypes.Tubesheet) {
        const laborHours = calculateMachiningHours(associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric, associatedLine.Quantity__c ?? 1, finishedWidth, associatedLine.TubesheetMachining__c, loadedQuoteVariableConfigurations, manufacturingSite);

        associatedDetail.NC_Tubesheet_Item_Details__r.TubesheetMachiningManHours__c = laborHours;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.TubesheetMachiningManHours);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TubesheetMachiningManHours, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.TubesheetMachiningTotalCost: {
      const machiningLaborRate = getLaborRate(cladMetal, manufacturingSite, LaborTypes.Machining);
      const machiningCost = associatedDetail.NC_Tubesheet_Item_Details__r?.TubesheetMachiningManHours__c * machiningLaborRate;

      associatedDetail.NC_Tubesheet_Item_Details__r.TubesheetMachiningTotalCost__c = machiningCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.TubesheetMachiningTotalCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.TubesheetMachiningTotalCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.ExportPackagingCost: {
      const isMetricUOM = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric;
      const weight = isMetricUOM ? associatedLine.Total_KG_PC__c : associatedLine.Total_LB_PC__c;

      // if export packaging is not enabled, set ExportPackingCost to 0, otherwise calculate
      const exportPackingCost = hasExportPackaging ? calculateExportPackingCost(isMetricUOM, weight, loadedQuoteVariableConfigurations, baseMetal, manufacturingSite, associatedLine.Quantity__c) : 0;

      associatedLine.ExportPackagingCost__c = exportPackingCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ExportPackagingCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ExportPackagingCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CosmeticPrepCost: {
      const isMetricUOM = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric;
      const cosmeticGrindingCost = cosmeticGrinding ? calculateCosmeticGrindingCost(isMetricUOM, cladMetal, cladArea, loadedQuoteVariableConfigurations, manufacturingSite, associatedDetail.Clads__c) : 0;

      associatedLine.CosmeticPrepCost__c = cosmeticGrindingCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CosmeticPrepCost);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CosmeticPrepCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.LeadTime: {
      let leadTime;
      if(associatedLine.Proposal_Type_Base__c === MetalProposalTypes.DeliveryBest && associatedLine.Proposal_Type_Clad__c === MetalProposalTypes.DeliveryBest) {
        switch(itemType.name) {
          case FinishedProductTypes.Plate:
          case FinishedProductTypes.Tubesheet:
            leadTime = StandardLeadTimes.EightWeeks;
            break;
          case FinishedProductTypes.Head:
          case FinishedProductTypes.Cylinder:
            leadTime = StandardLeadTimes.FifteenWeeks;
        }
      } else {
        leadTime = StandardLeadTimes.EighteenWeeks;
      }

      associatedLine.Lead_Time__c = leadTime;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.LeadTime);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.LeadTime, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.StressReliefHT: {
      associatedDetail.StressReliefHT__c = heatTreatment;

      dependentFieldsUpdated.push(QuoteLineItemValueTypes.StressReliefHT);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.StressReliefHT, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.HeatTreatCombinationGroup: {
      const heatTreatCombinationGroup = getHeatTreatCombinationGroup(loadedRawMaterialCombinations, baseMetal, cladMetal, manufacturingSite);
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.HeatTreatCombinationGroup, heatTreatCombinationGroup);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeatTreatCombinationGroup);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeatTreatCombinationGroup, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.SimulateHeatTreatmentHeadForming: {
      if(itemType.name === FinishedProductTypes.Head) {
        associatedDetail.SimulateHeatTreatmentHeadForming__c = getStressReliefDetails(associatedLine.Unit_of_Measure__c, associatedDetail.NCInhouseStressRefliefTimeMin__c, associatedDetail.NCInHouseStressReliefTimeMax__c, associatedDetail.NCInhouseStressReliefTemp__c);

        dependentFieldsUpdated.push(QuoteLineItemValueTypes.SimulateHeatTreatmentHeadForming);
        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.SimulateHeatTreatmentHeadForming, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }

      break;
    }
    case QuoteLineItemValueTypes.PreHeatTimingGroup: {
      const preHeatTimingGroup = getPreHeatTimingGroup(heatTreatCombinationGroup, associatedLine.Unit_of_Measure__c, totalTK);
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.PreHeatTimingGroup, preHeatTimingGroup);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PreHeatTimingGroup);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PreHeatTimingGroup, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.NCInhouseStressReliefTimeMin: {
      associatedDetail.NCInhouseStressRefliefTimeMin__c = associatedDetail.StressReliefHT__c ? preHeatTimingGroup?.minMinutes : null;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.NCInhouseStressReliefTimeMin);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.NCInhouseStressReliefTimeMin, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.NCInHouseStressReliefTimeMax: {
      associatedDetail.NCInHouseStressReliefTimeMax__c = associatedDetail.StressReliefHT__c ? preHeatTimingGroup?.maxMinutes : null;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.NCInHouseStressReliefTimeMax);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.NCInHouseStressReliefTimeMax, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.NCInhouseStressReliefTemp: {
      associatedDetail.NCInhouseStressReliefTemp__c = associatedDetail.StressReliefHT__c && heatTreatCombinationGroup ? heatTreatCombinationGroup[associatedLine.Unit_of_Measure__c.toLowerCase()].temperature : null;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.NCInhouseStressReliefTemp);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.NCInhouseStressReliefTemp, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.NCInhouseStressReliefDetails: {
      if(itemType.name !== FinishedProductTypes.Head) {
        associatedDetail.NCInhouseStressReliefDetails__c = getStressReliefDetails(associatedLine.Unit_of_Measure__c, associatedDetail.NCInhouseStressRefliefTimeMin__c, associatedDetail.NCInHouseStressReliefTimeMax__c, associatedDetail.NCInhouseStressReliefTemp__c);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.NCInhouseStressReliefDetails);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.NCInhouseStressReliefDetails, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }

      break;
    }
    case QuoteLineItemValueTypes.BaseNom: {
      associatedLine.Base_Nom_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, baseThickness,  Units.Millimeters, Units.Inches);
      associatedLine.Base_Nom_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, baseThickness,  Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseNom);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseNom, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseMin: {
      let baseMin;

      if(itemType.name === FinishedProductTypes.Head) {
        baseMin = 0;
      } else {
        switch(associatedLine.Base_Type__c) {
          case MetalTypes.NomToBuy:
            baseMin = finishedBaseMetalThickness - baseThicknessAddition;
            break;
          case MetalTypes.Minimum:
          default:
            baseMin = finishedBaseMetalThickness;
        }
      }

      associatedLine.Base_Min_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, baseMin,  Units.Millimeters, Units.Inches);
      associatedLine.Base_Min_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, baseMin,  Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseMin);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseMin, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladNom: {
      associatedLine.Clad_Nom_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, cladThickness,  Units.Millimeters, Units.Inches);
      associatedLine.Clad_Nom_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, cladThickness,  Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladNom);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladNom, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladMin: {
      let cladMin;

      if(itemType.name === FinishedProductTypes.Head) {
        cladMin = 0;
      } else {
        switch(associatedLine.Clad_Type__c) {
          case MetalTypes.NomToBuy:
            cladMin = finishedCladMetalThickness - cladThicknessAddition;
            break;
          case MetalTypes.Minimum:
          default:
            cladMin = finishedCladMetalThickness;
        }
      }

      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladMin);
      associatedLine.Clad_Min_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, cladMin,  Units.Millimeters, Units.Inches);
      associatedLine.Clad_Min_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, cladMin,  Units.Millimeters, Units.Inches);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladMin, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseFlatteningThinningGroup: {
      const baseFlatteningThinningGroup = getFlatteningThinningGroup(baseMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, totalTK, finishedWidth);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.BaseFlatteningThinningGroup, baseFlatteningThinningGroup);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseFlatteningThinningGroup);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseFlatteningThinningGroup, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladFlatteningThinningGroup: {
      const cladFlatteningThinningGroup = getFlatteningThinningGroup(cladMetal, manufacturingSite, associatedLine.Unit_of_Measure__c, totalTK, finishedWidth);

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.CladFlatteningThinningGroup, cladFlatteningThinningGroup);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladFlatteningThinningGroup);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladFlatteningThinningGroup, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.BaseFlatteningThinningValue: {
      const baseFlatteningThinningValue = associatedLine.Base_Type__c === MetalTypes.FandT ? baseFlatteningThinningGroup[associatedLine.Unit_of_Measure__c.toLowerCase()].ftFactorWidth : 0;

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.BaseFlatteningThinningValue, baseFlatteningThinningValue);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.BaseFlatteningThinningValue);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.BaseFlatteningThinningValue, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladFlatteningThinningValue: {
      const cladFlatteningThinningValue = associatedLine.Clad_Type__c === MetalTypes.FandT ? cladFlatteningThinningGroup[associatedLine.Unit_of_Measure__c.toLowerCase()].ftFactorWidth : 0;

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.CladFlatteningThinningValue, cladFlatteningThinningValue);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladFlatteningThinningValue);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladFlatteningThinningValue, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PriceHead: {
      if(!fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.PriceHead)) {
        associatedLine.Price_Head__c = headFormingCostPerHead;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.PriceHead);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PriceHead, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.HeadForming) ? QuoteLineItemValueTypes.HeadForming : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.HeadForming: {
      const pricePerHead = associatedLine.Price_Head__c || headFormingCostPerHead;
      associatedLine.Head_Forming__c = pricePerHead * associatedLine.Quantity__c;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeadForming);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeadForming, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.Length: {
      // dont recalculate dependent fields if the item type is not a Head or Cylinder or if reset was clicked
      if ((itemType.name === FinishedProductTypes.Head || itemType.name === FinishedProductTypes.Cylinder) && !reset) {
        associatedLine.Length_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, itemType.name === FinishedProductTypes.Head ? headDiameter : plateLengthSupplyToFormer, Units.Millimeters, Units.Inches);
        associatedLine.Length_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric,  itemType.name === FinishedProductTypes.Head ? headDiameter : plateLengthSupplyToFormer, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.Length);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.Length, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.Width: {
      // dont recalculate dependent fields if the item type is not a Head or Cylinder or if reset was clicked
      if ((itemType.name === FinishedProductTypes.Head || itemType.name === FinishedProductTypes.Cylinder) && !reset) {
        associatedLine.Width_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, itemType.name === FinishedProductTypes.Head ? headDiameter : plateWidthSupplyToFormer, Units.Millimeters, Units.Inches);
        associatedLine.Width_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, itemType.name === FinishedProductTypes.Head ? headDiameter : plateWidthSupplyToFormer, Units.Millimeters, Units.Inches);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.Width);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.Width, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.HeadFreight: {
      const headFreight = (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Head_Freight_LB__c * associatedLine.Ship_Weight_LB__c : associatedLine.Head_Freight_KG__c * associatedLine.Ship_Weight_KG__c);

      associatedLine.Head_Freight__c = headFreight;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeadFreight);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeadFreight, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.HeadFreightPerWeight) ? QuoteLineItemValueTypes.HeadFreightPerWeight : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.HeadFreightCostToFormer: {
      const headFreightCost = associatedLine.Unit_of_Measure__c === MeasurementSystems.Metric ? associatedLine.Head_Freight_KG__c : associatedLine.Head_Freight_LB__c;

      associatedDetail.NC_Outside_Service_Item_Details__r.HeadFreightCostToFormer__c = headFreightCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeadFreightCostToFormer);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeadFreightCostToFormer, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.HeadFreight) ? QuoteLineItemValueTypes.HeadFreight : undefined, saveMultipleFlag);
      break;
    }
    case QuoteLineItemValueTypes.CertificateCost: {
      let certificateCost = 0;
      switch(associatedLine.Certificate__c) {
        case QuoteLineItemCertificates.EN1020432Customer:
          certificateCost = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.CertificateCostEN1020432AllByCustomer, manufacturingSite, FieldTypes.Float) as number;
          break;
        case QuoteLineItemCertificates.EN1020432NobelClad: {
          //Add Testing Services-Clad as OS
          associatedDetail.NC_Outside_Service_Item_Details__r.TestingDescriptionOfEvent__c = OutsideServices.TestingServicesCladProduct;
          const testingTotalCostOfEvent = calculateTotalCostOfEvent(null, OutsideServices.TestingServicesCladProduct, undefined, undefined);
          associatedDetail.NC_Outside_Service_Item_Details__r.TestingCostOfEvent__c = testingTotalCostOfEvent;
          associatedDetail.NC_Outside_Service_Item_Details__r.TestingTotalCost__c = [testingTotalCostOfEvent, associatedDetail.NC_Outside_Service_Item_Details__r.TestingTotalInboundShippingCost__c || 0, associatedDetail.NC_Outside_Service_Item_Details__r.TestingTotalOutboundShippingCost__c || 0].sum();
          //Certificate Cost
          certificateCost = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, QuoteVariableConfigDescriptions.CertificateCostEN1020432AllByDMC, manufacturingSite, FieldTypes.Float) as number;
          break;
        }
      }
      associatedLine.CertificateCost__c = certificateCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CertificateCost);
      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CertificateCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladLength: {
      const cladLength = calculateCladDimension(baseLength, cladOverhang, cladOverhangThin);

      associatedDetail.Clad_Length_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, cladLength,  Units.Millimeters, Units.Inches);
      associatedDetail.Clad_Length_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, cladLength,  Units.Millimeters, Units.Inches);

      //setting/updating Purchase Clad Length along with raw base
      associatedDetail.PurchaseCladLength__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, cladLength, Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladLength);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladLength, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.CladWidth: {
      const cladWidth = calculateCladDimension(baseWidth, cladOverhang, cladOverhangThin);

      associatedDetail.Clad_Width_in__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Imperial, cladWidth,  Units.Millimeters, Units.Inches);
      associatedDetail.Clad_Width_mm__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, MeasurementSystems.Metric, cladWidth,  Units.Millimeters, Units.Inches);

      //setting/updating Purchase Clad Width along with raw base
      associatedDetail.PurchaseCladWidth__c = calculateUOMValue(associatedLine.Unit_of_Measure__c, associatedLine.Unit_of_Measure__c, cladWidth, Units.Millimeters, Units.Inches);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.CladWidth);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.CladWidth, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.NumberOfItemsWideThatFit: {
      if (!associatedLine.Pieces__c && kerfAllowance >= 0 && edgeAllowance >= 0 && boosterAllowance >= 0 && cladOverhang >= 0 && cladOverhangThin >= 0 && anvilEdgeAllowanceAddition >= 0) {
        const numberOfItemsWideThatFit = calculateNumberOfItemsWideThatFit(maximumWidth, finalWidth, kerfAllowance, edgeAllowance, boosterAllowance, cladOverhang, cladOverhangThin, anvilEdgeAllowanceAddition);

        dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.NumberOfItemsWideThatFit, numberOfItemsWideThatFit);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.NumberOfItemsWideThatFit);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.NumberOfItemsWideThatFit, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.NumberOfItemsLongThatFit: {
      if(kerfAllowance >= 0 && edgeAllowance >= 0 && specificationAdder >= 0 && baseMetalLengthAddition >= 0 && cladOverhang >= 0 && cladOverhangThin >= 0 && anvilEdgeAllowanceAddition >= 0) {
        const numberOfItemsLongThatFit = calculateNumberOfItemsLongThatFit(maximumLength, finalLength, kerfAllowance, edgeAllowance, specificationAdder, baseMetalLengthAddition, cladOverhang, cladOverhangThin, anvilEdgeAllowanceAddition);

        dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.NumberOfItemsLongThatFit, numberOfItemsLongThatFit);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.NumberOfItemsLongThatFit);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.NumberOfItemsLongThatFit, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.MaxQuantityOnClad: {
      if(numberOfItemsWideThatFit && numberOfItemsLongThatFit) {
        const maxQuantityOnClad = calculateMaxQuantityOnClad(numberOfItemsWideThatFit, numberOfItemsLongThatFit);

        dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.MaxQuantityOnClad, maxQuantityOnClad);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.MaxQuantityOnClad);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.MaxQuantityOnClad, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }

      break;
    }
    case QuoteLineItemValueTypes.Wide: {
      if(associatedLine.Pieces__c || (quantityForLeftoverCalculation && numberOfItemsWideThatFit)) {
        let numberOfItemsWide = 1;

        if(!associatedLine.Pieces__c) {
          if(numberOfItemsWideThatFit >= quantityForLeftoverCalculation) {
            numberOfItemsWide = quantityForLeftoverCalculation;
          } else {
            for(let i = numberOfItemsWideThatFit; i >= 1; i--) {
              if(quantityForLeftoverCalculation % i === 0) {
                numberOfItemsWide = i;
                break;
              }
            }
          }
        }

        associatedDetail.Wide__c = numberOfItemsWide;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.Wide);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.Wide, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }

      break;
    }
    case QuoteLineItemValueTypes.Long: {
      if(associatedLine.Pieces__c || (quantityForLeftoverCalculation && associatedDetail.Wide__c && maxQuantityOnClad)) {
        const numberOfItemsLong = associatedLine.Pieces__c ? 1 : Math.floor(Math.min(quantityForLeftoverCalculation, maxQuantityOnClad) / associatedDetail.Wide__c);

        associatedDetail.Long__c = numberOfItemsLong;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.Long);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.Long, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }

      break;
    }
    case QuoteLineItemValueTypes.ExtraCladRequired: {
      if(associatedDetail.Long__c && numberOfItemsLongThatFit && associatedDetail.Quantity__c && associatedDetail.Clads__c && quantityForLeftoverCalculation) {
        dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.ExtraCladRequired, determineExtraCladRequired(associatedDetail.Long__c, numberOfItemsLongThatFit, associatedDetail.Quantity__c, associatedDetail.Clads__c, quantityForLeftoverCalculation));
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.ExtraCladRequired);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ExtraCladRequired, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }

      break;
    }
    case QuoteLineItemValueTypes.QuantityForLeftoverCalculation: {
      //Special case, only runs when extraCladRequired gets updated in order to prevent infinite loops
      if(extraCladRequired) {
        let newQuantity;
        let leftOverQuantity;

        if((associatedDetail.Quantity__c * associatedDetail.Clads__c) < quantityForLeftoverCalculation) {
          newQuantity = associatedDetail.Quantity__c * associatedDetail.Clads__c;
          leftOverQuantity = quantityForLeftoverCalculation - newQuantity;
        } else {
          newQuantity = numberOfItemsLongThatFit;
          leftOverQuantity = quantityForLeftoverCalculation - newQuantity;
        }

        const updatedLeftoverQuantities = dependentUnwrittenFieldsMap.get(QuoteLineItemDependentUnwrittenFields.LeftoverQuantity) as number[] || [];
        updatedLeftoverQuantities.push(leftOverQuantity);

        dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.LeftoverQuantity, updatedLeftoverQuantities);

        dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.QuantityForLeftoverCalculation, newQuantity);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.QuantityForLeftoverCalculation);
        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.QuantityForLeftoverCalculation, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.SizingQuantity: {
      //Special case, only runs when extraCladRequired gets updated in order to prevent infinite loops
      if(!extraCladRequired && dependentUnwrittenFieldsMap.get(QuoteLineItemDependentUnwrittenFields.LeftoverQuantity) && !dependentUnwrittenFieldsMap.get(QuoteLineItemDependentUnwrittenFields.QuantityUpdatedForAutocombine)) {
        associatedLine.Quantity__c = quantityForLeftoverCalculation;
        dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.QuantityUpdatedForAutocombine, true);
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.SizingQuantity);
        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.SizingQuantity, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }
      break;
    }
    case QuoteLineItemValueTypes.Clads: {
      if(associatedLine.Pieces__c || (quantityForLeftoverCalculation && associatedDetail.Long__c && associatedDetail.Wide__c) || isTwoPieceHead) {
        let clads = associatedLine.Pieces__c ? associatedLine.Quantity__c : Math.floor(quantityForLeftoverCalculation / (associatedDetail.Long__c * associatedDetail.Wide__c));

        const headDiameterThresholdDescription = associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? QuoteVariableConfigDescriptions.TwoPieceHeadDiameterIN : QuoteVariableConfigDescriptions.TwoPieceHeadDiameterMM;
        const headDiameterThreshold = getQuoteVariableConfiguration(loadedQuoteVariableConfigurations, headDiameterThresholdDescription, manufacturingSite, FieldTypes.Float) as number;

        if (isTwoPieceHead && blankSize > headDiameterThreshold) {
          clads = 2 * associatedLine.Quantity__c;
        }

        associatedDetail.Clads__c = clads;

        associatedDetail.QuantityCL__c = clads;
        associatedDetail.QuantityBK__c = clads;
        associatedDetail.QuantityBacker__c = clads;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.Clads);

        recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.Clads, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      }

      break;
    }
    case QuoteLineItemValueTypes.QuantityAnvil: {
      if (associatedDetail.Anvil_Needed__c) {
        associatedDetail.NC_Anvil_Item_Details__r.QuantityAnvil__c = associatedDetail.Clads__c;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.QuantityAnvil);
      }

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.QuantityAnvil, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.QuantityFlyer: {
      if (associatedDetail.Flyer_Needed__c) {
        associatedDetail.NC_Flyer_Item_Details__r.QuantityFlyer__c = associatedDetail.Clads__c;
        dependentFieldsUpdated.push(QuoteLineItemValueTypes.QuantityFlyer);
      }
      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.QuantityFlyer, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.GeneralLaborCost: {
      const generalLaborCost = calculateGeneralLaborCost(associatedLine.Unit_of_Measure__c, manufacturingSite, cladMetal, cladArea, itemType, associatedDetail.Clads__c);

      associatedLine.GeneralLaborCost__c = generalLaborCost;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.GeneralLaborCost);
      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.GeneralLaborCost, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.HeadFreightPerWeight: {
      let headFreightPerPound;
      let headFreightPerKilogram;

      if(fieldManuallyUpdated(dependentFieldsUpdated, QuoteLineItemValueTypes.HeadFreight) || saveMultipleFlag) {
        headFreightPerPound = headFreight / associatedLine.Ship_Weight_LB__c;
        headFreightPerKilogram = headFreight / associatedLine.Ship_Weight_KG__c;
      } else {
        headFreightPerPound = associatedLine.Head_Freight_LB__c;
        headFreightPerKilogram = associatedLine.Head_Freight_KG__c;

        if(!calculatedFieldsEditedMap.get(lineItemId)?.includes(QuoteLineItemValueTypes.HeadFreightPerWeight) || reset) {
          const headFreightGroup = getFreightGroup(loadedFreight, associatedDetail.NC_Head_Item_Detail__r?.HeadFreightZone__c, associatedLine.Ship_Weight_LB__c, associatedLine.Ship_Weight_KG__c, manufacturingSite, associatedLine.Unit_of_Measure__c);
          headFreightPerPound = headFreightGroup?.imperial?.costPerUnit + headFreightGroup?.imperial?.wideAdderCostPerUnit;
          headFreightPerKilogram = headFreightGroup?.metric?.costPerUnit + headFreightGroup?.metric?.wideAdderCostPerUnit;
        }
      }

      associatedLine.Head_Freight_LB__c = headFreightPerPound;
      associatedLine.Head_Freight_KG__c = headFreightPerKilogram;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeadFreightPerWeight);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeadFreightPerWeight, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.IsForging: {
      const isForging = baseMetal.dmcClass.includes('/F');

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.IsForging, isForging);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.IsForging);
      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.IsForging, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    case QuoteLineItemValueTypes.PurchaseCladderPrice: {
      const purchaseCladderPrice = associatedLine.Customer_Provided_Clad__c ? 0 : ((associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_LB_PC__c : associatedLine.Clad_KG_PC__c) * (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Clad_Price_LB__c : associatedLine.Clad_Price_KG__c));

      associatedDetail.PurchaseCladderPrice__c = purchaseCladderPrice;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PurchaseCladderPrice);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PurchaseCladderPrice, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.PurchaseBackerPrice: {
      const purchaseBackerPrice = associatedLine.Customer_Provided_Base__c ? 0 : ((associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_LB_PC__c : associatedLine.Base_KG_PC__c) * (associatedLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? associatedLine.Base_Price_LB__c : associatedLine.Base_Price_KG__c));

      associatedDetail.PurchaseBackerPrice__c = purchaseBackerPrice;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PurchaseBackerPrice);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PurchaseBackerPrice, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.PurchaseAnvilPrice: {
      const purchaseAnvilPrice = associatedLine.Anvil__c / associatedDetail.Clads__c;

      associatedDetail.PurchaseAnvilPrice__c = purchaseAnvilPrice;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.PurchaseAnvilPrice);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.PurchaseAnvilPrice, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.HeatTreatment: {
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.HeatTreatment, heatTreatment);
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.HeatTreatment);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.HeatTreatment, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.AdditionalOptions: {
      let editedAdditionalOptions = additionalOptions;

      if(heatTreatment && !additionalOptions.includes(QuoteLineItemAdditionalOptions.HeatTreatment)) {
        editedAdditionalOptions.push(QuoteLineItemAdditionalOptions.HeatTreatment);
      }

      if ((cladMetal?.neverHeatTreatMetal === true || baseMetal?.neverHeatTreatMetal === true) && additionalOptions.includes(QuoteLineItemAdditionalOptions.HeatTreatment)) {
        editedAdditionalOptions = additionalOptions.filter(option => option !== QuoteLineItemAdditionalOptions.HeatTreatment);
      }

      associatedLine.Additional_Options__c = editedAdditionalOptions.join(';');
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.AdditionalOptions);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.AdditionalOptions, dependentFieldsUpdated.includes(QuoteLineItemValueTypes.HeatTreatment) ? QuoteLineItemValueTypes.HeatTreatment : undefined, saveMultipleFlag, reset, dependentFieldsUpdated);

      break;
    }
    case QuoteLineItemValueTypes.ASItemDescription: {
      const asItemDescription = `${totalRawTK} X ${associatedDetail.AS_Width__c} X ${associatedDetail.AS_Length__c}`;

      associatedDetail.ASItemDescription__c = asItemDescription;
      dependentFieldsUpdated.push(QuoteLineItemValueTypes.ASItemDescription);

      recalculateDependentFields(dataState, quote, associatedLine, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, QuoteLineItemValueTypes.ASItemDescription, undefined, saveMultipleFlag, reset, dependentFieldsUpdated);
      break;
    }
    }
  });
};

// Returns the proper UOM measurement based on the unit types
export const calculateUOMValue = (inputUOMType: MeasurementSystems, outputUOMType: MeasurementSystems, value: number, metricUnitType: Units, imperialUnitType: Units) => {
  switch(outputUOMType) {
  case MeasurementSystems.Imperial:
    switch(inputUOMType) {
    case MeasurementSystems.Imperial:
      return value;
    case MeasurementSystems.Metric:
      return convertUnits(value, metricUnitType, imperialUnitType);
    }
    break;
  case MeasurementSystems.Metric:
    switch(inputUOMType) {
    case MeasurementSystems.Metric:
      return value;
    case MeasurementSystems.Imperial:
      return convertUnits(value, imperialUnitType, metricUnitType);
    }
  }
};

export const getPurchaseDeltas = (
  qlid: QuoteLineItemDetail,
  lengthWidthTKDisplayFields: Record<string, number>,
  metal: MSTOAssemblyMetals,
  uom: MeasurementSystems
): { widthDelta: number; lengthDelta: number; tkDelta: number } => {
  let widthDelta = 0,
    lengthDelta = 0,
    tkDelta = 0;

  if (lengthWidthTKDisplayFields !== undefined && lengthWidthTKDisplayFields !== null) {
    const { DisplayWidth, DisplayLength, DisplayTK } = lengthWidthTKDisplayFields;

    switch (metal) {
      case MSTOAssemblyMetals.ANVIL:
        widthDelta = calculateUOMValue(qlid.Unit_of_Measure__c as MeasurementSystems, uom, qlid.PurchaseAnvilWidth__c - DisplayWidth, Units.Millimeters, Units.Inches);
        lengthDelta = calculateUOMValue(qlid.Unit_of_Measure__c as MeasurementSystems, uom, qlid.PurchaseAnvilLength__c - DisplayLength, Units.Millimeters, Units.Inches);
        tkDelta = calculateUOMValue(qlid.Unit_of_Measure__c as MeasurementSystems, uom, qlid.PurchaseAnvilTK__c - DisplayTK, Units.Millimeters, Units.Inches);
        break;
      case MSTOAssemblyMetals.BASE:
        widthDelta = calculateUOMValue(qlid.Unit_of_Measure__c as MeasurementSystems, uom, qlid.PurchaseBaseWidth__c - DisplayWidth, Units.Millimeters, Units.Inches);
        lengthDelta = calculateUOMValue(qlid.Unit_of_Measure__c as MeasurementSystems, uom, qlid.PurchaseBaseLength__c - DisplayLength, Units.Millimeters, Units.Inches);
        tkDelta = calculateUOMValue(qlid.Unit_of_Measure__c as MeasurementSystems, uom, qlid.PurchaseBaseTK__c - DisplayTK, Units.Millimeters, Units.Inches);
        break;
      case MSTOAssemblyMetals.CLAD:
      default:
        widthDelta = calculateUOMValue(qlid.Unit_of_Measure__c as MeasurementSystems, uom, qlid.PurchaseCladWidth__c - DisplayWidth, Units.Millimeters, Units.Inches);
        lengthDelta = calculateUOMValue(qlid.Unit_of_Measure__c as MeasurementSystems, uom, qlid.PurchaseCladLength__c - DisplayLength, Units.Millimeters, Units.Inches);
        tkDelta = calculateUOMValue(qlid.Unit_of_Measure__c as MeasurementSystems, uom, qlid.PurchaseCladTK__c - DisplayTK, Units.Millimeters, Units.Inches);
        break;
    }
  }

  return { widthDelta, lengthDelta, tkDelta };
};

// Returns field information based on the OS Service
export const getOSServicesFieldInfo = (outsideService: OutsideServices) => {
  switch(outsideService) {
    case OutsideServices.TestingServicesBacker:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.TestingBKDescriptionOfEvent,
          field: QuoteLineItemFields.TestingBKDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.TestingBKDurationOfEventDays,
          field: QuoteLineItemFields.TestingBKDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.TestingBKTotalTransitTimeDays,
          field: QuoteLineItemFields.TestingBKTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.TestingBKCostOfEvent,
          field: QuoteLineItemFields.TestingBKCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.TestingBKOutboundShippingCost,
          field: QuoteLineItemFields.TestingBKOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.TestingBKTotalOutboundShippingCost,
          field: QuoteLineItemFields.TestingBKTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.TestingBKInboundShippingCost,
          field: QuoteLineItemFields.TestingBKInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.TestingBKTotalInboundShippingCost,
          field: QuoteLineItemFields.TestingBKTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.TestingBKLeadTimeWeeks,
          field: QuoteLineItemFields.TestingBKLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.TestingBKTotalCost,
          field: QuoteLineItemFields.TestingBKTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.TestingBKNotes,
          field: QuoteLineItemFields.TestingBKNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.TestingBKVendor,
          field: QuoteLineItemFields.TestingBKVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.TestingBKShipToCustomer,
          field: QuoteLineItemFields.TestingBKShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.TestingBKSort
        }
      };
    case OutsideServices.TestingServicesCladder:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.TestingCLDescriptionOfEvent,
          field: QuoteLineItemFields.TestingCLDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.TestingCLDurationOfEventDays,
          field: QuoteLineItemFields.TestingCLDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.TestingCLTotalTransitTimeDays,
          field: QuoteLineItemFields.TestingCLTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.TestingCLCostOfEvent,
          field: QuoteLineItemFields.TestingCLCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.TestingCLOutboundShippingCost,
          field: QuoteLineItemFields.TestingCLOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.TestingCLTotalOutboundShippingCost,
          field: QuoteLineItemFields.TestingCLTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.TestingCLInboundShippingCost,
          field: QuoteLineItemFields.TestingCLInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.TestingCLTotalInboundShippingCost,
          field: QuoteLineItemFields.TestingCLTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.TestingCLLeadTimeWeeks,
          field: QuoteLineItemFields.TestingCLLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.TestingCLTotalCost,
          field: QuoteLineItemFields.TestingCLTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.TestingCLNotes,
          field: QuoteLineItemFields.TestingCLNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.TestingCLVendor,
          field: QuoteLineItemFields.TestingCLVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.TestingCLShipToCustomer,
          field: QuoteLineItemFields.TestingCLShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.TestingCLSort
        }
      };
    case OutsideServices.TestingServicesCladProduct:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.TestingDescriptionOfEvent,
          field: QuoteLineItemFields.TestingDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.TestingDurationOfEventDays,
          field: QuoteLineItemFields.TestingDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.TestingTotalTransitTimeDays,
          field: QuoteLineItemFields.TestingTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.TestingCostOfEvent,
          field: QuoteLineItemFields.TestingCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.TestingOutboundShippingCost,
          field: QuoteLineItemFields.TestingOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.TestingTotalOutboundShippingCost,
          field: QuoteLineItemFields.TestingTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.TestingInboundShippingCost,
          field: QuoteLineItemFields.TestingInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.TestingTotalInboundShippingCost,
          field: QuoteLineItemFields.TestingTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.TestingLeadTimeWeeks,
          field: QuoteLineItemFields.TestingLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.TestingTotalCost,
          field: QuoteLineItemFields.TestingTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.TestingNotes,
          field: QuoteLineItemFields.TestingNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.TestingVendor,
          field: QuoteLineItemFields.TestingVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.TestingShipToCustomer,
          field: QuoteLineItemFields.TestingShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.TestingSort
        }
      };
    case OutsideServices.BackerMetalHeatTreatment:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTDescriptionOfEvent,
          field: QuoteLineItemFields.BackerMetalHTDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTDurationOfEventDays,
          field: QuoteLineItemFields.BackerMetalHTDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTTotalTransitTimeDays,
          field: QuoteLineItemFields.BackerMetalHTTotalTransitTimeDays
        },
        costOfEventPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTCostOfEvent,
          field: QuoteLineItemFields.BackerMetalHTCostOfEvent
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTTotalCostOfEvent,
          field: QuoteLineItemFields.BackerMetalHTTotalCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTOutboundShippingCost,
          field: QuoteLineItemFields.BackerMetalHTOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTTotalOutboundShippingCost,
          field: QuoteLineItemFields.BackerMetalHTTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTInboundShippingCost,
          field: QuoteLineItemFields.BackerMetalHTInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTTotalInboundShippingCost,
          field: QuoteLineItemFields.BackerMetalHTTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTLeadTimeWeeks,
          field: QuoteLineItemFields.BackerMetalHTLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTTotalCost,
          field: QuoteLineItemFields.BackerMetalHTTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTNotes,
          field: QuoteLineItemFields.BackerMetalHTNotes
        },
        heatTreatmentVendor: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTVendor,
          field: QuoteLineItemFields.BackerMetalHTVendor
        },
        heatTreatmentType: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTType,
          field: QuoteLineItemFields.BackerMetalHTType
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.BackerMetalHTShipToCustomer,
          field: QuoteLineItemFields.BackerMetalHTShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.BackerMetalHTSort
        }
      };
    case OutsideServices.CladderMetalHeatTreatment:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTDescriptionOfEvent,
          field: QuoteLineItemFields.CladderMetalHTDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTDurationOfEventDays,
          field: QuoteLineItemFields.CladderMetalHTDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTTotalTransitTimeDays,
          field: QuoteLineItemFields.CladderMetalHTTotalTransitTimeDays
        },
        costOfEventPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTCostOfEvent,
          field: QuoteLineItemFields.CladderMetalHTCostOfEvent
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTTotalCostOfEvent,
          field: QuoteLineItemFields.CladderMetalHTTotalCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTOutboundShippingCost,
          field: QuoteLineItemFields.CladderMetalHTOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTTotalOutboundShippingCost,
          field: QuoteLineItemFields.CladderMetalHTTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTInboundShippingCost,
          field: QuoteLineItemFields.CladderMetalHTInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTTotalInboundShippingCost,
          field: QuoteLineItemFields.CladderMetalHTTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTLeadTimeWeeks,
          field: QuoteLineItemFields.CladderMetalHTLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTTotalCost,
          field: QuoteLineItemFields.CladderMetalHTTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTNotes,
          field: QuoteLineItemFields.CladderMetalHTNotes
        },
        heatTreatmentVendor: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTVendor,
          field: QuoteLineItemFields.CladderMetalHTVendor
        },
        heatTreatmentType: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTType,
          field: QuoteLineItemFields.CladderMetalHTType
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.CladderMetalHTShipToCustomer,
          field: QuoteLineItemFields.CladderMetalHTShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.CladderMetalHTSort
        }
      };
    case OutsideServices.PostbondHeatTreatment:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.PostbondHTDescriptionOfEvent,
          field: QuoteLineItemFields.PostbondHTDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.PostbondHTDurationOfEventDays,
          field: QuoteLineItemFields.PostbondHTDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.PostbondHTTotalTransitTimeDays,
          field: QuoteLineItemFields.PostbondHTTotalTransitTimeDays
        },
        costOfEventPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.PostbondHTCostOfEvent,
          field: QuoteLineItemFields.PostbondHTCostOfEvent
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.PostbondHTTotalCostOfEvent,
          field: QuoteLineItemFields.PostbondHTTotalCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.PostbondHTOutboundShippingCost,
          field: QuoteLineItemFields.PostbondHTOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.PostbondHTTotalOutboundShippingCost,
          field: QuoteLineItemFields.PostbondHTTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.PostbondHTInboundShippingCost,
          field: QuoteLineItemFields.PostbondHTInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.PostbondHTTotalInboundShippingCost,
          field: QuoteLineItemFields.PostbondHTTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.PostbondHTLeadTimeWeeks,
          field: QuoteLineItemFields.PostbondHTLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.PostbondHTTotalCost,
          field: QuoteLineItemFields.PostbondHTTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.PostbondHTNotes,
          field: QuoteLineItemFields.PostbondHTNotes
        },
        heatTreatmentVendor: {
          valueType: QuoteLineItemValueTypes.PostbondHTVendor,
          field: QuoteLineItemFields.PostbondHTVendor
        },
        heatTreatmentType: {
          valueType: QuoteLineItemValueTypes.PostbondHTType,
          field: QuoteLineItemFields.PostbondHTType
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.PostbondHTShipToCustomer,
          field: QuoteLineItemFields.PostbondHTShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.PostbondHTSort
        }
      };
    case OutsideServices.Welding:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.WeldingDescriptionOfEvent,
          field: QuoteLineItemFields.WeldingDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.WeldingDurationOfEventDays,
          field: QuoteLineItemFields.WeldingDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.WeldingTotalTransitTimeDays,
          field: QuoteLineItemFields.WeldingTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.WeldingCostOfEvent,
          field: QuoteLineItemFields.WeldingCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.WeldingOutboundShippingCost,
          field: QuoteLineItemFields.WeldingOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.WeldingTotalOutboundShippingCost,
          field: QuoteLineItemFields.WeldingTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.WeldingInboundShippingCost,
          field: QuoteLineItemFields.WeldingInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.WeldingTotalInboundShippingCost,
          field: QuoteLineItemFields.WeldingTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.WeldingLeadTimeWeeks,
          field: QuoteLineItemFields.WeldingLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.WeldingTotalCost,
          field: QuoteLineItemFields.WeldingTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.WeldingNotes,
          field: QuoteLineItemFields.WeldingNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.WeldingVendor,
          field: QuoteLineItemFields.WeldingVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.WeldingShipToCustomer,
          field: QuoteLineItemFields.WeldingShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.WeldingSort
        }
      };
    case OutsideServices.XRayServices:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.XRayServicesDescriptionOfEvent,
          field: QuoteLineItemFields.XRayServicesDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.XRayServicesDurationOfEventDays,
          field: QuoteLineItemFields.XRayServicesDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.XRayServicesTotalTransitTimeDays,
          field: QuoteLineItemFields.XRayServicesTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.XRayServicesCostOfEvent,
          field: QuoteLineItemFields.XRayServicesCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.XRayServicesOutboundShippingCost,
          field: QuoteLineItemFields.XRayServicesOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.XRayServicesTotalOutboundShippingCost,
          field: QuoteLineItemFields.XRayServicesTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.XRayServicesInboundShippingCost,
          field: QuoteLineItemFields.XRayServicesInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.XRayServicesTotalInboundShippingCost,
          field: QuoteLineItemFields.XRayServicesTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.XRayServicesLeadTimeWeeks,
          field: QuoteLineItemFields.XRayServicesLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.XRayServicesTotalCost,
          field: QuoteLineItemFields.XRayServicesTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.XRayServicesNotes,
          field: QuoteLineItemFields.XRayServicesNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.XRayServicesVendor,
          field: QuoteLineItemFields.XRayServicesVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.XRayServicesShipToCustomer,
          field: QuoteLineItemFields.XRayServicesShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.XRayServicesSort
        }
      };
    case OutsideServices.FlatteningBeforeCutting:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.FlatteningBKDescriptionOfEvent,
          field: QuoteLineItemFields.FlatteningBKDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.FlatteningBKDurationOfEventDays,
          field: QuoteLineItemFields.FlatteningBKDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.FlatteningBKTotalTransitTimeDays,
          field: QuoteLineItemFields.FlatteningBKTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.FlatteningBKCostOfEvent,
          field: QuoteLineItemFields.FlatteningBKCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.FlatteningBKOutboundShippingCost,
          field: QuoteLineItemFields.FlatteningBKOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.FlatteningBKTotalOutboundShippingCost,
          field: QuoteLineItemFields.FlatteningBKTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.FlatteningBKInboundShippingCost,
          field: QuoteLineItemFields.FlatteningBKInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.FlatteningBKTotalInboundShippingCost,
          field: QuoteLineItemFields.FlatteningBKTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.FlatteningBKLeadTimeWeeks,
          field: QuoteLineItemFields.FlatteningBKLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.FlatteningBKTotalCost,
          field: QuoteLineItemFields.FlatteningBKTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.FlatteningBKNotes,
          field: QuoteLineItemFields.FlatteningBKNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.FlatteningBKVendor,
          field: QuoteLineItemFields.FlatteningBKVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.FlatteningBKShipToCustomer,
          field: QuoteLineItemFields.FlatteningBKShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.FlatteningBKSort
        }
      };
    case OutsideServices.Cutting:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.CuttingDescriptionOfEvent,
          field: QuoteLineItemFields.CuttingDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.CuttingDurationOfEventDays,
          field: QuoteLineItemFields.CuttingDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.CuttingTotalTransitTimeDays,
          field: QuoteLineItemFields.CuttingTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.CuttingCostOfEvent,
          field: QuoteLineItemFields.CuttingCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.CuttingOutboundShippingCost,
          field: QuoteLineItemFields.CuttingOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.CuttingTotalOutboundShippingCost,
          field: QuoteLineItemFields.CuttingTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.CuttingInboundShippingCost,
          field: QuoteLineItemFields.CuttingInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.CuttingTotalInboundShippingCost,
          field: QuoteLineItemFields.CuttingTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.CuttingLeadTimeWeeks,
          field: QuoteLineItemFields.CuttingLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.CuttingTotalCost,
          field: QuoteLineItemFields.CuttingTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.CuttingNotes,
          field: QuoteLineItemFields.CuttingNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.CuttingVendor,
          field: QuoteLineItemFields.CuttingVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.CuttingShipToCustomer,
          field: QuoteLineItemFields.CuttingShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.CuttingSort
        }
      };
    case OutsideServices.WaterjetCutting:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingDescriptionOfEvent,
          field: QuoteLineItemFields.WaterJetCuttingDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingDurationOfEventDays,
          field: QuoteLineItemFields.WaterJetCuttingDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingTotalTransitTimeDays,
          field: QuoteLineItemFields.WaterJetCuttingTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingCostOfEvent,
          field: QuoteLineItemFields.WaterJetCuttingCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingOutboundShippingCost,
          field: QuoteLineItemFields.WaterJetCuttingOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingTotalOutboundShippingCost,
          field: QuoteLineItemFields.WaterJetCuttingTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingInboundShippingCost,
          field: QuoteLineItemFields.WaterJetCuttingInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingTotalInboundShippingCost,
          field: QuoteLineItemFields.WaterJetCuttingTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingLeadTimeWeeks,
          field: QuoteLineItemFields.WaterJetCuttingLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingTotalCost,
          field: QuoteLineItemFields.WaterJetCuttingTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingNotes,
          field: QuoteLineItemFields.WaterJetCuttingNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingVendor,
          field: QuoteLineItemFields.WaterJetCuttingVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.WaterJetCuttingShipToCustomer,
          field: QuoteLineItemFields.WaterJetCuttingShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.WaterJetCuttingSort
        }
      };
    case OutsideServices.FlatteningAfterCutting:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.FlatteningDescriptionOfEvent,
          field: QuoteLineItemFields.FlatteningDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.FlatteningDurationOfEventDays,
          field: QuoteLineItemFields.FlatteningDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.FlatteningTotalTransitTimeDays,
          field: QuoteLineItemFields.FlatteningTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.FlatteningCostOfEvent,
          field: QuoteLineItemFields.FlatteningCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.FlatteningOutboundShippingCost,
          field: QuoteLineItemFields.FlatteningOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.FlatteningTotalOutboundShippingCost,
          field: QuoteLineItemFields.FlatteningTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.FlatteningInboundShippingCost,
          field: QuoteLineItemFields.FlatteningInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.FlatteningTotalInboundShippingCost,
          field: QuoteLineItemFields.FlatteningTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.FlatteningLeadTimeWeeks,
          field: QuoteLineItemFields.FlatteningLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.FlatteningTotalCost,
          field: QuoteLineItemFields.FlatteningTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.FlatteningNotes,
          field: QuoteLineItemFields.FlatteningNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.FlatteningVendor,
          field: QuoteLineItemFields.FlatteningVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.FlatteningShipToCustomer,
          field: QuoteLineItemFields.FlatteningShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.FlatteningSort
        }
      };
    case OutsideServices.Machining:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.MachiningDescriptionOfEvent,
          field: QuoteLineItemFields.MachiningDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.MachiningDurationOfEventDays,
          field: QuoteLineItemFields.MachiningDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.MachiningTotalTransitTimeDays,
          field: QuoteLineItemFields.MachiningTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.MachiningCostOfEvent,
          field: QuoteLineItemFields.MachiningCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.MachiningOutboundShippingCost,
          field: QuoteLineItemFields.MachiningOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.MachiningTotalOutboundShippingCost,
          field: QuoteLineItemFields.MachiningTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.MachiningInboundShippingCost,
          field: QuoteLineItemFields.MachiningInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.MachiningTotalInboundShippingCost,
          field: QuoteLineItemFields.MachiningTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.MachiningLeadTimeWeeks,
          field: QuoteLineItemFields.MachiningLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.MachiningTotalCost,
          field: QuoteLineItemFields.MachiningTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.MachiningNotes,
          field: QuoteLineItemFields.MachiningNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.MachiningVendor,
          field: QuoteLineItemFields.MachiningVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.MachiningShipToCustomer,
          field: QuoteLineItemFields.MachiningShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.MachiningSort
        }
      };
    case OutsideServices.InspectionBacker:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.InspectionBKDescriptionOfEvent,
          field: QuoteLineItemFields.InspectionBKDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.InspectionBKDurationOfEventDays,
          field: QuoteLineItemFields.InspectionBKDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.InspectionBKTotalTransitTimeDays,
          field: QuoteLineItemFields.InspectionBKTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.InspectionBKCostOfEvent,
          field: QuoteLineItemFields.InspectionBKCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.InspectionBKOutboundShippingCost,
          field: QuoteLineItemFields.InspectionBKOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.InspectionBKTotalOutboundShippingCost,
          field: QuoteLineItemFields.InspectionBKTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.InspectionBKInboundShippingCost,
          field: QuoteLineItemFields.InspectionBKInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.InspectionBKTotalInboundShippingCost,
          field: QuoteLineItemFields.InspectionBKTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.InspectionBKLeadTimeWeeks,
          field: QuoteLineItemFields.InspectionBKLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.InspectionBKTotalCost,
          field: QuoteLineItemFields.InspectionBKTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.InspectionBKNotes,
          field: QuoteLineItemFields.InspectionBKNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.InspectionBKVendor,
          field: QuoteLineItemFields.InspectionBKVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.InspectionBKShipToCustomer,
          field: QuoteLineItemFields.InspectionBKShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.InspectionBKSort
        }
      };
    case OutsideServices.InspectionCladder:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.InspectionCLDescriptionOfEvent,
          field: QuoteLineItemFields.InspectionCLDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.InspectionCLDurationOfEventDays,
          field: QuoteLineItemFields.InspectionCLDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.InspectionCLTotalTransitTimeDays,
          field: QuoteLineItemFields.InspectionCLTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.InspectionCLCostOfEvent,
          field: QuoteLineItemFields.InspectionCLCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.InspectionCLOutboundShippingCost,
          field: QuoteLineItemFields.InspectionCLOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.InspectionCLTotalOutboundShippingCost,
          field: QuoteLineItemFields.InspectionCLTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.InspectionCLInboundShippingCost,
          field: QuoteLineItemFields.InspectionCLInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.InspectionCLTotalInboundShippingCost,
          field: QuoteLineItemFields.InspectionCLTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.InspectionCLLeadTimeWeeks,
          field: QuoteLineItemFields.InspectionCLLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.InspectionCLTotalCost,
          field: QuoteLineItemFields.InspectionCLTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.InspectionCLNotes,
          field: QuoteLineItemFields.InspectionCLNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.InspectionCLVendor,
          field: QuoteLineItemFields.InspectionCLVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.InspectionCLShipToCustomer,
          field: QuoteLineItemFields.InspectionCLShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.InspectionCLSort
        }
      };
    case OutsideServices.InspectionCladProduct:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.InspectionDescriptionOfEvent,
          field: QuoteLineItemFields.InspectionDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.InspectionDurationOfEventDays,
          field: QuoteLineItemFields.InspectionDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.InspectionTotalTransitTimeDays,
          field: QuoteLineItemFields.InspectionTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.InspectionCostOfEvent,
          field: QuoteLineItemFields.InspectionCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.InspectionOutboundShippingCost,
          field: QuoteLineItemFields.InspectionOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.InspectionTotalOutboundShippingCost,
          field: QuoteLineItemFields.InspectionTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.InspectionInboundShippingCost,
          field: QuoteLineItemFields.InspectionInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.InspectionTotalInboundShippingCost,
          field: QuoteLineItemFields.InspectionTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.InspectionLeadTimeWeeks,
          field: QuoteLineItemFields.InspectionLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.InspectionTotalCost,
          field: QuoteLineItemFields.InspectionTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.InspectionNotes,
          field: QuoteLineItemFields.InspectionNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.InspectionVendor,
          field: QuoteLineItemFields.InspectionVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.InspectionShipToCustomer,
          field: QuoteLineItemFields.InspectionShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.InspectionSort
        }
      };
    case OutsideServices.Packaging:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.PackagingDescriptionOfEvent,
          field: QuoteLineItemFields.PackagingDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.PackagingDurationOfEventDays,
          field: QuoteLineItemFields.PackagingDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.PackagingTotalTransitTimeDays,
          field: QuoteLineItemFields.PackagingTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.PackagingCostOfEvent,
          field: QuoteLineItemFields.PackagingCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.PackagingOutboundShippingCost,
          field: QuoteLineItemFields.PackagingOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.PackagingTotalOutboundShippingCost,
          field: QuoteLineItemFields.PackagingTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.PackagingInboundShippingCost,
          field: QuoteLineItemFields.PackagingInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.PackagingTotalInboundShippingCost,
          field: QuoteLineItemFields.PackagingTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.PackagingLeadTimeWeeks,
          field: QuoteLineItemFields.PackagingLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.PackagingTotalCost,
          field: QuoteLineItemFields.PackagingTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.PackagingNotes,
          field: QuoteLineItemFields.PackagingNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.PackagingVendor,
          field: QuoteLineItemFields.PackagingVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.PackagingShipToCustomer,
          field: QuoteLineItemFields.PackagingShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.PackagingSort
        }
      };
    case OutsideServices.CanRolling:
      return {
        descriptionOfEvent: {
          valueType: QuoteLineItemValueTypes.CanRollingDescriptionOfEvent,
          field: QuoteLineItemFields.CanRollingDescriptionOfEvent
        },
        durationOfEventDays: {
          valueType: QuoteLineItemValueTypes.CanRollingDurationOfEventDays,
          field: QuoteLineItemFields.CanRollingDurationOfEventDays
        },
        totalTransitTimeDays: {
          valueType: QuoteLineItemValueTypes.CanRollingTotalTransitTimeDays,
          field: QuoteLineItemFields.CanRollingTotalTransitTimeDays
        },
        costOfEvent: {
          valueType: QuoteLineItemValueTypes.CanRollingCostOfEvent,
          field: QuoteLineItemFields.CanRollingCostOfEvent
        },
        outboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.CanRollingOutboundShippingCost,
          field: QuoteLineItemFields.CanRollingOutboundShippingCost
        },
        outboundShip: {
          valueType: QuoteLineItemValueTypes.CanRollingTotalOutboundShippingCost,
          field: QuoteLineItemFields.CanRollingTotalOutboundShippingCost
        },
        inboundShipPerWeightUnit: {
          valueType: QuoteLineItemValueTypes.CanRollingInboundShippingCost,
          field: QuoteLineItemFields.CanRollingInboundShippingCost
        },
        inboundShip: {
          valueType: QuoteLineItemValueTypes.CanRollingTotalInboundShippingCost,
          field: QuoteLineItemFields.CanRollingTotalInboundShippingCost
        },
        leadTime: {
          valueType: QuoteLineItemValueTypes.CanRollingLeadTimeWeeks,
          field: QuoteLineItemFields.CanRollingLeadTimeWeeks
        },
        totalCost: {
          valueType: QuoteLineItemValueTypes.CanRollingTotalCost,
          field: QuoteLineItemFields.CanRollingTotalCost
        },
        notes: {
          valueType: QuoteLineItemValueTypes.CanRollingNotes,
          field: QuoteLineItemFields.CanRollingNotes
        },
        vendor: {
          valueType: QuoteLineItemValueTypes.CanRollingVendor,
          field: QuoteLineItemFields.CanRollingVendor
        },
        productShipsToCustomer: {
          valueType: QuoteLineItemValueTypes.CanRollingShipToCustomer,
          field: QuoteLineItemFields.CanRollingShipToCustomer
        },
        sort: {
          field: QuoteLineItemFields.CanRollingSort
        }
      };
  }
};

// Returns true if any of the OS services fields exist on the detail
export const someOsServicesFieldExists = (outsideService: OutsideServices, quoteLineItemDetail: QuoteLineItemDetail) => {
  return [...Object.values(getOSServicesFieldInfo(outsideService))].some(fieldType => quoteLineItemDetail.NC_Outside_Service_Item_Details__r[fieldType.field]);
};

export const outsideServicesInDetail = (quoteLineItemDetail: QuoteLineItemDetail) => {
  const osServicesFromQlid = [
    ...(someOsServicesFieldExists(OutsideServices.TestingServicesBacker, quoteLineItemDetail) ? [OutsideServices.TestingServicesBacker] : []),
    ...(someOsServicesFieldExists(OutsideServices.TestingServicesCladder, quoteLineItemDetail) ? [OutsideServices.TestingServicesCladder] : []),
    ...(someOsServicesFieldExists(OutsideServices.TestingServicesCladProduct, quoteLineItemDetail) ? [OutsideServices.TestingServicesCladProduct] : []),
    ...(someOsServicesFieldExists(OutsideServices.BackerMetalHeatTreatment, quoteLineItemDetail) ? [OutsideServices.BackerMetalHeatTreatment] : []),
    ...(someOsServicesFieldExists(OutsideServices.CladderMetalHeatTreatment, quoteLineItemDetail) ? [OutsideServices.CladderMetalHeatTreatment] : []),
    ...(someOsServicesFieldExists(OutsideServices.PostbondHeatTreatment, quoteLineItemDetail) ? [OutsideServices.PostbondHeatTreatment] : []),
    ...(someOsServicesFieldExists(OutsideServices.Welding, quoteLineItemDetail) ? [OutsideServices.Welding] : []),
    ...(someOsServicesFieldExists(OutsideServices.XRayServices, quoteLineItemDetail) ? [OutsideServices.XRayServices] : []),
    ...(someOsServicesFieldExists(OutsideServices.FlatteningBeforeCutting, quoteLineItemDetail) ? [OutsideServices.FlatteningBeforeCutting] : []),
    ...(someOsServicesFieldExists(OutsideServices.Cutting, quoteLineItemDetail) ? [OutsideServices.Cutting] : []),
    ...(someOsServicesFieldExists(OutsideServices.WaterjetCutting, quoteLineItemDetail) ? [OutsideServices.WaterjetCutting] : []),
    ...(someOsServicesFieldExists(OutsideServices.FlatteningAfterCutting, quoteLineItemDetail) ? [OutsideServices.FlatteningAfterCutting] : []),
    ...(someOsServicesFieldExists(OutsideServices.Machining, quoteLineItemDetail) ? [OutsideServices.Machining] : []),
    ...(someOsServicesFieldExists(OutsideServices.InspectionBacker, quoteLineItemDetail) ? [OutsideServices.InspectionBacker] : []),
    ...(someOsServicesFieldExists(OutsideServices.InspectionCladder, quoteLineItemDetail) ? [OutsideServices.InspectionCladder] : []),
    ...(someOsServicesFieldExists(OutsideServices.InspectionCladProduct, quoteLineItemDetail) ? [OutsideServices.InspectionCladProduct] : []),
    ...(someOsServicesFieldExists(OutsideServices.Packaging, quoteLineItemDetail) ? [OutsideServices.Packaging] : []),
    ...(someOsServicesFieldExists(OutsideServices.CanRolling, quoteLineItemDetail) ? [OutsideServices.CanRolling] : []),
  ];

  return osServicesFromQlid.orderBy(osService => quoteLineItemDetail.NC_Outside_Service_Item_Details__r[getOSServicesFieldInfo(osService)?.sort.field] || 99);
};

export const duplicatedQuoteLineItem = (quoteLineItemToDuplicate: QuoteLineItem, numQuoteLineItems: number) => {
  return {
    ...quoteLineItemToDuplicate,
    quoteLineItemDetail: duplicatedQuoteLineItemDetail(quoteLineItemToDuplicate, numQuoteLineItems),
    Id: undefined,
    Line__c: (numQuoteLineItems + 1).toString().padStart(3, '0'),
    displayId: (numQuoteLineItems + 1).toString(),
  };
};

export const duplicatedQuoteLineItemDetail = (quoteLineItemForDetail: QuoteLineItem, numQuoteLineItems: number) => {
  const quoteLineItemDetailToDuplicate = quoteLineItemForDetail.quoteLineItemDetail;

  return {
    ...quoteLineItemDetailToDuplicate,
    Id: undefined,
    NC_Quote_Line_Item__c: undefined,
    associatedLineItem: (numQuoteLineItems + 1).toString(),
    NC_Tubesheet_Item_Details__r: {
      ...quoteLineItemDetailToDuplicate.NC_Tubesheet_Item_Details__r,
      Id: undefined,
      NC_Quote_Line_Item_Detail__c: undefined
    },
    NC_Head_Item_Detail__r: {
      ...quoteLineItemDetailToDuplicate.NC_Head_Item_Detail__r,
      Id: undefined,
      NC_Quote_Line_Item_Detail__c: undefined
    },
    NC_Cylinder_Item_Details__r: {
      ...quoteLineItemDetailToDuplicate.NC_Cylinder_Item_Details__r,
      Id: undefined,
      NC_Quote_Line_Item_Detail__c: undefined
    },
    NC_Multi_Shot_Item_Details__r: {
      ...quoteLineItemDetailToDuplicate.NC_Multi_Shot_Item_Details__r,
      Id: undefined,
      NC_Quote_Line_Item_Detail__c: undefined
    },
    NC_Anvil_Item_Details__r: {
      ...quoteLineItemDetailToDuplicate.NC_Anvil_Item_Details__r,
      Id: undefined,
      NC_Quote_Line_Item_Detail__c: undefined
    },
    NC_Flyer_Item_Details__r: {
      ...quoteLineItemDetailToDuplicate.NC_Flyer_Item_Details__r,
      Id: undefined,
      NC_Quote_Line_Item_Detail__c: undefined
    },
    NC_Seam_Weld_Item_Details__r: {
      ...quoteLineItemDetailToDuplicate.NC_Seam_Weld_Item_Details__r,
      Id: undefined,
      NC_Quote_Line_Item_Detail__c: undefined
    },
    NC_Outside_Service_Item_Details__r: {
      ...quoteLineItemDetailToDuplicate.NC_Outside_Service_Item_Details__r,
      Id: undefined,
      NC_Quote_Line_Item_Detail__c: undefined
    }
  };
};

export const filterMSTOPanelFields = (activeTab: MSTOSelectionTabs, quoteLineItem: QuoteLineItem, quoteLineItemDetail: QuoteLineItemDetail, seamWeldItemDetail?: SeamWeldItemDetail, anvilItemDetail?: AnvilItemDetail) => {
  let lineItemPanelFields: QuoteLineItem = {
    Id: quoteLineItem.Id,
    displayId: quoteLineItem.displayId,
    UUID__c: quoteLineItem.UUID__c,
    piece: quoteLineItem.piece,
    Unit_of_Measure__c: quoteLineItem.Unit_of_Measure__c,
    Line__c: quoteLineItem.Line__c,
    CustomerPO__c: quoteLineItem.CustomerPO__c,
    CurrencyIsoCode: quoteLineItem.CurrencyIsoCode,
    quoteLineItemDetail: {
      Clads__c: quoteLineItem.quoteLineItemDetail.Clads__c
    }
  };

  let lineItemDetailPanelFields: QuoteLineItemDetail = {
    Id: quoteLineItemDetail.Id,
    associatedLineItem: quoteLineItemDetail.associatedLineItem,
    Unit_of_Measure__c: quoteLineItemDetail.Unit_of_Measure__c,
    Clads__c: quoteLineItemDetail.Clads__c,
    NC_Quote_Line_Item__c: quoteLineItemDetail.NC_Quote_Line_Item__c,
    UUID__c: quoteLineItemDetail.UUID__c,
    itemUUID: quoteLineItemDetail.itemUUID,
  };

  let seamWeldItemDetailPanelFields;
  let anvilItemDetailPanelFields;

  switch(activeTab) {
    case MSTOSelectionTabs.Cladder:
      lineItemPanelFields = {
        ...lineItemPanelFields,
        CladMetal__c: quoteLineItem.CladMetal__c,
        Proposal_Type_Clad__c: quoteLineItem.Proposal_Type_Clad__c,
        Customer_Provided_Clad__c: quoteLineItem.Customer_Provided_Clad__c,
        Clad_Price_LB__c: quoteLineItem.Clad_Price_LB__c,
        Clad_Price_KG__c: quoteLineItem.Clad_Price_KG__c,
        quoteLineItemDetail: {
          ...lineItemPanelFields.quoteLineItemDetail,
          PurchaseCladLength__c: quoteLineItem.quoteLineItemDetail.PurchaseCladLength__c,
          PurchaseCladWidth__c: quoteLineItem.quoteLineItemDetail.PurchaseCladWidth__c
        }
      };

      lineItemDetailPanelFields = {
        ...lineItemDetailPanelFields,
        SeamWeld__c: quoteLineItemDetail.SeamWeld__c,
        RawMaterialVendorCladder__c: quoteLineItemDetail.RawMaterialVendorCladder__c,
        ConfigurationID__c: quoteLineItemDetail.ConfigurationID__c,
        PurchaseCL__c: quoteLineItemDetail.PurchaseCL__c,
        Pullfromstockcladder__c: quoteLineItemDetail.Pullfromstockcladder__c,
        ShipDateCladder__c: quoteLineItemDetail.ShipDateCladder__c,
        CladderDaysForTransport__c: quoteLineItemDetail.CladderDaysForTransport__c,
        PreheattoTorchCutCladder__c: quoteLineItemDetail.PreheattoTorchCutCladder__c,
        PurchaseCladWidth__c: quoteLineItemDetail.PurchaseCladWidth__c,
        PurchaseCladLength__c: quoteLineItemDetail.PurchaseCladLength__c,
        PurchaseCladTK__c: quoteLineItemDetail.PurchaseCladTK__c,
        Anvil_Needed__c: quoteLineItemDetail.Anvil_Needed__c,
        TorchCutCladder__c: quoteLineItemDetail.TorchCutCladder__c,
        ItemDescriptionCladderim__c: quoteLineItemDetail.ItemDescriptionCladderim__c,
        ItemDescriptionCladdermm__c: quoteLineItemDetail.ItemDescriptionCladdermm__c,
        PurchaseCladderPrice__c: quoteLineItemDetail.PurchaseCladderPrice__c,
        PurchaseCladderFreight_LB__c: quoteLineItemDetail.PurchaseCladderFreight_LB__c,
        PurchaseCladderFreight_KG__c: quoteLineItemDetail.PurchaseCladderFreight_KG__c,
        PurchaseCladderWeight_LB__c: quoteLineItemDetail.PurchaseCladderWeight_LB__c,
        PurchaseCladderWeight_KG__c: quoteLineItemDetail.PurchaseCladderWeight_KG__c,
        AreaCladder_in__c: quoteLineItemDetail.AreaCladder_in__c,
        AreaCladder_mm__c: quoteLineItemDetail.AreaCladder_mm__c,
      };

      seamWeldItemDetailPanelFields = {
        ConfigurationID__c: seamWeldItemDetail.ConfigurationID__c,
      };

      for(let pieceNumber = 1; pieceNumber <= 9; pieceNumber++) {
        seamWeldItemDetailPanelFields[`RawMaterialVendorSW${pieceNumber}__c`] = seamWeldItemDetail[`RawMaterialVendorSW${pieceNumber}__c`];

        if(pieceNumber !== 1) {
          seamWeldItemDetailPanelFields[`ConfigurationIDSW${pieceNumber}__c`] = seamWeldItemDetail[`ConfigurationIDSW${pieceNumber}__c`];
        }

        seamWeldItemDetailPanelFields[`PurchaseSW${pieceNumber}__c`] = seamWeldItemDetail[`PurchaseSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`PullFromStockSW${pieceNumber}__c`] = seamWeldItemDetail[`PullFromStockSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`ShipDateSW${pieceNumber}__c`] = seamWeldItemDetail[`ShipDateSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`DaysForTransportSW${pieceNumber}__c`] = seamWeldItemDetail[`DaysForTransportSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`PreheatToCutSW${pieceNumber}__c`] = seamWeldItemDetail[`PreheatToCutSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`CladderWidthSW${pieceNumber}__c`] = seamWeldItemDetail[`CladderWidthSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`CladderLengthSW${pieceNumber}__c`] = seamWeldItemDetail[`CladderLengthSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`NominalThicknessSW${pieceNumber}__c`] = seamWeldItemDetail[`NominalThicknessSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`WeightSW${pieceNumber}__c`] = seamWeldItemDetail[`WeightSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`SWRawMaterialCostSW${pieceNumber}__c`] = seamWeldItemDetail[`SWRawMaterialCostSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`EstimatedShippingTotalSW${pieceNumber}__c`] = seamWeldItemDetail[`EstimatedShippingTotalSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`PanelLocationSW${pieceNumber}__c`] = seamWeldItemDetail[`PanelLocationSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`PanelNumberSW${pieceNumber}__c`] = seamWeldItemDetail[`PanelNumberSW${pieceNumber}__c`];
        seamWeldItemDetailPanelFields[`QuantitySW${pieceNumber}__c`] = seamWeldItemDetail[`QuantitySW${pieceNumber}__c`];
      }

      return { lineItemPanelFields, lineItemDetailPanelFields, seamWeldItemDetailPanelFields };
    case MSTOSelectionTabs.Backer:
      lineItemPanelFields = {
        ...lineItemPanelFields,
        BaseMetal__c: quoteLineItem.BaseMetal__c,
        Proposal_Type_Base__c: quoteLineItem.Proposal_Type_Base__c,
        Customer_Provided_Base__c: quoteLineItem.Customer_Provided_Base__c,
        Base_Price_LB__c: quoteLineItem.Base_Price_LB__c,
        Base_Price_KG__c: quoteLineItem.Base_Price_KG__c,
        quoteLineItemDetail: {
          ...lineItemPanelFields.quoteLineItemDetail,
          PurchaseBaseLength__c: quoteLineItem.quoteLineItemDetail.PurchaseBaseLength__c,
          PurchaseBaseWidth__c: quoteLineItem.quoteLineItemDetail.PurchaseBaseWidth__c
        }
      };

      lineItemDetailPanelFields = {
        ...lineItemDetailPanelFields,
        RawMaterialVendorBacker__c: quoteLineItemDetail.RawMaterialVendorBacker__c,
        ConfigurationIDBacker__c: quoteLineItemDetail.ConfigurationIDBacker__c,
        PurchaseBK__c: quoteLineItemDetail.PurchaseBK__c,
        Pullfromstockbacker__c: quoteLineItemDetail.Pullfromstockbacker__c,
        ShipDateBase__c: quoteLineItemDetail.ShipDateBase__c,
        BackerDaysForTransport__c: quoteLineItemDetail.BackerDaysForTransport__c,
        PreheattoTorchCutBacker__c: quoteLineItemDetail.PreheattoTorchCutBacker__c,
        PurchaseBaseWidth__c: quoteLineItemDetail.PurchaseBaseWidth__c,
        PurchaseBaseLength__c: quoteLineItemDetail.PurchaseBaseLength__c,
        PurchaseBaseTK__c: quoteLineItemDetail.PurchaseBaseTK__c,
        Anvil_Needed__c: quoteLineItemDetail.Anvil_Needed__c,
        TorchCutBacker__c: quoteLineItemDetail.TorchCutBacker__c,
        ItemDescriptionBackerim__c: quoteLineItemDetail.ItemDescriptionBackerim__c,
        ItemDescriptionBackermm__c: quoteLineItemDetail.ItemDescriptionBackermm__c,
        PurchaseBackerPrice__c: quoteLineItemDetail.PurchaseBackerPrice__c,
        PurchaseBackerFreight_LB__c: quoteLineItemDetail.PurchaseBackerFreight_LB__c,
        PurchaseBackerFreight_KG__c: quoteLineItemDetail.PurchaseBackerFreight_KG__c,
        PurchaseBackerWeight_LB__c: quoteLineItemDetail.PurchaseBackerWeight_LB__c,
        PurchaseBackerWeight_KG__c: quoteLineItemDetail.PurchaseBackerWeight_KG__c,
        AreaBacker_in__c: quoteLineItemDetail.AreaBacker_in__c,
        AreaBacker_mm__c: quoteLineItemDetail.AreaBacker_mm__c,
      };

      return { lineItemPanelFields, lineItemDetailPanelFields };
    case MSTOSelectionTabs.Anvil:
      lineItemPanelFields = {
        ...lineItemPanelFields,
        Anvil__c: quoteLineItem.Anvil__c,
        quoteLineItemDetail: {
          ...lineItemPanelFields.quoteLineItemDetail,
          PurchaseAnvilLength__c: quoteLineItem.quoteLineItemDetail.PurchaseAnvilLength__c,
          PurchaseAnvilWidth__c: quoteLineItem.quoteLineItemDetail.PurchaseAnvilWidth__c
        }
      };

      lineItemDetailPanelFields = {
        ...lineItemDetailPanelFields,
        VendorAnvil__c: quoteLineItemDetail.VendorAnvil__c,
        PurchaseBKAnvil__c: quoteLineItemDetail.PurchaseBKAnvil__c,
        PullfromstockBKAnvil__c: quoteLineItemDetail.PullfromstockBKAnvil__c,
        PreheattoCutAnvil__c: quoteLineItemDetail.PreheattoCutAnvil__c,
        PurchaseAnvilWidth__c: quoteLineItemDetail.PurchaseAnvilWidth__c,
        PurchaseAnvilLength__c: quoteLineItemDetail.PurchaseAnvilLength__c,
        PurchaseAnvilTK__c: quoteLineItemDetail.PurchaseAnvilTK__c,
        TorchCutAnvil1__c: quoteLineItemDetail.TorchCutAnvil1__c,
        PurchaseAnvilPrice__c: quoteLineItemDetail.PurchaseAnvilPrice__c,
        PurchaseAnvilFreight_LB__c: quoteLineItemDetail.PurchaseAnvilFreight_LB__c,
        PurchaseAnvilFreight_KG__c: quoteLineItemDetail.PurchaseAnvilFreight_KG__c,
        PurchaseAnvilWeight_LB__c: quoteLineItemDetail.PurchaseAnvilWeight_LB__c,
        PurchaseAnvilWeight_KG__c: quoteLineItemDetail.PurchaseAnvilWeight_KG__c,
        Anvil_Area_in__c: quoteLineItemDetail.Anvil_Area_in__c,
        Anvil_Area_mm__c: quoteLineItemDetail.Anvil_Area_mm__c,
      };

      anvilItemDetailPanelFields = {
        ConfigurationIDAnvil__c: anvilItemDetail.ConfigurationIDAnvil__c,
        ShipDateAnvil__c: anvilItemDetail.ShipDateAnvil__c,
        AnvilDaysForTransport__c: anvilItemDetail.AnvilDaysForTransport__c,
      };

      return { lineItemPanelFields, lineItemDetailPanelFields, anvilItemDetailPanelFields };
  }
};

export const filterMSTOAssemblyFields = (metalToAssemble: {
  quoteLineItemData: QuoteLineItem;
  quoteLineItemDetailData: QuoteLineItemDetail;
  metal: MSTOAssemblyMetals;
  panelNumber?: number;
  panelLocation?: PanelLocations;
  seamWeldPieceNumber?: number;
}) => {
  const currentQuoteLineItemData = metalToAssemble.quoteLineItemData;

  metalToAssemble.quoteLineItemData = {
    Id: currentQuoteLineItemData.Id,
    displayId: currentQuoteLineItemData.displayId,
    UUID__c: currentQuoteLineItemData.UUID__c,
    Line__c: currentQuoteLineItemData.Line__c,
    piece: currentQuoteLineItemData.piece,
    Unit_of_Measure__c: currentQuoteLineItemData.Unit_of_Measure__c
  };

  switch(metalToAssemble.metal) {
    case MSTOAssemblyMetals.CLAD:
      if(!metalToAssemble.seamWeldPieceNumber) {
        metalToAssemble.quoteLineItemData = {
          ...metalToAssemble.quoteLineItemData,
          Customer_Provided_Clad__c: currentQuoteLineItemData.Customer_Provided_Clad__c
        };

        metalToAssemble.quoteLineItemDetailData = {
          RawMaterialVendorCladder__c: metalToAssemble.quoteLineItemDetailData.RawMaterialVendorCladder__c ?? '0',
          ConfigurationID__c: metalToAssemble.quoteLineItemDetailData.ConfigurationID__c,
          PurchaseCL__c: metalToAssemble.quoteLineItemDetailData.PurchaseCL__c,
          Pullfromstockcladder__c: metalToAssemble.quoteLineItemDetailData.Pullfromstockcladder__c,
          ShipDateCladder__c: metalToAssemble.quoteLineItemDetailData.ShipDateCladder__c,
          CladderDaysForTransport__c: metalToAssemble.quoteLineItemDetailData.CladderDaysForTransport__c,
          PreheattoTorchCutCladder__c: metalToAssemble.quoteLineItemDetailData.PreheattoTorchCutCladder__c,
          PurchaseCladWidth__c: metalToAssemble.quoteLineItemDetailData.PurchaseCladWidth__c,
          PurchaseCladLength__c: metalToAssemble.quoteLineItemDetailData.PurchaseCladLength__c,
          PurchaseCladTK__c: metalToAssemble.quoteLineItemDetailData.PurchaseCladTK__c,
          TorchCutCladder__c: metalToAssemble.quoteLineItemDetailData.TorchCutCladder__c,
          PurchaseCladderPrice__c: metalToAssemble.quoteLineItemDetailData.PurchaseCladderPrice__c,
          PurchaseCladderFreight_LB__c: metalToAssemble.quoteLineItemDetailData.PurchaseCladderFreight_LB__c,
          PurchaseCladderFreight_KG__c: metalToAssemble.quoteLineItemDetailData.PurchaseCladderFreight_KG__c,
          PurchaseCladderWeight_LB__c: metalToAssemble.quoteLineItemDetailData.PurchaseCladderWeight_LB__c,
          PurchaseCladderWeight_KG__c: metalToAssemble.quoteLineItemDetailData.PurchaseCladderWeight_KG__c
        };
      } else {
        metalToAssemble.quoteLineItemDetailData = {
          SeamWeld__c: metalToAssemble.quoteLineItemDetailData.SeamWeld__c,
          Unit_of_Measure__c: metalToAssemble.quoteLineItemDetailData.Unit_of_Measure__c,
          NC_Seam_Weld_Item_Details__r: {
            [`RawMaterialVendorSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`RawMaterialVendorSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [metalToAssemble.seamWeldPieceNumber === 1 ? 'ConfigurationID__c' : `ConfigurationIDSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[metalToAssemble.seamWeldPieceNumber === 1 ? 'ConfigurationID__c' : `ConfigurationIDSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`PurchaseSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`PurchaseSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`PullFromStockSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`PullFromStockSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`ShipDateSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`ShipDateSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`DaysForTransportSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`DaysForTransportSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`PreheatToCutSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`PreheatToCutSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`CladderWidthSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderWidthSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`CladderLengthSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderLengthSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`NominalThicknessSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`NominalThicknessSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`WeightSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`WeightSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`SWRawMaterialCostSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`SWRawMaterialCostSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`EstimatedShippingTotalSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`EstimatedShippingTotalSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`PanelLocationSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`PanelLocationSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`PanelNumberSW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`PanelNumberSW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`PurchaseQuantitySW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`PurchaseQuantitySW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`PullFromStockQuantitySW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`PullFromStockQuantitySW${metalToAssemble.seamWeldPieceNumber}__c`],
            [`QuantitySW${metalToAssemble.seamWeldPieceNumber}__c`]: metalToAssemble.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`QuantitySW${metalToAssemble.seamWeldPieceNumber}__c`],
          }
        };
      }
      break;
    case MSTOAssemblyMetals.BASE:
      metalToAssemble.quoteLineItemData = {
        ...metalToAssemble.quoteLineItemData,
        Customer_Provided_Base__c: currentQuoteLineItemData.Customer_Provided_Base__c,
        BaseMetal__c: currentQuoteLineItemData.BaseMetal__c
      };

      metalToAssemble.quoteLineItemDetailData = {
        RawMaterialVendorBacker__c: (metalToAssemble.quoteLineItemDetailData.RawMaterialVendorBacker__c ?? '0'),
        ConfigurationIDBacker__c: metalToAssemble.quoteLineItemDetailData.ConfigurationIDBacker__c,
        PurchaseBK__c: metalToAssemble.quoteLineItemDetailData.PurchaseBK__c,
        Pullfromstockbacker__c: metalToAssemble.quoteLineItemDetailData.Pullfromstockbacker__c,
        ShipDateBase__c: metalToAssemble.quoteLineItemDetailData.ShipDateBase__c,
        BackerDaysForTransport__c: metalToAssemble.quoteLineItemDetailData.BackerDaysForTransport__c,
        PreheattoTorchCutBacker__c: metalToAssemble.quoteLineItemDetailData.PreheattoTorchCutBacker__c,
        PurchaseBaseWidth__c: metalToAssemble.quoteLineItemDetailData.PurchaseBaseWidth__c,
        PurchaseBaseLength__c: metalToAssemble.quoteLineItemDetailData.PurchaseBaseLength__c,
        PurchaseBaseTK__c: metalToAssemble.quoteLineItemDetailData.PurchaseBaseTK__c,
        TorchCutBacker__c: metalToAssemble.quoteLineItemDetailData.TorchCutBacker__c,
        ItemDescriptionBackerim__c: metalToAssemble.quoteLineItemDetailData.ItemDescriptionBackerim__c,
        ItemDescriptionBackermm__c: metalToAssemble.quoteLineItemDetailData.ItemDescriptionBackermm__c,
        PurchaseBackerPrice__c: metalToAssemble.quoteLineItemDetailData.PurchaseBackerPrice__c,
        PurchaseBackerFreight_LB__c: metalToAssemble.quoteLineItemDetailData.PurchaseBackerFreight_LB__c,
        PurchaseBackerFreight_KG__c: metalToAssemble.quoteLineItemDetailData.PurchaseBackerFreight_KG__c,
        PurchaseBackerWeight_LB__c: metalToAssemble.quoteLineItemDetailData.PurchaseBackerWeight_LB__c,
        PurchaseBackerWeight_KG__c: metalToAssemble.quoteLineItemDetailData.PurchaseBackerWeight_KG__c
      };
      break;
    case MSTOAssemblyMetals.ANVIL:
      metalToAssemble.quoteLineItemDetailData = {
        VendorAnvil__c: metalToAssemble.quoteLineItemDetailData.VendorAnvil__c ?? '0',
        PurchaseBKAnvil__c: metalToAssemble.quoteLineItemDetailData.PurchaseBKAnvil__c,
        PullfromstockBKAnvil__c: metalToAssemble.quoteLineItemDetailData.PullfromstockBKAnvil__c,
        PreheattoCutAnvil__c: metalToAssemble.quoteLineItemDetailData.PreheattoCutAnvil__c,
        PurchaseAnvilWidth__c: metalToAssemble.quoteLineItemDetailData.PurchaseAnvilWidth__c,
        PurchaseAnvilLength__c: metalToAssemble.quoteLineItemDetailData.PurchaseAnvilLength__c,
        PurchaseAnvilTK__c: metalToAssemble.quoteLineItemDetailData.PurchaseAnvilTK__c,
        TorchCutAnvil1__c: metalToAssemble.quoteLineItemDetailData.TorchCutAnvil1__c,
        PurchaseAnvilPrice__c: metalToAssemble.quoteLineItemDetailData.PurchaseAnvilPrice__c,
        PurchaseAnvilFreight_LB__c: metalToAssemble.quoteLineItemDetailData.PurchaseAnvilFreight_LB__c,
        PurchaseAnvilFreight_KG__c: metalToAssemble.quoteLineItemDetailData.PurchaseAnvilFreight_KG__c,
        PurchaseAnvilWeight_LB__c: metalToAssemble.quoteLineItemDetailData.PurchaseAnvilWeight_LB__c,
        PurchaseAnvilWeight_KG__c: metalToAssemble.quoteLineItemDetailData.PurchaseAnvilWeight_KG__c,
        NC_Anvil_Item_Details__r: {
          ConfigurationIDAnvil__c: metalToAssemble.quoteLineItemDetailData.NC_Anvil_Item_Details__r.ConfigurationIDAnvil__c,
          ShipDateAnvil__c: metalToAssemble.quoteLineItemDetailData.NC_Anvil_Item_Details__r.ShipDateAnvil__c,
          AnvilDaysForTransport__c: metalToAssemble.quoteLineItemDetailData.NC_Anvil_Item_Details__r.AnvilDaysForTransport__c
        }
      };
      break;
  }
};

export const calculatePercentAreaAllocated = (metalType: MSTOAssemblyMetals, metalPanelsMap: Map<string, SeamPanels | Panels>, quoteLineItem: QuoteLineItem, quoteLineItemDetail: QuoteLineItemDetail, uom: MeasurementSystems, rawMaterials: RawMaterial[], manufacturingSite: ManufacturingSites) => {
  const existingPanelKeysForAssembly = Array.from(metalPanelsMap.keys()).filter(key => key.split(' - ')[0] === quoteLineItem.Line__c);

  let metalArea;
  let metalTypeForAreaCalc;
  switch(metalType) {
    case MSTOAssemblyMetals.CLAD:
      metalArea = uom === MeasurementSystems.Metric ? quoteLineItemDetail.AreaCladder_mm__c : quoteLineItemDetail.AreaCladder_in__c;
      metalTypeForAreaCalc = MetalTypesForAreaCalc.Clad;
      break;
    case MSTOAssemblyMetals.BASE:
      metalArea = uom === MeasurementSystems.Metric ? quoteLineItemDetail.AreaBacker_mm__c : quoteLineItemDetail.AreaBacker_in__c;
      metalTypeForAreaCalc = MetalTypesForAreaCalc.Base;
      break;
    case MSTOAssemblyMetals.ANVIL:
      metalArea = uom === MeasurementSystems.Metric ? quoteLineItemDetail.Anvil_Area_mm__c : quoteLineItemDetail.Anvil_Area_in__c;
      metalTypeForAreaCalc = MetalTypesForAreaCalc.Anvil;
      break;
  }

  let totalAreaOrderedForLine = 0;
  existingPanelKeysForAssembly.forEach(key => {
    const lengthWidthTKDisplayFields = metalPanelsMap.get(key).lengthWidthTKDisplayFields;
    const pieceLength = lengthWidthTKDisplayFields.DisplayLength;
    const pieceWidth = lengthWidthTKDisplayFields.DisplayWidth;


    if(metalTypeForAreaCalc === MetalTypesForAreaCalc.Base) {
      const baseMetalName = metalPanelsMap.get(key).quoteLineItemData.BaseMetal__c;
      const baseMetal = rawMaterials.find(rawMaterial => rawMaterial.name === baseMetalName && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite));
      totalAreaOrderedForLine += calculateRawArea(uom, pieceLength, pieceWidth, metalTypeForAreaCalc, baseMetal.dmcClass.includes('/F'));
    } else {
      totalAreaOrderedForLine += calculateRawArea(uom, pieceLength, pieceWidth, metalTypeForAreaCalc);
    }
  });

  const percentAreaAllocated = 100 * (totalAreaOrderedForLine / (metalArea * quoteLineItemDetail.Clads__c));

  return percentAreaAllocated;
};

export const addToPanelsMapForSplitOutLines = (
  assemblyPieces: {
    quoteLineItemData: QuoteLineItem;
    quoteLineItemDetailData: QuoteLineItemDetail;
    metal: MSTOAssemblyMetals;
    panelNumber?: number;
    panelLocation?: PanelLocations;
    seamWeldPieceNumber?: number;
  }[],
  panelsMapForJSON: Map<string,
  {
    quoteLineItemData: QuoteLineItem;
    quoteLineItemDetailData: QuoteLineItemDetail;
    panelNumber?: number;
    panelLocation?: PanelLocations;
    seamWeldPieceNumber?: number;
  }>,
  oldLine: string,
  newLine: string
) => {
  const panelsMapForNewLineJSON = new Map<string,
  {
    quoteLineItemData: QuoteLineItem;
    quoteLineItemDetailData: QuoteLineItemDetail;
    panelNumber?: number;
    panelLocation?: PanelLocations;
    seamWeldPieceNumber?: number;
  }>();
  assemblyPieces.forEach(assemblyPanel => {
    const panelKeyForAssemblyPanel = `${oldLine} - ${assemblyPanel.quoteLineItemData.piece}`;
    const existingPanelForAssembly = panelsMapForJSON.get(panelKeyForAssemblyPanel);

    panelsMapForNewLineJSON.set(`${newLine} - ${assemblyPanel.quoteLineItemData.piece}`, {
      ...existingPanelForAssembly,
      quoteLineItemData: {
        ...existingPanelForAssembly.quoteLineItemData,
        Line__c: newLine
      },
      quoteLineItemDetailData: {
        ...existingPanelForAssembly.quoteLineItemDetailData,
      }
    });
  });

  return panelsMapForNewLineJSON;
};

export const removeFromPanelsMapForSplitOutLines = (
  assemblyPieces: {
    quoteLineItemData: QuoteLineItem;
    quoteLineItemDetailData: QuoteLineItemDetail;
    metal: MSTOAssemblyMetals;
    panelNumber?: number;
    panelLocation?: PanelLocations;
    seamWeldPieceNumber?: number;
  }[],
  panelsMapForJSON: Map<string,
  {
    quoteLineItemData: QuoteLineItem;
    quoteLineItemDetailData: QuoteLineItemDetail;
    panelNumber?: number;
    panelLocation?: PanelLocations;
    seamWeldPieceNumber?: number;
  }>,
  line: string
) => {
  const panelKeysToRemove = [];
  Array.from(panelsMapForJSON.keys()).filter(key => key.split(' - ')[0] === line).forEach((panelKey, keyIndex) => {
    if(keyIndex + 1 > assemblyPieces.length) {
      panelKeysToRemove.push(panelKey);
    }
  });

  panelKeysToRemove.forEach(keyToRemove => {
    panelsMapForJSON.delete(keyToRemove);
  });

  return panelsMapForJSON;
};

// Returns the correct salesforce quote line item property based on the column header
export const getColumnIdFromName = (colName: QuoteLineItemsColumnHeadings) => {
  return {
    [QuoteLineItemsColumnHeadings.Line]: 'Line__c'
  }[colName];
};

// Sorts the quote line items according to the sort column
export const getSortedQuoteLineItems = (quoteLineItems, quoteLineItemSortAsc = true) => {
  const quoteLineItemSortColumnId = getColumnIdFromName(QuoteLineItemsColumnHeadings.Line);

  let updatedItems;

  if(quoteLineItems) {
    updatedItems = [...quoteLineItems];
  }

  switch(quoteLineItemSortColumnId) {
    default:
      updatedItems = updatedItems?.orderBy(item => item.Parent_Line__c ? +item.Parent_Line__c + 0.1 + (item.Show_Detail__c ? 0.1 : 0) : +item[quoteLineItemSortColumnId], quoteLineItemSortAsc);
      break;
  }

  return updatedItems;
};

// Retrieves all the quote line item details from the quote line items
export const getAllQuoteLineItemDetails = (quoteLineItems: QuoteLineItem[]) => {
  return !quoteLineItems ? [] : quoteLineItems.map(qli => qli.quoteLineItemDetail);
};

// Build the quote fields to display for order entry
export const buildOrderEntryQuoteFieldsToUpdate = (quote: Quote, qlis: QuoteLineItem[], displayUOM: MeasurementSystems) => {
  const { Manufacturing_Site__c: manufacturingSite } = quote || {};
  const isUS = manufacturingSite === ManufacturingSites.US01;

  const firstItem: QuoteLineItem = qlis?.[0] || [] as QuoteLineItem;

  const formatState = (state) => {
    if(state) {
      if(isUS) {
        const shortStateCode = findStateCode(state);
        return shortStateCode?.value || state;
      }
      return state;
    }
    return;
  };

  const formatCountry = (country) => {
    if(!country) {
      return;
    }

   if(checkForUSASpelling(country)) {
    return 'USA';
   }

   const countryCode = findCountryCode(country);
   return countryCode?.value || country;
  };

  return {
    additionalPurchaseOrderNotesForBaseMetalSelections: quote?.AdditionalPONotesForBaseMetalSelections__c,
    additionalPurchaseOrderNotesForCladMetalSelections: quote?.AdditionalPONotesForCladMetalSelections__c,
    allFinishedGoodsOnSamePO: quote?.AllFinishedGoodsOnSamePO__c,
    baseCertificateApprovalatVendor: quote?.BaseCertificateApprovalatVendor__c,
    baseRetestAtDMCBeforeCladding: quote?.BaseRetestAtDMCBeforeCladding__c,
    baseTestCouponCutWitnessAtVendor: quote?.BaseTestCouponCutWitnessatVendor__c,
    baseTestWitnessatVendor: quote?.BaseTestWitnessatVendor__c,
    certificatesToBeSentBy: quote?.CertificatesToBeSentBy__c,
    cladCertificateApprovalatVendor: quote?.CladCertificateApprovalatVendor__c,
    cladDestructiveTesting: quote?.CladDestructiveTesting__c,
    claddingMetalRetestatDMCbeforeCladding: quote?.CladdingMetalRetestatDMCbeforeCladding__c,
    claddingMetalTestWitnessatVendor: quote?.CladdingMetalTestWitnessatVendor__c,
    cladNDTandFinalInspection: quote?.CladNDTAndFinalInspection__c,
    cladTestCouponCutWitnessatVendor: quote?.CladTestCouponCutWitnessatVendor__c,
    cladTestSpecimenRemovalAndStamping: quote?.CladTestSpecimenRemovalAndStamping__c,
    commissionPaidTo: quote?.CommissionPayTo__c,
    customerContactsForJob: quote?.CustomerContactforJob__c ? quote?.CustomerContactforJob__c : quote?.Order_Contact__c,
    customerDocumentUOM: quote?.DocumentUOM__c ?? displayUOM,
    customerPONumber: quote?.CustomerPONumber__c,
    doAllItemsShipTheSameWay: quote?.Doallitemsshipthesameway__c,
    doAllItemsShipToSameAddress: quote?.Doallitemsshiptosameaddress__c,
    documentReviewApprovalAtDMC: quote?.DocumentReviewAndApprovalAtDMC__c,
    generalCommentsForJobPlanning: quote?.GeneralCommentsForJobPlanning__c,
    headFormingEvent: quote?.HeadFormingEvent__c,
    headInspectionAtFormer: quote?.HeadInspectionAtFormer__c,
    heatProcedureDateNeeded: quote?.HeatProcedureDate__c,
    heatProcedureDetails: quote?.HeatProcedureNotes__c,
    heatTreatmentProcedureDateNeeded: quote?.HeatTreatmentProcedureDate__c,
    heatTreatmentProcedureDetails: quote?.HeatTreatmentProcedureNotes__c,
    incoterms: quote?.Incoterms__c,
    incotermsDescription: quote?.IncotermsDescription__c,
    incotermLocation: quote?.IncotermLocation__c,
    inspectionAndTestPlanProcedureDateNeeded: quote?.InspectionAndTestPlanProcedureDate__c,
    inspectionAndTestPlanProcedureDetails: quote?.InspectionAndTestPlanProcedureNotes__c,
    inspectionCompanyOption: quote?.InspectionCompanyOption__c,
    inspectionCompany: quote?.InspectionCompany__c,
    nonDestructiveEvaluationTestingProcedureDateNeeded: quote?.NonDestructiveTestingProcedureDate__c,
    nonDestructiveEvaluationTestingProcedureDetails: quote?.NonDestructiveTestingProcedureNotes__c,
    okToShipEarly: quote?.OkToShipEarly__c,
    orderBookedDate: quote?.OrderBookedDate__c,
    otherProcedureDateNeeded: quote?.OtherProcedureDate__c,
    otherProcedureDetails: quote?.OtherProcedureNotes__c,
    partialShipmentsAllowed: quote?.PartialShipmentsAllowed__c,
    paymentTerms: quote?.PaymentTerms__c,
    paymentTermsNotes: quote?.PaymentTermsDescription__c,
    pedRequalificationOfBaseMetal: quote?.PEDRequalificationOfBaseMetal__c,
    procedureApprovalsSendTo: quote?.ProcedureApprovalsSendTo__c,
    sendCertificatesTo: quote?.SendCertificatesTo__c,
    shippingStreet: quote?.Shipping_Street__c ??  firstItem?.Shipping_Street__c ?? quote?.Account__r?.ShippingStreet,
    shippingCity: quote?.Shipping_City__c ?? firstItem?.Shipping_City__c ?? quote?.Account__r?.ShippingCity,
    shippingState: formatState(quote?.Shipping_State__c ?? firstItem?.Shipping_State__c ?? quote?.Account__r?.ShippingState),
    shippingPostalCode: quote?.Shipping_Postal_Code__c ?? firstItem?.Shipping_Postal_Code__c ??  quote?.Account__r?.ShippingPostalCode,
    shippingCountry: formatCountry(quote?.Shipping_Country__c ?? firstItem?.Shipping_Country__c ?? quote?.Account__r?.ShippingCountry),
    shipToAddress: null, //TODO: fix in future story, 25660
    shipToAddressType: null, //TODO: fix in future story, 25660
    shipVia: quote?.ShipVia__c,
    weldingProcedureDateNeeded: quote?.WeldingProcedureDate__c,
    weldingProcedureDetails: quote?.WeldingProcedureNotes__c,
  };
};

export const getSpecificationTestOptions = (
  CladProductSpecification__c: string,
  specificationTestOption: SpecificationTestOptions[],
  areaId: ManufacturingSites
) => {
  const cladProductSpecsLabel = CladProductSpecification__c?.split('&')[0].trim();
  return specificationTestOption?.filter((specificationTestOption) => {
      return specificationTestOption.specification === cladProductSpecsLabel && specificationTestOption.dataAreaId === areaId;
    })
    .map(specificationTestOption => {
      return `${specificationTestOption.specification}: ${specificationTestOption.option}`;
    });
};

export const getRawMaterialsOptions = (
  BaseMetal__c: string,
  rawMaterials: RawMaterial[],
  areaId: ManufacturingSites
) => {

  const material = rawMaterials.find((rawMaterial: RawMaterial) =>
  rawMaterial.name === BaseMetal__c &&
  rawMaterial.usage === RawMaterialUsage.Base &&
  rawMaterial.dataAreaId.includes(areaId)
);
  const strs = material?.baseMetalUTOptions[areaId]?.map(bmUTOptions => bmUTOptions.option) || [];
  const newOptions = [
    ...strs,
    PostCladBaseMetalUTSpecifications.Other
  ];

  return newOptions;
};

export const computeFTNewThickness = (minTK: number, thinningValue: number, ftValue: number) => {
  return [minTK, thinningValue, ftValue].sum();
};