import { getLastViewed, updateLastViewed } from './last-viewed-api';
import * as React from 'react';
import { LastViewed } from '@interfaces/last-viewed';

const { createContext, useContext } = React;

const LastViewedContext = createContext(null);

export const LastViewedProvider = (props) => {
  const value = {
    getItemLastViewed: props.getItemLastViewed || getItemLastViewed,
    updateItemLastViewed: props.updateItemLastViewed || updateItemLastViewed
  };

  return (
    <LastViewedContext.Provider value={value}>
      {props.children}
    </LastViewedContext.Provider>
  );
};

export const useLastViewed = () => {
  return useContext(LastViewedContext);
};

// Calls api to get last viewed from cosmos
export const getItemLastViewed = async(itemId: string): Promise<string> => {
  try {
    return await getLastViewed(itemId);
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Calls api to update last viewed in cosmos
export const updateItemLastViewed = async(lastViewed: LastViewed): Promise<any> => {
  try {
    return await updateLastViewed(lastViewed);
  } catch (error) {
    console.error(error);
    return null;
  }
};
