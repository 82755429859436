import React from 'react';
import PanelAdvSpec from '../panel-sections/adv-spec/adv-spec';
import PanelFreight from '../panel-sections/freight/freight';
import PanelOutsideService from '../panel-sections/outside-service/outside-service';
import PanelPostCladProcessing from '../panel-sections/post-clad-processing/post-clad-processing';
import { SegmentProvider } from '@pages/new-advanced-specifications/segments/segment-context';
import { Quote } from '@interfaces/quote';
import PanelCylinderCanRolling from '../panel-sections/cylinder-can-rolling/cylinder-can-rolling';
import { AssemblyGroupFormKeys } from '../assembly-group-form-util';
import PanelHeadForming from '../panel-sections/head-forming/head-forming';
import { AssemblyGroupsColumnHeadings } from '@constants';
import { IonGrid, IonRow, IonCol, IonCard } from '@ionic/react';
import { AppLabel } from '@components/app-label/app-label';
import { AppLabelCurrency } from '@components/app-label-currency/app-label-currency';
import { Types } from '@shared/app-helpers';
import './panel-item.scss';

export interface PanelProp {
  form: any;
  areaId: string;
  updateForm: (key: string, value: any) => void;
  updateFormByObject: (payload: {}) => void;
  quote: Quote;
};

const TableInfo = ({ form }: PanelProp) => {
  const table = [
    { header: AssemblyGroupsColumnHeadings.Line, size: '0.4', value: form[AssemblyGroupFormKeys.Line] },
    { header: AssemblyGroupsColumnHeadings.Quantity, size: '0.5', value: form[AssemblyGroupFormKeys.Quantity] },
    {
      header: AssemblyGroupsColumnHeadings.Width,
      size: '0.8',
      value: <AppLabel
        metric={form[AssemblyGroupFormKeys.Width_mm]}
        imperial={form[AssemblyGroupFormKeys.Width_in]}
        type={Types.Lengths}
      />
    },
    {
      header: AssemblyGroupsColumnHeadings.Length,
      size: '0.8',
      value: <AppLabel
        metric={form[AssemblyGroupFormKeys.Length_mm]}
        imperial={form[AssemblyGroupFormKeys.Length_in]}
        type={Types.Lengths}
      />
    },
    {
      header: AssemblyGroupsColumnHeadings.CladNom,
      size: '0.8',
      value: <AppLabel
        metric={form[AssemblyGroupFormKeys.Clad_Nom_mm]}
        imperial={form[AssemblyGroupFormKeys.Clad_Nom_in]}
        type={Types.Lengths}
      />
    },
    {
      header: AssemblyGroupsColumnHeadings.CladMin,
      size: '0.8',
      value: <AppLabel
        metric={form[AssemblyGroupFormKeys.Clad_Min_mm]}
        imperial={form[AssemblyGroupFormKeys.Clad_Min_in]}
        type={Types.Lengths}
      />
    },
    {
      header: AssemblyGroupsColumnHeadings.BaseNom,
      size: '0.8',
      value: <AppLabel
        metric={form[AssemblyGroupFormKeys.Base_Nom_mm]}
        imperial={form[AssemblyGroupFormKeys.Base_Nom_in]}
        type={Types.Lengths}
      />
    },
    {
      header: AssemblyGroupsColumnHeadings.BaseMin,
      size: '0.8',
      value: <AppLabel
        metric={form[AssemblyGroupFormKeys.Base_Min_mm]}
        imperial={form[AssemblyGroupFormKeys.Base_Min_in]}
        type={Types.Lengths}
      />
    },
    { header: AssemblyGroupsColumnHeadings.Product, size: '1.6', value: form[AssemblyGroupFormKeys.Item_Type] || '- -' },
    { header: AssemblyGroupsColumnHeadings.Shape, size: '1.6', value: form[AssemblyGroupFormKeys.Shape] },
    {
      header: AssemblyGroupsColumnHeadings.Price,
      value: <AppLabelCurrency currency={form.CurrencyIsoCode} value={form[AssemblyGroupFormKeys.TotalAdjPrice]}/>
    }
  ];

  return <IonCard style={{
    padding: 0,
    margin: '10px 0',
   }}>
    <IonGrid style={{ padding: 0 }}>
      <IonRow>
        {table.map((h) => {
          return <IonCol className="table-header-col" size={h.size} key={`table-header-${h.header}`}>
            {h.header}
          </IonCol>;
        })}
      </IonRow>
      <IonRow>
        {table.map((h) => {
          return <IonCol className='table-values-col' size={h.size} key={`table-values-${h.header}`}>
            {h.value}
          </IonCol>;
        })}
      </IonRow>
    </IonGrid>
  </IonCard>
}

const PanelItem = (prop: PanelProp) => {
  return <div className='panel-item'>
    <TableInfo {...prop}/>
    <SegmentProvider>
      <PanelAdvSpec {...prop}/>
    </SegmentProvider>
    <PanelPostCladProcessing {...prop}/>
    {/* should only be shown when item type is cylinder */}
    { prop.form[AssemblyGroupFormKeys.Item_Type] === 'Cylinder' && <PanelCylinderCanRolling {...prop}/>}
    {/* should only be shown when item type is head */}
    { prop.form[AssemblyGroupFormKeys.Item_Type] === 'Head' && <PanelHeadForming {...prop}/>}
    <PanelOutsideService  {...prop}/>
    <PanelFreight {...prop}/>
  </div>
};

export default PanelItem;