import React, { useState } from 'react';

import Modal from '@components/modal/modal';
import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import AppCheckboxItem from '@components/app-checkbox-item/app-checkbox-item';
import AppLineItem from '@components/app-line-item/app-line-item';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import AppInput from '@components/app-input/app-input';
import AppRadioButtons from '@components/app-radio-buttons/app-radio-buttons';

import './customer-specified-impact-testing.scss';
import { FormKeys } from '../advance-specification-form-util';
import AppDropdownSelection from '@components/app-dropdown/app-dropdown-selection';
import { QuoteLineItemValueTypes } from '@constants';

interface Form {

};

interface Props {
  areaId: any,
  form?: Form,
  onDismiss: (payload) => void
  onSave,
};


const CustomerSpecifiedImpactTesting = ({onDismiss, form, onSave, areaId}: Props) => {
  const [ item, setItem ] = useState({
    performed: form[FormKeys.CustomerSpecifiedImpactTestToBePerformed],
    location: form[FormKeys.CustomerSpecifiedImpactTestLocation],
    direction: form[FormKeys.CustomerSpecifiedImpactTestDirection],
    temp: form[FormKeys.CustomerSpecifiedImpactTestTemperature],
    tempUOM: form[FormKeys.CustomerSpecifiedImpactTestTempUOM],
    uom: form[FormKeys.CustomerSpecifiedImpactTestUOM],
    avg: form[FormKeys.CustomerSpecifiedImpactTestAVG],
    min: form[FormKeys.CustomerSpecifiedImpactTestMIN],
  });

  const actionsButtons = [
    {
      label: 'Save',
      color: 'primary',
      onClick: () => {
        const payload = {
          [FormKeys.CustomerSpecifiedImpactTestToBePerformed]: item.performed,
          [FormKeys.CustomerSpecifiedImpactTestLocation]: item.location,
          [FormKeys.CustomerSpecifiedImpactTestDirection]: item.direction,
          [FormKeys.CustomerSpecifiedImpactTestTemperature]: item.temp,
          [FormKeys.CustomerSpecifiedImpactTestTempUOM]: item.tempUOM,
          [FormKeys.CustomerSpecifiedImpactTestUOM]: item.uom,
          [FormKeys.CustomerSpecifiedImpactTestAVG]: item.avg,
          [FormKeys.CustomerSpecifiedImpactTestMIN]: item.min,
        };

        onSave(payload);
      }
    },
    {
      label: 'Cancel',
      color: 'secondary',
      onClick: () => {
        onDismiss({})
      }
    }
  ];

  const onUpdateItem = (key) => {
    return value => {
      setItem({
        ...item,
        [key]: value
      });
    }
  }

  return <>
    <Modal
      title="Customer Specified Impact Testing"
      options={{
        isOpen: true,
        size: 'adv-spec'
      }}
      onClickClose={() => onDismiss({})}
      actionButtons={actionsButtons}
    >
      <div className='customer-specified-impact-testing-container'>
        <AppLineItem label="To be Performed" sizes={['3', '4']}>
          <AppDropdownSelection
            areaId={areaId}
            valueType={QuoteLineItemValueTypes.CustomerSpecifiedImpactTestToBePerformed}
            value={item.performed}
            onChange={({value}) => onUpdateItem('performed')(value)}
          />
        </AppLineItem>
        <AppLineItem label="Location" sizes={['3', '4']}>
          <AppDropdownSelection
            areaId={areaId}
            valueType={QuoteLineItemValueTypes.CustomerSpecifiedImpactTestLocation}
            value={item.location}
            onChange={({value}) => onUpdateItem('location')(value)}
          />
        </AppLineItem>
        <AppLineItem label="Direction" sizes={['3', '4']}>
          <AppDropdownSelection
            areaId={areaId}
            valueType={QuoteLineItemValueTypes.CustomerSpecifiedImpactTestDirection}
            value={item.direction}
            onChange={({value}) => onUpdateItem('direction')(value)}
          />
          <div style={{ margin: '5px 0' }}>
            <AppRadioButtons
              name={FormKeys.CustomerSpecifiedImpactTestUOM}
              selected={item.uom}
              onChange={onUpdateItem('uom')}
              options={['FT-LB', 'Joules']}
            />
          </div>
          <AppLineItemColumns
            components={[
              {
                component: <AppInput value={item.avg} onChange={onUpdateItem('avg')}/>,
              },
              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
                component: <IonLabel>Avg/</IonLabel>,
              },
              {
                component: <AppInput value={item.min} onChange={onUpdateItem('min')}/>,
              },
              {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
                component: <IonLabel>Min</IonLabel>,
              }
            ]}
          />
        </AppLineItem>
        <AppLineItem label="Temperature" sizes={['3', '4']}>
          <AppLineItemColumns
            components={[
              {
                size: '3',
                component: <AppInput
                  value={item.temp}
                  onChange={onUpdateItem('temp')}
                />,
              },
              {
                size: '9',
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
                component: <AppRadioButtons
                  selected={item.tempUOM}
                  name={FormKeys.CustomerSpecifiedImpactTestTempUOM}
                  onChange={onUpdateItem('tempUOM')}
                  options={['F', 'C']}
                />,
              }
            ]}
          />
        </AppLineItem>
      </div>
    </Modal>
  </>
};

export default CustomerSpecifiedImpactTesting;