import * as React from 'react';
import { BoosterAllowances } from '@interfaces/booster-allowances';
import { EdgeAllowances } from '@interfaces/edge-allowances';
import { FinishedGood } from '@interfaces/finished-good';
import { Freight } from '@interfaces/freight';
import { RawMaterial } from '@interfaces/raw-material';
import { SpecificationTestOptions } from '@interfaces/specification-test-options';
import { Selection } from '@interfaces/selection';
import { QuoteVariableConfiguration } from '@interfaces/quote-variable-configurations';
import { RawMaterialCombination } from '@interfaces/raw-material-combination';
import { Vendor } from '@interfaces/vendor';
import { WeldingPattern } from '@interfaces/welding-patterns';
import { createContext, Dispatch, useContext, useReducer } from 'react';
import { Content } from '@interfaces/content';
import { useRawMaterials } from '@services/raw-materials/raw-materials-service';
import { useFinishedGoods } from '@services/finished-goods/finished-goods-service';
import { useFreight } from '@services/freight/freight-service';
import { useContent } from '@services/content/content-service';
import { useEdgeAllowances } from '@services/edge-allowances/edge-allowances-service';
import { useSpecificationTestOptions } from '@services/specification-test-options/specification-test-options-service';
import { useBoosterAllowances } from '@services/booster-allowances/booster-allowances-service';
import { useSelections } from '@services/selections/selections-service';
import { useQuoteVariableConfigurations } from '@services/quote-variable-configurations/quote-variable-configurations-service';
import { useRawMaterialCombinations } from '@services/raw-material-combinations/raw-material-combinations-service';
import { useVendors } from '@services/vendors/vendors-service';
import { useWeldingPatterns } from '@services/welding-patterns/welding-patterns-service';
import { ContentSlugs, ManufacturingSites } from '@constants';
import { DataActionPayloadTypes } from '@shared/types';
import { OutsideServiceCosts } from '@interfaces/outside-service-costs';
import { useOutsideServiceCosts } from '@services/outside-service-costs/outside-service-costs-service';

export interface InitDataContextProps {
  rawMaterials: RawMaterial[];
  finishedGoods: FinishedGood[];
  freight: Freight[];
  edgeAllowances: EdgeAllowances[];
  specificationTestOptions: SpecificationTestOptions[];
  boosterAllowances: BoosterAllowances[];
  selections: Selection[];
  quoteVariableConfigurations: QuoteVariableConfiguration[];
  rawMaterialCombinations: RawMaterialCombination[];
  vendors: Vendor[];
  weldingPatterns: WeldingPattern[];
  paymentTermsContent: Content;
  contractTermsContent: Content;
  cancellationTermsContent: Content;
  validityContent: Content;
  outsideServiceCosts: OutsideServiceCosts[];
}

const INITIAL_LOAD_STATE: InitDataContextProps = {
  rawMaterials: undefined,
  finishedGoods: undefined,
  freight: undefined,
  edgeAllowances: undefined,
  specificationTestOptions: undefined,
  boosterAllowances: undefined,
  selections: undefined,
  quoteVariableConfigurations: undefined,
  rawMaterialCombinations: undefined,
  vendors: undefined,
  weldingPatterns: undefined,
  paymentTermsContent: undefined,
  contractTermsContent: undefined,
  cancellationTermsContent: undefined,
  validityContent: undefined,
  outsideServiceCosts: undefined
};

interface InitStateContextProps {
  dataState: InitDataContextProps;
  loadRawMaterials: () => void;
  loadFinishedGoods: () => void;
  loadFreight: () => void;
  loadContent: () => void;
  loadEdgeAllowances: () => void;
  loadSpecificationTestOptions: () => void;
  loadBoosterAllowances: () => void;
  loadSelections: () => void;
  loadQuoteVariableConfigurations: () => void;
  loadRawMaterialCombinations: (baseMetal: string, cladMetal: string, manufacturingSite: ManufacturingSites) => void;
  loadVendors: () => void;
  loadWeldingPatterns: () => void;
  loadOutsideServiceCosts: () => void;
}

interface InitDispatchContextProps {
  dispatchDataState: Dispatch<Action>;
}

interface ProviderProps {
  children: React.ReactNode;
}

const DataContext = createContext<InitStateContextProps>({
  dataState: null,
  /* eslint-disable */
  loadRawMaterials() {},
  loadFinishedGoods() {},
  loadFreight() {},
  loadContent() {},
  loadEdgeAllowances() {},
  loadSpecificationTestOptions() {},
  loadBoosterAllowances() {},
  loadSelections() {},
  loadQuoteVariableConfigurations() {},
  loadRawMaterialCombinations() {},
  loadVendors() {},
  loadWeldingPatterns() {},
  loadOutsideServiceCosts() {}
  /* eslint-enable */
});

const DataDispatchContext = createContext<InitDispatchContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatchDataState() {},
});

export enum DataActionTypes {
  loadRawMaterials = 'load-raw-materials',
  loadFinishedGoods = 'load-finished-goods',
  loadFreight = 'load-freight',
  loadEdgeAllowances = 'load-edge-allowances',
  loadSpecificationTestOptions = 'load-specification-test-options',
  loadBoosterAllowances = 'load-booster-allowances',
  loadSelections = 'load-selections',
  loadQuoteVariableConfigurations = 'load-quote-variable-configurations',
  loadRawMaterialCombinations = 'load-raw-material-combinations',
  loadVendors = 'load-vendors',
  loadWeldingPatterns = 'load-welding-patterns',
  loadPaymentTermsContent = 'load-payment-terms-content',
  loadContractTermsContent = 'load-contract-terms-content',
  loadCancellationTermsContent = 'load-cancellation-terms-content',
  loadValidityContent = 'load-validity-content',
  loadOutsideServiceCosts = 'load-outside-service-costs',
  setItem = 'setItem',
}

export type Action =
  | { type: DataActionTypes.loadRawMaterials; payload: RawMaterial[] }
  | { type: DataActionTypes.loadFinishedGoods; payload: FinishedGood[] }
  | { type: DataActionTypes.loadFreight; payload: Freight[] }
  | { type: DataActionTypes.loadEdgeAllowances; payload: EdgeAllowances[] }
  | { type: DataActionTypes.loadSpecificationTestOptions; payload: SpecificationTestOptions[] }
  | { type: DataActionTypes.loadBoosterAllowances; payload: BoosterAllowances[] }
  | { type: DataActionTypes.loadSelections; payload: Selection[] }
  | { type: DataActionTypes.loadQuoteVariableConfigurations; payload: QuoteVariableConfiguration[] }
  | { type: DataActionTypes.loadRawMaterialCombinations; payload: RawMaterialCombination[] }
  | { type: DataActionTypes.loadVendors; payload: Vendor[] }
  | { type: DataActionTypes.loadWeldingPatterns; payload: WeldingPattern[] }
  | { type: DataActionTypes.loadPaymentTermsContent; payload: Content }
  | { type: DataActionTypes.loadContractTermsContent; payload: Content }
  | { type: DataActionTypes.loadCancellationTermsContent; payload: Content }
  | { type: DataActionTypes.loadValidityContent; payload: Content }
  |  { type: DataActionTypes.loadOutsideServiceCosts; payload: OutsideServiceCosts[] }
  | { type: DataActionTypes.setItem; payload: { field: string; value: DataActionPayloadTypes } } // generic way to set a field with any value
  | Record<string, never>; // fallback for any other action

export const DataProvider = ({ children }: ProviderProps) => {
  const rawMaterials = useRawMaterials();
  const finishedGoods = useFinishedGoods();
  const freight = useFreight();
  const content = useContent();
  const edgeAllowances = useEdgeAllowances();
  const specificationTestOptions = useSpecificationTestOptions();
  const boosterAllowances = useBoosterAllowances();
  const selections = useSelections();
  const quoteVariableConfigurations = useQuoteVariableConfigurations();
  const rawMaterialCombinations = useRawMaterialCombinations();
  const vendors = useVendors();
  const weldingPatterns = useWeldingPatterns();
  const outsideServiceCosts = useOutsideServiceCosts(); 

  const dataReducer = (state = INITIAL_LOAD_STATE, action: Action) => {
    switch (action.type) {
      case DataActionTypes.loadRawMaterials:
        return { ...state, rawMaterials: action.payload };
      case DataActionTypes.loadFinishedGoods:
        return { ...state, finishedGoods: action.payload };
      case DataActionTypes.loadFreight:
        return { ...state, freight: action.payload };
      case DataActionTypes.loadEdgeAllowances:
        return { ...state, edgeAllowances: action.payload };
      case DataActionTypes.loadSpecificationTestOptions:
        return { ...state, specificationTestOptions: action.payload };
      case DataActionTypes.loadBoosterAllowances:
        return { ...state, boosterAllowances: action.payload };
      case DataActionTypes.loadSelections:
        return { ...state, selections: action.payload };
      case DataActionTypes.loadQuoteVariableConfigurations:
        return { ...state, quoteVariableConfigurations: action.payload };
      case DataActionTypes.loadRawMaterialCombinations:
        return { ...state, rawMaterialCombinations: action.payload };
      case DataActionTypes.loadVendors:
        return { ...state, vendors: action.payload };
      case DataActionTypes.loadWeldingPatterns:
        return { ...state, weldingPatterns: action.payload };
      case DataActionTypes.loadPaymentTermsContent:
        return { ...state, paymentTermsContent: action.payload };
      case DataActionTypes.loadContractTermsContent:
        return { ...state, contractTermsContent: action.payload };
      case DataActionTypes.loadCancellationTermsContent:
        return { ...state, cancellationTermsContent: action.payload };
      case DataActionTypes.loadValidityContent:
        return { ...state, validityContent: action.payload };
      case DataActionTypes.loadOutsideServiceCosts:
        return { ...state, outsideServiceCosts: action.payload };
      case DataActionTypes.setItem: // top level field setter, not typed
        return { ...state, [action.payload.field]: action.payload.value };
      /** Return state as a fallback */
      default:
        return state;
    }
  };

  const [dataState, dispatchDataState] = useReducer(dataReducer, INITIAL_LOAD_STATE);

  // Loads all the raw materials
  const loadRawMaterials = async () => {
    if(!dataState.rawMaterials) {
      dispatchDataState({type: DataActionTypes.loadRawMaterials, payload: await rawMaterials.getAllRawMaterials()});
    }
  };

  // Loads all the finished goods
  const loadFinishedGoods = async () => {
    if(!dataState.finishedGoods) {
      dispatchDataState({type: DataActionTypes.loadFinishedGoods, payload: await finishedGoods.getAllFinishedGoods()});
    }
  };

  // Loads all the freight
  const loadFreight = async () => {
    if(!dataState.freight) {
      dispatchDataState({type: DataActionTypes.loadFreight, payload: await freight.getAllFreight()});
    }
  };

  // Loads all the content (terms)
  const loadContent = async () => {
    if(!dataState.paymentTermsContent) {
      dispatchDataState({type: DataActionTypes.loadPaymentTermsContent, payload: (await content.getTermsContent(ContentSlugs.PaymentTerms))?.content});
    }

    if(!dataState.contractTermsContent) {
      dispatchDataState({type: DataActionTypes.loadContractTermsContent, payload: (await content.getTermsContent(ContentSlugs.ContractTerms))?.content});
    }

    if(!dataState.cancellationTermsContent) {
      dispatchDataState({type: DataActionTypes.loadCancellationTermsContent, payload: (await content.getTermsContent(ContentSlugs.CancellationTerms))?.content});
    }

    if(!dataState.validityContent) {
      dispatchDataState({type: DataActionTypes.loadValidityContent, payload: (await content.getTermsContent(ContentSlugs.Validity))?.content});
    }
  };

  // Loads all the edge allowances
  const loadEdgeAllowances = async () => {
    if(!dataState.edgeAllowances) {
      dispatchDataState({type: DataActionTypes.loadEdgeAllowances, payload: await edgeAllowances.getAllEdgeAllowances()});
    }
  };

  // Loads all the specification test options
  const loadSpecificationTestOptions = async () => {
    if(!dataState.specificationTestOptions) {
      dispatchDataState({type: DataActionTypes.loadSpecificationTestOptions, payload: await specificationTestOptions.getAllSpecificationTestOptions()});
    }
  };

  // Loads all the booster allowances
  const loadBoosterAllowances = async () => {
    if(!dataState.boosterAllowances) {
      dispatchDataState({type: DataActionTypes.loadBoosterAllowances, payload: await boosterAllowances.getAllBoosterAllowances()});
    }
  };

  // Loads all the selections
  const loadSelections = async () => {
    if(!dataState.selections) {
      dispatchDataState({type: DataActionTypes.loadSelections, payload: await selections.getAllSelections()});
    }
  };

  // Loads all the quote variable configurations
  const loadQuoteVariableConfigurations = async () => {
    if(!dataState.quoteVariableConfigurations) {
      dispatchDataState({type: DataActionTypes.loadQuoteVariableConfigurations, payload: await quoteVariableConfigurations.getAllQuoteVariableConfigurations()});
    }
  };

  // Adds the raw material combination for the specified metal combination and manufacturing site to the data state
  const loadRawMaterialCombinations = async (baseMetal: string, cladMetal: string, manufacturingSite: ManufacturingSites) => {
    const updatedRawMaterialCombinations = [...(dataState.rawMaterialCombinations || [])];

    if(!updatedRawMaterialCombinations.some(rmc => rmc.base === baseMetal && rmc.clad === cladMetal && rmc.dataAreaId === manufacturingSite)) {
      const rawMaterialCombinationForMetalCombo = await rawMaterialCombinations.getRawMaterialCombinationForMetalCombo(baseMetal, cladMetal, manufacturingSite);
      
      if(rawMaterialCombinationForMetalCombo) {
        updatedRawMaterialCombinations.push(rawMaterialCombinationForMetalCombo);
        dispatchDataState({type: DataActionTypes.loadRawMaterialCombinations, payload: updatedRawMaterialCombinations});
      }
    }
  };

  // Loads all the vendors
  const loadVendors = async () => {
    if(!dataState.vendors) {
      dispatchDataState({type: DataActionTypes.loadVendors, payload: await vendors.getAllVendors()});
    }
  };

  // Loads all the welding patterns
  const loadWeldingPatterns = async () => {
    if(!dataState.weldingPatterns) {
      dispatchDataState({type: DataActionTypes.loadWeldingPatterns, payload: await weldingPatterns.getAllWeldingPatterns()});
    }
  };

   // Loads all the OS costs 
   const loadOutsideServiceCosts = async () => {
    if(!dataState.outsideServiceCosts) {
      dispatchDataState({type: DataActionTypes.loadOutsideServiceCosts, payload: await outsideServiceCosts?.getAllOutsideServiceCosts?.()});
    }
  };
  

  return (
    <DataDispatchContext.Provider value={{ dispatchDataState }}>
      <DataContext.Provider value={{ dataState, loadRawMaterials, loadFinishedGoods, loadFreight,
      loadContent, loadEdgeAllowances,
      loadSpecificationTestOptions,
      loadBoosterAllowances,
      loadSelections,
      loadQuoteVariableConfigurations,
      loadRawMaterialCombinations,
      loadVendors,
      loadWeldingPatterns,
      loadOutsideServiceCosts
       }}
      >{children}</DataContext.Provider>
    </DataDispatchContext.Provider>
  );
};

// export useDataState hook
export const useDataState = () => {
  const context: InitStateContextProps = useContext(DataContext);
  if (!context) {
    throw new Error('You probably forgot the <DataProvider> context provider');
  }
  return context;
};

// export useDataDispatch hook
export const useDataDispatch = () => {
  const dispatch: InitDispatchContextProps = useContext(DataDispatchContext);
  if (!dispatch) {
    throw new Error('You probably forgot the <DataProvider> context provider');
  }
  return dispatch;
};

/**  Usage examples:
const { dataState } = useDataState();
const { dispatchDataState } = useDataDispatch();
dispatchDataState({ type: DataActionTypes.loadRawMaterials, payload: [] });
*/