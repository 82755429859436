import React from 'react';
import { IonButton, IonCol, IonImg, IonLabel, IonRow } from '@ionic/react';
import './po-number-popover.scss';
import { QuoteLineItemsColumnHeadings, QuoteLineItemValueTypes, SelectionTypes, QuoteLineItemFieldUnitTypes } from '@constants';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import LineItemField from '@components/line-item-field/line-item-field';
import SelectionContainer from '@components/selection-container/selection-container';
import { createLineNumber } from '@shared/quote-group-utils';
import { useQuoteState } from '@context/quote-context';
import { OrderEntryLineItemFieldsToUpdate } from '@shared/types';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';

const PONumberPopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
    showPopover: boolean;
    event: any;
  }>>,
  quoteLineItemFieldsToUpdate: { [key: string]: OrderEntryLineItemFieldsToUpdate; },
  setQuoteLineItemFieldsToUpdate: React.Dispatch<React.SetStateAction<{ [key: string]: OrderEntryLineItemFieldsToUpdate; }>>,
}) => {
  const { quoteState } = useQuoteState();
  const { quote} = quoteState;
  const {
    quoteLineItems
  } = quote || {};

  const { darkMode } = useDarkMode();
  const [quoteLineItemFieldsToUpdate] = React.useState(props.quoteLineItemFieldsToUpdate);
  
  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const saveQuoteLineItemPONumber = () => {
    props.setQuoteLineItemFieldsToUpdate(quoteLineItemFieldsToUpdate);
  };

  const cancelPONumber = () => {
    quoteLineItems.forEach(quoteLineItem => {
      const id = quoteLineItem.Id || quoteLineItem.displayId;
      quoteLineItemFieldsToUpdate[id].poNumber = quoteLineItem.CustomerPO__c;
    });
  };

  const LineItemDetails = (props: {
    lineItem: QuoteLineItem
  }) => {
    const id = props.lineItem.Id || props.lineItem.displayId;
    const [ poNumber, setPONumber ] = React.useState(quoteLineItemFieldsToUpdate[id].poNumber || props.lineItem?.CustomerPO__c);
    const updatePONumber = (number) => {
      setPONumber(number);
      quoteLineItemFieldsToUpdate[id].poNumber = number;
    };

    return (
      <IonRow class='po-number-line-items-row po-number-line-item-card-header'>
        <IonCol size='0.55' class='column-left'>
          <div className='column-container right-align'>
            <LineItemField
              valueType={QuoteLineItemValueTypes.Line}
              datatestid='QAPONumberPopoverAssemblyGroupNumber'
              displayedValue={createLineNumber(props.lineItem)}
            />
          </div>
        </IonCol>
        <IonCol size='7' class='column-left right-align'>
          <LineItemField
            valueType={QuoteLineItemValueTypes.ItemDescription}
            unitType={QuoteLineItemFieldUnitTypes.ItemDescription}
            datatestid='QAPONumberPopoverAssemblyGroupDescription'
            imperialValue={props.lineItem.ItemDescriptionImp__c}
            metricValue={props.lineItem.ItemDescriptionMetric__c}
            
          />
        </IonCol>
        <IonCol size='3' class='column-left'>
          <SelectionContainer
            placeholder="PO Number"
            type={SelectionTypes.TextInput}
            style={{ width: '253px', marginTop: '0', borderBottom: 'solid 1px var(--nc-hyperlink)'}}
            onBlur={poNumber => updatePONumber(poNumber)}
            value={poNumber}
          />
        </IonCol>
      </IonRow>
    );
  };

  const LineItemList = () => {
    return (
      <>
        {quoteLineItems?.orderBy(lineItem => lineItem.Line__c).map((lineItem) => {
          return <LineItemDetails lineItem={lineItem} key={`key-${lineItem.Id}`} />;
        })}
      </>
    );
  };

  const PONumbersTable = () => {
    const tableHeadersPONumbersTable = [
      { display: QuoteLineItemsColumnHeadings.Hashtag, colSize: '0.55' },
      { display: QuoteLineItemsColumnHeadings.Description, colSize: '7.05' },
      { display: QuoteLineItemsColumnHeadings.PONumber, colSize: '3' },
    ];

    return (
      <div className='po-number-items-container'>
        <TableHeaders labels={tableHeadersPONumbersTable}/>
        <LineItemList  />
      </div>
    );
  };

  return (
    <div className='popover po-number'>
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class='close-icon' onClick={() =>{
        dismissModal();
        }}
      ></IonImg>
      <div className='title-container'>
        <IonLabel class='po-number-title' data-testid={'QAPONumberPopoverHeader'}>
          PO Numbers
        </IonLabel>
      </div>
      <hr />
      <PONumbersTable/>
      <hr className='bottom-rule' />
      <div className='cta-button-container'>
        <IonButton color='primary' class='save-button' data-testid='QAPONumberPopoverSaveButton' onClick={() => {
          saveQuoteLineItemPONumber();
          dismissModal();
        }}
        >
          Save
        </IonButton>
        <IonButton color='secondary' class='cancel-button' onClick={() => {
          cancelPONumber();
          dismissModal();
        }}
        >
          Cancel
        </IonButton>
      </div>
    </div>
  );
};

export default PONumberPopover;