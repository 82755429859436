import React from 'react';
import { IonButton, IonImg, IonLabel } from '@ionic/react';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import './notification-popover.scss';
import parse from 'html-react-parser';
import moment from 'moment';
import { useDarkMode } from '@hooks/useDarkMode';

const NotificationPopover = (props) => {
  const { darkMode } = useDarkMode();

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  return (
    <div className="popover">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='notification-title' data-testid="QANotificationPopoverTitleHeader">{props.notification.title}</IonLabel>
      </div>
      <hr/>
      <div className="info-container">
        <IonLabel class="timestamp" data-testid="QANotificationPopoverTimestamp">{moment(props.notification.createdDateTime).format('L, LTS')}</IonLabel>
        <IonLabel class="description" data-testid="QANotificationPopoverDescription">{parse(props.notification.description)}</IonLabel>
      </div>
      <div className="cta-button-container">
        <IonButton color="primary" class="close-button" onClick={() => dismissModal()} data-testid="QANotificationPopoverCloseButton">Close</IonButton>
      </div>
    </div>
  );
};

export default NotificationPopover;