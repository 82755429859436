import React, { useEffect } from "react";
import AppDropdown from "./app-dropdown";
import { useDataState } from "@context/data-context";

const AppDropDownFinishedGoods = (props) => {
  const { itemType, areaId, valueType, value, onChange } = props;
  const { dataState } = useDataState();
  const { finishedGoods } = dataState;

  const type = finishedGoods.find((finishedGood) => {
    return finishedGood.name === itemType && finishedGood.dataAreaId === areaId;
  });

  const selection = type?.selections.find(selection => selection.valueType === valueType) || {}
  const options = selection.options?.map((option) => {
    return {
      label: option,
      value: option,
    };
  }) || [];

  const dropdownProps = {
    ...props,
    options,
  };

  useEffect(() => {
    // set default value to the first option if not yet set
    if (!value && onChange && options.length > 0) {
      onChange(options[0]);
    }
  }, [itemType, valueType, value]);

  return <AppDropdown {...dropdownProps}/>
};

export default AppDropDownFinishedGoods