import { IonRow, IonCol } from '@ionic/react';
import React from 'react';
import classNames from 'classnames';

import './app-line-item.scss';

interface Props {
  label: string | React.ReactNode | JSX.Element;
  children?: React.ReactNode;
  style?: {};
  sizes?: [string, string];
  light?: boolean
}

interface AppLineItemLabelProps {
  label: string | React.ReactNode;
  light?: boolean,
}

const AppLineItemLabel = ({ label, light }: AppLineItemLabelProps) => {
  if (typeof label === 'string') {
    return <span className={classNames({
      'app-line-item-label--text': true,
      'app-line-item-label--text-light': light
    })}>
      { label }
    </span>
  }

  return <div className='app-line-item-label--container'>{label}</div>;
};

const AppLineItem = ({ label, children, style, sizes, light }: Props) => {
  const [ labelSize, contentSize ] = sizes || [ '2', '8' ];

  return <>
    <IonRow className='app-line-item' style={{style}}>
      <IonCol sizeSm="12" sizeMd={labelSize}><AppLineItemLabel light={light} label={label}/></IonCol>
      <IonCol sizeSm='12' sizeMd={contentSize}>
      {children}
      </IonCol>
    </IonRow>
  </>
}

export default AppLineItem;