import Accordion from '@components/accordion/accordion';
import { MetalComboScroller } from '@components/metal-combo-scroller/metal-combo-scroller';
import SelectionContainer from '@components/selection-container/selection-container';
import { MeasurementSystems, MetalComboScrollerStyles, QuoteLineItemDependentUnwrittenFields, QuoteLineItemFields, QuoteLineItemValueTypes, Regexes, SelectionTypes, ToastMessages, ToastTypes, Units } from '@constants';
import { useQuoteState } from '@context/quote-context';
import { useQuoteGroup } from '@hooks/useQuoteGroup';
import { useToast } from '@hooks/useToast';
import { IonButton } from '@ionic/react';
import { buildGCAMetalComboLineFieldsToUpdate, buildMetalComboTotalWeights, DisplayedQuoteGroup } from '@shared/quote-group-utils';
import { calculateUOMValue, getAllQuoteLineItemDetails } from '@shared/quote-utils';
import { DependentUnwrittenFieldsMapValueType, GCAMetalComboLineFieldsToUpdate, MetalComboTotalWeights } from '@shared/types';
import React from 'react';
import './global-cost-adjustments-footer.scss';

const GlobalCostAdjustmentsFooter = (props: {
  unitsOfMeasure: MeasurementSystems;
  lineItemFieldsMap: {[key: string]: Map<QuoteLineItemFields, number>};
  setLineItemFieldsMap: React.Dispatch<React.SetStateAction<{
    [key: string]: Map<QuoteLineItemFields, number>;
  }>>
}) => {
  const { quoteState, saveQuoteLineItem } = useQuoteState();
  const { quote, isQuoteLocked } = quoteState;
  const {
    quoteLineItems
  } = quote || {};

  const [lineItemId, setLineItemId] = React.useState('');
  const displayedQuoteGroup: DisplayedQuoteGroup = useQuoteGroup(quoteLineItems, getAllQuoteLineItemDetails(quoteLineItems), lineItemId);

  const [metalComboLineFieldsToUpdate] = React.useState<{[key: string]: GCAMetalComboLineFieldsToUpdate}>(buildGCAMetalComboLineFieldsToUpdate(displayedQuoteGroup));
  const [metalComboTotalWeights, setMetalComboTotalWeights] = React.useState<{[key: string]: MetalComboTotalWeights}>(buildMetalComboTotalWeights(displayedQuoteGroup));
  
  const isMetricUOM = props.unitsOfMeasure === MeasurementSystems.Metric;
  const toast = useToast(4000);

  const fieldsUpdated = [];

  const updateLineItemFieldsMapPerMetalCombo = (field: QuoteLineItemFields, value: number) => {
    const updatedLineItemFieldsMap: {[key: string]: Map<QuoteLineItemFields, number>} = props.lineItemFieldsMap;

    displayedQuoteGroup.metalComboToQlisMap.get(displayedQuoteGroup.metalComboKey).forEach(qli => {
      const quoteLineItemId = qli.Id || qli.displayId;

      if(!Object.keys(updatedLineItemFieldsMap).includes(quoteLineItemId)) {
        updatedLineItemFieldsMap[quoteLineItemId] = new Map();
      }

      updatedLineItemFieldsMap[quoteLineItemId].set(field, value);
    });

    props.setLineItemFieldsMap(updatedLineItemFieldsMap);
  };
  
  React.useEffect(() => {
    setMetalComboTotalWeights(buildMetalComboTotalWeights(displayedQuoteGroup));
  }, [quoteLineItems]);

  const GlobalCostAdjustments = () => {
    const totalShipWeight = () => {
      return isMetricUOM ? metalComboTotalWeights[displayedQuoteGroup.metalComboKey]?.totalShipWeightKg : metalComboTotalWeights[displayedQuoteGroup.metalComboKey]?.totalShipWeightLb;
    };

    const totalBaseWeight = () => {
      return isMetricUOM ? metalComboTotalWeights[displayedQuoteGroup.metalComboKey]?.totalBaseWeightKg : metalComboTotalWeights[displayedQuoteGroup.metalComboKey]?.totalBaseWeightLb;
    };

    const totalCladWeight = () => {
      return isMetricUOM ? metalComboTotalWeights[displayedQuoteGroup.metalComboKey]?.totalCladWeightKg : metalComboTotalWeights[displayedQuoteGroup.metalComboKey]?.totalCladWeightLb;
    };

    // Sets all the costs
    const setGlobalCosts = () => {
      try {
        quoteLineItems.forEach(quoteLineItem => {
          const quoteLineItemToEdit = { ...quoteLineItem };
          const lineItemFieldsMapPerMetalCombo = props.lineItemFieldsMap[quoteLineItem.Id || quoteLineItem.displayId];

          if(lineItemFieldsMapPerMetalCombo) {
            Array.from(lineItemFieldsMapPerMetalCombo.keys()).forEach(field => {
              quoteLineItemToEdit[field] = lineItemFieldsMapPerMetalCombo.get(field);
            });

            saveQuoteLineItem(new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>(), fieldsUpdated, quoteLineItemToEdit);
          }
        });
      }
      catch(error) {
        toast(ToastTypes.Error, ToastMessages.SettingGlobalCostsFailed);
        console.log(error);
        return;
      }

      toast(ToastTypes.Success, ToastMessages.SettingGlobalCostsComplete);
    };

    const getSelectionType = (defaultSelectionType: SelectionTypes) => {
      if(isQuoteLocked) {
        return SelectionTypes.ReadOnly;
      }

      return defaultSelectionType;
    };

    return (
      <div className='global-cost-adjustments'>
        <div className='top-section'>
          <MetalComboScroller
            displayedQuoteGroup={displayedQuoteGroup}
            setLineItemId={setLineItemId}
            style={MetalComboScrollerStyles.Vertical}
          />
          <div className='selections'>
            <div className='left-side'>
              <div>
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  selectionLabel={`Clad Cost Per ${isMetricUOM ? 'kg' : 'lb'}`}
                  placeholder='0.00'
                  inputType='number'
                  regex={Regexes.DecimalNumber}
                  min='0.00'
                  style={{ width: '80px', marginRight: '32px', marginBottom: '11px' }}
                  onBlur={cladCostPerWeightUnit => {
                    metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladCostPerWeightUnit = cladCostPerWeightUnit;
                    updateLineItemFieldsMapPerMetalCombo(QuoteLineItemFields.CladPriceLB, calculateUOMValue(props.unitsOfMeasure, MeasurementSystems.Imperial, cladCostPerWeightUnit, Units.PricePerKilogram, Units.PricePerPound));
                    updateLineItemFieldsMapPerMetalCombo(QuoteLineItemFields.CladPriceKG, calculateUOMValue(props.unitsOfMeasure, MeasurementSystems.Metric, cladCostPerWeightUnit, Units.PricePerKilogram, Units.PricePerPound));
                    fieldsUpdated.push(QuoteLineItemValueTypes.CladPricePerWeight);
                  }}
                  value={metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladCostPerWeightUnit}
                />
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  selectionLabel={`Base Cost Per ${isMetricUOM ? 'kg' : 'lb'}`}
                  placeholder='0.00'
                  inputType='number'
                  regex={Regexes.DecimalNumber}
                  min='0.00'
                  style={{ width: '80px', marginRight: '32px' }}
                  onBlur={baseCostPerWeightUnit => {
                    metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].baseCostPerWeightUnit = baseCostPerWeightUnit;
                    updateLineItemFieldsMapPerMetalCombo(QuoteLineItemFields.BasePriceLB, calculateUOMValue(props.unitsOfMeasure, MeasurementSystems.Imperial, baseCostPerWeightUnit, Units.PricePerKilogram, Units.PricePerPound));
                    updateLineItemFieldsMapPerMetalCombo(QuoteLineItemFields.BasePriceKG, calculateUOMValue(props.unitsOfMeasure, MeasurementSystems.Metric, baseCostPerWeightUnit, Units.PricePerKilogram, Units.PricePerPound));
                    fieldsUpdated.push(QuoteLineItemValueTypes.BasePricePerWeight);
                  }}
                  value={metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].baseCostPerWeightUnit}
                />
              </div>
              <div>
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  selectionLabel={`Clad Freight Per ${isMetricUOM ? 'kg' : 'lb'}`}
                  placeholder='0.00'
                  inputType='number'
                  regex={Regexes.DecimalNumber}
                  min='0.00'
                  style={{ width: '80px', marginBottom: '11px' }}
                  onBlur={cladFreightPerWeightUnit => {
                    metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladFreightPerWeightUnit = cladFreightPerWeightUnit;
                    updateLineItemFieldsMapPerMetalCombo(QuoteLineItemFields.CladFreightLB, calculateUOMValue(props.unitsOfMeasure, MeasurementSystems.Imperial, cladFreightPerWeightUnit, Units.PricePerKilogram, Units.PricePerPound));
                    updateLineItemFieldsMapPerMetalCombo(QuoteLineItemFields.CladFreightKG, calculateUOMValue(props.unitsOfMeasure, MeasurementSystems.Metric, cladFreightPerWeightUnit, Units.PricePerKilogram, Units.PricePerPound));
                    fieldsUpdated.push(QuoteLineItemValueTypes.CladFreightPerWeight);
                  }}
                  value={metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladFreightPerWeightUnit}
                />
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  selectionLabel={`Base Freight Per ${isMetricUOM ? 'kg' : 'lb'}`}
                  placeholder='0.00'
                  inputType='number'
                  regex={Regexes.DecimalNumber}
                  min='0.00'
                  style={{ width: '80px' }}
                  onBlur={baseFreightPerWeightUnit => {
                    metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].baseFreightPerWeightUnit = baseFreightPerWeightUnit;
                    updateLineItemFieldsMapPerMetalCombo(QuoteLineItemFields.BaseFreightLB, calculateUOMValue(props.unitsOfMeasure, MeasurementSystems.Imperial, baseFreightPerWeightUnit, Units.PricePerKilogram, Units.PricePerPound));
                    updateLineItemFieldsMapPerMetalCombo(QuoteLineItemFields.BaseFreightKG, calculateUOMValue(props.unitsOfMeasure, MeasurementSystems.Metric, baseFreightPerWeightUnit, Units.PricePerKilogram, Units.PricePerPound));
                    fieldsUpdated.push(QuoteLineItemValueTypes.BaseFreightPerWeight);
                  }}
                  value={metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].baseFreightPerWeightUnit}
                />
              </div>
            </div>
            <div className='right-side'>
              <div>
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  selectionLabel={`Freight Out Per ${isMetricUOM ? 'kg' : 'lb'}`}
                  placeholder='0.00'
                  inputType='number'
                  regex={Regexes.DecimalNumber}
                  min='0.00'
                  style={{ width: '80px', marginRight: '32px', marginBottom: '11px' }}
                  onBlur={freightOutPerWeightUnit => {
                    metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].freightOutPerWeightUnit = freightOutPerWeightUnit;
                    updateLineItemFieldsMapPerMetalCombo(QuoteLineItemFields.TotalFreightLB, calculateUOMValue(props.unitsOfMeasure, MeasurementSystems.Imperial, freightOutPerWeightUnit, Units.PricePerKilogram, Units.PricePerPound));
                    updateLineItemFieldsMapPerMetalCombo(QuoteLineItemFields.TotalFreightKG, calculateUOMValue(props.unitsOfMeasure, MeasurementSystems.Metric, freightOutPerWeightUnit, Units.PricePerKilogram, Units.PricePerPound));
                    fieldsUpdated.push(QuoteLineItemValueTypes.TotalFreightPerWeight);
                  }}
                  value={metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].freightOutPerWeightUnit}
                />
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  selectionLabel='Total Ship Wt'
                  style={{ width: '80px', marginRight: '32px' }}
                  isDisabled={true}
                  value={totalShipWeight()}
                  formatter={(value) => {
                    return value?.toFixed(0) + (isMetricUOM ? ' KG' : ' LB');
                  }}
                />
              </div>
              <div>
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  selectionLabel='Total Base Wt'
                  style={{ width: '80px', marginRight: '32px', marginBottom: '11px' }}
                  isDisabled={true}
                  value={totalBaseWeight()}
                  formatter={(value) => {
                    return value?.toFixed(0) + (isMetricUOM ? ' KG' : ' LB');
                  }}
                />
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  selectionLabel='Total Clad Wt'
                  style={{ width: '80px', marginRight: '32px' }}
                  isDisabled={true}
                  value={totalCladWeight()}
                  formatter={(value) => {
                    return value?.toFixed(0) + (isMetricUOM ? ' KG' : ' LB');
                  }}
                />
              </div>
              <div>
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  selectionLabel='Base Ship'
                  placeholder='1 wks'
                  inputType='text'
                  style={{ width: '80px', marginBottom: '11px' }}
                  onBlur={baseShipTime => {
                    metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].baseShipTime = baseShipTime;
                  }}
                  value={metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].baseShipTime}
                />
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  selectionLabel='Clad Ship'
                  placeholder='1 wks'
                  inputType='text'
                  style={{ width: '80px' }}
                  onBlur={cladShipTime => {
                    metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladShipTime = cladShipTime;
                  }}
                  value={metalComboLineFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladShipTime}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='bottom-section'>
          <IonButton
            color="primary"
            class="save-button"
            data-testid='QAGlobalCostAdjustmentsFooterSaveButton'
            onClick={() => {
              setGlobalCosts();
            }}
            disabled={isQuoteLocked}
          >
            Save
          </IonButton>
        </div>
      </div>
    );
  };

  return (
    <div className='max-width-container'>
      <Accordion title='Global Cost Adjustments' content={<GlobalCostAdjustments/>}/>
    </div>
  );
};

export default GlobalCostAdjustmentsFooter;