import { environment } from '@environment';
import { getRefresh, setRefresh } from '@services/storage-service';
import axios from 'axios';

export async function postCall<ReturnType>(endpoint: string, body?: any, headers?: {[key: string]: any}): Promise<ReturnType> {
  return callApi('post', endpoint, body, headers);
}

export async function getCall<ReturnType>(endpoint: string): Promise<ReturnType> {
  return callApi<ReturnType>('get', endpoint);
}

export async function putCall<ReturnType>(endpoint: string, body?: any): Promise<ReturnType> {
  return callApi<ReturnType>('put', endpoint, body);
}

export async function deleteCall<ReturnType>(endpoint: string): Promise<ReturnType> {
  return callApi<ReturnType>('delete', endpoint);
}

// Makes api call based on method used
export async function callApi<ReturnType>(
  method: 'get' | 'post' | 'put' | 'delete',
  endpoint: string,
  body?: any, options: {[key: string]: any} = {}
): Promise<ReturnType> {

  // const featureFlags: FeatureFlag = await this.storageService.getFeatureFlags();
  const headers: {[key: string]: any} = {'Content-Type':  'application/json'};
  for (const key in options) {
    headers[key] = options[key];
  }

  const url = `${environment.API_BASE_URL}/${endpoint}`;

  let res: any;
  
  try {
    switch (method) {
    case 'put':
    case 'post':
      res = await axios[method](url, body, { headers });
      return res.data;
    case 'delete':
    default:
      res = await axios[method](url, { headers });
      return res.data;
    }
  } catch (error) {
    if (error.status === 401) {
      const refresh = await getRefresh();
      if (!refresh) {
        await setRefresh(true);
        location.reload();
      }
    }

    throw error;
  }
}
