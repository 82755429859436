import { IonLabel } from '@ionic/react';
import classNames from 'classnames';
import * as React from 'react';
import './tab.scss';

const Tab = (props: {
  isActive: boolean,
  label: string,
  setActiveTab: (value: React.SetStateAction<string>) => void
}) => {
  return (
    <IonLabel class={classNames({
      'line-items-label': true,
      'tab': true,
      'active': props.isActive
    })} data-testid={'QATab_' + props.label} onClick={() => props.setActiveTab(props.label)}
    >{props.label}</IonLabel>
  );
};

export default Tab;