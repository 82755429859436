import React from 'react';
import { IonButton, IonCol, IonIcon, IonImg, IonLabel, IonRow } from '@ionic/react';
import './ship-to-address-popover.scss';
import { SelectionTypes, QuoteLineItemValueTypes, QuoteLineItemsColumnHeadings, QuoteLineItemFieldUnitTypes, SearchFields } from '@constants';
import SelectionContainer from '@components/selection-container/selection-container';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import LineItemField from '@components/line-item-field/line-item-field';
import { createLineNumber } from '@shared/quote-group-utils';
import { useQuoteState } from '@context/quote-context';
import { checkmarkCircleOutline, closeCircleOutline, pencil } from 'ionicons/icons';
import { OrderEntryLineItemFieldsToUpdate } from '@shared/types';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';
import { countryCodes, usStates } from '@shared/data/countries';

const ShipToAddressPopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
    showPopover: boolean;
    event: any;
  }>>,
  quoteLineItemFieldsToUpdate: { [key: string]: OrderEntryLineItemFieldsToUpdate; },
  setQuoteLineItemFieldsToUpdate: React.Dispatch<React.SetStateAction<{ [key: string]: OrderEntryLineItemFieldsToUpdate; }>>
}) => {
  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const {
    quoteLineItems
  } = quote || {};

  const quoteShippingStreet = quote.Shipping_Street__c || 'Street Address';
  const quoteShippingCity = quote.Shipping_City__c || 'City';
  const quoteShippingState = quote.Shipping_State__c || 'State';
  const quoteShippingPostalCode = quote.Shipping_Postal_Code__c || 'Postal Code';
  const quoteShippingCountry = quote.Shipping_Country__c || 'Country';

  const { darkMode } = useDarkMode();
  const [quoteLineItemFieldsToUpdate] = React.useState(props.quoteLineItemFieldsToUpdate);

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const saveShipToAddress = () => {
    props.setQuoteLineItemFieldsToUpdate(quoteLineItemFieldsToUpdate);
  };

  const cancelShipToAddress = () => {
    quoteLineItems.forEach(quoteLineItem => {
      const id = quoteLineItem.Id || quoteLineItem.displayId;
      quoteLineItemFieldsToUpdate[id].streetAddress = quoteLineItem.Shipping_Street__c;
      quoteLineItemFieldsToUpdate[id].city = quoteLineItem.Shipping_City__c;
      quoteLineItemFieldsToUpdate[id].state = quoteLineItem.Shipping_State__c;
      quoteLineItemFieldsToUpdate[id].postalCode = quoteLineItem.Shipping_Postal_Code__c;
      quoteLineItemFieldsToUpdate[id].country = quoteLineItem.Shipping_Country__c;
    });
  };

  const LineItemDetails = (props: {
    lineItem: QuoteLineItem
  }) => {
    const id = props.lineItem.Id || props.lineItem.displayId;

    //address first has to check for shipping address updates, then line items saved to quote, and if neither of those exist it uses that address or default at the quote level.
    const [ streetAddress, setStreetAddress ] = React.useState((props.lineItem.Shipping_Street__c ? props.lineItem.Shipping_Street__c : quoteLineItemFieldsToUpdate[id].streetAddress) || quoteShippingStreet);
    const [ city, setCity ] = React.useState((props.lineItem.Shipping_City__c ? props.lineItem.Shipping_City__c : quoteLineItemFieldsToUpdate[id].city) || quoteShippingCity);
    const [ state, setState ] = React.useState((props.lineItem.Shipping_State__c ? props.lineItem.Shipping_State__c : quoteLineItemFieldsToUpdate[id].state) || quoteShippingState);
    const [ postalCode, setPostalCode ] = React.useState((props.lineItem.Shipping_Postal_Code__c ? props.lineItem.Shipping_Postal_Code__c : quoteLineItemFieldsToUpdate[id].postalCode) || quoteShippingPostalCode);
    const [ country, setCountry ] = React.useState((props.lineItem.Shipping_Country__c ? props.lineItem.Shipping_Country__c : quoteLineItemFieldsToUpdate[id].country) || quoteShippingCountry);

    const [ readOnly, setReadOnly ] = React.useState(true);

    const updatedAddress = {
      updatedStreetAddress: streetAddress,
      updatedCity: city,
      updatedState: state,
      updatedPostalCode: postalCode,
      updatedCountry: country
    };

    const updateShippingAddress = () => {
      setStreetAddress(updatedAddress.updatedStreetAddress);
      setCity(updatedAddress.updatedCity);
      setState(updatedAddress.updatedState);
      setPostalCode(updatedAddress.updatedPostalCode);
      setCountry(updatedAddress.updatedCountry);

      quoteLineItemFieldsToUpdate[id].streetAddress = updatedAddress.updatedStreetAddress;
      quoteLineItemFieldsToUpdate[id].city = updatedAddress.updatedCity;
      quoteLineItemFieldsToUpdate[id].state = updatedAddress.updatedState;
      quoteLineItemFieldsToUpdate[id].postalCode = updatedAddress.updatedPostalCode;
      quoteLineItemFieldsToUpdate[id].country = updatedAddress.updatedCountry;

      setReadOnly(true);
    };

    const EditableAddressFields = () => {
      return (
        <>
          <IonCol size='6' class='column-left'>
            <div className="address-update-button-container">
              <IonIcon class="check-icon" src={checkmarkCircleOutline} onClick={() => updateShippingAddress() }></IonIcon>
              <IonIcon class="close-address-icon" src={closeCircleOutline} onClick={() => setReadOnly(true) }></IonIcon>
            </div>
            <SelectionContainer
            type={SelectionTypes.TextArea}
            datatestid='QAShipToAddressAssemblyGroupStreetAddress'
            placeholder="Street Address"
            style={{ width: '263px', marginTop: '0', menuListHeight: '125px'}}
            onBlur={(streetAddress) => updatedAddress.updatedStreetAddress = streetAddress}
            value={updatedAddress.updatedStreetAddress}
            />
            <div className="flex-container">
              <SelectionContainer
              type={SelectionTypes.TextInput}
              datatestid='QAShipToAddressAssemblyGroupCity'
              placeholder="City"
              style={{ width: '102px', marginRight: '5px'}}
              onBlur={(city) => updatedAddress.updatedCity = city}
              value={updatedAddress.updatedCity}
              />
              <SelectionContainer
                type={SelectionTypes.SearchableDropdown}
                noneFoundText='No State Code Found'
                placeholder='State'
                options={usStates}
                suggestionDisplayFields={[SearchFields.Value, SearchFields.Label]}
                onChange={(state) => {
                  try {
                    updatedAddress.updatedState = state.value;
                  } catch (error) {
                    console.error(error);
                  }
                }}
                value={updatedAddress.updatedState}
              />
            </div>
            <div className="flex-container">
              <SelectionContainer
              type={SelectionTypes.TextInput}
              datatestid='QAShipToAddressAssemblyGroupPostalCode'
              placeholder="Postal Code"
              style={{ width: '102px', marginRight: '5px'}}
              onBlur={(postalCode) => updatedAddress.updatedPostalCode = postalCode}
              value={updatedAddress.updatedPostalCode}
              />
              <SelectionContainer
                type={SelectionTypes.SearchableDropdown}
                noneFoundText='No Country Code Found'
                placeholder='Country'
                options={countryCodes}
                suggestionDisplayFields={[SearchFields.Value, SearchFields.Label]}
                onChange={(country) => {
                  try {
                    updatedAddress.updatedCountry = country.value;
                  } catch (error) {
                    console.error(error);
                  }
                }}
                value={updatedAddress.updatedCountry}
              />
            </div>
          </IonCol>
        </>
      );
    };

    const DisplayAddressFields = () => {
      return (
        <>
          <IonCol size='6' class='column-left'>
            <IonIcon class="pencil-icon" src={pencil} onClick={() => setReadOnly(false) }></IonIcon>
            <span className="display-text" data-testid={'QAShipToAddressAssemblyGroupStreetAddressDisplay'}>{ streetAddress }</span>
            <div className="flex-container">
              <span className="display-text" data-testid={'QAShipToAddressAssemblyGroupCityDisplay'}>{ city + ',' }</span>
              <span className="display-text" data-testid={'QAShipToAddressAssemblyGroupStateDisplay'}>{ state }</span>
              <span className="display-text" data-testid={'QAShipToAddressAssemblyGroupPostalCodeDisplay'}>{ postalCode }</span>
            </div>
            <div className="flex-container">
              <span className="display-text" data-testid={'QAShipToAddressAssemblyGroupCountryDisplay'}>{ country }</span>
            </div>
          </IonCol>
        </>
      );
    };

    return (
      <IonRow class={readOnly ? 'ship-to-address-line-items-row-display ship-to-address-line-item-card-header-display' : 'ship-to-address-line-items-row-editable ship-to-address-line-item-card-header-editable'}>
        <IonCol size='0.55' class='column-left'>
          <div className='column-container right-align'>
            <LineItemField
              valueType={QuoteLineItemValueTypes.Line}
              datatestid='QAShipToAddressAssemblyGroupNumber'
              displayedValue={createLineNumber(props.lineItem)}
            />
          </div>
        </IonCol>
        <IonCol size='5' class='column-left right-align'>
          <LineItemField
            valueType={QuoteLineItemValueTypes.ItemDescription}
            unitType={QuoteLineItemFieldUnitTypes.ItemDescription}
            datatestid='QAShipToAddressPopoverAssemblyGroupDescription'
            imperialValue={props.lineItem.ItemDescriptionImp__c}
            metricValue={props.lineItem.ItemDescriptionMetric__c}
          />
        </IonCol>
        {readOnly ? <DisplayAddressFields/> : <EditableAddressFields/>}
      </IonRow>
    );
  };

  const LineItemList = () => {
    return (
      <>
        {quoteLineItems?.orderBy(lineItem => lineItem.Line__c).map((shipToAddressLineItem) => {
          return <LineItemDetails key={`shipToAddress${shipToAddressLineItem.Id}`} lineItem={shipToAddressLineItem}/>;
        })}
      </>
    );
  };

  const ShipToAddressTable = () => {
    const tableHeadersShipToAddressTable = [
      { display: QuoteLineItemsColumnHeadings.Hashtag, colSize: '0.55' },
      { display: QuoteLineItemsColumnHeadings.Description, colSize: '5.04' },
      { display: QuoteLineItemsColumnHeadings.ShipToAddress, colSize: '3' },
    ];

    return (
      <div className='ship-to-address-items-container'>
        <TableHeaders labels={tableHeadersShipToAddressTable}/>
        <LineItemList/>
      </div>
    );
  };

  return (
    <div className='popover ship-to-address'>
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class='close-icon' onClick={() => {
        dismissModal();
      }}
      ></IonImg>
      <div className='title-container'>
        <IonLabel class='ship-to-address-title' data-testid={'QAShipToAddressPopoverHeader'}>
          Ship To Address
        </IonLabel>
      </div>
      <hr />
      <ShipToAddressTable/>
      <hr className='bottom-rule' />
      <div className='cta-button-container'>
        <IonButton color='primary' class='save-button' data-testid='QAShipToAddressPopoverSaveButton' onClick={() => {
          saveShipToAddress();
          dismissModal();
        }}
        >
          Save
        </IonButton>
        <IonButton color='secondary' class='cancel-button' onClick={() => {
          cancelShipToAddress();
          dismissModal();
        }}
        >
          Cancel
        </IonButton>
      </div>
    </div>
  );
};

export default ShipToAddressPopover;