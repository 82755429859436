import * as React from 'react';
import FeatureFlag from '@interfaces/feature-flag';
import { setFeatureFlags } from '@services/storage-service';
import { getCall } from './rest-service';

const { createContext, useContext } = React;

const EnvironmentContext = createContext(null);

export const EnvironmentProvider = (props) => {
  const value = {
    userType: props.userType || userType,
    setUserType: props.setUserType || setUserType,
    internalView: props.internalView || internalView,
    setInternalView: props.setInternalView || setInternalView,
    isAdminOrPlanning: props.isAdminOrPlanning || isAdminOrPlanning,
    setIsAdminOrPlanning: props.setisAdminOrPlanning || setIsAdminOrPlanning,
    fetchConfig: props.fetchConfig || fetchConfig,
    getEnvironment: props.getEnvironment || getEnvironment,
  };

  return (
    <EnvironmentContext.Provider value={value}>
      {props.children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => {
  return useContext(EnvironmentContext);
};

export interface Config {
  salesforceBaseUrl: string;
  salesforceUsername: string;
  salesforceUserId: string;
  nrEnabled: boolean;
  nrBeacon: string;
  nrErrorBeacon: string;
  nrLicenseKey: string;
  nrApplicationID: string;
  continueShoppingPath: string;
  nrSa: number;
  loadTimeout: number;
  loadTimeoutMessage: string;
  featureFlags: FeatureFlag;
}

let _userType: string;
let _internalView: boolean;
let _isAdminOrPlanning: boolean;
let configPromise: Promise<Config>;
let publicFeatureFlags: FeatureFlag;

export const userType = (): string => {
  return _userType;
};

export const setUserType = (userType: string) => {
  _userType = userType;
};

export const internalView = (): boolean => {
  return _internalView;
};

export const setInternalView = (internalView: boolean) => {
  _internalView = internalView;
};

export const isAdminOrPlanning = (): boolean => {
  return _isAdminOrPlanning;
};

export const setIsAdminOrPlanning = (isAdminOrPlanning: boolean) => {
  _isAdminOrPlanning = isAdminOrPlanning;
};

export async function fetchConfig() {
  configPromise = getCall<Config>(`config${location.search}`);

  const { featureFlags } = await configPromise;

  publicFeatureFlags = featureFlags;
  setFeatureFlags(publicFeatureFlags);
}

export async function getEnvironment() {
  return configPromise;
}
