import { MeasurementSystems } from "@constants";

export enum Types {
  Lengths = 'Lengths',
  Weights = 'Weights',
  Temperature = 'Temperature',
  Currency = 'Currency',
};

export const TextUOMConversion = {
  [Types.Lengths]: {
    [MeasurementSystems.Imperial]: (value) => {
      const numDecimals = 3;
      return `${(value || 0)?.toFixed(numDecimals)}"`;
    },
    [MeasurementSystems.Metric]: (value) => {
      const numDecimals = 0;
      return `${value?.toFixed(numDecimals) || 0}mm`;
    },
  },
  // TODO: please add it whenever you need it
  [Types.Weights]: {
    [MeasurementSystems.Imperial]: () => {

    },
    [MeasurementSystems.Metric]: () => {

    },
  },
  // TODO: please add it whenever you need it
  [Types.Temperature]: {
    [MeasurementSystems.Imperial]: () => {

    },
    [MeasurementSystems.Metric]: () => {

    },
  },
}