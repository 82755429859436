import CurrencyConversions from '@interfaces/currency-conversions';
import { get } from './currency-conversions-api';
import * as React from 'react';

const { createContext, useContext } = React;

const CurrencyConversionsContext = createContext(null);

export const CurrencyConversionsProvider = (props) => {
  const value = {
    getCurrencyConversions: props.getCurrencyConversions || getCurrencyConversions,
  };

  return (
    <CurrencyConversionsContext.Provider value={value}>
      {props.children}
    </CurrencyConversionsContext.Provider>
  );
};

export const useCurrencyConversions = () => {
  return useContext(CurrencyConversionsContext);
};

export let currencyConversionsCache: CurrencyConversions = {};

// Adds currency conversions to the cache if they don't exist yet
export const getCurrencyConversions = async(): Promise<CurrencyConversions> => {
  if (Object.keys(currencyConversionsCache).length === 0) {
    try {
      currencyConversionsCache = await get();
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  return currencyConversionsCache;
};
