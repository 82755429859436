import { IonIcon, IonLabel } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { chevronDown, chevronUp } from 'ionicons/icons';
import { useAppAccordionGroup } from '../app-accordion-context';

import './app-accordion.scss';

interface Props {
  title: string;
  value: string;
  children?: JSX.Element | React.ReactNode;
}

const AppAccordionIcon = ({ isExpanded }: { isExpanded: boolean }) => {
  return <IonIcon
    className='app-accordion-icon'
    src={!isExpanded ? chevronUp : chevronDown}
  />;
}

const AppAccordion = ({value, children, title}: Props) => {
  const { value: active, toggleValue } = useAppAccordionGroup();
  const [isExpanded, setIsExpanded] = useState(active.includes(value));

  useEffect(() => {
    setIsExpanded(active.includes(value));
  }, [active]);

  return (
    <div className="app-accordion">
      <div className="top">
        <div className="app-accordion-expand-section" onClick={() => toggleValue(value)}>
          <IonLabel class='app-accordion-title'>{title}</IonLabel>
          <AppAccordionIcon isExpanded={isExpanded}/>
        </div>
      </div>

      {
        isExpanded && <div className='app-accordion-content'>
          {children}
        </div>
      }
    </div>
  );
};

export default AppAccordion;