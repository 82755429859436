import * as React from 'react';
import {
    IonButton,
    IonCard,
    IonCardHeader,
    IonCol,
    IonGrid,
    IonIcon,
    IonLabel,
    IonPopover,
    IonRow,
    IonImg,
    IonModal,
} from '@ionic/react';
import {  } from '@ionic/react';
import { useDarkMode } from '@hooks/useDarkMode';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import './modal.scss';

interface Options {
  isOpen?: boolean,
  animated?: boolean,
  backdropDismiss?: boolean
  onClickClose?: Function,
  size?: string,
}

interface ActionButton {
  color?: string,
  onClick?: React.MouseEventHandler,
  className?: string,
  testId?: string,
  label?: string,
}

const defOptions: Options = {
  isOpen: false,
  animated: false,
  backdropDismiss: false,
  size: 'md',
};

interface DataTestId {
  modalContainer: string,
  modalTitle: string,
  modalCloseButton: string,
}

const defDataTestIds: DataTestId = {
  modalContainer: 'QAModalContainer',
  modalTitle: 'QAModalTitle',
  modalCloseButton: 'QAModalCloseButton',
};

interface Props {
  options?: Options,
  children?: any,
  modalClass?: string,
  title?: React.Component | string,
  actionButtons?: Array<ActionButton>
  onClickClose?: Function,
  dataTestIds?: DataTestId,
  frame?: boolean,
}

const Modal = (props: Props) => {
  const { options, title, children, actionButtons, onClickClose, dataTestIds, modalClass = '', frame = false } = props;

  // assign defaults
  const {
    isOpen,
    animated,
    backdropDismiss,
    size,
  } = {
    ...defOptions,
    ...options
  };

  const testIds = {
    ...defDataTestIds,
    ...dataTestIds
  };

  const { darkMode } = useDarkMode();

  const onHandleClickClose = () => {
    if (onClickClose) {
      onClickClose();
    }
  };

  const ModalTitle = () => {
    return <div className="modal-title">
      <IonLabel data-testid={testIds.modalTitle}>
        {title}
      </IonLabel>
    </div>;
  };

  const ModalFooter = () => {
    const def = [{
      color: 'primary',
      onClick: () => onHandleClickClose(),
      className: 'close-button',
      testId: testIds.modalCloseButton,
      label: 'Close'
    }];

    const actions = actionButtons ? actionButtons : def;

    return <div className="modal-footer">
      { actions.map(({color, onClick, className, testId, label }, index) => <IonButton
        color={color}
        className={className}
        onClick={onClick}
        data-testid={testId}
        key={`modal--footer--${index}-${testId}`}
                                                                            >
        {label}
      </IonButton>)}
    </div>;
  };

  const closeIconSrc = !darkMode ? closeIcon : closeIconWhite;

  return <>
      <IonModal
          isOpen={isOpen}
          animated={animated}
          backdropDismiss={backdropDismiss}
          swipeToClose={false}
          cssClass={`app-modal ${size} ${modalClass}`}
      >
        {
          !frame
            ? <>
              <IonImg
                src={closeIconSrc}
                class="modal--close-icon"
                onClick={() => onHandleClickClose()}
              ></IonImg>

              <div className="modal-container" data-testid={testIds.modalContainer}>

                <ModalTitle/>

                <div className='modal-body'>
                  {children}
                </div>

                <ModalFooter/>
              </div>
            </>
            : <div className='modal-body'>
            {children}
          </div>
        }

      </IonModal>
  </>;
};

export default Modal;