import React from 'react';
import { IonInput } from '@ionic/react';

import './app-input.scss';

const AppInput = (props) => {
  return <IonInput className='app-input'
    onBlur={(e) => props.onChange && props.onChange(e.target.value)}
    {...props}
  />
};

export default AppInput;
