import React, { useState } from 'react';
import PanelSection from '../panel-section';
import AppAccordionGroup from '@components/app-accordion-group/app-accordion-group';
import AppAccordion from '@components/app-accordion-group/app-accordion/app-accordion';
import AppInput from '@components/app-input/app-input';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';
import AppCheckboxItem from '@components/app-checkbox-item/app-checkbox-item';
import { IonLabel } from '@ionic/react';
import AppLineItem from '@components/app-line-item/app-line-item';
import AppTextarea from '@components/app-textarea/app-textarea';
import { PanelProp } from '@pages/new-assembly-groups/panel-item/panel-item';
import AppDropdownSelection from '@components/app-dropdown/app-dropdown-selection';
import { MeasurementSystems, OutsideServices, QuoteLineItemValueTypes, Regexes, Units } from '@constants';
import AppDropdownVendors from '@components/app-dropdown/app-dropdown-vendors';
import { OutsideServiceFormKeyMapper } from './outside-service-form-key';
import './outside-service.scss';
import { useDisplayUom } from '@context/useDisplayUom';
import { calculateCostPerWeightUnit, calculateTotalShippingCost, calculateUOMValue } from '@shared/quote-utils';
import { getCurrencySymbol } from '@shared/utils';
import { AssemblyGroupFormKeys } from '@pages/new-assembly-groups/assembly-group-form-util';

enum OutsideServiceType {
  TestingServices = 'TestingServices',
  HeatTreatment = 'HeatTreatment',
};

interface OutsideServiceFormProp {
  firstLabel?: string;
  areaId?: string;
  formKey: string;
  form: any;
  updateForm: any;
  type?: OutsideServiceType
  quote: any,
};

const UOMCostLabel = {
  [MeasurementSystems.Imperial]: 'Lb',
  [MeasurementSystems.Metric]: 'Kg',
};

const costFormat = (value) => {
  return value !== '' ? (+value).toFixed(2) : '';
}

const CostInput = ({ quote, value, onChange}) => {
  const { displayUom } = useDisplayUom();
  const quoteUOM = quote.DocumentUOM__c || MeasurementSystems.Imperial;

  const displayValue = displayUom === quoteUOM
    ? value
    : calculateUOMValue(quoteUOM, displayUom, value, Units.PricePerKilogram, Units.PricePerPound);

  const onInputChange = (inputValue) => {
    const newValue = displayUom === quoteUOM
      ? inputValue
      : calculateUOMValue(displayUom, quoteUOM, inputValue, Units.PricePerKilogram, Units.PricePerPound);

    onChange(newValue);
  }

  return <AppInput
    type='number'
    placeholder='0.000'
    regex={Regexes.DecimalNumber}
    min='0.000'
    value={costFormat(displayValue)}
    onChange={onInputChange}
  />
}

const OutsideServiceForm = ({firstLabel, formKey, areaId, form: qliForm, updateForm, type, quote}: OutsideServiceFormProp) => {
  const { displayUom, isDisplayMetric } = useDisplayUom();
  const stateValues: any = Object.keys(OutsideServiceFormKeyMapper[formKey]).reduce((prev, localKey) => {
    const qliFormKey = OutsideServiceFormKeyMapper[formKey][localKey];

    if (!qliFormKey) {
      return prev;
    }

    prev[localKey] = qliForm[qliFormKey];
    return prev;
  }, {});

  const [form, setForm] = useState(stateValues);

  const currencySymbol = getCurrencySymbol(qliForm.CurrencyIsoCode);

  const costLabel = (label) => {
    return `${label} (${currencySymbol} Per ${UOMCostLabel[displayUom]})`
  };

  const onUpdateFormValue = (payload) => {
    setForm({
      ...form,
      ...payload,
    });

    Object.keys(payload).forEach(key => {
      const value = payload[key];
      updateForm(OutsideServiceFormKeyMapper[formKey][key], value);
    });
  }

  return <div className="outside-service-container">
    <AppLineItem light={true} label={firstLabel || 'Duration of Event (days):'}>
      <AppLineItemColumns components={{
        key: `time-${formKey}`,
        size: 6,
        component: <AppInput
          type='number'
          placeholder='0'
          regex={Regexes.PositiveInteger}
          value={form.durationEvent}
          onChange={(value) => onUpdateFormValue({ durationEvent: value })}
        />
      }}/>
    </AppLineItem>
    <AppLineItem light={true} label='Total Transit Time (days):'>
      <AppLineItemColumns components={{
        key: `transit-time-${formKey}`,
        size: 6,
        component: <AppInput
          type='number'
          placeholder='0'
          regex={Regexes.PositiveInteger}
          value={form.totalTransitTime}
          onChange={(value) => onUpdateFormValue({ totalTransitTime: value })}
        />
      }}/>
    </AppLineItem>
    <AppLineItem light={true} label={`Cost of Event (${currencySymbol} Per Lot):`}>
      <AppLineItemColumns components={{
        key: `cost-event-${formKey}`,
        size: 6,
        component: <AppInput
          type='number'
          placeholder='0'
          regex={Regexes.DecimalNumber}
          min='0'
          value={form.costEvent}
          onChange={(value) => onUpdateFormValue({ costEvent: value })}
        />
      }}/>
    </AppLineItem>
    {type === OutsideServiceType.HeatTreatment && <AppLineItem light={true} label={`Total Cost of Event (${currencySymbol} Per Lot):`}>
      <AppLineItemColumns components={{
        key: `total-cost-event-${formKey}`,
        size: 6,
        component: <AppInput
          value={costFormat(form.totalCostEvent)}
          type='number'
          placeholder='0'
          regex={Regexes.DecimalNumber}
          min='0'
          onChange={(value) => onUpdateFormValue({ totalCostEvent: value })}
        />
      }}/>
    </AppLineItem>}
    <AppLineItem label='Outbound Shipping [NC to Vendor]'/>
    <AppLineItem light={true} label={costLabel("Shipping Cost")}>
      <AppLineItemColumns components={{
        key: `outbound-shipping-cost-${formKey}`,
        size: 6,
        component: <CostInput
            quote={quote}
            value={form.outboundShippingCost}
            onChange={(value) => {
              const base = !isDisplayMetric
                ? qliForm[AssemblyGroupFormKeys.Base_LB_PC]
                : qliForm[AssemblyGroupFormKeys.Base_KG_PC];

              const total = calculateTotalShippingCost(base, parseInt(qliForm.Quantity__c), +value);
              onUpdateFormValue({
                outboundShippingCost: value,
                outboundTotalShippingCost: total,
              });
            }}
          />
      }}/>
    </AppLineItem>
    <AppLineItem light={true} label={`Total Shipping Cost (${currencySymbol} Per Lot):`}>
      <AppLineItemColumns components={{
        key: `total-outbound-shipping-cost-${formKey}`,
        size: 6,
        component: <AppInput
          type='number'
          placeholder='0.000'
          regex={Regexes.DecimalNumber}
          min='0.000'
          value={costFormat(form.outboundTotalShippingCost)}
          onChange={(value) => {
            const base = quote.DocumentUOM__c === MeasurementSystems.Imperial
              ? qliForm[AssemblyGroupFormKeys.Base_LB_PC]
              : qliForm[AssemblyGroupFormKeys.Base_KG_PC];

            const cost = calculateCostPerWeightUnit(
              base,
              qliForm[AssemblyGroupFormKeys.Quantity],
              +value
            );

            onUpdateFormValue({
              outboundShippingCost: cost,
              outboundTotalShippingCost: value
            });
          }}
        />
      }}/>
    </AppLineItem>
    <AppLineItem label='Inbound Shipping [From Vendor to NC]'/>
    <AppLineItem light={true} label={costLabel("Shipping Cost")}>
      <AppLineItemColumns components={{
        key: `inbound-shipping-cost-${formKey}`,
        size: 6,
        component: <CostInput
          quote={quote}
          value={form.inboundShippingCost}
          onChange={(value) => {
            const base = !isDisplayMetric
              ? qliForm[AssemblyGroupFormKeys.Base_LB_PC]
              : qliForm[AssemblyGroupFormKeys.Base_KG_PC];

            const total = calculateTotalShippingCost(base, parseInt(qliForm.Quantity__c), +value);
            onUpdateFormValue({
              inboundShippingCost: value,
              inboundTotalShippingCost: total,
            });
          }}
        />
      }}/>
    </AppLineItem>
    <AppLineItem light={true} label={`Total Shipping Cost (${currencySymbol} Per Lot):`}>
      <AppLineItemColumns components={{
        key: `total-inbound-shipping-cost-${formKey}`,
        size: 6,
        component: <AppInput
          type='number'
          placeholder='0.000'
          regex={Regexes.DecimalNumber}
          min='0.000'
          value={costFormat(form.inboundTotalShippingCost)}
          onChange={(value) => {
            const base = quote.DocumentUOM__c === MeasurementSystems.Imperial
              ? qliForm[AssemblyGroupFormKeys.Base_LB_PC]
              : qliForm[AssemblyGroupFormKeys.Base_KG_PC];

            const cost = calculateCostPerWeightUnit(
              base,
              qliForm[AssemblyGroupFormKeys.Quantity],
              +value
            );

            onUpdateFormValue({
              inboundShippingCost: cost,
              inboundTotalShippingCost: value
            });
          }}
        />
      }}/>
    </AppLineItem>
    <AppLineItem light={true} label='Vendor'>
      <AppLineItemColumns components={{
        key: `vendor-${formKey}`,
        size: 4,
        component: <AppDropdownVendors
            areaId={areaId}
            servicesPerformed={formKey}
            value={form.vendor}
            onChange={(value) => onUpdateFormValue({ vendor: value })}
          />
      }}/>
    </AppLineItem>
    {
      type === OutsideServiceType.HeatTreatment && <AppLineItem light={true} label='Heat Treatment Type'>
        <AppLineItemColumns components={{
          key: `heat-treatment-type-${formKey}`,
          size: 6,
          component: <AppDropdownSelection
            valueType={QuoteLineItemValueTypes.PostbondHTType}
            areaId={areaId}
            value={form.type}
            onChange={({value}) => onUpdateFormValue({ type: value })}
            firstOptionDefault={false}
          />
        }}/>
      </AppLineItem>
    }
    <AppLineItem light={true} label={<AppCheckboxItem label="Product Ships to Customer"/>} />
    <AppLineItem light={true} label='Additional Notes'>
      <AppLineItemColumns components={{
        key: `additional-note-${formKey}`,
        component: <AppTextarea
          value={form.notes}
          placeholder='Comments...'
          onChange={(value) => onUpdateFormValue({ notes: value })}
        />
      }}/>
    </AppLineItem>
  </div>
}

const PanelOutsideService = ({ areaId, form, updateForm, quote }: PanelProp) => {
  return <>
    <PanelSection title="Outside Services" className='panel-section--outside-services'>
      <AppAccordionGroup>
        <AppAccordion title={OutsideServices.TestingServicesBacker} value="backer">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.TestingServicesBacker}
            form={form}
            updateForm={updateForm}
            quote={quote}
            type={OutsideServiceType.TestingServices}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.TestingServicesCladder} value="cladder">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.TestingServicesCladder}
            form={form}
            updateForm={updateForm}
            quote={quote}
            type={OutsideServiceType.TestingServices}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.TestingServicesCladProduct} value="clad product">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.TestingServicesCladProduct}
            form={form}
            updateForm={updateForm}
            quote={quote}
            type={OutsideServiceType.TestingServices}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.BackerMetalHeatTreatment} value="backer-metal-heat-treatment">
          <OutsideServiceForm
            formKey={OutsideServices.BackerMetalHeatTreatment}
            firstLabel='Time for Heat Treatment (days):'
            type={OutsideServiceType.HeatTreatment}
            areaId={areaId}
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.CladderMetalHeatTreatment} value="cladder-metal-heat-treatment">
          <OutsideServiceForm
            formKey={OutsideServices.CladderMetalHeatTreatment}
            firstLabel='Time for Heat Treatment (days):'
            type={OutsideServiceType.HeatTreatment}
            areaId={areaId}
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.PostbondHeatTreatment} value="postbond-metal-heat-treatment">
          <OutsideServiceForm
            formKey={OutsideServices.PostbondHeatTreatment}
            firstLabel='Time for Heat Treatment (days):'
            type={OutsideServiceType.HeatTreatment}
            areaId={areaId}
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.Welding} value="welding">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.Welding}
            firstLabel='Time for Welding (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.XRayServices} value="xray">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.XRayServices}
            firstLabel='Time for X-Raying (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.FlatteningBeforeCutting} value="flattening-before">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.FlatteningBeforeCutting}
            firstLabel='Time for Flattening (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.Cutting} value="cutting">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.Cutting}
            firstLabel='Time for Cutting (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.WaterjetCutting} value="waterjet-cutting">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.WaterjetCutting}
            firstLabel='Time for Cutting (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.FlatteningAfterCutting} value="flattening-after">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.FlatteningAfterCutting}
            firstLabel='Time for Flattening (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.Machining} value="machining">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.Machining}
            firstLabel='Time for Machining (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.InspectionBacker} value="inspection-backer">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.InspectionBacker}
            firstLabel='Time for Inspection (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.InspectionCladder} value="inspection-cladder">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.InspectionCladder}
            firstLabel='Time for Inspection (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.InspectionCladProduct} value="inspection-clad-product">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.InspectionCladProduct}
            firstLabel='Time for Inspection (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
        <AppAccordion title={OutsideServices.Packaging} value="packaging">
          <OutsideServiceForm
            areaId={areaId}
            formKey={OutsideServices.Packaging}
            firstLabel='Time for Packaging (days):'
            form={form}
            updateForm={updateForm}
            quote={quote}
          />
        </AppAccordion>
      </AppAccordionGroup>
    </PanelSection>
  </>
};

export default PanelOutsideService;