import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SEGMENTS } from './segments';

interface Props {
  children: ReactNode;
}

interface IContextProps {
  segment: string;
  setSegment: Dispatch<SetStateAction<any>>;
}

// Context
const SegmentContext = createContext({} as IContextProps);

// Provider
export const SegmentProvider = ({ children }: Props): JSX.Element => {
  const [segment, setSegment] = useState(SEGMENTS.CLAD_PRODUCT_TESTING.value);

  // context value object
  const value: IContextProps = useMemo(() => {
    return {
      segment,
      setSegment,
    };
  }, [segment, setSegment]);

  return (
    <SegmentContext.Provider value={value}>{children}</SegmentContext.Provider>
  );
};

// useSegmentContext hook
export const useSegmentContext = (): IContextProps => {
  const context: IContextProps = useContext(SegmentContext);
  if (context === null || context === undefined) {
    throw new Error(
      'You probably forgot the <SegmentProvider> context provider'
    );
  }
  return context;
};