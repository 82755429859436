import React, { useEffect } from 'react';
import { IonLabel } from '@ionic/react';

import AppInput from '@components/app-input/app-input';
import AppTextarea from '@components/app-textarea/app-textarea';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';

import PanelSection from '../panel-section';
import AppLineItem from '@components/app-line-item/app-line-item';
import { AssemblyGroupFormKeys } from '@pages/new-assembly-groups/assembly-group-form-util';
import { PanelProp } from '@pages/new-assembly-groups/panel-item/panel-item';
import AppDropdownSelection from '@components/app-dropdown/app-dropdown-selection';
import { CutMethods, DefaultSurfaceTreatmentSelections, FieldTypes, FinishedProductTypes, FlatteningSelections, ManufacturingSites, MeasurementSystems, QuoteLineItemAdditionalOptions, QuoteLineItemValueTypes, QuoteVariableConfigDescriptions, RawMaterialUsage, Regexes, Units } from '@constants';
import AppCheckboxItem from '@components/app-checkbox-item/app-checkbox-item';
import AppDropDownFinishedGoods from '@components/app-dropdown/app-dropdown-finished-goods';
import { useDisplayUom } from '@context/useDisplayUom';
import { calculateUOMValue, getDefaultCuttingToleranceNotes, getDefaultFlatteningNotes, getDefaultMachiningNotes, getFlatteningThinningGroup, getHeatTreatCombinationGroup, getPreHeatTimingGroup, getQuoteVariableConfiguration } from '@shared/quote-utils';
import { useDataState } from '@context/data-context';
import { usePrevious } from '@hooks/usePrevious';

const temperatureFormat = (value) => {
  return value !== '' ? (+value).toFixed(1) : '';
};

const TempUOMLabel = {
  [MeasurementSystems.Imperial]: 'F',
  [MeasurementSystems.Metric]: 'C',
};

const LineItemFlatteningNotes = ({ form, updateForm, areaId }) => {
  const { dataState } = useDataState();
  const { quoteVariableConfigurations, rawMaterials, finishedGoods } = dataState;
  const { displayUom, isDisplayMetric } = useDisplayUom();
  const previousFlatteningNotes = usePrevious(form[AssemblyGroupFormKeys.FlatteningNotes]);

  const getDefaultValue = () => {
    const itemType = finishedGoods.find((finishedGood: { name: string; dataAreaId: ManufacturingSites; }) => finishedGood.name === form[AssemblyGroupFormKeys.Item_Type] && finishedGood.dataAreaId === areaId);
    const uomLabel = isDisplayMetric ? 'MM' : 'IN';
    let descriptionFlattening: QuoteVariableConfigDescriptions;
    let thickness: number = form[`Total_TK_${uomLabel}__c`];

    if (itemType.name === FinishedProductTypes.Tubesheet) {
      const baseMetal = rawMaterials.find(rawMaterial => rawMaterial.name === form[AssemblyGroupFormKeys.BaseMetal] && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(areaId));
      const width: number = form[`Width_${uomLabel.toLowerCase()}__c`];
      const measuringSystemName = isDisplayMetric ? MeasurementSystems.Metric.toLowerCase() : MeasurementSystems.Imperial.toLowerCase();
      const flatteningThinningGroup = getFlatteningThinningGroup(baseMetal, areaId, displayUom, thickness, width);
      thickness = flatteningThinningGroup ? flatteningThinningGroup[measuringSystemName].ftFactorWidth : null;
    }

    switch (form[AssemblyGroupFormKeys.PostCladFlattening]) {
      case FlatteningSelections.StandardPlateFlatness:
      default:
        descriptionFlattening = isDisplayMetric
          ? QuoteVariableConfigDescriptions.FlatteningPlateMetric
          : QuoteVariableConfigDescriptions.FlatteningPlateImperial;
        break;
      case FlatteningSelections.StandardTubesheetFlatness:
        descriptionFlattening = isDisplayMetric
          ? QuoteVariableConfigDescriptions.FlatteningTubesheetMetric
          : QuoteVariableConfigDescriptions.FlatteningTubesheetImperial;
        break;
      case FlatteningSelections.None:
        descriptionFlattening = QuoteVariableConfigDescriptions.FlatteningNone;
        break;
      case FlatteningSelections.Other:
        descriptionFlattening = QuoteVariableConfigDescriptions.FlatteningOther;
        break;
    }

    const valuePhraseFlattening = getQuoteVariableConfiguration(
      quoteVariableConfigurations,
      descriptionFlattening,
      areaId,
      FieldTypes.Text
    ) as string;


    return getDefaultFlatteningNotes(itemType, displayUom, thickness, valuePhraseFlattening);
  };

  useEffect(() => {
    if (!quoteVariableConfigurations.length || !rawMaterials.length || !finishedGoods.length) {
      return;
    }

    if (!form[AssemblyGroupFormKeys.FlatteningNotes]) {
      const value = getDefaultValue();
      updateForm(AssemblyGroupFormKeys.FlatteningNotes, value);
    }

  }, [quoteVariableConfigurations, rawMaterials, finishedGoods]);

  useEffect(() => {
    if (!quoteVariableConfigurations.length || !rawMaterials.length || !finishedGoods.length) {
      return;
    }

    // only change when it's the same thing?
    if (form[AssemblyGroupFormKeys.FlatteningNotes] === previousFlatteningNotes) {
      const value = getDefaultValue();
      updateForm(AssemblyGroupFormKeys.FlatteningNotes, value);
    }

  }, [form[AssemblyGroupFormKeys.PostCladFlattening]]);

  useEffect(() => {
    if (!quoteVariableConfigurations.length || !rawMaterials.length || !finishedGoods.length) {
      return;
    }

    switch(form[AssemblyGroupFormKeys.PostCladFlattening]) {
      case FlatteningSelections.StandardPlateFlatness:
      case FlatteningSelections.StandardTubesheetFlatness:
        if (form[AssemblyGroupFormKeys.FlatteningNotes] === previousFlatteningNotes) {
          const value = getDefaultValue();
          updateForm(AssemblyGroupFormKeys.FlatteningNotes, value);
        }
        break;
    }
  }, [displayUom]);

  return <AppLineItemColumns components={{
    key: 'flattening-textarea',
    component: <AppTextarea
      rows={5}
      placeholder="Flattening notes..."
      value={form[AssemblyGroupFormKeys.FlatteningNotes]}
      onChange={(value) => updateForm(AssemblyGroupFormKeys.FlatteningNotes, value)}
               />
  }}
         />;
};

const CuttingNotes = ({ form, updateForm, areaId }) => {
  const { dataState, } = useDataState();
  const { quoteVariableConfigurations, rawMaterials, finishedGoods } = dataState;
  const { displayUom, isDisplayMetric } = useDisplayUom();
  const previousCuttingNotes = usePrevious(form[AssemblyGroupFormKeys.PostCladCuttingTolerance]);

  const getDefaultValue = () => {
    const itemType = finishedGoods.find((finishedGood: { name: string; dataAreaId: ManufacturingSites; }) => finishedGood.name === form[AssemblyGroupFormKeys.Item_Type] && finishedGood.dataAreaId === areaId);
    const uomLabel = {
      lowercased: isDisplayMetric ? 'mm' : 'in',
      uppercased: isDisplayMetric ? 'MM' : 'IN',
    };
    const thickness: number = form[`Total_TK_${uomLabel.uppercased}__c`];
    const length: number = form[`Length_${uomLabel.lowercased}__c`];

    const description = isDisplayMetric
      ? QuoteVariableConfigDescriptions.CuttingValuePhraseMetric
      : QuoteVariableConfigDescriptions.CuttingValuePhraseImperial;

    const valuePhrase = getQuoteVariableConfiguration(
      quoteVariableConfigurations,
      description,
      areaId,
      FieldTypes.Text
    ) as string;

    // if cutting method changes, update the default cutting notes using the new cutting method
    return getDefaultCuttingToleranceNotes(itemType, form[AssemblyGroupFormKeys.Cut_Method], thickness, length, displayUom, valuePhrase);
  };

  // TODO: i think we could create a component that wait for the data to be available
  // and show a loader before it can proceed
  useEffect(() => {
    if (!quoteVariableConfigurations.length || !rawMaterials.length || !finishedGoods.length) {
      return;
    }

    if (!form[AssemblyGroupFormKeys.PostCladCuttingTolerance]) {
      const value = getDefaultValue();
      updateForm(AssemblyGroupFormKeys.PostCladCuttingTolerance, value);
    }
  }, [quoteVariableConfigurations, rawMaterials, finishedGoods]);

  useEffect(() => {
    if (!quoteVariableConfigurations.length || !rawMaterials.length || !finishedGoods.length) {
      return;
    }

    if(form[AssemblyGroupFormKeys.PostCladCuttingTolerance] === previousCuttingNotes) {
      const value = getDefaultValue();
      updateForm(AssemblyGroupFormKeys.PostCladCuttingTolerance, value);
    }
  }, [form[AssemblyGroupFormKeys.Cut_Method]]);

  useEffect(() => {
    if (!quoteVariableConfigurations.length || !rawMaterials.length || !finishedGoods.length || form[AssemblyGroupFormKeys.Cut_Method] === CutMethods.Uncut) {
      return;
    }

    //sets cutting notes with toggle flip if no edits have been made
    if(form[AssemblyGroupFormKeys.PostCladCuttingTolerance] === previousCuttingNotes) {
      const value = getDefaultValue();
      updateForm(AssemblyGroupFormKeys.PostCladCuttingTolerance, value);
    }
  }, [displayUom]);

  return <AppInput
    onChange={(value) => updateForm(AssemblyGroupFormKeys.PostCladCuttingTolerance, value)}
    value={form[AssemblyGroupFormKeys.PostCladCuttingTolerance]}
         />;
};

const MachiningNotes = ({ form, updateForm, areaId, quote }) => {
  const { displayUom, isDisplayMetric } = useDisplayUom();
  const { dataState } = useDataState();
  const { finishedGoods, quoteVariableConfigurations } = dataState;

  const getDefaultValue = () => {

  const itemType = finishedGoods.find(
    (finishedGood: { name: string; dataAreaId: ManufacturingSites }) =>
      finishedGood.name === form[AssemblyGroupFormKeys.Item_Type] && finishedGood.dataAreaId === areaId
  );

  let description;
  if (itemType.name === FinishedProductTypes.Tubesheet) {
    description = isDisplayMetric
      ? QuoteVariableConfigDescriptions.MachiningTubesheetMetric
      : QuoteVariableConfigDescriptions.MachiningTubesheetImperial;
  } else {
    description = isDisplayMetric
      ? QuoteVariableConfigDescriptions.MachiningNonTubesheetMetric
      : QuoteVariableConfigDescriptions.MachiningNonTubesheetImperial;
  }

  const manufacturingSite = quote.Manufacturing_Site__c;

  const valuePhrase = getQuoteVariableConfiguration(
    quoteVariableConfigurations,
    description,
    manufacturingSite,
    FieldTypes.Text
  ) as string;

  const uomLabel = {
    lowercased: isDisplayMetric ? 'mm' : 'in',
    uppercased: isDisplayMetric ? 'MM' : 'IN',
  };

  const length: number = form[`Length_${uomLabel.lowercased}__c`];

  const updatedDefaultMachiningNotes = getDefaultMachiningNotes(itemType, displayUom, length, valuePhrase);
  return updatedDefaultMachiningNotes;
  };

  const previousMachiningNotes = usePrevious(form[AssemblyGroupFormKeys.MachiningNotes]);

  useEffect(() => {
    if (!quoteVariableConfigurations.length || !finishedGoods.length) {
      return;
    }

    if (form[AssemblyGroupFormKeys.Machining] === 'None') {
      updateForm(AssemblyGroupFormKeys.MachiningNotes, '');
    }

    if (form[AssemblyGroupFormKeys.MachiningNotes] === previousMachiningNotes) {
      const value = getDefaultValue();
      updateForm(AssemblyGroupFormKeys.MachiningNotes, value);
    }

  }, [form[AssemblyGroupFormKeys.Machining]]);

  return (
    <AppInput
      placeholder='Specify Other Details...'
      value={form[AssemblyGroupFormKeys.MachiningNotes]}
      onChange={(value) => updateForm(AssemblyGroupFormKeys.MachiningNotes, value)}
    />
  );
};

const TemperatureLabel = ({ label }) => {
  const { displayUom } = useDisplayUom();

  return <>
    {`${label || 'Temp'} (°${TempUOMLabel[displayUom]})`}
  </>;
};

const TemperatureInput = ({ updateForm, quote, value, formKey }) => {
  const { displayUom } = useDisplayUom();
  const quoteUOM = quote.DocumentUOM__c || MeasurementSystems.Imperial;

  const displayValue = displayUom === quoteUOM
    ? value
    : calculateUOMValue(quoteUOM, displayUom, value, Units.Celsius, Units.Fahrenheit);

  const onChange = (inputValue) => {
    const newValue = displayUom === quoteUOM
      ? inputValue
      : calculateUOMValue(displayUom, quoteUOM, inputValue, Units.Celsius, Units.Fahrenheit);

    updateForm(formKey, newValue);
  };

  return <AppInput
    placeholder='0'
    type='number'
    min='1'
    regex={Regexes.Temperature}
    value={temperatureFormat(displayValue)}
    onChange={onChange}
         />;
};

const PreHeatReqForCladdingCheckbox = ({ form, updateForm, areaId, quote }) => {
  const { dataState, } = useDataState();
  const { quoteVariableConfigurations } = dataState;
  const quoteUOM = quote.DocumentUOM__c || MeasurementSystems.Imperial;

  const onChange = (value) => {
    updateForm(AssemblyGroupFormKeys.PreHeatRequiredforCladding, value);

    if(!quoteVariableConfigurations) {
      return;
    }

    if (!value) {
      updateForm(AssemblyGroupFormKeys.PreHeatReqforcladdingMinTempforPreheat, 0);
      return;
    }

    const defaultValue = getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.TemperatureRequiredForCladding, areaId, FieldTypes.Text) as string;
    const convertedValue = calculateUOMValue(MeasurementSystems.Imperial, quoteUOM, +defaultValue, Units.Celsius, Units.Fahrenheit);

    updateForm(AssemblyGroupFormKeys.PreHeatReqforcladdingMinTempforPreheat, convertedValue);
  };

  return <AppCheckboxItem
    label="Pre Heat Req For Cladding"
    checked={form[AssemblyGroupFormKeys.PreHeatRequiredforCladding]}
    onChange={onChange}
         />;
};

const PreHeatReqForCuttingCheckbox = ({ form, updateForm, areaId, quote }) => {
  const { dataState, } = useDataState();
  const { quoteVariableConfigurations } = dataState;
  const quoteUOM = quote.DocumentUOM__c || MeasurementSystems.Imperial;

  const onChange = (value) => {
    updateForm(AssemblyGroupFormKeys.PreheattoCut, value);

    if(!quoteVariableConfigurations) {
      return;
    }

    if (!value) {
      updateForm(AssemblyGroupFormKeys.PreHeatReqforcuttingmintempforpreheat, 0);
      return;
    }

    const defaultValue = getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.TemperatureRequiredForCutting, areaId, FieldTypes.Text) as string;
    const convertedValue = calculateUOMValue(MeasurementSystems.Imperial, quoteUOM, +defaultValue, Units.Celsius, Units.Fahrenheit);

    updateForm(AssemblyGroupFormKeys.PreHeatReqforcuttingmintempforpreheat, convertedValue);
  };

  return <AppCheckboxItem
    label="Pre Heat Req For Cutting"
    checked={form[AssemblyGroupFormKeys.PreheattoCut]}
    onChange={onChange}
         />;
};

const PanelPostCladProcessing = ({ form, updateForm, areaId, quote }: PanelProp) => {
  const { displayUom, isDisplayMetric } = useDisplayUom();
  const { dataState, loadRawMaterialCombinations } = useDataState();
  const { rawMaterials, rawMaterialCombinations } = dataState;
  const baseMetal = rawMaterials.find(rawMaterial => rawMaterial.name === form[AssemblyGroupFormKeys.BaseMetal] && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(areaId));
  const cladMetal = rawMaterials.find(rawMaterial => rawMaterial.name === form[AssemblyGroupFormKeys.CladMetal] && rawMaterial.usage === RawMaterialUsage.Clad && rawMaterial.dataAreaId.includes(areaId));
  const manufacturingSite = quote?.Manufacturing_Site__c;
  loadRawMaterialCombinations(baseMetal.name, cladMetal.name, manufacturingSite);

  useEffect(() => {
    if (form[AssemblyGroupFormKeys.SurfaceTreatment]) {
      return;
    }
    const hasCosmeticGrinding = form[AssemblyGroupFormKeys.Additional_Options]?.includes(QuoteLineItemAdditionalOptions.CosmeticGrinding);

    const value = hasCosmeticGrinding
      ? DefaultSurfaceTreatmentSelections.CosmeticGrinding
      : DefaultSurfaceTreatmentSelections.Standard;

    updateForm(AssemblyGroupFormKeys.SurfaceTreatment, value);
  }, []);

  useEffect(() => {
    if (
      !form[AssemblyGroupFormKeys.NCInhouseStressRefliefTimeMin]
      || !form[AssemblyGroupFormKeys.NCInHouseStressReliefTimeMax]
      || !form[AssemblyGroupFormKeys.NCInhouseStressReliefTemp]
    ) {
      return;
    }

    const quoteUOM = quote.DocumentUOM__c as MeasurementSystems || MeasurementSystems.Imperial;
    const min = form[AssemblyGroupFormKeys.NCInhouseStressRefliefTimeMin];
    const max = form[AssemblyGroupFormKeys.NCInHouseStressReliefTimeMax];
    const temp = calculateUOMValue(displayUom, quoteUOM, form[AssemblyGroupFormKeys.NCInhouseStressReliefTemp], Units.Celsius, Units.Fahrenheit) || 0;
    const uom = TempUOMLabel[quoteUOM];
    const value = `${min} Minimum Minutes, ${max} Maximum Minutes at ${(Number(temp).toFixed(1))} Temp (°${uom})`;
    updateForm(AssemblyGroupFormKeys.NCInhouseStressReliefDetails, value);
  }, [
    form[AssemblyGroupFormKeys.NCInhouseStressRefliefChecked],
    form[AssemblyGroupFormKeys.NCInhouseStressRefliefTimeMin],
    form[AssemblyGroupFormKeys.NCInHouseStressReliefTimeMax],
    form[AssemblyGroupFormKeys.NCInhouseStressReliefTemp],
    form[AssemblyGroupFormKeys.SimulateHeatTreatmentAtVendor],
  ]);

  const onChange = (checked: boolean) => {
   updateForm(AssemblyGroupFormKeys.NCInhouseStressRefliefChecked, checked);

   if(!rawMaterialCombinations) {
    return;
   }

   if (!checked) {
    updateForm(AssemblyGroupFormKeys.NCInhouseStressRefliefTimeMin, 0);
    updateForm(AssemblyGroupFormKeys.NCInHouseStressReliefTimeMax, 0);
    updateForm(AssemblyGroupFormKeys.NCInhouseStressReliefTemp, 0);
    return;
   }

    const heatTreatCombinationGroup = getHeatTreatCombinationGroup(rawMaterialCombinations, baseMetal, cladMetal, manufacturingSite);
    const preHeatTimingGroup = getPreHeatTimingGroup(heatTreatCombinationGroup, form[AssemblyGroupFormKeys.Unit_of_Measure], isDisplayMetric ? form[AssemblyGroupFormKeys.Total_TK_MM] : form[AssemblyGroupFormKeys.Total_TK_IN]);
    updateForm(AssemblyGroupFormKeys.NCInhouseStressRefliefTimeMin, preHeatTimingGroup?.minMinutes);
    updateForm(AssemblyGroupFormKeys.NCInHouseStressReliefTimeMax, preHeatTimingGroup?.maxMinutes);
    updateForm(AssemblyGroupFormKeys.NCInhouseStressReliefTemp, isDisplayMetric ? heatTreatCombinationGroup?.metric.temperature : heatTreatCombinationGroup?.imperial.temperature);
  }

  return <>
    <PanelSection title="Post Clad Processing">
      <AppLineItem label={<AppCheckboxItem
        label="NC House Stress Relief"
        checked={form[AssemblyGroupFormKeys.NCInhouseStressRefliefChecked]}
        onChange={onChange}
                          />}
      >
        <AppLineItemColumns components={[
          {
            key: 'nc-house-stress-relief-min',
            component: <AppLineItemColumns components={[
              {
                key: 'nc-house-stress-relief-min',
                component: <AppInput
                  placeholder='0'
                  type='number'
                  min='1'
                  regex={Regexes.IntegerAboveZero}
                  value={form[AssemblyGroupFormKeys.NCInhouseStressRefliefTimeMin]}
                  onChange={(value) => updateForm(AssemblyGroupFormKeys.NCInhouseStressRefliefTimeMin, value)}
                           />
              },
              {
                key: 'nc-house-stress-relief-minlabel',
                className: 'd-flex align-items-center padding-left-5',
                component: <IonLabel>Minimum Minutes</IonLabel>
              }
            ]}
                       />
          },
          {
            key: 'nc-house-stress-relief-max',
            component: <AppLineItemColumns components={[
              {
                key: 'nc-house-stress-relief-max',
                component: <AppInput
                  placeholder='0'
                  type='number'
                  min='1'
                  regex={Regexes.IntegerAboveZero}
                  value={form[AssemblyGroupFormKeys.NCInHouseStressReliefTimeMax]}
                  onChange={(value) => updateForm(AssemblyGroupFormKeys.NCInHouseStressReliefTimeMax, value)}
                           />
              },
              {
                key: 'nc-house-stress-relief-maxlabel',
                className: 'd-flex align-items-center padding-left-5',
                component: <IonLabel>Maximum Minutes</IonLabel>
              }
            ]}
                       />
          },
          {
            key: 'nc-house-stress-relief-temp',
            component: <AppLineItemColumns components={[
              {
                key: 'nc-house-stress-relief-temp',
                component: <TemperatureInput
                  updateForm={updateForm}
                  quote={quote}
                  value={form[AssemblyGroupFormKeys.NCInhouseStressReliefTemp]}
                  formKey={AssemblyGroupFormKeys.NCInhouseStressReliefTemp}
                           />
              },
              {
                key: 'nc-house-stress-relief-templabel',
                className: 'd-flex align-items-center padding-left-5',
                component: <IonLabel><TemperatureLabel label="Temp"/></IonLabel>
              }
            ]}
                       />
          },
          {
            key: 'nc-house-stress-relief-simulate',
            className: 'd-flex align-items-center',
            component: <AppCheckboxItem
              label="Simulate at Vendor"
              checked={form[AssemblyGroupFormKeys.SimulateHeatTreatmentAtVendor]}
              onChange={(value) => updateForm(AssemblyGroupFormKeys.SimulateHeatTreatmentAtVendor, value)}
                       />
          },
        ]}
        />
      </AppLineItem>
      <AppLineItem label={<PreHeatReqForCladdingCheckbox form={form} updateForm={updateForm} areaId={areaId} quote={quote} />}>
        <AppLineItemColumns components={[
          {
            key: 'pre-head-req-cladding',
            size: 6,
            component: <AppLineItemColumns components={[
              {
                key: 'pre-head-req-cladding',
                size: 3,
                component: <TemperatureInput
                  updateForm={updateForm}
                  quote={quote}
                  value={form[AssemblyGroupFormKeys.PreHeatReqforcladdingMinTempforPreheat]}
                  formKey={AssemblyGroupFormKeys.PreHeatReqforcladdingMinTempforPreheat}
                           />
              },
              {
                key: 'pre-head-req-claddinglabel',
                size: 9,
                className: 'd-flex align-items-center padding-left-5',
                component: <IonLabel><TemperatureLabel label='Minimum Temp' /></IonLabel>
              }
            ]}
                       />
          },
        ]}
        />
      </AppLineItem>
      <AppLineItem label={<PreHeatReqForCuttingCheckbox form={form} updateForm={updateForm} areaId={areaId} quote={quote}/>}>
        <AppLineItemColumns components={[
          {
            key: 'pre-head-req-cutting',
            size: 6,
            component: <AppLineItemColumns components={[
              {
                key: 'pre-head-req-cutting',
                size: 3,
                component: <TemperatureInput
                  updateForm={updateForm}
                  quote={quote}
                  value={form[AssemblyGroupFormKeys.PreHeatReqforcuttingmintempforpreheat]}
                  formKey={AssemblyGroupFormKeys.PreHeatReqforcuttingmintempforpreheat}
                           />
              },
              {
                key: 'pre-head-req-cuttinglabel',
                size: 9,
                className: 'd-flex align-items-center padding-left-5',
                component: <IonLabel><TemperatureLabel label='Minimum Temp'/></IonLabel>
              }
            ]}
                       />
          },
        ]}
        />
      </AppLineItem>

      <AppLineItem label='Flattening:'>
        <AppLineItemColumns components={{
          key: 'flattening-dropdown',
          size: 6,
          component: <AppDropdownSelection
            valueType={QuoteLineItemValueTypes.PostCladFlattening}
            areaId={areaId}
            onChange={({value}) => updateForm(AssemblyGroupFormKeys.PostCladFlattening, value)}
            value={form[AssemblyGroupFormKeys.PostCladFlattening]}
                     />
        }}
        />

        <LineItemFlatteningNotes form={form} updateForm={updateForm} areaId={areaId}/>
      </AppLineItem>

      <AppLineItem label='Cutting:'>
        <AppLineItemColumns components={[
          {
            key: 'cutting-dropdown1',
            component: <AppDropDownFinishedGoods
              itemType={form[AssemblyGroupFormKeys.Item_Type]}
              valueType={QuoteLineItemValueTypes.CutMethod}
              areaId={areaId}
              onChange={({value}) => updateForm(AssemblyGroupFormKeys.Cut_Method, value)}
              value={form[AssemblyGroupFormKeys.Cut_Method]}
                       />
          },
          {
            key: 'cutting-input',
            className: 'padding-left-5',
            component: <CuttingNotes form={form} updateForm={updateForm} areaId={areaId}/>
          },
        ]}
        />
      </AppLineItem>

      <AppLineItem label='Machining:'>
        <AppLineItemColumns components={[
          {
            key: 'machining-dropdown1',
            component: <AppDropdownSelection
              areaId={areaId}
              valueType={QuoteLineItemValueTypes.Machining}
              value={form[AssemblyGroupFormKeys.Machining]}
              onChange={({ value }) => updateForm(AssemblyGroupFormKeys.Machining, value)}
                       />
          },
          {
            key: 'machining-dropdown2',
            className: 'padding-left-5',
            component: <MachiningNotes form={form} updateForm={updateForm} areaId={areaId} quote={quote} />
          },
        ]}
        />
      </AppLineItem>

      <AppLineItem label='Surface Treatment:'>
        <AppLineItemColumns components={[
          {
            key: 'surface-treament-dropdown',
            component: <AppDropdownSelection
              valueType={QuoteLineItemValueTypes.SurfaceTreatment}
              onChange={({value}) => updateForm(AssemblyGroupFormKeys.SurfaceTreatment, value)}
              value={form[AssemblyGroupFormKeys.SurfaceTreatment]}
              firstOptionDefault={false}
              attrLabel='name'
              attrValue='name'
                       />
          },
          {
            key: 'surface-treament-input',
            className: 'padding-left-5',
            component: <AppInput
              onChange={(value) => updateForm(AssemblyGroupFormKeys.SurfaceTreatmentNotes, value)}
              placeholder='Specify Other Details...'
              value={form[AssemblyGroupFormKeys.SurfaceTreatmentNotes]}
                       />
          },
        ]}
        />
      </AppLineItem>

      <AppLineItem label='NobelClad Inhouse Packaging:'>
        <AppLineItemColumns components={[
          {
            key: 'nc-inhouse-packaging-dropdown1',
            component: <AppDropdownSelection
              valueType={QuoteLineItemValueTypes.NCInHousePackaging}
              onChange={({value}) => {
                updateForm(AssemblyGroupFormKeys.NCInHousePackaging, value);
              }}
              value={form[AssemblyGroupFormKeys.NCInHousePackaging]}
              attrValue="name"
                       />
          },
          {
            key: 'nc-inhouse-packaging-input',
            className: 'padding-left-5',
            component: <AppInput
              onChange={(value) => updateForm(AssemblyGroupFormKeys.PackagingNotes, value)}
              placeholder='Specify Other Details...'
              value={form[AssemblyGroupFormKeys.PackagingNotes]}
                       />
          },
        ]}
        />
      </AppLineItem>
    </PanelSection>
  </>;
};

export default PanelPostCladProcessing;