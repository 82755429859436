import { Quote } from '@interfaces/quote';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { SpecifyPerAssemblyMapper } from '@pages/new-advanced-specifications/advance-specification-form-util';
import _ from 'lodash-es';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react';

interface Props {
  children: ReactNode;
}

interface ComboGroup {
  [key: string]: string[];
};

interface SpecifyPerAssemblyItem {
  [key: string]: boolean;
}

interface SpecifyPerAssembly {
  [key: string]: SpecifyPerAssemblyItem;
};

interface EnableSpecifyPerAssembly {
  [key: string]: boolean;
}

interface InitComboGroupPayload {
  quote: Quote,
  quoteLineItems: QuoteLineItem[]
}

interface IContextProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export enum ComboGroupActionTypes {
  initState = 'initState',
  setSelectedKey = 'setSelectedKey',
  setComboGroup = 'setComboGroup',
  setSpecifyPerAssemblyBySelectedKey = 'setSpecifyPerAssemblyBySelectedKey',
  initComboGroup = 'initComboGroup',
  updateSpecifyPerAssembly = 'updateSpecifyPerAssembly',
  updateEnableSpecifyPerAssembly = 'updateEnableSpecifyPerAssembly',
  updateOnlyShowSpecifyPerAssemblyField = 'updateOnlyShowSpecifyPerAssemblyField',
  resetSpecifyPerAssembly = 'resetSpecifyPerAssembly',
}

export type Action =
  | { type: ComboGroupActionTypes.initState; payload: State }
  | { type: ComboGroupActionTypes.setSelectedKey; payload: string }
  | { type: ComboGroupActionTypes.setComboGroup; payload: ComboGroup }
  | { type: ComboGroupActionTypes.setSpecifyPerAssemblyBySelectedKey; payload: SpecifyPerAssembly }
  | { type: ComboGroupActionTypes.initComboGroup; payload: InitComboGroupPayload }
  | { type: ComboGroupActionTypes.updateSpecifyPerAssembly; payload: SpecifyPerAssemblyItem }
  | { type: ComboGroupActionTypes.updateEnableSpecifyPerAssembly; payload: boolean }
  | { type: ComboGroupActionTypes.updateOnlyShowSpecifyPerAssemblyField; payload: boolean }
  | { type: ComboGroupActionTypes.resetSpecifyPerAssembly; payload?: any}

interface State {
  comboGroup: ComboGroup;
  selectedKey: string | null;
  specifyPerAssembly: SpecifyPerAssembly;
  enableSpecifyPerAssembly: EnableSpecifyPerAssembly;
  onlyShowSpecifyPerAssemblyField: boolean;
}

const initialState: State = {
  comboGroup: {},
  selectedKey: null,
  specifyPerAssembly: {},
  enableSpecifyPerAssembly: {},
  onlyShowSpecifyPerAssemblyField: false,
};

// Context
const ComboGroupContext = createContext({} as IContextProps);

// extract combo group from line item
function extractComboGroup(lineItems: QuoteLineItem[]) {
  return lineItems.reduce((prev: ComboGroup, { CladMetal__c, BaseMetal__c, Id, displayId }: QuoteLineItem) => {
    const key = `${CladMetal__c}|${BaseMetal__c}`;

    if (prev[key]) {
      prev[key].push(Id || displayId);
    } else {
      prev[key] = [(Id || displayId)];
    }

    return prev;
  }, {});
};

function extractSpecifyPerAssembly(comboGroup: ComboGroup, payload: InitComboGroupPayload, specifyPerAssembly: SpecifyPerAssembly) {
  return Object.keys(comboGroup).reduce((prev, curr) => {
    const ids = comboGroup[curr];
    const exists = specifyPerAssembly[curr] || {};
    const filteredQli = payload.quoteLineItems.filter((li) => {
      return ids.includes(li.Id || li.displayId);
    });

    // if it only have 1 item, dont do anything
    // if it already exists from the state
    if (ids.length === 1 || Object.keys(exists).length > 0) {
      prev[curr] = exists;
      return prev;
    }

    const values = filteredQli.map((fqli) => {
      return Object.keys(SpecifyPerAssemblyMapper).reduce((p, c) => {
        p[c] = SpecifyPerAssemblyMapper[c]({ quoteLineItem: fqli, quote: payload.quote });

        return p;
      }, {});
    });

    // need to compare all arrays
    // can be improve in some other time
    const item = Object.keys(SpecifyPerAssemblyMapper)
      .reduce((accumlator, currentValue) => {
        // get all the values and filter the unique items
        const mapperValues = values.map(v => v[currentValue]).filter((v, index, arr) => arr.indexOf(v) === index);
        // if it has more than 1 unique items then we need to enable
        // specify per assembly for that key
        accumlator[currentValue] = mapperValues.length !== 1;

        return accumlator;
      }, {});

    prev[curr] = item;
    return prev;
  }, {});
}

function extractEnableSpecifyPerAssembly(specifyPerAssemblyGroup) {
  // enableSpecifyPerAssembly
  return Object.keys(specifyPerAssemblyGroup).reduce((prev, curr) => {
    const item = specifyPerAssemblyGroup[curr];
    prev[curr] = Object.keys(item).some((specifyKey) => item[specifyKey]);
    return prev;
  }, {});
}

function comboGroupReducer(state, {type, payload}) {
  switch (type) {
    case ComboGroupActionTypes.initState:
      return initialState;
    case ComboGroupActionTypes.setSelectedKey:
      return {
        ...state,
        selectedKey: payload
      };
    case ComboGroupActionTypes.setComboGroup:
      return {
        ...state,
        comboGroup: payload
      };
    case ComboGroupActionTypes.setSpecifyPerAssemblyBySelectedKey:
      return {
        ...state,
        specifyPerAssembly: {
          ...state.specifyPerAssembly,
          [state.selectedKey]: payload,
        },
      };
    case ComboGroupActionTypes.initComboGroup:
      const extractedComboGroup = extractComboGroup(payload.quoteLineItems);
      const extractedSpecifyPerAssembly = extractSpecifyPerAssembly(extractedComboGroup, payload, state.specifyPerAssembly);
      const extractedEnableSpecifyPerAssembly = extractEnableSpecifyPerAssembly(extractedSpecifyPerAssembly);
      const keys = Object.keys(extractedComboGroup);
      const newState = {
        ...state,
        comboGroup: extractedComboGroup,
        specifyPerAssembly: extractedSpecifyPerAssembly,
        enableSpecifyPerAssembly: extractedEnableSpecifyPerAssembly,
      };

      if ((!state.selectedKey || (state.selectedKey && !extractedComboGroup[state.selectedKey])) && keys.length > 0) {
        newState.selectedKey = keys[0];
      }

      return newState;
    case ComboGroupActionTypes.updateSpecifyPerAssembly:
      // only update and not to set (override)
      const key = state.selectedKey;
      const specifyPerAssembly = state.specifyPerAssembly[key];
      const newSpecifyPerAssembly = !specifyPerAssembly
        ? payload
        : {
          ...specifyPerAssembly,
          ...payload
        };

      return {
        ...state,
        specifyPerAssembly: {
          ...state.specifyPerAssembly,
          [key]: newSpecifyPerAssembly
        }
      }
    case ComboGroupActionTypes.updateEnableSpecifyPerAssembly:
      const enableSpecifyPerAssembly = {
        ...state.enableSpecifyPerAssembly,
        ...{
          [state.selectedKey]: payload
        }
      };

      return {
        ...state,
        enableSpecifyPerAssembly,
      };
    case ComboGroupActionTypes.updateOnlyShowSpecifyPerAssemblyField:
      return {
        ...state,
        onlyShowSpecifyPerAssemblyField: payload,
      };
    case ComboGroupActionTypes.resetSpecifyPerAssembly:
      return {
        ...state,
        specifyPerAssembly: {},
        enableSpecifyPerAssembly: {},
      }
    default:
      return state;
  }
}
// Provider
export const ComboGroupProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(comboGroupReducer, initialState)
  const value = {state, dispatch};

  return (
    <ComboGroupContext.Provider value={value}>
      {children}
    </ComboGroupContext.Provider>
  );
};

// useComboGroup hook
export const useComboGroup = (): IContextProps => {
  const context: IContextProps = useContext(ComboGroupContext);
  if (context === null || context === undefined) {
    throw new Error(
      'You probably forgot the <ComboGroupProvider> context provider'
    );
  }
  return context;
};

