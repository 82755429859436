import React from 'react';
import type {PortableTextTypeComponent} from '@portabletext/react';
import './copyable-info-list.scss';

export interface InfoListDef {
  _type: 'infoList'
  infoMap: Map<string, string>,
  infoListStyle: React.CSSProperties,
  infoRowStyle: React.CSSProperties,
  labelCellStyle: React.CSSProperties,
  valueCellStyle: React.CSSProperties,
  infoLabelStyle: React.CSSProperties,
  infoValueStyle: React.CSSProperties
}

export const CopyableInfoList: PortableTextTypeComponent<InfoListDef> = ({value}) => {
  const { infoMap, infoListStyle, infoRowStyle, labelCellStyle, valueCellStyle, infoLabelStyle, infoValueStyle } = value;

  return (
    <table style={infoListStyle}>
      <tbody>
        {Array.from(infoMap.keys()).map((label, index) => (
          <tr style={infoRowStyle} key={`${label}-${index}`}>
            <td style={labelCellStyle}>
              <span className='info-label' style={infoLabelStyle}>{label}:</span>
            </td>
            <td style={valueCellStyle}>
              <span className='info-value' style={infoValueStyle}>{infoMap.get(label)}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  
};