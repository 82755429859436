import { ProfileSettingsMap } from '@constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { addEmailToOnesignal, getUserSettings, updateUserSettings } from './user-api';
import * as React from 'react';

const { createContext, useContext } = React;

const UserContext = createContext(null);

export const UserProvider = (props) => {
  const value = {
    settings$: props.settings$ || settings$,
    loadSettings: props.loadSettings || loadSettings,
    updateUserSetting: props.updateUserSetting || updateUserSetting,
    addUserEmailToOnesignal: props.addUserEmailToOnesignal || addUserEmailToOnesignal
  };

  return (
    <UserContext.Provider value={value}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

export const settings = new BehaviorSubject(null);
let _settingsMap = new Map<string, string>(Array.from(ProfileSettingsMap).map(([setting, value]) => [setting, value.defaultSelection]));

export const settings$ = (): Observable<Map<string, string>> => {
  return settings.asObservable();
};

// Loads the user settings into the settings map
export const loadSettings = async() => {
  const retrievedSettings = await getUserSettings();
  if (retrievedSettings) {
    _settingsMap = new Map(Object.entries(retrievedSettings));
  }
  settings.next(_settingsMap);
};

export const updateUserSetting = async(setting: string, value: string) => {
  _settingsMap.set(setting, value);

  await updateUserSettings(_settingsMap);
  settings.next(_settingsMap);
};

export const addUserEmailToOnesignal = async(email: string, oneSignalEmailHash: string, userId: string, oneSignalExternalIdHash: string) => {
  await addEmailToOnesignal(email, oneSignalEmailHash, userId, oneSignalExternalIdHash);
};