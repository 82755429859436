import Loading from '@components/loading/loading';
import { InvoiceActions, InvoicesPageColumnHeadings } from '@constants';
import { Invoice } from '@interfaces/invoice';
import { IonButton, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { useSalesforce } from '@services/salesforce/salesforce-service';
import { formatCurrency } from '@shared/utils';
import classNames from 'classnames';
import { chevronDown, chevronUp } from 'ionicons/icons';
import * as React from 'react';
import './invoices.scss';
import Footer from '@components/footer/footer';
import LineItemActions from '@components/line-item-actions/line-item-actions';
import { usePlatform } from '@services/platform-service';
import { useHistory } from 'react-router';
import EmptyTableRow from '@components/empty-table-row/empty-table-row';
import Badge from '@components/badge/badge';
import { useLastViewed } from '@services/last-viewed/last-viewed-service';
import backArrow from '@assets/icon/arrow_back.svg';
import forwardArrow from '@assets/icon/arrow_forward.svg';
import { mapInvoice } from '@shared/invoice-utils';
import moment from 'moment';
import { useDarkMode } from '@hooks/useDarkMode';
import loadingSpinner from '@assets/icon/spinner_button_white_transparent_24.png';
import { useDocuments } from '@services/documents/documents-service';
import LineItemAttachment from '@components/line-item-attachment/line-item-attachment';
import { MappedInvoice } from '@shared/types';

interface Props {
  invoices: Invoice[];
}

const InvoicesPage = (props: Props) => {
  const salesforce = useSalesforce();
  const platform = usePlatform();
  const lastViewed = useLastViewed();
  const { darkMode } = useDarkMode();
  const documents = useDocuments();
  const history = useHistory();

  const [displayedInvoices, setDisplayedInvoices] = React.useState<MappedInvoice[]>(undefined);
  const [sortColumnId, setSortColumnId] = React.useState(undefined);
  const [sortAsc, setSortAsc] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [, setUserInfo] = React.useState(undefined);
  const [page, setPage] = React.useState(1);
  const [sortColumnHeading, setSortColumnHeading] = React.useState(undefined);
  const [headings] = React.useState(InvoicesPageColumnHeadings);
  const [rowsPerPage] = React.useState(10);
  const [firstRowIndex, setFirstRowIndex] = React.useState((page - 1) * rowsPerPage + 1);
  const [numberOfRows, setNumberOfRows] = React.useState(undefined);
  const [lastRowIndex, setLastRowIndex] = React.useState(undefined);
  const [pageItems, setPageItems] = React.useState(undefined);
  const [canGoToPreviousPage, setCanGoToPreviousPage] = React.useState(page > 1);
  const [maxPages, setMaxPages] = React.useState(undefined);
  const [canGoToNextPage, setCanGoToNextPage] = React.useState(undefined);

  React.useEffect(() => {
    salesforce.userInfo$().subscribe(async userInfo => {
      setUserInfo(userInfo);
    });
  }, []);

  React.useEffect(() => {
    finalizeDisplayedInvoices();
  }, [props.invoices]);

  React.useEffect(() => {
    setLastRowIndex(Math.min(numberOfRows, (rowsPerPage * page)));
  }, [numberOfRows, rowsPerPage, page]);

  React.useEffect(() => {
    setPageItems(displayedInvoices?.slice(firstRowIndex - 1, lastRowIndex));
  }, [displayedInvoices, firstRowIndex, lastRowIndex]);

  React.useEffect(() => {
    setFirstRowIndex((page - 1) * rowsPerPage + (displayedInvoices?.length ? 1 : 0));
  }, [page, rowsPerPage, displayedInvoices]);

  React.useEffect(() => {
    setCanGoToNextPage(page < maxPages);
  }, [page, maxPages]);

  React.useEffect(() => {
    setCanGoToPreviousPage(page > 1);
  }, [page]);

  React.useEffect(() => {
    setMaxPages(Math.ceil(numberOfRows / rowsPerPage));
  }, [numberOfRows, rowsPerPage]);

  React.useEffect(() => {
    setNumberOfRows(displayedInvoices?.length);
  }, [displayedInvoices]);

  React.useEffect(() => {
    sortItems();
  }, [sortColumnId, sortAsc]);

  // Sets the displayed invoices
  const finalizeDisplayedInvoices = async() => {
    try {
      if(props.invoices) {
        const mappedInvoices = await Promise.all(props.invoices.map(async invoice => await mapInvoice(invoice, lastViewed, darkMode)));
        setDisplayedInvoices(mappedInvoices);

        let databaseColumnId;
        if(mappedInvoices.some(invoice => invoice.Portal_Status__c !== mappedInvoices[0].Portal_Status__c)) {
          databaseColumnId = getColumnIdFromName(InvoicesPageColumnHeadings.Status);
        } else {
          databaseColumnId = getColumnIdFromName(InvoicesPageColumnHeadings.PaymentDue);
          setSortAsc(false);
        }

        setSortColumnId(databaseColumnId);
  
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Sorts the invoices according to the sort column
  const sortItems = async () => {
    let updatedItems;

    if(displayedInvoices) {
      updatedItems = [...displayedInvoices];
    }
    
    switch(sortColumnId) {
    case getColumnIdFromName(InvoicesPageColumnHeadings.Status):
      updatedItems = updatedItems?.orderBy(item => +item.statusSortingOrder, sortAsc);
      break;
    case getColumnIdFromName(InvoicesPageColumnHeadings.PaymentDue):
      updatedItems = updatedItems?.orderByDate(item => (item[sortColumnId] ? moment(item[sortColumnId]).format('MM/DD/YYYY') : item[sortColumnId]), sortAsc);
      break;
    default:
      updatedItems = updatedItems?.orderBy(item => item[sortColumnId], sortAsc);
      break;
    }

    setDisplayedInvoices(updatedItems);
    setPage(1);
  };

  // Returns the correct salesforce invoice property based on the column header
  const getColumnIdFromName = (colName: InvoicesPageColumnHeadings) => {
    return {
      [InvoicesPageColumnHeadings.Status]: 'Portal_Status__c',
      [InvoicesPageColumnHeadings.Invoice]: 'Name',
      [InvoicesPageColumnHeadings.PONumber]: 'PO_Number__c',
      [InvoicesPageColumnHeadings.PaymentDue]: 'Invoice_Due_Date__c',
      [InvoicesPageColumnHeadings.Total]: 'Invoice_Amount__c',
    }[colName];
  };

  // Handler for when an invoice header column is selected
  const selectColumnHandler = (column: InvoicesPageColumnHeadings) => {
    setSortColumnHeading(column);
    
    const databaseColumnId = getColumnIdFromName(column as InvoicesPageColumnHeadings);

    if(sortColumnId === databaseColumnId) {
      setSortAsc(!sortAsc);
    } else {
      setSortColumnId(databaseColumnId);
    }
  };

  // Returns proper column header class based on whether the column is sorted or not
  const getColHeaderClass = (column: InvoicesPageColumnHeadings) => {
    return (column === sortColumnHeading) ? 'invoices-text text-bold' : 'invoices-text';
  };

  // Returns proper column header icon based on whether the column is sorted or not
  const getColHeaderIcon = (column: InvoicesPageColumnHeadings) => {
    return (column === sortColumnHeading && sortAsc) ? chevronUp : chevronDown;
  };

  // Navigates to previous page of the orders screen
  const goToPreviousPage = () => {
    setPage(page - 1);
  };

  // Navigates to next page of the orders screen
  const goToNextPage = () => {
    setPage(page + 1);
  };

  // Navigates to the order detail page for the selected po number
  const navigateToOrderDetail = (orderId: string) => {
    history.push(`/orders/order-detail/${orderId}`);
  };

  // Navigates to the invoice detail page for the selected invoice
  const navigateToInvoiceDetail = (invoice: Invoice) => {
    history.push('/invoices/invoice-detail', { invoice });
  };

  const InvoiceLine = (props) => {
    const [loadingDocument, setLoadingDocument] = React.useState(false);
    const [invoiceDocument] = React.useState(props.invoice.Document_Links__r?.records[0].Document__r);

    // Retrieves the document data from dropbox and converts it into a blob
    const retrieveDocumentData = async () => {
      const documentInfo = await documents.downloadDropboxDocument(invoiceDocument.Dropbox_Id__c);
      const documentBytes = Uint8Array.from(atob(documentInfo.file), c => c.charCodeAt(0));
      const documentBlob = new Blob([documentBytes], { type: documentInfo.contentType });
      const documentData = window.URL.createObjectURL(documentBlob);

      return documentData;
    };

    // Downloads document onto device
    const downloadDocument = async () => {
      lastViewed.updateItemLastViewed({ id: invoiceDocument.Id, lastViewed: new Date().toLocaleString() });
      setLoadingDocument(true);
      const link = document.createElement('a');
      link.download = `${invoiceDocument.Name}`;
      link.href = await retrieveDocumentData();
      document.body.appendChild(link);
      link.click();
      setLoadingDocument(false);
    };

    // Views document in new tab
    const viewDocument = async () => {
      lastViewed.updateItemLastViewed({ id: invoiceDocument.Id, lastViewed: new Date().toLocaleString() });
      setLoadingDocument(true);
      window.open(await retrieveDocumentData());
      setLoadingDocument(false);
    };

    return (
      <IonCard className="invoice-card" onClick={() => navigateToInvoiceDetail(props.invoice)} data-testid={'QAInvoicesPageInvoiceLineItem_' + props.invoice.Name}>
        <div className={classNames({ 'loading-overlay': true, 'hidden': !loadingDocument ? true : false })}>
          <img className='loading' src={loadingSpinner}/>
        </div>
        <IonCardHeader class={classNames({ 'invoice-card-header': true, 'even': props.invoiceIndex % 2 === 0, 'odd': props.invoiceIndex % 2 !== 0 })}>
          <Badge text={props.invoice.badgeText}/>
          <IonGrid>
            <IonRow class="invoice-row">
              <IonCol class="column-left" size={ !platform.isMobile() ? undefined : '11' }>
                <span className="invoices-text mobile-only status-label">{ headings.Status }</span>
                <img className="status-icon" src={ props.invoice.statusImgSrc }/>
                <span className="invoice-summary-text" data-testid="QAInvoicesPageInvoiceLineItemStatus">{ props.invoice.Portal_Status__c }</span>
              </IonCol>
              <IonCol class="column-left" size={ !platform.isMobile() ? undefined : '11' }>
                <span className="invoices-text mobile-only invoice-label">{ headings.Invoice }</span>
                <span className="invoice-summary-text" data-testid="QAInvoicesPageInvoiceLineItemInvoice">{ props.invoice.Name }</span>
              </IonCol>
              <IonCol class="column-left po-number-col">
                <span className="invoice-summary-text po-number" onClick={(e) => { e.stopPropagation(); navigateToOrderDetail(props.invoice.NC_Order__c); }} data-testid="QAInvoicesPageInvoiceLineItemPONumber">{ props.invoice.PO_Number__c }</span>
              </IonCol>
              <IonCol class="column-left payment-due-col">
                <span className="invoice-summary-text" data-testid="QAInvoicesPageInvoiceLineItemPaymentDue">{ props.invoice.Invoice_Due_Date__c ? moment(props.invoice.Invoice_Due_Date__c).format('L') : props.invoice.Invoice_Due_Date__c }</span>
              </IonCol>
              <IonCol class="column-left" size={ !platform.isMobile() ? undefined : '11' }>
                <span className="invoices-text mobile-only total-label">{ headings.Total }</span>
                <span className="invoice-summary-text total-price" data-testid="QAInvoicesPageInvoiceLineItemTotal">{ formatCurrency(props.invoice.Invoice_Amount__c || 0, props.invoice.CurrencyIsoCode) }</span>
              </IonCol>
              <LineItemAttachment visible={props.invoice.Document_Links__r}/>
              <LineItemActions
                visible={props.invoice.Document_Links__r}
                actions={[{
                  label: InvoiceActions.Download,
                  actionToPerform: downloadDocument
                },{
                  label: InvoiceActions.View,
                  actionToPerform: viewDocument
                }]}
                data-testid="QAInvoicesPageInvoiceLineItemActions"
              >
              </LineItemActions>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
      </IonCard>
    );
  };

  const InvoicesList = () => {
    return ( pageItems?.length ?
      <>{pageItems?.map((invoice, index) => (
        <InvoiceLine invoice={invoice} invoiceIndex={index} key={index}/>
      ))}</> :
      <EmptyTableRow text={'No Invoices Available'}/>
    );
  };

  return ( !loading ?
    <>
      <IonContent class="invoices-page">
        <div className={classNames({
          'max-width-container': true,
          'invoices-wrapper': true,
          'loading': loading
        })}
        >
          <IonLabel class='invoices-title' data-testid="QAInvoicesPageTitle">Invoices</IonLabel>
          <div className='pagination-parent-container'>
            <div className="pagination-container">
              <IonLabel class="pagination-label">Viewing <span className="bold">{firstRowIndex}-{lastRowIndex}</span> of <span className="bold">{numberOfRows}</span></IonLabel>
              <div className="pagination-buttons-container">
                <IonButton disabled={!canGoToPreviousPage} class='change-page-button' color="secondary" onClick={() => goToPreviousPage()} data-testid="QAInvoicesPageTopPreviousPageButton">{!platform.isMobile() ? <IonIcon src={backArrow}></IonIcon> : 'Previous'}</IonButton>
                <IonButton disabled={!canGoToNextPage} class='change-page-button' color="secondary" onClick={() => goToNextPage()} data-testid="QAInvoicesPageTopNextPageButton">{!platform.isMobile() ? <IonIcon class="next-icon" src={forwardArrow}></IonIcon> : 'Next'}</IonButton>
              </div>
            </div>
          </div>
          <hr/>
          <IonCard class="invoices-card">
            <IonCardHeader class="invoices-card-header">
              <IonGrid>
                <IonRow class="invoices-row">
                  <IonCol class="invoices-col" onClick={() => selectColumnHandler(headings.Status)} data-testid="QAInvoicesPageStatusColumnHeader">
                    <span className={getColHeaderClass(headings.Status)}>{ headings.Status }</span>
                    <IonIcon icon={getColHeaderIcon(headings.Status)}></IonIcon>
                  </IonCol>
                  <IonCol class="invoices-col" onClick={() => selectColumnHandler(headings.Invoice)} data-testid="QAInvoicesPageInvoiceColumnHeader">
                    <span className={getColHeaderClass(headings.Invoice)}>{ headings.Invoice }</span>
                    <IonIcon icon={getColHeaderIcon(headings.Invoice)}></IonIcon>
                  </IonCol>
                  <IonCol class="invoices-col po-number-col" onClick={() => selectColumnHandler(headings.PONumber)} data-testid="QAInvoicesPagePONumberColumnHeader">
                    <span className={getColHeaderClass(headings.PONumber)}>{ headings.PONumber }</span>
                    <IonIcon icon={getColHeaderIcon(headings.PONumber)}></IonIcon>
                  </IonCol>
                  <IonCol class="invoices-col payment-due-col" onClick={() => selectColumnHandler(headings.PaymentDue)} data-testid="QAInvoicesPagePaymentDueColumnHeader">
                    <span className={getColHeaderClass(headings.PaymentDue)}>{ headings.PaymentDue }</span>
                    <IonIcon icon={getColHeaderIcon(headings.PaymentDue)}></IonIcon>
                  </IonCol>
                  <IonCol class="invoices-col" onClick={() => selectColumnHandler(headings.Total)} data-testid="QAInvoicesPageTotalColumnHeader">
                    <span className={getColHeaderClass(headings.Total)}>{ headings.Total }</span>
                    <IonIcon icon={getColHeaderIcon(headings.Total)}></IonIcon>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardHeader>
          </IonCard>
          <InvoicesList/>
          <hr className='bottom-rule'/>
          <div className="pagination-parent-container bottom">
            <div className="pagination-container bottom" data-testid="QAInvoicesPageBottomPaginationContainer">
              <IonLabel class="pagination-label">Viewing <span className="bold">{firstRowIndex}-{lastRowIndex}</span> of <span className="bold">{numberOfRows}</span></IonLabel>
              <div className="pagination-buttons-container">
                <IonButton disabled={!canGoToPreviousPage} class="change-page-button" color="secondary" onClick={() => goToPreviousPage()} data-testid="QAInvoicesPageBottomPreviousPageButton">{!platform.isMobile() ? <IonIcon src={backArrow}></IonIcon> : 'Previous'}</IonButton>
                <IonButton disabled={!canGoToNextPage} class="change-page-button" color="secondary" onClick={() => goToNextPage()} data-testid="QAInvoicesPageBottomNextPageButton">{!platform.isMobile() ? <IonIcon class="next-icon" src={forwardArrow}></IonIcon> : 'Next'}</IonButton>
              </div>
            </div>
          </div>
        </div>
        <div className="footer" data-testid="QAInvoicesPageFooterContainer">
          <Footer/>
        </div>
      </IonContent>
    </> :
    <div className="invoices-loading-container">
      <Loading data-testid="'QAInvoicesLoading'"/>
    </div>
  );
};

export default InvoicesPage;