import { useComboGroup } from '@context/combo-group-context';
import BaseMetal from '@pages/new-advanced-specifications/sections/base-metal';
import CladProductTesting from '@pages/new-advanced-specifications/sections/clad-product-testing';
import CladdingMetal from '@pages/new-advanced-specifications/sections/cladding-metal';
import ExceptionClarification from '@pages/new-advanced-specifications/sections/exception-clarification';
import Prewelding from '@pages/new-advanced-specifications/sections/prewelding';
import { SEGMENTS } from '@pages/new-advanced-specifications/segments/segments';
import { PanelProp } from '@pages/new-assembly-groups/panel-item/panel-item';
import React from 'react';
import PanelSection from '../panel-section';

const PanelAdvSpec = ({ areaId, updateForm, updateFormByObject, quote, form}: PanelProp) => {
  const {
    state: {
      enableSpecifyPerAssembly,
      specifyPerAssembly,
      selectedKey,
    },
  } = useComboGroup();

  const isEnabled = enableSpecifyPerAssembly[selectedKey] || false;
  const activeSpecifyPerAssembly = specifyPerAssembly[selectedKey];

  const sections = [
    {
      segmentKey: SEGMENTS.CLAD_PRODUCT_TESTING.value,
      show: true,
      component: CladProductTesting,
    },
    {
      segmentKey: SEGMENTS.CLAD_METAL.value,
      show: true,
      component: CladdingMetal,
    },
    {
      segmentKey: SEGMENTS.PREWELDING.value,
      show: true,
      component: Prewelding,
    },
    {
      segmentKey: SEGMENTS.BASE_METAL.value,
      show: true,
      component: BaseMetal,
    },
    {
      segmentKey: SEGMENTS.EXCEPTIONS_CLARIFICATIONS.value,
      show: true,
      component: ExceptionClarification,
    },
  ];
  const mapped = sections.map(section => {
    const activeSection = Object.keys(activeSpecifyPerAssembly)
      .filter(k => k.includes(section.segmentKey))
      .some(k => activeSpecifyPerAssembly[k]);

    return {
      ...section,
      show: activeSection,
    };
  }).filter(({show}) => show);

  return isEnabled && <PanelSection title="Advanced Spec">
    {
      mapped.map(({ component: Section, segmentKey }) => {
        return <Section
          segmentKey={segmentKey}
          key={`section--${segmentKey}`}
          quote={quote}
          areaId={areaId}
          form={form}
          updateForm={updateForm}
          updateFormByPayload={updateFormByObject}
        />
      })
    }
  </PanelSection>
};

export default PanelAdvSpec;