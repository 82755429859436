import { useDisplayUom } from '@context/useDisplayUom';
import { Quote } from '@interfaces/quote';
import React, { ReactNode, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MeasurementSystems } from '@constants';
import { useQuoteState } from '@context/quote-context';

interface Props {
  children: ReactNode;
}

interface IContextProps {
  displayUom: MeasurementSystems;
  setDisplayUom: (uom: MeasurementSystems) => void;
  isDisplayImperial: boolean;
  isDisplayMetric: boolean;
}

// Context
const UomWithInitContext = createContext({} as IContextProps);

export const UomWithInitProvider = ({ children }: Props): JSX.Element => {
  const [isMounted, setIsMounted] = useState(false);
  const { displayUom, setDisplayUom, isDisplayImperial, isDisplayMetric } = useDisplayUom();
  const { quoteState } = useQuoteState();
  const { quote } = quoteState;

  const updateDisplayUom = useCallback(
    (newUom: MeasurementSystems) => {
      setDisplayUom(newUom);
    },
    [setDisplayUom]
  );

  /**
   * This code checks the quote and if the quote's DocumentUOM__c is not equal to the displayUom, it updates the displayUom to the quote's DocumentUOM__c value.
   * This is done once, when the component is first mounted, but only after the quote is done loading.
   */
  useEffect(() => {
    if (isMounted || !quote?.DocumentUOM__c) {
      return;
    }

    if (quote?.DocumentUOM__c !== displayUom) {
      updateDisplayUom(quote.DocumentUOM__c as MeasurementSystems);
    }

    setIsMounted(true)
  }, [displayUom, quote, updateDisplayUom, isMounted]);

  const value: IContextProps = { displayUom, setDisplayUom, isDisplayMetric, isDisplayImperial };

  return <UomWithInitContext.Provider value={value}>{children}</UomWithInitContext.Provider>;
};

export const useDisplayUomWithInit = (): IContextProps => {
  const context: IContextProps = useContext(UomWithInitContext);
  if (context === null || context === undefined) {
    throw new Error('You probably forgot the <UomProvider> context provider');
  }
  return context;
};


