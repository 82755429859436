import React, { useEffect, useRef, useState } from 'react';
import { IonLabel } from '@ionic/react';

import AppCheckboxItem from '@components/app-checkbox-item/app-checkbox-item';
import AppInput from '@components/app-input/app-input';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';
import AppLineItem from '@components/app-line-item/app-line-item';
import AppRadioButtons from '@components/app-radio-buttons/app-radio-buttons';
import { Regexes } from '@constants';

// eg: Cycle 1: 2 minutes at 3°F Air Cooled: True Water Quenched: False
export const extractCycleValues = (value: string) => {
  let type = null;

  if (!!value?.match(/air cooled: true/i)) {
    type = 'Air Cool';
  }

  if (!!value?.match(/water quenched: true/i)) {
    type = 'Water Quench';
  }

  const extracted = value?.match(/cycle (\d+): (\d+) minutes at (\d+)°(f|c)/i) || [];

  return {
    type,
    num: (extracted.length && extracted[1]) || 0,
    min: (extracted.length && extracted[2]) || 0,
    temp: (extracted.length && extracted[3]) || 0,
    uom: extracted.length && (extracted[4] || '').toUpperCase(),
  }
};

const Cycle = ({ num, value, initialChecked, onToggleCheckbox, onChange }) => {
  const extracted = extractCycleValues(value);
  const [ checkbox, setCheckbox ] = useState(initialChecked);
  const [ min, setMin ] = useState(extracted.min);
  const [ temp, setTemp ] = useState(extracted.temp);
  const [ uom, setUOM ] = useState(extracted.uom);
  const [ type, setType ] = useState(extracted.type);
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!num || !temp) {
      return;
    }

    const cycle = `Cycle ${num}: ${min} minutes at ${temp}°${uom} Air Cooled: ${type === 'Air Cool' ? 'True' : 'False'} Water Quenched: ${type === 'Water Quench' ? 'True' : 'False'}`;

    initializedRef.current && onChange && onChange(cycle);
  }, [min, temp, uom, type])

  useEffect(() => {
    if (!initializedRef.current) {
      initializedRef.current = true;
      return;
    }

    if (!checkbox) {
      setMin(null);
      setTemp(null);
      setUOM(null);
      setType(null);
    } else {
      setMin(0);
      setTemp(0);
      setUOM('F');
      setType(null);
    }

    onToggleCheckbox(checkbox);
  }, [checkbox])

  return <AppLineItem label={<AppCheckboxItem
    label={`Cycle ${num}`}
    checked={checkbox}
    onChange={setCheckbox}
  />}>
    <AppLineItemColumns components={[
        {
          key: `cycle-${num}-form`,
          size: 9,
          component: <AppLineItemColumns components={[
            {
              size: 2,
              key: `cycle-${num}-form-input`,
              component: <AppInput
                type='number'
                placeholder='1'
                regex={Regexes.IntegerAboveZero}
                min='1'
                disabled={!checkbox}
                value={min}
                onChange={setMin}
              />
            },
            {
              size: 2,
              key: `cycle-${num}-form-label`,
              className: 'd-flex justify-content-center align-items-center',
              component: <IonLabel> minutes at </IonLabel>
            },
            {
              size: 2,
              key: `cycle-${num}-form-input2`,
              component: <AppInput
                type='number'
                placeholder='1'
                regex={Regexes.IntegerAboveZero}
                min='1'
                disabled={!checkbox}
                value={temp}
                onChange={setTemp}
              />
            },
            {
              size: 6,
              key: `cycle-${num}-form-radio`,
              className: 'd-flex justify-content-center',
              component: <AppRadioButtons
                disabled={!checkbox}
                options={['F', 'C']}
                selected={uom}
                onChange={setUOM}
              />
            }
          ]}/>
        },
        {
          key: 'cycle-1-radio',
          size: 3,
          className: 'd-flex justify-content-center',
          component: <AppRadioButtons
            disabled={!checkbox}
            selected={type}
            onChange={setType}
            options={['Air Cool', 'Water Quench']}
          />
        }
      ]}/>
  </AppLineItem>
}

export default Cycle;