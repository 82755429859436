import React from 'react';
import FloatingAction from '@components/floating-action/floating-action';
import quoteTab from '@assets/icon/Tab_Quote.svg';
import quoteTabDarkMode from '@assets/icon/Tab_Quote_DarkMode.svg';
import quoteTabHovered from '@assets/icon/Tab_Quote_Hovered.svg';
import orderTab from '@assets/icon/Tab_Order.svg';
import orderTabDarkMode from '@assets/icon/Tab_Order_DarkMode.svg';
import orderTabHovered from '@assets/icon/Tab_Order_Hovered.svg';
import MSTOTab from '@assets/icon/MSTOAction.svg';
import MSTOTabDarkMode from '@assets/icon/MSTOAction_DarkMode.svg';
import MSTOTabHovered from '@assets/icon/MSTOAction_Hovered.svg';
import AssGroups from '@assets/icon/AssGroupsAction.svg';
import AssGroupsDarkMode from '@assets/icon/AssGroupsAction_DarkMode.svg';
import AssGroupsHovered from '@assets/icon/AssGroupsAction_Hovered.svg';

import './page-floating-actions.scss';
import { useHistory } from 'react-router-dom';
import { useQuoteState } from '@context/quote-context';
import { useComboGroup } from '@context/combo-group-context';
import { PageRoutes } from '@constants';
import { navigateToRoute } from '@shared/utils';
import { useQuoteFormContext } from '@context/quote-form-context';
import _ from 'lodash-es';

const PageFloatingAction = () => {
  const history = useHistory();
  const {
    onResolveQuoteLineItems
  } = useQuoteFormContext()
  const {
    quoteState,
    syncFormQuoteLineItem
  } = useQuoteState();
  const { quote } = quoteState;

  const {
    state: { selectedKey, comboGroup }
  } = useComboGroup();

  const lineItemId = comboGroup && comboGroup[selectedKey] && comboGroup[selectedKey][0] || '';

  const syncForm = () => {
    syncFormQuoteLineItem(onResolveQuoteLineItems());
  }

  return <>
    <div className='page-floating-actions-container'>
      <FloatingAction
        icon={quoteTab}
        darkModeIcon={quoteTabDarkMode}
        hoveredIcon={quoteTabHovered}
        onClick={() => {
          // map to dot notation object and remove required field
          syncForm();
          navigateToRoute(PageRoutes.QuoteDetail, history, quote.Id);
        }}
        action='quote'
      />
      <FloatingAction
        icon={orderTab}
        darkModeIcon={orderTabDarkMode}
        hoveredIcon={orderTabHovered}
        onClick={() => {
          syncForm();
          navigateToRoute(PageRoutes.OrderEntry, history, quote.Id);
        }}
        action='order'
      />
      <FloatingAction
        icon={MSTOTab}
        darkModeIcon={MSTOTabDarkMode}
        hoveredIcon={MSTOTabHovered}
        onClick={() => {
          syncForm();
          navigateToRoute(PageRoutes.MSTO, history, quote.Id);
        }}
        action='msto'
      />
      <FloatingAction
        icon={AssGroups}
        darkModeIcon={AssGroupsDarkMode}
        hoveredIcon={AssGroupsHovered}
        onClick={() => {
          syncForm();
          navigateToRoute(PageRoutes.AssemblyGroups, history, quote.Id, undefined, lineItemId);
        }}
        action='assembly-groups'
      />
    </div>
  </>
};

export default PageFloatingAction