import { IonIcon } from '@ionic/react';
import React from 'react';
import './icon.scss';

export enum IconColors {
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
}

type Props = {
  src: string;
  isSpinning?: boolean;
  color?: string;
};

export const Icon = ({ src, isSpinning = false, color = IconColors.Success }: Props) => {
  const spinStyle = {
    animation: 'spin 2s linear infinite',
  };

  return (
    <div className='spinning-icon'>
      <IonIcon color={color} src={src} style={{ ...(isSpinning && spinStyle) }} />
    </div>
  );
};
