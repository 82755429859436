import { MeasurementSystems } from '@constants';
import { useDisplayUom } from '@context/useDisplayUom';
import { useEffect, useState } from 'react';

interface LocalUom {
  localUom: MeasurementSystems;
  setLocalUom: (uom: MeasurementSystems) => void;
  isDisplayMetric: boolean;
  isDisplayImperial: boolean;
}

export const useLocalUom = (system?: MeasurementSystems): LocalUom => {
  const { displayUom } = useDisplayUom(); // defaults to Imperial
  const [localUom, setLocalUom] = useState<MeasurementSystems>(system ?? displayUom);

  useEffect(() => {
    setLocalUom(system ?? displayUom);
  }, [system, displayUom]);

  const isDisplayMetric = localUom === MeasurementSystems.Metric;
  const isDisplayImperial = localUom === MeasurementSystems.Imperial;

  return {
    localUom,
    setLocalUom,
    isDisplayMetric,
    isDisplayImperial,
  };
};

// example usage:
// const { uom, setUom, isDisplayMetric, isDisplayImperial } = useLocalUom( option: uom );
