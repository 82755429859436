import advSpecsTab from '@assets/icon/Tab_Adv_Specs.svg';
import advSpecsTabDarkMode from '@assets/icon/Tab_Adv_Specs_DarkMode.svg';
import advSpecsTabDisabled from '@assets/icon/Tab_Adv_Specs_Disabled.svg';
import advSpecsTabHovered from '@assets/icon/Tab_Adv_Specs_Hovered.svg';
import bomTab from '@assets/icon/Tab_BoM.svg';
import bomTabDarkMode from '@assets/icon/Tab_BoM_DarkMode.svg';
import bomTabDisabled from '@assets/icon/Tab_BoM_Disabled.svg';
import bomTabHovered from '@assets/icon/Tab_BoM_Hovered.svg';
import combineTab from '@assets/icon/Tab_Combine.svg';
import combineTabDarkMode from '@assets/icon/Tab_Combine_DarkMode.svg';
import combineTabDisabled from '@assets/icon/Tab_Combine_Disabled.svg';
import combineTabHovered from '@assets/icon/Tab_Combine_Hovered.svg';
import manualTab from '@assets/icon/Tab_Manual.svg';
import manualTabDarkMode from '@assets/icon/Tab_Manual_DarkMode.svg';
import manualTabDisabled from '@assets/icon/Tab_Manual_Disabled.svg';
import manualTabHovered from '@assets/icon/Tab_Manual_Hovered.svg';
import orderTab from '@assets/icon/Tab_Order.svg';
import orderTabDarkMode from '@assets/icon/Tab_Order_DarkMode.svg';
import orderTabDisabled from '@assets/icon/Tab_Order_Disabled.svg';
import orderTabHovered from '@assets/icon/Tab_Order_Hovered.svg';
import resetTab from '@assets/icon/Tab_Reset.svg';
import resetTabDarkMode from '@assets/icon/Tab_Reset_DarkMode.svg';
import resetTabDisabled from '@assets/icon/Tab_Reset_Disabled.svg';
import resetTabHovered from '@assets/icon/Tab_Reset_Hovered.svg';
import statsTab from '@assets/icon/Tab_Stats.svg';
import statsTabDarkMode from '@assets/icon/Tab_Stats_DarkMode.svg';
import statsTabDisabled from '@assets/icon/Tab_Stats_Disabled.svg';
import statsTabHovered from '@assets/icon/Tab_Stats_Hovered.svg';
import termsTab from '@assets/icon/Tab_Terms.svg';
import termsTabDarkMode from '@assets/icon/Tab_Terms_DarkMode.svg';
import termsTabDisabled from '@assets/icon/Tab_Terms_Disabled.svg';
import termsTabHovered from '@assets/icon/Tab_Terms_Hovered.svg';

import notesTab from '@assets/icon/Tab_Notes.svg';
import notesTabDarkMode from '@assets/icon/Tab_Notes_DarkMode.svg';
import notesTabDisabled from '@assets/icon/Tab_Notes_Disabled.svg';
import notesTabHovered from '@assets/icon/Tab_Notes_Hovered.svg';


import EmptyTableRow from '@components/empty-table-row/empty-table-row';
import FloatingAction from '@components/floating-action/floating-action';
import Footer from '@components/footer/footer';
import GlobalCostAdjustmentsFooter from '@components/global-cost-adjustments-footer/global-cost-adjustments-footer';
import LineItemActions from '@components/line-item-actions/line-item-actions';
import LineItemField from '@components/line-item-field/line-item-field';
import Loading from '@components/loading/loading';
import LoadingEllipsisMessage from '@components/loading/loading-ellipsis';
import MultiActionButton from '@components/multi-action-button/multi-action-button';
import AddQuoteItemPopover from '@components/popover/add-quote-item-popover/add-quote-item-popover';
import CostDetailsPopover from '@components/popover/cost-details-popover/cost-details-popover';
import GenerateBomPopover from '@components/popover/generate-bom-popover/generate-bom-popover';
import ManualSizingPopover from '@components/popover/manual-sizing-popover/manual-sizing-popover';
import QuoteSetupPopover from '@components/popover/quote-setup-popover/quote-setup-popover';
import SizingStatisticsPopover from '@components/popover/sizing-statistics-popover/sizing-statistics-popover';
import TermsPopover from '@components/popover/terms-popover/terms-popover';
import SelectionContainer from '@components/selection-container/selection-container';
import { CutMethods, ExpandedRowLinks, GuardRailsLongDescription, GuardRailsTriggers, ManufacturingSites, MeasurementSystems, OutsideServices, PageRoutes, PlateMethods, QuoteActions, QuoteDisplayStatuses, QuoteItemSelectionTabs, QuoteLineItemActions, QuoteLineItemAdditionalOptions, QuoteLineItemDependentUnwrittenFields, QuoteLineItemFieldUnitTypes, QuoteLineItemFields, QuoteLineItemValueTypes, QuoteLineItemsColumnHeadings, QuoteLineTypes, RadiographyTypes, RawMaterialUsage, Regexes, SelectionTypes, ToastMessages, ToastTypes, Units } from '@constants';
import { AdvSpecActionTypes, useAdvSpecStateDispatch } from '@context/adv-spec-context';
import { useDataState } from '@context/data-context';
import { QuoteActionTypes, useQuoteDispatch, useQuoteState } from '@context/quote-context';
import { environment } from '@environment';
import { useToast } from '@hooks/useToast';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { IonButton, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRow, IonTextarea, useIonAlert } from '@ionic/react';
import { useLastViewed } from '@services/last-viewed/last-viewed-service';
import { useSalesforce } from '@services/salesforce/salesforce-service';
import { calculateFinishedMetalWeight, calculateMetalDensity, calculateThicknessAddition, calculateUOMValue, computeFTNewThickness, getColumnIdFromName, getOSServicesFieldInfo, getQuoteLineItemFromId, getSelectionOptions, outsideServicesInDetail, recalculateDependentFields, runGuardRail } from '@shared/quote-utils';
import { DependentUnwrittenFieldsMapValueType, FieldsMapValueType } from '@shared/types';
import { convertUnits, extractId, floorToHalf, formatCurrency, generateUUID, navigateToRoute } from '@shared/utils';
import classNames from 'classnames';
import { add, addCircle, checkmarkCircleOutline, chevronDown, chevronUp, closeCircleSharp, lockClosed, remove, warning } from 'ionicons/icons';
import moment from 'moment';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import './quote-detail.scss';

import Disabled from '@components/disabled';
import DocumentUOM from '@components/document-uom/document-uom';
import Modal from '@components/modal/modal';
import MultiShotDetailsLineGroup from '@components/popover/add-quote-item-popover/multi-shot/multi-shot-details-group/multi-shot-details-group';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';
import UomDisplayToggle from '@components/uom-toggle/uom-toggle';
import { AddEditLineItemProvider, useAddEditLineItemPopover } from '@context/add-edit-popover-context';
import { useDisplayUomWithInit } from '@context/useDisplayUomWithInit';
import { useSync } from '@context/useSync';
import { useLocalUom } from '@hooks/useLocalUom';
import { Quote } from '@interfaces/quote';
import _ from 'lodash-es';

interface QuoteDetailPageDetailProps {
  historyState: {
    cloning: boolean,
    quote: Quote
  }
  hasMultiShotFeatureFlag: boolean,
}

const QuoteDetailPageDetail = (props: QuoteDetailPageDetailProps) => {
  const quoteLineItemSortAsc = true;

  const salesforce = useSalesforce();
  const lastViewed = useLastViewed();
  const toast = useToast(4000);
  const history = useHistory();
  const { id } = useParams<{id: string}>();
  const {
    setShowPopover: setAddEditLineItemPopoverState,
    setQuoteLineItemToEdit,
    setQuoteLineItemDetailToEdit,
    setIsMultiShot,
  } = useAddEditLineItemPopover();

  const tabs = [QuoteItemSelectionTabs.FinishedGoods, QuoteItemSelectionTabs.Sizing, QuoteItemSelectionTabs.Cost, QuoteItemSelectionTabs.OSCost, QuoteItemSelectionTabs.Price];

  const {
    dataState,
    loadRawMaterials,
    loadFinishedGoods,
    loadFreight,
    loadContent,
    loadEdgeAllowances,
    loadSpecificationTestOptions,
    loadBoosterAllowances,
    loadSelections,
    loadQuoteVariableConfigurations,
    loadVendors,
    loadWeldingPatterns,
    loadOutsideServiceCosts
  } = useDataState();

  const {
    selections
  } = dataState;

  const { dispatchQuoteState } = useQuoteDispatch();
  const {
    quoteState,
    loadQuote,
    loadQuoteLineItemDetails,
    saveQuoteLineItem,
    saveQuote
  } = useQuoteState();
  const {
    quote,
    quoteLineItems,
    quoteLineItemDetails,
    calculatedFieldsEditedMap,
    inputFieldsEditedMap,
    defaultValueMap,
    lineUpdating,
    isQuoteLocked,
    calculationMap
  } = quoteState;
  console.log('quoteState: ', quoteState);

  const { dispatchAdvSpecState } = useAdvSpecStateDispatch();

  const { displayUom, isDisplayImperial } = useDisplayUomWithInit();

  const [confirmAlert] = useIonAlert();
  const { sync, isLoading: isLoadLoading, isSyncing } = useSync();

  const [isLoading, setIsLoading] = React.useState(true);
  const [areDetailsLoading, setAreDetailsLoading] = React.useState(true);
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState(QuoteItemSelectionTabs.FinishedGoods);
  const [contributionPercent, setContributionPercent] = React.useState(undefined);
  const [agentComRate, setAgentComRate] = React.useState(quote?.Agent_Com_Rate__c || undefined);
  const [quoteLoading, setQuoteLoading] = React.useState(undefined);
  const [linesLoading, setLinesLoading] = React.useState(undefined);
  const [termsPopoverState, setShowTermsPopover] = React.useState({ showPopover: false, event: undefined });
  const [quoteSetupPopoverState, setShowQuoteSetupPopover] = React.useState({ showPopover: false, event: undefined });
  const [sizingStatisticsPopoverState, setShowSizingStatisticsPopover] = React.useState({ showPopover: false, event: undefined });
  const [isCostDetailsModalOpen, setIsCostDetailsModalOpen] = React.useState(false);
  const [manualSizingPopoverState, setShowManualSizingPopover] = React.useState({ showPopover: false, event: undefined });
  const [isGenerateBomPopoverOpen, setIsGenerateBomPopoverOpen] = React.useState(false);
  const [displayedLineItemId, setDisplayedLineItemId] = React.useState<string>('');
  const [tabIndex, setTabIndex] = React.useState(undefined);
  const [showQuoteSetup, setShowQuoteSetup] = React.useState(false);
  const [gcaLineItemFieldsMap, setGcaLineItemFieldsMap] = React.useState<{[key: string]: Map<QuoteLineItemFields, number>}>({});
  const [currentAgentComAmount, setCurrentAgentComAmount] = React.useState(quote?.Agent_Com_Amount__c || 0);
  const [displayedCostWoCom, setDisplayedCostWoCom] = React.useState(undefined);
  const [quoteCloning, setQuoteCloning] = React.useState(props?.historyState?.cloning && !!quoteLineItems);
  const [detailsCloning, setDetailsCloning] = React.useState(props?.historyState?.cloning && !!quoteLineItemDetails);


  React.useEffect(() => {
    const disableF5Reload = (e: { which: any; keyCode: any; preventDefault: () => void; }) => {
      if ((e.which || e.keyCode) === 116) e.preventDefault();
    };

    window.addEventListener('keydown', disableF5Reload, false);

    return () => {
      window.removeEventListener('keydown', disableF5Reload, false);
    };
  }, []);

  React.useEffect(() => {
    if(!quote || quoteCloning) {
      loadQuote(
        id,
        props?.historyState?.quote,
        quoteLineItemSortAsc,
        setQuoteLoading,
        setLinesLoading,
        setShowQuoteSetup,
        false,
        setQuoteCloning
      );
    }
    loadRawMaterials();
    loadFinishedGoods();
    loadFreight();
    loadContent();
    loadEdgeAllowances();
    loadSpecificationTestOptions();
    loadBoosterAllowances();
    loadSelections();
    loadQuoteVariableConfigurations();
    loadVendors();
    loadWeldingPatterns();
    loadOutsideServiceCosts();

    dispatchAdvSpecState({ type: AdvSpecActionTypes.setHasInitialized, payload: false });
  }, []);

  React.useEffect(() => {
    if(dataState.rawMaterials &&
      dataState.finishedGoods &&
      dataState.freight &&
      dataState.edgeAllowances &&
      dataState.specificationTestOptions &&
      dataState.boosterAllowances &&
      dataState.selections &&
      dataState.quoteVariableConfigurations &&
      dataState.vendors &&
      dataState.weldingPatterns
      ) {
        setIsDataLoading(false);
      }
  }, [dataState]);

  React.useEffect(() => {
    if(quote) {
      setContributionPercent(quote.ContributionPercent__c || 40);
      setAgentComRate(quote.Agent_Com_Rate__c || 0);

      if(quoteLoading) {
        lastViewed.updateItemLastViewed({ id: quote.Id, lastViewed: new Date().toLocaleString() });
        setQuoteLoading(false);
      }
    }
  }, [quote]);

  const calculateQuoteTotals = async () => {
    if (quoteLineItems) {
      let cost = 0;
      let displayedCost = 0;
      let quoteTotal = 0;
      let areaSQM = 0;

      quoteLineItems.forEach(quoteLineItem => {
        //calculates the grand total for the quote
        quoteTotal += quoteLineItem.TotalAdjPrice__c;

        //using unit price to create total so it matches ui
        displayedCost += quoteLineItem.Quantity__c * +quoteLineItem.UnitCost__c?.toFixed(2);

        //true sum of all costs
        cost += quoteLineItem.Quantity__c * +quoteLineItem.UnitCost__c;

        if(!quoteLineItem.Parent_Line__c) {
          const { quoteLineItemDetail } = quoteLineItem;
          //calculates area per sqm total
          const areaSQMPerLine = (quoteLineItemDetail?.Area_Sqm__c || 0) * (quoteLineItemDetail?.Clads__c || 0);
          areaSQM += areaSQMPerLine;
        }
      });

      const totalWoComNumber = cost;
      const comAmount = quoteTotal * ((agentComRate || 0) * .01);
      const contributionCost = quoteTotal - totalWoComNumber - comAmount;
      const contPercent = (contributionCost / quoteTotal) * 100;
      const displaySQM = Number(areaSQM.toFixed(3));
      const totalSQM = Number(areaSQM);
      const contSQM = (contributionCost / totalSQM);

      //sets display to match costs on ui
      setDisplayedCostWoCom(displayedCost);
      setCurrentAgentComAmount(comAmount);

      const updatedQuote = {
        ...quote,
        Total__c: quoteTotal,
        Total_w_o_Com__c: totalWoComNumber,
        Contribution__c: contributionCost,
        ContributionPercent__c: contPercent,
        Total_SQ_M__c: displaySQM,
        Contribution_SQ_M__c: contSQM,
        Agent_Com_Amount__c: comAmount
      };

      dispatchQuoteState({type: QuoteActionTypes.loadQuote, payload: updatedQuote});
    }
  };

  React.useEffect(() => {
    if(lineUpdating) {
      dispatchQuoteState({type: QuoteActionTypes.updateLineUpdating, payload: false});
    } else if(quoteLineItems && linesLoading) {
      try {
        loadQuoteLineItemDetails(setDetailsCloning);
        setLinesLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setAreDetailsLoading(false);
      }
    }

    const updatedCalculationMap = new Map(calculationMap.entries());

    if(quoteLineItems && !quoteCloning) {
      quoteLineItems.forEach(quoteLineItem => {
        const lineCalculations = {
          Adj_Price__c: quoteLineItem.Adj_Price__c || 0,
          Anvil__c: quoteLineItem.Anvil__c,
          Anvil_TK_in__c: quoteLineItem.Anvil_TK_in__c,
          Anvil_TK_mm__c: quoteLineItem.Anvil_TK_mm__c,
          Base_Cost__c: quoteLineItem.Base_Cost__c,
          Base_Cost_Customer_Supplied__c: quoteLineItem.Base_Cost_Customer_Supplied__c,
          Base_Freight__c: quoteLineItem.Base_Freight__c,
          Base_Freight_Customer_Supplied__c: quoteLineItem.Base_Freight_Customer_Supplied__c,
          Base_Freight_KG__c: quoteLineItem.Base_Freight_KG__c,
          Base_Freight_LB__c: quoteLineItem.Base_Freight_LB__c,
          Base_KG_PC__c: quoteLineItem.Base_KG_PC__c,
          Base_LB_PC__c: quoteLineItem.Base_LB_PC__c,
          Base_Min_in__c: quoteLineItem.Base_Min_in__c,
          Base_Min_mm__c: convertUnits(quoteLineItem.Base_Min_in__c, Units.Inches, Units.Millimeters),
          Base_Nom_in__c: quoteLineItem.Base_Nom_in__c,
          Base_Nom_mm__c: convertUnits(quoteLineItem.Base_Nom_in__c, Units.Inches, Units.Millimeters),
          Base_Price_KG__c: quoteLineItem.Base_Price_KG__c,
          Base_Price_LB__c: quoteLineItem.Base_Price_LB__c,
          Clad_Cost__c: quoteLineItem.Clad_Cost__c,
          Clad_Cost_Customer_Supplied__c: quoteLineItem.Clad_Cost_Customer_Supplied__c,
          Clad_Freight__c: quoteLineItem.Clad_Freight__c,
          Clad_Freight_Customer_Supplied__c: quoteLineItem.Clad_Freight_Customer_Supplied__c,
          Clad_Freight_KG__c: quoteLineItem.Clad_Freight_KG__c,
          Clad_Freight_LB__c: quoteLineItem.Clad_Freight_LB__c,
          Clad_KG_PC__c: quoteLineItem.Clad_KG_PC__c,
          Clad_LB_PC__c: quoteLineItem.Clad_LB_PC__c,
          Clad_Min_in__c: quoteLineItem.Clad_Min_in__c,
          Clad_Min_mm__c: convertUnits(quoteLineItem.Clad_Min_in__c, Units.Inches, Units.Millimeters),
          Clad_Nom_in__c: quoteLineItem.Clad_Nom_in__c,
          Clad_Nom_mm__c: convertUnits(quoteLineItem.Clad_Nom_in__c, Units.Inches, Units.Millimeters),
          Clad_Price_KG__c: quoteLineItem.Clad_Price_KG__c,
          Clad_Price_LB__c: quoteLineItem.Clad_Price_LB__c,
          Cont_SQM__c: quoteLineItem.Cont_SQM__c || 0,
          Contribution__c: quoteLineItem.Contribution__c || 40.00,
          Dimensions_in__c: quoteLineItem.Dimensions_in__c,
          Dimensions_mm__c: quoteLineItem.Dimensions_mm__c,
          Freight_Out__c: quoteLineItem.Freight_Out__c || 0,
          Head_Forming__c: quoteLineItem.Head_Forming__c || 0,
          Head_Freight__c: quoteLineItem.Head_Freight__c || 0,
          Head_Freight_KG__c: quoteLineItem.Head_Freight_KG__c || 0,
          Head_Freight_LB__c: quoteLineItem.Head_Freight_LB__c || 0,
          Lead_Time__c: quoteLineItem.Lead_Time__c,
          OS_Cost__c: quoteLineItem.OS_Cost__c || 0,
          Price_Head__c: quoteLineItem.Price_Head__c || 0,
          Price_KG__c: quoteLineItem.Price_KG__c || 0,
          Price_LB__c: quoteLineItem.Price_LB__c || 0,
          Quantity__c: quoteLineItem.Quantity__c,
          Ship_Weight_KG__c: quoteLineItem.Ship_Weight_KG__c,
          Ship_Weight_LB__c: quoteLineItem.Ship_Weight_LB__c,
          TotalAdjPrice__c: quoteLineItem.TotalAdjPrice__c || 0,
          Total_Freight_KG__c: quoteLineItem.Total_Freight_KG__c,
          Total_Freight_LB__c: quoteLineItem.Total_Freight_LB__c,
          Total_KG_PC__c: quoteLineItem.Total_KG_PC__c,
          Total_LB_PC__c: quoteLineItem.Total_LB_PC__c,
          TotalCost__c: quoteLineItem.TotalCost__c || 0,
          UnitCost__c: quoteLineItem.UnitCost__c || 0,
          Welding__c: quoteLineItem.Welding__c,
          Welding_Length_in__c: quoteLineItem.Welding_Length_in__c || 0,
          Welding_Length_mm__c: convertUnits(quoteLineItem.Welding_Length_in__c || 0, Units.Inches, Units.Millimeters),
        };

        updatedCalculationMap.set(quoteLineItem.Line__c, lineCalculations);
      });

      dispatchQuoteState({type: QuoteActionTypes.setCalculationMap, payload: updatedCalculationMap});
      calculateQuoteTotals();
      setIsLoading(false);
    }
  }, [quoteLineItems, quoteCloning]);

  React.useEffect(() => {
    if(quoteLineItemDetails && !detailsCloning) {
      calculateQuoteTotals();
      setAreDetailsLoading(false);
      setIsLoading(false);

      if(showQuoteSetup) {
        setShowQuoteSetupPopover({ showPopover: true, event: undefined });
        setShowQuoteSetup(false);
      }
    }
  }, [quoteLineItemDetails, detailsCloning, showQuoteSetup]);

  React.useEffect(() => {
    if(quoteLineItemDetails){
      if(currentAgentComAmount !== quote?.Agent_Com_Amount__c) {
        quoteLineItems?.forEach(quoteLineItem => {
          const quoteLineItemToEdit = { ...quoteLineItem };

          saveQuoteLineItem(new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>(), [QuoteLineItemValueTypes.AgentComRate], quoteLineItemToEdit);
        });
      }}
  }, [currentAgentComAmount]);

  // Navigates back to the quotes page
  const navigateToQuotesPage = () => {
    history.push('/quotes');
  };

  // Returns true if the quote line items are expandable
  const expandableQuoteLineItems = () => {
    return (activeTab === QuoteItemSelectionTabs.Sizing || activeTab === QuoteItemSelectionTabs.Cost || activeTab === QuoteItemSelectionTabs.OSCost);
  };

  // Clones the quote to salesforce and opens it
  const cloneQuote = async () => {
    setIsLoading(true);
    setAreDetailsLoading(true);

    try {
      const { id } = await salesforce.cloneAccountQuoteById(quote.Id);
      history.push(`/quotes/quote-detail/${id}`, { cloning: true });
    } catch (error) {
      toast(ToastTypes.Error, ToastMessages.CloneFailed);
      setIsLoading(false);
      setAreDetailsLoading(false);
      return;
    }

    toast(ToastTypes.Success, ToastMessages.QuoteCloned);
  };

  // Deletes quote line item and its details from the UI
  const deleteQuoteLineItem = (quoteLineItemToDelete: QuoteLineItem) => {
    // if there is a virtual detail group, delete all the details in the group
    const vIds = quoteLineItemToDelete?.quoteLineItemDetail?.virtualDetailGroup?.virtualShotGroups
      ?.map(detail => quoteLineItems.find(item => item.UUID__c === detail.virtualLineUUID)?.UUID__c)
      ?.filter(Boolean) ?? [];

    // remove all the details in the group
    const filteredOutVirtualLineItems = quoteLineItems.filter(qli => (!vIds.includes(qli.UUID__c)));

    const updatedQuoteLineItems = filteredOutVirtualLineItems.filter(quoteLineItem => quoteLineItemToDelete.Id ? quoteLineItem.Id !== quoteLineItemToDelete.Id : quoteLineItem.displayId !== quoteLineItemToDelete.displayId);
    const updatedDefaultValueMap = new Map(defaultValueMap.entries());
    const updatedFieldsEditedMap = new Map(calculatedFieldsEditedMap.entries());
    
    // TODO: figure out how to handle virtual line decrementing
    updatedQuoteLineItems.forEach(quoteLineItem => {
      if(+quoteLineItem.Line__c > +quoteLineItemToDelete.Line__c) {
        const quoteLineItemDetailToUpdate = quoteLineItem.quoteLineItemDetail;

        quoteLineItem.Line__c = (+quoteLineItem.Line__c - 1).toString().padStart(3, '0');

        if(quoteLineItem.displayId) {
          quoteLineItemDetailToUpdate.associatedLineItem = (+quoteLineItemDetailToUpdate.associatedLineItem - 1).toString();

          const defaultValues = defaultValueMap.get(quoteLineItem.displayId);

          if(defaultValues) {
            updatedDefaultValueMap.set((+quoteLineItem.displayId - 1).toString(), defaultValues);
            updatedDefaultValueMap.delete(quoteLineItem.displayId);
          }

          const fieldsEdited = calculatedFieldsEditedMap.get(quoteLineItem.displayId);

          if(fieldsEdited) {
            updatedFieldsEditedMap.set((+quoteLineItem.displayId - 1).toString(), fieldsEdited);
            updatedFieldsEditedMap.delete(quoteLineItem.displayId);
          }

          quoteLineItem.displayId = (+quoteLineItem.displayId - 1).toString();
        }
      }
    });

    dispatchQuoteState({type: QuoteActionTypes.updateLineUpdating, payload: true});
    dispatchQuoteState({type: QuoteActionTypes.loadQuoteLineItems, payload: [...updatedQuoteLineItems]});
    dispatchQuoteState({type: QuoteActionTypes.updateDefaultValues, payload: updatedDefaultValueMap});
    dispatchQuoteState({type: QuoteActionTypes.updateCalculatedFieldsEdited, payload: updatedFieldsEditedMap});
  };

  const getDuplicatedQuoteLineItemDetail = (quoteLineItemForDetail: QuoteLineItem, itemUUID: string, detailUUID: string) => {
    const associatedLineItem = (quoteLineItems.length + 1).toString();
    const quoteLineItemDetailToDuplicate = quoteLineItemForDetail.quoteLineItemDetail;

    if (!quoteLineItemDetailToDuplicate) {
      return null;
    }

    const duplicatedDetail = {
      ...quoteLineItemDetailToDuplicate,
      Id: undefined,
      UUID__c: detailUUID,
      itemUUID: itemUUID,
      NC_Quote_Line_Item__c: undefined,
      associatedLineItem: associatedLineItem,
      NC_Tubesheet_Item_Details__r: {
        ...quoteLineItemDetailToDuplicate.NC_Tubesheet_Item_Details__r,
        Id: undefined,
        NC_Quote_Line_Item_Detail__c: undefined
      },
      NC_Head_Item_Detail__r: {
        ...quoteLineItemDetailToDuplicate.NC_Head_Item_Detail__r,
        Id: undefined,
        NC_Quote_Line_Item_Detail__c: undefined
      },
      NC_Cylinder_Item_Details__r: {
        ...quoteLineItemDetailToDuplicate.NC_Cylinder_Item_Details__r,
        Id: undefined,
        NC_Quote_Line_Item_Detail__c: undefined
      },
      NC_Multi_Shot_Item_Details__r: {
        ...quoteLineItemDetailToDuplicate.NC_Multi_Shot_Item_Details__r,
        Id: undefined,
        NC_Quote_Line_Item_Detail__c: undefined
      },
      NC_Anvil_Item_Details__r: {
        ...quoteLineItemDetailToDuplicate.NC_Anvil_Item_Details__r,
        Id: undefined,
        NC_Quote_Line_Item_Detail__c: undefined
      },
      NC_Flyer_Item_Details__r: {
        ...quoteLineItemDetailToDuplicate.NC_Flyer_Item_Details__r,
        Id: undefined,
        NC_Quote_Line_Item_Detail__c: undefined
      },
      NC_Seam_Weld_Item_Details__r: {
        ...quoteLineItemDetailToDuplicate.NC_Seam_Weld_Item_Details__r,
        Id: undefined,
        NC_Quote_Line_Item_Detail__c: undefined
      },
      NC_Outside_Service_Item_Details__r: {
        ...quoteLineItemDetailToDuplicate.NC_Outside_Service_Item_Details__r,
        Id: undefined,
        NC_Quote_Line_Item_Detail__c: undefined
      },
      NC_Int_Attributes__r: {}
    };

    return duplicatedDetail;
  };

  const duplicatedQuoteLineItem = (quoteLineItemToDuplicate: QuoteLineItem) => {
    const detailUUID = generateUUID();
    const itemUUID = generateUUID();

    return {
      ...quoteLineItemToDuplicate,
      quoteLineItemDetail: getDuplicatedQuoteLineItemDetail(quoteLineItemToDuplicate, itemUUID, detailUUID),
      Id: undefined,
      UUID__c: itemUUID,
      detailUUID: detailUUID,
      Line__c: (quoteLineItems.length + 1).toString().padStart(3, '0'),
      displayId: (quoteLineItems.length + 1).toString(),
    };
  };

  // Duplicates the quote line item and its details on the UI
  const duplicateQuoteLineItem = (quoteLineItemIdToDuplicate: string) => {

    const updatedQuoteLineItems: QuoteLineItem[] = quoteLineItems;

    const quoteLineItemToDuplicate = quoteLineItems.find(quoteLineItem => (quoteLineItem.Id || quoteLineItem.displayId) === quoteLineItemIdToDuplicate);

    const newQuoteLineItem: QuoteLineItem = duplicatedQuoteLineItem(quoteLineItemToDuplicate);

    updatedQuoteLineItems.push(newQuoteLineItem);

    dispatchQuoteState({type: QuoteActionTypes.updateLineUpdating, payload: true});
    dispatchQuoteState({type: QuoteActionTypes.loadQuoteLineItems, payload: [...updatedQuoteLineItems]});
  };

  // Returns true once all the necessary data is retrieved and the quote is finished loading
  const isLoadingComplete = () => {
    return !isLoading && !!quote;
  };

  // Updates the quote line item with the updated fields
  const updateQuoteLineItemCalculatedFields = (lineToUpdate: QuoteLineItem, updatedFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>, itemId: string) => {
    const updatedQuoteLineItem = lineToUpdate;

    Array.from(updatedFieldsMap.keys()).forEach(field => {
      updatedQuoteLineItem[field] = updatedFieldsMap.get(field);
    });

    dispatchQuoteState({type: QuoteActionTypes.loadQuoteLineItems, payload: [...quoteLineItems.filter(quoteLineItem => (quoteLineItem.Id || quoteLineItem.displayId) !== itemId), updatedQuoteLineItem].orderBy(item => item[getColumnIdFromName(QuoteLineItemsColumnHeadings.Line)], quoteLineItemSortAsc)});
  };

  // Updates the quote line item detail with the updated fields
  const updateQuoteLineItemDetailCalculatedFields = (lineToUpdate: QuoteLineItemDetail, updatedQuoteLineItemDetailFieldsMap:  Map<QuoteLineItemFields, FieldsMapValueType>, updatedOutsideServicesFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>, updatedTubesheetItemDetailFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>, updatedAnvilItemDetailFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>, itemId: string) => {
    const updatedQuoteLineItemDetail = lineToUpdate;

    Array.from(updatedQuoteLineItemDetailFieldsMap.keys()).forEach(field => {
      updatedQuoteLineItemDetail[field] = updatedQuoteLineItemDetailFieldsMap.get(field);
    });

    Array.from(updatedOutsideServicesFieldsMap.keys()).forEach(field => {
      updatedQuoteLineItemDetail.NC_Outside_Service_Item_Details__r[field] = updatedOutsideServicesFieldsMap.get(field);
    });

    Array.from(updatedTubesheetItemDetailFieldsMap.keys()).forEach(field => {
      updatedQuoteLineItemDetail.NC_Tubesheet_Item_Details__r[field] = updatedTubesheetItemDetailFieldsMap.get(field);
    });

    Array.from(updatedAnvilItemDetailFieldsMap.keys()).forEach(field => {
      updatedQuoteLineItemDetail.NC_Anvil_Item_Details__r[field] = updatedAnvilItemDetailFieldsMap.get(field);
    });

    dispatchQuoteState({type: QuoteActionTypes.loadQuoteLineItemDetails, payload: [...quoteLineItemDetails.filter(quoteLineItemDetail => (quoteLineItemDetail.Id || quoteLineItemDetail.associatedLineItem) !== itemId), updatedQuoteLineItemDetail]});
  };

  // Recalculates all the fields for the quote line item and detail
  const recalculateFields = () => {
    try {
      const fieldsToRecalculateDependencies = [
        QuoteLineItemValueTypes.QuantityForLeftoverCalculation,
        QuoteLineItemValueTypes.UnitOfMeasure,
        QuoteLineItemValueTypes.CladMetal,
        QuoteLineItemValueTypes.CladMetalProposalType,
        QuoteLineItemValueTypes.CustomerProvidedClad,
        QuoteLineItemValueTypes.BaseMetal,
        QuoteLineItemValueTypes.BaseMetalProposalType,
        QuoteLineItemValueTypes.CustomerProvidedBase,
        QuoteLineItemValueTypes.ItemType,
        QuoteLineItemValueTypes.Shape,
        QuoteLineItemValueTypes.Width,
        QuoteLineItemValueTypes.Length,
        QuoteLineItemValueTypes.CutMethod,
        QuoteLineItemValueTypes.HeadFormingType,
        QuoteLineItemValueTypes.IDorOD,
        QuoteLineItemValueTypes.HeadDiameter,
        QuoteLineItemValueTypes.StraightFlange,
        QuoteLineItemValueTypes.HeadEdgePreparation,
        QuoteLineItemValueTypes.OpenEndDiameter,
        QuoteLineItemValueTypes.InsideDishRadius,
        QuoteLineItemValueTypes.Cutback,
        QuoteLineItemValueTypes.InsideKnuckleRadius,
        QuoteLineItemValueTypes.HeadFormingCostPerHead,
        QuoteLineItemValueTypes.HeadFreightZone,
        QuoteLineItemValueTypes.TotalFreightPerWeight,
        QuoteLineItemValueTypes.HeadTimeToFormer,
        QuoteLineItemValueTypes.HeadTimeToFormHead,
        QuoteLineItemValueTypes.BlankSize,
        QuoteLineItemValueTypes.CreateTwoPieceHead,
        QuoteLineItemValueTypes.Machining,
        QuoteLineItemValueTypes.CylinderType,
        QuoteLineItemValueTypes.CylinderIDorOD,
        QuoteLineItemValueTypes.Diameter,
        QuoteLineItemValueTypes.CylinderEdgePreparation,
        QuoteLineItemValueTypes.RollingCostPerCylinder,
        QuoteLineItemValueTypes.FreightChargeToCustomerPer,
        QuoteLineItemValueTypes.FreightChargeToRollerPer,
        QuoteLineItemValueTypes.TransitTimeToRollerWeeks,
        QuoteLineItemValueTypes.TimeToRollWeeks,
        QuoteLineItemValueTypes.CylinderNotes,
        QuoteLineItemValueTypes.QuantityPerCylinder,
        QuoteLineItemValueTypes.PlateWidthSupplyToFormer,
        QuoteLineItemValueTypes.PlateLengthSupplyToFormer,
        QuoteLineItemValueTypes.FinishedCladTK,
        QuoteLineItemValueTypes.CladType,
        QuoteLineItemValueTypes.FinishedBaseTK,
        QuoteLineItemValueTypes.BaseType,
        QuoteLineItemValueTypes.DNC,
        QuoteLineItemValueTypes.Pieces,
        QuoteLineItemValueTypes.Specification,
        QuoteLineItemValueTypes.Certificate,
        QuoteLineItemValueTypes.BaseMetalUT,
        QuoteLineItemValueTypes.BondMetalUT,
        QuoteLineItemValueTypes.PostCladTestingOptions,
        QuoteLineItemValueTypes.AdditionalOptions,
        QuoteLineItemValueTypes.CosmeticGrindingSpecifications,
        QuoteLineItemValueTypes.PeggedSupply,
        QuoteLineItemValueTypes.SharedCladdedPlate,
        QuoteLineItemValueTypes.Item,
        QuoteLineItemValueTypes.ShowDetail,
        QuoteLineItemValueTypes.ParentLine,
        QuoteLineItemValueTypes.ContPercentage
      ];

      quoteLineItems.forEach(quoteLineItem => {
        const quoteLineItemToEdit = { ...quoteLineItem };

        quoteLineItemToEdit.PeggedSupply__c = null;
        quoteLineItemToEdit.SharedCladdedPlate__c = false;
        quoteLineItemToEdit.Item__c = 0;
        quoteLineItemToEdit.Show_Detail__c = true;
        quoteLineItemToEdit.Parent_Line__c = null;
        quoteLineItemToEdit.PlateMethod__c = quoteLineItem.Shape__c;
        quoteLineItemToEdit.Contribution__c = 40.00;

        saveQuoteLineItem(new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>(), fieldsToRecalculateDependencies, quoteLineItemToEdit, false, false, false, true);
      });

    }
    catch(error) {
      toast(ToastTypes.Error, ToastMessages.ResetFailed);
      console.error(error);
      return;
    }

    toast(ToastTypes.Success, ToastMessages.ResetComplete);
  };

  const handleQuoteAction = (action: QuoteActions) => {
    switch(action) {
      case QuoteActions.Save:
        saveQuote(id, action, props?.historyState?.quote, setIsLoading, setAreDetailsLoading, setQuoteLoading, setLinesLoading, setShowQuoteSetup);
        break;
      case QuoteActions.SubmitQuote:
        saveQuote(id, action, props?.historyState?.quote, setIsLoading, setAreDetailsLoading, setQuoteLoading, setLinesLoading, setShowQuoteSetup, undefined, QuoteDisplayStatuses.SubmitQuoteToD365);
        break;
      case QuoteActions.CloneQuote:
        sync(() => cloneQuote());
        break;
      case QuoteActions.Lock:
        saveQuote(id, action, props?.historyState?.quote, setIsLoading, setAreDetailsLoading, setQuoteLoading, setLinesLoading, setShowQuoteSetup, undefined, QuoteDisplayStatuses.Lock);
        break;
      case QuoteActions.SendQuoteToCustomer:
        saveQuote(id, action, props?.historyState?.quote, setIsLoading, setAreDetailsLoading, setQuoteLoading, setLinesLoading, setShowQuoteSetup, undefined, QuoteDisplayStatuses.SendQuoteToCustomer);
        break;
      case QuoteActions.SubmitToPlanning:
        saveQuote(id, action, props?.historyState?.quote, setIsLoading, setAreDetailsLoading, setQuoteLoading, setLinesLoading, setShowQuoteSetup, undefined, QuoteDisplayStatuses.SubmitToPlanning);
        break;
      case QuoteActions.RevertToDraft:
        saveQuote(id, action, props?.historyState?.quote, setIsLoading, setAreDetailsLoading, setQuoteLoading, setLinesLoading, setShowQuoteSetup, undefined, QuoteDisplayStatuses.Draft);
        break;
    }
  };

  const shouldShowLineItemDetail = (quoteLineItem: QuoteLineItem) => {
    return activeTab === QuoteItemSelectionTabs.Cost || activeTab === QuoteItemSelectionTabs.OSCost || (quoteLineItem.Show_Detail__c || (!quoteLineItem.PeggedSupply__c && !quoteLineItem.SharedCladdedPlate__c));
  };

  // Navigates to the order entry page for the quote
  const navigateToOrderEntry = () => {
    history.push(`/quotes/quote-detail/${quote.Id}/order-entry`);
  };

  const QuoteDetailTitleCard = () => {
    return (
      <>
        <IonCard class='quote-detail-title-card'>
          <IonCardHeader class='quote-detail-title-card-header'>
            <IonGrid>
              <IonRow data-testid="QAQuoteDetailPageHeader">
                <IonLabel class='quotes-title'><span className="quotes" onClick={() => navigateToQuotesPage()} data-testid="QAQuoteDetailPageTitle">Quotes</span><IonLabel class='quote-number-title' data-testid="QAQuoteDetailPageQuoteNumberTitle"> / {quote?.Name}</IonLabel></IonLabel>
                <LineItemActions
                  visible={!isQuoteLocked}
                  actions={[]}
                >
                </LineItemActions>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const Tab = (props) => {
    return (
      <IonLabel class={classNames({
        'line-items-label': true,
        'tab': true,
        'active': activeTab === props.tab
      })} data-testid={'QAQuoteDetailPageTab_' + props.tab} onClick={() => setActiveTab(props.tab)}
      >{props.tab}</IonLabel>
    );
  };

  const ExpandLineItemColumn = (props) => {
    return (expandableQuoteLineItems() ?
      <IonCol size={activeTab === QuoteItemSelectionTabs.Sizing ? '0.23' : '0.23'} class="column-left expand-column">
        {!props.hideDetail ? <IonIcon className="quote-line-item-expand-button" data-testid="QAQuoteDetailPageSizingQuoteLineItemExpandButton" src={props.expanded ? chevronUp : chevronDown} onClick={() => props.setExpanded(!props.expanded)}></IonIcon> : null}
      </IonCol> : null
    );
  };

  const SizingQuoteLineItem = (props) => {
    const [expanded, setExpanded] = React.useState(true);

    const handleOnEditClick = () => {
      if(!isQuoteLocked) {
        setQuoteLineItemToEdit(props.quoteLineItem);
        setQuoteLineItemDetailToEdit(props.quoteLineItem.quoteLineItemDetail);
        setAddEditLineItemPopoverState(true);
      }
    };

    const innerDeleteQuoteLineItem = () => {
      deleteQuoteLineItem(props.quoteLineItem);
      setQuoteLineItemToEdit(null);
      setQuoteLineItemDetailToEdit(null);
    };

    const innerDuplicateQuoteLineItem = () => {
      duplicateQuoteLineItem(props.quoteLineItem.Id || props.quoteLineItem.displayId);
      setQuoteLineItemToEdit(null);
      setQuoteLineItemDetailToEdit(null);
    };

    React.useEffect(() => {
      expandableQuoteLineItems();
      return () => {
        setExpanded(true);
      };
    }, []);

    const tableHeadersSizingQuoteLineItem = [
      { display: '', colSize: '0.23' }, // blank column
      { display: QuoteLineItemsColumnHeadings.Line, colSize: '0.48' },
      { display: QuoteLineItemsColumnHeadings.Quantity, colSize: '0.48' },
      { display: QuoteLineItemsColumnHeadings.ProductDescription, colSize: '1.97' },
      { display: QuoteLineItemsColumnHeadings.Width, colSize: '0.76' },
      { display: QuoteLineItemsColumnHeadings.Length, colSize: '0.76' },
      { display: QuoteLineItemsColumnHeadings.CladType, colSize: '0.76' },
      { display: QuoteLineItemsColumnHeadings.CladTK, colSize: '0.76' },
      { display: QuoteLineItemsColumnHeadings.BaseType, colSize: '0.76' },
      { display: QuoteLineItemsColumnHeadings.BaseTK, colSize: '0.76' },
    ];

    return (
      <>
        {activeTab === QuoteItemSelectionTabs.Sizing &&
          <TableHeaders labels={tableHeadersSizingQuoteLineItem} hidden={props.quoteLineItem.SharedCladdedPlate__c || props.quoteLineItemIndex === 0 || !expanded}/>
        }
        <IonCard className='quote-line-item-card' data-testid={'QAQuoteDetailPageSizingQuoteLineItem_' + props.quoteLineItemIndex}>
          <IonCardHeader class={classNames({'quote-line-item-card-header': true, 'orange-row': props.quoteLineItem.PlateMethod__c === PlateMethods.ManuallySized })}>
            <IonGrid>
              <IonRow class="quote-line-item-row">
                <ExpandLineItemColumn setExpanded={setExpanded} expanded={expanded} hideDetail={props.quoteLineItem.PeggedSupply__c || (props.quoteLineItem.SharedCladdedPlate__c && !props.quoteLineItem.Show_Detail__c)}/>
                <IonCol size="0.48" class="column-left">
                  <LineItemField valueType={QuoteLineItemValueTypes.Line} datatestid="QAQuoteDetailPageSizingQuoteLineItemLine" displayedValue={props.quoteLineItem.Line__c}/>
                </IonCol>
                <IonCol size="0.48" class="column-left">
                  <LineItemField
                    datatestid="QAQuoteDetailPageSizingQuoteLineItemQuantity"
                    valueType={QuoteLineItemValueTypes.SizingQuantity}
                    value={props.quoteLineItem.Quantity__c}
                    field={QuoteLineItemFields.Quantity}
                    itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                    lineType={QuoteLineTypes.Master}
                    tabIndex={props.quoteLineItemIndex + '.0'}
                    nextTabIndex={props.quoteLineItemIndex + '.1'}
                    readOnly={isQuoteLocked}
                  />
                </IonCol>
                <IonCol size="1.67" class="column-left">
                  <div
                    className={classNames({ 'line-item-popover-wrapper': true, 'link-no-underline': !isQuoteLocked})}
                    onDoubleClick={() => {
                      if(!isQuoteLocked) {
                        setQuoteLineItemToEdit(props.quoteLineItem);
                        setQuoteLineItemDetailToEdit(props.quoteLineItem.quoteLineItemDetail);
                        setAddEditLineItemPopoverState(true);
                      }
                    }}
                  >
                    <LineItemField valueType={QuoteLineItemValueTypes.SizingProduct} datatestid="QAQuoteDetailPageSizingQuoteLineItemProduct" displayedValue={isDisplayImperial ? props.quoteLineItem.ItemDescriptionImp__c : props.quoteLineItem.ItemDescriptionMetric__c || '- -'}/>
                  </div>
                </IonCol>
                {activeTab === QuoteItemSelectionTabs.FinishedGoods &&
                <IonCol size="1.1" class="column-left">
                  <div
                    className={classNames({ 'line-item-popover-wrapper': true, 'link-no-underline': !isQuoteLocked})}
                    onDoubleClick={() => {
                      if(!isQuoteLocked) {
                        setQuoteLineItemToEdit(props.quoteLineItem);
                        setQuoteLineItemDetailToEdit(props.quoteLineItem.quoteLineItemDetail);
                        setAddEditLineItemPopoverState(true);
                      }
                    }}
                  >
                    <LineItemField valueType={QuoteLineItemValueTypes.CladMetal} datatestid="QAQuoteDetailPageSizingQuoteLineItemCladMetal" displayedValue={props.quoteLineItem.CladMetal__c}/>
                  </div>
                </IonCol>}
                {activeTab === QuoteItemSelectionTabs.FinishedGoods &&
                <IonCol size="1.1" class="column-left">
                  <div
                    className={classNames({ 'line-item-popover-wrapper': true, 'link-no-underline': !isQuoteLocked})}
                    onDoubleClick={() => {
                      if(!isQuoteLocked) {
                        setQuoteLineItemToEdit(props.quoteLineItem);
                        setQuoteLineItemDetailToEdit(props.quoteLineItem.quoteLineItemDetail);
                        setAddEditLineItemPopoverState(true);
                      }
                    }}
                  >
                    <LineItemField valueType={QuoteLineItemValueTypes.BaseMetal} datatestid="QAQuoteDetailPageSizingQuoteLineItemBaseMetal" displayedValue={props.quoteLineItem.BaseMetal__c}/>
                  </div>
                </IonCol>}
                <IonCol size="0.76" class="column-left">
                  <LineItemField
                    datatestid="QAQuoteDetailPageSizingQuoteLineItemWidth"
                    valueType={QuoteLineItemValueTypes.Width}
                    uom={displayUom}
                    imperialValue={props.quoteLineItem.Width_in__c}
                    metricValue={props.quoteLineItem.Width_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    imperialField={QuoteLineItemFields.WidthIn}
                    metricField={QuoteLineItemFields.WidthMm}
                    itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                    lineType={QuoteLineTypes.Master}
                    tabIndex={props.quoteLineItemIndex + '.1'}
                    nextTabIndex={props.quoteLineItemIndex + '.2'}
                    readOnly={isQuoteLocked}
                    selectedTabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </IonCol>
                <IonCol size="0.76" class="column-left">
                  <LineItemField
                    datatestid="QAQuoteDetailPageSizingQuoteLineItemLength"
                    valueType={QuoteLineItemValueTypes.Length}
                    uom={displayUom}
                    imperialValue={props.quoteLineItem.Length_in__c}
                    metricValue={props.quoteLineItem.Length_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    imperialField={QuoteLineItemFields.LengthIn}
                    metricField={QuoteLineItemFields.LengthMm}
                    itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                    lineType={QuoteLineTypes.Master}
                    tabIndex={props.quoteLineItemIndex + '.2'}
                    nextTabIndex={props.quoteLineItemIndex + '.3'}
                    readOnly={isQuoteLocked}
                    selectedTabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </IonCol>
                <IonCol size="0.76" class="column-left">
                  <div
                    className={classNames({ 'line-item-popover-wrapper': true, 'link-no-underline': !isQuoteLocked})}
                    onDoubleClick={() => {
                      if(!isQuoteLocked) {
                        setQuoteLineItemToEdit(props.quoteLineItem);
                        setQuoteLineItemDetailToEdit(props.quoteLineItem.quoteLineItemDetail);
                        setAddEditLineItemPopoverState(true);
                      }
                    }}
                  >
                    <LineItemField valueType={QuoteLineItemValueTypes.CladType} datatestid="QAQuoteDetailPageSizingQuoteLineItemCladType" displayedValue={props.quoteLineItem.Clad_Type__c || '- -'}/>
                  </div>
                </IonCol>
                <IonCol size="0.76" class="column-left">
                  <LineItemField
                    datatestid="QAQuoteDetailPageSizingQuoteLineItemCladTK"
                    valueType={QuoteLineItemValueTypes.FinishedCladTK}
                    uom={displayUom}
                    imperialValue={props.quoteLineItem.Clad_TK_in__c}
                    metricValue={props.quoteLineItem.Clad_TK_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                    imperialField={QuoteLineItemFields.CladTKIn}
                    metricField={QuoteLineItemFields.CladTKMm}
                    itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                    lineType={QuoteLineTypes.Master}
                    tabIndex={props.quoteLineItemIndex + '.3'}
                    nextTabIndex={props.quoteLineItemIndex + '.4'}
                    readOnly={isQuoteLocked}
                    selectedTabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </IonCol>
                <IonCol size="0.76" class="column-left">
                  <div
                    className={classNames({ 'line-item-popover-wrapper': true, 'link-no-underline': !isQuoteLocked})}
                    onDoubleClick={() => {
                      if(!isQuoteLocked) {
                        setQuoteLineItemToEdit(props.quoteLineItem);
                        setQuoteLineItemDetailToEdit(props.quoteLineItem.quoteLineItemDetail);
                        setAddEditLineItemPopoverState(true);
                      }
                    }}
                  >
                    <LineItemField valueType={QuoteLineItemValueTypes.BaseType} datatestid="QAQuoteDetailPageSizingQuoteLineItemBaseType" displayedValue={props.quoteLineItem.Base_Type__c || '- -'}/>
                  </div>
                </IonCol>
                <IonCol size="0.76" class="column-left">
                  <LineItemField
                    datatestid="QAQuoteDetailPageSizingQuoteLineItemBaseTK"
                    valueType={QuoteLineItemValueTypes.FinishedBaseTK}
                    uom={displayUom}
                    imperialValue={props.quoteLineItem.Base_TK_in__c}
                    metricValue={props.quoteLineItem.Base_TK_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                    imperialField={QuoteLineItemFields.BaseTKIn}
                    metricField={QuoteLineItemFields.BaseTKMm}
                    itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                    lineType={QuoteLineTypes.Master}
                    tabIndex={props.quoteLineItemIndex + '.4'}
                    nextTabIndex={props.quoteLineItemIndex + '.0'}
                    readOnly={isQuoteLocked}
                    selectedTabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </IonCol>
                <IonCol size="0.57" class="column-left checkmark-column">
                  <div className="column-container">
                    <IonIcon class={classNames({ 'checkmark-icon': true, 'checked': props.quoteLineItem.DNC__c })} src={checkmarkCircleOutline}></IonIcon>
                    <span className={isQuoteLocked ? 'quote-line-item-summary-text checkmark-label' : 'quote-line-item-summary-text checkmark-label link'} onClick={() => handleOnEditClick()}>DNC</span>
                  </div>
                </IonCol>
                <IonCol size="0.57" class="column-left checkmark-column">
                  <div className="column-container">
                    <IonIcon class={classNames({ 'checkmark-icon': true, 'checked': props.quoteLineItem.Pieces__c })} src={checkmarkCircleOutline}></IonIcon>
                    <span className={isQuoteLocked ? 'quote-line-item-summary-text checkmark-label' : 'quote-line-item-summary-text checkmark-label link'} onClick={() => handleOnEditClick()}>Pieces</span>
                  </div>
                </IonCol>
                <IonCol size="0.57" class="column-left checkmark-column">
                  <div className="column-container">
                    <IonIcon class={classNames({ 'checkmark-icon': true, 'checked': props.quoteLineItem.OVSL__c })} src={checkmarkCircleOutline}></IonIcon>
                    <span className={isQuoteLocked ? 'quote-line-item-summary-text checkmark-label' : 'quote-line-item-summary-text checkmark-label link'} onClick={() =>handleOnEditClick()}>OVSL</span>
                  </div>
                </IonCol>
                <IonCol size="0.57" class="column-left checkmark-column">
                  <div className="column-container">
                    <IonIcon class={classNames({ 'checkmark-icon': true, 'checked': props.quoteLineItem.quoteLineItemDetail.MultiShotCount__c })} src={checkmarkCircleOutline}></IonIcon>
                    <span className={isQuoteLocked ? 'quote-line-item-summary-text checkmark-label' : 'quote-line-item-summary-text checkmark-label link'} /*onClick={() =>handleOnEditClick()}*/>Multi</span>
                  </div>
                </IonCol>

                { !isDataLoading && <BrokenRuleWarning lineItemData={props.quoteLineItem} /> }

                <LineItemActions
                  visible={!isQuoteLocked}
                  actions={props.quoteLineItem.isVirtualLineItem ? [{
                    label: QuoteLineItemActions.Delete,
                    actionToPerform: innerDeleteQuoteLineItem
                  }] : [{
                    label: QuoteLineItemActions.Edit,
                    actionToPerform: handleOnEditClick
                  },{
                    label: QuoteLineItemActions.Delete,
                    actionToPerform: innerDeleteQuoteLineItem
                  },{
                    label: QuoteLineItemActions.Duplicate,
                    actionToPerform: innerDuplicateQuoteLineItem
                  }]}
                  data-testid="QAQuoteDetailPageSizingQuoteLineItemActions"
                >
                </LineItemActions>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <QuoteLineItemExpandedTable expanded={expanded} quoteLineItem={props.quoteLineItem} quoteLineItemIndex={props.quoteLineItemIndex} activeTab={activeTab} quoteLineItems={quoteLineItems} runFrankie={props.runFrankie} />
      </>
    );
  };

  const QuoteLineItemDetails = (props) => {
    return (
      <>
        <IonCard className="quote-line-item-card detail" data-testid={'QAQuoteDetailPageQuoteLineItemDetails'}>
          <IonCardHeader class={classNames({ 'quote-line-item-card-header': true, 'even': props.quoteLineItemIndex % 2 === 0, 'odd': props.quoteLineItemIndex % 2 !== 0, 'teal-row': activeTab === QuoteItemSelectionTabs.Sizing })}>
            <IonGrid>
              <IonRow class="quote-line-item-row">
                <IonCol size="0.75" class="column-left">
                  <LineItemField valueType={QuoteLineItemValueTypes.Clads} datatestid="QAQuoteDetailPageQuoteLineItemDetailsClads" displayedValue={props.quoteLineItemDetail.Clads__c || '- -'}/>
                </IonCol>
                <IonCol size="0.75" class="column-left">
                  <LineItemField valueType={QuoteLineItemValueTypes.DetailQuantity} datatestid="QAQuoteDetailPageQuoteLineItemDetailsQuantity" displayedValue={props.quoteLineItemDetail.Quantity__c || '- -'}/>
                </IonCol>
                <IonCol size="0.50" class="column-left">
                  <LineItemField
                    datatestid="QAQuoteDetailPageQuoteLineItemDetailsW"
                    valueType={QuoteLineItemValueTypes.Wide}
                    value={props.quoteLineItemDetail.Wide__c}
                    field={QuoteLineItemFields.Wide}
                    itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                    lineId={props.quoteLineItemId}
                    lineType={QuoteLineTypes.Detail}
                    tabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.0'}
                    nextTabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.1'}
                    readOnly={isQuoteLocked}
                    selectedTabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </IonCol>
                <IonCol size="0.50" class="column-left">
                  <LineItemField
                    datatestid="QAQuoteDetailPageQuoteLineItemDetailsL"
                    valueType={QuoteLineItemValueTypes.Long}
                    value={props.quoteLineItemDetail.Long__c}
                    field={QuoteLineItemFields.Long}
                    itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                    lineId={props.quoteLineItemId}
                    lineType={QuoteLineTypes.Detail}
                    tabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.1'}
                    nextTabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.2'}
                    readOnly={isQuoteLocked}
                    selectedTabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </IonCol>
                <IonCol size="1.35" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemDetailsCladWidth"
                      valueType={QuoteLineItemValueTypes.CladWidth}
                      uom={displayUom}
                      imperialValue={props.quoteLineItemDetail.Clad_Width_in__c}
                      metricValue={props.quoteLineItemDetail.Clad_Width_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                      imperialField={QuoteLineItemFields.CladWidthIn}
                      metricField={QuoteLineItemFields.CladWidthMm}
                      itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                      lineId={props.quoteLineItemId}
                      lineType={QuoteLineTypes.Detail}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.2'}
                      nextTabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.3'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.35" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemDetailsCladLength"
                      valueType={QuoteLineItemValueTypes.CladLength}
                      uom={displayUom}
                      imperialValue={props.quoteLineItemDetail.Clad_Length_in__c}
                      metricValue={props.quoteLineItemDetail.Clad_Length_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                      imperialField={QuoteLineItemFields.CladLengthIn}
                      metricField={QuoteLineItemFields.CladLengthMm}
                      itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                      lineId={props.quoteLineItemId}
                      lineType={QuoteLineTypes.Detail}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.3'}
                      nextTabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.4'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.35" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemDetailsCladTK"
                      valueType={QuoteLineItemValueTypes.CladTK}
                      uom={displayUom}
                      imperialValue={props.quoteLineItemDetail.Clad_TK_in__c}
                      metricValue={props.quoteLineItemDetail.Clad_TK_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      imperialField={QuoteLineItemFields.CladTKIn}
                      metricField={QuoteLineItemFields.CladTKMm}
                      itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                      lineId={props.quoteLineItemId}
                      lineType={QuoteLineTypes.Detail}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.4'}
                      nextTabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.5'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.35" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemDetailsBaseWidth"
                      valueType={QuoteLineItemValueTypes.BaseWidth}
                      uom={displayUom}
                      imperialValue={props.quoteLineItemDetail.Base_Width_in__c}
                      metricValue={props.quoteLineItemDetail.Base_Width_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                      imperialField={QuoteLineItemFields.BaseWidthIn}
                      metricField={QuoteLineItemFields.BaseWidthMm}
                      itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                      lineId={props.quoteLineItemId}
                      lineType={QuoteLineTypes.Detail}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.5'}
                      nextTabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.6'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.35" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemDetailsBaseLength"
                      valueType={QuoteLineItemValueTypes.BaseLength}
                      uom={displayUom}
                      imperialValue={props.quoteLineItemDetail.Base_Length_in__c}
                      metricValue={props.quoteLineItemDetail.Base_Length_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                      imperialField={QuoteLineItemFields.BaseLengthIn}
                      metricField={QuoteLineItemFields.BaseLengthMm}
                      itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                      lineId={props.quoteLineItemId}
                      lineType={QuoteLineTypes.Detail}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.6'}
                      nextTabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.7'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.35" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemDetailsBaseTK"
                      valueType={QuoteLineItemValueTypes.BaseTK}
                      uom={displayUom}
                      imperialValue={props.quoteLineItemDetail.Base_TK_in__c}
                      metricValue={props.quoteLineItemDetail.Base_TK_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      imperialField={QuoteLineItemFields.BaseTKIn}
                      metricField={QuoteLineItemFields.BaseTKMm}
                      itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                      lineId={props.quoteLineItemId}
                      lineType={QuoteLineTypes.Detail}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.7'}
                      nextTabIndex={props.quoteLineItemIndex + '.' + props.quoteLineItemDetailIndex + '.0'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                {(activeTab === QuoteItemSelectionTabs.Sizing) &&
                  <IonCol class="column-left last">
                    <div onClick={()=>{
                      setDisplayedLineItemId(props.quoteLineItemId);
                      setShowSizingStatisticsPopover({ showPopover: true, event: undefined });
                    }} style={{marginTop: '10px'}}
                    >
                      <span className="quote-line-item-summary-text checkmark-label link sizing-stats" data-testid='QAQuoteDetailPageQuoteLineItemDetailsSizingStatsLink'>{ExpandedRowLinks.SizingStats}</span>
                    </div>
                  </IonCol>
                }
                {(activeTab === QuoteItemSelectionTabs.Cost || activeTab === QuoteItemSelectionTabs.OSCost) &&
                  <IonCol class="column-left last">
                    <div onClick={()=> {
                      setDisplayedLineItemId(props.quoteLineItemId);
                      setIsCostDetailsModalOpen(true);
                    }} style={{marginTop: '10px'}}
                    >
                      <span className="quote-line-item-summary-text checkmark-label link" data-testid='QAQuoteDetailPageQuoteLineItemDetailsCostDetailsLink'>{ExpandedRowLinks.CostDetails}</span>
                    </div>
                  </IonCol>
                }
                <LineItemActions
                  visible={!isQuoteLocked}
                  actions={[]}
                  data-testid="QAQuoteDetailPageQuoteLineItemDetailsActions"
                >
                </LineItemActions>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <OSCostServiceTable quoteLineItemId={props.quoteLineItemId} quoteLineItemDetail={props.quoteLineItemDetail}/>
      </>
    );
  };

  const OSCostServiceTable = (props) => {
    const [osServices, setOSServices] = React.useState<OutsideServices[] | string[]>(outsideServicesInDetail(props.quoteLineItemDetail));

    const addOSCost = () => {
      const newOSServicesArray = [...osServices, ''];
      setOSServices(newOSServicesArray);
    };

    const lineToUpdate = getQuoteLineItemFromId(quoteLineItems, props.quoteLineItemId);

    const imperialUom = displayUom === MeasurementSystems.Imperial;
    const isSpotRadiography = lineToUpdate.quoteLineItemDetail.Radiography_Clad__c === RadiographyTypes.Spot;
    const is100PercentRadiography = lineToUpdate.quoteLineItemDetail.Radiography_Clad__c === RadiographyTypes.OneHundredPercent;
    const hasWaterJetCutting: boolean = osServices.includes(OutsideServices.WaterjetCutting) && lineToUpdate.Cut_Method__c === CutMethods.WaterJetCut;
    const hasPostBondHeatTreatment: boolean = osServices.includes(OutsideServices.PostbondHeatTreatment) && (lineToUpdate.Additional_Options__c || []).includes(QuoteLineItemAdditionalOptions.HeatTreatment);
    const hasXraySpot: boolean = osServices.includes(OutsideServices.XRayServices) && isSpotRadiography && (!!lineToUpdate.Welding__c && imperialUom ? !!lineToUpdate.Welding_Length_in__c : !!lineToUpdate.Welding_Length_mm__c);
    const hasXray100: boolean = osServices.includes(OutsideServices.XRayServices) && is100PercentRadiography && (!!lineToUpdate.Welding__c && imperialUom ? !!lineToUpdate.Welding_Length_in__c : !!lineToUpdate.Welding_Length_mm__c);

    lineToUpdate.quoteLineItemDetail.SubcontractCutting__c = hasWaterJetCutting;
    lineToUpdate.quoteLineItemDetail.SubcontractHeatTreatment__c = hasPostBondHeatTreatment;
    lineToUpdate.quoteLineItemDetail.SubcontractRadiographySpot__c = hasXraySpot;
    lineToUpdate.quoteLineItemDetail.SubcontractRadiography100ofweld__c = hasXray100;

    const OSCostLineItems = () => {
      const tableHeadersOSCostTable = [
        { display: '', colSize: '0.23' }, // blank column
        { display: QuoteLineItemsColumnHeadings.AssemblyGroup1, colSize: '2.17' },
        { display: QuoteLineItemsColumnHeadings.CostOfEvent, colSize: '0.85' },
        { display: QuoteLineItemsColumnHeadings.InboundShip, colSize: '0.85' },
        { display: QuoteLineItemsColumnHeadings.OutboundShip, colSize: '0.85' },
        { display: QuoteLineItemsColumnHeadings.LeadTime, colSize: '1.7' },
        { display: QuoteLineItemsColumnHeadings.TotalPrice, colSize: '1.7' },
        { display: QuoteLineItemsColumnHeadings.Notes }
      ];

      return (osServices.length ?
        <>
          <hr className={classNames({ 'detail-connector-vert-line': true, 'cost': QuoteItemSelectionTabs.OSCost})}/>
          <TableHeaders labels={tableHeadersOSCostTable}/>
          <hr className={classNames({ 'detail-connector-vert-line': true, 'cost': QuoteItemSelectionTabs.OSCost})}/>
          <div>
          {osServices?.map((outsideService, index) => {
            return <OSCostSelectionDetails quoteLineItemId={props.quoteLineItemId} quoteLineItemDetail={props.quoteLineItemDetail} osCostIndex={index} key={`osCostsLine-${outsideService}-${index}`}/>;
            })}
          </div>
        </> : null
      );
    };

    const OSCostSelectionDetails = (props) => {
      const [fieldInfo, setFieldInfo] = React.useState(getOSServicesFieldInfo(osServices[props.osCostIndex] as OutsideServices));

      React.useEffect(() => {
        setFieldInfo(getOSServicesFieldInfo(osServices[props.osCostIndex] as OutsideServices));
      }, [osServices]);

      const originalOSCostSelections = getSelectionOptions(selections, quote?.Manufacturing_Site__c, QuoteLineItemValueTypes.OSCost).options;
      let filteredOSCostSelections = [...originalOSCostSelections.filter(item => !osServices.includes(item as OutsideServices))];

      const lineToUpdate = getQuoteLineItemFromId(quoteLineItems, props.quoteLineItemId);
      const isOsServiceRequired = quote?.Manufacturing_Site__c === ManufacturingSites.DE01 &&
      ((osServices[props.osCostIndex] === OutsideServices.WaterjetCutting) &&
      (osServices[props.osCostIndex] === OutsideServices.PostbondHeatTreatment && (lineToUpdate.Additional_Options__c || []).includes(QuoteLineItemAdditionalOptions.HeatTreatment)) ||
      (osServices[props.osCostIndex] === OutsideServices.XRayServices && !!lineToUpdate.Welding__c));

      const deleteOSCost = (osCostIndex) => {
        const newOsServices = [...osServices];
        newOsServices.splice(osCostIndex, 1);

        filteredOSCostSelections = originalOSCostSelections.filter(item => !newOsServices.includes(item));

        if(fieldInfo) {
          removeOutsideService();
        } else {
          setOSServices(newOsServices);
        }
      };

      const addOutsideServiceToMap = (updatedOutsideServicesFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>, outsideService: string, sortIndex: number) => {
        switch(outsideService) {
          case OutsideServices.TestingServicesBacker:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.TestingBKDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.TestingBKSort, sortIndex);
            break;
          case OutsideServices.TestingServicesCladder:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.TestingCLDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.TestingCLSort, sortIndex);
            break;
          case OutsideServices.TestingServicesCladProduct:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.TestingDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.TestingSort, sortIndex);
            break;
          case OutsideServices.BackerMetalHeatTreatment:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.BackerMetalHTDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.BackerMetalHTSort, sortIndex);
            break;
          case OutsideServices.CladderMetalHeatTreatment:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.CladderMetalHTDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.CladderMetalHTSort, sortIndex);
            break;
          case OutsideServices.PostbondHeatTreatment:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.PostbondHTDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.PostbondHTSort, sortIndex);
            break;
          case OutsideServices.Welding:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.WeldingDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.WeldingSort, sortIndex);
            break;
          case OutsideServices.XRayServices:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.XRayServicesDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.XRayServicesSort, sortIndex);
            break;
          case OutsideServices.FlatteningBeforeCutting:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.FlatteningBKDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.FlatteningBKSort, sortIndex);
            break;
          case OutsideServices.Cutting:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.CuttingDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.CuttingSort, sortIndex);
            break;
          case OutsideServices.WaterjetCutting:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.WaterJetCuttingDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.WaterJetCuttingSort, sortIndex);
            break;
          case OutsideServices.FlatteningAfterCutting:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.FlatteningDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.FlatteningSort, sortIndex);
            break;
          case OutsideServices.Machining:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.MachiningDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.MachiningSort, sortIndex);
            break;
          case OutsideServices.InspectionBacker:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.InspectionBKDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.InspectionBKSort, sortIndex);
            break;
          case OutsideServices.InspectionCladder:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.InspectionCLDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.InspectionCLSort, sortIndex);
            break;
          case OutsideServices.InspectionCladProduct:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.InspectionDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.InspectionSort, sortIndex);
            break;
          case OutsideServices.Packaging:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.PackagingDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.PackagingSort, sortIndex);
            break;
          case OutsideServices.CanRolling:
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.CanRollingDescriptionOfEvent, outsideService);
            updatedOutsideServicesFieldsMap.set(QuoteLineItemFields.CanRollingSort, sortIndex);
            break;
        }
      };

      const removeOutsideServiceFromMap = (updatedOutsideServicesFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>) => {
        updatedOutsideServicesFieldsMap.set(fieldInfo.descriptionOfEvent.field, null);
        updatedOutsideServicesFieldsMap.set(fieldInfo.costOfEvent.field, null);

        if(fieldInfo.costOfEventPerWeightUnit?.field) {
          updatedOutsideServicesFieldsMap.set(fieldInfo.costOfEventPerWeightUnit.field, null);
        }

        updatedOutsideServicesFieldsMap.set(fieldInfo.inboundShip.field, null);

        if(fieldInfo.costOfEventPerWeightUnit?.field) {
          updatedOutsideServicesFieldsMap.set(fieldInfo.inboundShipPerWeightUnit.field, null);
        }

        updatedOutsideServicesFieldsMap.set(fieldInfo.outboundShip.field, null);

        if(fieldInfo.costOfEventPerWeightUnit?.field) {
          updatedOutsideServicesFieldsMap.set(fieldInfo.outboundShipPerWeightUnit.field, null);
        }

        updatedOutsideServicesFieldsMap.set(fieldInfo.leadTime.field, null);
        updatedOutsideServicesFieldsMap.set(fieldInfo.totalCost.field, null);
        updatedOutsideServicesFieldsMap.set(fieldInfo.notes.field, null);
        updatedOutsideServicesFieldsMap.set(fieldInfo.sort.field, null);
      };

      const commitOSServices = (updatedOutsideServicesFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType>) => {
        const updatedLineItemFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
        const updatedLineItemDetailFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
        const dependentUnwrittenFieldsMap = new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>();
        const updatedTubesheetItemDetailFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
        const updatedAnvilItemDetailFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();

        dispatchQuoteState({type: QuoteActionTypes.updateLineUpdating, payload: true});

        updateQuoteLineItemCalculatedFields({ ...lineToUpdate }, updatedLineItemFieldsMap, lineToUpdate.Id || lineToUpdate.displayId);
        updateQuoteLineItemDetailCalculatedFields({ ...props.quoteLineItemDetail }, updatedLineItemDetailFieldsMap, updatedOutsideServicesFieldsMap, updatedTubesheetItemDetailFieldsMap, updatedAnvilItemDetailFieldsMap, props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem);

        [...Object.keys(fieldInfo)].forEach(fieldType => {
          recalculateDependentFields(dataState, quote, lineToUpdate, calculatedFieldsEditedMap, inputFieldsEditedMap, dependentUnwrittenFieldsMap, fieldInfo[fieldType].valueType);
        });

        updateQuoteLineItemCalculatedFields({ ...lineToUpdate }, updatedLineItemFieldsMap, lineToUpdate.Id || lineToUpdate.displayId);
        updateQuoteLineItemDetailCalculatedFields({ ...props.quoteLineItemDetail }, updatedLineItemDetailFieldsMap, updatedOutsideServicesFieldsMap, updatedTubesheetItemDetailFieldsMap, updatedAnvilItemDetailFieldsMap, props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem);
      };

      const removeOutsideService = () => {
        const updatedOutsideServicesFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
        removeOutsideServiceFromMap(updatedOutsideServicesFieldsMap);
        commitOSServices(updatedOutsideServicesFieldsMap);
      };

      const replaceOutsideService = (newOutsideService: string, sortIndex: number) => {
        const updatedOutsideServicesFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
        removeOutsideServiceFromMap(updatedOutsideServicesFieldsMap);
        addOutsideServiceToMap(updatedOutsideServicesFieldsMap, newOutsideService, sortIndex);
        commitOSServices(updatedOutsideServicesFieldsMap);
      };

      const changeHandler = (value, index) => {
          const newOsServices = [...osServices];
          newOsServices.splice(index, 1, value);

          filteredOSCostSelections = originalOSCostSelections.filter(item => !newOsServices.includes(item));

          const previousOutsideService = osServices[index];

          const updatedOutsideServicesFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();

          if (previousOutsideService === '') {
            addOutsideServiceToMap(updatedOutsideServicesFieldsMap, value, index + 1);
            updateQuoteLineItemDetailCalculatedFields({ ...props.quoteLineItemDetail }, new Map(), updatedOutsideServicesFieldsMap, new Map(), new Map(), props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem);
          } else {
            replaceOutsideService(value, index + 1);
          }
      };

      return (
        <IonCard className="os-cost-card" data-testid={'QAQuoteDetailPageOSCostQuoteLineItemSubline_' + props.osCostIndex}>
          <IonCardHeader class={classNames({ 'quote-line-item-card-header': true, 'even': props.osCostIndex % 2 === 0, 'odd': props.osCostIndex % 2 !== 0 })}>
              <IonGrid>
                <IonRow class="quote-line-item-row">
                  <IonCol size="2.4" class="column-left os-cost-dropdown">
                    <div className="column-container right-align">
                      <SelectionContainer
                        type={SelectionTypes.DropDown}
                        placeholder='Backer Metal Heat Treatment'
                        options={filteredOSCostSelections}
                        style={{ width: '193px', marginRight: '20px', menuListHeight: '125px' }}
                        onChange={(osService) => changeHandler(osService.label, props.osCostIndex)}
                        value={ osServices[props.osCostIndex] }
                        isDisabled={isQuoteLocked || isOsServiceRequired}
                      />
                    </div>
                  </IonCol>
                  <IonCol size=".85" class="column-left">
                  {isQuoteLocked || (isOsServiceRequired && osServices[props.osCostIndex] !== OutsideServices.WaterjetCutting) ? <IonIcon src={lockClosed}></IonIcon> : null}
                    <div className="column-container right-align">
                      <LineItemField
                        datatestid="QAQuoteDetailPageOSCostQuoteLineItemCostOfEvent"
                        valueType={fieldInfo?.costOfEvent?.valueType}
                        value={props.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[fieldInfo?.costOfEvent?.field]}
                        displayedValue={formatCurrency(props.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[fieldInfo?.costOfEvent?.field] || 0, props.quoteLineItemDetail.CurrencyIsoCode)}
                        currency={props.quoteLineItemDetail.CurrencyIsoCode}
                        field={fieldInfo?.costOfEvent?.field}
                        unitType={QuoteLineItemFieldUnitTypes.Currency}
                        itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                        lineId={props.quoteLineItemId}
                        lineType={QuoteLineTypes.OutsideServices}
                        readOnly={isQuoteLocked || isOsServiceRequired}
                        activeTab={activeTab}
                      />
                    </div>
                  </IonCol>
                  <IonCol size=".85" class="column-left">
                  {isQuoteLocked || isOsServiceRequired ? <IonIcon src={lockClosed}></IonIcon> : null}
                    <div className="column-container right-align">
                      <LineItemField
                        datatestid="QAQuoteDetailPageOSCostQuoteLineItemInboundShip"
                        valueType={fieldInfo?.inboundShip?.valueType}
                        value={props.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[fieldInfo?.inboundShip?.field]}
                        displayedValue={formatCurrency(props.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[fieldInfo?.inboundShip?.field] || 0, props.quoteLineItemDetail.CurrencyIsoCode)}
                        currency={props.quoteLineItemDetail.CurrencyIsoCode}
                        field={fieldInfo?.inboundShip?.field}
                        unitType={QuoteLineItemFieldUnitTypes.Currency}
                        itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                        lineId={props.quoteLineItemId}
                        lineType={QuoteLineTypes.OutsideServices}
                        readOnly={isQuoteLocked || (isOsServiceRequired && osServices[props.osCostIndex] !== OutsideServices.WaterjetCutting)}
                        activeTab={activeTab}
                      />
                    </div>
                  </IonCol>
                  <IonCol size=".85" class="column-left">
                  {isQuoteLocked || (isOsServiceRequired && osServices[props.osCostIndex] !== OutsideServices.WaterjetCutting) ? <IonIcon src={lockClosed}></IonIcon> : null}
                    <div className="column-container right-align">
                      <LineItemField
                        datatestid="QAQuoteDetailPageOSCostQuoteLineItemOutboundShip"
                        valueType={fieldInfo?.outboundShip?.valueType}
                        value={props.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[fieldInfo?.outboundShip?.field]}
                        displayedValue={formatCurrency(props.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[fieldInfo?.outboundShip?.field] || 0, props.quoteLineItemDetail.CurrencyIsoCode)}
                        currency={props.quoteLineItemDetail.CurrencyIsoCode}
                        field={fieldInfo?.outboundShip?.field}
                        unitType={QuoteLineItemFieldUnitTypes.Currency}
                        itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                        lineId={props.quoteLineItemId}
                        lineType={QuoteLineTypes.OutsideServices}
                        readOnly={isQuoteLocked || (isOsServiceRequired && osServices[props.osCostIndex] !== OutsideServices.WaterjetCutting)}
                        activeTab={activeTab}
                      />
                    </div>
                  </IonCol>
                  <IonCol size="1.7" class="column-left">
                    <div className="column-container right-align">
                      <LineItemField
                        datatestid="QAQuoteDetailPageOSCostQuoteLineItemLeadTime"
                        valueType={fieldInfo?.leadTime?.valueType}
                        value={props.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[fieldInfo?.leadTime?.field]}
                        field={fieldInfo?.leadTime?.field}
                        itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                        lineId={props.quoteLineItemId}
                        lineType={QuoteLineTypes.OutsideServices}
                        alwaysEdit={true}
                        readOnly={isQuoteLocked}
                        activeTab={activeTab}
                      />
                    </div>
                  </IonCol>
                  <IonCol size="1.7" class="column-left">
                    <IonIcon src={lockClosed}></IonIcon>
                    <div className="column-container right-align">
                      <LineItemField
                        datatestid="QAQuoteDetailPageOSCostQuoteLineItemTotalOSCost"
                        valueType={fieldInfo?.totalCost?.valueType}
                        value={props.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[fieldInfo?.totalCost?.field] || 0}
                        currency={props.quoteLineItemDetail.CurrencyIsoCode}
                        unitType={QuoteLineItemFieldUnitTypes.Currency}
                        readOnly={true}
                      />
                    </div>
                  </IonCol>
                  <IonCol size='3.2' class="column-left last">
                    <div className="column-container left-align">
                      <LineItemField
                        datatestid="QAQuoteDetailPageOSCostQuoteLineItemNotes"
                        valueType={fieldInfo?.notes?.valueType}
                        value={props.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[fieldInfo?.notes?.field]}
                        field={fieldInfo?.notes?.field}
                        itemId={props.quoteLineItemDetail.Id || props.quoteLineItemDetail.associatedLineItem}
                        lineId={props.quoteLineItemId}
                        lineType={QuoteLineTypes.OutsideServices}
                        alwaysEdit={true}
                        readOnly={isQuoteLocked}
                        activeTab={activeTab}
                      />
                    </div>
                  </IonCol>
                    <div className={classNames({ 'close-line-container': true, 'disabled': isQuoteLocked || isOsServiceRequired})}>
                      <IonIcon className="close-line-icon" src={closeCircleSharp} onClick={()=> deleteOSCost(props.osCostIndex) }></IonIcon>
                    </div>
                </IonRow>
              </IonGrid>
            </IonCardHeader>
        </IonCard>
      );
    };

    return (activeTab === QuoteItemSelectionTabs.OSCost ?
      <>
        <OSCostLineItems/>
        <div className={classNames({ 'add-item-container': true, 'disabled': isQuoteLocked })} onClick={()=> {
          if(!isQuoteLocked) {
            addOSCost();
          }
        }}
        >
          <IonIcon className={classNames({ 'add-item-icon': true, 'disabled': isQuoteLocked })} src={addCircle}></IonIcon>
          <IonLabel className={classNames({ 'add-item-label': true, 'disabled': isQuoteLocked })}>Add Outside Service</IonLabel>
        </div>
      </> : null
    );
  };

  const CostQuoteLineItemSubline = (props) => {
    return (activeTab === QuoteItemSelectionTabs.Cost || activeTab === QuoteItemSelectionTabs.OSCost ?
      <>
        <IonCard className="quote-line-item-card subline" data-testid={'QAQuoteDetailPageCostQuoteLineItemSubline_' + props.quoteLineItemIndex}>
          <IonCardHeader class='quote-line-item-card-header teal-row'>
            <IonGrid>
              <IonRow class="quote-line-item-row">
                <IonCol size="1.34" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemSublineCladPrice/Weight"
                      valueType={QuoteLineItemValueTypes.CladPricePerWeight}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      displayedImperialValue={props.quoteLineItem.Clad_Price_LB__c}
                      displayedMetricValue={props.quoteLineItem.Clad_Price_KG__c}
                      imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Price_LB__c}
                      metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Price_KG__c}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.CladPriceLB}
                      metricField={QuoteLineItemFields.CladPriceKG}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.10'}
                      nextTabIndex={props.quoteLineItemIndex + '.11'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.73" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemSublineCladFreight/Weight"
                      valueType={QuoteLineItemValueTypes.CladFreightPerWeight}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      displayedImperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Freight_LB__c}
                      displayedMetricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Freight_KG__c}
                      imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Freight_LB__c}
                      metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Freight_KG__c}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.CladFreightLB}
                      metricField={QuoteLineItemFields.CladFreightKG}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      hideUnits={true}
                      tabIndex={props.quoteLineItemIndex + '.11'}
                      nextTabIndex={props.quoteLineItemIndex + '.12'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.87" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemSublineBasePrice/Weight"
                      valueType={QuoteLineItemValueTypes.BasePricePerWeight}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      displayedImperialValue={props.quoteLineItem.Base_Price_LB__c}
                      displayedMetricValue={props.quoteLineItem.Base_Price_KG__c}
                      imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Price_LB__c}
                      metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Price_KG__c}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.BasePriceLB}
                      metricField={QuoteLineItemFields.BasePriceKG}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.12'}
                      nextTabIndex={props.quoteLineItemIndex + '.13'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.73" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemSublineBaseFreight/Weight"
                      valueType={QuoteLineItemValueTypes.BaseFreightPerWeight}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      displayedImperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Freight_LB__c}
                      displayedMetricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Freight_KG__c}
                      imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Freight_LB__c}
                      metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Freight_KG__c}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.BaseFreightLB}
                      metricField={QuoteLineItemFields.BaseFreightKG}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      hideUnits={true}
                      tabIndex={props.quoteLineItemIndex + '.13'}
                      nextTabIndex={props.quoteLineItemIndex + '.14'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.865" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemSublineAnvilTK"
                      valueType={QuoteLineItemValueTypes.AnvilTK}
                      displayedImperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Anvil_TK_in__c}
                      displayedMetricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Anvil_TK_mm__c}
                      imperialValue={props.quoteLineItem.Anvil_TK_in__c}
                      metricValue={props.quoteLineItem.Anvil_TK_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.AnvilTKIn}
                      metricField={QuoteLineItemFields.AnvilTKMm}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      extraOptions={true}
                      tabIndex={props.quoteLineItemIndex + '.14'}
                      nextTabIndex={props.quoteLineItemIndex + '.15'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.865" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemSublineWeldingLength"
                      valueType={QuoteLineItemValueTypes.WeldingLength}
                      displayedImperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Welding_Length_in__c}
                      displayedMetricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Welding_Length_mm__c}
                      imperialValue={props.quoteLineItem.Welding_Length_in__c}
                      metricValue={props.quoteLineItem.Welding_Length_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.WeldingLengthIn}
                      metricField={QuoteLineItemFields.WeldingLengthMm}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      tabIndex={props.quoteLineItemIndex + '.15'}
                      nextTabIndex={props.quoteLineItemIndex + '.16'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.865" class="column-left right-align">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.PriceHead}
                    datatestid="QAQuoteDetailPageQuoteLineItemSublinePrice/Head"
                    displayedValue={props.quoteLineItem.Price_Head__c || '- -'}
                    value={calculationMap.get(props.quoteLineItem.Line__c)?.Price_Head__c || '0'}
                    unitType={QuoteLineItemFieldUnitTypes.Currency}
                    currency={props.quoteLineItem.CurrencyIsoCode}
                    itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                    lineType={QuoteLineTypes.Master}
                    field={QuoteLineItemFields.PriceHead}
                    calculatedField={true}
                    tabIndex={props.quoteLineItemIndex + '.16'}
                    nextTabIndex={props.quoteLineItemIndex + '.17'}
                    readOnly={isQuoteLocked}
                    selectedTabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    activeTab={activeTab}
                  />
                </IonCol>
                <IonCol size="0.865" class="column-left right-align">
                  <LineItemField
                    datatestid="QAQuoteDetailPageQuoteLineItemSublineHeadFreight/Weight"
                    valueType={QuoteLineItemValueTypes.HeadFreightPerWeight}
                    unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                    currency={props.quoteLineItem.CurrencyIsoCode}
                    displayedImperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Head_Freight_LB__c}
                    displayedMetricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Head_Freight_KG__c}
                    imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Head_Freight_LB__c}
                    metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Head_Freight_KG__c}
                    uom={displayUom}
                    imperialField={QuoteLineItemFields.HeadFreightLB}
                    metricField={QuoteLineItemFields.HeadFreightKG}
                    itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                    lineType={QuoteLineTypes.Master}
                    calculatedField={true}
                    tabIndex={props.quoteLineItemIndex + '.17'}
                    nextTabIndex={props.quoteLineItemIndex + '.18'}
                    readOnly={isQuoteLocked}
                    selectedTabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    activeTab={activeTab}
                  />
                </IonCol>
                <IonCol size="0.865" class="column-left right-align">
                  <LineItemField
                    datatestid="QAQuoteDetailPageQuoteLineItemSublineShipWeight"
                    valueType={QuoteLineItemValueTypes.ShipWeight}
                    unitType={QuoteLineItemFieldUnitTypes.Weight}
                    uom={displayUom}
                    imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Ship_Weight_LB__c}
                    metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Ship_Weight_KG__c}
                  />
                </IonCol>
                <IonCol size="1.74" class="column-left last subline">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageQuoteLineItemSublineTotalFreight/Weight"
                      valueType={QuoteLineItemValueTypes.TotalFreightPerWeight}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      displayedImperialValue={props.quoteLineItem.Total_Freight_LB__c}
                      displayedMetricValue={props.quoteLineItem.Total_Freight_KG__c}
                      imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Total_Freight_LB__c}
                      metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Total_Freight_KG__c}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.TotalFreightLB}
                      metricField={QuoteLineItemFields.TotalFreightKG}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.18'}
                      nextTabIndex={props.quoteLineItemIndex + '.10'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <LineItemActions
                  visible={!isQuoteLocked}
                  actions={[]}
                  data-testid="QAQuoteDetailPageQuoteLineItemSublineActions"
                >
                </LineItemActions>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </> : null
    );
  };

  const QuoteLineItemExpandedTable = (props) => {
    const tableHeadersQuoteLineItemDetailsTable = [
      { display: QuoteLineItemsColumnHeadings.Clads, colSize: '0.75' },
      { display: QuoteLineItemsColumnHeadings.Quantity, colSize: '0.75' },
      { display: QuoteLineItemsColumnHeadings.W, colSize: '0.50' },
      { display: QuoteLineItemsColumnHeadings.L, colSize: '0.50' },
      { display: QuoteLineItemsColumnHeadings.CladWidth, colSize: '1.35' },
      { display: QuoteLineItemsColumnHeadings.CladLength, colSize: '1.35' },
      { display: QuoteLineItemsColumnHeadings.CladTK, colSize: '1.35' },
      { display: QuoteLineItemsColumnHeadings.BaseWidth, colSize: '1.35' },
      { display: QuoteLineItemsColumnHeadings.BaseLength, colSize: '1.35' },
      { display: QuoteLineItemsColumnHeadings.BaseTK, colSize: '1.35' },
    ];

    const isMultiShot = props.quoteLineItem.quoteLineItemDetail?.MultiShotCount__c > 0;

    return props.expanded && expandableQuoteLineItems() ? (
      <>
        <CostQuoteLineItemSubline quoteLineItem={props.quoteLineItem} quoteLineItemIndex={props.quoteLineItemIndex} />
        {shouldShowLineItemDetail(props.quoteLineItem) ? (
          <>
            <hr className='detail-divider' />
            <div className='detail-connector'>
              <hr
                className={classNames({
                  'detail-connector-vert-line': true,
                  cost: activeTab === QuoteItemSelectionTabs.Cost || QuoteItemSelectionTabs.OSCost,
                })}
              />
            </div>
            <hr
              className={classNames({
                'detail-connector-hor-line': true,
                cost: activeTab === QuoteItemSelectionTabs.Cost || QuoteItemSelectionTabs.OSCost,
              })}
            />
            {!isMultiShot || activeTab === QuoteItemSelectionTabs.OSCost ? (
              <>
                <TableHeaders labels={tableHeadersQuoteLineItemDetailsTable} details={true} />
                {props.quoteLineItem.quoteLineItemDetail ? (
                  <QuoteLineItemDetails
                    quoteLineItemDetail={props.quoteLineItem.quoteLineItemDetail}
                    quoteLineItemIndex={props.quoteLineItemIndex}
                    quoteLineItemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                  />
                ) : null}
              </>
            ) : (
                <MultiShotDetailsLineGroup
                  quoteLineItem={props.quoteLineItem}
                  quoteLineItems={props.quoteLineItems}
                  uom={displayUom}
                  activeTab={activeTab}
                  runFrankie={props.runFrankie}
                />
            )}
          </>
        ) : null}
      </>
    ) : null;
  };

  const CostTotal = ({quoteLineItems, rawMaterials}) => {
    const tableHeadersCostQuoteLineItem = [
      { display: 'Total Clad Cost', colSize: '0.85' },
      { display: isDisplayImperial
        ? 'Total Clad LB'
        : 'Total Clad KG', colSize: '0.85' },
      { display: 'Total Clad Frt', colSize: '0.85' },
      { display: 'Total Base Cost', colSize: '0.85' },
      { display: isDisplayImperial
        ? 'Total Base LB'
        : 'Total Base KG', colSize: '0.85' },
      { display: 'Total Base Frt', colSize: '0.85' },
      { display: 'Total Anvil', colSize: '0.85' },
      { display: 'Total Welding', colSize: '0.85' },
      { display: 'Total Head Frm', colSize: '0.85' },
      { display: 'Total Head Frt', colSize: '0.85' },
      { display: 'Total OS Cost', colSize: '0.85' },
      { display: isDisplayImperial
        ? 'Total LB'
        : 'Total KG', colSize: '0.85' },
      { display: 'Total Frt Out', colSize: '0.85' }
    ];

    const currency: any = displayUom ===  MeasurementSystems.Imperial
      ? 'USD'
      : 'EUR';

    const manufacturingSite = quote.Manufacturing_Site__c;

    const total = quoteLineItems?.filter((qli) => !qli.Parent_Line__c)?.reduce((prev, cur) => {
      const isCustomerProvidedClad = cur.Customer_Provided_Clad__c;
      const isCustomerProvidedBase = cur.Customer_Provided_Base__c;
      const clad = cur?.quoteLineItemDetail?.Clads__c;

      const getBaseCost = () => {
        return isCustomerProvidedBase
          ? calculationMap.get(cur.Line__c)?.Base_Cost_Customer_Supplied__c
          : calculationMap.get(cur.Line__c)?.Base_Cost__c;
      };

      const getCladCost = () => {
        return isCustomerProvidedClad
          ? calculationMap.get(cur.Line__c)?.Clad_Cost_Customer_Supplied__c
          : calculationMap.get(cur.Line__c)?.Clad_Cost__c;
      };

      const getBaseFreight = () => {
        return isCustomerProvidedBase
          ? calculationMap.get(cur.Line__c)?.Base_Freight_Customer_Supplied__c
          : calculationMap.get(cur.Line__c)?.Base_Freight__c;
      };

      const getCladFreight = () => {
        return isCustomerProvidedClad
          ? calculationMap.get(cur.Line__c)?.Clad_Freight_Customer_Supplied__c
          : calculationMap.get(cur.Line__c)?.Clad_Freight__c;
      };

      const getWeights = () => {
        // const isHead = itemType.name === FinishedProductTypes.Head;
        // can't use item type as thats not available in the line item
        const isHead = /head/i.test(displayUom ? cur.ItemDescriptionImp__c : cur.ItemDescriptionMetric__c);

        const notHeadMultiplier = cur?.Cut_Method__c === CutMethods.Uncut
          ? clad
          : clad * cur?.quoteLineItemDetail.Quantity__c;

        // if item is head multiply by quantity
        // if not should be clads
        const weightMultiplier = isHead
          ? cur.Quantity__c
          : notHeadMultiplier;

        if (!isHead) {
          if (displayUom === MeasurementSystems.Imperial) {
            return {
              cladWeight: calculationMap.get(cur.Line__c).Clad_LB_PC__c * weightMultiplier,
              baseWeight: calculationMap.get(cur.Line__c).Base_LB_PC__c * weightMultiplier,
              totalWeight: cur.Ship_Weight_LB__c,
            };
          }

          return {
            cladWeight: calculationMap.get(cur.Line__c).Clad_KG_PC__c * weightMultiplier,
            baseWeight: calculationMap.get(cur.Line__c).Base_KG_PC__c * weightMultiplier,
            totalWeight: cur.Ship_Weight_KG__c,
          };
        }

        const blankSize = cur?.quoteLineItemDetail?.NC_Head_Item_Detail__r?.BlankSize__c ?? 0;
        const baseMetal = rawMaterials.find(rawMaterial => rawMaterial.name === cur.BaseMetal__c && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite));
        const cladMetal = rawMaterials.find(rawMaterial => rawMaterial.name === cur.CladMetal__c && rawMaterial.usage === RawMaterialUsage.Clad && rawMaterial.dataAreaId.includes(manufacturingSite));
        const thickness = {
          base: 0,
          clad: 0,
        };
        const weight = {
          total: 0,
          base: 0,
          clad: 0,
        };

        switch(displayUom) {
          case MeasurementSystems.Metric:
            thickness.base = cur?.quoteLineItemDetail?.Base_TK_mm__c;
            thickness.clad = cur?.quoteLineItemDetail?.Clad_TK_mm__c;
            weight.total = cur.Ship_Weight_KG__c;

            weight.base = calculateFinishedMetalWeight(
              cur?.Unit_of_Measure__c,
              MeasurementSystems.Metric,
              blankSize,
              blankSize,
              thickness.base,
              calculateMetalDensity(MeasurementSystems.Metric, baseMetal),
              cur?.Shape__c
            );
            weight.clad = calculateFinishedMetalWeight(
              cur?.Unit_of_Measure__c,
              MeasurementSystems.Metric,
              blankSize,
              blankSize,
              thickness.clad,
              calculateMetalDensity(MeasurementSystems.Metric, cladMetal),
              cur?.Shape__c
            );
            break;
          case MeasurementSystems.Imperial:
            thickness.base = cur?.quoteLineItemDetail?.Base_TK_in__c;
            thickness.clad = cur?.quoteLineItemDetail?.Clad_TK_in__c;
            weight.total = cur.Ship_Weight_LB__c;

            weight.base = calculateFinishedMetalWeight(
              cur?.Unit_of_Measure__c,
              MeasurementSystems.Imperial,
              blankSize,
              blankSize,
              thickness.base,
              calculateMetalDensity(MeasurementSystems.Imperial, baseMetal),
              cur?.Shape__c
            );
            weight.clad = calculateFinishedMetalWeight(
              cur?.Unit_of_Measure__c,
              MeasurementSystems.Imperial,
              blankSize,
              blankSize,
              thickness.clad,
              calculateMetalDensity(MeasurementSystems.Imperial, cladMetal),
              cur?.Shape__c
            );
            break;
        }

        return {
          cladWeight: weight.clad * weightMultiplier,
          baseWeight: weight.base * weightMultiplier,
          totalWeight: weight.total,
        };
      };

      prev.cladCost += (getCladCost() || 0);
      prev.baseCost += (getBaseCost() || 0);
      prev.baseFrt += (getBaseFreight() || 0);
      prev.cladFrt += (getCladFreight() || 0);
      prev.anvil += (calculationMap.get(cur.Line__c)?.Anvil__c || 0);
      prev.welding += (calculationMap.get(cur.Line__c)?.Welding__c || 0);
      prev.headFrm += (calculationMap.get(cur.Line__c)?.Head_Forming__c || 0);
      prev.headFrt += (calculationMap.get(cur.Line__c)?.Head_Freight__c || 0);
      prev.osCost += (calculationMap.get(cur.Line__c)?.OS_Cost__c || 0);
      prev.frtOut += (calculationMap.get(cur.Line__c)?.Freight_Out__c || 0);

      const {
        cladWeight,
        baseWeight,
        totalWeight,
      } = getWeights();

      prev.cladWeight += cladWeight || 0;
      prev.baseWeight += baseWeight || 0;
      prev.totalWeight += totalWeight || 0;

      return prev;
    }, {
      cladCost: 0,
      cladWeight: 0,
      cladFrt: 0,
      baseCost: 0,
      baseWeight: 0,
      baseFrt: 0,
      anvil: 0,
      welding: 0,
      headFrm: 0,
      headFrt: 0,
      osCost: 0,
      totalWeight: 0,
      frtOut: 0,
    });

    return <div style={{ marginTop: '40px' }}>
      <TableHeaders labels={tableHeadersCostQuoteLineItem} />
      <IonCard className="quote-line-item-card">
        <IonCardHeader class={classNames({
          'quote-line-item-card-header': true,
          details: true
        })}
        >
          <IonGrid>
            <IonRow class="quote-line-item-row">
              <IonCol size="0.85" class="column-left">
                <div className="column-container right-align">
                  <LineItemField
                    currency={currency}
                    value={total.cladCost}
                    unitType={QuoteLineItemFieldUnitTypes.Currency}
                    readOnly={true}
                  />
                </div>
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.CladWeightPerPC}
                  unitType={QuoteLineItemFieldUnitTypes.Weight}
                  uom={displayUom}
                  imperialValue={total.cladWeight}
                  metricValue={total.cladWeight}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.CladFreight}
                  unitType={QuoteLineItemFieldUnitTypes.Currency}
                  currency={currency}
                  value={total.cladFrt}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.BaseCost}
                  currency={currency}
                  unitType={QuoteLineItemFieldUnitTypes.Currency}
                  value={total.baseCost}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.BaseWeightPerPC}
                  unitType={QuoteLineItemFieldUnitTypes.Weight}
                  uom={displayUom}
                  imperialValue={total.baseWeight}
                  metricValue={total.baseWeight}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.BaseFreight}
                  unitType={QuoteLineItemFieldUnitTypes.Currency}
                  currency={currency}
                  value={total.baseFrt}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.Anvil}
                  unitType={QuoteLineItemFieldUnitTypes.Currency}
                  currency={currency}
                  value={total.anvil}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.Welding}
                  unitType={QuoteLineItemFieldUnitTypes.Currency}
                  currency={currency}
                  value={total.welding}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.HeadForming}
                  unitType={QuoteLineItemFieldUnitTypes.Currency}
                  currency={currency}
                  value={total.headFrm}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.HeadFreight}
                  unitType={QuoteLineItemFieldUnitTypes.Currency}
                  currency={currency}
                  value={total.headFrt}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.OSCost}
                  unitType={QuoteLineItemFieldUnitTypes.Currency}
                  currency={currency}
                  value={total.osCost}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.TotalWeightPerPC}
                  unitType={QuoteLineItemFieldUnitTypes.Weight}
                  uom={displayUom}
                  imperialValue={total.totalWeight}
                  metricValue={total.totalWeight}
                  readOnly={true}
                />
              </IonCol>
              <IonCol size="0.85" class="column-left right-align">
                <LineItemField
                  valueType={QuoteLineItemValueTypes.OSCost}
                  unitType={QuoteLineItemFieldUnitTypes.Currency}
                  currency={currency}
                  value={total.frtOut}
                  readOnly={true}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
      </IonCard>
    </div>;
  };

  const QuoteLineItemsList = () => {
    const [showVirtualLines, setShowVirtualLines] = React.useState(false);
    const filteredLineItems = quoteLineItems?.filter((q) => showVirtualLines || !q.isVirtualLineItem);
    const [runFrankie, setRunFrankie] = React.useState(true);
    const hasVirtualItems = quoteLineItems.some(item => item.isVirtualLineItem);
    
    return quoteLineItems?.length ? (
      <>
        {hasVirtualItems && (
          <button
            onClick={() => setShowVirtualLines(!showVirtualLines)}
            style={{
              height: '20px',
              backgroundColor: 'rgba(0,0,0,0.1)',
              border: 'none',
              color: 'teal',
              cursor: 'pointer',
            }}
          >
            {showVirtualLines ? 'Hide' : 'Show'} Virtual Lines
          </button>
        )}
        {(activeTab === QuoteItemSelectionTabs.Cost || activeTab === QuoteItemSelectionTabs.Sizing) && (
          <button
            onClick={() => setRunFrankie(!runFrankie)}
            style={{
              marginLeft: '30px',
              height: '20px',
              backgroundColor: 'rgba(0,0,0,0.1)',
              border: 'none',
              color: '#fafafa',
              cursor: 'pointer',
            }}
          >
            {`Run MS Sizing Engine: ${runFrankie}`}
          </button>
        )}
        {filteredLineItems.map((quoteLineItem, index) => {
          switch (activeTab) {
            case QuoteItemSelectionTabs.FinishedGoods:
            case QuoteItemSelectionTabs.Sizing:
              return (
                <SizingQuoteLineItem
                  quoteLineItem={quoteLineItem}
                  quoteLineItemIndex={index}
                  key={index}
                  activeTab={activeTab}
                  runFrankie={runFrankie}
                />
              );
            case QuoteItemSelectionTabs.Cost:
            case QuoteItemSelectionTabs.OSCost:
              return quoteLineItem.PeggedSupply__c === 1 || !quoteLineItem.SharedCladdedPlate__c ? (
                <CostQuoteLineItem
                  quoteLineItem={quoteLineItem}
                  quoteLineItemIndex={index}
                  key={index}
                  activeTab={activeTab}
                  runFrankie={runFrankie}
                />
              ) : null;
            case QuoteItemSelectionTabs.Price:
              return <PriceQuoteLineItem quoteLineItem={quoteLineItem} quoteLineItemIndex={index} key={index} />;
          }
        })}

        { activeTab === QuoteItemSelectionTabs.Cost && dataState?.rawMaterials && <CostTotal quoteLineItems={quoteLineItems} rawMaterials={dataState?.rawMaterials}/> }
      </>
      ) : (
      <>
        <EmptyTableRow text={'No items added.'} />
      </>
    );
  };

  const SizingLineItemsTable = ({ hasMultiShotFeatureFlag }: { hasMultiShotFeatureFlag: boolean }) => {
    const tableHeadersSizingQuoteLineItemTable = [
      { display: '', colSize: '0.23', hidden: !expandableQuoteLineItems() }, // blank column
      { display: QuoteLineItemsColumnHeadings.Line, colSize: '0.48' },
      { display: QuoteLineItemsColumnHeadings.Quantity, colSize: '0.48' },
      { display: QuoteLineItemsColumnHeadings.ProductDescription, colSize: '1.97' },
      { display: QuoteLineItemsColumnHeadings.CladMetal, colSize: '1.2', hidden: activeTab !== QuoteItemSelectionTabs.FinishedGoods },
      { display: QuoteLineItemsColumnHeadings.BaseMetal, colSize: '1.2', hidden: activeTab !== QuoteItemSelectionTabs.FinishedGoods },
      { display: QuoteLineItemsColumnHeadings.Width, colSize: '0.76' },
      { display: QuoteLineItemsColumnHeadings.Length, colSize: '0.76' },
      { display: QuoteLineItemsColumnHeadings.CladType, colSize: '0.76' },
      { display: QuoteLineItemsColumnHeadings.CladTK, colSize: '0.76' },
      { display: QuoteLineItemsColumnHeadings.BaseType, colSize: '0.76' },
      { display: QuoteLineItemsColumnHeadings.BaseTK, colSize: '0.76' },
    ];

    return (
      <>
        <TableHeaders labels={tableHeadersSizingQuoteLineItemTable}/>
        <QuoteLineItemsList/>
        {/* disable add/edit action events if quote line item details are still fetching */}
        <div className={classNames({ 'add-item-container': true, 'disabled': areDetailsLoading || isDataLoading || isQuoteLocked })} onClick={() => setAddEditLineItemPopoverState(true)}>
          <IonIcon class="add-item-icon" src={addCircle}></IonIcon>
          <IonLabel class="add-item-label">Add Standard Item</IonLabel>
        </div>
        {hasMultiShotFeatureFlag && (
          <div
            className={classNames({
              'add-item-container': true,
              disabled: areDetailsLoading || isDataLoading || isQuoteLocked,
            })}
            onClick={() => {
              setAddEditLineItemPopoverState(true);
              setIsMultiShot(true);
            }}
          >
            <IonIcon class='add-item-icon' src={addCircle}></IonIcon>
            <IonLabel class='add-item-label'>Add MultiShot Item</IonLabel>
          </div>
        )}
      </>
    );
  };

  const CostQuoteLineItem = (props) => {
    const [expanded, setExpanded] = React.useState(true);
    const isCustomerProvidedBase = props.quoteLineItem.Customer_Provided_Base__c;
    const isCustomerProvidedClad = props.quoteLineItem.Customer_Provided_Clad__c;

    const handleOnEditClick = () =>{
      setQuoteLineItemToEdit(props.quoteLineItem);
      setQuoteLineItemDetailToEdit(props.quoteLineItem.quoteLineItemDetail);
      setAddEditLineItemPopoverState(true);
    };

    const innerDeleteQuoteLineItem = () => {
      deleteQuoteLineItem(props.quoteLineItem);
      setQuoteLineItemToEdit(null);
      setQuoteLineItemDetailToEdit(null);
    };

    const innerDuplicateQuoteLineItem = () => {
      duplicateQuoteLineItem(props.quoteLineItem.Id || props.quoteLineItem.displayId);
      setQuoteLineItemToEdit(null);
      setQuoteLineItemDetailToEdit(null);
    };

    const getBaseCost = () => {
      return isCustomerProvidedBase ? calculationMap.get(props.quoteLineItem.Line__c)?.Base_Cost_Customer_Supplied__c : calculationMap.get(props.quoteLineItem.Line__c)?.Base_Cost__c;
    };

    const getCladCost = () => {
      return isCustomerProvidedClad ? calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Cost_Customer_Supplied__c : calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Cost__c;
    };

    const getBaseFreight = () => {
      return isCustomerProvidedBase ? calculationMap.get(props.quoteLineItem.Line__c)?.Base_Freight_Customer_Supplied__c : calculationMap.get(props.quoteLineItem.Line__c)?.Base_Freight__c;
    };

    const getCladFreight = () => {
      return isCustomerProvidedClad ? calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Freight_Customer_Supplied__c : calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Freight__c;
    };

    const tableHeadersCostQuoteLineItem = [
      { display: '', colSize: '0.23' }, // blank column
      { display: QuoteLineItemsColumnHeadings.Line, colSize: '0.45' },
      { display: QuoteLineItemsColumnHeadings.CladCost, colSize: '0.85' },
      { display: isDisplayImperial ? QuoteLineItemsColumnHeadings.CladLBPC : QuoteLineItemsColumnHeadings.CladKGPC, colSize: '0.85' },
      { display: QuoteLineItemsColumnHeadings.CladFrt, colSize: '0.85' },
      { display: QuoteLineItemsColumnHeadings.BaseCost, colSize: '0.85' },
      { display: isDisplayImperial ? QuoteLineItemsColumnHeadings.BaseLBPC : QuoteLineItemsColumnHeadings.BaseKGPC, colSize: '0.85' },
      { display: QuoteLineItemsColumnHeadings.BaseFrt, colSize: '0.85' },
      { display: QuoteLineItemsColumnHeadings.Anvil, colSize: '0.85' },
      { display: QuoteLineItemsColumnHeadings.Welding, colSize: '0.85' },
      { display: QuoteLineItemsColumnHeadings.HeadFrm, colSize: '0.85' },
      { display: QuoteLineItemsColumnHeadings.HeadFrt, colSize: '0.85' },
      { display: QuoteLineItemsColumnHeadings.OSCost, colSize: '0.85' },
      { display: isDisplayImperial ? QuoteLineItemsColumnHeadings.TotalLBPC : QuoteLineItemsColumnHeadings.TotalKGPC, colSize: '0.85' },
      { display: QuoteLineItemsColumnHeadings.FrtOut, colSize: '0.85' }
    ];

    return (
      <>
        <TableHeaders labels={tableHeadersCostQuoteLineItem} />
        <IonCard className="quote-line-item-card" data-testid={'QAQuoteDetailPageCostQuoteLineItem_' + props.quoteLineItemIndex}>
          <IonCardHeader class={classNames({ 'quote-line-item-card-header': true, 'even': props.quoteLineItemIndex % 2 === 0, 'odd': props.quoteLineItemIndex % 2 !== 0 })}>
            <IonGrid>
              <IonRow class="quote-line-item-row">
                <ExpandLineItemColumn setExpanded={setExpanded} expanded={expanded}/>
                <IonCol size="0.45" class="column-left">
                  <LineItemField valueType={QuoteLineItemValueTypes.Line} datatestid="QAQuoteDetailPageCostQuoteLineItemLine" displayedValue={props.quoteLineItem.Line__c}/>
                </IonCol>
                <IonCol size="0.85" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemCladCost"
                      valueType={QuoteLineItemValueTypes.CladCost}
                      displayedValue={props.quoteLineItem.Clad_Cost__c}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      value={getCladCost()}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      field={QuoteLineItemFields.CladCost}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.0'}
                      nextTabIndex={props.quoteLineItemIndex + '.1'}
                      setTabIndex={setTabIndex}
                      selectedTabIndex={tabIndex}
                      isCustomerProvidedClad={isCustomerProvidedClad}
                      readOnly={isQuoteLocked}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.85" class="column-left right-align">
                  <LineItemField
                    datatestid="QAQuoteDetailPageCostQuoteLineItemCladWeight/PC"
                    valueType={QuoteLineItemValueTypes.CladWeightPerPC}
                    unitType={QuoteLineItemFieldUnitTypes.Weight}
                    uom={displayUom}
                    imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_LB_PC__c}
                    metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_KG_PC__c}
                  />
                </IonCol>
                <IonCol size="0.85" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemCladFrt"
                      valueType={QuoteLineItemValueTypes.CladFreight}
                      displayedValue={props.quoteLineItem.Clad_Freight__c}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      value={getCladFreight()}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      field={QuoteLineItemFields.CladFreight}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.1'}
                      nextTabIndex={props.quoteLineItemIndex + '.2'}
                      setTabIndex={setTabIndex}
                      selectedTabIndex={tabIndex}
                      isCustomerProvidedClad={isCustomerProvidedClad}
                      readOnly={isQuoteLocked}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.85" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemBaseCost"
                      valueType={QuoteLineItemValueTypes.BaseCost}
                      displayedValue={props.quoteLineItem.Base_Cost__c}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      value={getBaseCost()}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      field={QuoteLineItemFields.BaseCost}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.2'}
                      nextTabIndex={props.quoteLineItemIndex + '.3'}
                      setTabIndex={setTabIndex}
                      selectedTabIndex={tabIndex}
                      isCustomerProvidedBase={isCustomerProvidedBase}
                      readOnly={isQuoteLocked}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.85" class="column-left right-align">
                  <LineItemField
                    datatestid="QAQuoteDetailPageCostQuoteLineItemBaseWeight/PC"
                    valueType={QuoteLineItemValueTypes.BaseWeightPerPC}
                    unitType={QuoteLineItemFieldUnitTypes.Weight}
                    imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_LB_PC__c}
                    metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_KG_PC__c}
                    uom={displayUom}
                  />
                </IonCol>
                <IonCol size="0.85" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemBaseFrt"
                      valueType={QuoteLineItemValueTypes.BaseFreight}
                      displayedValue={props.quoteLineItem.Base_Freight__c}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      value={getBaseFreight()}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      field={QuoteLineItemFields.BaseFreight}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.3'}
                      nextTabIndex={props.quoteLineItemIndex + '.4'}
                      setTabIndex={setTabIndex}
                      selectedTabIndex={tabIndex}
                      isCustomerProvidedBase={isCustomerProvidedBase}
                      readOnly={isQuoteLocked}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.85" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemAnvil"
                      valueType={QuoteLineItemValueTypes.Anvil}
                      displayedValue={props.quoteLineItem.Anvil__c || '- -'}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.Anvil__c || '0'}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      field={QuoteLineItemFields.Anvil}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.4'}
                      nextTabIndex={props.quoteLineItemIndex + '.5'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.85" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemWelding"
                      valueType={QuoteLineItemValueTypes.Welding}
                      displayedValue={props.quoteLineItem.Welding__c || '- -'}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.Welding__c || '0'}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      field={QuoteLineItemFields.Welding}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineToEdit={props.quoteLineItem}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      extraOptions={true}
                      tabIndex={props.quoteLineItemIndex + '.5'}
                      nextTabIndex={props.quoteLineItemIndex + '.6'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.85" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemHeadFrm"
                      valueType={QuoteLineItemValueTypes.HeadForming}
                      displayedValue={props.quoteLineItem.Head_Forming__c || '- -'}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.Head_Forming__c || '0'}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      field={QuoteLineItemFields.HeadForming}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.6'}
                      nextTabIndex={props.quoteLineItemIndex + '.7'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.85" class="column-left right-align">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemHeadFrt"
                      valueType={QuoteLineItemValueTypes.HeadFreight}
                      displayedValue={props.quoteLineItem.Head_Freight__c || '- -'}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.Head_Freight__c || '0'}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      field={QuoteLineItemFields.HeadFreight}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.7'}
                      nextTabIndex={props.quoteLineItemIndex + '.8'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.85" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemOSCost"
                      valueType={QuoteLineItemValueTypes.OSCost}
                      displayedValue={props.quoteLineItem.OS_Cost__c}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.OS_Cost__c}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      field={QuoteLineItemFields.OSCost}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.8'}
                      nextTabIndex={props.quoteLineItemIndex + '.9'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.85" class="column-left right-align">
                  <LineItemField
                    datatestid="QAQuoteDetailPageCostQuoteLineItemTotalWeight/PC"
                    valueType={QuoteLineItemValueTypes.TotalWeightPerPC}
                    unitType={QuoteLineItemFieldUnitTypes.Weight}
                    uom={displayUom}
                    imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Total_LB_PC__c}
                    metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Total_KG_PC__c}
                  />
                </IonCol>
                <IonCol size="0.85" class="column-left last">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPageCostQuoteLineItemFrtOut"
                      valueType={QuoteLineItemValueTypes.FreightOut}
                      displayedValue={props.quoteLineItem.Freight_Out__c}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.Freight_Out__c}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      field={QuoteLineItemFields.FreightOut}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.9'}
                      nextTabIndex={props.quoteLineItemIndex + '.0'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      activeTab={activeTab}
                    />
                  </div>
                </IonCol>
                <LineItemActions
                  visible={!isQuoteLocked}
                  actions={props.quoteLineItem.isVirtualLineItem ? [{
                    label: QuoteLineItemActions.Delete,
                    actionToPerform: innerDeleteQuoteLineItem
                  }] : [{
                    label: QuoteLineItemActions.Edit,
                    actionToPerform: handleOnEditClick
                  },{
                    label: QuoteLineItemActions.Delete,
                    actionToPerform: innerDeleteQuoteLineItem
                  },{
                    label: QuoteLineItemActions.Duplicate,
                    actionToPerform: innerDuplicateQuoteLineItem
                  }]}
                  data-testid="QAQuoteDetailPageCostQuoteLineItemActions"
                >
                </LineItemActions>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <QuoteLineItemExpandedTable expanded={expanded} quoteLineItem={props.quoteLineItem} quoteLineItemIndex={props.quoteLineItemIndex} activeTab={activeTab} quoteLineItems={quoteLineItems} runFrankie={props.runFrankie} />
      </>
    );
  };

  const PriceQuoteLineItem = (props) => {
    const handleOnEditClick = () => {
      setQuoteLineItemToEdit(props.quoteLineItem);
      setQuoteLineItemDetailToEdit(props.quoteLineItem.quoteLineItemDetail);
      setAddEditLineItemPopoverState(true);
    };

    const innerDeleteQuoteLineItem = () => {
      deleteQuoteLineItem(props.quoteLineItem);
      setQuoteLineItemToEdit(null);
      setQuoteLineItemDetailToEdit(null);
    };

    const innerDuplicateQuoteLineItem = () => {
      duplicateQuoteLineItem(props.quoteLineItem.Id || props.quoteLineItem.displayId);
      setQuoteLineItemToEdit(null);
      setQuoteLineItemDetailToEdit(null);
    };

    return (
      <>
        <IonCard className="quote-line-item-card" data-testid={'QAQuoteDetailPagePriceQuoteLineItem_' + props.quoteLineItemIndex}>
          <IonCardHeader class={classNames({ 'quote-line-item-card-header': true, 'even': props.quoteLineItemIndex % 2 === 0, 'odd': props.quoteLineItemIndex % 2 !== 0 })}>
            <IonGrid>
              <IonRow class="quote-line-item-row">
                <IonCol size="0.47" class="column-left center-align">
                  <LineItemField valueType={QuoteLineItemValueTypes.Line} datatestid="QAQuoteDetailPagePriceQuoteLineItemLine" displayedValue={props.quoteLineItem.Line__c}/>
                </IonCol>
                <IonCol size="0.47" class="column-left">
                  <LineItemField valueType={QuoteLineItemValueTypes.PriceQuantity} datatestid="QAQuoteDetailPagePriceQuoteLineItemQuantity" displayedValue={props.quoteLineItem.Quantity__c || '- -'}/>
                </IonCol>
                <IonCol size="1.3" class="column-left">
                  <LineItemField valueType={QuoteLineItemValueTypes.PriceProduct} datatestid="QAQuoteDetailPagePriceQuoteLineItemProduct" displayedValue={props.quoteLineItem.Item_Type__c || '- -'}/>
                </IonCol>
                <IonCol size="1.35" class="column-left">
                  <LineItemField
                    datatestid="QAQuoteDetailPagePriceQuoteLineItemDimensions"
                    valueType={QuoteLineItemValueTypes.Dimensions}
                    unitType={QuoteLineItemFieldUnitTypes.Dimensions}
                    imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Dimensions_in__c}
                    metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Dimensions_mm__c}
                    uom={displayUom}
                  />
                </IonCol>
                <IonCol size="0.56" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPagePriceQuoteLineItemCladNom"
                      valueType={QuoteLineItemValueTypes.CladNom}
                      displayedImperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Nom_in__c}
                      displayedMetricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Nom_mm__c}
                      imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Nom_in__c || 0}
                      metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Nom_mm__c || 0}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.CladNomIn}
                      metricField={QuoteLineItemFields.CladNomMm}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      hideUnits={true}
                      tabIndex={props.quoteLineItemIndex + '.0'}
                      nextTabIndex={props.quoteLineItemIndex + '.1'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.56" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPagePriceQuoteLineItemCladMin"
                      valueType={QuoteLineItemValueTypes.CladMin}
                      displayedImperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Min_in__c}
                      displayedMetricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Min_mm__c}
                      imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Min_in__c || 0}
                      metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Clad_Min_mm__c || 0}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.CladMinIn}
                      metricField={QuoteLineItemFields.CladMinMm}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      hideUnits={true}
                      tabIndex={props.quoteLineItemIndex + '.1'}
                      nextTabIndex={props.quoteLineItemIndex + '.2'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.56" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPagePriceQuoteLineItemBaseNom"
                      valueType={QuoteLineItemValueTypes.BaseNom}
                      displayedImperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Nom_in__c}
                      displayedMetricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Nom_mm__c}
                      imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Nom_in__c || 0}
                      metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Nom_mm__c || 0}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.BaseNomIn}
                      metricField={QuoteLineItemFields.BaseNomMm}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      hideUnits={true}
                      tabIndex={props.quoteLineItemIndex + '.2'}
                      nextTabIndex={props.quoteLineItemIndex + '.3'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.56" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPagePriceQuoteLineItemBaseMin"
                      valueType={QuoteLineItemValueTypes.BaseMin}
                      displayedImperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Min_in__c}
                      displayedMetricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Min_mm__c}
                      imperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Min_in__c || 0}
                      metricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Base_Min_mm__c || 0}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      uom={displayUom}
                      imperialField={QuoteLineItemFields.BaseMinIn}
                      metricField={QuoteLineItemFields.BaseMinMm}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      hideUnits={true}
                      tabIndex={props.quoteLineItemIndex + '.3'}
                      nextTabIndex={props.quoteLineItemIndex + '.4'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.84" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPagePriceQuoteLineItemUnitCost"
                      valueType={QuoteLineItemValueTypes.UnitCost}
                      displayedValue={props.quoteLineItem.UnitCost__c}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.UnitCost__c}
                      field={QuoteLineItemFields.UnitCost}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.94" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPagePriceQuoteLineItemAdjPrice"
                      valueType={QuoteLineItemValueTypes.AdjPrice}
                      displayedValue={props.quoteLineItem.Adj_Price__c}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.Adj_Price__c}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      field={QuoteLineItemFields.AdjPrice}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      roundOff={true}
                      tabIndex={props.quoteLineItemIndex + '.4'}
                      nextTabIndex={props.quoteLineItemIndex + '.5'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.94" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPagePriceQuoteLineItemTotalPrice"
                      valueType={QuoteLineItemValueTypes.TotalAdjPrice}
                      displayedValue={props.quoteLineItem.TotalAdjPrice__c}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.TotalAdjPrice__c}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      field={QuoteLineItemFields.TotalAdjPrice}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.705" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPagePriceQuoteLineItemCont%"
                      valueType={QuoteLineItemValueTypes.ContPercentage}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.Contribution__c}
                      unitType={QuoteLineItemFieldUnitTypes.Percentage}
                      field={QuoteLineItemFields.Contribution}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.5'}
                      nextTabIndex={props.quoteLineItemIndex + '.6'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.94" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAQuoteDetailPagePriceQuoteLineItemCont/SQM"
                      valueType={QuoteLineItemValueTypes.ContPerSQM}
                      displayedValue={props.quoteLineItem.Cont_SQM__c || '- -'}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.Cont_SQM__c || '0'}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      currency={props.quoteLineItem.CurrencyIsoCode}
                      field={QuoteLineItemFields.ContSQM}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={false}
                      ignoreRecalculateFields
                      fieldChangedCB={(value) => {
                          const lineToUpdate = getQuoteLineItemFromId(quoteLineItems, props.quoteLineItem.Id || props.quoteLineItem.displayId);
                          const updatedPercentage =  (value * lineToUpdate.quoteLineItemDetail.Area_Sqm__c / lineToUpdate.Adj_Price__c) * 100;
                          lineToUpdate.Contribution__c = updatedPercentage;
                          saveQuoteLineItem(new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>(), [QuoteLineItemValueTypes.ContPercentage], lineToUpdate);
                      }}
                      tabIndex={props.quoteLineItemIndex + '.6'}
                      nextTabIndex={props.quoteLineItemIndex + '.7'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <IonCol size="0.84" class="column-left right-align">
                  <LineItemField
                    datatestid="QAQuoteDetailPagePriceQuoteLineItemPrice/Weight"
                    valueType={QuoteLineItemValueTypes.PricePerWeight}
                    unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                    currency={props.quoteLineItem.CurrencyIsoCode}
                    uom={displayUom}
                    displayedImperialValue={calculationMap.get(props.quoteLineItem.Line__c)?.Price_LB__c}
                    displayedMetricValue={calculationMap.get(props.quoteLineItem.Line__c)?.Price_KG__c}
                    hideUnits={true}
                  />
                </IonCol>
                <IonCol size="0.94" class="column-left last lead-time-column">
                  <div className="column-container">
                    <LineItemField
                      datatestid="QAQuoteDetailPagePriceQuoteLineItemLeadTime"
                      valueType={QuoteLineItemValueTypes.LeadTime}
                      value={calculationMap.get(props.quoteLineItem.Line__c)?.Lead_Time__c}
                      field={QuoteLineItemFields.LeadTime}
                      itemId={props.quoteLineItem.Id || props.quoteLineItem.displayId}
                      lineType={QuoteLineTypes.Master}
                      calculatedField={true}
                      tabIndex={props.quoteLineItemIndex + '.7'}
                      nextTabIndex={props.quoteLineItemIndex + '.0'}
                      readOnly={isQuoteLocked}
                      selectedTabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </div>
                </IonCol>
                <LineItemActions
                  visible={!isQuoteLocked}
                  actions={props.quoteLineItem.isVirtualLineItem ? [{
                    label: QuoteLineItemActions.Delete,
                    actionToPerform: innerDeleteQuoteLineItem
                  }] : [{
                    label: QuoteLineItemActions.Edit,
                    actionToPerform: handleOnEditClick
                  },{
                    label: QuoteLineItemActions.Delete,
                    actionToPerform: innerDeleteQuoteLineItem
                  },{
                    label: QuoteLineItemActions.Duplicate,
                    actionToPerform: innerDuplicateQuoteLineItem
                  }]}
                  dataTestId="QAQuoteDetailPagePriceQuoteLineItemActions"
                >
                </LineItemActions>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const BrokenRuleWarning = ({ lineItemData }) => {

    if (!lineItemData || !lineItemData.quoteLineItemDetail || !dataState?.rawMaterials) {
      return null;
    }
    const detail: QuoteLineItemDetail = lineItemData.quoteLineItemDetail;
    const rules = runGuardRail(lineItemData, detail, displayUom, dataState.rawMaterials, quote?.Manufacturing_Site__c);

    return (
      rules.length >= 1 ? (
        <IonCol size="0.40" class="column-left checkmark-column">
          <div className="column-container">
            <IonIcon className='warning' src={warning} onClick={() => navigateToOrderEntry()} ></IonIcon>
          </div>
        </IonCol>
      ) : null
    );
  };

  const PriceLineItemsTable = () => {
    const tableHeadersPriceQuoteLineItemTable = [
      { display: QuoteLineItemsColumnHeadings.Line, colSize: '0.47' },
      { display: QuoteLineItemsColumnHeadings.Quantity, colSize: '0.47' },
      { display: QuoteLineItemsColumnHeadings.Product, colSize: '1.3' },
      { display: QuoteLineItemsColumnHeadings.Dimensions, colSize: '1.35' },
      { display: QuoteLineItemsColumnHeadings.CladNom, colSize: '0.56' },
      { display: QuoteLineItemsColumnHeadings.CladMin, colSize: '0.56' },
      { display: QuoteLineItemsColumnHeadings.BaseNom, colSize: '0.56' },
      { display: QuoteLineItemsColumnHeadings.BaseMin, colSize: '0.56' },
      { display: QuoteLineItemsColumnHeadings.UnitCost, colSize: '0.84' },
      { display: QuoteLineItemsColumnHeadings.AdjPrice, colSize: '0.94' },
      { display: QuoteLineItemsColumnHeadings.TotalPrice, colSize: '0.94' },
      { display: QuoteLineItemsColumnHeadings.ContPct, colSize: '0.705' },
      { display: QuoteLineItemsColumnHeadings.ContSQM, colSize: '0.94' },
      { display: isDisplayImperial ? QuoteLineItemsColumnHeadings.PriceLB : QuoteLineItemsColumnHeadings.PriceKG, colSize: '0.84' },
      { display: QuoteLineItemsColumnHeadings.LeadTime, colSize: '0.94' },
    ];

    return (
      <>
        <TableHeaders labels={tableHeadersPriceQuoteLineItemTable}/>
        <QuoteLineItemsList/>
      </>
    );
  };

  const TabLoadingContainer = () => {
    return (
      <div className="tab-loading-container">
        <Loading data-testId="QAQuoteDetailTabLoading"/>
      </div>
    );
  };

  const LineItemsDisplayType = ({ hasMultiShotFeatureFlag }: { hasMultiShotFeatureFlag: boolean }) => {
    switch(activeTab) {
      case QuoteItemSelectionTabs.FinishedGoods:
        return (<SizingLineItemsTable hasMultiShotFeatureFlag={hasMultiShotFeatureFlag}/>);
      case QuoteItemSelectionTabs.Sizing:
        return (!areDetailsLoading ?
            <SizingLineItemsTable hasMultiShotFeatureFlag={hasMultiShotFeatureFlag}/> :
            <TabLoadingContainer/>
          );
      case QuoteItemSelectionTabs.Cost:
      case QuoteItemSelectionTabs.OSCost:
        return (!areDetailsLoading ?
            <QuoteLineItemsList/> :
            <TabLoadingContainer/>
        );
      case QuoteItemSelectionTabs.Price:
        return (<PriceLineItemsTable/>);
    }
  };

  const UpdateFloatingAction = (props) => {
    return (
      <FloatingAction icon={resetTab} darkModeIcon={resetTabDarkMode} hoveredIcon={resetTabHovered} disabledIcon={resetTabDisabled} disabled={props.disabled} onClick={() => {
          confirmAlert({
            cssClass: 'confirm-alert',
            header: 'Are you sure you want to reset the quote?',
            buttons: [
              {
                text: 'Cancel',
                cssClass: 'alert-button-cancel',
              },
              {
                text: 'Yes',
                cssClass: 'alert-button-confirm',
                handler: () => recalculateFields(),
              },
            ],
          });
        }} action='update'
      />
    );
  };

  const DynamicFloatingActions = () => {
    const actionsDisabled = quoteLineItems?.length === 0 || areDetailsLoading || isDataLoading;

    switch(activeTab) {
      case QuoteItemSelectionTabs.FinishedGoods:
        return (
          <>
            <UpdateFloatingAction disabled={actionsDisabled || isQuoteLocked}/>
            <FloatingAction icon={combineTab} darkModeIcon={combineTabDarkMode} hoveredIcon={combineTabHovered} disabledIcon={combineTabDisabled} disabled={actionsDisabled || isQuoteLocked} onClick={() => undefined} action='combine'/>
          </>
        );
      case QuoteItemSelectionTabs.Sizing:
        return (
          <>
            <IonPopover
              cssClass='manual-sizing-popover'
              isOpen={manualSizingPopoverState.showPopover}
              animated={ false }
              onDidDismiss={async () => {
                setShowManualSizingPopover({ showPopover: false, event: undefined });
              }}
              backdropDismiss={false}
            >
              <ManualSizingPopover
                setShowPopover={setShowManualSizingPopover}
              />
            </IonPopover>
            <UpdateFloatingAction disabled={actionsDisabled || isQuoteLocked}/>
            <FloatingAction icon={manualTab} darkModeIcon={manualTabDarkMode} hoveredIcon={manualTabHovered} disabledIcon={manualTabDisabled} disabled={actionsDisabled || isQuoteLocked} onClick={() => setShowManualSizingPopover({ showPopover: true, event: undefined })} action='manual'/>
            <FloatingAction icon={statsTab} darkModeIcon={statsTabDarkMode} hoveredIcon={statsTabHovered} disabledIcon={statsTabDisabled} disabled={actionsDisabled} onClick={() => setShowSizingStatisticsPopover({ showPopover: true, event: undefined })} action='stats'/>
          </>
        );
      case QuoteItemSelectionTabs.Cost:
      case QuoteItemSelectionTabs.OSCost:
        return (
          <>
            <FloatingAction icon={bomTab} darkModeIcon={bomTabDarkMode} hoveredIcon={bomTabHovered} disabledIcon={bomTabDisabled} disabled={actionsDisabled} onClick={() => setIsGenerateBomPopoverOpen(true)} action='bom'/>
            <UpdateFloatingAction disabled={actionsDisabled || isQuoteLocked}/>
          </>
        );
      case QuoteItemSelectionTabs.Price:
        return (
          <>
            <FloatingAction icon={bomTab} darkModeIcon={bomTabDarkMode} hoveredIcon={bomTabHovered} disabledIcon={bomTabDisabled} disabled={actionsDisabled} onClick={() => setIsGenerateBomPopoverOpen(true)} action='bom'/>
            <FloatingAction icon={orderTab} darkModeIcon={orderTabDarkMode} hoveredIcon={orderTabHovered} disabledIcon={orderTabDisabled} disabled={actionsDisabled} onClick={() => navigateToOrderEntry()} action='order'/>
          </>
        );
    }
  };

  const SizingStatsPopover = () => {
    return (quoteLineItemDetails?.length ?
      <IonPopover
        cssClass='sizing-statistics-popover'
        isOpen={sizingStatisticsPopoverState.showPopover}
        animated={ false }
        onDidDismiss={async () => {
          setShowSizingStatisticsPopover({ showPopover: false, event: undefined});
        }}
        backdropDismiss={false}
      >
        <SizingStatisticsPopover
          setShowPopover={setShowSizingStatisticsPopover}
          lineItemId={displayedLineItemId}
        />
      </IonPopover> : null
    );
  };

  const CostDetailPopover = () => {
    return (
      quoteLineItemDetails?.length ? (
        <IonPopover
          isOpen={isCostDetailsModalOpen}
          cssClass='cost-details-popover'
          animated={ false }
          backdropDismiss={false}
          onDidDismiss={async () => setIsCostDetailsModalOpen(false)}
        >
          <IonContent className='cost-details-popover'>
            <CostDetailsPopover
              setShowModal={setIsCostDetailsModalOpen}
              lineItemId={displayedLineItemId}
            />
          </IonContent>
        </IonPopover>
      ) : null
    );
  };

  const GenerateBillOfMaterialsPopover = () => {

    const onHandleDismiss = () => {
      setIsGenerateBomPopoverOpen(false);
    };

    return (
      quoteLineItemDetails?.length ? (
        <Modal
          options={{
            size: 'bom',
            isOpen: isGenerateBomPopoverOpen
          }}
        frame={true}
          onClickClose={onHandleDismiss}
        >
            <GenerateBomPopover
              setShowModal={setIsGenerateBomPopoverOpen}
            />
        </Modal>
      ) : null
    );
  };

  const AgentComRateContainer = () => {
    const [localAgentComRate, setLocalAgentComRate] = React.useState(quote?.Agent_Com_Rate__c || 0);
    const [editing, setEditing] = React.useState(false);

    React.useEffect(() => {
      if(localAgentComRate !== quote?.Agent_Com_Rate__c && !editing) {

        const newComAmount = quote.Total__c * (localAgentComRate * .01);
        setCurrentAgentComAmount(newComAmount);

        const updatedQuote = {
          ...quote,
          Agent_Com_Rate__c: localAgentComRate
        };

        dispatchQuoteState({type: QuoteActionTypes.loadQuote, payload: updatedQuote});
      }
    }, [localAgentComRate, editing]);

    return (!editing ?
      <IonItem class="ion-no-padding value-container">
        <IonLabel
          class={classNames({ 'quote-info-value': true, 'link': !isQuoteLocked })}
          data-testid="QAQuoteDetailPageAgentComRate"
          onClick={() => {
            if(!isQuoteLocked) {
              setEditing(true);
            }
          }}
        >
          {quote?.Agent_Com_Rate__c ? quote?.Agent_Com_Rate__c?.toFixed(2) + '%' : '0.00%'}
        </IonLabel>
      </IonItem> :
      <div className="rate-edit-container" tabIndex={0} onBlur={() => setEditing(false)}>
        <IonIcon class="decrement-icon" src={remove} onClick={() => setLocalAgentComRate(Math.max(localAgentComRate - 0.5, 0))}></IonIcon>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          inputType='number'
          placeholder='0.00'
          regex={Regexes.DecimalNumber}
          min='0.00'
          max='100.00'
          step='0.5'
          style={{ width: '38px', marginTop: '-5px', marginLeft: '8px', marginRight: '8px', textAlign: 'end', fontSize: '12px' }}
          onBlur={(agentComRate) => setLocalAgentComRate(floorToHalf(Math.min(Math.max(0, +agentComRate), 100 - contributionPercent - 0.1)))}
          value={localAgentComRate.toFixed(2)}
        />
        <IonIcon class="increment-icon" src={add} onClick={() => setLocalAgentComRate(floorToHalf(Math.min(localAgentComRate + 0.5, 100 - contributionPercent - 0.1)))}></IonIcon>
      </div>
    );
  };

  const MarginAdjustmentContainer = () => {
    const [localMargin, setLocalMargin] = React.useState(quote?.ContributionPercent__c || 0);
    const [editing, setEditing] = React.useState(false);

    React.useEffect(() => {
      if(localMargin !== (quote?.ContributionPercent__c || 0) && !editing) {

        quoteLineItems.forEach(quoteLineItem => {
          quoteLineItem.Contribution__c = localMargin;
          saveQuoteLineItem(new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>(), [QuoteLineItemValueTypes.ContPercentage], quoteLineItem);
        });
      }
    }, [localMargin, editing]);

    return (!editing ?
      <IonItem class="ion-no-padding value-container">
        <IonLabel
          class={classNames({ 'quote-info-value': true, 'link': !isQuoteLocked })}
          data-testid="QAQuoteDetailPageContribution%"
          onClick={() => {
            if(!isQuoteLocked) {
              setEditing(true);
            }
          }}
        >
          {localMargin.toFixed(2) + '%'}
        </IonLabel>
      </IonItem>
    :
      <div className="rate-edit-container" tabIndex={0} onBlur={() => setEditing(false)}>
        <IonIcon class="decrement-icon" src={remove} onClick={() => setLocalMargin(Math.max(localMargin - 0.5, 0))}></IonIcon>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          inputType='number'
          placeholder='0.00'
          regex={Regexes.DecimalNumber}
          min='0.00'
          max='100.00'
          step='0.5'
          style={{ width: '48px', marginTop: '-5px', marginLeft: '8px', marginRight: '8px', fontSize: '12px' }}
          onBlur={(newMargin) => setLocalMargin(newMargin)}
          value={localMargin.toFixed(2)}
        />
        <IonIcon class="increment-icon" src={add} onClick={() => setLocalMargin(Math.min(localMargin + 0.5, 100))}></IonIcon>
      </div>
    );
  };

  const GlobalCostAdjustmentsFooterContainer = () => {
    return (!areDetailsLoading && (activeTab === QuoteItemSelectionTabs.Cost && quoteLineItems.length) || (activeTab === QuoteItemSelectionTabs.OSCost && quoteLineItems.length) ?
      <GlobalCostAdjustmentsFooter unitsOfMeasure={displayUom} lineItemFieldsMap={gcaLineItemFieldsMap} setLineItemFieldsMap={setGcaLineItemFieldsMap}/> : null
    );
  };

  const getQuoteActionDropdownOptions = () => {
    const dropdownOptions = [QuoteActions.SubmitQuote, QuoteActions.CloneQuote, QuoteActions.Lock, QuoteActions.SendQuoteToCustomer, QuoteActions.SubmitToPlanning];

    //Clean up once verified
    // if(isQuoteLocked) {
    //   dropdownOptions = dropdownOptions.filter(option => option !== QuoteActions.Lock && option !== QuoteActions.SendQuoteToCustomer && option !== QuoteActions.SubmitToPlanning);
    // }

    // if(!isAdminOrPlanning()) {
    //   dropdownOptions = dropdownOptions.filter(option => option !== QuoteActions.SubmitQuote);
    // }

    //Removal of Revert To Draft Status
    // if(quote?.Quote_Status__c === QuoteDisplayStatuses.Draft) {
    //   dropdownOptions = dropdownOptions.filter(option => option !== QuoteActions.RevertToDraft);
    // }

    if(quote?.Quote_Status__c === QuoteDisplayStatuses.SubmitQuoteToD365) {
      return dropdownOptions.filter((option: string) => option === QuoteActions.CloneQuote);
    }

    return dropdownOptions.filter(option => option !== quote?.Quote_Status__c);
  };

  return (isLoadingComplete() ?
    <>
      <IonPopover
        cssClass='quote-setup-popover'
        isOpen={quoteSetupPopoverState.showPopover}
        animated={ false }
        onDidDismiss={async () => {
          setShowQuoteSetupPopover({ showPopover: false, event: undefined });
        }}
        backdropDismiss={false}
      >
        <QuoteSetupPopover
          setShowPopover={setShowQuoteSetupPopover}
        />
      </IonPopover>
      <SizingStatsPopover />
      <CostDetailPopover />
      <GenerateBillOfMaterialsPopover/>
      <IonContent class="quote-detail-page" tabIndex={0}>
        <div className={classNames({
          'max-width-container': true,
          'quote-detail-wrapper': true,
          'loading': isLoading
        })}
        >
          <div className='floating-actions-container'>
            <IonPopover
              cssClass='terms-popover'
              isOpen={termsPopoverState.showPopover}
              animated={ false }
              onDidDismiss={async () => {
                setShowTermsPopover({ showPopover: false, event: undefined });
              }}
            >
              <TermsPopover setShowPopover={setShowTermsPopover}/>
            </IonPopover>

            <QuoteNote quote={quote} isDataLoading={areDetailsLoading || isDataLoading}/>

            <FloatingAction icon={termsTab} darkModeIcon={termsTabDarkMode} hoveredIcon={termsTabHovered} disabledIcon={termsTabDisabled} disabled={quoteLineItems?.length === 0 || areDetailsLoading || isDataLoading} onClick={() => setShowTermsPopover({ showPopover: true, event: undefined })} action='terms'/>
            <FloatingAction icon={advSpecsTab} darkModeIcon={advSpecsTabDarkMode} hoveredIcon={advSpecsTabHovered} disabledIcon={advSpecsTabDisabled} disabled={quoteLineItems?.length === 0 || areDetailsLoading || isDataLoading} onClick={() => navigateToRoute(PageRoutes.AdvancedSpecifications, history, quote.Id)} action='adv specs'/>
          </div>
          <div className='floating-actions-container dynamic'>
            <DynamicFloatingActions/>
          </div>
          <QuoteDetailTitleCard/>
          <hr/>
          <div className='quote-details-container'>
            <div>
              <div className={classNames({
                'quote-status-container': true,
                'draft': quote?.Quote_Status__c === QuoteDisplayStatuses.Draft
              })}
              >
                <IonLabel class='quote-status-title'>Quote Status</IonLabel>
                <IonLabel class='quote-status' data-testid="QAQuoteDetailPageQuoteStatus">{quote?.Quote_Status__c}</IonLabel>
              </div>
              <Disabled checks={[isLoadLoading, isSyncing, isLoading, isDataLoading, quoteLoading]}>
                <div className='multi-action-button-container'>
                  <MultiActionButton
                    containerStyle={'multi-action-button-container'}
                    style={'default-display'}
                    defaultOption={QuoteActions.Save}
                    dropdownOptions={getQuoteActionDropdownOptions()}
                    buttonPosition='end'
                    actionHandler={(action) => handleQuoteAction(action)}
                  />
                </div>
              </Disabled>
            </div>
            <div className='left-side'>
              <IonLabel class="quote-info-label quote-details">Quote Details</IonLabel>
              <div className='quote-info-container'>
                <IonList lines="none" class="ion-no-padding quote-info-label-container">
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Date:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Quote Number:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Customer Reference:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Company Name:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Salesforce Opportunity:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Notes:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Manufacturing Site:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Quote&lsquo;s Save UOM:</IonLabel>
                  </IonItem>
                </IonList>
                <IonList lines="none" class="ion-no-padding quote-info-value-container">
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageDate">{quote.CreatedDate ? moment(quote.CreatedDate).format('L') : '- -'}</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value link" data-testid="QAQuoteDetailPageQuoteNumber" onClick={() => { window.open(`${environment.SALESFORCE_URL}lightning/r/NC_Quote__c/${quote?.Id}/view`, '_blank'); } }>{quote?.Name || '- -'}</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageCustomerReference">{ quote?.Customer_Reference__c || '- -' }</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageCompanyName">{ quote?.Account__r?.Name || '- -'}</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class={classNames({ 'quote-info-value': true, 'link': true })} data-testid="QAQuoteDetailPageSalesforceOpportunity" onClick={() => { window.open(`${environment.SALESFORCE_URL}lightning/r/Opportunity/${quote?.Opportunity__c}/view`, '_blank'); } }>{ (extractId(quote?.Opportunity__r?.Name) ?? '') || '- -' }</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageRevisionNotes">
                      <RevisionNote
                        quote={quote}
                      />
                    </IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageManufacturingSite">{quote?.Manufacturing_Site__c || '- -'}</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                      {quote && <DocumentUOM documentUom={quote?.DocumentUOM__c as MeasurementSystems} />}
                  </IonItem>
                </IonList>
              </div>
            </div>
            <div className='right-side'>
              <div className='quote-info-container'>
                <IonList lines="none" class="ion-no-padding quote-info-label-container">
                  <IonItem class="ion-no-padding label-container total">
                    <IonLabel class="quote-info-label total">Total</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Total Cost w/o Com:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Total SQ M:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Contribution $:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Contribution %:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Contrib $/SQ M:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Approval Level:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Agent Com Rate:</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding label-container">
                    <IonLabel class="quote-info-label">Agent Com Amt:</IonLabel>
                  </IonItem>
                </IonList>
                <IonList lines="none" class="ion-no-padding quote-info-value-container">
                  <IonItem class="ion-no-padding value-container total">
                    <IonLabel class="quote-info-value total" data-testid="QAQuoteDetailPageTotal">{formatCurrency(quote.Total__c, quote.CurrencyIsoCode)}</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageTotalw/oCom">{formatCurrency(displayedCostWoCom, quote.CurrencyIsoCode)}</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageTotalSQM">{quote?.Total_SQ_M__c || '0.000'}</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageContribution$">{formatCurrency(quote.Contribution__c, quote.CurrencyIsoCode)}</IonLabel>
                  </IonItem>
                  <MarginAdjustmentContainer/>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageContrib$/SQM">{formatCurrency(quote.Contribution_SQ_M__c || 0, quote.CurrencyIsoCode)}</IonLabel>
                  </IonItem>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageApprovalLevel">{'None'}</IonLabel>
                  </IonItem>
                  <AgentComRateContainer/>
                  <IonItem class="ion-no-padding value-container">
                    <IonLabel class="quote-info-value" data-testid="QAQuoteDetailPageAgentComAmt">{formatCurrency(currentAgentComAmount, quote.CurrencyIsoCode)}</IonLabel>
                  </IonItem>
                </IonList>
              </div>
            </div>
          </div>
          <div className="line-items-container">
            <div className='header-container'>
              {/* disable tab action events if quote line item details are still fetching */}
              <div className={classNames({ 'tabs-container': true, 'disabled': areDetailsLoading || isDataLoading })}>
                {tabs.map((tab, index) => (
                  <Tab tab={tab} key={index}/>
                ))}
              </div>
              {/* adjustment container for uom toggler */}
              <div style={{margin: '5px 10px 0 0'}}>
                <UomDisplayToggle />
              </div>
            </div>
            <hr/>
            { areDetailsLoading && <LoadingEllipsisMessage message="Loading Quote Line Item Details" /> }
            {/* disable line items table action events if quote line item details are still fetching */}
            <div className={classNames({ 'disabled': areDetailsLoading || isDataLoading })}>
              <LineItemsDisplayType hasMultiShotFeatureFlag={props.hasMultiShotFeatureFlag}/>
            </div>

          </div>
        </div>
        <GlobalCostAdjustmentsFooterContainer/>
        <Footer/>
      </IonContent>
    </> :
    <div className='quote-detail-loading-container'>
      <Loading data-testId='QAQuoteDetailLoading' message='Loading Quote...' />
      <Footer/>
    </div>
  );
};

const GuardrailPopover = ({ quoteLineItemToEdit, rules, onDismiss }) => {
  const { dataState } = useDataState();
  const { rawMaterials } = dataState;
  const {
    saveQuoteLineItem,
    quoteState
  } = useQuoteState();
  const { quote } = quoteState;
  const { localUom } = useLocalUom();

  const { Manufacturing_Site__c: manufacturingSite } = quote;

  const baseMetal = rawMaterials?.find(
    rawMaterial =>
      rawMaterial.name === quoteLineItemToEdit.BaseMetal__c &&
      rawMaterial.usage === RawMaterialUsage.Base &&
      rawMaterial.dataAreaId.includes(manufacturingSite)
  );
  const cladMetal = rawMaterials?.find(
    rawMaterial =>
      rawMaterial.name === quoteLineItemToEdit.CladMetal__c &&
      rawMaterial.usage === RawMaterialUsage.Clad &&
      rawMaterial.dataAreaId.includes(manufacturingSite)
  );

  const title = rules.find(value =>
    [GuardRailsLongDescription.FTValue, GuardRailsLongDescription.FTValueBase, GuardRailsLongDescription.FTValueClad]
      .includes(value)
  ) || '';

  const baseThinningValueMM = calculateThicknessAddition(baseMetal, manufacturingSite, localUom, quoteLineItemToEdit.Base_TK_mm__c) || 0;
  const baseThinningValueInches = calculateThicknessAddition(baseMetal, manufacturingSite, localUom, quoteLineItemToEdit.Base_TK_in__c) || 0;
  const cladThinningValueMM = calculateThicknessAddition(cladMetal, manufacturingSite, localUom, quoteLineItemToEdit.Clad_TK_mm__c) || 0;
  const cladThinningValueInches = calculateThicknessAddition(cladMetal, manufacturingSite, localUom, quoteLineItemToEdit.Clad_TK_in__c) || 0;

  const quoteLineItem: QuoteLineItem = quoteLineItemToEdit;
  const imperialUom = quoteLineItem.Unit_of_Measure__c === MeasurementSystems.Imperial;

  const [ftValue, setFTValue] = React.useState(0);

  const onHandleDismiss = () => {
    onDismiss();
  };

  const onClickClose = onHandleDismiss;

  const onClickSave = () => {
   const ftValueMM = calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, ftValue, Units.Millimeters, Units.Inches);
   const ftValueInches = calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, ftValue, Units.Millimeters, Units.Inches);
   
   try {
      const updatedFTValues = { ...quoteLineItem };
      if (rules.includes(GuardRailsLongDescription.FTValue) || rules.includes(GuardRailsLongDescription.FTValueBase)) {
        updatedFTValues.Base_FT_Value_in__c = ftValueInches;
        updatedFTValues.Base_FT_Value_mm__c = ftValueMM;
        updatedFTValues.quoteLineItemDetail.Base_TK_in__c = computeFTNewThickness(quoteLineItem.quoteLineItemDetail.Base_TK_in__c, baseThinningValueInches, ftValueInches);
        updatedFTValues.quoteLineItemDetail.Base_TK_mm__c = computeFTNewThickness(quoteLineItem.quoteLineItemDetail.Base_TK_mm__c, baseThinningValueMM, ftValueMM);
      }
      if (rules.includes(GuardRailsLongDescription.FTValue) || rules.includes(GuardRailsLongDescription.FTValueClad)) {
        updatedFTValues.Clad_FT_Value_in__c = ftValueInches;
        updatedFTValues.Clad_FT_Value_mm__c = ftValueMM;
        updatedFTValues.quoteLineItemDetail.Clad_TK_in__c = computeFTNewThickness(quoteLineItem.quoteLineItemDetail.Clad_TK_in__c, cladThinningValueInches, ftValueInches);
        updatedFTValues.quoteLineItemDetail.Clad_TK_mm__c = computeFTNewThickness(quoteLineItem.quoteLineItemDetail.Clad_TK_mm__c, cladThinningValueMM, ftValueMM);
      }
      saveQuoteLineItem(new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>(), [], updatedFTValues);
      onDismiss();
    } catch(e) {
      console.error(e);
   }
  };

  const actionButtons = [
    {
      color: 'primary',
      onClick: onClickSave,
      label: 'Save',
    },
    {
      color: 'secondary',
      onClick: onClickClose,
      label: 'Cancel',
    },

  ];
 
  const getSelectionLabel = (rule) => {
    const labelMap = {
      [GuardRailsLongDescription.FTValue]: QuoteLineItemsColumnHeadings.BaseTK,
      [GuardRailsLongDescription.FTValueBase]: QuoteLineItemsColumnHeadings.BaseTK,
      [GuardRailsLongDescription.FTValueClad]: QuoteLineItemsColumnHeadings.CladTK,
    };
  
    return labelMap[rule] || null;
  };

  const getSelectionValue = (rule) => {
    const valueMap = {
      [GuardRailsLongDescription.FTValue]: imperialUom ? quoteLineItem.Base_TK_in__c : quoteLineItem.Base_TK_mm__c,
      [GuardRailsLongDescription.FTValueBase]: imperialUom ? quoteLineItem.Base_TK_in__c : quoteLineItem.Base_TK_mm__c,
      [GuardRailsLongDescription.FTValueClad]: imperialUom ? quoteLineItem.Clad_TK_in__c : quoteLineItem.Clad_TK_mm__c,
    };
  
    return valueMap[rule] || null;
  };

  return (
    <Modal
      title={title}
      options={{
        isOpen: true,
        size: 'guardrail',
      }}
      onClickClose={onClickClose}
      actionButtons={actionButtons}
    >
        <IonGrid class='modal-gr'>
          <IonRow>
            <IonCol>
            {GuardRailsTriggers.map((rule, index) => (
              rules.includes(rule) && (
                <SelectionContainer
                  key={`trigger${index}`}
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel={getSelectionLabel(rule)}
                  placeholder='0.00'
                  formatter={(value) => value?.toFixed(3)}
                  value={getSelectionValue(rule)}
                  isDisabled={true}
                />
              )
            ))}
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                selectionLabel={'F&T Value'}
                placeholder='0.00'
                regex={Regexes.DecimalNumber}
                min='0.01'
                step='0.01'
                onBlur={(ftValue) => setFTValue(+ftValue)}
                formatter={(value) => {
                  return value?.toFixed(3);
                }}
                value={ftValue}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
    </Modal>
  );
};


const AddEditQuoteLineItemPopover = ({ hasMultiShotFeatureFlag }: { hasMultiShotFeatureFlag: boolean }) => {
  const {
    showPopover,
    setShowPopover,
    quoteLineItemToEdit,
    quoteLineItemDetailToEdit,
    setQuoteLineItemToEdit,
    setQuoteLineItemDetailToEdit,
  } = useAddEditLineItemPopover();

  const [ showGuardrailsPopover, setShowGuardrailsPopover ] = React.useState({
    broken: false,
    rule: null
  });
  const [ currentQuoteLineItem, setCurrentQuoteLineItem ] = React.useState(undefined);

  return <>
    <IonPopover
      cssClass='add-quote-item-popover'
      isOpen={showPopover}
      animated={ false }
      backdropDismiss={ false }
      onDidDismiss={async () => {
        setQuoteLineItemToEdit(null);
        setQuoteLineItemDetailToEdit(null);
      }}
    >
      <AddQuoteItemPopover
        setShowPopover={(payload) => {
          if(payload.showGuardrailsPopover?.broken) {
            setCurrentQuoteLineItem(payload.builtQuote);
            setShowGuardrailsPopover({
              broken: payload.showGuardrailsPopover.broken,
              rule: payload.showGuardrailsPopover.rule
            });
          }
          setShowPopover(payload.showPopover);
        }}
        quoteLineItemToEdit={quoteLineItemToEdit}
        quoteLineItemDetailToEdit={quoteLineItemDetailToEdit}
        hasMultiShotFeatureFlag={hasMultiShotFeatureFlag}
      />
    </IonPopover>

    {
      showGuardrailsPopover.broken && <GuardrailPopover
        quoteLineItemToEdit={currentQuoteLineItem}
        rules={showGuardrailsPopover.rule}
        onDismiss={() => setShowGuardrailsPopover({
          broken: false,
          rule: null
        })}
                                      />
    }
  </>;
};

const QuoteDetailPage = (props) => {
  const { quote, cloning } = props?.history?.location?.state || {};
  // Note: using a feature flag to enable multi-shot functionality,
  // This can come from Salesforce, for individual users, but using an environment variable for now, for DEV only.
  // This will eventually be removed once multi-shot is fully implemented.
  // This ENV VAR is REQUIRED to be set in order to use app now.
  const hasMultiShotFeatureFlag = environment.MULTI_SHOT_FEATURE_FLAG;
  return <>
    <AddEditLineItemProvider>
      <QuoteDetailPageDetail hasMultiShotFeatureFlag={hasMultiShotFeatureFlag} historyState={{ quote, cloning }}/>
      <AddEditQuoteLineItemPopover hasMultiShotFeatureFlag={hasMultiShotFeatureFlag}/>
    </AddEditLineItemProvider>
  </>;
};

const RevisionNote = ({quote}) => {
  const { dispatchQuoteState } = useQuoteDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const [note, setNote] = React.useState(quote?.RevisionNotes__c);

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickNote = () => {
    setIsOpen(true);
  };

  const onClickSave = () => {
    dispatchQuoteState({type: QuoteActionTypes.setQuoteRevisionNotes, payload: note});
    onClickClose();
  };

  const onUpdateNote = (value: string) => {
    setNote(value);
  };

  const actionButtons = [
    {
      color: 'primary',
      onClick: onClickSave,
      label: 'Save',
    },
    {
      color: 'secondary',
      onClick: onClickClose,
      label: 'Cancel',
    },
  ];

  return <>
    <IonLabel
      class="quote-info-value link"
      data-testid="QAQuoteDetailPageRevisionNotes"
      style={{ cursor: 'pointer' }}
      onClick={onClickNote}
    >
        {note || '- -'}
    </IonLabel>

    <Modal
      options={{
        isOpen,
        size: 'sm',
      }}
      onClickClose={onClickClose}
      actionButtons={actionButtons}
      title="Revision Notes"
      modalClass='revision-note-popover'
    >
      <IonTextarea
        class="notes-text-area"
        placeholder='Enter revision notes'
        rows={10}
        value={note}
        onIonChange={_.debounce((e) => onUpdateNote((e.target as HTMLInputElement).value), 200)}
      />
    </Modal>
  </>;
};

const QuoteNoteForm = ({ quote, done }) => {
  const { dispatchQuoteState } = useQuoteDispatch();
  const [notes, setNotes] = React.useState(quote?.Quote_Notes__c || '');
  const maxLength = 131072;

  const onSave = () => {
    dispatchQuoteState({type: QuoteActionTypes.setQuoteRevisionNotes, payload: notes});

    if (done) {
      done();
    }
  };

  return <div style={{
      position: 'fixed',
      right: '65px',
      width: '300px',
      background: 'white',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid black',
      overflowY: 'scroll',
      maxHeight: '80%',
      minHeight: '150px',
  }}
         >
    <span style={{
      display: 'block',
      fontWeight: 'bold',
      fontSize: '1em',
      borderBottom: '1px solid black'
    }}
    > Quote Notes:</span>

    <div style={{  }}>
        <IonTextarea
          class="notes-text-area"
          placeholder='Enter quote notes'
          autoGrow={true}
          rows={10}
          value={notes}
          maxlength={maxLength}
          onIonChange={(e) => setNotes((e.target as HTMLInputElement).value)}
        />
        <div>
          <span style={{
            float: 'right',
            fontSize: '10px'
          }}
          >
            {(notes || '').length} / {maxLength}
          </span>
        </div>


        <IonButton
          color='primary'
          disabled={(notes || '').length > maxLength}
          onClick={() => onSave()}
        >
          Save
        </IonButton>
    </div>
  </div>;
};

const QuoteNote = ({quote, isDataLoading}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return <>
    <FloatingAction
      elId='quote--note-icon'
      icon={notesTab}
      darkModeIcon={notesTabDarkMode}
      hoveredIcon={notesTabHovered}
      disabledIcon={notesTabDisabled}
      disabled={isDataLoading}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      action='Notes'
    />

    {isOpen && <QuoteNoteForm quote={quote} done={() => setIsOpen(false)}/>}
  </>;
};

export default QuoteDetailPage;