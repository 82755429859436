import { postCall as post, getCall as get, deleteCall } from '@services/rest-service';
import DocumentInfo from '@interfaces/document-info';

// Uploads the document to dropbox
export async function uploadDocument(documentInfo: DocumentInfo): Promise<{ success: boolean }> {
  return post<{ success: boolean }>('documents', { file: documentInfo.document },
    {
      documentPurpose: documentInfo.documentPurpose,
      fileExtension: documentInfo.fileExtension,
      salesOrderNumber: documentInfo.salesOrderNumber,
      poNumber: documentInfo.poNumber,
      salesOrderItemList: documentInfo.salesOrderItemList || '',
      name: documentInfo.name,
      visibility: documentInfo.visibility,
      incrementVersion: documentInfo.incrementVersion,
      invoiceNumber: documentInfo.invoiceNumber,
    });
}

// Downloads the document from dropbox
export async function downloadDocument(fileId: string): Promise<any> {
  return get<any>(`documents/${fileId}/download`);
}

// Deletes the document in dropbox
export async function deleteDocument(fileId: string): Promise<any> {
  return deleteCall<any>(`documents/${fileId}/delete`);
}