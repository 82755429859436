import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useSegmentContext } from '../segments/segment-context';
import LineItem from '../line-item/line-item';

import './section.scss';
import { SEGMENTS } from '../segments/segments';

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

interface Props {
  title?: string,
  segmentKey?: string,
  children: React.ReactNode;
}

const Section = ({ children, title, segmentKey }: Props) => {
  const ref = useRef(null);
  const isInView = useIsInViewport(ref);
  const { segment, setSegment } = useSegmentContext();

  useEffect(() => {
    if (isInView) {
      setSegment(segmentKey)
    }
  }, [isInView]);

  useEffect(() => {
    if (segment === segmentKey && !isInView && segment !== SEGMENTS.CLAD_PRODUCT_TESTING.value) {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [segment])

  return <div className='section' ref={ref}>
    { title && <h5>{title}</h5>}
    <div className="section-content">
      { children }
    </div>
  </div>
}

export default Section;