export enum MyAccountTabs {}

export enum CounterFormatTypes {
  Price = 'price',
  Percentage = 'percentage',
}

export enum CounterSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum CutMethods {
  WaterJetCut = 'Water Jet Cut',
  TorchCut = 'Torch Cut',
  SawCut = 'Saw Cut',
  Uncut = 'Uncut',
}

export enum ProfileSettings {
  MeasurementSystem = 'Preferred measurement system',
  Currency = 'Preferred currency',
}

export enum MeasurementSystems {
  Imperial = 'Imperial',
  Metric = 'Metric',
  Custom = 'Custom',
}

export enum Currencies {
  USD = '$ USD',
  CAN = '$ CAN',
  EUR = '\u20AC EUR',
}

export const Regexes: { [key: string]: RegExp } = {
  Email: /[a-z0-9]+@[a-z-0-9]+\.[a-z]{2,3}/,
  DecimalNumber: /^[0-9]*\.?[0-9]*$/,
  IntegerAboveZero: /^[1-9][0-9]*$/,
  PositiveInteger: /^[0-9][0-9]*$/,
  Value: /<<VALUE>>/g,
  Value2: /<<VALUE2>>/g,
  Temperature: /^-?[0-9]*\.?[0-9]*$/,
  WholeNumber: /^(0|[1-9]\d*|)$/
};

export const ProfileSettingsMap = new Map([
  [
    ProfileSettings.Currency,
    {
      selections: [...Object.values(Currencies)],
      defaultSelection: Currencies.USD,
    },
  ],
  [
    ProfileSettings.MeasurementSystem,
    {
      selections: [...Object.values(MeasurementSystems)],
      defaultSelection: MeasurementSystems.Imperial,
    },
  ],
]);

export const OPERATOR_DEFAULT_VALUE = 'None Selected';

export enum UserType {
  Anonymous = 'anonymous',
  External = 'external',
  Sales = 'sales',
}

export enum LanguageCountryCodes {
  USA = 'en-US',
  Germany = 'de-DE',
}

export enum OrdersPageColumnHeadings {
  Status = 'Status',
  PurchaseOrder = 'Purchase Order',
  PO = 'PO',
  NCSalesOrder = 'NC Sales Order',
  Account = 'Account',
  Items = 'Items',
  LastUpdate = 'Last Update',
  Total = 'Total',
}

export enum InvoicesPageColumnHeadings {
  Status = 'Status',
  Invoice = 'Invoice',
  PONumber = 'PO Number',
  PaymentDue = 'Payment Due',
  Total = 'Total',
}

export enum CertificationsPageColumnHeadings {
  Name = 'Name',
  Location = 'Location',
}

export enum DashboardPageOrdersColumnHeadings {
  Status = 'Status',
  PurchaseOrder = 'Purchase Order',
  PO = 'PO',
  NCSalesOrder = 'NC Sales Order',
  LastUpdate = 'Last Update',
  Total = 'Total',
}

export enum LineItemDetailsColumnHeadings {
  Item = 'Item',
  Status = 'Status',
  Description = 'Description',
  ShipDate = 'Ship Date',
  UnitPrice = 'Unit Price',
}

export enum LineItemOtherDescriptionHeadings {
  Base = 'Base',
  Clad = 'Clad',
  PO = 'PO',
  Tag = 'Tag',
  Project = 'Project',
  ShipDate = 'Ship Date',
  ShipTo = 'Ship To',
}

export enum UploadDocumentsColumnHeadings {
  Name = 'Name',
  Item = 'Item',
  DocumentType = 'Document Type',
  Version = 'Version',
}

export enum DocumentsColumnHeadings {
  Name = 'Name',
  DateAdded = 'Date Added',
}

export enum NCLineItemsColumnHeadings {
  Line = 'Line',
  Status = 'Status',
  Description = 'Description',
  Quantity = 'Quantity',
  UnitPrice = 'Unit Price',
  Total = 'Total',
}

export enum QuoteLineItemsColumnHeadings {
  AddDetails = 'Add Details',
  AdjPrice = 'Adj Price',
  Anvil = 'Anvil',
  AnvilTK = 'Anvil TK',
  AssemblyGroup1 = 'Assembly Group 1',
  BaseCost = 'Base Cost',
  BaseFrt = 'Base Frt',
  BaseKGPC = 'Base KG/PC',
  BaseLBPC = 'Base LB/PC',
  BaseLength = 'Base Length',
  BaseMetal = 'Base Metal',
  BaseMin = 'Base Min',
  BaseNom = 'Base Nom',
  BaseTK = 'Base TK',
  BaseType = 'Base Type',
  BaseWidth = 'Base Width',
  CladCost = 'Clad Cost',
  CladFrt = 'Clad Frt',
  CladKGPC = 'Clad KG/PC',
  CladLBPC = 'Clad LB/PC',
  CladLength = 'Clad Length',
  CladMetal = 'Clad Metal',
  CladMin = 'Clad Min',
  CladNom = 'Clad Nom',
  Clads = 'Clads',
  CladTK = 'Clad TK',
  CladType = 'Clad Type',
  CladWidth = 'Clad Width',
  ContPct = 'Cont %',
  ContSQM = 'Cont/SQM',
  CostOfEvent = 'Cost of Event',
  Description = 'Line Description',
  Dimensions = 'Dimensions',
  FrtOut = 'Frt Out',
  HeadFrm = 'Head Frm',
  HeadFrt = 'Head Frt',
  InboundShip = 'Inbound Ship',
  L = 'L',
  LeadTime = 'Lead Time (Weeks)',
  Length = 'Length',
  Line = 'Line',
  Notes = 'Notes',
  OSCost = 'OS Cost',
  OutboundShip = 'Outbound Ship',
  Piece = 'Piece',
  PONumber = 'PO Number',
  PriceKG = 'Price/KG',
  PriceLB = 'Price/LB',
  Product = 'Product',
  ProductDescription = 'Product Description',
  ProductTag = 'Product Tag',
  Quantity = 'Quantity',
  ShipVia = 'Ship Via',
  ShipToAddress = 'Ship To Address',
  TotalPrice = 'Total Price',
  TotalKGPC = 'Total KG/PC',
  TotalLBPC = 'Total LB/PC',
  UnitCost = 'Unit Cost',
  W = 'W',
  Welding = 'Welding',
  Width = 'Width',
  Hashtag = '#',
}

export enum AddPieceColumnHeadings {
  Allocation = 'Allocation',
  AssemblyGroup = 'Assembly Group',
  CladTK = 'Clad TK',
  Length = 'Length',
  Location = 'Location',
  PanelNumber = 'Panel Number',
  Width = 'Width',
}

export enum MetalTypes {
  Minimum = 'Minimum',
  NomToBuy = 'Nom to Buy',
  FandT = 'F+T',
}

export enum MetalClasses {
  CarbonSteels = 1,
  AlloySteels = 2,
  AusteniticStainlessSteels = 3,
  FerriticDuplexStainlessSteels = 4,
  CopperCopperAlloys = 5,
  NickelNickelAlloysHigherMolyAusteniticStainlessSteels = 6,
  Titanium = 7,
  Zirconium = 10,
}

export enum OrderAPIStatuses {
  BackOrder = 'BACKORDER',
  Canceled = 'CANCELED',
  Delivered = 'DELIVERED',
  Invoiced = 'INVOICED',
}

export enum NCLineItemAPIStatuses {
  AwaitingRawMaterials = 'AWAITING RAW MATERIALS',
  Bonded = 'BONDED',
  PartiallyShipped = 'PARTIALLY SHIPPED',
  PlanningToShipToContractor = 'PLANNING TO SHIP TO CONTRACTOR',
  RawMaterialsReceived = 'RAW MATERIALS RECEIVED',
  RescheduledShipDate = 'RESCHEDULED SHIP DATE',
  Shipped = 'SHIPPED',
  ShippedToContractor = 'SHIPPED TO CONTRACTOR',
}

export enum InvoiceAPIStatuses {
  Due = 'DUE',
  PastDue = 'PAST DUE',
  Paid = 'PAID',
}

export enum OrderDisplayStatuses {
  Open = 'Open',
  Complete = 'Complete',
  Canceled = 'Canceled',
}

export enum InvoiceDisplayStatuses {
  Due = 'Due',
  PastDue = 'Past Due',
  Paid = 'Paid',
}

export enum QuoteDisplayStatuses {
  Draft = 'Draft',
  SubmitQuoteToD365 = 'Submit Quote to D365',
  Lock = 'Lock',
  SendQuoteToCustomer = 'Send Quote to Customer',
  SubmitToPlanning = 'Submit to Planning',
  IntegrationError = 'Integration Error',
  SalesforceSyncError = 'Salesforce Sync Error'
}

export enum OrderLineItemDisplayStatuses {
  AwaitingRawMaterials = 'Awaiting Raw Materials',
  Bonded = 'Bonded',
  RawMaterialsReceived = 'Raw Materials Received',
  Shipped = 'Shipped',
  ShippedToContractor = 'Shipped to Contractor',
}

export enum BadgeText {
  New = 'New',
  RecentlyUpdated = 'Recently Updated',
}

export enum TimeIntervals {
  Days = 'Days',
  Hours = 'Hours',
  Minutes = 'Minutes',
  Weeks = 'Weeks',
}

export enum NotificationActions {
  Delete = 'Delete',
}

export enum AccountBalanceTypes {
  DueThisMonth = 'Due This Month',
  OpenOrders = 'Open Orders',
  PastDue = 'Past Due',
  Total = 'Total',
}

export enum DocumentActions {
  Download = 'Download',
  View = 'View',
  Delete = 'Delete',
}

export enum OrderActions {
  AddDocuments = 'Add Documents',
}

export enum InvoiceActions {
  Download = 'Download',
  View = 'View',
  AddDocuments = 'Add Documents',
}

export enum BaseMetalUTDefault {
  None = 'None',
}

export enum QuoteLineItemActions {
  Edit = 'Edit',
  Delete = 'Delete',
  Duplicate = 'Duplicate'
}

export enum PanelLocations {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum FileTypes {
  DOC = 'DOC',
  DOCX = 'DOCX',
  DWG = 'DWG',
  LOG = 'LOG',
  MSG = 'MSG',
  PDF = 'PDF',
  RTF = 'RTF',
  XLS = 'XLS',
}

export enum ReactSelectActionMeta {
  SelectOption = 'select-option',
  DeselectOption = 'deselect-option',
}

export enum VaultLockTypes {
  NoLocking = 'NoLocking',
  Biometrics = 'Biometrics',
  SystemPasscode = 'SystemPasscode',
}

export enum SizingDescriptions {
  SpecificationAdder = 'Resulting Specification Adder of the plate based on the specification options selected.',
  EdgeAllowance = 'Resulting Edge Allowance of the plate based on the thickness of the clad metal selected.',
  BoosterAllowance = 'Resulting Booster Allowance of the plate based on the thickness of the clad metal selected.',
  KerfAllowance = 'Resulting Kerf Allowance (or space between requested items on a clad) as a result of the calculation sizing engine.',
  CladOverhang = 'A Clad Metal Overhang allowance based on the clad metal selected.',
  CladOverhangThin = 'A Clad Metal Overhang Thinning allowance based on the clad metal selected.',
  BaseMetalLengthAdder = 'Resulting Base Metal Length Adder of the plate based on the base metal size limit rules for the base metal selected.',
  AnvilEdgeAllowanceAdder = 'Resulting Anvil Edge Allowance Adder of the plate based on the clad metal class rules for the base metal selected.',
  BaseWidth = 'Resulting Width of the Base plate after the sizing calculation has been performed.',
  BaseLength = 'Resulting Length of the Base plate after the sizing calculation has been performed.',
  BaseNominalValue = 'Resulting Nominal Thickness of the Base plate after the sizing calculation has been performed.',
  BaseThinningValue = 'Resulting Thinning value of the Base plate after the sizing calculation has been performed.',
  BaseFTValue = 'Resulting F & T value of the Base plate after the sizing calculation has been performed.',
  BaseThickness = 'Resulting Base Thickness after the sizing calculation has been performed.',
  CladWidth = 'Resulting Width of the Clad plate after the sizing calculation has been performed.',
  CladLength = 'Resulting Length of the Clad plate after the sizing calculation has been performed.',
  CladNominalValue = 'Resulting Nominal Thickness of the Clad plate after the sizing calculation has been performed.',
  CladThinningValue = 'Resulting Thinning value of the Clad plate after the sizing calculation has been performed.',
  CladFTValue = 'Resulting F & t value of the Clad plate after the sizing calculation has been performed.',
  CladThickness = 'Resulting Clad Thickness after the sizing calculation has been performed.',
  Method = 'Sizing method used by the calculation engine to derive the clad plate.',
  LineNumber = 'The associated line number for the requested item.',
  LotQuantity = 'The number of similar clad plated for the requested item (grouped together in a lot).',
  PiecesInWidth = 'The number of requested item pieces contained across the Width of the clad plate.',
  PiecesInLength = 'The number of requested item pieces contained across the Length of the clad plate.',
}

export enum QuoteVariableConfigDescriptions {
  AnvilCostByWeightKG = '[Anvil] Cost by Weight (Per KG)',
  AnvilCostByWeightLB = '[Anvil] Cost by Weight (Per LB)',
  AnvilFreightCostByWeightKG = '[Anvil] Freight Cost by Weight (Per KG)',
  AnvilFreightCostByWeightLB = '[Anvil] Freight Cost by Weight (Per LB)',
  AnvilStandardDensityForWeightCalculationGramsPerCUCM = '[Anvil] Standard Density for Weight Calculation (Grams Per CU CM)',
  AnvilStandardDensityForWeightCalculationPoundsPerCUIN = '[Anvil] Standard Density for Weight Calculation (Pounds Per CU IN)',
  BoosterAllowanceExceptionForSmallCircleDiametersMM = '[BoosterAllowance] Exception for Small Circle Diameters (MM)',
  BoosterAllowanceExceptionForSmallCircleDiametersIN = '[BoosterAllowance] Exception for Small Circle Diameters (IN)',
  BOMStaticText = '[BOM] Static Text',
  CertificateCostEN1020432AllByCustomer = '[Certificate Cost] EN10204-3.2 All By Customer',
  CertificateCostEN1020432AllByDMC = '[Certificate Cost] EN10204-3.2 All By DMC',
  ExportPackagingConsumableCostKG = '[Consumable Cost] Export Packaging (KG)',
  ExportPackagingConsumableCostLB = '[Consumable Cost] Export Packaging (LB)',
  HeatTreatmentComsumableCostLB = '[Consumable Cost] Heat Treatment (Per LB)',
  HeatTreatmentComsumableCostKG = '[Consumable Cost] Heat Treatment (Per KG)',
  ConsumableCostOverallCostSQM = '[Consumable Cost] Overall Cost (Per SQM)',
  ConsumableCostOverallCostSQFT = '[Consumable Cost] Overall Cost (Per SQFT)',
  ConsumableCostSawCutBladesSQMM = '[Consumable Cost] Saw Cut Blades (SQMM)',
  ConsumableCostSawCutBladesSQIN = '[Consumable Cost] Saw Cut Blades (SQIN)',
  ConsumableCostWaterJetCutPerLaborHour = '[Consumable Cost] Water Jet Cut (Per Labor Hour)',
  CosmeticGrindingClass7or10Metric = '[Cosmetic Grinding] Ti & Zr Labor Factor (Hours Per SQ M)',
  CosmeticGrindingClass7or10Imperial = '[Cosmetic Grinding] Ti & Zr Labor Factor (Hours Per SQ FT)',
  CosmeticGrindingOtherMetalsMetric = '[Cosmetic Grinding] Other Metals Labor Factor (Hours Per SQ M)',
  CosmeticGrindingOtherMetalsImperial = '[Cosmetic Grinding] Other Metals Labor Factor (Hours Per SQ FT)',
  CuttingValuePhraseImperial = '[OER Cutting] Value Phrase (Imperial)',
  CuttingValuePhraseMetric = '[OER Cutting] Value Phrase (Metric)',
  ExplosiveLoadCostForMaterialPerKG = '[Explosive Load] Cost for Material (Per KG)',
  ExplosiveLoadCostForMaterialPerLB = '[Explosive Load] Cost for Material (Per LB)',
  ExplosiveLoadDefaultExplosiveType = '[Explosive Load] Default Explosive Type',
  ExplosiveLoadCladAreaExtraLoadMultiplier = '[Explosive Load] Clad Area Extra Load Multiplier',
  ExplosiveLoadMinCladAreaThresholdForExtraLoadSQM = '[Explosive Load] Min Clad Area Threshold For Extra Load (SQ M)',
  ExplosiveLoadMinCladAreaThresholdForExtraLoadSQFT = '[Explosive Load] Min Clad Area Threshold For Extra Load (SQ FT)',
  ExplosiveLoadSSTIZRExtraLoadMultiplier = '[Explosive Load] SS & Ti/Zr Extra Load Multiplier',
  ExportPackagingLaborCostKG = '[Export Packaging] Labor Cost (Hours Per KG)',
  ExportPackagingLaborCostLB = '[Export Packaging] Labor Cost (Hours Per LB)',
  ExportPackagingMaxPartCost = '[Export Packaging] Maximum Part Cost',
  ExportPackagingMinPartCost = '[Export Packaging] Minimum Part Cost',
  FlatteningPlateImperial = '[OER Flattening] Plate Value Phrase (Imperial)',
  FlatteningPlateMetric = '[OER Flattening] Plate Value Phrase (Metric)',
  FlatteningTubesheetImperial = '[OER Flattening] Tubesheet Value Phrase (Imperial)',
  FlatteningTubesheetMetric = '[OER Flattening] Tubesheet Value Phrase (Metric)',
  FlatteningNone = '[OER Flattening] None',
  FlatteningOther = '[OER Flattening] Other',
  FreightWideAdderMaximumWidthMM = '[Freight] Wide Adder Maximum Width (MM)',
  FreightWideAdderMaximumWidthIN = '[Freight] Wide Adder Maximum Width (IN)',
  FreightWideAdderMinimumWidthMM = '[Freight] Wide Adder Minimum Width (MM)',
  FreightWideAdderMinimumWidthIN = '[Freight] Wide Adder Minimum Width (IN)',
  HeatTreatmentHoursRequiredPerClad = '[Heat Treatment] Hours Required (Per Clad)',
  HeatTreatmentBaseThicknessExceptionMM = '[Heat Treatment] Base Thickness Exception (MM)',
  HeatTreatmentBaseThicknessExceptionIN = '[Heat Treatment] Base Thickness Exception (IN)',
  HeatTreatmentRequiredBaseThicknessMaximumMM = '[Heat Treatment] Required Base Thickness Maximum (MM)',
  HeatTreatmentRequiredBaseThicknessMaximumIN = '[Heat Treatment] Required Base Thickness Maximum (IN)',
  HeatTreatmentRequiredTubesheetDiameterMinimumMM = '[Heat Treatment] Required Tubesheet Diameter Minimum (MM)',
  HeatTreatmentRequiredTubesheetDiameterMinimumIN = '[Heat Treatment] Required Tubesheet Diameter Minimum (IN)',
  HeatTreatmentRequiredTubesheetThicknessMaximumMM = '[Heat Treatment] Required Tubesheet Thickness Maximum (MM)',
  HeatTreatmentRequiredTubesheetThicknessMaximumIN = '[Heat Treatment] Required Tubesheet Thickness Maximum (IN)',
  MachiningFaceDiameterManHoursPartMachined = '[Machining] Face Diameter Man Hours (Part Machined)',
  MachiningFaceDiameterManHoursPerSQMM = '[Machining] Face Diameter Man Hours (Per SQ MM)',
  MachiningFaceDiameterManHoursPerSQIN = '[Machining] Face Diameter Man Hours (Per SQ IN)',
  MachiningOutsideDiameterManHoursPartMachined = '[Machining] Outside Diameter Man Hours (Part Machined)',
  MachiningOutsideDiameterManHoursPerSQMM = '[Machining] Outside Diameter Man Hours (Per SQ MM)',
  MachiningOutsideDiameterManHoursPerSQIN = '[Machining] Outside Diameter Man Hours (Per SQ IN)',
  MachiningTubesheetImperial = '[OER Machining] Tubesheet Value Phrase (Imperial)',
  MachiningTubesheetMetric = '[OER Machining] Tubesheet Value Phrase (Metric)',
  MachiningNonTubesheetImperial = '[OER Machining] Value Phrase (Imperial)',
  MachiningNonTubesheetMetric = '[OER Machining] Value Phrase (Metric)',
  OERTestingShearTestingPressurePSI = '[OER Testing] Shear Testing Pressure (PSI)',
  OERTestingShearTestingPressureMPA = '[OER Testing] Shear Testing Pressure (MPA)',
  OERWeldingRangeOfThicknessForLengthCheck1IN = '[OER Welding] Range of Thickness for Length Check 1 (IN)',
  OERWeldingRangeOfThicknessForLengthCheck2IN = '[OER Welding] Range of Thickness for Length Check 2 (IN)',
  OERWeldingRangeOfThicknessForLengthCheck1MM = '[OER Welding] Range of Thickness for Length Check 1 (MM)',
  OERWeldingRangeOfThicknessForLengthCheck2MM = '[OER Welding] Range of Thickness for Length Check 2 (MM)',
  OverhangSizeOfCladEdgeWBaseForgingMM = '[Overhang] Size of Clad Edge w/ Base Forging (MM)',
  OverhangSizeOfCladEdgeWBaseForgingIN = '[Overhang] Size of Clad Edge w/ Base Forging (IN)',
  SawCutGangingFactorWidthMinimumMM = '[Saw Cut] Ganging Factor Width Minimum (MM)',
  SawCutGangingFactorWidthMinimumIN = '[Saw Cut] Ganging Factor Width Minimum (IN)',
  SawCutHoursPerAreaSQMM = '[Saw Cut] Hours Per Area (SQMM)',
  SawCutHoursPerAreaSQIN = '[Saw Cut] Hours Per Area (SQIN)',
  SawCutSetupPerPart = '[Saw Cut] Setup Per Part',
  SpecificationTestingRange1CladWidthforAdditionMM = '[Specification Testing] Range 1 Clad Width for Addition (MM)',
  SpecificationTestingRange1CladWidthforAdditionIN = '[Specification Testing] Range 1 Clad Width for Addition (IN)',
  SpecificationTestingRange2CladWidthforAdditionMM = '[Specification Testing] Range 2 Clad Width for Addition (MM)',
  SpecificationTestingRange2CladWidthforAdditionIN = '[Specification Testing] Range 2 Clad Width for Addition (IN)',
  SpecificationAdderForSmallCirclesMM = '[Specification Testing] Specification Adder for Small Circles (MM)',
  SpecificationAdderForSmallCirclesIN = '[Specification Testing] Specification Adder for Small Circles (IN)',
  SpecificationSpecialTestingManHoursSQM = '[Specification] Special Testing Man Hours (SQM)',
  SpecificationSpecialTestingManHoursSQFT = '[Specification] Special Testing Man Hours (SQFT)',
  StandardSizeMaximumLengthIN = '[Standard Size] Maximum Length (IN)',
  StandardSizeMaximumLengthMM = '[Standard Size] Maximum Length (MM)',
  StandardSizeMaximumWidthIN = '[Standard Size] Maximum Width (IN)',
  StandardSizeMaximumWidthMM = '[Standard Size] Maximum Width (MM)',
  TemperatureRequiredForCladding = '[OER Preheat] Temperature Required for Cladding',
  TemperatureRequiredForCutting = '[OER Preheat] Temperature Required for Cutting',
  TorchCutAlloySteelGroupHoursM = '[Torch Cut] Alloy Steel Group (Hours/Meter)',
  TorchCutAlloySteelGroupHoursFT = '[Torch Cut] Alloy Steel Group (Hours/Foot)',
  TorchCutCarbonSteelGroupHoursM = '[Torch Cut] Carbon Steel Group (Hours/Meter)',
  TorchCutCarbonSteelGroupHoursFT = '[Torch Cut] Carbon Steel Group (Hours/Foot)',
  TwoPieceHeadDiameterMM = '[Two Piece Head] Diameter Size for Sizing (MM)',
  TwoPieceHeadDiameterIN = '[Two Piece Head] Diameter Size for Sizing (IN)',
  WaterJetConstantAUsedInJBFormula = '[Water Jet] Constant A used in JB Formula',
  WaterJetConstantBUsedInJBFormula = '[Water Jet] Constant B used in JB Formula',
  WeldingLaborManHoursSQMM = '[Welding] Labor Man Hours (SQMM)',
  WeldingLaborManHoursSQIN = '[Welding] Labor Man Hours (SQIN)',
  WeldingMetalCostMultiplier = '[Welding] Metal Cost Multiplier',
  WeldingRadiography100PercentCostM = '[Welding] Radiography 100% Cost (Per Meter)',
  WeldingRadiography100PercentCostF = '[Welding] Radiography 100% Cost (Per Foot)',
  WeldingRadiographySpotCostPerClad = '[Welding] Radiography Spot Cost (Per Clad)',
  OERMSTOCladThicknessDeviationAllowanceIN = '[OER MSTO] Clad Thickness Deviation Allowance (IN)',
  OERMSTOCladThicknessDeviationAllowanceMM = '[OER MSTO] Clad Thickness Deviation Allowance (MM)',
  OverhangThinMM = '[Overhang] Thickness Value used in Thin Overhang (MM)',
  OverhangThinIN = '[Overhang] Thickness Value used in Thin Overhang (IN)',

  PreHeatReqMinThicknessMM = '[Pre Heat Required] Minimum Thickness (MM)',
  PreHeatReqMinThicknessIN = '[Pre Heat Required] Minimum Thickness (IN)',
  PreHeatReqMinTempC = '[Pre Heat Required] Minimum Temp (C)',
  PreHeatReqMinTempF = '[Pre Heat Required] Minimum Temp (F)',
}

export enum SelectionTypes {
  TextInput = 'TextInput',
  RadioButtons = 'RadioButtons',
  DropDown = 'DropDown',
  SearchableDropdown = 'SearchableDropDown',
  CheckboxList = 'CheckboxList',
  TextArea = 'TextArea',
  TextList = 'TextList',
  Date = 'Date',
  ReadOnly = 'ReadOnly',
}

export enum QuoteItemSelectionTabs {
  FinishedGoods = 'Finished Goods',
  Sizing = 'Sizing',
  Cost = 'Cost',
  OSCost = 'OS Cost',
  Price = 'Price',
}

export enum GenerateBomTabs {
  BaseMetal = 'Base Metal',
  CladMetal = 'Clad Metal'
}

export enum QuoteAddItemSelectionTabs {
  Description = 'Description',
  Specifications = 'Specifications',
}

export enum QuoteTermsSelectionTabs {
  Shipping = 'Shipping',
  CommercialTerms = 'Commercial Terms',
}

export enum MSTOSelectionTabs {
  Cladder = 'Cladder',
  Backer = 'Backer',
  Anvil = 'Anvil',
  Assemblies = 'Assemblies',
}

export enum AdvancedSpecHeaderTabs {
  BaseMetal = 'Base Metal',
  CladMetal = 'Cladding Metal',
  CladTesting = 'Clad Product Testing',
  ExceptionsClarifications = 'Exceptions & Clarifications',
  Prewelding = 'Prewelding',
}

export enum OrderEntryHeaderTabs {
  Exceptions = 'Exceptions',
  InspectionsAndWitnessing = 'Inspections and Witnessing',
  JobSpecifications = 'Job Specifications',
  OrderNotes = 'Order Notes',
  ProcedureApprovals = 'Procedure Approvals',
  Shipping = 'Shipping',
}

export enum AssemblyGroupsHeaderTabs {
  AdvancedSpec = 'Advanced Spec',
  AssemblyGroup = 'Assembly Group',
  CanRolling = 'Cylinder + Can Rolling',
  Freight = 'Freight',
  HeadForming = 'Head Forming',
  OutsideServices = 'Outside Services',
  PostCladProcessing = 'Post Clad Processing',
}

export enum OutsideServices {
  BackerMetalHeatTreatment = 'Backer Metal Heat Treatment',
  CanRolling = 'Can Rolling',
  CladderMetalHeatTreatment = 'Cladder Metal Heat Treatment',
  Cutting = 'Cutting',
  FlatteningAfterCutting = 'Flattening (After Cutting)',
  FlatteningBeforeCutting = 'Flattening (Before Cutting)',
  InspectionBacker = 'Inspection - Backer',
  InspectionCladder = 'Inspection - Cladder',
  InspectionCladProduct = 'Inspection - Clad Product',
  Machining = 'Machining',
  OtherMetalEvent = 'Other Metal Event',
  Packaging = 'Packaging',
  PostbondHeatTreatment = 'Postbond Heat Treatment',
  TestingServicesBacker = 'Testing Services - Backer',
  TestingServicesCladder = 'Testing Services - Cladder',
  TestingServicesCladProduct = 'Testing Services - Clad Product',
  WaterjetCutting = 'Waterjet Cutting',
  Welding = 'Welding',
  XRayServices = 'X-Ray Services',
}

export enum VendorServices {
  RawMaterialsCladder = 'Raw Materials - Cladder',
  RawMaterialsBacker = 'Raw Materials - Backer',
  RawMaterialsAnvil = 'Raw Materials - Anvil',
}

export enum RawMaterialUsage {
  Base = 'Base',
  Clad = 'Clad',
}

export enum MetalTypesForAreaCalc {
  Base = 'Base',
  Clad = 'Clad',
  Anvil = 'Anvil',
  Flyer = 'Flyer',
}

export enum Shapes {
  Circle = 'Circle',
  Rectangle = 'Rectangle',
}

export enum CommercialTermsTypes {
  Custom = 'Custom',
  Standard = 'Standard',
}

export enum SearchFields {
  AccountName = 'accountName',
  AXOrderNumber = 'AX_Order_Number__c',
  InvoiceName = 'Name',
  PoNumber = 'PO_Number__c',
  VendorName = 'vendorName',
  MetalName = 'name',
  Label = 'label',
  Value = 'value',
}

export enum Units {
  Inches = 'Inches',
  Millimeters = 'Millimeters',
  Pounds = 'Pounds',
  Kilograms = 'Kilograms',
  SquareFeet = 'SquareFeet',
  SquareMeters = 'SquareMeters',
  Feet = 'Feet',
  Meters = 'Meters',
  PricePerPound = 'PricePerPound',
  PricePerKilogram = 'PricePerKilogram',
  HoursPerMeter = 'HoursPerMeter',
  HoursPerMillimeter = 'HoursPerMillimeter',
  HoursPerFoot = 'HoursPerFoot',
  HoursPerInch = 'HoursPerInch',
  Fahrenheit = 'Fahrenheit',
  Celsius = 'Celsius',
}

export enum QuoteLineItemFields {
  AdditionalHeadInformation = 'AdditionalHeadInformation__c',
  AdditionalOptions = 'Additional_Options__c',
  AdditionalRequirementsCladProductTesting = 'AdditionalRequirementsCladProductTesting__c',
  AdditionalRequirementsBaseMetal = 'AdditionalRequirementsBaseMetal__c',
  AdditionalRequirementsCladdingMetal = 'AdditionalRequirementsCladdingMetal__c',
  AdditionalRequirementsPrewelding = 'AdditionalRequirementsPrewelding__c',
  AdjPrice = 'Adj_Price__c',
  AdvancedSpecUOM = 'AdvancedSpecUOM__c',
  ADW8Bend = 'ADW8Bend__c',
  Anvil = 'Anvil__c',
  AnvilAreaIn = 'Anvil_Area_in__c',
  AnvilAreaMm = 'Anvil_Area_mm__c',
  AnvilDaysForTransport = 'AnvilDaysForTransport__c',
  AnvilEdgeAllowanceAdderIn = 'Anvil_Edge_Allowance_Adder_IN__c',
  AnvilEdgeAllowanceAdderMm = 'Anvil_Edge_Allowance_Adder_MM__c',
  AnvilJSON = 'AnvilJSON__c',
  AnvilLengthIn = 'Anvil_Length_in__c',
  AnvilLengthMm = 'Anvil_Length_mm__c',
  AnvilNeeded = 'Anvil_Needed__c',
  AnvilTKIn = 'Anvil_TK_in__c',
  AnvilTKMm = 'Anvil_TK_mm__c',
  AnvilWidthIn = 'Anvil_Width_in__c',
  AnvilWidthMm = 'Anvil_Width_mm__c',
  QuantityAnvil = 'QuantityAnvil__c',
  QuantityFlyer = 'QuantityFlyer__c',
  AreaSqft = 'Area_Sqft__c',
  AreaSqm = 'Area_Sqm__c',
  AreaBackerIn = 'AreaBacker_in__c',
  AreaBackerMm = 'AreaBacker_mm__c',
  AreaCladderIn = 'AreaCladder_in__c',
  AreaCladderMm = 'AreaCladder_mm__c',
  AssembliesJSON = 'AssembliesJSON__c',
  ASLength = 'AS_Length__c',
  ASWidth = 'AS_Width__c',
  ASUnitOfMeasure = 'AS_UnitofMeasure__c',
  AusteniticGrainSizeFinerThanValue = 'AusteniticGrainSizeFinerThanValue__c',
  BackerDaysForTransport = 'BackerDaysForTransport__c',
  BackerJSON = 'BackerJSON__c',
  BaseCost = 'Base_Cost__c',
  BaseCostCustomerSupplied = 'Base_Cost_Customer_Supplied__c',
  BaseFreight = 'Base_Freight__c',
  BaseFreightKG = 'Base_Freight_KG__c',
  BaseFreightLB = 'Base_Freight_LB__c',
  BaseFreightCustomerSupplied = 'Base_Freight_Customer_Supplied__c',
  BaseKgPc = 'Base_KG_PC__c',
  BaseLbPc = 'Base_LB_PC__c',
  BaseLengthIn = 'Base_Length_in__c',
  BaseLengthMm = 'Base_Length_mm__c',
  BaseMetal = 'BaseMetal__c',
  BaseMetalLengthAdderIn = 'Base_Metal_Length_Adder_IN__c',
  BaseMetalLengthAdderMm = 'Base_Metal_Length_Adder_MM__c',
  BaseMetalProductAnalysisRequired = 'BaseMetalProductAnalysisRequired__c',
  BaseMetalProductAnalysisRequiredNotes = 'BaseMetalProductAnalysisRequiredNotes__c',
  BaseMetalProductAnalysisType = 'BaseMetalProductAnalysisType__c',
  BaseMetalSpecAddendum = 'BaseMetalSpecAddendum__c',
  BaseMetalSpecEdition = 'BaseMetalSpecEdition__c',
  BaseMetalUT = 'Base_Metal_UT__c',
  BaseMinIn = 'Base_Min_in__c',
  BaseMinMm = 'Base_Min_mm__c',
  BaseNomIn = 'Base_Nom_in__c',
  BaseNomMm = 'Base_Nom_mm__c',
  BasePriceKG = 'Base_Price_KG__c',
  BasePriceLB = 'Base_Price_LB__c',
  BaseTensileTestLocation = 'BaseTensileTestLocation__c',
  BaseTKIn = 'Base_TK_in__c',
  BaseTKMm = 'Base_TK_mm__c',
  BaseType = 'Base_Type__c',
  BaseWeightKg = 'Base_Weight_Kg__c',
  BaseWeightLbs = 'Base_Weight_Lbs__c',
  BaseWidthIn = 'Base_Width_in__c',
  BaseWidthMm = 'Base_Width_mm__c',
  BendB898 = 'BendB898__c',
  BlankCrownCutByNC = 'BlankCrownCutByNC__c',
  BlankSize = 'BlankSize__c',
  BondMetalUT = 'Bond_Metal_UT__c',
  BondStrengthTensileTestRamUOM = 'BondStrengthTensileTestRamUOM__c',
  BondStrengthTensileTestRamValue = 'BondStrengthTensileTestRamValue__c',
  BoosterAllowanceIn = 'Booster_Allowance_IN__c',
  BoosterAllowanceMm = 'Booster_Allowance_MM__c',
  CalciumTreatedSulfideShapeControl = 'CalciumTreatedSulfideShapeControl__c',
  CarbonEquivalencyPercent = 'Carbon_Equivalency_Percent__c',
  CarbonPercent = 'Carbon_Percent__c',
  Certificate = 'Certificate__c',
  CertificateCost = 'CertificateCost__c',
  CertificationTypeRequired = 'CertificationTypeRequired__c',
  Clads = 'Clads__c',
  CladCost = 'Clad_Cost__c',
  CladCostCustomerSupplied = 'Clad_Cost_Customer_Supplied__c',
  CladderDaysForTransport = 'CladderDaysForTransport__c',
  CladderJSON = 'CladderJSON__c',
  CladdingForCorrosionAllowanceOnly = 'CladdingForCorrosionAllowanceOnly__c',
  CladFreight = 'Clad_Freight__c',
  CladFreightKG = 'Clad_Freight_KG__c',
  CladFreightLB = 'Clad_Freight_LB__c',
  CladFreightCustomerSupplied = 'Clad_Freight_Customer_Supplied__c',
  CladKgPc = 'Clad_KG_PC__c',
  CladLbPc = 'Clad_LB_PC__c',
  CladLengthIn = 'Clad_Length_in__c',
  CladLengthMm = 'Clad_Length_mm__c',
  CladMetal = 'CladMetal__c',
  CladMetalProductAnalysisRequired = 'CladMetalProductAnalysisRequired__c',
  CladMetalProductAnalysisRequiredNotes = 'CladMetalProductAnalysisRequiredNotes__c',
  CladMetalProductAnalysisType = 'CladMetalProductAnalysisType__c',
  CladMetalSpecAddendum = 'CladMetalSpecAddendum__c',
  CladMetalSpecEdition = 'CladMetalSpecEdition__c',
  CladMinIn = 'Clad_Min_in__c',
  CladMinMm = 'Clad_Min_mm__c',
  CladNomIn = 'Clad_Nom_in__c',
  CladNomMm = 'Clad_Nom_mm__c',
  CladOverhangIN = 'Clad_Overhang_IN__c',
  CladOverhangMM = 'Clad_Overhang_MM__c',
  CladOverhangThinIn = 'Clad_Overhang_Thin_IN__c',
  CladOverhangThinMm = 'Clad_Overhang_Thin_MM__c',
  CladPriceKG = 'Clad_Price_KG__c',
  CladPriceLB = 'Clad_Price_LB__c',
  CladProductionSpecificationNotes = 'CladProductionSpecificationNotes__c',
  CladProductSpecification = 'CladProductSpecification__c',
  CladTensileTestLocation = 'CladTensileTestLocation__c',
  CladTKIn = 'Clad_TK_in__c',
  CladTKMm = 'Clad_TK_mm__c',
  CladType = 'Clad_Type__c',
  CladWeightKg = 'Clad_Weight_Kg__c',
  CladWeightLbs = 'Clad_Weight_Lbs__c',
  CladWidthIn = 'Clad_Width_in__c',
  CladWidthMm = 'Clad_Width_mm__c',
  ConfigurationID = 'ConfigurationID__c',
  ConfigurationIDAnvil = 'ConfigurationIDAnvil__c',
  ConfigurationIDBacker = 'ConfigurationIDBacker__c',
  ConsumableCost = 'Consumable_Cost__c',
  Contribution = 'Contribution__c',
  ContributionPercent = 'ContributionPercent__c',
  ContSQM = 'Cont_SQM__c',
  CorrosionTesting = 'CorrosionTesting__c',
  CorrosionTestingNotes = 'CorrosionTestingNotes__c',
  CosmeticGrindingSpecifications = 'Cosmetic_Grinding_Specifications__c',
  CosmeticPrepCost = 'CosmeticPrepCost__c',
  CreateTwoPieceHead = 'CreateTwoPieceHead__c',
  CrownToPetals = 'CrownToPetals__c',
  CrownToPetalsNotes = 'CrownToPetalsNotes__c',
  CustBaseMetalSupplierRestrictionsNotes = 'CustBaseMetalSupplierRestrictionsNotes__c',
  CustCladMetalSupplierRestrictionsNotes = 'CustCladMetalSupplierRestrictionsNotes__c',
  CustomerBaseChemistryRestrictions = 'CustomerBaseChemistryRestrictions__c',
  CustomerBaseMetalSupplierRestrictions = 'CustomerBaseMetalSupplierRestrictions__c',
  CustomerCladChemistryRestrictionsNotes = 'CustomerCladChemistryRestrictionsNotes__c',
  CustomerCladMetalSupplierRestrictions = 'CustomerCladMetalSupplierRestrictions__c',
  CustomerPO = 'CustomerPO__c',
  CustomerProvidedBase = 'Customer_Provided_Base__c',
  CustomerProvidedClad = 'Customer_Provided_Clad__c',
  CustomerSpecifiedImpactTesting = 'CustomerSpecifiedImpactTesting__c',
  CustomerSpecifiedImpactTestToBePerformed = 'CustomerSpecifiedImpactTestToBePerformed__c',
  CustomerSpecifiedImpactTestLocation = 'CustomerSpecifiedImpactTestLocation__c',
  CustomerSpecifiedImpactTestDirection = 'CustomerSpecifiedImpactTestDirection__c',
  CustomerSpecifiedImpactTestUOM = 'CustomerSpecifiedImpactTestUOM__c',
  CustomerSpecifiedImpactTestAVG = 'CustomerSpecifiedImpactTestAVG__c',
  CustomerSpecifiedImpactTestMIN = 'CustomerSpecifiedImpactTestMIN__c',
  CustomerSpecifiedImpactTestTemperature = 'CustomerSpecifiedImpactTestTemperature__c',
  CustomerSpecifiedImpactTestTempUOM = 'CustomerSpecifiedImpactTestTempUOM__c',
  CustomerSpecifiedSPWHTTestCoupons = 'CustomerSpecifiedSPWHTTestCoupons__c',
  Cutback = 'Cutback__c',
  CutMethod = 'Cut_Method__c',
  CutMethodCost = 'Cut_Method_Cost__c',
  CutMethodLaborHours = 'Cut_Method_Labor_hrs__c',
  CuttingNotes = 'CuttingNotes__c',
  CylinderNotes = 'CylinderNotes__c',
  CylinderType = 'CylinderType__c',
  CylinderVendor = 'CylinderVendor__c',
  Diameter = 'Diameter__c',
  DimensionsIn = 'Dimensions_in__c',
  DimensionsMm = 'Dimensions_mm__c',
  DNC = 'DNC__c',
  DyePenetrantNotes = 'DyePenetrantNotes__c',
  EdgeAllowanceIn = 'Edge_Allowance_IN__c',
  EdgeAllowanceMm = 'Edge_Allowance_MM__c',
  EdgePreparation = 'EdgePreparation__c',
  ExceptionsAndClarifications = 'Exceptions_and_Clarifications__c',
  ExplosiveLoadCost = 'Explosive_Load_Cost__c',
  ExplosiveLoadKg = 'Explosive_Load_kg_sq_m__c',
  ExplosiveLoadLb = 'Explosive_Load_lb_sq_ft__c',
  ExportPackagingCost = 'ExportPackagingCost__c',
  FerriteNumberRequired = 'FerriteNumberRequired__c',
  FerroxylNotes = 'FerroxylNotes__c',
  FerroxylTestPerformed = 'FerroxylTestPerformed__c',
  Finalinspectionofcladdisk = 'Finalinspectionofcladdisk__c',
  FinalinspectionofcladdiskwithSeam = 'FinalinspectionofcladdiskwithSeam__c',
  Finalinspectionofrectangularclad = 'Finalinspectionofrectangularclad__c',
  FinalinspectionofrectangularcladwithSeam = 'FinalinspectionofrectangularcladwithSeam__c',
  FinishedGoodHeatTreatment = 'FinishedGoodHeatTreatment__c',
  FinishedGoodHeatTreatmentNotes = 'FinishedGoodHeatTreatmentNotes__c',
  FinishProductWeightKg = 'Finish_Product_Weight_Kg__c',
  FinishProductWeightLbs = 'Finish_Product_Weight_Lbs__c',
  FlatteningLaborCost = 'FlatteningLaborCost__c',
  FlatteningLaborHours = 'FlatteningLaborHours__c',
  FlyerAreaIn = 'Flyer_Area_in__c',
  FlyerAreaMm = 'Flyer_Area_mm__c',
  FlyerLengthIn = 'Flyer_Length_in__c',
  FlyerLengthMm = 'Flyer_Length_mm__c',
  FlyerNeeded = 'Flyer_Needed__c',
  FlyerTKIN = 'Flyer_TK_IN__c',
  FlyerTKMM = 'Flyer_TK_MM__c',
  FlyerWidthIn = 'Flyer_Width_in__c',
  FlyerWidthMm = 'Flyer_Width_mm__c',
  FreightChargeToCustomerPer = 'FreightChargeToCustomerPer__c',
  FreightChargeToRollerPer = 'FreightChargeToRollerPer__c',
  FreightOut = 'Freight_Out__c',
  GeneralLaborCost = 'GeneralLaborCost__c',
  HardnessBaseMetalSurface = 'HardnessBaseMetalSurface__c',
  HardnessBaseRejectionCriteria = 'HardnessBaseRejectionCriteria__c',
  HardnessCladdingMetalSurface = 'HardnessCladdingMetalSurface__c',
  HardnessCladdingRejectionCriteria = 'HardnessCladdingRejectionCriteria__c',
  HeadDiameter = 'HeadDiameter__c',
  HeadEdgePreparation = 'HeadEdgePreparation__c',
  HeadForming = 'Head_Forming__c',
  HeadFormingType = 'HeadFormingType__c',
  HeadFreight = 'Head_Freight__c',
  HeadFreightLB = 'Head_Freight_LB__c',
  HeadFreightKG = 'Head_Freight_KG__c',
  HeadNumberOfPieces = 'HeadNumberOfPieces__c',
  HeadMaxCenterHole = 'HeadMaxCenterHole__c',
  HeadQuantity = 'HeadQuantity__c',
  HeatTreatmentConsumableCost = 'Heat_Treatment_Consumable_Cost__c',
  HeatTreatmentCost = 'Heat_Treatment_Cost__c',
  HicTestNotes = 'HicTestNotes__c',
  HotTensileTestPerSA20S7Degrees = 'HotTensileTestPerSA20S7Degrees__c',
  HotTensileTestPerSA20S7Notes = 'HotTensileTestPerSA20S7Notes__c',
  HotTensileTestPerSA20S7UOM = 'HotTensileTestPerSA20S7UOM__c',
  IDorOD = 'IDorOD__c',
  InsideDishRadius = 'InsideDishRadius__c',
  InsideKnuckleRadius = 'InsideKnuckleRadius__c',
  IsWeldRepairAllowed = 'IsWeldRepairAllowed__c',
  Item = 'Item__c',
  ItemDescriptionImp = 'ItemDescriptionImp__c',
  ItemDescriptionMetric = 'ItemDescriptionMetric__c',
  ItemDescriptionBackerim = 'ItemDescriptionBackerim__c',
  ItemDescriptionBackermm = 'ItemDescriptionBackermm__c',
  ItemDescriptionCladderim = 'ItemDescriptionCladderim__c',
  ItemDescriptionCladdermm = 'ItemDescriptionCladdermm__c',
  ItemDescriptionAnvilim = 'ItemDescriptionAnvilim__c',
  ItemDescriptionAnvilmm = 'ItemDescriptionAnvilmm__c',
  ItemType = 'Item_Type__c',
  JFactorSA387S62 = 'JFactorSA387S62__c',
  KerfAllowanceIn = 'Kerf_Allowance_IN__c',
  KerfAllowanceMm = 'Kerf_Allowance_MM__c',
  LeadTime = 'Lead_Time__c',
  LeakTest = 'LeakTest__c',
  LeakTestGas = 'LeakTestGas__c',
  LeakTestReqt = 'LeakTestReqt__c',
  LeakTestValue = 'LeakTestValue__c',
  LeakTestPressure = 'LeakTestPressure__c',
  LengthIn = 'Length_in__c',
  LengthMm = 'Length_mm__c',
  LineItem = 'Line_Item__c',
  Long = 'Long__c',
  MachineBondStrengthBendsperSA26345 = 'MachineBondStrengthBendsperSA26345__c',
  MachineSideBends = 'MachineSideBends__c',
  MetalClassAnvil = 'MetalClassAnvil__c',
  MetalClassBacker = 'MetalClassBacker__c',
  MetalClassCladder = 'MetalClassCladder__c',
  MetalClassFlyer = 'MetalClassFlyer__c',
  NCBaseCertificateRequired = 'NCBaseCertificateRequired__c',
  NCBaseChemistryRestForBondingNotes = 'NCBaseChemistryRestForBondingNotes__c',
  NCBaseChemistryRestrictionsforBonding = 'NCBaseChemistryRestrictionsforBonding__c',
  NCBaseHeatTreatmentasSupplied = 'NCBaseHeatTreatmentasSupplied__c',
  NCBaseHeatTreatmentasSuppliedNotes = 'NCBaseHeatTreatmentasSuppliedNotes__c',
  NCBaseIsWeldRepairAllowed = 'NCBaseIsWeldRepairAllowed__c',
  NCBaseMarkingRequired = 'NCBaseMarkingRequired__c',
  NCBaseSimulatedHeatTreatment = 'NCBaseSimulatedHeatTreatment__c',
  NCBaseTestingRequirements = 'NCBaseTestingRequirements__c',
  NCBaseUTRequirement = 'NCBaseUTRequirement__c',
  NCChemistryRestrictionsForBonding = 'NCChemistryRestrictionsForBonding__c',
  NCChemistryRestrictionsForBondingNotes = 'NCChemistryRestrictionsForBondingNotes__c',
  NCCladCamberRequirement = 'NCCladCamberRequirement__c',
  NCCladCertificateRequired = 'NCCladCertificateRequired__c',
  NCCladChemistryRestForBondingNotes = 'NCCladChemistryRestForBondingNotes__c',
  NCCladChemistryRestrictionsforBonding = 'NCCladChemistryRestrictionsforBonding__c',
  NCCladFlatnessRequirement = 'NCCladFlatnessRequirement__c',
  NCCladHeatTreatmentasSupplied = 'NCCladHeatTreatmentasSupplied__c',
  NCCladHeatTreatmentasSuppliedNotes = 'NCCladHeatTreatmentasSuppliedNotes__c',
  NCCladIsWeldRepairAllowed = 'NCCladIsWeldRepairAllowed__c',
  NCCladMarkingRequired = 'NCCladMarkingRequired__c',
  NCEdgeSurfaceRequirement = 'NCEdgeSurfaceRequirement__c',
  NCMetallurgicReqForBase = 'NCMetallurgicReqForBase__c',
  NobelCladProcedure = 'NobelCladProcedure__c',
  NumberOfPiecesInHead = 'NumberOfPiecesInHead__c',
  OSCost = 'OS_Cost__c',
  OpenEnd = 'OpenEnd__c',
  OpenEndDiameter = 'OpenEndDiameter__c',
  OpenEndNotes = 'OpenEndNotes__c',
  OtherCladGroupsCombinedInThisHead = 'OtherCladGroupsCombinedInThisHead__c',
  ParentLine = 'Parent_Line__c',
  PeggedSupply = 'PeggedSupply__c',
  PetalsCutByNC = 'PetalsCutByNC__c',
  PetalToPetal = 'PetalToPetal__c',
  PetalToPetalNotes = 'PetalToPetalNotes__c',
  PhosphorusPercent = 'Phosphorus_Percent__c',
  Pieces = 'Pieces__c',
  PlateLengthSupplyToFormer = 'PlateLengthSupplyToFormer__c',
  PlateMethod = 'PlateMethod__c',
  PlateWidthSupplyToFormer = 'PlateWidthSupplyToFormer__c',
  PMIConfirmBaseMetal = 'PMIConfirmBaseMetal__c',
  PMIConfirmBaseMetalNotes = 'PMIConfirmBaseMetalNotes__c',
  PMIConfirmCladdingMetal = 'PMIConfirmCladdingMetal__c',
  PMIConfirmCladdingMetalNotes = 'PMIConfirmCladdingMetalNotes__c',
  PMIofWeldWire = 'PMIofWeldWire__c',
  PMIPreweldsRequired = 'PMIPreweldsRequired__c',
  PostCladBaseMetalUTSpecification = 'PostCladBaseMetalUTSpecification__c',
  PostCladBaseMetalUTSpecificationNotes = 'PostCladBaseMetalUTSpecificationNotes__c',
  PostCladBondUTSpecification = 'PostCladBondUTSpecification__c',
  PostCladBondUTSpecificationNotes = 'PostCladBondUTSpecificationNotes__c',
  PostCladTestingOptions = 'Post_Clad_Testing_Options__c',
  PrebondImpacts = 'PrebondImpacts__c',
  PreBondImpactsTestLocation = 'PreBondImpactsTestLocation__c',
  PreBondImpactsTestDirection = 'PreBondImpactsTestDirection__c',
  PreBondImpactsTestUOM = 'PreBondImpactsTestUOM__c',
  PreBondImpactsTestAVG = 'PreBondImpactsTestAVG__c',
  PreBondImpactsTestMIN = 'PreBondImpactsTestMIN__c',
  PreBondImpactsTestTemperature = 'PreBondImpactsTestTemperature__c',
  PreBondImpactsTestTemperatureUOM = 'PreBondImpactsTestTemperatureUOM__c',
  PrecladBaseMetalUT = 'PrecladBaseMetalUT__c',
  PrecladBaseMetalUTNotes = 'PrecladBaseMetalUTNotes__c',
  PrecladBaseMetalUTType = 'PrecladBaseMetalUTType__c',
  PreheattoTorchCutBacker = 'PreheattoTorchCutBacker__c',
  PreheattoTorchCutCladder = 'PreheattoTorchCutCladder__c',
  Pressflatten = 'Pressflatten_1_c__c',
  Presstubesheet = 'Presstubesheet__c',
  PriceHead = 'Price_Head__c',
  PriceKG = 'Price_KG__c',
  PriceLB = 'Price_LB__c',
  Product = 'Product__c',
  ProductTagging = 'ProductTagging__c',
  ProductTag = 'ProductTag__c',
  ProposalTypeBase = 'Proposal_Type_Base__c',
  ProposalTypeClad = 'Proposal_Type_Clad__c',
  Pullfromstockbacker = 'Pullfromstockbacker__c',
  PullfromstockBKAnvil = 'PullfromstockBKAnvil__c',
  Pullfromstockcladder = 'Pullfromstockcladder__c',
  PurchaseAnvilFreightKG = 'PurchaseAnvilFreight_KG__c',
  PurchaseAnvilFreightLB = 'PurchaseAnvilFreight_LB__c',
  PurchaseAnvilLength = 'PurchaseAnvilLength__c',
  PurchaseAnvilPrice = 'PurchaseAnvilPrice__c',
  PurchaseAnvilWeightKG = 'PurchaseAnvilWeight_KG__c',
  PurchaseAnvilWeightLB = 'PurchaseAnvilWeight_LB__c',
  PurchaseAnvilWidth = 'PurchaseAnvilWidth__c',
  PurchaseAnvilTK = 'PurchaseAnvilTK__c',
  PurchaseBackerFreightKG = 'PurchaseBackerFreight_KG__c',
  PurchaseBackerFreightLB = 'PurchaseBackerFreight_LB__c',
  PurchaseBackerPrice = 'PurchaseBackerPrice__c',
  PurchaseBackerWeightKG = 'PurchaseBackerWeight_KG__c',
  PurchaseBackerWeightLB = 'PurchaseBackerWeight_LB__c',
  PurchaseBaseLength = 'PurchaseBaseLength__c',
  PurchaseBaseWidth = 'PurchaseBaseWidth__c',
  PurchaseBaseTK = 'PurchaseBaseTK__c',
  PurchaseBK = 'PurchaseBK__c',
  PurchaseBKAnvil = 'PurchaseBKAnvil__c',
  PurchaseCL = 'PurchaseCL__c',
  PurchaseCladderFreightKG = 'PurchaseCladderFreight_KG__c',
  PurchaseCladderFreightLB = 'PurchaseCladderFreight_LB__c',
  PurchaseCladderPrice = 'PurchaseCladderPrice__c',
  PurchaseCladderWeightKG = 'PurchaseCladderWeight_KG__c',
  PurchaseCladderWeightLB = 'PurchaseCladderWeight_LB__c',
  PurchaseCladLength = 'PurchaseCladLength__c',
  PurchaseCladWidth = 'PurchaseCladWidth__c',
  PurchaseCladTK = 'PurchaseCladTK__c',
  VendorAnvil = 'VendorAnvil__c',
  RawMaterialVendorBacker = 'RawMaterialVendorBacker__c',
  RawMaterialVendorCladder = 'RawMaterialVendorCladder__c',
  Quantity = 'Quantity__c',
  QuantityPerCylinder = 'QuantityPerCylinder__c',
  Radiography = 'Radiography__c',
  RadiographyClad = 'Radiography_Clad__c',
  RawBaseWeightKg = 'Raw_Base_Weight_Kg__c',
  RawBaseWeightLbs = 'Raw_Base_Weight_Lbs__c',
  RawCladWeightKg = 'Raw_Clad_Weight_Kg__c',
  RawCladWeightLbs = 'Raw_Clad_Weight_Lbs__c',
  RollingCostPerCylinder = 'RollingCostPerCylinder__c',
  SeamWeld = 'SeamWeld__c',
  Shape = 'Shape__c',
  SharedCladdedPlate = 'SharedCladdedPlate__c',
  ShipDate = 'ShipDate__c',
  ShearTestUOM = 'ShearTestUOM__c',
  ShearTestValue = 'ShearTestValue__c',
  ShipWeightKg = 'Ship_Weight_KG__c',
  ShipWeightLb = 'Ship_Weight_LB__c',
  ShowDetail = 'Show_Detail__c',
  ShipDateAnvil = 'ShipDateAnvil__c',
  ShipDateBase = 'ShipDateBase__c',
  ShipDateCladder = 'ShipDateCladder__c',
  ShippingStreet = 'Shipping_Street__c',
  ShippingCity = 'Shipping_City__c',
  ShippingState = 'Shipping_State__c',
  ShippingPostalCode = 'Shipping_Postal_Code__c',
  ShippingCountry = 'Shipping_Country__c',
  ShipVia = 'ShipVia__c',
  ShipViaDetails = 'ShipViaDetails__c',
  Specification = 'Specification__c',
  SpecificationAdderIn = 'Specification_Adder_IN__c',
  SpecificationAdderMm = 'Specification_Adder_MM__c',
  SpecificationTestCost = 'Specification_Test_Cost__c',
  SPWHTGroupACycle1 = 'SPWHTGroupACycle1__c',
  SPWHTGroupACycle2 = 'SPWHTGroupACycle2__c',
  SPWHTGroupACycle3 = 'SPWHTGroupACycle3__c',
  SPWHTGroupAToBePerformed = 'SPWHTGroupAToBePerformed__c',
  SPWHTGroupBCycle1 = 'SPWHTGroupBCycle1__c',
  SPWHTGroupBCycle2 = 'SPWHTGroupBCycle2__c',
  SPWHTGroupBCycle3 = 'SPWHTGroupBCycle3__c',
  SPWHTGroupBToBePerformed = 'SPWHTGroupBToBePerformed__c',
  SPWHTGroupCCycle1 = 'SPWHTGroupCCycle1__c',
  SPWHTGroupCCycle2 = 'SPWHTGroupCCycle2__c',
  SPWHTGroupCCycle3 = 'SPWHTGroupCCycle3__c',
  SPWHTGroupCToBePerformed = 'SPWHTGroupCToBePerformed__c',
  SPWHTHeatingAndCoolingRateAbove = 'SPWHTHeatingAndCoolingRateAbove__c',
  SPWHTHeatingAndCoolingRateAboveUOM = 'SPWHTHeatingAndCoolingRateAboveUOM__c',
  SPWHTMaximumCoolingRateHour = 'SPWHTMaximumCoolingRateHour__c',
  SPWHTMaximumCoolingRateHourUOM = 'SPWHTMaximumCoolingRateHourUOM__c',
  SPWHTMaximumHeatingRateHour = 'SPWHTMaximumHeatingRateHour__c',
  SPWHTMaximumHeatingRateHourUOM = 'SPWHTMaximumHeatingRateHourUOM__c',
  Sulphur = 'Sulphur__c',
  StraightFlange = 'StraightFlange__c',
  ThroughThicknessTensilePerSA770 = 'ThroughThicknessTensilePerSA770__c',
  TimeToRollWeeks = 'TimeToRollWeeks__c',
  TotalCuttingCost = 'Total_Cutting_Cost__c',
  TotalAdjPrice = 'TotalAdjPrice__c',
  TotalFreightKG = 'Total_Freight_KG__c',
  TotalFreightLB = 'Total_Freight_LB__c',
  TotalKgPc = 'Total_KG_PC__c',
  TotalLbPc = 'Total_LB_PC__c',
  TotalTkIn = 'Total_TK_IN__c',
  TotalTkMm = 'Total_TK_MM__c',
  TotalCost = 'TotalCost__c',
  TotalLaborCost = 'TotalLaborCost__c',
  TotalManHours = 'TotalManHours__c',
  TransitTimeToRollerWeeks = 'TransitTimeToRollerWeeks__c',
  TubesheetMachining = 'TubesheetMachining__c',
  UnitOfMeasure = 'Unit_of_Measure__c',
  VacuumDegassed = 'VacuumDegassed__c',
  WeightKg = 'Weight_KG__c',
  WeightLb = 'Weight_LB__c',
  Welding = 'Welding__c',
  WeldingConsumableCost = 'Welding_Consumable_Cost__c',
  WeldingLengthIn = 'Welding_Length_in__c',
  WeldingLengthMm = 'Welding_Length_mm__c',
  Wide = 'Wide__c',
  WidthIn = 'Width_in__c',
  WidthMm = 'Width_mm__c',
  XFactorSA387S62 = 'XFactorSA387S62__c',
  BackerMetalHTCostOfEvent = 'BackerMetalHTCostOfEvent__c',
  BackerMetalHTDescriptionOfEvent = 'BackerMetalHTDescriptionOfEvent__c',
  BackerMetalHTDurationOfEventDays = 'BackerMetalHTDurationOfEventDays__c',
  BackerMetalHTInboundShippingCost = 'BackerMetalHTInboundShippingCost__c',
  BackerMetalHTLeadTimeWeeks = 'BackerMetalHTLeadTimeWeeks__c',
  BackerMetalHTNotes = 'BackerMetalHTNotes__c',
  BackerMetalHTOutboundShipping = 'BackerMetalHTOutboundShipping__c',
  BackerMetalHTOutboundShippingCost = 'BackerMetalHTOutboundShippingCost__c',
  BackerMetalHTShipToCustomer = 'BackerMetalHTShipToCustomer__c',
  BackerMetalHTSort = 'BackerMetalHTSort__c',
  BackerMetalHTTotalCost = 'BackerMetalHTTotalCost__c',
  BackerMetalHTTotalCostOfEvent = 'BackerMetalHTTotalCostOfEvent__c',
  BackerMetalHTTotalInboundShippingCost = 'BackerMetalHTTotalInboundShippingCost__c',
  BackerMetalHTTotalOutboundShippingCost = 'BackerMetalHTTotalOutboundShippingCost__c',
  BackerMetalHTTotalTransitTimeDays = 'BackerMetalHTTotalTransitTimeDays__c',
  BackerMetalHTType = 'BackerMetalHTType__c',
  BackerMetalHTVendor = 'BackerMetalHTVendor__c',
  CanRollingCostOfEvent = 'CanRollingCostOfEvent__c',
  CanRollingDescriptionOfEvent = 'CanRollingDescriptionOfEvent__c',
  CanRollingDurationOfEventDays = 'CanRollingDurationOfEventDays__c',
  CanRollingInboundShippingCost = 'CanRollingInboundShippingCost__c',
  CanRollingLeadTimeWeeks = 'CanRollingLeadTimeWeeks__c',
  CanRollingNotes = 'CanRollingNotes__c',
  CanRollingOutboundShippingCost = 'CanRollingOutboundShippingCost__c',
  CanRollingShipToCustomer = 'CanRollingShipToCustomer__c',
  CanRollingSort = 'CanRollingSort__c',
  CanRollingTotalCost = 'CanRollingTotalCost__c',
  CanRollingTotalInboundShippingCost = 'CanRollingTotalInboundShippingCost__c',
  CanRollingTotalOutboundShippingCost = 'CanRollingTotalOutboundShippingCost__c',
  CanRollingTotalTransitTimeDays = 'CanRollingTotalTransitTimeDays__c',
  CanRollingVendor = 'CanRollingVendor__c',
  CladderMetalHTCostOfEvent = 'CladderMetalHTCostOfEvent__c',
  CladderMetalHTDescriptionOfEvent = 'CladderMetalHTDescriptionOfEvent__c',
  CladderMetalHTDurationOfEventDays = 'CladderMetalHTDurationOfEventDays__c',
  CladderMetalHTInboundShippingCost = 'CladderMetalHTInboundShippingCost__c',
  CladderMetalHTLeadTimeWeeks = 'CladderMetalHTLeadTimeWeeks__c',
  CladderMetalHTNotes = 'CladderMetalHTNotes__c',
  CladderMetalHTOutboundShippingCost = 'CladderMetalHTOutboundShippingCost__c',
  CladderMetalHTShipToCustomer = 'CladderMetalHTShipToCustomer__c',
  CladderMetalHTSort = 'CladderMetalHTSort__c',
  CladderMetalHTTotalCost = 'CladderMetalHTTotalCost__c',
  CladderMetalHTTotalCostOfEvent = 'CladderMetalHTTotalCostOfEvent__c',
  CladderMetalHTTotalInboundShippingCost = 'CladderMetalHTTotalInboundShippingCost__c',
  CladderMetalHTTotalOutboundShippingCost = 'CladderMetalHTTotalOutboundShippingCost__c',
  CladderMetalHTTotalTransitTimeDays = 'CladderMetalHTTotalTransitTimeDays__c',
  CladderMetalHTType = 'CladderMetalHTType__c',
  CladderMetalHTVendor = 'CladderMetalHTVendor__c',
  CostPerLB = 'CostPerLB__c',
  CostPerLot = 'CostPerLot__c',
  CreatedById = 'CreatedById',
  CurrencyIsoCode = 'CurrencyIsoCode',
  CutMethodHeadBlank = 'CutMethodHeadBlank__c',
  CuttingCostOfEvent = 'CuttingCostOfEvent__c',
  CuttingDescriptionOfEvent = 'CuttingDescriptionOfEvent__c',
  CuttingDurationOfEventDays = 'CuttingDurationOfEventDays__c',
  CuttingInboundShippingCost = 'CuttingInboundShippingCost__c',
  CuttingLeadTimeWeeks = 'CuttingLeadTimeWeeks__c',
  CuttingOutboundShippingCost = 'CuttingOutboundShippingCost__c',
  CuttingShipToCustomer = 'CuttingShipToCustomer__c',
  CuttingSort = 'CuttingSort__c',
  CuttingTotalCost = 'CuttingTotalCost__c',
  CuttingTotalInboundShippingCost = 'CuttingTotalInboundShippingCost__c',
  CuttingTotalOutboundShippingCost = 'CuttingTotalOutboundShippingCost__c',
  CuttingTotalTransitTimeDays = 'CuttingTotalTransitTimeDays__c',
  CuttingVendor = 'CuttingVendor__c',
  DurationOfEventDays = 'DurationOfEventDays__c',
  FlatteningBKCostOfEvent = 'FlatteningBKCostOfEvent__c',
  FlatteningBKDescriptionOfEvent = 'FlatteningBKDescriptionOfEvent__c',
  FlatteningBKDurationOfEventDays = 'FlatteningBKDurationOfEventDays__c',
  FlatteningBKInboundShippingCost = 'FlatteningBKInboundShippingCost__c',
  FlatteningBKLeadTimeWeeks = 'FlatteningBKLeadTimeWeeks__c',
  FlatteningBKNotes = 'FlatteningBKNotes__c',
  FlatteningBKOutboundShippingCost = 'FlatteningBKOutboundShippingCost__c',
  FlatteningBKShipToCustomer = 'FlatteningBKShipToCustomer__c',
  FlatteningBKSort = 'FlatteningBKSort__c',
  FlatteningBKTotalCost = 'FlatteningBKTotalCost__c',
  FlatteningBKTotalInboundShippingCost = 'FlatteningBKTotalInboundShippingCost__c',
  FlatteningBKTotalOutboundShippingCost = 'FlatteningBKTotalOutboundShippingCost__c',
  FlatteningBKTotalTransitTimeDays = 'FlatteningBKTotalTransitTimeDays__c',
  FlatteningBKVendor = 'FlatteningBKVendor__c',
  FlatteningCostOfEvent = 'FlatteningCostOfEvent__c',
  FlatteningDescriptionOfEvent = 'FlatteningDescriptionOfEvent__c',
  FlatteningDurationOfEventDays = 'FlatteningDurationOfEventDays__c',
  FlatteningInboundShippingCost = 'FlatteningInboundShippingCost__c',
  FlatteningLeadTimeWeeks = 'FlatteningLeadTimeWeeks__c',
  FlatteningNotes = 'FlatteningNotes__c',
  FlatteningOutboundShippingCost = 'FlatteningOutboundShippingCost__c',
  FlatteningShipToCustomer = 'FlatteningShipToCustomer__c',
  FlatteningSort = 'FlatteningSort__c',
  FlatteningTotalCost = 'FlatteningTotalCost__c',
  FlatteningTotalInboundShippingCost = 'FlatteningTotalInboundShippingCost__c',
  FlatteningTotalOutboundShippingCost = 'FlatteningTotalOutboundShippingCost__c',
  FlatteningTotalTransitTimeDays = 'FlatteningTotalTransitTimeDays__c',
  FlatteningVendor = 'FlatteningVendor__c',
  Head_Diameter = 'Head_Diameter__c',
  HeadBlankFlattening = 'HeadBlankFlattening__c',
  HeadBlankFlatteningNotes = 'HeadBlankFlatteningNotes__c',
  HeadContourToleranceHTOptions = 'HeadContourToleranceHTOptions__c',
  HeadFormingCostPerHead = 'HeadFormingCostPerHead__c',
  HeadFormingDescriptionOfEvent = 'HeadFormingDescriptionOfEvent__c',
  HeadFormingIDvsOD = 'HeadFormingIDvsOD__c',
  HeadFormingLeadTimeWeeks = 'HeadFormingLeadTimeWeeks__c',
  HeadFormingSort = 'HeadFormingSort__c',
  HeadFormingSubcontractingCost = 'HeadFormingSubcontractingCost__c',
  HeadFormingSubcontractingFreightEstimate = 'HeadFormingSubcontractingFreightEstimate__c',
  HeadFormingTotalInboundShippingCost = 'HeadFormingTotalInboundShippingCost__c',
  HeadFormingTotalOutboundShippingCost = 'HeadFormingTotalOutboundShippingCost__c',
  HeadFormingVendor = 'HeadFormingVendor__c',
  HeadFormingVendorQuoteNumber = 'HeadFormingVendorQuoteNumber__c',
  HeadFreightCostToFormer = 'HeadFreightCostToFormer__c',
  HeadFreightZone = 'HeadFreightZone__c',
  HeadTimeToFormer = 'HeadTimeToFormer__c',
  HeadTimeToFormHead = 'HeadTimeToFormHead__c',
  HeadFormingNotes1 = 'HeadFormingNotes1__c',
  InspectionBKCostOfEvent = 'InspectionBKCostOfEvent__c',
  InspectionBKDescriptionOfEvent = 'InspectionBKDescriptionOfEvent__c',
  InspectionBKDurationOfEventDays = 'InspectionBKDurationOfEventDays__c',
  InspectionBKInboundShippingCost = 'InspectionBKInboundShippingCost__c',
  InspectionBKLeadTimeWeeks = 'InspectionBKLeadTimeWeeks__c',
  InspectionBKNotes = 'InspectionBKNotes__c',
  InspectionBKOutboundShippingCost = 'InspectionBKOutboundShippingCost__c',
  InspectionBKShipToCustomer = 'InspectionBKShipToCustomer__c',
  InspectionBKSort = 'InspectionBKSort__c',
  InspectionBKTotalCost = 'InspectionBKTotalCost__c',
  InspectionBKTotalInboundShippingCost = 'InspectionBKTotalInboundShippingCost__c',
  InspectionBKTotalOutboundShippingCost = 'InspectionBKTotalOutboundShippingCost__c',
  InspectionBKTotalTransitTimeDays = 'InspectionBKTotalTransitTimeDays__c',
  InspectionBKVendor = 'InspectionBKVendor__c',
  InspectionCLCostOfEvent = 'InspectionCLCostOfEvent__c',
  InspectionCLDescriptionOfEvent = 'InspectionCLDescriptionOfEvent__c',
  InspectionCLDurationOfEventDays = 'InspectionCLDurationOfEventDays__c',
  InspectionCLInboundShippingCost = 'InspectionCLInboundShippingCost__c',
  InspectionCLLeadTimeWeeks = 'InspectionCLLeadTimeWeeks__c',
  InspectionCLNotes = 'InspectionCLNotes__c',
  InspectionCLOutboundShippingCost = 'InspectionCLOutboundShippingCost__c',
  InspectionCLShipToCustomer = 'InspectionCLShipToCustomer__c',
  InspectionCLSort = 'InspectionCLSort__c',
  InspectionCLTotalCost = 'InspectionCLTotalCost__c',
  InspectionCLTotalInboundShippingCost = 'InspectionCLTotalInboundShippingCost__c',
  InspectionCLTotalOutboundShippingCost = 'InspectionCLTotalOutboundShippingCost__c',
  InspectionCLTotalTransitTimeDays = 'InspectionCLTotalTransitTimeDays__c',
  InspectionCLVendor = 'InspectionCLVendor__c',
  InspectionCostOfEvent = 'InspectionCostOfEvent__c',
  InspectionDescriptionOfEvent = 'InspectionDescriptionOfEvent__c',
  InspectionDurationOfEventDays = 'InspectionDurationOfEventDays__c',
  InspectionInboundShippingCost = 'InspectionInboundShippingCost__c',
  InspectionLeadTimeWeeks = 'InspectionLeadTimeWeeks__c',
  InspectionNotes = 'InspectionNotes__c',
  InspectionOutboundShippingCost = 'InspectionOutboundShippingCost__c',
  InspectionShipToCustomer = 'InspectionShipToCustomer__c',
  InspectionSort = 'InspectionSort__c',
  InspectionTotalCost = 'InspectionTotalCost__c',
  InspectionTotalInboundShippingCost = 'InspectionTotalInboundShippingCost__c',
  InspectionTotalOutboundShippingCost = 'InspectionTotalOutboundShippingCost__c',
  InspectionTotalTransitTimeDays = 'InspectionTotalTransitTimeDays__c',
  InspectionVendor = 'InspectionVendor__c',
  LastModifiedById = 'LastModifiedById',
  MachiningCostOfEvent = 'MachiningCostOfEvent__c',
  MachiningDescriptionOfEvent = 'MachiningDescriptionOfEvent__c',
  MachiningDurationOfEventDays = 'MachiningDurationOfEventDays__c',
  MachiningInboundShippingCost = 'MachiningInboundShippingCost__c',
  MachiningLeadTimeWeeks = 'MachiningLeadTimeWeeks__c',
  MachiningNotes = 'MachiningNotes__c',
  MachiningOutboundShippingCost = 'MachiningOutboundShippingCost__c',
  MachiningShipToCustomer = 'MachiningShipToCustomer__c',
  MachiningSort = 'MachiningSort__c',
  MachiningTotalCost = 'MachiningTotalCost__c',
  MachiningTotalInboundShippingCost = 'MachiningTotalInboundShippingCost__c',
  MachiningTotalOutboundShippingCost = 'MachiningTotalOutboundShippingCost__c',
  MachiningTotalTransitTimeDays = 'MachiningTotalTransitTimeDays__c',
  MachiningVendor = 'MachiningVendor__c',
  Name = 'Name',
  NCQuoteLineItemDetail = 'NC_Quote_Line_Item_Detail__c',
  Outside_Service_Type = 'Outside_Service_Type__c',
  PackagingCostOfEvent = 'PackagingCostOfEvent__c',
  PackagingDescriptionOfEvent = 'PackagingDescriptionOfEvent__c',
  PackagingDurationOfEventDays = 'PackagingDurationOfEventDays__c',
  PackagingInboundShippingCost = 'PackagingInboundShippingCost__c',
  PackagingLeadTimeWeeks = 'PackagingLeadTimeWeeks__c',
  PackagingNotes = 'PackagingNotes__c',
  PackagingOutboundShippingCost = 'PackagingOutboundShippingCost__c',
  PackagingShipToCustomer = 'PackagingShipToCustomer__c',
  PackagingSort = 'PackagingSort__c',
  PackagingTotalCost = 'PackagingTotalCost__c',
  PackagingTotalInboundShippingCost = 'PackagingTotalInboundShippingCost__c',
  PackagingTotalOutboundShippingCost = 'PackagingTotalOutboundShippingCost__c',
  PackagingTotalTransitTimeDays = 'PackagingTotalTransitTimeDays__c',
  PackagingVendor = 'PackagingVendor__c',
  PostbondHTCostOfEvent = 'PostbondHTCostOfEvent__c',
  PostbondHTDescriptionOfEvent = 'PostbondHTDescriptionOfEvent__c',
  PostbondHTDurationOfEventDays = 'PostbondHTDurationOfEventDays__c',
  PostbondHTInboundShippingCost = 'PostbondHTInboundShippingCost__c',
  PostbondHTLeadTimeWeeks = 'PostbondHTLeadTimeWeeks__c',
  PostbondHTNotes = 'PostbondHTNotes__c',
  PostbondHTOutboundShippingCost = 'PostbondHTOutboundShippingCost__c',
  PostbondHTShipToCustomer = 'PostbondHTShipToCustomer__c',
  PostbondHTSort = 'PostbondHTSort__c',
  PostbondHTTotalCost = 'PostbondHTTotalCost__c',
  PostbondHTTotalCostOfEvent = 'PostbondHTTotalCostOfEvent__c',
  PostbondHTTotalInboundShippingCost = 'PostbondHTTotalInboundShippingCost__c',
  PostbondHTTotalOutboundShippingCost = 'PostbondHTTotalOutboundShippingCost__c',
  PostbondHTTotalTransitTimeDays = 'PostbondHTTotalTransitTimeDays__c',
  PostbondHTType = 'PostbondHTType__c',
  PostbondHTVendor = 'PostbondHTVendor__c',
  SimulateHeatTreatmentAtVendor = 'SimulateHeatTreatmentAtVendor__c',
  SimulateHeatTreatmentHeadForming = 'SimulateHeatTreatmentHeadForming__c',
  SimulationHTCoolingCycle1 = 'SimulationHTCoolingCycle1__c',
  SimulationHTCoolingCycle2 = 'SimulationHTCoolingCycle2__c',
  SimulationHTCoolingCycle3 = 'SimulationHTCoolingCycle3__c',
  SimulationHTCoolingCycle4 = 'SimulationHTCoolingCycle4__c',
  SimulationHTCoolingCycle5 = 'SimulationHTCoolingCycle5__c',
  SimulationHTCycle1 = 'SimulationHTCycle1__c',
  SimulationHTCycle2 = 'SimulationHTCycle2__c',
  SimulationHTCycle3 = 'SimulationHTCycle3__c',
  SimulationHTCycle4 = 'SimulationHTCycle4__c',
  SimulationHTCycle5 = 'SimulationHTCycle5__c',
  SimulationHTCycleTemp1 = 'SimulationHTCycleTemp1__c',
  SimulationHTCycleTemp2 = 'SimulationHTCycleTemp2__c',
  SimulationHTCycleTemp3 = 'SimulationHTCycleTemp3__c',
  SimulationHTCycleTemp4 = 'SimulationHTCycleTemp4__c',
  SimulationHTCycleTemp5 = 'SimulationHTCycleTemp5__c',
  SimulationHTCycleTime1 = 'SimulationHTCycleTime1__c',
  SimulationHTCycleTime2 = 'SimulationHTCycleTime2__c',
  SimulationHTCycleTime3 = 'SimulationHTCycleTime3__c',
  SimulationHTCycleTime4 = 'SimulationHTCycleTime4__c',
  SimulationHTCycleTime5 = 'SimulationHTCycleTime5__c',
  SimulationHTTempUOMCycle1 = 'SimulationHTTempUOMCycle1__c',
  SimulationHTTempUOMCycle2 = 'SimulationHTTempUOMCycle2__c',
  SimulationHTTempUOMCycle3 = 'SimulationHTTempUOMCycle3__c',
  SimulationHTTempUOMCycle4 = 'SimulationHTTempUOMCycle4__c',
  SimulationHTTempUOMCycle5 = 'SimulationHTTempUOMCycle5__c',
  SpecificationTestLaborHours = 'SpecificationTestLaborHours__c',
  SubcontractHeadForming = 'SubcontractHeadForming__c',
  TestingBKCostOfEvent = 'TestingBKCostOfEvent__c',
  TestingBKDescriptionOfEvent = 'TestingBKDescriptionOfEvent__c',
  TestingBKDurationOfEventDays = 'TestingBKDurationOfEventDays__c',
  TestingBKInboundShippingCost = 'TestingBKInboundShippingCost__c',
  TestingBKLeadTimeWeeks = 'TestingBKLeadTimeWeeks__c',
  TestingBKNotes = 'TestingBKNotes__c',
  TestingBKOutboundShippingCost = 'TestingBKOutboundShippingCost__c',
  TestingBKShipToCustomer = 'TestingBKShipToCustomer__c',
  TestingBKSort = 'TestingBKSort__c',
  TestingBKTotalCost = 'TestingBKTotalCost__c',
  TestingBKTotalInboundShippingCost = 'TestingBKTotalInboundShippingCost__c',
  TestingBKTotalOutboundShippingCost = 'TestingBKTotalOutboundShippingCost__c',
  TestingBKTotalTransitTimeDays = 'TestingBKTotalTransitTimeDays__c',
  TestingBKVendor = 'TestingBKVendor__c',
  TestingCLCostOfEvent = 'TestingCLCostOfEvent__c',
  TestingCLDescriptionOfEvent = 'TestingCLDescriptionOfEvent__c',
  TestingCLDurationOfEventDays = 'TestingCLDurationOfEventDays__c',
  TestingCLInboundShippingCost = 'TestingCLInboundShippingCost__c',
  TestingCLLeadTimeWeeks = 'TestingCLLeadTimeWeeks__c',
  TestingCLNotes = 'TestingCLNotes__c',
  TestingCLOutboundShippingCost = 'TestingCLOutboundShippingCost__c',
  TestingCLShipToCustomer = 'TestingCLShipToCustomer__c',
  TestingCLSort = 'TestingCLSort__c',
  TestingCLTotalCost = 'TestingCLTotalCost__c',
  TestingCLTotalInboundShippingCost = 'TestingCLTotalInboundShippingCost__c',
  TestingCLTotalOutboundShippingCost = 'TestingCLTotalOutboundShippingCost__c',
  TestingCLTotalTransitTimeDays = 'TestingCLTotalTransitTimeDays__c',
  TestingCLVendor = 'TestingCLVendor__c',
  TestingCostOfEvent = 'TestingCostOfEvent__c',
  TestingDescriptionOfEvent = 'TestingDescriptionOfEvent__c',
  TestingDurationOfEventDays = 'TestingDurationOfEventDays__c',
  TestingInboundShippingCost = 'TestingInboundShippingCost__c',
  TestingLeadTimeWeeks = 'TestingLeadTimeWeeks__c',
  TestingNotes = 'TestingNotes__c',
  TestingOutboundShippingCost = 'TestingOutboundShippingCost__c',
  TestingShipToCustomer = 'TestingShipToCustomer__c',
  TestingSort = 'TestingSort__c',
  TestingTotalCost = 'TestingTotalCost__c',
  TestingTotalInboundShippingCost = 'TestingTotalInboundShippingCost__c',
  TestingTotalOutboundShippingCost = 'TestingTotalOutboundShippingCost__c',
  TestingTotalTransitTimeDays = 'TestingTotalTransitTimeDays__c',
  TestingVendor = 'TestingVendor__c',
  TorchCutCladder = 'TorchCutCladder__c',
  TorchCutBacker = 'TorchCutBacker__c',
  TorchCutAnvil1 = 'TorchCutAnvil1__c',
  TubesheetMachiningManHours = 'TubesheetMachiningManHours__c',
  TubesheetMachiningTotalCost = 'TubesheetMachiningTotalCost__c',
  WaterJetCuttingCostOfEvent = 'WaterJetCuttingCostOfEvent__c',
  WaterJetCuttingDescriptionOfEvent = 'WaterJetCuttingDescriptionOfEvent__c',
  WaterJetCuttingDurationOfEventDays = 'WaterJetCuttingDurationOfEventDays__c',
  WaterJetCuttingInboundShippingCost = 'WaterJetCuttingInboundShippingCost__c',
  WaterJetCuttingLeadTimeWeeks = 'WaterJetCuttingLeadTimeWeeks__c',
  WaterJetCuttingNotes = 'WaterJetCuttingNotes__c',
  WaterJetCuttingOutboundShipping = 'WaterJetCuttingOutboundShipping__c',
  WaterJetCuttingOutboundShippingCost = 'WaterJetCuttingOutboundShippingCost__c',
  WaterJetCuttingShipToCustomer = 'WaterJetCuttingShipToCustomer__c',
  WaterJetCuttingSort = 'WaterJetCuttingSort__c',
  WaterJetCuttingTotalCost = 'WaterJetCuttingTotalCost__c',
  WaterJetCuttingTotalInboundShippingCost = 'WaterJetCuttingTotalInboundShippingCost__c',
  WaterJetCuttingTotalOutboundShippingCost = 'WaterJetCuttingTotalOutboundShippingCost__c',
  WaterJetCuttingTotalTransitTimeDays = 'WaterJetCuttingTotalTransitTimeDays__c',
  WaterJetCuttingVendor = 'WaterJetCuttingVendor__c',
  WeightAnvil = 'WeightAnvil__c',
  WeldingCostOfEvent = 'WeldingCostOfEvent__c',
  WeldingDescriptionOfEvent = 'WeldingDescriptionOfEvent__c',
  WeldingDurationOfEventDays = 'WeldingDurationOfEventDays__c',
  WeldingInboundShippingCost = 'WeldingInboundShippingCost__c',
  WeldingLeadTimeWeeks = 'WeldingLeadTimeWeeks__c',
  WeldingNotes = 'WeldingNotes__c',
  WeldingOutboundShippingCost = 'WeldingOutboundShippingCost__c',
  WeldingShipToCustomer = 'WeldingShipToCustomer__c',
  WeldingSort = 'WeldingSort__c',
  WeldingTotalCost = 'WeldingTotalCost__c',
  WeldingTotalInboundShippingCost = 'WeldingTotalInboundShippingCost__c',
  WeldingTotalOutboundShippingCost = 'WeldingTotalOutboundShippingCost__c',
  WeldingTotalTransitTimeDays = 'WeldingTotalTransitTimeDays__c',
  WeldingVendor = 'WeldingVendor__c',
  XRayServicesCostOfEvent = 'XRayServicesCostOfEvent__c',
  XRayServicesDescriptionOfEvent = 'XRayServicesDescriptionOfEvent__c',
  XRayServicesDurationOfEventDays = 'XRayServicesDurationOfEventDays__c',
  XRayServicesInboundShippingCost = 'XRayServicesInboundShippingCost__c',
  XRayServicesLeadTimeWeeks = 'XRayServicesLeadTimeWeeks__c',
  XRayServicesNotes = 'XRayServicesNotes__c',
  XRayServicesOutboundShippingCost = 'XRayServicesOutboundShippingCost__c',
  XRayServicesShipToCustomer = 'XRayServicesShipToCustomer__c',
  XRayServicesSort = 'XRayServicesSort__c',
  XRayServicesTotalCost = 'XRayServicesTotalCost__c',
  XRayServicesTotalInboundShippingCost = 'XRayServicesTotalInboundShippingCost__c',
  XRayServicesTotalOutboundShippingCost = 'XRayServicesTotalOutboundShippingCost__c',
  XRayServicesTotalTransitTimeDays = 'XRayServicesTotalTransitTimeDays__c',
  XRayServicesVendor = 'XRayServicesVendor__c',
  StressReliefHT = 'StressReliefHT__c',
  NCInhouseStressRefliefTimeMin = 'NCInhouseStressRefliefTimeMin__c',
  NCInHouseStressReliefTimeMax = 'NCInHouseStressReliefTimeMax__c',
  NCInhouseStressReliefTemp = 'NCInhouseStressReliefTemp__c',
  NCInhouseStressReliefDetails = 'NCInhouseStressReliefDetails__c',
  PreHeatRequiredforCladding = 'PreHeatRequiredforCladding__c',
  PreHeatReqforcladdingMinTempforPreheat = 'PreHeatReqforcladdingMinTempforPreheat__c',
  PreHeatReqforcuttingmintempforpreheat = 'PreHeatReqforcuttingmintempforpreheat__c',
  PreheattoCut = 'PreheattoCut__c',
  PreheattoCutAnvil = 'PreheattoCutAnvil__c',
  PreHeatReqforcutting = 'PreHeatReqforcutting__c',
  PostCladFlattening = 'PostCladFlattening__c',
  PostCladCuttingTolerance = 'PostCladCuttingTolerance__c',
  Machining = 'Machining__c',
  SurfaceTreatment = 'SurfaceTreatment__c',
  SurfaceTreatmentNotes = 'SurfaceTreatmentNotes__c',
  NCInHousePackaging = 'NCInHousePackaging__c',
  UnitCost = 'UnitCost__c',
  QuantityCL = 'QuantityCL__c',
  QuantityBK = 'QuantityBK__c',
  QuantityBacker = 'QuantityBacker__c',
}

export enum QuoteLineItemDependentUnwrittenFields {
  AnvilDimensionAdder = 'AnvilDimensionAdder',
  AnvilEdgeAllowanceRequirementsGroup = 'AnvilEdgeAllowanceRequirementsGroup',
  AnvilRequirementsGroup = 'AnvilRequirementsGroup',
  BaseFlatteningThinningGroup = 'BaseFlatteningThinningGroup',
  BaseFlatteningThinningValue = 'BaseFlatteningThinningValue',
  BaseMetal = 'BaseMetal',
  BaseMetalPricing = 'BaseMetalPricing',
  BaseMetalPricingByProposalType = 'BaseMetalPricingByProposalType',
  BaseThicknessAddition = 'BaseThicknessAddition',
  BaseThicknessForPricing = 'BaseThicknessForPricing',
  BoosterAllowanceGroup = 'BoosterAllowanceGroup',
  CladFlatteningThinningGroup = 'CladFlatteningThinningGroup',
  CladFlatteningThinningValue = 'CladFlatteningThinningValue',
  CladMetal = 'CladMetal',
  CladMetalPricing = 'CladMetalPricing',
  CladMetalPricingByProposalType = 'CladMetalPricingByProposalType',
  CladOverhangThreshold = 'CladOverhangThreshold',
  CladThicknessAddition = 'CladThicknessAddition',
  CladThicknessForPricing = 'CladThicknessForPricing',
  CladWidthToFitQuantity = 'CladWidthToFitQuantity',
  ConsumableCostsToReconcile = 'ConsumableCostsToReconcile',
  CosmeticGrinding = 'CosmeticGrinding',
  CosmeticGrindingBaseThicknessAddition = 'CosmeticGrindingBaseThicknessAddition',
  CosmeticGrindingCladThicknessAddition = 'CosmeticGrindingCladThicknessAddition',
  EdgeAllowanceGroup = 'EdgeAllowanceGroup',
  ExplosiveLoadGroups = 'ExplosiveLoadGroups',
  HeatTreatment = 'HeatTreatment',
  HeatTreatCombinationGroup = 'HeatTreatCombinationGroup',
  ItemType = 'ItemType',
  IsForging = 'IsForging',
  BaseMetalUT = 'BaseMetalUT',
  TotalRawTkIn = 'TotalRawTkIn',
  TotalRawTkMm = 'TotalRawTkMm',
  LaborCostPerWeight = 'LaborCostPerWeight',
  PreHeatTimingGroup = 'PreHeatTimingGroup',
  NumberOfItemsLongThatFit = 'NumberOfItemsLongThatFit',
  NumberOfItemsWideThatFit = 'NumberOfItemsWideThatFit',
  OverallCost = 'OverallCost',
  MaxQuantityOnClad = 'MaxQuantityOnClad',
  ExtraCladRequired = 'ExtraCladRequired',
  LeftoverQuantity = 'LeftoverQuantity',
  QuantityUpdatedForAutocombine = 'QuantityUpdatedForAutocombine',
  QuantityForLeftoverCalculation = 'QuantityForLeftoverCalculation',
  SquareFinishedArea = 'SquareFinishedArea',
  TotalLBPerPC = 'TotalLBPerPC',
  TotalKGPerPC = 'TotalKGPerPC',
}

export enum ContentSlugs {
  PaymentTerms = 'PaymentTerms',
  ContractTerms = 'ContractTerms',
  CancellationTerms = 'CancellationTerms',
  Validity = 'Validity',
}

export enum FinishedProductTypes {
  Head = 'Head',
  HeadSegmental = 'Head - Segmental',
  Plate = 'Plate',
  Tubesheet = 'Tubesheet',
  Cylinder = 'Cylinder',
}

export enum MachiningTypes {
  None = 'None',
  MachineOD = 'Machine OD',
  ODCladFace = 'OD & Clad Face',
  AllSurfaces = 'All Surfaces',
  ODBaseFace = 'OD & Base Face',
  Other = 'Other',
}

export enum ManufacturingSites {
  US01 = 'US01',
  DE01 = 'DE01',
}

export enum MSTOPopovers {
  AddPiece = 'Add Piece',
  SeamWeld = 'Seam Weld',
  Supplier = 'Supplier',
}

export enum QuoteLineItemFieldEditTypes {
  None = 'None',
  Inline = 'Inline',
  Popover = 'Popover',
}

export enum FieldTypes {
  Integer = 'Integer',
  Float = 'Float',
  Text = 'Text',
  Date = 'Date',
}

export enum QuoteLineItemValueTypes {
  AddPieceSupplier = 'AddPieceSupplier',
  AddPieceSupplyChoice = 'AddPieceSupplyChoice',
  AdditionalHeadInformation = 'AdditionalHeadInformation',
  AdditionalOptions = 'AdditionalOptions',
  AdditionalRequirementsBaseMetal = 'AdditionalRequirementsBaseMetal',
  AdditionalRequirementsCladdingMetal = 'AdditionalRequirementsCladdingMetal',
  AdditionalRequirementsCladProductTesting = 'AdditionalRequirementsCladProductTesting',
  AdditionalRequirementsPrewelding = 'AdditionalRequirementsPrewelding',
  AdvancedSpecUOM = 'AdvancedSpecUOM',
  AddDetails = 'AddDetails',
  ADW8Bend = 'ADW8Bend',
  AgentComRate = 'AgentComRate',
  Allocation = 'Allocation',
  AreAllFinishedGoodsOnSamePO = 'AreAllFinishedGoodsOnSamePO',
  ASUnitOfMeasure = 'ASUnitOfMeasure',
  AusteniticGrainSizeFinerThanValue = 'AusteniticGrainSizeFinerThanValue',
  AnvilJSON = 'AnvilJSON',
  AnvilSupplier = 'AnvilSupplier',
  AssembliesJSON = 'AssembliesJSON',
  BackerJSON = 'BackerJSON',
  BackerSupplier = 'BackerSupplier',
  BaseMetalLongName = 'BaseMetalLongName',
  BaseMetalProductAnalysisRequired = 'BaseMetalProductAnalysisRequired',
  BaseMetalProductAnalysisRequiredNotes = 'BaseMetalProductAnalysisRequiredNotes',
  BaseMetalProductAnalysisType = 'BaseMetalProductAnalysisType',
  BaseMetalPurchaseRequirements = 'BaseMetalPurchaseRequirements',
  BaseMetalSpecAddendum = 'BaseMetalSpecAddendum',
  BaseMetalSpecEdition = 'BaseMetalSpecEdition',
  BaseTensileTestLocation = 'BaseTensileTestLocation',
  BendB898 = 'BendB898',
  BlankCrownCutByNC = 'BlankCrownCutByNC',
  BlankSize = 'BlankSize',
  BondStrengthTensileTestRamUOM = 'BondStrengthTensileTestRamUOM',
  BondStrengthTensileTestRamValue = 'BondStrengthTensileTestRamValue',
  CalciumTreatedSulfideShapeControl = 'CalciumTreatedSulfideShapeControl',
  CarbonEquivalencyPercent = 'CarbonEquivalencyPercent',
  CarbonPercent = 'CarbonPercent',
  CertificateCost = 'CertificateCost',
  CertificatesToBeSentBy = 'CertificatesToBeSentBy',
  CertificationTypeRequired = 'CertificationTypeRequired',
  CladderJSON = 'CladderJSON',
  CladdingForCorrosionAllowanceOnly = 'CladdingForCorrosionAllowanceOnly',
  CladMetalLongName = 'CladMetalLongName',
  CladMetalPreference = 'CladMetalPreference',
  CladMetalProductAnalysisRequired = 'CladMetalProductAnalysisRequired',
  CladMetalProductAnalysisRequiredNotes = 'CladMetalProductAnalysisRequiredNotes',
  CladMetalProductAnalysisType = 'CladMetalProductAnalysisType',
  CladMetalPurchaseRequirements = 'CladMetalPurchaseRequirements',
  CladMetalSpecAddendum = 'CladMetalSpecAddendum',
  CladMetalSpecEdition = 'CladMetalSpecEdition',
  CladProductionSpecificationNotes = 'CladProductionSpecificationNotes',
  CladProductSpecification = 'CladProductSpecification',
  CladTensileTestLocation = 'CladTensileTestLocation',
  Commission = 'Commission',
  CommissionPaidTo = 'CommissionPaidTo',
  ConsumableCostsToReconcile = 'ConsumableCostsToReconcile',
  CorrosionTesting = 'CorrosionTesting',
  CorrosionTestingNotes = 'CorrosionTestingNotes',
  CosmeticGrinding = 'CosmeticGrinding',
  CosmeticPrepCost = 'CosmeticPrepCost',
  CostOfEvent = 'CostOfEvent',
  CreateTwoPieceHead = 'CreateTwoPieceHead',
  CustBaseMetalSupplierRestrictionsNotes = 'CustBaseMetalSupplierRestrictionsNotes',
  CustCladMetalSupplierRestrictionsNotes = 'CustCladMetalSupplierRestrictionsNotes',
  CustomerBaseChemistryRestrictions = 'CustomerBaseChemistryRestrictions',
  CustomerBaseMetalSupplierRestrictions = 'CustomerBaseMetalSupplierRestrictions',
  CustomerCladChemistryRestrictionsNotes = 'CustomerCladChemistryRestrictionsNotes',
  CustomerCladMetalSupplierRestrictions = 'CustomerCladMetalSupplierRestrictions',
  CustomerDocumentUOM = 'CustomerDocumentUOM',
  CustomerSpecifiedImpactTesting = 'CustomerSpecifiedImpactTesting',
  CustomerSpecifiedImpactTestAVG = 'CustomerSpecifiedImpactTestAVG',
  CustomerSpecifiedImpactTestDirection = 'CustomerSpecifiedImpactTestDirection',
  CustomerSpecifiedImpactTestLocation = 'CustomerSpecifiedImpactTestLocation',
  CustomerSpecifiedImpactTestMIN = 'CustomerSpecifiedImpactTestMIN',
  CustomerSpecifiedImpactTestTemperature = 'CustomerSpecifiedImpactTestTemperature',
  CustomerSpecifiedImpactTestToBePerformed = 'CustomerSpecifiedImpactTestToBePerformed',
  CustomerSpecifiedImpactTestUOM = 'CustomerSpecifiedImpactTestUOM',
  CustomerSpecifiedImpactTestTempUOM = 'CustomerSpecifiedImpactTestTempUOM',
  CustomerSpecifiedSPWHTTestCoupons = 'CustomerSpecifiedSPWHTTestCoupons',
  Cutback = 'Cutback',
  CuttingNotes = 'CuttingNotes',
  CycleTempUOM = 'CycleTempUOM',
  CycleType = 'CycleType',
  CylinderDiameter = 'CylinderDiameter',
  CylinderLength = 'CylinderLength',
  DoAllItemsShipTheSameWay = 'DoAllItemsShipTheSameWay',
  DoAllItemsShipToSameAddress = 'DoAllItemsShipToSameAddress',
  DNC = 'DNC',
  DyePenetrantNotes = 'DyePenetrantNotes',
  ExceptionsAndClarifications = 'ExceptionsAndClarifications',
  ExportPackaging = 'ExportPackaging',
  ExportPackagingCost = 'ExportPackagingCost',
  ExtraCladRequired = 'ExtraCladRequired',
  FerriteNumberRequired = 'FerriteNumberRequired',
  FerroxylTestPerformed = 'FerroxylTestPerformed',
  FerroxylNotes = 'FerroxylNotes',
  Finalinspectionofcladdisk = 'Finalinspectionofcladdisk',
  FinalinspectionofcladdiskwithSeam = 'FinalinspectionofcladdiskwithSeam',
  Finalinspectionofrectangularclad = 'Finalinspectionofrectangularclad',
  FinalinspectionofrectangularcladwithSeam = 'FinalinspectionofrectangularcladwithSeam',
  FinishedGoodHeatTreatment = 'FinishedGoodHeatTreatment',
  FinishedGoodHeatTreatmentNotes = 'FinishedGoodHeatTreatmentNotes',
  BaseFlatteningThinningGroup = 'BaseFlatteningThinningGroup',
  BaseFlatteningThinningValue = 'BaseFlatteningThinningValue',
  CladFlatteningThinningGroup = 'CladFlatteningThinningGroup',
  CladFlatteningThinningValue = 'CladFlatteningThinningValue',
  HardnessBaseMetalSurface = 'HardnessBaseMetalSurface',
  HardnessBaseRejectionCriteria = 'HardnessBaseRejectionCriteria',
  HardnessCladdingMetalSurface = 'HardnessCladdingMetalSurface',
  HardnessCladdingRejectionCriteria = 'HardnessCladdingRejectionCriteria',
  HeadDiameter = 'HeadDiameter',
  HeadFormingSupplier = 'HeadFormingSupplier',
  HeadNumberOfPieces = 'HeadNumberOfPieces',
  HeadQuantity = 'HeadQuantity',
  HeadMaxCenterHole = 'HeadMaxCenterHole',
  HeatContourTolerancesAndHeatTreatmentOptions = 'HeatContourTolerancesAndHeatTreatmentOptions',
  HeatTreatment = 'HeatTreatment',
  HicTestNotes = 'HicTestNotes',
  HotTensileTestPerSA20S7Degrees = 'HotTensileTestPerSA20S7Degrees',
  HotTensileTestPerSA20S7Notes = 'HotTensileTestPerSA20S7Notes',
  HotTensileTestPerSA20S7UOM = 'HotTensileTestPerSA20S7UOM',
  InboundShip = 'InboundShip',
  Incoterms = 'Incoterms',
  InsideDishRadius = 'InsideDishRadius',
  InsideKnuckleRadius = 'InsideKnuckleRadius',
  InspectionCompany = 'InspectionCompany',
  IsForging = 'IsForging',
  IsWeldRepairAllowed = 'IsWeldRepairAllowed',
  ItemDescriptionBackerim = 'ItemDescriptionBackerim',
  ItemDescriptionBackermm = 'ItemDescriptionBackermm',
  ItemDescriptionCladderim = 'ItemDescriptionCladderim',
  ItemDescriptionCladdermm = 'ItemDescriptionCladdermm',
  ItemDescriptionAnvilim = 'ItemDescriptionAnvilim',
  ItemDescriptionAnvilmm = 'ItemDescriptionAnvilmm',
  ItemDescriptionSeamWeldim = 'ItemDescriptionSeamWeldim',
  ItemDescriptionSeamWeldmm = 'ItemDescriptionSeamWeldmm',

  JFactorSA387S62 = 'JFactorSA387S62',
  LeakTest = 'LeakTest',
  LeakTestGas = 'LeakTestGas',
  LeakTestReqt = 'LeakTestReqt',
  LeakTestPressure = 'LeakTestPressure',
  LeakTestValue = 'LeakTestValue',
  MachineBondStrengthBendsperSA26345 = 'MachineBondStrengthBendsperSA26345',
  MachineSideBends = 'MachineSideBends',
  MaxQuantityOnClad = 'MaxQuantityOnClad',
  NCBaseChemistryRestForBondingNotes = 'NCBaseChemistryRestForBondingNotes',
  NCBaseChemistryRestrictionsforBonding = 'NCBaseChemistryRestrictionsforBonding',
  NCBaseHeatTreatmentasSupplied = 'NCBaseHeatTreatmentasSupplied',
  NCBaseHeatTreatmentasSuppliedNotes = 'NCBaseHeatTreatmentasSuppliedNotes',
  NCBaseUTRequirement = 'NCBaseUTRequirement',
  NCChemistryRestrictionsForBonding = 'NCChemistryRestrictionsForBonding',
  NCChemistryRestrictionsForBondingNotes = 'NCChemistryRestrictionsForBondingNotes',
  NCCladChemistryRestForBondingNotes = 'NCCladChemistryRestForBondingNotes',
  NCCladChemistryRestrictionsforBonding = 'NCCladChemistryRestrictionsforBonding',
  NCCladHeatTreatmentasSupplied = 'NCCladHeatTreatmentasSupplied',
  NCCladHeatTreatmentasSuppliedNotes = 'NCCladHeatTreatmentasSuppliedNotes',
  NobelCladProcedure = 'NobelCladProcedure',
  NumberOfItemsLongThatFit = 'NumberOfItemsLongThatFit',
  NumberOfItemsWideThatFit = 'NumberOfItemsWideThatFit',
  OpenEndDiameter = 'OpenEndDiameter',
  OtherCladGroupsCombinedInThisHead = 'OtherCladGroupsCombinedInThisHead',
  OutboundShip = 'OutboundShip',
  OverallCost = 'OverallCost',
  PaymentTerms = 'PaymentTerms',
  PetalsCutByNC = 'PetalsCutByNC',
  PhosphorusPercent = 'PhosphorusPercent',
  Pieces = 'Pieces',
  PlateMethod = 'PlateMethod',
  PMIConfirmBaseMetal = 'PMIConfirmBaseMetal',
  PMIConfirmBaseMetalNotes = 'PMIConfirmBaseMetalNotes',
  PMIConfirmCladdingMetal = 'PMIConfirmCladdingMetal',
  PMIConfirmCladdingMetalNotes = 'PMIConfirmCladdingMetalNotes',
  PMIofWeldWire = 'PMIofWeldWire',
  PMIPreweldsRequired = 'PMIPreweldsRequired',
  PONumber = 'PONumber',
  PostCladBaseMetalUTSpecification = 'PostCladBaseMetalUTSpecification',
  PostCladBaseMetalUTSpecificationNotes = 'PostCladBaseMetalUTSpecificationNotes',
  PostCladBondUTSpecification = 'PostCladBondUTSpecification',
  PostCladBondUTSpecificationNotes = 'PostCladBondUTSpecificationNotes',
  PrebondImpacts = 'PrebondImpacts',
  PreBondImpactsTestLocation = 'PreBondImpactsTestLocation',
  PreBondImpactsTestDirection = 'PreBondImpactsTestDirection',
  PreBondImpactsTestUOM = 'PreBondImpactsTestUOM',
  PreBondImpactsTestAVG = 'PreBondImpactsTestAVG',
  PreBondImpactsTestMIN = 'PreBondImpactsTestMIN',
  PreBondImpactsTestTemperature = 'PreBondImpactsTestTemperature',
  PreBondImpactsTestTemperatureUOM = 'PreBondImpactsTestTemperatureUOM',
  PrecladBaseMetalUT = 'PrecladBaseMetalUT',
  PrecladBaseMetalUTOptions = 'PrecladBaseMetalUTOptions',
  PrecladBaseMetalUTNotes = 'PrecladBaseMetalUTNotes',
  PrecladBaseMetalUTType = 'PrecladBaseMetalUTType',
  PricePerWeight = 'PricePerWeight',
  ProductTagging = 'ProductTagging',
  PurchaseAnvilFreightPerWeight = 'PurchaseAnvilFreightPerWeight',
  PurchaseAnvilPrice = 'PurchaseAnvilPrice',
  PurchaseAnvilWeight = 'PurchaseAnvilWeight',
  PurchaseAnvilWidth = 'PurchaseAnvilWidth',
  PurchaseAnvilLength = 'PurchaseAnvilLength',
  PurchaseAnvilTK = 'PurchaseAnvilTK',
  PurchaseCladderFreightPerWeight = 'PurchaseCladderFreightPerWeight',
  PurchaseCladderPrice = 'PurchaseCladderPrice',
  PurchaseCladderWeight = 'PurchaseCladderWeight',
  PurchaseCladWidth = 'PurchaseCladWidth',
  PurchaseCladLength = 'PurchaseCladLength',
  PurchaseCladTK = 'PurchaseCladTK',
  PurchaseBackerFreightPerWeight = 'PurchaseBackerFreightPerWeight',
  PurchaseBackerPrice = 'PurchaseBackerPrice',
  PurchaseBackerWeight = 'PurchaseBackerWeight',
  PurchaseBaseWidth = 'PurchaseBaseWidth',
  PurchaseBaseLength = 'PurchaseBaseLength',
  PurchaseBaseTK = 'PurchaseBaseTK',
  QuantityForLeftoverCalculation = 'QuantityForLeftoverCalculation',
  Radiography = 'Radiography',
  SeamWeld = 'SeamWeld',
  ShearTestUOM = 'ShearTestUOM',
  ShearTestValue = 'ShearTestValue',
  ShippingStreet = 'ShippingStreet',
  ShippingCity = 'ShippingCity',
  ShippingState = 'ShippingState',
  ShippingPostalCode = 'ShippingPostalCode',
  ShippingCountry = 'ShippingCountry',
  ShipVia = 'ShipVia',
  ShipViaDetails = 'ShipViaDetails',
  ShipToAddressType = 'ShipToAddressType',
  Sulphur = 'Sulphur',
  ThroughThicknessTensilePerSA770 = 'ThroughThicknessTensilePerSA770',
  VacuumDegassed = 'VacuumDegassed',
  XFactorSA387S62 = 'XFactorSA387S62',
  AdjPrice = 'AdjPrice',
  Alloc = 'Alloc',
  Anvil = 'Anvil',
  AnvilArea = 'AnvilArea',
  AnvilDimensionAdder = 'AnvilDimensionAdder',
  AnvilEdgeAllowanceAddition = 'AnvilEdgeAllowanceAddition',
  AnvilLength = 'AnvilLength',
  AnvilNeeded = 'AnvilNeeded',
  AnvilTK = 'AnvilTK',
  AnvilEdgeAllowanceRequirementsGroup = 'AnvilEdgeAllowanceRequirementsGroup',
  AnvilRequirementsGroup = 'AnvilRequirementsGroup',
  AnvilPricePerWeight = 'AnvilPricePerWeight',
  AnvilFreightPerWeight = 'AnvilFreightPerWeight',
  WeightAnvil = 'WeightAnvil',
  AnvilDaysForTransport = 'AnvilDaysForTransport',
  AnvilWidth = 'AnvilWidth',
  AreaBacker = 'AreaBacker',
  AreaCladder = 'AreaCladder',
  AreaSQM = 'AreaSQM',
  AssemblyGroupPlate = 'AssemblyGroupPlate',
  BaseCost = 'BaseCost',
  BaseCostCustomerSupplied = 'BaseCostCustomerSupplied',
  BaseFreight = 'BaseFreight',
  BaseFreightCustomerSupplied = 'BaseFreightCustomerSupplied',
  BaseFreightPerWeight = 'BaseFreightPerWeight',
  BaseLength = 'BaseLength',
  BaseMetal = 'BaseMetal',
  BaseMetalLengthAdder = 'BaseMetalLengthAdder',
  BaseMetalPricing = 'BaseMetalPricing',
  BaseMetalPricingByProposalType = 'BaseMetalPricingByProposalType',
  BaseMetalProposalType = 'BaseMetalProposalType',
  BaseMetalUT = 'BaseMetalUT',
  BaseMin = 'BaseMin',
  BaseNom = 'BaseNom',
  BasePricePerWeight = 'BasePricePerWeight',
  BaseThicknessAddition = 'BaseThicknessAddition',
  BaseTK = 'BaseTK',
  BaseTKForPricing = 'BaseTKForPricing',
  BaseType = 'BaseType',
  BaseWeight = 'BaseWeight',
  BaseWeightPerPC = 'BaseWeightPerPC',
  BaseWidth = 'BaseWidth',
  BondMetalUT = 'BondMetalUT',
  BoosterAllowance = 'BoosterAllowance',
  BoosterAllowanceGroup = 'BoosterAllowanceGroup',
  Certificate = 'Certificate',
  BackerDaysForTransport = 'BackerDaysForTransport',
  CladderDaysForTransport = 'CladderDaysForTransport',
  CladderLengthSW = 'CladderLengthSW',
  CladderWidthSW = 'CladderWidthSW',
  CladCost = 'CladCost',
  CladCostCustomerSupplied = 'CladCostCustomerSupplied',
  CladFreight = 'CladFreight',
  CladFreightCustomerSupplied = 'CladFreightCustomerSupplied',
  CladFreightPerWeight = 'CladFreightPerWeight',
  CladLength = 'CladLength',
  CladMetal = 'CladMetal',
  CladMetalPricing = 'CladMetalPricing',
  CladMetalPricingByProposalType = 'CladMetalPricingByProposalType',
  CladMetalProposalType = 'CladMetalProposalType',
  CladMin = 'CladMin',
  CladNom = 'CladNom',
  CladOverhang = 'CladOverhang',
  CladOverhangThin = 'CladOverhangThin',
  CladOverhangThreshold = 'CladOverhangThreshold',
  CladPricePerWeight = 'CladPricePerWeight',
  Clads = 'Clads',
  CladThicknessAddition = 'CladThicknessAddition',
  CladTK = 'CladTK',
  CladTKForPricing = 'CladTKForPricing',
  CladType = 'CladType',
  CladWeight = 'CladWeight',
  CladWeightPerPC = 'CladWeightPerPC',
  CladWidth = 'CladWidth',
  ConfigurationIDAnvil = 'ConfigurationIDAnvil',
  ConfigurationIDBacker = 'ConfigurationIDBacker',
  ConfigurationID = 'ConfigurationID',
  ConfigurationIDSW = 'ConfigurationIDSW',
  CrownToPetals = 'CrownToPetals',
  CrownToPetalsNotes = 'CrownToPetalsNotes',
  CylinderEdgePreparation = 'CylinderEdgePreparation',
  CylinderNotes = 'CylinderNotes',
  CylinderType = 'CylinderType',
  CylinderVendor = 'CylinderVendor',
  CylinderIDorOD = 'CylinderIDorOD',
  ContPercentage = 'ContPercentage',
  ContPerSQM = 'ContPerSQM',
  CosmeticGrindingCladThicknessAddition = 'CosmeticGrindingCladThicknessAddition',
  CosmeticGrindingSpecifications = 'CosmeticGrindingSpecifications',
  TotalCost = 'TotalCost',
  CustomerProvidedBase = 'CustomerProvidedBase',
  CustomerProvidedClad = 'CustomerProvidedClad',
  CustomerPO = 'CustomerPO',
  CutMethod = 'CutMethod',
  CutMethodCost = 'CutMethodCost',
  DaysForTransportSW = 'DaysForTransportSW',
  DetailArea = 'DetailArea',
  DetailQuantity = 'DetailQuantity',
  Diameter = 'Diameter',
  Dimensions = 'Dimensions',
  EdgeAllowance = 'EdgeAllowance',
  EdgeAllowanceGroup = 'EdgeAllowanceGroup',
  EstimatedShippingTotalSW = 'EstimatedShippingTotalSW',
  ExplosiveItem = 'ExplosiveItem',
  ExplosiveLoadCost = 'ExplosiveLoadCost',
  ExplosiveLoadGroups = 'ExplosiveLoadGroups',
  ExplosiveLoadWeight = 'ExplosiveLoadWeight',
  HeatTreatCombinationGroup = 'HeatTreatCombination',
  HeatTreatmentConsumableCost = 'HeatTreatmentConsumableCost',
  HeatTreatmentCost = 'HeatTreatmentCost',
  SpecificationTestCost = 'SpecificationTestCost',
  SpecificationTestLaborHours = 'SpecificationTestLaborHours',
  WeldingConsumableCost = 'WeldingConsumableCost',
  ConsumableCost = 'ConsumableCost',
  EdgePreparation = 'EdgePreparation',
  FinishedArea = 'FinishedArea',
  FinishedBaseTK = 'FinishedBaseTK',
  FinishedCladTK = 'FinishedCladTK',
  FlatteningLaborCost = 'FlatteningLaborCost',
  FlatteningLaborHours = 'FlatteningLaborHours',
  FlyerArea = 'FlyerArea',
  FlyerLength = 'FlyerLength',
  FlyerNeeded = 'FlyerNeeded',
  FlyerWidth = 'FlyerWidth',
  FreightChargeToCustomerPer = 'FreightChargeToCustomerPer',
  FreightChargeToRollerPer = 'FreightChargeToRollerPer',
  FreightOut = 'FreightOut',
  GeneralLaborCost = 'GeneralLaborCost',
  HeadEdgePreparation = 'HeadEdgePreparation',
  HeadForming = 'HeadForming',
  HeadFormingType = 'HeadFormingType',
  HeadFreight = 'HeadFreight',
  HeadFreightPerWeight = 'HeadFreightPerWeight',
  IDorOD = 'IDorOD',
  Item = 'Item',
  ItemDescription = 'ItemDescription',
  ItemType = 'ItemType',
  KerfAllowance = 'KerfAllowance',
  LeadTime = 'LeadTime',
  LeftoverQuantity = 'LeftoverQuantity',
  Length = 'Length',
  Line = 'Line',
  Location = 'Location',
  Long = 'Long',
  Machining = 'Machining',
  MetalClassAnvil = 'MetalClassAnvil',
  NominalThicknessSW = 'NominalThicknessSW',
  ThicknessminimSW = 'ThicknessminimSW',
  ThicknessminmmSW = 'ThicknessminmmSW',
  OSCost = 'OSCost',
  OpenEnd = 'OpenEnd',
  OpenEndNotes = 'OpenEndNotes',
  PanelLocationSW = 'PanelLocationSW',
  PanelNumberSW = 'PanelNumberSW',
  ParentLine = 'ParentLine',
  PeggedSupply = 'PeggedSupply',
  PercentAllocated = 'PercentAllocated',
  PetalToPetal = 'PetalToPetal',
  PetalToPetalNotes = 'PetalToPetalNotes',
  Piece = 'Piece',
  PlateLengthSupplyToFormer = 'PlateLengthSupplyToFormer',
  PlateWidthSupplyToFormer = 'PlateWidthSupplyToFormer',
  PostCladTestingOptions = 'PostCladTestingOptions',
  PreheattoCutAnvil = 'PreheattoCutAnvil',
  PreheattoCutSW = 'PreheattoCutSW',
  PreheattoTorchCutBacker = 'PreheattoTorchCutBacker',
  PreheattoTorchCutCladder = 'PreheattoTorchCutCladder',
  TorchCutCladder = 'TorchCutCladder',
  TorchCutBacker = 'TorchCutBacker',
  TorchCutAnvil1 = 'TorchCutAnvil1',
  PriceHead = 'PriceHead',
  PriceProduct = 'PriceProduct',
  PriceQuantity = 'PriceQuantity',
  ProductTag = 'ProductTag',
  Pullfromstockbacker = 'Pullfromstockbacker',
  PullfromstockBKAnvil = 'PullfromstockBKAnvil',
  Pullfromstockcladder = 'Pullfromstockcladder',
  PullfromstockSW = 'PullfromstockSW',
  PullfromstockQuantitySW = 'PullfromstockQuantitySW',
  PurchaseBK = 'PurchaseBK',
  PurchaseBKAnvil = 'PurchaseBKAnvil',
  PurchaseCL = 'PurchaseCL',
  PurchaseSW = 'PurchaseSW',
  PurchaseQuantitySW = 'PurchaseQuantitySW',
  QuantityPerCylinder = 'QuantityPerCylinder',
  QuantityFlyer = 'QuantityFlyer',
  QuantityAnvil = 'QuantityAnvil',
  RadiographyClad = 'RadiographyClad',
  VendorAnvil = 'VendorAnvil',
  RawMaterialVendorBacker = 'RawMaterialVendorBacker',
  RawMaterialVendorCladder = 'RawMaterialVendorCladder',
  RawMaterialVendorSW = 'RawMaterialVendorSW',
  RollingCostPerCylinder = 'RollingCostPerCylinder',
  Shape = 'Shape',
  SharedCladdedPlate = 'SharedCladdedPlate',
  ShipDate = 'ShipDate',
  ShipDateAnvil = 'ShipDateAnvil',
  ShipDateBase = 'ShipDateBase',
  ShipDateCladder = 'ShipDateCladder',
  ShipDateSW = 'ShipDateSW',
  ShipWeight = 'ShipWeight',
  ShowDetail = 'ShowDetail',
  SizingProduct = 'SizingProduct',
  SizingQuantity = 'SizingQuantity',
  Specification = 'Specification',
  SpecificationAdder = 'SpecificationAdder',
  SquareFinishedArea = 'SquareFinishedArea',
  StraightFlange = 'StraightFlange',
  SWRawMaterialCostSW = 'SWRawMaterialCostSW',
  TimeToRollWeeks = 'TimeToRollWeeks',
  TotalAdjPrice = 'TotalAdjPrice',
  TotalFinishedGoodArea = 'TotalFinishedGoodArea',
  TotalFreight = 'TotalFreight',
  TotalFreightPerWeight = 'TotalFreightPerWeight',
  TotalOSCost = 'TotalOSCost',
  TotalRawTK = 'TotalRawTK',
  TotalTK = 'TotalTK',
  TotalWeightPerPC = 'TotalWeightPerPC',
  TransitTimeToRollerWeeks = 'TransitTimeToRollerWeeks',
  TubesheetMachining = 'TubesheetMachining',
  UnitOfMeasure = 'UnitOfMeasure',
  Weight = 'Weight',
  WeightSW = 'WeightSW',
  Welding = 'Welding',
  WeldingLength = 'WeldingLength',
  WeldingWidth = 'WeldingWidth',
  Wide = 'Wide',
  Width = 'Width',
  Witnessing = 'Witnessing',
  BackerMetalHTCostOfEvent = 'BackerMetalHTCostOfEvent',
  BackerMetalHTDescriptionOfEvent = 'BackerMetalHTDescriptionOfEvent',
  BackerMetalHTDurationOfEventDays = 'BackerMetalHTDurationOfEventDays',
  BackerMetalHTInboundShippingCost = 'BackerMetalHTInboundShippingCost',
  BackerMetalHTLeadTimeWeeks = 'BackerMetalHTLeadTimeWeeks',
  BackerMetalHTNotes = 'BackerMetalHTNotes',
  BackerMetalHTOutboundShipping = 'BackerMetalHTOutboundShipping',
  BackerMetalHTOutboundShippingCost = 'BackerMetalHTOutboundShippingCost',
  BackerMetalHTTotalCost = 'BackerMetalHTTotalCost',
  BackerMetalHTTotalCostOfEvent = 'BackerMetalHTTotalCostOfEvent',
  BackerMetalHTTotalInboundShippingCost = 'BackerMetalHTTotalInboundShippingCost',
  BackerMetalHTShipToCustomer = 'BackerMetalHTShipToCustomer',
  BackerMetalHTTotalOutboundShippingCost = 'BackerMetalHTTotalOutboundShippingCost',
  BackerMetalHTTotalTransitTimeDays = 'BackerMetalHTTotalTransitTimeDays',
  BackerMetalHTType = 'BackerMetalHTType',
  BackerMetalHTVendor = 'BackerMetalHTVendor',
  CanRollingCostOfEvent = 'CanRollingCostOfEvent',
  CanRollingDescriptionOfEvent = 'CanRollingDescriptionOfEvent',
  CanRollingDurationOfEventDays = 'CanRollingDurationOfEventDays',
  CanRollingInboundShippingCost = 'CanRollingInboundShippingCost',
  CanRollingLeadTimeWeeks = 'CanRollingLeadTimeWeeks',
  CanRollingNotes = 'CanRollingNotes',
  CanRollingOutboundShippingCost = 'CanRollingOutboundShippingCost',
  CanRollingShipToCustomer = 'CanRollingShipToCustomer',
  CanRollingTotalCost = 'CanRollingTotalCost',
  CanRollingTotalInboundShippingCost = 'CanRollingTotalInboundShippingCost',
  CanRollingTotalOutboundShippingCost = 'CanRollingTotalOutboundShippingCost',
  CanRollingTotalTransitTimeDays = 'CanRollingTotalTransitTimeDays',
  CanRollingVendor = 'CanRollingVendor',
  CladderMetalHTCostOfEvent = 'CladderMetalHTCostOfEvent',
  CladderMetalHTDescriptionOfEvent = 'CladderMetalHTDescriptionOfEvent',
  CladderMetalHTDurationOfEventDays = 'CladderMetalHTDurationOfEventDays',
  CladderMetalHTInboundShippingCost = 'CladderMetalHTInboundShippingCost',
  CladderMetalHTLeadTimeWeeks = 'CladderMetalHTLeadTimeWeeks',
  CladderMetalHTNotes = 'CladderMetalHTNotes',
  CladderMetalHTOutboundShippingCost = 'CladderMetalHTOutboundShippingCost',
  CladderMetalHTShipToCustomer = 'CladderMetalHTShipToCustomer',
  CladderMetalHTTotalCost = 'CladderMetalHTTotalCost',
  CladderMetalHTTotalCostOfEvent = 'CladderMetalHTTotalCostOfEvent',
  CladderMetalHTTotalInboundShippingCost = 'CladderMetalHTTotalInboundShippingCost',
  CladderMetalHTTotalOutboundShippingCost = 'CladderMetalHTTotalOutboundShippingCost',
  CladderMetalHTTotalTransitTimeDays = 'CladderMetalHTTotalTransitTimeDays',
  CladderMetalHTType = 'CladderMetalHTType',
  CladderMetalHTVendor = 'CladderMetalHTVendor',
  CladderSupplier = 'CladderSupplier',
  CostPerLB = 'CostPerLB',
  CostPerLot = 'CostPerLot',
  CreatedById = 'CreatedById',
  CurrencyIsoCode = 'CurrencyIsoCode',
  CutMethodHeadBlank = 'CutMethodHeadBlank',
  CuttingCostOfEvent = 'CuttingCostOfEvent',
  CuttingDescriptionOfEvent = 'CuttingDescriptionOfEvent',
  CuttingDurationOfEventDays = 'CuttingDurationOfEventDays',
  CuttingInboundShippingCost = 'CuttingInboundShippingCost',
  CuttingLeadTimeWeeks = 'CuttingLeadTimeWeeks',
  CuttingOutboundShippingCost = 'CuttingOutboundShippingCost',
  CuttingShipToCustomer = 'CuttingShipToCustomer',
  CuttingTotalCost = 'CuttingTotalCost',
  CuttingTotalInboundShippingCost = 'CuttingTotalInboundShippingCost',
  CuttingTotalOutboundShippingCost = 'CuttingTotalOutboundShippingCost',
  CuttingTotalTransitTimeDays = 'CuttingTotalTransitTimeDays',
  CuttingVendor = 'CuttingVendor',
  DurationOfEventDays = 'DurationOfEventDays',
  FlatteningBKCostOfEvent = 'FlatteningBKCostOfEvent',
  FlatteningBKDescriptionOfEvent = 'FlatteningBKDescriptionOfEvent',
  FlatteningBKDurationOfEventDays = 'FlatteningBKDurationOfEventDays',
  FlatteningBKInboundShippingCost = 'FlatteningBKInboundShippingCost',
  FlatteningBKLeadTimeWeeks = 'FlatteningBKLeadTimeWeeks',
  FlatteningBKNotes = 'FlatteningBKNotes',
  FlatteningBKOutboundShippingCost = 'FlatteningBKOutboundShippingCost',
  FlatteningBKShipToCustomer = 'FlatteningBKShipToCustomer',
  FlatteningBKTotalCost = 'FlatteningBKTotalCost',
  FlatteningBKTotalInboundShippingCost = 'FlatteningBKTotalInboundShippingCost',
  FlatteningBKTotalOutboundShippingCost = 'FlatteningBKTotalOutboundShippingCost',
  FlatteningBKTotalTransitTimeDays = 'FlatteningBKTotalTransitTimeDays',
  FlatteningBKVendor = 'FlatteningBKVendor',
  FlatteningCostOfEvent = 'FlatteningCostOfEvent',
  FlatteningDescriptionOfEvent = 'FlatteningDescriptionOfEvent',
  FlatteningDurationOfEventDays = 'FlatteningDurationOfEventDays',
  FlatteningInboundShippingCost = 'FlatteningInboundShippingCost',
  FlatteningLeadTimeWeeks = 'FlatteningLeadTimeWeeks',
  FlatteningNotes = 'FlatteningNotes',
  FlatteningOutboundShippingCost = 'FlatteningOutboundShippingCost',
  FlatteningShipToCustomer = 'FlatteningShipToCustomer',
  FlatteningTotalCost = 'FlatteningTotalCost',
  FlatteningTotalInboundShippingCost = 'FlatteningTotalInboundShippingCost',
  FlatteningTotalOutboundShippingCost = 'FlatteningTotalOutboundShippingCost',
  FlatteningTotalTransitTimeDays = 'FlatteningTotalTransitTimeDays',
  FlatteningVendor = 'FlatteningVendor',
  Head_Diameter = 'Head_Diameter',
  HeadBlankFlattening = 'HeadBlankFlattening',
  HeadBlankFlatteningNotes = 'HeadBlankFlatteningNotes',
  HeadContourToleranceHTOptions = 'HeadContourToleranceHTOptions',
  HeadFormingCostPerHead = 'HeadFormingCostPerHead',
  HeadFormingDescriptionOfEvent = 'HeadFormingDescriptionOfEvent',
  HeadFormingIDvsOD = 'HeadFormingIDvsOD',
  HeadFormingLeadTimeWeeks = 'HeadFormingLeadTimeWeeks',
  HeadFormingSubcontractingCost = 'HeadFormingSubcontractingCost',
  HeadFormingSubcontractingFreightEstimate = 'HeadFormingSubcontractingFreightEstimate',
  HeadFormingTotalInboundShippingCost = 'HeadFormingTotalInboundShippingCost',
  HeadFormingTotalOutboundShippingCost = 'HeadFormingTotalOutboundShippingCost',
  HeadFormingVendor = 'HeadFormingVendor',
  HeadFormingVendorQuoteNumber = 'HeadFormingVendorQuoteNumber',
  HeadFreightCostToFormer = 'HeadFreightCostToFormer',
  HeadFreightZone = 'HeadFreightZone',
  HeadTimeToFormer = 'HeadTimeToFormer',
  HeadTimeToFormHead = 'HeadTimeToFormHead',
  HeadFormingNotes1 = 'HeadFormingNotes1',
  InspectionBKCostOfEvent = 'InspectionBKCostOfEvent',
  InspectionBKDescriptionOfEvent = 'InspectionBKDescriptionOfEvent',
  InspectionBKDurationOfEventDays = 'InspectionBKDurationOfEventDays',
  InspectionBKInboundShippingCost = 'InspectionBKInboundShippingCost',
  InspectionBKLeadTimeWeeks = 'InspectionBKLeadTimeWeeks',
  InspectionBKNotes = 'InspectionBKNotes',
  InspectionBKOutboundShippingCost = 'InspectionBKOutboundShippingCost',
  InspectionBKShipToCustomer = 'InspectionBKShipToCustomer',
  InspectionBKTotalCost = 'InspectionBKTotalCost',
  InspectionBKTotalInboundShippingCost = 'InspectionBKTotalInboundShippingCost',
  InspectionBKTotalOutboundShippingCost = 'InspectionBKTotalOutboundShippingCost',
  InspectionBKTotalTransitTimeDays = 'InspectionBKTotalTransitTimeDays',
  InspectionBKVendor = 'InspectionBKVendor',
  InspectionCLCostOfEvent = 'InspectionCLCostOfEvent',
  InspectionCLDescriptionOfEvent = 'InspectionCLDescriptionOfEvent',
  InspectionCLDurationOfEventDays = 'InspectionCLDurationOfEventDays',
  InspectionCLInboundShippingCost = 'InspectionCLInboundShippingCost',
  InspectionCLLeadTimeWeeks = 'InspectionCLLeadTimeWeeks',
  InspectionCLNotes = 'InspectionCLNotes',
  InspectionCLOutboundShippingCost = 'InspectionCLOutboundShippingCost',
  InspectionCLShipToCustomer = 'InspectionCLShipToCustomer',
  InspectionCLTotalCost = 'InspectionCLTotalCost',
  InspectionCLTotalInboundShippingCost = 'InspectionCLTotalInboundShippingCost',
  InspectionCLTotalOutboundShippingCost = 'InspectionCLTotalOutboundShippingCost',
  InspectionCLTotalTransitTimeDays = 'InspectionCLTotalTransitTimeDays',
  InspectionCLVendor = 'InspectionCLVendor',
  InspectionCostOfEvent = 'InspectionCostOfEvent',
  InspectionDescriptionOfEvent = 'InspectionDescriptionOfEvent',
  InspectionDurationOfEventDays = 'InspectionDurationOfEventDays',
  InspectionInboundShippingCost = 'InspectionInboundShippingCost',
  InspectionLeadTimeWeeks = 'InspectionLeadTimeWeeks',
  InspectionNotes = 'InspectionNotes',
  InspectionOutboundShippingCost = 'InspectionOutboundShippingCost',
  InspectionShipToCustomer = 'InspectionShipToCustomer',
  InspectionTotalCost = 'InspectionTotalCost',
  InspectionTotalInboundShippingCost = 'InspectionTotalInboundShippingCost',
  InspectionTotalOutboundShippingCost = 'InspectionTotalOutboundShippingCost',
  InspectionTotalTransitTimeDays = 'InspectionTotalTransitTimeDays',
  InspectionVendor = 'InspectionVendor',
  LastModifiedById = 'LastModifiedById',
  MachiningCostOfEvent = 'MachiningCostOfEvent',
  MachiningDescriptionOfEvent = 'MachiningDescriptionOfEvent',
  MachiningDurationOfEventDays = 'MachiningDurationOfEventDays',
  MachiningInboundShippingCost = 'MachiningInboundShippingCost',
  MachiningLeadTimeWeeks = 'MachiningLeadTimeWeeks',
  MachiningNotes = 'MachiningNotes',
  MachiningOutboundShippingCost = 'MachiningOutboundShippingCost',
  MachiningShipToCustomer = 'MachiningShipToCustomer',
  MachiningTotalCost = 'MachiningTotalCost',
  MachiningTotalInboundShippingCost = 'MachiningTotalInboundShippingCost',
  MachiningTotalOutboundShippingCost = 'MachiningTotalOutboundShippingCost',
  MachiningTotalTransitTimeDays = 'MachiningTotalTransitTimeDays',
  MachiningVendor = 'MachiningVendor',
  Name = 'Name',
  NCQuoteLineItemDetail = 'NC_Quote_Line_Item_Detail',
  Outside_Service_Type = 'Outside_Service_Type',
  PackagingCostOfEvent = 'PackagingCostOfEvent',
  PackagingDescriptionOfEvent = 'PackagingDescriptionOfEvent',
  PackagingDurationOfEventDays = 'PackagingDurationOfEventDays',
  PackagingInboundShippingCost = 'PackagingInboundShippingCost',
  PackagingLeadTimeWeeks = 'PackagingLeadTimeWeeks',
  PackagingNotes = 'PackagingNotes',
  PackagingOutboundShippingCost = 'PackagingOutboundShippingCost',
  PackagingShipToCustomer = 'PackagingShipToCustomer',
  PackagingTotalCost = 'PackagingTotalCost',
  PackagingTotalInboundShippingCost = 'PackagingTotalInboundShippingCost',
  PackagingTotalOutboundShippingCost = 'PackagingTotalOutboundShippingCost',
  PackagingTotalTransitTimeDays = 'PackagingTotalTransitTimeDays',
  PackagingVendor = 'PackagingVendor',
  PostbondHTCostOfEvent = 'PostbondHTCostOfEvent',
  PostbondHTDescriptionOfEvent = 'PostbondHTDescriptionOfEvent',
  PostbondHTDurationOfEventDays = 'PostbondHTDurationOfEventDays',
  PostbondHTInboundShippingCost = 'PostbondHTInboundShippingCost',
  PostbondHTLeadTimeWeeks = 'PostbondHTLeadTimeWeeks',
  PostbondHTNotes = 'PostbondHTNotes',
  PostbondHTOutboundShippingCost = 'PostbondHTOutboundShippingCost',
  PostbondHTShipToCustomer = 'PostbondHTShipToCustomer',
  PostbondHTTotalCost = 'PostbondHTTotalCost',
  PostbondHTTotalCostOfEvent = 'PostbondHTTotalCostOfEvent',
  PostbondHTTotalInboundShippingCost = 'PostbondHTTotalInboundShippingCost',
  PostbondHTTotalOutboundShippingCost = 'PostbondHTTotalOutboundShippingCost',
  PostbondHTTotalTransitTimeDays = 'PostbondHTTotalTransitTimeDays',
  PostbondHTType = 'PostbondHTType',
  PostbondHTVendor = 'PostbondHTVendor',
  SimulateHeatTreatmentAtVendor = 'SimulateHeatTreatmentAtVendor',
  SimulateHeatTreatmentHeadForming = 'SimulateHeatTreatmentHeadForming',
  SimulationHTCoolingCycle1 = 'SimulationHTCoolingCycle1',
  SimulationHTCoolingCycle2 = 'SimulationHTCoolingCycle2',
  SimulationHTCoolingCycle3 = 'SimulationHTCoolingCycle3',
  SimulationHTCoolingCycle4 = 'SimulationHTCoolingCycle4',
  SimulationHTCoolingCycle5 = 'SimulationHTCoolingCycle5',
  SimulationHTCycle1 = 'SimulationHTCycle1',
  SimulationHTCycle2 = 'SimulationHTCycle2',
  SimulationHTCycle3 = 'SimulationHTCycle3',
  SimulationHTCycle4 = 'SimulationHTCycle4',
  SimulationHTCycle5 = 'SimulationHTCycle5',
  SimulationHTCycleTemp1 = 'SimulationHTCycleTemp1',
  SimulationHTCycleTemp2 = 'SimulationHTCycleTemp2',
  SimulationHTCycleTemp3 = 'SimulationHTCycleTemp3',
  SimulationHTCycleTemp4 = 'SimulationHTCycleTemp4',
  SimulationHTCycleTemp5 = 'SimulationHTCycleTemp5',
  SimulationHTCycleTime1 = 'SimulationHTCycleTime1',
  SimulationHTCycleTime2 = 'SimulationHTCycleTime2',
  SimulationHTCycleTime3 = 'SimulationHTCycleTime3',
  SimulationHTCycleTime4 = 'SimulationHTCycleTime4',
  SimulationHTCycleTime5 = 'SimulationHTCycleTime5',
  SimulationHTTempUOMCycle1 = 'SimulationHTTempUOMCycle1',
  SimulationHTTempUOMCycle2 = 'SimulationHTTempUOMCycle2',
  SimulationHTTempUOMCycle3 = 'SimulationHTTempUOMCycle3',
  SimulationHTTempUOMCycle4 = 'SimulationHTTempUOMCycle4',
  SimulationHTTempUOMCycle5 = 'SimulationHTTempUOMCycle5',
  SPWHTGroupACycle1 = 'SPWHTGroupACycle1',
  SPWHTGroupACycle2 = 'SPWHTGroupACycle2',
  SPWHTGroupACycle3 = 'SPWHTGroupACycle3',
  SPWHTGroupAToBePerformed = 'SPWHTGroupAToBePerformed',
  SPWHTGroupBCycle1 = 'SPWHTGroupBCycle1',
  SPWHTGroupBCycle2 = 'SPWHTGroupBCycle2',
  SPWHTGroupBCycle3 = 'SPWHTGroupBCycle3',
  SPWHTGroupBToBePerformed = 'SPWHTGroupBToBePerformed',
  SPWHTGroupCCycle1 = 'SPWHTGroupCCycle1',
  SPWHTGroupCCycle2 = 'SPWHTGroupCCycle2',
  SPWHTGroupCCycle3 = 'SPWHTGroupCCycle3',
  SPWHTGroupCToBePerformed = 'SPWHTGroupCToBePerformed',
  SPWHTHeatingAndCoolingRateAbove = 'SPWHTHeatingAndCoolingRateAbove',
  SPWHTHeatingAndCoolingRateAboveUOM = 'SPWHTHeatingAndCoolingRateAboveUOM',
  SPWHTMaximumCoolingRateHour = 'SPWHTMaximumCoolingRateHour',
  SPWHTMaximumCoolingRateHourUOM = 'SPWHTMaximumCoolingRateHourUOM',
  SPWHTMaximumHeatingRateHour = 'SPWHTMaximumHeatingRateHour',
  SPWHTMaximumHeatingRateHourUOM = 'SPWHTMaximumHeatingRateHourUOM',
  StandardCladWidthDifference = 'StandardCladWidthDifference',
  SubcontractHeadForming = 'SubcontractHeadForming',
  TestingBKCostOfEvent = 'TestingBKCostOfEvent',
  TestingBKDescriptionOfEvent = 'TestingBKDescriptionOfEvent',
  TestingBKDurationOfEventDays = 'TestingBKDurationOfEventDays',
  TestingBKInboundShippingCost = 'TestingBKInboundShippingCost',
  TestingBKLeadTimeWeeks = 'TestingBKLeadTimeWeeks',
  TestingBKNotes = 'TestingBKNotes',
  TestingBKOutboundShippingCost = 'TestingBKOutboundShippingCost',
  TestingBKShipToCustomer = 'TestingBKShipToCustomer',
  TestingBKTotalCost = 'TestingBKTotalCost',
  TestingBKTotalInboundShippingCost = 'TestingBKTotalInboundShippingCost',
  TestingBKTotalOutboundShippingCost = 'TestingBKTotalOutboundShippingCost',
  TestingBKTotalTransitTimeDays = 'TestingBKTotalTransitTimeDays',
  TestingBKVendor = 'TestingBKVendor',
  TestingCLCostOfEvent = 'TestingCLCostOfEvent',
  TestingCLDescriptionOfEvent = 'TestingCLDescriptionOfEvent',
  TestingCLDurationOfEventDays = 'TestingCLDurationOfEventDays',
  TestingCLInboundShippingCost = 'TestingCLInboundShippingCost',
  TestingCLLeadTimeWeeks = 'TestingCLLeadTimeWeeks',
  TestingCLNotes = 'TestingCLNotes',
  TestingCLOutboundShippingCost = 'TestingCLOutboundShippingCost',
  TestingCLShipToCustomer = 'TestingCLShipToCustomer',
  TestingCLTotalCost = 'TestingCLTotalCost',
  TestingCLTotalInboundShippingCost = 'TestingCLTotalInboundShippingCost',
  TestingCLTotalOutboundShippingCost = 'TestingCLTotalOutboundShippingCost',
  TestingCLTotalTransitTimeDays = 'TestingCLTotalTransitTimeDays',
  TestingCLVendor = 'TestingCLVendor',
  TestingCostOfEvent = 'TestingCostOfEvent',
  TestingDescriptionOfEvent = 'TestingDescriptionOfEvent',
  TestingDurationOfEventDays = 'TestingDurationOfEventDays',
  TestingInboundShippingCost = 'TestingInboundShippingCost',
  TestingLeadTimeWeeks = 'TestingLeadTimeWeeks',
  TestingNotes = 'TestingNotes',
  TestingOutboundShippingCost = 'TestingOutboundShippingCost',
  TestingShipToCustomer = 'TestingShipToCustomer',
  TestingTotalCost = 'TestingTotalCost',
  TestingTotalInboundShippingCost = 'TestingTotalInboundShippingCost',
  TestingTotalOutboundShippingCost = 'TestingTotalOutboundShippingCost',
  TestingTotalTransitTimeDays = 'TestingTotalTransitTimeDays',
  TestingVendor = 'TestingVendor',
  TubesheetMachiningManHours = 'TubesheetMachiningManHours',
  TubesheetMachiningTotalCost = 'TubesheetMachiningTotalCost',
  WaterJetCuttingCostOfEvent = 'WaterJetCuttingCostOfEvent',
  WaterJetCuttingDescriptionOfEvent = 'WaterJetCuttingDescriptionOfEvent',
  WaterJetCuttingDurationOfEventDays = 'WaterJetCuttingDurationOfEventDays',
  WaterJetCuttingInboundShippingCost = 'WaterJetCuttingInboundShippingCost',
  WaterJetCuttingLeadTimeWeeks = 'WaterJetCuttingLeadTimeWeeks',
  WaterJetCuttingNotes = 'WaterJetCuttingNotes',
  WaterJetCuttingOutboundShippingCost = 'WaterJetCuttingOutboundShippingCost',
  WaterJetCuttingShipToCustomer = 'WaterJetCuttingShipToCustomer',
  WaterJetCuttingTotalCost = 'WaterJetCuttingTotalCost',
  WaterJetCuttingTotalInboundShippingCost = 'WaterJetCuttingTotalInboundShippingCost',
  WaterJetCuttingTotalOutboundShippingCost = 'WaterJetCuttingTotalOutboundShippingCost',
  WaterJetCuttingTotalTransitTimeDays = 'WaterJetCuttingTotalTransitTimeDays',
  WaterJetCuttingVendor = 'WaterJetCuttingVendor',
  WeldingCostOfEvent = 'WeldingCostOfEvent',
  WeldingDescriptionOfEvent = 'WeldingDescriptionOfEvent',
  WeldingDurationOfEventDays = 'WeldingDurationOfEventDays',
  WeldingInboundShippingCost = 'WeldingInboundShippingCost',
  WeldingLeadTimeWeeks = 'WeldingLeadTimeWeeks',
  WeldingNotes = 'WeldingNotes',
  WeldingOutboundShippingCost = 'WeldingOutboundShippingCost',
  WeldingShipToCustomer = 'WeldingShipToCustomer',
  WeldingTotalCost = 'WeldingTotalCost',
  WeldingTotalInboundShippingCost = 'WeldingTotalInboundShippingCost',
  WeldingTotalOutboundShippingCost = 'WeldingTotalOutboundShippingCost',
  WeldingTotalTransitTimeDays = 'WeldingTotalTransitTimeDays',
  WeldingVendor = 'WeldingVendor',
  CladLengthToFitQuantity = 'CladLengthToFitQuantity',
  CladWidthToFitQuantity = 'CladWidthToFitQuantity',
  XRayServicesCostOfEvent = 'XRayServicesCostOfEvent',
  XRayServicesDescriptionOfEvent = 'XRayServicesDescriptionOfEvent',
  XRayServicesDurationOfEventDays = 'XRayServicesDurationOfEventDays',
  XRayServicesInboundShippingCost = 'XRayServicesInboundShippingCost',
  XRayServicesSort = 'XRayServicesSort',
  XRayServicesLeadTimeWeeks = 'XRayServicesLeadTimeWeeks',
  XRayServicesNotes = 'XRayServicesNotes',
  XRayServicesOutboundShippingCost = 'XRayServicesOutboundShippingCost',
  XRayServicesShipToCustomer = 'XRayServicesShipToCustomer',
  XRayServicesTotalCost = 'XRayServicesTotalCost',
  XRayServicesTotalInboundShippingCost = 'XRayServicesTotalInboundShippingCost',
  XRayServicesTotalOutboundShippingCost = 'XRayServicesTotalOutboundShippingCost',
  XRayServicesTotalTransitTimeDays = 'XRayServicesTotalTransitTimeDays',
  XRayServicesVendor = 'XRayServicesVendor',
  StressReliefHT = 'StressReliefHT',
  NCInhouseStressReliefDetails = 'NCInhouseStressReliefDetails',
  NCInhouseStressReliefTimeMin = 'NCInhouseStressRefliefTimeMin',
  NCInHouseStressReliefTimeMax = 'NCInHouseStressReliefTimeMax',
  NCInhouseStressReliefTemp = 'NCInhouseStressReliefTemp',
  PreHeatRequiredforCladding = 'PreHeatRequiredforCladding',
  PreHeatReqforcladdingMinTempforPreheat = 'PreHeatReqforcladdingMinTempforPreheat',
  PreHeatReqforcuttingmintempforpreheat = 'PreHeatReqforcuttingmintempforpreheat',
  PreheattoCut = 'PreheattoCut',
  PreHeatReqforcutting = 'PreHeatReqforcutting',
  PreHeatTimingGroup = 'PreHeatTimingGroup',
  PostCladFlattening = 'PostCladFlattening',
  PostCladCuttingTolerance = 'PostCladCuttingTolerance',
  SurfaceTreatment = 'SurfaceTreatment',
  SurfaceTreatmentNotes = 'SurfaceTreatmentNotes',
  NCInHousePackaging = 'NCInHousePackaging',
  TotalManHours = 'TotalManHours',
  TotalLaborCost = 'TotalLaborCost',
  UnitCost = 'UnitCost',
  WeldingLocation = 'WeldingLocation',
  NumberOfPiecesInHead = 'NumberOfPiecesInHead',
  ASItemDescription = 'ASItemDescription',
}

export enum QuoteLineItemFieldUnitTypes {
  Currency = 'Currency',
  CurrencyPerWeight = 'CurrencyPerWeight',
  Dimensions = 'Dimensions',
  ItemDescription = 'ItemDescription',
  LinearDistanceLW = 'LinearDistanceLW',
  LinearDistanceTK = 'LinearDistanceTK',
  Percentage = 'Percentage',
  Weeks = 'Weeks',
  Weight = 'Weight',
  Date = 'Date',
}

export enum QuoteLineItemAdditionalOptions {
  HeatTreatment = 'Heat Treatment',
  CosmeticGrinding = 'Cosmetic Grinding',
}

export enum DefaultSurfaceTreatmentSelections {
  Standard = 'Standard',
  CosmeticGrinding = 'Cosmetic Grinding',
}

export enum DefaultFlatteningSelections {
  Plate = 'Standard Plate Flatness',
  Tubesheet = 'Standard Tubesheet Flatness',
  None = 'None',
}

export enum QuoteLineItemCertificates {
  PerDMC100 = 'Per DMC 100',
  EN1020431 = 'EN10204-3.1',
  EN1020432NobelClad = 'EN10204-3.2 3rd Party Inspector Selected and Paid by NobelClad',
  EN1020432Customer = 'EN10204-3.2 3rd Party Inspector Selected and Paid by Customer',
}

export enum PostCladTestingOptionsAdderTypes {
  AnyCombinationOf12345 = 'Any combination of (1,2,3,4,5)',
  AnyOrNoneOf12345And6 = 'Any or/none of (1,2,3,4,5) + 6',
  AnyOrNoneOf12345And7 = 'Any or/none of (1,2,3,4,5) + 7',
  AnyOrNoneOf12345And7And8 = 'Any or/none of (1,2,3,4,5) + 7 + 8',
  AnyOrNoneOf12345And8 = 'Any or/none of (1,2,3,4,5) + 8',
  ShearTestOnly = 'Shear Test Only',
}

export enum QuoteActions {
  Save = 'Save',
  SubmitQuote = 'Submit Quote',
  CloneQuote = 'Clone Quote',
  Lock = 'Lock',
  SendQuoteToCustomer = 'Send Quote to Customer',
  SubmitToPlanning = 'Submit to Planning',
  RevertToDraft = 'Revert to Draft',
}

export enum GuardRailsLongDescription {
  WidthLengthRatio = 'The length exceeds the conventional values for the length manufacturing standards.',
  MaxLength = 'The length exceeds the standard length limit.',
  MaxWidth = 'The width exceeds the standard width limit.',
  MaxWeight = 'The freight weight exceeds the values for the freight cost values.',
  MaxThickness = 'The thickness exceeds the standard thickness limit.',
  MaxThicknessCutType = 'The thickness exceeds the thickness limit for the cut type.',
  FTValueClad = 'Specify Clad FT Value',
  FTValueBase = 'Specify Base FT Value',
  FTValue = 'Specify FT Value'
}

export const GuardRailsTriggers = [
  GuardRailsLongDescription.FTValue,
  GuardRailsLongDescription.FTValueBase,
  GuardRailsLongDescription.FTValueClad
];

export enum MSTOActions {
  AddPiece = 'Add Piece',
  Create = 'Create',
  Delete = 'Delete',
  Edit = 'Edit',
  Reset = 'Reset',
  Supplier = 'Supplier',
  UseAsIs = 'Use As Is',
  Duplicate = 'Duplicate',
}

export enum ToastTypes {
  Success = 'Success',
  Error = 'Error',
  Warning = 'Warning',
}

export enum ToastMessages {
  General = 'Something went wrong! Please check that all information is correct',
  CannotDecreaseValue = 'You cannot decrease this value past the minimum amount!',
  CannotIncreaseValue = 'You cannot increase this value past the maximum amount!',
  CloneFailed = 'Clone failed.',
  DownloadFailed = 'Download failed.',
  IncompleteFinishedGoods = 'Cannot save. Incomplete items in finished goods.',
  InvalidPrice = 'The price entered is invalid.',
  ManualSizingMultipleMetalCombos = 'You cannot combine different metal combinations on the same clad!',
  MSTOAddPiece = 'You must select an item to add a piece.',
  MSTOAddPiecePanels = 'You must add a panel to add a piece.',
  MSTOEdit = 'You must select an item to edit.',
  QuoteCloned = 'Quote cloned!',
  QuoteSaved = 'Quote saved!',
  QuoteSubmitted = 'Quote submitted!',
  QuoteLocked = 'Quote locked!',
  QuoteSentToCustomer = 'Quote sent to customer!',
  QuoteSentToPlanning = 'Quote sent to planning!',
  QuoteRevertedToDraft = 'Quote reverted to draft!',
  QuoteUpdated = 'Quote updated!',
  SaveFailed = 'Save failed.',
  QuoteSyncToSalesforceFailed = 'Quote sync to salesforce failed.',
  QuoteLoadFromSalesforceFailed = 'Quote request from salesforce failed.',
  QuoteLoadFromAPIFailed = 'Quote request from API failed.',
  QuoteLoadIntoStateFailed = 'Quote loading into state failed.',
  AlreadyUseAsIs = 'Cannot Use as is again',
  ResetComplete = 'Reset complete!',
  ResetFailed = 'Reset failed.',
  SettingGlobalCostsFailed = 'Setting global costs failed.',
  SettingGlobalCostsComplete = 'Setting global costs complete.',
  UseAsIs = 'Unable to Use As Is. Check selections and try again.',
  MSTOCreateAssembly = 'You must select an allocation to create an assembly.',
  MSTOInvalidWeldPattern = 'Invalid Weld Pattern',
  MSTODelete = 'You must select an item to delete.',
  MSTODuplicate = 'You must select an item to duplicate.',
  MSTOBaseCladAssembly = 'Each assembly needs to have a base and clad.',
  MSTOAnvilAssembly = 'An anvil is required for this assembly.',
  MSTOMultipleBaseAnvilsInAssembly = 'More than one base or anvil cannot be in an assembly.',
  MSTOCladThicknessDeviation = 'All clads in the assembly must have same thickness +-<<VALUE>> <<UOM>>.',
  MSTOUseAsIsFailed = 'Use As Is failed.',
  MSTOAddPieceFailed = 'Add Piece failed.',
  MSTOResetFailed = 'MSTO Reset failed.',
  MSTOEditFailed = 'Edit failed.',
  MSTOCreateAssemblyFailed = 'Create Assembly failed.',
  UpdateFailed = 'Update failed.',
  RequiredFields = 'Missing required fields.',
  TooManyCharactersFiveThousand = 'Too many characters! Please edit to 5,000 characters or less.',
  TooManyCharactersTenThousand = 'Too many characters! Please edit to 10,000 characters or less.',
  TooManyCharactersUpdate = 'One or more of your entries has too many characters. Please edit and try again',
  GenerateBomSelections = 'You must select a line to generate the BOM',
  BOMCopiedToClipboard = 'BOM copied to clipboard!',
  BOMFailedCopyToClipboard = 'Error copying BOM to clipboard',
  PONotesCopiedToClipboard = 'PO Notes copied to clipboard!'
}

export enum RadioButtonOptions {
  Yes = 'Yes',
  No = 'No',
  None = 'None',
  PayTo = 'Pay to',
  NobelCladChoice = 'NobelClad Choice',
  CustomerSpecified = 'Customer Specified',
}

export enum TestingUOMs {
  PSI = 'PSI',
  MPA = 'MPA',
}

export enum Incoterms {
  CFRParentheses = '(CFR)',
  CIFParentheses = '(CIF)',
  CIPParentheses = '(CIP)',
  CPTParentheses = '(CPT)',
  DAPParentheses = '(DAP)',
  DDPParentheses = '(DDP)',
  DPUParentheses = '(DPU)',
  EXWParentheses = '(EXW)',
  FASParentheses = '(FAS)',
  FCAParentheses = '(FCA)',
  FOBParentheses = '(FOB)',
  CFR = 'CFR',
  CIF = 'CIF',
  CIP = 'CIP',
  CPT = 'CPT',
  DAP = 'DAP',
  DDP = 'DDP',
  DPU = 'DPU',
  EXW = 'EXW',
  FAS = 'FAS',
  FCA = 'FCA',
  FOB = 'FOB',
}

export enum CladProductionSpecifications {
  OtherManualEntry = 'Other Manual Entry',
  ADW8DMC100 = 'AD-W8 & DMC100',
  B432DMC100 = 'B432 & DMC100',
  B898DMC100 = 'B898 & DMC100',
  DMC100 = 'DMC 100',
  SA263DMC100 = 'SA-263 & DMC100',
  SA264DMC100 = 'SA-264 & DMC100',
  SA265DMC100 = 'SA-265 & DMC100',
  CODAPDMC100 = 'CODAP & DMC100',
}

export enum PostCladBondUTSpecifications {
  SA264UTClass3 = 'SA-264: UT: Class 3 (formerly SA-578 S6)',
  SA264UTClass5 = 'SA-264: UT: Class 5',
  SA264UTClass1 = 'SA-264: UT: Class 1 (formerly SA-578 S7)',
}

export enum PostCladBaseMetalUTSpecifications {
  Other = 'Other',
}

export enum LaborTypes {
  CutMethodLabor = 'Cut Method Labor',
  FlatteningLabor = 'Flattening Labor',
  GeneralLabor = 'General Labor',
  HeatTreatment = 'Heat Treatment',
  Machining = 'Machining',
  SpecificationTesting = 'Specification Testing',
  WeldingLabor = 'Welding Labor',
}

export enum RadiographyTypes {
  Spot = 'Spot',
  OneHundredPercent = '100%',
}

export enum MetalPurchaseRequirementsGroups {
  HeatTreatment = 'Heat Treatment',
  ManufacturingRestrictions = 'Manufacturing Restrictions',
  CorrosionTesting = 'Corrosion Testing',
  FlatnessRequirements = 'Flatness Requirements',
  Camber = 'Camber',
  AdditionalRequirements = 'Additional Requirements',
  SupplierRestrictions = 'Supplier Restrictions',
  SpecificationEditionAddenda = 'Specification Edition/Addenda',
  Certification = 'Certification',
  WeldRepair = 'Weld Repair',
  Marking = 'Marking',
  MetalProductAnalysis = 'Metal Product Analysis',
  ChemistryRequirements = 'Chemistry Requirements',
  CustomerChemistryRestrictions='Customer Chemistry Restrictions',
  MetallurgicalRequirements = 'Metallurgical Requirements',
  TestingRequirements = 'Testing Requirements',
  SimulatedHeatTreatment = 'Simulated Heat Treatment',
  UltrasonicInspection = 'Ultrasonic Inspection',
  EdgeSurfaceCondition = 'Edge/Surface Condition',

  //Additional Fields - 6/22/2023
  CertificateType = 'Certificate Type',
  CladProductSpecification = 'Clad Product Specification',
  AdditionalRequirementsCladdingMetal = 'Additional Requirements Cladding Metal',
  AdditionalRequirementsBaseMetal = 'Additional Requirements Base Metal',
  CladProductTesting = 'Clad Product Testing',
  CladdingMetalSurfaceHardnessTest = 'Cladding Metal Surface Hardness Test',
  HICTestNotes='HIC Test Notes',
  Austenitic='Austenitic Grain Size Finer Than',

  Carbon='Carbon <=',
  CEQ='Carbon Equivalency per SA-20 S20 <=',
  Phosphorus='Phosphorus <=',
  Sulphur='Sulphur <=',
  J='J-Factor — SA-387 S62 <=',
  X='X-Factor — SA-387 S62 (1.4 & 1.5) <=',
  //SPWHT    C='C',
  SPWHT = 'SPWHT',
  SPWHTGroup = 'SPWHT Group',
  SPWHTGroupACycle1 = 'SPWHT Group A Cycle 1',
  SPWHTGroupACycle2 = 'SPWHT Group A Cycle 2',
  SPWHTGroupACycle3 = 'SPWHT Group A Cycle 3',
  SPWHTGroupAToBePerformed = 'SPWHT Group A To Be Performed',
  SPWHTGroupBCycle1 = 'SPWHT Group B Cycle 1',
  SPWHTGroupBCycle2 = 'SPWHT Group B Cycle 2',
  SPWHTGroupBCycle3 = 'SPWHT Group B Cycle 3',
  SPWHTGroupBToBePerformed = 'SPWHT Group B To Be Performed',
  SPWHTGroupCCycle1 = 'SPWHT Group C Cycle 1',
  SPWHTGroupCCycle2 = 'SPWHT Group C Cycle 2',
  SPWHTGroupCCycle3 = 'SPWHT Group C Cycle 3',
  SPWHTGroupCToBePerformed = 'SPWHT Group C To Be Performed',
  HeatingAndCoolingRateAbove = 'Heating And Cooling Rate Above',
  MaximumCoolingRateHour = 'Maximum Cooling Rate Hour',
  MaximumHeatingRateHour = 'Maximum Heating RateHour',
  MaximumHRCR = 'Max HR/CR',
  Blank = '',
  PrebondTesting = 'Impact Testing',
  HardnessCladdingMetalSurface = 'Hardness Cladding Metal Surface',
  HardnessBaseMetalSurface = 'Hardness Base Metal Surface',
  StressRelief = 'Stress Relief',
  FGHeatTreatment = 'Finished Good Heat Treatment',
  CalciumTesting = 'Calcium Treatment',
  CalciumRequired = 'Calcium Treatment for Inclusion Shape Control Required',
  CorrossionTesting = 'Corrossion Testing',
  Delivery = 'Delivery',
  BaseMetalUT = 'UT',
  TensileTest = 'Tensile Test',
ImpactTesting = 'Impact Testing',
FerroxylTest = 'Ferroxyl Test',
CladChemistryRestrictions=' Chemistry Restrictions',
}

export enum TensileTestLocations {
  PerSpecification = 'Per Specification',
  SpecificationCenterline = 'Specification & Centerline',
  CenterlineOnly12T = 'Centerline Only (1/2-T)',
}

export enum HardnessMetalSurfaces {
  None = 'None',
  RBRockwellB = 'RB (Rockwell B)',
  RCRockwellC = 'RC (Rockwell C)',
  Brinell = 'Brinell',
  HVVickers = 'HV (Vickers)',
  Other = 'Other',
}

export enum FerroxylTestOfCladdingSurfacePerformed {
  PriorToHeatTreatment = 'Prior to Heat Treatment',
  PriorToShipment = 'Prior to Shipment',
  PriorToBothHeatTreatmentAndShipment = 'Prior to Both Heat Treatment and Shipment',
}

export enum QuoteLineTypes {
  Master = 'Master',
  Detail = 'Detail',
  OutsideServices = 'OutsideServices',
}

export enum LeakTestSpecifications {
  Helium = 'Helium',
  Air = 'Air',
  OneByTen = '1 x 10',
  Vacuum = 'Vacuum',
  ATMCC = 'ATM-CC / SEC',
  Other = 'Other',
}

export const documentTypes = [
  'Bill of Lading',
  'Certificate of Conformance',
  'Invoice',
  'Other',
  'Packing List',
  'Sales Order Confirmation',
];

export const recentHoursThreshold = 72;
export const sqInToSqFtDivider = 144;
export const sqMmToSqMDivider = 1e6;
export const internalViewInitialOrderLoadLimit = 1000;
export const internalViewInitialInvoiceLimit = 1000;
export const sixtyMinutes = 60;
export const mmToInDivider = 25.4;
export const maxTextInputLength = 255;
export const twoPieceHeadMultiplier = 1.88;

export enum EdgePreps {
  SquareCut = 'Square Cut - No Stripback',
  SingleOutside = 'Single Outside - No Stripback',
  SingleInside = 'Single Inside - No Stripback',
  DoubleBevel = 'Double Bevel - No Stripback',
  BevelPlusStripback = 'Bevel and Stripback',
  PerDrawing = 'Per Drawing',
}

export enum HeadFormingTypes {
  TwoToOne = '2:1',
  Hemi = 'Hemi',
  FandD = 'F & D',
  DOH = 'DOH',
}

export enum DiameterUOMTypes {
  ID = 'I.D.',
  OD = 'O.D.',
}

export enum PostCladTestingOptions {
  Shear = 'Shear',
  Tensile = 'Tensile',
  RamTensile = 'Ram Tensile',
  SA26345BondStrength = 'SA-263/4/5 Bond Strength',
  B898Bend = 'B898 Bend',
  ADW8Bend = 'AD-W8 Bend',
  ImpactLCVN = 'Impact LCVN',
  ImpactTCVN = 'Impact TCVN',
}

export enum AdvSpecOptions {
  HardnessCladdingMetalSurface = 'HardnessCladdingMetalSurface',
  HardnessBaseMetalSurface = 'HardnessBaseMetalSurface',
  PMIConfirmCladdingMetal = 'PMIConfirmCladdingMetal',
  PMIConfirmBaseMetal = 'PMIConfirmBaseMetal',
  CladCustomerChemistryRestrictions = 'CladCustomerChemistryRestrictions',
  CorrosionTesting = 'CorrosionTesting',
  CladMetalSpecEdAndAd = 'CladMetalSpecEdAndAd',
  CladCustomerMetalSupplierRestrictions = 'CladCustomerMetalSupplierRestrictions',
  CladMetalProductAnalysisRequired = 'CladMetalProductAnalysisRequired',
  FerroxylTestOfCladdingSurfacePerformed = 'FerroxylTestOfCladdingSurfacePerformed',
  FerriteNumberRequired = 'FerriteNumberRequired',
  PrecladBaseMetalUT = 'PrecladBaseMetalUT',
  CarbonPercent = 'CarbonPercent',
  SulphurPercent = 'SulphurPercent',
  PhosphorousPercent = 'PhosphorousPercent',
  CarbonEquivalencyPercent = 'CarbonEquivalencyPercent',
  JFactorSA387S62 = 'JFactorSA387S62',
  XFactorSA387S62 = 'XFactorSA387S62',
  BaseCustomerChemistryRestrictions = 'BaseCustomerChemistryRestrictions',
  BaseTensileTest = 'BaseTensileTest',
  HotTensileTestPerSA20S7 = 'HotTensileTestPerSA20S7',
  HICTestingRequired = 'HICTestingRequired',
  AusteniticGrainSizeFinerThan = 'AusteniticGrainSizeFinerThan',
  BaseMetalSpecEdAndAd = 'BaseMetalSpecEdAndAd',
  BaseCustomerMetalSupplierRestrictions = 'BaseCustomerMetalSupplierRestrictions',
  BaseMetalProductAnalysisRequired = 'BaseMetalProductAnalysisRequired',
  CustomerSpecifiedImpactTesting = 'CustomerSpecifiedImpactTesting',
  PrebondImpacts = 'PrebondImpacts',
  LeakTest = 'LeakTest',
  CustomerSpecifiedSPWHTOfTestCoupons = 'CustomerSpecifiedSPWHTOfTestCoupons',
  DyePenetrant = 'DyePenetrant',
}

export enum CustomerSpecifiedImpactTestDirections {
  Longitudinal = 'Longitudinal',
  Transverse = 'Transverse',
  Both = 'Both',
}

export enum AssemblyGroupsColumnHeadings {
  Line = 'Line',
  Quantity = 'Quantity',
  Length = 'Length',
  Width = 'Width',
  CladNom = 'Clad Nom',
  CladMin = 'Clad Min',
  BaseNom = 'Base Nom',
  BaseMin = 'Base Min',
  Product = 'Product',
  Shape = 'Shape',
  Price = 'Price',
}

export enum PaymentTerms {
  SP02 = 'SP02',
}

export enum ShipToAddressTypes {
  AXDefaultAddress = 'AX Default Address',
}

export enum ProcedureApprovalOptionsRequiredFields {
  InspectionsAndTestPlanProcedure = 'inspectionsAndTestPlanProcedure',
  HeatTreatmentProcedure = 'heatTreatmentProcedure',
  HeatProcedure = 'heatProcedure',
  NonDestructiveEvaluationTestingProcedure = 'nonDestructiveEvaluationTestingProcedure',
  WeldingProcedure = 'weldingProcedure',
  OtherProcedure = 'otherProcedure',
}

export enum ProcedureApprovalDateNeededFields {
  InspectionAndTestPlanProcedureDateNeeded = 'inspectionAndTestPlanProcedureDateNeeded',
  HeatTreatmentProcedureDateNeeded = 'heatTreatmentProcedureDateNeeded',
  HeatProcedureDateNeeded = 'heatProcedureDateNeeded',
  NonDestructiveEvaluationTestingProcedureDateNeeded = 'nonDestructiveEvaluationTestingProcedureDateNeeded',
  WeldingProcedureDateNeeded = 'weldingProcedureDateNeeded',
  OtherProcedureDateNeeded = 'otherProcedureDateNeeded',
}

export enum ProcedureApprovalDetailsFields {
  InspectionAndTestPlanProcedureDetails = 'inspectionAndTestPlanProcedureDetails',
  HeatTreatmentProcedureDetails = 'heatTreatmentProcedureDetails',
  HeatProcedureDetails = 'heatProcedureDetails',
  NonDestructiveEvaluationTestingProcedureDetails = 'nonDestructiveEvaluationTestingProcedureDetails',
  WeldingProcedureDetails = 'weldingProcedureDetails',
  OtherProcedureDetails = 'otherProcedureDetails',
}

export enum OrderEntryColumnHeadings {
  Event = 'Event',
  ThirdPartyInspection = 'Third Party Inspection',
  CustomerWitness = 'Customer Witness',
  Line = 'Line',
  Quantity = 'Quantity',
  Description = 'Description',
  ShipDate = 'Ship Date',
  RulesBroken = 'Rules Broken'
}

export enum InspectionsAndWitnessingEvents {
  BaseMetalTestCouponCuttingToBeWitnessedAtVendors = 'Base Metal Test Coupon Cutting to be Witnessed at Vendors',
  BaseMetalTestWitnessAtVendor = 'Base Metal Test Witness At Vendor',
  BaseMetalCertificateApprovalAtVendor = 'Base Metal Certificate Approval At Vendor',
  BaseMetalRetestAtDMCBeforeCladding = 'Base Metal Retest at DMC before Cladding',
  PEDRequalificationOfBaseMetal = 'PED Requalification of Base Metal',
  CladdingMetalTestCouponCuttingToBeWitnessedAtVendor = 'Cladding Metal Test Coupon Cutting to be Witnessed at Vendor',
  CladdingMetalTestWitnessAtVendor = 'Cladding Metal Test Witness at Vendor',
  CladdingMetalCertificateApprovalAtVendor = 'Cladding Metal Certificate Approval at Vendor',
  CladdingMetalRetestAtDMCBeforeCladding = 'Cladding Metal Retest at DMC before Cladding',
  CladTestSpecimenRemovalAndStamping = 'Clad Test Specimen Removal and Stamping',
  CladDestructiveTesting = 'Clad Destructive Testing',
  DocumentReviewAndApprovalAtDMC = 'Document Review and Approval at DMC',
  CladNDTAndFinalInspection = 'Clad NDT and Final Inspection',
  HeadInspectionAtFormer = 'Head Inspection at Former',
  HeadFormingEvent = 'Head Forming Event',
}

export enum WitnessingFields {
  BaseTestCouponCutWitnessAtVendor = 'baseTestCouponCutWitnessAtVendor',
  BaseTestWitnessAtVendor = 'baseTestWitnessatVendor',
  BaseCertificateApprovalatVendor = 'baseCertificateApprovalatVendor',
  BaseRetestAtDMCBeforeCladding = 'baseRetestAtDMCBeforeCladding',
  PEDRequalificationOfBaseMetal = 'pedRequalificationOfBaseMetal',
  CladTestCouponCutWitnessAtVendor = 'cladTestCouponCutWitnessatVendor',
  CladdingMetalTestWitnessAtVendor = 'claddingMetalTestWitnessatVendor',
  CladCertificateApprovalAtVendor = 'cladCertificateApprovalatVendor',
  CladdingMetalRetestAtDMCBeforeCladding = 'claddingMetalRetestatDMCbeforeCladding',
  CladTestSpecimenRemovalAndStamping = 'cladTestSpecimenRemovalAndStamping',
  CladDestructiveTesting = 'cladDestructiveTesting',
  DocumentReviewApprovalAtDMC = 'documentReviewApprovalAtDMC',
  CladNDTAndFinalInspection = 'cladNDTandFinalInspection',
  HeadInspectionAtFormer = 'headInspectionAtFormer',
  HeadFormingEvent = 'headFormingEvent',
}

export enum WitnessingOptions {
  ThirdPartyInspection = 'Third Party Inspection',
  CustomerWitness = 'Customer Witness',
}

export enum SelectionLabelPositions {
  Left = 'Left',
}

export enum MetalComboScrollerStyles {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

export enum MetalProposalTypes {
  PriceBest = 'Price Best',
  DeliveryBest = 'Delivery Best',
}

export enum CycleTypes {
  AirCool = 'Air Cool',
  WaterQuench = 'Water Quench',
}

export enum BooleanStrings {
  True = 'True',
  False = 'False',
}

export enum TempUOMs {
  F = 'F',
  C = 'C',
}

export enum HeatContourTolerancesAndHeatTreatmentOptions {
  PerASMEVIIIUG79UG81AndUCS79 = 'Per ASME VIII: UG-79, UG-81 and UCS-79',
  Other = 'Other',
}

export enum NumberOfPiecesInHeadOptions {
  SinglePieceHead = 'Single Piece Head',
  TwoPieceHead = 'Two Piece Head',
  SegmentalHead = 'Segmental Head',
}

export enum StandardLeadTimes {
  EightWeeks = '8 weeks',
  FifteenWeeks = '15 weeks',
  EighteenWeeks = '18 weeks',
}

export enum OrderEntryTabs {
  JobWideData = 'Job Wide Data',
  AdvancedSpecifications = 'Advanced Specifications',
  MSTO = 'MSTO',
}

export enum ToBePerformed {
  PreBond = 'Pre-Bond',
  BothPreAndPostBond = 'Both - Pre and Post Bond',
}

export enum PlateMethods {
  Rectangle = 'Rectangle',
  Circle = 'Circle',
  ManuallySized = 'Manually Sized',
}

export enum InnerOrOuterDimensions {
  Inner = 'Inner',
  Outer = 'Outer',
}

export enum MSTOColumnHeadings {
  Piece = 'Piece',
  Width = 'Width',
  Length = 'Length',
  BaseTK = 'Base TK',
  CladTK = 'Clad TK',
  WeightLB = 'Weight LB',
  WeightKG = 'Weight KG',
  CostPerLB = 'Cost/LB',
  CostPerKG = 'Cost/KG',
  CostPerPC = 'Cost/PC',
  FrtCostPerLB = 'Frt Cost/LB',
  FrtCostPerKG = 'Frt Cost/KG',
  Supplier = 'Supplier',
  CustomerPO = 'Customer PO',
  Date = 'Date',
  Alloc = 'Alloc',
  AssemblyGroup = 'Assembly Group',
  Allocation = 'Allocation',
  Location = 'Location',
  AnvilTK = 'Anvil TK',
}

export enum AllocationOptions {
  AssemblyPart = 'Assembly Part',
  Unallocated = 'Unallocated',
}

export enum AddPieceSuppliers {
  SupplierContactFromQuoteRecords = 'Supplier Contact From Quote Records',
  NCStock = 'NC Stock (D365 Serial Number Required)',
  CustomerSuppliedMetal = 'Customer Supplied Metal',
}

export enum NCSupplier {
  Backer = 'VEN000869',
  Cladder = 'VEN000617',
}

export enum MSTOAssemblyMetals {
  CLAD = 'CLAD',
  BASE = 'BASE',
  ANVIL = 'ANVIL',
}

export enum MSTOSupplierTypes {
  Stock = 'Stock',
  Purchase = 'Purchase',
  CustomerProvided = 'CustomerProvided',
}

export enum NobelcladInhousePackaging {
  StandardDomestic = 'Standard Domestic',
  Export = 'Export',
}

export enum TooltipPositions {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
}

export enum SeamWeldQuantity {
  One = 1,
}

export enum CylinderTypes {
  RolledTacked = 'R&T',
  RolledWelded = 'R&W',
}

export enum CustomerSuppliedVendors {
  CladVendor = 'VEN000617',
  BaseVendor = 'VEN000869',
}

export enum PageRoutes {
  Quotes = 'Quotes',
  QuoteDetail = 'QuoteDetail',
  OrderEntry = 'OrderEntry',
  MSTO = 'MSTO',
  AdvancedSpecifications = 'AdvancedSpecifications',
  AssemblyGroups = 'AssemblyGroups',
}

export enum FlatteningSelections {
  StandardPlateFlatness = 'Standard Plate Flatness',
  StandardTubesheetFlatness = 'Standard Tubesheet Flatness',
  None = 'None',
  Other = 'Other',
}

export enum ProfileNames {
  NobelCladPlanning = 'NobelClad Planning',
  SystemAdmin = 'System Administrator',
}

export enum MetalSpecDefault {
  Current = 'Current',
}

export enum HeadAssemblyErrorSections {
  OpenEnd = 'OpenEnd',
  HeadQuantity = 'HeadQuantity',
  BlankSize = 'BlankSize',
  HeadDiameter = 'HeadDiameter',
  HeadFormingType = 'HeadFormingType',
  StraightFlange = 'StraightFlange',
  NobelCladProcedure = 'NobelCladProcedure',
  HeadFormingSupplier = 'HeadFormingSupplier',
}

export enum CertificateSentByDefault {
  EmailPDF = 'Email PDF',
}

export enum TextAreaMax {
  FiveThousand = 5000,
  TenThousand = 10000,
}

export enum BoosterAllowanceGroups {
  Group1 = 'Group 1',
}

export enum PrecladBaseMetalUTDefaults {
  None = 'None'
}

export enum BomHeaders {
  Metal = 'Metal:',
  DimensionsIn = 'Dimensions in:',
  Item = 'Item',
  Qty = 'Qty',
  Width = 'Width',
  Length = 'Length',
  ThicknessNom = 'Thickness (nom)',
  Price = 'Price',
  ShippingTime = 'Shipping Time',
  Incoterm = 'Incoterm',
  Diameter = 'Diameter'
}

export enum HeatTreatCheckCladTypes {
  Titanium = 'Titanium',
  Zirconium = 'Zirconium',
  FerriticStainless = 'Ferritic Stainless'
}

export enum ExpandedRowLinks {
  SizingStats = 'Sizing Stats',
  CostDetails = 'Cost Details'
}

export const PreBondAutoSelect = [
  ToBePerformed.PreBond,
  ToBePerformed.BothPreAndPostBond
];

export const resettableFields = [
  QuoteLineItemValueTypes.WeldingLength,
  QuoteLineItemValueTypes.WeldingWidth
];
