import AutoComplete from '@components/auto-complete/auto-complete';
import { IonButton } from '@ionic/react';
import * as React from 'react';
import './search-input.scss';

const SearchInput = (props) => {
  return (
    <div className='search-container' data-testid="QASearchInputSearchContainer">
      <AutoComplete
        noneFoundText={props.noneFoundText}
        placeholder={props.placeholder}
        suggestions={props.suggestions}
        suggestionDisplayFields={props.suggestionDisplayFields}
        commonSuggestionDisplayFields={props.commonSuggestionDisplayFields}
        location='auto'
        searchInputChangedHandler={(searchInput) => props.searchInputChangedHandler(searchInput)}
        suggestionClickHandler={(searchInput) => props.suggestionClickHandler(searchInput)}
        input={props.input}
      />
      <IonButton class='search-button' color='primary' onClick={() => props.searchButtonClickHandler()} data-testid="QASearchInputSearchButton">Go</IonButton>
    </div>
  );
};

export default SearchInput;