import AppInput from '@components/app-input/app-input';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';
import AppRadioButtons from '@components/app-radio-buttons/app-radio-buttons';
import AppTextarea from '@components/app-textarea/app-textarea';
import AppCheckboxItem from '@components/app-checkbox-item/app-checkbox-item';
import React, { useEffect } from 'react';
import { FormKeys } from '../advance-specification-form-util';
import LineItem from '../line-item/line-item';
import Section from './section';
import { ManufacturingSites } from '@constants';

const Prewelding = (prop) => {
  const {
    form,
    updateForm,
    segmentKey,
  } = prop;

  return <Section title="Prewelding" {...prop}>
  <LineItem
    label="Radiography"
    specifyKey={`${segmentKey}.radiography`}
  >
    <AppLineItemColumns components={[
      {
        key: 'radiography-dropdown',
        component: <AppRadioButtons
          name={FormKeys.Radiography_Clad}
          options={['Spot', '100%']}
          selected={form[FormKeys.Radiography_Clad]}
          onChange={(value) => updateForm(FormKeys.Radiography_Clad, value)}
                   />
      },
    ]}
    />
  </LineItem>
    <LineItem
      label={<AppCheckboxItem
        label="PMI of Weld Wire, Performed on Button"
        checked={form[FormKeys.PMIofWeldWire]}
        onChange={(value) => updateForm(FormKeys.PMIofWeldWire, value)}
             />}
      specifyKey={`${segmentKey}.pmi-weld-wire`}
    />
    <LineItem
      label={<AppCheckboxItem
        label="PMI of Prewelds Required"
        checked={form[FormKeys.PMIPreweldsRequired]}
        onChange={(value) => updateForm(FormKeys.PMIPreweldsRequired, value)}
             />}
      specifyKey={`${segmentKey}.pmi-prewelds`}
    />
    <LineItem
      label={<AppCheckboxItem
        label="Ferrite Number Required"
        checked={form[FormKeys.RequiredFerriteNumber]}
        onChange={(value) => updateForm(FormKeys.RequiredFerriteNumber, value)}
             />}
      specifyKey={`${segmentKey}.ferrite-number`}
    >
      <AppLineItemColumns components={[
        {
          key: 'ferrite-number',
          component: <AppInput
            value={form[FormKeys.FerriteNumberRequired]}
            disabled={!form[FormKeys.RequiredFerriteNumber]}
            onChange={(value) => updateForm(FormKeys.FerriteNumberRequired, value)}
            placeholder='Ferrite Number Required...'
                     />
        }
      ]}
      />
    </LineItem>
    <LineItem
      label="Additional Requirements"
      specifyKey={`${segmentKey}.additional-requirements`}
    >
      <AppLineItemColumns components={[
        {
          key: 'additional-requirements',
          component: <AppTextarea
            value={form[FormKeys.AdditionalRequirementsPrewelding]}
            onChange={(value) => updateForm(FormKeys.AdditionalRequirementsPrewelding, value)}
            rows={5}
            placeholder='Specify Additional Requirements...'
                     />
        }
      ]}
      />
    </LineItem>
  </Section>;
};

export default Prewelding;