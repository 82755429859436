import * as React from 'react';

import { IonLabel } from '@ionic/react';
import classNames from 'classnames';
import './msto-active-tab.scss';
import { MSTOSelectionTabs } from '@constants';

interface MSTOActiveTabProps {
  isActive: boolean;
  tab: string;
  handleTabSelected: (tab: MSTOSelectionTabs) => void
}

const MSTOActiveTab = ({ isActive, tab, handleTabSelected}: MSTOActiveTabProps) => {
  return (
    <>
      <IonLabel
        class={classNames({
          'sub-tab': true,
          active: isActive,
        })}
        data-testid={'QATab_' + tab}
        onClick={() => handleTabSelected(tab as MSTOSelectionTabs)}
      >
        {tab}
      </IonLabel>
    </>
  );
};

export default MSTOActiveTab;