import React, { useEffect, useState } from 'react';

import { IonButton, IonLabel } from '@ionic/react';
import AppInput from '@components/app-input/app-input';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';

import PanelSection from '../panel-section';
import AppLineItem from '@components/app-line-item/app-line-item';
import { PanelProp } from '@pages/new-assembly-groups/panel-item/panel-item';
import { AssemblyGroupFormKeys } from '@pages/new-assembly-groups/assembly-group-form-util';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import AppRadioButtons from '@components/app-radio-buttons/app-radio-buttons';
import AppTextarea from '@components/app-textarea/app-textarea';
import AppCheckboxItem from '@components/app-checkbox-item/app-checkbox-item';
import AppDropDownFinishedGoods from '@components/app-dropdown/app-dropdown-finished-goods';
import AppRadioButtonsSelection from '@components/app-radio-buttons/app-radio-buttons-selection';

import { CladProductionSpecifications, HeadFormingTypes, HeatContourTolerancesAndHeatTreatmentOptions, MeasurementSystems, NumberOfPiecesInHeadOptions, QuoteLineItemValueTypes, Regexes, Units } from '@constants';
import AppDropdownSelection from '@components/app-dropdown/app-dropdown-selection';
import PONotes from './modals/po-notes';
import Cycle, { extractCycleValues } from './cycle';
import { calculateUOMValue, getPONotesForSalesforce } from '@shared/quote-utils';
import { PONotesVariables } from '@shared/types';
import { formatCurrency } from '@shared/utils';
import './head-forming.scss';
import { useDisplayUom } from '@context/useDisplayUom';

// should move to common util
const WeightLabelUOM = {
  [MeasurementSystems.Imperial]: 'Lb',
  [MeasurementSystems.Metric]: 'Kg',
}

const freightFormat = (value) => {
  return value !== '' ? (+value).toFixed(2) : '';
}

const FreightCostInput = ({ quote, value, onChange}) => {
  const { displayUom } = useDisplayUom();
  const quoteUOM = quote.DocumentUOM__c || MeasurementSystems.Imperial;

  const displayValue = displayUom === quoteUOM
    ? value
    : calculateUOMValue(quoteUOM, displayUom, value, Units.PricePerKilogram, Units.PricePerPound);

  const onInputChange = (inputValue) => {
    const newValue = displayUom === quoteUOM
      ? inputValue
      : calculateUOMValue(displayUom, quoteUOM, inputValue, Units.PricePerKilogram, Units.PricePerPound);

    onChange(newValue);
  }

  return <AppInput
    type='number'
    placeholder='0.00'
    regex={Regexes.DecimalNumber}
    min='0.00'
    value={freightFormat(displayValue)}
    onChange={onInputChange}
  />
}

const parseCyclesForPONotes = (cycles) => {
  return cycles?.filter((a) => a).map((cycleString, index) => {
    const extracted = extractCycleValues(cycleString);
    let item = 'Total time and temperature allowed for Forming Cycle(s): ';

    if (index > 0) {
      item = `Post forming Heat Treatment ${extracted.num as number - 1}: `;
    }

    item += `${extracted.temp} Deg. ${extracted.uom}, Hold ${extracted.min} minutes. ${extracted.type || ''}`;

    return item;
  });
};

const PanelHeadForming = ({ form, updateForm, areaId, quote }: PanelProp) => {
  const [ showPoNotes, setShowPoNotes ] = useState(false);
  const { displayUom } = useDisplayUom();

  // used for checking stuff
  const headContourOptions = [
    HeatContourTolerancesAndHeatTreatmentOptions.PerASMEVIIIUG79UG81AndUCS79,
    HeatContourTolerancesAndHeatTreatmentOptions.Other,
  ];

  useEffect(() => {
    if (!form[AssemblyGroupFormKeys.HeadContourToleranceHTOptions]) {
      onUpdateForm(AssemblyGroupFormKeys.HeadContourToleranceHTOptions)(headContourOptions[0]);
    }
  }, []);

  useEffect(() => {
    if (!form[AssemblyGroupFormKeys.HeadContourToleranceHTOptions_HID]) {
      onUpdateForm(AssemblyGroupFormKeys.HeadContourToleranceHTOptions_HID)(headContourOptions[0]);
    }
  }, []);

  useEffect(() => {
    const contourVal = form[AssemblyGroupFormKeys.HeadContourToleranceHTOptions];
    const updatedValue = contourVal !== headContourOptions[0] ? `Other: ${contourVal}` : contourVal;
  
    onUpdateForm(AssemblyGroupFormKeys.HeadContourToleranceHTOptions_HID)(updatedValue);
  }, [form[AssemblyGroupFormKeys.HeadContourToleranceHTOptions]]);
  

  useEffect(() => {
    const isQuoteMetric = displayUom === MeasurementSystems.Metric;
    const currencyCode = form[AssemblyGroupFormKeys.CurrencyIsoCode] ?? 'USD';
    const line = form[AssemblyGroupFormKeys.Line];
    const quoteLineItems = quote?.quoteLineItems;
    const quoteLineItem = quoteLineItems?.find(qli => qli.Line__c === line);
    const productTagging = quoteLineItem && quoteLineItem.ProductTagging__c ? JSON.parse(quoteLineItem?.ProductTagging__c) : null;
    
    const updatedPONotesVariables: PONotesVariables = {
      quoteName: quote.Name,
      cladMetal: form[AssemblyGroupFormKeys.CladMetal],
      baseMetal: form[AssemblyGroupFormKeys.BaseMetal],
      cladNom: isQuoteMetric ? form[AssemblyGroupFormKeys.Clad_Nom_mm]?.toFixed(3) : form[AssemblyGroupFormKeys.Clad_Nom_in]?.toFixed(3),
      cladMin: isQuoteMetric ? form[AssemblyGroupFormKeys.Clad_Min_mm]?.toFixed(3) : form[AssemblyGroupFormKeys.Clad_Min_in]?.toFixed(3),
      baseNom: isQuoteMetric ? form[AssemblyGroupFormKeys.Base_Nom_mm]?.toFixed(3) : form[AssemblyGroupFormKeys.Base_Nom_in]?.toFixed(3),
      baseMin: isQuoteMetric ? form[AssemblyGroupFormKeys.Base_Min_mm]?.toFixed(3) : form[AssemblyGroupFormKeys.Base_Min_in]?.toFixed(3),
      baseMetalFinishedGoodHeatTreatment: form[AssemblyGroupFormKeys.FinishedGoodHeatTreatment],
      postBondUTSpecification: form[AssemblyGroupFormKeys.PostCladBondUTSpecs],
      shipWeight: isQuoteMetric ? `${form[AssemblyGroupFormKeys.Ship_Weight_KG].toFixed(0)} KG` : `${form[AssemblyGroupFormKeys.Ship_Weight_LB].toFixed(0)} LB`,
      headDescription: isQuoteMetric ? form[AssemblyGroupFormKeys.ItemDescriptionImp] : form[AssemblyGroupFormKeys.ItemDescriptionMetric],
      postCladBaseMetalUTSpecification: form[AssemblyGroupFormKeys.PostCladBaseMetalUT],
      cladProductionSpecification: form[AssemblyGroupFormKeys.CladProductionSpecification] === CladProductionSpecifications.OtherManualEntry
        ? form[AssemblyGroupFormKeys.CladProductionSpecificationNotes]
        : form[AssemblyGroupFormKeys.CladProductionSpecification],
      headQuantity: form[AssemblyGroupFormKeys.HeadQuantity],
      headFormingCostPerHead: `${formatCurrency(form[AssemblyGroupFormKeys.HeadFormingCostPerHead] as number, currencyCode, 2)}`,
      headContour: form[AssemblyGroupFormKeys.HeadContourToleranceHTOptions],
      nobelCladProcedure: form[AssemblyGroupFormKeys.NobelCladProcedure],
      heatTreatCycles: parseCyclesForPONotes([
        form[AssemblyGroupFormKeys.SimulationHTCycle1],
        form[AssemblyGroupFormKeys.SimulationHTCycle2],
        form[AssemblyGroupFormKeys.SimulationHTCycle3],
        form[AssemblyGroupFormKeys.SimulationHTCycle4],
        form[AssemblyGroupFormKeys.SimulationHTCycle5],
      ]),
      blankOD: `${form[AssemblyGroupFormKeys.BlankSize]} ${isQuoteMetric ? 'MM' : 'IN'} O.D.`,
      numPiecesDescription: form[AssemblyGroupFormKeys.HeadNumberOfPieces] === 1 ? NumberOfPiecesInHeadOptions.SinglePieceHead : NumberOfPiecesInHeadOptions.TwoPieceHead,
      straightFlange: form[AssemblyGroupFormKeys.StraightFlange],
      openEnd: form[AssemblyGroupFormKeys.OpenEnd],
      maxCenterHole: form[AssemblyGroupFormKeys.HeadMaxCenterHole],
      cladMetalPreference: form[AssemblyGroupFormKeys.HeadFormingIDvsOD],
      accountName: quote?.Account__r.Name || '',
      po: quote?.CustomerPONumber__c || '',
      tag: `${productTagging?.CustomerTags[0].CustomerTag || ''}`
    };

    onUpdateForm(AssemblyGroupFormKeys.HeadFormingNotes1)(getPONotesForSalesforce(updatedPONotesVariables));
  }, [
    displayUom,
    form[AssemblyGroupFormKeys.HeadQuantity],
    form[AssemblyGroupFormKeys.HeadFormingCostPerHead],
    form[AssemblyGroupFormKeys.HeadContourToleranceHTOptions],
    form[AssemblyGroupFormKeys.NobelCladProcedure],
    form[AssemblyGroupFormKeys.BlankSize],
    form[AssemblyGroupFormKeys.HeadNumberOfPieces],
    form[AssemblyGroupFormKeys.StraightFlange],
    form[AssemblyGroupFormKeys.OpenEnd],
    form[AssemblyGroupFormKeys.HeadMaxCenterHole],
    form[AssemblyGroupFormKeys.HeadFormingIDvsOD],
    // cycles
    form[AssemblyGroupFormKeys.SimulationHTCycle1],
    form[AssemblyGroupFormKeys.SimulationHTCycle2],
    form[AssemblyGroupFormKeys.SimulationHTCycle3],
    form[AssemblyGroupFormKeys.SimulationHTCycle4],
    form[AssemblyGroupFormKeys.SimulationHTCycle5],
  ]);

  useEffect(() => {
    const value = [
      form[AssemblyGroupFormKeys.SimulationHTCycle1],
      form[AssemblyGroupFormKeys.SimulationHTCycle2],
      form[AssemblyGroupFormKeys.SimulationHTCycle3],
      form[AssemblyGroupFormKeys.SimulationHTCycle4],
      form[AssemblyGroupFormKeys.SimulationHTCycle5],
    ].reduce((prev, curr) => {
      if (curr) {
        return [
          ...prev,
          extractCycleValues(curr)
        ];
      }

      return prev;
    }, [])
    .map((cycle) => {
      let str = `Cycle ${cycle.num}: ${cycle.min} at ${cycle.temp} °${cycle.uom}`

      if (cycle.type) {
        str += ` - ${cycle.type}`;
      }

      return str;
    }).join('\n');

    updateForm(AssemblyGroupFormKeys.SimulateHeatTreatmentHeadForming, value);
  }, [
    form[AssemblyGroupFormKeys.SimulationHTCycle1],
    form[AssemblyGroupFormKeys.SimulationHTCycle2],
    form[AssemblyGroupFormKeys.SimulationHTCycle3],
    form[AssemblyGroupFormKeys.SimulationHTCycle4],
    form[AssemblyGroupFormKeys.SimulationHTCycle5],
  ])

  const onUpdateForm = (key: string) => {
    return (value: any) => {
      updateForm(key, value);
    }
  };

  const onGetRadioButtonValueNumberOfPiecesInHead = () => {
    const value = form[AssemblyGroupFormKeys.HeadNumberOfPieces];

    if (!value) {
      return null;
    }

    switch(value) {
      case 1:
        return NumberOfPiecesInHeadOptions.SinglePieceHead;
      case 2:
        return NumberOfPiecesInHeadOptions.TwoPieceHead;
      default:
        return NumberOfPiecesInHeadOptions.SegmentalHead;
    };
  }

  const onUpdateNumberOfPiecesInHeadRadioButton = (value) => {
    const formValue = value === NumberOfPiecesInHeadOptions.SinglePieceHead ? 1 : 2;

    onUpdateForm(AssemblyGroupFormKeys.HeadNumberOfPieces)(formValue);
  };

  const onGetHeadContourValue = () => {
    const value = form[AssemblyGroupFormKeys.HeadContourToleranceHTOptions];
    if (!value) {
      return null;
    }

    return value === headContourOptions[0]
      ? headContourOptions[0]
      : headContourOptions[1]
  }

  const numberOfPiecesInHeadValue = onGetRadioButtonValueNumberOfPiecesInHead();
  const headContourValue = onGetHeadContourValue();

  return <>
    <PanelSection title="Head Forming" className='head-forming'>
      <AppLineItem light={true} label='Head Quantity' >
        <AppLineItemColumns components={[{
          key: 'head-quantity',
          size: 3,
          component: <AppInput
            type='number'
            placeholder='1'
            regex={Regexes.IntegerAboveZero}
            min='1'
            value={form[AssemblyGroupFormKeys.HeadQuantity]}
            onChange={onUpdateForm(AssemblyGroupFormKeys.HeadQuantity)}
          />
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Blank Size' >
        <AppLineItemColumns components={[{
          key: 'blank-size',
          size: 6,
          component: <AppInput
            type='number'
            placeholder='0.00'
            regex={Regexes.DecimalNumber}
            min='0.01'
            step='0.01'
            value={form[AssemblyGroupFormKeys.BlankSize]}
            onChange={onUpdateForm(AssemblyGroupFormKeys.BlankSize)}
          />
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Head Diameter' >
        <AppLineItemColumns components={[{
          key: 'head-diameter',
          size: 6,
          component: <AppInput
            type='number'
            placeholder='0.00'
            regex={Regexes.DecimalNumber}
            min='0.01'
            step='0.01'
            value={form[AssemblyGroupFormKeys.HeadDiameter]}
            onChange={onUpdateForm(AssemblyGroupFormKeys.HeadDiameter)}
          />
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Head Forming Type' >
        <AppLineItemColumns components={[{
          key: 'head-forming-type',
          size: 6,
          component: <AppDropDownFinishedGoods
            areaId={areaId}
            itemType={form[AssemblyGroupFormKeys.Item_Type]}
            valueType={QuoteLineItemValueTypes.HeadFormingType}
            value={form[AssemblyGroupFormKeys.HeadFormingType]}
            onChange={({value}) => onUpdateForm(AssemblyGroupFormKeys.HeadFormingType)(value)}
          />
        }]}/>
      </AppLineItem>

      <AppLineItem light={true} label='Straight Flange' >
        <AppLineItemColumns components={[{
          key: 'straight-flange',
          size: 6,
          component: <AppInput
            type='number'
            placeholder='0.000'
            regex={Regexes.DecimalNumber}
            min='0.001'
            step='0.001'
            value={form[AssemblyGroupFormKeys.StraightFlange]}
            onChange={onUpdateForm(AssemblyGroupFormKeys.StraightFlange)}
          />
        }]}/>
      </AppLineItem>

      <AppLineItem light={true} label='Max Center Hole' >
        <AppLineItemColumns components={[{
          key: 'max-center-hole',
          size: 3,
          component: <AppInput
            type='number'
            placeholder='0.000'
            regex={Regexes.DecimalNumber}
            min='0.001'
            step='0.001'
            value={form[AssemblyGroupFormKeys.HeadMaxCenterHole]}
            onChange={onUpdateForm(AssemblyGroupFormKeys.HeadMaxCenterHole)}
          />
        }]}/>
      </AppLineItem>

      {/* HERE */}
      {
        [
          HeadFormingTypes.Hemi,
          HeadFormingTypes.DOH,
          HeadFormingTypes.FandD
        ].includes(form[AssemblyGroupFormKeys.HeadFormingType])

        && <AppLineItem light={true} label='Inside Dish Radius' >
          <AppLineItemColumns components={[{
            key: 'inside-dish-radius',
            size: 3,
            component: <AppInput
              type='number'
              placeholder='0.000'
              regex={Regexes.DecimalNumber}
              min='0.001'
              step='0.001'
              value={form[AssemblyGroupFormKeys.InsideDishRadius]}
              onChange={onUpdateForm(AssemblyGroupFormKeys.InsideDishRadius)}
            />
          }]}/>
        </AppLineItem>
      }
      {
        form[AssemblyGroupFormKeys.HeadFormingType] === HeadFormingTypes.Hemi
        && <AppLineItem light={true} label='Cutback' >
          <AppLineItemColumns components={[{
            key: 'cutback',
            size: 3,
            component: <AppInput
              type='number'
              placeholder='0.000'
              regex={Regexes.DecimalNumber}
              min='0.001'
              step='0.001'
              value={form[AssemblyGroupFormKeys.Cutback]}
              onChange={onUpdateForm(AssemblyGroupFormKeys.Cutback)}
            />
          }]}/>
        </AppLineItem>
      }

      {
        form[AssemblyGroupFormKeys.HeadFormingType] === HeadFormingTypes.FandD
        && <AppLineItem light={true} label='Inside Knuckle Radius' >
          <AppLineItemColumns components={[{
            key: 'inside-knuckle-radius',
            size: 3,
            component: <AppInput
              type='number'
              placeholder='0.000'
              regex={Regexes.DecimalNumber}
              min='0.001'
              step='0.001'
              value={form[AssemblyGroupFormKeys.InsideKnuckleRadius]}
              onChange={onUpdateForm(AssemblyGroupFormKeys.InsideKnuckleRadius)}
            />
          }]}/>
        </AppLineItem>
      }

      <AppLineItem label='Additional Bevel Details' />

      <AppLineItem light={true} label='Open End:'>
        <AppLineItemColumns components={[
          {
            key: 'open-end-dropdown',
            component: <AppDropDownFinishedGoods
              areaId={areaId}
              valueType={QuoteLineItemValueTypes.EdgePreparation}
              itemType={form[AssemblyGroupFormKeys.Item_Type]}
              value={form[AssemblyGroupFormKeys.OpenEnd]}
              onChange={({value}) => onUpdateForm(AssemblyGroupFormKeys.OpenEnd)(value)}
            />
          },
          {
            key: 'open-end-input',
            className: 'padding-left-5',
            component: <AppInput
              placeholder='Specify Other Details...'
              value={form[AssemblyGroupFormKeys.OpenEndNotes]}
              onChange={onUpdateForm(AssemblyGroupFormKeys.OpenEndNotes)}
            />
          },
        ]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Petal to Petal:'>
        <AppLineItemColumns components={[
          {
            key: 'petal-to-petal-dropdown',
            component: <AppDropDownFinishedGoods
              areaId={areaId}
              valueType={QuoteLineItemValueTypes.EdgePreparation}
              itemType={form[AssemblyGroupFormKeys.Item_Type]}
              value={form[AssemblyGroupFormKeys.PetalToPetal]}
              onChange={({value}) => onUpdateForm(AssemblyGroupFormKeys.PetalToPetal)(value)}
            />
          },
          {
            className: 'padding-left-5',
            key: 'petal-to-petal-input',
            component: <AppInput
              placeholder='Specify Other Details...'
              value={form[AssemblyGroupFormKeys.PetalToPetalNotes]}
              onChange={onUpdateForm(AssemblyGroupFormKeys.PetalToPetalNotes)}
            />
          },
        ]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Crown to Petal:'>
        <AppLineItemColumns components={[
          {
            key: 'crown-to-petal-dropdown',
            component: <AppDropDownFinishedGoods
              areaId={areaId}
              valueType={QuoteLineItemValueTypes.EdgePreparation}
              itemType={form[AssemblyGroupFormKeys.Item_Type]}
              value={form[AssemblyGroupFormKeys.CrownToPetals]}
              onChange={({value}) => onUpdateForm(AssemblyGroupFormKeys.CrownToPetals)(value)}
            />
          },
          {
            key: 'crown-to-petal-input',
            className: 'padding-left-5',
            component: <AppInput
              placeholder='Specify Other Details...'
              value={form[AssemblyGroupFormKeys.CrownToPetalsNotes]}
              onChange={onUpdateForm(AssemblyGroupFormKeys.CrownToPetalsNotes)}
            />
          },
        ]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Number of Pieces in Head:'>
        <AppLineItemColumns components={[
          {
            key: 'number-pieces-head-radio',
            component: <AppRadioButtonsSelection
                valueType={QuoteLineItemValueTypes.HeadNumberOfPieces}
                areaId={areaId}
                selected={numberOfPiecesInHeadValue}
                onChange={onUpdateNumberOfPiecesInHeadRadioButton}
              />
          }
        ]}/>
      </AppLineItem>

      <AppLineItem label={<AppCheckboxItem
        label="Blank/Crown Cut by NC"
        checked={form[AssemblyGroupFormKeys.BlankCrownCutByNC]}
        onChange={onUpdateForm(AssemblyGroupFormKeys.BlankCrownCutByNC)}
      />} />
      <AppLineItem label={<AppCheckboxItem
        label="Petals Cut by NC"
        checked={form[AssemblyGroupFormKeys.PetalsCutByNC]}
        onChange={onUpdateForm(AssemblyGroupFormKeys.PetalsCutByNC)}
      />}/>

      <AppLineItem light={true} label='Other Clad Groups Combined in this head' >
        <AppLineItemColumns components={[{
          key: 'other-clad-group-combined',
          component: <AppInput
            placeholder='Specify Other Clad Groups...'
            value={form[AssemblyGroupFormKeys.OtherCladGroupsCombinedInThisHead]}
            onChange={onUpdateForm(AssemblyGroupFormKeys.OtherCladGroupsCombinedInThisHead)}
          />
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='NobelClad Procedure:'>
        <AppLineItemColumns components={[
          {
            key: 'nobel-clad-procedure-dropdown',
            component: <AppDropdownSelection
              areaId={areaId}
              valueType={QuoteLineItemValueTypes.NobelCladProcedure}
              value={form[AssemblyGroupFormKeys.NobelCladProcedure]}
              onChange={({ value }) => onUpdateForm(AssemblyGroupFormKeys.NobelCladProcedure)(value)}
            />
          },
          {
            key: 'nobel-clad-procedure-input',
            className: 'padding-left-5',
            component: <IonButton
              color='secondary'
              size='small'
              className='po-notes-button'
              onClick={() => setShowPoNotes(true)}
            >
              PO NOTES
            </IonButton>
          },
        ]}/>
      </AppLineItem>
      <AppLineItem light={true} label='NC Chemistry Restrictions for Bonding:'>
        <AppLineItemColumns components={[
          {
            key: 'nc-chemistry-rest-bonding-dropdown',
            component: <AppDropdownSelection
              areaId={areaId}
              valueType={QuoteLineItemValueTypes.NCChemistryRestrictionsForBonding}
              value={form[AssemblyGroupFormKeys.NCChemistryRestrictionsForBonding]}
              onChange={({ value }) => onUpdateForm(AssemblyGroupFormKeys.NCChemistryRestrictionsForBonding)(value)}
            />
          },
          {
            key: 'nc-chemistry-rest-bonding-input',
            className: 'padding-left-5',
            component: <AppInput
              placeholder='Specify Other Details...'
              value={form[AssemblyGroupFormKeys.NCChemistryRestrictionsForBondingNotes]}
              onChange={onUpdateForm(AssemblyGroupFormKeys.NCChemistryRestrictionsForBondingNotes)}
            />
          },
        ]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Head Forming Supplier:'>
        <AppLineItemColumns components={[
          {
            size: 6,
            key: 'nc-chemistry-rest-bonding-dropdown',
            component: <AppDropdownSelection
              areaId={areaId}
              valueType={QuoteLineItemValueTypes.HeadFormingSupplier}
              value={form[AssemblyGroupFormKeys.HeadFormingVendor]}
              onChange={({ value }) => onUpdateForm(AssemblyGroupFormKeys.HeadFormingVendor)(value)}
            />
          }
        ]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Clad Metal Preference:'>
        <AppLineItemColumns components={[
          {
            key: 'clad-metal-pref-radio',
            component: <AppRadioButtons
              selected={form[AssemblyGroupFormKeys.HeadFormingIDvsOD]}
              options={['Inside', 'Outside']}
              onChange={onUpdateForm(AssemblyGroupFormKeys.HeadFormingIDvsOD)}
            />
          }
        ]}/>
      </AppLineItem>
      <AppLineItem label='Simulate Heat Treatments:'/>
      <AppLineItem label={<AppCheckboxItem
        label="Simulate at Vendor"
        checked={form[AssemblyGroupFormKeys.OutsideServiceSimulateHeatTreatmentAtVendor]}
        onChange={onUpdateForm(AssemblyGroupFormKeys.OutsideServiceSimulateHeatTreatmentAtVendor)}
      />}/>
      <Cycle
        num="1"
        value={form[AssemblyGroupFormKeys.SimulationHTCycle1]}
        initialChecked={!!form[AssemblyGroupFormKeys.SimulationHTCycle1]}
        onToggleCheckbox={(checked) => {
          if (!checked) {
            onUpdateForm(AssemblyGroupFormKeys.SimulationHTCycle1)(null);
          }
        }}
        onChange={onUpdateForm(AssemblyGroupFormKeys.SimulationHTCycle1)}
      />
      <Cycle
        num="2"
        value={form[AssemblyGroupFormKeys.SimulationHTCycle2]}
        initialChecked={!!form[AssemblyGroupFormKeys.SimulationHTCycle2]}
        onToggleCheckbox={(checked) => {
          if (!checked) {
            onUpdateForm(AssemblyGroupFormKeys.SimulationHTCycle2)(null);
          }
        }}
        onChange={onUpdateForm(AssemblyGroupFormKeys.SimulationHTCycle2)}
      />
      <Cycle
        num="3"
        value={form[AssemblyGroupFormKeys.SimulationHTCycle3]}
        initialChecked={!!form[AssemblyGroupFormKeys.SimulationHTCycle3]}
        onToggleCheckbox={(checked) => {
          if (!checked) {
            onUpdateForm(AssemblyGroupFormKeys.SimulationHTCycle3)(null);
          }
        }}
        onChange={onUpdateForm(AssemblyGroupFormKeys.SimulationHTCycle3)}
      />
      <Cycle
        num="4"
        value={form[AssemblyGroupFormKeys.SimulationHTCycle4]}
        initialChecked={!!form[AssemblyGroupFormKeys.SimulationHTCycle4]}
        onToggleCheckbox={(checked) => {
          if (!checked) {
            onUpdateForm(AssemblyGroupFormKeys.SimulationHTCycle4)(null);
          }
        }}
        onChange={onUpdateForm(AssemblyGroupFormKeys.SimulationHTCycle4)}
      />
      <Cycle
        num="5"
        value={form[AssemblyGroupFormKeys.SimulationHTCycle5]}
        initialChecked={!!form[AssemblyGroupFormKeys.SimulationHTCycle5]}
        onToggleCheckbox={(checked) => {
          if (!checked) {
            onUpdateForm(AssemblyGroupFormKeys.SimulationHTCycle5)(null);
          }
        }}
        onChange={onUpdateForm(AssemblyGroupFormKeys.SimulationHTCycle5)}
      />

      <AppLineItem light={true} label='Head Forming Cost Per Head:' >
        <AppLineItemColumns components={[{
          key: 'head-forming-cost-per-head',
          size: 3,
          component: <AppLineItemColumns components={[
            {
              size: 6,
              key: 'head-forming-cost-per-head-input',
              component: <AppInput
                placeholder='0.00'
                type='number'
                regex={Regexes.DecimalNumber}
                min='0.00'
                value={form[AssemblyGroupFormKeys.HeadFormingCostPerHead]}
                onChange={onUpdateForm(AssemblyGroupFormKeys.HeadFormingCostPerHead)}
              />
            },
          ]}/>
        }]}/>
      </AppLineItem>

      <AppLineItem light={true} label='Head Freight Cost to Former:' >
        <AppLineItemColumns components={[{
          key: 'head-freight-cost-to-former',
          size: 3,
          component: <AppLineItemColumns components={[
            {
              key: 'head-freight-cost-to-former-input',
              component: <FreightCostInput
                quote={quote}
                value={form[AssemblyGroupFormKeys.HeadFreightCostToFormer]}
                onChange={onUpdateForm(AssemblyGroupFormKeys.HeadFreightCostToFormer)}
              />
            },
            {
              key: 'head-freight-cost-to-former-label',
              className: 'd-flex align-items-center',
              component: <IonLabel className='padding-left-5'>Per {WeightLabelUOM[displayUom]}</IonLabel>
            }
          ]}/>
        }]}/>
      </AppLineItem>

      <AppLineItem light={true} label='Time to Ship to Former:' >
        <AppLineItemColumns components={[{
          key: 'time-to-ship-to-former',
          size: 3,
          component: <AppLineItemColumns components={[
            {
              key: 'time-to-ship-to-former-input',
              component: <AppInput
                type='number'
                placeholder='1'
                regex={Regexes.IntegerAboveZero}
                min='1'
                value={form[AssemblyGroupFormKeys.HeadTimeToFormer]}
                onChange={onUpdateForm(AssemblyGroupFormKeys.HeadTimeToFormer)}
              />
            },
            {
              key: 'time-to-ship-to-former-label',
              className: 'd-flex align-items-center',
              component: <IonLabel className='padding-left-5'>Weeks</IonLabel>
            }
          ]}/>
        }]}/>
      </AppLineItem>

      <AppLineItem light={true} label='Time to Form Head:' >
        <AppLineItemColumns components={[{
          key: 'time-to-form-head',
          size: 3,
          component: <AppLineItemColumns components={[
            {
              key: 'time-to-form-head-input',
              component: <AppInput
                type='number'
                placeholder='1'
                regex={Regexes.IntegerAboveZero}
                min='1'
                value={form[AssemblyGroupFormKeys.HeadTimeToFormHead]}
                onChange={onUpdateForm(AssemblyGroupFormKeys.HeadTimeToFormHead)}
              />
            },
            {
              key: 'time-to-form-head-label',
              className: 'd-flex align-items-center',
              component: <IonLabel className='padding-left-5'>Weeks</IonLabel>
            }
          ]}/>
        }]}/>
      </AppLineItem>
      <AppLineItem light={true} label='Head Contour, Tolerances & Heat Treatment Options:' >
      <AppLineItemColumns components={[
      {
        key: 'head-contour',
        component: <AppRadioButtonsSelection
          valueType={QuoteLineItemValueTypes.HeatContourTolerancesAndHeatTreatmentOptions}
          areaId={areaId}
          selected={headContourValue}
          onChange={(value) => {
            onUpdateForm(AssemblyGroupFormKeys.HeadContourToleranceHTOptions)(value);
          }}
          />
        }
      ]}/>
      <AppInput
        disabled={headContourValue === headContourOptions[0]}
        placeholder='Specify Other...'
        onChange={(value) => {
          if (!value) {
            return;
           }        
          onUpdateForm(AssemblyGroupFormKeys.HeadContourToleranceHTOptions)(value); // Update the same form key
        }}
        value={headContourOptions.includes(form[AssemblyGroupFormKeys.HeadContourToleranceHTOptions]) ? '' : form[AssemblyGroupFormKeys.HeadContourToleranceHTOptions]}
      />

      </AppLineItem>
      <AppLineItem light={true} label='Additional Head Information:' >
        <AppLineItemColumns components={[{
          key: 'additional-head-info',
          component: <AppTextarea
            rows={5}
            placeholder="Specify Additional Head Info..."
            value={form[AssemblyGroupFormKeys.AdditionalHeadInformation]}
            onChange={(value)=> {
              onUpdateForm(AssemblyGroupFormKeys.AdditionalHeadInformation)(value);
              onUpdateForm(AssemblyGroupFormKeys.AdditionalHeadInformation_HID)(value);
            }}
          />
        }]}/>
      </AppLineItem>
    </PanelSection>

    {
      showPoNotes && <PONotes
        form={form}
        onDismiss={() => setShowPoNotes(false)}
      />
    }
  </>
};

export default PanelHeadForming;