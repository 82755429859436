import Footer from '@components/footer/footer';
import LineItemActions from '@components/line-item-actions/line-item-actions';
import Loading from '@components/loading/loading';
import { IonButton, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPopover, IonRow, IonTextarea } from '@ionic/react';
import { useSalesforce } from '@services/salesforce/salesforce-service';
import { formatCurrency, getStatusImageFromAPIStatus } from '@shared/utils';
import { mapOrder } from '@shared/order-utils';
import classNames from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router';
import './order-detail.scss';
import { NCLineItemsColumnHeadings, OrderActions, OrderDisplayStatuses, UserType } from '@constants';
import { chevronDown, chevronUp, pencilSharp } from 'ionicons/icons';
import { usePlatform } from '@services/platform-service';
import OrderLineItemDetailsPopover from '@components/popover/order-line-item-details-popover/order-line-item-details-popover';
import { useLastViewed } from '@services/last-viewed/last-viewed-service';
import Badge from '@components/badge/badge';
import { useParams } from 'react-router-dom';
import { getColumnIdFromName, mapNCLineItem } from '@shared/nc-line-item-utils';
import AddDocumentsPopover from '@components/popover/add-documents-popover/add-documents-popover';
import { useEnvironment } from '@services/environment-service';
import moment from 'moment';
import Documents from '@components/documents/documents';
import { attachOutline } from 'ionicons/icons';
import { useDarkMode } from '@hooks/useDarkMode';
import { mapDocumentData, uniqueDocuments } from '@shared/document-utils';
import { Subscription } from 'rxjs';
import { MappedDocumentData, MappedNCLineItem } from '@shared/types';

const OrderDetailPage = (props) => {
  const salesforce = useSalesforce();
  const platform = usePlatform();
  const lastViewed = useLastViewed();
  const environment = useEnvironment();
  const { darkMode } = useDarkMode();
  const history = useHistory();
  const { id } = useParams<{id: string}>();

  const [displayedOrderLineItems, setDisplayedOrderLineItems] = React.useState<MappedNCLineItem[]>(undefined);
  const [mappedOrderLineItems, setMappedOrderLineItems] = React.useState<MappedNCLineItem[]>(undefined);
  const [order, setOrder] = React.useState(undefined);
  const [loading, setLoading] = React.useState(undefined);
  const [total, setTotal] = React.useState(undefined);
  const [orderLineItemHeadings] = React.useState(NCLineItemsColumnHeadings);
  const [orderLineItemSortColumnHeading, setOrderLineItemSortColumnHeading] = React.useState(undefined);
  const [orderLineItemSortColumnId, setOrderLineItemSortColumnId] = React.useState(undefined);
  const [orderLineItemSortAsc, setOrderLineItemSortAsc] = React.useState(true);
  const [displayedDocuments, setDisplayedDocuments] = React.useState<MappedDocumentData[]>(undefined);
  const [mappedDocuments, setMappedDocuments] = React.useState<MappedDocumentData[]>(undefined);
  const [documentUpdated, setDocumentUpdated] = React.useState(false);
  const [updatingNotes, setUpdatingNotes] = React.useState(false);
  const [newOrderNotes, setNewOrderNotes] = React.useState(undefined);
  const [contact, setContact] = React.useState(undefined);
  const [showDocumentActions, setShowDocumentActions] = React.useState<boolean>(false);

  const subscriptions: Subscription[] = [];

  React.useEffect(() => {
    loadOrder();

    subscriptions.push(salesforce.userInfo$().subscribe(async userInfo => {
      setContact(userInfo?.contact);
    }));

    return () => {
      subscriptions.forEach(s => s.unsubscribe());
    };
  }, []);

  React.useEffect(() => {
    if (order) {
      lastViewed.updateItemLastViewed({ id: order.Id, lastViewed: new Date().toLocaleString() });
      loadData();
      const { Payment_Terms__c: terms, Invoice_Paid__c: isPaid } = order;
      // restrict document access if the payment terms are LOC or CAD, and the order invoice is not marked as paid
      // also dont restrict if user is sales or internally viewed
      const hasRestrictedAccess =
        terms === ('LOC' || 'CAD') && !isPaid && !(userType() === UserType.Sales || internalView());
      setShowDocumentActions(!hasRestrictedAccess);
    }
  }, [order]);

  React.useEffect(() => {
    sortOrderLineItems();
  }, [orderLineItemSortColumnId, orderLineItemSortAsc]);

  React.useEffect(() => {
    if(documentUpdated) {
      loadDocuments();
      setDocumentUpdated(false);
    }
  }, [documentUpdated]);

  React.useEffect(() => {
    if(mappedDocuments) {
      setDisplayedDocuments(uniqueDocuments(mappedDocuments).slice(0, 6));
    }
  }, [mappedDocuments]);

  React.useEffect(() => {
    if(newOrderNotes !== undefined) {
      setUpdatingNotes(false);
      updateOrder();
    }
  }, [newOrderNotes]);

  // Loads the order from order state or from the order id in the route parameters
  const loadOrder = async (forceReload?: boolean) => {
    const orderState = props?.history?.location?.state?.order;

    if(orderState) {
      setOrder(!forceReload ? orderState : await mapOrder(await salesforce.getAccountOrderById(orderState.Id), undefined, darkMode));
    } else {
      const order = await mapOrder(await salesforce.getAccountOrderById(id), undefined, darkMode);
      setOrder(order);
    }
  };

  // Loads the order line item data and documents
  const loadData = async () => {
    try {
      await loadOrderLineItems();
      await loadDocuments();

      setLoading(false);
      setTotal(formatCurrency(order.Order_Total_Amount__c || 0, order.CurrencyIsoCode));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Loads the order line items
  const loadOrderLineItems = async () => {
    const orderLineItems = await salesforce.getAccountOrderLineItems(order.Id);
    const mappedOrderLineItems: MappedNCLineItem[] = await Promise.all(
      orderLineItems.map(async orderLineItem => await mapNCLineItem(orderLineItem, lastViewed, darkMode))
    );

    setMappedOrderLineItems(mappedOrderLineItems);
    setDisplayedOrderLineItems(mappedOrderLineItems.filter(orderLineItem => orderLineItem.Line_Item_Detail_Number__c === '01'));

    const databaseColumnId = getColumnIdFromName(NCLineItemsColumnHeadings.Line);
    setOrderLineItemSortColumnId(databaseColumnId);
  };

  // Loads the documents
  const loadDocuments = async () => {
    const documentData = await salesforce.getAccountOrderDocuments(order.Id);

    const mappedDocuments: MappedDocumentData[] = await Promise.all(
      documentData.map(async documentData => await mapDocumentData(documentData, lastViewed))
    );

    setMappedDocuments(mappedDocuments.orderByDate(document => document.CreatedDate ? moment(document.CreatedDate).format('MM/DD/YYYY') : document.CreatedDate, false));
  };

  // Handler for when an order line item column is selected
  const selectColumnHandler = (column: NCLineItemsColumnHeadings) => {
    setOrderLineItemSortColumnHeading(column);

    const databaseColumnId = getColumnIdFromName(column as NCLineItemsColumnHeadings);

    if(orderLineItemSortColumnId === databaseColumnId) {
      setOrderLineItemSortAsc(!orderLineItemSortAsc);
    } else {
      setOrderLineItemSortColumnId(databaseColumnId);
    }
  };

  // For order line items, Returns proper column header class based on whether the column is sorted or not
  const getOrderLineItemsColHeaderClass = (column: NCLineItemsColumnHeadings) => {
    return (column === orderLineItemSortColumnHeading) ? 'order-line-items-text text-bold' : 'order-line-items-text';
  };

  // For order line items, Returns proper column header icon based on whether the column is sorted or not
  const getOrderLineItemsColHeaderIcon = (column: NCLineItemsColumnHeadings) => {
    return (column === orderLineItemSortColumnHeading && orderLineItemSortAsc) ? chevronUp : chevronDown;
  };

  // Navigates back to the orders page
  const navigateToOrdersPage = () => {
    history.push('/orders');
  };

  // Sorts the order line items according to the sort column
  const sortOrderLineItems = async () => {
    let updatedItems;

    if(displayedOrderLineItems) {
      updatedItems = [...displayedOrderLineItems];
    }

    switch(orderLineItemSortColumnId) {
    case getColumnIdFromName(NCLineItemsColumnHeadings.Status):
      updatedItems = updatedItems?.orderBy(item => +item.statusSortingOrder, orderLineItemSortAsc);
      break;
    case getColumnIdFromName(NCLineItemsColumnHeadings.Quantity):
      updatedItems = updatedItems?.orderBy(item => +item[orderLineItemSortColumnId], orderLineItemSortAsc);
      break;
    default:
      updatedItems = updatedItems?.orderBy(item => item[orderLineItemSortColumnId], orderLineItemSortAsc);
      break;
    }

    setDisplayedOrderLineItems(updatedItems);
  };

  // Returns the user type from environment
  const userType = () => {
    return environment?.userType();
  };

  // Returns internal view flag from environment
  const internalView = () => {
    return environment?.internalView();
  };

  const updateOrder = async () => {
    setLoading(true);

    const updatedOrderFields = {
      Description__c: newOrderNotes,
      Notes_Updated_By__c: `${contact?.FirstName} ${contact?.LastName}`,
      Notes_Updated_Date_Time__c: new Date()
    };

    await salesforce.updateAccountOrderById(order.Id, updatedOrderFields);
    await loadOrder(true);
  };

  const OrderLineItem = (props) => {
    const [orderLineItemDetailsPopoverState, setShowOrderLineItemDetailsPopover] = React.useState({ showPopover: false, event: undefined });
    const [addDocumentsPopoverState, setShowAddDocumentsPopover] = React.useState({ showPopover: false, event: undefined });
    const [documentsAdded, setDocumentsAdded] = React.useState(false);

    const lineItemDetails = mappedOrderLineItems?.filter(mappedOrderLineItem => mappedOrderLineItem.Line_Number__c === props.orderLineItem.Line_Number__c);

    const linesMap = new Map<string, string[]>();
    linesMap.set(props.orderLineItem.Line_Number__c,
      lineItemDetails
        .orderBy(lineItemDetail => lineItemDetail.Line_Item_Detail_Number__c, true)
        .map(lineItemDetail => `${lineItemDetail.Line_Number__c}-${lineItemDetail.Line_Item_Detail_Number__c}`));

    const setShowAddDocumentsPopoverState = () => {
      setShowAddDocumentsPopover({ showPopover: true, event: undefined });
    };

    const lineItemDocuments = () => {
      return mappedDocuments?.filter(mappedDocument => lineItemDetailContainedInLine(mappedDocument.NC_Line_Item__c));
    };

    const lineItemDetailContainedInLine = (documentLineItem) => {
      return lineItemDetails.some(lineItemDetail => lineItemDetail.Id === documentLineItem);
    };

    const earliestLineItemDetailStatus = () => {
      const minStatusSortingOrder = Math.min(...lineItemDetails.map(lineItemDetail => +lineItemDetail.statusSortingOrder));

      return lineItemDetails.find(lineItemDetail => +lineItemDetail.statusSortingOrder === minStatusSortingOrder)?.Status__c;
    };

    const LineItemAttachmentIndicator = () => {
      return lineItemDocuments().length ?
        <IonIcon data-testid="QAOrderDetailPageAttachmentIndicator" class='paperclip-icon' icon={attachOutline}></IonIcon> : null;
    };

    return (
      <>
        <IonPopover
          cssClass='order-line-item-details-popover'
          isOpen={orderLineItemDetailsPopoverState.showPopover}
          animated={ false }
          onDidDismiss={() => {
            setShowOrderLineItemDetailsPopover({ showPopover: false, event: undefined });
          }}
        >
          <OrderLineItemDetailsPopover
            setShowPopover={setShowOrderLineItemDetailsPopover}
            orderLineItem={props.orderLineItem}
            lineItemDetails={lineItemDetails}
            order={props.order}
            documents={uniqueDocuments(lineItemDocuments())}
            setDocumentUpdated={setDocumentUpdated}
            showDocumentActions={showDocumentActions}
          />
        </IonPopover>
        <IonPopover
          cssClass='add-documents-popover'
          isOpen={addDocumentsPopoverState.showPopover}
          animated={ false }
          onDidDismiss={async () => {
            setShowAddDocumentsPopover({ showPopover: false, event: undefined });

            if(documentsAdded) {
              loadDocuments();
            }
          }}
        >
          <AddDocumentsPopover setShowPopover={setShowAddDocumentsPopover} setDocumentsAdded={setDocumentsAdded} salesOrderNumber={order.AX_Order_Number__c} poNumber={order.PO_Number__c} linesMap={linesMap}/>
        </IonPopover>
        <IonCard className="order-line-item-card" onClick={(e: any) => {
          e.persist();
          setShowOrderLineItemDetailsPopover({ showPopover: true, event: e });
          props.orderLineItem.badgeText = undefined;
        }} data-testid={'QAOrderDetailPageOrderLineItem_' + props.orderLineItemIndex}
        >
          <IonCardHeader class={classNames({ 'order-line-item-card-header': true, 'even': props.orderLineItemIndex % 2 === 0, 'odd': props.orderLineItemIndex % 2 !== 0 })}>
            <Badge text={props.orderLineItem.badgeText}/>
            <IonGrid>
              <IonRow class="order-line-item-row">
                <IonCol class="column-left" size={ !platform.isMobile() ? (!platform.isTablet() ? '0.5' : '1') : '11' }>
                  <span className="order-line-items-text mobile-only line-label">{ orderLineItemHeadings.Line }</span>
                  <span className="order-line-item-summary-text" data-testid="QAOrderDetailPageLineItemLine">{ props.orderLineItem.Line_Number__c }</span>
                </IonCol>
                <IonCol class="column-left" size={ !platform.isMobile() ? '2' : '11' }>
                  <span className="order-line-items-text mobile-only status-label">{ orderLineItemHeadings.Status }</span>
                  <img className="status-icon" src={ getStatusImageFromAPIStatus(earliestLineItemDetailStatus(), darkMode) }/>
                  <span className="order-line-item-summary-text" data-testid="QAOrderDetailPageLineItemStatus">{ earliestLineItemDetailStatus() }</span>
                </IonCol>
                <IonCol class="column-left" size={ !platform.isMobile() ? (!platform.isTablet() ? '4.5' : '4') : '11' }>
                  <span className="order-line-items-text mobile-only description-label">{ orderLineItemHeadings.Description }</span>
                  <span className="order-line-item-summary-text" data-testid="QAOrderDetailPageLineItemDescription">{ props.orderLineItem.Description__c }</span>
                </IonCol>
                <IonCol class="column-left" size={ !platform.isMobile() ? (!platform.isTablet() ? '1.5' : '1') : '11' }>
                  <span className="order-line-items-text mobile-only quantity-label">{ orderLineItemHeadings.Quantity }</span>
                  <span className="order-line-item-summary-text" data-testid="QAOrderDetailPageLineItemQuantity">{ props.orderLineItem.Portal_Quantity__c }</span>
                </IonCol>
                <IonCol class="column-left" size={ !platform.isMobile() ? '1.5' : '11' }>
                  <span className="order-line-items-text mobile-only unit-price-label">{ orderLineItemHeadings.UnitPrice }</span>
                  <span className="order-line-item-summary-text" data-testid="QAOrderDetailPageLineItemUnitPrice">{ formatCurrency(props.orderLineItem.Unit_Price__c || 0, props.orderLineItem.CurrencyIsoCode) }</span>
                </IonCol>
                <IonCol class="column-left" size={ !platform.isMobile() ? '2' : '11' }>
                  <span className="order-line-items-text mobile-only total-label">{ orderLineItemHeadings.Total }</span>
                  <span className="order-line-item-summary-text total-price" data-testid="QAOrderDetailPageLineItemTotal">{  formatCurrency(props.orderLineItem.Unit_Price_Quantity__c || 0, props.orderLineItem.CurrencyIsoCode) }</span>
                </IonCol>
                <LineItemAttachmentIndicator/>
                <LineItemActions
                  visible={true}
                  actions={(userType() === UserType.Sales || internalView()) && !platform.isMobile() && !platform.isTablet() ? [{
                    label: OrderActions.AddDocuments,
                    actionToPerform: setShowAddDocumentsPopoverState
                  }] : []}
                  data-testid="QAOrderDetailPageOrderLineItemActions"
                >
                </LineItemActions>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const OrderTitleBreak = () => {
    return (order?.PO_Number__c?.length > 10 && platform.isMobile() ?
      <br/> : null
    );
  };

  const OrderDetailTitleCard = () => {
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });
    const [documentsAdded, setDocumentsAdded] = React.useState(false);

    const setShowPopoverState = () => {
      setShowPopover({ showPopover: true, event: undefined });
    };

    const linesMap = new Map<string, string[]>();
    displayedOrderLineItems?.forEach(orderLineItem => {
      linesMap.set(orderLineItem.Line_Number__c,
        mappedOrderLineItems?.filter(mappedOrderLineItem => mappedOrderLineItem.Line_Number__c === orderLineItem.Line_Number__c)
          .orderBy(lineItemDetail => lineItemDetail.Line_Item_Detail_Number__c, true)
          .map(lineItemDetail => `${lineItemDetail.Line_Number__c}-${lineItemDetail.Line_Item_Detail_Number__c}`));
    });

    return (
      <>
        <IonPopover
          cssClass='add-documents-popover'
          isOpen={popoverState.showPopover}
          animated={ false }
          onDidDismiss={async () => {
            setShowPopover({ showPopover: false, event: undefined });

            if(documentsAdded) {
              loadDocuments();
            }
          }}
        >
          <AddDocumentsPopover setShowPopover={setShowPopover} setDocumentsAdded={setDocumentsAdded} salesOrderNumber={order.AX_Order_Number__c} poNumber={order.PO_Number__c} linesMap={linesMap}/>
        </IonPopover>
        <IonCard class='order-detail-title-card'>
          <IonCardHeader class='order-detail-title-card-header'>
            <IonGrid>
              <IonRow data-testid="QAOrderDetailPageHeader">
                <IonLabel class='orders-title'><span className="orders" onClick={() => navigateToOrdersPage()} data-testid="QAOrderDetailPageTitle">Orders</span><IonLabel class='order-number-title' data-testid="QAOrderDetailPageOrderNumberTitle"> / <OrderTitleBreak/>{order?.PO_Number__c}</IonLabel></IonLabel>
                <LineItemActions
                  visible={true}
                  actions={(userType() === UserType.Sales || internalView()) && !platform.isMobile() && !platform.isTablet() ? [{
                    label: OrderActions.AddDocuments,
                    actionToPerform: setShowPopoverState
                  }] : []}
                >
                </LineItemActions>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const OrderNotes = () => {
    const orderNotesTextArea = React.useRef(null);

    return (!updatingNotes ?
      <IonLabel class="notes" data-testid="QAOrderDetailPageOrderNotes">{ order?.Description__c }</IonLabel> :
      <>
        <IonTextarea
          ref={orderNotesTextArea}
          class="notes-text-area"
          placeholder='Order Notes...'
          rows={10}
          value={order?.Description__c}
          autoGrow={true}
          data-testid='QAOrderDetailPageOrderNotesTextArea'
        />
        <div className='notes-buttons-container'>
          <IonButton color="primary" class="save-notes-button" data-testid="QAOrderDetailPageOrderNotesSaveButton" onClick={() => setNewOrderNotes(orderNotesTextArea.current.value)}>Save</IonButton>
          <IonButton color="secondary" class="cancel-editing-notes-button" onClick={() => setUpdatingNotes(false)}>Cancel</IonButton>
        </div>
      </>
    );
  };

  const NotesPencil = () => {
    return (!updatingNotes && (userType() === UserType.Sales || internalView()) ?
      <IonIcon class='pencil-icon' src={pencilSharp} onClick={() => setUpdatingNotes(true)} data-testid="QAOrderDetailPageEditOrderNotes"/>
      : null
    );
  };

  const AccountLabel = () => {
    return (internalView() ?
      <IonItem class="ion-no-padding label-container">
        <IonLabel class="order-info-label">Account:</IonLabel>
      </IonItem>
      : null
    );
  };

  const AccountValue = () => {
    return (internalView() ?
      <IonItem class="ion-no-padding value-container">
        <IonLabel class="order-info-value" data-testid="QAOrderDetailPageAccount">{order?.Account_Name__r?.Name || '--'}</IonLabel>
      </IonItem>
      : null
    );
  };

  return ( displayedOrderLineItems && displayedDocuments && !loading ?
    <IonContent class="order-detail-page">
      <div className={classNames({
        'max-width-container': true,
        'order-detail-wrapper': true,
        'loading': loading
      })}
      >
        <OrderDetailTitleCard/>
        <hr/>
        <div className='order-details-container'>
          <div className={classNames({
            'order-status-container': true,
            'open': order?.Status === OrderDisplayStatuses.Open,
            'complete': order?.Status === OrderDisplayStatuses.Complete,
            'canceled': order?.Status === OrderDisplayStatuses.Canceled,
          })}
          >
            <IonLabel class='order-status-title'>Order Status</IonLabel>
            <IonLabel class='order-status' data-testid="QAOrderDetailPageOrderStatus">{order?.Status}</IonLabel>
          </div>
          <div className='order-info-container'>
            <IonList lines="none" class="ion-no-padding order-info-label-container">
              <IonItem class="ion-no-padding label-container total">
                <IonLabel class="order-info-label total">Total</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding label-container">
                <IonLabel class="order-info-label">NC Sales Order:</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding label-container">
                <IonLabel class="order-info-label">Order Date:</IonLabel>
              </IonItem>
              <AccountLabel/>
              <IonItem class="ion-no-padding label-container">
                <IonLabel class="order-info-label">Payment Terms:</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding label-container">
                <IonLabel class="order-info-label">Delivery Term:</IonLabel>
              </IonItem>
            </IonList>
            <IonList lines="none" class="ion-no-padding order-info-value-container">
              <IonItem class="ion-no-padding value-container total">
                <IonLabel class="order-info-value total" data-testid="QAOrderDetailPageTotal">{total || '--'}</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding value-container">
                <IonLabel class="order-info-value" data-testid="QAOrderDetailPageNCSalesOrder">{order?.AX_Order_Number__c || '--'}</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding value-container">
                <IonLabel class="order-info-value" data-testid="QAOrderDetailPageOrderDate">{order?.Order_Booking_Date__c || '--'}</IonLabel>
              </IonItem>
              <AccountValue/>
              <IonItem class="ion-no-padding value-container">
                <IonLabel class="order-info-value" data-testid="QAOrderDetailPagePaymentTerms">{order?.Payment_Terms__c || '--'}</IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding value-container">
                <IonLabel class="order-info-value" data-testid="QAOrderDetailPageDeliveryTerm">{order?.Delivery_Term__c || '--'}</IonLabel>
              </IonItem>
            </IonList>
          </div>
        </div>
        <div className="line-items-container">
          <IonLabel class="line-items-label">Line Items</IonLabel>
          <hr/>
          <IonCard class="order-line-items-card">
            <IonCardHeader class="order-line-items-card-header">
              <IonGrid>
                <IonRow class="order-line-items-row">
                  <IonCol class="order-line-items-col"  size={(!platform.isTablet() ? '0.5' : '1')} onClick={() => selectColumnHandler(orderLineItemHeadings.Line)} data-testid="QAOrderDetailPageLineItemLineColumnHeader">
                    <span className={getOrderLineItemsColHeaderClass(orderLineItemHeadings.Line)}>{ orderLineItemHeadings.Line }</span>
                    <IonIcon icon={getOrderLineItemsColHeaderIcon(orderLineItemHeadings.Line)}></IonIcon>
                  </IonCol>
                  <IonCol class="order-line-items-col"  size="2" onClick={() => selectColumnHandler(orderLineItemHeadings.Status)} data-testid="QAOrderDetailPageLineItemStatusColumnHeader">
                    <span className={getOrderLineItemsColHeaderClass(orderLineItemHeadings.Status)}>{ orderLineItemHeadings.Status }</span>
                    <IonIcon icon={getOrderLineItemsColHeaderIcon(orderLineItemHeadings.Status)}></IonIcon>
                  </IonCol>
                  <IonCol class="order-line-items-col non-sorting" size={(!platform.isTablet() ? '4.5' : '4')} data-testid="QAOrderDetailPageLineItemDescriptionColumnHeader">
                    <span className="order-line-items-text">{ orderLineItemHeadings.Description }</span>
                  </IonCol>
                  <IonCol class="order-line-items-col" size={(!platform.isTablet() ? '1.5' : '1')} onClick={() => selectColumnHandler(orderLineItemHeadings.Quantity)} data-testid="QAOrderDetailPageLineItemQuantityColumnHeader">
                    <span className={getOrderLineItemsColHeaderClass(orderLineItemHeadings.Quantity)}>{ orderLineItemHeadings.Quantity }</span>
                    <IonIcon icon={getOrderLineItemsColHeaderIcon(orderLineItemHeadings.Quantity)}></IonIcon>
                  </IonCol>
                  <IonCol class="order-line-items-col" size="1.5" onClick={() => selectColumnHandler(orderLineItemHeadings.UnitPrice)} data-testid="QAOrderDetailPageLineItemUnitPriceColumnHeader">
                    <span className={getOrderLineItemsColHeaderClass(orderLineItemHeadings.UnitPrice)}>{ orderLineItemHeadings.UnitPrice }</span>
                    <IonIcon icon={getOrderLineItemsColHeaderIcon(orderLineItemHeadings.UnitPrice)}></IonIcon>
                  </IonCol>
                  <IonCol class="order-line-items-col" size="2" onClick={() => selectColumnHandler(orderLineItemHeadings.Total)} data-testid="QAOrderDetailPageLineItemTotalColumnHeader">
                    <span className={getOrderLineItemsColHeaderClass(orderLineItemHeadings.Total)}>{ orderLineItemHeadings.Total }</span>
                    <IonIcon icon={getOrderLineItemsColHeaderIcon(orderLineItemHeadings.Total)}></IonIcon>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardHeader>
          </IonCard>
          {displayedOrderLineItems?.map((orderLineItem, index) => (
            <OrderLineItem orderLineItem={orderLineItem} orderLineItemIndex={index} key={index} order={order}/>
          ))}
          <hr className='bottom-rule'/>
        </div>
        <div className="notes-container">
          <div className='notes-header-container'>
            <div className='notes-label-container'>
              <IonLabel class="order-info-label">Notes</IonLabel>
              <NotesPencil/>
            </div>
            <IonLabel class="last-update-label" data-testid="QAOrderDetailPageNotesLastUpdate">Last update: { order?.Notes_Updated_Date_Time__c }</IonLabel>
          </div>
          <OrderNotes/>
        </div>
        <Documents
          displayedDocuments={displayedDocuments}
          mappedDocuments={uniqueDocuments(mappedDocuments)}
          setDocumentUpdated={setDocumentUpdated}
          showDocumentActions={showDocumentActions}
        />
      </div>
      <Footer/>
    </IonContent> :
    <div className="order-detail-loading-container">
      <Loading data-testId="'QAOrderDetailLoading'"/>
    </div>
  );
};

export default OrderDetailPage;