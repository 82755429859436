import { ToastTypes } from '@constants';
import { useDarkMode } from '@hooks/useDarkMode';
import { IonIcon, IonImg, IonLabel } from '@ionic/react';
import React from 'react';
import './toast.scss';
import { useToastDispatchContext } from '@services/toast-service';
import { checkmarkCircle, warning, close } from 'ionicons/icons';
import classNames from 'classnames';
import rickroll from '@assets/images/rickroll.gif';

const Toast = (props) => {
  const { darkMode } = useDarkMode();
  const dispatch = useToastDispatchContext();

  const ToastTypeIndicator = () => {
    switch(props.type) {
    case ToastTypes.Error:
      return (
        <>
          <div className="toast-type-line error"/>
          <IonIcon class="toast-type-icon error" src={warning}></IonIcon>
          <IonImg class="secret-rick" src={rickroll}></IonImg>
        </>
      );
      case ToastTypes.Warning:
        return (
          <>
            <div className="toast-type-line warning" />
            <IonIcon class="toast-type-icon warning" src={warning}></IonIcon>
          </>
        );
    }

    return (
      <>
        <div className="toast-type-line success"/>
        <IonIcon class="toast-type-icon success" src={checkmarkCircle}></IonIcon>
      </>
    );
  };

  const ToastTitle = () => {
    return props.type === ToastTypes.Warning ? (
      <div>
        <IonLabel class="toast-title">Guideline Violations</IonLabel>
      </div>
    ) : null;
  };
  

  return (
    <div className={classNames({'toast-container': true, 'dark-mode': darkMode, 'warning-padding': props.type === ToastTypes.Warning })}>
      <div className="toast-type-container">
        <ToastTypeIndicator/>
      </div>
      <div className="message-container">
        <ToastTitle />
        <IonLabel class="toast-message">
          {props.type !== ToastTypes.Warning ? (
            props.message
          ) : props.type === ToastTypes.Warning ? (
            props.message.map((rule, index) => <p className={'rules'} key={index}>{rule}</p>)
          ) : null}
        </IonLabel>
      </div>
      <IonIcon src={close} class="close-icon" onClick={() => dispatch({ type: 'DELETE_TOAST', id: props.id })}></IonIcon>
    </div>
  );
};

export default Toast;