import blueStatusIcon from '@assets/icon/status_blue.svg';
import grayStatusIcon from '@assets/icon/status_gray.svg';
import greenStatusIcon from '@assets/icon/status_green.svg';
import lightBlueStatusIcon from '@assets/icon/status_lightblue.svg';
import lightGreenStatusIcon from '@assets/icon/status_lightgreen.svg';
import magentaStatusIcon from '@assets/icon/status_magenta.svg';
import purpleStatusIcon from '@assets/icon/status_purple.svg';
import redStatusIcon from '@assets/icon/status_red.svg';
import silverStatusIcon from '@assets/icon/status_silver.svg';
import yellowStatusIcon from '@assets/icon/status_yellow.svg';
import {
  BadgeText,
  InvoiceAPIStatuses,
  LanguageCountryCodes,
  NCLineItemAPIStatuses,
  OrderAPIStatuses,
  PageRoutes,
  recentHoursThreshold,
  TimeIntervals,
  Units
} from '@constants';
import { Currency } from '@interfaces/salesforce';
import { convertCurrency } from '@services/currency-conversion-service';
import { v4 as uuidv4 } from 'uuid';

export const isNullOrUndefined = (obj: any) => {
  return obj === null || obj === undefined;
};

export const isNumeric = (obj: any) => {
  return !isNaN(parseFloat(obj));
};

export const isObject = (obj: any) => {
  return obj && typeof obj === 'object' && !Array.isArray(obj);
};

export const getPercentageNumber = (percentage: any) => {
  return +(percentage as string).replace('%', '');
};

export const getPriceNumber = (price: any, selectedCurrencySymbol: string) => {
  return +(price as string).replace(selectedCurrencySymbol, '').replace(',', '');
};

export const validatePrice = (price: any, selectedCurrencySymbol: string) => {
  const splitCurrencyString = selectedCurrencySymbol.split('$');
  let currencyStringPrefix: string;
  let currencyStringSymbol: string;

  if (splitCurrencyString.length > 1) {
    currencyStringPrefix = splitCurrencyString[0];
    const indexOfDollarSign = selectedCurrencySymbol.indexOf(splitCurrencyString[0]) + splitCurrencyString[0].length;
    currencyStringSymbol = selectedCurrencySymbol.charAt(indexOfDollarSign);
  } else {
    currencyStringPrefix = '';
    currencyStringSymbol = selectedCurrencySymbol;
  }

  const regexString =
    '(?=.*\\d)^(' + currencyStringPrefix + ')?\\' + currencyStringSymbol + '?(([1-9]\\d*(,\\d{3})*)|0)?(\\.\\d{1,2})?$';

  const priceRegex = new RegExp(regexString);

  return ((price as string).match(priceRegex) || []).length;
};

export default {
  isNullOrUndefined,
};

export const getRegion = (region: string) => {
  return (
    {
      EMEA: 'emea',
      CAN: 'can',
      AME: 'us',
    }[region] || 'us'
  );
};

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

// Transforms the value into the selected currency
export const transformCurrency = (value: number, selectedCurrency: Currency): string => {
  const convertedValue: string = convertCurrency(value, selectedCurrency);
  return formatCurrency(+convertedValue, selectedCurrency);
};

export const formatCurrency = (value: number, selectedCurrency: Currency, minimumFractionDigits?: number) => {
  let languageCountryCode;
  switch (selectedCurrency) {
    case 'EUR':
      languageCountryCode = LanguageCountryCodes.Germany;
      break;
    case 'USD':
    case 'CAD':
    default:
      languageCountryCode = LanguageCountryCodes.USA;
      break;
  }

  const formatter = new Intl.NumberFormat(languageCountryCode, {
    style: 'currency',
    currency: selectedCurrency || 'USD',
    minimumFractionDigits,
  });

  return !isNaN(value) ? formatter.format(value) : formatter.format(0);
};

export const getCurrencySymbol = (currency: Currency) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'CAD':
      return 'CA$';
    case 'USD':
    default:
      return '$';
  }
};

export const timeBetweenDates = (date1: Date, date2: Date, timeInterval: TimeIntervals) => {
  let millisecondsPerTimeInterval;
  switch (timeInterval) {
    case TimeIntervals.Minutes:
      millisecondsPerTimeInterval = 1000 * 60;
      break;
    case TimeIntervals.Hours:
      millisecondsPerTimeInterval = 1000 * 60 * 60;
      break;
    case TimeIntervals.Days:
      millisecondsPerTimeInterval = 1000 * 60 * 60 * 24;
      break;
    case TimeIntervals.Weeks:
      millisecondsPerTimeInterval = 1000 * 60 * 60 * 24 * 7;
      break;
  }

  const date1milliseconds = date1.getTime();
  const date2milliseconds = date2.getTime();

  const millisecondsBetweenDates =
    date1milliseconds > date2milliseconds
      ? date1milliseconds - date2milliseconds
      : date2milliseconds - date1milliseconds;

  const timeDifference = millisecondsBetweenDates / millisecondsPerTimeInterval;

  return Math.floor(timeDifference);
};

export const earliestDate = (date1: Date, date2: Date) => {
  return date1.getTime() < date2.getTime() ? date1 : date2;
};

// Gets the proper status image based on the status received from salesforce API
export const getStatusImageFromAPIStatus = (status: string, darkMode: boolean) => {
  if (!status || status === '') {
    return yellowStatusIcon;
  }

  const statusIcon = {
    [NCLineItemAPIStatuses.AwaitingRawMaterials]: redStatusIcon,
    [NCLineItemAPIStatuses.RawMaterialsReceived]: purpleStatusIcon,
    [NCLineItemAPIStatuses.Bonded]: lightBlueStatusIcon,
    [NCLineItemAPIStatuses.ShippedToContractor]: magentaStatusIcon,
    [NCLineItemAPIStatuses.PartiallyShipped]: grayStatusIcon,
    [NCLineItemAPIStatuses.Shipped]: greenStatusIcon,
    [OrderAPIStatuses.BackOrder]: blueStatusIcon,
    [OrderAPIStatuses.Invoiced]: lightGreenStatusIcon,
    [OrderAPIStatuses.Canceled]: yellowStatusIcon,
    [OrderAPIStatuses.Delivered]: lightGreenStatusIcon,
    [InvoiceAPIStatuses.Due]: blueStatusIcon,
    [InvoiceAPIStatuses.PastDue]: yellowStatusIcon,
    [InvoiceAPIStatuses.Paid]: lightGreenStatusIcon,
  }[status.toUpperCase()];

  return statusIcon || (!darkMode ? grayStatusIcon : silverStatusIcon);
};

// Returns the sorting order for the specified line item status
export const getStatusSortingOrderFromAPIStatus = (status: string) => {
  if (!status || status === '') {
    return 5;
  }

  return {
    [NCLineItemAPIStatuses.AwaitingRawMaterials]: 1,
    [InvoiceAPIStatuses.PastDue]: 1,
    [NCLineItemAPIStatuses.RawMaterialsReceived]: 2,
    [InvoiceAPIStatuses.Due]: 2,
    [NCLineItemAPIStatuses.RescheduledShipDate]: 3,
    [InvoiceAPIStatuses.Paid]: 3,
    [NCLineItemAPIStatuses.Bonded]: 4,
    [NCLineItemAPIStatuses.PlanningToShipToContractor]: 5,
    [NCLineItemAPIStatuses.ShippedToContractor]: 6,
    [NCLineItemAPIStatuses.PartiallyShipped]: 7,
    [NCLineItemAPIStatuses.Shipped]: 8,
  }[status.toUpperCase()];
};

// Returns proper badge text for the item based on when it was last modified/viewed
export const getBadgeText = async (itemId: string, createdDate: Date, lastModifiedDate: Date, lastViewed?: any) => {
  let badgeText;
  const currentDate = new Date();
  const newItem = createdDate
    ? timeBetweenDates(createdDate, currentDate, TimeIntervals.Hours) <= recentHoursThreshold
    : false;
  const recentlyUpdatedItem = lastModifiedDate
    ? timeBetweenDates(lastModifiedDate, currentDate, TimeIntervals.Hours) <= recentHoursThreshold
    : false;

  if (lastViewed && (newItem || recentlyUpdatedItem)) {
    const lastViewedString = await lastViewed.getItemLastViewed(itemId);

    let lastViewedDate;
    if (lastViewedString) {
      lastViewedDate = new Date(lastViewedString);
    }

    if (newItem && (!lastViewedDate || earliestDate(lastViewedDate, createdDate) === lastViewedDate)) {
      badgeText = BadgeText.New;
    } else if (
      recentlyUpdatedItem &&
      (!lastViewedDate || earliestDate(lastViewedDate, lastModifiedDate) === lastViewedDate)
    ) {
      badgeText = BadgeText.RecentlyUpdated;
    }
  }

  return badgeText;
};

// Converts the input value to the output units
export const convertUnits = (value: number, inputUnits: Units, outputUnits: Units) => {
  if (inputUnits === Units.Inches && outputUnits === Units.Millimeters) {
    return value * 25.4;
  } else if (inputUnits === Units.Millimeters && outputUnits === Units.Inches) {
    return value / 25.4;
  } else if (inputUnits === Units.Pounds && outputUnits === Units.Kilograms) {
    return value * 0.45359237;
  } else if (inputUnits === Units.Kilograms && outputUnits === Units.Pounds) {
    return value / 0.45359237;
  } else if (inputUnits === Units.SquareFeet && outputUnits === Units.SquareMeters) {
    return value / 10.7639;
  } else if (inputUnits === Units.SquareMeters && outputUnits === Units.SquareFeet) {
    return value * 10.7639;
  } else if (inputUnits === Units.Inches && outputUnits === Units.Feet) {
    return value / 12;
  } else if (inputUnits === Units.Feet && outputUnits === Units.Inches) {
    return value * 12;
  } else if (inputUnits === Units.Millimeters && outputUnits === Units.Meters) {
    return value * 0.001;
  } else if (inputUnits === Units.Meters && outputUnits === Units.Millimeters) {
    return value / 0.001;
  } else if (inputUnits === Units.PricePerPound && outputUnits === Units.PricePerKilogram) {
    return value / 0.45359237;
  } else if (inputUnits === Units.PricePerKilogram && outputUnits === Units.PricePerPound) {
    return value * 0.45359237;
  } else if (inputUnits === Units.HoursPerMeter && outputUnits === Units.HoursPerMillimeter) {
    return value * 0.001;
  } else if (inputUnits === Units.HoursPerFoot && outputUnits === Units.HoursPerInch) {
    return value / 12;
  } else if (inputUnits === Units.Fahrenheit && outputUnits === Units.Celsius) {
    return (value - 32) * (5 / 9);
  } else if (inputUnits === Units.Celsius && outputUnits === Units.Fahrenheit) {
    return value * (9 / 5) + 32;
  } else if (inputUnits === outputUnits) {
    // if input unit and output unit is the same., just return the value
    return value;
  }
};

// Converts the string to a float with specific decimal points
export const stringToFloat = (stringToConvert: string, decimalPoints: number) => {
  return parseFloat(stringToConvert).toFixed(decimalPoints);
};

// Determines if two arrays have same values
export const arraysEqual = (array1: Array<any>, array2: Array<any>) => {
  return array1 && array2 && array1.length === array2.length && array1.every((value, index) => value === array2[index]);
};

// Floors the specified decimal value to nearest half
export const floorToHalf = (value: number) => {
  return Math.floor(value * 2) / 2;
};

// Route navigation helper
export const navigateToRoute = (
  route: PageRoutes,
  history,
  quoteId?: string,
  alertCallback?: (url: string) => void,
  lineItemId?: string
) => {
  let url = '/';

  switch (route) {
    case PageRoutes.Quotes:
      url = '/quotes';
      break;
    case PageRoutes.QuoteDetail:
      url = `/quotes/quote-detail/${quoteId}`;
      break;
    case PageRoutes.OrderEntry:
      url = `/quotes/quote-detail/${quoteId}/order-entry`;
      break;
    case PageRoutes.MSTO:
      url = `/quotes/quote-detail/${quoteId}/order-entry/msto`;
      break;
    case PageRoutes.AdvancedSpecifications:
      url = `/quotes/quote-detail/${quoteId}/new-advanced-specifications`;
      break;
    case PageRoutes.AssemblyGroups:
      url = `/quotes/quote-detail/${quoteId}/advanced-specifications/${lineItemId}/new-assembly-groups`;
      break;
  }

  if (alertCallback) {
    alertCallback(url);
  } else {
    history.push(url);
  }
};

export const removeNullValues = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeNullValues); // Process each element of the array
  } else if (typeof obj === 'object' && obj !== null) {
    const result: any = {};
    for (const key in obj) {
      const value = removeNullValues(obj[key]);
      if (value !== null) {
        result[key] = value; // Add non-null values to the result object
      }
    }
    return result;
  } else {
    return obj; // Return non-object values as is
  }
};

export const excludeFields = (quote): any => {
  quote.quoteLineItems = quote.quoteLineItems.map(item => {
      const { Base_FT_Value_in__c, Base_FT_Value_mm__c, Clad_FT_Value_in__c, Clad_FT_Value_mm__c, ...lineItem } = item;
      return lineItem;
  });

  return quote;
};


export const generateUUID = () => {
  return uuidv4();
};

export const extractId = (str: string) => {
  const regex = /\d{4,8}/g; // between 4 and 8 digit ids // 6 digt ids: /\d{6}/g;
  const match = str?.match(regex);
  return match ? match[0] : '';
};