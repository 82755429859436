
interface SimpleCacheStorageItem {
  until: number;
  value: any;
}

class SimpleCacheStorage {
  setItem(key: string, value: any, min = 0) {
    const now = (new Date()).getTime();
    const until = (min * 60000) + now;

    localStorage.setItem(key, JSON.stringify({
      until,
      value,
    }));
  }

  getItem(key: string) {
    try {
      const item: SimpleCacheStorageItem = JSON.parse(localStorage.getItem(key));
      const now = (new Date()).getTime();

      if (!item || item && item.until < now) {
        this.removeItem(key);
        return null;
      }

      return item.value;
    } catch {
      return null;
    }
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

export default new SimpleCacheStorage();