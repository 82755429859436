import { getVendors } from './vendors-api';
import * as React from 'react';
import { Vendor } from '@interfaces/vendor';

const { createContext, useContext } = React;

const VendorsContext = createContext(null);

export const VendorsProvider = (props) => {
  const value = {
    getAllVendors: props.getAllVendors || getAllVendors,
  };

  return (
    <VendorsContext.Provider value={value}>
      {props.children}
    </VendorsContext.Provider>
  );
};

export const useVendors = () => {
  return useContext(VendorsContext);
};

// Calls api to get all the vendors from cosmos
export const getAllVendors = async(): Promise<Vendor[]> => {
  try {
    return await getVendors();
  } catch (error) {
    console.error(error);
    return null;
  }
};
