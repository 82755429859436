import React, { useEffect, useRef, useState } from 'react';
import PageLayout from './page-layout';
import { useComboGroup, ComboGroupActionTypes } from '@context/combo-group-context';
import { useQuoteState } from '@context/quote-context';
import { useQuoteFormContext } from '@context/quote-form-context';
import { useParams } from 'react-router-dom';
import Loading from '@components/loading/loading';
import _ from 'lodash-es';
import { reduceFormState } from '@pages/new-advanced-specifications/advance-specification-form-util';
import { initFormState } from '@pages/new-assembly-groups/assembly-group-form-util';
import { useDataState } from '@context/data-context';

const ComboGroupLayout = (props) => {
  const [ isLinesDetailsLoading, setLinesDetailsLoading ] = useState(undefined);
  const { id } = useParams<{id: string}>();
  const {
    dispatch: comboGroupDispatch,
  } = useComboGroup();

  const {
    loadQuoteVariableConfigurations,
    loadRawMaterials,
    loadSelections,
    loadSpecificationTestOptions,
    loadFinishedGoods,
    loadVendors,
    dataState,
  } = useDataState();

  const {
    quoteLineItems: formQuoteLineItems,
    onUpdateQuoteLineItemsByArray,
    setQuoteLineItems,
  } = useQuoteFormContext();

  const {
    quoteState,
    loadQuote,
    loadQuoteLineItemDetails,
   } = useQuoteState();

  const { quote, quoteLineItemDetails } = quoteState;
  const { quoteLineItems } = quote || {};
  const {
    quoteVariableConfigurations,
    rawMaterials,
    selections,
    specificationTestOptions,
    finishedGoods,
    vendors,
  } = dataState;

  // load the quote if it doesnt already exist in state, and load the cosmos data
  useEffect(() => {
    if(!quote) {
      loadQuote(
        id,
        undefined,
        true
      );
    }

    // load raw materials
    loadRawMaterials();

    // load selections
    loadSelections();

    // load specification test options
    loadSpecificationTestOptions();

    // load vendors
    loadVendors();

    // load finished goods
    loadFinishedGoods();

    // load quote variable configurations
    loadQuoteVariableConfigurations();

    return () => {
      // reset fqli
      setQuoteLineItems((previousQli) => {
        return [];
      });
    };
  }, []);

  useEffect(() => {
    if(quoteLineItems && !quoteLineItemDetails) {
      loadQuoteLineItemDetails(setLinesDetailsLoading);
    }
  }, [quoteLineItems, quoteLineItemDetails]);

  // init combo group
  useEffect(() => {
    if (
      quoteLineItems
      && quoteLineItems.length > 0
      && quoteLineItemDetails
      && quoteLineItemDetails.length > 0
    ) {
      debounceInitComboGroup({quote, quoteLineItems});
    }

    return () => {
      debounceInitComboGroup.cancel();
    };
  }, [quoteLineItems, quoteLineItemDetails]);

  const initComboGroup = (payload) => {
    comboGroupDispatch({
      type: ComboGroupActionTypes.initComboGroup,
      payload,
    });
  };

  const debounceInitComboGroup = useRef(
    _.debounce(initComboGroup, 200)
  ).current;
  // end

  // need to check if quote line item details is already set
  // but for some reason the state is being change multiple times
  // somewhere in quote-context, to make it only run once, used _.debounce
  useEffect(() => {
    if (quoteLineItemDetails && quoteLineItemDetails.length > 0) {
      debouceInitFormQuoteLineItem(quoteLineItems, formQuoteLineItems);
    }

    return () => {
      debouceInitFormQuoteLineItem.cancel();
    };
  }, [quoteLineItems, quoteLineItemDetails]);

  const initFormQuoteLineItem = (qlis, fqlis) => {
    const payloads = qlis.map((qli) => {
      const fqli = fqlis.find((fqli) => {
        const qliId = qli.Id || qli.displayId;
        const fqliId = fqli.Id || fqli.displayId;

        return fqliId === qliId;
      });

      return {
        ...reduceFormState(initFormState(fqli), initFormState(qli)),
        Id: qli.Id,
        displayId: qli.displayId,
        Line__c: qli.Line__c,
        Parent_Line__c: qli.Parent_Line__c,
        CurrencyIsoCode: qli.CurrencyIsoCode,
      };
    });

    onUpdateQuoteLineItemsByArray(payloads);
  };

  const debouceInitFormQuoteLineItem = useRef(
    _.debounce(initFormQuoteLineItem, 200)
  ).current;
  // end init form quote line item

  return <PageLayout {...props}>
    {
      quoteVariableConfigurations?.length
      && !isLinesDetailsLoading
      && quoteLineItems?.length
      && rawMaterials?.length
      && selections?.length
      && specificationTestOptions?.length
      && finishedGoods?.length
      && formQuoteLineItems?.length
      && vendors?.length
      && !props.loading
        ? props.children
        : <Loading className='padding-top'/>
    }
  </PageLayout>;
};

export default ComboGroupLayout;