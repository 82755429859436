import React from 'react';
import { IonGrid, IonRow, IonCol } from "@ionic/react";

export interface AppLineItemComponent {
  size?: string | number;
  key?: string | number;
  className?: string;
  style?: object;
  component: React.FC | JSX.Element;
}

interface LineItemColumnsProps {
  components: AppLineItemComponent | AppLineItemComponent[];
  className?: string;
  style?: any,
}

const AppLineItemColumns = ({components, className, style}: LineItemColumnsProps) => {
  const col = !Array.isArray(components)
    ? [components]
    : (components || []);

  const defaultSize = (Math.floor(12 / col.length));

  return <IonGrid style={{ padding: 0, ...style }} className='app-line-item-columns'>
    <IonRow className={className}>
      {col.map((component, index) =>
        (<IonCol
          key={component.key || `app-line-item-${index}`}
          sizeSm="12"
          sizeMd={(component.size || defaultSize).toString()}
          style={component.style}
          className={component.className}
        >
          {component.component}
        </IonCol>)
      )}
    </IonRow>
  </IonGrid>
}

export default AppLineItemColumns