import * as React from 'react';
import { useEffect, useState } from 'react';

import './metal-combo-scroller.scss';
import { IonButton, IonIcon, IonImg, IonLabel } from '@ionic/react';
import backArrow from '@assets/icon/arrow_back.svg';
import forwardArrow from '@assets/icon/arrow_forward.svg';
import lineIcon from '@assets/icon/remove-outline.svg';
import { QuoteLineItem } from '@interfaces/quote-line-item';

import { getKeysIndex } from './metal-combo-scroller-utils';
import { DisplayedQuoteGroup } from '@shared/quote-group-utils';
import { MetalComboScrollerStyles } from '@constants';
import classNames from 'classnames';

type Props = {
  displayedQuoteGroup: DisplayedQuoteGroup;
  setLineItemId?: React.Dispatch<React.SetStateAction<string>>;
  style: MetalComboScrollerStyles,
  hideArrows?: boolean
};

// MetalScroller is a component that displays a scrollable list of metal combinations.
// from a DisplayedQuoteGroup, made from given quote line items, quote line item details, and a line item id.
// This component sets the parent component's quote line item / quote line item detail displayed,
// defaulting the displays to be first index in the provided arrays.
export const MetalComboScroller = ({ displayedQuoteGroup, setLineItemId, style, hideArrows }: Props): JSX.Element => {
  const [baseMetalShortName, setBaseMetalShortName] = useState<string>(displayedQuoteGroup.baseMetalShortName);
  const [cladMetalShortName, setCladMetalShortName] = useState<string>(displayedQuoteGroup.cladMetalShortName);
  // this is a json string key used to identify the metal combo that is being displayed on the screen
  // it uses the base and clad metal short names for the key
  const [jsonMetalCombo, setJSONMetalCombo] = React.useState(
    JSON.stringify({ base: baseMetalShortName, clad: cladMetalShortName })
  );

  // this is an array of json metal combo keys mapped to the quote line items that have that metal combo
  // this is used as a displayable list of metal combos by quote line item metal short names
  const metalComboByJsonKeyOptions = Array.from(displayedQuoteGroup.metalComboToQlisMap.keys());
  // this is the index of the metal combo that is currently displayed on the screen
  const [mapIndex, setMapIndex] = useState<number>(
    getKeysIndex(metalComboByJsonKeyOptions, displayedQuoteGroup.metalComboKey)
  );

  // when the jsonMetalCombo (key) changes from a scroll,
  // set the base and clad short names displayed
  // and set the quote line item and quote line item detail displayed via line item id / display id
  useEffect(() => {
    const { base, clad }: { base: string; clad: string } = JSON.parse(jsonMetalCombo);
    setBaseMetalShortName(base);
    setCladMetalShortName(clad);

    // set the new displayed line item id, from the first index in metal group...
    const qli: QuoteLineItem = displayedQuoteGroup.metalComboToQlisMap.get(jsonMetalCombo)[0];
    // re-rendering allows this to work as opposed to setting the setQuoteLineItem/DetailDisplayed with:
    // a newly set QuoteGroup.setDisplayedQuoteLines(qli.Id ? qli.Id : qli.displayId), that is done in the parent component upon this change as an effect
    if(setLineItemId) {
      setLineItemId(qli.Id ? qli.Id : qli.displayId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonMetalCombo, displayedQuoteGroup]);

  // when the map index changes from a scroll, set the metal combo displayed
  useEffect(() => setJSONMetalCombo(metalComboByJsonKeyOptions[mapIndex]), [mapIndex, metalComboByJsonKeyOptions]);

  // navigates to previous metal combination
  const goToPreviousCombo = () => setMapIndex(prev => prev - 1);
  // navigates to next metal combination
  const goToNextCombo = () => setMapIndex(prev => prev + 1);

  return (
    <>
      <div className='metals-scroller-parent-container'>
        <div className='metals-scroller-pagination-container'>
          <IonLabel class={classNames({'pagination-labels': true, 'column-style': style === MetalComboScrollerStyles.Vertical})}>
            <span className={classNames({'label-left': true, 'column-style': style === MetalComboScrollerStyles.Vertical, 'no-arrows': hideArrows})}>{style === MetalComboScrollerStyles.Horizontal ? 'Clad Metal' : 'Metal Combination'}</span>
            {style === MetalComboScrollerStyles.Horizontal && <span className={classNames({'label-right': true, 'no-arrows': hideArrows})}>Base Metal</span>}
          </IonLabel>
          <div className={classNames({'pagination-buttons-container': true, 'column-style': style === MetalComboScrollerStyles.Vertical})}>
            {!hideArrows &&
            <IonButton
              disabled={mapIndex === 0}
              class={classNames({'change-combo-button': true, 'column-style': style === MetalComboScrollerStyles.Vertical})}
              color='secondary'
              onClick={() => goToPreviousCombo()}
              data-testid='QAAdvancedSpecPagePreviousComboButton'
            >
              <IonIcon class='prev-icon' src={backArrow} />
            </IonButton>}
            <div className={classNames({'metals-container': true, 'column-style': style === MetalComboScrollerStyles.Vertical})}>
              <div className={classNames({'clad-metal-container': true, 'column-style': style === MetalComboScrollerStyles.Vertical})}>
                <IonLabel class='clad-metal'>{cladMetalShortName}</IonLabel>
              </div>
              {style === MetalComboScrollerStyles.Horizontal && <IonImg src={lineIcon} class='line-icon' />}
              <div className={classNames({'base-metal-container': true, 'column-style': style === MetalComboScrollerStyles.Vertical})}>
                <IonLabel class='base-metal'>{baseMetalShortName}</IonLabel>
              </div>
            </div>
            {!hideArrows &&
            <IonButton
              disabled={mapIndex === metalComboByJsonKeyOptions.length - 1}
              class={classNames({'change-combo-button': true, 'column-style': style === MetalComboScrollerStyles.Vertical})}
              color='secondary'
              onClick={() => goToNextCombo()}
              data-testid='QAAdvancedSpecPageNextComboButton'
            >
              <IonIcon class='next-icon' src={forwardArrow} />
            </IonButton>}
          </div>
        </div>
      </div>
    </>
  );
};
