import React, { useEffect, useState } from 'react';

import { IonCol, IonRow } from '@ionic/react';
import UOMToggle from '@components/uom-toggle/uom-toggle';
import AppToggle from '@components/app-toggle/app-toggle';
import { MeasurementSystems } from '@constants';
import { useComboGroup, ComboGroupActionTypes } from '@context/combo-group-context';

import Segments from '../segments/segments';

import './page-sub-header.scss';
import { SpecifyPerAssemblyMapper } from '../advance-specification-form-util';

const SpecifyPerAssembly = () => {
  const {
    state: { enableSpecifyPerAssembly, selectedKey },
    dispatch: comboGroupDispatch
  } = useComboGroup();
  const value = enableSpecifyPerAssembly[selectedKey];

  const onChange = (value) => {
    if (typeof (value) === 'undefined') {
      return;
    }

    comboGroupDispatch({
      type: ComboGroupActionTypes.updateEnableSpecifyPerAssembly,
      payload: value,
    });

    // if it set false then it should remove the specify per assembly
    // im not sure if this is necessary
    if (!value) {

      // set everything to false
      // dont leave it empty as it will try to
      // fill it up again with the quote line item details
      comboGroupDispatch({
        type: ComboGroupActionTypes.setSpecifyPerAssemblyBySelectedKey,
        payload: Object.keys(SpecifyPerAssemblyMapper).reduce((prev, cur) => {
          prev[cur] = false;
          return prev;
        }, {}),
      });
    }
  }

  return <AppToggle
    value={value}
    onChange={(e) => onChange(e)}
    label="Specify Per Assembly"
  />
}

const PageSubHeader = () => {
  return (
    <>
      <IonRow className="page-sub-header">
        <IonCol sizeSm='12' sizeMd='7' className='segment--container'>
          <Segments/>
        </IonCol>
        <IonCol sizeSm='12' sizeMd='5' className='page-sub-header-right-container'>
          <IonRow>
            <IonCol size="8">
              <SpecifyPerAssembly/>
            </IonCol>
            <IonCol size="4" className='page-sub-header-right-container--uom-toggle'>
              <UOMToggle/>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  );
};

export default PageSubHeader;