import { getFreight } from './freight-api';
import * as React from 'react';
import { Freight } from '@interfaces/freight';

const { createContext, useContext } = React;

const FreightContext = createContext(null);

export const FreightProvider = (props) => {
  const value = {
    getAllFreight: props.getAllFreight || getAllFreight,
  };

  return (
    <FreightContext.Provider value={value}>
      {props.children}
    </FreightContext.Provider>
  );
};

export const useFreight = () => {
  return useContext(FreightContext);
};

// Calls api to get all the freight from cosmos
export const getAllFreight = async(): Promise<Freight[]> => {
  try {
    return await getFreight();
  } catch (error) {
    console.error(error);
    return null;
  }
};
