import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { ToastMessages, ToastTypes } from '@constants';
import { useDarkMode } from '@hooks/useDarkMode';
import { useToast } from '@hooks/useToast';
import { IonButton, IonImg, IonLabel, IonTextarea } from '@ionic/react';
import * as React from 'react';
import './head-forming-po-notes-popover.scss';

const HeadFormingPONotesPopover = (props: {
  poNotes: string,
  setShowPopover: (arg0: { showPopover: boolean; event: undefined }) => void;
}) => {
  const toast = useToast(4000);
  
  const { darkMode } = useDarkMode();
  const [headFormingPONotes, setHeadFormingPONotes] = React.useState(props.poNotes.replace(/<br\/>/g, '\n'));

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  }

  const copyText = () => {
    navigator.clipboard.writeText(headFormingPONotes);
    toast(ToastTypes.Success, ToastMessages.PONotesCopiedToClipboard);
  }

  const CopyCancelButtons = () => (
    <>
      <IonButton
        color='primary'
        class='copy-po-button'
        data-testid={'QAHeadFormingPONotesPopoverCopyButton'}
        onClick={() => {
          copyText();
        }}
      >
        Copy
      </IonButton>
      <IonButton color='secondary' class='cancel-button' onClick={() => dismissModal()}>
        Cancel
      </IonButton>
    </>
  );

  return (
    <div className='popover head-forming-po-notes'>
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class='close-icon' onClick={() => dismissModal()} />
      <div className='title-container'>
        <IonLabel class='title' data-testid={'QAHeadFormingPONotesPopoverHeader'}>
          PO Notes
        </IonLabel>
      </div>
      <hr />
      <div className='text-area-container'>
        <IonTextarea
          rows={20}
          onIonChange={(e) => {
            setHeadFormingPONotes((e.target as HTMLInputElement).value);
          }}
          value={headFormingPONotes}
        />
      </div>
      <hr />
      <div className='copy-cancel-button-container'>
        <CopyCancelButtons />
      </div>
    </div>
  );
};

export default HeadFormingPONotesPopover;