import * as React from 'react';
import './loading.scss';
import ncSymbol from '@assets/images/symbol-nc.png';

interface LoadingProps {
  className?: string;
  message?: string;
}
interface MessageProps {
  message?: string;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  return message ? <h4 data-testid='QALoadingMessage'>{message}</h4> : null;
};

const Loading: React.FC<LoadingProps> = ({ className, message = 'Loading...' }) => {
  return (
    <div className={`loading animate ${className}`} data-testid='QALoadingSpinner'>
      <img className='logo' src={ncSymbol} alt='logo' />
      <Message message={message} />
    </div>
  );
};
export default Loading;