import * as React from 'react';
import { useDarkMode } from '@hooks/useDarkMode';
import { IonButton, IonCard, IonCardHeader, IonCheckbox, IonCol, IonGrid, IonIcon, IonImg, IonLabel, IonRow } from '@ionic/react';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { CutMethods, FieldTypes, MeasurementSystems, PlateMethods, QuoteLineItemFieldUnitTypes, QuoteLineItemsColumnHeadings, QuoteLineItemValueTypes, QuoteLineTypes, QuoteVariableConfigDescriptions, RawMaterialUsage, Regexes, SelectionTypes, Shapes, ToastMessages, ToastTypes, Units } from '@constants';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import './manual-sizing-popover.scss';
import LineItemField from '@components/line-item-field/line-item-field';
import SelectionContainer from '@components/selection-container/selection-container';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
import { addCircle, chevronDown, chevronUp, closeCircle } from 'ionicons/icons';
import { getQuoteLineItemDetailFromQuoteLineItem, calculateUOMValue, getQuoteLineItemFromId, quoteLineItemExistsInArray, quoteLineItemDetailExistsInArray, calculateEdgeAllowance, calculateKerfAllowance, calculateBoosterAllowance, getSpecificationAdderBySpecification, getSpecificationAdderByPostCladTestingOptions, getQuoteVariableConfiguration, calculateTotalWeightPerPiece, getBoosterAllowanceGroup, getAllQuoteLineItemDetails } from '@shared/quote-utils';
import classNames from 'classnames';
import { RawMaterial } from '@interfaces/raw-material';
import { convertUnits } from '@shared/utils';
import { useToast } from '@hooks/useToast';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';
import { LocalUomDisplayToggle } from '@components/uom-toggle/uom-toggle';
import { useLocalUom } from '@hooks/useLocalUom';

const ManualSizingPopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
      showPopover: boolean;
      event: any;
  }>>,
}) => {
  const { dataState } = useDataState();
  const { rawMaterials, edgeAllowances, boosterAllowances, specificationTestOptions, quoteVariableConfigurations } = dataState;

  const { localUom, setLocalUom, isDisplayMetric } = useLocalUom();

  const { quoteState, saveQuoteLineItem } = useQuoteState();
  const { quote } = quoteState;
  const { quoteLineItems, Manufacturing_Site__c: manufacturingSite } = quote;

  const { darkMode } = useDarkMode();
  const toast = useToast(4000);
  const [availableQuoteLineItems] = React.useState(quoteLineItems.filter(quoteLineItem => !quoteLineItem.DNC__c));
  const [updatedQuoteLineItems, setUpdatedQuoteLineItems] = React.useState(availableQuoteLineItems);
  const [finishedGoods, setFinishedGoods] = React.useState([]);
  const [manuallySizedAssembliesLines, setManuallySizedAssembliesLines] = React.useState(availableQuoteLineItems.filter(qli => !qli.SharedCladdedPlate__c));
  const [manuallySizedAssembliesRowsToCombine, setManuallySizedAssembliesRowsToCombine] = React.useState([]);
  const [finishedGoodsRowsToSplit, setFinishedGoodsRowsToSplit] = React.useState([]);

  const [assemblyQuantityArray, setAssemblyQuantityArray] = React.useState([]);
  const [selection, setSelection] = React.useState(undefined);
  const [pieceQuantity, setPieceQuantity] = React.useState(undefined);
  const [finishedGoodsForAssembly, setFinishedGoodsForAssembly] = React.useState([]);
  const [createDisabled, setCreateDisabled] = React.useState(true);
  const [addLineDisabled, setAddLineDisabled] = React.useState(true);
  const [combinedLines, setCombinedLines] = React.useState(availableQuoteLineItems.filter(qli => qli.PeggedSupply__c).map(masterPlate => {
    return [masterPlate, ...availableQuoteLineItems.filter(qli => qli.Parent_Line__c === masterPlate.Line__c)];
  }));

  const [manualSizingSpecs] = React.useState({
    nominalCladTKPerClad: undefined,
    nominalBaseTKPerClad: undefined,
    widthPerClad: undefined,
    lengthPerClad: undefined,
    identicalAssemblyQuantity: 1,
    addEdgeAllowance: true,
    addBoosterAllowance: true,
    addTestSpecimenAllowance: true,
    finishedGoodsLine: undefined,
    pieces: undefined,
  });

  const [localQuoteLineItemDetails, setLocalQuoteLineItemDetails] = React.useState([...getAllQuoteLineItemDetails(quoteLineItems)]);
  const [displayAssemblyLineArray, setDisplayAssemblyLineArray] = React.useState([]);
  const [finishedGoodsForAssemblyLineArray, setFinishedGoodsForAssemblyLineArray] = React.useState([]);
  const [completedAssemblies, setCompletedAssemblies] = React.useState(availableQuoteLineItems.filter(qli => qli.PeggedSupply__c).map(qli => getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, qli)));
  const [masterPlateLine, setMasterPlateLine] = React.useState<QuoteLineItem>(undefined);
  const [baseMetal, setBaseMetal] = React.useState<RawMaterial>(undefined);
  const [cladMetal, setCladMetal] = React.useState<RawMaterial>(undefined);
  const [calculatedImperialEdgeAllowance, setCalculatedImperialEdgeAllowance] = React.useState(0);
  const [calculatedMetricEdgeAllowance, setCalculatedMetricEdgeAllowance] = React.useState(0);
  const [imperialKerfAllowance, setImperialKerfAllowance] = React.useState(0);
  const [metricKerfAllowance, setMetricKerfAllowance] = React.useState(0);
  const [calculatedImperialBoosterAllowance, setCalculatedImperialBoosterAllowance] = React.useState(0);
  const [calculatedMetricBoosterAllowance, setCalculatedMetricBoosterAllowance] = React.useState(0);
  const [calculatedImperialTestingAllowance, setCalculatedImperialTestingAllowance] = React.useState(0);
  const [calculatedMetricTestingAllowance, setCalculatedMetricTestingAllowance] = React.useState(0);
  const [expandedMap] = React.useState<Map<QuoteLineItem, boolean>>(new Map());

  const originalQuoteLineItemDetails = [...getAllQuoteLineItemDetails(quoteLineItems)];

  React.useEffect(() => {
    try {
      if(finishedGoodsForAssembly.length) {
        const newMasterPlateLine = finishedGoods.find(good => good.Line__c === finishedGoodsForAssembly[0].line);
        setMasterPlateLine(newMasterPlateLine);
        setBaseMetal(rawMaterials.find(rawMaterial => rawMaterial.name === newMasterPlateLine?.BaseMetal__c && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite)));
        setCladMetal(rawMaterials.find(rawMaterial => rawMaterial.name === newMasterPlateLine?.CladMetal__c && rawMaterial.usage === RawMaterialUsage.Clad && rawMaterial.dataAreaId.includes(manufacturingSite)));
      } else {
        setMasterPlateLine(undefined);
        setBaseMetal(undefined);
        setCladMetal(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  }, [finishedGoodsForAssembly]);

  const clearSelectedGoods = () => {
    manualSizingSpecs.finishedGoodsLine = undefined;
    setFinishedGoodsForAssembly([]);
  };

  const clearQuantityArray = () => {
    manualSizingSpecs.pieces = undefined;
    setAssemblyQuantityArray([]);
  };

  const findQuantityArray = (selectedLine) => {
    const lineItem = finishedGoods.find(lineItem => lineItem.Line__c === selectedLine);

    const quantity = finishedGoodsForAssembly.reduce(
      (quantity, curr) =>
        curr.line === lineItem.Line__c ? quantity - curr.quantity : quantity,
      lineItem.Quantity__c
    );

    const newArray = Array.from({length: quantity}, (_, i) => i + 1);
    setAssemblyQuantityArray(newArray);
  };

  const setLineItem = (finishedGoodsLine) => {
    manualSizingSpecs.finishedGoodsLine = finishedGoodsLine;
    const selection = finishedGoodsLine;
    setSelection(selection);
    findQuantityArray(selection);
  };

  const addLine = () => {
    const lineToAdd: QuoteLineItem = finishedGoods.find(good => good.Line__c === selection);

    if(baseMetal && cladMetal && (lineToAdd.BaseMetal__c !== baseMetal.name || lineToAdd.CladMetal__c !== cladMetal.name)) {
      toast(ToastTypes.Error, ToastMessages.ManualSizingMultipleMetalCombos);
    } else {
      if (createDisabled) {
        setCreateDisabled(false);
      }
      const currentAssemblyLineArray = [...displayAssemblyLineArray];
      const currentFinishedGoods = [...finishedGoodsForAssembly, {line: selection, quantity: pieceQuantity}];

      setFinishedGoodsForAssembly(currentFinishedGoods);
      currentAssemblyLineArray.splice(currentAssemblyLineArray.indexOf(selection), 1);
      setDisplayAssemblyLineArray(currentAssemblyLineArray);

      //checks quantity before removing from array
      const chosenQuantity = finishedGoodsForAssembly.reduce(
        (quantity, curr) =>
          curr.line === lineToAdd.Line__c ? quantity + curr.quantity : quantity,
        0
      );

      if (lineToAdd.Quantity__c === pieceQuantity || lineToAdd.Quantity__c <= (chosenQuantity + pieceQuantity) ) {
      setFinishedGoodsForAssemblyLineArray(finishedGoodsForAssemblyLineArray.filter(lineId => lineId !== selection));
      }

      setPieceQuantity(' ');
      setSelection(' ');
      setAddLineDisabled(true);
    }
  };

  const removeLine = (item) => {
    const selectedFinishedGoods = [...finishedGoodsForAssembly];
    const currentLineArray = [...finishedGoodsForAssemblyLineArray];
    const itemIndex = selectedFinishedGoods.indexOf(item);

    selectedFinishedGoods.splice(itemIndex, 1);

    if (!currentLineArray.includes(item.line)) {
      currentLineArray.push(item.line);
    }

    setFinishedGoodsForAssemblyLineArray(currentLineArray);
    setFinishedGoodsForAssembly(selectedFinishedGoods);

    if (selectedFinishedGoods.length === 0) {
      setCreateDisabled(true);
    }
  };

  const setPieces = (pieces) => {
    setAddLineDisabled(false);
    manualSizingSpecs.pieces = +pieces;
    setPieceQuantity(pieces);
  };

  const splitCombinedLines = item => {
    const splitComboArray = [...manuallySizedAssembliesRowsToCombine];
    combinedLines.forEach(combination => {
      if (combination[0].Id ? combination[0].Id === item.Id : combination[0].displayId === item.displayId) {
        combination.forEach(lineItem =>
          splitComboArray.push(getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, lineItem))
        );
      }
    });
    return splitComboArray;
  };

  const overwriteLineItems = (finishedGoodsToCombine) => {
    if(finishedGoodsToCombine.length === 1) {
      finishedGoodsToCombine[0].PeggedSupply__c = 1;
      finishedGoodsToCombine[0].SharedCladdedPlate__c = false;
      finishedGoodsToCombine[0].Item__c = 0;
      finishedGoodsToCombine[0].Parent_Line__c = null;
      finishedGoodsToCombine[0].Show_Detail__c = true;
      finishedGoodsToCombine[0].PlateMethod__c = PlateMethods.ManuallySized;
    } else {
      finishedGoodsToCombine.forEach((finishedGoodToCombine, index) => {
        if(index === 0) {
          finishedGoodToCombine.PeggedSupply__c = 1;
          finishedGoodToCombine.SharedCladdedPlate__c = false;
          finishedGoodToCombine.Item__c = 0;
          finishedGoodToCombine.Parent_Line__c = null;
        } else {
          finishedGoodToCombine.PeggedSupply__c = 0;
          finishedGoodToCombine.SharedCladdedPlate__c = true;
          finishedGoodToCombine.Item__c = 1;
          finishedGoodToCombine.Parent_Line__c = finishedGoodsToCombine[0].Line__c;
        }

        finishedGoodToCombine.Show_Detail__c = false;
        finishedGoodToCombine.PlateMethod__c = PlateMethods.ManuallySized;
      });

      finishedGoodsToCombine[finishedGoodsToCombine.length - 1].Show_Detail__c = true;
    }
    return finishedGoodsToCombine;
  };



  const createManualSizedAssembly = () => {
    setCreateDisabled(true);

    const newFinishedGoods = [...finishedGoods];
    const finishedGoodsToCombine = [];
    const finishedGoodsForQuantityCalc = [];

    finishedGoodsForAssembly.forEach(item => {
      finishedGoodsToCombine.push({ ...newFinishedGoods.find(good => good.Line__c === item.line) });
      finishedGoodsForQuantityCalc.push({ ...newFinishedGoods.find(good => good.Line__c === item.line) });
    });

    //overwrite lines
    overwriteLineItems(finishedGoodsToCombine);
    overwriteLineItems(finishedGoodsForQuantityCalc);

   //setting line items for detail display
    const currentUpdatedQuoteLineItems = [...updatedQuoteLineItems];
     currentUpdatedQuoteLineItems.forEach(lineItem => {
      finishedGoodsToCombine.forEach(updatedLineItem => {
        if (lineItem.Line__c === updatedLineItem.Line__c) {
          updatedLineItem.Quantity__c = finishedGoodsForAssembly.find(item => item.line === updatedLineItem.Line__c).quantity;
          currentUpdatedQuoteLineItems.splice(currentUpdatedQuoteLineItems.indexOf(lineItem), 1, updatedLineItem);
        }
      });
    });

    setUpdatedQuoteLineItems(currentUpdatedQuoteLineItems);

    const originalLines = [...combinedLines];
    setCombinedLines([...originalLines, finishedGoodsToCombine]);

    const newImperialEdgeAllowance = manualSizingSpecs.addEdgeAllowance ? calculatedImperialEdgeAllowance : 0;
    const newMetricEdgeAllowance = manualSizingSpecs.addEdgeAllowance ? calculatedMetricEdgeAllowance : 0;
    const newImperialBoosterAllowance = manualSizingSpecs.addBoosterAllowance ? calculatedImperialBoosterAllowance : 0;
    const newMetricBoosterAllowance = manualSizingSpecs.addBoosterAllowance ? calculatedMetricBoosterAllowance : 0;
    const newImperialTestingAllowance = manualSizingSpecs.addTestSpecimenAllowance ? calculatedImperialTestingAllowance : 0;
    const newMetricTestingAllowance = manualSizingSpecs.addTestSpecimenAllowance ? calculatedMetricTestingAllowance : 0;

    // Tentative wide/long functionality for now, will fix later if needed
    let masterPlateQuantity = 0;
    let masterPlateWide = 0;
    let masterPlateLong = 0;
    let totalWidthSoFar = 0;

    const totalWidthWithAdders = isDisplayMetric ?
      (manualSizingSpecs.widthPerClad + (newImperialEdgeAllowance * 2) + newImperialBoosterAllowance) :
      (manualSizingSpecs.widthPerClad + (newMetricEdgeAllowance * 2) + newMetricBoosterAllowance);

    finishedGoodsForAssembly.forEach(obj => {
      masterPlateQuantity += obj.quantity;

      const finishedGoodLine = finishedGoodsToCombine.find(finishedGoodLine => finishedGoodLine.Line__c === obj.line);
      const width = isDisplayMetric ? finishedGoodLine.Width_mm__c : finishedGoodLine.Width_in__c;

      let quantityToTest = 1;
      while((totalWidthSoFar + width) <= totalWidthWithAdders && quantityToTest <= obj.quantity) {
        totalWidthSoFar += width;
        masterPlateWide++;
        quantityToTest++;
      }
    });

    if(masterPlateQuantity % masterPlateWide === 0) {
      masterPlateLong = masterPlateQuantity / masterPlateWide;
    } else {
      masterPlateWide = 1;
      masterPlateLong = masterPlateQuantity;
    }

    setFinishedGoods([...newFinishedGoods.filter(quoteLineItem => { return !quoteLineItemExistsInArray(finishedGoodsToCombine, quoteLineItem); })]);

    const masterPlateLine = finishedGoodsToCombine.filter(item => +item.Line__c == Math.min(...finishedGoodsForAssembly.map(item => item.line)))[0];

    let masterPlateDetail = getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, masterPlateLine);

    const updatedQuoteLineItemDetails = [...localQuoteLineItemDetails];

    //overwrite details
    finishedGoodsToCombine.forEach(finishedGoodToCombine => {
      const plateDetail = getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, finishedGoodToCombine);
      let updatedPlateDetail = { ...plateDetail };

      const indexToReplace = localQuoteLineItemDetails.indexOf(plateDetail);

      updatedPlateDetail = {
        ...updatedPlateDetail,
        Clads__c: manualSizingSpecs.identicalAssemblyQuantity,
        Quantity__c: masterPlateQuantity,
        Wide__c: masterPlateWide,
        Long__c: masterPlateLong,
        Clad_Width_in__c: calculateUOMValue(localUom, MeasurementSystems.Imperial, (manualSizingSpecs.widthPerClad + (newImperialEdgeAllowance * 2) + newImperialBoosterAllowance), Units.Millimeters, Units.Inches),
        Clad_Width_mm__c: calculateUOMValue(localUom, MeasurementSystems.Metric, (manualSizingSpecs.widthPerClad + (newMetricEdgeAllowance * 2) + newMetricBoosterAllowance), Units.Millimeters, Units.Inches),
        Clad_Length_in__c: calculateUOMValue(localUom, MeasurementSystems.Imperial, (manualSizingSpecs.lengthPerClad + (newImperialEdgeAllowance * 2) + newImperialTestingAllowance), Units.Millimeters, Units.Inches),
        Clad_Length_mm__c: calculateUOMValue(localUom, MeasurementSystems.Metric, (manualSizingSpecs.lengthPerClad + (newMetricEdgeAllowance * 2) + newMetricTestingAllowance), Units.Millimeters, Units.Inches),
        Clad_TK_in__c: calculateUOMValue(localUom, MeasurementSystems.Imperial, (manualSizingSpecs.nominalCladTKPerClad), Units.Millimeters, Units.Inches),
        Clad_TK_mm__c: calculateUOMValue(localUom, MeasurementSystems.Metric, (manualSizingSpecs.nominalCladTKPerClad), Units.Millimeters, Units.Inches),
        Base_Width_in__c: calculateUOMValue(localUom, MeasurementSystems.Imperial, (manualSizingSpecs.widthPerClad + (newImperialEdgeAllowance * 2) + newImperialBoosterAllowance), Units.Millimeters, Units.Inches),
        Base_Width_mm__c: calculateUOMValue(localUom, MeasurementSystems.Metric, (manualSizingSpecs.widthPerClad + (newMetricEdgeAllowance * 2) + newMetricBoosterAllowance), Units.Millimeters, Units.Inches),
        Base_Length_in__c: calculateUOMValue(localUom, MeasurementSystems.Imperial, (manualSizingSpecs.lengthPerClad + (newImperialEdgeAllowance * 2) + newImperialTestingAllowance), Units.Millimeters, Units.Inches),
        Base_Length_mm__c: calculateUOMValue(localUom, MeasurementSystems.Metric, (manualSizingSpecs.lengthPerClad + (newMetricEdgeAllowance * 2) + newMetricTestingAllowance), Units.Millimeters, Units.Inches),
        Base_TK_in__c: calculateUOMValue(localUom, MeasurementSystems.Imperial, (manualSizingSpecs.nominalBaseTKPerClad), Units.Millimeters, Units.Inches),
        Base_TK_mm__c: calculateUOMValue(localUom, MeasurementSystems.Metric, (manualSizingSpecs.nominalBaseTKPerClad), Units.Millimeters, Units.Inches),
        Kerf_Allowance_IN__c: imperialKerfAllowance,
        Kerf_Allowance_MM__c: metricKerfAllowance,
      };

      updatedQuoteLineItemDetails[indexToReplace] = updatedPlateDetail;
    });

    setLocalQuoteLineItemDetails(updatedQuoteLineItemDetails);

    //save new line item to manual assemblies
    const currentAssemblies = [...manuallySizedAssembliesLines];
    currentAssemblies.push(masterPlateLine);
    setManuallySizedAssembliesLines(currentAssemblies);

    masterPlateDetail = getQuoteLineItemDetailFromQuoteLineItem(updatedQuoteLineItemDetails, masterPlateLine);

    //sets completed assemblies for line color change
    const currentCompletedAssemblies = [...completedAssemblies];
    currentCompletedAssemblies.push(masterPlateDetail);
    setCompletedAssemblies(currentCompletedAssemblies);

    const updatedFinishedGoods = [...newFinishedGoods.filter(quoteLineItem => { return !quoteLineItemExistsInArray(finishedGoodsToCombine, quoteLineItem); })];

    //logic for leftover quanitites in line items
    finishedGoodsForQuantityCalc.forEach(line => {
      finishedGoodsForAssembly.forEach(assembly => {
        if (line.Line__c === assembly.line && line.Quantity__c !== assembly.quantity * manualSizingSpecs.identicalAssemblyQuantity) {
          line.Quantity__c = line.Quantity__c - assembly.quantity * manualSizingSpecs.identicalAssemblyQuantity;
          updatedFinishedGoods.push(line);
        }
      });
    });

    setFinishedGoods(updatedFinishedGoods);

    //clears selections from Finished Goods For Assembly
    clearSelectedGoods();
    clearQuantityArray();
  };

  // dismissModal - hides assembly groups modal
  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const handleAddAssembliesToCombine = () => {
    //update finished goods array
    const finishedGoodsArray = [
      ...finishedGoods,
      ...availableQuoteLineItems.filter(quoteLineItem => {
        return quoteLineItemDetailExistsInArray(manuallySizedAssembliesRowsToCombine, getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, quoteLineItem));
      }),
    ];
    setFinishedGoods(finishedGoodsArray);

    //begin checks for already completed assemblies
    const linesToSplit = [];

    combinedLines.forEach(combo =>
      finishedGoodsArray.forEach(line => {
        if (quoteLineItemExistsInArray(combo, line)) {
          linesToSplit.push({ ...line });
        }
      }));

    //handling splitting up previously combined assemblies
    if (linesToSplit.length > 0) {
      const multipleIndexesOfDetailToChange = [];
      const combinedLineDetails = [];
      const originalDetails = [];
      const combinedLinesToRemove = [];

      const currentQuoteLineItemDetails = [...localQuoteLineItemDetails];

      //retrieving out the combined items that are back in finished goods
      combinedLines.forEach(combo =>
        finishedGoodsArray.forEach(finishedGood => {
          if (combo[0].Id ? combo[0].Id === finishedGood.Id : combo[0].displayId === finishedGood.displayId) {
            combinedLinesToRemove.push(combo);
          }
        }));

      //getting current line item details and clearing them from completedAssemblies
      combinedLinesToRemove.forEach((combo) => {
        combo.forEach((combinedLine) => {
          combinedLineDetails.push(getQuoteLineItemDetailFromQuoteLineItem(currentQuoteLineItemDetails, combinedLine));
        });
      });

      combinedLineDetails.forEach(detail => completedAssemblies.splice(completedAssemblies.indexOf(detail), 1));

      //getting original line item details
      combinedLinesToRemove.forEach(combo => {
        combo.forEach(combinedLine => {
          originalDetails.push(getQuoteLineItemDetailFromQuoteLineItem(originalQuoteLineItemDetails, combinedLine));
        });
      });

      //getting the index of quoteLineItemDetails to change
      combinedLineDetails.forEach(item => {
        multipleIndexesOfDetailToChange.push(currentQuoteLineItemDetails.indexOf(item));
        }
      );

      //combining the index with its incoming detail
      const indexDetailPairs = multipleIndexesOfDetailToChange.map(function (value, index){
        return [value, originalDetails[index]];
      });

      //replacing created assemblies with original detail
      indexDetailPairs.forEach(indexAndItem => {
          currentQuoteLineItemDetails[indexAndItem[0]] = indexAndItem[1];
      });

      //setting quoteLineItemDetails and removing split assemblies from combinedLines
      setLocalQuoteLineItemDetails([...currentQuoteLineItemDetails]);
      combinedLinesToRemove.forEach(line => combinedLines.splice(combinedLines.indexOf(line), 1));
    }

    //update current assemblies
    const currentManualAssembliesLines = [...manuallySizedAssembliesLines];
    setManuallySizedAssembliesLines([
      ...currentManualAssembliesLines.filter(quoteLineItem => {
        return (
          !quoteLineItemExistsInArray(finishedGoods, quoteLineItem) &&
          !quoteLineItemDetailExistsInArray(manuallySizedAssembliesRowsToCombine, getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, quoteLineItem))
        );
      }),
    ]);

    const lineArray = finishedGoodsArray.map(finishedGood => finishedGood.Line__c);
    setFinishedGoodsForAssemblyLineArray(lineArray);
    setManuallySizedAssembliesRowsToCombine([]);
  };

  const updateQuoteLineData = (
    lineToUpdate: QuoteLineItem,
    fieldsUpdated: QuoteLineItemValueTypes[],
    combinedLine: QuoteLineItem,
    plateDetail: QuoteLineItemDetail,
    totalFinishedGoodAreaOnClad: number,
    totalFlatteningLaborHours: number,
    totalFlatteningCost: number,
    totalLBPerPC: number,
    totalKGPerPC: number,
    shipWeightLb: number,
    shipWeightKg: number
  ) => {
    lineToUpdate.PeggedSupply__c = combinedLine.PeggedSupply__c;
    fieldsUpdated.push(QuoteLineItemValueTypes.PeggedSupply);

    lineToUpdate.SharedCladdedPlate__c = combinedLine.SharedCladdedPlate__c;
    fieldsUpdated.push(QuoteLineItemValueTypes.SharedCladdedPlate);

    lineToUpdate.Item__c = combinedLine.Item__c;
    fieldsUpdated.push(QuoteLineItemValueTypes.Item);

    lineToUpdate.Show_Detail__c = combinedLine.Show_Detail__c;
    fieldsUpdated.push(QuoteLineItemValueTypes.ShowDetail);

    lineToUpdate.Parent_Line__c = combinedLine.Parent_Line__c;
    fieldsUpdated.push(QuoteLineItemValueTypes.ParentLine);

    lineToUpdate.PlateMethod__c = combinedLine.PlateMethod__c;
    fieldsUpdated.push(QuoteLineItemValueTypes.PlateMethod);

    lineToUpdate.quoteLineItemDetail.Clads__c = plateDetail.Clads__c;
    fieldsUpdated.push(QuoteLineItemValueTypes.Clads);

    lineToUpdate.quoteLineItemDetail.Quantity__c = plateDetail.Quantity__c;

    lineToUpdate.quoteLineItemDetail.Wide__c = plateDetail.Wide__c;

    lineToUpdate.quoteLineItemDetail.Long__c = plateDetail.Long__c;

    lineToUpdate.quoteLineItemDetail.Clad_TK_in__c = plateDetail.Clad_TK_in__c;
    lineToUpdate.quoteLineItemDetail.Clad_TK_mm__c = plateDetail.Clad_TK_mm__c;
    lineToUpdate.quoteLineItemDetail.PurchaseCladTK__c = calculateUOMValue(MeasurementSystems.Imperial, localUom, plateDetail.Clad_TK_in__c, Units.Millimeters, Units.Inches);
    fieldsUpdated.push(QuoteLineItemValueTypes.CladTK);

    lineToUpdate.quoteLineItemDetail.Base_Width_in__c = plateDetail.Base_Width_in__c;
    lineToUpdate.quoteLineItemDetail.Base_Width_mm__c = plateDetail.Base_Width_mm__c;
    lineToUpdate.quoteLineItemDetail.PurchaseBaseWidth__c = calculateUOMValue(MeasurementSystems.Imperial, localUom, plateDetail.Base_Width_in__c, Units.Millimeters, Units.Inches);
    fieldsUpdated.push(QuoteLineItemValueTypes.BaseWidth);

    lineToUpdate.quoteLineItemDetail.Base_Length_in__c = plateDetail.Base_Length_in__c;
    lineToUpdate.quoteLineItemDetail.Base_Length_mm__c = plateDetail.Base_Length_mm__c;
    lineToUpdate.quoteLineItemDetail.PurchaseBaseLength__c = calculateUOMValue(MeasurementSystems.Imperial, localUom, plateDetail.Base_Length_in__c, Units.Millimeters, Units.Inches);
    fieldsUpdated.push(QuoteLineItemValueTypes.BaseLength);

    lineToUpdate.quoteLineItemDetail.Base_TK_in__c = plateDetail.Base_TK_in__c;
    lineToUpdate.quoteLineItemDetail.Base_TK_mm__c = plateDetail.Base_TK_mm__c;
    lineToUpdate.quoteLineItemDetail.PurchaseBaseTK__c = calculateUOMValue(MeasurementSystems.Imperial, localUom, plateDetail.Base_TK_in__c, Units.Millimeters, Units.Inches);
    fieldsUpdated.push(QuoteLineItemValueTypes.BaseTK);

    lineToUpdate.quoteLineItemDetail.Edge_Allowance_IN__c = manualSizingSpecs.addEdgeAllowance ? calculatedImperialEdgeAllowance : 0;
    lineToUpdate.quoteLineItemDetail.Edge_Allowance_MM__c = manualSizingSpecs.addEdgeAllowance ? calculatedMetricEdgeAllowance : 0;

    lineToUpdate.quoteLineItemDetail.Booster_Allowance_IN__c = manualSizingSpecs.addBoosterAllowance ? calculatedImperialBoosterAllowance : 0;
    lineToUpdate.quoteLineItemDetail.Booster_Allowance_MM__c = manualSizingSpecs.addBoosterAllowance ? calculatedMetricBoosterAllowance : 0;

    lineToUpdate.quoteLineItemDetail.Specification_Adder_IN__c = manualSizingSpecs.addTestSpecimenAllowance ? calculatedImperialTestingAllowance : 0;
    lineToUpdate.quoteLineItemDetail.Specification_Adder_MM__c = manualSizingSpecs.addTestSpecimenAllowance ? calculatedMetricTestingAllowance : 0;

    lineToUpdate.quoteLineItemDetail.Kerf_Allowance_IN__c = plateDetail.Kerf_Allowance_IN__c;
    lineToUpdate.quoteLineItemDetail.Kerf_Allowance_MM__c = plateDetail.Kerf_Allowance_MM__c;

    lineToUpdate.quoteLineItemDetail.TotalFinishedGoodArea__c = totalFinishedGoodAreaOnClad;
    fieldsUpdated.push(QuoteLineItemValueTypes.TotalFinishedGoodArea);

    lineToUpdate.quoteLineItemDetail.FlatteningLaborHours__c = totalFlatteningLaborHours;
    fieldsUpdated.push(QuoteLineItemValueTypes.FlatteningLaborHours);

    lineToUpdate.quoteLineItemDetail.FlatteningLaborCost__c = totalFlatteningCost;
    fieldsUpdated.push(QuoteLineItemValueTypes.FlatteningLaborCost);

    lineToUpdate.Total_LB_PC__c = totalLBPerPC;
    lineToUpdate.Total_KG_PC__c = totalKGPerPC;
    fieldsUpdated.push(QuoteLineItemValueTypes.TotalWeightPerPC);

    const weightMultiplier = plateDetail.Clads__c;

    lineToUpdate.Ship_Weight_LB__c = shipWeightLb * weightMultiplier;
    lineToUpdate.Ship_Weight_KG__c = shipWeightKg * weightMultiplier;
    lineToUpdate.Weight_LB__c = lineToUpdate.Ship_Weight_LB__c;
    lineToUpdate.Weight_KG__c = lineToUpdate.Ship_Weight_KG__c;
    lineToUpdate.quoteLineItemDetail.Finish_Product_Weight_Lbs__c = lineToUpdate.Ship_Weight_LB__c;
    lineToUpdate.quoteLineItemDetail.Finish_Product_Weight_Kg__c = lineToUpdate.Ship_Weight_KG__c;

    fieldsUpdated.push(QuoteLineItemValueTypes.ShipWeight);
  };

  const saveManualSizing = () => {

    combinedLines.forEach(combination => {
      const totalFinishedGoodAreaOnClad = combination.map(
        qli => {
          return (
            qli.Unit_of_Measure__c === MeasurementSystems.Imperial
              ? qli.Area_Sqft__c
              : qli.Area_Sqm__c
            )
          * qli.Quantity__c
          * getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, qli).Clads__c
        }
      ).sum();

      const totalFlatteningLaborHours = combination.map(qli => getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, qli).FlatteningLaborHours__c).sum();
      const totalFlatteningCost = combination.map(qli => getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, qli).FlatteningLaborCost__c).sum();

      combination.forEach(combinedLine => {
        const fieldsUpdated = [];

        const plateDetail = getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, combinedLine);
        const quoteLineItemToEdit = { ...getQuoteLineItemFromId(availableQuoteLineItems, combinedLine.Id || combinedLine.displayId)};
        const quoteLineItemDetailToEdit = {...quoteLineItemToEdit.quoteLineItemDetail};

        let childTotalPoundsPerPiece = 0;
        let childTotalKilogramsPerPiece = 0;
        let childTotalLB = 0;
        let childTotalKG = 0;

        if(!combinedLine.SharedCladdedPlate__c) {
          const childQuoteLines = combination.filter(quoteLineItem => quoteLineItem.SharedCladdedPlate__c);

          for(const childLine of childQuoteLines) {
            childTotalPoundsPerPiece += childLine.Total_LB_PC__c;
            childTotalKilogramsPerPiece += childLine.Total_KG_PC__c;

            childTotalLB += childLine.Ship_Weight_LB__c;
            childTotalKG += childLine.Ship_Weight_KG__c;
          }
        }

        const lineLBPerPiece = quoteLineItemToEdit.Cut_Method__c === CutMethods.Uncut ? calculateTotalWeightPerPiece(quoteLineItemToEdit.Clad_LB_PC__c, quoteLineItemToEdit.Base_LB_PC__c) : calculateTotalWeightPerPiece(quoteLineItemDetailToEdit.Clad_Weight_Lbs__c, quoteLineItemDetailToEdit.Base_Weight_Lbs__c);
        const lineKGPerPiece = quoteLineItemToEdit.Cut_Method__c === CutMethods.Uncut ? calculateTotalWeightPerPiece(quoteLineItemToEdit.Clad_KG_PC__c, quoteLineItemToEdit.Base_KG_PC__c) : calculateTotalWeightPerPiece(quoteLineItemDetailToEdit.Clad_Weight_Kg__c, quoteLineItemDetailToEdit.Base_Weight_Kg__c);

        const totalLBPerPC = lineLBPerPiece + childTotalPoundsPerPiece;
        const totalKGPerPC = lineKGPerPiece + childTotalKilogramsPerPiece;

        const shipWeightLb = lineLBPerPiece + childTotalLB;
        const shipWeightKg = lineKGPerPiece + childTotalKG;

        updateQuoteLineData(quoteLineItemToEdit, fieldsUpdated, combinedLine, plateDetail, totalFinishedGoodAreaOnClad, totalFlatteningLaborHours, totalFlatteningCost, totalLBPerPC, totalKGPerPC, shipWeightLb, shipWeightKg);

        saveQuoteLineItem(new Map(), fieldsUpdated, quoteLineItemToEdit);
      });
    });
    dismissModal();
  };

  const handleNominalCladThicknessInput = (nomCladThicknessPerClad: number) => {
    manualSizingSpecs.nominalCladTKPerClad = +nomCladThicknessPerClad;

    const edgeAllowanceGroup = edgeAllowances.find(edgeAllowanceGroup => edgeAllowanceGroup.baseMetalClass === baseMetal.dmcClass && edgeAllowanceGroup.cladMetalClass === cladMetal.dmcClass && edgeAllowanceGroup.dataAreaId === manufacturingSite).edgeAllowances;
    setCalculatedImperialEdgeAllowance(calculateEdgeAllowance(edgeAllowanceGroup, calculateUOMValue(localUom, MeasurementSystems.Imperial, nomCladThicknessPerClad, Units.Millimeters, Units.Inches), MeasurementSystems.Imperial));
    setCalculatedMetricEdgeAllowance(calculateEdgeAllowance(edgeAllowanceGroup, calculateUOMValue(localUom, MeasurementSystems.Metric, nomCladThicknessPerClad, Units.Millimeters, Units.Inches), MeasurementSystems.Metric));

    const boosterAllowanceGroup = getBoosterAllowanceGroup(boosterAllowances, baseMetal, cladMetal, manufacturingSite);
    const circleBoosterThresholdIN = getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.BoosterAllowanceExceptionForSmallCircleDiametersIN, manufacturingSite, FieldTypes.Float) as number;
    const circleBoosterThresholdMM = getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.BoosterAllowanceExceptionForSmallCircleDiametersMM, manufacturingSite, FieldTypes.Float) as number;
    setCalculatedImperialBoosterAllowance(calculateBoosterAllowance(boosterAllowanceGroup, calculateUOMValue(localUom, MeasurementSystems.Imperial, nomCladThicknessPerClad, Units.Millimeters, Units.Inches), MeasurementSystems.Imperial, circleBoosterThresholdIN, false, masterPlateLine.Width_in__c, masterPlateLine.Shape__c));
    setCalculatedMetricBoosterAllowance(calculateBoosterAllowance(boosterAllowanceGroup, calculateUOMValue(localUom, MeasurementSystems.Metric, nomCladThicknessPerClad, Units.Millimeters, Units.Inches), MeasurementSystems.Metric, circleBoosterThresholdMM, false, masterPlateLine.Width_mm__c, masterPlateLine.Shape__c));

    if(manualSizingSpecs.nominalBaseTKPerClad) {
      setImperialKerfAllowance(calculateKerfAllowance(cladMetal, manufacturingSite, masterPlateLine.Cut_Method__c, MeasurementSystems.Imperial, calculateUOMValue(localUom, MeasurementSystems.Imperial, nomCladThicknessPerClad + manualSizingSpecs.nominalBaseTKPerClad, Units.Millimeters, Units.Inches)));
      setMetricKerfAllowance(calculateKerfAllowance(cladMetal, manufacturingSite, masterPlateLine.Cut_Method__c, MeasurementSystems.Metric, calculateUOMValue(localUom, MeasurementSystems.Metric, nomCladThicknessPerClad + manualSizingSpecs.nominalBaseTKPerClad, Units.Millimeters, Units.Inches)));
    }
  };

  const handleNominalBaseThicknessInput = (nomBaseThicknessPerClad: number) => {
    manualSizingSpecs.nominalBaseTKPerClad = +nomBaseThicknessPerClad;

    if(manualSizingSpecs.nominalCladTKPerClad) {
      setImperialKerfAllowance(calculateKerfAllowance(cladMetal, manufacturingSite, masterPlateLine.Cut_Method__c, MeasurementSystems.Imperial, calculateUOMValue(localUom, MeasurementSystems.Imperial, nomBaseThicknessPerClad + manualSizingSpecs.nominalCladTKPerClad, Units.Millimeters, Units.Inches)));
      setMetricKerfAllowance(calculateKerfAllowance(cladMetal, manufacturingSite, masterPlateLine.Cut_Method__c, MeasurementSystems.Metric, calculateUOMValue(localUom, MeasurementSystems.Metric, nomBaseThicknessPerClad + manualSizingSpecs.nominalCladTKPerClad, Units.Millimeters, Units.Inches)));
    }
  };

  const handleCladWidthInput = (cladWidth: number) => {
    manualSizingSpecs.widthPerClad = +cladWidth;

    const boosterAllowance = isDisplayMetric ? calculatedMetricBoosterAllowance : calculatedImperialBoosterAllowance;
    const finishedWidth = masterPlateLine.Unit_of_Measure__c === MeasurementSystems.Imperial ? masterPlateLine.Width_in__c : masterPlateLine.Width_mm__c;
    const specificationTestOption = specificationTestOptions.find((specificationTestOption) => specificationTestOption.specification === masterPlateLine.Specification__c && specificationTestOption.option === masterPlateLine.Bond_Metal_UT__c && specificationTestOption.dataAreaId === manufacturingSite);
    const specificationAdderBySpecification = getSpecificationAdderBySpecification(localUom, specificationTestOption, masterPlateLine.Specification__c, finishedWidth, masterPlateLine.Shape__c as Shapes, false, boosterAllowance, quoteVariableConfigurations, manufacturingSite);
    const specificationAdderByPostCladTestingOptions = getSpecificationAdderByPostCladTestingOptions(localUom, masterPlateLine.Post_Clad_Testing_Options__c?.split(';') || [], specificationTestOption, finishedWidth, masterPlateLine.Shape__c as Shapes, false, boosterAllowance, quoteVariableConfigurations, manufacturingSite);
    const specificationAdder = specificationAdderBySpecification || specificationAdderByPostCladTestingOptions;

    setCalculatedImperialTestingAllowance(specificationAdder);
    setCalculatedMetricTestingAllowance(convertUnits(specificationAdder, Units.Inches, Units.Millimeters));
  };

  const FinishedGood = (props: {
    quoteLineItem: QuoteLineItem,
    quoteLineItemIndex: number
  }) => {

    return (
      <>
        <IonCard className='finished-good-card' onClick={(e: any) => {
          e.persist();
        }} data-testid={'QAManualSizingPopoverFinishedGood_' + props.quoteLineItemIndex}
        >
          <IonCardHeader class={classNames({'finished-good-card-header': true, 'selected': quoteLineItemExistsInArray(finishedGoodsRowsToSplit, props.quoteLineItem)})}>
            <IonGrid>
              <IonRow class="finished-good-row">
                <IonCol size="0.458" class="column-left">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Line}
                    datatestid="QAManualSizingPopoverFinishedGoodLine"
                    displayedValue={props.quoteLineItem.Line__c}
                  />
                </IonCol>
                <IonCol size="0.6" class="column-left">
                  <LineItemField
                    datatestid="QAManualSizingPopoverFinishedGoodQuantity"
                    valueType={QuoteLineItemValueTypes.SizingQuantity}
                    value={props.quoteLineItem.Quantity__c}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="0.916" class="column-left">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.SizingProduct}
                    datatestid="QAManualSizingPopoverFinishedGoodProduct"
                    displayedValue={props.quoteLineItem.Item_Type__c || '- -'}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="1.2" class="column-left">
                  <LineItemField
                    datatestid="QAManualSizingPopoverFinishedGoodWidth"
                    valueType={QuoteLineItemValueTypes.Width}
                    imperialValue={props.quoteLineItem.Width_in__c}
                    metricValue={props.quoteLineItem.Width_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}
                    uom={localUom}
                  />
                </IonCol>
                <IonCol size="1.2" class="column-left">
                  <LineItemField
                    datatestid="QAManualSizingPopoverFinishedGoodLength"
                    valueType={QuoteLineItemValueTypes.Length}
                    imperialValue={props.quoteLineItem.Length_in__c}
                    metricValue={props.quoteLineItem.Length_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}
                    uom={localUom}
                  />
                </IonCol>
                <IonCol size="1.374" class="column-left">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladType}
                    datatestid="QAManualSizingPopoverFinishedGoodCladType"
                    displayedValue={props.quoteLineItem.Clad_Type__c || '- -'}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="1.2" class="column-left">
                  <LineItemField
                    datatestid="QAManualSizingPopoverFinishedGoodCladTK"
                    valueType={QuoteLineItemValueTypes.FinishedCladTK}
                    imperialValue={props.quoteLineItem.Clad_TK_in__c}
                    metricValue={props.quoteLineItem.Clad_TK_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                    readOnly={true}
                    uom={localUom}
                  />
                </IonCol>
                <IonCol size="1.374" class="column-left">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseType}
                    datatestid="QAManualSizingPopoverFinishedGoodBaseType"
                    displayedValue={props.quoteLineItem.Base_Type__c || '- -'}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="1.2" class="column-left base-tk-column">
                  <LineItemField
                    datatestid="QAManualSizingPopoverFinishedGoodBaseTK"
                    valueType={QuoteLineItemValueTypes.FinishedBaseTK}
                    imperialValue={props.quoteLineItem.Base_TK_in__c}
                    metricValue={props.quoteLineItem.Base_TK_mm__c}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                    readOnly={true}
                    uom={localUom}
                  />
                </IonCol>
                <IonCol class="column-left">
                  <div className="column-container right-align">
                    <IonCheckbox
                      class='checkbox'
                      slot="start"
                      color="primary"
                      checked={quoteLineItemExistsInArray(finishedGoodsRowsToSplit, props.quoteLineItem)}
                      onIonChange={(e) => {
                        const updatedFGRowsToSplit = e.detail.checked ?
                          [...finishedGoodsRowsToSplit, props.quoteLineItem] :
                          [...finishedGoodsRowsToSplit.filter(rowToSplit => rowToSplit.Id ? rowToSplit.Id !== props.quoteLineItem.Id : rowToSplit.displayId !== props.quoteLineItem.displayId)];
                        setFinishedGoodsRowsToSplit(updatedFGRowsToSplit);
                      }}
                      data-testid={'QAManualSizingPopoverFinishedGoodCheckbox'}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const FinishedGoodForAssembly = (props: {
    finishedGoodForAssembly: { line: string, quantity: number },
    finishedGoodForAssemblyIndex: number
  }) => {

    return (
      <>
        <IonCard className='finished-good-for-assembly-card' onClick={(e: any) => {
          e.persist();
        }} data-testid={'QAManualSizingPopoverFinishedGoodForAssembly_' + props.finishedGoodForAssemblyIndex}
        >
          <IonCardHeader class='finished-good-for-assembly-card-header'>
            <IonGrid>
              <IonRow class="finished-good-for-assembly-row">
                <IonCol class="column-left">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Line}
                    datatestid="QAManualSizingPopoverFinishedGoodForAssemblyLine"
                    displayedValue={props.finishedGoodForAssembly.line}
                  />
                </IonCol>
                <IonCol class="column-left">
                  <LineItemField
                    datatestid="QAManualSizingPopoverFinishedGoodForAssemblyQuantity"
                    valueType={QuoteLineItemValueTypes.SizingQuantity}
                    value={props.finishedGoodForAssembly.quantity}
                    readOnly={true}
                  />
                   <div className='close-line-container' onClick={() => {removeLine(props.finishedGoodForAssembly);}}>
                    <IonIcon class="close-line-icon" src={closeCircle}></IonIcon>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const ExpandLineItemColumn = (props) => {
    return (
        <IonIcon className='quote-line-item-expand-button' data-testid="QAManualSizingAssemblyExpandButton" src={props.expanded ? chevronUp : chevronDown} onClick={() => props.setExpanded(!props.expanded)}></IonIcon>
    );
  };

  const ManualSizingAssemblyDetails = (props: {
    quoteLineItemDetailIndex: number,
    quoteLineItem: QuoteLineItem
  }) => {
    return (
      <>
        <div className='detail-connector'>
          <hr className='detail-connector-vert-line'/>
        </div>
        <div className='detail-connector'>
          <hr className='detail-connector-hor-line'/>
        </div>
        <IonCard className="manually-sized-assembly-card assembly-detail" data-testid={'QAManuallySizedAssembliesDetails_' + props.quoteLineItemDetailIndex}>
          <IonCardHeader class='manually-sized-assembly-card-header'>
            <IonGrid>
              <IonRow class="manually-sized-assembly-row">
                <IonCol size="3" class="column-left">
                  <LineItemField datatestid="QAManuallySizedAssembliesDetailsLine" displayedValue={props.quoteLineItem.Line__c}/>
                </IonCol>
                <IonCol class="column-left">
                  <LineItemField datatestid="QAManuallySizedAssembliesDetailsQuantity" displayedValue={props.quoteLineItem.Quantity__c}/>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const ManuallySizedAssembliesDetailsList = (props: {
    quoteLineItem: QuoteLineItem,
  }) => {
    return (manuallySizedAssembliesLines?.length ?
      <>
      {
      updatedQuoteLineItems?.filter((sizedLineItem) => sizedLineItem.Line__c === props.quoteLineItem.Line__c || sizedLineItem.Parent_Line__c === props.quoteLineItem.Line__c)
        .map((quoteLineItem, index) => {
          return <ManualSizingAssemblyDetails quoteLineItem={quoteLineItem} quoteLineItemDetailIndex={index}/>;
        })}
      </> : null
    );
  };

  const AssemblyDetailExpandedTable = (props: {
    quoteLineItem: QuoteLineItem,
    expanded: boolean
  }) => {

    const tableHeadersAssemblyDetailExpandedTable = [
      { display: QuoteLineItemsColumnHeadings.Line, colSize: '3' },
      { display: QuoteLineItemsColumnHeadings.Quantity, colSize: '' },
    ];

    return (props.expanded ?
      <>
        <TableHeaders labels={tableHeadersAssemblyDetailExpandedTable} details={true}/>
        <ManuallySizedAssembliesDetailsList quoteLineItem={props.quoteLineItem}/>
      </> : null
    );
  };

  const ManuallySizedAssembly = (props: {
    quoteLineItem: QuoteLineItem,
    quoteLineItemDetail: QuoteLineItemDetail,
    quoteLineItemDetailIndex: number
  }) => {
    const [expanded, setExpanded] = React.useState(!!expandedMap.get(props.quoteLineItem));

    React.useEffect(() => {
      expandedMap.set(props.quoteLineItem, expanded);
    }, [expanded]);

    return (
      <>
        <IonCard className='manually-sized-assembly-card' onClick={(e: any) => {
          e.persist();
        }} data-testid={'QAManualSizingPopoverManuallySizedAssembly_' + props.quoteLineItemDetailIndex}
        >
          <IonCardHeader class={classNames({'manually-sized-assembly-card-header': true, 'selected': quoteLineItemDetailExistsInArray(manuallySizedAssembliesRowsToCombine, props.quoteLineItemDetail), 'combined': quoteLineItemDetailExistsInArray(completedAssemblies, props.quoteLineItemDetail)})}>
            <IonGrid>
              <IonRow class="manually-sized-assembly-row">
                <IonCol size="1.17411" class="column-left">
                <ExpandLineItemColumn expanded={expanded} setExpanded={setExpanded}/>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Clads}
                    datatestid="QAManualSizingPopoverManuallySizedAssemblyClads"
                    displayedValue={props.quoteLineItemDetail?.Clads__c || '- -'}
                  />
                </IonCol>
                <IonCol size="0.84411" class="column-left">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.DetailQuantity}
                    datatestid="QAManualSizingPopoverManuallySizedAssemblyQuantity"
                    displayedValue={props.quoteLineItemDetail?.Quantity__c || '- -'}
                  />
                </IonCol>
                <IonCol size="0.84411" class="column-left">
                  <LineItemField
                    datatestid="QAManualSizingPopoverManuallySizedAssemblyW"
                    valueType={QuoteLineItemValueTypes.Wide}
                    value={props.quoteLineItemDetail?.Wide__c}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="0.84411" class="column-left">
                  <LineItemField
                    datatestid="QAManualSizingPopoverManuallySizedAssemblyL"
                    valueType={QuoteLineItemValueTypes.Long}
                    value={props.quoteLineItemDetail?.Long__c}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size="1.2705" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAManualSizingPopoverManuallySizedAssemblyCladWidth"
                      valueType={QuoteLineItemValueTypes.CladWidth}
                      imperialValue={props.quoteLineItemDetail?.Clad_Width_in__c}
                      metricValue={props.quoteLineItemDetail?.Clad_Width_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                      readOnly={true}
                      uom={localUom}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.2705" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAManualSizingPopoverManuallySizedAssemblyCladLength"
                      valueType={QuoteLineItemValueTypes.CladLength}
                      imperialValue={props.quoteLineItemDetail?.Clad_Length_in__c}
                      metricValue={props.quoteLineItemDetail?.Clad_Length_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                      readOnly={true}
                      uom={localUom}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.2705" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAManualSizingPopoverManuallySizedAssemblyCladTK"
                      valueType={QuoteLineItemValueTypes.CladTK}
                      imperialValue={props.quoteLineItemDetail?.Clad_TK_in__c}
                      metricValue={props.quoteLineItemDetail?.Clad_TK_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      readOnly={true}
                      uom={localUom}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.2705" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAManualSizingPopoverManuallySizedAssemblyBaseWidth"
                      valueType={QuoteLineItemValueTypes.BaseWidth}
                      imperialValue={props.quoteLineItemDetail?.Base_Width_in__c}
                      metricValue={props.quoteLineItemDetail?.Base_Width_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                      readOnly={true}
                      uom={localUom}
                    />
                  </div>
                </IonCol>
                <IonCol size="1.2705" class="column-left">
                  <div className="column-container right-align">
                    <LineItemField
                      datatestid="QAManualSizingPopoverManuallySizedAssemblyBaseLength"
                      valueType={QuoteLineItemValueTypes.BaseLength}
                      imperialValue={props.quoteLineItemDetail?.Base_Length_in__c}
                      metricValue={props.quoteLineItemDetail?.Base_Length_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                      readOnly={true}
                      uom={localUom}
                    />
                  </div>
                </IonCol>
                <IonCol class="column-left base-tk-column">
                  <div className="column-container">
                    <LineItemField
                      datatestid="QAManualSizingPopoverManuallySizedAssemblyBaseTK"
                      valueType={QuoteLineItemValueTypes.BaseTK}
                      imperialValue={props.quoteLineItemDetail?.Base_TK_in__c}
                      metricValue={props.quoteLineItemDetail?.Base_TK_mm__c}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      readOnly={true}
                      uom={localUom}
                    />
                  </div>
                </IonCol>
                <IonCol class="column-left">
                  <div className="column-container right-align">
                    <IonCheckbox
                      class='checkbox'
                      slot="start"
                      color="primary"
                      checked={quoteLineItemDetailExistsInArray(manuallySizedAssembliesRowsToCombine, props.quoteLineItemDetail)}
                      onIonChange={(e) => {
                        if (combinedLines.some(combo => quoteLineItemExistsInArray(combo, props.quoteLineItem))) {
                          const itemsToSplit = splitCombinedLines(props.quoteLineItem);
                          const updatedMSARowsToCombine = e.detail.checked ?
                          itemsToSplit :
                          [...manuallySizedAssembliesRowsToCombine.filter(rowToCombine => !quoteLineItemExistsInArray(itemsToSplit, rowToCombine))];
                          setManuallySizedAssembliesRowsToCombine(updatedMSARowsToCombine);
                        } else {
                          const updatedMSARowsToCombine = e.detail.checked ?
                          [...manuallySizedAssembliesRowsToCombine, props.quoteLineItemDetail] :
                          [...manuallySizedAssembliesRowsToCombine.filter(rowToCombine => rowToCombine.Id ? rowToCombine.Id !== props.quoteLineItemDetail?.Id : rowToCombine.associatedLineItem !== props.quoteLineItemDetail?.associatedLineItem)];
                          setManuallySizedAssembliesRowsToCombine(updatedMSARowsToCombine);
                        }

                      }}
                      data-testid={'QAManualSizingPopoverManuallySizedAssemblyCheckbox'}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <AssemblyDetailExpandedTable expanded={expanded} quoteLineItem={props.quoteLineItem}/>
      </>
    );
  };

  const FinishedGoodsList = () => {
    return (
      <>
        {finishedGoods?.orderBy(quoteLineItem => quoteLineItem.Line__c).map((quoteLineItem, index) => {
          return <FinishedGood quoteLineItem={quoteLineItem} quoteLineItemIndex={index} key={index}/>;
        })}
      </>
    );
  };

  const FinishedGoodsForAssemblyList = () => {
    return (
      <div className='finished-goods-for-assembly-container'>
        <hr className='finished-goods-for-assembly-hr'></hr>
        {finishedGoodsForAssembly?.map((finishedGoodForAssembly, index) => {
          return <FinishedGoodForAssembly finishedGoodForAssembly={finishedGoodForAssembly} finishedGoodForAssemblyIndex={index} key={index}/>;
        })}
      </div>
    );
  };

  const ManuallySizedAssembliesList = () => {
    return (
      <>
        {manuallySizedAssembliesLines?.orderBy(quoteLineItem => quoteLineItem.Line__c).map((quoteLineItem, index) => {
          return <ManuallySizedAssembly quoteLineItem={quoteLineItem} quoteLineItemDetail={getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, quoteLineItem)} quoteLineItemDetailIndex={index}/>;
        })}
      </>
    );
  };

  const FinishedCancelButtons = () => (
    <>
      <IonButton
        color='primary'
        class='finished-button'
        data-testid={'QAManualSizingPopoverFinishedButton'}
        onClick={() => {
          finishedGoods.length > 0 ? toast(ToastTypes.Error, ToastMessages.IncompleteFinishedGoods) : saveManualSizing();
        }}
        disabled={!completedAssemblies.length}
      >
        Finished
      </IonButton>
      <IonButton color='secondary' class='cancel-button' onClick={() => dismissModal()}>
        Cancel
      </IonButton>
    </>
  );

  const AllowanceDescription = (props: {
    label: string,
    metricValue: number,
    imperialValue: number,
    description: string
  }) => {

    return (
      <IonLabel class='allowance-description'>{`${props.label}: ${isDisplayMetric ? props.metricValue.toFixed(3) + 'mm': props.imperialValue.toFixed(3) + '"'} ${props.description}`}</IonLabel>
    );
  };

  const SelectAllRowsButton = (props: {
    rowType: QuoteLineTypes
  }) => {
    return (
      <IonButton
        color='secondary'
        class='toggle-rows select-all'
        data-testid={'QAManualSizingPopoverSelectAllRowsButton'}
        onClick={() => {
          const updatedRows = [];
          const currentFinishedGoods = [...finishedGoods];

          if(props.rowType === QuoteLineTypes.Detail) {
            availableQuoteLineItems.forEach(quoteLineItem => {
              updatedRows.push(getQuoteLineItemDetailFromQuoteLineItem(localQuoteLineItemDetails, quoteLineItem));
            });

            setManuallySizedAssembliesRowsToCombine([...updatedRows]);
          } else {
            currentFinishedGoods.forEach(quoteLineItem => {
              updatedRows.push(quoteLineItem);
            });

            setFinishedGoodsRowsToSplit([...updatedRows]);
          }
        }}
        disabled={props.rowType === QuoteLineTypes.Detail ? !manuallySizedAssembliesLines.length : !finishedGoods.length}
      >
      </IonButton>
    );
  };

  const DeselectAllRowsButton = (props: {
    rowType: QuoteLineTypes
  }) => {
    return (
      <IonButton
        color='primary'
        class='toggle-rows'
        data-testid={'QAManualSizingPopoverDeselectAllRowsButton'}
        onClick={() => props.rowType === QuoteLineTypes.Detail ? setManuallySizedAssembliesRowsToCombine([]) : setFinishedGoodsRowsToSplit([])}
      >
        ----
      </IonButton>
    );
  };

  const FinishedGoodsTable = () => {
    const tableHeadersFinishedGoodsTable = [
      { display: QuoteLineItemsColumnHeadings.Line, colSize: '0.458' },
      { display: QuoteLineItemsColumnHeadings.Quantity, colSize: '0.6' },
      { display: QuoteLineItemsColumnHeadings.Product, colSize: '0.916' },
      { display: QuoteLineItemsColumnHeadings.Width, colSize: '1.2' },
      { display: QuoteLineItemsColumnHeadings.Length, colSize: '1.2' },
      { display: QuoteLineItemsColumnHeadings.CladType, colSize: '1.374' },
      { display: QuoteLineItemsColumnHeadings.CladTK, colSize: '1.2' },
      { display: QuoteLineItemsColumnHeadings.BaseType, colSize: '1.374' },
      { display: QuoteLineItemsColumnHeadings.BaseTK, colSize: '1.2' },
    ];

    return (finishedGoods.length ?
      <div>
        <TableHeaders labels={tableHeadersFinishedGoodsTable}/>
        <div className='finished-goods-container'>
          <FinishedGoodsList/>
        </div>
        <IonButton
          color='primary'
          class='remove-button'
          data-testid={'QAManualSizingPopoverFinishedGoodsRemoveButton'}
          disabled={!finishedGoodsRowsToSplit.length}
          onClick={() => {
            const currentFinishedGoods = [...finishedGoods];
            const updatedLineItemArray = finishedGoodsForAssemblyLineArray.filter(dropdownItem => !finishedGoodsRowsToSplit.find(finishedGood => finishedGood.Line__c === dropdownItem));
            setFinishedGoodsForAssemblyLineArray(updatedLineItemArray);
            setManuallySizedAssembliesLines([...manuallySizedAssembliesLines, ...availableQuoteLineItems.filter(quoteLineItem => { return quoteLineItemExistsInArray(finishedGoodsRowsToSplit, quoteLineItem);})]);
            setFinishedGoods([...currentFinishedGoods.filter(quoteLineItem => { return !quoteLineItemExistsInArray(manuallySizedAssembliesLines, quoteLineItem) && !quoteLineItemExistsInArray(finishedGoodsRowsToSplit, quoteLineItem); })]);
            setFinishedGoodsRowsToSplit([]);
          }}
        >
          Remove
        </IonButton>
      </div> : null
    );
  };

  const ManuallySizedAssembliesTable = () => {
    const tableHeadersManuallySizedAssembliesTable = [
      { display: '', colSize: '0.33' },
      { display: QuoteLineItemsColumnHeadings.Clads, colSize: '0.84411' },
      { display: QuoteLineItemsColumnHeadings.Quantity, colSize: '0.84411' },
      { display: QuoteLineItemsColumnHeadings.W, colSize: '0.84411' },
      { display: QuoteLineItemsColumnHeadings.L, colSize: '0.84411' },
      { display: QuoteLineItemsColumnHeadings.CladWidth, colSize: '1.2705' },
      { display: QuoteLineItemsColumnHeadings.CladLength, colSize: '1.2705' },
      { display: QuoteLineItemsColumnHeadings.CladTK, colSize: '1.2705' },
      { display: QuoteLineItemsColumnHeadings.BaseWidth, colSize: '1.2705' },
      { display: QuoteLineItemsColumnHeadings.BaseLength, colSize: '1.2705' },
      { display: QuoteLineItemsColumnHeadings.BaseTK },
    ];

    return (manuallySizedAssembliesLines.length ?
      <div>
        <TableHeaders labels={tableHeadersManuallySizedAssembliesTable}/>
        <div className='manually-sized-assemblies-container'>
          <ManuallySizedAssembliesList/>
        </div>
        <IonButton
          color='primary'
          class='add-button'
          data-testid={'QAManualSizingPopoverManuallySizedAssembliesAddButton'}
          disabled={!manuallySizedAssembliesRowsToCombine.length}
          onClick={handleAddAssembliesToCombine}
        >
          Add
        </IonButton>
      </div> : null
    );
  };

  const FinishedGoodsForAssemblyTable = () => {
    const tableHeadersFinishedGoodsForAssemblyTable = [
      { display: QuoteLineItemsColumnHeadings.Line },
      { display: QuoteLineItemsColumnHeadings.Quantity },
    ];

    return (
      <div>
        <TableHeaders labels={tableHeadersFinishedGoodsForAssemblyTable}/>
        <FinishedGoodsForAssemblyList/>
      </div>
    );
  };

  return (
    <div className='popover manual-sizing'>
      <div className='title-container'>
        <IonLabel class='title' data-testid={'QAManualSizingPopoverHeader'}>
          Manual Sizing
        </IonLabel>
        {/* adjustment container for uom toggler */}
        <div style={{margin: '108px 44px 10px 0'}}>
          <LocalUomDisplayToggle uom={localUom} setUom={setLocalUom} />
        </div>
        <IonImg src={!darkMode ? closeIcon : closeIconWhite} class='close-icon' onClick={() => dismissModal()}></IonImg>
      </div>
      <hr />
      <div className='inner-section'>
        <IonGrid>
          <IonRow>
            <IonCol size='3.5' class='flex-column section-column'>
              <div className='flex-container flex-direction-column-sm'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel={`Nom Clad TK / Clad (${isDisplayMetric ? 'mm' : 'in'})`}
                  placeholder={isDisplayMetric ? '0.00' : '0.000'}
                  regex={Regexes.DecimalNumber}
                  min={isDisplayMetric ? '0.01' : '0.001'}
                  step={isDisplayMetric ? '0.01' : '0.001'}
                  style={{ width: '100%', marginBottom: '2px' }}
                  inputContainerStyle={{ paddingRight: '10px' }}
                  containerStyle={{ flex: 1 }}
                  onBlur={nominalCladTKPerClad => handleNominalCladThicknessInput(nominalCladTKPerClad)}
                  value={manualSizingSpecs.nominalCladTKPerClad}
                  isDisabled={!finishedGoodsForAssembly.length}
                />
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel={`Nom Base TK / Clad (${isDisplayMetric ? 'mm' : 'in'})`}
                  placeholder={isDisplayMetric ? '0.00' : '0.000'}
                  regex={Regexes.DecimalNumber}
                  min={isDisplayMetric ? '0.01' : '0.001'}
                  step={isDisplayMetric ? '0.01' : '0.001'}
                  style={{ width: '100%', marginBottom: '2px' }}
                  inputContainerStyle={{ paddingRight: '10px' }}
                  containerStyle={{ flex: 1 }}
                  onBlur={nominalBaseTKPerClad => handleNominalBaseThicknessInput(nominalBaseTKPerClad)}
                  value={manualSizingSpecs.nominalBaseTKPerClad}
                  isDisabled={!finishedGoodsForAssembly.length}
                />
              </div>
              <div className='flex-container flex-direction-column-sm'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel={`Width per Clad (${isDisplayMetric ? 'mm' : 'in'})`}
                  placeholder={isDisplayMetric ? '0' : '0.000'}
                  regex={Regexes.DecimalNumber}
                  min={isDisplayMetric ? '1' : '0.001'}
                  step={isDisplayMetric ? '1' : '0.001'}
                  style={{ width: '100%', marginBottom: '2px' }}
                  inputContainerStyle={{ paddingRight: '10px' }}
                  containerStyle={{ flex: 1 }}
                  onBlur={width => handleCladWidthInput(width)}
                  value={manualSizingSpecs.widthPerClad}
                  isDisabled={!finishedGoodsForAssembly.length}
                />
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  selectionLabel={`Length per Clad (${isDisplayMetric ? 'mm' : 'in'})`}
                  placeholder={isDisplayMetric ? '0' : '0.000'}
                  regex={Regexes.DecimalNumber}
                  min={isDisplayMetric ? '0' : '0.001'}
                  step={isDisplayMetric ? '0' : '0.001'}
                  style={{ width: '100%' }}
                  inputContainerStyle={{ paddingRight: '10px' }}
                  containerStyle={{ flex: 1 }}
                  onBlur={length => manualSizingSpecs.lengthPerClad = +length}
                  value={manualSizingSpecs.lengthPerClad}
                  isDisabled={!finishedGoodsForAssembly.length}
                />
              </div>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                selectionLabel='Identical Assembly Quantity'
                placeholder='1'
                regex={Regexes.IntegerAboveZero}
                min='1'
                style={{ width: '100%', maxWidth: '156px' }}
                onBlur={identicalAssemblyQuantity => manualSizingSpecs.identicalAssemblyQuantity = +identicalAssemblyQuantity}
                value={manualSizingSpecs.identicalAssemblyQuantity}
              />
              <CheckboxItem
                label='Add Edge Allowance'
                style={{ width: 'fit-content', marginTop: '18px' }}
                onIonChange={(addEdgeAllowance) => manualSizingSpecs.addEdgeAllowance = addEdgeAllowance}
                checked={manualSizingSpecs.addEdgeAllowance}
              />
              <CheckboxItem
                label='Add Booster Allowance'
                style={{ width: 'fit-content', marginTop: '18px' }}
                onIonChange={(addBoosterAllowance) => manualSizingSpecs.addBoosterAllowance = addBoosterAllowance}
                checked={manualSizingSpecs.addBoosterAllowance}
              />
              <CheckboxItem
                label='Add Test Specimen Allowance'
                style={{ width: 'fit-content', marginTop: '18px' }}
                onIonChange={(addTestSpecimenAllowance) => manualSizingSpecs.addTestSpecimenAllowance = addTestSpecimenAllowance}
                checked={manualSizingSpecs.addTestSpecimenAllowance}
              />
            </IonCol>
            <IonCol class='flex-column section-column'>
              <div className='section-header-container'>
                <IonLabel class='subheader' data-testid={'QAManualSizingPopoverFinishedGoodsSection'}>
                  Finished Goods
                </IonLabel>
                {!finishedGoodsRowsToSplit.length ? <SelectAllRowsButton rowType={QuoteLineTypes.Master}/> : <DeselectAllRowsButton rowType={QuoteLineTypes.Master}/>}
              </div>
              <hr className='section-rule' />
              <FinishedGoodsTable/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='3.5' class='flex-column section-column'>
              <IonLabel class='subheader' data-testid={'QAManualSizingPopoverFinishedGoodsForAssemblySection'}>
                Finished Goods For Assembly
              </IonLabel>
              <hr className='section-rule short' />
              <div className='flex-container flex-direction-column-sm'>
                <SelectionContainer
                  type={SelectionTypes.DropDown}
                  options={finishedGoodsForAssemblyLineArray}
                  selectionLabel='Finished Goods Line'
                  placeholder='001'
                  style={{ width: '100%', marginBottom: '2px' }}
                  containerStyle={{ flex: 1, paddingRight: '10px' }}
                  onChange={finishedGoodsLine => setLineItem(finishedGoodsLine.label)}
                  value={selection}
                />
                <SelectionContainer
                  type={SelectionTypes.DropDown}
                  options={assemblyQuantityArray}
                  selectionLabel='Pieces'
                  placeholder='1'
                  style={{ width: '100%', marginBottom: '8px' }}
                  containerStyle={{ flex: 1, paddingRight: '10px' }}
                  onChange={pieces => setPieces(pieces.label)}
                  value={pieceQuantity}
                />
                <IonCard className='flex-container button-container' disabled={addLineDisabled} onClick={() => {addLine();}}>
                    <IonIcon class="button-icon add-line" src={addCircle}></IonIcon>
                    <IonLabel class="button-label add-line">Add Line</IonLabel>
                </IonCard>
              </div>
              <FinishedGoodsForAssemblyTable/>
              <div className='flex-container finished-good-for-assembly-buttons'>
                <IonCard className='button-container create-container' disabled={createDisabled} onClick={() => {createManualSizedAssembly();}}>
                  <IonIcon class="button-icon create" src={addCircle}></IonIcon>
                  <IonLabel class="button-label create">Create</IonLabel>
                </IonCard>
              </div>
            </IonCol>
            <IonCol class='flex-column section-column'>
              <div className='section-header-container'>
                <IonLabel class='subheader' data-testid={'QAManualSizingPopoverManuallySizedAssembliesSection'}>
                  Assemblies
                </IonLabel>
                {!manuallySizedAssembliesRowsToCombine.length ? <SelectAllRowsButton rowType={QuoteLineTypes.Detail}/> : <DeselectAllRowsButton rowType={QuoteLineTypes.Detail}/>}
              </div>
              <hr className='section-rule' />
              <ManuallySizedAssembliesTable/>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <hr className='bottom-rule'/>
      <div className='bottom-container'>
        <div>
          <FinishedCancelButtons />
        </div>
        <div className='allowance-description-container'>
          <AllowanceDescription
            label='Edge'
            imperialValue={calculatedImperialEdgeAllowance}
            metricValue={calculatedMetricEdgeAllowance}
            description='Per Side'
          />
        </div>
        <div className='allowance-description-container'>
          <AllowanceDescription
            label='Kerf'
            imperialValue={imperialKerfAllowance}
            metricValue={metricKerfAllowance}
            description='Between Pieces'
          />
        </div>
        <div className='allowance-description-container'>
          <AllowanceDescription
            label='Booster'
            imperialValue={calculatedImperialBoosterAllowance}
            metricValue={calculatedMetricBoosterAllowance}
            description='Per Side'
          />
        </div>
        <div className='allowance-description-container'>
          <AllowanceDescription
            label='Testing'
            imperialValue={calculatedImperialTestingAllowance}
            metricValue={calculatedMetricTestingAllowance}
            description='Per Side'
          />
        </div>
      </div>
    </div>
  );
};

export default ManualSizingPopover;