import { NCLineItemsColumnHeadings } from '@constants';
import { NCLineItem } from '@interfaces/nc-line-item';
import { getBadgeText, getStatusImageFromAPIStatus, getStatusSortingOrderFromAPIStatus } from '@shared/utils';
import moment from 'moment';

// Returns correct ship date field for the line item detail
export const getShipDateField = (ncLineItem: NCLineItem) => {
  return ncLineItem.Rescheduled_Customer_Ship_Date__c ? 'Rescheduled_Customer_Ship_Date__c' : (ncLineItem.Actual_Ship_Date__c ? 'Actual_Ship_Date__c' : 'Customer_Request_Date__c');
};

// Maps some nc line item properties so that the UI can consume the nc line item
export const mapNCLineItem = async (ncLineItem: NCLineItem, lastViewed?: any, darkMode?: boolean) => {
  const Line_Number__c = String(ncLineItem.Line_Number__c).padStart(3, '0');
  const shipDate = ncLineItem[getShipDateField(ncLineItem)];

  return {
    ...ncLineItem,
    statusImgSrc: getStatusImageFromAPIStatus(ncLineItem.Status__c, darkMode),
    statusSortingOrder: getStatusSortingOrderFromAPIStatus(ncLineItem.Status__c),
    Line_Number__c,
    item: `${Line_Number__c}-${ncLineItem.Line_Item_Detail_Number__c}`,
    badgeText: await getBadgeText(ncLineItem.Id, undefined, new Date(ncLineItem.Line_Status_Change_Date__c), lastViewed),
    shipDate: shipDate ? moment(shipDate).format('MM/DD/YYYY') : ''
  };
};

// Returns the correct salesforce nc line item property based on the column header
export const getColumnIdFromName = (colName: NCLineItemsColumnHeadings) => {
  return {
    [NCLineItemsColumnHeadings.Line]: 'Line_Number__c',
    [NCLineItemsColumnHeadings.Status]: 'Status__c',
    [NCLineItemsColumnHeadings.Description]: 'Description__c',
    [NCLineItemsColumnHeadings.Quantity]: 'Quanitity__c',
    [NCLineItemsColumnHeadings.UnitPrice]: 'Unit_Price__c',
    [NCLineItemsColumnHeadings.Total]: 'Unit_Price_Quantity__c',
  }[colName];
};