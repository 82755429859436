import { QuoteLineItemValueTypes } from '@constants';

// prettier-ignore
export const quoteFieldDependencyMap: Map<QuoteLineItemValueTypes, QuoteLineItemValueTypes[]> =
  new Map([
    [
      //Refactored
      QuoteLineItemValueTypes.UnitOfMeasure,
      [
        QuoteLineItemValueTypes.CladWeight,
        QuoteLineItemValueTypes.BaseWeight,
        QuoteLineItemValueTypes.CladThicknessAddition,
        QuoteLineItemValueTypes.TotalTK,
        QuoteLineItemValueTypes.BaseThicknessAddition,
        QuoteLineItemValueTypes.CladOverhangThreshold,
        QuoteLineItemValueTypes.CosmeticGrindingCladThicknessAddition,
        QuoteLineItemValueTypes.CladOverhang,
        QuoteLineItemValueTypes.Dimensions,
        QuoteLineItemValueTypes.TubesheetMachiningManHours,
        QuoteLineItemValueTypes.NCInhouseStressReliefTemp,
        QuoteLineItemValueTypes.MachiningNotes,
        QuoteLineItemValueTypes.HeatTreatment
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.BaseMetal,
      [
        QuoteLineItemValueTypes.BaseMetalPricingByProposalType,
        QuoteLineItemValueTypes.BaseThicknessAddition,
        QuoteLineItemValueTypes.BaseWeight,
        QuoteLineItemValueTypes.BoosterAllowanceGroup,
        QuoteLineItemValueTypes.EdgeAllowanceGroup,
        QuoteLineItemValueTypes.TubesheetMachiningTotalCost,
        QuoteLineItemValueTypes.Welding,
        QuoteLineItemValueTypes.HeatTreatCombinationGroup,
        QuoteLineItemValueTypes.BaseFlatteningThinningGroup,
        QuoteLineItemValueTypes.IsForging,
        QuoteLineItemValueTypes.ExplosiveItem,
        QuoteLineItemValueTypes.HeatTreatment
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseMetalPricingByProposalType,
      [
        QuoteLineItemValueTypes.BaseMetalPricing
      ]
    ],
    [
      QuoteLineItemValueTypes.CosmeticPrepCost,
      [
        QuoteLineItemValueTypes.TotalLaborCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.ExportPackagingCost,
      [
        QuoteLineItemValueTypes.TotalLaborCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.EdgeAllowanceGroup,
      [
        QuoteLineItemValueTypes.EdgeAllowance
      ]
    ],
    [
      QuoteLineItemValueTypes.BoosterAllowanceGroup,
      [
        QuoteLineItemValueTypes.BoosterAllowance
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CladMetal,
      [
        QuoteLineItemValueTypes.BoosterAllowanceGroup,
        QuoteLineItemValueTypes.CladMetalPricingByProposalType,
        QuoteLineItemValueTypes.CladOverhang,
        QuoteLineItemValueTypes.CladOverhangThreshold,
        QuoteLineItemValueTypes.CladThicknessAddition,
        QuoteLineItemValueTypes.CladWeight,
        QuoteLineItemValueTypes.CosmeticGrindingCladThicknessAddition,
        QuoteLineItemValueTypes.EdgeAllowanceGroup,
        QuoteLineItemValueTypes.TubesheetMachiningTotalCost,
        QuoteLineItemValueTypes.WeldingConsumableCost,
        QuoteLineItemValueTypes.HeatTreatCombinationGroup,
        QuoteLineItemValueTypes.CladFlatteningThinningGroup,
        QuoteLineItemValueTypes.HeatTreatment
      ]
    ],
    [
      QuoteLineItemValueTypes.CladOverhangThreshold,
      [
        QuoteLineItemValueTypes.CladOverhangThin,
      ]
    ],
    [
      QuoteLineItemValueTypes.CosmeticGrindingCladThicknessAddition,
      [
        QuoteLineItemValueTypes.CladTKForPricing,
      ]
    ],
    [
      QuoteLineItemValueTypes.CladMetalPricingByProposalType,
      [
        QuoteLineItemValueTypes.CladMetalPricing
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseMetalProposalType,
      [
        QuoteLineItemValueTypes.BaseMetalPricingByProposalType,
        QuoteLineItemValueTypes.LeadTime
      ]
    ],
    [
      QuoteLineItemValueTypes.CladMetalProposalType,
      [
        QuoteLineItemValueTypes.CladMetalPricingByProposalType,
        QuoteLineItemValueTypes.LeadTime
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CosmeticGrinding,
      [
        QuoteLineItemValueTypes.CosmeticGrindingCladThicknessAddition,
      ]
    ],
    [
      QuoteLineItemValueTypes.HeatTreatment,
      [
        QuoteLineItemValueTypes.HeatTreatmentConsumableCost,
        QuoteLineItemValueTypes.HeatTreatmentCost,
        QuoteLineItemValueTypes.StressReliefHT,
        QuoteLineItemValueTypes.AdditionalOptions
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CutMethod,
      [
        QuoteLineItemValueTypes.KerfAllowance,
        QuoteLineItemValueTypes.PostCladCuttingTolerance,
        QuoteLineItemValueTypes.CutMethodCost,
        QuoteLineItemValueTypes.ItemDescription
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.SizingQuantity,
      [
        QuoteLineItemValueTypes.CanRollingInboundShippingCost,
        QuoteLineItemValueTypes.CanRollingOutboundShippingCost,
        QuoteLineItemValueTypes.CladderMetalHTCostOfEvent,
        QuoteLineItemValueTypes.CladderMetalHTInboundShippingCost,
        QuoteLineItemValueTypes.CladderMetalHTOutboundShippingCost,
        QuoteLineItemValueTypes.CuttingInboundShippingCost,
        QuoteLineItemValueTypes.CuttingOutboundShippingCost,
        QuoteLineItemValueTypes.ExportPackagingCost,
        QuoteLineItemValueTypes.FlatteningInboundShippingCost,
        QuoteLineItemValueTypes.FlatteningLaborCost,
        QuoteLineItemValueTypes.FlatteningOutboundShippingCost,
        QuoteLineItemValueTypes.FreightOut,
        QuoteLineItemValueTypes.HeadQuantity,
        QuoteLineItemValueTypes.HeadForming,
        QuoteLineItemValueTypes.HeadFreight,
        QuoteLineItemValueTypes.InspectionCLInboundShippingCost,
        QuoteLineItemValueTypes.InspectionCLOutboundShippingCost,
        QuoteLineItemValueTypes.InspectionInboundShippingCost,
        QuoteLineItemValueTypes.InspectionOutboundShippingCost,
        QuoteLineItemValueTypes.MachiningInboundShippingCost,
        QuoteLineItemValueTypes.MachiningOutboundShippingCost,
        QuoteLineItemValueTypes.OSCost,
        QuoteLineItemValueTypes.PackagingInboundShippingCost,
        QuoteLineItemValueTypes.PackagingOutboundShippingCost,
        QuoteLineItemValueTypes.PostbondHTCostOfEvent,
        QuoteLineItemValueTypes.PostbondHTInboundShippingCost,
        QuoteLineItemValueTypes.PostbondHTOutboundShippingCost,
        QuoteLineItemValueTypes.ShipWeight,
        QuoteLineItemValueTypes.TestingCLInboundShippingCost,
        QuoteLineItemValueTypes.TestingCLOutboundShippingCost,
        QuoteLineItemValueTypes.TestingInboundShippingCost,
        QuoteLineItemValueTypes.TestingOutboundShippingCost,
        QuoteLineItemValueTypes.TubesheetMachiningManHours,
        QuoteLineItemValueTypes.WaterJetCuttingInboundShippingCost,
        QuoteLineItemValueTypes.WaterJetCuttingOutboundShippingCost,
        QuoteLineItemValueTypes.WeldingInboundShippingCost,
        QuoteLineItemValueTypes.WeldingOutboundShippingCost,
        QuoteLineItemValueTypes.XRayServicesInboundShippingCost,
        QuoteLineItemValueTypes.XRayServicesOutboundShippingCost,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.ShipWeight,
      [
        QuoteLineItemValueTypes.TotalFreightPerWeight,
        QuoteLineItemValueTypes.HeadFreightPerWeight
      ]
    ],
    [
      QuoteLineItemValueTypes.TotalFreightPerWeight,
      [
        QuoteLineItemValueTypes.FreightOut
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.Width,
      [
        QuoteLineItemValueTypes.CladWeight,
        QuoteLineItemValueTypes.BaseWeight,
        QuoteLineItemValueTypes.Dimensions,
        QuoteLineItemValueTypes.TubesheetMachiningManHours,
        QuoteLineItemValueTypes.BaseFlatteningThinningGroup,
        QuoteLineItemValueTypes.CladFlatteningThinningGroup,
        QuoteLineItemValueTypes.CutMethodCost,
        QuoteLineItemValueTypes.FinishedArea,
        QuoteLineItemValueTypes.SquareFinishedArea,
        QuoteLineItemValueTypes.HeatTreatment,
        QuoteLineItemValueTypes.ConsumableCostsToReconcile,
        QuoteLineItemValueTypes.ASItemDescription,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.SpecificationAdder,
      [
        QuoteLineItemValueTypes.NumberOfItemsLongThatFit,
        QuoteLineItemValueTypes.BaseWidth,
        QuoteLineItemValueTypes.BaseMetalLengthAdder
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseMetalLengthAdder,
      [
        QuoteLineItemValueTypes.BaseLength,
        QuoteLineItemValueTypes.NumberOfItemsLongThatFit
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CladWidth,
      [
        QuoteLineItemValueTypes.CladWeightPerPC,
        QuoteLineItemValueTypes.AreaCladder

      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.BaseWidth,
      [
        QuoteLineItemValueTypes.FlyerWidth,
        QuoteLineItemValueTypes.AnvilWidth,
        QuoteLineItemValueTypes.CladWidth,
        QuoteLineItemValueTypes.BaseWeightPerPC
      ]
    ],
    [
      QuoteLineItemValueTypes.CladWeight,
      [
        QuoteLineItemValueTypes.TotalWeightPerPC,
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseWeight,
      [
        QuoteLineItemValueTypes.TotalWeightPerPC,
      ]
    ],
    [
      QuoteLineItemValueTypes.AnvilWidth,
      [
        QuoteLineItemValueTypes.WeightAnvil,
        QuoteLineItemValueTypes.AnvilArea
      ]
    ],
    [
      QuoteLineItemValueTypes.CladLength,
      [
        QuoteLineItemValueTypes.CladWeightPerPC,
        QuoteLineItemValueTypes.AreaCladder,
        QuoteLineItemValueTypes.ConsumableCostsToReconcile
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.BaseLength,
      [
        QuoteLineItemValueTypes.BaseWeightPerPC,
        QuoteLineItemValueTypes.AreaBacker,
        QuoteLineItemValueTypes.FlyerLength,
        QuoteLineItemValueTypes.AnvilLength,
        QuoteLineItemValueTypes.CladLength,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CladWeightPerPC,
      [
        QuoteLineItemValueTypes.CladFreightPerWeight,
        QuoteLineItemValueTypes.PurchaseCladderPrice,
        QuoteLineItemValueTypes.HeatTreatmentConsumableCost,
        QuoteLineItemValueTypes.TestingCLInboundShippingCost,
        QuoteLineItemValueTypes.TestingCLOutboundShippingCost,
        QuoteLineItemValueTypes.CladderMetalHTCostOfEvent,
        QuoteLineItemValueTypes.CladderMetalHTInboundShippingCost,
        QuoteLineItemValueTypes.CladderMetalHTOutboundShippingCost,
        QuoteLineItemValueTypes.WeldingInboundShippingCost,
        QuoteLineItemValueTypes.WeldingOutboundShippingCost,
        QuoteLineItemValueTypes.XRayServicesInboundShippingCost,
        QuoteLineItemValueTypes.XRayServicesOutboundShippingCost,
        QuoteLineItemValueTypes.InspectionCLInboundShippingCost,
        QuoteLineItemValueTypes.InspectionCLOutboundShippingCost,
        QuoteLineItemValueTypes.TotalWeightPerPC
      ]
    ],
    [
      QuoteLineItemValueTypes.AreaCladder,
      [
        QuoteLineItemValueTypes.DetailArea,
        QuoteLineItemValueTypes.ExplosiveLoadWeight,
        QuoteLineItemValueTypes.GeneralLaborCost,
        QuoteLineItemValueTypes.SpecificationTestLaborHours,
        QuoteLineItemValueTypes.CosmeticPrepCost,
        QuoteLineItemValueTypes.OverallCost,
        QuoteLineItemValueTypes.AnvilRequirementsGroup
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.BaseWeightPerPC,
      [
        QuoteLineItemValueTypes.BaseFreightPerWeight,
        QuoteLineItemValueTypes.PurchaseBackerPrice,
        QuoteLineItemValueTypes.TestingBKInboundShippingCost,
        QuoteLineItemValueTypes.TestingBKOutboundShippingCost,
        QuoteLineItemValueTypes.BackerMetalHTCostOfEvent,
        QuoteLineItemValueTypes.BackerMetalHTInboundShippingCost,
        QuoteLineItemValueTypes.BackerMetalHTOutboundShippingCost,
        QuoteLineItemValueTypes.FlatteningBKInboundShippingCost,
        QuoteLineItemValueTypes.FlatteningBKOutboundShippingCost,
        QuoteLineItemValueTypes.InspectionBKInboundShippingCost,
        QuoteLineItemValueTypes.InspectionBKOutboundShippingCost,
        QuoteLineItemValueTypes.HeatTreatmentConsumableCost,
        QuoteLineItemValueTypes.TotalWeightPerPC
      ]
    ],
    [
      QuoteLineItemValueTypes.AreaBacker,
      [
        QuoteLineItemValueTypes.DetailArea
      ]
    ],
    [
      QuoteLineItemValueTypes.FlyerWidth,
      [
        QuoteLineItemValueTypes.FlyerArea
      ]
    ],
    [
      QuoteLineItemValueTypes.FlyerLength,
      [
        QuoteLineItemValueTypes.FlyerArea
      ]
    ],
    [
      QuoteLineItemValueTypes.CladFreightPerWeight,
      [
        QuoteLineItemValueTypes.CladFreight
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CladCost,
      [
        QuoteLineItemValueTypes.CladCostCustomerSupplied,
        QuoteLineItemValueTypes.CladPricePerWeight,
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseFreightPerWeight,
      [
        QuoteLineItemValueTypes.BaseFreight
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.Length,
      [
        QuoteLineItemValueTypes.BaseMetalLengthAdder,
        QuoteLineItemValueTypes.BaseWeight,
        QuoteLineItemValueTypes.CladWeight,
        QuoteLineItemValueTypes.Dimensions,
        QuoteLineItemValueTypes.PostCladCuttingTolerance,
        QuoteLineItemValueTypes.FinishedArea,
        QuoteLineItemValueTypes.SquareFinishedArea,
        QuoteLineItemValueTypes.MachiningNotes,
        QuoteLineItemValueTypes.ConsumableCostsToReconcile,
        QuoteLineItemValueTypes.ASItemDescription,
      ]
    ],
    [
      QuoteLineItemValueTypes.AnvilLength,
      [
        QuoteLineItemValueTypes.WeightAnvil,
        QuoteLineItemValueTypes.AnvilArea,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.FinishedCladTK,
      [
        QuoteLineItemValueTypes.CladThicknessAddition,
        QuoteLineItemValueTypes.TotalTK
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.EdgeAllowance,
      [
        QuoteLineItemValueTypes.BaseWidth,
        QuoteLineItemValueTypes.NumberOfItemsWideThatFit,
        QuoteLineItemValueTypes.BaseMetalLengthAdder
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.BoosterAllowance,
      [
        QuoteLineItemValueTypes.NumberOfItemsWideThatFit,
        QuoteLineItemValueTypes.SpecificationAdder
      ]
    ],
    [
      QuoteLineItemValueTypes.CladThicknessAddition,
      [
        QuoteLineItemValueTypes.CladTKForPricing,
        QuoteLineItemValueTypes.CladMin
      ]
    ],
    [
      QuoteLineItemValueTypes.CladTKForPricing,
      [
        QuoteLineItemValueTypes.CladMetalPricing
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.AnvilTK,
      [
        QuoteLineItemValueTypes.AnvilDimensionAdder,
        QuoteLineItemValueTypes.AnvilNeeded
      ]
    ],
    [
      QuoteLineItemValueTypes.CladMetalPricing,
      [
        QuoteLineItemValueTypes.CladFreightPerWeight,
        QuoteLineItemValueTypes.CladPricePerWeight,
        QuoteLineItemValueTypes.CladTK
      ]
    ],
    [
      QuoteLineItemValueTypes.AnvilEdgeAllowanceAddition,
      [
        QuoteLineItemValueTypes.NumberOfItemsLongThatFit,
        QuoteLineItemValueTypes.NumberOfItemsWideThatFit,
        QuoteLineItemValueTypes.BaseMetalLengthAdder
      ]
    ],
    [
      QuoteLineItemValueTypes.AnvilDimensionAdder,
      [
        QuoteLineItemValueTypes.AnvilLength,
        QuoteLineItemValueTypes.AnvilWidth
      ]
    ],
    [
      QuoteLineItemValueTypes.CladPricePerWeight,
      [
        QuoteLineItemValueTypes.PurchaseCladderPrice,
        QuoteLineItemValueTypes.WeldingConsumableCost
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CladTK,
      [
        QuoteLineItemValueTypes.CladOverhangThin,
        QuoteLineItemValueTypes.ExplosiveLoadGroups,
        QuoteLineItemValueTypes.BoosterAllowance,
        QuoteLineItemValueTypes.TotalRawTK,
        QuoteLineItemValueTypes.CladNom,
        QuoteLineItemValueTypes.EdgeAllowance,
        QuoteLineItemValueTypes.CladWeight,
        QuoteLineItemValueTypes.AnvilEdgeAllowanceRequirementsGroup
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CladOverhangThin,
      [
        QuoteLineItemValueTypes.NumberOfItemsWideThatFit
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CladOverhang,
      [
        QuoteLineItemValueTypes.NumberOfItemsWideThatFit
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.FinishedBaseTK,
      [
        QuoteLineItemValueTypes.BaseThicknessAddition,
        QuoteLineItemValueTypes.TotalTK
      ]
    ],
    [
      QuoteLineItemValueTypes.WeightAnvil,
      [
        QuoteLineItemValueTypes.Anvil
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseThicknessAddition,
      [
        QuoteLineItemValueTypes.BaseTKForPricing,
        QuoteLineItemValueTypes.BaseMin
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseTKForPricing,
      [
        QuoteLineItemValueTypes.BaseMetalPricing
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.BaseMetalPricing,
      [
        QuoteLineItemValueTypes.BasePricePerWeight,
        QuoteLineItemValueTypes.BaseTK
      ]
    ],
    [
      QuoteLineItemValueTypes.BasePricePerWeight,
      [
        QuoteLineItemValueTypes.PurchaseBackerPrice,
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseTK,
      [
        QuoteLineItemValueTypes.TotalRawTK,
        QuoteLineItemValueTypes.BaseNom,
        QuoteLineItemValueTypes.BaseWeight,
        QuoteLineItemValueTypes.HeatTreatment,
        QuoteLineItemValueTypes.AnvilEdgeAllowanceRequirementsGroup
      ]
    ],
    [
      QuoteLineItemValueTypes.CladFreight,
      [
        QuoteLineItemValueTypes.CladFreightCustomerSupplied,
        QuoteLineItemValueTypes.CladFreightPerWeight,
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseCost,
      [
        QuoteLineItemValueTypes.BaseCostCustomerSupplied,
        QuoteLineItemValueTypes.BasePricePerWeight,
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseFreight,
      [
        QuoteLineItemValueTypes.BaseFreightCustomerSupplied,
        QuoteLineItemValueTypes.BaseFreightPerWeight,
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.FreightOut,
      [
        QuoteLineItemValueTypes.TotalFreightPerWeight,
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.TotalWeightPerPC,
      [
        QuoteLineItemValueTypes.CanRollingInboundShippingCost,
        QuoteLineItemValueTypes.CanRollingOutboundShippingCost,
        QuoteLineItemValueTypes.CuttingInboundShippingCost,
        QuoteLineItemValueTypes.CuttingOutboundShippingCost,
        QuoteLineItemValueTypes.ExportPackagingCost,
        QuoteLineItemValueTypes.FlatteningInboundShippingCost,
        QuoteLineItemValueTypes.FlatteningOutboundShippingCost,
        QuoteLineItemValueTypes.InspectionInboundShippingCost,
        QuoteLineItemValueTypes.InspectionOutboundShippingCost,
        QuoteLineItemValueTypes.MachiningInboundShippingCost,
        QuoteLineItemValueTypes.MachiningOutboundShippingCost,
        QuoteLineItemValueTypes.PackagingInboundShippingCost,
        QuoteLineItemValueTypes.PackagingOutboundShippingCost,
        QuoteLineItemValueTypes.PostbondHTCostOfEvent,
        QuoteLineItemValueTypes.PostbondHTTotalCostOfEvent,
        QuoteLineItemValueTypes.PostbondHTInboundShippingCost,
        QuoteLineItemValueTypes.PostbondHTOutboundShippingCost,
        QuoteLineItemValueTypes.ShipWeight,
        QuoteLineItemValueTypes.TestingInboundShippingCost,
        QuoteLineItemValueTypes.TestingOutboundShippingCost,
        QuoteLineItemValueTypes.WaterJetCuttingInboundShippingCost,
        QuoteLineItemValueTypes.WaterJetCuttingOutboundShippingCost,
        QuoteLineItemValueTypes.OSCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.AnvilNeeded,
      [
        QuoteLineItemValueTypes.AnvilArea,
        QuoteLineItemValueTypes.QuantityAnvil,
      ]
    ],
    [
      QuoteLineItemValueTypes.FlyerNeeded,
      [
        QuoteLineItemValueTypes.FlyerArea,
        QuoteLineItemValueTypes.QuantityFlyer,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.Specification,
      [
        QuoteLineItemValueTypes.SpecificationAdder,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.BondMetalUT,
      [
        QuoteLineItemValueTypes.SpecificationAdder,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.PostCladTestingOptions,
      [
        QuoteLineItemValueTypes.SpecificationAdder,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.ExplosiveLoadGroups,
      [
        QuoteLineItemValueTypes.ExplosiveLoadWeight,
        QuoteLineItemValueTypes.ExplosiveItem
      ]
    ],
    [
      QuoteLineItemValueTypes.ExplosiveLoadWeight,
      [
        QuoteLineItemValueTypes.ExplosiveLoadCost
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.WeldingLength,
      [
        QuoteLineItemValueTypes.WeldingConsumableCost,
        QuoteLineItemValueTypes.XRayServicesCostOfEvent
      ]
    ],
    [
      QuoteLineItemValueTypes.WeldingConsumableCost,
      [
        QuoteLineItemValueTypes.Welding
      ]
    ],
    [
      QuoteLineItemValueTypes.HeatTreatmentConsumableCost,
      [
        QuoteLineItemValueTypes.ConsumableCost
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseMetalUT,
      [
        QuoteLineItemValueTypes.SpecificationTestLaborHours,
      ]
    ],
    [
      QuoteLineItemValueTypes.FerroxylTestPerformed,
      [
        QuoteLineItemValueTypes.SpecificationTestLaborHours,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.TotalTK,
      [
        QuoteLineItemValueTypes.PreHeatTimingGroup,
        QuoteLineItemValueTypes.PostCladCuttingTolerance,
        QuoteLineItemValueTypes.CladFlatteningThinningGroup,
        QuoteLineItemValueTypes.BaseFlatteningThinningGroup,
        QuoteLineItemValueTypes.ASItemDescription,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.ItemType,
      [
        QuoteLineItemValueTypes.Dimensions,
        QuoteLineItemValueTypes.TubesheetMachiningManHours,
        QuoteLineItemValueTypes.LeadTime,
        QuoteLineItemValueTypes.PostCladCuttingTolerance,
        QuoteLineItemValueTypes.FlatteningNotes,
        QuoteLineItemValueTypes.MachiningNotes,
        QuoteLineItemValueTypes.PostCladFlattening,
        QuoteLineItemValueTypes.BoosterAllowance,
        QuoteLineItemValueTypes.FinishedArea,
        QuoteLineItemValueTypes.BaseMetalPricing,
        QuoteLineItemValueTypes.HeatTreatment
      ]
    ],
    [
      QuoteLineItemValueTypes.TotalRawTK,
      [
        QuoteLineItemValueTypes.FlatteningLaborHours,
        QuoteLineItemValueTypes.KerfAllowance,
        QuoteLineItemValueTypes.CutMethodCost,
        QuoteLineItemValueTypes.ConsumableCostsToReconcile
      ]
    ],
    [
      QuoteLineItemValueTypes.FlatteningLaborHours,
      [
        QuoteLineItemValueTypes.FlatteningLaborCost,
        QuoteLineItemValueTypes.TotalManHours,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.Shape,
      [
        QuoteLineItemValueTypes.Dimensions,
        QuoteLineItemValueTypes.CutMethodCost,
        QuoteLineItemValueTypes.FinishedArea,
        QuoteLineItemValueTypes.SquareFinishedArea,
        QuoteLineItemValueTypes.CladWeight,
        QuoteLineItemValueTypes.BaseWeight,
        QuoteLineItemValueTypes.BoosterAllowance
      ]
    ],
    [
      QuoteLineItemValueTypes.RadiographyClad,
      [
        QuoteLineItemValueTypes.XRayServicesCostOfEvent
      ]
    ],
    [
      QuoteLineItemValueTypes.Anvil,
      [
        QuoteLineItemValueTypes.PurchaseAnvilPrice,
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.Welding,
      [
        QuoteLineItemValueTypes.TotalCost,
        QuoteLineItemValueTypes.XRayServicesDescriptionOfEvent,
        QuoteLineItemValueTypes.XRayServicesSort,
        QuoteLineItemValueTypes.RadiographyClad,
      ]
    ],
    [
      QuoteLineItemValueTypes.HeadForming,
      [
        QuoteLineItemValueTypes.PriceHead,
        QuoteLineItemValueTypes.HeadFormingCostPerHead,
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.HeadFreight,
      [
        QuoteLineItemValueTypes.HeadFreightPerWeight,
        QuoteLineItemValueTypes.HeadFreightCostToFormer,
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.OSCost,
      [
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.ExplosiveLoadCost,
      [
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.HeatTreatmentCost,
      [
        QuoteLineItemValueTypes.TotalManHours,
        QuoteLineItemValueTypes.TotalLaborCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.ConsumableCost,
      [
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.SpecificationTestLaborHours,
      [
        QuoteLineItemValueTypes.SpecificationTestCost,
        QuoteLineItemValueTypes.TotalManHours
      ]
    ],
    [
      QuoteLineItemValueTypes.SpecificationTestCost,
      [
        QuoteLineItemValueTypes.TotalLaborCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.CutMethodCost,
      [
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.TotalLaborCost,
      [
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.GeneralLaborCost,
      [
        QuoteLineItemValueTypes.TotalLaborCost,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.ConsumableCostsToReconcile,
      [
        QuoteLineItemValueTypes.ConsumableCost
      ]
    ],
    [
      QuoteLineItemValueTypes.OverallCost,
      [
        QuoteLineItemValueTypes.ConsumableCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TotalCost,
      [
        QuoteLineItemValueTypes.UnitCost
      ]
    ],
    [
      QuoteLineItemValueTypes.ContPercentage,
      [
        QuoteLineItemValueTypes.AdjPrice,
        QuoteLineItemValueTypes.ContPerSQM
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.AgentComRate,
      [
        QuoteLineItemValueTypes.AdjPrice,
      ]
    ],
    [
      //Refactored
      QuoteLineItemValueTypes.AdjPrice,
      [
        QuoteLineItemValueTypes.ContPercentage,
        QuoteLineItemValueTypes.TotalAdjPrice
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.FinishedArea,
      [
        QuoteLineItemValueTypes.AreaSQM,
        QuoteLineItemValueTypes.UnitCost
      ]
    ],
    [
      QuoteLineItemValueTypes.FlatteningLaborCost,
      [
        QuoteLineItemValueTypes.TotalLaborCost
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.BaseType,
      [
        QuoteLineItemValueTypes.BaseFlatteningThinningValue
      ]
    ],
    [
      QuoteLineItemValueTypes.CladType,
      [
        QuoteLineItemValueTypes.CladMin,
        QuoteLineItemValueTypes.CladFlatteningThinningValue
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingBKCostOfEvent,
      [
        QuoteLineItemValueTypes.TestingBKTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingBKTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.TestingBKTotalCost,
        QuoteLineItemValueTypes.TestingBKInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingBKTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.TestingBKTotalCost,
        QuoteLineItemValueTypes.TestingBKOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingBKTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingCLCostOfEvent,
      [
        QuoteLineItemValueTypes.TestingCLTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingCLTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.TestingCLTotalCost,
        QuoteLineItemValueTypes.TestingCLInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingCLTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.TestingCLTotalCost,
        QuoteLineItemValueTypes.TestingCLOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingCLTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingCostOfEvent,
      [
        QuoteLineItemValueTypes.TestingTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.TestingTotalCost,
        QuoteLineItemValueTypes.TestingInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.TestingTotalCost,
        QuoteLineItemValueTypes.TestingOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TestingTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.BackerMetalHTTotalCostOfEvent,
      [
        QuoteLineItemValueTypes.BackerMetalHTTotalCost,
        QuoteLineItemValueTypes.BackerMetalHTCostOfEvent,
      ]
    ],
    [
      QuoteLineItemValueTypes.BackerMetalHTTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.BackerMetalHTTotalCost,
        QuoteLineItemValueTypes.BackerMetalHTInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.BackerMetalHTTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.BackerMetalHTTotalCost,
        QuoteLineItemValueTypes.BackerMetalHTOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.BackerMetalHTTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.CladderMetalHTTotalCostOfEvent,
      [
        QuoteLineItemValueTypes.CladderMetalHTTotalCost,
        QuoteLineItemValueTypes.CladderMetalHTCostOfEvent,
      ]
    ],
    [
      QuoteLineItemValueTypes.CladderMetalHTTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.CladderMetalHTTotalCost,
        QuoteLineItemValueTypes.CladderMetalHTInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.CladderMetalHTTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.CladderMetalHTTotalCost,
        QuoteLineItemValueTypes.CladderMetalHTOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.CladderMetalHTTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.PostbondHTTotalCostOfEvent,
      [
        QuoteLineItemValueTypes.PostbondHTTotalCost,
        QuoteLineItemValueTypes.PostbondHTCostOfEvent
      ]
    ],
    [
      QuoteLineItemValueTypes.PostbondHTTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.PostbondHTTotalCost,
        QuoteLineItemValueTypes.PostbondHTInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.PostbondHTTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.PostbondHTTotalCost,
        QuoteLineItemValueTypes.PostbondHTOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.PostbondHTTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.WeldingCostOfEvent,
      [
        QuoteLineItemValueTypes.WeldingTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.WeldingTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.WeldingTotalCost,
        QuoteLineItemValueTypes.WeldingInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.WeldingTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.WeldingTotalCost,
        QuoteLineItemValueTypes.WeldingOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.WeldingTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.XRayServicesCostOfEvent,
      [
        QuoteLineItemValueTypes.XRayServicesTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.XRayServicesTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.XRayServicesTotalCost,
        QuoteLineItemValueTypes.XRayServicesInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.XRayServicesTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.XRayServicesTotalCost,
        QuoteLineItemValueTypes.XRayServicesOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.XRayServicesTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.FlatteningBKCostOfEvent,
      [
        QuoteLineItemValueTypes.FlatteningBKTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.FlatteningBKTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.FlatteningBKTotalCost,
        QuoteLineItemValueTypes.FlatteningBKInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.FlatteningBKTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.FlatteningBKTotalCost,
        QuoteLineItemValueTypes.FlatteningBKOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.FlatteningBKTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.CuttingCostOfEvent,
      [
        QuoteLineItemValueTypes.CuttingTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.CuttingTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.CuttingTotalCost,
        QuoteLineItemValueTypes.CuttingInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.CuttingTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.CuttingTotalCost,
        QuoteLineItemValueTypes.CuttingOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.CuttingTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.WaterJetCuttingCostOfEvent,
      [
        QuoteLineItemValueTypes.WaterJetCuttingTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.WaterJetCuttingTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.WaterJetCuttingTotalCost,
        QuoteLineItemValueTypes.WaterJetCuttingInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.WaterJetCuttingTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.WaterJetCuttingTotalCost,
        QuoteLineItemValueTypes.WaterJetCuttingOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.WaterJetCuttingTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.FlatteningCostOfEvent,
      [
        QuoteLineItemValueTypes.FlatteningTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.FlatteningTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.FlatteningTotalCost,
        QuoteLineItemValueTypes.FlatteningInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.FlatteningTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.FlatteningTotalCost,
        QuoteLineItemValueTypes.FlatteningOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.FlatteningTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.MachiningCostOfEvent,
      [
        QuoteLineItemValueTypes.MachiningTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.MachiningTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.MachiningTotalCost,
        QuoteLineItemValueTypes.MachiningInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.MachiningTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.MachiningTotalCost,
        QuoteLineItemValueTypes.MachiningOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.MachiningTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionBKCostOfEvent,
      [
        QuoteLineItemValueTypes.InspectionBKTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionBKTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.InspectionBKTotalCost,
        QuoteLineItemValueTypes.InspectionBKInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionBKTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.InspectionBKTotalCost,
        QuoteLineItemValueTypes.InspectionBKOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionBKTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionCLCostOfEvent,
      [
        QuoteLineItemValueTypes.InspectionCLTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionCLTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.InspectionCLTotalCost,
        QuoteLineItemValueTypes.InspectionCLInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionCLTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.InspectionCLTotalCost,
        QuoteLineItemValueTypes.InspectionCLOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionCLTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionCostOfEvent,
      [
        QuoteLineItemValueTypes.InspectionTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.InspectionTotalCost,
        QuoteLineItemValueTypes.InspectionInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.InspectionTotalCost,
        QuoteLineItemValueTypes.InspectionOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.InspectionTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.PackagingCostOfEvent,
      [
        QuoteLineItemValueTypes.PackagingTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.PackagingTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.PackagingTotalCost,
        QuoteLineItemValueTypes.PackagingInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.PackagingTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.PackagingTotalCost,
        QuoteLineItemValueTypes.PackagingOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.PackagingTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.CanRollingCostOfEvent,
      [
        QuoteLineItemValueTypes.CanRollingTotalCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.CanRollingTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.CanRollingTotalCost,
        QuoteLineItemValueTypes.CanRollingInboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.CanRollingTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.CanRollingTotalCost,
        QuoteLineItemValueTypes.CanRollingOutboundShippingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.CanRollingTotalCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.HeadFormingCostPerHead,
      [
        QuoteLineItemValueTypes.PriceHead
      ]
    ],
    [
      QuoteLineItemValueTypes.HeadFormingTotalInboundShippingCost,
      [
        QuoteLineItemValueTypes.HeadFormingSubcontractingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.HeadFormingTotalOutboundShippingCost,
      [
        QuoteLineItemValueTypes.HeadFormingSubcontractingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.HeadFormingSubcontractingCost,
      [
        QuoteLineItemValueTypes.OSCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TubesheetMachining,
      [
        QuoteLineItemValueTypes.TubesheetMachiningManHours
      ]
    ],
    [
      QuoteLineItemValueTypes.TubesheetMachiningTotalCost,
      [
        QuoteLineItemValueTypes.TotalLaborCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TubesheetMachiningManHours,
      [
        QuoteLineItemValueTypes.TubesheetMachiningTotalCost,
        QuoteLineItemValueTypes.TotalManHours,
      ]
    ],
    [
      QuoteLineItemValueTypes.HeatTreatCombinationGroup,
      [
        QuoteLineItemValueTypes.PreHeatTimingGroup,
        QuoteLineItemValueTypes.NCInhouseStressReliefTemp
      ]
    ],
    [
      QuoteLineItemValueTypes.PreHeatTimingGroup,
      [
        QuoteLineItemValueTypes.NCInhouseStressReliefTimeMin,
        QuoteLineItemValueTypes.NCInHouseStressReliefTimeMax,
      ]
    ],
    [
      QuoteLineItemValueTypes.StressReliefHT,
      [
        QuoteLineItemValueTypes.NCInhouseStressReliefTimeMin,
        QuoteLineItemValueTypes.NCInHouseStressReliefTimeMax,
        QuoteLineItemValueTypes.NCInhouseStressReliefTemp,
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseFlatteningThinningGroup,
      [
        QuoteLineItemValueTypes.BaseFlatteningThinningValue,
      ]
    ],
    [
      QuoteLineItemValueTypes.CladFlatteningThinningGroup,
      [
        QuoteLineItemValueTypes.CladFlatteningThinningValue,
      ]
    ],
    [
      QuoteLineItemValueTypes.BaseFlatteningThinningValue,
      [
        QuoteLineItemValueTypes.BaseTKForPricing,
        QuoteLineItemValueTypes.FlatteningNotes
      ]
    ],
    [
      QuoteLineItemValueTypes.CladFlatteningThinningValue,
      [
        QuoteLineItemValueTypes.CladTKForPricing,
      ]
    ],
    [
      QuoteLineItemValueTypes.Certificate,
      [
        QuoteLineItemValueTypes.CertificateCost,
      ]
    ],
    [
      QuoteLineItemValueTypes.CertificateCost,
      [
        QuoteLineItemValueTypes.TotalCost,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.Clads,
      [
        QuoteLineItemValueTypes.BaseCost,
        QuoteLineItemValueTypes.CladCost,
        QuoteLineItemValueTypes.Anvil,
        QuoteLineItemValueTypes.ExtraCladRequired,
        QuoteLineItemValueTypes.ExplosiveLoadCost,
        QuoteLineItemValueTypes.OverallCost,
        QuoteLineItemValueTypes.HeatTreatmentConsumableCost,
        QuoteLineItemValueTypes.HeatTreatmentCost,
        QuoteLineItemValueTypes.CutMethodCost,
        QuoteLineItemValueTypes.CladFreight,
        QuoteLineItemValueTypes.GeneralLaborCost,
        QuoteLineItemValueTypes.QuantityAnvil,
        QuoteLineItemValueTypes.QuantityFlyer,
        QuoteLineItemValueTypes.SpecificationTestCost,
        QuoteLineItemValueTypes.CosmeticPrepCost,
        QuoteLineItemValueTypes.ConsumableCostsToReconcile,
        QuoteLineItemValueTypes.Welding
      ]
    ],
    [
      QuoteLineItemValueTypes.PriceHead,
      [
        QuoteLineItemValueTypes.HeadForming,
      ]
    ],
    [
      QuoteLineItemValueTypes.HeadFreightPerWeight,
      [
        QuoteLineItemValueTypes.HeadFreightCostToFormer,
        QuoteLineItemValueTypes.HeadFreight,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.PlateMethod,
      [
        QuoteLineItemValueTypes.CladOverhangThin,
        QuoteLineItemValueTypes.BoosterAllowance
      ]
    ],
    [
      QuoteLineItemValueTypes.PlateLengthSupplyToFormer,
      [
        QuoteLineItemValueTypes.Length,
      ]
    ],
    [
      QuoteLineItemValueTypes.PlateWidthSupplyToFormer,
      [
        QuoteLineItemValueTypes.Width,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.KerfAllowance,
      [
        QuoteLineItemValueTypes.BaseWidth,
        QuoteLineItemValueTypes.NumberOfItemsWideThatFit,
        QuoteLineItemValueTypes.BaseMetalLengthAdder
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.Wide,
      [
        QuoteLineItemValueTypes.BaseWidth,
        QuoteLineItemValueTypes.Long
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.Long,
      [
        QuoteLineItemValueTypes.BaseLength,
        QuoteLineItemValueTypes.DetailQuantity,
        QuoteLineItemValueTypes.Clads
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.NumberOfItemsWideThatFit,
      [
        QuoteLineItemValueTypes.Wide,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.NumberOfItemsLongThatFit,
      [
        QuoteLineItemValueTypes.MaxQuantityOnClad
      ]
    ],
    [
      QuoteLineItemValueTypes.MaxQuantityOnClad,
      [
        QuoteLineItemValueTypes.Long
      ]
    ],
    [
      QuoteLineItemValueTypes.ExtraCladRequired,
      [
        QuoteLineItemValueTypes.QuantityForLeftoverCalculation,
        QuoteLineItemValueTypes.SizingQuantity
      ]
    ],
    [
      QuoteLineItemValueTypes.DetailQuantity,
      [
        QuoteLineItemValueTypes.ExtraCladRequired,
        QuoteLineItemValueTypes.CutMethodCost,
        QuoteLineItemValueTypes.ConsumableCostsToReconcile
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.QuantityForLeftoverCalculation,
      [
        QuoteLineItemValueTypes.Wide
      ]
    ],
    [
      QuoteLineItemValueTypes.HeadDiameter,
      [
        QuoteLineItemValueTypes.Length,
        QuoteLineItemValueTypes.Width,
      ]
    ],
    [
      QuoteLineItemValueTypes.IDorOD,
      [
        QuoteLineItemValueTypes.Dimensions,
      ]
    ],
    [
      QuoteLineItemValueTypes.HeadFormingType,
      [
        QuoteLineItemValueTypes.Dimensions,
      ]
    ],
    [
      QuoteLineItemValueTypes.CreateTwoPieceHead,
      [
        QuoteLineItemValueTypes.BaseWidth,
        QuoteLineItemValueTypes.Clads,
        QuoteLineItemValueTypes.BaseMetalLengthAdder
      ]
    ],
    [
      //Refactored
      QuoteLineItemValueTypes.Pieces,
      [
        QuoteLineItemValueTypes.NumberOfItemsWideThatFit,
        QuoteLineItemValueTypes.Wide,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CustomerProvidedBase,
      [
        QuoteLineItemValueTypes.PurchaseBackerPrice,
        QuoteLineItemValueTypes.BaseFreight,
        QuoteLineItemValueTypes.RawMaterialVendorBacker
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.CustomerProvidedClad,
      [
        QuoteLineItemValueTypes.PurchaseCladderPrice,
        QuoteLineItemValueTypes.CladFreight,
        QuoteLineItemValueTypes.RawMaterialVendorCladder
      ]
    ],
    [
      QuoteLineItemValueTypes.UnitCost,
      [
        QuoteLineItemValueTypes.AdjPrice,
        QuoteLineItemValueTypes.ContPercentage,
      ]
    ],
    [
      QuoteLineItemValueTypes.HeadFreightZone,
      [
        QuoteLineItemValueTypes.HeadFreightPerWeight
      ]
    ],
    [
      QuoteLineItemValueTypes.CylinderType,
      [
        QuoteLineItemValueTypes.ItemDescription
      ]
    ],
    [
      QuoteLineItemValueTypes.CylinderLength,
      [
        QuoteLineItemValueTypes.Dimensions
      ]
    ],
    [
      QuoteLineItemValueTypes.CylinderIDorOD,
      [
        QuoteLineItemValueTypes.Dimensions,
      ]
    ],
    [
      // Refactored
      QuoteLineItemValueTypes.BlankSize,
      [
        QuoteLineItemValueTypes.FinishedArea,
        QuoteLineItemValueTypes.BoosterAllowance,
        QuoteLineItemValueTypes.BaseMetalPricing,
        QuoteLineItemValueTypes.Clads,
        QuoteLineItemValueTypes.ConsumableCostsToReconcile
      ]
    ],
    [
      QuoteLineItemValueTypes.IsForging,
      [
        QuoteLineItemValueTypes.CladOverhang,
        QuoteLineItemValueTypes.BaseMetalLengthAdder
      ]
    ],
    [
      QuoteLineItemValueTypes.TotalFinishedGoodArea,
      [
        QuoteLineItemValueTypes.UnitCost
      ]
    ],
    [
      QuoteLineItemValueTypes.PurchaseCladderPrice,
      [
        QuoteLineItemValueTypes.CladCost
      ]
    ],
    [
      QuoteLineItemValueTypes.PurchaseBackerPrice,
      [
        QuoteLineItemValueTypes.BaseCost
      ]
    ],
    [
      QuoteLineItemValueTypes.TotalAdjPrice,
      [
        QuoteLineItemValueTypes.PricePerWeight,
      ]
    ],
    [
      QuoteLineItemValueTypes.SquareFinishedArea,
      [
        QuoteLineItemValueTypes.FlatteningLaborHours,
        QuoteLineItemValueTypes.AnvilEdgeAllowanceRequirementsGroup
      ]
    ],
    [
      QuoteLineItemValueTypes.Dimensions,
      [
        QuoteLineItemValueTypes.ItemDescription
      ]
    ],
    [
      QuoteLineItemValueTypes.ExportPackaging,
      [
        QuoteLineItemValueTypes.ExportPackagingCost
      ]
    ],
    [
      QuoteLineItemValueTypes.NCInhouseStressReliefTimeMin,
      [
        QuoteLineItemValueTypes.NCInhouseStressReliefDetails,
        QuoteLineItemValueTypes.SimulateHeatTreatmentHeadForming
      ]
    ],
    [
      QuoteLineItemValueTypes.NCInHouseStressReliefTimeMax,
      [
        QuoteLineItemValueTypes.NCInhouseStressReliefDetails,
        QuoteLineItemValueTypes.SimulateHeatTreatmentHeadForming
      ]
    ],
    [
      QuoteLineItemValueTypes.NCInhouseStressReliefTemp,
      [
        QuoteLineItemValueTypes.NCInhouseStressReliefDetails,
        QuoteLineItemValueTypes.SimulateHeatTreatmentHeadForming
      ]
    ],
    [
      QuoteLineItemValueTypes.AdditionalOptions,
      [
        QuoteLineItemValueTypes.HeatTreatment
      ]
    ],
    [
      QuoteLineItemValueTypes.AnvilRequirementsGroup,
      [
        QuoteLineItemValueTypes.AnvilTK,
      ]
    ],
    [
      QuoteLineItemValueTypes.AnvilEdgeAllowanceRequirementsGroup,
      [
        QuoteLineItemValueTypes.AnvilEdgeAllowanceAddition,
      ]
    ]
  ]);
