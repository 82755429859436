import React, { RefObject } from 'react';
import { IonButton, IonCheckbox, IonCol, IonGrid, IonIcon, IonImg, IonInput, IonLabel, IonRow } from '@ionic/react';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import deleteIcon from '@assets/icon/round-cancel-icon.svg';
import errorIcon from '@assets/icon/error.svg';
import './add-documents-popover.scss';
import { useDocuments } from '@services/documents/documents-service';
import DocumentInfo from '@interfaces/document-info';
import { documentTypes, UploadDocumentsColumnHeadings } from '@constants';
import Select, { MultiValue } from 'react-select';
import classNames from 'classnames';
import loadingSpinner from '@assets/icon/spinner_button_white.png';
import MultiselectDropdown from '@components/multiselect-dropdown/multiselect-dropdown';
import carrotIcon from '@assets/icon/carrot_field.svg';
import carrotIconWhite from '@assets/icon/carrot_field_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { Buffer } from 'buffer';
import Dropzone from 'react-dropzone';
import { cloudUploadOutline } from 'ionicons/icons';

const AddDocumentsPopover = (props) => {
  const documents = useDocuments();
  const { darkMode } = useDarkMode();

  const [documentsToUpload, setDocumentsToUpload] = React.useState<File[]>([]);
  const [fileInputReference] = React.useState<RefObject<HTMLInputElement>>(React.createRef());
  const [errorText, setErrorText] = React.useState(undefined);
  const [noDocumentsSelected, setNoDocumentsSelected] = React.useState(false);
  const [documentsWithNoType, setDocumentsWithNoType] = React.useState([]);
  const [otherDocumentsWithNoName, setOtherDocumentsWithNoName] = React.useState([]);
  const [documentTypeMap, setDocumentTypeMap] = React.useState(new Map<string, string>());
  const [documentItemsMap, setDocumentItemsMap] = React.useState(new Map<string, MultiValue<any>>());
  const [documentNameMap, setDocumentNameMap] = React.useState(new Map<string, string>());
  const [documentVersionFlagMap, setDocumentVersionFlagMap] = React.useState(new Map<string, boolean>());
  const [uploading, setUploading] = React.useState(false);
  const [headings] = React.useState(UploadDocumentsColumnHeadings);

  const dismissModal = (payload = {}) => {
    props.setShowPopover({ showPopover: false, event: undefined, payload});
  };

  // Handler for when files are changed while selecting documents
  const onFilesChanged = (event) => {
    let updatedDocumentsToUpload = [...documentsToUpload];
    let files;

      try {
        files = [...event.target.files];
      } catch {
        files = event;
      }

      files.forEach(file => {
      if(updatedDocumentsToUpload.length + 1 <= 6) {
        updatedDocumentsToUpload = updatedDocumentsToUpload.filter(document => document.name !== file.name);
        updatedDocumentsToUpload.push(file);
      } else {
        setErrorText('Maximum number of documents selected.');
      }
    });

    setDocumentsToUpload(updatedDocumentsToUpload);
  };

  // Clicks the actual file input element when the select documents button is clicked
  const onFileUpload = () => {
    fileInputReference.current.click();
  };

  // Removes the document from the documents to upload
  const removeSelectedDocument = (documentToRemove: File) => {
    setErrorText(undefined);
    setDocumentsToUpload(documentsToUpload.filter(document => document.name !== documentToRemove.name));

    const updatedDocumentItems = new Map(documentItemsMap.entries());
    updatedDocumentItems.delete(documentToRemove.name);
    setDocumentItemsMap(updatedDocumentItems);

    const updatedDocumentTypeMap = new Map(documentTypeMap.entries());
    updatedDocumentTypeMap.delete(documentToRemove.name);
    setDocumentTypeMap(updatedDocumentTypeMap);

    const updatedDocumentNameMap = new Map(documentNameMap.entries());
    updatedDocumentNameMap.delete(documentToRemove.name);
    setDocumentNameMap(updatedDocumentNameMap);

    const updatedDocumentVersionFlagMap = new Map(documentVersionFlagMap.entries());
    updatedDocumentVersionFlagMap.delete(documentToRemove.name);
    setDocumentVersionFlagMap(updatedDocumentVersionFlagMap);
  };

  // Returns all documents with 'Other' type
  const otherDocuments = () => {
    return documentsToUpload.filter(document => documentTypeMap.get(document.name) === 'Other');
  };

  // Returns true if all documents with 'Other' type have names
  const requiredDocumentNamesExist = () => {
    return otherDocuments()
      .every(document => !!documentNameMap.get(document.name));
  };

  // Uploads the selected documents unless a document doesn't have a type selected
  const uploadSelectedDocuments = async () => {
    if(!documentsToUpload.length) {
      setNoDocumentsSelected(true);
    } else {
      if(requiredDocumentNamesExist()) {
        setOtherDocumentsWithNoName([]);

        if(documentsToUpload.every(document => documentTypeMap.has(document.name))) {
          setUploading(true);
          setDocumentsWithNoType([]);

          await Promise.all(documentsToUpload.map(async (document) => {
            const fileExtension = document.name.split('.').pop();

            const arrayBuffer = await document.arrayBuffer();

            const documentLineNumberList = documentItemsMap.get(document.name)
              ?.filter(item => item.parent)
              .map(item => item.value).join(',');

            const documentInfo: DocumentInfo = {
              document: Buffer.from(arrayBuffer).toString('base64'),
              documentPurpose: documentTypeMap.get(document.name),
              fileExtension,
              salesOrderNumber: props.salesOrderNumber,
              poNumber: props.poNumber,
              salesOrderItemList: documentLineNumberList,
              name: documentNameMap.get(document.name),
              visibility: 'Customer-Facing',
              incrementVersion: documentVersionFlagMap.get(document.name) || false,
              invoiceNumber: props.invoiceNumber || ''
            };

            await documents.uploadDropboxDocument(documentInfo);
          }));

          setUploading(false);
          props.setDocumentsAdded(true);
          dismissModal({ added: true });
        } else {
          let updatedDocumentsWithNoType = [...documentsWithNoType];

          documentsToUpload.forEach(documentToUpload => {
            if(!documentTypeMap.has(documentToUpload.name)) {
              if(!updatedDocumentsWithNoType.includes(documentToUpload.name)) {
                updatedDocumentsWithNoType.push(documentToUpload.name);
              }
            } else {
              updatedDocumentsWithNoType = updatedDocumentsWithNoType.filter(documentWithNoType => documentWithNoType !== documentToUpload.name);
            }
          });

          setDocumentsWithNoType(updatedDocumentsWithNoType);
        }
      } else {
        let updatedOtherDocumentsWithNoName = [...otherDocumentsWithNoName];

        otherDocuments().forEach(otherDocument => {
          if(!documentNameMap.get(otherDocument.name)) {
            if(!updatedOtherDocumentsWithNoName.includes(otherDocument.name)) {
              updatedOtherDocumentsWithNoName.push(otherDocument.name);
            }
          } else {
            updatedOtherDocumentsWithNoName = updatedOtherDocumentsWithNoName.filter(otherDocumentWithNoName => otherDocumentWithNoName !== otherDocument.name);
          }
        });

        setOtherDocumentsWithNoName(updatedOtherDocumentsWithNoName);
      }

      setNoDocumentsSelected(false);
    }
  };

  const Document = (props) => {
    const [selectedDocumentType, setSelectedDocumentType] = React.useState(documentTypeMap.has(props.document.name) ? { label: documentTypeMap.get(props.document.name) } : (!props.invoiceDocument ? null : { label: 'Invoice' }));
    const [selectedItems, setSelectedItems] = React.useState<MultiValue<string>>(documentItemsMap.has(props.document.name) ? documentItemsMap.get(props.document.name) : null);
    const [documentName, setDocumentName] = React.useState(documentNameMap.has(props.document.name) ? documentNameMap.get(props.document.name) : null);
    const [documentVersionFlag, setDocumentVersionFlag] = React.useState(documentVersionFlagMap.has(props.document.name) ? documentVersionFlagMap.get(props.document.name) : null);

    React.useEffect(() => {
      if(selectedDocumentType && documentTypeMap.get(props.document.name) !== selectedDocumentType.label) {
        const updatedDocumentTypeMap = new Map(documentTypeMap.entries());
        updatedDocumentTypeMap.set(props.document.name, selectedDocumentType?.label);
        setDocumentTypeMap(updatedDocumentTypeMap);
      }
    }, [selectedDocumentType]);

    React.useEffect(() => {
      if(documentItemsMap.get(props.document.name) !== selectedItems) {
        const updatedDocumentItems = new Map(documentItemsMap.entries());
        updatedDocumentItems.set(props.document.name, selectedItems);
        setDocumentItemsMap(updatedDocumentItems);
      }
    }, [selectedItems]);

    React.useEffect(() => {
      if(documentNameMap.get(props.document.name) !== documentName) {
        const updatedDocumentNameMap = new Map(documentNameMap.entries());
        updatedDocumentNameMap.set(props.document.name, documentName);
        setDocumentNameMap(updatedDocumentNameMap);
      }
    }, [documentName]);

    React.useEffect(() => {
      if(documentVersionFlagMap.get(props.document.name) !== documentVersionFlag) {
        const updatedDocumentVersionFlagMap = new Map(documentVersionFlagMap.entries());
        updatedDocumentVersionFlagMap.set(props.document.name, documentVersionFlag);
        setDocumentVersionFlagMap(updatedDocumentVersionFlagMap);
      }
    }, [documentVersionFlag]);

    // Returns whether the document popover was opened on the order level or not
    const orderLevel = () => {
      return Array.from(props.linesMap?.keys() || []).length > 1;
    };

    // Returns whether the 'other' document type is selected for the document
    const otherDocumentTypeSelected = () => {
      return documentTypeMap.get(props.document.name) === 'Other';
    };

    const itemOptions: { value: string, label: string, parent?: string }[] = orderLevel() ? [{ value: '*', label: 'All Items' }] : [];
    Array.from(props.linesMap?.keys() || []).forEach((lineNumber: string) => {
      itemOptions.push({ value: lineNumber, label: lineNumber });

      props.linesMap.get(lineNumber).forEach((lineItemDetailNumber) => {
        itemOptions.push({ value: lineItemDetailNumber, label: lineItemDetailNumber, parent: lineNumber });
      });
    });

    const itemsDropdownStyle = {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? '#cd202c'
          : darkMode ? '#1A1818' : '#ffffff',
        color: state.isFocused
          ? '#ffffff'
          : (darkMode ? '#ececec': '#8b8d8e'),
        ':active': {
          ...provided[':active'],
          backgroundColor: darkMode ? '#8b8d8e': '#ececec'
        },
        cursor: 'pointer',
        display: 'flex',
        height: '23px',
        padding: '6px 6px',
        '& input': {
          marginLeft: state.data.parent ? '19px' : 'unset',
        },
        '& label': {
          marginTop: '1px',
          marginLeft: '6px'
        },
      }),
      control: (provided) => ({
        ...provided,
        height: 26,
        flexWrap: 'unset',
        boxShadow: 'unset',
        borderColor: '#c9cac8 !important',
        borderRadius: '0px',
        backgroundColor: darkMode ? '#1A1818' : '#ffffff',
        cursor: 'pointer',
      }),
      placeholder: (provided) => ({
        ...provided,
        color: darkMode ? '#ffffff' : '#1A1818'
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: '0px',
        marginTop: '0px',
        '> div': {
          paddingTop: 'unset',
          paddingBottom: 'unset',
        },
        boxShadow: darkMode ?
          'rgb(256 256 256 / 10%) 0px 0px 0px 1px, rgb(256 256 256 / 10%) 0px 4px 11px' :
          'rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px'
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: !state.hasValue ? 'grid' : 'initial'
      }),
      menuList: (provided) => ({
        ...provided,
        '::-webkit-scrollbar': {
          width: '4px'
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#d8d8d8'
        },
        '::-webkit-scrollbar-track-piece': {
          background: darkMode ? '#1A1818' : '#ffffff'
        }
      })
    };

    const documentTypeOptions = documentTypes.map((label) => {
      return { label };
    });

    const documentTypeDropdownStyle = {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? (darkMode ? '#8b8d8e' : '#ececec')
          : (darkMode ? '#1A1818' : '#ffffff'),
        color: darkMode ? '#ffffff' : '#1A1818',
        ':active': {
          ...provided[':active'],
          backgroundColor: (darkMode ? '#8b8d8e': '#ececec')
        },
        cursor: 'pointer'
      }),
      control: (provided) => ({
        ...provided,
        height: 26,
        flexWrap: 'unset',
        boxShadow: 'unset',
        borderColor: '#c9cac8 !important',
        borderRadius: '0px',
        backgroundColor: documentsWithNoType.includes(props.document.name) ? '#ffc5ba' : (darkMode ? '#1A1818' : '#ffffff'),
        cursor: 'pointer'
      }),
      placeholder: (provided) => ({
        ...provided,
        color: documentsWithNoType.includes(props.document.name) ? '#e84c3d': (darkMode ? '#ffffff' : '#1A1818')
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: '0px',
        '> div': {
          paddingTop: 'unset',
          paddingBottom: 'unset',
        },
        boxShadow: darkMode ?
          'rgb(256 256 256 / 10%) 0px 0px 0px 1px, rgb(256 256 256 / 10%) 0px 4px 11px' :
          'rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px'
      }),
      singleValue: (provided) => ({
        ...provided,
        borderRadius: '0px',
        color: darkMode ? '#ffffff' : '#1A1818',
      }),
    };

    const DropdownIndicator = () => {
      return (
        <IonIcon class='dropdown-indicator' icon={!darkMode ? carrotIcon : carrotIconWhite}></IonIcon>
      );
    };

    const DocumentName = () => {
      let updatedDocumentName;
      const specialCharsRegex = /^[^\\/:*?"<>|[\]]+$/;

      return (!otherDocumentTypeSelected() ?
        <IonLabel class='document-label'>{props.document.name}</IonLabel> :
        <IonInput
          class={classNames({ 'document-label-input': true, 'error': otherDocumentsWithNoName.includes(props.document.name)})}
          value={documentName}
          defaultValue={documentName}
          placeholder={`Enter document name (${props.document.name})`}
          onIonChange={e => updatedDocumentName = e.detail.value}
          onBlur={() => setDocumentName(updatedDocumentName)}
          onKeyPress={(e) => {
            if (!specialCharsRegex.test(e.key)) {
              e.preventDefault();
            }
          }}
          type='text'
          maxlength={50}
          clearInput
        >
        </IonInput>
      );
    };

    return (
      <div className='document-container'>
        <div className='left-side'>
          <DocumentName/>
        </div>
        <div className='right-side'>
          <IonCheckbox
            class='checkbox'
            color="primary"
            checked={documentVersionFlag}
            onIonChange={(e) => setDocumentVersionFlag(e.detail.checked)}
          />
          <Select
            className={classNames({ 'document-type-dropdown': true, 'error': documentsWithNoType.includes(props.document.name)})}
            options={documentTypeOptions}
            placeholder="Select Document Type"
            styles={documentTypeDropdownStyle}
            isSearchable={false}
            defaultValue={selectedDocumentType}
            value={selectedDocumentType}
            onChange={setSelectedDocumentType}
            theme={(theme) => ({
              ...theme,
              spacing: {
                ...theme.spacing,
                controlHeight: 26,
              }
            })}
            components={{
              DropdownIndicator
            }}
          />
          <MultiselectDropdown
            itemOptions={itemOptions}
            itemsDropdownStyle={itemsDropdownStyle}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
          />
          <IonLabel class='clear-items-label' onClick={() => setSelectedItems(null)}>Clear</IonLabel>
          <IonIcon class='document-delete-icon' icon={deleteIcon} onClick={() => removeSelectedDocument(props.document)}></IonIcon>
        </div>
      </div>
    );
  };

  const DocumentDivider = (props) => {
    return (
      props.index < 5 ? <hr className='document-divider'/> : null
    );
  };

  const DocumentsList = (props) => {
    return (
      <>
        {documentsToUpload.map((document, index) => (
          <>
            <Document document={document} linesMap={props.linesMap} invoiceDocument={props.invoiceDocument}/>
            <DocumentDivider index={index}/>
          </>
        ))}
      </>
    );
  };

  const DocumentsListContainer = (props) => {
    return (documentsToUpload.length ?
      <DocumentsList linesMap={props.linesMap} invoiceDocument={props.invoiceDocument}/> :
      <div className='error-container'>
        <BottomError/>
      </div>
    );
  };

  const DocumentDragDrop = () => {
    return (documentsToUpload.length <= 5 ?
      <Dropzone onDrop={e => onFilesChanged(e)}>
        {({getRootProps, getInputProps}) => (
          <div className='document-drag-drop'>
            <div className='document-drag-container'>
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps({})} />
                <IonIcon icon={cloudUploadOutline}></IonIcon><br/>
                <span className='drag-drop-text'>Drag & Drop</span><br/>
                <span className='drag-drop-text'>Suggested files: .pdf .doc .xls .dwg .log .msg</span>
              </div>
            </div>
          </div>
        )}
      </Dropzone> : null
    );
  };

  const BottomError = () => {
    return (noDocumentsSelected ?
      <>
        <IonIcon class='error-icon' icon={errorIcon}></IonIcon>
        <IonLabel class='error-label'>No documents selected.</IonLabel>
      </> :
      <IonLabel class='no-document-selected-label' data-testid="QAAddDocumentsPopoverNoDocumentSelectedLabel">No document selected.</IonLabel>
    );
  };

  const TopError = () => {
    return (errorText ?
      <>
        <IonIcon class='error-icon' icon={errorIcon}></IonIcon>
        <IonLabel class='error-label'>{errorText}</IonLabel>
      </> :
      null
    );
  };

  return (
    <div className="popover">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='add-documents-title' data-testid="QAAddDocumentsPopoverAddDocumentsHeader">Add Documents</IonLabel>
      </div>
      <hr/>
      <div className='top-button-header'>
        <input type="file" hidden ref={fileInputReference} multiple={true} onChange={(e) => onFilesChanged(e)} />
        <IonButton color="secondary" class="select-document-button" onClick={() => onFileUpload()} data-testid="QAAddDocumentsPopoverSelectDocumentButton">Select Document</IonButton>
        <div className='error-container top'>
          <TopError/>
        </div>
      </div>
      <div className='selected-documents-container'>
        <div className='selected-documents-header'>
          <IonLabel class='selected-documents-label'>Selected Documents <span className='maximum-label'>(maximum of 6)</span></IonLabel>
          <IonLabel class='required-label'><span className='required-asterisk'>*</span> Required</IonLabel>
        </div>
        <hr className='table-top-rule'/>
        <IonGrid class="upload-documents-grid">
          <IonRow class="upload-documents-row">
            <IonCol class="upload-documents-col" size='4.25'>
              <span className="upload-documents-text">{ headings.Name }</span>
            </IonCol>
            <IonCol class="upload-documents-col" size='0.75'>
              <span className="upload-documents-text">{ headings.Version }</span>
            </IonCol>
            <IonCol class="upload-documents-col" size='3'>
              <span className="upload-documents-text">{ headings.DocumentType } <span className='required-asterisk'>*</span></span>
            </IonCol>
            <IonCol class="upload-documents-col" size='3'>
              <span className="upload-documents-text">{ headings.Item }</span>
            </IonCol>
          </IonRow>
        </IonGrid>
        <hr className='table-bottom-rule'/>
        <DocumentsListContainer linesMap={props.linesMap} invoiceDocument={!!props.invoiceNumber}/>
        <DocumentDragDrop />
      </div>
      <hr className='bottom-rule'/>
      <div className="cta-button-container">
        <IonButton color="primary" class="add-button" onClick={() => uploadSelectedDocuments()}>
          Add
          <img className={classNames({ 'loading': true, 'hidden': !uploading ? true : false })} src={loadingSpinner}/>
        </IonButton>
        <IonButton color="secondary" class="cancel-button" onClick={() => dismissModal()}>Cancel</IonButton>
      </div>
    </div>
  );
};

export default AddDocumentsPopover;