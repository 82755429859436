import * as React from 'react';
import { IonButton, IonCol, IonGrid, IonImg, IonLabel, IonRow } from '@ionic/react';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import './terms-popover.scss';
import { useDarkMode } from '@hooks/useDarkMode';
import { SelectionTypes, QuoteTermsSelectionTabs, CommercialTermsTypes, QuoteLineItemValueTypes, ManufacturingSites, QuoteLineItemDependentUnwrittenFields } from '@constants';
import classNames from 'classnames';
import SelectionContainer from '@components/selection-container/selection-container';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
import { getFreightZones } from '@shared/quote-utils';
import { getSelectionOptions } from '@shared/quote-utils';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { DependentUnwrittenFieldsMapValueType } from '@shared/types';

const TermsPopover = (props) => {
  const { dataState } = useDataState();
  const {
    freight,
    selections,
    paymentTermsContent,
    contractTermsContent,
    cancellationTermsContent,
    validityContent
  } = dataState;

  const {
    quoteState,
    loadQuote,
    saveQuoteLineItem
  } = useQuoteState();
  const {
    quote,
    isQuoteLocked
  } = quoteState;
  const { quoteLineItems } = quote;

  const { darkMode } = useDarkMode();

  const manufacturingSite = quote.Manufacturing_Site__c ?? ManufacturingSites.US01;
  const standardPaymentTerms = paymentTermsContent?.[manufacturingSite];
  const standardContractTerms = contractTermsContent?.[manufacturingSite];
  const standardCancellationTerms = cancellationTermsContent?.[manufacturingSite];
  const standardValidityTerms = validityContent?.[manufacturingSite];

  const [activeTab, setActiveTab] = React.useState(QuoteTermsSelectionTabs.Shipping);
  const [incoterm, setIncoterm] = React.useState(quote.Incoterms__c);
  const [destinationStreet, setDestinationStreet] = React.useState(quote.Shipping_Street__c);
  const [destinationCity, setDestinationCity] = React.useState(quote.Shipping_City__c);
  const [destinationState, setDestinationState] = React.useState(quote.Shipping_State__c);
  const [destinationPostalCode, setDestinationPostalCode] = React.useState(quote.Shipping_Postal_Code__c);
  const [destinationCountry, setDestinationCountry] = React.useState(quote.Shipping_Country__c);
  const [freightZone, setFreightZone] = React.useState(quote.Freight_Zone__c?.replace('_', ' '));
  const [exportPackaging, setExportPackaging] = React.useState(quote.Export_Packaging__c);
  const [paymentTermsType, setPaymentTermsType] = React.useState(!quote.Payment_Terms__c || quote.Payment_Terms__c === standardPaymentTerms ? CommercialTermsTypes.Standard : CommercialTermsTypes.Custom);
  const [paymentTerms, setPaymentTerms] = React.useState(quote.Payment_Terms__c);
  const [cancellationTermsType, setCancellationTermsType] = React.useState(!quote.Cancelation_Terms__c || quote.Cancelation_Terms__c === standardCancellationTerms ? CommercialTermsTypes.Standard : CommercialTermsTypes.Custom);
  const [cancellationTerms, setCancellationTerms] = React.useState(quote.Cancelation_Terms__c);
  const [contractTermsType, setContractTermsType] = React.useState(!quote.Contract_Terms__c || quote.Contract_Terms__c === standardContractTerms ? CommercialTermsTypes.Standard : CommercialTermsTypes.Custom);
  const [contractTerms, setContractTerms] = React.useState(quote.Contract_Terms__c);
  const [validityType, setValidityType] = React.useState(!quote.Validity__c || quote.Validity__c === standardValidityTerms ? CommercialTermsTypes.Standard : CommercialTermsTypes.Custom);
  const [validity, setValidity] = React.useState(quote.Validity__c);
  const [hasExportPackagingChanged, setHasExportPackagingChanged] = React.useState(false);

  const tabs = [QuoteTermsSelectionTabs.Shipping, QuoteTermsSelectionTabs.CommercialTerms];

  React.useEffect(() => {
    if(paymentTermsType === CommercialTermsTypes.Standard) {
      setPaymentTerms(standardPaymentTerms);
    }
  }, [paymentTermsType]);

  React.useEffect(() => {
    if(cancellationTermsType === CommercialTermsTypes.Standard) {
      setCancellationTerms(standardCancellationTerms);
    }
  }, [cancellationTermsType]);

  React.useEffect(() => {
    if(contractTermsType === CommercialTermsTypes.Standard) {
      setContractTerms(standardContractTerms);
    }
  }, [contractTermsType]);

  React.useEffect(() => {
    if(validityType === CommercialTermsTypes.Standard) {
      setValidity(standardValidityTerms);
    }
  }, [validityType]);

  React.useEffect(() => {
    if(hasExportPackagingChanged) {
      quoteLineItems?.forEach(quoteLineItem => {
        const quoteLineItemToEdit = { ...quoteLineItem };
        
        saveQuoteLineItem(new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>(), [QuoteLineItemValueTypes.ExportPackaging], quoteLineItemToEdit);
      });
    }
  }, [quote]);

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  // Builds the quote and updates on the UI
  const updateQuote = () => {
    const updatedQuote = {
      ...quote,
      Incoterms__c: incoterm,
      Shipping_Street__c: destinationStreet,
      Shipping_City__c: destinationCity,
      Shipping_State__c: destinationState,
      Shipping_Postal_Code__c: destinationPostalCode,
      Shipping_Country__c: destinationCountry,
      Freight_Zone__c: freightZone,
      Export_Packaging__c: exportPackaging,
      Payment_Terms__c: paymentTerms,
      Cancelation_Terms__c: cancellationTerms,
      Contract_Terms__c: contractTerms,
      Validity__c: validity,
    };

    setHasExportPackagingChanged(exportPackaging !== quote?.Export_Packaging__c);

    loadQuote(
      quote.Id,
      updatedQuote,
      true,
    );
  };

  const Tab = (props) => {
    return (
      <IonLabel class={classNames({
        'tab': true,
        'active': activeTab === props.tab
      })} data-testid={'QATermsPopoverTab_' + props.tab} onClick={() => setActiveTab(props.tab)}
      >{props.tab}</IonLabel>
    );
  };

  const Selections = () => {
    const getSelectionType = (defaultSelectionType: SelectionTypes) => {
      if(isQuoteLocked) {
        return SelectionTypes.ReadOnly;
      }

      return defaultSelectionType;
    };

    switch(activeTab) {
    case QuoteTermsSelectionTabs.Shipping:
      return (
        <div className='selections'>
          <IonGrid>
            <IonRow>
              <IonCol>
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.DropDown)}
                  selectionLabel='Incoterm'
                  placeholder='Select incoterm'
                  options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.Incoterms).options}
                  style={{ width: '193px' }}
                  onChange={(incoterm) => setIncoterm(incoterm.label)}
                  value={incoterm}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  inputType='text'
                  selectionLabel='Destination Street'
                  placeholder='Street'
                  style={{ width: '193px' }}
                  containerStyle={{ marginTop: '10px' }}
                  onBlur={(destinationStreet) => setDestinationStreet(destinationStreet)}
                  value={destinationStreet}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  inputType='text'
                  selectionLabel='Destination City'
                  placeholder='City'
                  style={{ width: '193px' }}
                  containerStyle={{ marginTop: '10px', marginRight: '10px' }}
                  onBlur={(destinationCity) => setDestinationCity(destinationCity)}
                  value={destinationCity}
                />
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  inputType='text'
                  selectionLabel='Destination State'
                  placeholder='State'
                  style={{ width: '193px' }}
                  containerStyle={{ marginTop: '10px', marginRight: '10px' }}
                  onBlur={(destinationState) => setDestinationState(destinationState)}
                  value={destinationState}
                />
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  inputType='text'
                  selectionLabel='Destination Postal Code'
                  placeholder='Postal Code'
                  style={{ width: '193px' }}
                  containerStyle={{ marginTop: '10px' }}
                  onBlur={(destinationPostalCode) => setDestinationPostalCode(destinationPostalCode)}
                  value={destinationPostalCode}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.TextInput)}
                  inputType='text'
                  selectionLabel='Destination Country'
                  placeholder='Country'
                  style={{ width: '193px' }}
                  containerStyle={{ marginTop: '10px' }}
                  onBlur={(destinationCountry) => setDestinationCountry(destinationCountry)}
                  value={destinationCountry}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class='flex-column'>
                <SelectionContainer
                  type={getSelectionType(SelectionTypes.DropDown)}
                  selectionLabel='Freight Zone'
                  placeholder='Zone'
                  options={getFreightZones(freight, manufacturingSite)}
                  style={{ width: '90px' }}
                  containerStyle={{ marginTop: '10px' }}
                  onChange={(freightZone) => setFreightZone(freightZone.label)}
                  value={freightZone}
                />
                <CheckboxItem
                  label='Export Packaging'
                  style={{ width: 'fit-content', marginTop: '29px' }}
                  onIonChange={(exportPackaging) => setExportPackaging(exportPackaging)}
                  checked={exportPackaging}
                  disabled={isQuoteLocked}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      );
    case QuoteTermsSelectionTabs.CommercialTerms:
      return (
        <div className='selections commercial-terms'>
          <IonGrid>
            <IonRow>
              <IonCol>
                <SelectionContainer
                  type={SelectionTypes.RadioButtons}
                  selectionLabel='Payment Terms'
                  options={[CommercialTermsTypes.Standard, CommercialTermsTypes.Custom]}
                  onIonChange={(paymentTermsType) => setPaymentTermsType(paymentTermsType)}
                  value={paymentTermsType}
                  isDisabled={isQuoteLocked}
                />
              </IonCol>
              <IonCol>
                <SelectionContainer
                  type={SelectionTypes.RadioButtons}
                  selectionLabel='Cancellation Terms'
                  options={[CommercialTermsTypes.Standard, CommercialTermsTypes.Custom]}
                  onIonChange={(cancellationTermsType) => setCancellationTermsType(cancellationTermsType)}
                  value={cancellationTermsType}
                  isDisabled={isQuoteLocked}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <SelectionContainer
                  type={SelectionTypes.TextArea}
                  placeholder='User entered custom commercial terms...'
                  style={{ width: '356px', marginTop: '0px' }}
                  onBlur={(paymentTerms) => setPaymentTerms(paymentTerms)}
                  value={paymentTerms}
                  isDisabled={isQuoteLocked || paymentTermsType === CommercialTermsTypes.Standard}
                  textAreaType='Payment Terms'
                  rows={5}
                />
              </IonCol>
              <IonCol>
                <SelectionContainer
                  type={SelectionTypes.TextArea}
                  placeholder='User entered custom commercial terms...'
                  style={{ width: '356px', marginTop: '0px' }}
                  onBlur={(cancellationTerms) => setCancellationTerms(cancellationTerms)}
                  value={cancellationTerms}
                  isDisabled={isQuoteLocked || cancellationTermsType === CommercialTermsTypes.Standard}
                  textAreaType='Cancellation Terms'
                  rows={5}
                />
              </IonCol>
            </IonRow>
            <hr className='vert-divider'/>
            <IonRow>
              <IonCol>
                <SelectionContainer
                  type={SelectionTypes.RadioButtons}
                  style={{ marginTop: '10px' }}
                  selectionLabel='Contract Terms'
                  options={[CommercialTermsTypes.Standard, CommercialTermsTypes.Custom]}
                  onIonChange={(contractTermsType) => setContractTermsType(contractTermsType)}
                  value={contractTermsType}
                  isDisabled={isQuoteLocked}
                />
              </IonCol>
              <IonCol>
                <SelectionContainer
                  type={SelectionTypes.RadioButtons}
                  style={{ marginTop: '10px' }}
                  selectionLabel='Validity'
                  options={[CommercialTermsTypes.Standard, CommercialTermsTypes.Custom]}
                  onIonChange={(validityType) => setValidityType(validityType)}
                  value={validityType}
                  isDisabled={isQuoteLocked}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <SelectionContainer
                  type={SelectionTypes.TextArea}
                  placeholder='User entered custom commercial terms...'
                  style={{ width: '356px', marginTop: '0px' }}
                  onBlur={(contractTerms) => setContractTerms(contractTerms)}
                  value={contractTerms}
                  isDisabled={isQuoteLocked || contractTermsType === CommercialTermsTypes.Standard}
                  textAreaType='Contract Terms'
                  rows={5}
                />
              </IonCol>
              <IonCol>
                <SelectionContainer
                  type={SelectionTypes.TextArea}
                  placeholder='User entered custom commercial terms...'
                  style={{ width: '356px', marginTop: '0px' }}
                  onBlur={(validity) => setValidity(validity)}
                  value={validity}
                  isDisabled={isQuoteLocked || validityType === CommercialTermsTypes.Standard}
                  textAreaType='Validity'
                  rows={5}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      );
    }
  };

  return (
    // todo add conditional class for locked
    <div className="popover terms">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='shipping-commercial-terms-title' data-testid={'QATermsPopoverShipping&CommercialTermsHeader'}>{'Shipping & Commercial Terms'}</IonLabel>
      </div>
      <hr/>
      <div className='tabs-container'>
        {tabs.map((tab, index) => (
          <Tab tab={tab} key={index}/>
        ))}
      </div>
      <hr className='mid-rule'/>
      <Selections/>
      <hr className='bottom-rule'/>
      <div className="cta-button-container">
        <IonButton
          color="primary"
          class="save-button"
          data-testid="QATermsPopoverSaveButton"
          onClick={() => {
            updateQuote();
            dismissModal();
          }}
          disabled={isQuoteLocked}
        >
          Save
        </IonButton>
        <IonButton color="secondary" class="cancel-button" onClick={() => dismissModal()}>Cancel</IonButton>
      </div>
    </div>
  );
};

export default TermsPopover;