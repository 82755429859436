import React, { useEffect, useState } from "react";
import { IonLabel, IonToggle } from '@ionic/react';

import './app-toggle.scss';

interface Props {
  label?: string;
  value: boolean;
  onChange?: (value: boolean) => void;
}

const AppToggle = ({ label, value, onChange}: Props) => {
  // hack to stop calling the event if the props.checked changes progmatically
  // until ionic fix this issue on their end
  const [focus, setFocus] = useState(false);

  return <div className="app-toggle">
    <div className="app-toggle-container">
      { label && <IonLabel>{label}</IonLabel>}
      <IonToggle
        mode="ios"
        checked={value}
        onMouseEnter={() => {
          setFocus(true);
        }}
        onMouseLeave={() => {
          setFocus(false);
        }}
        onIonChange={(e) => focus && onChange && onChange(Boolean(e.detail.checked))}
      />
    </div>
  </div>
};

export default AppToggle;