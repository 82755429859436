export enum FormKeys {
  Id = 'Id',
  displayId = 'displayId',
  BaseMetal = 'BaseMetal__c',
  CladMetal = 'CladMetal__c',
  Post_Clad_Testing_Options = 'Post_Clad_Testing_Options__c',
  CladProductionSpecification = 'quoteLineItemDetail.CladProductSpecification__c',
  CladProductionSpecificationNotes = 'quoteLineItemDetail.CladProductionSpecificationNotes__c',
  PostCladBondUTSpecs = 'quoteLineItemDetail.PostCladBondUTSpecification__c',
  PostCladBondUTSpecificationNotes = 'quoteLineItemDetail.PostCladBondUTSpecificationNotes__c',
  PostCladBaseMetalUT = 'quoteLineItemDetail.PostCladBaseMetalUTSpecification__c',
  PostCladBaseMetalUTNotes = 'quoteLineItemDetail.PostCladBaseMetalUTSpecificationNotes__c',
  CertificationTypeRequired = 'quoteLineItemDetail.CertificationTypeRequired__c',
  CladdingForCorrosionAllowanceOnly = 'quoteLineItemDetail.CladdingForCorrosionAllowanceOnly__c',
  IsWeldRepairAllowed = 'quoteLineItemDetail.IsWeldRepairAllowed__c',
  ShearTestUOM = 'quoteLineItemDetail.ShearTestUOM__c',
  ShearTestValue = 'quoteLineItemDetail.ShearTestValue__c',
  CladTensileTestLocation = 'quoteLineItemDetail.CladTensileTestLocation__c',
  BondStrengthTensileTestRamUOM = 'quoteLineItemDetail.BondStrengthTensileTestRamUOM__c',
  BondStrengthTensileTestRamValue = 'quoteLineItemDetail.BondStrengthTensileTestRamValue__c',
  HardnessBaseMetalSurface = 'quoteLineItemDetail.HardnessBaseMetalSurface__c',
  HardnessBaseRejectionCriteria = 'quoteLineItemDetail.HardnessBaseRejectionCriteria__c',
  HardnessCladdingMetalSurface = 'quoteLineItemDetail.HardnessCladdingMetalSurface__c',
  HardnessCladdingRejectionCriteria = 'quoteLineItemDetail.HardnessCladdingRejectionCriteria__c',
  PMIConfirmBaseMetalNotes = 'quoteLineItemDetail.PMIConfirmBaseMetalNotes__c',
  PMIConfirmBaseMetal = 'quoteLineItemDetail.PMIConfirmBaseMetal__c',
  PMIConfirmCladdingMetalNotes = 'quoteLineItemDetail.PMIConfirmCladdingMetalNotes__c',
  PMIConfirmCladdingMetal = 'quoteLineItemDetail.PMIConfirmCladdingMetal__c',
  AdditionalRequirementsCladProductTesting = 'quoteLineItemDetail.AdditionalRequirementsCladProductTesting__c',
  NCCladHeatTreatmentasSupplied = 'quoteLineItemDetail.NCCladHeatTreatmentasSupplied__c',
  NCCladHeatTreatmentasSuppliedNotes = 'quoteLineItemDetail.NCCladHeatTreatmentasSuppliedNotes__c',
  NCCladChemistryRestrictionsforBonding = 'quoteLineItemDetail.NCCladChemistryRestrictionsforBonding__c',
  NCCladChemistryRestForBondingNotes = 'quoteLineItemDetail.NCCladChemistryRestForBondingNotes__c',
  CustomerCladChemistryRestrictionsNotes = 'quoteLineItemDetail.CustomerCladChemistryRestrictionsNotes__c',
  CorrosionTesting = 'quoteLineItemDetail.CorrosionTesting__c',
  CorrosionTestingNotes = 'quoteLineItemDetail.CorrosionTestingNotes__c',
  CladMetalSpecEdition = 'quoteLineItemDetail.CladMetalSpecEdition__c',
  CladMetalSpecAddendum = 'quoteLineItemDetail.CladMetalSpecAddendum__c',
  CustomerCladMetalSupplierRestrictions = 'quoteLineItemDetail.CustomerCladMetalSupplierRestrictions__c',
  CustCladMetalSupplierRestrictionsNotes = 'quoteLineItemDetail.CustCladMetalSupplierRestrictionsNotes__c',
  CladMetalProductAnalysisRequired = 'quoteLineItemDetail.CladMetalProductAnalysisRequired__c',
  CladMetalProductAnalysisRequiredNotes = 'quoteLineItemDetail.CladMetalProductAnalysisRequiredNotes__c',
  CladMetalProductAnalysisType = 'quoteLineItemDetail.CladMetalProductAnalysisType__c',
  FerroxylTestPerformed = 'quoteLineItemDetail.FerroxylTestPerformed__c',
  FerroxylNotes = 'quoteLineItemDetail.FerroxylNotes__c',
  AdditionalRequirementsCladdingMetal = 'quoteLineItemDetail.AdditionalRequirementsCladdingMetal__c',
  Radiography_Clad = 'quoteLineItemDetail.Radiography_Clad__c',
  FerriteNumberRequired = 'quoteLineItemDetail.FerriteNumberRequired__c',
  AdditionalRequirementsPrewelding = 'quoteLineItemDetail.AdditionalRequirementsPrewelding__c',
  NCBaseHeatTreatmentasSupplied = 'quoteLineItemDetail.NCBaseHeatTreatmentasSupplied__c',
  NCBaseHeatTreatmentasSuppliedNotes = 'quoteLineItemDetail.NCBaseHeatTreatmentasSuppliedNotes__c',
  FinishedGoodHeatTreatment = 'quoteLineItemDetail.FinishedGoodHeatTreatment__c',
  FinishedGoodHeatTreatmentNotes = 'quoteLineItemDetail.FinishedGoodHeatTreatmentNotes__c',
  NCBaseChemistryRestrictionsforBonding = 'quoteLineItemDetail.NCBaseChemistryRestrictionsforBonding__c',
  NCBaseChemistryRestForBondingNotes = 'quoteLineItemDetail.NCBaseChemistryRestForBondingNotes__c',
  PrecladBaseMetalUT = 'quoteLineItemDetail.PrecladBaseMetalUT__c',
  PrecladBaseMetalUTNotes = 'quoteLineItemDetail.PrecladBaseMetalUTNotes__c',
  PrecladBaseMetalUTType = 'quoteLineItemDetail.PrecladBaseMetalUTType__c',
  Carbon_Percent = 'quoteLineItemDetail.Carbon_Percent__c',
  Sulphur = 'quoteLineItemDetail.Sulphur__c',
  Phosphorus_Percent = 'quoteLineItemDetail.Phosphorus_Percent__c',
  Carbon_Equivalency_Percent = 'quoteLineItemDetail.Carbon_Equivalency_Percent__c',
  JFactorSA387S62 = 'quoteLineItemDetail.JFactorSA387S62__c',
  XFactorSA387S62 = 'quoteLineItemDetail.XFactorSA387S62__c',
  CustomerBaseChemistryRestrictions = 'quoteLineItemDetail.CustomerBaseChemistryRestrictions__c',
  BaseTensileTestLocation = 'quoteLineItemDetail.BaseTensileTestLocation__c',
  HotTensileTestPerSA20S7Degrees = 'quoteLineItemDetail.HotTensileTestPerSA20S7Degrees__c',
  HotTensileTestPerSA20S7UOM = 'quoteLineItemDetail.HotTensileTestPerSA20S7UOM__c',
  HotTensileTestPerSA20S7Notes = 'quoteLineItemDetail.HotTensileTestPerSA20S7Notes__c',
  HicTestNotes = 'quoteLineItemDetail.HicTestNotes__c',
  AusteniticGrainSizeFinerThanValue = 'quoteLineItemDetail.AusteniticGrainSizeFinerThanValue__c',
  BaseMetalSpecEdition = 'quoteLineItemDetail.BaseMetalSpecEdition__c',
  BaseMetalSpecAddendum = 'quoteLineItemDetail.BaseMetalSpecAddendum__c',
  CustomerBaseMetalSupplierRestrictions = 'quoteLineItemDetail.CustomerBaseMetalSupplierRestrictions__c',
  CustBaseMetalSupplierRestrictionsNotes = 'quoteLineItemDetail.CustBaseMetalSupplierRestrictionsNotes__c',
  BaseMetalProductAnalysisRequired = 'quoteLineItemDetail.BaseMetalProductAnalysisRequired__c',
  BaseMetalProductAnalysisRequiredNotes = 'quoteLineItemDetail.BaseMetalProductAnalysisRequiredNotes__c',
  BaseMetalProductAnalysisType = 'quoteLineItemDetail.BaseMetalProductAnalysisType__c',
  AdditionalRequirementsBaseMetal = 'quoteLineItemDetail.AdditionalRequirementsBaseMetal__c',
  Exceptions_and_Clarifications = 'quoteLineItemDetail.Exceptions_and_Clarifications__c',

  // MODALS

  // CustomerSpecifiedSPWHTTestCoupons
  SPWHTHeatingAndCoolingRateAbove = 'quoteLineItemDetail.SPWHTHeatingAndCoolingRateAbove__c',
  SPWHTHeatingAndCoolingRateAboveUOM = 'quoteLineItemDetail.SPWHTHeatingAndCoolingRateAboveUOM__c',
  SPWHTMaximumCoolingRateHour = 'quoteLineItemDetail.SPWHTMaximumCoolingRateHour__c',
  SPWHTMaximumCoolingRateHourUOM = 'quoteLineItemDetail.SPWHTMaximumCoolingRateHourUOM__c',
  SPWHTMaximumHeatingRateHour = 'quoteLineItemDetail.SPWHTMaximumHeatingRateHour__c',
  SPWHTMaximumHeatingRateHourUOM = 'quoteLineItemDetail.SPWHTMaximumHeatingRateHourUOM__c',

  SPWHTGroupACycle1 = 'quoteLineItemDetail.SPWHTGroupACycle1__c',
  SPWHTGroupACycle2 = 'quoteLineItemDetail.SPWHTGroupACycle2__c',
  SPWHTGroupACycle3 = 'quoteLineItemDetail.SPWHTGroupACycle3__c',
  SPWHTGroupAToBePerformed = 'quoteLineItemDetail.SPWHTGroupAToBePerformed__c',
  SPWHTGroupBCycle1 = 'quoteLineItemDetail.SPWHTGroupBCycle1__c',
  SPWHTGroupBCycle2 = 'quoteLineItemDetail.SPWHTGroupBCycle2__c',
  SPWHTGroupBCycle3 = 'quoteLineItemDetail.SPWHTGroupBCycle3__c',
  SPWHTGroupBToBePerformed = 'quoteLineItemDetail.SPWHTGroupBToBePerformed__c',
  SPWHTGroupCCycle1 = 'quoteLineItemDetail.SPWHTGroupCCycle1__c',
  SPWHTGroupCCycle2 = 'quoteLineItemDetail.SPWHTGroupCCycle2__c',
  SPWHTGroupCCycle3 = 'quoteLineItemDetail.SPWHTGroupCCycle3__c',
  SPWHTGroupCToBePerformed = 'quoteLineItemDetail.SPWHTGroupCToBePerformed__c',

  // CustomerSpecifiedImpactTesting
  CustomerSpecifiedImpactTestToBePerformed = 'quoteLineItemDetail.CustomerSpecifiedImpactTestToBePerformed__c',
  CustomerSpecifiedImpactTestLocation = 'quoteLineItemDetail.CustomerSpecifiedImpactTestLocation__c',
  CustomerSpecifiedImpactTestDirection = 'quoteLineItemDetail.CustomerSpecifiedImpactTestDirection__c',
  CustomerSpecifiedImpactTestTemperature = 'quoteLineItemDetail.CustomerSpecifiedImpactTestTemperature__c',
  CustomerSpecifiedImpactTestTempUOM = 'quoteLineItemDetail.CustomerSpecifiedImpactTestTempUOM__c',
  CustomerSpecifiedImpactTestUOM = 'quoteLineItemDetail.CustomerSpecifiedImpactTestUOM__c',
  CustomerSpecifiedImpactTestAVG = 'quoteLineItemDetail.CustomerSpecifiedImpactTestAVG__c',
  CustomerSpecifiedImpactTestMIN = 'quoteLineItemDetail.CustomerSpecifiedImpactTestMIN__c',

  // PrebondImpacts
  PreBondImpactsTestToBePerformed = "quoteLineItemDetail.PreBondImpactsTestToBePerformed__c",
  PreBondImpactsTestLocation = "quoteLineItemDetail.PreBondImpactsTestLocation__c",
  PreBondImpactsTestDirection = "quoteLineItemDetail.PreBondImpactsTestDirection__c",
  PreBondImpactsTestUOM = "quoteLineItemDetail.PreBondImpactsTestUOM__c",
  PreBondImpactsTestTemperature = "quoteLineItemDetail.PreBondImpactsTestTemperature__c",
  PreBondImpactsTestTemperatureUOM = "quoteLineItemDetail.PreBondImpactsTestTemperatureUOM__c",
  PreBondImpactsTestAVG = "quoteLineItemDetail.PreBondImpactsTestAVG__c",
  PreBondImpactsTestMIN = "quoteLineItemDetail.PreBondImpactsTestMIN__c",

  // RequiredLeakTest
  LeakTestGas = 'quoteLineItemDetail.LeakTestGas__c',
  LeakTestReqt = 'quoteLineItemDetail.LeakTestReqt__c',
  LeakTestValue = 'quoteLineItemDetail.LeakTestValue__c',
  LeakTestPressure = 'quoteLineItemDetail.LeakTestPressure__c',

  // checkboxes
  CustomerSpecifiedSPWHTTestCoupons = 'quoteLineItemDetail.CustomerSpecifiedSPWHTTestCoupons__c',
  CustomerSpecifiedImpactTesting = 'quoteLineItemDetail.CustomerSpecifiedImpactTesting__c',
  PrebondImpacts = 'quoteLineItemDetail.PrebondImpacts__c',
  MachineBondStrengthBendsperSA26345 = 'quoteLineItemDetail.MachineBondStrengthBendsperSA26345__c',
  BendB898 = 'quoteLineItemDetail.BendB898__c',
  MachineSideBends = 'quoteLineItemDetail.MachineSideBends__c',
  ADW8Bend = 'quoteLineItemDetail.ADW8Bend__c',
  VacuumDegassed = 'quoteLineItemDetail.VacuumDegassed__c',
  CalciumTreatedSulfideShapeControl = 'quoteLineItemDetail.CalciumTreatedSulfideShapeControl__c',
  ThroughThicknessTensilePerSA770 = 'quoteLineItemDetail.ThroughThicknessTensilePerSA770__c',
  HotTensileTestPerSA20S7 = 'quoteLineItemDetail.HotTensileTestPerSA20S7__c',
  PMIofWeldWire = 'quoteLineItemDetail.PMIofWeldWire__c',
  PMIPreweldsRequired = 'quoteLineItemDetail.PMIPreweldsRequired__c',

  // _requiredField is not necessarily part of the NC_QuoteLineItem data
  RequiredShearTest = '_requiredField.shearTest',
  RequiredTensileTest = '_requiredField.tensileTest',
  RequiredBondStrTensileTest = '_requiredField.bondStrTensileTest',
  RequiredHardnessCladdingMetalSurface = '_requiredField.hardnessCladdingMetalSurface',
  RequiredHardnessBaseMetalSurface = '_requiredField.hardnessBaseMetalSurface',
  RequiredPMIConfirmCladdingMetal = '_requiredField.PMIConfirmCladdingMetal',
  RequiredPMIConfirmBaseMetal = '_requiredField.PMIConfirmBaseMetal',
  RequiredLeakTest = '_requiredField.leakTest',
  RequiredCorrosionTesting = '_requiredField.corrosionTesting',
  RequiredCladdingCustomerChemRestriction = '_requiredField.claddingCustomerChemRestriction',
  RequiredCladdingMetalSpecEdAd = '_requiredField.claddingMetalSpecEdAd',
  RequiredCladdingCustomerMetalSupplierRestriction = '_requiredField.claddingCustomerMetalSupplierRestriction',
  RequiredCladdingMetalProdAnalysis = '_requiredField.claddingMetalProdAnalysis',
  RequiredFerroxlyTestOfCladdingSurface = '_requiredField.ferroxlyTestOfCladdingSurface',
  RequiredFerriteNumber = '_requiredField.ferriteNumber',
  RequiredPrecladBaseMetalUT = '_requiredField.precladBaseMetalUT',
  RequiredPrebondImpacts = '_requiredField.prebondImpacts',
  RequiredCarbon = '_requiredField.carbon',
  RequiredSulphur = '_requiredField.sulphur',
  RequiredPhosphorus = '_requiredField.phosphorus',
  RequiredCarbonEquivalency = '_requiredField.carbonEquivalency',
  RequiredJFactor = '_requiredField.jFactor',
  RequiredXFactor = '_requiredField.xFactor',
  RequiredBaseCustomerChemRestriction = '_requiredField.baseCustomerChemRestriction',
  RequiredCenterlineTensile = '_requiredField.centerlineTensile',
  RequiredHICTesting = '_requiredField.HICTesting',
  RequiredAusteniticGrainSizeFiner = '_requiredField.austeniticGrainSizeFiner',
  RequiredBaseMetalSpecEdAd = '_requiredField.baseMetalSpecEdAd',
  RequiredBaseCustomerMetalSupplierRestriction = '_requiredField.baseCustomerMetalSupplierRestriction',
  RequiredBaseMetalProdAnalysis = '_requiredField.baseMetalProdAnalysis',
};