import { IonCard, IonCardHeader, IonGrid, IonLabel, IonRow } from '@ionic/react';
import React from 'react';
import './empty-table-row.scss';

const EmptyTableRow = (props) => {
  return (
    <IonCard className="card" data-testid='QAEmptyTableRow'>
      <IonCardHeader class="empty-card-header even">
        <IonGrid>
          <IonRow class="row">
            <IonLabel class="empty-label">{props.text}</IonLabel>
          </IonRow>
        </IonGrid>
      </IonCardHeader>
    </IonCard>
  );
};

export default EmptyTableRow;
