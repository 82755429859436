import React from 'react';

import { IonLabel } from '@ionic/react';
import AppInput from '@components/app-input/app-input';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';

import PanelSection from '../panel-section';
import AppLineItem from '@components/app-line-item/app-line-item';
import { PanelProp } from '@pages/new-assembly-groups/panel-item/panel-item';
import { AssemblyGroupFormKeys } from '@pages/new-assembly-groups/assembly-group-form-util';
import { MeasurementSystems, Regexes, Units } from '@constants';
import { useDisplayUom } from '@context/useDisplayUom';
import { calculateUOMValue } from '@shared/quote-utils';

const UOMCostLabel = {
  [MeasurementSystems.Imperial]: 'Lb',
  [MeasurementSystems.Metric]: 'Kg',
};

const costFormat = (value) => {
  return value !== '' ? (+value).toFixed(2) : '';
}

const PanelFreight = ({ form, updateFormByObject }: PanelProp) => {
  const { displayUom, isDisplayImperial } = useDisplayUom();
  const freightValue = isDisplayImperial
    ? form[AssemblyGroupFormKeys.Total_Freight_LB]
    : form[AssemblyGroupFormKeys.Total_Freight_KG];

  const onUpdateFreightValue = (value) => {
    const perLb = calculateUOMValue(displayUom, MeasurementSystems.Imperial, value, Units.Kilograms, Units.Pounds);
    const perKG = calculateUOMValue(displayUom, MeasurementSystems.Metric, value, Units.Kilograms, Units.Pounds);

    updateFormByObject({
      [AssemblyGroupFormKeys.Total_Freight_LB]: perLb,
      [AssemblyGroupFormKeys.Total_Freight_KG]: perKG,
    });
  };

  return <>
    <PanelSection title="Freight" className="margin-bottom-5">
      <AppLineItem label='Total Shipping Cost (Per Lot):' >
        <AppLineItemColumns components={[{
          key: 'total-shipping-cost',
          size: 6,
          component: <AppLineItemColumns components={[
            {
              key: 'total-shipping-cost-input',
              size: 4,
              component: <AppInput
                type='number'
                placeholder='0.000'
                regex={Regexes.DecimalNumber}
                min='0'
                value={costFormat(freightValue)}
                onChange={onUpdateFreightValue}
              />,
              style: {
                padding: 0,
              }
            },
            {
              key: 'total-shipping-cost-label',
              size: 8,
              className: 'd-flex align-items-center',
              style: {
                padding: '0 5px',
              },
              component: <IonLabel>Per {UOMCostLabel[displayUom]}</IonLabel>
            }
          ]} />
        }]}/>
      </AppLineItem>
    </PanelSection>
  </>
};

export default PanelFreight;