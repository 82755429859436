import _ from 'lodash-es';
import { FormKeys } from './form-keys';
import { ManufacturingSites, PostCladTestingOptions } from '@constants';

export const SpecifyPerAssemblyMapper = {
  "clad-product-testing.post-clad-bond-ut-spec": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.PostCladBondUTSpecs, null),
      _.get(quoteLineItem, FormKeys.PostCladBondUTSpecificationNotes, null),
    ].filter(i => i);

    return items.join(',');
  },
  "clad-product-testing.post-clad-base-bond-ut-spec": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.PostCladBaseMetalUT, null),
      _.get(quoteLineItem, FormKeys.PostCladBaseMetalUTNotes, null),
    ].filter(i => i);

    return items.join(',');
  },
  "clad-product-testing.cert-type-req": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CertificationTypeRequired, null),
    ].filter(i => i);

    return items.join(',');
  },
  "clad-product-testing.cladding-corrosion-allowance": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CladdingForCorrosionAllowanceOnly, null),
    ].filter(i => i);

    return items.join(',');
  },
  "clad-product-testing.is-weld-repair-allowed": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.IsWeldRepairAllowed, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.customer-specified-spwht": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.SPWHTHeatingAndCoolingRateAbove, null),
      _.get(quoteLineItem, FormKeys.SPWHTHeatingAndCoolingRateAboveUOM, null),
      _.get(quoteLineItem, FormKeys.SPWHTMaximumCoolingRateHour, null),
      _.get(quoteLineItem, FormKeys.SPWHTMaximumCoolingRateHourUOM, null),
      _.get(quoteLineItem, FormKeys.SPWHTMaximumHeatingRateHour, null),
      _.get(quoteLineItem, FormKeys.SPWHTMaximumHeatingRateHourUOM, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupACycle1, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupACycle2, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupACycle3, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupAToBePerformed, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupBCycle1, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupBCycle2, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupBCycle3, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupBToBePerformed, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupCCycle1, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupCCycle2, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupCCycle3, null),
      _.get(quoteLineItem, FormKeys.SPWHTGroupCToBePerformed, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.customer-specified-impact": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestToBePerformed, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestLocation, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestDirection, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestTemperature, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestTempUOM, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestUOM, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestAVG, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestMIN, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.shear-test": ({ quoteLineItem }) => {
    const options = _.get(quoteLineItem, FormKeys.Post_Clad_Testing_Options, undefined);
    const split = options && typeof options === 'string' ? options.split(';') : [];

    const items = [
      split.includes(PostCladTestingOptions.Shear).toString(),
      _.get(quoteLineItem, FormKeys.ShearTestValue, null),
      _.get(quoteLineItem, FormKeys.ShearTestUOM, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.tensile-test": ({ quoteLineItem }) => {
    const options = _.get(quoteLineItem, FormKeys.Post_Clad_Testing_Options, undefined);
    const split = options && typeof options === 'string' ? options.split(';') : [];

    const items = [
      split.includes(PostCladTestingOptions.Tensile).toString(),
      _.get(quoteLineItem, FormKeys.CladTensileTestLocation, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.bond-strength-tensile-test": ({ quoteLineItem }) => {
    const options = _.get(quoteLineItem, FormKeys.Post_Clad_Testing_Options, undefined);
    const split = options && typeof options === 'string' ? options.split(';') : [];

    const items = [
      split.includes(PostCladTestingOptions.RamTensile).toString(),
      _.get(quoteLineItem, FormKeys.BondStrengthTensileTestRamValue, null),
      _.get(quoteLineItem, FormKeys.BondStrengthTensileTestRamUOM, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.hardness-cladding-metal-surface": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.HardnessCladdingMetalSurface, null),
      _.get(quoteLineItem, FormKeys.HardnessCladdingRejectionCriteria, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.hardness-base-metal-surface": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.HardnessBaseMetalSurface, null),
      _.get(quoteLineItem, FormKeys.HardnessBaseRejectionCriteria, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.pmi-confirm-cladding-metal": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.PMIConfirmCladdingMetalNotes, null),
      _.get(quoteLineItem, FormKeys.PMIConfirmCladdingMetal, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.pmi-confirm-base-metal": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.PMIConfirmBaseMetalNotes, null),
      _.get(quoteLineItem, FormKeys.PMIConfirmBaseMetal, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.bond-strength-bend": ({ quoteLineItem }) => {
    const options = _.get(quoteLineItem, FormKeys.Post_Clad_Testing_Options, undefined);
    const split = options && typeof options === 'string' ? options.split(';') : [];

    const items = [
      split.includes(PostCladTestingOptions.SA26345BondStrength).toString(),
      _.get(quoteLineItem, FormKeys.MachineBondStrengthBendsperSA26345, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.bend-b898": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.BendB898, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.side-bend": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.MachineSideBends, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.adw8-bend": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.ADW8Bend, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.leak-test": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.LeakTestGas, null),
      _.get(quoteLineItem, FormKeys.LeakTestReqt, null),
      _.get(quoteLineItem, FormKeys.LeakTestValue, null),
      _.get(quoteLineItem, FormKeys.LeakTestPressure, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-product-testing.additional-requirements": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.AdditionalRequirementsCladProductTesting, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-metal.heat-treatment-as-supplied": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.NCCladHeatTreatmentasSupplied, null),
      _.get(quoteLineItem, FormKeys.NCCladHeatTreatmentasSuppliedNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-metal.chemistry-restriction": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.NCCladChemistryRestrictionsforBonding, null),
      _.get(quoteLineItem, FormKeys.NCCladChemistryRestForBondingNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-metal.customer-chemistry-restriction": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CustomerCladChemistryRestrictionsNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-metal.corrosion-testing": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CorrosionTesting, null),
      _.get(quoteLineItem, FormKeys.CorrosionTestingNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-metal.metal-spec-ed-ad": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CladMetalSpecEdition, null),
      _.get(quoteLineItem, FormKeys.CladMetalSpecAddendum, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-metal.customer-metal": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CustomerCladMetalSupplierRestrictions, null),
      _.get(quoteLineItem, FormKeys.CustCladMetalSupplierRestrictionsNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-metal.metal-product": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CladMetalProductAnalysisRequired, null),
      _.get(quoteLineItem, FormKeys.CladMetalProductAnalysisRequiredNotes, null),
      _.get(quoteLineItem, FormKeys.CladMetalProductAnalysisType, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-metal.ferroxyl-test": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.FerroxylTestPerformed, null),
      _.get(quoteLineItem, FormKeys.FerroxylNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "clad-metal.additional-requirements": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.AdditionalRequirementsCladdingMetal, null),
    ].filter(i => i);
    return items.join(',');
  },
  "prewelding.radiography": ({ quoteLineItem, quote }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.Radiography_Clad, null),
    ].filter(i => i);

    return items.join(',');
  },
  "prewelding.pmi-weld-wire": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.PMIofWeldWire, null),
    ].filter(i => i);
    return items.join(',');
  },
  "prewelding.pmi-prewelds": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.PMIPreweldsRequired, null),
    ].filter(i => i);
    return items.join(',');
  },
  "prewelding.ferrite-number": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.FerriteNumberRequired, null),
    ].filter(i => i);
    return items.join(',');
  },
  "prewelding.additional-requirements": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.AdditionalRequirementsPrewelding, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.headTreatmentAsSupplied": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.NCBaseHeatTreatmentasSupplied, null),
      _.get(quoteLineItem, FormKeys.NCBaseHeatTreatmentasSuppliedNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.finishedGoodHeadTreatment": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.FinishedGoodHeatTreatment, null),
      _.get(quoteLineItem, FormKeys.FinishedGoodHeatTreatmentNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.chemistryRegistrationForBonding": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.NCBaseChemistryRestrictionsforBonding, null),
      _.get(quoteLineItem, FormKeys.NCBaseChemistryRestForBondingNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.precladBaseMetalUT": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.PrecladBaseMetalUT, null),
      _.get(quoteLineItem, FormKeys.PrecladBaseMetalUTNotes, null),
      _.get(quoteLineItem, FormKeys.PrecladBaseMetalUTType, null),
    ].filter(i => i);

    return items.join(',');
  },
  "base-metal.prebondImpacts": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestToBePerformed, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestLocation, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestDirection, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestTemperature, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestTempUOM, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestUOM, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestAVG, null),
      _.get(quoteLineItem, FormKeys.CustomerSpecifiedImpactTestMIN, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.carbon": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.Carbon_Percent, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.phosphorus": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.Phosphorus_Percent, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.sulphur": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.Sulphur, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.carbon-equivalency": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.Carbon_Equivalency_Percent, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.j-factor": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.JFactorSA387S62, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.x-factor": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.XFactorSA387S62, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.customer-chemistry-restriction": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CustomerBaseChemistryRestrictions, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.vacuum-degassed": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.VacuumDegassed, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.calcium-treated": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CalciumTreatedSulfideShapeControl, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.through-thickness-tensile": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.ThroughThicknessTensilePerSA770, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.centerline-tensile": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.BaseTensileTestLocation, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.hot-tensile": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.HotTensileTestPerSA20S7, null),
      _.get(quoteLineItem, FormKeys.HotTensileTestPerSA20S7Degrees, null),
      _.get(quoteLineItem, FormKeys.HotTensileTestPerSA20S7UOM, null),
      _.get(quoteLineItem, FormKeys.HotTensileTestPerSA20S7Notes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.hic-testing-required": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.HicTestNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.authenitic-grain-size": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.AusteniticGrainSizeFinerThanValue, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.metal-spec-ed-ad": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.BaseMetalSpecEdition, null),
      _.get(quoteLineItem, FormKeys.BaseMetalSpecAddendum, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.customer-metal-supplier": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.CustomerBaseMetalSupplierRestrictions, null),
      _.get(quoteLineItem, FormKeys.CustBaseMetalSupplierRestrictionsNotes, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.metal-product-analysis-req": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.BaseMetalProductAnalysisRequired, null),
      _.get(quoteLineItem, FormKeys.BaseMetalProductAnalysisRequiredNotes, null),
      _.get(quoteLineItem, FormKeys.BaseMetalProductAnalysisType, null),
    ].filter(i => i);
    return items.join(',');
  },
  "base-metal.additional-requirements": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.AdditionalRequirementsBaseMetal, null),
    ].filter(i => i);
    return items.join(',');
  },
  "exceptions-clarifications.exceptions-clarification": ({ quoteLineItem }) => {
    const items = [
      _.get(quoteLineItem, FormKeys.Exceptions_and_Clarifications, null),
    ].filter(i => i);
    return items.join(',');
  }
};