import Footer from '@components/footer/footer';
import { IonContent } from '@ionic/react';
import * as React from 'react';
import { FC } from 'react';

import './page-layout.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
};

// This is just a starting point for using page layouts.
// You can extend this layout to other page layouts, add or send in custom css classes, or use another layout all together.
// You can execute useEffects here, or use custom hooks to execute code when the page is loaded, or when state changes, etc.
const PageLayout: FC<Props> = ({ className, children, ...rest }) => {
  return (
    <>
      <IonContent class={`page ${className}`} {...rest}>
        <div className='max-width-container page-content'>{children}</div>
        <div className='footer-container'>
        <Footer />
        </div>
      </IonContent>
    </>
  );
};

export default PageLayout;
