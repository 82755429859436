import React from 'react';
import { IonTextarea } from '@ionic/react';

import './app-textarea.scss';

const AppTextarea = (props) => {
  // hack to stop calling the event if the props.checked changes progmatically
  // until ionic fix this issue on their end

  return <IonTextarea className='app-textarea'
    onBlur={(e) => props.onChange && props.onChange(e.target.value)}
    {...props}
  />
};

export default AppTextarea;
