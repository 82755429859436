import React from 'react';
import type {PortableTextTypeComponent} from '@portabletext/react';
import './copyable-table.scss';

export interface TableDef {
  _type: 'table';
  headers: string[];
  rows: string[][];
  tableStyle: React.CSSProperties;
  tableRowStyle: React.CSSProperties;
  headerCellStyle: React.CSSProperties;
  headerTextStyle: React.CSSProperties;
  cellStyle: React.CSSProperties;
  cellTextStyle: React.CSSProperties;
}

export const CopyableTable: PortableTextTypeComponent<TableDef> = ({value}) => {
  const { tableStyle, tableRowStyle, headerCellStyle, headerTextStyle, cellStyle, cellTextStyle, headers, rows } = value;

  return (
    <>
      <table className='table' style={tableStyle}>
        <tbody>
          <tr className='table-row' style={tableRowStyle}>
            {headers.map((header, index) => (
              <th style={headerCellStyle} key={`header-${index}`}>
                <span className='table-header' style={headerTextStyle}>{header}</span>
              </th>
            ))}
          </tr>
          {rows.map((row, rowIndex) => (
            <React.Fragment key={`row-${rowIndex}`}>
              <tr className='table-row' style={tableRowStyle}>
                {row.map((cellValue, index) => (
                  <td style={cellStyle} key={`cell-${rowIndex}-${index}`}>
                    <span className='table-value' style={cellTextStyle}>{cellValue}</span>
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
};