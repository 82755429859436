import { CladProductionSpecifications } from "@constants";
import _ from 'lodash-es';
import { FormKeys } from "./form-utils/form-keys";
import { FormKeyHandler } from "./form-utils/form-key-handler";
import { SpecifyPerAssemblyMapper } from "./form-utils/specify-per-assembly-mapper";
import { SpecifyPerAssemblyFields } from "./form-utils/specify-per-assembly-fields";
import { FormKeyMapper } from "./form-utils/form-key-mapper";

/**
 * convert string to a label value object
 * @param value any
 * @returns Object<{
 *    label: value,
 *    value: value
 * }>
 */
const strToLabelValue = (value) => {
  if (typeof value === 'string') {
    return {
      label: value,
      value,
    };
  }
  return value;
}

/**
 * take each key and check if value is undefined,
 * and if it is, it will use QLI value instead
 * @param fqli FormQuoteLineItem
 * @param qli QuoteLineItem
 * @returns FormQuoteLineItem
 */
const reduceFormState = (fqli = {}, qli = {}) => {
  return Object.keys(fqli).reduce((prev, key) => {
    const fqliValue = _.get(fqli, key);
    const qliValue = _.get(qli, key);

    prev[key] = typeof fqliValue === 'undefined' ? qliValue : fqliValue;

    return prev;
  }, {});
};

export {
  reduceFormState,
  strToLabelValue,
  FormKeys,
  FormKeyMapper,
  FormKeyHandler,
  SpecifyPerAssemblyMapper,
  SpecifyPerAssemblyFields,
};
