import * as React from 'react';
import { useState } from 'react';
import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonLabel, IonRow } from '@ionic/react';
import './supplier-popover.scss';
import closeIcon from '@assets/icon/cancel_icon.svg';
import { earth } from 'ionicons/icons';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import SelectionContainer from '@components/selection-container/selection-container';
import {
  SelectionTypes,
  QuoteLineItemValueTypes,
  ManufacturingSites,
  AddPieceSuppliers,
  MSTOSelectionTabs,
  VendorServices,
  MSTOPopovers,
  MSTOAssemblyMetals,
  PanelLocations,
  SearchFields,
  NCSupplier,
} from '@constants';
import {
  SupplierData,
  PanelsMap,
  SeamPanels,
  Panels,
  AssemblyPanels,
} from '@shared/types';
import { Vendor } from '@interfaces/vendor';
import { getVendors, getSelectionOptions } from '@shared/quote-utils';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { MetalToAutocombine } from '@shared/types';

const NCSuppliers: string[] = [
  NCSupplier.Backer,
  NCSupplier.Cladder,
];

const getVendorIdForCustomerSuppliedMetal = (activeTab) => {
  switch(activeTab) {
    case MSTOSelectionTabs.Cladder:
      return NCSupplier.Cladder;
    case MSTOSelectionTabs.Backer:
      return NCSupplier.Backer;
  }

  return null;
}

const SupplierPopover = (props: {
  mstoData: {
    activeTab: MSTOSelectionTabs,
    anvilPanelsMap: Map<string, SeamPanels>,
    cladderPanelsMap: Map<string, SeamPanels>,
    backerPanelsMap: Map<string, SeamPanels>,
    assemblies?: Map<string, AssemblyPanels[]>
  },
  dismissModal: Function,
  buildAutocombinedGroups: (updatedMetalPanelsMap: Map<string, SeamPanels | Panels>) => MetalToAutocombine[][],
  autocombineMetals: (autocombinedGroups?: {
    quoteLineItemData: QuoteLineItem;
    quoteLineItemDetailData: QuoteLineItemDetail;
    metal: MSTOAssemblyMetals;
    panelNumber?: number;
    panelLocation?: PanelLocations;
    seamWeldPieceNumber?: number;
  }[][], assemblies?: any) => Map<string, AssemblyPanels[]>,
  updatePanelsMap: Function,
  updateAssemblies: Function
}) => {
  const { dataState } = useDataState();
  const { selections, vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;

  const { darkMode } = useDarkMode();

  // data used in the form
  const [supplierData, setSupplierData] = useState<SupplierData>({
    choice: AddPieceSuppliers.CustomerSuppliedMetal,
    customerProvided: true,
    pullFromStock: false,
    purchase: false,
    shipDate: '',
    vendorId: getVendorIdForCustomerSuppliedMetal(props.mstoData.activeTab),
  });

  const dismissModal = () => props.dismissModal(MSTOPopovers.Supplier);

  const SupplierContainer = () => {
    return (
      <>
        <IonGrid class='sublabel-header-container'>
          <IonRow>
            <IonCol>
              <IonLabel class='sublabel-header'>Set Supplier For All {props.mstoData.activeTab} Pieces:</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className='supplier-container'>
          <IonGrid>
            <IonRow>
              <IonCol>
                <SupplierSelections />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </>
    );
  };

  const SupplierSelections = () => {
    const name = vendors?.find((vendor: { vendorId: string }) => vendor.vendorId === supplierData.vendorId && !NCSuppliers.includes(supplierData.vendorId))?.vendorName;

    const handleSupplierChoiceChange = (supplierChoice: AddPieceSuppliers) => {
      let purchase = false,
        pullFromStock = false,
        customerProvided = false,
        vendorId = null;

      switch (supplierChoice) {
        case AddPieceSuppliers.SupplierContactFromQuoteRecords:
          purchase = true;
          pullFromStock = false;

          if (props.mstoData.activeTab !== MSTOSelectionTabs.Anvil) {
            customerProvided = false;
          }
          break;
        case AddPieceSuppliers.CustomerSuppliedMetal:
          purchase = false;
          pullFromStock = false;

          if (props.mstoData.activeTab !== MSTOSelectionTabs.Anvil) {
            customerProvided = true;
          }

          vendorId = getVendorIdForCustomerSuppliedMetal(props.mstoData.activeTab);
          break;
      }

      setSupplierData((prevData) => {
        // if the user selects SupplierContactFromQuoteRecords, clear the vendorId
        const id = supplierChoice === AddPieceSuppliers.SupplierContactFromQuoteRecords ? '' : prevData.vendorId;
        return {
          ...prevData,
          choice: supplierChoice,
          vendorId: vendorId || id,
          customerProvided,
          purchase,
          pullFromStock,
        };
      });
    };

    return (
      <>
        <IonGrid>
          <IonRow>
            <IonCol class='fixed-flex-width'>
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                groupStyle={{ display: 'block' }}
                itemStyle={{ '--inner-padding-end': '7px', width: '230px' }}
                options={getSelectionOptions(
                  selections,
                  manufacturingSite,
                  QuoteLineItemValueTypes.AddPieceSupplyChoice
                )?.options.filter((opt) => opt !== AddPieceSuppliers.NCStock)}
                onIonChange={(choice: AddPieceSuppliers) => {
                  handleSupplierChoiceChange(choice);
                }}
                value={supplierData.choice}
              />
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.SearchableDropdown}
                noneFoundText='No suppliers found'
                placeholder='Select Supplier'
                containerStyle={{ width: '193px', top: '-6px', position: 'relative' }}
                options={getSupplierSelections(props.mstoData.activeTab, vendors, manufacturingSite)}
                isDisabled={supplierData.choice !== AddPieceSuppliers.SupplierContactFromQuoteRecords}
                suggestionDisplayFields={[SearchFields.VendorName]}
                onChange={(vendorSelection: { vendorId: string }) => {
                  setSupplierData((prevData) => {
                    return { ...prevData, vendorId: vendorSelection.vendorId };
                  });
                }}
                value={name}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.Date}
                  min={new Date().toISOString().slice(0, 10)}
                  containerStyle={{ width: '193px', height: '26px', marginRight: '20px' }}
                  selectionLabel='Metal Ship Date'
                  onBlur={(metalShipDate: string) => {
                    setSupplierData((prevData) => {
                      return { ...prevData, shipDate: metalShipDate };
                    });
                  }}
                  value={supplierData.shipDate}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  };

  return (
    <>
      <div className='popover supplier'>
        <TitleHeaderContainer
          darkMode={darkMode}
          closeIcon={closeIcon}
          closeIconWhite={closeIconWhite}
          dismissModal={dismissModal}
          title='Supplier'
          headerIcon={earth}
        />
        <hr className='mid-rule' />
        <SupplierContainer />
        <hr className='mid-rule' />
        <ApplyCancelButtonContainer
          dismissModal={dismissModal}
          supplierData={supplierData}
          buildAutocombinedGroups={props.buildAutocombinedGroups}
          autocombineMetals={props.autocombineMetals}
          activeTab={props.mstoData.activeTab}
          anvilPanelsMap={props.mstoData.anvilPanelsMap}
          cladderPanelsMap={props.mstoData.cladderPanelsMap}
          backerPanelsMap={props.mstoData.backerPanelsMap}
          assemblies={props.mstoData.assemblies}
          updatePanelsMap={props.updatePanelsMap}
          updateAssemblies={props.updateAssemblies}
        />
      </div>
    </>
  );
};

export default SupplierPopover;

/** Helper Functions */
const getSupplierSelections = (activeTab: MSTOSelectionTabs, vendors: Vendor[], manufacturingSite: ManufacturingSites) => {
  switch (activeTab) {
    case MSTOSelectionTabs.Cladder:
      return getVendors(vendors, manufacturingSite, VendorServices.RawMaterialsCladder);
    case MSTOSelectionTabs.Backer:
      return getVendors(vendors, manufacturingSite, VendorServices.RawMaterialsBacker);
    case MSTOSelectionTabs.Anvil:
      return getVendors(vendors, manufacturingSite, VendorServices.RawMaterialsAnvil);
  }
};

/** Pure Components */
const TitleHeaderContainer = ({ title, darkMode, closeIcon, closeIconWhite, dismissModal, headerIcon }) => {
  return (
    <IonGrid class='title-header-container'>
      <IonRow>
        <IonCol>
          <IonImg src={!darkMode ? closeIcon : closeIconWhite} class='close-icon' onClick={() => dismissModal()} />
          <IonGrid className='title-container'>
            <IonRow>
              <IonCol>
                <IonLabel class='title' data-testid={'QASupplierModalHeader'}>
                  {title}
                </IonLabel>
                <IonIcon className='header-icon' src={headerIcon} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const ApplyCancelButtonContainer = ({
  dismissModal,
  supplierData,
  buildAutocombinedGroups,
  autocombineMetals,
  activeTab,
  anvilPanelsMap,
  cladderPanelsMap,
  backerPanelsMap,
  assemblies,
  updatePanelsMap,
  updateAssemblies
}: {
  dismissModal: () => void;
  supplierData: SupplierData;
  buildAutocombinedGroups: (updatedMetalPanelsMap: Map<string, SeamPanels | Panels>) => MetalToAutocombine[][];
  autocombineMetals: (autocombinedGroups?: {
    quoteLineItemData: QuoteLineItem;
    quoteLineItemDetailData: QuoteLineItemDetail;
    metal: MSTOAssemblyMetals;
    panelNumber?: number;
    panelLocation?: PanelLocations;
    seamWeldPieceNumber?: number;
  }[][], assemblies?: any) => Map<string, AssemblyPanels[]>;
  activeTab: MSTOSelectionTabs;
  anvilPanelsMap;
  cladderPanelsMap;
  backerPanelsMap;
  assemblies?: any;
  updatePanelsMap: Function;
  updateAssemblies: Function
}) => {

  const ApplyCancelButtons = () => {
    // if the supplierChoice is SupplierContactFromQuoteRecords, and there is no vendor selection,
    // or if supplierShipDate is empty, there is an issue with the form, so disable Button
    const hasError =
      (supplierData.choice === AddPieceSuppliers.SupplierContactFromQuoteRecords && !supplierData.vendorId) ||
      supplierData.shipDate === '';

    // getUpdatedPanelsFieldsWithAppliedSupplierData - returns an object with the updated supplier fields for the correct panelsMap
    const getUpdatedPanelsMapWithAppliedSupplierData = (supplierData: SupplierData) => {
      // temp var to hold updated item field
      let customerProvidedFieldToUpdate: string;
      // temp vars to hold updated detail fields
      let panelsMapToUpdate: PanelsMap;
      let pullFromStockFieldToUpdate: string;
      let rawMaterialVendorFieldToUpdate: string;
      let shipDateFieldToUpdate: string;
      let purchaseFieldToUpdate: string;
      let configurationFieldToUpdate: string;
      let newAssemblies: any = Array.from(assemblies);

      // set temp vars based on active tab
      switch (activeTab) {
        case MSTOSelectionTabs.Anvil:
          panelsMapToUpdate = new Map(anvilPanelsMap);
          pullFromStockFieldToUpdate = 'PullfromstockBKAnvil__c';
          rawMaterialVendorFieldToUpdate = 'VendorAnvil__c';
          shipDateFieldToUpdate = 'ShipDateAnvil__c';
          purchaseFieldToUpdate = 'PurchaseBKAnvil__c';
          break;
        case MSTOSelectionTabs.Cladder:
          panelsMapToUpdate = new Map(cladderPanelsMap);
          customerProvidedFieldToUpdate = 'Customer_Provided_Clad__c';
          pullFromStockFieldToUpdate = 'Pullfromstockcladder__c';
          rawMaterialVendorFieldToUpdate = 'RawMaterialVendorCladder__c';
          shipDateFieldToUpdate = 'ShipDateCladder__c';
          purchaseFieldToUpdate = 'PurchaseCL__c';
          configurationFieldToUpdate = 'ConfigurationID__c';
          break;
        case MSTOSelectionTabs.Backer:
        default:
          panelsMapToUpdate = new Map(backerPanelsMap);
          customerProvidedFieldToUpdate = 'Customer_Provided_Base__c';
          pullFromStockFieldToUpdate = 'Pullfromstockbacker__c';
          rawMaterialVendorFieldToUpdate = 'RawMaterialVendorBacker__c';
          shipDateFieldToUpdate = 'ShipDateBase__c';
          purchaseFieldToUpdate = 'PurchaseBK__c';
          configurationFieldToUpdate = 'ConfigurationIDBacker__c';
          break;
      }

      // update panelsMap with supplier data
      if (panelsMapToUpdate.size > 0) {
        panelsMapToUpdate.forEach((payload) => {
          const { quoteLineItemData, quoteLineItemDetailData, seamWeldPieceNumber } = payload;
          // update supplier detail fields
          // if cladder should get the panel data and check if seamWeldPieceNumber exists
          // that indicates that the cladder is seam weld that has different attributes to updates
          if (activeTab === MSTOSelectionTabs.Cladder && seamWeldPieceNumber) {
            const updatesToSeamWeld = {
              [`RawMaterialVendorSW${seamWeldPieceNumber}__c`]: supplierData.vendorId,
              [`ShipDateSW${seamWeldPieceNumber}__c`]: supplierData.shipDate,
              [seamWeldPieceNumber === 1 ? 'ConfigurationID__c' : `ConfigurationIDSW${seamWeldPieceNumber}__c`]: null,
              [`PullFromStockSW${seamWeldPieceNumber}__c`]: supplierData.pullFromStock
            };
            newAssemblies = newAssemblies.map(([key, values]: any) => {
              const newValues = values.map((assemblyPayload) => {
                const {
                  metal,
                  quoteLineItemData: _quoteLineItemData,
                  quoteLineItemDetailData: _quoteLineItemDetailData
                } = assemblyPayload;
                if (
                  metal !== MSTOAssemblyMetals.CLAD
                  || !quoteLineItemData?.Line__c
                  || !quoteLineItemData?.piece
                  || _quoteLineItemData.Line__c !== quoteLineItemData?.Line__c
                  || _quoteLineItemData.piece !== quoteLineItemData?.piece
                ) {
                  return assemblyPayload;
                }

                return {
                  ...assemblyPayload,
                  quoteLineItemData: {
                    ..._quoteLineItemData,
                    [customerProvidedFieldToUpdate]: supplierData.customerProvided,
                  },
                  quoteLineItemDetailData: {
                    ..._quoteLineItemDetailData,
                    [shipDateFieldToUpdate]: null,
                    [configurationFieldToUpdate]: null,
                    [rawMaterialVendorFieldToUpdate]: null,
                    [pullFromStockFieldToUpdate]: supplierData.pullFromStock,
                    NC_Seam_Weld_Item_Details__r: {
                      ..._quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r,
                      ...updatesToSeamWeld,
                    }
                  }
                }
              });

              return [key, newValues];
            });

            quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r = {
              ...quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r,
              ...updatesToSeamWeld,
            };
            quoteLineItemDetailData[shipDateFieldToUpdate] = null;
            quoteLineItemDetailData[configurationFieldToUpdate] = null;
            quoteLineItemDetailData[rawMaterialVendorFieldToUpdate] = null;
            quoteLineItemDetailData[pullFromStockFieldToUpdate] = null;
            quoteLineItemData[customerProvidedFieldToUpdate] = supplierData.customerProvided;
            return;
          }

          quoteLineItemDetailData[rawMaterialVendorFieldToUpdate] = supplierData.vendorId;
          quoteLineItemDetailData[pullFromStockFieldToUpdate] = supplierData.pullFromStock;
          quoteLineItemDetailData[purchaseFieldToUpdate] = supplierData.purchase;

          if (activeTab === MSTOSelectionTabs.Anvil) {
            quoteLineItemDetailData.NC_Anvil_Item_Details__r[shipDateFieldToUpdate] = supplierData.shipDate;
          } else {
            quoteLineItemDetailData[shipDateFieldToUpdate] = supplierData.shipDate;
            quoteLineItemDetailData[configurationFieldToUpdate] = null;
            quoteLineItemData[customerProvidedFieldToUpdate] = supplierData.customerProvided;
          }
        });
      }

      const newAssembliesMap: Map<string, AssemblyPanels[]> = new Map(newAssemblies);

      return {
        panelsMapToUpdate,
        newAssembliesMap,
      };
    };

    return (
      <>
        <IonButton
          color='primary'
          class='submit-order-button'
          onClick={() => {
            const {panelsMapToUpdate: updatedPanelsMap, newAssembliesMap} = getUpdatedPanelsMapWithAppliedSupplierData(supplierData);
            updatePanelsMap(updatedPanelsMap);

            // const autocombinedGroups = buildAutocombinedGroups(updatedPanelsMap);
            // const updatedAssemblies = autocombineMetals(autocombinedGroups, newAssembliesMap);
            updateAssemblies(newAssembliesMap);

            dismissModal();
          }}
          disabled={hasError}
        >
          Apply
        </IonButton>
        <IonButton color='secondary' class='cancel-button' onClick={() => dismissModal()}>
          Cancel
        </IonButton>
      </>
    );
  };

  return (
    <div className='save-cancel-button-container'>
      <IonGrid>
        <IonRow>
          <IonCol>
            <ApplyCancelButtons />
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};