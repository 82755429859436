import { isPlatform } from '@ionic/react';
import { Platforms } from '@ionic/core';
import * as React from 'react';

const PLATFORMS: {
  MOBILE: Platforms[],
  TABLET: Platforms[],
} = {
  MOBILE: ['iphone', 'android'],
  TABLET: ['ipad', 'tablet'],
};

const { createContext, useContext } = React;

const PlatformContext = createContext(null);

export const PlatformProvider = (props) => {
  const value = {
    isMobile: props.isMobile || isMobile,
    isTablet: props.isTablet || isTablet,
    isMobileOrTablet: props.isMobileOrTablet || isMobileOrTablet,
  };

  return (
    <PlatformContext.Provider value={value}>
      {props.children}
    </PlatformContext.Provider>
  );
};

export const usePlatform = () => {
  return useContext(PlatformContext);
};

// Returns true if the device used to access app is an iphone or android device
export const isMobile = () => {
  const platforms: Platforms[] = PLATFORMS.MOBILE;

  return platforms.some(p => isPlatform(p));
};

// Returns true if the device used to access app is an ipad
export const isTablet = () => {
  const platforms: Platforms[] = PLATFORMS.TABLET;

  return platforms.some(p => isPlatform(p));
};

// Returns true if the device used to access app is either a mobile or tablet device
export const isMobileOrTablet = () => {
  const platforms: Platforms[] = [
    ...PLATFORMS.MOBILE,
    ...PLATFORMS.TABLET,
  ];

  return platforms.some(p => isPlatform(p));
};
