import * as React from 'react';
import { Observable, combineLatest } from 'rxjs';
import Account from '@interfaces/account';
import User from '@interfaces/user';
import Contact from '@interfaces/contact';
import { map } from 'rxjs/operators';
import { user$ as userState$, contact$ as contactState$, account$ as accountState$, setContact, setAccount } from './salesforce-state';
import { getAccount, getAccountTeam, getContact, getOrderById, getOrderLineItems, getOrders, getInvoices, getInvoiceSearchValues, getInvoiceById, getInvoiceLineItems, getOrderDocuments, getQuoteById, getQuoteGroupByQuoteId, getQuoteGroupDetailsByItemIds, getQuoteLineItems, getQuoteLineItemDetails, updateQuoteById, getOrderSearchValues, getInvoiceDocuments, cloneQuoteById, updateOrderById, updateFullQuoteDataById, updateQuoteStatus } from './salesforce-api';
import { Order } from '@interfaces/order';
import { ProjectTeam } from '@interfaces/project-team';
import { Invoice } from '@interfaces/invoice';
import { NCLineItem } from '@interfaces/nc-line-item';
import { DocumentData } from '@interfaces/document-data';
import { Quote } from '@interfaces/quote';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { excludeFields } from '@shared/utils';

const { createContext, useContext } = React;

const SalesforceContext = createContext(null);

export const SalesforceProvider = (props) => {
  const value = React.useMemo(() => ({
    userInfo$: props.userInfo$ || userInfo$,
    user$: props.user$ || user$,
    contact$: props.contact$ || contact$,
    account$: props.account$ || account$,
    loadContactAccount: props.loadContactAccount || loadContactAccount,
    getAccountOrders: props.getAccountOrders || getAccountOrders,
    getAccountOrderSearchValues: props.getAccountOrderSearchValues || getAccountOrderSearchValues,
    getAccountOrderById: props.getAccountOrderById || getAccountOrderById,
    updateAccountOrderById: props.updateAccountOrderById || updateAccountOrderById,
    getAccountOrderLineItems: props.getAccountOrderLineItems || getAccountOrderLineItems,
    getAccountOrderDocuments: props.getAccountOrderDocuments || getAccountOrderDocuments,
    getContactAccountTeam: props.getContactAccountTeam || getContactAccountTeam,
    getAccountInvoices: props.getAccountInvoices || getAccountInvoices,
    getAccountInvoiceSearchValues: props.getAccountInvoiceSearchValues || getAccountInvoiceSearchValues,
    getAccountInvoiceById: props.getAccountInvoiceById || getAccountInvoiceById,
    getAccountInvoiceLineItems: props.getAccountInvoiceLineItems || getAccountInvoiceLineItems,
    getAccountInvoiceDocuments: props.getAccountInvoiceDocuments || getAccountInvoiceDocuments,
    getAccountQuoteById: props.getAccountQuoteById || getAccountQuoteById,
    getSalesforceQuoteAndItemsByQuoteId: props.getSalesforceQuoteAndItemsByQuoteId || getSalesforceQuoteAndItemsByQuoteId,
    getSalesforceQuoteItemDetailsByItemIds: props.getSalesforceQuoteItemDetailsByItemIds || getSalesforceQuoteItemDetailsByItemIds,
    getAccountQuoteLineItems: props.getAccountQuoteLineItems || getAccountQuoteLineItems,
    getAccountQuoteLineItemDetails: props.getAccountQuoteLineItemDetails || getAccountQuoteLineItemDetails,
    updateAccountQuoteById: props.updateAccountQuoteById || updateAccountQuoteById,
    cloneAccountQuoteById: props.cloneAccountQuoteById || cloneAccountQuoteById,
    getAccountById: props.getAccountById || getAccountById,
    updateFullQuoteById: props.updateFullQuoteById || updateFullQuoteById,
    updateStatus: props.updateStatus || updateStatus,
  }), [props.account$, props.cloneAccountQuoteById, props.contact$, props.getAccountById, props.getAccountInvoiceById, props.getAccountInvoiceDocuments, props.getAccountInvoiceLineItems, props.getAccountInvoiceSearchValues, props.getAccountInvoices, props.getAccountOrderById, props.getAccountOrderDocuments, props.getAccountOrderLineItems, props.getAccountOrderSearchValues, props.getAccountOrders, props.getAccountQuoteById, props.getAccountQuoteLineItemDetails, props.getAccountQuoteLineItems, props.getContactAccountTeam, props.getSalesforceQuoteAndItemsByQuoteId, props.getSalesforceQuoteItemDetailsByItemIds, props.loadContactAccount, props.updateAccountOrderById, props.updateAccountQuoteById, props.user$, props.userInfo$]);

  return (
    <SalesforceContext.Provider value={value}>
      {props.children}
    </SalesforceContext.Provider>
  );
};

export const useSalesforce = () => {
  return useContext(SalesforceContext);
};

export interface UserInfo {
  account: Account,
  contact: Contact,
  user: User
}

export const userInfo$ = (): Observable<UserInfo> => {
  return combineLatest([user$(), contact$(), account$()]).pipe(
    map((userInfo) => ({
      user: userInfo[0],
      contact: userInfo[1],
      account: userInfo[2]
    }))
  );
};

export const user$ = (): Observable<User> => {
  return userState$();
};

export const contact$ = (): Observable<Contact> => {
  return contactState$();
};

export const account$ = (): Observable<Account> => {
  return accountState$();
};

export async function loadContactAccount(contactId: string) {
  const contact = await getContact(contactId);
  setContact(contact);

  const account = await getAccount(contact.AccountId);
  setAccount(account);
}

// Gets the salesforce orders based on the account
export const getAccountOrders = (limit?: number): Promise<Order[]> => {
  return getOrders(limit);
};

// Gets the salesforce order search values
export const getAccountOrderSearchValues = (): Promise<Order[]> => {
  return getOrderSearchValues();
};

// Gets the salesforce order based on the order id
export const getAccountOrderById = (orderId: string): Promise<Order> => {
  return getOrderById(orderId);
};

// Saves the specified order to salesforce
export const updateAccountOrderById = (orderId: string, order: Order): Promise<any> => {
  return updateOrderById(orderId, order);
};

// Gets the salesforce order line items based on the order id
export const getAccountOrderLineItems = (orderId: string): Promise<NCLineItem[]> => {
  return getOrderLineItems(orderId);
};

// Gets the salesforce order documents based on the order id
export const getAccountOrderDocuments = (orderId: string): Promise<DocumentData[]> => {
  return getOrderDocuments(orderId);
};

// Gets the salesforce account team based on the account
export const getContactAccountTeam = (): Promise<ProjectTeam[]> => {
  return getAccountTeam();
};

// Gets the salesforce invoices based on the account
export const getAccountInvoices = (limit?: number): Promise<Invoice[]> => {
  return getInvoices(limit);
};

// Gets the salesforce invoice search values
export const getAccountInvoiceSearchValues = (): Promise<Invoice[]> => {
  return getInvoiceSearchValues();
};

// Gets the salesforce invoice based on the invoice id
export const getAccountInvoiceById = (invoiceId: string): Promise<Invoice> => {
  return getInvoiceById(invoiceId);
};

// Gets the salesforce invoice line items based on the invoice id
export const getAccountInvoiceLineItems = (invoiceId: string): Promise<NCLineItem[]> => {
  return getInvoiceLineItems(invoiceId);
};

// Gets the salesforce invoice documents based on the invoice id
export const getAccountInvoiceDocuments = (invoiceId: string): Promise<DocumentData[]> => {
  return getInvoiceDocuments(invoiceId);
};

// Gets the salesforce quote based on the quote id
export const getAccountQuoteById = (quoteId: string): Promise<Quote> => {
  return getQuoteById(quoteId);
};

// Gets the salesforce quote line items based on the quote id
export const getAccountQuoteLineItems = (quoteId: string): Promise<QuoteLineItem[]> => {
  return getQuoteLineItems(quoteId);
};

// Gets the salesforce quote line item details based on the quote line item id
export const getAccountQuoteLineItemDetails = (quoteLineItemId: string): Promise<QuoteLineItemDetail[]> => {
  return getQuoteLineItemDetails(quoteLineItemId);
};

// Saves the specified quote to salesforce
export const updateAccountQuoteById = (quoteId: string, quoteMap: any): Promise<any> => {
  return updateQuoteById(quoteId, quoteMap);
};

// Clones the specified quote to salesforce
export const cloneAccountQuoteById = (quoteId: string): Promise<any> => {
  return cloneQuoteById(quoteId);
};

// Gets the salesforce account based on the account id
export const getAccountById = (accountId: string): Promise<any> => {
  return getAccount(accountId);
};

// Gets the salesforce quote group based on the quote id
export const getSalesforceQuoteAndItemsByQuoteId = (quoteId: string): Promise<Quote> => {
  const quoteGroup = getQuoteGroupByQuoteId(quoteId);
  return quoteGroup;
};

// Gets the salesforce quote group details based on the quote id
export const getSalesforceQuoteItemDetailsByItemIds = (quoteId: string): Promise<Quote> => {
  const quoteGroupDetails = getQuoteGroupDetailsByItemIds(quoteId);
  return quoteGroupDetails;
};

// Saves the specified quote to salesforce
export const updateFullQuoteById = (quoteId: string, quoteData: any): Promise<any> => {
  console.log('saving to salesforce', quoteData);
  const finalQuote = excludeFields(quoteData);
  return updateFullQuoteDataById(quoteId, finalQuote);
};

export const updateStatus = (quoteId: string, quoteStatus: string): Promise<any> => {
  return updateQuoteStatus(quoteId, quoteStatus);
};
