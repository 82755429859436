import { IonIcon, IonLabel } from '@ionic/react';
import React, { useState } from 'react';
import { chevronDown, chevronUp } from 'ionicons/icons';
import './accordion.scss';

const Accordion = (props: {
  title: string,
  content: JSX.Element
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="accordion">
      <div className="top">
        <div className="accordion-expand-section" onClick={() => setIsExpanded(!isExpanded)}>
          <IonLabel class='accordion-title'>{props.title}</IonLabel>
          <IonIcon className='accordion-expand-button' data-testid="QAAccordionExpandButton" src={!isExpanded ? chevronUp : chevronDown}></IonIcon>
        </div>
      </div>
      {isExpanded && <div className='accordion-content'>{props.content}</div>}

    </div>
  );
};

export default Accordion;