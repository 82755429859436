import SelectionContainer from '@components/selection-container/selection-container';
import { SelectionTypes } from '@constants';
import { useQuoteState } from '@context/quote-context';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { IonButton, IonLabel } from '@ionic/react';
import { OrderEntryLineItemFieldsToUpdate } from '@shared/types';
import React from 'react';
import './product-tag-popover.scss';

const ProductTagPopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
    showPopover: boolean;
    event: any;
  }>>,
  quoteLineItem?: QuoteLineItem,
  quoteLineItemFieldsToUpdate: { [key: string]: OrderEntryLineItemFieldsToUpdate; },
  setQuoteLineItemFieldsToUpdate: React.Dispatch<React.SetStateAction<{ [key: string]: OrderEntryLineItemFieldsToUpdate; }>>
}) => {
  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const {
    quoteLineItems
  } = quote || {};

  const quoteLineItemId = props.quoteLineItem?.Id || props.quoteLineItem?.displayId;
  const selectedQuoteLineItem = props.quoteLineItemFieldsToUpdate[quoteLineItemId];
  const selectedProductTagJSON = selectedQuoteLineItem?.productTag ? JSON.parse(selectedQuoteLineItem.productTag) : null;
  const [productTag, setProductTag] = React.useState(selectedProductTagJSON?.CustomerTags.find(customerTag => customerTag.CustomerTag).CustomerTag);
  const [quoteLineItemFieldsToUpdate] = React.useState(props.quoteLineItemFieldsToUpdate);

  // dismissModal - hides order entry modal
  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const createTagJSON = (lineItem: QuoteLineItem) => {
    const groupTags = [];
    const productsToTag = Array.from({length: lineItem.Quantity__c}, (_, i) => i + 1);

    productsToTag.forEach(product => {
      const tagObject = {
        'PartNumber': product,
        'CustomerTag': productTag
      };

      groupTags.push(tagObject);
    });

    const productTagsObject = {'CustomerTags': groupTags};
    const productTagsJSON = JSON.stringify(productTagsObject);

    return productTagsJSON;
  };

  const saveQuoteLineItemDetails = () => {

    if(props.quoteLineItem) {
      //function for tag group
      const productTags = createTagJSON(props.quoteLineItem);
      quoteLineItemFieldsToUpdate[props.quoteLineItem.Id || props.quoteLineItem.displayId].productTag = productTags;
    } else {
      //function for tag all
      quoteLineItems.forEach(quoteLineItem => {
        const productTags = createTagJSON(quoteLineItem);
        quoteLineItemFieldsToUpdate[quoteLineItem.Id || quoteLineItem.displayId].productTag = productTags;
      });
    }

    props.setQuoteLineItemFieldsToUpdate(quoteLineItemFieldsToUpdate);
  };

  return (
    <div className='popover product-tag'>
      <div className='content-container'>
        <IonLabel class='product-tag-title'>{!props.quoteLineItem ? 'Tag All Products' : `Tag Line ${+props.quoteLineItem.Line__c} Products`}</IonLabel>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          placeholder='Product Tag'
          inputType='text'
          style={{ width: '202px' }}
          onBlur={productTag => setProductTag(productTag)}
          value={productTag}
        />
        <IonButton
          color='primary'
          class='confirm-button'
          data-testid={'QAProductTagPopoverConfirmButton'}
          onClick={() => {
            saveQuoteLineItemDetails();
            dismissModal();
          }}
        >
          Confirm
        </IonButton>
        <IonButton color='secondary' class='cancel-button' onClick={() => dismissModal()}>
          Cancel
        </IonButton>
      </div>
    </div>
  );
};

export default ProductTagPopover;