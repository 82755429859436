import AppDropdown from '@components/app-dropdown/app-dropdown';
import AppDropdownFerroxylTest from '@components/app-dropdown/app-dropdown-ferroxyl-test';
import AppDropdownSelection from '@components/app-dropdown/app-dropdown-selection';
import AppInput from '@components/app-input/app-input';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';
import AppRadioButtons from '@components/app-radio-buttons/app-radio-buttons';
import AppTextarea from '@components/app-textarea/app-textarea';
import AppCheckboxItem from '@components/app-checkbox-item/app-checkbox-item';
import { MetalPurchaseRequirementsGroups, QuoteLineItemValueTypes, RawMaterialUsage } from '@constants';
import { RawMaterial } from '@interfaces/raw-material';
import { getDefaultMetalPurchaseRequirement } from '@shared/quote-utils';
import React, { useEffect, useState } from 'react';
import LineItem from '../line-item/line-item';
import Section from './section';
import { FormKeys } from '../advance-specification-form-util';
import { useDataState } from '@context/data-context';

// Customer Metal Supplier Restrictions
const CustomerMetalSupplierRestrictionsDropdown = (props) => {
  const { dataState } = useDataState();
  const { rawMaterials } = dataState;
  const [excludeValues, setExcludeValues] = useState([]);
  const { areaId, cladMetal, value, onChange, disabled } = props;

  const init = () => {
    if (!cladMetal || !areaId || rawMaterials.length === 0) {
      return;
    }

    const material = rawMaterials.find((rawMaterial: RawMaterial) =>
        rawMaterial.name === cladMetal &&
        rawMaterial.usage === RawMaterialUsage.Clad &&
        rawMaterial.dataAreaId.includes(areaId)
    )

    const defaultMetalSupplierRestrictions = getDefaultMetalPurchaseRequirement(material, areaId, MetalPurchaseRequirementsGroups.SupplierRestrictions);

    setExcludeValues([defaultMetalSupplierRestrictions]);
  }

  useEffect(() => {
    init();
  }, [rawMaterials])

  useEffect(() => {
    init();
  }, []);

  return <>
    <AppDropdownSelection
      areaId={areaId}
      valueType={QuoteLineItemValueTypes.CladMetalPurchaseRequirements}
      excludeValues={excludeValues}
      attrLabel='description'
      attrValue='description'
      value={value}
      onChange={onChange}
      disabled={disabled}
      firstOptionDefault={false}
    />
  </>
};

const CladdingMetal = (prop) => {
  const {
    form,
    updateForm,
    areaId,
    updateFormByPayload,
  } = prop;
  const segmentKey = prop.segmentKey;

  useEffect(() => {
    const values = {
      [FormKeys.CladMetalSpecEdition]: form[FormKeys.RequiredCladdingMetalSpecEdAd] ? form[FormKeys.CladMetalSpecEdition] : null,
      [FormKeys.CladMetalSpecAddendum]: form[FormKeys.RequiredCladdingMetalSpecEdAd] ? form[FormKeys.CladMetalSpecAddendum] : null,
    };

    updateFormByPayload(values);
  }, [form[FormKeys.RequiredCladdingMetalSpecEdAd]])

  return <Section title="Cladding Metal" {...prop}>
    <LineItem label='NC Heat Treatment as Supplied' specifyKey={`${segmentKey}.heat-treatment-as-supplied`}>
      <AppLineItemColumns components={[
        {
          key: 'heat-treatment-as-supplied-dropdown',
          size: 4,
          component: <AppDropdownSelection
            areaId={areaId}
            valueType={QuoteLineItemValueTypes.NCCladHeatTreatmentasSupplied}
            value={form[FormKeys.NCCladHeatTreatmentasSupplied]}
            onChange={({value}) => updateForm(FormKeys.NCCladHeatTreatmentasSupplied, value)}
          />
        },
        {
          key: 'heat-treatment-as-supplied-input',
          size: 8,
          component: <AppInput
            placeholder='Additional Note'
            value={form[FormKeys.NCCladHeatTreatmentasSuppliedNotes]}
            onChange={(value) => updateForm(FormKeys.NCCladHeatTreatmentasSuppliedNotes, value)}
          />
        }
      ]} />
    </LineItem>

    <LineItem label='NC Chemistry Restrictions for Bonding' specifyKey={`${segmentKey}.chemistry-restriction`}>
      <AppLineItemColumns components={[
        {
          key: 'chemistry-restriction-dropdown',
          size: 4,
          component: <AppDropdownSelection
            areaId={areaId}
            valueType={QuoteLineItemValueTypes.NCCladChemistryRestrictionsforBonding}
            value={form[FormKeys.NCCladChemistryRestrictionsforBonding]}
            onChange={({value}) => updateForm(FormKeys.NCCladChemistryRestrictionsforBonding, value)}
          />
        },
        {
          key: 'chemistry-restriction-input',
          size: 8,
          component: <AppInput
            placeholder='Additional Note'
            value={form[FormKeys.NCCladChemistryRestForBondingNotes]}
            onChange={(value) => updateForm(FormKeys.NCCladChemistryRestForBondingNotes, value)}
          />
        }
      ]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="Customer Chemistry Restrictions"
        checked={form[FormKeys.RequiredCladdingCustomerChemRestriction]}
        onChange={(value) => updateForm(FormKeys.RequiredCladdingCustomerChemRestriction, value)}
      />}
      specifyKey={`${segmentKey}.customer-chemistry-restriction`}
    >
      <AppLineItemColumns components={[
        {
          key: 'customer-chemistry-restriction',
          size: 8,
          component: <AppInput
            value={form[FormKeys.CustomerCladChemistryRestrictionsNotes]}
            disabled={!form[FormKeys.RequiredCladdingCustomerChemRestriction]}
            onChange={(value) => updateForm(FormKeys.CustomerCladChemistryRestrictionsNotes, value)}
          />
        }
      ]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="Corrosion Testing"
        checked={form[FormKeys.RequiredCorrosionTesting]}
        onChange={(value) => updateForm(FormKeys.RequiredCorrosionTesting, value)}
      />}
      specifyKey={`${segmentKey}.corrosion-testing`}
    >
      <AppLineItemColumns components={[
        {
          key: 'corrosion-testing-dropdown',
          size: 4,
          component: <AppDropdownSelection
            areaId={areaId}
            disabled={!form[FormKeys.RequiredCorrosionTesting]}
            valueType={QuoteLineItemValueTypes.CorrosionTesting}
            value={form[FormKeys.CorrosionTesting]}
            firstOptionDefault={false}
            onChange={({value}) => updateForm(FormKeys.CorrosionTesting, value)}
          />
        },
        {
          key: 'corrosion-testing-input',
          size: 8,
          component: <AppInput
            placeholder='Additional Note'
            disabled={!form[FormKeys.RequiredCorrosionTesting]}
            value={form[FormKeys.CorrosionTestingNotes]}
            onChange={(value) => updateForm(FormKeys.CorrosionTestingNotes, value)}
          />
        }
      ]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="Metal Spec Ed and Ad (if not latest)"
        checked={form[FormKeys.RequiredCladdingMetalSpecEdAd]}
        onChange={(value) => updateForm(FormKeys.RequiredCladdingMetalSpecEdAd, value)}
      />}
      specifyKey={`${segmentKey}.metal-spec-ed-ad`}
    >
      <AppLineItemColumns components={[
        {
          key: 'metal-spec-ed-ad-input1',
          size: 4,
          component: <AppInput
            disabled={!form[FormKeys.RequiredCladdingMetalSpecEdAd]}
            value={form[FormKeys.CladMetalSpecEdition]}
            onChange={(value) => updateForm(FormKeys.CladMetalSpecEdition, value)}
          />
        },
        {
          key: 'metal-spec-ed-ad-input2',
          size: 8,
          component: <AppInput
            disabled={!form[FormKeys.RequiredCladdingMetalSpecEdAd]}
            value={form[FormKeys.CladMetalSpecAddendum]}
            onChange={(value) => updateForm(FormKeys.CladMetalSpecAddendum, value)}
          />
        }
      ]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="Customer Metal Supplier Restrictions"
        checked={form[FormKeys.RequiredCladdingCustomerMetalSupplierRestriction]}
        onChange={(value) => updateForm(FormKeys.RequiredCladdingCustomerMetalSupplierRestriction, value)}
      />}
      specifyKey={`${segmentKey}.customer-metal`}
    >
      <AppLineItemColumns components={[
        {
          key: 'customer-metal-dropdown',
          size: 4,
          component: <CustomerMetalSupplierRestrictionsDropdown
            disabled={!form[FormKeys.RequiredCladdingCustomerMetalSupplierRestriction]}
            cladMetal={form[FormKeys.CladMetal]}
            areaId={areaId}
            value={form[FormKeys.CustomerCladMetalSupplierRestrictions]}
            onChange={({value}) => updateForm(FormKeys.CustomerCladMetalSupplierRestrictions, value)}
          />
        },
        {
          key: 'customer-metal-input',
          size: 8,
          component: <AppInput placeholder='Additional Note'
            disabled={!form[FormKeys.RequiredCladdingCustomerMetalSupplierRestriction]}
            value={form[FormKeys.CustCladMetalSupplierRestrictionsNotes]}
            onChange={(value) => updateForm(FormKeys.CustCladMetalSupplierRestrictionsNotes, value)}
          />
        }
      ]} />
    </LineItem>
    <LineItem
      label={<AppCheckboxItem
        label="Metal Product Analysis Required"
        checked={form[FormKeys.RequiredCladdingMetalProdAnalysis]}
        onChange={(value) => updateForm(FormKeys.RequiredCladdingMetalProdAnalysis, value)}
      />}
      specifyKey={`${segmentKey}.metal-product`}
    >
      <AppLineItemColumns components={[
        {
          key: 'metal-product-dropdown',
          size: 4,
          component: <AppDropdownSelection
            disabled={!form[FormKeys.RequiredCladdingMetalProdAnalysis]}
            areaId={areaId}
            valueType={QuoteLineItemValueTypes.CladMetalProductAnalysisRequired}
            value={form[FormKeys.CladMetalProductAnalysisRequired]}
            firstOptionDefault={false}
            onChange={({value}) => updateForm(FormKeys.CladMetalProductAnalysisRequired, value)}
          />
        },
        {
          key: 'metal-product-input',
          size: 8,
          component: <AppInput placeholder='Additional Note'
            disabled={!form[FormKeys.RequiredCladdingMetalProdAnalysis]}
            value={form[FormKeys.CladMetalProductAnalysisRequiredNotes]}
            onChange={(value) => updateForm(FormKeys.CladMetalProductAnalysisRequiredNotes, value)}
          />
        }
      ]} />
      <AppLineItemColumns components={[{
        key: 'metal-product-dropdown',
        component: <AppRadioButtons
          name={FormKeys.CladMetalProductAnalysisType}
          options={['Performed by Vendor', 'Performed by Independent Test Lab']}
          disabled={!form[FormKeys.RequiredCladdingMetalProdAnalysis]}
          selected={form[FormKeys.CladMetalProductAnalysisType]}
          onChange={(value) => updateForm(FormKeys.CladMetalProductAnalysisType, value)}
        />
      }]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="Ferroxyl Test of Cladding Surface Performed"
        checked={form[FormKeys.RequiredFerroxlyTestOfCladdingSurface]}
        onChange={(value) => updateForm(FormKeys.RequiredFerroxlyTestOfCladdingSurface, value)}
      />}
      specifyKey={`${segmentKey}.ferroxyl-test`}
    >
      <AppLineItemColumns components={[
        {
          key: 'ferroxyl-test-dropdown',
          size: 4,
          component: <AppDropdownFerroxylTest
            disabled={!form[FormKeys.RequiredFerroxlyTestOfCladdingSurface]}
            value={form[FormKeys.FerroxylTestPerformed]}
            onChange={({value}) => updateForm(FormKeys.FerroxylTestPerformed, value)}
          />
        },
        {
          key: 'ferroxyl-test-input',
          size: 8,
          component: <AppInput placeholder='Additional Note'
            disabled={!form[FormKeys.RequiredFerroxlyTestOfCladdingSurface]}
            value={form[FormKeys.FerroxylNotes]}
            onChange={(value) => updateForm(FormKeys.FerroxylNotes, value)}
          />
        }
      ]} />
    </LineItem>

    <LineItem
      label="Additional Requirements"
      specifyKey={`${segmentKey}.additional-requirements`}
    >
      <AppLineItemColumns components={[{
        key: 'additional-requirements',
        component: <AppTextarea
          value={form[FormKeys.AdditionalRequirementsCladdingMetal]}
          onChange={(value) => updateForm(FormKeys.AdditionalRequirementsCladdingMetal, value)}
          rows={5}
          placeholder="Specify Additional Requirements..."
        />
      }]} />
    </LineItem>
  </Section>
}

export default CladdingMetal;