/* eslint-disable dot-notation */
import { Capacitor } from '@capacitor/core';
import Loading from '@components/loading/loading';
import Toast from '@components/toast/toast';
import { ProfileNames, ProfileSettings, UserType, VaultLockTypes, internalViewInitialInvoiceLimit, internalViewInitialOrderLoadLimit } from '@constants';
import { environment } from '@environment';
import { useDarkMode } from '@hooks/useDarkMode';
import { Device } from '@ionic-enterprise/identity-vault';
import {
  IonApp
} from '@ionic/react';
import { useAuth } from '@services/auth-service';
import { useEnvironment } from '@services/environment-service';
import { useNavigation } from '@services/navigation-service';
import { useSalesforce } from '@services/salesforce/salesforce-service';
import { useToastStateContext } from '@services/toast-service';
import { useUser } from '@services/user/user-service';
import tokenUtils from '@utils/token-utils';
import axios from 'axios';
import moment from 'moment';
import OneSignal from 'onesignal-cordova-plugin';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './app.scss';
import { useVault } from './hooks/useVault';
import { Notification } from '@interfaces/notification';
import { useSync } from '@context/useSync';
import { ContentContainer } from './components/content-container/content-container';
import { HeaderContainer } from './header-container';
import { useCurrencyConversions } from '@services/currency-conversions/currency-conversions-service';
import { mapNotification } from '@components/content-container/content-utils';
import { useNotifications } from '@services/notifications/notifications-service';
import { Invoice } from '@interfaces/invoice';
import { Order } from '@interfaces/order';
import { MappedNotification } from '@shared/types';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingMessage] = React.useState('Loading...');

  const [accessTokenInterceptor, setAccessTokenInterceptor] = React.useState(undefined);
  const [tokenInterceptorId, setTokenInterceptorId] = React.useState(1);
  const isInitialized = React.useRef(false);

  const { authenticating, token, storeToken, restoreToken, setLockType, storeAccessToken, restoreAccessToken } = useVault();
  const navigation = useNavigation();
  const auth = useAuth();

  const salesforce = useSalesforce();
  const user = useUser();
  const notifications = useNotifications();
  const currencyConversions = useCurrencyConversions();
  const environmentService = useEnvironment();
  const location = useLocation();
  const { initializeDarkMode } = useDarkMode();
  const history = useHistory();
  const { setIsLoading: setIsLoadLoading } = useSync();

  const [orders, setOrders] = React.useState(undefined);
  const [orderSearchValues, setOrderSearchValues] = React.useState(undefined);
  
  const [invoices, setInvoices] = React.useState(undefined);
  const [invoiceSearchValues, setInvoiceSearchValues] = React.useState(undefined);
  
  const [unmappedNotifications, setUnmappedNotifications] = React.useState(undefined);
  const [finalizedNotifications, setFinalizedNotifications] = React.useState(undefined);

  React.useEffect(() => {
    if(Capacitor.isNativePlatform()) {
      OneSignalMobileInit();
    } else {
      initialize();
    }
  }, []);

  React.useEffect(() => {
    auth.setIsLoggedIn(isLoggedIn);

    if(isLoggedIn === false) {
      const loadingPromises =
        [
          preloadMenu()
        ];

      Promise.all(loadingPromises)
        .catch(err => {
          console.error(err);
        });
    }

    if(isLoggedIn === true || isLoggedIn === false) {
      setIsLoading(false);
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (!token) {
      if(authenticating) {
        setIsLoggedIn(false);
      }

      return;
    }
    setIsLoadLoading(true);
    axios.interceptors.request.use(async request => {
      request.headers['Authorization'] = `Bearer ${token}`;
      return request;
    },
    error => {
      return Promise.reject(error);
    });

    axios.interceptors.request.eject(accessTokenInterceptor);

    isSessionValid().then((sessionValid) => {
      if(sessionValid) {
        const { contactId, userType, internalView, profileName } = tokenUtils.decode(token);
        const isAdminOrPlanning = profileName === ProfileNames.SystemAdmin || profileName === ProfileNames.NobelCladPlanning;

        const commonLoadingPromises = [
          preloadContactAccount(contactId),
          preloadUserSettings(),
          preloadMenu(),
          preloadCurrencyConversions(),
          preloadNotifications(),
        ];
        
        const conditionalLoadingPromises = internalView
          ? [
              ...commonLoadingPromises,
              preloadOrders(internalViewInitialOrderLoadLimit),
              preloadOrderSearchValues(),
              preloadInvoices(internalViewInitialInvoiceLimit),
              preloadInvoiceSearchValues(),
            ]
          : [
              ...commonLoadingPromises,
              preloadOrders(),
              preloadInvoices(),
            ];
        
        const loadingPromises = conditionalLoadingPromises;

        Promise.all(loadingPromises)
          .then(() => {
            console.debug('preLoading resolved');
            setIsLoggedIn(true);
            setIsLoadLoading(false);
          })
          .catch(async err => {
            console.error(err);

            if(userType !== UserType.Sales) {
              axios.interceptors.request.eject(tokenInterceptorId);
              setTokenInterceptorId(tokenInterceptorId + 2);
              setAccessTokenInterceptor(axios.interceptors.request.use(async request => {
                request.headers['Authorization'] = `Bearer ${await restoreAccessToken()}`;
                return request;
              },
              error => {
                return Promise.reject(error);
              }));

              const { token } = await auth.generateTokenForExternal();
              await storeToken(token);
            }
          });

        environmentService.setUserType(userType);
        environmentService.setInternalView(internalView);
        environmentService.setIsAdminOrPlanning(isAdminOrPlanning);
      } else if(environmentService.userType !== UserType.Sales) {
        auth.authConnectService.logout();
      }
    });
  }, [token]);

  function checkRoute(route) {
    // When a route change happens outside of app.tsx, location.pathname is not recognized
    // Replace the route manually to allow topbar's useEffect to recognize the location change
    if (route.pathname !== location.pathname) {
      history.replace(route.pathname, route.state);
    }
  }

  let query: URLSearchParams;

  // Call this function when your app starts
  function OneSignalMobileInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);

    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId(environment.ONESIGNAL_APP_ID);

    OneSignal.setNotificationOpenedHandler(function(jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));

      const additionalData = jsonData?.notification?.additionalData;
      if(additionalData) {
        const link = additionalData['link'];

        if(link) {
          history.push(link);
        }
      }
    });

    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      console.log('User accepted notifications: ' + accepted);
      initialize();
    });
  }

  async function OneSignalUserSetup(email, oneSignalEmailHash, userId, oneSignalExternalIdHash): Promise<void> {
    if(Capacitor.isNativePlatform()) {
      OneSignal.setEmail(email, oneSignalEmailHash, (results) => {
        // The results will contain push and email success statuses
        console.log('Results of setting email');
        console.log(results);

        // Push can be expected in almost every situation with a success status, but
        // as a pre-caution its good to verify it exists
        if (results['push'] && results['push'].success) {
          console.log('Results of setting email push status:');
          console.log(results['push'].success);
        }

        // Setting External User Id with Callback Available in SDK Version 3.9.3+
        OneSignal.setExternalUserId(userId, oneSignalExternalIdHash, (results) => {
          // The results will contain push and email success statuses
          console.log('Results of setting external user id');
          console.log(results);

          // Push can be expected in almost every situation with a success status, but
          // as a pre-caution its good to verify it exists
          if (results['push'] && results['push'].success) {
            console.log('Results of setting external user id push status:');
            console.log(results['push'].success);
          }
        });
      });

      if(await Device.isBiometricsEnabled()) {
        setLockType(VaultLockTypes.Biometrics);
      } else if(await Device.isSystemPasscodeSet()) {
        setLockType(VaultLockTypes.SystemPasscode);
      }
    } else {
      user.addUserEmailToOnesignal(email, oneSignalEmailHash, userId, oneSignalExternalIdHash);
    }
  }

  const isSessionValid = async () => {
    const quoteId = sessionStorage.getItem('currentQuote');

    const { contactId } = token
      ? tokenUtils.decode(token)
      : {
        contactId: undefined,
      };

    if (contactId || quoteId) {
      const valid = await auth.checkToken(token);
      if (!valid) {
        return false;
      }
    }

    return true;
  };

  const checkIdTokenClaims = async () => {
    const idTokenClaims = await auth.authConnectService.getIdToken();

    if (idTokenClaims) {
      const accessToken = await auth.authConnectService.getAccessToken();
      storeAccessToken(accessToken);

      setAccessTokenInterceptor(axios.interceptors.request.use(async request => {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
        return request;
      },
      error => {
        return Promise.reject(error);
      }));

      const { token } = await auth.generateTokenForExternal();
      await storeToken(token);

      const { userId, oneSignalExternalIdHash, email, oneSignalEmailHash } = tokenUtils.decode(token);

      OneSignalUserSetup(email, oneSignalEmailHash, userId, oneSignalExternalIdHash);

      return true;
    }

    return false;
  };

  const initialize = async() => {
    initializeDarkMode();

    moment.locale(navigator.languages[0].toLowerCase());

    auth.authConnectService.loginStatusChanged.subscribe(authenticated => handleAuthChange(authenticated));

    salesforce.account$().subscribe(async account => {
      if (account) {
        await user.updateUserSetting(ProfileSettings.Currency, account.Currency_Symbol_For_Portals__c + ' ' + account.CurrencyIsoCode);
      }
    });

    try {
      /*if (!String.isNullOrEmpty(this.analyticsService.config.gtm_id)) {
        this.analyticsService.addGtmToDom();
      }*/

      await checkLogin();
      isInitialized.current = true;

    } catch (error) {
      // wait a bit for the UI to catch up...
      setTimeout(() => {
        setIsLoggedIn(false);
      }, 1500);
    }
  };

  // Handle login status change event
  const handleAuthChange = async (authenticated: boolean) => {
    setIsLoading(true);
    setIsLoadLoading(true);

    if (authenticated) {
      await checkLogin();
    } else {
      setIsLoggedIn(false);
    }

    history.push('/dashboard');
  };

  const preloadContactAccount = async(contactId) => {
    return new Promise((resolve, reject) => {
      salesforce.loadContactAccount(contactId)
        .then(() => resolve(true))
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  };

  const preloadUserSettings = async() => {
    return new Promise((resolve, reject) => {
      user.loadSettings()
        .then(() => resolve(true))
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  };

  const preloadMenu = async() => {
    return new Promise((resolve, reject) => {
      navigation.getNavigationOptions()
        .then(() => resolve(true))
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  };

  // Loads currency conversions into the cache
  const preloadCurrencyConversions = async () => {
    return new Promise((resolve, reject) => {
      currencyConversions.getCurrencyConversions()
        .then(() => resolve(true))
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  };

  // Loads the orders into the app
  const loadOrders = async(limit?: number) => {
    const orders = await salesforce.getAccountOrders(limit);
    setOrders(orders);
  };

  // Loads the order search values into the app
  const loadOrderSearchValues = async() => {
    const orderSearchValues = await salesforce.getAccountOrderSearchValues();
    setOrderSearchValues(orderSearchValues);
  };

  // Preloads contact orders
  const preloadOrders = async (limit?: number) => {
    return new Promise((resolve, reject) => {
      loadOrders(limit)
        .then(() => resolve(true))
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  };

  // Preloads order search values for internal users
  const preloadOrderSearchValues = async () => {
    return new Promise((resolve, reject) => {
      loadOrderSearchValues()
        .then(() => resolve(true))
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  };

  // Loads the invoices into the app
  const loadInvoices = async(limit?: number) => {
    const invoices = await salesforce.getAccountInvoices(limit);
    setInvoices(invoices);
  };

  // Loads the invoices search values into the app
  const loadInvoiceSearchValues = async() => {
    const invoiceSearchValues = await salesforce.getAccountInvoiceSearchValues();
    setInvoiceSearchValues(invoiceSearchValues);
  };

  // Preloads contact invoices
  const preloadInvoices = async (limit?: number) => {
    return new Promise((resolve, reject) => {
      loadInvoices(limit)
        .then(() => resolve(true))
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  };

  // Preloads invoice search values for internal users
  const preloadInvoiceSearchValues = () => {
    return loadInvoiceSearchValues()
      .then(() => true)
      .catch(err => {
        console.error(err);
        return Promise.reject();
      });
  };

  // Loads the contact notifications into the app
  const loadNotifications = async () => {
    const contactNotifications = await notifications.getContactNotifications();
    setUnmappedNotifications(contactNotifications);

    if(contactNotifications) {
      const mappedNotifications = contactNotifications.map(notification => mapNotification(notification));
      const finalizedContactNotifications = mappedNotifications.orderByDateTime(notification => notification.createdDateTime, false);

      setFinalizedNotifications(finalizedContactNotifications);
    }
  };

  // Preloads contact notifications
  const preloadNotifications = async () => {
    return new Promise((resolve, reject) => {
      loadNotifications()
        .then(() => resolve(true))
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  };

  const checkLogin = async(): Promise<boolean> => {
    return new Promise(async (resolve) => {
      query = new URLSearchParams(location.search);

      if (/*this.config.featureFlags.sfSSOAuth &&*/ query.has('access_token')) {
        const { token } = await auth.generateTokenSSO(query.get('access_token'));

        await storeToken(token);

        if(location.pathname !== '/') {
          query.delete('access_token');
          history.push(location.pathname, { queryParams: JSON.stringify(query) });
        }

        return resolve(true);
      } else if (query.has('sessionId') && query.has('serverUrl')) {
        const { token } = await auth.generateToken(query.get('sessionId'), query.get('serverUrl'));

        await storeToken(token);

        return resolve(true);
      } else if (query.has('automation')) {
        const { token } = await auth.generateTokenForAutomation(query.get('automation'));

        await storeToken(token);

        return resolve(true);
      } else {
        await checkIdTokenClaims();

        /*if (!this.config.featureFlags.allowAnonymousUsers) {
          if (
            userType !== UserType.Sales &&
            !this.msalService.getLoginInProgress()
          ) {
            try {
              return reject(
                this.msalService.loginRedirect()
              );
            } catch (error) {
              console.log('Error logging into Azure Account: ' + error);
            }
          }

          this.message = `You can't visit this page directly.`;
          return reject();
        }*/

        await restoreToken();
        return resolve(false);
      }
    });
  };

  const ToastContainer = () => {
    const { toasts } = useToastStateContext();

    return (!isLoading ?
      <div className="parent-toast-container">
        {toasts &&
          toasts.map((toast) => (
            <Toast
              id={toast.id}
              key={toast.id}
              type={toast.type}
              message={toast.message}
            />
          ))}
      </div> : null
    );
  };

  const LoadingContainer = () => {
    return (
      isLoading ?
        <div className="loading-container">
          <Loading message={loadingMessage} data-testid="'QAAppLoading'"></Loading>
        </div> : null
    );
  };

  interface AppHeaderContainerProps {
    orders: Order[];
    orderSearchValues: any[];
    invoices: Invoice[];
    invoiceSearchValues: any[];
    unmappedNotifications: Notification[];
    finalizedNotifications: MappedNotification[];
    loadNotifications: () => void;
  }
  
  const AppHeaderContainer = (props: AppHeaderContainerProps) => {
    return (
      !isLoading && (
        <HeaderContainer
          orders={props.orders}
          orderSearchValues={props.orderSearchValues}
          invoices={props.invoices}
          invoiceSearchValues={props.invoiceSearchValues}
          unmappedNotifications={props.unmappedNotifications}
          finalizedNotifications={props.finalizedNotifications}
          loadNotifications={props.loadNotifications}
        />
      )
    );
  };

  interface IonContentContainerProps {
    checkRoute: (route: string) => void;
    isLoggedIn: boolean;
    orders: Order[];
    orderSearchValues: any[];
    invoices: Invoice[];
    invoiceSearchValues: any[];
    loadNotifications: () => void;
    unmappedNotifications: Notification[];
    finalizedNotifications: MappedNotification[];
  }

  const IonContentContainer = (props: IonContentContainerProps) => {
    return (
      !isLoading && (
        <ContentContainer
          isLoggedIn={props.isLoggedIn}
          checkRoute={props.checkRoute}
          orders={props.orders}
          orderSearchValues={props.orderSearchValues}
          invoices={props.invoices}
          invoiceSearchValues={props.invoiceSearchValues}
          loadNotifications={props.loadNotifications}
          unmappedNotifications={props.unmappedNotifications}
          finalizedNotifications={props.finalizedNotifications}
        />
      )
    );
  };

  return (
    <IonApp data-testid='QAAppIonApp' class='app'>
      <ToastContainer />
      <LoadingContainer />

      {isInitialized.current && (
        <>
          <AppHeaderContainer
            orders={orders}
            orderSearchValues={orderSearchValues}
            invoices={invoices}
            invoiceSearchValues={invoiceSearchValues}
            unmappedNotifications={unmappedNotifications}
            finalizedNotifications={finalizedNotifications}
            loadNotifications={loadNotifications}
          />
          <IonContentContainer
            isLoggedIn={isLoggedIn}
            checkRoute={checkRoute}
            orders={orders}
            orderSearchValues={orderSearchValues}
            invoices={invoices}
            invoiceSearchValues={invoiceSearchValues}
            loadNotifications={loadNotifications}
            unmappedNotifications={unmappedNotifications}
            finalizedNotifications={finalizedNotifications}
          />
        </>
      )}
    </IonApp>
  );
};

export default App;