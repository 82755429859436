import { CladProductionSpecifications } from '@constants';
import { ComboGroupActionTypes, useComboGroup } from '@context/combo-group-context';
import { useQuoteState } from '@context/quote-context';
import { useQuoteFormContext } from '@context/quote-form-context';
import React, { useState, useRef, useEffect, useMemo } from 'react';

import BaseMetal from '../sections/base-metal';
import CladProductTesting from '../sections/clad-product-testing';
import CladdingMetal from '../sections/cladding-metal';
import ExceptionClarification from '../sections/exception-clarification';
import Prewelding from '../sections/prewelding';
import { SEGMENTS } from '../segments/segments';
import _ from 'lodash-es';
import { FormKeys, FormKeyHandler, SpecifyPerAssemblyFields } from '../advance-specification-form-util';
import { toDotNotation } from '@pages/new-assembly-groups/assembly-group-form-util';

const PageForm = () => {
  const {
    quoteState,
  } = useQuoteState();
  const {
    state: { selectedKey, comboGroup, specifyPerAssembly },
    dispatch: comboGroupDispatch,
  } = useComboGroup();
  const {
    quoteLineItems: formQuoteLineItems,
    onUpdateQuoteLineItemsByArray,
  } = useQuoteFormContext();
  const { quote } = quoteState;
  const areaId = quote?.Manufacturing_Site__c;
  const [form, setForm] = useState({});
  const activeSpecifyPerAssembly = specifyPerAssembly[selectedKey] || {};

  useEffect(() => {
    comboGroupDispatch({
      type: ComboGroupActionTypes.updateOnlyShowSpecifyPerAssemblyField,
      payload: false,
    });
  }, []);

  useEffect(() => {
    const fqli = formQuoteLineItems.find(qli => (comboGroup && comboGroup[selectedKey] || []).includes((qli.Id || qli.displayId))) || {};
    const values = toDotNotation(fqli, FormKeys);
    setForm(values);
  }, [selectedKey]);

  useEffect(() => {
    if (!Object.keys(form).length) {
      return;
    }

    onSyncItems(form);
  }, [form]);

  const onUpdateFormValue = (key, value) => {
    setForm((previousForm) => {
      const handler = FormKeyHandler[key]
      let newItem = {
        ...previousForm,
        [key]: value,
      };

      if (handler) {
        const additionalUpdate = handler(value, previousForm);
        newItem = {
          ...newItem,
          ...additionalUpdate,
        };
      }
      // onSyncItems(result);
      return newItem;
    });
  }

  const onUpdateFormByPayload = (obj) => {
    setForm((previousForm) => {
      const newItems = Object.keys(obj).reduce((prev, key) => {
        const handler = FormKeyHandler[key];
        const value = obj[key];
        let newItem = {
          ...prev,
          [key]: value,
        };

        if (handler) {
          const additionalUpdate = handler(value, previousForm);
          newItem = {
            ...newItem,
            ...additionalUpdate,
          };
        }

        return newItem;
      }, obj);

      const result = {
        ...previousForm,
        ...newItems,
      }

      // onSyncItems(result);
      return result;
    });
  }

  const onSyncItems = (newForm) => {
    const ids = (comboGroup && comboGroup[selectedKey] || []);

    // get all fields that were enabled to specify per assembly
    // which will be ignored in adv spec sync
    const ignoreFields = Object.keys(activeSpecifyPerAssembly).reduce((prev, key) => {
      if (activeSpecifyPerAssembly[key]) {
        prev = [
          ...prev,
          ...SpecifyPerAssemblyFields[key],
        ]
      }

      return prev;
    }, []);

    // remove all fields that needs to be ignored
    const filteredForm = _.omit(newForm, ignoreFields);

    const payloads = ids.map((Id) => {
      // if id length (string) is on <= thousands should be displayId
      if (Id.length <= 4) {
        return {
          ...filteredForm,
          displayId: Id,
          Id: undefined,
        }
      }

      return {
        ...filteredForm,
        Id,
        displayId: undefined,
      }
    });
    onUpdateQuoteLineItemsByArray(payloads);
  }

  const sections = {
    [SEGMENTS.CLAD_PRODUCT_TESTING.value]: CladProductTesting,
    [SEGMENTS.CLAD_METAL.value]: CladdingMetal,
    [SEGMENTS.PREWELDING.value]: Prewelding,
    [SEGMENTS.BASE_METAL.value]: BaseMetal,
    [SEGMENTS.EXCEPTIONS_CLARIFICATIONS.value]: ExceptionClarification,
  };

  return <div style={{ marginLeft: '34px', padding: '10px' }}>
    {
      Object.keys(sections).map((key: string) => {
        const Section = sections[key];
        return <Section
          segmentKey={key}
          key={`section--${key}`}
          quote={quote}
          areaId={areaId}
          form={form}
          updateForm={onUpdateFormValue}
          updateFormByPayload={onUpdateFormByPayload}
        />
      })
    }
  </div>
}

export default PageForm