import * as React from 'react';

import { HeaderTabTypes } from '@shared/types';
import { IonCol, IonLabel, IonRow } from '@ionic/react';

import './mapped-section.scss';
import Accordion from '@components/accordian-section/accordian-section';
import classNames from 'classnames';

interface Props {
  component: React.FC;
  index: number;
  label: HeaderTabTypes | string;
  sectionRefMap?: Map<HeaderTabTypes | string, React.MutableRefObject<HTMLDivElement>>;
  QALabel?: string;
  showFirstHeader?: boolean;
  isCollapsible?: boolean;
  largeHeader?: boolean;
  blockDisplay?: boolean;
  expandedMap?: Map<string, boolean>;
  setExpandedMap?: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
  hasError?: boolean;
}

// MappedSection - the individual sections getting mapped over, consisting of a header label and a component.
// The first header is not shown by default, but can be made visible if desired.
// The sections are not collapsible by default, but can be made to be collapsible if desired.
const MappedSection = ({
  component: Component,
  index,
  label,
  sectionRefMap,
  QALabel = 'Testing',
  showFirstHeader = false,
  isCollapsible = false,
  largeHeader = false,
  blockDisplay = false,
  expandedMap,
  setExpandedMap,
  hasError = false

}: Props): JSX.Element => {
  const CollapsableSection = (
    <>
      <IonCol class={classNames({'section-flex-column': true, 'large-header': largeHeader })}>
        <Accordion
          // don't render first tab header by default
          title={
            (showFirstHeader ? true : index !== 0) && (
              <>
                <IonLabel class={classNames({'collapsable-section': true, 'error': hasError})} data-testid={`QA${QALabel}Section_${label}`}>
                  {label}
                </IonLabel>
              </>
            )
          }
          content={
            <div className={classNames({'section-flex-row': !blockDisplay })}>
              <Component />
            </div>
          }
          expandedMap={expandedMap}
          setExpandedMap={setExpandedMap}
          label={label}
        />
      </IonCol>
    </>
  );
  const ExpandedSection = (
    <>
      <IonCol class='section-flex-column'>
        {/* don't render first tab header by default */}
        {(showFirstHeader ? true : index !== 0) && (
          <>
            <IonLabel class='section-label-header' data-testid={`QA${QALabel}Section_${label}`}>
              {label}
            </IonLabel>
            <hr className='section-label-rule' />
          </>
        )}
        <div className='section-flex-row'>
          <Component />
        </div>
      </IonCol>
    </>
  );

  return (
    <div ref={sectionRefMap?.get(label)}>
      <IonRow>{isCollapsible ? CollapsableSection : ExpandedSection}</IonRow>
    </div>
  );
};

export default MappedSection;
