import { MultiShotItemDetail } from '@interfaces/multi-shot-item-detail';
import { generateUUID } from '@shared/utils';

export interface MultiShotMetal {
  id?: string;
  metal: string;
  proposalType: string;
  thickness: string;
  metalType: string;
  customerProvided: boolean;
  position?: number;
}

export interface MultiShotDetail extends MultiShotMetal {
  position?: number;
}

export interface MultiShotItemStateDetail extends MultiShotItemDetail {
  UUID__c?: string;
  id?: string;
  Id?: string; // this is the SFDC Id
  multiShotCount?: number;
  metal?: string;
  isInterlayer?: boolean;
  proposalType?: string;
  metalType?: string;
  customerProvided?: boolean;
  thickness?: number;
}

export interface CladMetal extends MultiShotMetal {
  id: string;
}

export interface InterlayerMetal extends MultiShotMetal {
  id: string;
}

export type InterlayerMetalKey = keyof Pick<InterlayerMetal, MetalKey>;

// This is the State of the Table for the Multishot Component
export interface MultiShotState {
  baseMetal: MultiShotMetal;
  cladMetals: CladMetal[];
  interlayerMetals: InterlayerMetal[];
}

export enum MultiShotMetalTypes {
  BASE = 'baseMetal',
  CLAD = 'cladMetals',
  INTERLAYER = 'interlayerMetals',
}

export enum MultiShotVariants {
  CLAD_ONE_SIDE_MULTI_SHOT = 'Clad with Interlayer(s)',
  CLAD_BOTH_SIDES = 'Clad Both Sides',
  CLAD_ONE_SIDE_SINGLE_SHOT = 'Clad with Interlayer - Single Shot',
}

const blankMetal: MultiShotMetal = {
  metal: '',
  proposalType: '',
  thickness: '',
  metalType: '',
  customerProvided: false,
};

export const initialMultiShotState: MultiShotState = {
  baseMetal: {
    id: generateUUID(),
    ...{ ...blankMetal },
  },
  cladMetals: [
    {
      id: generateUUID(),
      ...{ ...blankMetal },
    },
  ],
  interlayerMetals: [
    {
      id: generateUUID(),
      ...{ ...blankMetal },
    },
  ],
};

export type MultiShotMetalUpdatePayload = {
  metalType: MultiShotMetalTypes;
  key: keyof MultiShotMetal;
  value: any;
  index?: number;
};

type MetalKey = 'metal' | 'proposalType' | 'metalType' | 'thickness';
export type MultiShotAction =
  | { type: 'ADD_INTERLAYER'; ref: React.MutableRefObject<MultiShotState> }
  | { type: 'REMOVE_INTERLAYER'; payload: number; ref: React.MutableRefObject<MultiShotState> }
  | { type: 'UPDATE_METAL'; payload: MultiShotMetalUpdatePayload; ref: React.MutableRefObject<MultiShotState> }
  | { type: 'INIT_CLAD_ONE_SIDE'; ref: React.MutableRefObject<MultiShotState> } // init state for clad one side
  | { type: 'INIT_CLAD_BOTH_SIDES'; ref: React.MutableRefObject<MultiShotState> } // init state for clad both sides
  | { type: 'INIT_CLAD_ONE_SIDE_SINGLE'; ref: React.MutableRefObject<MultiShotState> }; // init state for clad one side - single-shot

/**
note: due to the business desiring new layers to be on top of existing in the UI, actions on
the interlayerMetals array in the UI is reversed before mapping through it
so the indices for the mapped interlayers that are passed in, have been reversed
*/
export const multiShotReducer = (state: MultiShotState, action: MultiShotAction): MultiShotState => {
  switch (action.type) {
    case 'ADD_INTERLAYER': {
      return addInterlayer(state, action);
    }
    case 'REMOVE_INTERLAYER': {
      return removeInterlayer(state, action);
    }
    case 'UPDATE_METAL': {
      return updateMetal(state, action);
    }
    // This is the INIT state for CLAD_BOTH_SIDES
    case 'INIT_CLAD_BOTH_SIDES': {
      return initCladsBothSides(state, action);
    }
    // This is the INIT state for CLAD_ONE_SIDE
    case 'INIT_CLAD_ONE_SIDE': {
      return initCladOneSide(state, action);
    }
    // This is the INIT state for CLAD_ONE_SIDE_SINGLE
    case 'INIT_CLAD_ONE_SIDE_SINGLE': {
      return initCladOneSideSingle(state, action);
    }
    default:
      return state;
  }
};

export const getInitMultiShotState = () => {
  const updatedState = {
    baseMetal: { ...initialMultiShotState.baseMetal },
    cladMetals: initialMultiShotState.cladMetals.map((clad) => ({ ...clad, ...blankMetal })),
    interlayerMetals: initialMultiShotState.interlayerMetals.map((interlayer) => ({
      ...interlayer,
      ...blankMetal,
    })),
  };

  return updatedState;
};

const addInterlayer = (state: MultiShotState, action: MultiShotAction): MultiShotState => {
  const newInterlayer: InterlayerMetal = {
    id: generateUUID(),
    ...blankMetal,
  };

  const updatedInterlayerMetals = [...state.interlayerMetals, newInterlayer];

  const updatedState = {
    ...state,
    interlayerMetals: updatedInterlayerMetals,
  };
  action.ref.current = updatedState;

  return updatedState;
};

const removeInterlayer = (
  state: MultiShotState,
  action: { type: 'REMOVE_INTERLAYER'; payload: number; ref: React.MutableRefObject<MultiShotState> }
): MultiShotState => {
  const updatedInterlayers = [...state.interlayerMetals];
  // remove the interlayer at the specified index
  updatedInterlayers.splice(action.payload, 1);

  const updatedInterlayerMetals = updatedInterlayers;

  const updatedState = {
    ...state,
    interlayerMetals: updatedInterlayerMetals,
  };
  action.ref.current = updatedState;

  return updatedState;
};

const updateMetal = (
  state: MultiShotState,
  action: { type: 'UPDATE_METAL'; payload: MultiShotMetalUpdatePayload; ref: React.MutableRefObject<MultiShotState> }
): MultiShotState => {
  const { metalType, key, value, index } = action.payload;

  let updatedMetal = state[metalType];

  if (metalType === 'interlayerMetals' && Array.isArray(updatedMetal)) {
    const updatedInterlayers = [...updatedMetal];

    // make the necessary changes to the updated array
    updatedInterlayers[index] = { ...updatedInterlayers[index], [key]: value };

    // update the state with the updated array
    updatedMetal = [...updatedInterlayers];
  } else if (metalType === 'cladMetals' && Array.isArray(updatedMetal)) {
    const updatedClads = [...updatedMetal];

    // make the necessary changes to the updated array
    updatedClads[index] = { ...updatedClads[index], [key]: value };

    updatedMetal = [...updatedClads];
  } else {
    // else metalType is a single base metal
    updatedMetal[key] = value;
  }
  // update the state with the updated array or single base metal
  const updatedState = {
    ...state,
    [metalType]: updatedMetal,
  };
  action.ref.current = updatedState;

  return updatedState;
};

const initCladsBothSides = (state: MultiShotState, action: MultiShotAction): MultiShotState => {
  // the starting state for CLAD_BOTH_SIDES has no interlayers, and has two clad metals
  // if there are already two clads, use them, if not, create two new ones
  const updatedClads = [
    state.cladMetals.length > 0 ? state.cladMetals[0] : { id: generateUUID(), ...blankMetal },
    state.cladMetals.length > 1 ? state.cladMetals[1] : { id: generateUUID(), ...blankMetal },
  ];

  const updatedInterlayers = [];

  const updatedBase = { id: generateUUID(), ...blankMetal };

  const updatedState = {
    // ...state,
    baseMetal: updatedBase,
    interlayerMetals: updatedInterlayers,
    cladMetals: updatedClads,
  };
  action.ref.current = updatedState;

  return updatedState;
};

const initCladOneSide = (state: MultiShotState, action: MultiShotAction): MultiShotState => {
  // the starting state for CLAD_ONE_SIDE has interlayers, and has only one clad metal
  // if there is already one clad metal, use it, if not, create a new one
  const newCladMetals = [state.cladMetals.length > 0 ? state.cladMetals[0] : { id: generateUUID(), ...blankMetal }];
  // if there are already interlayers, use them, if not, create a new one
  const newInterlayerMetals =
    state.interlayerMetals.length > 0 ? state.interlayerMetals : [{ id: generateUUID(), ...blankMetal }];

  const updatedBase = { id: generateUUID(), ...blankMetal };

  const updatedState = {
    // ...state,
    baseMetal: updatedBase,
    cladMetals: newCladMetals,
    interlayerMetals: newInterlayerMetals,
  };
  action.ref.current = updatedState;

  return updatedState;
};

const initCladOneSideSingle = (state: MultiShotState, action: MultiShotAction): MultiShotState => {
  // the starting state for CLAD_ONE_SIDE_SINGLE has only 1 interlayer, and has only one clad metal
  // if there is already one clad metal, use it, if not, create a new one
  const newCladMetals = [state.cladMetals.length > 0 ? state.cladMetals[0] : { id: generateUUID(), ...blankMetal }];
  // if there are already interlayers, use index 0, if not, create a new one
  const newInterlayerMetals = [
    state.interlayerMetals.length > 0 ? state.interlayerMetals[0] : { id: generateUUID(), ...blankMetal },
  ];

  const updatedBase = { id: generateUUID(), ...blankMetal };

  const updatedState = {
    // ...state,
    baseMetal: updatedBase,
    cladMetals: newCladMetals,
    interlayerMetals: newInterlayerMetals,
  };
  action.ref.current = updatedState;

  return updatedState;
};
