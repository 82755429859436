import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Selection } from '@interfaces/selection';
import simpleCacheStorage from '@services/app/simple-cache-storage';
import { getSelections } from '@services/selections/selections-api';

const KEY = 'NC_SELECTIONS';

interface Props {
  children: ReactNode;
}

interface IContextProps {
  selections: Selection[];
  setSelections: Dispatch<SetStateAction<Selection[]>>;
  getSelections: () => Promise<boolean>;
}

// Context
const SelectionsContext = createContext({} as IContextProps);

// Provider
export const SelectionsProvider = ({ children }: Props): JSX.Element => {
  const [
    selections,
    setSelections
  ] = useState(simpleCacheStorage.getItem(KEY) || []);

  const onUpdate = (items) => {
    simpleCacheStorage.setItem(KEY, items, 60);
    setSelections(items);
  };

  // dont return the values and instead use state
  const onGetSelections = () => {
    if (selections.length >= 1) {
      return Promise.resolve(true);
    }

    return getSelections()
      .then((result) => {
        onUpdate(result);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  }

  // context value object
  const value: IContextProps = useMemo(() => {
    return {
      selections,
      setSelections: onUpdate,
      getSelections: onGetSelections,
    };
  }, [selections, setSelections, onUpdate, simpleCacheStorage, KEY]);

  return (
    <SelectionsContext.Provider value={value}>{children}</SelectionsContext.Provider>
  );
};

// useSelections hook
export const useSelections = (): IContextProps => {
  const context: IContextProps = useContext(SelectionsContext);
  if (context === null || context === undefined) {
    throw new Error(
      'You probably forgot the <SelectionsProvider> context provider'
    );
  }
  return context;
};