import { useEffect, useState, useRef, MutableRefObject } from 'react';

export const useOnScreen = (threshold = 0.01, rootMargin = '0px', root = null): [MutableRefObject<HTMLDivElement>, boolean] => {
  const observerRef = useRef(null);
  const [isOnScreen, setIsOnScreen] = useState<boolean>(false);

  useEffect(() => {
    const options: IntersectionObserverInit = { root, rootMargin, threshold };
    const observer: IntersectionObserver = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting);
    }, options);

    const currentRef = observerRef.current;
    if (observerRef.current) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [observerRef, rootMargin]);

  return [observerRef, isOnScreen];
};

// Example usage:
// const [ref, isOnScreen] = useOnScreen(ref);
