import React from 'react';
import { TooltipPositions } from '@constants';
import './tooltip.scss';

const Tooltip = (props: {
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal,
  position: TooltipPositions,
  text: string | React.ReactChild | React.ReactFragment | React.ReactPortal,
  offset?: { x: string, y: string }
  style?: React.CSSProperties
}) => {
  const [active, setActive] = React.useState(false);

  const showTooltip = () => {
    setActive(true);
  };

  const hideTooltip = () => {
    setActive(false);
  };

  const getTooltipStyle = () => {
    return {
      '--x-offset': props.offset ? props.offset.x : '0px',
      '--y-offset': props.offset ? props.offset.y : '0px'
    };
  };

  const style = props.style ? props.style : {};

  return (
    <div className="tooltip-container" onMouseEnter={showTooltip} onMouseLeave={hideTooltip} style={{...style}}>
      {props.children}
      {active && <><div className={`tooltip ${props.position}`} style={getTooltipStyle() as React.CSSProperties}>
        {props.text}
      </div></>}
    </div>
  );
};

export default Tooltip;