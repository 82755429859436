import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

interface Props {
  children: ReactNode;
}

interface IContextProps {
  showPopover: boolean;
  setShowPopover: Dispatch<SetStateAction<any>>;
  quoteLineItemToEdit: any;
  quoteLineItemDetailToEdit: any;
  setQuoteLineItemToEdit: Dispatch<SetStateAction<any>>;
  setQuoteLineItemDetailToEdit: Dispatch<SetStateAction<any>>;
  isMultiShot: boolean;
  setIsMultiShot: Dispatch<SetStateAction<boolean>>;
}

// Context
const AddEditLineItemContext = createContext({} as IContextProps);

// Provider
export const AddEditLineItemProvider = ({ children }: Props): JSX.Element => {
  const [showPopover, setShowPopover] = useState(false);
  const [quoteLineItemToEdit, setQuoteLineItemToEdit] = useState(null);
  const [quoteLineItemDetailToEdit, setQuoteLineItemDetailToEdit] = useState(null);
  const [isMultiShot, setIsMultiShot] = useState(false); // used to determine if we are adding/editing a multi-shot line item

  // context value object
  const value: IContextProps = useMemo(() => {
    return {
      showPopover,
      quoteLineItemToEdit,
      quoteLineItemDetailToEdit,
      setShowPopover,
      setQuoteLineItemToEdit,
      setQuoteLineItemDetailToEdit,
      isMultiShot,
      setIsMultiShot
    };
  }, [showPopover, quoteLineItemToEdit, quoteLineItemDetailToEdit, isMultiShot]);

  return (
    <AddEditLineItemContext.Provider value={value}>{children}</AddEditLineItemContext.Provider>
  );
};

// useAddEditLineItemPopover hook
export const useAddEditLineItemPopover = (): IContextProps => {
  const context: IContextProps = useContext(AddEditLineItemContext);
  if (context === null || context === undefined) {
    throw new Error(
      'You probably forgot the <AddEditLineItemProvider> context provider'
    );
  }
  return context;
};