import { getCall as get, putCall as put, postCall as post } from '@services/rest-service';

// Retrieves the user settings from cosmos
export const getUserSettings = async(): Promise<any> => {
  return await get('user/settings');
};

export const updateUserSettings = async(settings: Map<string, string>): Promise<any> => {
  return await put('user/settings', Object.fromEntries(settings));
};

// Adds the user email to onesignal
export const addEmailToOnesignal = async(email: string, oneSignalEmailHash: string, userId: string, oneSignalExternalIdHash: string): Promise<any> => {
  return await post('users/onesignal', {
    email,
    oneSignalEmailHash,
    userId,
    oneSignalExternalIdHash
  });
};