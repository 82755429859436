import { IonButton, IonContent, IonIcon, IonLabel } from '@ionic/react';
import classNames from 'classnames';
import * as React from 'react';
import './notifications.scss';
import Footer from '@components/footer/footer';
import { usePlatform } from '@services/platform-service';
import NotificationsList from '@components/notifications-list/notifications-list';
import backArrow from '@assets/icon/arrow_back.svg';
import forwardArrow from '@assets/icon/arrow_forward.svg';
import { MappedNotification } from '@shared/types';
import { Notification } from '@interfaces/notification';
interface Props {
  finalizedNotifications: MappedNotification[];
  loadNotifications: () => void;
  unmappedNotifications: Notification[];
}

  const NotificationsPage = (props: Props) => {
  const platform = usePlatform();

  const [displayedNotifications] = React.useState<MappedNotification[]>(props.finalizedNotifications as MappedNotification[]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage] = React.useState(10);
  const [firstRowIndex, setFirstRowIndex] = React.useState((page - 1) * rowsPerPage + 1);
  const [numberOfRows, setNumberOfRows] = React.useState(undefined);
  const [lastRowIndex, setLastRowIndex] = React.useState(undefined);
  const [pageItems, setPageItems] = React.useState(undefined);
  const [canGoToPreviousPage, setCanGoToPreviousPage] = React.useState(page > 1);
  const [maxPages, setMaxPages] = React.useState(undefined);
  const [canGoToNextPage, setCanGoToNextPage] = React.useState(undefined);

  React.useEffect(() => {
    setLastRowIndex(Math.min(numberOfRows, (rowsPerPage * page)));
  }, [numberOfRows, rowsPerPage, page]);

  React.useEffect(() => {
    setPageItems(displayedNotifications?.slice(firstRowIndex - 1, lastRowIndex));
  }, [displayedNotifications, firstRowIndex, lastRowIndex]);

  React.useEffect(() => {
    setFirstRowIndex((page - 1) * rowsPerPage + (displayedNotifications?.length ? 1 : 0));
  }, [page, rowsPerPage, displayedNotifications]);

  React.useEffect(() => {
    setCanGoToNextPage(page < maxPages);
  }, [page, maxPages]);

  React.useEffect(() => {
    setCanGoToPreviousPage(page > 1);
  }, [page]);

  React.useEffect(() => {
    setMaxPages(Math.ceil(numberOfRows / rowsPerPage));
  }, [numberOfRows, rowsPerPage]);

  React.useEffect(() => {
    setNumberOfRows(displayedNotifications?.length);
  }, [displayedNotifications]);

  // Navigates to previous page of the notifications screen
  const goToPreviousPage = () => {
    setPage(page - 1);
  };

  // Navigates to next page of the notifications screen
  const goToNextPage = () => {
    setPage(page + 1);
  };

  return (
    <IonContent class="notifications-page">
      <div className='max-width-container notifications-wrapper'>
        <IonLabel class='notifications-title' data-testid="QANotificationsPageTitle">Notifications</IonLabel>
        <div className={classNames({
          'pagination-parent-container': true
        })}
        >
          <div className="pagination-container">
            <IonLabel class="pagination-label">Viewing <span className="bold">{firstRowIndex}-{lastRowIndex}</span> of <span className="bold">{numberOfRows}</span></IonLabel>
            <div className="pagination-buttons-container">
              <IonButton disabled={!canGoToPreviousPage} class='change-page-button' color="secondary" onClick={() => goToPreviousPage()} data-testid="QANotificationsPageTopPreviousPageButton">{!platform.isMobile() ? <IonIcon src={backArrow}></IonIcon> : 'Previous'}</IonButton>
              <IonButton disabled={!canGoToNextPage} class='change-page-button' color="secondary" onClick={() => goToNextPage()} data-testid="QANotificationsPageTopNextPageButton">{!platform.isMobile() ? <IonIcon class="next-icon" src={forwardArrow}></IonIcon> : 'Next'}</IonButton>
            </div>
          </div>
        </div>
        <hr/>
        <NotificationsList
          pageItems={pageItems}
          striping={true}
          actionsVisible={true}
          loadNotifications={props.loadNotifications}
          unmappedNotifications={props.unmappedNotifications}
          displayedNotifications={displayedNotifications}
        />
        <hr className='bottom-rule'/>
        <div className="pagination-parent-container bottom">
          <div className="pagination-container bottom" data-testid="QANotificationsPageBottomPaginationContainer">
            <IonLabel class="pagination-label">Viewing <span className="bold">{firstRowIndex}-{lastRowIndex}</span> of <span className="bold">{numberOfRows}</span></IonLabel>
            <div className="pagination-buttons-container">
              <IonButton disabled={!canGoToPreviousPage} class="change-page-button" color="secondary" onClick={() => goToPreviousPage()} data-testid="QANotificationsPageBottomPreviousPageButton">{!platform.isMobile() ? <IonIcon src={backArrow}></IonIcon> : 'Previous'}</IonButton>
              <IonButton disabled={!canGoToNextPage} class="change-page-button" color="secondary" onClick={() => goToNextPage()} data-testid="QANotificationsPageBottomNextPageButton">{!platform.isMobile() ? <IonIcon class="next-icon" src={forwardArrow}></IonIcon> : 'Next'}</IonButton>
            </div>
          </div>
        </div>
      </div>
      <div className="footer" data-testid="QANotificationsPageFooterContainer">
        <Footer/>
      </div>
    </IonContent>
  );
};

export default NotificationsPage;