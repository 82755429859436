import { useDarkMode } from '@hooks/useDarkMode';
import { IonIcon } from '@ionic/react';
import classNames from 'classnames';
import * as React from 'react';
import Select from 'react-select';
import carrotIcon from '@assets/icon/carrot_field.svg';
import carrotIconWhite from '@assets/icon/carrot_field_white.svg';
import './dropdown.scss';

const Dropdown = (props) => {
  const { darkMode } = useDarkMode();

  const DropdownIndicator = () => {
    return (
      <IonIcon class='dropdown-indicator' icon={!darkMode ? carrotIcon : carrotIconWhite}></IonIcon>
    );
  };

  return (
    <Select
      className={classNames({ 'error': props.errorExists})}
      options={props.options}
      placeholder={props.placeholder}
      styles={props.style}
      isSearchable={false}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={props.onChange}
      isDisabled={props.isDisabled}
      theme={(theme) => ({
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 26,
        }
      })}
      components={{
        DropdownIndicator
      }}
    />
  );
};

export default Dropdown;