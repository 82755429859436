import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { DisplayedQuoteGroup } from '@shared/quote-group-utils';
import { useEffect, useMemo } from 'react';

export const useQuoteGroup = (
  quoteLineItems: QuoteLineItem[],
  quoteLineItemDetails: QuoteLineItemDetail[],
  lineItemId: string
) => {
  // create a new DisplayQuoteGroup from the supplied quote line items, quote line item details, and line item id (or display id)
  const displayedQuoteGroup: DisplayedQuoteGroup = useMemo(
    () => { return quoteLineItems && quoteLineItemDetails ? new DisplayedQuoteGroup(quoteLineItems, quoteLineItemDetails, lineItemId) : undefined; },
    [lineItemId, quoteLineItemDetails, quoteLineItems]
  );

  // when the line item id for the displayed quote line item group changes, set the new displayed item's id
  useEffect(() => {
    if(displayedQuoteGroup) {
      displayedQuoteGroup.setDisplayedQuoteLines(lineItemId);
    }
  }, [displayedQuoteGroup, lineItemId]);

  return displayedQuoteGroup;
};

// Usage example:
// const { displayedQuoteGroup } = useQuoteGroup(quoteLineItems, quoteLineItemDetails, lineItemId);
