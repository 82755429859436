import { IonLabel, IonSpinner } from '@ionic/react';
import * as React from 'react';

interface Props {
  message: string;
}

const LoadingEllipsisMessage = ({ message }: Props): React.ReactElement | JSX.Element => {
  const style = {
    fontFamily: 'DINPro, Sans Serif',
    fontSize: '16px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    opacity: 0.9,
  };

  return (
    <>
      <div style={style}>
        <IonLabel>{message}</IonLabel>
        <IonSpinner style={{ marginLeft: '10px' }} name='dots' />
      </div>
    </>
  );
};

export default LoadingEllipsisMessage;
