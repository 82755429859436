import React from 'react';
import { IonButton, IonCol, IonGrid, IonImg, IonLabel, IonRow } from '@ionic/react';
import './pre-bond-impacts-popover.scss';
import { SelectionTypes, QuoteLineItemValueTypes, Regexes } from '@constants';
import SelectionContainer from '@components/selection-container/selection-container';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { getSelectionOptions, getSelections } from '@shared/quote-utils';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { AdvSpecsAssemblyGroupDetailFieldsToUpdate, AdvSpecsMetalComboDetailFieldsToUpdate } from '@shared/types';

const PrebondImpactsPopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
    showPopover: boolean;
    event: any;
  }>>,
  detailFieldsToUpdate: AdvSpecsMetalComboDetailFieldsToUpdate | AdvSpecsAssemblyGroupDetailFieldsToUpdate,
}) => {
  const { dataState } = useDataState();
  const { selections } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  
  const { darkMode } = useDarkMode();

  const [detailFieldsToUpdate] = React.useState({
    preBondImpactsTestLocation: props.detailFieldsToUpdate.preBondImpactsTestLocation || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.PreBondImpactsTestLocation).default,
    preBondImpactsTestDirection: props.detailFieldsToUpdate.preBondImpactsTestDirection || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.PreBondImpactsTestDirection).default,
    preBondImpactsTestUOM: props.detailFieldsToUpdate.preBondImpactsTestUOM || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.PreBondImpactsTestUOM).default,
    preBondImpactsTestAVG: props.detailFieldsToUpdate.preBondImpactsTestAVG,
    preBondImpactsTestMIN: props.detailFieldsToUpdate.preBondImpactsTestMIN,
    preBondImpactsTestTemperature: props.detailFieldsToUpdate.preBondImpactsTestTemperature,
    preBondImpactsTestTemperatureUOM: props.detailFieldsToUpdate.preBondImpactsTestTemperatureUOM || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.PreBondImpactsTestTemperatureUOM).default,
  });

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const updateDetails = () => {
    props.detailFieldsToUpdate.preBondImpactsTestLocation = detailFieldsToUpdate.preBondImpactsTestLocation;
    props.detailFieldsToUpdate.preBondImpactsTestDirection = detailFieldsToUpdate.preBondImpactsTestDirection;
    props.detailFieldsToUpdate.preBondImpactsTestUOM = detailFieldsToUpdate.preBondImpactsTestUOM;
    props.detailFieldsToUpdate.preBondImpactsTestAVG = detailFieldsToUpdate.preBondImpactsTestAVG;
    props.detailFieldsToUpdate.preBondImpactsTestMIN = detailFieldsToUpdate.preBondImpactsTestMIN;
    props.detailFieldsToUpdate.preBondImpactsTestTemperature = detailFieldsToUpdate.preBondImpactsTestTemperature;
    props.detailFieldsToUpdate.preBondImpactsTestTemperatureUOM = detailFieldsToUpdate.preBondImpactsTestTemperatureUOM;
  };

  return (
    <div className="popover prebond-impacts">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='prebond-impacts-title' data-testid={'QAPrebondImpactsTestingPopoverPrebondImpactsTestingHeader'}>Prebond Impacts</IonLabel>
      </div>
      <hr/>
      <div className='selections'>
        <IonGrid>
          <IonRow>
            <IonCol size="2.8">
              <IonLabel class='selection-label'>
                Location
              </IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select Test Location...'
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.PreBondImpactsTestLocation).options}
                style={{ width: '200px', marginTop: 'unset' }}
                onChange={preBondImpactsTestLocation =>
                  (detailFieldsToUpdate.preBondImpactsTestLocation = preBondImpactsTestLocation.label)
                }
                defaultValue={detailFieldsToUpdate.preBondImpactsTestLocation}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="2.8">
              <IonLabel class='selection-label section'>
                Direction
              </IonLabel>
            </IonCol>
            <IonCol class='flex-column'>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select Test Direction...'
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.PreBondImpactsTestDirection).options}
                style={{ width: '200px', marginTop: 'unset' }}
                onChange={preBondImpactsTestDirection =>
                  (detailFieldsToUpdate.preBondImpactsTestDirection = preBondImpactsTestDirection.label)
                }
                defaultValue={detailFieldsToUpdate.preBondImpactsTestDirection}
              />
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.PreBondImpactsTestUOM).options}
                onIonChange={preBondImpactsTestUOM =>
                  (detailFieldsToUpdate.preBondImpactsTestUOM = preBondImpactsTestUOM)
                }
                value={detailFieldsToUpdate.preBondImpactsTestUOM}
              />
              <div className='flex-container'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  placeholder='0'
                  regex={Regexes.IntegerAboveZero}
                  min='0'
                  style={{ width: '48px', marginRight: '10px', marginTop: 'auto' }}
                  onBlur={preBondImpactsTestAVG =>
                    (detailFieldsToUpdate.preBondImpactsTestAVG = preBondImpactsTestAVG)
                  }
                  value={detailFieldsToUpdate.preBondImpactsTestAVG}
                />
                <IonLabel class='unit-label'>AVG/</IonLabel>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  placeholder='0'
                  regex={Regexes.IntegerAboveZero}
                  min='0'
                  style={{ width: '48px', marginRight: '10px', marginTop: 'auto' }}
                  onBlur={preBondImpactsTestMIN =>
                    (detailFieldsToUpdate.preBondImpactsTestMIN = preBondImpactsTestMIN)
                  }
                  value={detailFieldsToUpdate.preBondImpactsTestMIN}
                />
                <IonLabel class='unit-label'>MIN</IonLabel>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="2.8">
              <IonLabel class='selection-label'>
                Temperature
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='flex-container'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='number'
                  placeholder='0'
                  regex={Regexes.Temperature}
                  min='0'
                  style={{ width: '48px', marginRight: '19px' }}
                  containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                  onBlur={preBondImpactsTestTemperature =>
                    (detailFieldsToUpdate.preBondImpactsTestTemperature = preBondImpactsTestTemperature)
                  }
                  value={detailFieldsToUpdate.preBondImpactsTestTemperature}
                />
                <SelectionContainer
                  type={SelectionTypes.RadioButtons}
                  options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.PreBondImpactsTestTemperatureUOM).options}
                  onIonChange={preBondImpactsTestTemperatureUOM =>
                    (detailFieldsToUpdate.preBondImpactsTestTemperatureUOM = preBondImpactsTestTemperatureUOM)
                  }
                  value={detailFieldsToUpdate.preBondImpactsTestTemperatureUOM}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <hr className='bottom-rule'/>
      <div className="cta-button-container">
        <IonButton color="primary" class="save-button" data-testid="QAPrebondImpactsPopoverSaveButton" onClick={() => {
          updateDetails();
          dismissModal();
        }}
        >
          Save
        </IonButton>
        <IonButton color="secondary" class="cancel-button" onClick={() => dismissModal()}>Cancel</IonButton>
      </div>
    </div>
  );
};

export default PrebondImpactsPopover;