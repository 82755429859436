import triangle from '@assets/icon/cell_menu_icon.svg';
import ManualWeldEntryPopover from '@components/popover/manual-weld-entry-popover/manual-weld-entry-popover';
import Tooltip from '@components/tooltip/tooltip';
import { FieldTypes, MeasurementSystems, QuoteItemSelectionTabs, QuoteLineItemDependentUnwrittenFields, QuoteLineItemFieldEditTypes, QuoteLineItemFieldUnitTypes, QuoteLineItemFields, QuoteLineItemValueTypes, QuoteLineTypes, Regexes, TooltipPositions, Units, resettableFields } from '@constants';
import { QuoteActionTypes, useQuoteDispatch, useQuoteState } from '@context/quote-context';
import { useLocalUom } from '@hooks/useLocalUom';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { Currency } from '@interfaces/salesforce';
import { TextFieldTypes } from '@ionic/core';
import { IonIcon, IonInput, IonPopover } from '@ionic/react';
import { calculateUOMValue, formatLengthWidthMeasurement, formatPercentage, formatPricePerWeight, formatThicknessMeasurement, formatWeight } from '@shared/quote-utils';
import { DependentUnwrittenFieldsMapValueType, FieldsMapValueType } from '@shared/types';
import { convertUnits, formatCurrency, stringToFloat } from '@shared/utils';
import classNames from 'classnames';
import { ellipse } from 'ionicons/icons';
import moment from 'moment';
import * as React from 'react';
import './line-item-field.scss';

const LineItemField = (props: {
  valueType?: QuoteLineItemValueTypes,
  value?: string | number,
  unitType?: QuoteLineItemFieldUnitTypes,
  uom?: MeasurementSystems,
  imperialValue?: string | number,
  metricValue?: string | number,
  displayedMetricValue?: number,
  displayedImperialValue?: number,
  hideUnits?: boolean,
  currency?: Currency,
  lineToEdit?: QuoteLineItem,
  extraOptions?: boolean,
  datatestid?: string,
  displayedValue?: string | number,
  itemId?: string,
  imperialField?: QuoteLineItemFields,
  metricField?: QuoteLineItemFields,
  field?: QuoteLineItemFields,
  lineId?: string,
  lineType?: QuoteLineTypes,
  calculatedField?: boolean,
  tabIndex?: string,
  selectedTabIndex?: string,
  nextTabIndex?: string,
  setTabIndex?: React.Dispatch<string>,
  readOnly?: boolean,
  ignoreRecalculateFields?: boolean,
  fieldChangedCB?: (newValue: number, valueType: QuoteLineItemValueTypes, index: number) => void,
  indexToUpdate?: number,
  alwaysEdit?: boolean,
  isCustomerProvidedClad?: boolean,
  isCustomerProvidedBase?: boolean,
  activeTab?: QuoteItemSelectionTabs,
  roundOff?: boolean,
}) => {
  const { dispatchQuoteState } = useQuoteDispatch();
  const { quoteState, saveQuoteLineItem, handleLeftoverQuantitiesForAutocombine } = useQuoteState();

  const { localUom, isDisplayMetric } = useLocalUom(props.uom);

  const {
    quote,
    calculatedFieldsEditedMap,
    inputFieldsEditedMap,
    defaultValueMap
  } = quoteState;
  const {
    quoteLineItems
  } = quote || {};

  const [valueType] = React.useState(props.valueType);
  const [lineToEdit] = React.useState(props.lineToEdit);
  const [extraOptions] = React.useState(props.extraOptions);
  const [imperialField] = React.useState(props.imperialField);
  const [metricField] = React.useState(props.metricField);
  const [field] = React.useState(props.field);
  const [itemId] = React.useState(props.itemId);
  const [lineId] = React.useState(props.lineId);
  const [lineType] = React.useState(props.lineType);
  const [calculatedField] = React.useState(props.calculatedField);
  const [nextTabIndex] = React.useState(props.nextTabIndex);
  const [datatestid] = React.useState(props.datatestid);
  const [setTabIndex] = React.useState(() => props.setTabIndex);
  const fieldsChangedCB = props.fieldChangedCB;
  const ignoreRecalculateFields = props.ignoreRecalculateFields;
  const indexToUpdate = props.indexToUpdate;
  const isCustomerProvidedMetal = props.isCustomerProvidedClad || props.isCustomerProvidedBase;
  const [alwaysEdit] = React.useState(props.alwaysEdit);
  const [activeTab] = React.useState(props.activeTab);
  const [roundOff] = React.useState(props.roundOff);

  let formattedDisplayedValue: string | number;
  let editType: QuoteLineItemFieldEditTypes;
  let fieldType: FieldTypes;
  let inputType: TextFieldTypes;
  let placeHolder: string;
  let regex: RegExp;
  let numDecimals: number;
  let min: string;
  let step: string;
  let value: string;
  let imperialUnit: Units;
  let metricUnit: Units;
  let customerSuppliedMetalType: string;

  switch (valueType) {
    case QuoteLineItemValueTypes.SizingQuantity:
    case QuoteLineItemValueTypes.Wide:
    case QuoteLineItemValueTypes.Long:
    case QuoteLineItemValueTypes.PanelNumberSW:
      editType = props.readOnly
        ? QuoteLineItemFieldEditTypes.None
        : QuoteLineItemFieldEditTypes.Inline;
      fieldType = FieldTypes.Integer;
      min = '1';
      placeHolder = '1';
      break;
    case QuoteLineItemValueTypes.Width:
    case QuoteLineItemValueTypes.Length:
    case QuoteLineItemValueTypes.CladWidth:
    case QuoteLineItemValueTypes.CladLength:
    case QuoteLineItemValueTypes.BaseWidth:
    case QuoteLineItemValueTypes.BaseLength:
    case QuoteLineItemValueTypes.WeldingLength:
    case QuoteLineItemValueTypes.WeldingWidth:
    case QuoteLineItemValueTypes.AnvilWidth:
    case QuoteLineItemValueTypes.AnvilLength:
      editType = props.readOnly
      ? QuoteLineItemFieldEditTypes.None
      : QuoteLineItemFieldEditTypes.Inline;
      fieldType = isDisplayMetric ? FieldTypes.Integer : FieldTypes.Float;
      min = isDisplayMetric ? '1' : '0.001';
      placeHolder = isDisplayMetric ? '1' : '0.001';
      numDecimals = 3;
      break;
    case QuoteLineItemValueTypes.CladTK:
    case QuoteLineItemValueTypes.FinishedCladTK:
    case QuoteLineItemValueTypes.BaseTK:
    case QuoteLineItemValueTypes.FinishedBaseTK:
    case QuoteLineItemValueTypes.AnvilTK:
    case QuoteLineItemValueTypes.CladNom:
    case QuoteLineItemValueTypes.CladMin:
    case QuoteLineItemValueTypes.BaseNom:
    case QuoteLineItemValueTypes.BaseMin:
      editType = props.readOnly
      ? QuoteLineItemFieldEditTypes.None
      : QuoteLineItemFieldEditTypes.Inline;
      fieldType = FieldTypes.Float;
      min = isDisplayMetric ? '0.01' : '0.001';
      placeHolder = isDisplayMetric ? '0.01' : '0.001';
      numDecimals = 3;
      break;
    case QuoteLineItemValueTypes.CladPricePerWeight:
    case QuoteLineItemValueTypes.CladFreightPerWeight:
    case QuoteLineItemValueTypes.PurchaseCladderFreightPerWeight:
    case QuoteLineItemValueTypes.BasePricePerWeight:
    case QuoteLineItemValueTypes.BaseFreightPerWeight:
    case QuoteLineItemValueTypes.PurchaseBackerFreightPerWeight:
    case QuoteLineItemValueTypes.TotalFreightPerWeight:
    case QuoteLineItemValueTypes.HeadFreightPerWeight:
    case QuoteLineItemValueTypes.AnvilPricePerWeight:
    case QuoteLineItemValueTypes.AnvilFreightPerWeight:
    case QuoteLineItemValueTypes.PurchaseAnvilFreightPerWeight:
      editType = props.readOnly
        ? QuoteLineItemFieldEditTypes.None
        : QuoteLineItemFieldEditTypes.Inline;
      fieldType = FieldTypes.Float;
      min = '0.001';
      step = '0.001';
      placeHolder = '0.001';
      numDecimals = 3;
      break;
    case QuoteLineItemValueTypes.CladCost:
    case QuoteLineItemValueTypes.CladFreight:
    case QuoteLineItemValueTypes.PurchaseCladderPrice:
      customerSuppliedMetalType = 'clad';
      editType = props.readOnly ? QuoteLineItemFieldEditTypes.None : QuoteLineItemFieldEditTypes.Inline;
      fieldType = FieldTypes.Float;
      min = '0.01';
      step = '0.01';
      placeHolder = '0.01';
      numDecimals = 2;
      break;
    case QuoteLineItemValueTypes.BaseCost:
    case QuoteLineItemValueTypes.BaseFreight:
    case QuoteLineItemValueTypes.PurchaseBackerPrice:
      customerSuppliedMetalType = 'base';
      editType = props.readOnly ? QuoteLineItemFieldEditTypes.None : QuoteLineItemFieldEditTypes.Inline;
      fieldType = FieldTypes.Float;
      min = '0.01';
      step = '0.01';
      placeHolder = '0.01';
      numDecimals = 2;
      break;
    case QuoteLineItemValueTypes.Anvil:
    case QuoteLineItemValueTypes.Welding:
    case QuoteLineItemValueTypes.HeadForming:
    case QuoteLineItemValueTypes.HeadFreight:
    case QuoteLineItemValueTypes.OSCost:
    case QuoteLineItemValueTypes.FreightOut:
    case QuoteLineItemValueTypes.AdjPrice:
    case QuoteLineItemValueTypes.ContPercentage:
    case QuoteLineItemValueTypes.ContPerSQM:
    case QuoteLineItemValueTypes.InboundShip:
    case QuoteLineItemValueTypes.OutboundShip:
    case QuoteLineItemValueTypes.TestingBKCostOfEvent:
    case QuoteLineItemValueTypes.TestingBKTotalInboundShippingCost:
    case QuoteLineItemValueTypes.TestingBKTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.TestingBKTotalCost:
    case QuoteLineItemValueTypes.TestingCLCostOfEvent:
    case QuoteLineItemValueTypes.TestingCLTotalInboundShippingCost:
    case QuoteLineItemValueTypes.TestingCLTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.TestingCLTotalCost:
    case QuoteLineItemValueTypes.TestingCostOfEvent:
    case QuoteLineItemValueTypes.TestingTotalInboundShippingCost:
    case QuoteLineItemValueTypes.TestingTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.TestingTotalCost:
    case QuoteLineItemValueTypes.BackerMetalHTTotalCostOfEvent:
    case QuoteLineItemValueTypes.BackerMetalHTTotalInboundShippingCost:
    case QuoteLineItemValueTypes.BackerMetalHTTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.BackerMetalHTTotalCost:
    case QuoteLineItemValueTypes.CladderMetalHTTotalCostOfEvent:
    case QuoteLineItemValueTypes.CladderMetalHTTotalInboundShippingCost:
    case QuoteLineItemValueTypes.CladderMetalHTTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.CladderMetalHTTotalCost:
    case QuoteLineItemValueTypes.PostbondHTTotalCostOfEvent:
    case QuoteLineItemValueTypes.PostbondHTTotalInboundShippingCost:
    case QuoteLineItemValueTypes.PostbondHTTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.PostbondHTTotalCost:
    case QuoteLineItemValueTypes.WeldingCostOfEvent:
    case QuoteLineItemValueTypes.WeldingTotalInboundShippingCost:
    case QuoteLineItemValueTypes.WeldingTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.WeldingTotalCost:
    case QuoteLineItemValueTypes.XRayServicesCostOfEvent:
    case QuoteLineItemValueTypes.XRayServicesTotalInboundShippingCost:
    case QuoteLineItemValueTypes.XRayServicesTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.XRayServicesTotalCost:
    case QuoteLineItemValueTypes.FlatteningBKCostOfEvent:
    case QuoteLineItemValueTypes.FlatteningBKTotalInboundShippingCost:
    case QuoteLineItemValueTypes.FlatteningBKTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.FlatteningBKTotalCost:
    case QuoteLineItemValueTypes.CuttingCostOfEvent:
    case QuoteLineItemValueTypes.CuttingTotalInboundShippingCost:
    case QuoteLineItemValueTypes.CuttingTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.CuttingTotalCost:
    case QuoteLineItemValueTypes.WaterJetCuttingCostOfEvent:
    case QuoteLineItemValueTypes.WaterJetCuttingTotalInboundShippingCost:
    case QuoteLineItemValueTypes.WaterJetCuttingTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.WaterJetCuttingTotalCost:
    case QuoteLineItemValueTypes.FlatteningCostOfEvent:
    case QuoteLineItemValueTypes.FlatteningTotalInboundShippingCost:
    case QuoteLineItemValueTypes.FlatteningTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.FlatteningTotalCost:
    case QuoteLineItemValueTypes.MachiningCostOfEvent:
    case QuoteLineItemValueTypes.MachiningTotalInboundShippingCost:
    case QuoteLineItemValueTypes.MachiningTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.MachiningTotalCost:
    case QuoteLineItemValueTypes.InspectionBKCostOfEvent:
    case QuoteLineItemValueTypes.InspectionBKTotalInboundShippingCost:
    case QuoteLineItemValueTypes.InspectionBKTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.InspectionBKTotalCost:
    case QuoteLineItemValueTypes.InspectionCLCostOfEvent:
    case QuoteLineItemValueTypes.InspectionCLTotalInboundShippingCost:
    case QuoteLineItemValueTypes.InspectionCLTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.InspectionCLTotalCost:
    case QuoteLineItemValueTypes.InspectionCostOfEvent:
    case QuoteLineItemValueTypes.InspectionTotalInboundShippingCost:
    case QuoteLineItemValueTypes.InspectionTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.InspectionTotalCost:
    case QuoteLineItemValueTypes.PackagingCostOfEvent:
    case QuoteLineItemValueTypes.PackagingTotalInboundShippingCost:
    case QuoteLineItemValueTypes.PackagingTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.PackagingTotalCost:
    case QuoteLineItemValueTypes.CanRollingCostOfEvent:
    case QuoteLineItemValueTypes.CanRollingTotalInboundShippingCost:
    case QuoteLineItemValueTypes.CanRollingTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.CanRollingTotalCost:
    case QuoteLineItemValueTypes.HeadFormingCostPerHead:
    case QuoteLineItemValueTypes.HeadFormingTotalInboundShippingCost:
    case QuoteLineItemValueTypes.HeadFormingTotalOutboundShippingCost:
    case QuoteLineItemValueTypes.PriceHead:
    case QuoteLineItemValueTypes.HeadFormingSubcontractingCost:
    case QuoteLineItemValueTypes.PurchaseAnvilPrice:
      editType = props.readOnly
        ? QuoteLineItemFieldEditTypes.None
        : QuoteLineItemFieldEditTypes.Inline;
      fieldType = FieldTypes.Float;
      min = '0.01';
      step = '0.01';
      placeHolder = '0.01';
      numDecimals = 2;
      break;
    case QuoteLineItemValueTypes.LeadTime:
      editType = QuoteLineItemFieldEditTypes.Inline;
      fieldType = FieldTypes.Text;
      placeHolder = '1 wks';
      break;
    case QuoteLineItemValueTypes.TestingBKLeadTimeWeeks:
    case QuoteLineItemValueTypes.TestingCLLeadTimeWeeks:
    case QuoteLineItemValueTypes.TestingLeadTimeWeeks:
    case QuoteLineItemValueTypes.BackerMetalHTLeadTimeWeeks:
    case QuoteLineItemValueTypes.CladderMetalHTLeadTimeWeeks:
    case QuoteLineItemValueTypes.PostbondHTLeadTimeWeeks:
    case QuoteLineItemValueTypes.WeldingLeadTimeWeeks:
    case QuoteLineItemValueTypes.XRayServicesLeadTimeWeeks:
    case QuoteLineItemValueTypes.FlatteningBKLeadTimeWeeks:
    case QuoteLineItemValueTypes.CuttingLeadTimeWeeks:
    case QuoteLineItemValueTypes.WaterJetCuttingLeadTimeWeeks:
    case QuoteLineItemValueTypes.FlatteningLeadTimeWeeks:
    case QuoteLineItemValueTypes.MachiningLeadTimeWeeks:
    case QuoteLineItemValueTypes.InspectionBKLeadTimeWeeks:
    case QuoteLineItemValueTypes.InspectionCLLeadTimeWeeks:
    case QuoteLineItemValueTypes.InspectionLeadTimeWeeks:
    case QuoteLineItemValueTypes.PackagingLeadTimeWeeks:
    case QuoteLineItemValueTypes.CanRollingLeadTimeWeeks:
    case QuoteLineItemValueTypes.HeadFormingLeadTimeWeeks:
      editType = QuoteLineItemFieldEditTypes.Inline;
      fieldType = FieldTypes.Text;
      placeHolder = '1';
      break;
    case QuoteLineItemValueTypes.TotalCost:
    case QuoteLineItemValueTypes.TotalOSCost:
    case QuoteLineItemValueTypes.UnitCost:
    case QuoteLineItemValueTypes.TotalAdjPrice:
      editType = QuoteLineItemFieldEditTypes.None;
      fieldType = FieldTypes.Float;
      numDecimals = 2;
      break;
    case QuoteLineItemValueTypes.TestingBKNotes:
    case QuoteLineItemValueTypes.TestingCLNotes:
    case QuoteLineItemValueTypes.TestingNotes:
    case QuoteLineItemValueTypes.BackerMetalHTNotes:
    case QuoteLineItemValueTypes.CladderMetalHTNotes:
    case QuoteLineItemValueTypes.PostbondHTNotes:
    case QuoteLineItemValueTypes.WeldingNotes:
    case QuoteLineItemValueTypes.XRayServicesNotes:
    case QuoteLineItemValueTypes.FlatteningBKNotes:
    case QuoteLineItemValueTypes.PostCladCuttingTolerance:
    case QuoteLineItemValueTypes.CuttingNotes:
    case QuoteLineItemValueTypes.WaterJetCuttingNotes:
    case QuoteLineItemValueTypes.FlatteningNotes:
    case QuoteLineItemValueTypes.MachiningNotes:
    case QuoteLineItemValueTypes.InspectionBKNotes:
    case QuoteLineItemValueTypes.InspectionCLNotes:
    case QuoteLineItemValueTypes.InspectionNotes:
    case QuoteLineItemValueTypes.PackagingNotes:
    case QuoteLineItemValueTypes.CanRollingNotes:
    case QuoteLineItemValueTypes.AdditionalHeadInformation:
      editType = QuoteLineItemFieldEditTypes.Inline;
      fieldType = FieldTypes.Text;
      placeHolder = 'Notes';
      break;
    case QuoteLineItemValueTypes.ShipDate:
      editType = QuoteLineItemFieldEditTypes.Inline;
      fieldType = FieldTypes.Date;
      break;
    case QuoteLineItemValueTypes.ItemDescription:
    case QuoteLineItemValueTypes.Shape:
    case QuoteLineItemValueTypes.Piece:
    case QuoteLineItemValueTypes.PercentAllocated:
    case QuoteLineItemValueTypes.CladderSupplier:
    case QuoteLineItemValueTypes.CustomerPO:
    case QuoteLineItemValueTypes.ShipDateCladder:
    case QuoteLineItemValueTypes.ShipDateBase:
    case QuoteLineItemValueTypes.Alloc:
    case QuoteLineItemValueTypes.Allocation:
    case QuoteLineItemValueTypes.Location:
    case QuoteLineItemValueTypes.WeightAnvil:
    case QuoteLineItemValueTypes.PurchaseAnvilWeight:
    case QuoteLineItemValueTypes.ShipDateAnvil:
    case QuoteLineItemValueTypes.Line:
    case QuoteLineItemValueTypes.Clads:
    case QuoteLineItemValueTypes.DetailQuantity:
    case QuoteLineItemValueTypes.CladWeightPerPC:
    case QuoteLineItemValueTypes.PurchaseCladderWeight:
    case QuoteLineItemValueTypes.BaseWeightPerPC:
    case QuoteLineItemValueTypes.PurchaseBackerWeight:
    case QuoteLineItemValueTypes.TotalWeightPerPC:
    case QuoteLineItemValueTypes.ShipWeight:
    case QuoteLineItemValueTypes.PriceQuantity:
    case QuoteLineItemValueTypes.PriceProduct:
    case QuoteLineItemValueTypes.Dimensions:
    case QuoteLineItemValueTypes.PricePerWeight:
    case QuoteLineItemValueTypes.SizingProduct:
    case QuoteLineItemValueTypes.CladType:
    case QuoteLineItemValueTypes.BaseType:
    case QuoteLineItemValueTypes.CladMetal:
    case QuoteLineItemValueTypes.BaseMetal:
    default:
      editType = QuoteLineItemFieldEditTypes.None;
  }

  switch(fieldType) {
    case FieldTypes.Integer:
      inputType = 'number';
      regex = resettableFields.includes(props.valueType) ? Regexes.WholeNumber : Regexes.InftegerAboveZero;
      formattedDisplayedValue = props.value || '- -';
      break;
    case FieldTypes.Float:
      inputType = 'number';
      regex = resettableFields.includes(props.valueType) ? Regexes.WholeNumber : new RegExp(`^[0-9]+\\.?[0-9]{0,${numDecimals}}$|^\\.[0-9]{0,${numDecimals}}$`);
      break;
    case FieldTypes.Text:
      inputType = 'text';
      formattedDisplayedValue = props.value;
  }

  switch(props.unitType) {
    case QuoteLineItemFieldUnitTypes.LinearDistanceLW:
      value = !isDisplayMetric ? stringToFloat(props.imperialValue as string, 3) : stringToFloat(props.metricValue as string, 0);
      formattedDisplayedValue = formatLengthWidthMeasurement(isDisplayMetric, props.displayedMetricValue || props.metricValue, props.displayedImperialValue || props.imperialValue, props.hideUnits);
      imperialUnit = Units.Inches;
      metricUnit = Units.Millimeters;
      break;
    case QuoteLineItemFieldUnitTypes.LinearDistanceTK:
      value = !isDisplayMetric ? stringToFloat(props.imperialValue as string, 3) : stringToFloat(props.metricValue as string, 2);
      formattedDisplayedValue = formatThicknessMeasurement(isDisplayMetric, props.displayedMetricValue || props.metricValue, props.displayedImperialValue || props.imperialValue, props.hideUnits);
      imperialUnit = Units.Inches;
      metricUnit = Units.Millimeters;
      break;
    case QuoteLineItemFieldUnitTypes.Currency:
      if(props.value === null || props.value === undefined) {
        value = '0.00';
      } else {
        value = stringToFloat(props.value as string, numDecimals);
      }
      formattedDisplayedValue = formatCurrency(props.value as number, props.currency, numDecimals);
      break;
    case QuoteLineItemFieldUnitTypes.Weight:
      formattedDisplayedValue = formatWeight(localUom, props.metricValue, props.imperialValue);
      break;
    case QuoteLineItemFieldUnitTypes.CurrencyPerWeight:
      value = localUom === MeasurementSystems.Imperial ? stringToFloat(props.imperialValue as string, 3) : stringToFloat(props.metricValue as string, 3);
      formattedDisplayedValue = formatPricePerWeight(localUom, props.currency, props.displayedMetricValue, props.displayedImperialValue, props.hideUnits);
      imperialUnit = Units.PricePerPound;
      metricUnit = Units.PricePerKilogram;
      break;
    case QuoteLineItemFieldUnitTypes.Dimensions:
    case QuoteLineItemFieldUnitTypes.ItemDescription:
      formattedDisplayedValue = localUom === MeasurementSystems.Metric ? props.metricValue : props.imperialValue;
      break;
    case QuoteLineItemFieldUnitTypes.Percentage:
      value = stringToFloat(props.value as string, 2);
      formattedDisplayedValue = formatPercentage(props.value);
      break;
    case QuoteLineItemFieldUnitTypes.Date:
      formattedDisplayedValue = moment(props.value).format('DD-MMM-YYYY');
      break;
  }

  const EditedIndicator = (props: {
    edited: boolean
  }) => {
    return (props.edited ?
      <IonIcon class='ellipse-icon' icon={ellipse}></IonIcon> : null
    );
  };

  const CustomerSuppliedIndicator = () => {
    return (isCustomerProvidedMetal ?
      <Tooltip position={TooltipPositions.Bottom} text={`Customer supplied ${customerSuppliedMetalType}`}>
        <IonIcon class='customer-supplied-icon' icon={ellipse}></IonIcon>
      </Tooltip> : null
    );
  };

  const ExtraOptionsIndicator = () => {
    const [manualWeldEntryPopoverState, setShowManualWeldEntryPopover] = React.useState({ showPopover: false, event: undefined });

    let popoverElement: JSX.Element;
    let setShowPopover: React.Dispatch<React.SetStateAction<{
        showPopover: boolean;
        event: any;
    }>>;

    switch(valueType) {
    case QuoteLineItemValueTypes.Welding:
      setShowPopover = setShowManualWeldEntryPopover;

      popoverElement =
      <IonPopover
        cssClass='manual-weld-entry-popover'
        isOpen={manualWeldEntryPopoverState.showPopover}
        animated={ false }
        onDidDismiss={async () => {
          setShowManualWeldEntryPopover({ showPopover: false, event: undefined });
        }}
      >
        <ManualWeldEntryPopover
          setShowPopover={setShowManualWeldEntryPopover}
          lineToEdit={lineToEdit}
          detailToEdit={lineToEdit.quoteLineItemDetail}
        />
      </IonPopover>;

      break;
    }

    return (extraOptions ?
      <>
        {popoverElement}
        <IonIcon class="triangle-icon" src={triangle} onClick={() => setShowPopover({ showPopover: true, event: undefined })}></IonIcon>
      </> : null
    );
  };

  const InlineEditableField = (props: {
    tabbed: boolean,
    metricUnit: Units,
    imperialUnit: Units,
    regex: RegExp,
    inputType: TextFieldTypes,
    placeholder?: string,
    min?: string,
    step?: string,
    value: string | number,
    displayedValue: string | number,
  }) => {
    const [editing, setEditing] = React.useState(props.tabbed || alwaysEdit);
    const [hasError, setHasError] = React.useState(false);

    const textInput = React.useRef(null);

    const handleSelection = async () => {
      (await textInput.current.getInputElement()).select();
    };

    React.useEffect(() => {
      if(editing && !alwaysEdit || hasError) {
        textInput.current.setFocus();
      }
    }, [editing, hasError]);

    React.useEffect(() => {
      if(editing && !alwaysEdit) {
        handleSelection();
      }
    }, [editing]);

    const recalculateDependencyFields = (quoteLineType: QuoteLineTypes, lineToUpdate: QuoteLineItem, detailToUpdate: QuoteLineItemDetail, newValue: string | number, valueType: QuoteLineItemValueTypes, field : QuoteLineItemFields, imperialField: QuoteLineItemFields, metricField: QuoteLineItemFields, imperialUnit: Units, metricUnit: Units) => {
      const updatedLineItemFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
      const updatedLineItemDetailFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
      const updatedOutsideServicesFieldsMap = new Map<QuoteLineItemFields, FieldsMapValueType>();
      const dependentUnwrittenFieldsMap = new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>();
      const fieldsUpdated = [valueType];

      const quoteLineItemToEdit = { ...lineToUpdate };

      if(field) {
        switch(quoteLineType) {
          case QuoteLineTypes.Master:
            updatedLineItemFieldsMap.set(field, newValue);
            quoteLineItemToEdit[field] = newValue;
            break;
          case QuoteLineTypes.Detail:
            updatedLineItemDetailFieldsMap.set(field, newValue);
            quoteLineItemToEdit.quoteLineItemDetail[field] = newValue;
            break;
          case QuoteLineTypes.OutsideServices:
            updatedOutsideServicesFieldsMap.set(field, newValue);
            quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[field] = newValue;
            break;
        }
      } else if(imperialField && metricField) {
        (quoteLineType === QuoteLineTypes.Master ? updatedLineItemFieldsMap : updatedLineItemDetailFieldsMap).set(imperialField, localUom === MeasurementSystems.Imperial ? newValue : convertUnits(newValue as number, metricUnit, imperialUnit));
        (quoteLineType === QuoteLineTypes.Master ? updatedLineItemFieldsMap : updatedLineItemDetailFieldsMap).set(metricField, localUom === MeasurementSystems.Metric ? newValue : convertUnits(newValue as number, imperialUnit, metricUnit));
        (quoteLineType === QuoteLineTypes.Master ? quoteLineItemToEdit : quoteLineItemToEdit.quoteLineItemDetail)[imperialField] = localUom === MeasurementSystems.Imperial ? newValue : convertUnits(newValue as number, metricUnit, imperialUnit);
        (quoteLineType === QuoteLineTypes.Master ? quoteLineItemToEdit : quoteLineItemToEdit.quoteLineItemDetail)[metricField] = localUom === MeasurementSystems.Metric ? newValue : convertUnits(newValue as number, imperialUnit, metricUnit);
      }

      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.QuantityForLeftoverCalculation, quoteLineItemToEdit.Quantity__c);

      if(field === QuoteLineItemFields.Quantity || imperialField === QuoteLineItemFields.WidthIn || imperialField === QuoteLineItemFields.LengthIn) {
        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.Clads, null);
        quoteLineItemToEdit.quoteLineItemDetail.Clads__c = null;
      }

      if(field === QuoteLineItemFields.Quantity) {
        fieldsUpdated.push(QuoteLineItemValueTypes.QuantityForLeftoverCalculation);
      }

      if(field === QuoteLineItemFields.CladCost) {
        updatedLineItemFieldsMap.set(QuoteLineItemFields.CladCostCustomerSupplied, newValue as number);
        quoteLineItemToEdit.Clad_Cost_Customer_Supplied__c = newValue as number;
      }

      if(field === QuoteLineItemFields.CladFreight) {
        updatedLineItemFieldsMap.set(QuoteLineItemFields.CladFreightCustomerSupplied, newValue as number);
        quoteLineItemToEdit.Clad_Freight_Customer_Supplied__c = newValue as number;
      }

      if(field === QuoteLineItemFields.BaseCost) {
        updatedLineItemFieldsMap.set(QuoteLineItemFields.BaseCostCustomerSupplied, newValue as number);
        quoteLineItemToEdit.Base_Cost_Customer_Supplied__c = newValue as number;
      }

      if(field === QuoteLineItemFields.BaseFreight) {
        updatedLineItemFieldsMap.set(QuoteLineItemFields.BaseFreightCustomerSupplied, newValue as number);
        quoteLineItemToEdit.Base_Freight_Customer_Supplied__c = newValue as number;
      }

      const convertedNewValue = calculateUOMValue(localUom, lineToUpdate.Unit_of_Measure__c as MeasurementSystems, newValue as number, Units.Millimeters, Units.Inches);

      if(imperialField === QuoteLineItemFields.AnvilTKIn) {
        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseAnvilTK, convertedNewValue);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseAnvilTK__c = convertedNewValue;
      }

      if(imperialField === QuoteLineItemFields.BaseLengthIn) {
        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBaseLength, convertedNewValue);
        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.ASLength, convertedNewValue);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseBaseLength__c = convertedNewValue;
        quoteLineItemToEdit.quoteLineItemDetail.AS_Length__c = convertedNewValue;
      }

      if(imperialField === QuoteLineItemFields.BaseWidthIn) {
        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBaseWidth, convertedNewValue);
        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.ASWidth, convertedNewValue);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseBaseWidth__c = convertedNewValue;
        quoteLineItemToEdit.quoteLineItemDetail.AS_Width__c = convertedNewValue;
      }

      if(imperialField === QuoteLineItemFields.BaseTKIn) {
        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBaseTK, convertedNewValue);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseBaseTK__c = convertedNewValue;
      }

      if(imperialField === QuoteLineItemFields.CladLengthIn) {
        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladLength, convertedNewValue);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseCladLength__c = convertedNewValue;
      }

      if(imperialField === QuoteLineItemFields.CladWidthIn) {
        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladWidth, convertedNewValue);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseCladWidth__c = convertedNewValue;
      }

      if(imperialField === QuoteLineItemFields.CladTKIn) {
        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladTK, convertedNewValue);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseCladTK__c = convertedNewValue;
      }

      if(imperialField === QuoteLineItemFields.BaseFreightLB) {
        const convertedNewFreightPerWeight = calculateUOMValue(localUom, MeasurementSystems.Imperial, newValue as number, Units.PricePerKilogram, Units.PricePerPound);

        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBackerFreightLB, convertedNewFreightPerWeight);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseBackerFreight_LB__c = convertedNewFreightPerWeight;
      }

      if(metricField === QuoteLineItemFields.BaseFreightKG) {
        const convertedNewFreightPerWeight = calculateUOMValue(localUom, MeasurementSystems.Metric, newValue as number, Units.PricePerKilogram, Units.PricePerPound);

        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBackerFreightKG, convertedNewFreightPerWeight);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseBackerFreight_KG__c = convertedNewFreightPerWeight;
      }

      if(imperialField === QuoteLineItemFields.CladFreightLB) {
        const convertedNewFreightPerWeight = calculateUOMValue(localUom, MeasurementSystems.Imperial, newValue as number, Units.PricePerKilogram, Units.PricePerPound);

        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladderFreightLB, convertedNewFreightPerWeight);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseCladderFreight_LB__c = convertedNewFreightPerWeight;
      }

      if(metricField === QuoteLineItemFields.CladFreightKG) {
        const convertedNewFreightPerWeight = calculateUOMValue(localUom, MeasurementSystems.Metric, newValue as number, Units.PricePerKilogram, Units.PricePerPound);

        updatedLineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCladderFreightKG, convertedNewFreightPerWeight);
        quoteLineItemToEdit.quoteLineItemDetail.PurchaseCladderFreight_KG__c = convertedNewFreightPerWeight;
      }

      const { quoteLineItems: updatedQuoteLineItems }  = saveQuoteLineItem(dependentUnwrittenFieldsMap, fieldsUpdated, quoteLineItemToEdit);

      handleLeftoverQuantitiesForAutocombine(dependentUnwrittenFieldsMap, duplicatedQuoteLineItem(quoteLineItemToEdit), duplicatedQuoteLineItemDetail(quoteLineItemToEdit), updatedQuoteLineItems);

      if(quoteLineType === QuoteLineTypes.Detail || (quoteLineType === QuoteLineTypes.Master && (activeTab === QuoteItemSelectionTabs.Cost || activeTab === QuoteItemSelectionTabs.OSCost))) {
        if(lineToUpdate.Parent_Line__c) {
          const quoteLineItemToEdit = { ...updatedQuoteLineItems.find(quoteLineItem => quoteLineItem.Line__c === lineToUpdate.Parent_Line__c) };

          Array.from(updatedLineItemFieldsMap.keys()).forEach(field => {
            quoteLineItemToEdit[field] = updatedLineItemFieldsMap.get(field);
          });

          Array.from(updatedLineItemDetailFieldsMap.keys()).forEach(field => {
            quoteLineItemToEdit.quoteLineItemDetail[field] = updatedLineItemDetailFieldsMap.get(field);
          });

          Array.from(updatedOutsideServicesFieldsMap.keys()).forEach(field => {
            quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[field] = updatedOutsideServicesFieldsMap.get(field);
          });

          saveQuoteLineItem(new Map(), [valueType], quoteLineItemToEdit);
        } else {
          const childLinesToUpdate = updatedQuoteLineItems.filter(quoteLineItem => quoteLineItem.Parent_Line__c === lineToUpdate.Line__c);

          childLinesToUpdate.forEach(childLineToUpdate => {
            const quoteLineItemToEdit = { ...childLineToUpdate };

            Array.from(updatedLineItemFieldsMap.keys()).forEach(field => {
              quoteLineItemToEdit[field] = updatedLineItemFieldsMap.get(field);
            });

            Array.from(updatedLineItemDetailFieldsMap.keys()).forEach(field => {
              quoteLineItemToEdit.quoteLineItemDetail[field] = updatedLineItemDetailFieldsMap.get(field);
            });

            Array.from(updatedOutsideServicesFieldsMap.keys()).forEach(field => {
              quoteLineItemToEdit.quoteLineItemDetail.NC_Outside_Service_Item_Details__r[field] = updatedOutsideServicesFieldsMap.get(field);
            });

            saveQuoteLineItem(new Map(), [valueType], quoteLineItemToEdit);
          });
        }
      }
    };

    const duplicatedQuoteLineItem = (quoteLineItemToDuplicate: QuoteLineItem) => {
      return {
        ...quoteLineItemToDuplicate,
        quoteLineItemDetail: duplicatedQuoteLineItemDetail(quoteLineItemToDuplicate),
        Id: undefined,
        Line__c: (quoteLineItems.length + 1).toString().padStart(3, '0'),
        displayId: (quoteLineItems.length + 1).toString(),
      };
    };

    const duplicatedQuoteLineItemDetail = (quoteLineItemForDetail: QuoteLineItem) => {
      const quoteLineItemDetailToDuplicate = quoteLineItemForDetail.quoteLineItemDetail;

      return {
        ...quoteLineItemDetailToDuplicate,
        Id: undefined,
        NC_Quote_Line_Item__c: undefined,
        associatedLineItem: (quoteLineItems.length + 1).toString(),
        NC_Tubesheet_Item_Details__r: {
          ...quoteLineItemDetailToDuplicate.NC_Tubesheet_Item_Details__r,
          Id: undefined,
          NC_Quote_Line_Item_Detail__c: undefined
        },
        NC_Head_Item_Detail__r: {
          ...quoteLineItemDetailToDuplicate.NC_Head_Item_Detail__r,
          Id: undefined,
          NC_Quote_Line_Item_Detail__c: undefined
        },
        NC_Cylinder_Item_Details__r: {
          ...quoteLineItemDetailToDuplicate.NC_Cylinder_Item_Details__r,
          Id: undefined,
          NC_Quote_Line_Item_Detail__c: undefined
        },
        NC_Multi_Shot_Item_Details__r: {
          ...quoteLineItemDetailToDuplicate.NC_Multi_Shot_Item_Details__r,
          Id: undefined,
          NC_Quote_Line_Item_Detail__c: undefined
        },
        NC_Anvil_Item_Details__r: {
          ...quoteLineItemDetailToDuplicate.NC_Anvil_Item_Details__r,
          Id: undefined,
          NC_Quote_Line_Item_Detail__c: undefined
        },
        NC_Flyer_Item_Details__r: {
          ...quoteLineItemDetailToDuplicate.NC_Flyer_Item_Details__r,
          Id: undefined,
          NC_Quote_Line_Item_Detail__c: undefined
        },
        NC_Seam_Weld_Item_Details__r: {
          ...quoteLineItemDetailToDuplicate.NC_Seam_Weld_Item_Details__r,
          Id: undefined,
          NC_Quote_Line_Item_Detail__c: undefined
        },
        NC_Outside_Service_Item_Details__r: {
          ...quoteLineItemDetailToDuplicate.NC_Outside_Service_Item_Details__r,
          Id: undefined,
          NC_Quote_Line_Item_Detail__c: undefined
        }
      };
    };

    const commitToField = (value: string, tabToNextField?: boolean) => {
      if (props.value === value && nextTabIndex) {
        setHasError(false);
        setEditing(false);

        // note: this is a hack to wait for the updates or next tick to finish to render the page
        if(setTabIndex) {
          setTimeout(() => {
            if(tabToNextField) {
              setTabIndex(nextTabIndex);
            } else {
              setTabIndex(undefined);
            }
          });
        }

        return;
      }

      if (props.regex && !props.regex.test(value)) {
        setHasError(true);
      } else {
        setHasError(false);
        let newValue: any = props.inputType === 'number' ? +value : value;

        if (roundOff) {
          newValue = Math.round(newValue);
        }

        if(!ignoreRecalculateFields) {
          let lineToUpdate;

          let detailToUpdate;
          if(lineType === QuoteLineTypes.Master) {
            lineToUpdate = quoteLineItems?.find(quoteLineItem => (quoteLineItem.Id || quoteLineItem.displayId) === itemId);
            detailToUpdate = lineToUpdate.quoteLineItemDetail;
          } else {
            lineToUpdate = quoteLineItems.find(quoteLineItem => (quoteLineItem.Id || quoteLineItem.displayId) === lineId);
            detailToUpdate = lineToUpdate.quoteLineItemDetail;
          }

          recalculateDependencyFields(lineType, { ...lineToUpdate }, { ...detailToUpdate }, newValue, valueType, field, imperialField, metricField, imperialUnit, metricUnit);

          if(calculatedField) {
            if(newValue !== (lineType === QuoteLineTypes.Master ? lineToUpdate : detailToUpdate)[field || (localUom === MeasurementSystems.Imperial ? imperialField : metricField)]) {

              if(!calculatedFieldsEditedMap.has(itemId) || !calculatedFieldsEditedMap.get(itemId)?.includes(valueType)) {
                const updatedFieldsEdited = [...(calculatedFieldsEditedMap.get(itemId) || []), valueType];
                const updatedFieldsEditedMap = new Map(calculatedFieldsEditedMap.entries());

                updatedFieldsEditedMap.set(itemId, updatedFieldsEdited);
                dispatchQuoteState({type: QuoteActionTypes.updateCalculatedFieldsEdited, payload: updatedFieldsEditedMap});

                const updatedDefaultValueMap = new Map(defaultValueMap.entries());
                const updatedDefaultValueLineMap = new Map(updatedDefaultValueMap.get(itemId)?.entries());
                updatedDefaultValueLineMap.set(valueType, (lineType === QuoteLineTypes.Master ? lineToUpdate : detailToUpdate)[field || (localUom === MeasurementSystems.Imperial ? imperialField : metricField)]);
                updatedDefaultValueMap.set(itemId, updatedDefaultValueLineMap);
                dispatchQuoteState({type: QuoteActionTypes.updateDefaultValues, payload: updatedDefaultValueMap});
              } else if(newValue === defaultValueMap.get(itemId)?.get(valueType)) {
                const updatedFieldsEdited = calculatedFieldsEditedMap.get(itemId).filter(fieldEdited => fieldEdited !== valueType);
                const updatedFieldsEditedMap = new Map(calculatedFieldsEditedMap.entries());
                updatedFieldsEditedMap.set(itemId, updatedFieldsEdited);
                dispatchQuoteState({type: QuoteActionTypes.updateCalculatedFieldsEdited, payload: updatedFieldsEditedMap});
              }
            }
          } else {
            if(newValue !== (lineType === QuoteLineTypes.Master ? lineToUpdate : detailToUpdate)[field || (localUom === MeasurementSystems.Imperial ? imperialField : metricField)]) {

              if(!inputFieldsEditedMap?.has(itemId) || !inputFieldsEditedMap?.get(itemId)?.includes(valueType)) {
                const updatedFieldsEdited = [...(inputFieldsEditedMap?.get(itemId) || []), valueType];
                const updatedFieldsEditedMap = new Map(inputFieldsEditedMap?.entries());
                updatedFieldsEditedMap.set(itemId, updatedFieldsEdited);
                dispatchQuoteState({type: QuoteActionTypes.updateInputFieldsEdited, payload: updatedFieldsEditedMap});
              }
            }
          }

          setEditing(false);

          if(setTabIndex) {
            if(tabToNextField) {
              setTabIndex(nextTabIndex);
            } else {
              setTabIndex(undefined);
            }
          }
        } else {
          // if there is a callback, valueType, and indexToUpdate, and callback is a function, call the callback
          if (fieldsChangedCB && typeof fieldsChangedCB === 'function') {
            fieldsChangedCB(newValue as number, valueType, indexToUpdate);
          }
          setEditing(false);
        }
      }
    };

    return (!editing || !valueType ?
      <span className="line-item-field-text editable" data-testid={datatestid} onClick={() => setEditing(true)}>{ formattedDisplayedValue || props.displayedValue }</span> :
      <IonInput
        class={classNames({'editable-text-input': true, 'error': hasError})}
        type={props.inputType}
        placeholder={props.placeholder}
        min={props.min}
        step={props.step}
        onBlur={(e) => commitToField(e.target.value as string)}
        onKeyPress={(e) => {
          if(e.key === 'Enter') {
            commitToField(textInput.current.value);
          }
        }}
        onKeyDown={(e) => {
          if(e.key === 'Tab') {
            e.preventDefault();
            commitToField(textInput.current.value, true);
          }
        }}
        value={props.value}
        defaultValue={props.value}
        ref={(ref) => textInput.current = ref}
        data-testid={'QALineItemFieldTextInput_' + valueType}
      />
    );
  };

  switch(editType) {
  case QuoteLineItemFieldEditTypes.Inline:
    return (
      <>
        <EditedIndicator edited={calculatedFieldsEditedMap?.get(itemId)?.includes(valueType)}/>
        <InlineEditableField
          inputType={inputType}
          placeholder={placeHolder}
          regex={regex}
          min={min}
          step={step}
          displayedValue={formattedDisplayedValue || value || props.value}
          value={value || props.value}
          imperialUnit={imperialUnit}
          metricUnit={metricUnit}
          tabbed={props.selectedTabIndex !== undefined && props.tabIndex !== undefined && props.selectedTabIndex === props.tabIndex}
        />
        <ExtraOptionsIndicator/>
        <CustomerSuppliedIndicator/>
      </>
    );
  case QuoteLineItemFieldEditTypes.None:
  default:
    return (
      <span className="line-item-field-text" data-testid={datatestid}>{ formattedDisplayedValue || props.displayedValue }</span>
    );
  }
};

export default LineItemField;