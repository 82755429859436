import * as React from 'react';
import { useRef, useState } from 'react';
import { IonHeader, IonButton, IonLabel, IonIcon, IonPopover } from '@ionic/react';
import { environment } from '@environment';
import classNames from 'classnames';
import { useEnvironment } from '@services/environment-service';
import ncLogo from '@assets/images/logo-nc.svg';
import ncLogoWhite from '@assets/images/logo-nc-white.svg';
import myAccountIcon from '@assets/icon/Account.svg';
import myAccountIconDarkMode from '@assets/icon/Account - White.svg';
import myAccountIconWhite from '@assets/icon/my_account.svg';
import myAccountIconUserRibbonDarkMode from '@assets/icon/my_account_black.svg';
import notificationsIcon from '@assets/icon/Notifications.svg';
import notificationsIconWhite from '@assets/icon/Notifications - White.svg';
// import feedbackIcon from '@assets/icon/support_feedback.png';
import './topbar.scss';
import { Subscription } from 'rxjs';
import { navOptions$ }  from '@services/navigation-service';
import MyAccountPopover from '@components/popover/my-account-popover/my-account-popover';
import { useSalesforce } from '@services/salesforce/salesforce-service';
import { useHistory } from 'react-router';
import { useNotifications } from '@services/notifications/notifications-service';
import NotificationsPopover from '@components/popover/notifications-popover/notifications-popover';
import { SearchFields, UserType } from '@constants';
import { useDarkMode } from '@hooks/useDarkMode';
import { search } from 'ionicons/icons';
import { findOrderById } from '@shared/order-utils';
import { findInvoiceById } from '@shared/invoice-utils';
import { Order } from '@interfaces/order';
import { Invoice } from '@interfaces/invoice';
import SearchBar from '@components/search-bar/search-bar';
import SyncIndicator from './sync-indicator/sync-indicator';
import { Notification } from '@interfaces/notification';
import { MappedNotification } from '@shared/types';

interface Props {
  orders: Order[];
  orderSearchValues: any[];
  invoices: Invoice[];
  invoiceSearchValues: any[];
  unmappedNotifications: Notification[];
  finalizedNotifications: MappedNotification[];
  loadNotifications: () => void;
}

const Topbar = (props: Props) => {
  const displayedNotifications = React.useMemo(() => {
    return props.finalizedNotifications?.slice(0,6) ?? [];
  }, [props.finalizedNotifications]);

  const salesforce = useSalesforce();
  const environmentService = useEnvironment();
  const notifications = useNotifications();
  const { darkMode } = useDarkMode();
  const history = useHistory();

  const [tabsLeft, setTabsLeft] = React.useState([]);
  const [contact, setContact] = React.useState(undefined);
  const [, setAccount] = React.useState(undefined);
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const [searchBarOpen, setSearchBarOpen] = React.useState(false);

  React.useEffect(() => {
    subscriptions.push(navOptions$.subscribe(options => {
      if (options) {
        setTabsLeft(options.tabs.map(tab => ({ ...tab, isActive: isActiveTab(tab.path) })));
      }
    }));

    subscriptions.push(salesforce.userInfo$().subscribe(async userInfo => {
      setContact(userInfo?.contact);
      setAccount(userInfo.account);
    }));

    return () => {
      subscriptions.forEach(s => s.unsubscribe());
    };
  }, []);

  React.useEffect(() => {
    if(mobileNavOpen) {
      setSearchBarOpen(false);
    }
  }, [mobileNavOpen]);

  React.useEffect(() => {
    if(searchBarOpen) {
      setMobileNavOpen(false);
    }
  }, [searchBarOpen]);

  const subscriptions: Subscription[] = [];

  const userType = () => {
    return environmentService.userType();
  };

  const isActiveTab = (path: string): boolean => {
    return window.location.href.includes(path);
  };

  // const openFeedbackModal = async() => {
  //   //Fix this later if we need feedback
  // };

  const numUnreadNotificationsFormula = () => {
    return (props.unmappedNotifications || [])
      .filter(notification => !notification.lastViewedDateTime)
      .reduce((numUnreadNotifications) => numUnreadNotifications + 1, 0);
  };

  const navigate = (path: string) => {
    const handler = {
      'about-us': () => { window.open(`${environment.DMC_WEB_ROOT}/about`, '_blank'); },
      'resources': () => { window.open(`${environment.DMC_WEB_ROOT}/resources`, '_blank'); },
      'products': () => { window.open(`${environment.DMC_WEB_ROOT}/products`, '_blank'); },
      'industries': () => { window.open(`${environment.DMC_WEB_ROOT}/industries`, '_blank'); },
      'dashboard': () => { history.push('/dashboard'); },
      'quotes': () => { history.push('/quotes'); },
      'orders': () => { history.push('/orders'); },
      'invoices': () => { history.push('/invoices'); },
      'certifications': () => { history.push('/certifications'); },
    }[path];

    if (path && handler) {
      handler();
    }
  };

  // Handles click event for when user clicks on topbar link
  const navigateToPath = (event: any) => {
    const { path } = event.currentTarget.dataset;
    navigate(path);
  };

  const showMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const Tab = (props) => {
    return (
      props.tab.path !== '' && props.tab.label ?
        <IonButton
          id="{{props.tab.label}}-left-nav-btn"
          fill="default"
          class={classNames({
            'ion-no-margin': true,
            'topbar-buttons': true,
            'active-tab': props.tab.isActive,
            'first-button': props.tab.label === 'Dashboard'
          })}
          onClick={navigateToPath}
          data-path={props.tab.path}
          data-testid={'QATopBarTab_' + props.tab.label}
        >
          <div className='navigation-title'>
            <IonLabel class="left-nav-label p1"
              data-path={props.tab.path}
            >
              {props.tab.label}
            </IonLabel>
          </div>
        </IonButton> : null
    );
  };

  // const FeedbackButton = () => {
  //   return (
  //     userType() === 'external' ?
  //       <img className='feedback-img'
  //         src={feedbackIcon}
  //         onClick={openFeedbackModal}
  //         alt="feedback"
  //         data-testId="QAFeedbackBtn"
  //       /> : null
  //   );
  // };

  const SearchIcon = () => {
    return (
      <IonIcon class="nc-icon-size search-icon"
        src={search}
        onClick={
          () => {
            setSearchBarOpen(true);
          }}
        data-testid="QATopBarSearchBtn"
      >
      </IonIcon>
    );
  };

  const MyAccountIcon = () => {
    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
    const myAccountPopover = useRef(null);

    const AccountPopoverCaret = () => {
      return (
        popoverState.showPopover ?
          <span className="caret"></span> : null
      );
    };

    return (
      <>
        <IonPopover
          cssClass='my-account-popover'
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          animated={ false }
          showBackdrop={ false }
          ref={myAccountPopover}
          onWillPresent={()=> myAccountPopover.current.style.left = `calc(100vW - ${window.innerWidth}px)`}
          onDidDismiss={() => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
        >
          <MyAccountPopover setShowPopover={setShowPopover}/>
        </IonPopover>
        <IonIcon class="nc-icon-size my-account-icon"
          src={!darkMode ? myAccountIcon : myAccountIconDarkMode}
          onClick={
            (e: any) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e });
            }}
          data-testid="QATopBarMyAccountBtn"
        >
        </IonIcon>
        <span className="caret-container">
          <AccountPopoverCaret/>
        </span>
      </>
    );
  };

  const UnreadNotificationsContainer = (innerProps) => {
    const [numUnreadNotifications, setNumUnreadNotifications] = React.useState(numUnreadNotificationsFormula());

    React.useEffect(() => {
      subscriptions.push(notifications.notificationViewed$().subscribe(notificationId => {
        if(props.unmappedNotifications && notificationId) {
          props.unmappedNotifications.find(notification => notification.id === notificationId).lastViewedDateTime = new Date().toLocaleString();
          setNumUnreadNotifications(numUnreadNotificationsFormula());
        }
      }));
    }, []);

    return (numUnreadNotifications > 0 ?
      <div className="unread-notifications-container" onClick={
        (e: any) => {
          e.persist();
          innerProps.setShowPopover({ showPopover: true, event: e });
        }}
      >
        <IonLabel class="unread-notifications">{numUnreadNotifications}</IonLabel>
      </div> : null
    );
  };

  const NotificationsIcon = () => {
    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
    const notificationsPopover = useRef(null);

    const NotificationsPopoverCaret = () => {
      return (
        popoverState.showPopover ?
          <span className="caret"></span> : null
      );
    };

    return (
      <>
        <IonPopover
          cssClass='notifications-popover'
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          animated={ false }
          showBackdrop={ false }
          ref={notificationsPopover}
          onWillPresent={()=> notificationsPopover.current.style.left = `calc(100vW - ${window.innerWidth}px)`}
          onDidDismiss={() => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
        >
          <NotificationsPopover
            unmappedNotifications={props.unmappedNotifications}
            displayedNotifications={displayedNotifications}
            loadNotifications={props.loadNotifications}
          />
        </IonPopover>
        <IonIcon class="nc-icon-size notifications-icon"
          src={!darkMode ? notificationsIcon : notificationsIconWhite}
          onClick={
            (e: any) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e });
            }}
          data-testid="QATopBarNotificationsBtn"
        >
        </IonIcon>
        <UnreadNotificationsContainer setShowPopover={setShowPopover}/>
        <span className="caret-container">
          <NotificationsPopoverCaret/>
        </span>
      </>
    );
  };

  const MobileNavButton = () => {
    return (
      !mobileNavOpen ?
        <div className={classNames({ 'navigation__mobile-button': true, 'user-ribbon-exists': userType() === UserType.Sales && contact })} onClick={() => showMobileNav()}>
          <div className='mobile-button-bar'/>
          <div className='mobile-button-bar'/>
          <div className='mobile-button-bar'/>
        </div> :
        <div className={classNames({ 'navigation__mobile-button': true, 'user-ribbon-exists': userType() === UserType.Sales && contact })} onClick={() => showMobileNav()}>
        X
        </div>
    );
  };

  const LoggedInRibbon = () => {
    return (
      userType() === UserType.Sales && contact ?
        <div className="logged-in-ribbon" data-testid='QATopBarLoggedInRibbon'>
          <IonIcon class="my-account-icon" src={!darkMode ? myAccountIconWhite : myAccountIconUserRibbonDarkMode} />
          <IonLabel class='logged-in-as' data-testid='QATopBarLoggedInRibbonLabel'>Logged in as <span className='bold'>{contact?.FirstName} {contact?.LastName} ({contact?.Email})</span></IonLabel>
        </div>
        : null
    );
  };

  const SearchContainer = (props) => {
    const history = useHistory();

    const [searchInput, setSearchInput] = React.useState(undefined);

    // Navigates to the orders page with search value
    const navigateToOrdersPage = (orderAccount?: string) => {
      history.push('/orders', { orderSearchInput: orderAccount || searchInput});
    };

    // Navigates to the order detail page for the selected order
    const navigateToOrderDetail = (order: Order) => {
      history.push('/orders/order-detail', { order });
    };

    // Navigates to the order detail page by order id
    const navigateToOrderDetailById = (orderId: string) => {
      history.push(`/orders/order-detail/${orderId}`);
    };

    const navigateToInvoiceDetail = (invoice: Invoice) => {
      history.push('/invoices/invoice-detail', { invoice });
    };

    // Navigates to the invoice detail page by invoice id
    const navigateToInvoiceDetailById = (invoiceId: string) => {
      history.push(`/invoices/invoice-detail/${invoiceId}`);
    };

    // Action that occurs when clicking on an order search suggestion
    const suggestionClickHandler = async (suggestionClicked: any) => {
      if(suggestionClicked.matchingDisplayField === SearchFields.AXOrderNumber || suggestionClicked.matchingDisplayField === SearchFields.PoNumber) {
        const order = await findOrderById(props.orders, suggestionClicked.Id);

        if(order) {
          navigateToOrderDetail(order);
        } else {
          navigateToOrderDetailById(suggestionClicked.Id);
        }
      } else if(suggestionClicked.matchingDisplayField === SearchFields.AccountName) {
        navigateToOrdersPage(suggestionClicked[SearchFields.AccountName]);
      } else if(suggestionClicked.matchingDisplayField === SearchFields.InvoiceName) {
        const invoice = await findInvoiceById(props.invoices, suggestionClicked.Id);

        if (invoice) {
          navigateToInvoiceDetail(invoice);
        } else {
          navigateToInvoiceDetailById(suggestionClicked.Id);
        }
      }
    };

    //Returns suggestions depending on orderSearchValues or orders
    const getSuggestions = () => {
      return !props.orderSearchValues?.length ?
        [...props.orders, ...props.invoices] :
        [...props.orderSearchValues, ...(props.invoiceSearchValues || [])];
    };

    return (
      <SearchBar
        noneFoundText='No items found'
        placeholder='Search...'
        suggestions={getSuggestions()}
        suggestionDisplayFields={[SearchFields.AXOrderNumber, SearchFields.PoNumber, SearchFields.InvoiceName]}
        commonSuggestionDisplayFields={[SearchFields.AccountName]}
        searchInputChangedHandler={(searchInput) => setSearchInput(searchInput)}
        suggestionClickHandler={(suggestionClicked) => suggestionClickHandler(suggestionClicked)}
        enterPressedHandler={() => navigateToOrdersPage()}
        closeSearchBar={() => setSearchBarOpen(false)}
        userRibbonExists={userType() === UserType.Sales && contact}
      />
    );
  };

  const SearchBarContainer = () => {
    return (searchBarOpen ?
      <div>
        <SearchContainer
          orders={props.orders}
          orderSearchValues={props.orderSearchValues}
          invoices={props.invoices}
          invoiceSearchValues={props.invoiceSearchValues}
        />
      </div> : null
    );
  };

  return (
    <IonHeader mode="ios"
      class="top-bar-container"
    >
      <LoggedInRibbon/>
      <div className="topbar">
        <div className="left-side">
          <img data-testid='QATopBarLogo' className='logo' src={!darkMode ? ncLogo : ncLogoWhite} onClick={() => navigate('dashboard')} alt="logo"/>
          <div className={classNames({
            'navigation': true,
            'navigation-title': true,
            'show-mobile-navigation': mobileNavOpen,
          })}
          >
            <div className='tabs-parent-container'>
              <nav>
                <div className='tabs-child-container'>
                  {tabsLeft.map((tab, index) => (
                    <Tab tab={tab} key={index}/>
                  ))}
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="right-side">
          <div style={{ marginRight: 36 }}>
            <SyncIndicator />
          </div>
          <div className="search-icon-container">
            <SearchIcon/>
          </div>
          <div className="my-account-container">
            <MyAccountIcon/>
          </div>
          <div className="notifications-container">
            <NotificationsIcon/>
          </div>
          <MobileNavButton/>
        </div>
      </div>
      <SearchBarContainer />
    </IonHeader>
  );
};

export default Topbar;