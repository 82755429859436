import * as React from 'react';
import { useEffect, useState } from 'react';

import ToggleLock from '@components/toggle-lock/toggle-lock';
import { IonLabel } from '@ionic/react';

import { MeasurementSystems } from '@constants';
import { QuoteActionTypes, useQuoteDispatch } from '@context/quote-context';

interface DocumentUOMProps {
  documentUom: MeasurementSystems;
}

const DocumentUOM = ({ documentUom }: DocumentUOMProps) => {
  const [isLabelLocked, setIsLabelLocked] = useState(true);
  const { dispatchQuoteState } = useQuoteDispatch();
  const [isLocked, setIsLocked] = useState(true);
  
const handleLabelClick = (): void => {
    setIsLocked(true);
    const oppositeUOM =
      documentUom === MeasurementSystems.Metric ? MeasurementSystems.Imperial : MeasurementSystems.Metric;

    dispatchQuoteState({ type: QuoteActionTypes.setDocumentUOM, payload: oppositeUOM });
    setIsLabelLocked(!isLabelLocked);
  };

  // If the user opens and then closes the lock, we want to reset the labelLock state.
  // This is so that the user can't change the UOM by clicking on the label with the lock closed.
  useEffect(() => {
    if (!isLabelLocked && isLocked) {
      setIsLabelLocked(true);
    }
  }, [isLocked, isLabelLocked]);

  return (
    <>
      <IonLabel
        style={!isLabelLocked ? { cursor: 'pointer' } : {}}
        class='quote-info-value'
        onClick={!isLabelLocked ? handleLabelClick : null}
      >
        {(documentUom as string) || 'Has not been set'}
      </IonLabel>
      <ToggleLock onToggle={() => setIsLabelLocked(!isLabelLocked)} isLocked={isLocked} setIsLocked={setIsLocked} />
    </>
  );
};

export default DocumentUOM;
