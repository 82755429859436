import * as React from 'react';
import { IonButton, IonCard, IonCardHeader, IonCol, IonGrid, IonImg, IonLabel, IonRow } from '@ionic/react';
import './generate-bom-popover.scss';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { BomHeaders, FieldTypes, FinishedProductTypes, GenerateBomTabs, ManufacturingSites, MeasurementSystems, MetalPurchaseRequirementsGroups, QuoteLineItemFieldUnitTypes, QuoteLineItemValueTypes, QuoteVariableConfigDescriptions, RawMaterialUsage, ToastMessages, ToastTypes, Units } from '@constants';
import { useQuoteState } from '@context/quote-context';
import Tab from '@components/tab/tab';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
import LineItemField from '@components/line-item-field/line-item-field';
import { calculateUOMValue, getMetalPurchasingRequirements, getQuoteVariableConfiguration } from '@shared/quote-utils';
import { useToast } from '@hooks/useToast';
import { PortableTextComponents, PortableText } from '@portabletext/react';
import { useDataState } from '@context/data-context';
import { RawMaterial } from '@interfaces/raw-material';
import { CopyableTable } from '@components/copyable-table/copyable-table';
import { CopyableInfoList } from '@components/copyable-info-list/copyable-info-list';
import { TextAlign } from 'chart.js';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { useLocalUom } from '@hooks/useLocalUom';

type Props = {
  setShowModal: (showme: boolean) => void;
};

const GenerateBomPopover = ({
  setShowModal,
}: Props) => {
  const { darkMode } = useDarkMode();
  const toast = useToast(4000);

  const { dataState } = useDataState();
  const { rawMaterials, quoteVariableConfigurations } = dataState;

    const { localUom, isDisplayImperial } = useLocalUom();

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { quoteLineItems, Manufacturing_Site__c: manufacturingSite } = quote;

  const tabs = [GenerateBomTabs.BaseMetal, GenerateBomTabs.CladMetal];
  const richTextComponents: PortableTextComponents = {
    types: {
      copyableTable: CopyableTable,
      copyableInfoList: CopyableInfoList
    }
  };

  const [activeTab, setActiveTab] = React.useState(GenerateBomTabs.BaseMetal);
  const [topRichTextForBase, setTopRichTextForBase] = React.useState(undefined);
  const [tableRichTextForBase, setTableRichTextForBase] = React.useState(undefined);
  const [bottomRichTextForBase, setBottomRichTextForBase] = React.useState(undefined);
  const [topRichTextForClad, setTopRichTextForClad] = React.useState(undefined);
  const [tableRichTextForClad, setTableRichTextForClad] = React.useState(undefined);
  const [bottomRichTextForClad, setBottomRichTextForClad] = React.useState(undefined);
  const selectedBasesToGenerateBom = React.useRef<QuoteLineItem[]>([]);
  const selectedCladsToGenerateBom = React.useRef<QuoteLineItem[]>([]);
  const [isAllBasesChecked, setIsAllBasesChecked] = React.useState<boolean>(false);
  const [isAllCladsChecked, setIsAllCladsChecked] = React.useState<boolean>(false);
  /** functions */
  const dismissModal = () => {
    setShowModal(false);
  };

  const createTopRichText = (metal: RawMaterial) => {
    const infoMap = new Map<string, string>([
      ['Metal', metal.metalLongName],
      ['Dimensions in', isDisplayImperial ? Units.Inches : Units.Millimeters]
    ]);

    const richText =
    {
      _type: 'copyableInfoList',
      _key: 'top-info-list',
      infoMap,
      infoListStyle: { width: '100%' },
      infoRowStyle: { marginBottom: '10px' },
      labelCellStyle: { height: '14px', width: '15%' },
      valueCellStyle: { height: '14px' },
      infoLabelStyle: { fontFamily: 'Calibri', fontSize: '11pt', fontWeight: 'bold', margin: '0' },
      infoValueStyle: { fontFamily: 'Calibri', fontSize: '11pt', margin: '0', whiteSpace: 'inherit' }
    };

    return richText;
  };

  const createTableRichText = (rawMaterialUsage: RawMaterialUsage, qlis: QuoteLineItem[], metal?: any) => {
    const isForging = metal.dmcClass.includes('/F');
    let tableHeaders = [
      BomHeaders.Item,
      BomHeaders.Qty,
      BomHeaders.Diameter,
      BomHeaders.ThicknessNom,
      BomHeaders.Price,
      BomHeaders.ShippingTime,
      BomHeaders.Incoterm
    ];

    if (!isForging || rawMaterialUsage !== RawMaterialUsage.Base) {
      tableHeaders.splice(2, 1, BomHeaders.Width, BomHeaders.Length);
    }

    const precision = isDisplayImperial ? 3 : 0;
    const precisionTK = isDisplayImperial ? 3 : 2;

    const tableRows = [];
    qlis.forEach(qli => {
      const qlid = qli.quoteLineItemDetail;

      if(rawMaterialUsage === RawMaterialUsage.Base) {
        const convertedBaseWidth = (isDisplayImperial ? qlid.Base_Width_in__c : qlid.Base_Width_mm__c).toFixed(precision);
        const convertedBaseLength = (isDisplayImperial ? qlid.Base_Length_in__c : qlid.Base_Length_mm__c).toFixed(precision);
        const convertedBaseTK = (isDisplayImperial ? qlid.Base_TK_in__c : qlid.Base_TK_mm__c).toFixed(precisionTK);
        let row = [qli.Line__c, qlid.Clads__c, convertedBaseWidth, convertedBaseTK, ' ', ' ', ' '];

        if (!isForging) {
          row.splice(3, 0, convertedBaseLength);
        }
      
        tableRows.push(row);
      } else {
        const convertedCladWidth = (isDisplayImperial ? qlid.Clad_Width_in__c : qlid.Clad_Width_mm__c).toFixed(precision);
        const convertedCladLength = (isDisplayImperial ? qlid.Clad_Length_in__c : qlid.Clad_Length_mm__c).toFixed(precision);
        const convertedCladTK = (isDisplayImperial ? qlid.Clad_TK_in__c : qlid.Clad_TK_mm__c).toFixed(precisionTK);
        tableRows.push([qli.Line__c, qlid.Clads__c, convertedCladWidth, convertedCladLength,  convertedCladTK, ' ', ' ', ' ']);
      }
    });

    const richText =
    {
      _type: 'copyableTable',
      _key: 'lines-table',
      headers: tableHeaders,
      rows: tableRows,
      tableStyle: { width: '100%' },
      headerCellStyle: { borderTop: 'solid 1px', borderBottom: 'solid 1px', height: '28px', textAlign: 'center' as TextAlign, verticalAlign: 'middle' },
      headerTextStyle: { fontSize: '11pt', fontWeight: 'bold', fontFamily: 'Calibri' },
      cellStyle: { borderBottom: 'solid 1px', height: '28px', textAlign: 'center' as TextAlign, verticalAlign: 'middle', fontFamily: 'Calibri', fontSize: '11pt' },
      cellTextStyle: { fontFamily: 'Calibri', fontSize: '11pt' }
    };

    return richText;
  };

  const getSupplierRestrictionsString = (defaultRestrictions: string, additionalRestrictions: string, notes: string) => {
    return `${additionalRestrictions || ''}${notes ? `: ${notes}` : ''} ${defaultRestrictions}`;
  };

  const getEditionAddendaString = (defaultEdAd: string, specifiedEdition: string, specifiedAddenda: string) => {
    return specifiedEdition || specifiedAddenda ? `Edition: ${specifiedEdition}\nAddenda: ${specifiedAddenda}` : defaultEdAd;
  };

  const getPreBondDescription = (isPreBond: boolean, qlid: QuoteLineItemDetail) => {
    const description = `Location: ${qlid.PreBondImpactsTestLocation__c}\nDirection: ${qlid.PreBondImpactsTestDirection__c}\n${qlid.PreBondImpactsTestUOM__c}\n${qlid.PreBondImpactsTestAVG__c} Avg/${qlid.PreBondImpactsTestMIN__c} Min\n${qlid.PreBondImpactsTestTemperature__c} ${qlid.PreBondImpactsTestTemperatureUOM__c}`;
    return isPreBond ? description : '';
  };

  const getBaseHardness = (qlid: QuoteLineItemDetail) => {
    const hardness = qlid.HardnessBaseMetalSurface__c ? `${qlid.HardnessBaseMetalSurface__c} ${qlid.HardnessBaseRejectionCriteria__c}` : '';
    return hardness;
  };

  const getCladHardness = (qlid: QuoteLineItemDetail) => {
    const hardness = qlid.HardnessCladdingMetalSurface__c ? `${qlid.HardnessCladdingMetalSurface__c} ${qlid.HardnessCladdingRejectionCriteria__c}` : '';
    return hardness;
  };


  const createBottomRichText = (rawMaterialUsage: RawMaterialUsage, metal: RawMaterial, qlid: QuoteLineItemDetail) => {
    let infoMap;

    if(rawMaterialUsage === RawMaterialUsage.Base) {
      infoMap = new Map<string, any>([
        [MetalPurchaseRequirementsGroups.HeatTreatment, `${qlid.NCBaseHeatTreatmentasSupplied__c}${qlid.NCBaseHeatTreatmentasSuppliedNotes__c ? ` ${qlid.NCBaseHeatTreatmentasSuppliedNotes__c}` : ''}`],
        // [MetalPurchaseRequirementsGroups.FGHeatTreatment, qlid.FinishedGoodHeatTreatment__c ? `${qlid.FinishedGoodHeatTreatment__c} ${qlid.FinishedGoodHeatTreatmentNotes__c} ${qlid.PrecladBaseMetalUT__c} ${qlid.PrecladBaseMetalUTType__c} ${qlid.PrecladBaseMetalUTNotes__c} ` : ''],
        [MetalPurchaseRequirementsGroups.ChemistryRequirements, `${qlid.NCBaseChemistryRestrictionsforBonding__c}${qlid.NCBaseChemistryRestForBondingNotes__c ? ` ${qlid.NCBaseChemistryRestForBondingNotes__c}` : ''}`],
        [MetalPurchaseRequirementsGroups.CustomerChemistryRestrictions, qlid.CustomerBaseChemistryRestrictions__c],
        [MetalPurchaseRequirementsGroups.Carbon, qlid.Carbon_Percent__c],
        [MetalPurchaseRequirementsGroups.CEQ, qlid.Carbon_Equivalency_Percent__c],
        [MetalPurchaseRequirementsGroups.Phosphorus, qlid.Phosphorus_Percent__c],
        [MetalPurchaseRequirementsGroups.Sulphur, qlid.Sulphur__c],
        [MetalPurchaseRequirementsGroups.J, qlid.JFactorSA387S62__c],
        [MetalPurchaseRequirementsGroups.X, qlid.XFactorSA387S62__c],
        [MetalPurchaseRequirementsGroups.MetalProductAnalysis, `${qlid.BaseMetalProductAnalysisRequiredNotes__c} ${qlid.BaseMetalProductAnalysisType__c}`],
        [MetalPurchaseRequirementsGroups.MetallurgicalRequirements, `${qlid.NCMetallurgicReqForBase__c}  ${qlid.VacuumDegassed__c ? '\n Vacuum treatment per SA-20 s1 required' : ''}`],
        [MetalPurchaseRequirementsGroups.CalciumTesting, qlid.CalciumTreatedSulfideShapeControl__c ? MetalPurchaseRequirementsGroups.CalciumRequired : '' ],
        [MetalPurchaseRequirementsGroups.Austenitic, qlid.AusteniticGrainSizeFinerThanValue__c],
        [MetalPurchaseRequirementsGroups.HardnessBaseMetalSurface, getBaseHardness(qlid)],
        [MetalPurchaseRequirementsGroups.TestingRequirements, qlid.NCBaseTestingRequirements__c],
        [MetalPurchaseRequirementsGroups.TensileTest, qlid.BaseTensileTestLocation__c],
        [MetalPurchaseRequirementsGroups.ImpactTesting, qlid.PrebondImpacts__c ? getPreBondDescription(qlid.PrebondImpacts__c, qlid) : ''],
        [MetalPurchaseRequirementsGroups.HICTestNotes, qlid.HicTestNotes__c],
        [MetalPurchaseRequirementsGroups.SPWHTGroupACycle1, qlid.SPWHTGroupACycle1__c],
        [MetalPurchaseRequirementsGroups.SPWHTGroupACycle2, qlid.SPWHTGroupACycle2__c],
        [MetalPurchaseRequirementsGroups.SPWHTGroupACycle3, qlid.SPWHTGroupACycle3__c],
        [MetalPurchaseRequirementsGroups.SPWHTGroupBCycle1, qlid.SPWHTGroupBCycle1__c],
        [MetalPurchaseRequirementsGroups.SPWHTGroupBCycle2, qlid.SPWHTGroupBCycle2__c],
        [MetalPurchaseRequirementsGroups.SPWHTGroupBCycle3, qlid.SPWHTGroupBCycle3__c],
        [MetalPurchaseRequirementsGroups.SPWHTGroupCCycle1, qlid.SPWHTGroupCCycle1__c],
        [MetalPurchaseRequirementsGroups.SPWHTGroupCCycle2, qlid.SPWHTGroupCCycle2__c],
        [MetalPurchaseRequirementsGroups.SPWHTGroupCCycle3, qlid.SPWHTGroupCCycle3__c],
        [MetalPurchaseRequirementsGroups.SPWHT, 1],
        [MetalPurchaseRequirementsGroups.BaseMetalUT, qlid.PrecladBaseMetalUT__c],
        [MetalPurchaseRequirementsGroups.EdgeSurfaceCondition, qlid.NCEdgeSurfaceRequirement__c],
        [MetalPurchaseRequirementsGroups.FlatnessRequirements, qlid.FlatteningNotes__c],
        [MetalPurchaseRequirementsGroups.SupplierRestrictions, getSupplierRestrictionsString(getMetalPurchasingRequirements(metal, MetalPurchaseRequirementsGroups.SupplierRestrictions, manufacturingSite), qlid.CustomerBaseMetalSupplierRestrictions__c, qlid.CustBaseMetalSupplierRestrictionsNotes__c)],
        [MetalPurchaseRequirementsGroups.SpecificationEditionAddenda, getEditionAddendaString(getMetalPurchasingRequirements(metal, MetalPurchaseRequirementsGroups.SpecificationEditionAddenda, manufacturingSite), qlid.BaseMetalSpecEdition__c, qlid.BaseMetalSpecAddendum__c)],
        [MetalPurchaseRequirementsGroups.Certification, `${qlid.CertificationTypeRequired__c} \n ${qlid.NCBaseCertificateRequired__c}`],
        [MetalPurchaseRequirementsGroups.WeldRepair, qlid.NCBaseIsWeldRepairAllowed__c],
        [MetalPurchaseRequirementsGroups.Marking, qlid.NCBaseMarkingRequired__c],
        [MetalPurchaseRequirementsGroups.AdditionalRequirementsBaseMetal, qlid.AdditionalRequirementsBaseMetal__c],
        [MetalPurchaseRequirementsGroups.Delivery, manufacturingSite === ManufacturingSites.US01 ? 'Mount Braddock, PA' : 'Liebenscheid, DE'],
        //If US - Mt. Braddock, if DE - Liebenscheid
        //Removed
        // [MetalPurchaseRequirementsGroups.UltrasonicInspection, `${qlid.PrecladBaseMetalUTType__c} ${qlid.PrecladBaseMetalUTNotes__c}`],
        // [MetalPurchaseRequirementsGroups.CertificateType, qlid.CertificationTypeRequired__c],
        // [MetalPurchaseRequirementsGroups.PrebondTesting, getPreBondDescription(qlid.PrebondImpacts__c, qlid)],
        // [MetalPurchaseRequirementsGroups.StressRelief, qlid.StressReliefHT__c ? qlid.NCInhouseStressReliefDetails__c : ''],

        
      ]);
    } else {
      console.log(qlid.CorrosionTesting__c, qlid.CorrosionTestingNotes__c)
      infoMap = new Map<string, string>([
        [MetalPurchaseRequirementsGroups.HeatTreatment, qlid.NCCladHeatTreatmentasSupplied__c],
        [MetalPurchaseRequirementsGroups.CorrossionTesting, `${qlid.CorrosionTestingNotes__c ? `${qlid.CorrosionTesting__c} \n ${qlid.CorrosionTestingNotes__c}` : qlid.CorrosionTesting__c} `],
        [MetalPurchaseRequirementsGroups.FerroxylTest,  `${qlid.FerroxylNotes__c ? `${qlid.FerroxylTestPerformed__c} \n ${qlid.FerroxylNotes__c}` : qlid.FerroxylTestPerformed__c}`],
        [MetalPurchaseRequirementsGroups.CladChemistryRestrictions, `${qlid.NCCladChemistryRestrictionsforBonding__c} \n ${qlid.NCCladChemistryRestForBondingNotes__c}`],
        [MetalPurchaseRequirementsGroups.MetalProductAnalysis, `${qlid.CladMetalProductAnalysisRequiredNotes__c} ${qlid.CladMetalProductAnalysisType__c}`],
        [MetalPurchaseRequirementsGroups.CladdingMetalSurfaceHardnessTest, `${qlid.HardnessCladdingMetalSurface__c} ${qlid.HardnessCladdingRejectionCriteria__c}`],
        [MetalPurchaseRequirementsGroups.ManufacturingRestrictions, `${qlid.NCCladChemistryRestrictionsforBonding__c}${qlid.NCCladChemistryRestForBondingNotes__c ? ` ${qlid.NCCladChemistryRestForBondingNotes__c}` : ''}`],
        [MetalPurchaseRequirementsGroups.FlatnessRequirements, qlid.NCCladFlatnessRequirement__c],
        [MetalPurchaseRequirementsGroups.Camber, qlid.NCCladCamberRequirement__c],
        [MetalPurchaseRequirementsGroups.SupplierRestrictions, getSupplierRestrictionsString(getMetalPurchasingRequirements(metal, MetalPurchaseRequirementsGroups.SupplierRestrictions, manufacturingSite), qlid.CustomerCladMetalSupplierRestrictions__c, qlid.CustCladMetalSupplierRestrictionsNotes__c)],
        [MetalPurchaseRequirementsGroups.SpecificationEditionAddenda, getEditionAddendaString(getMetalPurchasingRequirements(metal, MetalPurchaseRequirementsGroups.SpecificationEditionAddenda, manufacturingSite), qlid.CladMetalSpecEdition__c, qlid.CladMetalSpecAddendum__c)],
        [MetalPurchaseRequirementsGroups.Certification, `${qlid.CertificationTypeRequired__c} \n ${qlid.NCCladCertificateRequired__c}`],
        [MetalPurchaseRequirementsGroups.Marking, qlid.NCCladMarkingRequired__c],
        [MetalPurchaseRequirementsGroups.AdditionalRequirementsCladdingMetal, qlid.CladManufacturingRestrictions__c],
        [MetalPurchaseRequirementsGroups.Delivery, manufacturingSite === ManufacturingSites.US01 ? 'Mount Braddock' : 'Liebenscheid'],

       //Removed
        // [MetalPurchaseRequirementsGroups.WeldRepair, qlid.NCCladIsWeldRepairAllowed__c],
        // [MetalPurchaseRequirementsGroups.HardnessCladdingMetalSurface, getCladHardness(qlid)],
        // [MetalPurchaseRequirementsGroups.CertificateType, qlid.CertificationTypeRequired__c],
        // [MetalPurchaseRequirementsGroups.CladProductTesting, qlid.AdditionalRequirementsCladProductTesting__c],


      ]);
    }
    //Remove all blank fields
    for (const [key, value] of infoMap) {
      if (!value || value === '' || value === null || value === undefined || (typeof value === 'string' && (value.includes('undefined') || value.includes('null')))) {
        infoMap.delete(key);
      }
    }

    if (!infoMap.has(MetalPurchaseRequirementsGroups.SPWHTGroupACycle1)) {
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHT)
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupACycle1);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupACycle2);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupACycle3);

    } else if (!infoMap.has(MetalPurchaseRequirementsGroups.SPWHTGroupACycle2)) {
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupACycle2);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupACycle3);

    } else if (!infoMap.has(MetalPurchaseRequirementsGroups.SPWHTGroupACycle3)) {
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupACycle3);

    } else if (!infoMap.has(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle1)) {
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle1);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle2);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle3);

    } else if (!infoMap.has(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle2)) {
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle2);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle3);

    } else if (!infoMap.has(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle3)) {
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle3);

    } else if (!infoMap.has(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle1)) {
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle1);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle2);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle3);
    } else if (!infoMap.has(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle2)) {
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle2);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle3);
    } else if (!infoMap.has(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle3)) {
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle3);
    }


   if(infoMap.has(MetalPurchaseRequirementsGroups.SPWHTGroupACycle1)) {
      const groupACycle1 = infoMap.get(MetalPurchaseRequirementsGroups.SPWHTGroupACycle1);
      const groupACycle2 = infoMap.get(MetalPurchaseRequirementsGroups.SPWHTGroupACycle2);
      const groupACycle3 = infoMap.get(MetalPurchaseRequirementsGroups.SPWHTGroupACycle3);

      const groupBCycle1 = infoMap.get(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle1);
      const groupBCycle2 = infoMap.get(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle2);
      const groupBCycle3 = infoMap.get(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle3);

      const groupCCycle1 = infoMap.get(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle1);
      const groupCCycle2 = infoMap.get(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle2);
      const groupCCycle3 = infoMap.get(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle3);

      let finalValue = '';

      if (groupACycle1) {
        finalValue += `${qlid.SPWHTGroupAToBePerformed__c} \n`
        finalValue += groupACycle1;
      }

      if (groupACycle2) {
        finalValue += `\n${groupACycle2}`;
      }

      if (groupACycle3) {
        finalValue += `\n${groupACycle3}`;
      }

      if (groupBCycle1) {
        finalValue += `\n${qlid.SPWHTGroupBToBePerformed__c}`
        finalValue += `\n${groupBCycle1}`;
      }

      if (groupBCycle2) {
        finalValue += `\n${groupBCycle2}`;
      }

      if (groupBCycle3) {
        finalValue += `\n${groupBCycle3}`;
      }

      if (groupCCycle1) {
        finalValue += `\n${qlid.SPWHTGroupCToBePerformed__c}`
        finalValue += `\n${groupCCycle1}`;
      }

      if (groupCCycle2) {
        finalValue += `\n${groupCCycle2}`;
      }

      if (groupCCycle3) {
        finalValue += `\n${groupCCycle3}`;
      }

      finalValue += `\nMax HR/CR: ${qlid.SPWHTMaximumHeatingRateHour__c} ${qlid.SPWHTMaximumHeatingRateHourUOM__c} / ${qlid.SPWHTMaximumCoolingRateHour__c} ${qlid.SPWHTMaximumCoolingRateHourUOM__c} from/to: ${qlid.SPWHTHeatingAndCoolingRateAbove__c} ${qlid.SPWHTHeatingAndCoolingRateAboveUOM__c}`

      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupACycle1);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupACycle2);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupACycle3);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle1);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle2);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupBCycle3);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle1);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle2);
      infoMap.delete(MetalPurchaseRequirementsGroups.SPWHTGroupCCycle3);

      infoMap.set(MetalPurchaseRequirementsGroups.SPWHT, finalValue);
    }


    const richText =
    {
      _type: 'copyableInfoList',
      _key: 'bottom-info-list',
      infoMap,
      infoListStyle: { width: '100%' },
      infoRowStyle: { marginBottom: '10px' },
      labelCellStyle: { height: '14px', width: '30%' },
      valueCellStyle: { height: '14px' },
      infoLabelStyle: { fontFamily: 'Calibri', fontSize: '11pt', fontWeight: 'bold', margin: '0' },
      infoValueStyle: { fontFamily: 'Calibri', fontSize: '11pt', margin: '0', whiteSpace: 'pre-line' }
    };

    return richText;
  };

  const generateBom = () => {
    switch(activeTab) {
      case GenerateBomTabs.BaseMetal:
        if(!selectedBasesToGenerateBom.current.length) {
          toast(ToastTypes.Error, ToastMessages.GenerateBomSelections);
        } else {
          const uniqueBasesSelected = [...new Set(selectedBasesToGenerateBom.current.map(qli => qli.BaseMetal__c))];
          const updatedTopRichTextForBase = [];
          const updatedTableRichTextForBase = [];
          const updatedBottomRichTextForBase = [];

          uniqueBasesSelected.forEach(baseMetalName => {
            const qlis = selectedBasesToGenerateBom.current.filter(qli => qli.BaseMetal__c === baseMetalName).orderBy(qli => qli.Line__c);
            const baseMetal = rawMaterials.find(
              (rawMaterial: RawMaterial) =>
                rawMaterial.name === baseMetalName &&
                rawMaterial.usage === RawMaterialUsage.Base &&
                rawMaterial.dataAreaId.includes(manufacturingSite));

            updatedTopRichTextForBase.push(createTopRichText(baseMetal));
            updatedTableRichTextForBase.push(createTableRichText(RawMaterialUsage.Base, qlis, baseMetal));
            updatedBottomRichTextForBase.push(createBottomRichText(RawMaterialUsage.Base, baseMetal, qlis[0].quoteLineItemDetail));
          });

          setTopRichTextForBase(updatedTopRichTextForBase);
          setTableRichTextForBase(updatedTableRichTextForBase);
          setBottomRichTextForBase(updatedBottomRichTextForBase);
          setIsAllBasesChecked(false);
        }
        break;
      case GenerateBomTabs.CladMetal:
      default:
        if(!selectedCladsToGenerateBom.current.length) {
          toast(ToastTypes.Error, ToastMessages.GenerateBomSelections);
        } else {
          const uniqueCladsSelected = [...new Set(selectedCladsToGenerateBom.current.map(qli => qli.CladMetal__c))];
          const updatedTopRichTextForClad = [];
          const updatedTableRichTextForClad = [];
          const updatedBottomRichTextForClad = [];

          uniqueCladsSelected.forEach(cladMetalName => {
            const qlis = selectedCladsToGenerateBom.current.filter(qli => qli.CladMetal__c === cladMetalName).orderBy(qli => qli.Line__c);
            const cladMetal = rawMaterials.find(
              (rawMaterial: RawMaterial) =>
                rawMaterial.name === cladMetalName &&
                rawMaterial.usage === RawMaterialUsage.Clad &&
                rawMaterial.dataAreaId.includes(manufacturingSite));

            updatedTopRichTextForClad.push(createTopRichText(cladMetal));
            updatedTableRichTextForClad.push(createTableRichText(RawMaterialUsage.Clad, qlis));
            updatedBottomRichTextForClad.push(createBottomRichText(RawMaterialUsage.Clad, cladMetal, qlis[0].quoteLineItemDetail));
          });

          setTopRichTextForClad(updatedTopRichTextForClad);
          setTableRichTextForClad(updatedTableRichTextForClad);
          setBottomRichTextForClad(updatedBottomRichTextForClad);
          setIsAllCladsChecked(false);
        }
    }
  };

  const copyBom = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText && window.getSelection) {
      const richText = document.getElementById('rich-text');

      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob(
          [richText.innerText],
          { type: 'text/plain' }
        ),
        'text/html': new Blob(
          [richText.outerHTML],
          { type: 'text/html' }
        ),
      });

      navigator.clipboard.write([clipboardItem]);

      toast(ToastTypes.Success, ToastMessages.BOMCopiedToClipboard);
    } else {
      toast(ToastTypes.Error, ToastMessages.BOMFailedCopyToClipboard);
    }
  };

  /** components */
  const BaseQuoteLineItemRow = ({
    quoteLineItem,
    quoteLineItemIndex = 1
  }: {
    quoteLineItem: QuoteLineItem;
    quoteLineItemIndex: number;
  }) => {
    const quoteLineItemId = quoteLineItem.Id || quoteLineItem.displayId;
    const lineSelected = selectedBasesToGenerateBom.current.some(
      (baseLine) => (baseLine.Id || baseLine.displayId) === quoteLineItemId
    )
    const baseDetail = quoteLineItem.quoteLineItemDetail;

    const [isLineChecked, setIsLineChecked] = React.useState<boolean>(lineSelected);

    let isChecked = false

    React.useEffect(() => {
      isChecked = isAllBasesChecked
    }, [isAllBasesChecked]);

    React.useEffect(() => {

      try {
        if (isLineChecked) {
          if (!lineSelected) {
            const updatedSelectedBases = [...selectedBasesToGenerateBom.current];
            updatedSelectedBases.push(quoteLineItem);
            selectedBasesToGenerateBom.current = updatedSelectedBases;
          }
        } else {
          const updatedSelectedBases = [
            ...selectedBasesToGenerateBom.current.filter(
              (baseLine) => (baseLine.Id || baseLine.displayId) !== quoteLineItemId
            ),
          ];
          selectedBasesToGenerateBom.current = updatedSelectedBases;
        }
      } catch (error) {
        console.error(error);
      }
    }, [isLineChecked]);

    return (
      <>

        <IonCard className='generate-bom-quote-line-item-card' data-testid={'QAGenerateBomPopoverQuoteLineItem_' + quoteLineItemIndex}>
          <IonCardHeader class='generate-bom-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='generate-bom-quote-line-item-row'>
                <IonCol size='0.9' class='generate-bom-quote-line-items-col'>
                  <CheckboxItem
                    style={{
                      width: 'fit-content',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginRight: '-20px',
                      ['--background']: 'transparent',
                    }}
                    onIonChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                      setIsLineChecked(checked);
                    }}
                    checked={isLineChecked || isChecked}
                  />
                </IonCol>
                <IonCol size='0.8' class='column-left generate-bom-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Line} displayedValue={quoteLineItem.Line__c} />
                </IonCol>
                <IonCol size='1.4' class='column-left generate-bom-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Clads}
                    displayedValue={baseDetail?.Clads__c || '- -'}
                  />
                </IonCol>
                <IonCol size='3.5' class='column-left generate-bom-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseMetal}
                    displayedValue={quoteLineItem.BaseMetal__c}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size='1.8' class='column-left generate-bom-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseWidth}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={baseDetail?.Base_Width_in__c}
                    displayedMetricValue={baseDetail?.Base_Width_mm__c}
                    readOnly={true}
                    uom={localUom}
                  />
                </IonCol>
                <IonCol size='1.8' class='column-left generate-bom-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseLength}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={baseDetail?.Base_Length_in__c}
                    displayedMetricValue={baseDetail?.Base_Length_mm__c}
                    readOnly={true}
                    uom={localUom}
                  />
                </IonCol>
                <IonCol size='1.8' class='column-left generate-bom-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.BaseTK}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={baseDetail?.Base_TK_in__c}
                      displayedMetricValue={baseDetail?.Base_TK_mm__c}
                      readOnly={true}
                      uom={localUom}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const CladQuoteLineItemRow = ({
    quoteLineItem,
    quoteLineItemIndex = 1
  }: {
    quoteLineItem: QuoteLineItem;
    quoteLineItemIndex: number;
  }) => {
    const quoteLineItemId = quoteLineItem.Id || quoteLineItem.displayId;
    const lineSelected = selectedCladsToGenerateBom.current.some(
      (cladLine) => (cladLine.Id || cladLine.displayId) === quoteLineItemId
    );
    const cladDetail = quoteLineItem.quoteLineItemDetail;
    const [isLineChecked, setIsLineChecked] = React.useState<boolean>(lineSelected);

    let isChecked = false

    React.useEffect(() => {
      isChecked = isAllCladsChecked
    }, [isAllCladsChecked]);

    React.useEffect(() => {
      try {
        if (isLineChecked) {
          if (!lineSelected) {
            const updatedSelectedClads = [...selectedCladsToGenerateBom.current];
            updatedSelectedClads.push(quoteLineItem);
            selectedCladsToGenerateBom.current = updatedSelectedClads;
          }
        } else {
          const updatedSelectedClads = [
            ...selectedCladsToGenerateBom.current.filter(
              (baseLine) => (baseLine.Id || baseLine.displayId) !== quoteLineItemId
            ),
          ];
          selectedCladsToGenerateBom.current = updatedSelectedClads;
        }
      } catch (error) {
        console.error(error);
      }
    }, [isLineChecked]);

    return (
      <>
        <IonCard className='generate-bom-quote-line-item-card' data-testid={'QAGenerateBomQuoteLineItem_' + quoteLineItemIndex}>
          <IonCardHeader class='generate-bom-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='generate-bom-quote-line-item-row'>
                <IonCol size='0.9' class='generate-bom-quote-line-items-col'>
                  <CheckboxItem
                    style={{
                      width: 'fit-content',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginRight: '-20px',
                      ['--background']: 'transparent',
                    }}
                    onIonChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                      setIsLineChecked(checked);
                    }}
                    checked={isLineChecked || isChecked}
                  />
                </IonCol>
                <IonCol size='0.8' class='column-left generate-bom-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Line} displayedValue={quoteLineItem.Line__c} />
                </IonCol>
                <IonCol size='1.4' class='column-left generate-bom-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Clads}
                    displayedValue={cladDetail?.Clads__c || '- -'}
                  />
                </IonCol>
                <IonCol size='3.5' class='column-left generate-bom-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladMetal}
                    displayedValue={quoteLineItem.CladMetal__c}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size='1.8' class='column-left generate-bom-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladWidth}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={cladDetail?.Clad_Width_in__c}
                    displayedMetricValue={cladDetail?.Clad_Width_mm__c}
                    readOnly={true}
                    uom={localUom}
                  />
                </IonCol>
                <IonCol size='1.8' class='column-left generate-bom-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladLength}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={cladDetail?.Clad_Length_in__c}
                    displayedMetricValue={cladDetail?.Clad_Length_mm__c}
                    readOnly={true}
                    uom={localUom}
                  />
                </IonCol>
                <IonCol size='1.8' class='column-left generate-bom-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.CladTK}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={cladDetail?.Clad_TK_in__c}
                      displayedMetricValue={cladDetail?.Clad_TK_mm__c}
                      readOnly={true}
                      uom={localUom}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const DisplayedLinesTable = () => {
    const tableHeadersBaseList = [
      { display: '', colSize: '0.9', isCheckbox: true }, // blank column
      { display: 'Line', colSize: '0.8' },
      { display: 'Quantity', colSize: '1.4' },
      { display: 'Base Metal', colSize: '3.5' },
      { display: 'Base Width', colSize: '1.8' },
      { display: 'Base Length', colSize: '1.8' },
      { display: 'Base TK', colSize: '1.8' },
    ];

    const tableHeadersCladList = [
      { display: '', colSize: '0.9', isCheckbox: true }, // blank column
      { display: 'Line', colSize: '0.8' },
      { display: 'Quantity', colSize: '1.4' },
      { display: 'Clad Metal', colSize: '3.5' },
      { display: 'Clad Width', colSize: '1.8' },
      { display: 'Clad Length', colSize: '1.8' },
      { display: 'Clad TK', colSize: '1.8' },
    ];

    const qli = quoteLineItems?.filter(qli => !qli.Parent_Line__c)
    selectedBasesToGenerateBom.current = isAllBasesChecked ? qli : [];
    selectedCladsToGenerateBom.current = isAllCladsChecked ? qli : [];

    switch (activeTab) {
      case GenerateBomTabs.BaseMetal:
        return (
          <>
            <TableHeaders labels={tableHeadersBaseList} isChecked={setIsAllBasesChecked} checked={isAllBasesChecked} />
            {/* for each assembly group, show the row */}
            {quoteLineItems.filter(qli => !qli.Parent_Line__c).map((qli, idx) => {
              return <BaseQuoteLineItemRow key={idx} quoteLineItem={qli} quoteLineItemIndex={idx} />;
            })}
          </>
        );
      case GenerateBomTabs.CladMetal:
      default:

        return (
          <>
            <TableHeaders labels={tableHeadersCladList} isChecked={setIsAllCladsChecked} checked={isAllCladsChecked} />
            {/* for each assembly group, show the row */}
            {quoteLineItems.filter(qli => !qli.Parent_Line__c).map((qli, idx) => {
              return <CladQuoteLineItemRow key={idx} quoteLineItem={qli} quoteLineItemIndex={idx} />;
            })}
          </>
        );
    }
  };

  const BottomSection = () => {
    let topRichTextForMetals;
    let tableRichTextForMetals;
    let bottomRichTextForMetals;

    switch(activeTab) {
      case GenerateBomTabs.BaseMetal:
        topRichTextForMetals = topRichTextForBase;
        tableRichTextForMetals = tableRichTextForBase;
        bottomRichTextForMetals = bottomRichTextForBase;
        break;
      case GenerateBomTabs.CladMetal:
      default:
        topRichTextForMetals = topRichTextForClad;
        tableRichTextForMetals = tableRichTextForClad;
        bottomRichTextForMetals = bottomRichTextForClad;
    }

    return (
      <>
        <div className='rich-text-parent-container' id='rich-text' hidden={!topRichTextForMetals}>
        {topRichTextForMetals?.map((topRichText, index) => {
          const uniqueKey = `top-${topRichText.id}-${index}`; // Generate a unique key using identifier and index
          return (
            <React.Fragment key={uniqueKey}>
              <div className='rich-text-container top'>
                <PortableText components={richTextComponents} value={topRichText} key={`top-${index}`} />
              </div>
              <PortableText
                components={richTextComponents}
                value={(tableRichTextForMetals || [])[index]}
                key={`table-${index}`}
              />
              <div className='rich-text-container bottom'>
                <PortableText
                  components={richTextComponents}
                  value={(bottomRichTextForMetals || [])[index]}
                  key={`bottom-${index}`}
                />
              </div>
              <br />
              <span>------------</span>
              <br />
              <br />
            </React.Fragment>
          );
        })}

          <span className='static-text' style={{ fontFamily: 'Calibri', fontSize: '11pt' }}>{getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.BOMStaticText, manufacturingSite, FieldTypes.Text)}</span>
        </div>
        {topRichTextForMetals ?
        <IonButton
          color='primary'
          class='copy-button'
          data-testid={'QAGenerateBomCopyButton'}
          onClick={() => {
            copyBom();
          }}
        >
          Copy
        </IonButton> : null}
      </>
    );
  };

  return (
    <div className='popover generate-bom'>
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class='close-icon' onClick={() => dismissModal()} />
      <div className='title-container'>
        <IonLabel class='title' data-testid={'QAGenerateBomModalHeader'}>
          Generate BOM
        </IonLabel>
      </div>
      <hr className='mid-rule' />
      <div className='tabs-container'>
        {tabs.map((tab, index) => (
          <Tab label={tab} isActive={activeTab === tab} setActiveTab={setActiveTab} key={index}/>
        ))}
      </div>
      <hr className='mid-rule'/>
      <div className='tab-section-container'>
        <DisplayedLinesTable/>
        <IonButton
          color='primary'
          class='generate-button'
          data-testid={'QAGenerateBomGenerateButton'}
          onClick={() => {
            generateBom();
          }}
        >
          Generate
        </IonButton>
        <BottomSection/>
      </div>
    </div>
  );
};

export default GenerateBomPopover;