import Backdrop from '@components/backdrop/backdrop';
import ProtectedRoute from '@components/protected-route/protected-route';
import { environment } from '@environment';
import { Invoice } from '@interfaces/invoice';
import { Notification } from '@interfaces/notification';
import { Order } from '@interfaces/order';
import { IonButton, IonContent, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import AdvSpecsPage from '@pages/advanced-specifications/advanced-specifications';
import AssemblyGroupsPage from '@pages/assembly-groups/assembly-groups';
import Callback from '@pages/callback/callback';
import CertificationsPage from '@pages/certifications/certifications';
import DashboardPage from '@pages/dashboard/dashboard';
import InvoiceDetailPage from '@pages/invoice-detail/invoice-detail';
import InvoicesPage from '@pages/invoices/invoices';
import Logout from '@pages/logout/logout';
import MSTOPage from '@pages/msto/msto';
import AdvancedSpecificationPage from '@pages/new-advanced-specifications/advanced-specifications';
import NewAssemblyGroupsPage from '@pages/new-assembly-groups/assembly-groups';
import NotificationsPage from '@pages/notifications/notifications';
import OrderDetailPage from '@pages/order-detail/order-detail';
import OrderEntryPage from '@pages/order-entry/order-entry';
import OrdersPage from '@pages/orders/orders';
import QuoteDetailPage from '@pages/quote-detail/quote-detail';
import QuotesPage from '@pages/quotes/quotes';
import { MappedNotification } from '@shared/types';
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface CookieDisclaimerProps {
  isLoggedIn: boolean;
}

const CookieDisclaimer = (isLoggedIn: CookieDisclaimerProps) => {
  const [isCookieDisclaimerAccepted, setIsCookieDisclaimerAccepted] = React.useState(() => {
    const cookieExists = document.cookie.indexOf('acceptedDisclaimer=true') > -1;
    return isLoggedIn && environment.PRODUCTION ? cookieExists : true;
  });
  
  const acceptCookies = () => {
    document.cookie = 'acceptedDisclaimer=true';
    setIsCookieDisclaimerAccepted(true);
  };

  return (
    !isCookieDisclaimerAccepted ?
      <div className='cookie-notice p3'>
        <div>
        We use cookies to ensure you get the best experience of our website. By your continued use of our website you agree to NobelClads&apos; usage of cookies.
          <a target='_blank' href='https://www.nobelclad.com/corporate-information'>More Information &gt;&gt;</a>
        </div>
        <IonButton class="ion-margin-top ion-margin-bottom" onClick={() => acceptCookies()} >yes, i accept cookies</IonButton>
      </div> : null
  );
};
interface Props {
  checkRoute: (route: string) => void;
  isLoggedIn: boolean;
  orders: Order[];
  orderSearchValues: any[];
  invoices: Invoice[];
  invoiceSearchValues: any[];
  loadNotifications?: () => void;
  unmappedNotifications?: Notification[];
  finalizedNotifications?: MappedNotification[];
}

export const ContentContainer = (props: Props) => {
  const { checkRoute, isLoggedIn } = props;

  return (
    <IonContent data-testid="QAAppIonContent" class="wrapper">
      <IonReactRouter>
        <IonRouterOutlet>
          <Redirect exact from="/" to="/dashboard" />
          <Route path="/callback" component={Callback} />
          <ProtectedRoute handleRouteChange={checkRoute} isLoggedIn={isLoggedIn} path="/dashboard" component={DashboardPage} orders={props.orders} orderSearchValues={props.orderSearchValues} invoices={props.invoices} unmappedNotifications={props.unmappedNotifications} finalizedNotifications={props.finalizedNotifications} />
          <Route path="/quotes" component={QuotesPage} />
          <ProtectedRoute handleRouteChange={checkRoute} exact isLoggedIn={isLoggedIn} path='/quotes/quote-detail' component={QuoteDetailPage} finalizedNotifications={props.finalizedNotifications} loadNotifications={props.loadNotifications} />
          <ProtectedRoute handleRouteChange={checkRoute} isLoggedIn={isLoggedIn} path='/quotes/quote-detail/:id' component={QuoteDetailPage} finalizedNotifications={props.finalizedNotifications} loadNotifications={props.loadNotifications} />
          <ProtectedRoute handleRouteChange={checkRoute} isLoggedIn={isLoggedIn} path='/quotes/quote-detail/:id/order-entry' component={OrderEntryPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} isLoggedIn={isLoggedIn} path='/quotes/quote-detail/:id/advanced-specifications' component={AdvSpecsPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} isLoggedIn={isLoggedIn} path='/quotes/quote-detail/:id/advanced-specifications/:lineItemId/assembly-groups' component={AssemblyGroupsPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} isLoggedIn={isLoggedIn} path='/quotes/quote-detail/:id/new-advanced-specifications' component={AdvancedSpecificationPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} isLoggedIn={isLoggedIn} path='/quotes/quote-detail/:id/advanced-specifications/:lineItemId/new-assembly-groups' component={NewAssemblyGroupsPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} isLoggedIn={isLoggedIn} path='/quotes/quote-detail/:id/order-entry/msto' component={MSTOPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} exact isLoggedIn={isLoggedIn} path='/orders' component={OrdersPage} orders={props.orders}/>
          <ProtectedRoute handleRouteChange={checkRoute} exact isLoggedIn={isLoggedIn} path='/orders/order-detail' component={OrderDetailPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} isLoggedIn={isLoggedIn} path='/orders/order-detail/:id' component={OrderDetailPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} exact isLoggedIn={isLoggedIn} path="/invoices" component={InvoicesPage} invoices={props.invoices}/>
          <ProtectedRoute handleRouteChange={checkRoute} exact isLoggedIn={isLoggedIn} path='/invoices/invoice-detail' component={InvoiceDetailPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} isLoggedIn={isLoggedIn} path='/invoices/invoice-detail/:id' component={InvoiceDetailPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} exact isLoggedIn={isLoggedIn} path='/certifications' component={CertificationsPage}/>
          <ProtectedRoute handleRouteChange={checkRoute} exact isLoggedIn={isLoggedIn} path='/notifications' component={NotificationsPage} loadNotifications={props.loadNotifications} unmappedNotifications={props.unmappedNotifications} finalizedNotifications={props.finalizedNotifications} />
          <Route path="/logout" component={Logout} />
          <Backdrop/>
        </IonRouterOutlet>
      </IonReactRouter>
      <CookieDisclaimer isLoggedIn={isLoggedIn}/>
    </IonContent>
  );
};