import { useNotifications } from '@services/notifications/notifications-service';
import * as React from 'react';
import { Subscription } from 'rxjs';
import './unread-indicator.scss';

const UnreadIndicator = (props) => {
  const notifications = useNotifications();
  const [read, setRead] = React.useState(!!props.notification.lastViewedDateTime);

  React.useEffect(() => {
    subscriptions.push(notifications.notificationViewed$().subscribe(notificationId => {
      if(notificationId && props.notification.id === notificationId) {
        setRead(true);
      }
    }));

    return () => {
      subscriptions.forEach(s => s.unsubscribe());
    };
  }, []);

  React.useEffect(() => {
    setRead(!!props.notification.lastViewedDateTime);
  }, [props.notification]);

  const subscriptions: Subscription[] = [];

  return (!read ?
    <div className="unread-indicator" data-testid="QAUnreadIndicator"></div> : null
  );
};

export default UnreadIndicator;