import { FormKeys } from './form-keys';

export const SpecifyPerAssemblyFields = {
  "clad-product-testing.post-clad-bond-ut-spec": [
    FormKeys.PostCladBondUTSpecs,
    FormKeys.PostCladBondUTSpecificationNotes,
  ],
  "clad-product-testing.post-clad-base-bond-ut-spec": [
    FormKeys.PostCladBaseMetalUT,
    FormKeys.PostCladBaseMetalUTNotes,
  ],
  "clad-product-testing.cert-type-req": [
    FormKeys.CertificationTypeRequired,
  ],
  "clad-product-testing.cladding-corrosion-allowance": [
    FormKeys.CladdingForCorrosionAllowanceOnly,
  ],
  "clad-product-testing.is-weld-repair-allowed": [
    FormKeys.IsWeldRepairAllowed,
  ],
  "clad-product-testing.customer-specified-spwht": [
    FormKeys.SPWHTHeatingAndCoolingRateAbove,
    FormKeys.SPWHTHeatingAndCoolingRateAboveUOM,
    FormKeys.SPWHTMaximumCoolingRateHour,
    FormKeys.SPWHTMaximumCoolingRateHourUOM,
    FormKeys.SPWHTMaximumHeatingRateHour,
    FormKeys.SPWHTMaximumHeatingRateHourUOM,
    FormKeys.SPWHTGroupACycle1,
    FormKeys.SPWHTGroupACycle2,
    FormKeys.SPWHTGroupACycle3,
    FormKeys.SPWHTGroupAToBePerformed,
    FormKeys.SPWHTGroupBCycle1,
    FormKeys.SPWHTGroupBCycle2,
    FormKeys.SPWHTGroupBCycle3,
    FormKeys.SPWHTGroupBToBePerformed,
    FormKeys.SPWHTGroupCCycle1,
    FormKeys.SPWHTGroupCCycle2,
    FormKeys.SPWHTGroupCCycle3,
    FormKeys.SPWHTGroupCToBePerformed,
  ],
  "clad-product-testing.customer-specified-impact": [
    FormKeys.CustomerSpecifiedImpactTestToBePerformed,
    FormKeys.CustomerSpecifiedImpactTestLocation,
    FormKeys.CustomerSpecifiedImpactTestDirection,
    FormKeys.CustomerSpecifiedImpactTestTemperature,
    FormKeys.CustomerSpecifiedImpactTestTempUOM,
    FormKeys.CustomerSpecifiedImpactTestUOM,
    FormKeys.CustomerSpecifiedImpactTestAVG,
    FormKeys.CustomerSpecifiedImpactTestMIN,
  ],
  "clad-product-testing.shear-test": [
    FormKeys.ShearTestValue,
    FormKeys.ShearTestUOM,
  ],
  "clad-product-testing.tensile-test": [
    FormKeys.CladTensileTestLocation,
  ],
  "clad-product-testing.bond-strength-tensile-test": [
    FormKeys.BondStrengthTensileTestRamValue,
    FormKeys.BondStrengthTensileTestRamUOM,
  ],
  "clad-product-testing.hardness-cladding-metal-surface": [
    FormKeys.HardnessCladdingMetalSurface,
    FormKeys.HardnessCladdingRejectionCriteria,
  ],
  "clad-product-testing.hardness-base-metal-surface": [
    FormKeys.HardnessBaseMetalSurface,
    FormKeys.HardnessBaseRejectionCriteria,
  ],
  "clad-product-testing.pmi-confirm-cladding-metal": [
    FormKeys.PMIConfirmCladdingMetalNotes,
    FormKeys.PMIConfirmCladdingMetal,
  ],
  "clad-product-testing.pmi-confirm-base-metal": [
    FormKeys.PMIConfirmBaseMetalNotes,
    FormKeys.PMIConfirmBaseMetal,
  ],
  "clad-product-testing.bond-strength-bend": [
    FormKeys.MachineBondStrengthBendsperSA26345,
  ],
  "clad-product-testing.bend-b898": [
    FormKeys.BendB898,
  ],
  "clad-product-testing.side-bend": [
    FormKeys.MachineSideBends,
  ],
  "clad-product-testing.adw8-bend": [
    FormKeys.ADW8Bend,
  ],
  "clad-product-testing.leak-test": [
    FormKeys.LeakTestGas,
    FormKeys.LeakTestReqt,
    FormKeys.LeakTestValue,
    FormKeys.LeakTestPressure,
  ],
  "clad-product-testing.additional-requirements": [
    FormKeys.AdditionalRequirementsCladProductTesting,
  ],
  "clad-metal.heat-treatment-as-supplied": [
    FormKeys.NCCladHeatTreatmentasSupplied,
    FormKeys.NCCladHeatTreatmentasSuppliedNotes,
  ],
  "clad-metal.chemistry-restriction": [
    FormKeys.NCCladChemistryRestrictionsforBonding,
    FormKeys.NCCladChemistryRestForBondingNotes,
  ],
  "clad-metal.customer-chemistry-restriction": [
    FormKeys.CustomerCladChemistryRestrictionsNotes,
  ],
  "clad-metal.corrosion-testing": [
    FormKeys.CorrosionTesting,
    FormKeys.CorrosionTestingNotes,
  ],
  "clad-metal.metal-spec-ed-ad": [
    FormKeys.CladMetalSpecEdition,
    FormKeys.CladMetalSpecAddendum,
  ],
  "clad-metal.customer-metal": [
    FormKeys.CustomerCladMetalSupplierRestrictions,
    FormKeys.CustCladMetalSupplierRestrictionsNotes,
  ],
  "clad-metal.metal-product": [
    FormKeys.CladMetalProductAnalysisRequired,
    FormKeys.CladMetalProductAnalysisRequiredNotes,
    FormKeys.CladMetalProductAnalysisType,
  ],
  "clad-metal.ferroxyl-test": [
    FormKeys.FerroxylTestPerformed,
    FormKeys.FerroxylNotes,
  ],
  "clad-metal.additional-requirements": [
    FormKeys.AdditionalRequirementsCladdingMetal,
  ],
  "prewelding.radiography": [
    FormKeys.Radiography_Clad,
  ],
  "prewelding.pmi-weld-wire": [
    FormKeys.PMIofWeldWire,
  ],
  "prewelding.pmi-prewelds": [
    FormKeys.PMIPreweldsRequired,
  ],
  "prewelding.ferrite-number": [
    FormKeys.FerriteNumberRequired,
  ],
  "prewelding.additional-requirements": [
    FormKeys.AdditionalRequirementsPrewelding,
  ],
  "base-metal.headTreatmentAsSupplied": [
    FormKeys.NCBaseHeatTreatmentasSupplied,
    FormKeys.NCBaseHeatTreatmentasSuppliedNotes,
  ],
  "base-metal.finishedGoodHeadTreatment": [
    FormKeys.FinishedGoodHeatTreatment,
    FormKeys.FinishedGoodHeatTreatmentNotes,
  ],
  "base-metal.chemistryRegistrationForBonding": [
    FormKeys.NCBaseChemistryRestrictionsforBonding,
    FormKeys.NCBaseChemistryRestForBondingNotes,
  ],
  "base-metal.precladBaseMetalUT": [
    FormKeys.PrecladBaseMetalUT,
    FormKeys.PrecladBaseMetalUTNotes,
    FormKeys.PrecladBaseMetalUTType,
  ],
  "base-metal.prebondImpacts": [
    FormKeys.CustomerSpecifiedImpactTestToBePerformed,
    FormKeys.CustomerSpecifiedImpactTestLocation,
    FormKeys.CustomerSpecifiedImpactTestDirection,
    FormKeys.CustomerSpecifiedImpactTestTemperature,
    FormKeys.CustomerSpecifiedImpactTestTempUOM,
    FormKeys.CustomerSpecifiedImpactTestUOM,
    FormKeys.CustomerSpecifiedImpactTestAVG,
    FormKeys.CustomerSpecifiedImpactTestMIN,
  ],
  "base-metal.carbon": [
    FormKeys.Carbon_Percent,
  ],
  "base-metal.phosphorus": [
    FormKeys.Phosphorus_Percent,
  ],
  "base-metal.sulphur": [
    FormKeys.Sulphur,
  ],
  "base-metal.carbon-equivalency": [
    FormKeys.Carbon_Equivalency_Percent,
  ],
  "base-metal.j-factor": [
    FormKeys.JFactorSA387S62,
  ],
  "base-metal.x-factor": [
    FormKeys.XFactorSA387S62,
  ],
  "base-metal.customer-chemistry-restriction": [
    FormKeys.CustomerBaseChemistryRestrictions,
  ],
  "base-metal.vacuum-degassed": [
    FormKeys.VacuumDegassed,
  ],
  "base-metal.calcium-treated": [
    FormKeys.CalciumTreatedSulfideShapeControl,
  ],
  "base-metal.through-thickness-tensile": [
    FormKeys.ThroughThicknessTensilePerSA770,
  ],
  "base-metal.centerline-tensile": [
    FormKeys.BaseTensileTestLocation,
  ],
  "base-metal.hot-tensile": [
    FormKeys.HotTensileTestPerSA20S7,
    FormKeys.HotTensileTestPerSA20S7Degrees,
    FormKeys.HotTensileTestPerSA20S7UOM,
    FormKeys.HotTensileTestPerSA20S7Notes,
  ],
  "base-metal.hic-testing-required": [
    FormKeys.HicTestNotes,
  ],
  "base-metal.authenitic-grain-size": [
    FormKeys.AusteniticGrainSizeFinerThanValue,
  ],
  "base-metal.metal-spec-ed-ad": [
    FormKeys.BaseMetalSpecEdition,
    FormKeys.BaseMetalSpecAddendum,
  ],
  "base-metal.customer-metal-supplier": [
    FormKeys.CustomerBaseMetalSupplierRestrictions,
    FormKeys.CustBaseMetalSupplierRestrictionsNotes,
  ],
  "base-metal.metal-product-analysis-req": [
    FormKeys.BaseMetalProductAnalysisRequired,
    FormKeys.BaseMetalProductAnalysisRequiredNotes,
    FormKeys.BaseMetalProductAnalysisType,
  ],
  "base-metal.additional-requirements": [
    FormKeys.AdditionalRequirementsBaseMetal,
  ],
  "exceptions-clarifications.exceptions-clarification": [
    FormKeys.Exceptions_and_Clarifications,
  ]
};