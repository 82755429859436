import TableHeaders from '@components/quote-line-table/quote-line-table-header';
import SelectionContainer from '@components/selection-container/selection-container';
import { ManufacturingSites, MeasurementSystems, RawMaterialUsage, SelectionTypes } from '@constants';
import { RawMaterial } from '@interfaces/raw-material';
import { IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { addCircle } from 'ionicons/icons';
import * as React from 'react';
import { useReducer } from 'react';
import { MetalChangeHandlerParams, MetalRow } from './metal-row';
import {
  MultiShotAction,
  MultiShotItemStateDetail,
  MultiShotMetal,
  MultiShotMetalTypes,
  MultiShotMetalUpdatePayload,
  MultiShotState,
  MultiShotVariants,
  getInitMultiShotState,
  multiShotReducer,
} from './useMultiShotReducer';

// use indices for the two clad metals
enum Indices {
  ZERO = 0,
  ONE = 1,
}

interface AddLayerButtonProps {
  handleAddLayer: () => void;
  buttonLabel: string;
}
const AddLayerButton = ({ handleAddLayer, buttonLabel }: AddLayerButtonProps) => {
  return (
    <div className={'add-plate-container'} onClick={handleAddLayer}>
      <IonIcon class='add-plate-icon' src={addCircle}></IonIcon>
      <IonLabel class='add-plate-label'>{buttonLabel}</IonLabel>
    </div>
  );
};

export const positionColSize = '1.75';
export const metalColSize = '3';
export const proposalTypeColSize = '2.5';
export const thicknessColSize = '1';
export const thicknessTypeColSize = '2';
export const cstProvidedColSize = '1';

// MultiShotTableHeaders - this is the header for the table
// if you adjust these sizes, you''ll need to address sizing for the matal row
const MultiShotTableHeaders = () => {
  const tableHeaders = [
    { display: 'Position', colSize: positionColSize },
    { display: 'Metal', colSize: metalColSize },
    { display: 'Proposal Type', colSize: proposalTypeColSize },
    { display: 'Thickness', colSize: thicknessColSize },
    { display: 'Thickness Type', colSize: thicknessTypeColSize },
    { display: 'Cst Provided', colSize: cstProvidedColSize },
  ];
  return <TableHeaders labels={tableHeaders} />;
};

interface MultiShotDropdownProps {
  multiShotType: MultiShotVariants;
  setMultiShotType: (type: MultiShotVariants) => void;
  multiShotState: MultiShotState;
}

// 🔑 #0 - buildMapFromState - this builds a shot postition map from the state: MultiShotState
// this returns a map of the metals in the order they are shot 🧨
export const buildMapFromState = (state: MultiShotState): Map<number, MultiShotItemStateDetail> => {
  const map = new Map<number, MultiShotItemStateDetail>();

  // the base is always the base metal
  map.set(0, state.baseMetal as unknown as MultiShotItemStateDetail);
  // it follows the interlayers next, followed by clads
  state.interlayerMetals.forEach((metal, index) => {
    map.set(index + 1, metal as unknown as MultiShotItemStateDetail);
  });

  const last = map.size - 1;

  state.cladMetals.forEach((metal, index) => {
    map.set(last + index + 1, metal as unknown as MultiShotItemStateDetail);
  });

  return map;
};

// MultiShotSelectDropdown - this is the dropdown for selecting the multi-shot type
export const MultiShotSelectDropdown = ({ multiShotType, setMultiShotType }: MultiShotDropdownProps) => {
  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <div className='flex-row'>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              selectionLabel='Muilti-Shot Type'
              placeholder='Select Multi-Shot Type'
              options={Object.values(MultiShotVariants)}
              style={{ width: '193px', marginRight: '20px' }}
              onChange={(type) => {
                if (type.label !== multiShotType) {
                  setMultiShotType(type.label);
                }
              }}
              value={multiShotType}
            />
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

interface CladsTableProps {
  multiShotMetalsState: MultiShotState;
  handleCladMetalChange?: ({ key, value, index }: MetalChangeHandlerParams) => void;
  handleBaseMetalChange?: ({ key, value }: MetalChangeHandlerParams) => void;
  handleInterlayerMetalChange?: ({ key, value, index }: MetalChangeHandlerParams) => void;
  handleRemovePlate: (index: number) => void;
  uom: MeasurementSystems;
  manufacturingSite: ManufacturingSites;
  handleMetalChange: (metal: RawMaterial, usage: RawMaterialUsage) => void;
  setBaseMetalProposalType: (proposalType: string) => void;
  setCladMetalProposalType: (proposalType: string) => void;
  setFinishedBaseMetalThickness: (thickness: number) => void;
  setFinishedCladMetalThickness: (thickness: number) => void;
  setBaseMetalCustomerProvided: (customerProvided: boolean) => void;
  setCladMetalCustomerProvided: (customerProvided: boolean) => void;
  setBaseMetalType: (metalType: string) => void;
  setCladMetalType: (metalType: string) => void;
  multiShotType: MultiShotVariants;
}

// CladOneSideTable - this is the table for the multi-shot metals with clad on only one side
const CladOneSideTable = ({
  handleBaseMetalChange,
  handleCladMetalChange,
  handleInterlayerMetalChange,
  handleRemovePlate,
  multiShotMetalsState,
  uom,
  manufacturingSite,
  handleMetalChange,
  setBaseMetalProposalType,
  setCladMetalProposalType,
  setFinishedBaseMetalThickness,
  setFinishedCladMetalThickness,
  setBaseMetalCustomerProvided,
  setCladMetalCustomerProvided,
  setBaseMetalType,
  setCladMetalType,
  multiShotType,
}: CladsTableProps) => {
  return (
    <>
      {/* CLAD - Zero Index */}
      <MetalRow
        index={Indices.ZERO}
        multiShotMetalsState={multiShotMetalsState}
        handleMetalChange={handleCladMetalChange}
        metal={multiShotMetalsState.cladMetals[Indices.ZERO]}
        uom={uom}
        manufacturingSite={manufacturingSite}
        handleMetalChangeProp={handleMetalChange}
        setBaseMetalProposalType={setBaseMetalProposalType}
        setCladMetalProposalType={setCladMetalProposalType}
        setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
        setFinishedCladMetalThickness={setFinishedCladMetalThickness}
        setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
        setCladMetalCustomerProvided={setCladMetalCustomerProvided}
        setBaseMetalType={setBaseMetalType}
        setCladMetalType={setCladMetalType}
        multiShotType={multiShotType}
      />

      {/* INTERLAYERS Mapping */}
      {multiShotMetalsState.interlayerMetals
        .slice()
        // reversing the order of the metals so that the first metal in the array is on the bottom of the stack
        // due to the business desiring new layers to be on top of existing in the ui
        .reverse()
        .map((metal, index) => {
          return (
            <div key={metal.id}>
              <MetalRow
                index={index}
                // reverse indices needed due to the reveresed indices from mapping of the interlayers
                useReverseIndices={true}
                metal={metal}
                isInterlayerMetal={true}
                multiShotMetalsState={multiShotMetalsState}
                handleMetalChange={handleInterlayerMetalChange}
                handleRemovePlate={handleRemovePlate}
                uom={uom}
                handleMetalChangeProp={handleMetalChange}
                setBaseMetalProposalType={setBaseMetalProposalType}
                setCladMetalProposalType={setCladMetalProposalType}
                setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
                setFinishedCladMetalThickness={setFinishedCladMetalThickness}
                setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
                setCladMetalCustomerProvided={setCladMetalCustomerProvided}
                setBaseMetalType={setBaseMetalType}
                setCladMetalType={setCladMetalType}
                manufacturingSite={manufacturingSite}
                multiShotType={multiShotType}
              />
            </div>
          );
        })}

      {/* BASE */}
      <MetalRow
        isBaseMetal={true}
        metal={multiShotMetalsState.baseMetal}
        handleMetalChange={handleBaseMetalChange}
        multiShotMetalsState={multiShotMetalsState}
        uom={uom}
        manufacturingSite={manufacturingSite}
        handleMetalChangeProp={handleMetalChange}
        setBaseMetalProposalType={setBaseMetalProposalType}
        setCladMetalProposalType={setCladMetalProposalType}
        setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
        setFinishedCladMetalThickness={setFinishedCladMetalThickness}
        setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
        setCladMetalCustomerProvided={setCladMetalCustomerProvided}
        setBaseMetalType={setBaseMetalType}
        setCladMetalType={setCladMetalType}
        multiShotType={multiShotType}
      />
    </>
  );
};

// CladsTwoSidesTable - this is the table for the multi-shot metals with clads on both sides
const CladsTwoSidesTable = ({
  handleBaseMetalChange,
  handleCladMetalChange,
  handleInterlayerMetalChange,
  handleRemovePlate,
  multiShotMetalsState,
  uom,
  manufacturingSite,
  handleMetalChange,
  setBaseMetalProposalType,
  setCladMetalProposalType,
  setFinishedBaseMetalThickness,
  setFinishedCladMetalThickness,
  setBaseMetalCustomerProvided,
  setCladMetalCustomerProvided,
  setBaseMetalType,
  setCladMetalType,
  multiShotType,
}: CladsTableProps) => {
  return (
    <>
      {/* CLAD - Index ONE */}
      <MetalRow
        index={Indices.ONE}
        metal={multiShotMetalsState.cladMetals[Indices.ONE]}
        multiShotMetalsState={multiShotMetalsState}
        handleMetalChange={handleCladMetalChange}
        uom={uom}
        handleMetalChangeProp={handleMetalChange}
        setBaseMetalProposalType={setBaseMetalProposalType}
        setCladMetalProposalType={setCladMetalProposalType}
        setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
        setFinishedCladMetalThickness={setFinishedCladMetalThickness}
        setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
        setCladMetalCustomerProvided={setCladMetalCustomerProvided}
        setBaseMetalType={setBaseMetalType}
        setCladMetalType={setCladMetalType}
        manufacturingSite={manufacturingSite}
        multiShotType={multiShotType}
      />

      {/* INTERLAYER 2 - Index ONE */}
      {/* If there is a multiShotMetalsState.interlayerMetals index 1 then show it here  */}
      {multiShotMetalsState.interlayerMetals.length > 1 && (
        // note: not using reverse indices
        <MetalRow
          index={Indices.ONE}
          metal={multiShotMetalsState.interlayerMetals[Indices.ONE]}
          isInterlayerMetal={true}
          multiShotMetalsState={multiShotMetalsState}
          handleMetalChange={handleInterlayerMetalChange}
          handleRemovePlate={handleRemovePlate}
          uom={uom}
          manufacturingSite={manufacturingSite}
          handleMetalChangeProp={handleMetalChange}
          setBaseMetalProposalType={setBaseMetalProposalType}
          setCladMetalProposalType={setCladMetalProposalType}
          setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
          setFinishedCladMetalThickness={setFinishedCladMetalThickness}
          setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
          setCladMetalCustomerProvided={setCladMetalCustomerProvided}
          setBaseMetalType={setBaseMetalType}
          setCladMetalType={setCladMetalType}
          multiShotType={multiShotType}
        />
      )}

      {/* BASE */}
      <MetalRow
        isBaseMetal={true}
        metal={multiShotMetalsState.baseMetal}
        handleMetalChange={handleBaseMetalChange}
        multiShotMetalsState={multiShotMetalsState}
        uom={uom}
        manufacturingSite={manufacturingSite}
        handleMetalChangeProp={handleMetalChange}
        setBaseMetalProposalType={setBaseMetalProposalType}
        setCladMetalProposalType={setCladMetalProposalType}
        setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
        setFinishedCladMetalThickness={setFinishedCladMetalThickness}
        setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
        setCladMetalCustomerProvided={setCladMetalCustomerProvided}
        setBaseMetalType={setBaseMetalType}
        setCladMetalType={setCladMetalType}
        multiShotType={multiShotType}
      />

      {/* INTERLAYER 1 - Index ZERO */}
      {/* If there is a multiShotMetalsState.interlayerMetals index 0 then show it here  */}
      {multiShotMetalsState.interlayerMetals.length > 0 && (
        // note: not using reverse indices
        <MetalRow
          index={Indices.ZERO}
          metal={multiShotMetalsState.interlayerMetals[Indices.ZERO]}
          isInterlayerMetal={true}
          multiShotMetalsState={multiShotMetalsState}
          handleMetalChange={handleInterlayerMetalChange}
          handleRemovePlate={handleRemovePlate}
          uom={uom}
          manufacturingSite={manufacturingSite}
          handleMetalChangeProp={handleMetalChange}
          setBaseMetalProposalType={setBaseMetalProposalType}
          setCladMetalProposalType={setCladMetalProposalType}
          setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
          setFinishedCladMetalThickness={setFinishedCladMetalThickness}
          setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
          setCladMetalCustomerProvided={setCladMetalCustomerProvided}
          setBaseMetalType={setBaseMetalType}
          setCladMetalType={setCladMetalType}
          multiShotType={multiShotType}
        />
      )}

      {/* CLAD 2 - Index ZERO */}
      <MetalRow
        index={Indices.ZERO}
        multiShotMetalsState={multiShotMetalsState}
        handleMetalChange={handleCladMetalChange}
        metal={multiShotMetalsState.cladMetals[Indices.ZERO]}
        uom={uom}
        manufacturingSite={manufacturingSite}
        handleMetalChangeProp={handleMetalChange}
        setBaseMetalProposalType={setBaseMetalProposalType}
        setCladMetalProposalType={setCladMetalProposalType}
        setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
        setFinishedCladMetalThickness={setFinishedCladMetalThickness}
        setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
        setCladMetalCustomerProvided={setCladMetalCustomerProvided}
        setBaseMetalType={setBaseMetalType}
        setCladMetalType={setCladMetalType}
        multiShotType={multiShotType}
      />
    </>
  );
};

interface MultiShotMetalsTableProps {
  multiShotMetalsStateRef: React.MutableRefObject<MultiShotState>;
  multiShotMetalsState: MultiShotState;
  dispatch: React.Dispatch<MultiShotAction>;
  uom: MeasurementSystems;
  manufacturingSite: ManufacturingSites;
  handleMetalChange: (metal: RawMaterial, usage: RawMaterialUsage) => void;
  setBaseMetalProposalType: (proposalType: string) => void;
  setCladMetalProposalType: (proposalType: string) => void;
  setBaseMetalCustomerProvided: (customerProvided: boolean) => void;
  setCladMetalCustomerProvided: (customerProvided: boolean) => void;
  setFinishedBaseMetalThickness: (thickness: number) => void;
  setFinishedCladMetalThickness: (thickness: number) => void;
  setBaseMetalType: (metalType: string) => void;
  setCladMetalType: (metalType: string) => void;
  isTwoSided: boolean;
  multiShotType: MultiShotVariants;
}

// MultiShotMetalsTable - displays the clad one side and/or clad both sides table
const MultiShotMetalsTable = ({
  multiShotMetalsStateRef,
  multiShotMetalsState,
  dispatch,
  uom,
  manufacturingSite,
  handleMetalChange: handleMetalChangeProp,
  setBaseMetalProposalType,
  setCladMetalProposalType,
  setFinishedBaseMetalThickness,
  setFinishedCladMetalThickness,
  setBaseMetalCustomerProvided,
  setCladMetalCustomerProvided,
  setBaseMetalType,
  setCladMetalType,
  isTwoSided,
  multiShotType,
}: MultiShotMetalsTableProps) => {
  // handleRemovePlate - removes the layer at the index position
  const handleRemovePlate = (index: number) => {
    dispatch({ type: 'REMOVE_INTERLAYER', payload: index, ref: multiShotMetalsStateRef });
  };

  // handleMetalChange passes the metal type and then the key and value (and in the case of interlayer, the index) to the handler
  const handleMetalChange =
    (metalType: MultiShotMetalTypes) => (key: keyof MultiShotMetal, value: any, index?: number) => {
      const payload = {
        metalType,
        key,
        value,
      } as MultiShotMetalUpdatePayload;

      if (typeof index === 'number') {
        payload.index = index;
      }

      dispatch({ type: 'UPDATE_METAL', payload, ref: multiShotMetalsStateRef });
    };

  // base in a multishot is always just the base metal, interlayers and clads are both clad metals and can switch between shots
  const handleBaseMetalChange = ({ key, value }: MetalChangeHandlerParams) => {
    return handleMetalChange(MultiShotMetalTypes.BASE)(key, value);
  };

  const handleCladMetalChange = ({ key, value, index }: MetalChangeHandlerParams) => {
    return handleMetalChange(MultiShotMetalTypes.CLAD)(key, value, index);
  };

  const handleInterlayerMetalChange = ({ key, value, index }: MetalChangeHandlerParams) => {
    return handleMetalChange(MultiShotMetalTypes.INTERLAYER)(key, value, index);
  };

  if (isTwoSided) {
    return (
      <CladsTwoSidesTable
        multiShotMetalsState={multiShotMetalsState}
        handleRemovePlate={handleRemovePlate}
        handleBaseMetalChange={handleBaseMetalChange}
        handleCladMetalChange={handleCladMetalChange}
        handleInterlayerMetalChange={handleInterlayerMetalChange}
        uom={uom}
        handleMetalChange={handleMetalChangeProp}
        setBaseMetalProposalType={setBaseMetalProposalType}
        setCladMetalProposalType={setCladMetalProposalType}
        setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
        setFinishedCladMetalThickness={setFinishedCladMetalThickness}
        setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
        setCladMetalCustomerProvided={setCladMetalCustomerProvided}
        setBaseMetalType={setBaseMetalType}
        setCladMetalType={setCladMetalType}
        manufacturingSite={manufacturingSite}
        multiShotType={multiShotType}
      />
    );
  } else {
    return (
      <CladOneSideTable
        multiShotMetalsState={multiShotMetalsState}
        handleRemovePlate={handleRemovePlate}
        handleBaseMetalChange={handleBaseMetalChange}
        handleCladMetalChange={handleCladMetalChange}
        handleInterlayerMetalChange={handleInterlayerMetalChange}
        uom={uom}
        handleMetalChange={handleMetalChangeProp}
        setBaseMetalProposalType={setBaseMetalProposalType}
        setCladMetalProposalType={setCladMetalProposalType}
        setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
        setFinishedCladMetalThickness={setFinishedCladMetalThickness}
        setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
        setCladMetalCustomerProvided={setCladMetalCustomerProvided}
        setBaseMetalType={setBaseMetalType}
        setCladMetalType={setCladMetalType}
        manufacturingSite={manufacturingSite}
        multiShotType={multiShotType}
      />
    );
  }
};

/* Default Component */
interface MultiShotTableProps {
  multiShotType: MultiShotVariants;
  uom: MeasurementSystems;
  manufacturingSite: ManufacturingSites;
  multiShotMetalsStateRef: React.MutableRefObject<MultiShotState>;
  handleMetalChange: (metal: RawMaterial, usage: RawMaterialUsage) => void;
  setBaseMetalProposalType: (proposalType: string) => void;
  setCladMetalProposalType: (proposalType: string) => void;
  setFinishedBaseMetalThickness: (thickness: number) => void;
  setFinishedCladMetalThickness: (thickness: number) => void;
  setBaseMetalCustomerProvided: (customerProvided: boolean) => void;
  setCladMetalCustomerProvided: (customerProvided: boolean) => void;
  setBaseMetalType: (metalType: string) => void;
  setCladMetalType: (metalType: string) => void;
}

enum MaxInterlayers {
  CLAD_ONE_SIDE_MAX = 3,
  CLAD_BOTH_SIDES_MAX = 2,
}

// MultiShotTable - Default Component - displays the table based on the multiShotType prop
// it has a table header, the metals table, and an add interlayer button if needed
const MultiShotTable = ({
  multiShotType,
  uom,
  manufacturingSite,
  multiShotMetalsStateRef,
  handleMetalChange,
  setBaseMetalProposalType,
  setCladMetalProposalType,
  setFinishedBaseMetalThickness,
  setFinishedCladMetalThickness,
  setBaseMetalCustomerProvided,
  setCladMetalCustomerProvided,
  setBaseMetalType,
  setCladMetalType,
}: MultiShotTableProps) => {
  // Determine the max number of interlayers based on the multiShotType
  const maxInterlayers =
    multiShotType === MultiShotVariants.CLAD_ONE_SIDE_MULTI_SHOT
      ? MaxInterlayers.CLAD_ONE_SIDE_MAX
      : MaxInterlayers.CLAD_BOTH_SIDES_MAX;

  // Determine if interlayers can be added based on the current number of interlayers and the max number of interlayers
  const canAddInterlayers = multiShotMetalsStateRef.current.interlayerMetals.length < maxInterlayers;

  // Determine if the add button should be shown based on the multiShotType and the current number of interlayers
  const shouldShowAddButton = multiShotType !== MultiShotVariants.CLAD_ONE_SIDE_SINGLE_SHOT && canAddInterlayers;

  const [multiShotMetalsState, dispatch] = useReducer(multiShotReducer, multiShotMetalsStateRef.current);

  React.useEffect(() => {
    switch (multiShotType) {
      case MultiShotVariants.CLAD_BOTH_SIDES:
        dispatch({ type: 'INIT_CLAD_BOTH_SIDES', ref: multiShotMetalsStateRef });
        break;
      case MultiShotVariants.CLAD_ONE_SIDE_MULTI_SHOT:
        dispatch({ type: 'INIT_CLAD_ONE_SIDE', ref: multiShotMetalsStateRef });
        break;
      case MultiShotVariants.CLAD_ONE_SIDE_SINGLE_SHOT:
        dispatch({ type: 'INIT_CLAD_ONE_SIDE_SINGLE', ref: multiShotMetalsStateRef });
        break;
      default:
        break;
    }
  }, [multiShotMetalsStateRef, multiShotType]);

  // creates a new interlayer
  const handleAddLayer = () => {
    dispatch({ type: 'ADD_INTERLAYER', ref: multiShotMetalsStateRef });
  };

  // if multiShotType isn't selected yet, return null
  if (!multiShotType) {
    // clear the ref by resetting the state
    multiShotMetalsStateRef.current = getInitMultiShotState();
    return null;
  }

  return (
    <>
      <MultiShotTableHeaders />
      <MultiShotMetalsTable
        multiShotType={multiShotType}
        multiShotMetalsStateRef={multiShotMetalsStateRef}
        multiShotMetalsState={multiShotMetalsState}
        handleMetalChange={handleMetalChange}
        setBaseMetalProposalType={setBaseMetalProposalType}
        setCladMetalProposalType={setCladMetalProposalType}
        setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
        setFinishedCladMetalThickness={setFinishedCladMetalThickness}
        setBaseMetalType={setBaseMetalType}
        setCladMetalType={setCladMetalType}
        dispatch={dispatch}
        uom={uom}
        manufacturingSite={manufacturingSite}
        isTwoSided={multiShotType === MultiShotVariants.CLAD_BOTH_SIDES}
        setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
        setCladMetalCustomerProvided={setCladMetalCustomerProvided}
      />
      {shouldShowAddButton && <AddLayerButton buttonLabel='Add Layer' handleAddLayer={handleAddLayer} />}
    </>
  );
};

export default MultiShotTable;
