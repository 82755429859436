import React, { useState, useEffect } from 'react';
import Modal from '@components/modal/modal';
import { IonCol, IonGrid, IonIcon,IonRow } from '@ionic/react';
import './invoice-line-item-details-popover.scss';
import { chevronDown, chevronUp, attachOutline } from 'ionicons/icons';
import { LineItemDetailsColumnHeadings } from '@constants';
import Documents from '@components/documents/documents';
import { uniqueDocuments } from '@shared/document-utils';
import moment from 'moment';
import { formatCurrency } from '@shared/utils';
import { MappedDocumentData, MappedNCLineItem } from '@shared/types';

interface Document {
  NC_Line_Item__c: string,
  Name: string,
}

interface Payload {
  invoiceLineItem?: MappedNCLineItem,
}

interface Props {
  payload: Payload,
  isOpen: boolean,
  onDismiss: Function,
  loadDocuments: Function,
  mappedInvoiceLineItems: Array<MappedNCLineItem>,
  mappedDocuments: Array<MappedDocumentData>,
  showDocumentActions: boolean,
}

interface InvoiceLineItemPopoverListProps {
  lineItemDetails: Array<MappedNCLineItem>,
  documents: Array<Document>
}

const InvoiceLineItemPopoverList = ({ lineItemDetails, documents }: InvoiceLineItemPopoverListProps) => {
  const [sortBy, setSortBy] = useState(null);
  // down = false
  const [sortAsc, setSortAsc] = useState(false);

  const [lineItems, setLineItems] = useState(lineItemDetails);

  const columns = {
    [LineItemDetailsColumnHeadings.Item]: 'item',
    [LineItemDetailsColumnHeadings.Status]: 'Status__c',
    [LineItemDetailsColumnHeadings.Description]: 'Description__c',
    [LineItemDetailsColumnHeadings.UnitPrice]: 'Unit_Price__c',
  };

  useEffect(() => {
    if (!lineItems || !lineItems.length) {
      return;
    }

    switch(sortBy) {
      case LineItemDetailsColumnHeadings.Status:
        setLineItems(lineItems?.orderBy(item => +item.statusSortingOrder, sortAsc));
        break;
      case LineItemDetailsColumnHeadings.ShipDate:
        setLineItems(lineItemDetails?.orderByDate(item => (item.shipDate ? moment(item.shipDate).format('MM/DD/YYYY') : item.shipDate), sortAsc));
        break;
      default:
        setLineItems(lineItemDetails?.orderBy(item => item[columns[sortBy]], sortAsc));
        break;
      }
  }, [sortBy, sortAsc]);

  const onClickHeaderColumn = (text) => {
    if (sortBy === text) {
      setSortAsc(!sortAsc);
    } else {
      setSortBy(text);
      setSortAsc(false);
    }
  };

  const InvoiceLineItemHeader = () => {
    const HeaderColumn = ({ text, sortable = false, size, testId = ''}) => {
      const getSortIcon = () => {
        if (sortBy !== text) {
          return chevronDown;
        }

        return sortAsc ? chevronUp : chevronDown;
      };

      const icon = getSortIcon();

      return <IonCol
        size={size}
        class={`header-detail-col d-flex ${sortable && 'cursor-pointer'} ${sortBy === text && 'active'}`}
        onClick={() => sortable && onClickHeaderColumn(text)}
        data-testid={testId}
             >
        <span>{ text } </span>
        {sortable && <IonIcon icon={icon}></IonIcon>}
      </IonCol>;
    };

    return <IonGrid class="header-detail-container">
      <IonRow class="header-detail-row">
        <HeaderColumn size="1" text={LineItemDetailsColumnHeadings.Item} sortable={true} testId={'QAOrderLineItemDetailsPopoverLineItemItemColumnHeader'}></HeaderColumn>
        <HeaderColumn size="2" text={LineItemDetailsColumnHeadings.Status} sortable={true} testId={'QAOrderLineItemDetailsPopoverLineItemStatusColumnHeader'}></HeaderColumn>
        <HeaderColumn size="5" text={LineItemDetailsColumnHeadings.Description} testId={'QAOrderLineItemDetailsPopoverLineItemDescriptionColumnHeader'}></HeaderColumn>
        <HeaderColumn size="2" text={LineItemDetailsColumnHeadings.ShipDate} sortable={true} testId={'QAOrderLineItemDetailsPopoverLineItemShipDateColumnHeader'}></HeaderColumn>
        <HeaderColumn size="2" text={LineItemDetailsColumnHeadings.UnitPrice} testId={'QAOrderLineItemDetailsPopoverLineItemUnitPriceColumnHeader'}></HeaderColumn>
      </IonRow>
    </IonGrid>;
  };

  const InvoiceLineItemBody = ({lineItems}) => {

    const LineItemAttachmentIndicator = ({className = ''}) => {
        return <IonIcon data-testid="QAOrderLineItemDetailsPopoverAttachmentIndicator" class={`paperclip-icon ${className}`} icon={attachOutline}></IonIcon>;
    };

    const LineItemColumn = ({ header, text, size, status = '', className = '', attachment = false, testId = ''}) => {
      return <IonCol sizeLg={size} sizeXl={size} sizeMd={size} sizeSm={size} sizeXs='12' class='invoice-detail-col'>
        <IonGrid class="ion-no-padding">
          <IonRow class="ion-no-padding">
            <IonCol class="mobile-only ion-no-padding invoice-detail-col-text-header" sizeXs="3"> {header} </IonCol>
            <IonCol sizeSm="12" sizeXs='9' class={`ion-no-padding invoice-detail-col-text-container ${attachment && 'd-flex'}`}>
              {
                status && <img className="status-icon" src={status}/>
              }
              <span className={className} data-testid={testId}>{ text } </span>
              {
                attachment && <LineItemAttachmentIndicator/>
              }
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCol>;
    };

    const LineItemRow = ({lineItemDetail}) => {
      const attachment = documents.some(document => document.Name.contains(lineItemDetail.item));
      const items = [
        {
          header: LineItemDetailsColumnHeadings.Item,
          text: lineItemDetail.item,
          testId: `QAOrderLineItemDetailsPopoverLineItemItem_${lineItemDetail.item}`,
          size: '1'
        },
        {
          header: LineItemDetailsColumnHeadings.Status,
          text: lineItemDetail.Status__c,
          status: lineItemDetail.statusImgSrc,
          testId: `QAOrderLineItemDetailsPopoverLineItemStatus_${lineItemDetail.Status__c}`,
          size: '2'
        },
        {
          header: LineItemDetailsColumnHeadings.Description,
          text: lineItemDetail.Description__c,
          testId: 'QAOrderLineItemDetailsPopoverLineItemDescription',
          size: '5'
        },
        {
          header: LineItemDetailsColumnHeadings.ShipDate,
          text: lineItemDetail.shipDate ? moment(lineItemDetail.shipDate).format('L') : lineItemDetail.shipDate,
          testId: 'QAOrderLineItemDetailsPopoverLineItemShipDate',
          size: '2'
        },
        {
          header: LineItemDetailsColumnHeadings.UnitPrice,
          text: formatCurrency(lineItemDetail.Unit_Price__c || 0, lineItemDetail.CurrencyIsoCode),
          size: '2',
          testId: 'QAOrderLineItemDetailsPopoverLineItemUnitPrice',
          className: 'unit-price',
          attachment
        },
      ];
      return <IonRow class="invoice-detail-row">
        { items.map((item) => <LineItemColumn {...item} key={`${item.header}-${lineItemDetail.item}`}></LineItemColumn>) }
        { attachment && <LineItemAttachmentIndicator className="mobile-only" /> }
      </IonRow>;
    };

    return <IonGrid class="invoice-detail-container">
      { lineItems.map((lineItemDetail) => <LineItemRow lineItemDetail={lineItemDetail} key={`invoice-detail-${lineItemDetail.item}`}/>)}
    </IonGrid>;
  };

  return <>
    <InvoiceLineItemHeader/>
    <InvoiceLineItemBody lineItems={lineItems}/>
  </>;
};

const InvoiceLineItemPopoverDocuments = ({ documents, onDocumentUpdate, showDocumentActions }) => {
  return <>
      <Documents
        displayedDocuments={documents}
        setDocumentUpdated={onDocumentUpdate}
        showDocumentActions={showDocumentActions}
      />
  </>;
};

const InvoiceLineItemDetailsPopover = ({payload, isOpen, onDismiss, loadDocuments, mappedInvoiceLineItems, mappedDocuments, showDocumentActions}: Props) => {
  const { invoiceLineItem } = payload;

  const lineItemDetails = mappedInvoiceLineItems?.filter(mappedInvoiceLineItem => mappedInvoiceLineItem.Line_Number__c === invoiceLineItem?.Line_Number__c);

  const lineItemDetailContainedInLine = (documentLineItem) => {
    return lineItemDetails?.some(lineItemDetail => lineItemDetail.Id === documentLineItem);
  };

  const lineItemDocuments = (documents) => {
    return (documents || []).filter(document => lineItemDetailContainedInLine(document.NC_Line_Item__c));
  };

  const onClickClose = () => {
    onDismiss();
  };

  const onDocumentUpdate = (updates) => {
    if (!updates) {
      return ;
    }

    // call load documents;
    if (loadDocuments) {
      loadDocuments();
    }
  };

  const documents = uniqueDocuments(lineItemDocuments(mappedDocuments));
  const title = `${invoiceLineItem?.Portal_Quantity__c} Items`;

  return <>
      <Modal
        options={{
          isOpen: isOpen
        }}
        title={title}
        onClickClose={onClickClose}
        modalClass='invoice-line-item-document-popover'
      >
        <InvoiceLineItemPopoverList
          lineItemDetails={lineItemDetails}
          documents={documents}
        />
        <InvoiceLineItemPopoverDocuments
          documents={documents}
          onDocumentUpdate={onDocumentUpdate}
          showDocumentActions={showDocumentActions}
        />
      </Modal>
  </>;
};

export default InvoiceLineItemDetailsPopover;