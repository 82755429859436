import Loading from '@components/loading/loading';
import { OrdersPageColumnHeadings, SearchFields } from '@constants';
import { Order } from '@interfaces/order';
import { IonButton, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { useSalesforce } from '@services/salesforce/salesforce-service';
import { formatCurrency } from '@shared/utils';
import { mapOrder, findOrderById } from '@shared/order-utils';
import classNames from 'classnames';
import { chevronDown, chevronUp } from 'ionicons/icons';
import * as React from 'react';
import './orders.scss';
import Footer from '@components/footer/footer';
import { usePlatform } from '@services/platform-service';
import { useHistory } from 'react-router';
import EmptyTableRow from '@components/empty-table-row/empty-table-row';
import SearchInput from '@components/search-input/search-input';
import Badge from '@components/badge/badge';
import { useLastViewed } from '@services/last-viewed/last-viewed-service';
import backArrow from '@assets/icon/arrow_back.svg';
import forwardArrow from '@assets/icon/arrow_forward.svg';
import moment from 'moment';
import { useDarkMode } from '@hooks/useDarkMode';
import { useEnvironment } from '@services/environment-service';
import LineItemAttachment from '@components/line-item-attachment/line-item-attachment';
import { MappedOrder } from '@shared/types';

const SearchContainer = (props) => {
  const environment = useEnvironment();

  const [orderSearchInput, setOrderSearchInput] = React.useState(props.searchInput);

  // Returns internal view flag from environment
  const internalView = () => {
    return environment?.internalView();
  };

  // Action that occurs when clicking on an order search suggestion
  const suggestionClickHandler = async (suggestionClicked: any) => {
    if(suggestionClicked.matchingDisplayField === SearchFields.AXOrderNumber || suggestionClicked.matchingDisplayField === SearchFields.PoNumber) {
      props.navigateToOrderDetail(await findOrderById(props.orders, suggestionClicked.Id));
    } else {
      const updatedSearchInput = suggestionClicked[suggestionClicked.matchingDisplayField];
      setOrderSearchInput(updatedSearchInput);
      props.searchButtonClickHandler(updatedSearchInput);
    }
  };

  return (
    <SearchInput
      noneFoundText='No orders found'
      placeholder={internalView() ? 'Search...' : 'Search by order number'}
      suggestions={props.orders}
      suggestionDisplayFields={[SearchFields.AXOrderNumber, SearchFields.PoNumber]}
      commonSuggestionDisplayFields={[SearchFields.AccountName]}
      searchInputChangedHandler={(searchInput) => setOrderSearchInput(searchInput)}
      suggestionClickHandler={(suggestionClicked) => suggestionClickHandler(suggestionClicked)}
      searchButtonClickHandler={() => props.searchButtonClickHandler(orderSearchInput)}
      input={orderSearchInput}
    />
  );
};

interface Props {
  orders: Order[];
  location?: { state: { orderSearchInput: any; }; };
}

const OrdersPage = (props: Props) => {
  const salesforce = useSalesforce();
  const platform = usePlatform();
  const lastViewed = useLastViewed();
  const environment = useEnvironment();
  const { darkMode } = useDarkMode();
  const history = useHistory();

  const [orders, setOrders] = React.useState<Order[]>(props.orders);
  const [displayedOrders, setDisplayedOrders] = React.useState<MappedOrder[]>(undefined);
  const [sortColumnId, setSortColumnId] = React.useState(undefined);
  const [sortAsc, setSortAsc] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [, setUserInfo] = React.useState(undefined);
  const [page, setPage] = React.useState(1);
  const [sortColumnHeading, setSortColumnHeading] = React.useState(undefined);
  const [headings] = React.useState(OrdersPageColumnHeadings);
  const [rowsPerPage] = React.useState(10);
  const [firstRowIndex, setFirstRowIndex] = React.useState((page - 1) * rowsPerPage + 1);
  const [numberOfRows, setNumberOfRows] = React.useState(undefined);
  const [lastRowIndex, setLastRowIndex] = React.useState(undefined);
  const [pageItems, setPageItems] = React.useState(undefined);
  const [canGoToPreviousPage, setCanGoToPreviousPage] = React.useState(page > 1);
  const [maxPages, setMaxPages] = React.useState(undefined);
  const [canGoToNextPage, setCanGoToNextPage] = React.useState(undefined);
  const [searchInput, setSearchInput] = React.useState(props?.location?.state?.orderSearchInput);
  const [ordersSearched, setOrdersSearched] = React.useState(undefined);
  const [allOrdersLoaded, setAllOrdersLoaded] = React.useState(undefined);

  React.useEffect(() => {
    salesforce.userInfo$().subscribe(async userInfo => {
      setUserInfo(userInfo);
    });

    setOrdersSearched(!!props?.location?.state?.orderSearchInput);
  }, []);

  React.useEffect(() => {
    if(searchInput && internalView() && !allOrdersLoaded) {
      loadInternalOrders();
    } else {
      finalizeDisplayedOrders();
    }

  }, [searchInput, orders]);

  React.useEffect(() => {
    setLastRowIndex(Math.min(numberOfRows, (rowsPerPage * page)));
  }, [numberOfRows, rowsPerPage, page]);

  React.useEffect(() => {
    setPageItems(displayedOrders?.slice(firstRowIndex - 1, lastRowIndex));
  }, [displayedOrders, firstRowIndex, lastRowIndex]);

  React.useEffect(() => {
    setFirstRowIndex((page - 1) * rowsPerPage + (displayedOrders?.length ? 1 : 0));
  }, [page, rowsPerPage, displayedOrders]);

  React.useEffect(() => {
    setCanGoToNextPage(page < maxPages);
  }, [page, maxPages]);

  React.useEffect(() => {
    setCanGoToPreviousPage(page > 1);
  }, [page]);

  React.useEffect(() => {
    setMaxPages(Math.ceil(numberOfRows / rowsPerPage));
  }, [numberOfRows, rowsPerPage]);

  React.useEffect(() => {
    setNumberOfRows(displayedOrders?.length);
  }, [displayedOrders]);

  React.useEffect(() => {
    sortItems();
  }, [sortColumnId, sortAsc]);

  // Loads all orders for internal view search
  const loadInternalOrders = async () => {
    setAllOrdersLoaded(true);
    setOrders(await salesforce.getAccountOrders());
  };

  // Returns internal view flag from environment
  const internalView = () => {
    return environment?.internalView();
  };

  // Sets the displayed orders
  const finalizeDisplayedOrders = async () => {
    const uppercaseSearchInput = searchInput?.toUpperCase();

    if(orders) {
      const mappedOrders = await Promise.all(
        orders.filter(order => uppercaseSearchInput ?
          order.PO_Number__c?.toUpperCase().includes(uppercaseSearchInput) ||
          order.AX_Order_Number__c?.toUpperCase().includes(uppercaseSearchInput) ||
          order.Account_Name__r?.Name?.toUpperCase().includes(uppercaseSearchInput) : true)
          .map(async order => await mapOrder(order, lastViewed, darkMode)));

      const databaseColumnId = getColumnIdFromName(OrdersPageColumnHeadings.LastUpdate);
      const finalizedOrders = mappedOrders.orderByDate(item => item[databaseColumnId] ? moment(item[databaseColumnId]).format('MM/DD/YYYY') : item[databaseColumnId], false);

      setDisplayedOrders(finalizedOrders);
      setLoading(false);
    }
  };

  // Sorts the orders according to the sort column
  const sortItems = async () => {
    let updatedItems;

    if(displayedOrders) {
      updatedItems = [...displayedOrders];
    }
    
    switch(sortColumnId) {
    case getColumnIdFromName(OrdersPageColumnHeadings.NCSalesOrder):
      updatedItems = updatedItems?.orderBy(item => +(item[sortColumnId]?.split('_').pop()), sortAsc);
      break;
    case getColumnIdFromName(OrdersPageColumnHeadings.Items):
      updatedItems = updatedItems?.orderBy(item => +item[sortColumnId], sortAsc);
      break;
    case getColumnIdFromName(OrdersPageColumnHeadings.LastUpdate):
      updatedItems = updatedItems?.orderByDate(item => (item[sortColumnId] ? moment(item[sortColumnId]).format('MM/DD/YYYY') : item[sortColumnId]), sortAsc);
      break;
    default:
      updatedItems = updatedItems?.orderBy(item => item[sortColumnId], sortAsc);
      break;
    }

    setDisplayedOrders(updatedItems);
    setPage(1);
  };

  // Returns the correct salesforce order property based on the column header
  const getColumnIdFromName = (colName: OrdersPageColumnHeadings) => {
    return {
      [OrdersPageColumnHeadings.Status]: 'Status',
      [OrdersPageColumnHeadings.PurchaseOrder]: 'PO_Number__c',
      [OrdersPageColumnHeadings.NCSalesOrder]: 'AX_Order_Number__c',
      [OrdersPageColumnHeadings.Account]: 'accountName',
      [OrdersPageColumnHeadings.Items]: 'Number_of_Line_Items__c',
      [OrdersPageColumnHeadings.LastUpdate]: 'Last_Line_Updated_Date__c',
      [OrdersPageColumnHeadings.Total]: 'Order_Total_Amount__c',
    }[colName];
  };

  // Handler for when an order header column is selected
  const selectColumnHandler = (column: OrdersPageColumnHeadings) => {
    setSortColumnHeading(column);
    
    const databaseColumnId = getColumnIdFromName(column as OrdersPageColumnHeadings);

    if(sortColumnId === databaseColumnId) {
      setSortAsc(!sortAsc);
    } else {
      setSortColumnId(databaseColumnId);
    }
  };

  // Returns proper column header class based on whether the column is sorted or not
  const getColHeaderClass = (column: OrdersPageColumnHeadings) => {
    return (column === sortColumnHeading) ? 'orders-text text-bold' : 'orders-text';
  };

  // Returns proper column header icon based on whether the column is sorted or not
  const getColHeaderIcon = (column: OrdersPageColumnHeadings) => {
    return (column === sortColumnHeading && sortAsc) ? chevronUp : chevronDown;
  };

  // Navigates to previous page of the orders screen
  const goToPreviousPage = () => {
    setPage(page - 1);
  };

  // Navigates to next page of the orders screen
  const goToNextPage = () => {
    setPage(page + 1);
  };

  // Navigates to the order detail page for the selected order
  const navigateToOrderDetail = (order: Order) => {
    history.push('orders/order-detail', { order });
  };

  const AccountColumn = (props) => {
    return (internalView() && !platform.isTablet() ?
      <IonCol class="column-left account-col">
        <span className="order-summary-text" data-testid="QAOrdersPageOrderLineItemAccount">{ props.order.accountName }</span>
      </IonCol> : null
    );
  };


  const OrderLine = (props) => {
    return (
      <IonCard className="order-card" onClick={() => navigateToOrderDetail(props.order)} data-testid={'QAOrdersPageOrderLineItem_' + props.order.PO_Number__c}>
        <IonCardHeader class={classNames({ 'order-card-header': true, 'even': props.orderIndex % 2 === 0, 'odd': props.orderIndex % 2 !== 0 })}>
          <Badge text={props.order.badgeText}/>
          <IonGrid>
            <IonRow class="order-row">
              <IonCol class="column-left" size={ !platform.isMobile() ? (!internalView() || platform.isTablet() ? '2' : '1.43') : '11' }>
                <span className="orders-text mobile-only status-label">{ headings.Status }</span>
                <img className="status-icon" src={ props.order.statusImgSrc }/>
                <span className="order-summary-text" data-testid="QAOrdersPageOrderLineItemStatus">{ props.order.Status }</span>
              </IonCol>
              <IonCol class="column-left" size={ !platform.isMobile() ? (!internalView() || platform.isTablet() ? '2' : '1.43') : '11' }>
                <span className="orders-text mobile-only po-label">{ headings.PO }</span>
                <span className="order-summary-text" data-testid="QAOrdersPageOrderLineItemPurchaseOrder">{ props.order.PO_Number__c }</span>
              </IonCol>
              <IonCol class="column-left nc-sales-order-col" size={!internalView() || platform.isTablet() ? '2' : '1.43'}>
                <span className="order-summary-text" data-testid="QAOrdersPageOrderLineItemNCSalesOrder">{ props.order.AX_Order_Number__c }</span>
              </IonCol>
              <AccountColumn order={props.order}/>
              <IonCol class="column-left items-col" size={ !platform.isTablet() ? (!internalView() || platform.isTablet() ? '2' : '0.78') : '1' }>
                <span className="order-summary-text" data-testid="QAOrdersPageOrderLineItemItems">{ props.order.Number_of_Line_Items__c }</span>
              </IonCol>
              <IonCol class="column-left last-update-col" size={ !internalView() || platform.isTablet() ? '2' : '1.43' }>
                <span className="order-summary-text" data-testid="QAOrdersPageOrderLineItemLastUpdate">{ props.order.Last_Line_Updated_Date__c ? moment(props.order.Last_Line_Updated_Date__c).format('L') : props.order.Last_Line_Updated_Date__c }</span>
              </IonCol>
              <IonCol class="column-left" size={ !platform.isMobile() ? (!internalView() || platform.isTablet() ? '2' : '1.43') : '11' }>
                <span className="orders-text mobile-only total-label">{ headings.Total }</span>
                <span className="order-summary-text total-price" data-testid="QAOrdersPageOrderLineItemTotal">{ formatCurrency(props.order.Order_Total_Amount__c || 0, props.order.CurrencyIsoCode) }</span>
              </IonCol>
              <LineItemAttachment visible={props.order.Document_Links__r}/>
              {/*<LineItemActions
                stopPropagation='true'
                data-testid="QAOrdersPageOrderLineItemActions">
              </LineItemActions>*/}
            </IonRow>
          </IonGrid>
        </IonCardHeader>
      </IonCard>
    );
  };

  const OrdersList = () => {
    return ( pageItems?.length ?
      <>{pageItems?.map((order, index) => (
        <OrderLine order={order} orderIndex={index} key={index}/>
      ))}</> :
      <EmptyTableRow text={'No Orders Available'}/>
    );
  };

  const SearchParentContainer = () => {
    return ( ordersSearched ?
      <SearchContainer
        searchInput={searchInput}
        orders={orders}
        searchButtonClickHandler={(searchInput) => setSearchInput(searchInput)}
        navigateToOrderDetail={navigateToOrderDetail}
      /> : null
    );
  };

  const AccountColumnHeader = () => {
    return (internalView() && !platform.isTablet() ?
      <IonCol class="orders-col account-col" onClick={() => selectColumnHandler(headings.Account)} data-testid="QAOrdersPageAccountColumnHeader">
        <span className={getColHeaderClass(headings.Account)}>{ headings.Account }</span>
        <IonIcon icon={getColHeaderIcon(headings.Account)}></IonIcon>
      </IonCol> : null
    );
  };

  return ( !loading ?
    <>
      <IonContent class="orders-page">
        <div className={classNames({
          'max-width-container': true,
          'orders-wrapper': true,
          'loading': loading
        })}
        >
          <IonLabel class='orders-title' data-testid="QAOrdersPageTitle">{ordersSearched ? 'Search Results' : 'Orders'}</IonLabel>
          <div className={classNames({
            'pagination-parent-container': true,
            'searched': ordersSearched
          })}
          >
            <div className="pagination-container">
              <IonLabel class="pagination-label">Viewing <span className="bold">{firstRowIndex}-{lastRowIndex}</span> of <span className="bold">{numberOfRows}</span></IonLabel>
              <div className="pagination-buttons-container">
                <IonButton disabled={!canGoToPreviousPage} class={classNames({
                  'change-page-button': true,
                  'searched': ordersSearched
                })} color="secondary" onClick={() => goToPreviousPage()} data-testid="QAOrdersPageTopPreviousPageButton"
                >{!platform.isMobile() ? <IonIcon src={backArrow}></IonIcon> : 'Previous'}</IonButton>
                <IonButton disabled={!canGoToNextPage} class={classNames({
                  'change-page-button': true,
                  'searched': ordersSearched
                })} color="secondary" onClick={() => goToNextPage()} data-testid="QAOrdersPageTopNextPageButton"
                >{!platform.isMobile() ? <IonIcon class="next-icon" src={forwardArrow}></IonIcon> : 'Next'}</IonButton>
              </div>
            </div>
          </div>
          <SearchParentContainer/>
          <hr className={classNames({
            'searched': ordersSearched
          })}
          />
          <IonCard class="orders-card">
            <IonCardHeader class="orders-card-header">
              <IonGrid>
                <IonRow class="orders-row">
                  <IonCol class="orders-col" size={ !internalView() || platform.isTablet() ? '2' : '1.43' } onClick={() => selectColumnHandler(headings.Status)} data-testid="QAOrdersPageStatusColumnHeader">
                    <span className={getColHeaderClass(headings.Status)}>{ headings.Status }</span>
                    <IonIcon icon={getColHeaderIcon(headings.Status)}></IonIcon>
                  </IonCol>
                  <IonCol class="orders-col" size={ !internalView() || platform.isTablet() ? '2' : '1.43' } onClick={() => selectColumnHandler(headings.PurchaseOrder)} data-testid="QAOrdersPagePurchaseOrderColumnHeader">
                    <span className={getColHeaderClass(headings.PurchaseOrder)}>{ headings.PurchaseOrder }</span>
                    <IonIcon icon={getColHeaderIcon(headings.PurchaseOrder)}></IonIcon>
                  </IonCol>
                  <IonCol class="orders-col nc-sales-order-col" size={ !internalView() || platform.isTablet() ? '2' : '1.43' } onClick={() => selectColumnHandler(headings.NCSalesOrder)} data-testid="QAOrdersPageNCSalesOrderColumnHeader">
                    <span className={getColHeaderClass(headings.NCSalesOrder)}>{ headings.NCSalesOrder }</span>
                    <IonIcon icon={getColHeaderIcon(headings.NCSalesOrder)}></IonIcon>
                  </IonCol>
                  <AccountColumnHeader/>
                  <IonCol class="orders-col items-col" size={ !platform.isTablet() ? (!internalView() ? '2' : '0.78') : '1' } onClick={() => selectColumnHandler(headings.Items)} data-testid="QAOrdersPageItemsColumnHeader">
                    <span className={getColHeaderClass(headings.Items)}>{ headings.Items }</span>
                    <IonIcon icon={getColHeaderIcon(headings.Items)}></IonIcon>
                  </IonCol>
                  <IonCol class="orders-col last-update-col" size={ !internalView() || platform.isTablet() ? '2' : '1.43' } onClick={() => selectColumnHandler(headings.LastUpdate)} data-testid="QAOrdersPageLastUpdateColumnHeader">
                    <span className={getColHeaderClass(headings.LastUpdate)}>{ headings.LastUpdate }</span>
                    <IonIcon icon={getColHeaderIcon(headings.LastUpdate)}></IonIcon>
                  </IonCol>
                  <IonCol class="orders-col" size={ !internalView() || platform.isTablet() ? '2' : '1.43' } onClick={() => selectColumnHandler(headings.Total)} data-testid="QAOrdersPageTotalColumnHeader">
                    <span className={getColHeaderClass(headings.Total)}>{ headings.Total }</span>
                    <IonIcon icon={getColHeaderIcon(headings.Total)}></IonIcon>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardHeader>
          </IonCard>
          <OrdersList/>
          <hr className='bottom-rule'/>
          <div className="pagination-parent-container bottom">
            <div className="pagination-container bottom" data-testid="QAOrdersPageBottomPaginationContainer">
              <IonLabel class="pagination-label">Viewing <span className="bold">{firstRowIndex}-{lastRowIndex}</span> of <span className="bold">{numberOfRows}</span></IonLabel>
              <div className="pagination-buttons-container">
                <IonButton disabled={!canGoToPreviousPage} class="change-page-button" color="secondary" onClick={() => goToPreviousPage()} data-testid="QAOrdersPageBottomPreviousPageButton">{!platform.isMobile() ? <IonIcon src={backArrow}></IonIcon> : 'Previous'}</IonButton>
                <IonButton disabled={!canGoToNextPage} class="change-page-button" color="secondary" onClick={() => goToNextPage()} data-testid="QAOrdersPageBottomNextPageButton">{!platform.isMobile() ? <IonIcon class="next-icon" src={forwardArrow}></IonIcon> : 'Next'}</IonButton>
              </div>
            </div>
          </div>
        </div>
        <div className="footer" data-testid="QAOrdersPageFooterContainer">
          <Footer/>
        </div>
      </IonContent>
    </> :
    <div className="orders-loading-container">
      <Loading data-testid="'QAOrdersLoading'"/>
    </div>
  );
};

export default OrdersPage;