import { IonIcon } from '@ionic/react';
import { lockClosed, lockOpen } from 'ionicons/icons';
import React from 'react';
import useLockOnWindowEvents from '@components/toggle-lock/useLockWindowEvents';

interface ToggleLockProps {
  onToggle: () => void;
  isLocked: boolean;
  setIsLocked: (isLocked: boolean) => void;
}
const ToggleLock = ({ onToggle, isLocked, setIsLocked }: ToggleLockProps) => {
  // give the user a limited number of chances to change the Quote.DocumentUOM__c, before the lock is re-locked.
  useLockOnWindowEvents(() => {
    setIsLocked(true);
  });
  
  const handleClick = () => {
    if (onToggle && isLocked) {
      onToggle();
    }
    setIsLocked(!isLocked);
  };

  const color = 'medium';
  const iconColor = isLocked ? color : 'default';

  return (
    <>
      <div onClick={handleClick} style={{ cursor: 'pointer' }}>
        <IonIcon src={isLocked ? lockClosed : lockOpen} size='small' color={iconColor} />
      </div>
    </>
  );
};

export default ToggleLock;
