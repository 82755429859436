import React from 'react';
import { IonItem, IonLabel, IonList } from '@ionic/react';
import './actions-popover.scss';

const ActionsPopover = (props) => {
  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const ActionsList = () => {
    return (
      <>
        {props.actions.map((action, idx) => (
          <IonItem key={idx}>
            <IonLabel class="action-label" data-testid={'QAActionsPopoverAction_' + action.label} onClick={(e) => {
              e.stopPropagation();
              dismissModal();
              action.actionToPerform();
            }}
            >{action.label}</IonLabel>
          </IonItem>
        ))}
      </>
    );
  };

  return (
    <div className="popup-container">
      <IonList className="actions-list-container">
        <ActionsList/>
      </IonList>
    </div>
  );
};

export default ActionsPopover;