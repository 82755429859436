import { Quote } from '@interfaces/quote';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { getCall, postCall } from '@services/rest-service';

interface QuoteData {
  response: {
  quote: Quote;
  quoteLineItems: QuoteLineItem[];
  quoteLineItemDetails: QuoteLineItemDetail[];
  };
}

// Retrieves quote from cosmos
export const getQuote = (quoteId: string): Promise<Quote> => {
  return getCall<Quote>(`quotes/${quoteId}`);
};

// Updates quote in cosmos
export const updateQuote = (quote: Quote, id: string): Promise<any> => {
  return postCall('quotes', { ...quote, id });
};

// retrieves quote data from cosmos or salesforce
export const getQuoteData = (quoteId: string): Promise<QuoteData> => {
  return getCall<QuoteData>(`quotedata/${quoteId}`);
};

// updates quote data from cosmos or salesforce
export const postQuoteData = (quoteId: string, payload: any): Promise<QuoteData> => {
  return postCall<QuoteData>(`quotedata/${quoteId}`, payload);
};