import { CustomerSpecifiedImpactTestDirections, PostCladTestingOptions, TensileTestLocations } from '@constants';
import _ from 'lodash-es';
import { FormKeys } from "./form-keys";

const PostCladTestingOptionsOrder = [
  PostCladTestingOptions.Shear,
  PostCladTestingOptions.Tensile,
  PostCladTestingOptions.ImpactLCVN,
  PostCladTestingOptions.ImpactTCVN,
  PostCladTestingOptions.RamTensile,
  PostCladTestingOptions.ADW8Bend,
  PostCladTestingOptions.B898Bend,
  PostCladTestingOptions.SA26345BondStrength,
];

interface MapperResult {
  [key: string]: any
};

interface IFormKeyHandler {
  [key: string]: (value, form) => MapperResult;
}

export const FormKeyHandler: IFormKeyHandler = {
  [FormKeys.BendB898]: (value, form) => {
    return {}
  }
}

export const FormKeyResolveHandler: IFormKeyHandler = {
  [FormKeys.RequiredShearTest]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.ShearTestUOM]: null,
      [FormKeys.ShearTestValue]: null,
    };
  },
  [FormKeys.RequiredTensileTest]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.CladTensileTestLocation]: null,
    };
  },
  [FormKeys.RequiredBondStrTensileTest]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.BondStrengthTensileTestRamUOM]: null,
      [FormKeys.BondStrengthTensileTestRamValue]: null,
    };
  },
  [FormKeys.RequiredHardnessCladdingMetalSurface]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.HardnessCladdingMetalSurface]: null,
      [FormKeys.HardnessCladdingRejectionCriteria]: null,
    };
  },
  [FormKeys.RequiredHardnessBaseMetalSurface]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.HardnessBaseMetalSurface]: null,
      [FormKeys.HardnessBaseRejectionCriteria]: null,
    };
  },
  [FormKeys.RequiredPMIConfirmCladdingMetal]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.PMIConfirmCladdingMetal]: null,
      [FormKeys.PMIConfirmCladdingMetalNotes]: null,
    };
  },
  [FormKeys.RequiredPMIConfirmBaseMetal]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.PMIConfirmBaseMetal]: null,
      [FormKeys.PMIConfirmBaseMetalNotes]: null,
    };
  },
  [FormKeys.RequiredCorrosionTesting]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.CorrosionTesting]: null,
      [FormKeys.CorrosionTestingNotes]: null,
    };
  },
  [FormKeys.RequiredCladdingCustomerChemRestriction]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.CustomerCladChemistryRestrictionsNotes]: null,
    };
  },
  [FormKeys.RequiredCladdingMetalSpecEdAd]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.CladMetalSpecEdition]: null,
      [FormKeys.CladMetalSpecAddendum]: null,
    };
  },
  [FormKeys.RequiredCladdingCustomerMetalSupplierRestriction]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.CustomerCladMetalSupplierRestrictions]: null,
      [FormKeys.CustCladMetalSupplierRestrictionsNotes]: null,
    };
  },
  [FormKeys.RequiredCladdingMetalProdAnalysis]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.CladMetalProductAnalysisRequired]: null,
      [FormKeys.CladMetalProductAnalysisRequiredNotes]: null,
      [FormKeys.CladMetalProductAnalysisType]: null,
    };
  },
  [FormKeys.RequiredFerroxlyTestOfCladdingSurface]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.FerroxylTestPerformed]: null,
      [FormKeys.FerroxylNotes]: null,
    };
  },
  [FormKeys.RequiredFerriteNumber]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.FerriteNumberRequired]: null,
    };
  },
  [FormKeys.RequiredPrecladBaseMetalUT]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.PrecladBaseMetalUT]: null,
      [FormKeys.PrecladBaseMetalUTNotes]: null,
      [FormKeys.PrecladBaseMetalUTType]: null,
    };
  },
  [FormKeys.RequiredPrebondImpacts]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      // [FormKeys.HardnessBaseMetalSurface]: null,
      // [FormKeys.HardnessBaseRejectionCriteria]: null,
    };
  },
  [FormKeys.RequiredCarbon]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.Carbon_Percent]: null,
    };
  },
  [FormKeys.RequiredSulphur]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.Sulphur]: null,
    };
  },
  [FormKeys.RequiredPhosphorus]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.Phosphorus_Percent]: null,
    };
  },
  [FormKeys.RequiredCarbonEquivalency]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.Carbon_Equivalency_Percent]: null,
    };
  },
  [FormKeys.RequiredJFactor]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.JFactorSA387S62]: null,
    };
  },
  [FormKeys.RequiredXFactor]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.XFactorSA387S62]: null,
    };
  },
  [FormKeys.RequiredBaseCustomerChemRestriction]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.CustomerBaseChemistryRestrictions]: null,
    };
  },
  [FormKeys.RequiredCenterlineTensile]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.BaseTensileTestLocation]: null,
    };
  },
  [FormKeys.RequiredHICTesting]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.HicTestNotes]: null,
    };
  },
  [FormKeys.RequiredAusteniticGrainSizeFiner]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.AusteniticGrainSizeFinerThanValue]: null,
    };
  },
  [FormKeys.RequiredBaseMetalSpecEdAd]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.BaseMetalSpecEdition]: null,
      [FormKeys.BaseMetalSpecAddendum]: null,
    };
  },
  [FormKeys.RequiredBaseCustomerMetalSupplierRestriction]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.CustomerBaseMetalSupplierRestrictions]: null,
      [FormKeys.CustBaseMetalSupplierRestrictionsNotes]: null,
    };
  },
  [FormKeys.RequiredBaseMetalProdAnalysis]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.BaseMetalProductAnalysisRequired]: null,
      [FormKeys.BaseMetalProductAnalysisType]: null,
    };
  },

  // Modals
  [FormKeys.CustomerSpecifiedSPWHTTestCoupons]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.SPWHTHeatingAndCoolingRateAbove]: null,
      [FormKeys.SPWHTHeatingAndCoolingRateAboveUOM]: null,
      [FormKeys.SPWHTMaximumCoolingRateHour]: null,
      [FormKeys.SPWHTMaximumCoolingRateHourUOM]: null,
      [FormKeys.SPWHTMaximumHeatingRateHour]: null,
      [FormKeys.SPWHTMaximumHeatingRateHourUOM]: null,
    };
  },
  [FormKeys.CustomerSpecifiedImpactTesting]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.CustomerSpecifiedImpactTestToBePerformed]: null,
      [FormKeys.CustomerSpecifiedImpactTestLocation]: null,
      [FormKeys.CustomerSpecifiedImpactTestDirection]: null,
      [FormKeys.CustomerSpecifiedImpactTestTemperature]: null,
      [FormKeys.CustomerSpecifiedImpactTestTempUOM]: null,
      [FormKeys.CustomerSpecifiedImpactTestUOM]: null,
      [FormKeys.CustomerSpecifiedImpactTestAVG]: null,
      [FormKeys.CustomerSpecifiedImpactTestMIN]: null,
    };
  },
  [FormKeys.PrebondImpacts]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.PreBondImpactsTestToBePerformed]: null,
      [FormKeys.PreBondImpactsTestLocation]: null,
      [FormKeys.PreBondImpactsTestDirection]: null,
      [FormKeys.PreBondImpactsTestUOM]: null,
      [FormKeys.PreBondImpactsTestTemperature]: null,
      [FormKeys.PreBondImpactsTestTemperatureUOM]: null,
      [FormKeys.PreBondImpactsTestAVG]: null,
      [FormKeys.PreBondImpactsTestMIN]: null,
    };
  },
  [FormKeys.RequiredLeakTest]: (value, form) => {
    if (value) {
      return {}
    }

    return {
      [FormKeys.LeakTestGas]: null,
      [FormKeys.LeakTestReqt]: null,
      [FormKeys.LeakTestValue]: null,
      [FormKeys.LeakTestPressure]: null
    };
  },

  // UOM strict values and needed `°`
  [FormKeys.HotTensileTestPerSA20S7UOM]: (value, form) => {
    if (!value || value?.includes('°')) {
      return {};
    }

    return {
      [FormKeys.HotTensileTestPerSA20S7UOM]: `°${value}`,
    };
  },
  [FormKeys.CustomerSpecifiedImpactTestTempUOM]: (value, form) => {
    if (!value || value?.includes('°')) {
      return {};
    }

    return {
      [FormKeys.CustomerSpecifiedImpactTestTempUOM]: `°${value}`,
    };
  },
  [FormKeys.PreBondImpactsTestTemperatureUOM]: (value, form) => {
    if (!value || value?.includes('°')) {
      return {};
    }

    return {
      [FormKeys.PreBondImpactsTestTemperatureUOM]: `°${value}`,
    };
  },

  [FormKeys.Post_Clad_Testing_Options]: (value, form) => {
    let options = value ? value.split(';') : [];

    const remove = (item) => {
      return options.filter((option) => option !== item);
    }

    // shear
    if (form[FormKeys.RequiredShearTest]) {
      if (!options.includes(PostCladTestingOptions.Shear)) {
        options.push(PostCladTestingOptions.Shear);
      }
    } else {
      options = remove(PostCladTestingOptions.Shear);
    }

    if (form[FormKeys.RequiredTensileTest]) {
      if (!options.includes(PostCladTestingOptions.Tensile)) {
        options.push(PostCladTestingOptions.Tensile);
      }
    } else {
      options = remove(PostCladTestingOptions.Tensile);
    }

    if (form[FormKeys.MachineBondStrengthBendsperSA26345]) {
      if (!options.includes(PostCladTestingOptions.SA26345BondStrength)) {
        options.push(PostCladTestingOptions.SA26345BondStrength);
      }
    } else {
      options = remove(PostCladTestingOptions.SA26345BondStrength);
    }

    if (form[FormKeys.BendB898]) {
      if (!options.includes(PostCladTestingOptions.B898Bend)) {
        options.push(PostCladTestingOptions.B898Bend);
      }
    } else {
      options = remove(PostCladTestingOptions.B898Bend);
    }

    if (form[FormKeys.ADW8Bend]) {
      if (!options.includes(PostCladTestingOptions.ADW8Bend)) {
        options.push(PostCladTestingOptions.ADW8Bend);
      }
    } else {
      options = remove(PostCladTestingOptions.ADW8Bend);
    }

    if (form[FormKeys.CustomerSpecifiedImpactTesting] && form[FormKeys.CustomerSpecifiedImpactTestDirection]) {
      const impactValue = form[FormKeys.CustomerSpecifiedImpactTestDirection];
      if ([
        CustomerSpecifiedImpactTestDirections.Both,
        CustomerSpecifiedImpactTestDirections.Longitudinal,
      ].includes(impactValue)) {
        if (!options.includes(PostCladTestingOptions.ImpactLCVN)) {
          options.push(PostCladTestingOptions.ImpactLCVN);
        }
      } else {
        remove(PostCladTestingOptions.ImpactLCVN)
      }

      if ([
        CustomerSpecifiedImpactTestDirections.Both,
        CustomerSpecifiedImpactTestDirections.Transverse,
      ].includes(impactValue)) {
        if (!options.includes(PostCladTestingOptions.ImpactTCVN)) {
          options.push(PostCladTestingOptions.ImpactTCVN);
        }
      } else {
        remove(PostCladTestingOptions.ImpactTCVN)
      }
    } else {
      options = remove(PostCladTestingOptions.ImpactLCVN);
      options = remove(PostCladTestingOptions.ImpactTCVN);
    }

    if (form[FormKeys.RequiredBondStrTensileTest]) {
      if (!options.includes(PostCladTestingOptions.RamTensile)) {
        options.push(PostCladTestingOptions.RamTensile);
      }
    } else {
      options = remove(PostCladTestingOptions.RamTensile);
    }

    const newOptions = PostCladTestingOptionsOrder.reduce((prev, curr) => {
      const exists = options.find(option => option === curr);

      if (exists) {
        prev.push(curr);
      }

      return prev;
    }, []);

    return {
      [FormKeys.Post_Clad_Testing_Options]: newOptions.join(';')
    };
  }

}