import React from 'react';
import FloatingAction from '@components/floating-action/floating-action';
import advancedSpecsTab from '@assets/icon/Tab_Adv_Specs.svg';
import advancedSpecsTabDarkMode from '@assets/icon/Tab_Adv_Specs_DarkMode.svg';
import advancedSpecsTabHovered from '@assets/icon/Tab_Adv_Specs_Hovered.svg';

import './page-floating-actions.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useQuoteState } from '@context/quote-context';
import { PageRoutes } from '@constants';
import { navigateToRoute } from '@shared/utils';
import { useQuoteFormContext } from '@context/quote-form-context';
import _ from 'lodash-es';

const PageFloatingAction = () => {
  const history = useHistory();
  const {
    onResolveQuoteLineItems
  } = useQuoteFormContext()
  const {
    quoteState,
    syncFormQuoteLineItem
  } = useQuoteState();
  const { quote } = quoteState;

  const { lineItemId } = useParams<{lineItemId: string}>();

  const syncForm = () => {
    syncFormQuoteLineItem(onResolveQuoteLineItems());
  }

  return <>
    <div className='page-floating-actions-container'>
      <FloatingAction
        icon={advancedSpecsTab}
        darkModeIcon={advancedSpecsTabDarkMode}
        hoveredIcon={advancedSpecsTabHovered}
        onClick={() => {
          syncForm();
          navigateToRoute(PageRoutes.AdvancedSpecifications, history, quote.Id, undefined, lineItemId);
        }}
        action='advanced-specifications'
      />
    </div>
  </>
};

export default PageFloatingAction