import React, { useState } from 'react';

import Modal from '@components/modal/modal';
import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import AppLineItem from '@components/app-line-item/app-line-item';
import AppLineItemColumns from '@components/app-line-item-columns/app-line-item-columns';
import AppInput from '@components/app-input/app-input';
import AppRadioButtons from '@components/app-radio-buttons/app-radio-buttons';

import './leak-test.scss';
import { FormKeys } from '../advance-specification-form-util';

interface Props {
  form?: any,
  onSave,
  onDismiss: (payload) => void
};

const extractGas = (value) => {
  const items = ['Helium', 'Air'];
  const radio = items.includes(value)
    ? value
    : 'Other';
  const input = !items.includes(value)
    ? value
    : '';

  return {
    radio,
    input,
  }
};

const extractValue = (value = '') => {
  const radio = value?.match(/1( )?x( )?10/i)
    ? '1x10'
    : 'Other';

  const input1 = radio == '1x10'
    ? value?.match(/1x10(.*)ATM-cc\/sec/i)
    : ''

  const input2 = radio == 'Other'
    ? value
    : ''

  return {
    radio,
    input1: input1 && input1[0],
    input2
  };
};

const extractPressure = (value) => {
  const radio = value === 'Vacuum'
    ? 'Vacuum'
    : 'Other';

  const input = value !== 'Vacuum'
    ? value
    : ''

  return {
    radio,
    input
  };
};

const LeakTest = ({onDismiss, onSave, form}: Props) => {
  const [ item, setItem ] = useState({
    gas: extractGas(form[FormKeys.LeakTestGas]),
    reqt: form[FormKeys.LeakTestReqt],
    value: extractValue(form[FormKeys.LeakTestValue]),
    pressure: extractPressure(form[FormKeys.LeakTestPressure])
  });

  const actionsButtons = [
    {
      label: 'Save',
      color: 'primary',
      onClick: () => {
        const payload = {
          [FormKeys.LeakTestGas]: item.gas.radio === 'Other' ? item.gas.input : item.gas.radio,
          [FormKeys.LeakTestReqt]: item.reqt,
          [FormKeys.LeakTestValue]: item.value.radio === 'Other' ? item.value.input2 : `1 x 10 ${item.value.input1} ATM-CC / SEC`,
          [FormKeys.LeakTestPressure]: item.pressure.radio === 'Other' ? item.pressure.input : item.pressure.radio,
        };

        onSave(payload)
      }
    },
    {
      label: 'Cancel',
      color: 'secondary',
      onClick: () => {
        onDismiss({})
      }
    }
  ];

  return <>
    <Modal
      title="Leak Test"
      options={{
        isOpen: true,
        size: 'adv-spec'
      }}
      onClickClose={() => onDismiss({})}
      actionButtons={actionsButtons}
    >
      <div className='leak-test-impact-testing-container'>
        <AppLineItem label="Gas" sizes={['2', '10']}>
          <AppLineItemColumns
            components={[
              {
                size: '5.5',
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
                component: <AppRadioButtons
                  options={['Helium', 'Air', 'Other']}
                  selected={item.gas.radio}
                  onChange={(value) => {
                    setItem({
                      ...item,
                      gas: {
                        radio: value,
                        input: item.gas.input
                      }
                    });
                  }}
                />,
              },
              {
                size: '6.5',
                component: <AppInput
                  disabled={item.gas.radio !== 'Other'}
                  value={item.gas.input}
                  onChange={(value) => {
                    setItem({
                      ...item,
                      gas: {
                        radio: item.gas.radio,
                        input: value
                      }
                    });
                  }}
                />,
              },
            ]}
          />
        </AppLineItem>
        <AppLineItem label="Reqt" sizes={['2', '10']}>
          <AppLineItemColumns
            components={[{
              style: {
                display: 'flex',
                alignItems: 'center',
              },
              component: <AppRadioButtons
                selected={item.reqt}
                options={['Maximum', 'Minimum']}
                onChange={(value) => setItem({
                  ...item,
                  reqt: value
                })}
              />
            }]}
          />
        </AppLineItem>
        <AppLineItem label="Value" sizes={['2', '10']}>
          <AppLineItemColumns
            components={[
              {
                size: '2',
                component: <AppRadioButtons
                  selected={item.value.radio}
                  options={['1x10']}
                  onChange={(value) => {
                    setItem({
                      ...item,
                      value: {
                        ...item.value,
                        radio: value
                      }
                    });
                  }}
                />,
              },
              {
                size: '3',
                component: <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AppInput
                    style={{ maxWidth: '40px'}}
                    value={item.value.input1}
                    disabled={item.value.radio !== '1x10'}
                    onChange={(value) => {
                      setItem({
                        ...item,
                        value: {
                          ...item.value,
                          input1: value
                        }
                      });
                    }}
                  />
                  <IonLabel style={{ marginLeft: '5px' }}>
                    ATM-CC/SEC
                  </IonLabel>
                </div>
              },
              {
                size: '2.5',
                component: <AppRadioButtons
                  selected={item.value.radio}
                  options={['Other']}
                  onChange={(value) => {
                    setItem({
                      ...item,
                      value: {
                        ...item.value,
                        radio: value
                      }
                    });
                  }}
                />,
              },
              {
                component: <AppInput
                  disabled={item.value.radio !== 'Other'}
                  value={item.value.input1}
                    onChange={(value) => {
                      setItem({
                        ...item,
                        value: {
                          ...item.value,
                          input2: value
                        }
                      });
                    }}
                  />,
              }
            ]}
          />
        </AppLineItem>
        <AppLineItem label="Pressure" sizes={['2', '10']}>
          <AppLineItemColumns
            components={[
              {
                size: '4.5',
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
                component: <AppRadioButtons
                  selected={item.pressure.radio}
                  onChange={(value) => {
                    setItem({
                      ...item,
                      pressure: {
                        ...item.pressure,
                        radio: value
                      }
                    })
                  }}
                  options={['Vacuum', 'Other']}
                />,
              },
              {
                size: '7.5',
                component: <AppInput
                  disabled={item.pressure.radio !== 'Other'}
                  value={item.pressure.input}
                  onChange={(value) => {
                    setItem({
                      ...item,
                      pressure: {
                        ...item.pressure,
                        input: value
                      }
                    })
                  }}
                />,
              },
            ]}
          />
        </AppLineItem>
      </div>
    </Modal>
  </>
};

export default LeakTest;