import CheckboxItem from '@components/checkbox-item/checkbox-item';
import LineItemField from '@components/line-item-field/line-item-field';
import {
  getBaseOrCladMetalOptions,
  getImperialValue,
  getMetricValue,
  getProposalTypeOptionsByMetalName,
  getReverseIndex,
} from '@components/popover/add-quote-item-popover/multi-shot/multi-shot-utils';
import {
  MultiShotMetal,
  MultiShotState,
  MultiShotVariants,
} from '@components/popover/add-quote-item-popover/multi-shot/useMultiShotReducer';
import SelectionContainer from '@components/selection-container/selection-container';
import {
  ManufacturingSites,
  MeasurementSystems,
  MetalProposalTypes,
  MetalTypes,
  QuoteLineItemFieldUnitTypes,
  QuoteLineItemValueTypes,
  RawMaterialUsage,
  SearchFields,
  SelectionTypes,
} from '@constants';
import { useDataState } from '@context/data-context';
import { useLocalUom } from '@hooks/useLocalUom';
import { RawMaterial } from '@interfaces/raw-material';
import { IonCard, IonCardHeader, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import * as React from 'react';
import {
  cstProvidedColSize,
  metalColSize,
  positionColSize,
  proposalTypeColSize,
  thicknessColSize,
  thicknessTypeColSize,
} from './multi-shot';

export interface MetalChangeHandlerParams {
  key: keyof MultiShotMetal;
  value: any;
  index?: number;
}

interface MetalRowProps {
  multiShotMetalsState: MultiShotState;
  multiShotType: MultiShotVariants;
  metal: MultiShotMetal;
  uom: MeasurementSystems;
  manufacturingSite: ManufacturingSites;
  handleMetalChange: (params: MetalChangeHandlerParams) => void;
  handleRemovePlate?: (index: number) => void;
  isBaseMetal?: boolean;
  handleMetalChangeProp: (metal: RawMaterial, usage: RawMaterialUsage) => void;
  setBaseMetalProposalType: (proposalType: string) => void;
  setCladMetalProposalType: (proposalType: string) => void;
  setFinishedCladMetalThickness: (thickness: number) => void;
  setFinishedBaseMetalThickness: (thickness: number) => void;
  setBaseMetalCustomerProvided: (customerProvided: boolean) => void;
  setCladMetalCustomerProvided: (customerProvided: boolean) => void;
  setBaseMetalType: (type: string) => void;
  setCladMetalType: (type: string) => void;
  isInterlayerMetal?: boolean; // used to determine what subset of clads to use when we get that info from the bizzz🐝
  useReverseIndices?: boolean; // may be coming in reverse order, so we may need to reverse the index
  index?: number; // used for clads and interlayers arrays
}

// MetalRow renders the metal row card, consisting of the Position, Metal, Proposal Type, Thickness, and Thickness Type
// Clads (and thus Interlayers) use index to determine which clad metal they are, Bases do not.
export const MetalRow = ({
  metal,
  handleMetalChange,
  handleRemovePlate,
  uom,
  manufacturingSite,
  multiShotMetalsState,
  index,
  handleMetalChangeProp,
  setBaseMetalProposalType,
  setCladMetalProposalType,
  setFinishedCladMetalThickness,
  setFinishedBaseMetalThickness,
  setBaseMetalCustomerProvided,
  setCladMetalCustomerProvided,
  setBaseMetalType,
  setCladMetalType,
  isBaseMetal = false,
  isInterlayerMetal = false,
  useReverseIndices = false,
}: MetalRowProps) => {
  const {
    dataState: { rawMaterials = [] },
  } = useDataState();

  const { localUom } = useLocalUom(uom);

  // get the subset of clads to use, either the interlayer clads or the regular clads
  const metalsArray = isInterlayerMetal ? multiShotMetalsState.interlayerMetals : multiShotMetalsState.cladMetals;
  // get the index of the clad, either the index of the interlayer or the index of the clad, it may be mapped and coming in reverse order
  const cardIndex = useReverseIndices ? getReverseIndex(metalsArray, index) : index;
  // is this the first layer, either the first interlayer or the first clad
  const isInitialLayer = useReverseIndices ? getReverseIndex(metalsArray, index) === 0 : index === 0;
  // does this card belong to a multi clad arrangement, used to determine if we should show the remove button for interlayers
  const isDoubleClad = multiShotMetalsState.cladMetals.length > 1;

  // set up the label and value type for the metal, and string variables
  const cladType = isInterlayerMetal ? 'interlayer' : 'clad';
  const lowercaseMetal = isBaseMetal ? 'base' : cladType;

  const valueType = isBaseMetal ? QuoteLineItemValueTypes.BaseTK : QuoteLineItemValueTypes.CladTK;
  const cladsIndexLabel = isInterlayerMetal ? `Interlayer ${cardIndex + 1}` : `Clad Metal ${cardIndex + 1}`;
  // either a base or a clad metal label, interlayers are clads
  let cladsSingleLabel = 'Clad Metal';
  if (isInterlayerMetal) {
    cladsSingleLabel = 'Interlayer';
  }
  const baseSingleLabel = 'Base Metal';
  // either it's an indexed label or a single label, ie: Clad Metal 2
  const cladMetalLabel = metalsArray.length > 1 ? cladsIndexLabel : cladsSingleLabel;
  // either it's a base, or a clad/interlayer label
  const label = isBaseMetal ? baseSingleLabel : cladMetalLabel;

  // get the options for the metal selection
  const metalOptions: RawMaterial[] = getBaseOrCladMetalOptions(
    rawMaterials,
    isBaseMetal ? RawMaterialUsage.Base : RawMaterialUsage.Clad,
    manufacturingSite
  );

  // get the options for the proposal type selection
  const proposalTypeOptions = getProposalTypeOptionsByMetalName(metal?.metal, rawMaterials, manufacturingSite) ?? [];

  // validate that the proposalType is valid, given the new metalName
  const validateProposalType = (metalName: string, proposalType: string) => {
    const proposalTypeOptions = getProposalTypeOptionsByMetalName(metalName, rawMaterials, manufacturingSite);

    if (!proposalTypeOptions.includes(proposalType as MetalProposalTypes) || !proposalType) {
      console.warn(
        `Invalid existing proposal type, "${proposalType} does not exist on ${metalName}, changing value to "${proposalTypeOptions[0]}"`
      );

      if (isBaseMetal) {
        setBaseMetalProposalType(proposalTypeOptions[0]);
        handleMetalChange({ key: 'proposalType', value: proposalTypeOptions[0] });
      } else {
        setCladMetalProposalType(proposalTypeOptions[0]);
        handleMetalChange({ key: 'proposalType', value: proposalTypeOptions[0], index: cardIndex });
      }
    }
  };

  const handleBaseMetalChange = (mtl: { name: string }) => {
    try {
      handleMetalChange({ key: 'metal', value: mtl?.name });
      const baseMetal = rawMaterials.find(
        (rawMaterial) =>
          rawMaterial.name === mtl?.name &&
          rawMaterial.usage === RawMaterialUsage.Base &&
          rawMaterial.dataAreaId.includes(manufacturingSite)
      );
      handleMetalChangeProp(baseMetal, RawMaterialUsage.Base);
      validateProposalType(mtl?.name, metal?.proposalType);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCladMetalChange = (mtl: { name: string }, index: number) => {
    try {
      handleMetalChange({ key: 'metal', value: mtl?.name, index: index });
      const shouldWeFireTheCladMetalChangePropHandler =
        cardIndex === 0 ? isInterlayerMetal || (isDoubleClad && !isInterlayerMetal) : false;

      if (shouldWeFireTheCladMetalChangePropHandler) {
        const cladMetal = rawMaterials.find(
          (rm) =>
            rm.name === mtl?.name && rm.usage === RawMaterialUsage.Clad && rm.dataAreaId.includes(manufacturingSite)
        );
        handleMetalChangeProp(cladMetal, RawMaterialUsage.Clad);
      }
      validateProposalType(mtl?.name, metal?.proposalType);
    } catch (error) {
      console.error(error);
    }
  };

  const handleBaseProposalTypeChange = (type: { label: string }) => {
    handleMetalChange({ key: 'proposalType', value: type.label });
    setBaseMetalProposalType(type.label);
  };

  const handleCladProposalTypeChange = (type: { label: string }, index: number) => {
    handleMetalChange({ key: 'proposalType', value: type.label, index: index });
    setCladMetalProposalType(type.label);
  };

  const handleBaseMetalThicknessChange = (value: number) => {
    handleMetalChange({ key: 'thickness', value });
    setFinishedBaseMetalThickness(value);
  };

  const handleCladMetalThicknessChange = (value: number, index: number) => {
    handleMetalChange({ key: 'thickness', value, index: index });
    setFinishedCladMetalThickness(value);
  };

  const handleBaseMetalTypeChange = (type: { label: string }) => {
    handleMetalChange({ key: 'metalType', value: type.label });
    setBaseMetalType(type.label);
  };

  const handleCladMetalTypeChange = (type: { label: string }, index: number) => {
    handleMetalChange({ key: 'metalType', value: type.label, index: index });
    setCladMetalType(type.label);
  };

  //TODO: need handlers for customer provided checkboxes
  const handleBaseMetalCustomerProvidedChange = (customerProvided: boolean) => {
    handleMetalChange({ key: 'customerProvided', value: customerProvided });
    setBaseMetalCustomerProvided(customerProvided);
  };

  const handleCladMetalCustomerProvidedChange = (customerProvided: boolean, index: number) => {
    handleMetalChange({ key: 'customerProvided', value: customerProvided, index: index });
    setCladMetalCustomerProvided(customerProvided);
  };

  return (
    <IonCard className='add-item-quote-line-item-card'>
      <IonCardHeader class={'add-item-quote-line-item-card-header'}>
        <IonGrid>
          <IonRow class='add-item-quote-line-item-row'>
            {/* Metal - BASE, CLAD, or INTERLAYER */}
            <IonCol size={positionColSize} class={'add-item-quote-line-items-col'}>
              <LineItemField valueType={QuoteLineItemValueTypes.SizingQuantity} value={label} readOnly={true} />
            </IonCol>
            {/* Metal */}
            <IonCol size={metalColSize} class={'add-item-quote-line-items-col'}>
              <SelectionContainer
                type={SelectionTypes.SearchableDropdown}
                noneFoundText={`No ${lowercaseMetal} metals found`}
                placeholder={`Select ${lowercaseMetal} metal`}
                options={metalOptions}
                suggestionDisplayFields={[SearchFields.MetalName]}
                onChange={(mtl: { name: string }) => {
                  isBaseMetal ? handleBaseMetalChange(mtl) : handleCladMetalChange(mtl, cardIndex);
                }}
                value={metal?.metal}
              />
            </IonCol>
            {/* Proposal Type */}
            <IonCol size={proposalTypeColSize} class={'add-item-quote-line-items-col'}>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select type'
                options={[...new Set(proposalTypeOptions)]}
                style={{ marginTop: '10px', width: '110px' }}
                onChange={(type) =>
                  isBaseMetal ? handleBaseProposalTypeChange(type) : handleCladProposalTypeChange(type, cardIndex)
                }
                value={metal?.proposalType}
                isDisabled={!metal?.metal}
              />
            </IonCol>
            {/* Thickness */}
            <IonCol
              class={'add-item-quote-line-items-col'}
              size={thicknessColSize}
              style={{
                paddingTop: '8px',
              }}
            >
              <LineItemField
                valueType={valueType}
                imperialValue={getImperialValue(parseFloat(metal?.thickness), localUom)}
                metricValue={getMetricValue(parseFloat(metal?.thickness), localUom)}
                unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                readOnly={false}
                ignoreRecalculateFields
                fieldChangedCB={(value) =>
                  isBaseMetal ? handleBaseMetalThicknessChange(value) : handleCladMetalThicknessChange(value, cardIndex)
                }
                uom={uom}
              />
            </IonCol>
            {/* Thickness Type */}
            <IonCol class={'add-item-quote-line-items-col'} size={thicknessTypeColSize}>
              <SelectionContainer
                valueType={QuoteLineItemValueTypes.BaseType}
                type={SelectionTypes.DropDown}
                options={Object.values(MetalTypes)}
                style={{ marginTop: '10px', width: '100px' }}
                onChange={(type) =>
                  isBaseMetal ? handleBaseMetalTypeChange(type) : handleCladMetalTypeChange(type, cardIndex)
                }
                value={metal?.metalType}
              />
            </IonCol>
            {/* Customer Provided */}
            <IonCol class={'add-item-quote-line-items-col'} size={cstProvidedColSize}>
              <CheckboxItem
                style={{ width: 'fit-content', marginTop: '12px' }}
                onIonChange={(customerProvided) =>
                  isBaseMetal
                    ? handleBaseMetalCustomerProvidedChange(customerProvided)
                    : handleCladMetalCustomerProvidedChange(customerProvided, cardIndex)
                }
                checked={metal?.customerProvided}
                skipTab={true}
              />
            </IonCol>
            {/* The Interlayers have Remove Buttons */}
            {/* Don't show interlayer[0] if isDoubleClad */}
            {isInterlayerMetal && (!isInitialLayer || isDoubleClad) && (
              <IonCol size='0.5' class='add-item-quote-line-items-col'>
                <div
                  className='close-line-container'
                  onClick={() => {
                    handleRemovePlate(cardIndex);
                  }}
                >
                  <IonIcon class='close-line-icon' src={closeCircle} />
                </div>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonCardHeader>
    </IonCard>
  );
};
