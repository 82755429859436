import React from 'react';

const style: React.CSSProperties =  {
  pointerEvents: 'none',
  opacity: 0.4
};

function Disabled({ checks, children }) {
  return (
    <div style={checks.some((check: boolean) => check) ? style : null} >
      {children}
    </div>
  );
}

export default Disabled;

/* usage example:
<Disabled checks={[isLoading, syncing]}> // checks is an array of booleans
  {children}
</Disabled>
*/