import Badge from '@components/badge/badge';
import { DocumentActions, DocumentsColumnHeadings, FileTypes, UserType } from '@constants';
import { IonCard, IonCardHeader, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { useDocuments } from '@services/documents/documents-service';
import { usePlatform } from '@services/platform-service';
import classNames from 'classnames';
import * as React from 'react';
import docFileIcon from '@assets/icon/filetype_DOC.svg';
import docFileIconWhite from '@assets/icon/filetype_DOC_white.svg';
import dwgFileIcon from '@assets/icon/filetype_DWG.svg';
import dwgFileIconWhite from '@assets/icon/filetype_DWG_white.svg';
import logFileIcon from '@assets/icon/filetype_LOG.svg';
import logFileIconWhite from '@assets/icon/filetype_LOG_white.svg';
import miscFileIcon from '@assets/icon/filetype_MISC.svg';
import miscFileIconWhite from '@assets/icon/filetype_MISC_white.svg';
import msgFileIcon from '@assets/icon/filetype_MSG.svg';
import msgFileIconWhite from '@assets/icon/filetype_MSG_white.svg';
import pdfFileIcon from '@assets/icon/filetype_PDF.svg';
import pdfFileIconWhite from '@assets/icon/filetype_PDF_white.svg';
import xlsFileIcon from '@assets/icon/filetype_XLS.svg';
import xlsFileIconWhite from '@assets/icon/filetype_XLS_white.svg';
import loadingSpinner from '@assets/icon/spinner_button_white_transparent_24.png';
import LineItemActions from '@components/line-item-actions/line-item-actions';
import './document-line.scss';
import { useLastViewed } from '@services/last-viewed/last-viewed-service';
import moment from 'moment';
import { useDarkMode } from '@hooks/useDarkMode';
import { useEnvironment } from '@services/environment-service';

const DocumentLine = (props) => {
  const documents = useDocuments();
  const lastViewed = useLastViewed();
  const environment = useEnvironment();
  const platform = usePlatform();
  const { darkMode } = useDarkMode();

  const [documentHeadings] = React.useState(DocumentsColumnHeadings);
  const [loading, setLoading] = React.useState(false);

  // Returns correct icon for the file type of the document
  const getFileTypeIconFromDocumentExtension = (fileExtension: string) => {
    switch(fileExtension.toUpperCase()) {
    case FileTypes.PDF:
      return !darkMode ? pdfFileIcon : pdfFileIconWhite;
    case FileTypes.DOC:
      return !darkMode ? docFileIcon : docFileIconWhite;
    case FileTypes.XLS:
      return !darkMode ? xlsFileIcon : xlsFileIconWhite;
    case FileTypes.DWG:
      return !darkMode ? dwgFileIcon : dwgFileIconWhite;
    case FileTypes.LOG:
      return !darkMode ? logFileIcon : logFileIconWhite;
    case FileTypes.MSG:
      return !darkMode ? msgFileIcon : msgFileIconWhite;
    default:
      return !darkMode ? miscFileIcon : miscFileIconWhite;
    }
  };

  // Retrieves the document data from dropbox and converts it into a blob
  const retrieveDocumentData = async () => {
    const documentInfo = await documents.downloadDropboxDocument(props.document.Dropbox_Id__c);
    const documentBytes = Uint8Array.from(atob(documentInfo.file), c => c.charCodeAt(0));
    const documentBlob = new Blob([documentBytes], { type: documentInfo.contentType });
    const documentData = window.URL.createObjectURL(documentBlob);

    return documentData;
  };

  // Views document in new tab
  const viewDocument = async () => {
    lastViewed.updateItemLastViewed({ id: props.document.Id, lastViewed: new Date().toLocaleString() });
    setLoading(true);
    window.open(await retrieveDocumentData());
    setLoading(false);
    props.setDocumentUpdated(true);
  };

  // Downloads document onto device
  const downloadDocument = async () => {
    lastViewed.updateItemLastViewed({ id: props.document.Id, lastViewed: new Date().toLocaleString() });
    setLoading(true);
    const link = document.createElement('a');
    link.download = `${props.document.Name}`;
    link.href = await retrieveDocumentData();
    document.body.appendChild(link);
    link.click();
    setLoading(false);
    props.setDocumentUpdated(true);
  };

  // Deletes the document from dropbox
  const deleteDocument = async () => {
    setLoading(true);
    await documents.deleteDropboxDocument(props.document.Dropbox_Id__c);
    props.setDocumentUpdated(true);
  };

  // Returns the user type from environment
  const userType = () => {
    return environment?.userType();
  };

  // Returns internal view flag from environment
  const internalView = () => {
    return environment?.internalView();
  };

  return (
    <IonCard className="document-card" data-testid={'QADocumentLine_' + props.document.Name}>
      <div className={classNames({ 'deleting-overlay': true, 'hidden': !loading ? true : false })}>
        <img className='deleting' src={loadingSpinner}/>
      </div>
      <div className={classNames({ 'inactive-overlay': !props.showDocumentActions ? true : false })}></div>
      <IonCardHeader onClick={props.showDocumentActions ? () => viewDocument() : undefined } class={classNames({ 'document-card-header': true, 'even': props.documentIndex % 2 === 0, 'odd': props.documentIndex % 2 !== 0 })}>
        <Badge text={props.document.badgeText}/>
        <IonGrid>
          <IonRow class="document-row">
            <IonCol class="column-left" size={ !platform.isMobile() ? '10' : '11' }>
              <span className="documents-text mobile-only name-label">{ documentHeadings.Name }</span>
              <div className='document-name-container'>
                <IonIcon class='file-type-icon' src={getFileTypeIconFromDocumentExtension(props.document.FileExtension__c)}/>
                <span className="document-summary-text" data-testid="QADocumentLineName">{ props.document.Name }</span>
              </div>
            </IonCol>
            {/*<IonCol class="column-left description-col" size='6'>
              <span className="document-summary-text" data-testid="QAOrderDetailPageDocumentDescription">{ props.document.Description }</span>
            </IonCol>*/}
            <IonCol class="column-left" size={ !platform.isMobile() ? '2' : '11' }>
              <span className="documents-text mobile-only date-added-label">{ documentHeadings.DateAdded }</span>
              <span className="document-summary-text" data-testid="QADocumentLineDateAdded">{ props.document.CreatedDate ? moment(props.document.CreatedDate).format('L') : props.document.CreatedDate }</span>
            </IonCol>
            <LineItemActions
              visible={props.showDocumentActions}
              actions={userType() === UserType.Sales || internalView() ?
                [{
                  label: DocumentActions.Download,
                  actionToPerform: downloadDocument
                },{
                  label: DocumentActions.View,
                  actionToPerform: viewDocument
                },{
                  label: DocumentActions.Delete,
                  actionToPerform: deleteDocument
                }] :
                [{
                  label: DocumentActions.Download,
                  actionToPerform: downloadDocument
                },{
                  label: DocumentActions.View,
                  actionToPerform: viewDocument
                }]}
              data-testid="QADocumentLineItemActions"
            >
            </LineItemActions>
          </IonRow>
        </IonGrid>
      </IonCardHeader>
    </IonCard>
  );
};

export default DocumentLine;