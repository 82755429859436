import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import * as React from 'react';
import './checkbox-item.scss';

const CheckboxItem = (props) => {
  return (
    <IonItem class='checkbox-item' lines="none"  style={props.style}>
      <IonCheckbox
        class='checkbox'
        style={props.checkboxStyle}
        slot="start"
        color="primary"
        checked={props.checked}
        onIonChange={(e) => props.onIonChange(e.detail.checked)}
        disabled={props.disabled}
        data-testid={'QACheckboxItem_' + props.label}
      />
      <IonLabel class='checkbox-label'>{props.label}</IonLabel>
    </IonItem>
  );
};

export default CheckboxItem;