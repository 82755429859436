import React from 'react';
import { IonLabel, IonCardHeader } from '@ionic/react';
import './notifications-popover.scss';
import NotificationsList from '@components/notifications-list/notifications-list';
import { useHistory } from 'react-router-dom';
import { Notification } from '@interfaces/notification';
import { MappedNotification } from '@shared/types';

interface Props {
  unmappedNotifications: Notification[];
  loadNotifications: () => void;
  displayedNotifications: MappedNotification[];
}

const NotificationsPopover = (props: Props) => {
  const history = useHistory();

  // Navigates to the notifications page
  const navigateToNotificationsPage = () => {
    history.push('/notifications');
  };
  
  return (
    <div className="popup-container" data-testid="QANotificationsPopover">
      <div className="notifications-container">
        <IonCardHeader class='header-container'>
          <div className='top'>
            <IonLabel class='title'>Notifications</IonLabel>
            <IonLabel class='link' onClick={() => navigateToNotificationsPage()} data-testid="QANotificationsPopoverViewAllNotifications">View All Notifications</IonLabel>
          </div>
        </IonCardHeader>
        <NotificationsList
          unmappedNotifications={props.unmappedNotifications}
          loadNotifications={props.loadNotifications}
          displayedNotifications={props.displayedNotifications}
        />
      </div>
    </div>
  );
};

export default NotificationsPopover;