import React from 'react';
import { IonButton, IonCol, IonGrid, IonImg, IonLabel, IonRow } from '@ionic/react';
import './leak-test-popover.scss';
import { SelectionTypes, LeakTestSpecifications, QuoteLineItemValueTypes } from '@constants';
import SelectionContainer from '@components/selection-container/selection-container';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { getSelectionOptions, getSelections } from '@shared/quote-utils';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { AdvSpecsAssemblyGroupDetailFieldsToUpdate, AdvSpecsMetalComboDetailFieldsToUpdate } from '@shared/types';

const LeakTestPopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
    showPopover: boolean;
    event: any;
  }>>,
  detailFieldsToUpdate: AdvSpecsMetalComboDetailFieldsToUpdate | AdvSpecsAssemblyGroupDetailFieldsToUpdate,
}) => {
  const { dataState } = useDataState();
  const { selections } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  
  const { darkMode } = useDarkMode();

  const [detailFieldsToUpdate] = React.useState({
    leakTestGas: props.detailFieldsToUpdate.leakTestGas || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.LeakTestGas).default,
    leakTestReqt: props.detailFieldsToUpdate.leakTestReqt || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.LeakTestReqt).default,
    leakTestPressure: props.detailFieldsToUpdate.leakTestPressure || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.LeakTestPressure).default,
    leakTestValue: props.detailFieldsToUpdate.leakTestValue || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.LeakTestValue).default,
  });

  const [savedLeakGas, setSavedLeakGas] = React.useState(detailFieldsToUpdate.leakTestGas);
  const [savedLeakValue, setSavedLeakValue] = React.useState(undefined);
  const [savedLeakPressure, setSavedLeakPressure] = React.useState(detailFieldsToUpdate.leakTestPressure);
  const [textLeakValue, setTextLeakValue] = React.useState(undefined);
  const [textLeakValueNumber, setTextLeakValueNumber] = React.useState(undefined);
  const [textLeakGas, setTextLeakGas] = React.useState(undefined);
  const [textLeakPressure, setTextLeakPressure] = React.useState(undefined);
  
  React.useEffect(() => {

    if (detailFieldsToUpdate.leakTestGas != LeakTestSpecifications.Helium && detailFieldsToUpdate.leakTestGas != LeakTestSpecifications.Air) {
      setSavedLeakGas(LeakTestSpecifications.Other);
      setTextLeakGas(detailFieldsToUpdate.leakTestGas);
    }
   
   if (detailFieldsToUpdate.leakTestValue == LeakTestSpecifications.OneByTen) {
       setSavedLeakValue(LeakTestSpecifications.OneByTen);
    } else if (detailFieldsToUpdate.leakTestValue.includes(LeakTestSpecifications.OneByTen, 0)) {
      setSavedLeakValue(LeakTestSpecifications.OneByTen);
      const valueIndex = detailFieldsToUpdate.leakTestValue.indexOf('A');
      const valueNum = detailFieldsToUpdate.leakTestValue.substring(7, valueIndex);
      setTextLeakValueNumber(valueNum.trim());
    } else {
      setSavedLeakValue(LeakTestSpecifications.Other);
      setTextLeakValue(detailFieldsToUpdate.leakTestValue);
     }

    if (detailFieldsToUpdate.leakTestPressure != LeakTestSpecifications.Vacuum) {
      setSavedLeakPressure(LeakTestSpecifications.Other);
      setTextLeakPressure(detailFieldsToUpdate.leakTestPressure);
    }

  });

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const updateDetails = () => {
    props.detailFieldsToUpdate.leakTestGas = detailFieldsToUpdate.leakTestGas;
    props.detailFieldsToUpdate.leakTestReqt = detailFieldsToUpdate.leakTestReqt;
    props.detailFieldsToUpdate.leakTestValue = detailFieldsToUpdate.leakTestValue;
    props.detailFieldsToUpdate.leakTestPressure = detailFieldsToUpdate.leakTestPressure;
  };

  return (
    <div className="popover leak-test">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='leak-test-title' data-testid={'QALeakTestPopoverLeakTestHeader'}>Leak Test</IonLabel>
      </div>
      <hr/>
      <div className='selections'>
        <IonGrid>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label'>
                Gas
              </IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                listStyle={{ background: 'transparent' }}
                itemStyle={{ ['--background']: 'transparent' }}
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.LeakTestGas).options}
                style={{ marginLeft: '0px' }}
                value={savedLeakGas}
                onIonChange={leakTestGas =>
                  (leakTestGas != LeakTestSpecifications.Other ? detailFieldsToUpdate.leakTestGas = leakTestGas : '' )
                }
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                style={{ width: '310px', marginTop: '4px' }}
                placeholder='Specify Other Detail...'
                value={textLeakGas ? textLeakGas : ''}
                onBlur={detail =>
                 (detailFieldsToUpdate.leakTestGas = detail)
                }
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label'>
                Reqt
              </IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                listStyle={{ background: 'transparent' }}
                itemStyle={{ ['--background']: 'transparent' }}
                onIonChange={leakTestReqt =>
                  (detailFieldsToUpdate.leakTestReqt = leakTestReqt)
                }
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.LeakTestReqt).options}
                value={detailFieldsToUpdate.leakTestReqt}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label'>
                Value
              </IonLabel>
            </IonCol>
            <IonCol>
              <div className='flex-container'>
                <SelectionContainer
                  type={SelectionTypes.RadioButtons}
                  listStyle={{ background: 'transparent' }}
                  itemStyle={{ ['--background']: 'transparent' }}
                  options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.LeakTestValue).options}
                  value={savedLeakValue}
                  extraComponents={[
                    <>
                      <SelectionContainer
                        type={SelectionTypes.TextInput}
                        style={{ width: '48px', marginRight: '5px', marginTop: '5px' }}
                        placeholder='-7'
                        value={textLeakValueNumber ? textLeakValueNumber : ''}
                        regex={/^^[-+]?\d*$/}
                        onBlur={leakTestValue =>
                          (detailFieldsToUpdate.leakTestValue = LeakTestSpecifications.OneByTen + ' ' + leakTestValue + ' ' + LeakTestSpecifications.ATMCC)
                        }
                      />
                      <IonLabel class='unit-label'>ATM-CC / SEC</IonLabel>
                    </>
                  ]}
                />
              </div>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                style={{ width: '250px', marginRight: '15px', marginTop: '4px' }}
                placeholder='Specify Other Detail...'
                value={textLeakValue ? textLeakValue : ''}
                onBlur={detail =>
                 (detailFieldsToUpdate.leakTestValue = detail)
                }
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label'>
                Pressure
              </IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                listStyle={{ background: 'transparent' }}
                itemStyle={{ ['--background']: 'transparent' }}
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.LeakTestPressure).options}
                onIonChange={leakTestPressure =>
                  (leakTestPressure != LeakTestSpecifications.Other ? detailFieldsToUpdate.leakTestPressure = leakTestPressure : '')
                }
                value={savedLeakPressure}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                style={{ width: '368px', marginTop: '4px' }}
                placeholder='Specify Other Detail...'
                value={textLeakPressure ? textLeakPressure : ''}
                onBlur={detail =>
                 (detailFieldsToUpdate.leakTestPressure = detail)
                }
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <hr className='bottom-rule'/>
      <div className="cta-button-container">
        <IonButton color="primary" class="save-button" data-testid="QALeakTestPopoverSaveButton"
        onClick={() => {
          updateDetails();
          dismissModal();
        }}
        >
          Save
        </IonButton>
        <IonButton color="secondary" class="cancel-button" onClick={() => dismissModal()}>Cancel</IonButton>
      </div>
    </div>
  );
};

export default LeakTestPopover;