import React, { useState, useMemo, useEffect, useRef } from 'react';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import AppInput from '@components/app-input/app-input';
import
  AppLineItemColumns, { AppLineItemComponent }
from '@components/app-line-item-columns/app-line-item-columns';
import AppCheckboxItem from '@components/app-checkbox-item/app-checkbox-item';

import Section from './section';
import LineItem from '../line-item/line-item';
import AppRadioButtons from '@components/app-radio-buttons/app-radio-buttons';
import { IonButton } from '@ionic/react';
import AppTextarea from '@components/app-textarea/app-textarea';
import AppDropdownSelection from '@components/app-dropdown/app-dropdown-selection';
import { MetalPurchaseRequirementsGroups, PreBondAutoSelect, QuoteLineItemValueTypes, RawMaterialUsage } from '@constants';
import { getDefaultMetalPurchaseRequirement } from '@shared/quote-utils';
import { RawMaterial } from '@interfaces/raw-material';
import { FormKeys } from '../advance-specification-form-util';
import PrebondImpacts from '../modals/prebond-impacts';
import { useDataState } from '@context/data-context';

// Customer Metal Supplier Restrictions
const CustomerMetalSupplierRestrictionsDropdown = (props) => {
  const { dataState } = useDataState();
  const { rawMaterials } = dataState;
  const [excludeValues, setExcludeValues] = useState([]);
  const { areaId, baseMetal, value, disabled, onChange } = props;

  const init = () => {
    if (rawMaterials.length === 0) {
      return;
    }

    const material = rawMaterials.find((rawMaterial: RawMaterial) =>
        rawMaterial.name === baseMetal &&
        rawMaterial.usage === RawMaterialUsage.Base &&
        rawMaterial.dataAreaId.includes(areaId)
    )

    const defaultMetalSupplierRestrictions = getDefaultMetalPurchaseRequirement(material, areaId, MetalPurchaseRequirementsGroups.SupplierRestrictions);

    if (!excludeValues.includes(defaultMetalSupplierRestrictions)) {
      setExcludeValues([defaultMetalSupplierRestrictions]);
    }
  }

  useEffect(() => {
    init();
  }, [rawMaterials, baseMetal, value])

  useEffect(() => {
    init();
  }, []);

  return <>
    <AppDropdownSelection
      areaId={areaId}
      valueType={QuoteLineItemValueTypes.BaseMetalPurchaseRequirements}
      excludeValues={excludeValues}
      attrLabel='description'
      attrValue='description'
      value={value}
      firstOptionDefault={false}
      onChange={onChange}
      disabled={disabled}
    />
  </>
};

const BaseMetal = (prop) => {
  const {
    form,
    updateForm,
    updateFormByPayload,
    areaId,
    segmentKey,
  } = prop;
  const [ openPrebondImpacts, setOpenPrebondImpacts ] = useState(false);

  const onToggleRequiredField = (key) => {
    return (value) => {
      updateForm(key, value);
    }
  }

  useEffect(() => {
    const values = {
      [FormKeys.BaseMetalSpecEdition]: form[FormKeys.RequiredBaseMetalSpecEdAd] ? form[FormKeys.BaseMetalSpecEdition] : null,
      [FormKeys.BaseMetalSpecAddendum]: form[FormKeys.RequiredBaseMetalSpecEdAd] ? form[FormKeys.BaseMetalSpecAddendum] : null,
    };

    updateFormByPayload(values);
  }, [form[FormKeys.RequiredBaseMetalSpecEdAd]])

  return <Section title="Base Metal" {...prop}>
    <LineItem label='NC Heat Treatment as Supplied' specifyKey={`${segmentKey}.headTreatmentAsSupplied`}>
      <AppLineItemColumns components={[
        {
          key: 'headTreatmentAsSupplied-dropdown',
          size: 4,
          component: <AppDropdownSelection
            areaId={areaId}
            valueType={QuoteLineItemValueTypes.NCBaseHeatTreatmentasSupplied}
            onChange={({value}) => updateForm(FormKeys.NCBaseHeatTreatmentasSupplied, value)}
            value={form[FormKeys.NCBaseHeatTreatmentasSupplied]}
          />
        },
        {
          key: 'headTreatmentAsSupplied-input',
          size: 8,
          component: <AppInput
            onChange={(value) => updateForm(FormKeys.NCBaseHeatTreatmentasSuppliedNotes, value)}
            value={form[FormKeys.NCBaseHeatTreatmentasSuppliedNotes]}
            placeholder='Additional Note'
          />
        }
      ]} />
    </LineItem>

    <LineItem label='Finished Good Heat Treatment' specifyKey={`${segmentKey}.finishedGoodHeadTreatment`}>
      <AppLineItemColumns components={[
        {
          key: 'finishedGoodHeadTreatment-dropdown',
          size: 4,
          component: <AppDropdownSelection
            areaId={areaId}
            valueType={QuoteLineItemValueTypes.FinishedGoodHeatTreatment}
            onChange={({value}) => updateForm(FormKeys.FinishedGoodHeatTreatment, value)}
            value={form[FormKeys.FinishedGoodHeatTreatment]}
          />
        },
        {
          key: 'finishedGoodHeadTreatment-input',
          size: 8,
          component: <AppInput
            placeholder='Additional Note'
            onChange={(value) => updateForm(FormKeys.FinishedGoodHeatTreatmentNotes, value)}
            value={form[FormKeys.FinishedGoodHeatTreatmentNotes]}
          />
        }
      ]} />
    </LineItem>

    <LineItem label='NC Chemistry Restrictions for Bonding' specifyKey={`${segmentKey}.chemistryRegistrationForBonding`}>
      <AppLineItemColumns components={[
        {
          key: 'chemistryRegistrationForBonding-dropdown',
          size: 4,
          component: <AppDropdownSelection
            areaId={areaId}
            valueType={QuoteLineItemValueTypes.NCBaseChemistryRestrictionsforBonding}
            onChange={({value}) => updateForm(FormKeys.NCBaseChemistryRestrictionsforBonding, value)}
            value={form[FormKeys.NCBaseChemistryRestrictionsforBonding]}
          />
        },
        {
          key: 'chemistryRegistrationForBonding-input',
          size: 8,
          component: <AppInput
            placeholder='Additional Note'
            onChange={(value) => updateForm(FormKeys.NCBaseChemistryRestForBondingNotes, value)}
            value={form[FormKeys.NCBaseChemistryRestForBondingNotes]}
          />
        }
      ]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem label="Preclad Base Metal UT"
        checked={form[FormKeys.RequiredPrecladBaseMetalUT]}
        onChange={onToggleRequiredField(FormKeys.RequiredPrecladBaseMetalUT)}
      />}
      specifyKey={`${segmentKey}.precladBaseMetalUT`}
    >
      <AppLineItemColumns components={[
        {
          key: 'precladBaseMetalUT-dropdown',
          size: 4,
          component: <AppDropdownSelection
            valueType={QuoteLineItemValueTypes.PrecladBaseMetalUTOptions}
            disabled={!form[FormKeys.RequiredPrecladBaseMetalUT]}
            areaId={areaId}
            onChange={({value}) => updateForm(FormKeys.PrecladBaseMetalUT, value)}
            value={form[FormKeys.PrecladBaseMetalUT]}
          />
        },
        {
          key: 'precladBaseMetalUT-input',
          size: 8,
          component: <AppInput
            disabled={!form[FormKeys.RequiredPrecladBaseMetalUT]}
            placeholder='Additional Note'
            onChange={(value) => updateForm(FormKeys.PrecladBaseMetalUTNotes, value)}
            value={form[FormKeys.PrecladBaseMetalUTNotes]}
          />
        },
      ]} />
      <AppLineItemColumns components={[{
        key: 'precladBaseMetalUT-radio',
        component: <AppRadioButtons
          name={FormKeys.PrecladBaseMetalUTType}
          disabled={!form[FormKeys.RequiredPrecladBaseMetalUT]}
          onChange={(value) => updateForm(FormKeys.PrecladBaseMetalUTType, value)}
          selected={form[FormKeys.PrecladBaseMetalUTType]}
          defaultValue='Performed by Vendor'
          options={[ 'Performed by Vendor', 'Performed by NobelClad']}
        />
      }]} />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        label="Prebond Impacts"
        checked={form[FormKeys.PrebondImpacts]}
        readOnly={PreBondAutoSelect.includes(form[FormKeys.CustomerSpecifiedImpactTestToBePerformed])}
        onChange={(value) => {
          if (PreBondAutoSelect.includes(form[FormKeys.CustomerSpecifiedImpactTestToBePerformed])) {
            return;
          }
          return onToggleRequiredField(FormKeys.PrebondImpacts)(value);
        }}
      />}
      specifyKey={`${segmentKey}.prebondImpacts`}
    >

      <AppLineItemColumns components={[{
          key: 'prebondImpacts-button',
          component: <IonButton
            disabled={!form[FormKeys.PrebondImpacts]}
            color='secondary'
            size='small'
            className='specify-button'
            onClick={() => setOpenPrebondImpacts(true)}
          >Specify</IonButton>
        }]}
      />
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredCarbon]}
        onChange={onToggleRequiredField(FormKeys.RequiredCarbon)}
        label="Carbon <="
      />}
      specifyKey={`${segmentKey}.carbon`}
    >
      <AppLineItemColumns components={[{
          key: 'carbon-input',
          size: 2,
          component: <AppInput
            disabled={!form[FormKeys.RequiredCarbon]}
            onChange={(value) => updateForm(FormKeys.Carbon_Percent, value)}
            value={form[FormKeys.Carbon_Percent]}
            type="number" step="1"
          />
        }]}
      />
    </LineItem>
    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredSulphur]}
        onChange={onToggleRequiredField(FormKeys.RequiredSulphur)}
        label="Sulphur <="
      />}
      specifyKey={`${segmentKey}.sulphur`}
    >
      <AppLineItemColumns components={[{
          key: 'sulphur-input',
          size: 2,
          component: <AppInput
            disabled={!form[FormKeys.RequiredSulphur]}
            onChange={(value) => updateForm(FormKeys.Sulphur, value)}
            value={form[FormKeys.Sulphur]}
            type="number" step="1"
          />
        }]}
      />
    </LineItem>
    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredPhosphorus]}
        onChange={onToggleRequiredField(FormKeys.RequiredPhosphorus)}
        label="Phosphorus <="
      />}
      specifyKey={`${segmentKey}.phosphorus`}
    >
      <AppLineItemColumns components={[{
          key: 'phosphorus-input',
          size: 2,
          component: <AppInput
            disabled={!form[FormKeys.RequiredPhosphorus]}
            onChange={(value) => updateForm(FormKeys.Phosphorus_Percent, value)}
            value={form[FormKeys.Phosphorus_Percent]}
            type="number" step="1"
          />
        }]}
      />
    </LineItem>
    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredCarbonEquivalency]}
        onChange={onToggleRequiredField(FormKeys.RequiredCarbonEquivalency)}
        label="Carbon Equivalency per SA-20 S20 <="
      />}
      specifyKey={`${segmentKey}.carbon-equivalency`}
    >
      <AppLineItemColumns components={[{
          key: 'carbon-equivalency-input',
          size: 2,
          component: <AppInput
            onChange={(value) => updateForm(FormKeys.Carbon_Equivalency_Percent, value)}
            disabled={!form[FormKeys.RequiredCarbonEquivalency]}
            value={form[FormKeys.Carbon_Equivalency_Percent]}
            type="number" step="1"
          />
        }]}
      />
    </LineItem>
    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredJFactor]}
        onChange={onToggleRequiredField(FormKeys.RequiredJFactor)}
        label="J-Factor — SA-387 S62 <="
      />}
      specifyKey={`${segmentKey}.j-factor`}
    >
      <AppLineItemColumns components={[{
          key: 'j-factor-input',
          size: 2,
          component: <AppInput
            disabled={!form[FormKeys.RequiredJFactor]}
            onChange={(value) => updateForm(FormKeys.JFactorSA387S62, value)}
            value={form[FormKeys.JFactorSA387S62]}
            type="number" step="1"
          />
        }]}
      />
    </LineItem>
    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredXFactor]}
        onChange={onToggleRequiredField(FormKeys.RequiredXFactor)}
        label="X-Factor — SA-387 S62 (1.4 & 1.5) <="
      />}
      specifyKey={`${segmentKey}.x-factor`}
    >
      <AppLineItemColumns components={[{
          key: 'x-factor-input',
          size: 2,
          component: <AppInput
            disabled={!form[FormKeys.RequiredXFactor]}
            onChange={(value) => updateForm(FormKeys.XFactorSA387S62, value)}
            value={form[FormKeys.XFactorSA387S62]}
            type="number" step="1"
          />
        }]}
      />
    </LineItem>
    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredBaseCustomerChemRestriction]}
        onChange={onToggleRequiredField(FormKeys.RequiredBaseCustomerChemRestriction)}
        label="Customer Chemistry Restrictions"
      />}
      specifyKey={`${segmentKey}.customer-chemistry-restriction`}>
      <AppLineItemColumns components={[{
        key: 'customer-chemistry-restriction-input',
        size: 6,
        component: <AppInput
          disabled={!form[FormKeys.RequiredBaseCustomerChemRestriction]}
          onChange={(value) => updateForm(FormKeys.CustomerBaseChemistryRestrictions, value)}
          value={form[FormKeys.CustomerBaseChemistryRestrictions]}
        />
      }]}/>
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.VacuumDegassed]}
        onChange={onToggleRequiredField(FormKeys.VacuumDegassed)}
        label="Vacuum Degassed"
      />}
      specifyKey={`${segmentKey}.vacuum-degassed`}
    />
    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.CalciumTreatedSulfideShapeControl]}
        onChange={onToggleRequiredField(FormKeys.CalciumTreatedSulfideShapeControl)}
        label="Calcium Treated for Sulfide Shape Control"
    />}
      specifyKey={`${segmentKey}.calcium-treated`}
    />
    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.ThroughThicknessTensilePerSA770]}
        onChange={onToggleRequiredField(FormKeys.ThroughThicknessTensilePerSA770)}
        label="Through Thickness Tensile per SA-770"
      />}
      specifyKey={`${segmentKey}.through-thickness-tensile`}
    />

    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredCenterlineTensile]}
        onChange={onToggleRequiredField(FormKeys.RequiredCenterlineTensile)}
        label="Centerline Tensile"
      />}
      specifyKey={`${segmentKey}.centerline-tensile`}
    >
      <AppLineItemColumns components={[{
        key: 'centerline-tensile-dropdown',
        size: 3,
        component: <AppDropdownSelection
          areaId={areaId}
          disabled={!form[FormKeys.RequiredCenterlineTensile]}
          valueType={QuoteLineItemValueTypes.BaseTensileTestLocation}
          onChange={({value}) => updateForm(FormKeys.BaseTensileTestLocation, value)}
          firstOptionDefault={false}
          value={form[FormKeys.BaseTensileTestLocation]}
        />
      }]}/>
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.HotTensileTestPerSA20S7]}
        onChange={onToggleRequiredField(FormKeys.HotTensileTestPerSA20S7)}
        label="Hot Tensile Test per SA-20 S7"
      />}
      specifyKey={`${segmentKey}.hot-tensile`}
    >
      <AppLineItemColumns components={[
        {
          key: 'hot-tensile-input-number',
          size: 2,
          style: { display: 'flex', alignItems: 'center' },
          component: <AppInput
            disabled={!form[FormKeys.HotTensileTestPerSA20S7]}
            onChange={(value) => updateForm(FormKeys.HotTensileTestPerSA20S7Degrees, value)}
            value={form[FormKeys.HotTensileTestPerSA20S7Degrees]}
            type="number" step=".1"
          />
        },
        {
          key: 'hot-tensile-input-radio',
          size: 2,
          component: <AppRadioButtons
            name={FormKeys.HotTensileTestPerSA20S7UOM}
            disabled={!form[FormKeys.HotTensileTestPerSA20S7]}
            onChange={(value) => updateForm(FormKeys.HotTensileTestPerSA20S7UOM, value)}
            selected={form[FormKeys.HotTensileTestPerSA20S7UOM]}
            options={['F', 'C']}
          />
        },
        {
          key: 'hot-tensile-input-text',
          style: { display: 'flex', alignItems: 'center' },
          size: 5,
          component: <AppInput
            disabled={!form[FormKeys.HotTensileTestPerSA20S7]}
            onChange={(value) => updateForm(FormKeys.HotTensileTestPerSA20S7Notes, value)}
            value={form[FormKeys.HotTensileTestPerSA20S7Notes]}
          />
        },
      ]}/>
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredHICTesting]}
        onChange={onToggleRequiredField(FormKeys.RequiredHICTesting)}
        label="HIC Testing Required"
      />}
      key="hic-testing-required"
      specifyKey={`${segmentKey}.hic-testing-required`}
    >
      <AppLineItemColumns components={[{
        key: 'hic-testing-required-input',
        size: 7,
        component: <AppInput
          disabled={!form[FormKeys.RequiredHICTesting]}
          onChange={(value) => updateForm(FormKeys.HicTestNotes, value)}
          value={form[FormKeys.HicTestNotes]}
          placeholder="Specify Other Details"
        />
      }]}/>
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredAusteniticGrainSizeFiner]}
        onChange={onToggleRequiredField(FormKeys.RequiredAusteniticGrainSizeFiner)}
        label="Austenitic Grain Size Finer Than"
      />}
      specifyKey={`${segmentKey}.authenitic-grain-size`}
    >
      <AppLineItemColumns components={[{
        key: 'authenitic-grain-size-input',
        size: 2,
        component: <AppInput
          disabled={!form[FormKeys.RequiredAusteniticGrainSizeFiner]}
          onChange={(value) => updateForm(FormKeys.AusteniticGrainSizeFinerThanValue, value)}
          value={form[FormKeys.AusteniticGrainSizeFinerThanValue]}
          type="number"
        />
      }]}/>
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredBaseMetalSpecEdAd]}
        onChange={onToggleRequiredField(FormKeys.RequiredBaseMetalSpecEdAd)}
        label="Metal Spec Ed and Ad (if not latest)"
      />}
      specifyKey={`${segmentKey}.metal-spec-ed-ad`}
    >
      <AppLineItemColumns components={[
        {
          key: 'metal-spec-ed-ad-input1',
          size: 5,
          component: <AppInput
            disabled={!form[FormKeys.RequiredBaseMetalSpecEdAd]}
            onChange={(value) => updateForm(FormKeys.BaseMetalSpecEdition, value)}
            value={form[FormKeys.BaseMetalSpecEdition]}
          />
        },
        {
          key: 'metal-spec-ed-ad-input2',
          size: 5,
          component: <AppInput
            disabled={!form[FormKeys.RequiredBaseMetalSpecEdAd]}
            onChange={(value) => updateForm(FormKeys.BaseMetalSpecAddendum, value)}
            value={form[FormKeys.BaseMetalSpecAddendum]}
          />
        }
      ]}/>
    </LineItem>

    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredBaseCustomerMetalSupplierRestriction]}
        onChange={onToggleRequiredField(FormKeys.RequiredBaseCustomerMetalSupplierRestriction)}
        label="Customer Metal Supplier Restrictions"
      />}
      specifyKey={`${segmentKey}.customer-metal-supplier`}
    >
      <AppLineItemColumns components={[
        {
          key: 'customer-metal-supplier-dropdown',
          size: 5,
          component: <CustomerMetalSupplierRestrictionsDropdown
            disabled={!form[FormKeys.RequiredBaseCustomerMetalSupplierRestriction]}
            baseMetal={form[FormKeys.BaseMetal]}
            areaId={areaId}
            onChange={({value}) => updateForm(FormKeys.CustomerBaseMetalSupplierRestrictions, value)}
            value={form[FormKeys.CustomerBaseMetalSupplierRestrictions]}
          />
        },
        {
          key: 'customer-metal-supplier-input',
          size: 7,
          component: <AppInput
            disabled={!form[FormKeys.RequiredBaseCustomerMetalSupplierRestriction]}
            onChange={(value) => updateForm(FormKeys.CustBaseMetalSupplierRestrictionsNotes, value)}
            value={form[FormKeys.CustBaseMetalSupplierRestrictionsNotes]}
          />
        }
      ]}/>
    </LineItem>
    <LineItem
      label={<AppCheckboxItem
        checked={form[FormKeys.RequiredBaseMetalProdAnalysis]}
        onChange={onToggleRequiredField(FormKeys.RequiredBaseMetalProdAnalysis)}
        label="Metal Product Analysis Required"
      />}
      specifyKey={`${segmentKey}.metal-product-analysis-req`}
    >
      <AppLineItemColumns components={[
        {
          key: 'metal-product-analysis-req-dropdown',
          size: 5,
          component: <AppDropdownSelection
            disabled={!form[FormKeys.RequiredBaseMetalProdAnalysis]}
            areaId={areaId}
            valueType={QuoteLineItemValueTypes.BaseMetalProductAnalysisRequired}
            onChange={({value}) => updateForm(FormKeys.BaseMetalProductAnalysisRequired, value)}
            firstOptionDefault={false}
            value={form[FormKeys.BaseMetalProductAnalysisRequired]}
          />
        },
        {
          key: 'metal-product-analysis-req-input',
          size: 7,
          component: <AppInput
            disabled={!form[FormKeys.RequiredBaseMetalProdAnalysis]}
            onChange={(value) => updateForm(FormKeys.BaseMetalProductAnalysisRequiredNotes, value)}
            value={form[FormKeys.BaseMetalProductAnalysisRequiredNotes]}
          />
        }
      ]}/>
      <AppLineItemColumns components={[
        {
          key: 'metal-product-analysis-req-dropdown',
          component: <AppRadioButtons
            name={FormKeys.BaseMetalProductAnalysisType}
            disabled={!form[FormKeys.RequiredBaseMetalProdAnalysis]}
            options={['Performed by Vendor', 'Performed by Independent Test Lab']}
            onChange={(value) => updateForm(FormKeys.BaseMetalProductAnalysisType, value)}
            selected={form[FormKeys.BaseMetalProductAnalysisType]}
          />
        },
      ]}/>
    </LineItem>

    <LineItem
      label="Additional Requirements"
      key="additional-requirements"
      specifyKey={`${segmentKey}.additional-requirements`}
    >
      <AppLineItemColumns components={[
        {
          key: 'additional-requirements-dropdown',
          component: <AppTextarea
            onChange={(value) => updateForm(FormKeys.AdditionalRequirementsBaseMetal, value)}
            value={form[FormKeys.AdditionalRequirementsBaseMetal]}
            rows={5}
            placeholder='Additional Requirements...'
          />
        },
      ]}/>
    </LineItem>

    { openPrebondImpacts && <PrebondImpacts
      form={form}
      areaId={areaId}
      onSave={(payload) => {
        updateFormByPayload(payload);
        setOpenPrebondImpacts(false)
      }}
      onDismiss={(payload) => setOpenPrebondImpacts(false)}
    />}
  </Section>
}

export default BaseMetal;