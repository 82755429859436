import { CutMethods, DiameterUOMTypes, MeasurementSystems, QuoteActions, QuoteDisplayStatuses, QuoteLineItemDependentUnwrittenFields, QuoteLineItemValueTypes, RawMaterialUsage, ToastMessages, ToastTypes } from '@constants';
import { SyncMessages, useSync } from '@context/useSync';
import { useToast } from '@hooks/useToast';
import { MultiShotItemDetail } from '@interfaces/multi-shot-item-detail';
import { Quote } from '@interfaces/quote';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { getQuoteData } from '@services/quotes/quotes-api';
import { useQuotes } from '@services/quotes/quotes-service';
import { useSalesforce } from '@services/salesforce/salesforce-service';
import { getAllQuoteLineItemDetails, getSortedQuoteLineItems, recalculateDependentFields } from '@shared/quote-utils';
import {
  DependentUnwrittenFieldsMapValueType,
  QuoteActionPayloadTypes,
  QuoteDetailLineCalculations,
} from '@shared/types';
import { generateUUID } from '@shared/utils';
import { updateBrowserTabName } from '@utils/browser-utils';
import _ from 'lodash-es';
import * as React from 'react';
import { Dispatch, createContext, useContext, useReducer } from 'react';
import { useDataState } from './data-context';
import { MultiShotVirtualGroup, createMultiShotDetailsFromVirtualGroup, getVirtualGroupFromMultiShotDetails } from '@components/popover/add-quote-item-popover/multi-shot/multi-shot-details-group/multi-shot-utils';

export interface QuoteDataType {
  quote: Quote;
  quoteLineItems: QuoteLineItem[];
  quoteLineItemDetails: QuoteLineItemDetail[];
}

interface InitContextProps {
  quote: Quote;
  quoteLineItems: QuoteLineItem[];
  quoteLineItemDetails: QuoteLineItemDetail[];
  lineToDuplicate: QuoteLineItem;
  lineUpdating: boolean;
  calculatedFieldsEditedMap: Map<string, string[]>;
  inputFieldsEditedMap: Map<string, string[]>;
  defaultValueMap: Map<string, Map<string, string>>;
  isQuoteLocked: boolean;
  calculationMap: Map<string, any>;
}

const INITIAL_LOAD_STATE: InitContextProps = {
  quote: undefined,
  quoteLineItems: undefined,
  quoteLineItemDetails: undefined,
  lineToDuplicate: undefined,
  lineUpdating: false,
  calculatedFieldsEditedMap: new Map<string, string[]>(),
  inputFieldsEditedMap: new Map<string, string[]>(),
  defaultValueMap: new Map<string, Map<string, string>>(),
  isQuoteLocked: false,
  calculationMap: new Map<string, any>(),
};

interface InitStateContextProps {
  quoteState: InitContextProps;
  loadQuote: (
    id: string,
    quoteRouteState: Quote,
    quoteLineItemSortAsc: boolean,
    setQuoteLoading?: (value: boolean) => void,
    setLinesLoading?: (value: boolean) => void,
    setShowQuoteSetup?: (value: React.SetStateAction<boolean>) => void,
    setupQuote?: boolean,
    setQuoteCloning?: (value: React.SetStateAction<boolean>) => void
  ) => void;
  loadQuoteLineItemDetails: (setDetailsCloningOrSaving?: (value: boolean) => void) => void;
  saveQuote: (
    id: string,
    action: QuoteActions,
    quoteRouteState: Quote,
    setIsLoading?: (value: boolean) => void,
    setAreDetailsLoading?: (value: boolean) => void,
    setQuoteLoading?: (value: boolean) => void,
    setLinesLoading?: (value: boolean) => void,
    setShowQuoteSetup?: (value: React.SetStateAction<boolean>) => void,
    setQuoteSaving?: (value: boolean) => void,
    updatedQuoteStatus?: string,
    quoteToSave?: Quote
  ) => Promise<void>;
  saveQuoteLineItem: (
    dependentUnwrittenFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>,
    fieldsUpdated: QuoteLineItemValueTypes[],
    quoteLineItemToEdit: QuoteLineItem,
    duplicatingFlag?: boolean,
    saveMultipleFlag?: boolean,
    leftoverLineCreation?: boolean,
    reset?: boolean,
    preventDependencyRecalcs?: boolean
  ) => { lineUpdating: boolean; quoteLineItems: QuoteLineItem[] };
  handleLeftoverQuantitiesForAutocombine: (
    dependentUnwrittenFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>,
    quoteLineItemToCreate: QuoteLineItem,
    quoteLineItemDetailToCreate: QuoteLineItemDetail,
    quoteLineItems: QuoteLineItem[],
    keepPopoverVisible?: boolean
  ) => void;

  syncFormQuoteLineItem: (formQuoteLineItems) => any;
}

interface InitDispatchContextProps {
  dispatchQuoteState: Dispatch<Action>;
}

interface ProviderProps {
  children: React.ReactNode;
}

const QuoteContext = createContext<InitStateContextProps>({
  quoteState: null,
  /* eslint-disable */
  loadQuote() {},
  loadQuoteLineItemDetails() {},
  saveQuote() {
    return null;
  },
  saveQuoteLineItem() {
    return { lineUpdating: false, quoteLineItems: [] };
  },
  handleLeftoverQuantitiesForAutocombine() {},

  syncFormQuoteLineItem(formQuoteLineItems) {},
  /* eslint-enable */
});

const QuoteDispatchContext = createContext<InitDispatchContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatchQuoteState() {},
});

export enum QuoteActionTypes {
  loadQuote = 'load-quote',
  loadQuoteLineItems = 'load-quote-line-items',
  loadQuoteLineItemDetails = 'load-quote-line-item-details',
  loadQuoteData = 'load-quote-data',
  updateCalculatedFieldsEdited = 'update-calculated-fields-edited',
  updateInputFieldsEdited = 'update-input-fields-edited',
  updateDefaultValues = 'update-default-values',
  updateLineToDuplicate = 'update-line-to-duplicate',
  updateLineUpdating = 'update-line-updating',
  setDocumentUOM = 'update-document-uom',
  setIsQuoteLocked = 'set-quote-is-locked',
  setCalculationMap = 'set-calculation-map',
  setItem = 'setItem',
  setLineItemIds = 'setLineItemIds',
  setQuoteStatus = 'setQuoteStatus',
  setQuoteRevisionNotes = 'setQuoteRevisionNotes',
  setQuoteNotes = 'setQuoteNotes',
}

export type Action =
  | { type: QuoteActionTypes.loadQuote; payload: Quote }
  | { type: QuoteActionTypes.loadQuoteLineItems; payload: QuoteLineItem[] }
  | { type: QuoteActionTypes.loadQuoteLineItemDetails; payload: QuoteLineItemDetail[] }
  | {
      type: QuoteActionTypes.loadQuoteData;
      payload: { quote: Quote; quoteLineItems: QuoteLineItem[]; quoteLineItemDetails: QuoteLineItemDetail[] };
    }
  | { type: QuoteActionTypes.updateCalculatedFieldsEdited; payload: Map<string, string[]> }
  | { type: QuoteActionTypes.updateInputFieldsEdited; payload: Map<string, string[]> }
  | { type: QuoteActionTypes.updateDefaultValues; payload: Map<string, Map<string, string>> }
  | { type: QuoteActionTypes.updateLineToDuplicate; payload: QuoteLineItem }
  | { type: QuoteActionTypes.updateLineUpdating; payload: boolean }
  | { type: QuoteActionTypes.setDocumentUOM; payload: MeasurementSystems }
  | { type: QuoteActionTypes.setIsQuoteLocked; payload: boolean }
  | { type: QuoteActionTypes.setCalculationMap; payload: Map<string, QuoteDetailLineCalculations> }
  | { type: QuoteActionTypes.setItem; payload: { field: string; value: QuoteActionPayloadTypes } } // generic way to set a field with any value
  | { type: QuoteActionTypes.setLineItemIds; payload: { key: string; lineItem: any[] } }
  | { type: QuoteActionTypes.setQuoteStatus; payload: string }
  | { type: QuoteActionTypes.setQuoteRevisionNotes; payload: string }
  | { type: QuoteActionTypes.setQuoteNotes; payload: string }
  | Record<string, never>; // fallback for any other action

export const QuoteProvider = ({ children }: ProviderProps) => {
  const { dataState } = useDataState();
  const salesforce = useSalesforce();
  const { sync, endSyncWithError } = useSync();
  const quotes = useQuotes();
  const toast = useToast(4000);

  const updateSetLineItemIds = (state, action) => {
    const result = action.payload;
    const detailAttrMap = {
      Id: 'detailId',
      NC_Quote_Line_Item__c: 'Id',

      'NC_Anvil_Item_Details__r.Id': 'anvil',
      'NC_Cylinder_Item_Details__r.Id': 'cylinder',
      'NC_Flyer_Item_Details__r.Id': 'flyer',
      'NC_Head_Item_Detail__r.Id': 'head',
      'NC_Int_Attributes__r.Id': 'intAttributes',
      'NC_Multi_Shot_Item_Details__r.Id': 'multiShot', //TODO: ask Mark
      'NC_Outside_Service_Item_Details__r.Id': 'outsideService',
      'NC_Seam_Weld_Item_Details__r.Id': 'seamWeld',
      'NC_Tubesheet_Item_Details__r.Id': 'tubesheet',

      'NC_Int_Attributes__r.NC_Anvil_Int_Attributes__c': 'intAttr.NC_Anvil_Int_Attributes__c',
      'NC_Int_Attributes__r.NC_Flyer_Int_Attributes__c': 'intAttr.NC_Flyer_Int_Attributes__c',
      'NC_Int_Attributes__r.NC_Head_Int_Attribute__c': 'intAttr.NC_Head_Int_Attribute__c',
      'NC_Int_Attributes__r.NC_Hidden_Int_Attribute__c': 'intAttr.NC_Hidden_Int_Attribute__c',
      'NC_Int_Attributes__r.NC_Multi_Shot_Int_Attribute__c': 'intAttr.NC_Multi_Shot_Int_Attribute__c',
      'NC_Int_Attributes__r.NC_Outside_Service_Int_Attributes__c': 'intAttr.NC_Outside_Service_Int_Attributes__c',
      'NC_Int_Attributes__r.NC_Seam_Weld_Int_Attributes__c': 'intAttr.NC_Seam_Weld_Int_Attributes__c',
      'NC_Int_Attributes__r.NC_TubeSheet_Int_Att__c': 'intAttr.NC_TubeSheet_Int_Att__c',
      'NC_Int_Attributes__r.NC_Cylinder_Int_Attribute__c': 'intAttr.NC_Cylinder_Int_Attribute__c',
    };

    const qlis = state.quoteLineItems.map((qli) => {
      const lineItemResult = result.find((resultItem) => resultItem.key === qli.UUID__c);

      if (!lineItemResult) {
        return qli;
      }

      const { lineItem } = lineItemResult;

      const qlid = Object.keys(detailAttrMap).reduce(
        (prev, curr) => {
          const value = _.get(lineItem, detailAttrMap[curr]);
          if (Array.isArray(value)) {
            value.forEach((v, index) => {
              const key = curr.replace('[x]', `[${index}]`);
              _.set(prev, key, v);
            });
          } else {
            _.set(prev, curr, value);
          }

          return prev;
        },
        {
          ...qli.quoteLineItemDetail,
          associatedLineItem: undefined,
        }
      );

      return {
        ...qli,
        Id: lineItem.Id,
        displayId: undefined,
        quoteLineItemDetail: qlid,
      };
    });

    return {
      ...state,
      quote: {
        ...state.quote,
        quoteLineItems: qlis,
      },
      quoteLineItems: qlis,
      quoteLineItemDetails: getAllQuoteLineItemDetails(qlis),
    };
  };

  const quoteReducer = (state = INITIAL_LOAD_STATE, action: Action) => {
    switch (action.type) {
      case QuoteActionTypes.loadQuote:
        return { ...state, quote: action.payload };
      case QuoteActionTypes.loadQuoteLineItems:
        return {
          ...state,
          quote: {
            ...state.quote,
            quoteLineItems: action.payload,
          },
          quoteLineItems: action.payload,
          quoteLineItemDetails: getAllQuoteLineItemDetails(action.payload),
        };
      case QuoteActionTypes.loadQuoteLineItemDetails:
        return {
          ...state,
          quoteLineItemDetails: action.payload,
        };
      case QuoteActionTypes.loadQuoteData:
        return {
          ...state,
          quote: {
            ...action.payload.quote,
            quoteLineItems: action.payload.quoteLineItems,
          },
          quoteLineItems: action.payload.quoteLineItems,
          quoteLineItemDetails: action.payload.quoteLineItemDetails,
        };
      case QuoteActionTypes.updateCalculatedFieldsEdited:
        return { ...state, calculatedFieldsEditedMap: action.payload };
      case QuoteActionTypes.updateInputFieldsEdited:
        return { ...state, inputFieldsEditedMap: action.payload };
      case QuoteActionTypes.updateDefaultValues:
        return { ...state, defaultValueMap: action.payload };
      case QuoteActionTypes.updateLineToDuplicate:
        return { ...state, lineToDuplicate: action.payload };
      case QuoteActionTypes.updateLineUpdating:
        return { ...state, lineUpdating: action.payload };
      case QuoteActionTypes.setDocumentUOM:
        return {
          ...state,
          quote: {
            ...state.quote,
            DocumentUOM__c: action.payload,
          },
        };
      case QuoteActionTypes.setIsQuoteLocked:
        return { ...state, isQuoteLocked: action.payload };
      case QuoteActionTypes.setCalculationMap:
        return { ...state, calculationMap: action.payload };
      case QuoteActionTypes.setItem: // top level field setter, not typed
        return { ...state, [action.payload.field]: action.payload.value };
      case QuoteActionTypes.setLineItemIds:
        return updateSetLineItemIds(state, action);
      case QuoteActionTypes.setQuoteStatus:
        return {
          ...state,
          quote: {
            ...state.quote,
            Quote_Status__c: action.payload,
          },
        };
      case QuoteActionTypes.setQuoteRevisionNotes:
        return {
          ...state,
          quote: {
            ...state.quote,
            RevisionNotes__c: action.payload,
          },
        };
      case QuoteActionTypes.setQuoteNotes:
        return {
          ...state,
          quote: {
            ...state.quote,
            Quote_Notes__c: action.payload,
          },
        };
      /** Return state as a fallback */
      default:
        return state;
    }
  };

  const [quoteState, dispatchQuoteState] = useReducer(quoteReducer, INITIAL_LOAD_STATE);

  const loadFromQuoteState = (quoteRouteState: Quote) => {
    dispatchQuoteState({ type: QuoteActionTypes.loadQuote, payload: quoteRouteState });
    dispatchQuoteState({
      type: QuoteActionTypes.setIsQuoteLocked,
      payload:
        quoteRouteState.Quote_Status__c !== QuoteDisplayStatuses.Draft &&
        quoteRouteState.Quote_Status__c !== QuoteDisplayStatuses.IntegrationError,
    });
  };
  
  const loadQuoteDataIntoState = (
    quote: Quote,
    quoteLineItems: QuoteLineItem[],
    quoteLineItemDetails: QuoteLineItemDetail[],
    quoteLineItemSortAsc: boolean
  ) => {
    try {
      const sortedQuoteLineItems: QuoteLineItem[] = getSortedQuoteLineItems(quoteLineItems, quoteLineItemSortAsc);
      console.log('sortedQuoteLineItems: ', sortedQuoteLineItems);
      const sortedQuoteLineItemDetails: QuoteLineItemDetail[] =
        sortedQuoteLineItems?.map((sqli) => sqli.quoteLineItemDetail) ?? quoteLineItemDetails;
      
      for (const detail of sortedQuoteLineItemDetails) {
        const multiShotDetails = detail?.NC_Multi_Shot_Item_Details__r;
        console.log('multiShotDetails: ', multiShotDetails);
        console.log('detail.virtualDetailGroup: ', detail.virtualDetailGroup);

        const hasValidMultiShotDetails = multiShotDetails?.some(detail =>
          Object.keys(detail).length > 1 || !('Id' in detail)
        );

        if (Object.keys(detail).length > 1 && 'Id' in detail) {
          console.log('Detail has more than one key and includes Id:', detail);
        }
        
        if (hasValidMultiShotDetails) {
          const virtualGroup: MultiShotVirtualGroup = getVirtualGroupFromMultiShotDetails(detail, sortedQuoteLineItems);
          console.log('virtualGroup: ', virtualGroup);

          const hasVirtualShotGroups = virtualGroup?.virtualShotGroups.length > 0;

          if (!hasVirtualShotGroups) {
            console.log('TODO: create new virtual items, no cosmos data');
          } else {
            detail.virtualDetailGroup = virtualGroup;
          }
        }
      }

      // load quote data into state
      dispatchQuoteState({
        type: QuoteActionTypes.loadQuoteData,
        payload: {
          quote: quote,
          quoteLineItems: sortedQuoteLineItems,
          quoteLineItemDetails: sortedQuoteLineItemDetails,
        },
      });

      dispatchQuoteState({
        type: QuoteActionTypes.setIsQuoteLocked,
        payload:
          quote.Quote_Status__c !== QuoteDisplayStatuses.Draft &&
          quote.Quote_Status__c !== QuoteDisplayStatuses.IntegrationError &&
          quote.Quote_Status__c !== QuoteDisplayStatuses.SalesforceSyncError,
      });

      console.debug('quote data has been loaded into state');
    } catch (error) {
      console.error('loadQuoteDataIntoState error: ', error);
      toast(ToastTypes.Error, ToastMessages.QuoteLoadIntoStateFailed);
    }
  };

  const syncQuoteStatus = async (quote: Quote) => {
    // it will try to sync status from salesforce to cosmos on load quote
    // and update quote state in the process if its not match and not a salesforce sync error
    const quoteStatus = await salesforce.updateStatus(quote.Id);

    if (
      quote.Quote_Status__c !== quoteStatus.Quote_Status__c &&
      quote.Quote_Status__c !== QuoteDisplayStatuses.SalesforceSyncError
    ) {
      console.log("quote status' mismatch: ", quoteStatus);
      dispatchQuoteState({ type: QuoteActionTypes.setQuoteStatus, payload: quoteStatus.Quote_Status__c });
    }
  };

  const loadQuote = async (
    id: string,
    quoteRouteState: Quote,
    quoteLineItemSortAsc: boolean,
    setQuoteLoading?: (value: boolean) => void,
    setLinesLoading?: (value: boolean) => void,
    setShowQuoteSetup?: (value: React.SetStateAction<boolean>) => void,
    setupQuote?: boolean,
    setQuoteCloningOrSaving?: (value: React.SetStateAction<boolean>) => void
  ) => {
    let requiresQuoteSetup = false;

    const findQlid = (qli, quoteLineItemDetails) => {
      return quoteLineItemDetails.find((qlid) => {
        if (qlid.itemUUID && qli.UUID__c) {
          return qlid.itemUUID === qli.UUID__c;
        }

        return (
          qlid.NC_Quote_Line_Item__c === qli.Id ||
          (qlid.associatedLineItem && qli.displayId && qlid.associatedLineItem === qli.displayId)
        );
      });
    };

    const getNestedQuote = (
      quote: Quote,
      quoteLineItems: QuoteLineItem[],
      quoteLineItemDetails: QuoteLineItemDetail[]
    ) => {
      const nestedQuote: Quote = {
        ...quote,
      };

      if (quoteLineItems?.length) {
        const _quoteLineItems = quoteLineItems.map((qli) => {
          const _quoteLineItemDetail = findQlid(qli, quoteLineItemDetails);

          // Ensure that multishot is an array
          if (_quoteLineItemDetail && _quoteLineItemDetail.NC_Multi_Shot_Item_Details__r && !Array.isArray(_quoteLineItemDetail.NC_Multi_Shot_Item_Details__r)) {
            _quoteLineItemDetail.NC_Multi_Shot_Item_Details__r = [_quoteLineItemDetail.NC_Multi_Shot_Item_Details__r];
          }

          return {
            ...qli,
            quoteLineItemDetail: _quoteLineItemDetail,
          };
        });

        nestedQuote.quoteLineItems = _quoteLineItems;
      } else {
        nestedQuote.quoteLineItems = [];
      }

      return nestedQuote;
    };

    if (setQuoteLoading) {
      setQuoteLoading(true);
    }

    if (setLinesLoading) {
      setLinesLoading(true);
    }

    if (quoteRouteState) {
      console.log('quoteRouteState is NOT undefined: ', quoteRouteState);
      loadFromQuoteState(quoteRouteState);
    } else {
      const defaultResponse = {
        response: {
          quote: {} as Quote,
          quoteLineItems: [] as QuoteLineItem[],
          quoteLineItemDetails: [] as QuoteLineItemDetail[],
        },
      };
      // response api call retrieves the quote data from either Salesforce or Cosmos, depending on logic in the api
      const { response } = (await getQuoteData(id)) ?? defaultResponse;

      const { quote, quoteLineItems, quoteLineItemDetails }: any = response;

      if (!quote) {
        throw new Error('Quote not found');
      } else {
        const opportunityName = quote.Opportunity__r?.Name;

        const newTabName = `${quote.Name}${opportunityName ? ` | ${opportunityName}` : ''}`;

        updateBrowserTabName(newTabName);
      }

      const _quoteLineItems = [];
      const _quoteLineItemDetails = [];

      // set the uuids for line item and details if not exists
      quoteLineItems.forEach((qli) => {
        const quoteLineItemDetail = findQlid(qli, quoteLineItemDetails) ?? {} as QuoteLineItemDetail;
        qli.UUID__c = qli?.UUID__c ?? generateUUID();

        quoteLineItemDetail.UUID__c = quoteLineItemDetail?.UUID__c ?? generateUUID();
        quoteLineItemDetail.itemUUID = qli.UUID__c;

        _quoteLineItemDetails.push(quoteLineItemDetail);
        _quoteLineItems.push(qli);
      });

      // nest the quoteLineItemDetail inside of the quoteLineItem, and the quoteLineItems inside of the quote
      const nestedQuote: Quote = getNestedQuote(quote, _quoteLineItems, _quoteLineItemDetails);

      // if the quote doesn't have a Manufacturing_Site__c, we need to set up the quote
      requiresQuoteSetup = !quote?.Manufacturing_Site__c;

      loadQuoteDataIntoState(nestedQuote, nestedQuote.quoteLineItems, _quoteLineItemDetails, quoteLineItemSortAsc);

      syncQuoteStatus(nestedQuote);
    }

    if (setQuoteCloningOrSaving) {
      setQuoteCloningOrSaving(false);
    }

    if (setShowQuoteSetup && (setupQuote || requiresQuoteSetup)) {
      setShowQuoteSetup(true);
    }
  };

  // Loads the quote line item details
  const loadQuoteLineItemDetails = async (setDetailsCloningOrSaving?: (value: boolean) => void) => {
    if (setDetailsCloningOrSaving) {
      setDetailsCloningOrSaving(false);
    }
  };

  // Saves the quote line item - the return type is { lineUpdating: boolean; }
  const saveQuoteLineItem = (
    dependentUnwrittenFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>,
    fieldsUpdated: QuoteLineItemValueTypes[],
    quoteLineItemToEdit: QuoteLineItem,
    duplicatingFlag?: boolean,
    saveMultipleFlag?: boolean,
    leftoverLineCreation?: boolean,
    reset?: boolean,
    preventDependencyRecalcs?: boolean,
    quoteLineItemsForAutocombine?: QuoteLineItem[]
  ) => {
    const { quote, calculatedFieldsEditedMap, inputFieldsEditedMap } = quoteState;
    const quoteLineItemsToSave = quoteLineItemsForAutocombine || quote.quoteLineItems;
    const quantityEntered = quoteLineItemToEdit.Quantity__c;

    if (!preventDependencyRecalcs) {
      fieldsUpdated.forEach((fieldValueType) => {
        recalculateDependentFields(
          dataState,
          quote,
          quoteLineItemToEdit,
          calculatedFieldsEditedMap,
          inputFieldsEditedMap,
          dependentUnwrittenFieldsMap,
          fieldValueType,
          undefined,
          saveMultipleFlag,
          reset,
          fieldsUpdated
        );
      });
    }

    const lineItemUpdateIndex = quoteLineItemsToSave.findIndex(
      (quoteLineItemToUpdate) => quoteLineItemToUpdate.Line__c === quoteLineItemToEdit.Line__c
    );

    if (lineItemUpdateIndex === -1) {
      quoteLineItemsToSave.push(quoteLineItemToEdit);
    } else {
      quoteLineItemsToSave[lineItemUpdateIndex] = {
        ...quoteLineItemsToSave[lineItemUpdateIndex],
        ...quoteLineItemToEdit,
      };
    }

    let lineToDuplicate: QuoteLineItem;
    if (duplicatingFlag && !leftoverLineCreation) {
      lineToDuplicate = { ...quoteLineItemToEdit, Quantity__c: quantityEntered };
    }

    const lineUpdating = true;

    dispatchQuoteState({ type: QuoteActionTypes.updateLineUpdating, payload: lineUpdating });

    if (!leftoverLineCreation) {
      dispatchQuoteState({ type: QuoteActionTypes.updateLineToDuplicate, payload: lineToDuplicate });

      if (!dependentUnwrittenFieldsMap.has(QuoteLineItemDependentUnwrittenFields.LeftoverQuantity)) {
        dispatchQuoteState({ type: QuoteActionTypes.loadQuoteLineItems, payload: [...quoteLineItemsToSave] });
      }
    }

    return {
      lineUpdating,
      quoteLineItems: [...quoteLineItemsToSave],
    };
  };

  // Handles the leftover quantities after autocombining finished goods
  const handleLeftoverQuantitiesForAutocombine = (
    dependentUnwrittenFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>,
    quoteLineItemToCreate: QuoteLineItem,
    quoteLineItemDetailToCreate: QuoteLineItemDetail,
    quoteLineItems: QuoteLineItem[],
    keepPopoverVisible?: boolean
  ) => {
    const leftoverQuantities = dependentUnwrittenFieldsMap.get(
      QuoteLineItemDependentUnwrittenFields.LeftoverQuantity
    ) as number[];

    let newStates;
    while (leftoverQuantities?.length) {
      dependentUnwrittenFieldsMap.set(
        QuoteLineItemDependentUnwrittenFields.QuantityForLeftoverCalculation,
        leftoverQuantities[0]
      );
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.LeftoverQuantity, []);
      dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.QuantityUpdatedForAutocombine, false);

      const newLine = (quoteLineItems.length + 1).toString().padStart(3, '0');
      const newDisplayId = (quoteLineItems.length + 1).toString();
      const newAssociatedLineItem = (quoteLineItems.length + 1).toString();
      const newUUID = generateUUID();

      leftoverQuantities.splice(0, 1);

      const quoteLineItemToSave = {
        ...quoteLineItemToCreate,
        Line__c: newLine,
        displayId: newDisplayId,
        Id: undefined,
        Quantity__c: leftoverQuantities[0],
        UUID__c: newUUID,
        quoteLineItemDetail: {
          ...quoteLineItemDetailToCreate,
          NC_Quote_Line_Item__c: undefined,
          associatedLineItem: newAssociatedLineItem,
          UUID__c: generateUUID(),
          itemUUID: newUUID,
          Id: undefined,
        },
      };

      newStates = saveQuoteLineItem(
        dependentUnwrittenFieldsMap,
        [
          QuoteLineItemValueTypes.SizingQuantity,
          QuoteLineItemValueTypes.QuantityForLeftoverCalculation,
          QuoteLineItemValueTypes.UnitOfMeasure,
        ],
        quoteLineItemToSave,
        keepPopoverVisible,
        false,
        true,
        false,
        false,
        quoteLineItems
      );

      const leftoverQuantitiesFromNewLine = dependentUnwrittenFieldsMap.get(
        QuoteLineItemDependentUnwrittenFields.LeftoverQuantity
      ) as number[];
      if (leftoverQuantitiesFromNewLine.length) {
        leftoverQuantities.push(...leftoverQuantitiesFromNewLine);
      }
    }

    if (newStates) {
      const { lineUpdating, quoteLineItems: updatedQuoteLineItems } = newStates;

      dispatchQuoteState({ type: QuoteActionTypes.updateLineUpdating, payload: lineUpdating });
      dispatchQuoteState({ type: QuoteActionTypes.loadQuoteLineItems, payload: [...updatedQuoteLineItems] });
    }
  };

  const calculateTotalThickness = (quoteDataLineItems: QuoteLineItem[], parentLineUUID: string, virtualItemIds: string[]): {totalThicknessImperial: number, totalThicknessMetric: number} => {
      const parentLineItem: QuoteLineItem = quoteDataLineItems.find((qli) => qli.UUID__c === parentLineUUID);
      const virtualLineItems: QuoteLineItem[] = quoteDataLineItems.filter((qli) => virtualItemIds.includes(qli.UUID__c));

      const baseThicknessImperial = parentLineItem.quoteLineItemDetail.Base_TK_in__c;
      const baseThicknessMetric = parentLineItem.quoteLineItemDetail.Base_TK_mm__c;

      const cladThicknessImperial = parentLineItem.quoteLineItemDetail.Clad_TK_in__c;
      const cladThicknessMetric = parentLineItem.quoteLineItemDetail.Clad_TK_mm__c;

      const virtualThicknessImperial = virtualLineItems.map((vli) => vli.Clad_Nom_in__c).reduce((acc, curr) => acc + curr, 0);
      const virtualThicknessMetric = virtualLineItems.map((vli) => vli.Clad_Nom_mm__c).reduce((acc, curr) => acc + curr, 0);

      const totalThicknessImperial = baseThicknessImperial + cladThicknessImperial + virtualThicknessImperial;
      const totalThicknessMetric = baseThicknessMetric + cladThicknessMetric + virtualThicknessMetric;

      return {
          totalThicknessImperial,
          totalThicknessMetric
      };
  };

  interface PreviousValues {
    previousCladKGs: number;
    previousCladLbs: number;
    previousRawWeightsLbs: number;
    previousRawWeightsKg: number;
    previousNominalTKASMM: number;
    previousNominalTKASIN: number;
    previousNominalTKBKMM: number;
    previousNominalTKBKIN: number;
    previousMinBKTKIN: number;
    previousMinBKTKMM: number;
    previousMinCladTKIN: number;
    previousMinCladTKMM: number;
    previousNominalCladTKIN: number;
    previousNominalCladTKMM: number;
  }

  // Saves the quote to salesforce
  const saveQuote = async (
    id: string,
    action: QuoteActions,
    quoteRouteState: Quote,
    setIsLoading?: (value: boolean) => void,
    setAreDetailsLoading?: (value: boolean) => void,
    setQuoteLoading?: (value: boolean) => void,
    setLinesLoading?: (value: boolean) => void,
    setShowQuoteSetup?: (value: React.SetStateAction<boolean>) => void,
    setQuoteSaving?: (value: boolean) => void,
    updatedQuoteStatus = undefined,
    quoteToSave?: Quote
  ) => {

    const { quote } = quoteState;

    const { paymentTermsContent, cancellationTermsContent, contractTermsContent, validityContent } = dataState;

    if (setIsLoading) {

      setIsLoading(true);
    }

    if (setAreDetailsLoading) {
      setAreDetailsLoading(true);
    }

    // order entry also uses quoteToSave, just to be safe
    // quoteLineItems should be available when using quoteToSave
    const quoteData = quoteToSave || quote;
    const quoteDataLineItems =
      quoteToSave?.quoteLineItems?.length > 0 ? quoteToSave.quoteLineItems : quote.quoteLineItems;
    const quoteStatus = updatedQuoteStatus || quoteData.Quote_Status__c;

    if (updatedQuoteStatus) {
      dispatchQuoteState({ type: QuoteActionTypes.setQuoteStatus, payload: quoteStatus });
    }

    /* Multi-Shot loop */
    for (const qli of quoteDataLineItems as QuoteLineItem[]) {
      const virtualDetailGroup: MultiShotVirtualGroup = qli.quoteLineItemDetail.virtualDetailGroup; // detail virtual groups get built on `add item` and on load quote
      
      if (virtualDetailGroup) {
        // if the associated detail is to a quote line item that is a virtual item, then we need to update the original qli's ASItemDescription with this detail's ASItemDescription and composite thickness
        const { parentLineUUID, virtualShotGroups } = virtualDetailGroup;
        const virtualItemIds = virtualShotGroups.map((vsg) => vsg.virtualLineUUID);

        const isImperial = (qli.quoteLineItemDetail.Unit_of_Measure__c ?? qli.Unit_of_Measure__c) === MeasurementSystems.Imperial;
        
        // to get the total thickness, we want the base nominal thickness of the parent line item, its clad thickness, and the thicknesses of the virtual line items cladded to it
        const { totalThicknessImperial, totalThicknessMetric } = calculateTotalThickness(
          quoteDataLineItems,
          parentLineUUID,
          virtualItemIds
        );

        const totalThickness = isImperial ? totalThicknessImperial : totalThicknessMetric;
               
        qli.TotalThickness__c = totalThicknessImperial;
        qli.TotalThicknessMM__c = totalThicknessMetric;
        qli.quoteLineItemDetail.ASItemDescription__c = `${totalThickness} X ${qli.quoteLineItemDetail.AS_Width__c} X ${qli.quoteLineItemDetail.AS_Length__c}`;
        
        const virtualLineItems: QuoteLineItem[] = quoteDataLineItems.filter((qli) => virtualItemIds.includes(qli.UUID__c));
    
        const rollingMSValues: PreviousValues = virtualLineItems.reduce(
          (prev, curr, index) => {
            prev.previousCladKGs += curr.quoteLineItemDetail.Clad_Weight_Kg__c;
            prev.previousCladLbs += curr.quoteLineItemDetail.Clad_Weight_Lbs__c;

            prev.previousRawWeightsLbs += curr.quoteLineItemDetail.Raw_Clad_Weight_Lbs__c;
            prev.previousRawWeightsKg += curr.quoteLineItemDetail.Raw_Clad_Weight_Kg__c;

            prev.previousNominalTKASMM += curr.Clad_Nom_mm__c;
            prev.previousNominalTKASIN += curr.Clad_Nom_in__c;

            prev.previousMinCladTKIN += curr.Clad_Min_in__c;
            prev.previousMinCladTKMM += curr.Clad_Min_mm__c;

            prev.previousNominalCladTKIN += curr.Clad_TK_in__c;
            prev.previousNominalCladTKMM += curr.Clad_TK_mm__c;

            // // Update the qlid of the current item
            virtualLineItems[index].quoteLineItemDetail.Clad_Weight_Kg__c = prev.previousCladKGs; // in MS detail: RawCladderWeight__c
            virtualLineItems[index].quoteLineItemDetail.Clad_Weight_Lbs__c = prev.previousCladLbs; // in MS detail: RawCladderWeight__c

            virtualLineItems[index].quoteLineItemDetail.Raw_Clad_Weight_Lbs__c = prev.previousRawWeightsLbs; // in MS detail: MasterPlateWeight__c
            virtualLineItems[index].quoteLineItemDetail.Raw_Clad_Weight_Kg__c = prev.previousRawWeightsKg; // in MS detail: MasterPlateWeight__c

            virtualLineItems[index].Clad_Nom_mm__c = prev.previousNominalTKASMM; // in MS detail: ThicknessnommmAS__c
            virtualLineItems[index].Clad_Nom_in__c = prev.previousNominalTKASIN; // in MS detail: ThicknessnomAS__c

            virtualLineItems[index].Clad_Min_mm__c = prev.previousMinCladTKMM; // in MS detail: ThicknessminmmCL__c and in ThicknessminmmAS__c
            virtualLineItems[index].Clad_Min_in__c = prev.previousMinCladTKIN; // in MS detail: ThicknessminmmCL__c and in ThicknessminimAS__c

            virtualLineItems[index].quoteLineItemDetail.Clad_TK_in__c = prev.previousNominalCladTKIN; // in MS detail: ThicknessnomimCL__c, Clad_TK_in__c, and NominalThicknessCladder__c
            virtualLineItems[index].quoteLineItemDetail.Clad_TK_mm__c = prev.previousNominalCladTKMM; // in MS detail: ThicknessminmmCL__c, Clad_TK_mm__c, and NominalThicknessCladder__c

            return prev;
          },
          {
            previousCladKGs: qli.quoteLineItemDetail.Clad_Weight_Kg__c,
            previousCladLbs: qli.quoteLineItemDetail.Clad_Weight_Lbs__c,
    
            previousRawWeightsLbs: qli.quoteLineItemDetail.Raw_Clad_Weight_Lbs__c,
            previousRawWeightsKg: qli.quoteLineItemDetail.Raw_Clad_Weight_Kg__c,
                    
            previousNominalTKASMM: qli.Clad_Nom_mm__c,
            previousNominalTKASIN: qli.Clad_Nom_in__c,
    
            previousNominalTKBKMM: qli.Base_Nom_mm__c,
            previousNominalTKBKIN: qli.Base_Nom_in__c,
    
            previousMinBKTKIN: qli.Base_Min_in__c,
            previousMinBKTKMM: qli.Base_Min_mm__c,
                    
            previousMinCladTKIN: qli.Clad_Min_in__c,
            previousMinCladTKMM: qli.Clad_Min_mm__c,
    
            previousNominalCladTKIN: qli.quoteLineItemDetail.Clad_TK_in__c,
            previousNominalCladTKMM: qli.quoteLineItemDetail.Clad_TK_mm__c,
          }
        );
        
        console.log('previousTotals: ', rollingMSValues);
        
        const msDetails: MultiShotItemDetail[] = createMultiShotDetailsFromVirtualGroup(
          virtualDetailGroup,
          quoteDataLineItems,
          qli,
        ) as MultiShotItemDetail[];

        qli.quoteLineItemDetail.virtualDetailGroup = undefined;
        qli.quoteLineItemDetail.NC_Multi_Shot_Item_Details__r = msDetails;
      }
    }
        
    const paymentTerms = quoteData.Payment_Terms__c || paymentTermsContent?.[quoteData.Manufacturing_Site__c];
    const cancellationTerms =
      quoteData.Cancelation_Terms__c || cancellationTermsContent?.[quoteData.Manufacturing_Site__c];
    const contractTerms = quoteData.Contract_Terms__c || contractTermsContent?.[quoteData.Manufacturing_Site__c];
    const validity = quoteData.Validity__c || validityContent?.[quoteData.Manufacturing_Site__c];

    //Re-adding defaults
    quoteData.Payment_Terms__c = paymentTerms;
    quoteData.Cancelation_Terms__c = cancellationTerms;
    quoteData.Contract_Terms__c = contractTerms;
    quoteData.Validity__c = validity;

    const getRawMaterial = (metal) => {
      return dataState?.rawMaterials.find((rm) => rm.name === metal);
    };

    const lineItemAdditionalInfo = (qli) => {
      const clad = getRawMaterial(qli.CladMetal__c);
      const base = getRawMaterial(qli.BaseMetal__c);

      return {
        baseMetalASME__c: base?.asmeDesignation,
        cladMetalASME__c: clad?.asmeDesignation,
        Base_Long_Name__c: base?.metalLongName,
        Clad_Long_Name__c: clad?.metalLongName,
      };
    };

    //Get ASME Designation from Cosmos Raw Material
    const asmeDesignation = (metal) => {
      const result = dataState?.rawMaterials.find((rm) => rm.name === metal)?.asmeDesignation;
      return result !== null && result !== undefined ? result : undefined;
    };

    // all endpoint should be expecting un-nested quote
    const data = {
      quote: {
        ...quoteData,
        Quote_Status__c: quoteStatus,
        quoteLineItems: [],
      },
      quoteLineItems: quoteDataLineItems.map((qli) => ({
        ...qli,
        quoteLineItemDetail: undefined,
        NC_Quote__c: quoteData.Id,
        Name: quoteData.Name,
        baseMetalASME__c: asmeDesignation(qli.BaseMetal__c),
        cladMetalASME__c: asmeDesignation(qli.CladMetal__c),
        ...lineItemAdditionalInfo(qli),
      })),
      quoteLineItemDetails: quoteDataLineItems.map((qli) => qli.quoteLineItemDetail),
    };

    /* Set Uncut Descriptions loop */
    for (const qli of quoteDataLineItems as QuoteLineItem[]) {
      // set uncut item descriptions
      if (qli.Cut_Method__c === CutMethods.Uncut) {
        const associatedDetail = qli.quoteLineItemDetail;
        const baseWidthImperial = associatedDetail.Base_Width_in__c.toFixed(3);
        const baseWidthMetric = associatedDetail.Base_Width_mm__c.toFixed(0);
  
        const baseMetal = dataState?.rawMaterials.find(rawMaterial => rawMaterial.name === qli.BaseMetal__c && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(quote.Manufacturing_Site__c));
  
        const isForging = baseMetal.dmcClass.includes('/F') as boolean;
  
        // Remove dash and anything that follows it
        const cleanedItemDescriptionIn = isForging ? `${baseWidthImperial} - ${DiameterUOMTypes.OD}` : `${baseWidthImperial} X ${associatedDetail.Base_Length_in__c.toFixed(3)}`;
        const cleanedItemDescriptionMm = isForging ? `${baseWidthMetric} - ${DiameterUOMTypes.OD}` : `${baseWidthMetric} X ${associatedDetail.Base_Length_mm__c.toFixed(0)}`;
  
        qli.UncutItemDescriptionImperial__c = cleanedItemDescriptionIn;
        qli.UncutItemDescriptionMetric__c = cleanedItemDescriptionMm;
      }
    }

    const saveToSalesforce = async () => {
      try {
        if (data.quote.Quote_Status__c === QuoteDisplayStatuses.SalesforceSyncError) {
          const updatedQuote = {
            ...data,
            quote: {
              ...data.quote,
              Quote_Status__c: undefined,
            },
          };

          const results = await salesforce.updateFullQuoteById(data.quote.Id, /*doubleQuoteReplace.mapObjects(*/updatedQuote/*)*/);
          const newQuote = await salesforce.getAccountQuoteById(data.quote.Id);

          dispatchQuoteState({
            type: QuoteActionTypes.setQuoteStatus,
            payload: newQuote.Quote_Status__c ?? QuoteDisplayStatuses.Draft,
          });
          dispatchQuoteState({ type: QuoteActionTypes.setLineItemIds, payload: results });

          quotes.updateFullQuote(/*doubleQuoteReplace.mapObjects(*/data/*)*/, data.quote.Id);
        } else {
          const results = await salesforce.updateFullQuoteById(data.quote.Id, /*doubleQuoteReplace.mapObjects(*/data/*)*/);
          const quoteStatus = await salesforce.updateStatus(data.quote.Id);
          dispatchQuoteState({ type: QuoteActionTypes.setLineItemIds, payload: results });

          if (data.quote.Quote_Status__c !== quoteStatus.Quote_Status__c) {
            dispatchQuoteState({ type: QuoteActionTypes.setQuoteStatus, payload: quoteStatus.Quote_Status__c });
          }
        }
      } catch (e) {
        endSyncWithError(SyncMessages.SalesforceError);
        toast(ToastTypes.Error, ToastMessages.QuoteSyncToSalesforceFailed);
        dispatchQuoteState({
          type: QuoteActionTypes.setQuoteStatus,
          payload: QuoteDisplayStatuses.SalesforceSyncError,
        });

        await quotes.updateFullQuote(/*doubleQuoteReplace.mapObjects(*/data/*)*/, data.quote.Id);
      }
    };

    sync(() => saveToSalesforce());

    try {
      await quotes.updateFullQuote(/*doubleQuoteReplace.mapObjects(*/data/*)*/, data.quote.Id);
    } catch (error) {
      endSyncWithError(SyncMessages.CosmosError);

      toast(ToastTypes.Error, ToastMessages.SaveFailed);
      if (setIsLoading) {
        setIsLoading(false);
      }

      if (setAreDetailsLoading) {
        setAreDetailsLoading(false);
      }

      return;
    }

    // loadQuote(
    //   id,
    //   quoteRouteState,
    //   true,
    //   setQuoteLoading,
    //   setLinesLoading,
    //   setShowQuoteSetup,
    //   false,
    //   setQuoteSaving
    // );

    let toastMessage: ToastMessages;
    switch (action) {
      case QuoteActions.Save:
        toastMessage = ToastMessages.QuoteSaved;
        break;
      case QuoteActions.SubmitQuote:
        toastMessage = ToastMessages.QuoteSubmitted;
        break;
      case QuoteActions.Lock:
        toastMessage = ToastMessages.QuoteLocked;
        break;
      case QuoteActions.SendQuoteToCustomer:
        toastMessage = ToastMessages.QuoteSentToCustomer;
        break;
      case QuoteActions.SubmitToPlanning:
        toastMessage = ToastMessages.QuoteSentToPlanning;
        break;
      case QuoteActions.RevertToDraft:
        toastMessage = ToastMessages.QuoteRevertedToDraft;
        break;
    }
    toast(ToastTypes.Success, toastMessage);

    return;
  };

  // make sure the formQuoteLineItems is in dot notation object
  const syncFormQuoteLineItem = (formQuoteLineItems) => {
    const { quote, quoteLineItems } = quoteState;

    // clean up the form quote line items
    const _formQuoteLineItems = formQuoteLineItems.filter((fqli) => {
      return quoteLineItems.find((qli) => {
        if (fqli.Id) {
          return fqli.Id === qli.Id;
        }

        return fqli.displayId === qli.displayId;
      });
    });

    const newQuoteLineItems = quoteLineItems.map((qli) => {
      const newItem = {
        ...qli,
      };

      const fqli = _formQuoteLineItems.find((mfqli: any) => {
        if (mfqli.Id) {
          return mfqli.Id === newItem.Id;
        }

        return mfqli.displayId === newItem.displayId;
      });

      Object.keys(fqli).forEach((key) => {
        const value = fqli[key];

        _.set(newItem, key, value);
      });

      return {
        ...newItem,
        _requiredField: undefined,
      };
    });
    const newQuoteLineItemDetails = newQuoteLineItems.map((nqlid) => {
      return {
        ...nqlid.quoteLineItemDetail,
      };
    });

    dispatchQuoteState({ type: QuoteActionTypes.loadQuoteLineItems, payload: [...newQuoteLineItems] });
    dispatchQuoteState({ type: QuoteActionTypes.loadQuoteLineItemDetails, payload: [...newQuoteLineItemDetails] });

    return {
      ...quote,
      quoteLineItems: newQuoteLineItems,
    };

    // dispatchQuoteState({type: QuoteActionTypes.setItem, payload: {
    //   field: 'quote',
    //   value: {
    //     ...quote,
    //     quoteLineItems: newQuoteLineItems,
    //   },
    // }});
    // dispatchQuoteState({
    //   type: QuoteActionTypes.setItem,
    //   payload: {
    //     field: 'quoteLineItems',
    //     value: newQuoteLineItems,
    // }});
    // dispatchQuoteState({
    //   type: QuoteActionTypes.setItem,
    //   payload: {
    //     field: 'quoteLineItemDetails',
    //     value: newQuoteLineItemDetails,
    // }});
  };

  const providerValue = {
    quoteState,
    loadQuote,
    loadQuoteLineItemDetails,
    saveQuote,
    saveQuoteLineItem,
    handleLeftoverQuantitiesForAutocombine,

    syncFormQuoteLineItem,
  };

  return (
    <QuoteDispatchContext.Provider value={{ dispatchQuoteState }}>
      <QuoteContext.Provider value={providerValue}>{children}</QuoteContext.Provider>
    </QuoteDispatchContext.Provider>
  );
};

// export useQuoteState hook
export const useQuoteState = () => {
  const context: InitStateContextProps = useContext(QuoteContext);
  if (!context) {
    throw new Error('You probably forgot the <QuoteProvider> context provider');
  }
  return context;
};

// export useQuoteDispatch hook
export const useQuoteDispatch = () => {
  const dispatch: InitDispatchContextProps = useContext(QuoteDispatchContext);
  if (!dispatch) {
    throw new Error('You probably forgot the <QuoteProvider> context provider');
  }
  return dispatch;
};

/**  Usage examples:
const { dataState } = useDataState();
const { dispatchDataState } = useDataDispatch();
dispatchDataState({ type: DataActionTypes.loadRawMaterials, payload: [] });
*/
