import React, { useEffect, useState } from "react";
import { IonLabel, IonButton, IonIcon } from "@ionic/react"
import backArrow from '@assets/icon/arrow_back.svg';
import forwardArrow from '@assets/icon/arrow_forward.svg';
import { useQuoteState } from "@context/quote-context";
import { useComboGroup, ComboGroupActionTypes } from "@context/combo-group-context";

import './metal-combo.scss';

const MetalCombo = () => {
  const {
    quoteState,
  } = useQuoteState();
  const {
    state: comboGroupState,
    dispatch: comboGroupDispatch,
  } = useComboGroup();
  const { comboGroup, selectedKey } = comboGroupState;
  const { quote } = quoteState;
  const { quoteLineItems } = quote || {};
  const comboItems = Object.keys(comboGroup)
    .filter((key) => {
      const comboId = comboGroup[key][0];
      return quoteLineItems.find((lineItem) => (lineItem.Id || lineItem.displayId) === comboId);
    })
    .map((key: string) => {
      const comboId = comboGroup[key][0];
      const {
        CladMetal__c,
        BaseMetal__c
      } = quoteLineItems.find((lineItem) => (lineItem.Id || lineItem.displayId) === comboId);
      return {
        key,
        CladMetal__c,
        BaseMetal__c
      };
    });

  const comboItemIndex = comboItems.findIndex(ci => ci.key === selectedKey);
  const [ selectedIndex, setSelectedIndex] = useState(comboItemIndex);
  const item: any = comboItems[selectedIndex] || {};

  useEffect(() => {
    if (!comboItems[comboItemIndex] || !comboItems[comboItemIndex].key) {
      setSelectedIndex(0);
    }
  }, []);

  const onSetSelected = (newIndex) => {
    setSelectedIndex(newIndex);
    comboGroupDispatch({
      type: ComboGroupActionTypes.setSelectedKey,
      payload: comboItems[newIndex].key,
    });
  }

  const onClickNext = () => {
    onSetSelected(selectedIndex + 1);
  };

  const onClickPrev = () => {
    onSetSelected(selectedIndex - 1);
  }

  return <>
    <div className='metal-combo'>
      <div className='metal-combo-labels'>
        <IonLabel>Clad Metal</IonLabel>
        <IonLabel>Base Metal</IonLabel>
      </div>
      <div className='metal-combo-container'>
          <IonButton
            color='secondary'
            disabled={selectedIndex === 0}
            onClick={onClickPrev}
          >
            <IonIcon slot='icon-only' class='prev-icon' src={backArrow} />
          </IonButton>
          <div className='metal-combo-item-labels'>
            <IonLabel>
              {item.CladMetal__c}
            </IonLabel>
            <IonLabel>
              {item.BaseMetal__c}
            </IonLabel>
          </div>
          <IonButton
            color='secondary'
            disabled={selectedIndex === (comboItems.length -1)}
            onClick={onClickNext}
          >
            <IonIcon slot='icon-only' class='next-icon' src={forwardArrow} />
          </IonButton>
      </div>
    </div>
  </>
}

export default MetalCombo;