import React, { useState, useRef } from 'react';

import './accordian-section.scss';
import { chevronUpOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
interface Props {
  title: string | number | React.ReactChild | React.ReactFragment | React.ReactPortal;
  content: string | number | React.ReactChild | React.ReactFragment | React.ReactPortal;
  expandedMap: Map<string, boolean>;
  setExpandedMap: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
  label: string;
}

function Accordion({title, content, expandedMap, setExpandedMap, label} : Props) {
  const [activeState, setActiveState] = useState(expandedMap?.get(label) ? 'active-accordian-section-header' : '');
  const [visibleState, setVisibleState] = useState(expandedMap?.get(label));
  const [rotateState, setRotateState] = useState(expandedMap?.get(label) ? 'accordion-section-icon rotate180' : 'accordion-section-icon');

  const contentRef = useRef(null);

  React.useEffect(() => {
    if(expandedMap) {
      const expanded = expandedMap?.get(label);

      setActiveState(expanded ? 'active-accordian-section-header' : '');
      setVisibleState(expanded ? true : false);
      setRotateState(expanded ? 'accordion-section-icon rotate180' : 'accordion-section-icon');
    }
  }, [expandedMap]);

  function toggleAccordion() {
    setActiveState(activeState === '' ? 'active-accordian-section-header' : '');
    setVisibleState(activeState === 'active-accordian-section-header' ? false : true);
    setRotateState(
      activeState === 'active-accordian-section-header' ? 'accordion-section-icon' : 'accordion-section-icon rotate180'
    );
    
    if(expandedMap) {
      if(![...expandedMap.values()].some(value => value) || ([...expandedMap.values()].filter(value => value).length === 1 && activeState !== '')) {
        const updatedExpandedMap = new Map(expandedMap);
        updatedExpandedMap.set(label, activeState === '');
        setExpandedMap(updatedExpandedMap);
      } else {
        expandedMap.set(label, activeState === '');
      }
    }
  }

  return (
    <>
      <div className='accordion-section'>
        <div className={`accordion-section-button ${activeState}`} onClick={toggleAccordion}>
          {title}
          <IonIcon className={`${rotateState}`} src={chevronUpOutline} />
        </div>
        <div ref={contentRef} style={{ display: `${visibleState ? 'block' : 'none'}` }} className='accordion-section-content'>
          {content}
        </div>
      </div>
    </>
  );
}

export default Accordion;
