import * as React from 'react';
import { IonLabel } from '@ionic/react';
import classNames from 'classnames';

import './breadcrumb-navigation.scss';

interface Props {
  breadcrumbItems: BreadcrumbLabel[];
}

interface BreadcrumbLabel {
  label: string;
  goTo?: () => void;
  // example - goTo: () => { history.push('/quotes/quote-detail/${quoteId}/advanced-specifications'); }
  // example - goTo: () => navigateToRoute(PageRoutes.Quotes, history, quote?.Id, routeChangeAlert)
}
// navigation breadcrumbs url bar, ie:   Quotes / Quote Id / Advanced Specifications
// the last item is the current page and not a clickable nav link *see usage example below
const BreadcrumbNavigation = ({ breadcrumbItems }: Props): React.ReactElement | JSX.Element => {
  return (
    <>
      <IonLabel class='breadcrumb-navigation-title'>
        {breadcrumbItems.map((page, index) => (
          <React.Fragment key={page.label}>
            <span
              className={classNames({
                'breadcrumb-navigation-parent-title-link': index !== breadcrumbItems.length - 1,
              })}
              onClick={page.goTo ? page.goTo : null}
            >
              {page.label}
            </span>
            {index !== breadcrumbItems.length - 1 ? ' / ' : null}
          </React.Fragment>
        ))}
      </IonLabel>
    </>
  );
};

export default BreadcrumbNavigation;

/** Example Usage:

 <BreadcrumbNavigation
   // navigation bread crumbs url bar, ie: Quotes / Quote Id / Advanced Specifications
   breadcrumbItems={[
     {
       label: 'Quotes',
       goTo: () => navigateToRoute(PageRoutes.Quotes, history, quote?.Id, routeChangeAlert),
     },
     {
       label: quote?.Name,
       goTo: () => navigateToRoute(PageRoutes.QuoteDetail, history, quote.Id, routeChangeAlert),
     },
     { label: 'Advanced Specifications' },
   ]}
 />;

 */
