import { IonIcon } from '@ionic/react';
import * as React from 'react';
import { attachOutline } from 'ionicons/icons';
import './line-item-attachment.scss';

const LineItemAttachment = (props) => {
  return (props.visible ?
    <div className='attachment-container'>
      <IonIcon data-testid="QALineItemAttachmentIndicator" class='paperclip-icon' icon={attachOutline}></IonIcon>
    </div> : null
  );
};

export default LineItemAttachment;