import React, { useEffect } from 'react';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import { TensileTestLocations } from '@constants';

const AppDropdownTesileTest = (props) => {
  const options = [
    TensileTestLocations.PerSpecification,
    TensileTestLocations.SpecificationCenterline,
    TensileTestLocations.CenterlineOnly12T,
  ].map((item) => {
    return {
      label: item,
      value: item
    };
  });

  return <AppDropdown options={options} {...props}/>
};

export default AppDropdownTesileTest;