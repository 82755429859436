import { FormKeyMapper as AdvanceSpecFormKeyMapper, FormKeys as AdvanceSpecFormKeys, FormKeyHandler } from '@pages/new-advanced-specifications/advance-specification-form-util';
import _ from 'lodash-es';

export enum AssemblyGroupFormKeys {
  Item_Type = 'Item_Type__c',
  CurrencyIsoCode= 'CurrencyIsoCode',
  BaseMetal = 'BaseMetal__c',
  CladMetal = 'CladMetal__c',
  Unit_of_Measure = 'Unit_of_Measure__c',
  Additional_Options = 'Additional_Options__c',

  // table info
  Line = 'Line__c',
  Quantity = 'Quantity__c',
  Width_in = 'Width_in__c',
  Width_mm = 'Width_mm__c',
  Length_in = 'Length_in__c',
  Length_mm = 'Length_mm__c',
  Shape = 'Shape__c',
  TotalAdjPrice = 'TotalAdjPrice__c',

  Base_LB_PC = 'Base_LB_PC__c',
  Base_KG_PC = 'Base_KG_PC__c',

  // metric
  Clad_Nom_mm = 'Clad_Nom_mm__c',
  Clad_Min_mm = 'Clad_Min_mm__c',
  Base_Nom_mm = 'Base_Nom_mm__c',
  Base_Min_mm = 'Base_Min_mm__c',
  Ship_Weight_LB = 'Ship_Weight_LB__c',
  ItemDescriptionMetric = 'ItemDescriptionMetric__c',
  Total_TK_MM = 'Total_TK_MM__c',

  // imperial
  Clad_Nom_in = 'Clad_Nom_in__c',
  Clad_Min_in = 'Clad_Min_in__c',
  Base_Nom_in = 'Base_Nom_in__c',
  Base_Min_in = 'Base_Min_in__c',
  Ship_Weight_KG = 'Ship_Weight_KG__c',
  ItemDescriptionImp = 'ItemDescriptionImp__c',
  Total_TK_IN = 'Total_TK_IN__c',

  FinishedGoodHeatTreatment = 'quoteLineItemDetail.FinishedGoodHeatTreatment__c',
  PostCladBondUTSpecs = 'quoteLineItemDetail.PostCladBondUTSpecification__c',
  PostCladBaseMetalUT = 'quoteLineItemDetail.PostCladBaseMetalUTSpecification__c',
  CladProductionSpecification = 'quoteLineItemDetail.CladProductSpecification__c',
  CladProductionSpecificationNotes = 'quoteLineItemDetail.CladProductionSpecificationNotes__c',

  // Cyclinder
  CylinderType = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.CylinderType__c',
  Diameter = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.Diameter__c',
  IDorOD = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.IDorOD__c',
  EdgePreparation = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.EdgePreparation__c',
  RollingCostPerCylinder = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.RollingCostPerCylinder__c',
  FreightChargeToCustomerPer = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.FreightChargeToCustomerPer__c',
  FreightChargeToRollerPer = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.FreightChargeToRollerPer__c',
  TransitTimeToRollerWeeks = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.TransitTimeToRollerWeeks__c',
  TimeToRollWeeks = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.TimeToRollWeeks__c',
  QuantityPerCylinder = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.QuantityPerCylinder__c',
  PlateWidthSupplyToFormer = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.PlateWidthSupplyToFormer__c',
  PlateLengthSupplyToFormer = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.PlateLengthSupplyToFormer__c',
  CylinderNotes = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.CylinderNotes__c',
  CylinderVendor = 'quoteLineItemDetail.NC_Cylinder_Item_Details__r.CylinderVendor__c',

  // Head
  SimulateHeatTreatmentHeadForming = 'quoteLineItemDetail.SimulateHeatTreatmentHeadForming__c',
  HeadQuantity = 'quoteLineItemDetail.NC_Head_Item_Detail__r.HeadQuantity__c',
  BlankSize = 'quoteLineItemDetail.NC_Head_Item_Detail__r.BlankSize__c',
  HeadDiameter = 'quoteLineItemDetail.NC_Head_Item_Detail__r.HeadDiameter__c',
  HeadFormingType = 'quoteLineItemDetail.NC_Head_Item_Detail__r.HeadFormingType__c',
  StraightFlange = 'quoteLineItemDetail.NC_Head_Item_Detail__r.StraightFlange__c',
  HeadMaxCenterHole = 'quoteLineItemDetail.NC_Head_Item_Detail__r.HeadMaxCenterHole__c',
  OpenEnd = 'quoteLineItemDetail.NC_Head_Item_Detail__r.OpenEnd__c',
  OpenEndNotes = 'quoteLineItemDetail.NC_Head_Item_Detail__r.OpenEndNotes__c',
  PetalToPetal = 'quoteLineItemDetail.NC_Head_Item_Detail__r.PetalToPetal__c',
  PetalToPetalNotes = 'quoteLineItemDetail.NC_Head_Item_Detail__r.PetalToPetalNotes__c',
  CrownToPetals = 'quoteLineItemDetail.NC_Head_Item_Detail__r.CrownToPetals__c',
  CrownToPetalsNotes = 'quoteLineItemDetail.NC_Head_Item_Detail__r.CrownToPetalsNotes__c',
  HeadNumberOfPieces = 'quoteLineItemDetail.NC_Head_Item_Detail__r.HeadNumberOfPieces__c',
  BlankCrownCutByNC = 'quoteLineItemDetail.NC_Head_Item_Detail__r.BlankCrownCutByNC__c',
  PetalsCutByNC = 'quoteLineItemDetail.NC_Head_Item_Detail__r.PetalsCutByNC__c',
  OtherCladGroupsCombinedInThisHead = 'quoteLineItemDetail.NC_Head_Item_Detail__r.OtherCladGroupsCombinedInThisHead__c',
  NobelCladProcedure = 'quoteLineItemDetail.NC_Head_Item_Detail__r.NobelCladProcedure__c',
  NCChemistryRestrictionsForBonding = 'quoteLineItemDetail.NC_Head_Item_Detail__r.NCChemistryRestrictionsForBonding__c',
  NCChemistryRestrictionsForBondingNotes = 'quoteLineItemDetail.NC_Head_Item_Detail__r.NCChemistryRestrictionsForBondingNotes__c',
  HeadFormingVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.HeadFormingVendor__c',
  HeadFormingIDvsOD = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.HeadFormingIDvsOD__c',
  OutsideServiceSimulateHeatTreatmentAtVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.SimulateHeatTreatmentAtVendor__c',

  HeadFormingCostPerHead = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.HeadFormingCostPerHead__c',
  HeadFreightCostToFormer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.HeadFreightCostToFormer__c',
  HeadTimeToFormer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.HeadTimeToFormer__c',
  HeadTimeToFormHead = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.HeadTimeToFormHead__c',
  HeadContourToleranceHTOptions = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.HeadContourToleranceHTOptions__c',
  AdditionalHeadInformation = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.AdditionalHeadInformation__c',
  HeadFormingNotes1 = 'quoteLineItemDetail.NC_Head_Item_Detail__r.HeadFormingNotes1__c',
  InsideDishRadius = 'quoteLineItemDetail.NC_Head_Item_Detail__r.InsideDishRadius__c',
  Cutback = 'quoteLineItemDetail.NC_Head_Item_Detail__r.Cutback__c',
  InsideKnuckleRadius = 'quoteLineItemDetail.NC_Head_Item_Detail__r.InsideKnuckleRadius__c',

  AdditionalHeadInformation_HID = 'quoteLineItemDetail.NC_Head_Item_Detail__r.AdditionalHeadInformation__c',
  HeadContourToleranceHTOptions_HID = 'quoteLineItemDetail.NC_Head_Item_Detail__r.HeadContourToleranceHTOptions__c',
  
  SimulationHTCycle1 = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.SimulationHTCycle1__c',
  SimulationHTCycle2 = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.SimulationHTCycle2__c',
  SimulationHTCycle3 = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.SimulationHTCycle3__c',
  SimulationHTCycle4 = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.SimulationHTCycle4__c',
  SimulationHTCycle5 = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.SimulationHTCycle5__c',

  NCInhouseStressReliefDetails = 'quoteLineItemDetail.NCInhouseStressReliefDetails__c',
  NCInhouseStressRefliefChecked = 'quoteLineItemDetail.StressReliefHT__c',
  SimulateHeatTreatmentAtVendor = 'quoteLineItemDetail.SimulateHeatTreatmentAtVendor__c',
  NCInhouseStressRefliefTimeMin = 'quoteLineItemDetail.NCInhouseStressRefliefTimeMin__c',
  NCInHouseStressReliefTimeMax = 'quoteLineItemDetail.NCInHouseStressReliefTimeMax__c',
  NCInhouseStressReliefTemp = 'quoteLineItemDetail.NCInhouseStressReliefTemp__c',

  PreHeatReqforcuttingmintempforpreheat = 'quoteLineItemDetail.PreHeatReqforcuttingmintempforpreheat__c',
  PreHeatRequiredforCladding = 'quoteLineItemDetail.PreHeatRequiredforCladding__c',
  PreheattoCut = 'quoteLineItemDetail.PreheattoCut__c',
  PreHeatReqforcladdingMinTempforPreheat = 'quoteLineItemDetail.PreHeatReqforcladdingMinTempforPreheat__c',
  PostCladFlattening = 'quoteLineItemDetail.PostCladFlattening__c',
  FlatteningNotes = 'quoteLineItemDetail.FlatteningNotes__c',

  Machining = 'quoteLineItemDetail.Machining__c',
  MachiningNotes = 'quoteLineItemDetail.MachiningNotes__c',

  Cut_Method = 'Cut_Method__c',
  PostCladCuttingTolerance = 'quoteLineItemDetail.PostCladCuttingTolerance__c',
  SurfaceTreatment = 'quoteLineItemDetail.SurfaceTreatment__c',
  SurfaceTreatmentNotes = 'quoteLineItemDetail.SurfaceTreatmentNotes__c',
  NCInHousePackaging = 'quoteLineItemDetail.NCInHousePackaging__c',
  PackagingNotes = 'quoteLineItemDetail.PackagingNotes__c',

  // outside service[testing service backer]
  TestingBKDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingBKDurationOfEventDays__c',
  TestingBKTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingBKTotalTransitTimeDays__c',
  TestingBKCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingBKCostOfEvent__c',
  TestingBKOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingBKOutboundShippingCost__c',
  TestingBKTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingBKTotalOutboundShippingCost__c',
  TestingBKInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingBKInboundShippingCost__c',
  TestingBKTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingBKTotalInboundShippingCost__c',
  TestingBKVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingBKVendor__c',
  TestingBKShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingBKShipToCustomer__c',
  TestingBKNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingBKNotes__c',

  // outside service[testing service cladder]
  TestingCLDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCLDurationOfEventDays__c',
  TestingCLTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCLTotalTransitTimeDays__c',
  TestingCLCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCLCostOfEvent__c',
  TestingCLOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCLOutboundShippingCost__c',
  TestingCLTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCLTotalOutboundShippingCost__c',
  TestingCLInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCLInboundShippingCost__c',
  TestingCLTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCLTotalInboundShippingCost__c',
  TestingCLVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCLVendor__c',
  TestingCLShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCLShipToCustomer__c',
  TestingCLNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCLNotes__c',

  // outside service[testing service clad product]
  TestingDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingDurationOfEventDays__c',
  TestingTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingTotalTransitTimeDays__c',
  TestingCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingCostOfEvent__c',
  TestingOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingOutboundShippingCost__c',
  TestingTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingTotalOutboundShippingCost__c',
  TestingInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingInboundShippingCost__c',
  TestingTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingTotalInboundShippingCost__c',
  TestingVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingVendor__c',
  TestingShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingShipToCustomer__c',
  TestingNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.TestingNotes__c',

  // outside service[backer metal heat treatment]
  BackerMetalHTDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTDurationOfEventDays__c',
  BackerMetalHTTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTTotalTransitTimeDays__c',
  BackerMetalHTCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTCostOfEvent__c',
  BackerMetalHTInboundShippingCostKG = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTInboundShippingCostKG__c',
  BackerMetalHTOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTOutboundShippingCost__c',
  BackerMetalHTTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTTotalOutboundShippingCost__c',
  BackerMetalHTInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTInboundShippingCost__c',
  BackerMetalHTTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTTotalInboundShippingCost__c',
  BackerMetalHTVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTVendor__c',
  BackerMetalHTNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTNotes__c',
  BackerMetalHTShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTShipToCustomer__c',
  BackerMetalHTType = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTType__c',
  BackerMetalHTTotalCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.BackerMetalHTTotalCostOfEvent__c',

  // outside service[cladder metal heat treatment]
  CladderMetalHTDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTDurationOfEventDays__c',
  CladderMetalHTTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTTotalTransitTimeDays__c',
  CladderMetalHTCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTCostOfEvent__c',
  CladderMetalHTInboundShippingCostKG = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTInboundShippingCostKG__c',
  CladderMetalHTOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTOutboundShippingCost__c',
  CladderMetalHTTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTTotalOutboundShippingCost__c',
  CladderMetalHTInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTInboundShippingCost__c',
  CladderMetalHTTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTTotalInboundShippingCost__c',
  CladderMetalHTVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTVendor__c',
  CladderMetalHTNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTNotes__c',
  CladderMetalHTShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTShipToCustomer__c',
  CladderMetalHTType = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTType__c',
  CladderMetalHTTotalCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CladderMetalHTTotalCostOfEvent__c',

  // outside service[postbond metal heat treatment]
  PostBondHTDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTDurationOfEventDays__c',
  PostBondHTTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTTotalTransitTimeDays__c',
  PostBondHTCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTCostOfEvent__c',
  PostBondHTOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTOutboundShippingCost__c',
  PostBondHTTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTTotalOutboundShippingCost__c',
  PostBondHTInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTInboundShippingCost__c',
  PostBondHTTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTTotalInboundShippingCost__c',
  PostBondHTVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTVendor__c',
  PostbondHTNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTNotes__c',
  PostBondHTShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTShipToCustomer__c',
  PostBondHTType = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTType__c',
  PostBondHTTotalCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PostbondHTTotalCostOfEvent__c',

  // outside service[welding]
  WeldingDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WeldingDurationOfEventDays__c',
  WeldingTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WeldingTotalTransitTimeDays__c',
  WeldingCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WeldingCostOfEvent__c',
  WeldingOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WeldingOutboundShippingCost__c',
  WeldingTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WeldingTotalOutboundShippingCost__c',
  WeldingInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WeldingInboundShippingCost__c',
  WeldingTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WeldingTotalInboundShippingCost__c',
  WeldingVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WeldingVendor__c',
  WeldingNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WeldingNotes__c',
  WeldingShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WeldingShipToCustomer__c',

  // outside service[xray]
  XRayServicesDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.XRayServicesDurationOfEventDays__c',
  XRayServicesTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.XRayServicesTotalTransitTimeDays__c',
  XRayServicesCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.XRayServicesCostOfEvent__c',
  XRayServicesOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.XRayServicesOutboundShippingCost__c',
  XRayServicesTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.XRayServicesTotalOutboundShippingCost__c',
  XRayServicesInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.XRayServicesInboundShippingCost__c',
  XRayServicesTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.XRayServicesTotalInboundShippingCost__c',
  XRayServicesVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.XRayServicesVendor__c',
  XRayServicesNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.XRayServicesNotes__c',
  XRayServicesShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.XRayServicesShipToCustomer__c',

  // outside service[flattening before cutting]
  FlatteningBKDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningBKDurationOfEventDays__c',
  FlatteningBKTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningBKTotalTransitTimeDays__c',
  FlatteningBKCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningBKCostOfEvent__c',
  FlatteningBKOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningBKOutboundShippingCost__c',
  FlatteningBKTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningBKTotalOutboundShippingCost__c',
  FlatteningBKInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningBKInboundShippingCost__c',
  FlatteningBKTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningBKTotalInboundShippingCost__c',
  FlatteningBKVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningBKVendor__c',
  FlatteningBKNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningBKNotes__c',
  FlatteningBKShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningBKShipToCustomer__c',

  // outside service[cutting]
  CuttingDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CuttingDurationOfEventDays__c',
  CuttingTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CuttingTotalTransitTimeDays__c',
  CuttingCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CuttingCostOfEvent__c',
  CuttingOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CuttingOutboundShippingCost__c',
  CuttingTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CuttingTotalOutboundShippingCost__c',
  CuttingInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CuttingInboundShippingCost__c',
  CuttingTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CuttingTotalInboundShippingCost__c',
  CuttingVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CuttingVendor__c',
  CuttingNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CuttingNotes__c',
  CuttingShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.CuttingShipToCustomer__c',

  // outside service[waterjet cutting]
  WaterJetCuttingDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingDurationOfEventDays__c',
  WaterJetCuttingTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingTotalTransitTimeDays__c',
  WaterJetCuttingCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingCostOfEvent__c',
  WaterJetCuttingOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingOutboundShippingCost__c',
  WaterJetCuttingTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingTotalOutboundShippingCost__c',
  WaterJetCuttingInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingInboundShippingCost__c',
  WaterJetCuttingTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingTotalInboundShippingCost__c',
  WaterJetCuttingVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingVendor__c',
  WaterJetCuttingNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingNotes__c',
  WaterJetCuttingShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.WaterJetCuttingShipToCustomer__c',

  // outside service[flattening after cutting]
  FlatteningDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningDurationOfEventDays__c',
  FlatteningTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningTotalTransitTimeDays__c',
  FlatteningCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningCostOfEvent__c',
  FlatteningOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningOutboundShippingCost__c',
  FlatteningTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningTotalOutboundShippingCost__c',
  FlatteningInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningInboundShippingCost__c',
  FlatteningTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningTotalInboundShippingCost__c',
  FlatteningVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningVendor__c',
  OutsideServiceFlatteningNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningNotes__c',
  FlatteningShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.FlatteningShipToCustomer__c',

  // outside service[machining]
  MachiningDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.MachiningDurationOfEventDays__c',
  MachiningTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.MachiningTotalTransitTimeDays__c',
  MachiningCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.MachiningCostOfEvent__c',
  MachiningOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.MachiningOutboundShippingCost__c',
  MachiningTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.MachiningTotalOutboundShippingCost__c',
  MachiningInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.MachiningInboundShippingCost__c',
  MachiningTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.MachiningTotalInboundShippingCost__c',
  MachiningVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.MachiningVendor__c',
  OutsideServiceMachiningNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.MachiningNotes__c',
  MachiningShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.MachiningShipToCustomer__c',

  // outside service[inspection backer]
  InspectionBKDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionBKDurationOfEventDays__c',
  InspectionBKTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionBKTotalTransitTimeDays__c',
  InspectionBKCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionBKCostOfEvent__c',
  InspectionBKOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionBKOutboundShippingCost__c',
  InspectionBKTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionBKTotalOutboundShippingCost__c',
  InspectionBKInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionBKInboundShippingCost__c',
  InspectionBKTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionBKTotalInboundShippingCost__c',
  InspectionBKVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionBKVendor__c',
  InspectionBKNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionBKNotes__c',
  InspectionBKShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionBKShipToCustomer__c',

  // outside service[inspection cladder]
  InspectionCLDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCLDurationOfEventDays__c',
  InspectionCLTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCLTotalTransitTimeDays__c',
  InspectionCLCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCLCostOfEvent__c',
  InspectionCLOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCLOutboundShippingCost__c',
  InspectionCLTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCLTotalOutboundShippingCost__c',
  InspectionCLInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCLInboundShippingCost__c',
  InspectionCLTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCLTotalInboundShippingCost__c',
  InspectionCLVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCLVendor__c',
  InspectionCLNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCLNotes__c',
  InspectionCLShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCLShipToCustomer__c',

  // outside service[inspection clad product]
  InspectionDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionDurationOfEventDays__c',
  InspectionTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionTotalTransitTimeDays__c',
  InspectionCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionCostOfEvent__c',
  InspectionOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionOutboundShippingCost__c',
  InspectionTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionTotalOutboundShippingCost__c',
  InspectionInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionInboundShippingCost__c',
  InspectionTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionTotalInboundShippingCost__c',
  InspectionVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionVendor__c',
  InspectionNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionNotes__c',
  InspectionShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.InspectionShipToCustomer__c',

  // outside service[inspection clad product]
  PackagingDurationOfEventDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PackagingDurationOfEventDays__c',
  PackagingTotalTransitTimeDays = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PackagingTotalTransitTimeDays__c',
  PackagingCostOfEvent = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PackagingCostOfEvent__c',
  PackagingOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PackagingOutboundShippingCost__c',
  PackagingTotalOutboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PackagingTotalOutboundShippingCost__c',
  PackagingInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PackagingInboundShippingCost__c',
  PackagingTotalInboundShippingCost = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PackagingTotalInboundShippingCost__c',
  PackagingVendor = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PackagingVendor__c',
  OutsideServicePackagingNotes = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PackagingNotes__c',
  PackagingShipToCustomer = 'quoteLineItemDetail.NC_Outside_Service_Item_Details__r.PackagingShipToCustomer__c',

  // outside service[freight]
  Total_Freight_LB = 'Total_Freight_LB__c',
  Total_Freight_KG = 'Total_Freight_KG__c',
};

export const FormKeyMapper = {
  // [AssemblyGroupFormKeys.PostCladFlattening]: (({ value }) => strToLabelValue(value) ),
  // [AssemblyGroupFormKeys.Cut_Method]: (({ value }) => strToLabelValue(value) ),
  // [AssemblyGroupFormKeys.SurfaceTreatment]: (({ value }) => strToLabelValue(value) ),
  // [AssemblyGroupFormKeys.NCInHousePackaging]: (({ value }) => strToLabelValue(value) ),
  // [AssemblyGroupFormKeys.NCInHousePackaging]: (({ value }) => strToLabelValue(value) ),
  // [AssemblyGroupFormKeys.BackerMetalHTType]: (({ value }) => strToLabelValue(value) ),
  // [AssemblyGroupFormKeys.CladderMetalHTType]: (({ value }) => strToLabelValue(value) ),
  // [AssemblyGroupFormKeys.PostBondHTType]: (({ value }) => strToLabelValue(value) ),
};

const MergeFormKeys = {
  ...AdvanceSpecFormKeys,
  ...AssemblyGroupFormKeys,
};

const MergeFormKeyMapper = {
  ...AdvanceSpecFormKeyMapper,
  ...FormKeyMapper,
};

export const MergeFormKeyHandler = {
  ...FormKeyHandler,
};

export const initFormState = (quoteLineItem: any = {}, FK: any = MergeFormKeys) => {
  return Object.keys(FK).reduce((prev, current) => {
    const key = FK[current];
    prev[key] = MergeFormKeyMapper[key]
      ? MergeFormKeyMapper[key]({
          key: key,
          lineItem: quoteLineItem,
          value: _.get(quoteLineItem, key, undefined)
        })
      : quoteLineItem[key] ?? _.get(quoteLineItem, key, undefined);
    return prev;
  }, {});
};

export const toDotNotation = (payload, formKeys: any = MergeFormKeys) => {
  return Object.keys(formKeys).reduce((prev, curr) => {
    const key = formKeys[curr];
    prev[key] = _.get(payload, key);

    return prev;
  }, {});
};