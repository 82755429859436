import { IonLabel } from '@ionic/react';
import React from 'react';
import { formatCurrency } from '@shared/utils';
import { Currency } from '@interfaces/salesforce';

interface Props {
  children?: any;
  currency?: Currency
  value?: any,
  minFractionDigits?: number
};

export const AppLabelCurrency = ({ value, children, currency = 'USD', minFractionDigits }: Props) => {
  const _value = formatCurrency(value || children, currency, minFractionDigits);

  return <>
    <IonLabel> { _value } </IonLabel>
  </>
}