import EmptyTableRow from '@components/empty-table-row/empty-table-row';
import NotificationLine from '@components/notification-line/notification-line';
import * as React from 'react';
import { Notification } from '@interfaces/notification';
interface Props {
  striping?: boolean;
  actionsVisible?: boolean;
  pageItems?: Notification[];
  unmappedNotifications: Notification[];
  loadNotifications: () => void;
  displayedNotifications: Notification[];
}

const NotificationsList = (props: Props) => {
  const displayNotifs = props.pageItems ? props.pageItems : props.displayedNotifications;

  return (displayNotifs?.length ?
    <>{displayNotifs.map((notification, index) => (
      <NotificationLine
        notification={notification}
        notificationIndex={index}
        numNotifications={displayNotifs.length}
        striping={props.striping}
        actionsVisible={props.actionsVisible}
        reloadNotifications={props.loadNotifications}
        unmappedNotification={props.unmappedNotifications.find(unMapNotif => unMapNotif.id === notification.id)}
        key={index}
      />
    ))}</> :
    <EmptyTableRow text={'No Notifications Available'}/>
  );
};

export default NotificationsList;