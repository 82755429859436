import * as React from 'react';

import { chevronDown, chevronUp } from 'ionicons/icons';
import infoIcon from '@assets/icon/info-icon.svg';

import { IonButton, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonPopover, IonRow, useIonAlert } from '@ionic/react';

import './msto.scss';

import {
  AllocationOptions,
  FieldTypes,
  MeasurementSystems,
  MetalTypesForAreaCalc,
  MSTOActions,
  MSTOAssemblyMetals,
  MSTOColumnHeadings,
  MSTOPopovers,
  MSTOSelectionTabs,
  MSTOSupplierTypes,
  PageRoutes,
  PanelLocations,
  QuoteLineItemDependentUnwrittenFields,
  QuoteLineItemFields,
  QuoteLineItemFieldUnitTypes,
  QuoteLineItemValueTypes,
  QuoteVariableConfigDescriptions,
  RawMaterialUsage,
  ToastMessages,
  ToastTypes,
  TooltipPositions,
  Units,
  Shapes,
  QuoteActions,
  NCSupplier,
} from '@constants';

import {
  AnvilAssemblyDisplayMapValueType,
  AssemblyPanels,
  BackerAssemblyDisplayMapValueType,
  CladderAssemblyDisplayMapValueType,
  FieldsMapValueType,
  MSTOAnvilItemDetailFieldsToUpdate,
  MSTOAssemblyDisplayMapTypes,
  MSTOQuoteLineItemDetailFieldsToUpdate,
  MSTOUseAsIsDetailFieldsToUpdate,
  Panels,
  PanelsMap,
  SeamPanels
} from '@shared/types';

import LineItemField from '@components/line-item-field/line-item-field';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
import SubActiveTab from '@components/tab/msto-active-tab';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import {
  addFieldToMapAndUpdatedList,
  addToPanelsMapForSplitOutLines,
  calculatePercentAreaAllocated,
  calculateRawArea,
  calculateUOMValue,
  duplicatedQuoteLineItem,
  filterMSTOAssemblyFields,
  filterMSTOPanelFields,
  getPurchaseDeltas,
  getQuoteVariableConfiguration,
  removeFromPanelsMapForSplitOutLines,
} from '@shared/quote-utils';

import MultiActionButton from '@components/multi-action-button/multi-action-button';
import AddPiecePopover from '@components/popover/add-piece-popover/add-piece-popover';
import { useToast } from '@hooks/useToast';
import moment from 'moment';
import classNames from 'classnames';
import SeamWeldPopover from '@components/popover/seam-weld-popover/seam-weld-popover';
import Tooltip from '@components/tooltip/tooltip';
import SupplierPopover from '@components/popover/supplier-popover/supplier-popover';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { useHistory, useParams } from 'react-router-dom';
import { Buffer } from 'buffer';
import FloatingAction from '@components/floating-action/floating-action';
import orderEntryTab from '@assets/icon/Tab_Order.svg';
import orderEntryTabDarkMode from '@assets/icon/Tab_Order_DarkMode.svg';
import orderEntryTabHovered from '@assets/icon/Tab_Order_Hovered.svg';
import quoteTab from '@assets/icon/Tab_Quote.svg';
import quoteTabDarkMode from '@assets/icon/Tab_Quote_DarkMode.svg';
import quoteTabHovered from '@assets/icon/Tab_Quote_Hovered.svg';
import advSpecsTab from '@assets/icon/Tab_Adv_Specs.svg';
import advSpecsTabDarkMode from '@assets/icon/Tab_Adv_Specs_DarkMode.svg';
import advSpecsTabHovered from '@assets/icon/Tab_Adv_Specs_Hovered.svg';
import Loading from '@components/loading/loading';
import { convertUnits, navigateToRoute } from '@shared/utils';
import { DependentUnwrittenFieldsMapValueType, WeldingDimensionsMapValueType } from '@shared/types';
import { AdvSpecActionTypes, useAdvSpecStateDispatch } from '@context/adv-spec-context';
import UomDisplayToggle from '@components/uom-toggle/uom-toggle';
import { useDisplayUomWithInit } from '@context/useDisplayUomWithInit';

const NCSuppliers: string[] = [
  NCSupplier.Backer,
  NCSupplier.Cladder,
];

const MSTOPage = (): React.ReactElement | JSX.Element => {
  const {
    dataState,
    loadRawMaterials,
    loadFinishedGoods,
    loadFreight,
    loadContent,
    loadEdgeAllowances,
    loadSpecificationTestOptions,
    loadBoosterAllowances,
    loadSelections,
    loadQuoteVariableConfigurations,
    loadVendors,
    loadWeldingPatterns
  } = useDataState();

  const {
    rawMaterials,
    specificationTestOptions,
    selections,
    quoteVariableConfigurations,
    vendors,
    freight
  } = dataState;

  const {
    quoteState,
    loadQuote,
    loadQuoteLineItemDetails,
    saveQuoteLineItem,
    saveQuote
   } = useQuoteState();
  const { quote, quoteLineItemDetails } = quoteState;
  const { quoteLineItems, Manufacturing_Site__c: manufacturingSite } = quote || {};

   const { displayUom, isDisplayImperial } = useDisplayUomWithInit();

  const { id } = useParams<{id: string}>();
  const history: any = useHistory();
  const { dispatchAdvSpecState } = useAdvSpecStateDispatch();

  const [sortAsc, setSortAsc] = React.useState(sessionStorage.getItem('nc-quote-assemblies-sortAsc') ? JSON.parse(sessionStorage.getItem('nc-quote-assemblies-sortAsc')) : true);
  const [sortColumnHeading, setSortColumnHeading] = React.useState(sessionStorage.getItem('nc-quote-assemblies-sortColumnHeading') ? sessionStorage.getItem('nc-quote-assemblies-sortColumnHeading') : 'Piece');
  const [activeTab, setActiveTab] = React.useState<MSTOSelectionTabs>(MSTOSelectionTabs.Cladder);
  const selectedAnvilsToEdit = React.useRef<QuoteLineItem[]>([]);
  const selectedBackersToEdit = React.useRef<QuoteLineItem[]>([]);
  const selectedCladdersToEdit = React.useRef<QuoteLineItem[]>([]);
  const selectedAnvilsToUseAsIs = React.useRef<QuoteLineItem[]>([]);
  const selectedBackersToUseAsIs = React.useRef<QuoteLineItem[]>([]);
  const selectedCladdersToUseAsIs = React.useRef<QuoteLineItem[]>([]);
  const selectedAssemblies = React.useRef<string[]>([]);
  const anvilAssemblyDisplayMap = React.useRef<Map<string, AnvilAssemblyDisplayMapValueType>>(new Map<string, AnvilAssemblyDisplayMapValueType>);
  const backerAssemblyDisplayMap = React.useRef<Map<string, BackerAssemblyDisplayMapValueType>>(new Map<string, BackerAssemblyDisplayMapValueType>);
  const cladderAssemblyDisplayMap = React.useRef<Map<string, CladderAssemblyDisplayMapValueType>>(new Map<string, CladderAssemblyDisplayMapValueType>);
  const [anvilItemDetailFieldsToUpdate, setAnvilItemDetailFieldsToUpdate] = React.useState<{ [key: string]: MSTOAnvilItemDetailFieldsToUpdate }>({});
  const [quoteLineItemDetailFieldsToUpdate, setQuoteLineItemDetailFieldsToUpdate] = React.useState<{ [key: string]: MSTOQuoteLineItemDetailFieldsToUpdate }>({});
  const selectedMetalsToAssemble = React.useRef<AssemblyPanels[]>([]);
  const [anvilPanelsMap, setAnvilPanelsMap] = React.useState<Map<string, Panels>>(new Map<string, Panels>);
  const [backerPanelsMap, setBackerPanelsMap] = React.useState<Map<string, Panels>>(new Map<string, Panels>);
  const [cladderPanelsMap, setCladderPanelsMap] = React.useState<Map<string, SeamPanels>>(new Map<string, SeamPanels>);
  const [assemblies, setAssemblies] = React.useState<Map<string, AssemblyPanels[]>>(new Map<string, AssemblyPanels[]>);
  const [shouldBuildAssembly, setShouldBuildAssembly] = React.useState<boolean>(true);
  const [shouldAskAgain, setShouldAskAgain] = React.useState<boolean>(false);

  const [showSeamWeldPopover, setShowSeamWeldPopover] = React.useState(false);
  const [showSupplierPopover, setShowSupplierPopover] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(undefined);
  const [areLinesLoading, setAreLinesLoading] = React.useState(undefined);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [isQuoteSaving, setIsQuoteSaving] = React.useState(false);
  const weldingDimensionsMap = React.useRef<Map<string, WeldingDimensionsMapValueType>>(new Map<string, WeldingDimensionsMapValueType>);

  const grabJsonText = (text) => {
    try {
      JSON.parse(text);
      return text;
    } catch(e) {
      return Buffer.from(text, 'base64').toString('ascii');
    }
  };

  React.useEffect(() => {
    setIsLoading(true);

    if(!quote) {
      loadQuote(
        id,
        history?.location?.state?.quote,
        true,
        undefined,
        setAreLinesLoading
      );
    }

    loadRawMaterials();
    loadFinishedGoods();
    loadFreight();
    loadContent();
    loadEdgeAllowances();
    loadSpecificationTestOptions();
    loadBoosterAllowances();
    loadSelections();
    loadQuoteVariableConfigurations();
    loadVendors();
    loadWeldingPatterns();
  }, []);

  React.useEffect(() => {
    if(rawMaterials &&
       selections &&
       specificationTestOptions &&
       quoteVariableConfigurations &&
       vendors &&
       freight
    ) {
      setDataLoaded(true);
    }
  }, [dataState]);

  React.useEffect(() => {
    if(quoteLineItems && areLinesLoading) {
      try {
        loadQuoteLineItemDetails(setIsQuoteSaving);
        setAreLinesLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  }, [quoteLineItems]);

  React.useEffect(() => {
    if(quoteLineItemDetails && !isQuoteSaving) {
      setIsLoading(false);
    }
  }, [quoteLineItemDetails, isQuoteSaving]);

  // Returns true once all the necessary data is retrieved and the quote is finished loading
  const loadingComplete = () => {
    return dataLoaded && !isLoading;
  };

  const tabs: MSTOSelectionTabs[] = [
    MSTOSelectionTabs.Cladder,
    MSTOSelectionTabs.Backer,
    MSTOSelectionTabs.Anvil,
    MSTOSelectionTabs.Assemblies,
  ];

  const [addPiecePopoverState, setShowAddPiecePopover] = React.useState({
    showPopover: false,
    event: undefined,
    itemsToEdit: undefined,
  });
  const toast = useToast(4000);
  const [confirmAlert] = useIonAlert();

  const isUseAsIsDisabled = () => {
    switch (activeTab) {
      case MSTOSelectionTabs.Cladder:
        return (
          !selectedCladdersToUseAsIs.current.length ||
          selectedCladdersToUseAsIs.current.some(
            (cladderLine) => cladderAssemblyDisplayMap.current.get(cladderLine.UUID__c || cladderLine.Id || cladderLine.displayId).weld
          )
        );
      case MSTOSelectionTabs.Backer:
        return !selectedBackersToUseAsIs.current.length;
      case MSTOSelectionTabs.Anvil:
        return !selectedAnvilsToUseAsIs.current.length;
    }
  };

  React.useEffect(() => {
    try {
      if(quoteLineItemDetails) {
        const updatedCladderAssemblyDisplayMap = new Map();
        const updatedBackerAssemblyDisplayMap = new Map();
        const updatedAnvilAssemblyDisplayMap = new Map();
        const updatedCladderPanelsMap = new Map();
        const updatedBackerPanelsMap = new Map();
        const updatedAnvilPanelsMap = new Map();
        const updatedAssembliesMap = new Map();

        quoteLineItemDetails.forEach(quoteLineItemDetail => {
          if(quoteLineItemDetail.CladderJSON__c) {
            const cladderPanelJSON = grabJsonText(quoteLineItemDetail.CladderJSON__c);
            const cladderPanelMapForDetail: Map<string, {
              quoteLineItemData: QuoteLineItem;
              quoteLineItemDetailData: QuoteLineItemDetail;
              panelNumber?: number;
              panelLocation?: PanelLocations;
              seamWeldPieceNumber?: number;
              lengthWidthTKDisplayFields?: Record<string, number>;
            }> = new Map(JSON.parse(cladderPanelJSON));
            Array.from(cladderPanelMapForDetail.keys()).forEach(key => {
              updatedCladderPanelsMap.set(key, cladderPanelMapForDetail.get(key));
            });
          }

          if(quoteLineItemDetail.BackerJSON__c) {
            const backerPanelJSON = grabJsonText(quoteLineItemDetail.BackerJSON__c);
            const backerPanelMapForDetail: Map<string, {
              quoteLineItemData: QuoteLineItem;
              quoteLineItemDetailData: QuoteLineItemDetail;
              lengthWidthTKDisplayFields?: Record<string, number>;
            }> = new Map(JSON.parse(backerPanelJSON));

            Array.from(backerPanelMapForDetail.keys()).forEach(key => {
              updatedBackerPanelsMap.set(key, backerPanelMapForDetail.get(key));
            });
          }

          if(quoteLineItemDetail.AnvilJSON__c) {
            const anvilPanelJSON = grabJsonText(quoteLineItemDetail.AnvilJSON__c);
            const anvilPanelMapForDetail: Map<string, {
              quoteLineItemData: QuoteLineItem;
              quoteLineItemDetailData: QuoteLineItemDetail;
              lengthWidthTKDisplayFields?: Record<string, number>;
            }> = new Map(JSON.parse(anvilPanelJSON));

            Array.from(anvilPanelMapForDetail.keys()).forEach(key => {
              updatedAnvilPanelsMap.set(key, anvilPanelMapForDetail.get(key));
            });
          }

          if(quoteLineItemDetail.AssembliesJSON__c) {
            const assemblyJSON = grabJsonText(quoteLineItemDetail.AssembliesJSON__c);
            const assemblyMapForDetail: Map<string, {
              quoteLineItemData: QuoteLineItem;
              quoteLineItemDetailData: QuoteLineItemDetail;
              panelNumber?: number;
              panelLocation?: PanelLocations;
              seamWeldPieceNumber?: number;
            }> = new Map(JSON.parse(assemblyJSON));

            Array.from(assemblyMapForDetail.keys()).forEach(key => {
              updatedAssembliesMap.set(key, assemblyMapForDetail.get(key));
            });
          }
        });

        setCladderPanelsMap(updatedCladderPanelsMap);
        setBackerPanelsMap(updatedBackerPanelsMap);
        setAnvilPanelsMap(updatedAnvilPanelsMap);
        setAssemblies(updatedAssembliesMap);

        quoteLineItems.forEach(quoteLineItem => {
          const quoteLineItemId = quoteLineItem.UUID__c || quoteLineItem.Id || quoteLineItem.displayId;
          const { quoteLineItemDetail } = quoteLineItem;

          updatedCladderAssemblyDisplayMap.set(quoteLineItemId,
            {
              percent: calculatePercentAreaAllocated(MSTOAssemblyMetals.CLAD, updatedCladderPanelsMap, quoteLineItem, quoteLineItemDetail, quoteLineItem.Unit_of_Measure__c as MeasurementSystems, rawMaterials, manufacturingSite),
              weld: quoteLineItem.Welding__c > 0 || quoteLineItemDetail.SeamWeld__c
            }
          );

          updatedBackerAssemblyDisplayMap.set(quoteLineItemId,
            {
              percent: calculatePercentAreaAllocated(MSTOAssemblyMetals.BASE, updatedBackerPanelsMap, quoteLineItem, quoteLineItemDetail, quoteLineItem.Unit_of_Measure__c as MeasurementSystems, rawMaterials, manufacturingSite),
              cutting: quoteLineItemDetail.TorchCutBacker__c
            }
          );

          updatedAnvilAssemblyDisplayMap.set(quoteLineItemId,
            {
              percent: calculatePercentAreaAllocated(MSTOAssemblyMetals.ANVIL, updatedAnvilPanelsMap, quoteLineItem, quoteLineItemDetail, quoteLineItem.Unit_of_Measure__c as MeasurementSystems, rawMaterials, manufacturingSite)
            }
          );
        });

        cladderAssemblyDisplayMap.current = updatedCladderAssemblyDisplayMap;
        backerAssemblyDisplayMap.current = updatedBackerAssemblyDisplayMap;
        anvilAssemblyDisplayMap.current = updatedAnvilAssemblyDisplayMap;
      }
    } catch(error) {
      console.log(error);
      return;
    }
  }, [quoteLineItemDetails]);

  // initDisplayMap - returns a display map of quote line item ids for anvil, backer, or cladder assemblies
  const initDisplayMap = (quoteLineItems: QuoteLineItem[], map: string): Map<string, MSTOAssemblyDisplayMapTypes> => {
    const displayMap = new Map<string, MSTOAssemblyDisplayMapTypes>();

    switch (map) {
      case 'anvilAssemblyDisplayMap': {
        quoteLineItems.forEach((quoteLineItem) => {
          displayMap.set(quoteLineItem.UUID__c || quoteLineItem.Id || quoteLineItem.displayId, { percent: 0 });
        });
        return displayMap;
      }
      case 'backerAssemblyDisplayMap': {
        quoteLineItems.forEach((quoteLineItem) => {
          displayMap.set(quoteLineItem.UUID__c || quoteLineItem.Id || quoteLineItem.displayId, { percent: 0, cutting: false });
        });
        return displayMap;
      }
      case 'cladderAssemblyDisplayMap': {
        quoteLineItems.forEach((quoteLineItem) => {
          displayMap.set(quoteLineItem.UUID__c || quoteLineItem.Id || quoteLineItem.displayId, { percent: 0, weld: quoteLineItem.Welding__c > 0 });
        });
        return displayMap;
      }
    }
  };

  const metalsUseAsIs = (
    selectedMetalsToUseAsIs: QuoteLineItem[],
    assemblyDisplayMap: Map<string, MSTOAssemblyDisplayMapTypes>,
    metalDetailFields: MSTOUseAsIsDetailFieldsToUpdate,
    metalPanelsMap?: Map<string, SeamPanels | Panels>,
    lineItemDetailFieldsMap?: Map<QuoteLineItemFields, string | boolean>,
    weldingDimensionsMap?: React.MutableRefObject<Map<string, WeldingDimensionsMapValueType>>,
    anvilItemDetailFieldsMap?: Map<QuoteLineItemFields, string | boolean>,
  ) => {
    const updatedDetailFieldsToUpdate = { ...quoteLineItemDetailFieldsToUpdate };
    const updatedAssemblyDisplayMap = new Map(assemblyDisplayMap);
    const updatedMetalPanelsMap = new Map(metalPanelsMap);
    const newMetalPanels = new Map();
    const selectedMetalIds = selectedMetalsToUseAsIs.map(metal => metal.UUID__c || metal.Id || metal.displayId);
    const weldingDimensions = weldingDimensionsMap.current;
    let hasUsedAsIs = false;

    updatedMetalPanelsMap.forEach(panel => {
      if(selectedMetalIds.includes(panel.quoteLineItemData.UUID__c) && panel.quoteLineItemData.useAsIs) {
        hasUsedAsIs = true;
      }
    });

    if(hasUsedAsIs) {
      return toast(ToastTypes.Error, ToastMessages.AlreadyUseAsIs);
    }

    let piece = Array.from(metalPanelsMap.keys()).length + 1;
    selectedMetalsToUseAsIs.forEach((metalLine) => {
      const updatedMetalDetail = { ...metalLine.quoteLineItemDetail };
      const metalLineId = metalLine.UUID__c || metalLine.Id || metalLine.displayId;
      const metalDetailId = updatedMetalDetail.UUID__c || updatedMetalDetail.Id || updatedMetalDetail.associatedLineItem;
      //This is where is is getting jammed up
      updatedDetailFieldsToUpdate[metalDetailId] = {
        ...quoteLineItemDetailFieldsToUpdate[metalDetailId],
        ...metalDetailFields,
        panelQuantity: updatedMetalDetail.Clads__c - 1,
      };

      Array.from(lineItemDetailFieldsMap.keys()).forEach((field) => {
        updatedMetalDetail[field] = lineItemDetailFieldsMap.get(field);
      });

      Array.from(anvilItemDetailFieldsMap?.keys() || []).forEach((field) => {
        updatedMetalDetail.NC_Anvil_Item_Details__r[field] = anvilItemDetailFieldsMap.get(field);
      });

      const panelFields = filterMSTOPanelFields(activeTab, metalLine, updatedMetalDetail, updatedMetalDetail.NC_Seam_Weld_Item_Details__r, updatedMetalDetail.NC_Anvil_Item_Details__r);

      const lengthWidthTKDisplayFields: { DisplayWidth?: number, DisplayLength?: number, DisplayTK?: number } = { };

      switch(activeTab) {
        case MSTOSelectionTabs.Cladder:
        default:
          lengthWidthTKDisplayFields.DisplayLength = panelFields.lineItemDetailPanelFields.PurchaseCladLength__c;
          lengthWidthTKDisplayFields.DisplayWidth = panelFields.lineItemDetailPanelFields.PurchaseCladWidth__c;
          lengthWidthTKDisplayFields.DisplayTK = panelFields.lineItemDetailPanelFields.PurchaseCladTK__c;
          break;
        case MSTOSelectionTabs.Backer:
          lengthWidthTKDisplayFields.DisplayLength = panelFields.lineItemDetailPanelFields.PurchaseBaseLength__c;
          lengthWidthTKDisplayFields.DisplayWidth = panelFields.lineItemDetailPanelFields.PurchaseBaseWidth__c;
          lengthWidthTKDisplayFields.DisplayTK = panelFields.lineItemDetailPanelFields.PurchaseBaseTK__c;
          break;
        case MSTOSelectionTabs.Anvil:
          lengthWidthTKDisplayFields.DisplayLength = panelFields.lineItemDetailPanelFields.PurchaseAnvilLength__c;
          lengthWidthTKDisplayFields.DisplayWidth = panelFields.lineItemDetailPanelFields.PurchaseAnvilWidth__c;
          lengthWidthTKDisplayFields.DisplayTK = panelFields.lineItemDetailPanelFields.PurchaseAnvilTK__c;
          break;
      }

      for (let i = updatedDetailFieldsToUpdate[metalDetailId].panelQuantity; i >= 0; i--) {
        const weldingAdders = weldingDimensions.get(panelFields.lineItemPanelFields.UUID__c) || weldingDimensions.get(panelFields.lineItemPanelFields.Id) || weldingDimensions.get(panelFields.lineItemPanelFields.displayId);
        let purchaseWidth = panelFields.lineItemDetailPanelFields.PurchaseCladWidth__c;
        let purchaseLength = panelFields.lineItemDetailPanelFields.PurchaseCladLength__c;
        const anvilFreightCostPerLB = activeTab === MSTOSelectionTabs.Anvil ? getQuoteVariableConfiguration(
          quoteVariableConfigurations,
          QuoteVariableConfigDescriptions.AnvilFreightCostByWeightLB,
          manufacturingSite,
          FieldTypes.Float
        ) as number : null;
        const anvilFreightCostPerKG = activeTab === MSTOSelectionTabs.Anvil ? getQuoteVariableConfiguration(
          quoteVariableConfigurations,
          QuoteVariableConfigDescriptions.AnvilFreightCostByWeightKG,
          manufacturingSite,
          FieldTypes.Float
        ) as number : null;

        if (weldingAdders) {
          if (weldingAdders.weldWidth) {
            purchaseWidth += weldingAdders.weldWidth;
          }
          if (weldingAdders.weldLength) {
            purchaseLength += weldingAdders.weldLength;
          }
        }

        const newMetalPanelKey = `${metalLine.Line__c} - ${piece}`;
        const newMetalPanel = {
          quoteLineItemData: { ...panelFields.lineItemPanelFields, piece, useAsIs: true },
          quoteLineItemDetailData: {
            ...panelFields.lineItemDetailPanelFields,
            NC_Anvil_Item_Details__r: {
              ...panelFields.anvilItemDetailPanelFields,
            },
            NC_Seam_Weld_Item_Details__r: {
              ...panelFields.seamWeldItemDetailPanelFields
            },
            PurchaseCladWidth__c: purchaseWidth,
            PurchaseCladLength__c: purchaseLength,
            PurchaseAnvilFreight_LB__c: anvilFreightCostPerLB,
            PurchaseAnvilFreight_KG__c: anvilFreightCostPerKG
          },
          lengthWidthTKDisplayFields
        };

        updatedMetalPanelsMap.set(newMetalPanelKey, newMetalPanel);
        newMetalPanels.set(newMetalPanelKey, newMetalPanel);
        piece++;
      }

      const currentAssemblyDisplay = updatedAssemblyDisplayMap.get(metalLineId);
      updatedAssemblyDisplayMap.set(metalLineId, { ...currentAssemblyDisplay, percent: 100 });
    });

    setQuoteLineItemDetailFieldsToUpdate(updatedDetailFieldsToUpdate);
    updateAssembliesDisplayMap(updatedAssemblyDisplayMap);
    updateMetalPanelsMap(updatedMetalPanelsMap);
    updateSelectedMetalsToUseAsIs([]);
  };

  const getQuoteLineToAdd = () => {
    switch (activeTab) {
      case MSTOSelectionTabs.Cladder:
        return selectedCladdersToUseAsIs.current[0];
      case MSTOSelectionTabs.Backer:
        return selectedBackersToUseAsIs.current[0];
      case MSTOSelectionTabs.Anvil:
        return selectedAnvilsToUseAsIs.current[0];
    }
  };

  const getQuoteLinesToEdit = () => {
    switch (activeTab) {
      case MSTOSelectionTabs.Cladder:
        return selectedCladdersToEdit.current;
      case MSTOSelectionTabs.Backer:
        return selectedBackersToEdit.current;
      case MSTOSelectionTabs.Anvil:
        return selectedAnvilsToEdit.current;
    }
  };

  const hasMultipleClads = () => {
    const cladsToAssemble = selectedMetalsToAssemble.current.filter(panel => panel.metal === MSTOAssemblyMetals.CLAD);

    return cladsToAssemble.length > 1;
  };

  const hasWelding = () => {
    return selectedMetalsToAssemble.current.find(
      (item) => item.metal === MSTOAssemblyMetals.CLAD && item.quoteLineItemDetailData.SeamWeld__c
    );
  };

  // vendor defaulting to “0” when pull from stock
  const actionHandlerUseAsIs = () => {
    const lineItemDetailFieldsMap = new Map<QuoteLineItemFields, string | boolean>();
    const anvilItemDetailFieldsMap = new Map<QuoteLineItemFields, string | boolean>();

    if (activeTab === MSTOSelectionTabs.Cladder) {
      const detailFieldsToUpdate = {
        pullFromStockCladder: true,
        purchaseCl: false,
        shipDateCladder: moment(new Date()).format('YYYY-MM-DD'),
        cladderAllocation: AllocationOptions.AssemblyPart,
        rawMaterialVendor: '0'
      };

      lineItemDetailFieldsMap.set(QuoteLineItemFields.Pullfromstockcladder, detailFieldsToUpdate.pullFromStockCladder);
      lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseCL, detailFieldsToUpdate.purchaseCl);
      lineItemDetailFieldsMap.set(QuoteLineItemFields.ShipDateCladder, detailFieldsToUpdate.shipDateCladder);

      metalsUseAsIs(
        selectedCladdersToUseAsIs.current,
        cladderAssemblyDisplayMap.current,
        detailFieldsToUpdate,
        cladderPanelsMap,
        lineItemDetailFieldsMap,
        weldingDimensionsMap
      );
    } else if (activeTab === MSTOSelectionTabs.Backer) {
      const detailFieldsToUpdate = {
        pullFromStockBacker: true,
        purchaseBK: false,
        shipDateBacker: moment(new Date()).format('YYYY-MM-DD'),
        backerAllocation: AllocationOptions.AssemblyPart,
        rawMaterialVendor: '0'
      };

      lineItemDetailFieldsMap.set(QuoteLineItemFields.Pullfromstockbacker, detailFieldsToUpdate.pullFromStockBacker);
      lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBK, detailFieldsToUpdate.purchaseBK);
      lineItemDetailFieldsMap.set(QuoteLineItemFields.ShipDateBase, detailFieldsToUpdate.shipDateBacker);

      metalsUseAsIs(
        selectedBackersToUseAsIs.current,
        backerAssemblyDisplayMap.current,
        detailFieldsToUpdate,
        backerPanelsMap,
        lineItemDetailFieldsMap,
        weldingDimensionsMap
      );
    } else if (activeTab === MSTOSelectionTabs.Anvil) {
      const detailFieldsToUpdate = {
        pullFromStockBKAnvil: true,
        purchaseBKAnvil: false,
        shipDateAnvil: moment(new Date()).format('YYYY-MM-DD'),
        anvilAllocation: AllocationOptions.AssemblyPart,
        rawMaterialVendor: '0'
      };

      lineItemDetailFieldsMap.set(QuoteLineItemFields.PullfromstockBKAnvil, detailFieldsToUpdate.pullFromStockBKAnvil);
      lineItemDetailFieldsMap.set(QuoteLineItemFields.PurchaseBKAnvil, detailFieldsToUpdate.purchaseBKAnvil);
      anvilItemDetailFieldsMap.set(QuoteLineItemFields.ShipDateAnvil, detailFieldsToUpdate.shipDateAnvil);

      metalsUseAsIs(
        selectedAnvilsToUseAsIs.current,
        anvilAssemblyDisplayMap.current,
        detailFieldsToUpdate,
        anvilPanelsMap,
        lineItemDetailFieldsMap,
        weldingDimensionsMap,
        anvilItemDetailFieldsMap,
      );
    }
  };

  const reset = () => {
    cladderAssemblyDisplayMap.current = initDisplayMap(quoteLineItems, 'cladderAssemblyDisplayMap') as Map<string, CladderAssemblyDisplayMapValueType>;
    backerAssemblyDisplayMap.current = initDisplayMap(quoteLineItems, 'backerAssemblyDisplayMap') as Map<string, BackerAssemblyDisplayMapValueType>;
    anvilAssemblyDisplayMap.current = initDisplayMap(quoteLineItems, 'anvilAssemblyDisplayMap') as Map<string, AnvilAssemblyDisplayMapValueType>;
    weldingDimensionsMap.current = new Map<string, WeldingDimensionsMapValueType>;
    selectedAnvilsToUseAsIs.current = [];
    selectedBackersToUseAsIs.current = [];
    selectedCladdersToUseAsIs.current = [];
    selectedAnvilsToEdit.current = [];
    selectedBackersToEdit.current = [];
    selectedCladdersToEdit.current = [];
    selectedMetalsToAssemble.current = [];
    setActiveTab(MSTOSelectionTabs.Cladder);
    setAnvilItemDetailFieldsToUpdate({});
    setAnvilPanelsMap(new Map<string, Panels>);
    setAssemblies(new Map<string, AssemblyPanels[]>);
    setBackerPanelsMap(new Map<string, Panels>);
    setCladderPanelsMap(new Map<string, SeamPanels>);
    // TODO set manufacturingSite via quote context
    // manufacturingSite: ManufacturingSites.US01;
    setQuoteLineItemDetailFieldsToUpdate({});
    setShowSeamWeldPopover(false);
    setShowSupplierPopover(false);
  };

  const handleQuoteAction = (action: MSTOActions) => {
    switch (action) {
      case MSTOActions.UseAsIs:
        try {
          isUseAsIsDisabled() ? toast(ToastTypes.Error, ToastMessages.UseAsIs) : actionHandlerUseAsIs();
        } catch (error) {
          console.error(error);
          toast(ToastTypes.Error, ToastMessages.MSTOUseAsIsFailed);
        }
        break;
      case MSTOActions.AddPiece:
        try {
          !getQuoteLineToAdd()
            ? toast(ToastTypes.Error, ToastMessages.MSTOAddPiece)
            : setShowAddPiecePopover({ showPopover: true, event: undefined, itemsToEdit: [getQuoteLineToAdd()] });
        } catch (error) {
          console.error(error);
          toast(ToastTypes.Error, ToastMessages.MSTOAddPieceFailed);
        }
        break;
      case MSTOActions.Supplier:
        setShowSupplierPopover(true);
        break;
      case MSTOActions.Reset:
        try {
          confirmAlert({
            cssClass: 'confirm-alert',
            header: "Are you sure you want to reset any changes you've made to Metal Sizes To Order?",
            buttons: [
              {
                text: 'Cancel',
                cssClass: 'alert-button-cancel',
              },
              {
                text: 'Yes',
                cssClass: 'alert-button-confirm',
                handler: () => reset(),
              },
            ],
          });
        } catch (error) {
          console.error(error);
          toast(ToastTypes.Error, ToastMessages.MSTOResetFailed);
        }
        break;
      case MSTOActions.Edit:
        try {
          !getQuoteLinesToEdit().length
            ? toast(ToastTypes.Error, ToastMessages.MSTOEdit)
            : setShowAddPiecePopover({ showPopover: true, event: undefined, itemsToEdit: getQuoteLinesToEdit() });
        } catch (error) {
          console.error(error);
          toast(ToastTypes.Error, ToastMessages.MSTOEditFailed);
        }
        break;
      case MSTOActions.Delete:
        activeTab !== MSTOSelectionTabs.Assemblies && !getQuoteLinesToEdit().length || activeTab === MSTOSelectionTabs.Assemblies && !assemblies.size ? toast(ToastTypes.Error, ToastMessages.MSTODelete) :
        confirmAlert({
          cssClass: 'confirm-alert',
          header: `Are you sure you want to delete any changes you've made to ${activeTab} Metal Sizes To Order?`,
          buttons: [
            {
              text: 'Cancel',
              cssClass: 'alert-button-cancel',
            },
            {
              text: 'Yes',
              cssClass: 'alert-button-confirm',
              handler: () => actionHandlerDelete()
            },
          ],
        });
        break;
      case MSTOActions.Create:
        try {
          if(validateAssembly()) {
            if (hasWelding() && hasMultipleClads()) {
              setShowSeamWeldPopover(true);
            } else {
              actionHandlerCreateAssembly();
            }
          }
        } catch (error) {
          console.error(error);
          toast(ToastTypes.Error, ToastMessages.MSTOCreateAssemblyFailed);
        }
        break;
      case MSTOActions.Duplicate:
        !getQuoteLinesToEdit() ? toast(ToastTypes.Error, ToastMessages.MSTODuplicate) :
        actionHandlerDuplicate();
        break;
    }
  };

  const dismissSelectedPopover = (popover: MSTOPopovers) => {
    switch(popover) {
      case MSTOPopovers.SeamWeld:
        setShowSeamWeldPopover(false);
        break;
      case MSTOPopovers.Supplier:
        setShowSupplierPopover(false);
        break;
    }
  };

  const DisplayedSeamWeldPopover = () => {
    return (
      <IonPopover
        isOpen={showSeamWeldPopover}
        cssClass='seam-weld-popover'
        animated={false}
        backdropDismiss={false}
        onDidDismiss={async () => setShowSeamWeldPopover(false)}
      >
        <SeamWeldPopover
          actionHandlerCreateAssembly={actionHandlerCreateAssembly}
          mstoData={
            {
              selectedMetalsToAssemble: selectedMetalsToAssemble.current,
              cladderPanelsMap
            }
          }
          dismissModal={dismissSelectedPopover}
          updateSelectedMetalsToAssemble={updateSelectedMetalsToAssemble}
          updateCladderPanelsMap={updateSpecifiedMetalPanelsMap}
        />
      </IonPopover>
    );
  };

  const DisplayedSupplierPopover = () => {
    return (
      <IonPopover
        isOpen={showSupplierPopover}
        cssClass='supplier-popover'
        animated={false}
        backdropDismiss={false}
        onDidDismiss={async () => setShowSupplierPopover(false)}
      >
        <SupplierPopover
          mstoData={
            {
              activeTab,
              anvilPanelsMap,
              backerPanelsMap,
              cladderPanelsMap,
              assemblies,
            }
          }
          dismissModal={dismissSelectedPopover}
          buildAutocombinedGroups={buildAutocombinedGroups}
          autocombineMetals={autocombineMetals}
          updatePanelsMap={updateSpecifiedMetalPanelsMap}
          updateAssemblies={updateAssemblies}
        />
      </IonPopover>
    );
  };

  const DisplayedMSTOAssembliesTable = () => {
    const tableHeadersCladderList = [
      { display: '', colSize: '0.5' }, // blank column
      { display: 'Assembly Group', colSize: '0.7' },
      { display: 'Quantity', colSize: '0.5' },
      { display: 'Clad Metal', colSize: '1.5' },
      { display: 'Width', colSize: '0.7' },
      { display: 'Length', colSize: '0.7' },
      { display: 'Clad TK', colSize: '0.7' },
      { display: 'Percent', colSize: '0.7' },
      { display: 'Weld', colSize: '0.7' },
      { display: 'Weld Width', colSize: '0.7' },
      { display: 'Weld Length', colSize: '0.7' },
    ];

    const tableHeadersBackerList = [
      { display: '', colSize: '0.5' }, // blank column
      { display: 'Assembly Group', colSize: '0.7' },
      { display: 'Quantity', colSize: '0.5' },
      { display: 'Base Metal', colSize: '1.5' },
      { display: 'Width', colSize: '0.7' },
      { display: 'Length', colSize: '0.7' },
      { display: 'Base TK', colSize: '0.7' },
      { display: 'Percent', colSize: '0.7' },
      { display: 'Cutting', colSize: '0.7' },
    ];

    const tableHeadersAnvilList = [
      { display: '', colSize: '0.5' }, // blank column
      { display: 'Assembly Group', colSize: '0.7' },
      { display: 'Quantity', colSize: '0.5' },
      { display: 'Width', colSize: '0.7' },
      { display: 'Length', colSize: '0.7' },
      { display: 'Anvil TK', colSize: '0.7' },
      { display: 'Percent', colSize: '0.7' },
    ];

    switch (activeTab) {
      case MSTOSelectionTabs.Backer:
        return (
          <>
            <TableHeaders labels={tableHeadersBackerList} />
            {/* for each assembly group, show the row */}
            {quoteLineItems.filter(qli => !qli.Parent_Line__c).map((qli, idx) => {
              return <MSTOBackerQuoteLineItemRow key={idx} quoteLineItem={qli} quoteLineItemIndex={idx} />;
            })}
          </>
        );
      case MSTOSelectionTabs.Anvil:
        return (
          <>
            {quoteLineItems.filter(qli => !qli.Parent_Line__c).some((qli) => qli.Anvil__c) ? (
              <TableHeaders labels={tableHeadersAnvilList} />
            ) : null}
            {/* for each assembly group, show the row */}
            {quoteLineItems.filter(qli => !qli.Parent_Line__c).map((qli, idx) => {
              return qli.Anvil__c ? (
                <MSTOAnvilQuoteLineItemRow key={idx} quoteLineItem={qli} quoteLineItemIndex={idx} />
              ) : null;
            })}
          </>
        );
      case MSTOSelectionTabs.Assemblies:
        return <AllocationsAssembliesPanels />;
      case MSTOSelectionTabs.Cladder:
      default:
        return (
          <>
            <TableHeaders labels={tableHeadersCladderList} />
            {/* for each assembly group, show the row */}
            {quoteLineItems?.filter(qli => !qli.Parent_Line__c).map((qli, idx) => {
              return <MSTOCladderQuoteLineItemRow key={idx} quoteLineItem={qli} quoteLineItemIndex={idx} />;
            })}
          </>
        );
    }
  };

  const AllocationsPane = () => {
    const allocations: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      metal: MSTOAssemblyMetals;
      panelNumber?: number;
      panelLocation?: PanelLocations;
    }[] = [];

    Array.from(cladderPanelsMap.values()).forEach((panel) => {
      allocations.push({ ...panel, metal: MSTOAssemblyMetals.CLAD });
    });

    Array.from(backerPanelsMap.values()).forEach((panel) => {
      allocations.push({ ...panel, metal: MSTOAssemblyMetals.BASE });
    });

    Array.from(anvilPanelsMap.values()).forEach((panel) => {
      allocations.push({ ...panel, metal: MSTOAssemblyMetals.ANVIL });
    });

    const tableHeadersAllocationsList = [
      { display: '', colSize: '0.75' }, // blank column
      { display: 'Metal', colSize: '1' },
      { display: 'Piece', colSize: '1' },
      { display: 'Width', colSize: '1' },
      { display: 'Length', colSize: '1' },
      { display: 'TK', colSize: '1' },
      { display: 'Ass Grp', colSize: '1' },
      { display: 'Location', colSize: '1' },
      { display: 'Panel', colSize: '1' },
      { display: 'Assembly Group Plate', colSize: '3.25' },
    ];

    const [sortedAllocations, setSortedAllocations] = React.useState([]);

    const sortItems = async (column, ignoreSetSort) => {
      let updatedAllocations;
      let localSortAsc = sortAsc;

      if(column === sortColumnHeading && !ignoreSetSort) {
        setSortAsc(!sortAsc);
        localSortAsc = !sortAsc;
        sessionStorage.setItem('nc-quote-assemblies-sortAsc', localSortAsc);
      } else {
        setSortColumnHeading(column);
        sessionStorage.setItem('nc-quote-assemblies-sortColumnHeading', column);
      }

      if(allocations) {
        updatedAllocations = [...allocations];
      }

      switch(column) {
        case 'Metal':
          updatedAllocations = [...updatedAllocations].orderBy(allocation => allocation.metal, localSortAsc);
          break;
        case 'Piece':
          updatedAllocations = [...updatedAllocations].orderBy(allocation => allocation.quoteLineItemData.piece, localSortAsc);
          break;
        case 'Width':
          updatedAllocations = [...updatedAllocations].orderBy((allocation) => {
            const metalType = allocation.metal;
            let width;

            if(metalType === MSTOAssemblyMetals.CLAD) {
              width = QuoteLineItemFields.PurchaseCladWidth;
            } else if(metalType === MSTOAssemblyMetals.BASE) {
              width = QuoteLineItemFields.PurchaseBaseWidth;
            } else if(metalType === MSTOAssemblyMetals.ANVIL) {
              width = QuoteLineItemFields.PurchaseAnvilWidth;
            }
            return +allocation.quoteLineItemDetailData[width];
          }, localSortAsc);
          break;
        case 'Length':
          updatedAllocations = [...updatedAllocations].orderBy((allocation) => {
            const metalType = allocation.metal;
            let length;

            if(metalType === MSTOAssemblyMetals.CLAD) {
              length = QuoteLineItemFields.PurchaseCladLength;
            } else if(metalType === MSTOAssemblyMetals.BASE) {
              length = QuoteLineItemFields.PurchaseCladLength;
            } else if(metalType === MSTOAssemblyMetals.ANVIL) {
              length = QuoteLineItemFields.PurchaseAnvilLength;
            }
            return +allocation.quoteLineItemDetailData[length];
          }, localSortAsc);
          break;
        case 'TK':
          updatedAllocations = [...updatedAllocations].orderBy((allocation) => {
            const metalType = allocation.metal;
            let thickness;

            if(metalType === MSTOAssemblyMetals.CLAD) {
              thickness = QuoteLineItemFields.PurchaseCladTK;
            } else if(metalType === MSTOAssemblyMetals.BASE) {
              thickness = QuoteLineItemFields.PurchaseBaseTK;
            } else if(metalType === MSTOAssemblyMetals.ANVIL) {
              thickness = QuoteLineItemFields.PurchaseAnvilTK;
            }
            return +allocation.quoteLineItemDetailData[thickness];
          }, localSortAsc);
          break;
        case 'Ass Grp':
          updatedAllocations = [...updatedAllocations].orderBy(allocation => allocation.quoteLineItemData.Line__c, localSortAsc);
          break;
        case 'Location':
          updatedAllocations = [...updatedAllocations].orderBy(allocation => allocation.panelLocation, localSortAsc);
          break;
        case 'Panel':
          updatedAllocations = [...updatedAllocations].orderBy(allocation => allocation.panelNumber, localSortAsc);
          break;
        case 'Assembly Group Plate':
          updatedAllocations = [...updatedAllocations].orderBy(allocation => {
            // eslint-disable-next-line no-unused-vars
            const assemblyKey = ([...assemblies].find(([_, assemblyPanels]) => assemblyPanels.find(assemblyPanel => assemblyPanel.quoteLineItemData.Line__c === allocation.quoteLineItemData.Line__c && assemblyPanel.quoteLineItemData.piece === allocation.quoteLineItemData.piece)) || [])[0];
            const assemblyKeyObject = assemblyKey ? JSON.parse(assemblyKey) : undefined;
            return assemblyKeyObject ? `Assembly Group ${assemblyKeyObject.assemblyGroup} - ${assemblyKeyObject.pieceNumber}` : '';
          }, localSortAsc);
          break;
        default:
          return;
      }

      setSortedAllocations(updatedAllocations);
    };

    React.useEffect(() => {
      sortItems(sortColumnHeading, true);
    }, []);

    return (
      <>
        <IonGrid>
          {sortedAllocations.length ? (
            <>
              <IonRow>
                <TableHeaders labels={tableHeadersAllocationsList} sortItems={sortItems} selectedColumnHeader={sortColumnHeading} sortAsc={sortAsc} />
              </IonRow>
              {sortedAllocations.map((allocation, idx) => {
                return (
                  <AllocationsQuoteLineItemRow allocation={allocation} key={idx} />
                );
              })}
            </>
          ) : (
            <div className='assemblies-empty-state'>No Allocations...</div>
          )}
        </IonGrid>
      </>
    );
  };

  const AssembliesPane = () => {
    const tableHeadersAssembliesList = [
      { display: '', colSize: '1' },
      { display: 'Assembly', colSize: '2'},
    ];

    return (
      <>
        <IonGrid>
          {Array.from(assemblies.keys()).length ? (
            <>
              {Array.from(assemblies.keys()).orderBy(key => JSON.parse(key).assemblyGroup).map((assemblyKey, idx) => {
                return (
                  <div key={idx}>
                    <IonRow className="msto-assemblies-header">
                      <TableHeaders labels={tableHeadersAssembliesList} />
                    </IonRow>
                    <AssemblyGroupMSTORow assemblyKey={assemblyKey} assemblyIndex={idx} />
                  </div>
                );
              })}
            </>
          ) : (
            <div className='assemblies-empty-state'>No Assemblies...</div>
          )}
        </IonGrid>
      </>
    );
  };

  const AllocationsAssembliesPanels = () => {
    React.useEffect(() => {
      if (!assemblies.size && shouldBuildAssembly) {
        const metalPanels = buildAutocombinedGroups();
        if (metalPanels.length) {
          const updatedAssemblies = autocombineMetals(metalPanels);
          setAssemblies(updatedAssemblies);
          if (updatedAssemblies.size) {
            setShouldBuildAssembly(false);
          }
        }
      }

      if (!assemblies.size && shouldAskAgain) {
        confirmAlert({
          cssClass: 'confirm-alert',
          header: 'Do you want to build the metals again?',
          buttons: [
            {
              text: 'Cancel',
              cssClass: 'alert-button-cancel',
              handler: () => {
                setShouldAskAgain(false);
              }
            },
            {
              text: 'Yes',
              cssClass: 'alert-button-confirm',
              handler: () => {
                setShouldBuildAssembly(true);
                setShouldAskAgain(false);
              },
            },
          ],
        });
      }
    }, []);

    return (
      <>
        <div className='assemblies-container'>
          <>
            <IonGrid>
              <IonRow>
                <IonCol class='allocations-container' size='8'>
                  <IonGrid>
                    <IonRow>
                      <div className='title'>Allocations:</div>
                    </IonRow>
                    <IonRow class='allocations-pane-row-container'>
                      <AllocationsPane />
                    </IonRow>
                  </IonGrid>
                </IonCol>
                <IonCol class='assembled-groups-container' size='4'>
                  <IonGrid>
                    <IonRow class='msto-dropdown-button-title-row'>
                      <div className='title'>Assemblies:</div>
                      <MSTOModalDropdown
                        default={MSTOActions.Create}
                        actions={[MSTOActions.Delete, MSTOActions.Reset]}
                      />
                    </IonRow>
                    <IonRow>
                      <AssembliesPane />
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        </div>
      </>
    );
  };

  const AllocationsQuoteLineItemRow = (props: {
    allocation: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      metal: MSTOAssemblyMetals;
      panelNumber?: number;
      panelLocation?: PanelLocations;
    };
  }) => {
    const { quoteLineItemData, quoteLineItemDetailData, metal, panelNumber, panelLocation } = props.allocation;

    const allocationSelected = selectedMetalsToAssemble.current.some(metalToAssemble =>
      metalToAssemble.quoteLineItemData.Line__c === quoteLineItemData.Line__c &&
      metalToAssemble.quoteLineItemData.piece === quoteLineItemData.piece &&
      metalToAssemble.metal === metal);

    // eslint-disable-next-line no-unused-vars
    const assemblyKey = ([...assemblies].find(([_, assemblyPanels]) => assemblyPanels.find(assemblyPanel =>
      assemblyPanel.quoteLineItemData.Line__c === quoteLineItemData.Line__c &&
      assemblyPanel.quoteLineItemData.piece === quoteLineItemData.piece &&
      assemblyPanel.metal === metal)) || [])[0];

    const assemblyKeyObject = assemblyKey ? JSON.parse(assemblyKey) : undefined;

    const [isLineChecked, setIsLineChecked] = React.useState<boolean>(allocationSelected);

    React.useEffect(() => {
      try {
        if (isLineChecked) {
          if (!allocationSelected) {
            const updatedMetalsToAssemble = [...selectedMetalsToAssemble.current];
            updatedMetalsToAssemble.push(props.allocation);
            selectedMetalsToAssemble.current = updatedMetalsToAssemble;
          }
        } else {
          if (allocationSelected) {
            const updatedMetalsToAssemble = [
              ...selectedMetalsToAssemble.current.filter(
                (metalToAssemble) =>
                  metalToAssemble.quoteLineItemData.Line__c !== quoteLineItemData.Line__c ||
                  metalToAssemble.quoteLineItemData.piece !== quoteLineItemData.piece ||
                  metalToAssemble.metal !== metal
              ),
            ];
            selectedMetalsToAssemble.current = updatedMetalsToAssemble;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, [isLineChecked]);

    let imperialWidthField: number,
      metricWidthField: number,
      imperialLengthField: number,
      metricLengthField: number,
      imperialTKField: number,
      metricTKField: number;

    switch (metal) {
      case MSTOAssemblyMetals.CLAD:
        imperialWidthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladWidth__c, Units.Millimeters, Units.Inches);
        metricWidthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladWidth__c, Units.Millimeters, Units.Inches);
        imperialLengthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladLength__c, Units.Millimeters, Units.Inches);
        metricLengthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladLength__c, Units.Millimeters, Units.Inches);
        imperialTKField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladTK__c, Units.Millimeters, Units.Inches);
        metricTKField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladTK__c, Units.Millimeters, Units.Inches);
        break;
      case MSTOAssemblyMetals.BASE:
        imperialWidthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches);
        metricWidthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches);
        imperialLengthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseLength__c, Units.Millimeters, Units.Inches);
        metricLengthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseLength__c, Units.Millimeters, Units.Inches);
        imperialTKField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseTK__c, Units.Millimeters, Units.Inches);
        metricTKField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseTK__c, Units.Millimeters, Units.Inches);
        break;
      case MSTOAssemblyMetals.ANVIL:
        imperialWidthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches);
        metricWidthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches);
        imperialLengthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches);
        metricLengthField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches);
        imperialTKField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches);
        metricTKField = calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches);
        break;
    }

    return (
      <>
        <IonCard className='msto-quote-line-item-card' data-testid={'QAMSTOAllocationsRow'}>
          <IonCardHeader class={'msto-quote-line-item-card-header'}>
            <IonGrid>
              <IonRow class="msto-quote-line-item-row">
                <IonCol size='0.75' class={classNames({'msto-quote-line-items-col checkbox': true, 'teal-col': !!assemblyKey})}>
                  <CheckboxItem
                    style={{
                      width: 'fit-content',
                      marginTop: '6px',
                      marginLeft: '6px',
                      marginBottom: 'auto',
                      ['--background']: 'transparent',
                    }}
                    onIonChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                      setIsLineChecked(checked);
                    }}
                    checked={isLineChecked}
                  />
                </IonCol>
                <IonCol size='1' class={classNames({'msto-quote-line-items-col': true, 'teal-col': !!assemblyKey})}>
                  <LineItemField displayedValue={metal} />
                </IonCol>
                <IonCol size='1' class={classNames({'msto-quote-line-items-col': true, 'teal-col': !!assemblyKey})}>
                  <LineItemField valueType={QuoteLineItemValueTypes.Piece} displayedValue={`${quoteLineItemData.piece} - 1`} />
                </IonCol>
                <IonCol class={classNames({'msto-quote-line-items-col': true, 'teal-col': !!assemblyKey})} size="1">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladWidth}
                    datatestid='QAMSTOAllocationWidth'
                    imperialValue={imperialWidthField}
                    metricValue={metricWidthField}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol class={classNames({'msto-quote-line-items-col': true, 'teal-col': !!assemblyKey})} size="1">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladLength}
                    datatestid='QAMSTOAllocationLength'
                    imperialValue={imperialLengthField}
                    metricValue={metricLengthField}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='1' class={classNames({'msto-quote-line-items-col': true, 'teal-col': !!assemblyKey})}>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.CladTK}
                      datatestid='QAMSTOAllocationTK'
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={imperialTKField}
                      displayedMetricValue={metricTKField}
                      imperialValue={imperialTKField || 0}
                      metricValue={metricTKField || 0}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='1' class={classNames({'msto-quote-line-items-col': true, 'teal-col': !!assemblyKey})}>
                  <LineItemField valueType={QuoteLineItemValueTypes.Line} displayedValue={quoteLineItemData.Line__c} />
                </IonCol>
                <IonCol size='1' class={classNames({'msto-quote-line-items-col': true, 'teal-col': !!assemblyKey})}>
                  <LineItemField valueType={QuoteLineItemValueTypes.Location} displayedValue={panelLocation} />
                </IonCol>
                <IonCol size='1' class={classNames({'msto-quote-line-items-col': true, 'teal-col': !!assemblyKey})}>
                  <LineItemField valueType={QuoteLineItemValueTypes.PanelNumberSW} value={panelNumber} readOnly={true}/>
                </IonCol>
                <IonCol size='3.25' class={classNames({'msto-quote-line-items-col': true, 'teal-col': !!assemblyKey})}>
                  <LineItemField displayedValue={assemblyKeyObject ? `Assembly Group ${assemblyKeyObject.assemblyGroup} - ${assemblyKeyObject.pieceNumber}` : '-'} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const AssembliesQuoteLineItemRow = (props: {
    assemblyPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      metal: MSTOAssemblyMetals;
      panelNumber?: number;
      panelLocation?: PanelLocations;
      seamWeldPieceNumber?: number;
    };
  }) => {
    const { quoteLineItemData, quoteLineItemDetailData, metal, seamWeldPieceNumber } = props.assemblyPanel;
    const lineItemUOM = quoteLineItemData.Unit_of_Measure__c;

    let imperialWidthField: number,
      metricWidthField: number,
      imperialLengthField: number,
      metricLengthField: number,
      imperialTKField: number,
      metricTKField: number,
      seamWeldWidthIn: number,
      seamWeldWidthMm: number,
      seamWeldLengthIn: number,
      seamWeldLengthMm: number,
      seamWeldTKIn: number,
      seamWeldTKMm: number;

    switch (metal) {
      case MSTOAssemblyMetals.CLAD:
        if(seamWeldPieceNumber) {
          seamWeldWidthIn = calculateUOMValue(
            quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
            MeasurementSystems.Imperial,
            quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderWidthSW${seamWeldPieceNumber}__c`],
            Units.Millimeters,
            Units.Inches
          );

          seamWeldWidthMm = calculateUOMValue(
            quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
            MeasurementSystems.Metric,
            quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderWidthSW${seamWeldPieceNumber}__c`],
            Units.Millimeters,
            Units.Inches
          );

          seamWeldLengthIn = calculateUOMValue(
            quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
            MeasurementSystems.Imperial,
            quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderLengthSW${seamWeldPieceNumber}__c`],
            Units.Millimeters,
            Units.Inches
          );

          seamWeldLengthMm = calculateUOMValue(
            quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
            MeasurementSystems.Metric,
            quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderLengthSW${seamWeldPieceNumber}__c`],
            Units.Millimeters,
            Units.Inches
          );

          seamWeldTKIn = calculateUOMValue(
            quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
            MeasurementSystems.Imperial,
            quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`NominalThicknessSW${seamWeldPieceNumber}__c`],
            Units.Millimeters,
            Units.Inches
          );

          seamWeldTKMm = calculateUOMValue(
            quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
            MeasurementSystems.Metric,
            quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`NominalThicknessSW${seamWeldPieceNumber}__c`],
            Units.Millimeters,
            Units.Inches
          );
        }

        imperialWidthField = seamWeldWidthIn || calculateUOMValue(lineItemUOM, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladWidth__c, Units.Millimeters, Units.Inches);
        metricWidthField = seamWeldWidthMm || calculateUOMValue(lineItemUOM, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladWidth__c, Units.Millimeters, Units.Inches);
        imperialLengthField = seamWeldLengthIn || calculateUOMValue(lineItemUOM, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladLength__c, Units.Millimeters, Units.Inches);
        metricLengthField = seamWeldLengthMm || calculateUOMValue(lineItemUOM, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladLength__c, Units.Millimeters, Units.Inches);
        imperialTKField = seamWeldTKIn || calculateUOMValue(lineItemUOM, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladTK__c, Units.Millimeters, Units.Inches);
        metricTKField = seamWeldTKMm || calculateUOMValue(lineItemUOM, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladTK__c, Units.Millimeters, Units.Inches);
        break;
      case MSTOAssemblyMetals.BASE:
        imperialWidthField = calculateUOMValue(lineItemUOM, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches);
        metricWidthField = calculateUOMValue(lineItemUOM, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches);
        imperialLengthField = calculateUOMValue(lineItemUOM, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseLength__c, Units.Millimeters, Units.Inches);
        metricLengthField = calculateUOMValue(lineItemUOM, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseLength__c, Units.Millimeters, Units.Inches);
        imperialTKField = calculateUOMValue(lineItemUOM, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseTK__c, Units.Millimeters, Units.Inches);
        metricTKField = calculateUOMValue(lineItemUOM, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseTK__c, Units.Millimeters, Units.Inches);
        break;
      case MSTOAssemblyMetals.ANVIL:
        imperialWidthField = calculateUOMValue(lineItemUOM, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches);
        metricWidthField = calculateUOMValue(lineItemUOM, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches);
        imperialLengthField = calculateUOMValue(lineItemUOM, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches);
        metricLengthField = calculateUOMValue(lineItemUOM, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches);
        imperialTKField = calculateUOMValue(lineItemUOM, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches);
        metricTKField = calculateUOMValue(lineItemUOM, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches);
        break;
    }

    return (
      <>
        <IonCard className='msto-quote-line-item-card detail' data-testid={'QAMSTOAssemblyPanelRow'}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class="msto-quote-line-item-row">
                <IonCol size="1.5" class='column-left msto-quote-line-items-col'>
                  <LineItemField displayedValue={metal} />
                </IonCol>
                <IonCol size="1.5" class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Piece}
                    displayedValue={`${quoteLineItemData.piece} - 1`}
                  />
                </IonCol>
                <IonCol size="3" class="msto-quote-line-items-col">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladWidth}
                    datatestid='QAMSTOAssemblyPanelWidth'
                    imperialValue={imperialWidthField}
                    metricValue={metricWidthField}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size="3" class="msto-quote-line-items-col">
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladLength}
                    datatestid='QAMSTOAssemblyPanelLength'
                    imperialValue={imperialLengthField}
                    metricValue={metricLengthField}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size="3" class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.CladTK}
                      datatestid='QAMSTOAssemblyPanelTK'
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={imperialTKField}
                      displayedMetricValue={metricTKField}
                      imperialValue={imperialTKField || 0}
                      metricValue={metricTKField || 0}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const AssemblyExpandedTable = (props: {
    assemblyPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      metal: MSTOAssemblyMetals;
      panelNumber?: number;
      panelLocation?: PanelLocations;
      seamWeldPieceNumber?: number;
    };
    assemblyPanelIndex: number;
    expanded: boolean;
  }) => {
    return props.expanded ? (
      <>
        <div className='detail-connector'>
          <hr className='detail-connector-vert-line assembly' />
        </div>
        <div className='detail-connector'>
          <hr className='detail-connector-hor-line assembly' />
        </div>
        <AssembliesQuoteLineItemRow assemblyPanel={props.assemblyPanel} />
      </>
    ) : null;
  };

  const AssemblyGroupMSTORow = ({ assemblyKey, assemblyIndex }: { assemblyKey: string; assemblyIndex: number }) => {
    const assemblyGroupSelected = selectedAssemblies.current.includes(assemblyKey);
    const [expanded, setExpanded] = React.useState(false);
    const [isLineChecked, setIsLineChecked] = React.useState<boolean>(assemblyGroupSelected);

    const tableHeadersAssemblyPanelsList = [
      { display: 'Metal', colSize: '1.5' },
      { display: 'Piece', colSize: '1.5' },
      { display: 'Width', colSize: '3' },
      { display: 'Length', colSize: '3' },
      { display: 'TK', colSize: '3' },
    ];

    const assemblyKeyObject = JSON.parse(assemblyKey);

    React.useEffect(() => {
      if(isLineChecked) {
        if(!assemblyGroupSelected) {
          const updatedSelectedAssembliesToDelete = [...selectedAssemblies.current];
          updatedSelectedAssembliesToDelete.push(assemblyKey);
          selectedAssemblies.current = updatedSelectedAssembliesToDelete;
        }
      } else {
        if(assemblyGroupSelected) {
          const updatedSelectedAssembliesToDelete = [...selectedAssemblies.current.filter(assembly => assembly !== assemblyKey)];
          selectedAssemblies.current = updatedSelectedAssembliesToDelete;
        }
      }
    }, [isLineChecked]);

    return (
      <>
        <IonCard className='msto-quote-line-item-card' data-testid={'QAMSTOAssembly_' + assemblyIndex}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='1' class='column-left msto-quote-line-items-col expand-column'>
                  <IonIcon
                    className='msto-quote-line-item-expand-button'
                    data-testid='QAMSTOAssemblyExpandButton'
                    src={expanded ? chevronUp : chevronDown}
                    onClick={() => setExpanded(!expanded)}
                  ></IonIcon>
                </IonCol>
                <IonCol size='1' class='msto-quote-line-items-col checkbox'>
                  <CheckboxItem
                    style={{
                      width: 'fit-content',
                      marginTop: '6px',
                      marginBottom: 'auto',
                      ['--background']: 'transparent',
                    }}
                    onIonChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                      setIsLineChecked(checked);
                    }}
                    checked={isLineChecked}
                  />
                </IonCol>
                <IonCol size='10' class='msto-quote-line-items-col'>
                  <LineItemField displayedValue={`Assembly Group ${assemblyKeyObject.assemblyGroup} - ${assemblyKeyObject.pieceNumber}`} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        {expanded ? <TableHeaders labels={tableHeadersAssemblyPanelsList} details={true} /> : null}
        {assemblies.get(assemblyKey).map((assemblyPanel, idx) => {
          return (
            <AssemblyExpandedTable
              expanded={expanded}
              assemblyPanel={assemblyPanel}
              assemblyPanelIndex={idx}
              key={idx}
            />
          );
        })}
      </>
    );
  };

  const MSTOCladderQuoteLineItemRow = ({
    quoteLineItem,
    quoteLineItemIndex = 1,
  }: {
    quoteLineItem: QuoteLineItem;
    quoteLineItemIndex: number;
  }) => {
    const [weldWidthIn, setWeldWidthIn] = React.useState(0);
    const [weldWidthMm, setWeldWidthMm] = React.useState(0);
    const [weldLengthIn, setWeldLengthIn] = React.useState(0);
    const [weldLengthMm, setWeldLengthMm] = React.useState(0);

    const quoteLineItemId = quoteLineItem.UUID__c || quoteLineItem.Id || quoteLineItem.displayId;
    const assemblySelected = selectedCladdersToUseAsIs.current.some(
      (cladderLine) => (cladderLine.UUID__c || cladderLine.Id || cladderLine.displayId) === quoteLineItemId
    );
    const cladderDetail = quoteLineItem.quoteLineItemDetail;
    const [isLineChecked, setIsLineChecked] = React.useState<boolean>(assemblySelected);

    const updateChangedFields = (newValue, valueType: QuoteLineItemValueTypes, index: number) => {
      switch(valueType) {
        case QuoteLineItemValueTypes.WeldingWidth:
          const newWeldWidthIn = calculateUOMValue(displayUom, MeasurementSystems.Imperial, newValue, Units.Millimeters, Units.Inches);
          const newWeldWidthMm = calculateUOMValue(displayUom, MeasurementSystems.Metric, newValue, Units.Millimeters, Units.Inches);

          weldingDimensionsMap.current.set(quoteLineItemId, {
            weldWidth: quoteLineItem.Unit_of_Measure__c === MeasurementSystems.Metric ? newWeldWidthMm : newWeldWidthIn,
            weldLength: quoteLineItem.Unit_of_Measure__c === MeasurementSystems.Metric ? weldLengthMm : weldLengthIn
          });
          setWeldWidthIn(newWeldWidthIn);
          setWeldWidthMm(newWeldWidthMm);
          break;
        case QuoteLineItemValueTypes.WeldingLength:
          const newWeldLengthIn = calculateUOMValue(displayUom, MeasurementSystems.Imperial, newValue, Units.Millimeters, Units.Inches);
          const newWeldLengthMm = calculateUOMValue(displayUom, MeasurementSystems.Metric, newValue, Units.Millimeters, Units.Inches);

          weldingDimensionsMap.current.set(quoteLineItemId, {
            weldWidth: quoteLineItem.Unit_of_Measure__c === MeasurementSystems.Metric ? weldWidthMm : weldWidthIn,
            weldLength: quoteLineItem.Unit_of_Measure__c === MeasurementSystems.Metric ? newWeldLengthMm : newWeldLengthIn
          });
          setWeldLengthIn(newWeldLengthIn);
          setWeldLengthMm(newWeldLengthMm);
          break;
      }
    };

    React.useEffect(() => {
      const lineItem = weldingDimensionsMap.current.get(quoteLineItem.UUID__c || quoteLineItem.Id || quoteLineItem.displayId);
      if(lineItem) {
        setWeldWidthIn(calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, lineItem.weldWidth, Units.Millimeters, Units.Inches));
        setWeldWidthMm(calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, lineItem.weldWidth, Units.Millimeters, Units.Inches));
        setWeldLengthIn(calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, lineItem.weldLength, Units.Millimeters, Units.Inches));
        setWeldLengthMm(calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, lineItem.weldLength, Units.Millimeters, Units.Inches));
      }
    }, [weldingDimensionsMap]);

    React.useEffect(() => {
      try {
        if (isLineChecked) {
          if (!assemblySelected) {
            const updatedSelectedCladders = [...selectedCladdersToUseAsIs.current];
            updatedSelectedCladders.push(quoteLineItem);
            selectedCladdersToUseAsIs.current = updatedSelectedCladders;
          }
        } else {
          if (assemblySelected) {
            const updatedSelectedCladders = [
              ...selectedCladdersToUseAsIs.current.filter(
                (cladderLine) => (cladderLine.UUID__c || cladderLine.Id || cladderLine.displayId) !== quoteLineItemId
              )
            ];
            selectedCladdersToUseAsIs.current = updatedSelectedCladders;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, [isLineChecked]);

    return (
      <>
        <IonCard className='msto-quote-line-item-card' data-testid={'QAMSTOQuoteLineItem_' + quoteLineItemIndex}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.5' class='msto-quote-line-items-col'>
                  <CheckboxItem
                    style={{
                      width: 'fit-content',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginRight: '-20px',
                      ['--background']: 'transparent',
                    }}
                    onIonChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                      setIsLineChecked(checked);
                    }}
                    checked={isLineChecked}
                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Line} displayedValue={quoteLineItem.Line__c} />
                </IonCol>
                <IonCol size='0.5' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Clads}
                    displayedValue={cladderDetail.Clads__c || '- -'}
                  />
                </IonCol>
                <IonCol size='1.5' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladMetal}
                    displayedValue={quoteLineItem.CladMetal__c}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladWidth}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, cladderDetail.PurchaseCladWidth__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, cladderDetail.PurchaseCladWidth__c, Units.Millimeters, Units.Inches)}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladLength}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, cladderDetail.PurchaseCladLength__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, cladderDetail.PurchaseCladLength__c, Units.Millimeters, Units.Inches)}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.CladTK}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, cladderDetail.PurchaseCladTK__c, Units.Millimeters, Units.Inches)}
                      displayedMetricValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, cladderDetail.PurchaseCladTK__c, Units.Millimeters, Units.Inches)}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.PercentAllocated}
                      unitType={QuoteLineItemFieldUnitTypes.Percentage}
                      value={cladderAssemblyDisplayMap.current.get(quoteLineItemId)?.percent}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <CheckboxItem
                      style={{
                        width: 'fit-content',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        ['--background']: 'transparent',
                      }}
                      onIonChange={(checked: boolean) => {
                        const updatedCladderAssemblyDisplayMap = new Map(cladderAssemblyDisplayMap.current);
                        const currentAssemblyDisplay = updatedCladderAssemblyDisplayMap.get(quoteLineItemId);

                        updatedCladderAssemblyDisplayMap.set(quoteLineItemId, {
                          ...currentAssemblyDisplay,
                          weld: checked,
                        });
                        cladderAssemblyDisplayMap.current = updatedCladderAssemblyDisplayMap;
                      }}
                      checked={cladderAssemblyDisplayMap.current.get(quoteLineItemId)?.weld}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                  <LineItemField
                    datatestid="QAAddPieceModalPanelWidth"
                    valueType={QuoteLineItemValueTypes.WeldingWidth}
                    imperialValue={weldWidthIn}
                    metricValue={weldWidthMm}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={false}

                    fieldChangedCB={updateChangedFields}
                    ignoreRecalculateFields={true}
                    tabIndex={quoteLineItemIndex + '.0'}
                    nextTabIndex={quoteLineItemIndex + '.1'}
                  />
                  </div>
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.WeldingLength}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                      imperialValue={weldLengthIn}
                      metricValue={weldLengthMm}
                      readOnly={false}

                      fieldChangedCB={updateChangedFields}
                      ignoreRecalculateFields={true}
                      tabIndex={quoteLineItemIndex + '.1'}
                      nextTabIndex={quoteLineItemIndex + '.2'}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const MSTOBackerQuoteLineItemRow = ({
    quoteLineItem,
    quoteLineItemIndex = 1,
  }: {
    quoteLineItem: QuoteLineItem;
    quoteLineItemIndex: number;
  }) => {
    const quoteLineItemId = quoteLineItem.UUID__c || quoteLineItem.Id || quoteLineItem.displayId;
    const assemblySelected = selectedBackersToUseAsIs.current.some(
      (backerLine) => (backerLine.UUID__c || backerLine.Id || backerLine.displayId) === quoteLineItemId
    );
    const backerDetail = quoteLineItem.quoteLineItemDetail;

    const [isLineChecked, setIsLineChecked] = React.useState<boolean>(assemblySelected);

    React.useEffect(() => {
      try {
        if (isLineChecked) {
          if (!assemblySelected) {
            const updatedSelectedBackers = [...selectedBackersToUseAsIs.current];
            updatedSelectedBackers.push(quoteLineItem);
            selectedBackersToUseAsIs.current = updatedSelectedBackers;
          }
        } else {
          if (assemblySelected) {
            const updatedSelectedBackers = [
              ...selectedBackersToUseAsIs.current.filter(
                (cladderLine) => (cladderLine.UUID__c || cladderLine.Id || cladderLine.displayId) !== quoteLineItemId
              ),
            ];
            selectedBackersToUseAsIs.current = updatedSelectedBackers;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, [isLineChecked]);

    return (
      <>
        <IonCard className='msto-quote-line-item-card' data-testid={'QAMSTOQuoteLineItem_' + quoteLineItemIndex}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.5' class='msto-quote-line-items-col'>
                  <CheckboxItem
                    style={{
                      width: 'fit-content',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginRight: '-20px',
                      ['--background']: 'transparent',
                    }}
                    onIonChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                      setIsLineChecked(checked);
                    }}
                    checked={isLineChecked}
                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Line} displayedValue={quoteLineItem.Line__c} />
                </IonCol>
                <IonCol size='0.5' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Clads}
                    displayedValue={backerDetail.Clads__c || '- -'}
                  />
                </IonCol>
                <IonCol size='1.5' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseMetal}
                    displayedValue={quoteLineItem.BaseMetal__c}
                    readOnly={true}
                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseWidth}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, backerDetail.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, backerDetail.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches)}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseLength}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, backerDetail.PurchaseBaseLength__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, backerDetail.PurchaseBaseLength__c, Units.Millimeters, Units.Inches)}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.BaseTK}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, backerDetail.PurchaseBaseTK__c, Units.Millimeters, Units.Inches)}
                      displayedMetricValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, backerDetail.PurchaseBaseTK__c, Units.Millimeters, Units.Inches)}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.PercentAllocated}
                      unitType={QuoteLineItemFieldUnitTypes.Percentage}
                      value={backerAssemblyDisplayMap.current.get(quoteLineItemId)?.percent}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <CheckboxItem
                      style={{
                        width: 'fit-content',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        ['--background']: 'transparent',
                      }}
                      onIonChange={(checked: boolean) => {
                        const updatedBackerAssemblyDisplayMap = new Map(backerAssemblyDisplayMap.current);
                        const currentAssemblyDisplay = updatedBackerAssemblyDisplayMap.get(quoteLineItemId);

                        updatedBackerAssemblyDisplayMap.set(quoteLineItemId, {
                          ...currentAssemblyDisplay,
                          cutting: checked,
                        });
                        backerAssemblyDisplayMap.current = updatedBackerAssemblyDisplayMap;
                      }}
                      checked={backerAssemblyDisplayMap.current.get(quoteLineItemId)?.cutting}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const MSTOAnvilQuoteLineItemRow = ({
    quoteLineItem,
    quoteLineItemIndex = 1,
  }: {
    quoteLineItem: QuoteLineItem;
    quoteLineItemIndex: number;
  }) => {
    const quoteLineItemId = quoteLineItem.UUID__c || quoteLineItem.Id || quoteLineItem.displayId;
    const assemblySelected = selectedAnvilsToUseAsIs.current.some(
      (anvilLine) => (anvilLine.UUID__c || anvilLine.Id || anvilLine.displayId) === quoteLineItemId
    );
    const anvilDetail = quoteLineItem.quoteLineItemDetail;

    const [isLineChecked, setIsLineChecked] = React.useState<boolean>(assemblySelected);

    React.useEffect(() => {
      try {
        if (isLineChecked) {
          if (!assemblySelected) {
            const updatedSelectedAnvils = [...selectedAnvilsToUseAsIs.current];
            updatedSelectedAnvils.push(quoteLineItem);
            selectedAnvilsToUseAsIs.current = updatedSelectedAnvils;
          }
        } else {
          if (assemblySelected) {
            const updatedSelectedAnvils = [
              ...selectedAnvilsToUseAsIs.current.filter(
                (anvilLine) => (anvilLine.UUID__c || anvilLine.Id || anvilLine.displayId) !== quoteLineItemId
              ),
            ];
            selectedAnvilsToUseAsIs.current = updatedSelectedAnvils;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, [isLineChecked]);

    return (
      <>
        <IonCard className='msto-quote-line-item-card' data-testid={'QAMSTOQuoteLineItem_' + quoteLineItemIndex}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.5' class='msto-quote-line-items-col'>
                  <CheckboxItem
                    style={{
                      width: 'fit-content',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginRight: '-20px',
                      ['--background']: 'transparent',
                    }}
                    onIonChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                      setIsLineChecked(checked);
                    }}
                    checked={isLineChecked}
                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Line} displayedValue={quoteLineItem.Line__c} />
                </IonCol>
                <IonCol size='0.5' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Clads}
                    displayedValue={anvilDetail.Clads__c || '- -'}
                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.AnvilWidth}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, anvilDetail.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, anvilDetail.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches)}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.AnvilLength}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, anvilDetail.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, anvilDetail.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches)}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.AnvilTK}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Imperial, anvilDetail.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches)}
                      displayedMetricValue={calculateUOMValue(quoteLineItem.Unit_of_Measure__c, MeasurementSystems.Metric, anvilDetail.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches)}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.PercentAllocated}
                      unitType={QuoteLineItemFieldUnitTypes.Percentage}
                      value={anvilAssemblyDisplayMap.current.get(quoteLineItemId)?.percent}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
      </>
    );
  };

  const CladderMSTODetails = (props: {
    cladderPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      panelNumber?: number;
      panelLocation?: PanelLocations;
      seamWeldPieceNumber?: number;
      lengthWidthTKDisplayFields?: Record<string, number>;
    };
    hasPurchaseDelta: boolean;
    deltas: { widthDelta: number; lengthDelta: number; tkDelta: number };
  }) => {
    const { panelLocation, quoteLineItemData, quoteLineItemDetailData, seamWeldPieceNumber, lengthWidthTKDisplayFields } = props.cladderPanel;

    const seamWeldWidthIn = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Imperial,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderWidthSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );
    const seamWeldWidthMm = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Metric,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderWidthSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );

    const seamWeldLengthIn = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Imperial,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderLengthSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );
    const seamWeldLengthMm = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Metric,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderLengthSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );

    const seamWeldTKIn = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Imperial,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`NominalThicknessSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );
    const seamWeldTKMm = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Metric,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`NominalThicknessSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );
    return (
      <>
        <IonCard className='msto-quote-line-item-card detail' data-testid={'QAMSTOCladderMSTODetails'}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.72' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Piece}
                    displayedValue={`${quoteLineItemData.piece} - 1`}
                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  {/* this is the line detail that displays the cut down panel size */}
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladWidth}
                    datatestid="QAMSTOCladderMSTODetailsWidth"
                    displayedImperialValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, lengthWidthTKDisplayFields?.DisplayWidth || quoteLineItemDetailData.PurchaseCladWidth__c, Units.Millimeters, Units.Inches) : seamWeldWidthIn}
                    displayedMetricValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, lengthWidthTKDisplayFields?.DisplayWidth || quoteLineItemDetailData.PurchaseCladWidth__c, Units.Millimeters, Units.Inches) : seamWeldWidthMm}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladLength}
                    datatestid='QAMSTOCladderMSTODetailsLength'
                    displayedImperialValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, lengthWidthTKDisplayFields?.DisplayLength || quoteLineItemDetailData.PurchaseCladLength__c, Units.Millimeters, Units.Inches) : seamWeldLengthIn}
                    displayedMetricValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, lengthWidthTKDisplayFields?.DisplayLength || quoteLineItemDetailData.PurchaseCladLength__c, Units.Millimeters, Units.Inches) : seamWeldLengthMm}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='1.14' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.CladTK}
                      datatestid='QAMSTOCladderMSTODetailsCladTK'
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, lengthWidthTKDisplayFields?.DisplayTK || quoteLineItemDetailData.PurchaseCladTK__c, Units.Millimeters, Units.Inches) : seamWeldTKIn}
                      displayedMetricValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, lengthWidthTKDisplayFields?.DisplayTK || quoteLineItemDetailData.PurchaseCladTK__c, Units.Millimeters, Units.Inches) : seamWeldTKMm}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Line}
                    displayedValue={quoteLineItemData.Line__c}
                  />
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Allocation}
                    displayedValue={AllocationOptions.AssemblyPart}
                  />
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Location} displayedValue={panelLocation} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        {props.hasPurchaseDelta && (
        <IonCard className='msto-quote-line-item-card detail' data-testid={'QAMSTOCladderMSTODetails'}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.72' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Piece}
                    displayedValue={`${quoteLineItemData.piece} - 2`}
                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladWidth}
                    datatestid="QAMSTOCladderMSTODetailsWidth"
                    displayedImperialValue={props.deltas.widthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladWidth__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={props.deltas.widthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladWidth__c, Units.Millimeters, Units.Inches)}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladLength}
                    datatestid='QAMSTOCladderMSTODetailsLength'
                    displayedImperialValue={props.deltas.lengthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladLength__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={props.deltas.lengthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladLength__c, Units.Millimeters, Units.Inches)}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='1.14' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.CladTK}
                      datatestid='QAMSTOCladderMSTODetailsCladTK'
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={props.deltas.tkDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladTK__c, Units.Millimeters, Units.Inches)}
                      displayedMetricValue={props.deltas.tkDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladTK__c, Units.Millimeters, Units.Inches)}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col' />
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Allocation}
                    displayedValue={AllocationOptions.Unallocated}
                  />
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Location} displayedValue={'Unallocated'} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        )}
      </>
    );
  };

  const BackerMSTODetails = (props: {
    backerPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      lengthWidthTKDisplayFields?: Record<string, number>;
    };
    hasPurchaseDelta: boolean;
    deltas: { widthDelta: number; lengthDelta: number; tkDelta: number };
  }) => {
    const { quoteLineItemData, quoteLineItemDetailData, lengthWidthTKDisplayFields } = props.backerPanel;

    return (
      <>
        <IonCard className='msto-quote-line-item-card detail' data-testid={'QAMSTOBackerMSTODetails'}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.72' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Piece}
                    displayedValue={`${quoteLineItemData.piece} - 1`}
                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseWidth}
                    datatestid='QAMSTOBackerMSTODetailsWidth'
                    displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, lengthWidthTKDisplayFields?.DisplayWidth || quoteLineItemDetailData.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, lengthWidthTKDisplayFields?.DisplayWidth || quoteLineItemDetailData.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches)}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseLength}
                    datatestid='QAMSTOBackerMSTODetailsLength'
                    displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, lengthWidthTKDisplayFields?.DisplayLength || quoteLineItemDetailData.PurchaseBaseLength__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, lengthWidthTKDisplayFields?.DisplayLength || quoteLineItemDetailData.PurchaseBaseLength__c, Units.Millimeters, Units.Inches)}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='1.14' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.BaseTK}
                      datatestid='QAMSTOBackerMSTODetailsBaseTK'
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, lengthWidthTKDisplayFields?.DisplayTK || quoteLineItemDetailData.PurchaseBaseTK__c, Units.Millimeters, Units.Inches)}
                      displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, lengthWidthTKDisplayFields?.DisplayTK || quoteLineItemDetailData.PurchaseBaseTK__c, Units.Millimeters, Units.Inches)}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Line}
                    displayedValue={quoteLineItemData.Line__c}
                  />
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Allocation}
                    displayedValue={AllocationOptions.AssemblyPart}
                  />
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Location} displayedValue={''} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        {props.hasPurchaseDelta && (
        <IonCard className='msto-quote-line-item-card detail' data-testid={'QAMSTOBackerMSTODetails'}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.72' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Piece}
                    displayedValue={`${quoteLineItemData.piece} - 2`}
                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseWidth}
                    datatestid='QAMSTOBackerMSTODetailsWidth'
                    displayedImperialValue={props.deltas.widthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={props.deltas.widthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches)}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseLength}
                    datatestid='QAMSTOBackerMSTODetailsLength'
                    displayedImperialValue={props.deltas.lengthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseLength__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={props.deltas.lengthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseLength__c, Units.Millimeters, Units.Inches)}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='1.14' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.BaseTK}
                      datatestid='QAMSTOBackerMSTODetailsBaseTK'
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={props.deltas.tkDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseTK__c, Units.Millimeters, Units.Inches)}
                      displayedMetricValue={props.deltas.tkDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseTK__c, Units.Millimeters, Units.Inches)}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col' />
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Allocation}
                    displayedValue={AllocationOptions.Unallocated}
                  />
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Location} displayedValue={'Unallocated'} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        )}
      </>
    );
  };

  const AnvilMSTODetails = (props: {
    anvilPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      lengthWidthTKDisplayFields?: Record<string, number>;
    };
    hasPurchaseDelta: boolean;
    deltas: { widthDelta: number; lengthDelta: number; tkDelta: number };
  }) => {
    const { quoteLineItemData, quoteLineItemDetailData, lengthWidthTKDisplayFields } = props.anvilPanel;

    return (
      <>
        <IonCard className='msto-quote-line-item-card detail' data-testid={'QAMSTOAnvilMSTODetails'}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.72' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Piece}
                    displayedValue={`${quoteLineItemData.piece} - 1`}
                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.AnvilWidth}
                    datatestid='QAMSTOAnvilMSTODetailsWidth'
                    displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, lengthWidthTKDisplayFields?.DisplayWidth || quoteLineItemDetailData.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, lengthWidthTKDisplayFields?.DisplayWidth || quoteLineItemDetailData.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches)}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.AnvilLength}
                    datatestid="QAMSTOAnvilMSTODetailsLength"
                    displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, lengthWidthTKDisplayFields?.DisplayLength || quoteLineItemDetailData.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, lengthWidthTKDisplayFields?.DisplayLength || quoteLineItemDetailData.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches)}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='1.14' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.AnvilTK}
                      datatestid='QAMSTOAnvilMSTODetailsAnvilTK'
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, lengthWidthTKDisplayFields?.DisplayTK || quoteLineItemDetailData.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches)}
                      displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, lengthWidthTKDisplayFields?.DisplayTK || quoteLineItemDetailData.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches)}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Line}
                    displayedValue={quoteLineItemData.Line__c}
                  />
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Allocation}
                    displayedValue={AllocationOptions.AssemblyPart}
                  />
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Location} displayedValue={''} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        {props.hasPurchaseDelta && (
        <IonCard className='msto-quote-line-item-card detail' data-testid={'QAMSTOAnvilMSTODetails'}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.72' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Piece}
                    displayedValue={`${quoteLineItemData.piece} - 2`}
                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.AnvilWidth}
                    datatestid='QAMSTOAnvilMSTODetailsWidth'
                    displayedImperialValue={props.deltas.widthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={props.deltas.widthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches)}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol class='msto-quote-line-items-col' size='1.14'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.AnvilLength}
                    datatestid="QAMSTOAnvilMSTODetailsLength"
                    displayedImperialValue={props.deltas.lengthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={props.deltas.lengthDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches)}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='1.14' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.AnvilTK}
                      datatestid='QAMSTOAnvilMSTODetailsAnvilTK'
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={props.deltas.tkDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches)}
                      displayedMetricValue={props.deltas.tkDelta || calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches)}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col' />
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.Allocation}
                    displayedValue={AllocationOptions.Unallocated}
                  />
                </IonCol>
                <IonCol size='2.22' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Location} displayedValue={'Unallocated'} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        )}
      </>
    );
  };

  const CladderMSTOExpandedTable = (props: {
    cladderPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      panelNumber?: number;
      panelLocation?: PanelLocations;
      seamWeldPieceNumber?: number;
      lengthWidthTKDisplayFields?: Record<string, number>;
    };
    hasPurchaseDelta: boolean;
    deltas: { widthDelta: number, lengthDelta: number, tkDelta: number };
    quoteLineItemIndex: number;
    expanded: boolean;
  }) => {
    const tableHeadersExpandedCladderList = [
      { display: MSTOColumnHeadings.Piece, colSize: '0.72' },
      { display: MSTOColumnHeadings.Width, colSize: '1.14' },
      { display: MSTOColumnHeadings.Length, colSize: '1.14' },
      { display: MSTOColumnHeadings.CladTK, colSize: '1.14' },
      { display: MSTOColumnHeadings.AssemblyGroup, colSize: '2.22' },
      { display: MSTOColumnHeadings.Allocation, colSize: '2.22' },
      { display: MSTOColumnHeadings.Location, colSize: '2.22' },
    ];

    return props.expanded ? (
      <>
        <div className='detail-connector'>
          <hr className='detail-connector-vert-line' />
        </div>
        <div className='detail-connector'>
          <hr className='detail-connector-hor-line' />
        </div>
        <TableHeaders labels={tableHeadersExpandedCladderList} details={true} />
        <CladderMSTODetails
          cladderPanel={props.cladderPanel}
          hasPurchaseDelta={props.hasPurchaseDelta}
          deltas={props.deltas}
        />
      </>
    ) : null;
  };

  const BackerMSTOExpandedTable = (props: {
    backerPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      lengthWidthTKDisplayFields?: Record<string, number>;
    };
    hasPurchaseDelta: boolean;
    deltas: { widthDelta: number, lengthDelta: number, tkDelta: number };
    quoteLineItemIndex: number;
    expanded: boolean;
  }) => {
    const tableHeadersExpandedBackerList = [
      { display: MSTOColumnHeadings.Piece, colSize: '0.72' },
      { display: MSTOColumnHeadings.Width, colSize: '1.14' },
      { display: MSTOColumnHeadings.Length, colSize: '1.14' },
      { display: MSTOColumnHeadings.BaseTK, colSize: '1.14' },
      { display: MSTOColumnHeadings.AssemblyGroup, colSize: '2.22' },
      { display: MSTOColumnHeadings.Allocation, colSize: '2.22' },
      { display: MSTOColumnHeadings.Location, colSize: '2.22' },
    ];

    return props.expanded ? (
      <>
        <div className='detail-connector'>
          <hr className='detail-connector-vert-line' />
        </div>
        <div className='detail-connector'>
          <hr className='detail-connector-hor-line' />
        </div>
        <TableHeaders labels={tableHeadersExpandedBackerList} details={true} />
        <BackerMSTODetails
          backerPanel={props.backerPanel}
          hasPurchaseDelta={props.hasPurchaseDelta}
          deltas={props.deltas}
        />
      </>
    ) : null;
  };

  const AnvilMSTOExpandedTable = (props: {
    anvilPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      lengthWidthTKDisplayFields?: Record<string, number>;
    };
    hasPurchaseDelta: boolean;
    deltas: { widthDelta: number, lengthDelta: number, tkDelta: number };
    quoteLineItemIndex: number;
    expanded: boolean;
  }) => {
    const tableHeadersExpandedAnvilList = [
      { display: MSTOColumnHeadings.Piece, colSize: '0.72' },
      { display: MSTOColumnHeadings.Width, colSize: '1.14' },
      { display: MSTOColumnHeadings.Length, colSize: '1.14' },
      { display: MSTOColumnHeadings.AnvilTK, colSize: '1.14' },
      { display: MSTOColumnHeadings.AssemblyGroup, colSize: '2.22' },
      { display: MSTOColumnHeadings.Allocation, colSize: '2.22' },
      { display: MSTOColumnHeadings.Location, colSize: '2.22' },
    ];

    return props.expanded ? (
      <>
        <div className='detail-connector'>
          <hr className='detail-connector-vert-line' />
        </div>
        <div className='detail-connector'>
          <hr className='detail-connector-hor-line' />
        </div>
        <TableHeaders labels={tableHeadersExpandedAnvilList} details={true} />
        <AnvilMSTODetails
          anvilPanel={props.anvilPanel}
          hasPurchaseDelta={props.hasPurchaseDelta}
          deltas={props.deltas}
        />
      </>
    ) : null;
  };

  const CladderMSTORow = ({
    cladderPanel,
    quoteLineItemIndex = 1,
  }: {
    cladderPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      panelNumber?: number;
      panelLocation?: PanelLocations;
      seamWeldPieceNumber?: number;
      lengthWidthTKDisplayFields?: Record<string, number>;
    };
    quoteLineItemIndex: number;
  }) => {
    const { quoteLineItemData, quoteLineItemDetailData, seamWeldPieceNumber, lengthWidthTKDisplayFields } = cladderPanel;
    const { widthDelta, lengthDelta, tkDelta } = getPurchaseDeltas(
      quoteLineItemDetailData,
      lengthWidthTKDisplayFields,
      MSTOAssemblyMetals.CLAD,
      displayUom
    );
    // Note: leaving in negative deltas for now, but may need to remove them later in favor of only positive deltas, per Jen
    const hasPurchaseDelta = widthDelta !== 0 || lengthDelta !== 0 || tkDelta !== 0;

    const quoteLineItemId = quoteLineItemData.UUID__c || quoteLineItemData.Id || quoteLineItemData.displayId;
    const cladderMSTOSelected = selectedCladdersToEdit.current.some(
      (cladderMSTO) =>
        (cladderMSTO.UUID__c || cladderMSTO.Id || cladderMSTO.displayId) === quoteLineItemId && cladderMSTO.piece === quoteLineItemData.piece
    );
    const seamWeldWidthIn = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Imperial,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderWidthSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );
    const seamWeldWidthMm = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Metric,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderWidthSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );

    const seamWeldLengthIn = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Imperial,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderLengthSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );
    const seamWeldLengthMm = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Metric,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`CladderLengthSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );

    const seamWeldTKIn = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Imperial,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`NominalThicknessSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );
    const seamWeldTKMm = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Metric,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`NominalThicknessSW${seamWeldPieceNumber}__c`],
      Units.Millimeters,
      Units.Inches
    );

    const seamWeldWeightLb = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Imperial,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`WeightSW${seamWeldPieceNumber}__c`],
      Units.Kilograms,
      Units.Pounds
    );
    const seamWeldWeightKg = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Metric,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`WeightSW${seamWeldPieceNumber}__c`],
      Units.Kilograms,
      Units.Pounds
    );

    const seamWeldPricePerLb = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Imperial,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`SWRawMaterialCostSW${seamWeldPieceNumber}__c`] /
        quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`WeightSW${seamWeldPieceNumber}__c`],
      Units.PricePerKilogram,
      Units.PricePerPound
    );
    const seamWeldPricePerKg = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Metric,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`SWRawMaterialCostSW${seamWeldPieceNumber}__c`] /
        quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`WeightSW${seamWeldPieceNumber}__c`],
      Units.PricePerKilogram,
      Units.PricePerPound
    );

    const seamWeldFreightPerLb = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Imperial,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`EstimatedShippingTotalSW${seamWeldPieceNumber}__c`],
      Units.PricePerKilogram,
      Units.PricePerPound
    );
    const seamWeldFreightPerKg = calculateUOMValue(
      quoteLineItemDetailData.Unit_of_Measure__c as MeasurementSystems,
      MeasurementSystems.Metric,
      quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`EstimatedShippingTotalSW${seamWeldPieceNumber}__c`],
      Units.PricePerKilogram,
      Units.PricePerPound
    );

    let cladderSupplier;

    if (!seamWeldPieceNumber) {
      cladderSupplier = quoteLineItemDetailData.Pullfromstockcladder__c || NCSuppliers.includes(quoteLineItemDetailData?.RawMaterialVendorCladder__c)
        ? 'DMC Stock'
        : vendors?.find((vendor) => vendor.vendorId === quoteLineItemDetailData.RawMaterialVendorCladder__c)
            ?.vendorName;
    } else {
      cladderSupplier = quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`PullFromStockSW${seamWeldPieceNumber}__c`] || NCSuppliers.includes(quoteLineItemDetailData?.NC_Seam_Weld_Item_Details__r[`RawMaterialVendorSW${seamWeldPieceNumber}__c`])
        ? 'DMC Stock'
        : vendors?.find(
            (vendor) =>
              vendor.vendorId ===
              quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`RawMaterialVendorSW${seamWeldPieceNumber}__c`]
          )?.vendorName;
    }

    const [isLineChecked, setIsLineChecked] = React.useState<boolean>(cladderMSTOSelected);
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
      try {
        if (isLineChecked) {
          if (!cladderMSTOSelected) {
            const updatedSelectedCladders = [...selectedCladdersToEdit.current];
            updatedSelectedCladders.push({ ...quoteLineItemData });
            selectedCladdersToEdit.current = updatedSelectedCladders;
          }
        } else {
          if (cladderMSTOSelected) {
            const updatedSelectedCladders = [
              ...selectedCladdersToEdit.current.filter(
                (cladderLine) =>
                  (cladderLine.UUID__c || cladderLine.Id || cladderLine.displayId) !== quoteLineItemId ||
                  cladderLine.piece !== quoteLineItemData.piece
              ),
            ];
            selectedCladdersToEdit.current = updatedSelectedCladders;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, [isLineChecked]);

    return (
      <>
        <IonCard className='msto-quote-line-item-card' data-testid={'QAMSTOQuoteLineItem_' + quoteLineItemIndex}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.23' class='column-left msto-quote-line-items-col expand-column'>
                  <IonIcon
                    className='msto-quote-line-item-expand-button'
                    data-testid='QAMSTOQuoteLineItemExpandButton'
                    src={expanded ? chevronUp : chevronDown}
                    onClick={() => setExpanded(!expanded)}
                  ></IonIcon>
                </IonCol>
                <IonCol size='0.76' class='msto-quote-line-items-col'>
                  <CheckboxItem
                    style={{
                      width: 'fit-content',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      ['--background']: 'transparent',
                    }}
                    onIonChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                      setIsLineChecked(checked);
                    }}
                    checked={isLineChecked}
                  />
                </IonCol>
                <IonCol size='0.4' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Piece} displayedValue={quoteLineItemData.piece} />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  {/* this is the main line on the bottom when clad is added, displays purchased price */}
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladWidth}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladWidth__c, Units.Millimeters, Units.Inches) : seamWeldWidthIn}
                    displayedMetricValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladWidth__c, Units.Millimeters, Units.Inches) : seamWeldWidthMm}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.CladLength}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladLength__c, Units.Millimeters, Units.Inches) : seamWeldLengthIn}
                    displayedMetricValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladLength__c, Units.Millimeters, Units.Inches) : seamWeldLengthMm}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.CladTK}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseCladTK__c, Units.Millimeters, Units.Inches) : seamWeldTKIn}
                      displayedMetricValue={!seamWeldPieceNumber ? calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseCladTK__c, Units.Millimeters, Units.Inches) : seamWeldTKMm}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOCladderMSTOWeight'
                      valueType={QuoteLineItemValueTypes.PurchaseCladderWeight}
                      unitType={QuoteLineItemFieldUnitTypes.Weight}
                      imperialValue={!seamWeldPieceNumber ? quoteLineItemDetailData.PurchaseCladderWeight_LB__c : seamWeldWeightLb}
                      metricValue={!seamWeldPieceNumber ? quoteLineItemDetailData.PurchaseCladderWeight_KG__c : seamWeldWeightKg}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOCladderMSTOPrice/Weight'
                      valueType={QuoteLineItemValueTypes.CladPricePerWeight}
                      currency={quoteLineItemData.CurrencyIsoCode}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      displayedImperialValue={!seamWeldPieceNumber ? quoteLineItemData.Clad_Price_LB__c : seamWeldPricePerLb}
                      displayedMetricValue={!seamWeldPieceNumber ? quoteLineItemData.Clad_Price_KG__c : seamWeldPricePerKg}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOCladderMSTOPrice/PC'
                      valueType={QuoteLineItemValueTypes.PurchaseCladderPrice}
                      currency={quoteLineItemData.CurrencyIsoCode}
                      value={
                        !seamWeldPieceNumber
                          ? quoteLineItemDetailData.PurchaseCladderPrice__c
                          : quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`SWRawMaterialCostSW${seamWeldPieceNumber}__c`]
                      }
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      readOnly={true}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOCladderMSTOFreightCost/Weight'
                      valueType={QuoteLineItemValueTypes.PurchaseCladderFreightPerWeight}
                      currency={quoteLineItemData.CurrencyIsoCode}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      displayedImperialValue={
                        !seamWeldPieceNumber ? quoteLineItemDetailData.PurchaseCladderFreight_LB__c : seamWeldFreightPerLb
                      }
                      displayedMetricValue={!seamWeldPieceNumber ? quoteLineItemDetailData.PurchaseCladderFreight_KG__c : seamWeldFreightPerKg}
                      imperialValue={!seamWeldPieceNumber ? quoteLineItemDetailData.PurchaseCladderFreight_LB__c : seamWeldFreightPerLb}
                      metricValue={!seamWeldPieceNumber ? quoteLineItemDetailData.PurchaseCladderFreight_KG__c : seamWeldFreightPerKg}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='1.5' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOCladderMSTOSupplier'
                      valueType={QuoteLineItemValueTypes.CladderSupplier}
                      displayedValue={cladderSupplier}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.9' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOCladderMSTOCustomerPO'
                      valueType={QuoteLineItemValueTypes.CustomerPO}
                      displayedValue={quoteLineItemData.CustomerPO__c}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.9' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOCladderMSTODate'
                      valueType={QuoteLineItemValueTypes.ShipDateCladder}
                      value={
                        !seamWeldPieceNumber
                          ? quoteLineItemDetailData.ShipDateCladder__c
                          : quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[`ShipDateSW${seamWeldPieceNumber}__c`]
                      }
                      unitType={QuoteLineItemFieldUnitTypes.Date}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.4' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Alloc} displayedValue={1} />
                </IonCol>
                {hasPurchaseDelta && (
                  <IonCol size='0.26' class='column-left msto-quote-line-items-col indicator'>
                    <div className='column-container right-align delta-indicator'>
                      <PurchaseDisplayDeltaInfoIndicator icon={infoIcon} />
                    </div>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <CladderMSTOExpandedTable
          expanded={expanded}
          cladderPanel={cladderPanel}
          quoteLineItemIndex={quoteLineItemIndex}
          hasPurchaseDelta={hasPurchaseDelta}
          deltas={{ widthDelta, lengthDelta, tkDelta }}
        />
      </>
    );
  };

  const BackerMSTORow = ({
    backerPanel,
    quoteLineItemIndex = 1,
  }: {
    backerPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      lengthWidthTKDisplayFields?: Record<string, number>;
    };
    quoteLineItemIndex: number;
  }) => {
    const { quoteLineItemData, quoteLineItemDetailData, lengthWidthTKDisplayFields } = backerPanel;
    const { widthDelta, lengthDelta, tkDelta } = getPurchaseDeltas(
      quoteLineItemDetailData,
      lengthWidthTKDisplayFields,
      MSTOAssemblyMetals.BASE,
      displayUom
    );
    // Note: leaving in negative deltas for now, but may need to remove them later in favor of only positive deltas, per Jen
    const hasPurchaseDelta = widthDelta !== 0 || lengthDelta !== 0 || tkDelta !== 0;

    const quoteLineItemId = quoteLineItemData.UUID__c || quoteLineItemData.Id || quoteLineItemData.displayId;
    const backerMSTOSelected = selectedBackersToEdit.current.some(
      (backerMSTO) =>
        (backerMSTO.UUID__c || backerMSTO.Id || backerMSTO.displayId) === quoteLineItemId && backerMSTO.piece === quoteLineItemData.piece
    );

    const [isLineChecked, setIsLineChecked] = React.useState<boolean>(backerMSTOSelected);
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
      try {
        if (isLineChecked) {
          if (!backerMSTOSelected) {
            const updatedSelectedBackers = [...selectedBackersToEdit.current];
            updatedSelectedBackers.push({ ...quoteLineItemData });
            selectedBackersToEdit.current = updatedSelectedBackers;
          }
        } else {
          if (backerMSTOSelected) {
            const updatedSelectedBackers = [
              ...selectedBackersToEdit.current.filter(
                (backerLine) =>
                  (backerLine.UUID__c || backerLine.Id || backerLine.displayId) !== quoteLineItemId || backerLine.piece !== quoteLineItemData.piece
              ),
            ];
            selectedBackersToEdit.current = updatedSelectedBackers;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, [isLineChecked]);

    return (
      <>
        <IonCard className='msto-quote-line-item-card' data-testid={'QAMSTOQuoteLineItem_' + quoteLineItemIndex}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.23' class='column-left msto-quote-line-items-col expand-column'>
                  <IonIcon
                    className='msto-quote-line-item-expand-button'
                    data-testid='QAMSTOQuoteLineItemExpandButton'
                    src={expanded ? chevronUp : chevronDown}
                    onClick={() => setExpanded(!expanded)}
                  ></IonIcon>
                </IonCol>
                <IonCol size='0.76' class='msto-quote-line-items-col'>
                  <CheckboxItem
                    style={{
                      width: 'fit-content',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      ['--background']: 'transparent',
                    }}
                    onIonChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                      setIsLineChecked(checked);
                    }}
                    checked={isLineChecked}
                  />
                </IonCol>
                <IonCol size='0.4' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Piece} displayedValue={quoteLineItemData.piece} />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseWidth}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseWidth__c, Units.Millimeters, Units.Inches)}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.BaseLength}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseLength__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseLength__c, Units.Millimeters, Units.Inches)}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.BaseTK}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseBaseTK__c, Units.Millimeters, Units.Inches)}
                      displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseBaseTK__c, Units.Millimeters, Units.Inches)}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOBackerMSTOWeight'
                      valueType={QuoteLineItemValueTypes.PurchaseBackerWeight}
                      unitType={QuoteLineItemFieldUnitTypes.Weight}
                      imperialValue={quoteLineItemDetailData.PurchaseBackerWeight_LB__c}
                      metricValue={quoteLineItemDetailData.PurchaseBackerWeight_KG__c}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOBackerMSTOPrice/Weight'
                      valueType={QuoteLineItemValueTypes.BasePricePerWeight}
                      currency={quoteLineItemData.CurrencyIsoCode}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      displayedImperialValue={quoteLineItemData.Base_Price_LB__c}
                      displayedMetricValue={quoteLineItemData.Base_Price_KG__c}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOBackerMSTOPrice/PC'
                      valueType={QuoteLineItemValueTypes.PurchaseBackerPrice}
                      currency={quoteLineItemData.CurrencyIsoCode}
                      value={quoteLineItemDetailData.PurchaseBackerPrice__c}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      readOnly={true}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOBackerMSTOFreightCost/Weight'
                      valueType={QuoteLineItemValueTypes.PurchaseBackerFreightPerWeight}
                      currency={quoteLineItemData.CurrencyIsoCode}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      displayedImperialValue={quoteLineItemDetailData.PurchaseBackerFreight_LB__c}
                      displayedMetricValue={quoteLineItemDetailData.PurchaseBackerFreight_KG__c}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='1.5' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOBackerMSTOSupplier'
                      valueType={QuoteLineItemValueTypes.BackerSupplier}
                      displayedValue={
                        quoteLineItemDetailData.Pullfromstockbacker__c || NCSuppliers.includes(quoteLineItemDetailData.RawMaterialVendorBacker__c)
                          ? 'DMC Stock'
                          : vendors?.find(
                              (vendor) => vendor.vendorId === quoteLineItemDetailData.RawMaterialVendorBacker__c
                            )?.vendorName
                      }
                    />
                  </div>
                </IonCol>
                <IonCol size='0.9' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOBackerMSTOCustomerPO'
                      valueType={QuoteLineItemValueTypes.CustomerPO}
                      displayedValue={quoteLineItemData.CustomerPO__c}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.9' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOBackerMSTODate'
                      valueType={QuoteLineItemValueTypes.ShipDateBase}
                      value={quoteLineItemDetailData.ShipDateBase__c}
                      unitType={QuoteLineItemFieldUnitTypes.Date}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.4' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Alloc} displayedValue={1} />
                </IonCol>
                {hasPurchaseDelta && (
                  <IonCol size='0.26' class='column-left msto-quote-line-items-col indicator'>
                    <div className='column-container right-align delta-indicator'>
                      <PurchaseDisplayDeltaInfoIndicator icon={infoIcon} />
                    </div>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <BackerMSTOExpandedTable
          expanded={expanded}
          backerPanel={backerPanel}
          quoteLineItemIndex={quoteLineItemIndex}
          hasPurchaseDelta={hasPurchaseDelta}
          deltas={{ widthDelta, lengthDelta, tkDelta }}
        />
      </>
    );
  };

  const AnvilMSTORow = ({
    anvilPanel,
    quoteLineItemIndex = 1,
  }: {
    anvilPanel: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      lengthWidthTKDisplayFields?: Record<string, number>;
    };
    quoteLineItemIndex: number;
  }) => {
    const { quoteLineItemData, quoteLineItemDetailData, lengthWidthTKDisplayFields } = anvilPanel;
    const { widthDelta, lengthDelta, tkDelta } = getPurchaseDeltas(
      quoteLineItemDetailData,
      lengthWidthTKDisplayFields,
      MSTOAssemblyMetals.ANVIL,
      displayUom
    );
    // Note: leaving in negative deltas for now, but may need to remove them later in favor of only positive deltas, per Jen
    const hasPurchaseDelta = widthDelta !== 0 || lengthDelta !== 0 || tkDelta !== 0;

    const quoteLineItemId = quoteLineItemData.UUID__c || quoteLineItemData.Id || quoteLineItemData.displayId;
    const anvilMSTOSelected = selectedAnvilsToEdit.current.some(
      (anvilMSTO) =>
        (anvilMSTO.UUID__c || anvilMSTO.Id || anvilMSTO.displayId) === quoteLineItemId && anvilMSTO.piece === quoteLineItemData.piece
    );

    const anvilFreightCostPerLB = getQuoteVariableConfiguration(
      quoteVariableConfigurations,
      QuoteVariableConfigDescriptions.AnvilFreightCostByWeightLB,
      manufacturingSite,
      FieldTypes.Text
    ) as number;
    const anvilFreightCostPerKG = getQuoteVariableConfiguration(
      quoteVariableConfigurations,
      QuoteVariableConfigDescriptions.AnvilFreightCostByWeightKG,
      manufacturingSite,
      FieldTypes.Text
    ) as number;

    const [isLineChecked, setIsLineChecked] = React.useState<boolean>(anvilMSTOSelected);
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
      try {
        if (isLineChecked) {
          if (!anvilMSTOSelected) {
            const updatedSelectedAnvils = [...selectedAnvilsToEdit.current];
            updatedSelectedAnvils.push({ ...quoteLineItemData });
            selectedAnvilsToEdit.current = updatedSelectedAnvils;
          }
        } else {
          if (anvilMSTOSelected) {
            const updatedSelectedAnvils = [
              ...selectedAnvilsToEdit.current.filter(
                (anvilLine) =>
                  (anvilLine.UUID__c || anvilLine.Id || anvilLine.displayId) !== quoteLineItemId || anvilLine.piece !== quoteLineItemData.piece
              ),
            ];
            selectedAnvilsToEdit.current = updatedSelectedAnvils;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, [isLineChecked]);

    return (
      <>
        <IonCard className='msto-quote-line-item-card' data-testid={'QAMSTOQuoteLineItem_' + quoteLineItemIndex}>
          <IonCardHeader class='msto-quote-line-item-card-header'>
            <IonGrid>
              <IonRow class='msto-quote-line-item-row'>
                <IonCol size='0.23' class='column-left msto-quote-line-items-col expand-column'>
                  <IonIcon
                    className='msto-quote-line-item-expand-button'
                    data-testid='QAMSTOQuoteLineItemExpandButton'
                    src={expanded ? chevronUp : chevronDown}
                    onClick={() => setExpanded(!expanded)}
                  ></IonIcon>
                </IonCol>
                <IonCol size='0.76' class='msto-quote-line-items-col'>
                  <CheckboxItem
                    style={{
                      width: 'fit-content',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      ['--background']: 'transparent',
                    }}
                    onIonChange={(checked: boolean | ((prevState: boolean) => boolean)) => {
                      setIsLineChecked(checked);
                    }}
                    checked={isLineChecked}
                  />
                </IonCol>
                <IonCol size='0.4' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Piece} displayedValue={quoteLineItemData.piece} />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.AnvilWidth}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilWidth__c, Units.Millimeters, Units.Inches)}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <LineItemField
                    valueType={QuoteLineItemValueTypes.AnvilLength}
                    unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                    displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches)}
                    displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilLength__c, Units.Millimeters, Units.Inches)}
                    readOnly={true}

                  />
                </IonCol>
                <IonCol size='0.7' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      valueType={QuoteLineItemValueTypes.AnvilTK}
                      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                      displayedImperialValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Imperial, quoteLineItemDetailData.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches)}
                      displayedMetricValue={calculateUOMValue(quoteLineItemData.Unit_of_Measure__c, MeasurementSystems.Metric, quoteLineItemDetailData.PurchaseAnvilTK__c, Units.Millimeters, Units.Inches)}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOAnvilMSTOWeight'
                      valueType={QuoteLineItemValueTypes.PurchaseAnvilWeight}
                      unitType={QuoteLineItemFieldUnitTypes.Weight}
                      imperialValue={quoteLineItemDetailData.PurchaseAnvilWeight_LB__c}
                      metricValue={quoteLineItemDetailData.PurchaseAnvilWeight_KG__c}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOAnvilMSTOPrice/Weight'
                      valueType={QuoteLineItemValueTypes.AnvilPricePerWeight}
                      currency={quoteLineItemData.CurrencyIsoCode}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      displayedImperialValue={
                        quoteLineItemDetailData.PurchaseAnvilPrice__c / quoteLineItemDetailData.PurchaseAnvilWeight_LB__c
                      }
                      displayedMetricValue={
                        quoteLineItemDetailData.PurchaseAnvilPrice__c / quoteLineItemDetailData.PurchaseAnvilWeight_KG__c
                      }
                      imperialValue={quoteLineItemDetailData.PurchaseAnvilPrice__c / quoteLineItemDetailData.PurchaseAnvilWeight_LB__c}
                      metricValue={quoteLineItemDetailData.PurchaseAnvilPrice__c / quoteLineItemDetailData.PurchaseAnvilWeight_KG__c}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOAnvilMSTOPrice/PC'
                      valueType={QuoteLineItemValueTypes.PurchaseAnvilPrice}
                      currency={quoteLineItemData.CurrencyIsoCode}
                      value={quoteLineItemDetailData.PurchaseAnvilPrice__c}
                      unitType={QuoteLineItemFieldUnitTypes.Currency}
                      readOnly={true}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.8' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOAnvilMSTOFreightCost/Weight'
                      valueType={QuoteLineItemValueTypes.PurchaseAnvilFreightPerWeight}
                      currency={quoteLineItemData.CurrencyIsoCode}
                      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
                      displayedImperialValue={quoteLineItemDetailData.PurchaseAnvilFreight_LB__c || anvilFreightCostPerLB}
                      displayedMetricValue={quoteLineItemDetailData.PurchaseAnvilFreight_KG__c || anvilFreightCostPerKG}
                      imperialValue={quoteLineItemDetailData.PurchaseAnvilFreight_LB__c || anvilFreightCostPerLB}
                      metricValue={quoteLineItemDetailData.PurchaseAnvilFreight_KG__c || anvilFreightCostPerKG}
                      readOnly={true}

                    />
                  </div>
                </IonCol>
                <IonCol size='1.5' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOAnvilMSTOSupplier'
                      valueType={QuoteLineItemValueTypes.AnvilSupplier}
                      displayedValue={
                        quoteLineItemDetailData.PullfromstockBKAnvil__c
                          ? 'DMC Stock'
                          : vendors?.find(
                              (vendor) => vendor.vendorId === quoteLineItemDetailData.VendorAnvil__c
                            )?.vendorName
                      }
                    />
                  </div>
                </IonCol>
                <IonCol size='0.9' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOAnvilMSTOCustomerPO'
                      valueType={QuoteLineItemValueTypes.CustomerPO}
                      displayedValue={quoteLineItemData.CustomerPO__c}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.9' class='column-left msto-quote-line-items-col'>
                  <div className='column-container right-align'>
                    <LineItemField
                      datatestid='QAMSTOAnvilMSTODate'
                      valueType={QuoteLineItemValueTypes.ShipDateAnvil}
                      value={quoteLineItemDetailData.NC_Anvil_Item_Details__r.ShipDateAnvil__c}
                      unitType={QuoteLineItemFieldUnitTypes.Date}
                    />
                  </div>
                </IonCol>
                <IonCol size='0.4' class='column-left msto-quote-line-items-col'>
                  <LineItemField valueType={QuoteLineItemValueTypes.Alloc} displayedValue={1} />
                </IonCol>
                {hasPurchaseDelta && (
                  <IonCol size='0.26' class='column-left msto-quote-line-items-col indicator'>
                    <div className='column-container right-align delta-indicator'>
                      <PurchaseDisplayDeltaInfoIndicator icon={infoIcon} />
                    </div>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <AnvilMSTOExpandedTable
          expanded={expanded}
          anvilPanel={anvilPanel}
          quoteLineItemIndex={quoteLineItemIndex}
          hasPurchaseDelta={hasPurchaseDelta}
          deltas={{ widthDelta, lengthDelta, tkDelta }}
        />
      </>
    );
  };

  const updateSpecifiedMetalPanelsMap = (panelsMap, selectedTabToUpdate?: MSTOSelectionTabs) => {
    const tabToUpdate = selectedTabToUpdate ? selectedTabToUpdate : activeTab;
    switch(tabToUpdate) {
      case MSTOSelectionTabs.Cladder:
        setCladderPanelsMap(panelsMap);
        break;
      case MSTOSelectionTabs.Backer:
        setBackerPanelsMap(panelsMap);
        break;
      case MSTOSelectionTabs.Anvil:
        setAnvilPanelsMap(panelsMap);
        break;
    }
  };

  const updateAssemblies = (updatedAssemblies) => {
    setAssemblies(updatedAssemblies);
  };

  const updateAssembliesDisplayMap = (assembliesDisplayMap) => {
    switch(activeTab) {
      case MSTOSelectionTabs.Cladder:
        cladderAssemblyDisplayMap.current = assembliesDisplayMap;
        break;
      case MSTOSelectionTabs.Backer:
        backerAssemblyDisplayMap.current = assembliesDisplayMap;
        break;
      case MSTOSelectionTabs.Anvil:
        anvilAssemblyDisplayMap.current = assembliesDisplayMap;
        break;
    }
  };

  const updateMetalPanelsMap = (metalPanelsMap, panelToUpdate?: MSTOSelectionTabs) => {
    const panelType = panelToUpdate ? panelToUpdate : activeTab;

    switch(panelType) {
      case MSTOSelectionTabs.Cladder:
        setCladderPanelsMap(metalPanelsMap);
        break;
      case MSTOSelectionTabs.Backer:
        setBackerPanelsMap(metalPanelsMap);
        break;
      case MSTOSelectionTabs.Anvil:
        setAnvilPanelsMap(metalPanelsMap);
        break;
    }
  };

  const updateSelectedMetalsToUseAsIs = (selectedMetals) => {
    switch(activeTab) {
      case MSTOSelectionTabs.Cladder:
        selectedCladdersToUseAsIs.current = selectedMetals;
        break;
      case MSTOSelectionTabs.Backer:
        selectedBackersToUseAsIs.current = selectedMetals;
        break;
      case MSTOSelectionTabs.Anvil:
        selectedAnvilsToUseAsIs.current = selectedMetals;
        break;
    }
  };

  const updateSelectedMetalsToAssemble = (updatedSelectedMetalsToAssemble) => {
    selectedMetalsToAssemble.current = updatedSelectedMetalsToAssemble;
  };

  const updateQuoteLineItemDetailFieldsToUpdate = (quoteLineItemDetailFieldsToUpdate) => {
    setQuoteLineItemDetailFieldsToUpdate(quoteLineItemDetailFieldsToUpdate);
  };

  const updateAnvilItemDetailFieldsToUpdate = (anvilItemDetailFieldsToUpdate) => {
    setAnvilItemDetailFieldsToUpdate(anvilItemDetailFieldsToUpdate);
  };

  const actionHandlerDelete = () => {
    let panels: Map<string, {quoteLineItemData: QuoteLineItem, quoteLineItemDetailData: QuoteLineItemDetail, lengthWidthTKDisplayFields?: Record<string, number>}>;
    let assemblyDisplayMap: Map<string, MSTOAssemblyDisplayMapTypes>;
    let selectedList: {id: string, piece: number, quoteLineItemDetail: QuoteLineItemDetail}[] = [];
    let updatedAssemblies: Map<string, { quoteLineItemData: QuoteLineItem, quoteLineItemDetailData: QuoteLineItemDetail, metal: MSTOAssemblyMetals}[]> = new Map(assemblies);
    let mstoName: string;
    let metalType: MetalTypesForAreaCalc;

    switch(activeTab) {
      case MSTOSelectionTabs.Cladder:
        panels = cladderPanelsMap;
        assemblyDisplayMap = cladderAssemblyDisplayMap.current;
        selectedList = selectedCladdersToEdit.current.map((cladder) => {
          return {
            id: cladder.UUID__c || cladder.Id || cladder.displayId,
            piece: cladder.piece,
            quoteLineItemDetail: cladder.quoteLineItemDetail
          };
        });
        mstoName = 'Clad';
        metalType = MetalTypesForAreaCalc.Clad;
        break;
      case MSTOSelectionTabs.Backer:
        panels = backerPanelsMap;
        assemblyDisplayMap = backerAssemblyDisplayMap.current;
        selectedList = selectedBackersToEdit.current.map((backer) => {
          return {
            id: backer.UUID__c || backer.Id || backer.displayId,
            piece: backer.piece,
            quoteLineItemDetail: backer.quoteLineItemDetail
          };
        });
        mstoName = 'Base';
        metalType = MetalTypesForAreaCalc.Base;
        break;
      case MSTOSelectionTabs.Anvil:
        panels = anvilPanelsMap;
        assemblyDisplayMap = anvilAssemblyDisplayMap.current;
        selectedList = selectedAnvilsToEdit.current.map((anvil) => {
          return {
            id: anvil.UUID__c || anvil.Id || anvil.displayId,
            piece: anvil.piece,
            quoteLineItemDetail: anvil.quoteLineItemDetail
          };
        });
        mstoName = 'Anvil';
        metalType = MetalTypesForAreaCalc.Anvil;
        break;
      case MSTOSelectionTabs.Assemblies:
        selectedAssemblies.current.forEach(assemblyKey => {
          updatedAssemblies.delete(assemblyKey);
        });
        setAssemblies(updatedAssemblies);
        setShouldAskAgain(true);
        return;
    }

    const updatedPanelsMap = new Map(
      // eslint-disable-next-line no-unused-vars
      [...panels].filter(([key, value]) => {
        let result = true;
        selectedList.forEach(selected => {
          if(
              (
                value.quoteLineItemData.Id
                && (selected.id === value.quoteLineItemData.UUID__c || selected.id === value.quoteLineItemData.Id || selected.id === value.quoteLineItemData.displayId)
              )
              && selected.piece === value.quoteLineItemData.piece
            ) {
            result = false;
          }
        });

        return result;
      })
    );
    const updatedAssemblyDisplayMap = assemblyDisplayMap;
    const selectedIds = selectedList.map(selected => selected.id);
    let totalArea = 0;
    let updatedArea = 0;

    selectedList.forEach(selected => {
      const selectedQuoteLineItemDetail = selected.quoteLineItemDetail;
      const totalLength = selectedQuoteLineItemDetail[`Purchase${mstoName}Length__c`];
      const totalWidth = selectedQuoteLineItemDetail[`Purchase${mstoName}Width__c`];

      let metalArea;
      if(metalType === MetalTypesForAreaCalc.Base) {
        const baseMetalName = quoteLineItems.find(qli => (qli.UUID__c || qli.Id || qli.displayId) === selected.id).BaseMetal__c;
        const baseMetal = rawMaterials.find(rawMaterial => rawMaterial.name === baseMetalName && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite));
        metalArea = calculateRawArea(displayUom, totalLength, totalWidth, metalType, baseMetal.dmcClass.includes('/F'));
      } else {
        metalArea = calculateRawArea(displayUom, totalLength, totalWidth, metalType);
      }

      totalArea = metalArea * selectedQuoteLineItemDetail.Clads__c;
    });

    updatedPanelsMap.forEach(panel => {
      if(selectedIds.includes(panel.quoteLineItemData.UUID__c || panel.quoteLineItemData.Id || panel.quoteLineItemData.displayId)) {
        const length = panel.lengthWidthTKDisplayFields.DisplayLength;
        const width = panel.lengthWidthTKDisplayFields.DisplayWidth;

        if(metalType === MetalTypesForAreaCalc.Base) {
          const baseMetalName = panel.quoteLineItemData.BaseMetal__c;
          const baseMetal = rawMaterials.find(rawMaterial => rawMaterial.name === baseMetalName && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite));
          updatedArea += calculateRawArea(displayUom, length, width, metalType, baseMetal.dmcClass.includes('/F'));
        } else {
          updatedArea += calculateRawArea(displayUom, length, width, metalType);
        }
      }
    });
    const percent = 100 * (updatedArea / totalArea);

    selectedList.forEach(selected => {
      const selectedDisplayMap = updatedAssemblyDisplayMap.get(selected.id);
      updatedAssemblyDisplayMap.set(selected.id, {...selectedDisplayMap, percent});
    });

    updatedAssemblies = new Map(
      // eslint-disable-next-line no-unused-vars
      [...updatedAssemblies].filter(([key, value]) => {
        let result = true;
        value.forEach(assembly => {
          const id = assembly.quoteLineItemData.UUID__c || assembly.quoteLineItemData.Id || assembly.quoteLineItemData.displayId;
          if(selectedList.some(selected => selected.id === id) && selectedList.some(selected => selected.piece === assembly.quoteLineItemData.piece)) {
            result = false;
          }
        });

        return result;
      })
    );

    updateSpecifiedMetalPanelsMap(updatedPanelsMap);
    updateAssembliesDisplayMap(updatedAssemblyDisplayMap);
    setAssemblies(updatedAssemblies);

    selectedAnvilsToEdit.current = [];
    selectedBackersToEdit.current = [];
    selectedCladdersToEdit.current = [];
  };

  const actionHandlerDuplicate = () => {
    let panels: Map<string, {quoteLineItemData: QuoteLineItem, quoteLineItemDetailData: QuoteLineItemDetail, lengthWidthTKDisplayFields?: Record<string, number>}>;
    let assemblyDisplayMap: Map<string, MSTOAssemblyDisplayMapTypes>;
    let selectedList: {id: string, line: string, piece: number, quoteLineItemDetail: QuoteLineItemDetail}[] = [];
    let mstoName: string;
    let metalType: MetalTypesForAreaCalc;

    switch(activeTab) {
      case MSTOSelectionTabs.Cladder:
        panels = cladderPanelsMap;
        assemblyDisplayMap = cladderAssemblyDisplayMap.current;
        selectedList = selectedCladdersToEdit.current.map((cladder) => {
          return {
            id: cladder.UUID__c || cladder.Id || cladder.displayId,
            line: cladder.Line__c,
            piece: cladder.piece,
            quoteLineItemDetail: cladder.quoteLineItemDetail
          };
        });
        mstoName = 'Clad';
        metalType = MetalTypesForAreaCalc.Clad;
        break;
      case MSTOSelectionTabs.Backer:
        panels = backerPanelsMap;
        assemblyDisplayMap = backerAssemblyDisplayMap.current;
        selectedList = selectedBackersToEdit.current.map((backer) => {
          return {
            id: backer.UUID__c || backer.Id || backer.displayId,
            line: backer.Line__c,
            piece: backer.piece,
            quoteLineItemDetail: backer.quoteLineItemDetail
          };
        });
        mstoName = 'Base';
        metalType = MetalTypesForAreaCalc.Base;
        break;
      case MSTOSelectionTabs.Anvil:
        panels = anvilPanelsMap;
        assemblyDisplayMap = anvilAssemblyDisplayMap.current;
        selectedList = selectedAnvilsToEdit.current.map((anvil) => {
          return {
            id: anvil.UUID__c || anvil.Id || anvil.displayId,
            line: anvil.Line__c,
            piece: anvil.piece,
            quoteLineItemDetail: anvil.quoteLineItemDetail
          };
        });
        mstoName = 'Anvil';
        metalType = MetalTypesForAreaCalc.Anvil;
        break;
    }

    const updatedPanelsMap = new Map(panels);

    let piece = Array.from(panels.keys()).length + 1;
    selectedList.forEach(selected => {
      const newMetalPanelKey = `${selected.line} - ${piece}`;
      const panelToDuplicate = panels.get(`${selected.line} - ${selected.piece}`);

      const newPanel = {
        ...panelToDuplicate,
        quoteLineItemData: {
          ...panelToDuplicate.quoteLineItemData,
          piece
        }
      };

      updatedPanelsMap.set(newMetalPanelKey, newPanel);
      piece++;
    });

    const updatedAssemblyDisplayMap = assemblyDisplayMap;
    const selectedIds = selectedList.map(selected => selected.id);
    let totalArea = 0;
    let updatedArea = 0;

    selectedList.forEach(selected => {
      const selectedQuoteLineItemDetail = selected.quoteLineItemDetail;
      const totalLength = selectedQuoteLineItemDetail[`Purchase${mstoName}Length__c`];
      const totalWidth = selectedQuoteLineItemDetail[`Purchase${mstoName}Width__c`];

      let metalArea;
      if(metalType === MetalTypesForAreaCalc.Base) {
        const baseMetalName = quoteLineItems.find(qli => (qli.UUID__c || qli.Id || qli.displayId) === selected.id).BaseMetal__c;
        const baseMetal = rawMaterials.find(rawMaterial => rawMaterial.name === baseMetalName && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite));
        metalArea = calculateRawArea(displayUom, totalLength, totalWidth, metalType, baseMetal.dmcClass.includes('/F'));
      } else {
        metalArea = calculateRawArea(displayUom, totalLength, totalWidth, metalType);
      }

      totalArea = metalArea * selectedQuoteLineItemDetail.Clads__c;
    });

    updatedPanelsMap.forEach(panel => {
      if(selectedIds.includes(panel.quoteLineItemData.UUID__c || panel.quoteLineItemData.Id || panel.quoteLineItemData.displayId)) {
        const length = panel.lengthWidthTKDisplayFields.DisplayLength;
        const width = panel.lengthWidthTKDisplayFields.DisplayWidth;

        if(metalType === MetalTypesForAreaCalc.Base) {
          const baseMetalName = panel.quoteLineItemData.BaseMetal__c;
          const baseMetal = rawMaterials.find(rawMaterial => rawMaterial.name === baseMetalName && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite));
          updatedArea += calculateRawArea(displayUom, length, width, metalType, baseMetal.dmcClass.includes('/F'));
        } else {
          updatedArea += calculateRawArea(displayUom, length, width, metalType);
        }
      }
    });
    const percent = 100 * (updatedArea / totalArea);

    selectedList.forEach(selected => {
      const selectedDisplayMap = updatedAssemblyDisplayMap.get(selected.id);
      updatedAssemblyDisplayMap.set(selected.id, {...selectedDisplayMap, percent});
    });

    updateSpecifiedMetalPanelsMap(updatedPanelsMap);
    updateAssembliesDisplayMap(updatedAssemblyDisplayMap);
    selectedAnvilsToEdit.current = [];
    selectedBackersToEdit.current = [];
    selectedCladdersToEdit.current = [];
  };

  const validateAssembly = () => {
    let assemblyValid = true;
    if (!selectedMetalsToAssemble.current.length) {
      toast(ToastTypes.Error, ToastMessages.MSTOCreateAssembly);
      assemblyValid = false;
    } else if (!selectedMetalsToAssemble.current.some(assemblyPanel => assemblyPanel.metal === MSTOAssemblyMetals.CLAD) || !selectedMetalsToAssemble.current.some(assemblyPanel => assemblyPanel.metal === MSTOAssemblyMetals.BASE)) {
      toast(ToastTypes.Error, ToastMessages.MSTOBaseCladAssembly);
      assemblyValid = false;
    } else if (selectedMetalsToAssemble.current[0].quoteLineItemDetailData.Anvil_Needed__c && !selectedMetalsToAssemble.current.some(assemblyPanel => assemblyPanel.metal === MSTOAssemblyMetals.ANVIL)) {
      toast(ToastTypes.Error, ToastMessages.MSTOAnvilAssembly);
      assemblyValid = false;
    } else if (selectedMetalsToAssemble.current.filter(assemblyPanel => assemblyPanel.metal === MSTOAssemblyMetals.BASE).length > 1 || selectedMetalsToAssemble.current.filter(assemblyPanel => assemblyPanel.metal === MSTOAssemblyMetals.ANVIL).length > 1) {
      toast(ToastTypes.Error, ToastMessages.MSTOMultipleBaseAnvilsInAssembly);
      assemblyValid = false;
    } else {
      const cladThicknessDeviationAllowanceDesc = isDisplayImperial ? QuoteVariableConfigDescriptions.OERMSTOCladThicknessDeviationAllowanceIN : QuoteVariableConfigDescriptions.OERMSTOCladThicknessDeviationAllowanceMM;
      const cladThicknessDeviationAllowance = getQuoteVariableConfiguration(quoteVariableConfigurations, cladThicknessDeviationAllowanceDesc, manufacturingSite, FieldTypes.Float) as number;

      const cladThicknesses: number[] = selectedMetalsToAssemble.current.filter(assemblyPanel => assemblyPanel.metal === MSTOAssemblyMetals.CLAD).map(cladPanel => cladPanel.quoteLineItemDetailData.PurchaseCladTK__c);
      const minCladThickness = Math.min(...cladThicknesses);
      const maxCladThickness = Math.max(...cladThicknesses);

      if(maxCladThickness - minCladThickness > cladThicknessDeviationAllowance) {
        toast(ToastTypes.Error, ToastMessages.MSTOCladThicknessDeviation.replace('<<VALUE>>', cladThicknessDeviationAllowance.toString()).replace('<<UOM>>', isDisplayImperial ? 'IN' : 'MM'));
        assemblyValid = false;
      }
    }

    return assemblyValid;
  };

  const actionHandlerCreateAssembly = (selectedMetalsToAssembleBySeamWeld?: {
    quoteLineItemData: QuoteLineItem;
    quoteLineItemDetailData: QuoteLineItemDetail;
    metal: MSTOAssemblyMetals;
    panelNumber?: number;
    panelLocation?: PanelLocations;
    seamWeldPieceNumber?: number;
  }[]) => {
    const selectedAssemblyGroup = +selectedMetalsToAssemble.current[0].quoteLineItemData.Line__c;
    const numExistingAssembliesForAssemblyGroup = Array.from(assemblies.keys()).filter(assemblyKey => JSON.parse(assemblyKey).assemblyGroup === selectedAssemblyGroup).length;
    const updatedAssemblies = new Map(assemblies);

    const assemblyKeyObject = { assemblyGroup: selectedAssemblyGroup, pieceNumber:  numExistingAssembliesForAssemblyGroup + 1};
    const metalsToAssemble = selectedMetalsToAssembleBySeamWeld || selectedMetalsToAssemble.current;

    metalsToAssemble.forEach(metalToAssemble => {
      filterMSTOAssemblyFields(metalToAssemble);
    });

    updatedAssemblies.set(JSON.stringify(assemblyKeyObject), metalsToAssemble);

    selectedMetalsToAssemble.current = [];

    setAssemblies(updatedAssemblies);
  };

  const buildAutocombinedGroups = (updatedMetalPanelsMap?: Map<string, SeamPanels | Panels>) => {
    const autocombinedGroups = [];

    Array.from(cladderPanelsMap.keys()).forEach((key) => {
      // const currentPanelAdded = updatedMetalPanelsMap.get(key);
      const currentPanelAdded = (updatedMetalPanelsMap || cladderPanelsMap).get(key);
      const anvilNeeded = currentPanelAdded.quoteLineItemDetailData.Anvil_Needed__c;
      // const currentCladderPanelKeysForAssembly = getPanelKeysByLine(cladderPanelsMap, currentPanelAdded.quoteLineItemData.Line__c);
      const currentBackerPanelKeysForAssembly = getPanelKeysByLine(backerPanelsMap, currentPanelAdded.quoteLineItemData.Line__c);
      const currentAnvilPanelKeysForAssembly = getPanelKeysByLine(anvilPanelsMap, currentPanelAdded.quoteLineItemData.Line__c);

      // just need to check if seamWeldRequired is required or not
      if(currentBackerPanelKeysForAssembly.length && (anvilNeeded ? currentAnvilPanelKeysForAssembly.length : true)) {
        const updatedCladderPanelKeysForAssembly = getPanelKeysByLine((updatedMetalPanelsMap || cladderPanelsMap), currentPanelAdded.quoteLineItemData.Line__c);
        const panelIndexToUse = updatedCladderPanelKeysForAssembly.indexOf(key);
        const backerPanelToAutocombine = backerPanelsMap.get(currentBackerPanelKeysForAssembly[panelIndexToUse]);
        const anvilPanelToAutocombine = anvilNeeded ? anvilPanelsMap.get(currentAnvilPanelKeysForAssembly[panelIndexToUse]) : undefined;

        if(backerPanelToAutocombine && (anvilNeeded ? anvilPanelToAutocombine : true) && !currentPanelAdded.quoteLineItemDetailData.SeamWeld__c) {
          const metalsToAutocombine = [];
          metalsToAutocombine.push({ ...currentPanelAdded, metal: MSTOAssemblyMetals.CLAD });
          metalsToAutocombine.push({ ...backerPanelToAutocombine, metal: MSTOAssemblyMetals.BASE });

          if(anvilNeeded) {
            metalsToAutocombine.push({ ...anvilPanelToAutocombine, metal: MSTOAssemblyMetals.ANVIL });
          }

          autocombinedGroups.push(metalsToAutocombine);
        }
      }
    });

    return autocombinedGroups;
  };

  const autocombineMetals = (
    autocombinedGroups?: {
      quoteLineItemData: QuoteLineItem;
      quoteLineItemDetailData: QuoteLineItemDetail;
      metal: MSTOAssemblyMetals;
      panelNumber?: number;
      panelLocation?: PanelLocations;
      seamWeldPieceNumber?: number;
    }[][], assembliesToUse?: any) => {
    const toUseAssemblies = assembliesToUse || assemblies;
    const updatedAssemblies: any = new Map(toUseAssemblies);

    autocombinedGroups.forEach(metalsToAutocombine => {
      const selectedAssemblyGroup = +metalsToAutocombine[0].quoteLineItemData.Line__c;

      const mstoAssemblyGroupKeysForLine = Array.from(toUseAssemblies.keys()).filter((assemblyKey: any) => JSON.parse(assemblyKey).assemblyGroup === selectedAssemblyGroup);
      const numExistingAssembliesForAssemblyGroup = mstoAssemblyGroupKeysForLine.length;

      const existingAssemblyForAutocombinedGroup = Array.from(toUseAssemblies.entries())
        // eslint-disable-next-line no-unused-vars
        .find(([_, toUseAssemblies]) => toUseAssemblies.some(assembly => assembly.quoteLineItemData.Line__c === metalsToAutocombine[0].quoteLineItemData.Line__c && assembly.quoteLineItemData.piece === metalsToAutocombine[0].quoteLineItemData.piece));

      const assemblyKeyObject = { assemblyGroup: selectedAssemblyGroup, pieceNumber: numExistingAssembliesForAssemblyGroup + 1};
      const metalsToAssemble = metalsToAutocombine;

      metalsToAssemble.forEach(metalToAssemble => {
        filterMSTOAssemblyFields(metalToAssemble);
      });

      updatedAssemblies.set(existingAssemblyForAutocombinedGroup ? existingAssemblyForAutocombinedGroup[0] : JSON.stringify(assemblyKeyObject), metalsToAssemble);
    });

    return updatedAssemblies;
  };

  const getPanelKeysByLine = (panelsMap: Map<string, SeamPanels | Panels>, line: string) => {
    return Array.from(panelsMap.keys()).filter(key => key.split(' - ')[0] === line);
  };

  const MSTOModalDropdown = (props) => {
    let panelsMap;
    switch (activeTab) {
      case MSTOSelectionTabs.Cladder:
        panelsMap = cladderPanelsMap;
        break;
      case MSTOSelectionTabs.Backer:
        panelsMap = backerPanelsMap;
        break;
      case MSTOSelectionTabs.Anvil:
        panelsMap = anvilPanelsMap;
        break;
    }

    const mstoPanel = panelsMap?.get(
      `${(addPiecePopoverState.itemsToEdit || [])[0]?.Line__c} - ${(addPiecePopoverState.itemsToEdit || [])[0]?.piece}`
    );

    return (
      <div className={activeTab === MSTOSelectionTabs.Assemblies ? '' : 'sticky-msto-action-button'}>
        <IonPopover
          cssClass='add-piece-popover'
          isOpen={addPiecePopoverState.showPopover}
          animated={false}
          onDidDismiss={async () => {
            // depending on the activeTab, we need to update the state for the correct tab
            switch (activeTab) {
              case MSTOSelectionTabs.Backer:
                selectedBackersToEdit.current = [];
                selectedBackersToUseAsIs.current = [];
                break;
              case MSTOSelectionTabs.Anvil:
                selectedAnvilsToEdit.current = [];
                selectedAnvilsToUseAsIs.current = [];
                break;
              case MSTOSelectionTabs.Cladder:
              default:
                selectedCladdersToEdit.current = [];
                selectedCladdersToUseAsIs.current = [];
                break;
            }

            setShowAddPiecePopover({ showPopover: false, event: undefined, itemsToEdit: undefined });
          }}
        >
          <AddPiecePopover
            setShowPopover={setShowAddPiecePopover}
            quoteLineItemsToEdit={addPiecePopoverState.itemsToEdit}
            quoteLineItemDetailData={
              mstoPanel?.quoteLineItemDetailData || (addPiecePopoverState.itemsToEdit || [])[0]?.quoteLineItemDetail
            }
            weldingDimensions={weldingDimensionsMap.current}
            seamWeldPieceNumber={mstoPanel?.seamWeldPieceNumber}
            panelNumber={mstoPanel?.panelNumber}
            panelLocation={mstoPanel?.panelLocation}
            editing={!!mstoPanel}
            displayPanelFields={mstoPanel?.lengthWidthTKDisplayFields}
            mstoData={
              {
                activeTab,
                anvilAssemblyDisplayMap: anvilAssemblyDisplayMap.current,
                backerAssemblyDisplayMap: backerAssemblyDisplayMap.current,
                cladderAssemblyDisplayMap: cladderAssemblyDisplayMap.current,
                anvilItemDetailFieldsToUpdate,
                quoteLineItemDetailFieldsToUpdate,
                anvilPanelsMap,
                backerPanelsMap,
                cladderPanelsMap,
                assemblies,
              }
            }
            updateSpecifiedMetalPanelsMap={updateSpecifiedMetalPanelsMap}
            updateAssembliesDisplayMap={updateAssembliesDisplayMap}
            updateQuoteLineItemDetailFieldsToUpdate={updateQuoteLineItemDetailFieldsToUpdate}
            updateAnvilItemDetailFieldsToUpdate={updateAnvilItemDetailFieldsToUpdate}
            buildAutocombinedGroups={buildAutocombinedGroups}
            autocombineMetals={autocombineMetals}
            updateAssemblies={updateAssemblies}

          />
        </IonPopover>
        <div className='msto-action-button-wrapper'>
          <MultiActionButton
            containerStyle={activeTab === MSTOSelectionTabs.Assemblies ? 'msto-assemblies-action-button-container' : 'msto-action-button-container'}
            style={'msto-display'}
            defaultOption={props.default}
            dropdownOptions={props.actions}
            buttonPosition='end'
            actionHandler={(action) => handleQuoteAction(action)}
          />
        </div>
      </div>
    );
  };

  const DisplayedMSTOTable = () => {
    const tableHeadersCladderList = [
      { display: '', colSize: '0.23' }, // blank column
      { display: '', colSize: '0.76' }, // blank column
      { display: MSTOColumnHeadings.Piece, colSize: '0.4' },
      { display: MSTOColumnHeadings.Width, colSize: '0.7' },
      { display: MSTOColumnHeadings.Length, colSize: '0.7' },
      { display: MSTOColumnHeadings.CladTK, colSize: '0.7' },
      { display: isDisplayImperial ? MSTOColumnHeadings.WeightLB : MSTOColumnHeadings.WeightKG, colSize: '0.8' },
      { display: isDisplayImperial ? MSTOColumnHeadings.CostPerLB : MSTOColumnHeadings.CostPerKG, colSize: '0.8' },
      { display: MSTOColumnHeadings.CostPerPC, colSize: '0.8' },
      { display: isDisplayImperial ? MSTOColumnHeadings.FrtCostPerLB : MSTOColumnHeadings.FrtCostPerKG, colSize: '0.8' },
      { display: MSTOColumnHeadings.Supplier, colSize: '1.5' },
      { display: MSTOColumnHeadings.CustomerPO, colSize: '0.9' },
      { display: MSTOColumnHeadings.Date, colSize: '0.9' },
      { display: MSTOColumnHeadings.Alloc, colSize: '0.4' },
    ];

    const tableHeadersBackerList = [
      { display: '', colSize: '0.23' }, // blank column
      { display: '', colSize: '0.76' }, // blank column
      { display: MSTOColumnHeadings.Piece, colSize: '0.4' },
      { display: MSTOColumnHeadings.Width, colSize: '0.7' },
      { display: MSTOColumnHeadings.Length, colSize: '0.7' },
      { display: MSTOColumnHeadings.BaseTK, colSize: '0.7' },
      { display: isDisplayImperial ? MSTOColumnHeadings.WeightLB : MSTOColumnHeadings.WeightKG, colSize: '0.8' },
      { display: isDisplayImperial ? MSTOColumnHeadings.CostPerLB : MSTOColumnHeadings.CostPerKG, colSize: '0.8' },
      { display: MSTOColumnHeadings.CostPerPC, colSize: '0.8' },
      { display: isDisplayImperial ? MSTOColumnHeadings.FrtCostPerLB : MSTOColumnHeadings.FrtCostPerKG, colSize: '0.8' },
      { display: MSTOColumnHeadings.Supplier, colSize: '1.5' },
      { display: MSTOColumnHeadings.CustomerPO, colSize: '0.9' },
      { display: MSTOColumnHeadings.Date, colSize: '0.9' },
      { display: MSTOColumnHeadings.Alloc, colSize: '0.4' },
    ];

    const tableHeadersAnvilList = [
      { display: '', colSize: '0.23' }, // blank column
      { display: '', colSize: '0.76' }, // blank column
      { display: MSTOColumnHeadings.Piece, colSize: '0.4' },
      { display: MSTOColumnHeadings.Width, colSize: '0.7' },
      { display: MSTOColumnHeadings.Length, colSize: '0.7' },
      { display: MSTOColumnHeadings.AnvilTK, colSize: '0.7' },
      { display: isDisplayImperial ? MSTOColumnHeadings.WeightLB : MSTOColumnHeadings.WeightKG, colSize: '0.8' },
      { display: isDisplayImperial ? MSTOColumnHeadings.CostPerLB : MSTOColumnHeadings.CostPerKG, colSize: '0.8' },
      { display: MSTOColumnHeadings.CostPerPC, colSize: '0.8' },
      { display: isDisplayImperial ? MSTOColumnHeadings.FrtCostPerLB : MSTOColumnHeadings.FrtCostPerKG, colSize: '0.8' },
      { display: MSTOColumnHeadings.Supplier, colSize: '1.5' },
      { display: MSTOColumnHeadings.CustomerPO, colSize: '0.9' },
      { display: MSTOColumnHeadings.Date, colSize: '0.9' },
      { display: MSTOColumnHeadings.Alloc, colSize: '0.4' },
    ];

    switch (activeTab) {
      case MSTOSelectionTabs.Backer:
        return (
          <>
            {Array.from(backerPanelsMap.values()).length ? (
              <>
                <MSTOModalDropdown
                  default={MSTOActions.Edit}
                  actions={[MSTOActions.Delete, MSTOActions.Duplicate]}
                  itemToEdit={selectedBackersToEdit.current[0]}
                />
                <TableHeaders labels={tableHeadersBackerList} />
              </>
            ) : null}
            {/* for each metal size to order, show the row */}
            <>
              {Array.from(backerPanelsMap.values()).orderBy(panel => panel.quoteLineItemData.piece).map((backerPanel, idx) => {
                return <BackerMSTORow key={idx} backerPanel={backerPanel} quoteLineItemIndex={idx} />;
              })}
            </>
          </>
        );
      case MSTOSelectionTabs.Anvil:
        return (
          <>
            {Array.from(anvilPanelsMap.values()).length ? (
              <>
                <MSTOModalDropdown
                  default={MSTOActions.Edit}
                  actions={[MSTOActions.Delete, MSTOActions.Duplicate]}
                  itemToEdit={selectedAnvilsToEdit.current[0]}
                />
                <TableHeaders labels={tableHeadersAnvilList} />
              </>
            ) : null}
            {/* for each assembly group, show the row */}
            <>
              {Array.from(anvilPanelsMap.values()).orderBy(panel => panel.quoteLineItemData.piece).map((anvilPanel, idx) => {
                return <AnvilMSTORow key={idx} anvilPanel={anvilPanel} quoteLineItemIndex={idx} />;
              })}
            </>
          </>
        );
      case MSTOSelectionTabs.Cladder:
      default:
        return (
          <>
            {Array.from(cladderPanelsMap.values()).length ? (
              <>
                <MSTOModalDropdown
                  default={MSTOActions.Edit}
                  actions={[MSTOActions.Delete, MSTOActions.Duplicate]}
                  itemToEdit={selectedCladdersToEdit.current[0]}
                />
                <TableHeaders labels={tableHeadersCladderList} />
              </>
            ) : null}
            {/* for each metal size to order, show the row */}
            <>
              {Array.from(cladderPanelsMap.values()).orderBy(panel => panel.quoteLineItemData.piece).map((cladderPanel, idx) => {
                return <CladderMSTORow key={idx} cladderPanel={cladderPanel} quoteLineItemIndex={idx} />;
              })}
            </>
          </>
        );
    }
  };

  const SizesToOrderSection = () => {
    return (
      <>
        <div className='msto-sizes-header-container'>
          <div className='msto-sub-header-label'>{`${activeTab} Metal Sizes To Order`}</div>
        </div>
        <hr className='msto-header-rule' />
        <div className='msto-sizes-container'>
          <DisplayedMSTOTable />
        </div>
      </>
    );
  };

  const handleTabSelected = (tab: MSTOSelectionTabs) => {
    setActiveTab(tab);
  };

  const routeChangeAlert = (url: string) => {
    confirmAlert({
      cssClass: 'confirm-alert',
      header: 'Are you sure you want to leave the page? Changes you made may not be saved.',
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: () => history.push(url),
        },
      ],
    });
  };

  const formatDecimal = (value: number): number => {
    return Math.trunc(value * 1000) / 1000;
  };

  const createMSTOItemDescription = (thickness: number, width: number, length: number, isForging: boolean, isMetric?: boolean) => {

    const formattedThickness = (isMetric ? formatDecimal(thickness): formatDecimal(thickness).toFixed(3));
    const formattedWidth = (isMetric ? formatDecimal(width): formatDecimal(width).toFixed(3));
    const formattedLength = (isMetric ? formatDecimal(length): formatDecimal(length).toFixed(3));

    if(isForging) {
      return formattedThickness + ' X ' + formattedWidth + ' OD';
    }

    return formattedThickness + ' X ' + formattedWidth + ' X ' + formattedLength;
  };

  const mapCalculateUOMValue = (type, value, unit) => {
    switch(type) {
      case MeasurementSystems.Imperial:
        return calculateUOMValue(
          unit as MeasurementSystems,
          MeasurementSystems.Imperial,
          value,
          Units.Millimeters,
          Units.Inches
        );
      case MeasurementSystems.Metric:
      default:
        return calculateUOMValue(
          unit as MeasurementSystems,
          MeasurementSystems.Metric,
          value,
          Units.Millimeters,
          Units.Inches
        );
    }
  };

  const calculateMetricUOMValue = (value, unit) => {
    return mapCalculateUOMValue(MeasurementSystems.Metric, value, unit);
  };

  const calculateImperialUOMValue = (value, unit) => {
    return mapCalculateUOMValue(MeasurementSystems.Imperial, value, unit);
  };

  const saveMsto = (saveQuoteToSf = false) => {
    try {
      quoteLineItems.forEach(quoteLineItem => {
        const quoteLineItemDetail = quoteLineItem.quoteLineItemDetail;
        const unit = quoteLineItemDetail?.Unit_of_Measure__c;

        const fieldsUpdated = [];
        const lineItemFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType> = new Map<QuoteLineItemFields, FieldsMapValueType>();
        const lineItemDetailFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType> = new Map<QuoteLineItemFields, FieldsMapValueType>();

        let isComplete: boolean;

        const mstoAssemblyGroupKeysForLine = Array.from(assemblies.keys()).filter(assemblyKey => JSON.parse(assemblyKey).assemblyGroup === +quoteLineItem.Line__c);

        const supplyCombinations = new Map<string, {
          quoteLineItemData: QuoteLineItem;
          quoteLineItemDetailData: QuoteLineItemDetail;
          metal: MSTOAssemblyMetals;
          panelNumber?: number;
          panelLocation?: PanelLocations;
          seamWeldPieceNumber?: number;
        }[]>();
        mstoAssemblyGroupKeysForLine.forEach(assemblyGroupKey => {
          const mstoAssemblyPanelsForAssembly = assemblies.get(assemblyGroupKey);

          const supplierTypeCombination = { clad: undefined, base: undefined, anvil: undefined };

          mstoAssemblyPanelsForAssembly?.forEach(assemblyPanel => {
            switch(assemblyPanel.metal) {
              case MSTOAssemblyMetals.CLAD:
                if(assemblyPanel.quoteLineItemDetailData.Pullfromstockcladder__c) {
                  supplierTypeCombination.clad = MSTOSupplierTypes.Stock;
                } else if(assemblyPanel.quoteLineItemDetailData.PurchaseCL__c) {
                  supplierTypeCombination.clad = MSTOSupplierTypes.Purchase;
                } else if(assemblyPanel.quoteLineItemData.Customer_Provided_Clad__c) {
                  supplierTypeCombination.clad = MSTOSupplierTypes.CustomerProvided;
                }
                break;
              case MSTOAssemblyMetals.BASE:
                if(assemblyPanel.quoteLineItemDetailData.Pullfromstockbacker__c) {
                  supplierTypeCombination.base = MSTOSupplierTypes.Stock;
                } else if(assemblyPanel.quoteLineItemDetailData.PurchaseBK__c) {
                  supplierTypeCombination.base = MSTOSupplierTypes.Purchase;
                } else if(assemblyPanel.quoteLineItemData.Customer_Provided_Base__c) {
                  supplierTypeCombination.base = MSTOSupplierTypes.CustomerProvided;
                }
                break;
              case MSTOAssemblyMetals.ANVIL:
                if(assemblyPanel.quoteLineItemDetailData.PullfromstockBKAnvil__c) {
                  supplierTypeCombination.anvil = MSTOSupplierTypes.Stock;
                } else if(assemblyPanel.quoteLineItemDetailData.PurchaseBKAnvil__c) {
                  supplierTypeCombination.anvil = MSTOSupplierTypes.Purchase;
                }
                break;
            }
          });

          const supplierTypeCombinationString = JSON.stringify(supplierTypeCombination);
          supplyCombinations.set(supplierTypeCombinationString, [...(supplyCombinations.get(supplierTypeCombinationString) || []), ...mstoAssemblyPanelsForAssembly]);
        });

        if(Array.from(supplyCombinations.values()).length) {
          Array.from(supplyCombinations.values()).forEach((assemblyPanelsForCombination, index) => {
            let updatedCladderPanelsMapForJSON = new Map(cladderPanelsMap);
            let updatedBackerPanelsMapForJSON =  new Map(backerPanelsMap);
            let updatedAnvilPanelsMapForJSON =  new Map(anvilPanelsMap);
            let updatedAssembliesForJSON = new Map(assemblies);

            const supplierCombinationLineItemFieldsMap = new Map(lineItemFieldsMap);
            const supplierCombinationLineItemDetailFieldsMap = new Map(lineItemDetailFieldsMap);
            const supplierCombinationFieldsUpdated = [...fieldsUpdated];

            const dependentUnwrittenFieldsMap: Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType> = new Map<QuoteLineItemDependentUnwrittenFields, DependentUnwrittenFieldsMapValueType>();
            const anvilItemDetailFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType> = new Map<QuoteLineItemFields, FieldsMapValueType>();
            const seamWeldItemDetailFieldsMap: Map<QuoteLineItemFields, FieldsMapValueType> = new Map<QuoteLineItemFields, FieldsMapValueType>();

            const cladPieces = assemblyPanelsForCombination.filter(assemblyPanel => assemblyPanel.metal === MSTOAssemblyMetals.CLAD);
            const basePieces = assemblyPanelsForCombination.filter(assemblyPanel => assemblyPanel.metal === MSTOAssemblyMetals.BASE);
            const anvilPieces = assemblyPanelsForCombination.filter(assemblyPanel => assemblyPanel.metal === MSTOAssemblyMetals.ANVIL);

            isComplete = mstoAssemblyGroupKeysForLine.length === quoteLineItemDetail?.Clads__c ? true : false;

            const manuallySizedChildLineQuantities = quoteLineItems.filter(quoteLineItemToCheck => quoteLineItemToCheck.Parent_Line__c === quoteLineItem.Line__c)?.map(quoteLineItem => quoteLineItem.Quantity__c).sum() || 0;
            const newLineQuantity = isComplete ? quoteLineItemDetail?.Quantity__c * basePieces.length : quoteLineItem?.Quantity__c;
            addFieldToMapAndUpdatedList(newLineQuantity, quoteLineItem?.Quantity__c + manuallySizedChildLineQuantities, QuoteLineItemFields.Quantity, QuoteLineItemValueTypes.SizingQuantity, supplierCombinationLineItemFieldsMap, supplierCombinationFieldsUpdated);

            if(newLineQuantity !== (quoteLineItem?.Quantity__c + manuallySizedChildLineQuantities)) {
              supplierCombinationLineItemDetailFieldsMap.set(QuoteLineItemFields.Clads, null);
              dependentUnwrittenFieldsMap.set(QuoteLineItemDependentUnwrittenFields.QuantityForLeftoverCalculation, newLineQuantity);

              if(!supplierCombinationFieldsUpdated.includes(QuoteLineItemValueTypes.QuantityForLeftoverCalculation)) {
                supplierCombinationFieldsUpdated.push(QuoteLineItemValueTypes.QuantityForLeftoverCalculation);
              }
            }

            //creating strings for purchase item descriptions
            const baseMetalName = basePieces[0].quoteLineItemData.BaseMetal__c;
            const baseMetal = rawMaterials.find(rawMaterial => rawMaterial.name === baseMetalName && rawMaterial.usage === RawMaterialUsage.Base && rawMaterial.dataAreaId.includes(manufacturingSite));

            basePieces[0].quoteLineItemDetailData.ItemDescriptionBackermm__c = createMSTOItemDescription(
              calculateMetricUOMValue(quoteLineItemDetail?.PurchaseBaseTK__c, unit),
              calculateMetricUOMValue(quoteLineItemDetail?.PurchaseBaseWidth__c, unit),
              calculateMetricUOMValue(quoteLineItemDetail?.PurchaseBaseLength__c, unit),
              baseMetal.dmcClass.includes('/F'),
              true
            );
            basePieces[0].quoteLineItemDetailData.ItemDescriptionBackerim__c = createMSTOItemDescription(
              calculateImperialUOMValue( quoteLineItemDetail?.PurchaseBaseTK__c, unit ),
              calculateImperialUOMValue( quoteLineItemDetail?.PurchaseBaseWidth__c, unit ),
              calculateImperialUOMValue( quoteLineItemDetail?.PurchaseBaseLength__c, unit ),
              baseMetal.dmcClass.includes('/F')
            );

            cladPieces[0].quoteLineItemDetailData.ItemDescriptionCladdermm__c = createMSTOItemDescription(
              calculateMetricUOMValue(quoteLineItemDetail?.PurchaseCladTK__c, unit),
              calculateMetricUOMValue(quoteLineItemDetail?.PurchaseCladWidth__c, unit),
              calculateMetricUOMValue(quoteLineItemDetail?.PurchaseCladLength__c, unit),
              false,
              true
            );

            cladPieces[0].quoteLineItemDetailData.ItemDescriptionCladderim__c = createMSTOItemDescription(
              calculateImperialUOMValue(quoteLineItemDetail?.PurchaseCladTK__c, unit),
              calculateImperialUOMValue(quoteLineItemDetail?.PurchaseCladWidth__c, unit),
              calculateImperialUOMValue(quoteLineItemDetail?.PurchaseCladLength__c, unit),
              false
            );

            if (anvilPieces && anvilPieces.length > 0) {
              // Metric
              anvilPieces[0].quoteLineItemDetailData.NC_Anvil_Item_Details__r.ItemDescriptionAnvilmm__c = createMSTOItemDescription(
                calculateMetricUOMValue(quoteLineItemDetail?.PurchaseAnvilTK__c, unit),
                calculateMetricUOMValue(quoteLineItemDetail?.PurchaseAnvilWidth__c, unit),
                calculateMetricUOMValue(quoteLineItemDetail?.PurchaseAnvilLength__c, unit),
                false,
                true
              );

              // Imperial
              anvilPieces[0].quoteLineItemDetailData.NC_Anvil_Item_Details__r.ItemDescriptionAnvilim__c = createMSTOItemDescription(
                calculateImperialUOMValue(quoteLineItemDetail?.PurchaseAnvilTK__c, unit),
                calculateImperialUOMValue(quoteLineItemDetail?.PurchaseAnvilWidth__c, unit),
                calculateImperialUOMValue(quoteLineItemDetail?.PurchaseAnvilLength__c, unit),
                false
              );
            }

            const finishedGoodShape = quoteLineItem?.Shape__c;
            const seamWeldedClad = !!cladPieces[0].seamWeldPieceNumber;

            if(seamWeldedClad) {
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.SeamWeld__c || false, quoteLineItemDetail?.SeamWeld__c, QuoteLineItemFields.SeamWeld, QuoteLineItemValueTypes.SeamWeld, supplierCombinationLineItemDetailFieldsMap, fieldsUpdated);
              addFieldToMapAndUpdatedList(false, quoteLineItemDetail?.PurchaseCL__c, QuoteLineItemFields.PurchaseCL, QuoteLineItemValueTypes.PurchaseCL, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(true, quoteLineItemDetail?.Pullfromstockcladder__c, QuoteLineItemFields.Pullfromstockcladder, QuoteLineItemValueTypes.Pullfromstockcladder, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);

              cladPieces.forEach(cladPiece => {
                const rawMaterialVendorField = `RawMaterialVendorSW${cladPiece.seamWeldPieceNumber}__c`;
                const configurationIdField = cladPiece.seamWeldPieceNumber === 1 ? 'ConfigurationID__c' : `ConfigurationIDSW${cladPiece.seamWeldPieceNumber}__c`;
                const purchaseField = `PurchaseSW${cladPiece.seamWeldPieceNumber}__c`;
                const purchaseQuantityField = `PurchaseQuantitySW${cladPiece.seamWeldPieceNumber}__c`;
                const quantitySWField = `QuantitySW${cladPiece.seamWeldPieceNumber}__c`;
                const pullFromStockQuantityField = `PullFromStockQuantitySW${cladPiece.seamWeldPieceNumber}__c`;
                const pullFromStockField = `PullFromStockSW${cladPiece.seamWeldPieceNumber}__c`;
                const shipDateField = `ShipDateSW${cladPiece.seamWeldPieceNumber}__c`;
                const daysForTransportField = `DaysForTransportSW${cladPiece.seamWeldPieceNumber}__c`;
                const preHeatToCutField = `PreheatToCutSW${cladPiece.seamWeldPieceNumber}__c`;
                const widthField = `CladderWidthSW${cladPiece.seamWeldPieceNumber}__c`;
                const lengthField = `CladderLengthSW${cladPiece.seamWeldPieceNumber}__c`;
                const tkField = `NominalThicknessSW${cladPiece.seamWeldPieceNumber}__c`;
                const weightField = `WeightSW${cladPiece.seamWeldPieceNumber}__c`;
                const pricePerPieceField = `SWRawMaterialCostSW${cladPiece.seamWeldPieceNumber}__c`;
                const freightPerWeightField = `EstimatedShippingTotalSW${cladPiece.seamWeldPieceNumber}__c`;
                const panelLocationField = `PanelLocationSW${cladPiece.seamWeldPieceNumber}__c`;
                const panelNumberField = `PanelNumberSW${cladPiece.seamWeldPieceNumber}__c`;
                const itemDescImField = `ItemDescriptionSeamWeld${cladPiece.seamWeldPieceNumber}im__c`;
                const itemDescMmField = `ItemDescriptionSeamWeld${cladPiece.seamWeldPieceNumber}mm__c`;
                const tkMinImField = `ThicknessminimSW${cladPiece.seamWeldPieceNumber}__c`;
                const tkMinMmField = `ThicknessminmmSW${cladPiece.seamWeldPieceNumber}__c`;

                const width = cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[widthField] || null;
                const length = cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[lengthField] || null;
                const tk = cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[tkField] || null;

                const tkUnitInput = unit === MeasurementSystems.Metric ? Units.Millimeters : Units.Inches;
                const tkIm = convertUnits(tk, tkUnitInput, Units.Inches) || null;
                const tkMM = convertUnits(tk, tkUnitInput, Units.Millimeters) || null;

                // imperial
                // ItemDescriptionSeamWeld{x}im__c
                const itemDescIm = createMSTOItemDescription(
                  calculateImperialUOMValue(tk, unit),
                  calculateImperialUOMValue(width, unit),
                  calculateImperialUOMValue(length, unit),
                  false
                );

                // metric
                // ItemDescriptionSeamWeld{x}mm__c
                const itemDescMm = createMSTOItemDescription(
                  calculateMetricUOMValue(tk, unit),
                  calculateMetricUOMValue(width, unit),
                  calculateMetricUOMValue(length, unit),
                  false,
                  true
                );

                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[rawMaterialVendorField] || 0, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[rawMaterialVendorField], rawMaterialVendorField as QuoteLineItemFields, QuoteLineItemValueTypes.RawMaterialVendorSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[configurationIdField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[configurationIdField], configurationIdField as QuoteLineItemFields, QuoteLineItemValueTypes.ConfigurationIDSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[purchaseField] || false, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[purchaseField], purchaseField as QuoteLineItemFields, QuoteLineItemValueTypes.PurchaseSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[purchaseQuantityField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[purchaseQuantityField], purchaseQuantityField as QuoteLineItemFields, QuoteLineItemValueTypes.PurchaseQuantitySW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[pullFromStockQuantityField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[pullFromStockQuantityField], pullFromStockQuantityField as QuoteLineItemFields, QuoteLineItemValueTypes.PullfromstockQuantitySW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[pullFromStockField] || false, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[pullFromStockField], pullFromStockField as QuoteLineItemFields, QuoteLineItemValueTypes.PullfromstockSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[shipDateField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[shipDateField], shipDateField as QuoteLineItemFields, QuoteLineItemValueTypes.ShipDateSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[daysForTransportField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[daysForTransportField], daysForTransportField as QuoteLineItemFields, QuoteLineItemValueTypes.DaysForTransportSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[preHeatToCutField] || false, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[preHeatToCutField], preHeatToCutField as QuoteLineItemFields, QuoteLineItemValueTypes.PreheattoCutSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(width, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[widthField], widthField as QuoteLineItemFields, QuoteLineItemValueTypes.CladderWidthSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(length, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[lengthField], lengthField as QuoteLineItemFields, QuoteLineItemValueTypes.CladderLengthSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(tk, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[tkField], tkField as QuoteLineItemFields, QuoteLineItemValueTypes.NominalThicknessSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[weightField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[weightField], weightField as QuoteLineItemFields, QuoteLineItemValueTypes.WeightSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[pricePerPieceField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[pricePerPieceField], pricePerPieceField as QuoteLineItemFields, QuoteLineItemValueTypes.SWRawMaterialCostSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[freightPerWeightField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[freightPerWeightField], freightPerWeightField as QuoteLineItemFields, QuoteLineItemValueTypes.EstimatedShippingTotalSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[panelLocationField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[panelLocationField], panelLocationField as QuoteLineItemFields, QuoteLineItemValueTypes.PanelLocationSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[panelNumberField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[panelNumberField], panelNumberField as QuoteLineItemFields, QuoteLineItemValueTypes.PanelNumberSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(itemDescIm, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[itemDescImField], itemDescImField as QuoteLineItemFields, QuoteLineItemValueTypes.ItemDescriptionSeamWeldim, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(itemDescMm, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[itemDescMmField], itemDescMmField as QuoteLineItemFields, QuoteLineItemValueTypes.ItemDescriptionSeamWeldmm, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);

                addFieldToMapAndUpdatedList(tkIm, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[tkMinImField], tkMinImField as QuoteLineItemFields, QuoteLineItemValueTypes.ThicknessminimSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
                addFieldToMapAndUpdatedList(tkMM, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[tkMinMmField], tkMinMmField as QuoteLineItemFields, QuoteLineItemValueTypes.ThicknessminmmSW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);

                addFieldToMapAndUpdatedList(cladPiece.quoteLineItemDetailData.NC_Seam_Weld_Item_Details__r[quantitySWField] || null, quoteLineItemDetail?.NC_Seam_Weld_Item_Details__r[quantitySWField], quantitySWField as QuoteLineItemFields, QuoteLineItemValueTypes.PurchaseQuantitySW, seamWeldItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              });
            } else {
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemData.Customer_Provided_Clad__c || false, quoteLineItem?.Customer_Provided_Clad__c, QuoteLineItemFields.CustomerProvidedClad, QuoteLineItemValueTypes.CustomerProvidedClad, supplierCombinationLineItemFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.RawMaterialVendorCladder__c || 0, quoteLineItemDetail?.RawMaterialVendorCladder__c, QuoteLineItemFields.RawMaterialVendorCladder, QuoteLineItemValueTypes.RawMaterialVendorCladder, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.ConfigurationID__c || null, quoteLineItemDetail?.ConfigurationID__c, QuoteLineItemFields.ConfigurationID, QuoteLineItemValueTypes.ConfigurationID, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.PurchaseCL__c || false, quoteLineItemDetail?.PurchaseCL__c, QuoteLineItemFields.PurchaseCL, QuoteLineItemValueTypes.PurchaseCL, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.Pullfromstockcladder__c || false, quoteLineItemDetail?.Pullfromstockcladder__c, QuoteLineItemFields.Pullfromstockcladder, QuoteLineItemValueTypes.Pullfromstockcladder, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.ShipDateCladder__c || null, quoteLineItemDetail?.ShipDateCladder__c, QuoteLineItemFields.ShipDateCladder, QuoteLineItemValueTypes.ShipDateCladder, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.CladderDaysForTransport__c || null, quoteLineItemDetail?.CladderDaysForTransport__c, QuoteLineItemFields.CladderDaysForTransport, QuoteLineItemValueTypes.CladderDaysForTransport, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.PreheattoTorchCutCladder__c || false, quoteLineItemDetail?.PreheattoTorchCutCladder__c, QuoteLineItemFields.PreheattoTorchCutCladder, QuoteLineItemValueTypes.PreheattoTorchCutCladder, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);

              // note: never update PurchaseCladWidth__c and PurchaseCladLength__c on this page
              // addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.PurchaseCladWidth__c || null, quoteLineItemDetail?.PurchaseCladWidth__c, QuoteLineItemFields.PurchaseCladWidth, QuoteLineItemValueTypes.PurchaseCladWidth, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              // addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.PurchaseCladLength__c || null, quoteLineItemDetail?.PurchaseCladLength__c, QuoteLineItemFields.PurchaseCladLength, QuoteLineItemValueTypes.PurchaseCladLength, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);

              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.PurchaseCladTK__c || null, quoteLineItemDetail?.PurchaseCladTK__c, QuoteLineItemFields.PurchaseCladTK, QuoteLineItemValueTypes.PurchaseCladTK, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.TorchCutCladder__c || false, quoteLineItemDetail?.TorchCutCladder__c, QuoteLineItemFields.TorchCutCladder, QuoteLineItemValueTypes.TorchCutCladder, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.ItemDescriptionCladdermm__c, quoteLineItemDetail?.ItemDescriptionCladdermm__c, QuoteLineItemFields.ItemDescriptionCladdermm, QuoteLineItemValueTypes.ItemDescriptionCladdermm, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.ItemDescriptionCladderim__c, quoteLineItemDetail?.ItemDescriptionCladderim__c, QuoteLineItemFields.ItemDescriptionCladderim, QuoteLineItemValueTypes.ItemDescriptionCladderim, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.PurchaseCladderPrice__c || null, quoteLineItemDetail?.PurchaseCladderPrice__c, QuoteLineItemFields.PurchaseCladderPrice, QuoteLineItemValueTypes.PurchaseCladderPrice, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.PurchaseCladderFreight_LB__c || null, quoteLineItemDetail?.PurchaseCladderFreight_LB__c, QuoteLineItemFields.PurchaseCladderFreightLB, QuoteLineItemValueTypes.PurchaseCladderFreightPerWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.PurchaseCladderFreight_KG__c || null, quoteLineItemDetail?.PurchaseCladderFreight_KG__c, QuoteLineItemFields.PurchaseCladderFreightKG, QuoteLineItemValueTypes.PurchaseCladderFreightPerWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.PurchaseCladderWeight_LB__c || null, quoteLineItemDetail?.PurchaseCladderWeight_LB__c, QuoteLineItemFields.PurchaseCladderWeightLB, QuoteLineItemValueTypes.PurchaseCladderWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
              addFieldToMapAndUpdatedList(cladPieces[0].quoteLineItemDetailData.PurchaseCladderWeight_KG__c || null, quoteLineItemDetail?.PurchaseCladderWeight_KG__c, QuoteLineItemFields.PurchaseCladderWeightKG, QuoteLineItemValueTypes.PurchaseCladderWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            }

            addFieldToMapAndUpdatedList(seamWeldedClad && finishedGoodShape === Shapes.Rectangle, quoteLineItem?.FinalinspectionofrectangularcladwithSeam__c, QuoteLineItemFields.FinalinspectionofrectangularcladwithSeam, QuoteLineItemValueTypes.FinalinspectionofrectangularcladwithSeam, supplierCombinationLineItemFieldsMap, fieldsUpdated);
            addFieldToMapAndUpdatedList(seamWeldedClad && finishedGoodShape === Shapes.Circle, quoteLineItem?.FinalinspectionofcladdiskwithSeam__c, QuoteLineItemFields.FinalinspectionofcladdiskwithSeam, QuoteLineItemValueTypes.FinalinspectionofcladdiskwithSeam, supplierCombinationLineItemFieldsMap, fieldsUpdated);
            addFieldToMapAndUpdatedList(!seamWeldedClad && finishedGoodShape === Shapes.Rectangle, quoteLineItem?.Finalinspectionofrectangularclad__c, QuoteLineItemFields.Finalinspectionofrectangularclad, QuoteLineItemValueTypes.Finalinspectionofrectangularclad, supplierCombinationLineItemFieldsMap, fieldsUpdated);
            addFieldToMapAndUpdatedList(!seamWeldedClad && finishedGoodShape === Shapes.Circle, quoteLineItem?.Finalinspectionofcladdisk__c, QuoteLineItemFields.Finalinspectionofcladdisk, QuoteLineItemValueTypes.Finalinspectionofcladdisk, supplierCombinationLineItemFieldsMap, fieldsUpdated);

            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemData.Customer_Provided_Base__c || false, quoteLineItem?.Customer_Provided_Base__c, QuoteLineItemFields.CustomerProvidedBase, QuoteLineItemValueTypes.CustomerProvidedBase, supplierCombinationLineItemFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.RawMaterialVendorBacker__c || 0, quoteLineItemDetail?.RawMaterialVendorBacker__c, QuoteLineItemFields.RawMaterialVendorBacker, QuoteLineItemValueTypes.RawMaterialVendorBacker, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.ConfigurationIDBacker__c || null, quoteLineItemDetail?.ConfigurationIDBacker__c, QuoteLineItemFields.ConfigurationIDBacker, QuoteLineItemValueTypes.ConfigurationIDBacker, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.PurchaseBK__c || false, quoteLineItemDetail?.PurchaseBK__c, QuoteLineItemFields.PurchaseBK, QuoteLineItemValueTypes.PurchaseBK, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.Pullfromstockbacker__c || false, quoteLineItemDetail?.Pullfromstockbacker__c, QuoteLineItemFields.Pullfromstockbacker, QuoteLineItemValueTypes.Pullfromstockbacker, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.ShipDateBase__c || null, quoteLineItemDetail?.ShipDateBase__c, QuoteLineItemFields.ShipDateBase, QuoteLineItemValueTypes.ShipDateBase, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.BackerDaysForTransport__c || null, quoteLineItemDetail?.BackerDaysForTransport__c, QuoteLineItemFields.BackerDaysForTransport, QuoteLineItemValueTypes.BackerDaysForTransport, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.PreheattoTorchCutBacker__c || false, quoteLineItemDetail?.PreheattoTorchCutBacker__c, QuoteLineItemFields.PreheattoTorchCutBacker, QuoteLineItemValueTypes.PreheattoTorchCutBacker, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.PurchaseBaseWidth__c || null, quoteLineItemDetail?.PurchaseBaseWidth__c, QuoteLineItemFields.PurchaseBaseWidth, QuoteLineItemValueTypes.PurchaseBaseWidth, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.PurchaseBaseLength__c || null, quoteLineItemDetail?.PurchaseBaseLength__c, QuoteLineItemFields.PurchaseBaseLength, QuoteLineItemValueTypes.PurchaseBaseLength, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.PurchaseBaseTK__c || null, quoteLineItemDetail?.PurchaseBaseTK__c, QuoteLineItemFields.PurchaseBaseTK, QuoteLineItemValueTypes.PurchaseBaseTK, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.TorchCutBacker__c || false, quoteLineItemDetail?.TorchCutBacker__c, QuoteLineItemFields.TorchCutBacker, QuoteLineItemValueTypes.TorchCutBacker, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.ItemDescriptionBackermm__c, quoteLineItemDetail?.ItemDescriptionBackermm__c, QuoteLineItemFields.ItemDescriptionBackermm, QuoteLineItemValueTypes.ItemDescriptionBackermm, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.ItemDescriptionBackerim__c, quoteLineItemDetail?.ItemDescriptionBackerim__c, QuoteLineItemFields.ItemDescriptionBackerim, QuoteLineItemValueTypes.ItemDescriptionBackerim, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.PurchaseBackerPrice__c || null, quoteLineItemDetail?.PurchaseBackerPrice__c, QuoteLineItemFields.PurchaseBackerPrice, QuoteLineItemValueTypes.PurchaseBackerPrice, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.PurchaseBackerFreight_LB__c || null, quoteLineItemDetail?.PurchaseBackerFreight_LB__c, QuoteLineItemFields.PurchaseBackerFreightLB, QuoteLineItemValueTypes.PurchaseBackerFreightPerWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.PurchaseBackerFreight_KG__c || null, quoteLineItemDetail?.PurchaseBackerFreight_KG__c, QuoteLineItemFields.PurchaseBackerFreightKG, QuoteLineItemValueTypes.PurchaseBackerFreightPerWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.PurchaseBackerWeight_LB__c || null, quoteLineItemDetail?.PurchaseBackerWeight_LB__c, QuoteLineItemFields.PurchaseBackerWeightLB, QuoteLineItemValueTypes.PurchaseBackerWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList(basePieces[0].quoteLineItemDetailData.PurchaseBackerWeight_KG__c || null, quoteLineItemDetail?.PurchaseBackerWeight_KG__c, QuoteLineItemFields.PurchaseBackerWeightKG, QuoteLineItemValueTypes.PurchaseBackerWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);

            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.VendorAnvil__c || 0, quoteLineItemDetail?.VendorAnvil__c, QuoteLineItemFields.VendorAnvil, QuoteLineItemValueTypes.VendorAnvil, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.NC_Anvil_Item_Details__r.ConfigurationIDAnvil__c || null, quoteLineItemDetail?.NC_Anvil_Item_Details__r.ConfigurationIDAnvil__c, QuoteLineItemFields.ConfigurationIDAnvil, QuoteLineItemValueTypes.ConfigurationIDAnvil, anvilItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PurchaseBKAnvil__c || false, quoteLineItemDetail?.PurchaseBKAnvil__c, QuoteLineItemFields.PurchaseBKAnvil, QuoteLineItemValueTypes.PurchaseBKAnvil, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PullfromstockBKAnvil__c || false, quoteLineItemDetail?.PullfromstockBKAnvil__c, QuoteLineItemFields.PullfromstockBKAnvil, QuoteLineItemValueTypes.PullfromstockBKAnvil, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.NC_Anvil_Item_Details__r.ShipDateAnvil__c || null, quoteLineItemDetail?.NC_Anvil_Item_Details__r.ShipDateAnvil__c, QuoteLineItemFields.ShipDateAnvil, QuoteLineItemValueTypes.ShipDateAnvil, anvilItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.NC_Anvil_Item_Details__r.AnvilDaysForTransport__c || null, quoteLineItemDetail?.NC_Anvil_Item_Details__r.AnvilDaysForTransport__c, QuoteLineItemFields.AnvilDaysForTransport, QuoteLineItemValueTypes.AnvilDaysForTransport, anvilItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PreheattoCutAnvil__c || false, quoteLineItemDetail?.PreheattoCutAnvil__c, QuoteLineItemFields.PreheattoCutAnvil, QuoteLineItemValueTypes.PreheattoCutAnvil, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PurchaseAnvilWidth__c || null, quoteLineItemDetail?.PurchaseAnvilWidth__c, QuoteLineItemFields.PurchaseAnvilWidth, QuoteLineItemValueTypes.PurchaseAnvilWidth, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PurchaseAnvilLength__c || null, quoteLineItemDetail?.PurchaseAnvilLength__c, QuoteLineItemFields.PurchaseAnvilLength, QuoteLineItemValueTypes.PurchaseAnvilLength, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PurchaseAnvilTK__c || null, quoteLineItemDetail?.PurchaseAnvilTK__c, QuoteLineItemFields.PurchaseAnvilTK, QuoteLineItemValueTypes.PurchaseAnvilTK, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.TorchCutAnvil1__c || false, quoteLineItemDetail?.TorchCutAnvil1__c, QuoteLineItemFields.TorchCutAnvil1, QuoteLineItemValueTypes.TorchCutAnvil1, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PurchaseAnvilPrice__c || null, quoteLineItemDetail?.PurchaseAnvilPrice__c, QuoteLineItemFields.PurchaseAnvilPrice, QuoteLineItemValueTypes.PurchaseAnvilPrice, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PurchaseAnvilFreight_LB__c || null, quoteLineItemDetail?.PurchaseAnvilFreight_LB__c, QuoteLineItemFields.PurchaseAnvilFreightLB, QuoteLineItemValueTypes.PurchaseAnvilFreightPerWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PurchaseAnvilFreight_KG__c || null, quoteLineItemDetail?.PurchaseAnvilFreight_KG__c, QuoteLineItemFields.PurchaseAnvilFreightKG, QuoteLineItemValueTypes.PurchaseAnvilFreightPerWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PurchaseAnvilWeight_LB__c || null, quoteLineItemDetail?.PurchaseAnvilWeight_LB__c, QuoteLineItemFields.PurchaseAnvilWeightLB, QuoteLineItemValueTypes.PurchaseAnvilWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.PurchaseAnvilWeight_KG__c || null, quoteLineItemDetail?.PurchaseAnvilWeight_KG__c, QuoteLineItemFields.PurchaseAnvilWeightKG, QuoteLineItemValueTypes.PurchaseAnvilWeight, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.NC_Anvil_Item_Details__r.ItemDescriptionAnvilim__c || null, quoteLineItemDetail?.NC_Anvil_Item_Details__r.ItemDescriptionAnvilim__c, QuoteLineItemFields.ItemDescriptionAnvilim, QuoteLineItemValueTypes.ItemDescriptionAnvilim, anvilItemDetailFieldsMap, supplierCombinationFieldsUpdated);
            addFieldToMapAndUpdatedList((anvilPieces || [])[0]?.quoteLineItemDetailData.NC_Anvil_Item_Details__r.ItemDescriptionAnvilmm__c || null, quoteLineItemDetail?.NC_Anvil_Item_Details__r.ItemDescriptionAnvilmm__c, QuoteLineItemFields.ItemDescriptionAnvilmm, QuoteLineItemValueTypes.ItemDescriptionAnvilmm, anvilItemDetailFieldsMap, supplierCombinationFieldsUpdated);


            let newQuoteLineItem: QuoteLineItem;
            if(index !== 0) {
              newQuoteLineItem = duplicatedQuoteLineItem(quoteLineItem, quoteLineItems.length);

              //Adding panels/assemblies for new split out lines
              updatedCladderPanelsMapForJSON = addToPanelsMapForSplitOutLines(cladPieces, updatedCladderPanelsMapForJSON, quoteLineItem.Line__c, newQuoteLineItem.Line__c);
              updatedBackerPanelsMapForJSON = addToPanelsMapForSplitOutLines(basePieces, updatedBackerPanelsMapForJSON, quoteLineItem.Line__c, newQuoteLineItem.Line__c);
              updatedAnvilPanelsMapForJSON = addToPanelsMapForSplitOutLines(anvilPieces, updatedAnvilPanelsMapForJSON, quoteLineItem.Line__c, newQuoteLineItem.Line__c);

              const assembliesForNewLineJSON = new Map<string, {
                quoteLineItemData: QuoteLineItem;
                quoteLineItemDetailData: QuoteLineItemDetail;
                metal: MSTOAssemblyMetals;
                panelNumber?: number;
                panelLocation?: PanelLocations;
                seamWeldPieceNumber?: number;
              }[]>();

              mstoAssemblyGroupKeysForLine.forEach((assemblyGroupKey, index) => {
                if(index + 1 > basePieces.length && Array.from(assembliesForNewLineJSON.keys()).length < basePieces.length) {
                  const existingAssemblyForSplitLine = updatedAssembliesForJSON.get(assemblyGroupKey);
                  const newAssemblyKeyObject = { assemblyGroup: +newQuoteLineItem.Line__c, pieceNumber: index + 1 - basePieces.length};

                  existingAssemblyForSplitLine.forEach(assemblyPanel => {
                    assemblyPanel.quoteLineItemData.Line__c = newQuoteLineItem.Line__c;
                  });

                  assembliesForNewLineJSON.set(JSON.stringify(newAssemblyKeyObject), existingAssemblyForSplitLine);
                }
              });

              updatedAssembliesForJSON = assembliesForNewLineJSON;
            } else {
              //Remove the original panels/assemblies that were split onto new lines
              updatedCladderPanelsMapForJSON = removeFromPanelsMapForSplitOutLines(cladPieces, updatedCladderPanelsMapForJSON, quoteLineItem.Line__c);
              updatedBackerPanelsMapForJSON = removeFromPanelsMapForSplitOutLines(basePieces, updatedBackerPanelsMapForJSON, quoteLineItem.Line__c);
              updatedAnvilPanelsMapForJSON = removeFromPanelsMapForSplitOutLines(anvilPieces, updatedAnvilPanelsMapForJSON, quoteLineItem.Line__c);

              mstoAssemblyGroupKeysForLine.forEach((assemblyGroupKey, index) => {
                if(index + 1 > basePieces.length) {
                  updatedAssembliesForJSON.delete(assemblyGroupKey);
                }
              });
            }

            const cladderPanelsMapJSON = JSON.stringify(Array.from(updatedCladderPanelsMapForJSON.entries()).filter((entry) => entry[0].split(' - ')[0] === (newQuoteLineItem || quoteLineItem).Line__c));
            addFieldToMapAndUpdatedList(cladderPanelsMapJSON || null, quoteLineItemDetail?.CladderJSON__c, QuoteLineItemFields.CladderJSON, QuoteLineItemValueTypes.CladderJSON, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);

            const backerPanelsMapJSON = JSON.stringify(Array.from(updatedBackerPanelsMapForJSON.entries()).filter((entry) => entry[0].split(' - ')[0] === (newQuoteLineItem || quoteLineItem).Line__c));
            addFieldToMapAndUpdatedList(backerPanelsMapJSON || null, quoteLineItemDetail?.BackerJSON__c, QuoteLineItemFields.BackerJSON, QuoteLineItemValueTypes.BackerJSON, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);

            const anvilPanelsMapJSON = JSON.stringify(Array.from(updatedAnvilPanelsMapForJSON.entries()).filter((entry) => entry[0].split(' - ')[0] === (newQuoteLineItem || quoteLineItem).Line__c));
            addFieldToMapAndUpdatedList(anvilPanelsMapJSON || null, quoteLineItemDetail?.AnvilJSON__c, QuoteLineItemFields.AnvilJSON, QuoteLineItemValueTypes.AnvilJSON, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);

            const assembliesMapJSON = JSON.stringify(Array.from(updatedAssembliesForJSON.entries()).filter((entry) => JSON.parse(entry[0]).assemblyGroup === +(newQuoteLineItem || quoteLineItem).Line__c));
            addFieldToMapAndUpdatedList(assembliesMapJSON || null, quoteLineItemDetail?.AssembliesJSON__c, QuoteLineItemFields.AssembliesJSON, QuoteLineItemValueTypes.AssembliesJSON, supplierCombinationLineItemDetailFieldsMap, supplierCombinationFieldsUpdated);

            const quoteLineItemToEdit = { ...(newQuoteLineItem || quoteLineItem) };

            Array.from(supplierCombinationLineItemFieldsMap.keys()).forEach(field => {
              quoteLineItemToEdit[field] = supplierCombinationLineItemFieldsMap.get(field);
            });

            Array.from(supplierCombinationLineItemDetailFieldsMap.keys()).forEach(field => {
              quoteLineItemToEdit.quoteLineItemDetail[field] = supplierCombinationLineItemDetailFieldsMap.get(field);
            });

            Array.from(anvilItemDetailFieldsMap.keys()).forEach(field => {
              quoteLineItemToEdit.quoteLineItemDetail.NC_Anvil_Item_Details__r[field] = anvilItemDetailFieldsMap.get(field);
            });

            Array.from(seamWeldItemDetailFieldsMap.keys()).forEach(field => {
              quoteLineItemToEdit.quoteLineItemDetail.NC_Seam_Weld_Item_Details__r[field] = seamWeldItemDetailFieldsMap.get(field);
            });

            saveQuoteLineItem(dependentUnwrittenFieldsMap, supplierCombinationFieldsUpdated, quoteLineItemToEdit, false, false, false, false, true);

            const childsupplierCombinationLineItemFieldsMap = new Map(supplierCombinationLineItemFieldsMap);

            const childsupplierCombinationLineItemDetailFieldsMap = new Map(supplierCombinationLineItemDetailFieldsMap);
            childsupplierCombinationLineItemDetailFieldsMap.delete(QuoteLineItemFields.SeamWeld);

            const childLinesToUpdate = quoteLineItems.filter(quoteLineItemToCheck => quoteLineItemToCheck.Parent_Line__c === quoteLineItem.Line__c);

            childLinesToUpdate.forEach(childLineToUpdate => {
              const quoteLineItemToEdit = { ...childLineToUpdate };

              const childLineFinishedGoodShape = quoteLineItemToEdit?.Shape__c;
              addFieldToMapAndUpdatedList(seamWeldedClad && childLineFinishedGoodShape === Shapes.Rectangle, quoteLineItemToEdit?.FinalinspectionofrectangularcladwithSeam__c, QuoteLineItemFields.FinalinspectionofrectangularcladwithSeam, QuoteLineItemValueTypes.FinalinspectionofrectangularcladwithSeam, childsupplierCombinationLineItemFieldsMap, fieldsUpdated);
              addFieldToMapAndUpdatedList(seamWeldedClad && childLineFinishedGoodShape === Shapes.Circle, quoteLineItemToEdit?.FinalinspectionofcladdiskwithSeam__c, QuoteLineItemFields.FinalinspectionofcladdiskwithSeam, QuoteLineItemValueTypes.FinalinspectionofcladdiskwithSeam, childsupplierCombinationLineItemFieldsMap, fieldsUpdated);
              addFieldToMapAndUpdatedList(!seamWeldedClad && childLineFinishedGoodShape === Shapes.Rectangle, quoteLineItemToEdit?.Finalinspectionofrectangularclad__c, QuoteLineItemFields.Finalinspectionofrectangularclad, QuoteLineItemValueTypes.Finalinspectionofrectangularclad, childsupplierCombinationLineItemFieldsMap, fieldsUpdated);
              addFieldToMapAndUpdatedList(!seamWeldedClad && childLineFinishedGoodShape === Shapes.Circle, quoteLineItemToEdit?.Finalinspectionofcladdisk__c, QuoteLineItemFields.Finalinspectionofcladdisk, QuoteLineItemValueTypes.Finalinspectionofcladdisk, childsupplierCombinationLineItemFieldsMap, fieldsUpdated);

              Array.from(childsupplierCombinationLineItemFieldsMap.keys()).forEach(field => {
                quoteLineItemToEdit[field] = childsupplierCombinationLineItemFieldsMap.get(field);
              });

              Array.from(childsupplierCombinationLineItemDetailFieldsMap.keys()).forEach(field => {
                quoteLineItemToEdit.quoteLineItemDetail[field] = childsupplierCombinationLineItemDetailFieldsMap.get(field);
              });

              Array.from(anvilItemDetailFieldsMap.keys()).forEach(field => {
                quoteLineItemToEdit.quoteLineItemDetail.NC_Anvil_Item_Details__r[field] = anvilItemDetailFieldsMap.get(field);
              });

              Array.from(seamWeldItemDetailFieldsMap.keys()).forEach(field => {
                quoteLineItemToEdit.quoteLineItemDetail.NC_Seam_Weld_Item_Details__r[field] = seamWeldItemDetailFieldsMap.get(field);
              });

              saveQuoteLineItem(dependentUnwrittenFieldsMap, supplierCombinationFieldsUpdated, quoteLineItemToEdit, false, false, false, false, true);
            });
          });
        } else {
          // TODO: if we want to update fields if it's not part of
          // assemblies yet, we have to dissect this part
          const cladderArray = Array.from(cladderPanelsMap.entries()).filter((entry) => entry[0].split(' - ')[0] === quoteLineItem.Line__c);
          const cladderPanelsMapJSON = JSON.stringify(cladderArray);
          addFieldToMapAndUpdatedList(cladderPanelsMapJSON || null, quoteLineItemDetail?.CladderJSON__c, QuoteLineItemFields.CladderJSON, QuoteLineItemValueTypes.CladderJSON, lineItemDetailFieldsMap, fieldsUpdated);

          const backerArray = Array.from(backerPanelsMap.entries()).filter((entry) => entry[0].split(' - ')[0] === quoteLineItem.Line__c);
          const backerPanelsMapJSON = JSON.stringify(backerArray);
          addFieldToMapAndUpdatedList(backerPanelsMapJSON || null, quoteLineItemDetail?.BackerJSON__c, QuoteLineItemFields.BackerJSON, QuoteLineItemValueTypes.BackerJSON, lineItemDetailFieldsMap, fieldsUpdated);

          const anvilArray = Array.from(anvilPanelsMap.entries()).filter((entry) => entry[0].split(' - ')[0] === quoteLineItem.Line__c);
          const anvilPanelsMapJSON = JSON.stringify(anvilArray);
          addFieldToMapAndUpdatedList(anvilPanelsMapJSON || null, quoteLineItemDetail?.AnvilJSON__c, QuoteLineItemFields.AnvilJSON, QuoteLineItemValueTypes.AnvilJSON, lineItemDetailFieldsMap, fieldsUpdated);

          const assembliesMapJSON = JSON.stringify(Array.from(assemblies.entries()).filter((entry) => JSON.parse(entry[0]).assemblyGroup === +quoteLineItem.Line__c));
          addFieldToMapAndUpdatedList(assembliesMapJSON || null, quoteLineItemDetail?.AssembliesJSON__c, QuoteLineItemFields.AssembliesJSON, QuoteLineItemValueTypes.AssembliesJSON, lineItemDetailFieldsMap, fieldsUpdated);

          const quoteLineItemToEdit = { ...quoteLineItem };

          cladderArray.forEach(([, value]: any) => {
            // Note: for now i will only update this items below
            // RawMaterialVendorCladder__c
            // ConfigurationID__c
            // ShipDateCladder__c
            const { quoteLineItemDetailData }
            : {
              quoteLineItemDetailData: QuoteLineItemDetail
            } = value || {};
            if (!quoteLineItemDetailData) {
              return;
            }

            addFieldToMapAndUpdatedList( quoteLineItemDetailData?.ConfigurationID__c, quoteLineItemDetail?.ConfigurationID__c, QuoteLineItemFields.ConfigurationID, QuoteLineItemValueTypes.ConfigurationID, lineItemDetailFieldsMap, fieldsUpdated );
            addFieldToMapAndUpdatedList( quoteLineItemDetailData?.RawMaterialVendorCladder__c, quoteLineItemDetail?.RawMaterialVendorCladder__c, QuoteLineItemFields.RawMaterialVendorCladder, QuoteLineItemValueTypes.RawMaterialVendorCladder, lineItemDetailFieldsMap, fieldsUpdated );
            addFieldToMapAndUpdatedList( quoteLineItemDetailData?.ShipDateCladder__c, quoteLineItemDetail?.ShipDateCladder__c, QuoteLineItemFields.ShipDateCladder, QuoteLineItemValueTypes.ShipDateCladder, lineItemDetailFieldsMap, fieldsUpdated );
          });

          backerArray.forEach(([, value]: any) => {
            // Note: for now i will only update this items below
            // RawMaterialVendorBacker__c
            // ConfigurationIDBacker__c
            // ShipDateBase__c
            const { quoteLineItemDetailData }
            : {
              quoteLineItemDetailData: QuoteLineItemDetail
            } = value || {};
            if (!quoteLineItemDetailData) {
              return;
            }

            addFieldToMapAndUpdatedList( quoteLineItemDetailData?.ConfigurationIDBacker__c, quoteLineItemDetail?.ConfigurationIDBacker__c, QuoteLineItemFields.ConfigurationIDBacker, QuoteLineItemValueTypes.ConfigurationIDBacker, lineItemDetailFieldsMap, fieldsUpdated );
            addFieldToMapAndUpdatedList( quoteLineItemDetailData?.RawMaterialVendorBacker__c, quoteLineItemDetail?.RawMaterialVendorBacker__c, QuoteLineItemFields.RawMaterialVendorBacker, QuoteLineItemValueTypes.RawMaterialVendorBacker, lineItemDetailFieldsMap, fieldsUpdated );
            addFieldToMapAndUpdatedList( quoteLineItemDetailData?.ShipDateBase__c, quoteLineItemDetail?.ShipDateBase__c, QuoteLineItemFields.ShipDateBase, QuoteLineItemValueTypes.ShipDateBase, lineItemDetailFieldsMap, fieldsUpdated );
          });

          Array.from(lineItemFieldsMap.keys()).forEach(field => {
            quoteLineItemToEdit[field] = lineItemFieldsMap.get(field);
          });

          Array.from(lineItemDetailFieldsMap.keys()).forEach(field => {
            quoteLineItemToEdit.quoteLineItemDetail[field] = lineItemDetailFieldsMap.get(field);
          });

          saveQuoteLineItem(new Map(), fieldsUpdated, quoteLineItemToEdit, false, false, false, false, true);

          const childLinesToUpdate = quoteLineItems.filter(quoteLineItemToCheck => quoteLineItemToCheck.Parent_Line__c === quoteLineItem.Line__c);

          childLinesToUpdate.forEach(childLineToUpdate => {
            const quoteLineItemToEdit = { ...childLineToUpdate };

            Array.from(lineItemFieldsMap.keys()).forEach(field => {
              quoteLineItemToEdit[field] = lineItemFieldsMap.get(field);
            });

            Array.from(lineItemDetailFieldsMap.keys()).forEach(field => {
              quoteLineItemToEdit.quoteLineItemDetail[field] = lineItemDetailFieldsMap.get(field);
            });

            saveQuoteLineItem(new Map(), fieldsUpdated, quoteLineItemToEdit, false, false, false, false, true);
          });
        }

      });
    } catch (error) {
      console.log(error);
    }

    if(saveQuoteToSf) {
      setIsQuoteSaving(true);
      saveQuote(id, QuoteActions.Save, undefined, setIsLoading, undefined, undefined, setAreLinesLoading, undefined, setIsQuoteSaving);
    }

    dispatchAdvSpecState({ type: AdvSpecActionTypes.setHasInitialized, payload: false });
  };

  const SaveCancelButtons = () => (
    <>
      <IonButton
        color='primary'
        class='msto-update-button'
        data-testid={'QAMSTOSaveButton'}
        onClick={() => {
          saveMsto(true);
        }}
      >
        Save
      </IonButton>
      {/* <IonButton color='secondary' class='cancel-button' onClick={() => navigateToOrderEntry()}>
        Cancel
      </IonButton> */}
    </>
  );

  return (loadingComplete() ?
    <>
    <IonContent className='msto-page'>
      <div className={classNames({
          'max-width-container': true,
          'msto-container': true,
        })}
      >
        <div className='floating-actions-container'>
          <FloatingAction icon={quoteTab} darkModeIcon={quoteTabDarkMode} hoveredIcon={quoteTabHovered} onClick={() => {
            saveMsto();
            navigateToRoute(PageRoutes.QuoteDetail, history, quote.Id);
          }} action='quote-detail'
          />
          <FloatingAction icon={orderEntryTab} darkModeIcon={orderEntryTabDarkMode} hoveredIcon={orderEntryTabHovered} onClick={() => {
            saveMsto();
            navigateToRoute(PageRoutes.OrderEntry, history, quote.Id);
          }} action='order-entry'
          />
          <FloatingAction icon={advSpecsTab} darkModeIcon={advSpecsTabDarkMode} hoveredIcon={advSpecsTabHovered} onClick={() => {
            saveMsto();
            navigateToRoute(PageRoutes.AdvancedSpecifications, history, quote.Id);
          }} action='adv specs'
          />
        </div>
        <div className='title-container'>
          <IonLabel class='title' data-testid={'QAMSTOOHeader'}>
            <span className="quotes-title" onClick={() => navigateToRoute(PageRoutes.Quotes, history, quote.Id, routeChangeAlert)} data-testid="QAMSTOOPageQuotesTitle">Quotes </span>
            /
            <span className='quote-number-title' onClick={() => {
              saveMsto();
              navigateToRoute(PageRoutes.QuoteDetail, history, quote.Id);
            }} data-testid="QAMSTOOPageQuoteNumberTitle"
            > {quote?.Name} </span>
            /
            <span className="order-entry-title" onClick={() => {
              saveMsto();
              navigateToRoute(PageRoutes.OrderEntry, history, quote.Id);
            }} data-testid="QAMSTOOPageOrderEntryTitle"
            > Order Entry </span>
            / MSTO
          </IonLabel>
        </div>
        <div className='sticky'>
          <div className='msto-header-container spaced'>
            <div className='msto-order-entry-tabs-container'>
              {tabs.map((tab) => (
                <SubActiveTab
                  tab={tab}
                  isActive={tab === activeTab}
                  handleTabSelected={handleTabSelected}
                  key={tab}
                />
              ))}
            </div>
            {/* adjustment container for uom toggler */}
            <div style={{margin: '0 0 0 0'}}>
              <UomDisplayToggle />
            </div>
          </div>
          <hr className='msto-header-rule' />
        </div>
        {activeTab !== MSTOSelectionTabs.Assemblies ? (
          <>
            <div className='msto-line-items-container'>
              <MSTOModalDropdown
                default={MSTOActions.UseAsIs}
                actions={[MSTOActions.AddPiece, MSTOActions.Supplier, MSTOActions.Reset]}
              />
              <div className='msto-line-header-margin' />
              <DisplayedMSTOAssembliesTable />
            </div>
            <SizesToOrderSection />
          </>
        ) : (
          <AllocationsAssembliesPanels />
        )}
        <div className='cta-button-container'>
          <SaveCancelButtons />
        </div>
      </div>
      </IonContent>
      <DisplayedSeamWeldPopover />
      <DisplayedSupplierPopover />
    </> :
    <div className="order-entry-loading-container">
      <Loading data-testId="'QAMSTOLoading'"/>
    </div>
  );
};

export default MSTOPage;

const PurchaseDisplayDeltaInfoIndicator = ({ icon }) => {
  return (
    <Tooltip
      position={TooltipPositions.Bottom}
      text={
        <div className='tool-tip-text'>
          There is unallocated metal for scrap
          <br />
          on the purchased panel size.
        </div>
      }
      offset={{ x: '-7px', y: '-6px' }}
    >
      <IonIcon class='info-icon' icon={icon} />
    </Tooltip>
  );
};