import React, { useEffect, useState } from 'react';
import AppDropdown from '@components/app-dropdown/app-dropdown';
import { useDataState } from '@context/data-context';

const AppDropdownSelection = (props) => {
  const { dataState } = useDataState();
  const { selections } = dataState;

  const [ options, setOptions ] = useState([]);
  const { valueType, areaId, attrLabel, attrValue, excludeValues, value, onChange, firstOptionDefault = true } = props;
  const oLabel = attrLabel || 'name';
  const oValue = attrValue || 'value';
  const oExcludeValues = excludeValues || [];

  const getOptions = () => {
    if (!selections
      || (selections && selections.length === 0)
    ) {
      return;
    }

    const item = selections.find((option) => {
      if (areaId) {
        return option.valueType === valueType && option.dataAreaId === areaId;
      }

      return option.valueType === valueType;
    });

    const _options = (item && item?.options?.map((option, index) => {
        return { label: option[oLabel], value: option[oValue], index };
      })) || [];

    if (oExcludeValues.length === 0) {
      setOptions(_options);
    } else {
      setOptions(_options.filter(_o => !oExcludeValues.includes(_o.value)));
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    getOptions();
  }, [selections])

  useEffect(() => {
    if (value || !options.length || !onChange) {
      return;
    }

    if (!value && firstOptionDefault) {
      const defaultValue = options[0];
      onChange(defaultValue);
    }
  }, [value, options]);

  return <AppDropdown options={options} {...props}/>
};

export default AppDropdownSelection;