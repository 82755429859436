import { Capacitor } from '@capacitor/core';
import { VaultLockTypes } from '@constants';
import {
  BrowserVault,
  DeviceSecurityType,
  IdentityVaultConfig,
  Vault,
  VaultType,
} from '@ionic-enterprise/identity-vault';
import { useMemo, useState, useEffect } from 'react';

const config: IdentityVaultConfig = {
  key: 'com.dmc.nc',
  type: VaultType.DeviceSecurity,
  deviceSecurityType: DeviceSecurityType.Both,
  lockAfterBackgrounded: 2000,
  shouldClearVaultAfterTooManyFailedAttempts: true,
  customPasscodeInvalidUnlockAttempts: 2,
  unlockVaultOnLoad: false,
};
const tokenKey = 'token';
const accessTokenKey = 'accessToken';

const getConfigUpdates = (lockType: VaultLockTypes) => {
  switch (lockType) {
    case VaultLockTypes.Biometrics:
      return {
        type: VaultType.DeviceSecurity,
        deviceSecurityType: DeviceSecurityType.Biometrics,
      };
    case VaultLockTypes.SystemPasscode:
      return {
        type: VaultType.DeviceSecurity,
        deviceSecurityType: DeviceSecurityType.SystemPasscode,
      };
    default:
      return {
        type: VaultType.SecureStorage,
        deviceSecurityType: DeviceSecurityType.None,
      };
  }
};

export const useVault = () => {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [lockType, setLockType] = useState<VaultLockTypes>(undefined);
  
  const vault = useMemo(() => {
    const vault = !Capacitor.isNativePlatform()
      ? new BrowserVault(config)
      : new Vault(config);

      return vault;
  }, []);

  useEffect(() => {
    if (lockType) {
      const { type, deviceSecurityType } = getConfigUpdates(lockType);
      vault.updateConfig({ ...vault.config, type, deviceSecurityType });
    }
  }, [lockType, vault]);

  const storeToken = async (value: string): Promise<void> => {
    setToken(value);
    await vault.setValue(tokenKey, value);
  };

  const restoreToken = async (): Promise<void> => {
    setAuthenticating(true);
    const value = await vault.getValue(tokenKey);
    setToken(value);
  };

  const storeAccessToken = async (value: string): Promise<void> => {
    await vault.setValue(accessTokenKey, value);
  };

  const restoreAccessToken = async (): Promise<string> => {
    return vault.getValue<string>(accessTokenKey);
  };

  return { authenticating, token, storeToken, restoreToken, setLockType, storeAccessToken, restoreAccessToken };
};