import React, { useEffect } from 'react';

import AppAccordion from './app-accordion/app-accordion';
import { AppAccordionGroupProvider } from './app-accordion-context';

import './app-accordion-group.scss';

interface Props {
  defaultValue?: string | string[];
  multiple?: boolean;
  children?: React.ReactNode | JSX.Element;
}

const reduceChildren = (children) => {
  return children.reduce((prev, curr) => {
    if (curr.type === AppAccordion) {
      prev.push(curr);
      return prev;
    }

    if (Array.isArray(curr)) {
      return reduceChildren(curr);
    }

    return prev;
  }, []);
};

const AppAccordionGroup = ({ children, defaultValue, multiple }: Props) => {
  const values = reduceChildren((children as any[]))
    .map((child) => {
      return child.props.value;
    });

  return <AppAccordionGroupProvider
    defaultValue={defaultValue}
    isMultiple={multiple}
    items={values}
  >
    <div className='app-accordion-group'>
      {children}
    </div>
  </AppAccordionGroupProvider>
};

export default AppAccordionGroup;