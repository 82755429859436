import { useAuth } from '@services/auth-service';
import React, { useEffect } from 'react';

interface CallbackProps {}

const Callback: React.FC<CallbackProps> = () => {
  const auth = useAuth();

  useEffect(() => {
    async function doCallback() {
      try {
        await auth.authConnectService.handleLoginCallback();
      } catch (e) { console.error(e);}
    }
    doCallback();
  }, []);
  return null;
};

export default Callback;