import * as React from 'react';
import { useState } from 'react';
import { IonButton, IonCard, IonCardHeader, IonCol, IonGrid, IonLabel, IonRow, useIonAlert, IonPopover } from '@ionic/react';
import { AssemblyGroupsColumnHeadings, AssemblyGroupsHeaderTabs, BooleanStrings, CladProductionSpecifications, CutMethods, CycleTypes, DefaultSurfaceTreatmentSelections, EdgePreps, FieldTypes, FinishedProductTypes, FlatteningSelections, HeadAssemblyErrorSections, HeadFormingTypes, HeatContourTolerancesAndHeatTreatmentOptions, MachiningTypes, ManufacturingSites, MeasurementSystems, NumberOfPiecesInHeadOptions, OutsideServices, PageRoutes, QuoteActions, QuoteLineItemAdditionalOptions, QuoteLineItemFieldUnitTypes, QuoteLineItemValueTypes, QuoteVariableConfigDescriptions, RawMaterialUsage, Regexes, SelectionTypes, TempUOMs, ToastMessages, ToastTypes, Units } from '@constants';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import './assembly-groups.scss';
import OutsideServicesSectionList from '@pages/assembly-groups/outside-services/outside-services-section-list';
import { DisplayedQuoteGroup, saveQuoteLineItemsAdvSpecs } from '@shared/quote-group-utils';
import LineItemField from '@components/line-item-field/line-item-field';
import MappedSection from '@components/mapped-section/mapped-section';
import SelectionContainer from '@components/selection-container/selection-container';
import { FinishedGood } from '@interfaces/finished-good';
import { calculateUOMValue, getDefaultCuttingToleranceNotes, getDefaultFlatteningNotes, getDefaultMachiningNotes, getFlatteningThinningGroup, getStressReliefDetails, getQuoteLineItemFromId, getQuoteVariableConfiguration, getSelectionOptions, getSelections, getVendors, getPONotesForSalesforce, getSelectionOptionsWithNameAndValue } from '@shared/quote-utils';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
import { useMapSetState } from '@hooks/useMapSetState';
import { useToast } from '@hooks/useToast';
import { RawMaterial } from '@interfaces/raw-material';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { AdvSpecActionTypes, useAdvSpecState, useAdvSpecStateDispatch } from '@context/adv-spec-context';
import { useQuoteGroup } from '@hooks/useQuoteGroup';
import { useHistory, useParams } from 'react-router-dom';
import { formatCurrency, navigateToRoute } from '@shared/utils';
import Loading from '@components/loading/loading';
import FloatingAction from '@components/floating-action/floating-action';
import advancedSpecsTab from '@assets/icon/Tab_Adv_Specs.svg';
import advancedSpecsTabDarkMode from '@assets/icon/Tab_Adv_Specs_DarkMode.svg';
import advancedSpecsTabHovered from '@assets/icon/Tab_Adv_Specs_Hovered.svg';
import PageLayout from '@components/page/page-layout';
import { AdvSpecsAssemblyGroupDetailFieldsToUpdate, AdvSpecsAssemblyGroupLineFieldsToUpdate, PONotesVariables } from '@shared/types';
import HeadFormingPONotesPopover from '@components/popover/head-forming-po-notes-popover/head-forming-po-notes-popover';
import TableHeaders from '@components/quote-line-table/quote-line-table-header';
import UomDisplayToggle from '@components/uom-toggle/uom-toggle';
import { useDisplayUomWithInit } from '@context/useDisplayUomWithInit';

const AssemblyGroupsPage = () => {
  const {
    dataState,
    loadRawMaterials,
    loadFinishedGoods,
    loadContent,
    loadEdgeAllowances,
    loadBoosterAllowances,
    loadSelections,
    loadQuoteVariableConfigurations,
    loadVendors,
    loadWeldingPatterns
  } = useDataState();

  const {
    finishedGoods,
    rawMaterials,
    selections,
    quoteVariableConfigurations,
    vendors
  } = dataState;

  const { advSpecState } = useAdvSpecState();
  const { dispatchAdvSpecState } = useAdvSpecStateDispatch();

  const {
    assemblyGroupCylinderDetailFieldsToUpdate,
    assemblyGroupDetailFieldsToUpdate,
    assemblyGroupHeadFieldsToUpdate,
    assemblyGroupLineFieldsToUpdate,
    assemblyGroupOptionsRequired,
    assemblyGroupOutsideServiceItemFieldsToUpdate,
    specifyPerAssemblyMap,
    metalComboDetailFieldsToUpdate,
    metalComboOptionsRequired,
    metalComboLineFieldsToUpdate,
  } = advSpecState;

  const {
    quoteState,
    loadQuote,
    loadQuoteLineItemDetails,
    saveQuoteLineItem,
    saveQuote
  } = useQuoteState();

  const { quote, quoteLineItemDetails } = quoteState;
  const { quoteLineItems, Manufacturing_Site__c: manufacturingSite } = quote || {};

  const toast = useToast(4000);
  const [confirmAlert] = useIonAlert();
  const { id, lineItemId } = useParams<{id: string, lineItemId: string}>();
  const history = useHistory();

  const [baseMetal, setBaseMetal] = React.useState<RawMaterial>(undefined);
  const [cladMetal, setCladMetal] = React.useState<RawMaterial>(undefined);
  const displayedQuoteGroup: DisplayedQuoteGroup = useQuoteGroup(quoteLineItems, quoteLineItemDetails, lineItemId);
  const [detailsAssociatedWithMetalCombo, setDetailsAssociatedWithMetalCombo] = React.useState<QuoteLineItemDetail[]>(displayedQuoteGroup?.allQlidsMappedToMetalCombo);
  const [isLoading, setIsLoading] = React.useState(true);
  const [areLinesLoading, setAreLinesLoading] = React.useState(undefined);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  const [openEndNotes, setOpenEndNotes] = React.useState(new Map<string, string>());
  const [openEndNotesRequired, setOpenEndNotesRequired] = React.useState(new Map<string, boolean>());
  const [headSectionErrorMap, setHeadSectionErrorMap] = React.useState(new Map<string, HeadAssemblyErrorSections[]>());
  const [hasHeadSectionErrors, setHasHeadSectionErrors] = React.useState(false);
  const [isQuoteSaving, setIsQuoteSaving] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);

    if(!quote) {
      loadQuote(
        id,
        undefined,
        true,
        undefined,
        setAreLinesLoading
      );
    }

    loadRawMaterials();
    loadFinishedGoods();
    loadContent();
    loadEdgeAllowances();
    loadBoosterAllowances();
    loadSelections();
    loadQuoteVariableConfigurations();
    loadVendors();
    loadWeldingPatterns();
  }, []);

  React.useEffect(() => {
    if(rawMaterials &&
      selections &&
      quoteVariableConfigurations &&
      vendors &&
      finishedGoods
    ) {
      setDataLoaded(true);
    }
  }, [dataState]);

  React.useEffect(() => {
    if(quoteLineItems && areLinesLoading) {
      try {
        loadQuoteLineItemDetails(setIsQuoteSaving);
        setAreLinesLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  }, [quoteLineItems]);

  React.useEffect(() => {
    if(displayedQuoteGroup && !isQuoteSaving) {
      if(!advSpecState.hasInitialized) {
        dispatchAdvSpecState({ type: AdvSpecActionTypes.initStateByQuoteGroup, payload: displayedQuoteGroup });
      }

      setDetailsAssociatedWithMetalCombo(displayedQuoteGroup.allQlidsMappedToMetalCombo);
      setIsLoading(false);
    }
  }, [displayedQuoteGroup, advSpecState.hasInitialized, isQuoteSaving]);

  React.useEffect(() => {
    if(displayedQuoteGroup && detailsAssociatedWithMetalCombo && assemblyGroupHeadFieldsToUpdate && assemblyGroupOutsideServiceItemFieldsToUpdate && !isQuoteSaving) {
      const headTypeQuoteLineItemIds = displayedQuoteGroup.allQlisMappedToMetalCombo.filter(qli => qli.Item_Type__c === FinishedProductTypes.Head)
        .map(qli => qli.Id || qli.displayId);

      const headDetails: QuoteLineItemDetail[] = [];
      quoteLineItems.forEach((qli) => {
        const id = qli.Id || qli.displayId;

        if(headTypeQuoteLineItemIds.find(qlidId => qlidId === id)) {
          headDetails.push(qli.quoteLineItemDetail);
        }
      });

      const headDetailIds = headDetails.map(headDetail => headDetail.Id || headDetail.associatedLineItem);

      const headDetailsAssociatedWithMetalCombo = detailsAssociatedWithMetalCombo.filter(detail => headDetailIds.includes(detail.Id || detail.associatedLineItem));
      const updatedOpenEndNotesRequired = new Map(openEndNotesRequired);
      const updatedOpenEndNotes = new Map(openEndNotes);

      for(const quoteDetail of headDetailsAssociatedWithMetalCombo) {
        const headDetail = assemblyGroupHeadFieldsToUpdate.get(quoteDetail.Id || quoteDetail.associatedLineItem);
        const edgePreparation = headDetail.openEnd;
        const openEndNotes = headDetail.openEndNotes;
        const id = quoteDetail.Id || quoteDetail.associatedLineItem;
        updatedOpenEndNotes.set(id, openEndNotes);

        if(edgePreparation && edgePreparation !== EdgePreps.SquareCut) {
          updatedOpenEndNotesRequired.set(id, true);
          setOpenEndNotesRequired(updatedOpenEndNotesRequired);
        }

        setOpenEndNotes(updatedOpenEndNotes);
      }

      setHasHeadSectionErrors(getHasHeadSectionErrors(headDetailIds));
      setIsLoading(false);
    }
  }, [displayedQuoteGroup, detailsAssociatedWithMetalCombo, assemblyGroupHeadFieldsToUpdate, assemblyGroupOutsideServiceItemFieldsToUpdate, isQuoteSaving]);

  const sectionsToDisplay: { [key: string]: ((props: {
    hideSpecifyPerAssembly?: boolean,
    quoteLineIdForRow?: string,
    quoteDetailForRow?: QuoteLineItemDetail,
    assemblyGroupOptionsRequired?: Map<string, {[key: string]: boolean}>,
    assemblyGroupLineFieldsToUpdate?:  {[key: string]: AdvSpecsAssemblyGroupLineFieldsToUpdate},
    assemblyGroupDetailFieldsToUpdate?: Map<string, AdvSpecsAssemblyGroupDetailFieldsToUpdate>}) => JSX.Element)[],
  } = advSpecState.assemblyGroupSectionsMap;

  const { displayUom: uom } = useDisplayUomWithInit();
  
  const isDisplayMetric = uom === MeasurementSystems.Metric;

  // set the metals when quoteLineItemDisplayed changes
  React.useEffect(() => {
    if(rawMaterials && displayedQuoteGroup) {
      try {
        setBaseMetal(
          rawMaterials.find(
            (rawMaterial: RawMaterial) =>
              rawMaterial.name === displayedQuoteGroup.qliDisplayed?.BaseMetal__c &&
              rawMaterial.usage === RawMaterialUsage.Base &&
              rawMaterial.dataAreaId.includes(manufacturingSite)
          )
        );
        setCladMetal(
          rawMaterials.find(
            (rawMaterial: RawMaterial) =>
              rawMaterial.name === displayedQuoteGroup.qliDisplayed?.CladMetal__c &&
              rawMaterial.usage === RawMaterialUsage.Clad &&
              rawMaterial.dataAreaId.includes(manufacturingSite)
          )
        );
      } catch(err) {
        console.log(err);
      }
    }
  }, [rawMaterials, displayedQuoteGroup]);

  const assemblyGroupRef = React.useRef<HTMLDivElement>(null);
  const advancedSpecRef = React.useRef<HTMLDivElement>(null);
  const postCladProcessingRef = React.useRef<HTMLDivElement>(null);
  const outsideServicesRef = React.useRef<HTMLDivElement>(null);
  const headFormingRef = React.useRef<HTMLDivElement>(null);
  const freightRef = React.useRef<HTMLDivElement>(null);

  const sectionRefMap: Map<AssemblyGroupsHeaderTabs, React.MutableRefObject<HTMLDivElement>> = new Map([
    [AssemblyGroupsHeaderTabs.AssemblyGroup, assemblyGroupRef],
    [AssemblyGroupsHeaderTabs.AdvancedSpec, advancedSpecRef],
    [AssemblyGroupsHeaderTabs.PostCladProcessing, postCladProcessingRef],
    [AssemblyGroupsHeaderTabs.OutsideServices, outsideServicesRef],
    [AssemblyGroupsHeaderTabs.HeadForming, headFormingRef],
    [AssemblyGroupsHeaderTabs.Freight, freightRef],
  ]);

  const loadingComplete = () => {
    return dataLoaded && !isLoading;
  };

  const kgOrLbString = isDisplayMetric ? 'kg' : ' lb';

  const routeChangeAlert = (url: string) => {
    confirmAlert({
      cssClass: 'confirm-alert',
      header: 'Are you sure you want to leave the page? Changes you made may not be saved.',
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'alert-button-cancel',
        },
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: () => history.push(url),
        },
      ],
    });
  };

  const PONotesButton = (props: {
    isDisabled?: boolean,
    onClick?: React.MouseEventHandler<HTMLIonButtonElement>
  }) => (
    <>
      <IonButton class='po-notes-button' disabled={props.isDisabled} onClick={props.onClick} data-testid='QAAssemblyGroupsPONotesButton'>
        PO Notes
      </IonButton>
    </>
  );

  const updateAssGrp = (saveQuoteToSf = false) => {
    try {
      dispatchAdvSpecState({ type: AdvSpecActionTypes.setAssemblyGroupOptionsRequired, payload: assemblyGroupOptionsRequired });
      dispatchAdvSpecState({ type: AdvSpecActionTypes.setAssemblyGroupLineFieldsToUpdate, payload: assemblyGroupLineFieldsToUpdate});
      dispatchAdvSpecState({ type: AdvSpecActionTypes.setAssemblyGroupDetailFieldsToUpdate, payload: assemblyGroupDetailFieldsToUpdate});
      dispatchAdvSpecState({ type: AdvSpecActionTypes.setAssemblyGroupHeadFieldsToUpdate, payload: assemblyGroupHeadFieldsToUpdate});
      dispatchAdvSpecState({ type: AdvSpecActionTypes.setAssemblyGroupOutsideServiceItemFieldsToUpdate, payload: assemblyGroupOutsideServiceItemFieldsToUpdate});

      saveQuoteLineItemsAdvSpecs(
        displayedQuoteGroup,
        assemblyGroupLineFieldsToUpdate,
        assemblyGroupDetailFieldsToUpdate,
        assemblyGroupHeadFieldsToUpdate,
        assemblyGroupOutsideServiceItemFieldsToUpdate,
        assemblyGroupCylinderDetailFieldsToUpdate,
        metalComboDetailFieldsToUpdate,
        metalComboOptionsRequired,
        assemblyGroupOptionsRequired,
        quoteLineItems,
        saveQuoteLineItem,
        specifyPerAssemblyMap,
        metalComboLineFieldsToUpdate,
        baseMetal,
        cladMetal
      );

      dispatchAdvSpecState({ type: AdvSpecActionTypes.setHasInitialized, payload: false });
      dispatchAdvSpecState({ type: AdvSpecActionTypes.setSelectedLineItemId, payload: lineItemId });

      if(saveQuoteToSf) {
        setIsQuoteSaving(true);
        saveQuote(id, QuoteActions.Save, undefined, setIsLoading, undefined, undefined, setAreLinesLoading, undefined, setIsQuoteSaving);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getHasHeadSectionErrors = (headDetailIds): boolean => {
    const headDetailsAssociatedWithMetalCombo = detailsAssociatedWithMetalCombo.filter(detail => headDetailIds.includes(detail.Id || detail.associatedLineItem));
    const updatedHeadSectionsMap = new Map(headSectionErrorMap);
    let hasError = false;

    for(const quoteDetail of headDetailsAssociatedWithMetalCombo) {
      const id = quoteDetail.Id || quoteDetail.associatedLineItem;
      const headDetail = assemblyGroupHeadFieldsToUpdate.get(id);
      const outsideService = assemblyGroupOutsideServiceItemFieldsToUpdate.get(id);

      if(!headDetail.headQuantity) {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id) ? [...updatedHeadSectionsMap.get(id)] : [];

        if(!updatedErrorsList.includes(HeadAssemblyErrorSections.HeadQuantity)) {
          updatedErrorsList.push(HeadAssemblyErrorSections.HeadQuantity);
        }
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
        hasError = true;
      } else {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id)?.filter(error => error !== HeadAssemblyErrorSections.HeadQuantity);
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
      }

      if(!headDetail.blankSize) {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id) ? [...updatedHeadSectionsMap.get(id)] : [];

        if(!updatedErrorsList.includes(HeadAssemblyErrorSections.BlankSize)) {
          updatedErrorsList.push(HeadAssemblyErrorSections.BlankSize);
        }
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
        hasError = true;
      } else {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id)?.filter(error => error !== HeadAssemblyErrorSections.BlankSize);
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
      }

      if(!headDetail.headDiameter) {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id) ? [...updatedHeadSectionsMap.get(id)] : [];

        if(!updatedErrorsList.includes(HeadAssemblyErrorSections.HeadDiameter)) {
          updatedErrorsList.push(HeadAssemblyErrorSections.HeadDiameter);
        }
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
        hasError = true;
      } else {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id)?.filter(error => error !== HeadAssemblyErrorSections.HeadDiameter);
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
      }

      if(!headDetail.headFormingType) {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id) ? [...updatedHeadSectionsMap.get(id)] : [];

        if(!updatedErrorsList.includes(HeadAssemblyErrorSections.HeadFormingType)) {
          updatedErrorsList.push(HeadAssemblyErrorSections.HeadFormingType);
        }
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
        hasError = true;
      } else {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id)?.filter(error => error !== HeadAssemblyErrorSections.HeadFormingType);
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
      }

      if(!headDetail.straightFlange) {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id) ? [...updatedHeadSectionsMap.get(id)] : [];

        if(!updatedErrorsList.includes(HeadAssemblyErrorSections.StraightFlange)) {
          updatedErrorsList.push(HeadAssemblyErrorSections.StraightFlange);
        }
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
        hasError = true;
      } else {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id)?.filter(error => error !== HeadAssemblyErrorSections.StraightFlange);
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
      }

      if(!headDetail.nobelCladProcedure) {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id) ? [...updatedHeadSectionsMap.get(id)] : [];

        if(!updatedErrorsList.includes(HeadAssemblyErrorSections.NobelCladProcedure)) {
          updatedErrorsList.push(HeadAssemblyErrorSections.NobelCladProcedure);
        }
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
        hasError = true;
      } else {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id)?.filter(error => error !== HeadAssemblyErrorSections.NobelCladProcedure);
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
      }

      if(!outsideService.headFormingVendor) {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id) ? [...updatedHeadSectionsMap.get(id)] : [];

        if(!updatedErrorsList.includes(HeadAssemblyErrorSections.HeadFormingSupplier)) {
          updatedErrorsList.push(HeadAssemblyErrorSections.HeadFormingSupplier);
        }
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
        hasError = true;
      } else {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id)?.filter(error => error !== HeadAssemblyErrorSections.HeadFormingSupplier);
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
      }

      if(openEndNotesRequired?.get(id) && !openEndNotes?.get(id)) {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id) ? [...updatedHeadSectionsMap.get(id)] : [];

        if(!updatedErrorsList.includes(HeadAssemblyErrorSections.OpenEnd)) {
          updatedErrorsList.push(HeadAssemblyErrorSections.OpenEnd);
        }
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
        hasError = true;
      } else {
        const updatedErrorsList = updatedHeadSectionsMap?.get(id)?.filter(error => error !== HeadAssemblyErrorSections.OpenEnd);
        updatedHeadSectionsMap.set(id, updatedErrorsList);

        setHeadSectionErrorMap(updatedHeadSectionsMap);
      }
    }

    return hasError;
  };

  const validateAssemblyGroups = () => {
    const headTypeQuoteLineItemIds = displayedQuoteGroup.allQlisMappedToMetalCombo.filter(qli => qli.Item_Type__c === FinishedProductTypes.Head)
      .map(qli => qli.Id || qli.displayId);

    const headDetails: QuoteLineItemDetail[] = [];
    quoteLineItems.forEach(qli => {
      const id = qli.Id || qli.displayId;

      if(headTypeQuoteLineItemIds.find(qlidId => qlidId === id)) {
        headDetails.push(qli.quoteLineItemDetail);
      }
    });

    const headDetailIds = headDetails.map(headDetail => headDetail.Id || headDetail.associatedLineItem);
    const hasErrors = getHasHeadSectionErrors(headDetailIds);
    setHasHeadSectionErrors(hasErrors);

    if(headDetailIds.length && hasErrors) {
      toast(ToastTypes.Error, ToastMessages.RequiredFields);
      return false;
    } else {
      return true;
    }
  };

  const UpdateButton = () => (
    <>
      <IonButton
        color='primary'
        class='save-button'
        data-testid={'QAAssemblyGroupsSaveButton'}
        onClick={() => {
          try {
            if(validateAssemblyGroups()) {
              updateAssGrp(true);
            }
          } catch (error) {
            console.log(error);
            return;
          }
        }}
      >
        Save
      </IonButton>
    </>
  );

  const AssemblyGroupSection = (displayedQuoteLines: QuoteLineItem[]) => {
    const tableHeadersAssemblyGroupTable = [
      { display: AssemblyGroupsColumnHeadings.Line, colSize: '0.4' },
      { display: AssemblyGroupsColumnHeadings.Quantity, colSize: '0.4' },
      { display: AssemblyGroupsColumnHeadings.Width, colSize: '0.8' },
      { display: AssemblyGroupsColumnHeadings.Length, colSize: '0.8' },
      { display: AssemblyGroupsColumnHeadings.CladNom, colSize: '0.8' },
      { display: AssemblyGroupsColumnHeadings.CladMin, colSize: '0.8' },
      { display: AssemblyGroupsColumnHeadings.BaseNom, colSize: '0.8' },
      { display: AssemblyGroupsColumnHeadings.BaseMin, colSize: '0.8' },
      { display: AssemblyGroupsColumnHeadings.Product, colSize: '1.6' },
      { display: AssemblyGroupsColumnHeadings.Shape, colSize: '1.6' },
      { display: AssemblyGroupsColumnHeadings.Price }
    ];

    return (
      <>
        <IonGrid>
          <IonRow class='section-row'>
            <IonCol class='flex-column'>
              <div>
                <IonGrid>
                  <IonRow class='section-row'>
                    <IonCol class='block'>
                      <TableHeaders labels={tableHeadersAssemblyGroupTable}/>
                      {displayedQuoteLines.map(displayedQuoteLine => (
                      <IonCard key={displayedQuoteLine.Id} className='assembly-group-card' onClick={(e: any) => {
                        e.persist();
                      }} data-testid={'QAAssemblyGroupsAssemblyGroupLine_' + displayedQuoteLine.Line__c}
                      >
                        <IonCardHeader class='assembly-group-card-header'>
                          <IonGrid>
                            <IonRow class="assembly-group-row">
                              <IonCol class="assembly-group-col" size="0.4">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.Line}
                                  datatestid="QAAssemblyGroupsAssemblyGroupLine"
                                  displayedValue={displayedQuoteLine.Line__c}
                                />
                              </IonCol>
                              <IonCol class="assembly-group-col" size="0.4">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.SizingQuantity}
                                  datatestid="QAAssemblyGroupsAssemblyGroupQuantity"
                                  value={displayedQuoteLine.Quantity__c}
                                  readOnly={true}
                                />
                              </IonCol>
                              <IonCol class="assembly-group-col" size="0.8">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.Width}
                                  datatestid="QAAssemblyGroupsAssemblyGroupWidth"
                                  imperialValue={displayedQuoteLine.Width_in__c}
                                  metricValue={displayedQuoteLine.Width_mm__c}
                                  unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                                  readOnly={true}
                                  uom={uom}
                                />
                              </IonCol>
                              <IonCol class="assembly-group-col" size="0.8">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.Length}
                                  datatestid="QAAssemblyGroupsAssemblyGroupLength"
                                  imperialValue={displayedQuoteLine.Length_in__c}
                                  metricValue={displayedQuoteLine.Length_mm__c}
                                  unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
                                  readOnly={true}
                                  uom={uom}
                                />
                              </IonCol>
                              <IonCol class="assembly-group-col" size="0.8">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.CladNom}
                                  datatestid="QAAssemblyGroupsAssemblyGroupCladNom"
                                  imperialValue={displayedQuoteLine.Clad_Nom_in__c || 0}
                                  metricValue={displayedQuoteLine.Clad_Nom_mm__c || 0}
                                  unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                                  readOnly={true}
                                  uom={uom}
                                />
                              </IonCol>
                              <IonCol class="assembly-group-col" size="0.8">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.CladMin}
                                  datatestid="QAAssemblyGroupsAssemblyGroupCladMin"
                                  imperialValue={displayedQuoteLine.Clad_Min_in__c || 0}
                                  metricValue={displayedQuoteLine.Clad_Min_mm__c || 0}
                                  unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                                  readOnly={true}
                                  uom={uom}
                                />
                              </IonCol>
                              <IonCol class="assembly-group-col" size="0.8">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.BaseNom}
                                  datatestid="QAAssemblyGroupsAssemblyGroupBaseNom"
                                  imperialValue={displayedQuoteLine.Base_Nom_in__c || 0}
                                  metricValue={displayedQuoteLine.Base_Nom_mm__c || 0}
                                  unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                                  readOnly={true}
                                  uom={uom}
                                />
                              </IonCol>
                              <IonCol class="assembly-group-col" size="0.8">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.BaseMin}
                                  datatestid="QAAssemblyGroupsAssemblyGroupBaseMin"
                                  imperialValue={displayedQuoteLine.Base_Min_in__c || 0}
                                  metricValue={displayedQuoteLine.Base_Min_mm__c || 0}
                                  unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
                                  readOnly={true}
                                  uom={uom}
                                />
                              </IonCol>
                              <IonCol class="assembly-group-col" size="1.6">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.SizingProduct}
                                  datatestid="QAQuoteDetailPageSizingQuoteLineItemProduct"
                                  displayedValue={displayedQuoteLine.Item_Type__c || '- -'}
                                  readOnly={true}
                                />
                              </IonCol>
                              <IonCol class="assembly-group-col" size="1.6">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.Shape}
                                  datatestid="QAAssemblyGroupsAssemblyGroupShape"
                                  displayedValue={displayedQuoteLine.Shape__c}
                                />
                              </IonCol>
                              <IonCol class="assembly-group-col">
                                <LineItemField
                                  valueType={QuoteLineItemValueTypes.TotalAdjPrice}
                                  datatestid="QAAssemblyGroupsAssemblyGroupTotalAdjPrice"
                                  value={displayedQuoteLine.TotalAdjPrice__c}
                                  unitType={QuoteLineItemFieldUnitTypes.Currency}
                                  currency={displayedQuoteLine.CurrencyIsoCode}
                                  readOnly={true}
                                />
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCardHeader>
                      </IonCard>))}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  };

  const AdvancedSpecSection = (displayedQuoteLine: QuoteLineItem, displayedQuoteDetail: QuoteLineItemDetail) => {
    return (
      <IonGrid>
        {Object.keys(sectionsToDisplay).map((section) => (
          <Section key={section} section={section} displayedQuoteLine={displayedQuoteLine} displayedQuoteDetail={displayedQuoteDetail}/>
        ))}
      </IonGrid>
    );
  };

  const PostCladProcessingSection = (displayedQuoteLine: QuoteLineItem, displayedQuoteDetail: QuoteLineItemDetail) => {
    const updateMap = assemblyGroupLineFieldsToUpdate[displayedQuoteLine.Id || displayedQuoteLine.displayId];
    const displayedQuoteDetailId = displayedQuoteDetail.Id || displayedQuoteDetail.associatedLineItem;

    const [isNCHouseStressReliefChecked, setIsNCHouseStressReliefChecked] = useMapSetState<string, boolean>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingIsNCHouseStressReliefChecked');
    const [NCHouseStressReliefMin, setNCHouseStressReliefMin] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingNCHouseStressReliefMin');
    const [NCHouseStressReliefMax, setNCHouseStressReliefMax] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingNCHouseStressReliefMax');
    const [NCHouseStressReliefTemp, setNCHouseStressReliefTemp] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingNCHouseStressReliefTemp');
    const [SimulateHeatTreatmentHeadForming, setSimulateHeatTreatmentHeadForming] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingHeadFormingHeatTreat');
    const [NCHouseStressReliefDetails, setNCHouseStressReliefDetails] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingNCHouseStressReliefDetails');
    const [isNCHouseStressReliefVendorChecked, setIsNCHouseStressReliefVendorChecked] = useMapSetState<string, boolean>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingIsNCHouseStressReliefVendorChecked');
    const [isPreHeatReqForCladdingChecked, setIsPreHeatReqForCladdingChecked] = useMapSetState<string, boolean>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingIsPreHeatReqForCladdingChecked');
    const [preHeatReqForCladdingMin, setPreHeatReqForCladdingMin] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingPreHeatReqForCladdingMin');
    const [isPreHeatReqForCuttingChecked, setIsPreHeatReqForCuttingChecked] = useMapSetState<string, boolean>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingIsPreHeatReqForCuttingChecked');
    const [preHeatReqForCuttingMin, setPreHeatReqForCuttingMin] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingPreHeatReqForCuttingMin');
    const [flattening, setFlattening] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingFlattening');
    const [flatteningNotes, setFlatteningNotes] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingFlatteningNotes');
    const [cutMethod, setCutMethod] = useState<string>(updateMap?.postCladProcessingCutting);
    const [cuttingNotes, setCuttingNotes] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingCuttingNotes');
    const [machining, setMachining] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingMachining');
    const [machiningNotes, setMachiningNotes] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingMachiningNotes');
    const [surfaceTreatment, setSurfaceTreatment] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingSurfaceTreatment');
    const [surfaceTreatmentNotes, setSurfaceTreatmentNotes] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingSurfaceTreatmentNotes');
    const [inhousePackaging, setInhousePackaging] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingInhousePackaging');
    const [inhousePackagingNotes, setInhousePackagingNotes] = useMapSetState<string>(assemblyGroupDetailFieldsToUpdate, displayedQuoteDetailId, 'postCladProcessingInhousePackagingNotes');

    const itemType = finishedGoods.find((finishedGood: { name: string; dataAreaId: ManufacturingSites; }) => finishedGood.name === displayedQuoteLine?.Item_Type__c && finishedGood.dataAreaId === manufacturingSite);
    const hasCosmeticGrinding = displayedQuoteLine.Additional_Options__c?.split(';').includes(QuoteLineItemAdditionalOptions.CosmeticGrinding);
    const uomTempLabel = isDisplayMetric ? 'C' : 'F';

    const flatteningNotesDefault = (selection) => {
      let descriptionFlattening: QuoteVariableConfigDescriptions;
      const uomLabel = isDisplayMetric ? 'MM' : 'IN';
      let thickness: number = displayedQuoteLine[`Total_TK_${uomLabel}__c`];

      if (itemType.name === FinishedProductTypes.Tubesheet) {
        const width: number = displayedQuoteLine[`Width_${uomLabel.toLowerCase()}__c`];
        const measuringSystemName = isDisplayMetric ? MeasurementSystems.Metric.toLowerCase() : MeasurementSystems.Imperial.toLowerCase();
        thickness = getFlatteningThinningGroup(baseMetal, manufacturingSite, uom, thickness, width)[`${measuringSystemName}`].ftFactorWidth;
      }

      switch (selection) {
        case FlatteningSelections.StandardPlateFlatness:
        default:
          descriptionFlattening = isDisplayMetric
          ? QuoteVariableConfigDescriptions.FlatteningPlateMetric
          : QuoteVariableConfigDescriptions.FlatteningPlateImperial;
          break;
        case FlatteningSelections.StandardTubesheetFlatness:
          descriptionFlattening = isDisplayMetric
          ? QuoteVariableConfigDescriptions.FlatteningTubesheetMetric
          : QuoteVariableConfigDescriptions.FlatteningTubesheetImperial;
          break;
        case FlatteningSelections.None:
          descriptionFlattening = QuoteVariableConfigDescriptions.FlatteningNone;
          break;
        case FlatteningSelections.Other:
          descriptionFlattening = QuoteVariableConfigDescriptions.FlatteningOther;
          break;
      }

      const valuePhraseFlattening = getQuoteVariableConfiguration(
        quoteVariableConfigurations,
        descriptionFlattening,
        manufacturingSite,
        FieldTypes.Text
      ) as string;

        setFlatteningNotes(getDefaultFlatteningNotes(itemType, uom, thickness, valuePhraseFlattening));
    };

    const cuttingNotesDefault = (cutMethodSelection) => {
      const uomLabel = {
        lowercased: isDisplayMetric ? 'mm' : 'in',
        uppercased: isDisplayMetric ? 'MM' : 'IN',
      };
      const thickness: number = displayedQuoteLine[`Total_TK_${uomLabel.uppercased}__c`];
      const length: number = displayedQuoteLine[`Length_${uomLabel.lowercased}__c`];

      const description = isDisplayMetric
        ? QuoteVariableConfigDescriptions.CuttingValuePhraseMetric
        : QuoteVariableConfigDescriptions.CuttingValuePhraseImperial;

      const valuePhrase = getQuoteVariableConfiguration(
        quoteVariableConfigurations,
        description,
        manufacturingSite,
        FieldTypes.Text
      ) as string;

      // if cutting method changes, update the default cutting notes using the new cutting method
      setCuttingNotes(getDefaultCuttingToleranceNotes(itemType, cutMethodSelection, thickness, length, uom, valuePhrase));
    };

    React.useEffect(() => {
      if(updateMap) {
        updateMap.postCladProcessingCutting = cutMethod;
      }
    }, [cutMethod, updateMap]);

    React.useEffect(() => {
      try {
        if (!preHeatReqForCladdingMin && isPreHeatReqForCladdingChecked) {
          const defaultCladdingTemp = getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.TemperatureRequiredForCladding, manufacturingSite, FieldTypes.Text) as string;
          setPreHeatReqForCladdingMin(defaultCladdingTemp);
        }
        if (!preHeatReqForCuttingMin && isPreHeatReqForCuttingChecked) {
          const defaultCuttingTemp = getQuoteVariableConfiguration(quoteVariableConfigurations, QuoteVariableConfigDescriptions.TemperatureRequiredForCutting, manufacturingSite, FieldTypes.Text) as string;
          setPreHeatReqForCuttingMin(defaultCuttingTemp);
        }
      } catch(error) {
        console.log(error);
      }
    }, [isPreHeatReqForCladdingChecked, isPreHeatReqForCuttingChecked]);

    React.useEffect(() => {
      try {
        //machining uom description change
        let description;
        switch (itemType.name) {
          case FinishedProductTypes.Tubesheet:
            description = isDisplayMetric
              ? QuoteVariableConfigDescriptions.MachiningTubesheetMetric
              : QuoteVariableConfigDescriptions.MachiningTubesheetImperial;
            break;
          case FinishedProductTypes.Head:
          case FinishedProductTypes.Plate:
          default:
            description = isDisplayMetric
            ? QuoteVariableConfigDescriptions.MachiningNonTubesheetMetric
            : QuoteVariableConfigDescriptions.MachiningNonTubesheetImperial;
        }
        let previousDescription;
        switch (itemType.name) {
          case FinishedProductTypes.Tubesheet:
            previousDescription = isDisplayMetric
              ? QuoteVariableConfigDescriptions.MachiningTubesheetImperial
              : QuoteVariableConfigDescriptions.MachiningTubesheetMetric;
            break;
          case FinishedProductTypes.Head:
          case FinishedProductTypes.Plate:
          default:
            previousDescription = isDisplayMetric
            ? QuoteVariableConfigDescriptions.MachiningNonTubesheetImperial
            : QuoteVariableConfigDescriptions.MachiningNonTubesheetMetric;
        }

        const valuePhrase = getQuoteVariableConfiguration(
          quoteVariableConfigurations,
          description,
          manufacturingSite,
          FieldTypes.Text
        ) as string;
        const previousValuePhrase = getQuoteVariableConfiguration(
          quoteVariableConfigurations,
          previousDescription,
          manufacturingSite,
          FieldTypes.Text
        ) as string;

        //flattening uom description change
        let previousDescriptionFlattening;
        switch (flattening) {
        case FlatteningSelections.StandardPlateFlatness:
        default:
          previousDescriptionFlattening = isDisplayMetric
          ? QuoteVariableConfigDescriptions.FlatteningPlateImperial
          : QuoteVariableConfigDescriptions.FlatteningPlateMetric;
          break;
        case FlatteningSelections.StandardTubesheetFlatness:
          previousDescriptionFlattening = isDisplayMetric
          ? QuoteVariableConfigDescriptions.FlatteningTubesheetImperial
          : QuoteVariableConfigDescriptions.FlatteningTubesheetMetric;
          break;
        case FlatteningSelections.None:
          previousDescriptionFlattening = QuoteVariableConfigDescriptions.FlatteningNone;
          break;
        case FlatteningSelections.Other:
          previousDescriptionFlattening = QuoteVariableConfigDescriptions.FlatteningOther;
          break;
        }

        const previousValuePhraseFlattening = getQuoteVariableConfiguration(
          quoteVariableConfigurations,
          previousDescriptionFlattening,
          manufacturingSite,
          FieldTypes.Text
        ) as string;

        //cutting uom description change
        const previousDescriptionCutting = isDisplayMetric
          ? QuoteVariableConfigDescriptions.CuttingValuePhraseImperial
          : QuoteVariableConfigDescriptions.CuttingValuePhraseMetric;

        const previousValuePhraseCutting = getQuoteVariableConfiguration(
          quoteVariableConfigurations,
          previousDescriptionCutting,
          manufacturingSite,
          FieldTypes.Text
        ) as string;

        const previousUom = uom === MeasurementSystems.Imperial ? MeasurementSystems.Metric : MeasurementSystems.Imperial;

        //machinging variables
        const finishedLength = uom === MeasurementSystems.Imperial ? displayedQuoteLine.Length_in__c : displayedQuoteLine.Length_mm__c;
        const previousFinishedLength = previousUom === MeasurementSystems.Imperial ? displayedQuoteLine.Length_in__c : displayedQuoteLine.Length_mm__c;
        const previousDefaultMachiningNotes = getDefaultMachiningNotes(itemType, previousUom, previousFinishedLength, previousValuePhrase);
        const updatedDefaultMachiningNotes = getDefaultMachiningNotes(itemType, uom, finishedLength, valuePhrase);

        //flattening variables
        let previousThickness = previousUom === MeasurementSystems.Imperial ? displayedQuoteLine.Total_TK_IN__c : displayedQuoteLine.Total_TK_MM__c;
        if (itemType.name === FinishedProductTypes.Tubesheet) {
          const width: number = previousUom === MeasurementSystems.Imperial ? displayedQuoteLine.Width_in__c : displayedQuoteLine.Width_mm__c;
          const measuringSystemName = previousUom === MeasurementSystems.Imperial ? MeasurementSystems.Imperial.toLowerCase() : MeasurementSystems.Metric.toLowerCase();
          previousThickness = getFlatteningThinningGroup(baseMetal, manufacturingSite, previousUom, previousThickness, width)[`${measuringSystemName}`].ftFactorWidth;
        }
        const previousDefaultFlatteningNotes = getDefaultFlatteningNotes(itemType, previousUom, previousThickness, previousValuePhraseFlattening);

        //cutting tolerance variables
        const previousLength = previousUom === MeasurementSystems.Imperial ? displayedQuoteLine.Length_in__c : displayedQuoteLine.Length_mm__c;
        const previousDefaultCuttingToleranceNotes = getDefaultCuttingToleranceNotes(itemType, cutMethod, previousThickness, previousLength, previousUom, previousValuePhraseCutting);

        // only set the default machiningNotes if the user did not change the value manually
        if(machiningNotes === previousDefaultMachiningNotes) {
          setMachiningNotes(updatedDefaultMachiningNotes ?? '');
        }

        //sets flattening notes with toggle flip if no edits have been made
        if (flattening === FlatteningSelections.StandardPlateFlatness || flattening === FlatteningSelections.StandardTubesheetFlatness) {
          console.log('set flatenning notes if true ==', flatteningNotes === previousDefaultFlatteningNotes);
          if(flatteningNotes === previousDefaultFlatteningNotes) {
            flatteningNotesDefault(flattening);
          }
        }

        //sets cutting notes with toggle flip if no edits have been made
        if (cutMethod !== CutMethods.Uncut) {
          if(cuttingNotes === previousDefaultCuttingToleranceNotes) {
            cuttingNotesDefault(cutMethod);
          }
        }

      } catch(error) {
        console.log(error);
      }
    }, [uom]);

    React.useEffect(() => {
      try {
        let description;
        switch (itemType.name) {
          case FinishedProductTypes.Tubesheet:
            description = isDisplayMetric
              ? QuoteVariableConfigDescriptions.MachiningTubesheetMetric
              : QuoteVariableConfigDescriptions.MachiningTubesheetImperial;
            break;
          case FinishedProductTypes.Head:
          case FinishedProductTypes.Plate:
          default:
            description = isDisplayMetric
            ? QuoteVariableConfigDescriptions.MachiningNonTubesheetMetric
            : QuoteVariableConfigDescriptions.MachiningNonTubesheetImperial;
        }
        const valuePhrase = getQuoteVariableConfiguration(
          quoteVariableConfigurations,
          description,
          manufacturingSite,
          FieldTypes.Text
        ) as string;
        const finishedLength = uom === MeasurementSystems.Imperial ? displayedQuoteLine.Length_in__c : displayedQuoteLine.Length_mm__c;
        const updatedDefaultMachiningNotes = getDefaultMachiningNotes(itemType, uom, finishedLength, valuePhrase);

        // only set the default machiningNotes if notes are blank
        if(!machiningNotes) {
          setMachiningNotes(updatedDefaultMachiningNotes ?? '');
        }

        if(machining === MachiningTypes.None || !machining) {
          setMachiningNotes('');
        }
      } catch(error) {
        console.log(error);
      }
    }, [machining]);

    React.useEffect(() => {
      try {
        const updatedNCInHouseStressReliefDetails = getStressReliefDetails(uom, +NCHouseStressReliefMin, +NCHouseStressReliefMax, +NCHouseStressReliefTemp);

        if(itemType.name === FinishedProductTypes.Head) {
          setSimulateHeatTreatmentHeadForming(updatedNCInHouseStressReliefDetails);
        } else {
          setNCHouseStressReliefDetails(updatedNCInHouseStressReliefDetails);
        }
      } catch(error) {
        console.log(error);
      }
    }, [uom, NCHouseStressReliefMin, NCHouseStressReliefMax, NCHouseStressReliefTemp]);

    React.useEffect(() => {
      try {
        // if the machining is undefined and item type is a tubesheet, set the default machining using quote line tubesheet machining from add item
        if (!machining && itemType.name === FinishedProductTypes.Tubesheet) {
          setMachining(displayedQuoteLine.TubesheetMachining__c);
        }
      } catch(error) {
        console.log(error);
      }
    }, []);

    return (
      <>
        <IonGrid>
          <IonRow>
            <IonCol size='2.8'>
              <CheckboxItem
                label='NC House Stress Relief'
                style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
                onIonChange={checked =>
                  setIsNCHouseStressReliefChecked(checked)
                }
                checked={isNCHouseStressReliefChecked}
              />
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.IntegerAboveZero}
                min='1'
                style={{ width: '78px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(min: React.SetStateAction<string>) => setNCHouseStressReliefMin(min)}
                value={NCHouseStressReliefMin}
              />
              <IonLabel class='selection-input-sub-label'>Minimum Minutes</IonLabel>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.IntegerAboveZero}
                min='1'
                style={{ width: '78px', marginLeft: '20px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(max: React.SetStateAction<string>) => setNCHouseStressReliefMax(max)}
                value={NCHouseStressReliefMax}
              />
              <IonLabel class='selection-input-sub-label'>Maximum Minutes</IonLabel>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.Temperature}
                min='1'
                style={{ width: '87px', marginLeft: '20px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(temp: React.SetStateAction<string>) => setNCHouseStressReliefTemp(temp)}
                value={calculateUOMValue(displayedQuoteDetail.AdvancedSpecUOM__c, uom, +NCHouseStressReliefTemp, Units.Celsius, Units.Fahrenheit)}
                formatter={(value) => {
                  return value !== '' ? value.toFixed(1) : '';
                }}
              />
              <IonLabel class='selection-input-sub-label'>{`Temp (°${uomTempLabel})`}</IonLabel>
            </IonCol>
            <IonCol>
              <CheckboxItem
                label='Simulate at Vendor'
                style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
                onIonChange={checked =>
                  setIsNCHouseStressReliefVendorChecked(checked)
                }
                checked={isNCHouseStressReliefVendorChecked}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <CheckboxItem
                label='Pre Heat Req For Cladding'
                style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
                onIonChange={checked =>
                  setIsPreHeatReqForCladdingChecked(checked)
                }
                checked={isPreHeatReqForCladdingChecked}
              />
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.Temperature}
                min='1'
                step='1'
                style={{ width: '78px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(min: React.SetStateAction<string>) => setPreHeatReqForCladdingMin(min)}
                value={calculateUOMValue(displayedQuoteDetail.AdvancedSpecUOM__c, uom, +preHeatReqForCladdingMin, Units.Celsius, Units.Fahrenheit)}
                formatter={(value) => {
                  return value !== '' ? (+value).toFixed(1) : '';
                }}
              />
              <IonLabel class='selection-input-sub-label'>{`Minimum Temp (°${uomTempLabel})`}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <CheckboxItem
                label='Pre Heat Req For Cutting'
                style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
                onIonChange={checked =>
                  setIsPreHeatReqForCuttingChecked(checked)
                }
                checked={isPreHeatReqForCuttingChecked}
              />
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.Temperature}
                min='1'
                step='1'
                style={{ width: '78px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(min: React.SetStateAction<string>) => setPreHeatReqForCuttingMin(min)}
                value={calculateUOMValue(displayedQuoteDetail.AdvancedSpecUOM__c, uom, +preHeatReqForCuttingMin, Units.Celsius, Units.Fahrenheit)}
                formatter={(value) => {
                  return value !== '' ? (+value).toFixed(1) : '';
                }}
              />
              <IonLabel class='selection-input-sub-label'>{`Minimum Temp (°${uomTempLabel})`}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label'>Flattening:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select Flattening'
                options={
                  getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.PostCladFlattening)?.options
                }
                style={{ width: '320px', marginTop: '0px' }}
                onChange={(flatteningSelection: { label: React.SetStateAction<string> }) => {
                  flatteningNotesDefault(flatteningSelection.label);
                  setFlattening(flatteningSelection.label);
                }}
                value={flattening}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8' />
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextArea}
                placeholder='Flattening Notes...'
                style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto' }}
                rows={6}
                onBlur={(flatteningNotesValue: React.SetStateAction<string>) =>
                  setFlatteningNotes(flatteningNotesValue)
                }
                value={flatteningNotes}
                textAreaType='Additional Flattening Notes'
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label'>Cutting:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select Cutting'
                options={itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.CutMethod)?.options}
                style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
                onChange={(cuttingSelection: { label: React.SetStateAction<string> }) => {
                  cuttingNotesDefault(cuttingSelection.label);
                  setCutMethod(cuttingSelection.label);
                }}
                value={cutMethod}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                placeholder='Specify Other Details...'
                inputType='text'
                style={{ width: '430px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(notes: React.SetStateAction<string>) => setCuttingNotes(notes)}
                value={cuttingNotes}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label'>Machining:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select Machining'
                options={
                  getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.Machining)?.options
                }
                style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
                onChange={(machiningSelection: { label: React.SetStateAction<string> }) =>
                  setMachining(machiningSelection.label)
                }
                value={machining}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                placeholder='Specify Other Details...'
                inputType='text'
                style={{ width: '430px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(notes: React.SetStateAction<string>) => setMachiningNotes(notes)}
                value={machiningNotes}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label'>Surface Treatment:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select Surface Treatment'
                options={getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.SurfaceTreatment)?.options.map(option => option.name)}
                style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
                onChange={(surfaceTreatmentSelection: { label: React.SetStateAction<string> }) =>
                  setSurfaceTreatment(surfaceTreatmentSelection.label)
                }
                defaultValue={hasCosmeticGrinding ? DefaultSurfaceTreatmentSelections.CosmeticGrinding : DefaultSurfaceTreatmentSelections.Standard }
                value={surfaceTreatment}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                placeholder='Specify Other Details...'
                inputType='text'
                style={{ width: '430px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(notes: React.SetStateAction<string>) => setSurfaceTreatmentNotes(notes)}
                value={surfaceTreatmentNotes}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label'>NobelClad Inhouse Packaging:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select Packaging'
                options={
                  getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NCInHousePackaging)?.options
                }
                style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
                onChange={(inhousePackagingSelection: { label: React.SetStateAction<string> }) =>
                  setInhousePackaging(inhousePackagingSelection.label)
                }
                defaultValue={getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.NCInHousePackaging)?.default}
                value={inhousePackaging}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                placeholder='Specify Other Details...'
                inputType='text'
                style={{ width: '430px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(notes: React.SetStateAction<string>) => setInhousePackagingNotes(notes)}
                value={inhousePackagingNotes}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  };

  /** (Cylinder + Can Rolling) */
  const CanRollingSection = (displayedQuoteLine: QuoteLineItem, displayedQuoteDetail: QuoteLineItemDetail) => {
    const displayedQuoteDetailId = displayedQuoteDetail.Id || displayedQuoteDetail.associatedLineItem;
    const updateMap = assemblyGroupCylinderDetailFieldsToUpdate;

    const itemType = finishedGoods.find(
      (finishedGood: { name: string; dataAreaId: ManufacturingSites }) =>
        finishedGood.name === displayedQuoteLine?.Item_Type__c && finishedGood.dataAreaId === manufacturingSite
    );

    const [cylinderType, setCylinderType] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'cylinderType');
    const [diameter, setDiameter] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'diameter');
    const [diameterLocation, setDiameterLocation] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'innerDiameterOrOuterDiameter');
    const [cylinderEdgePrep, setCylinderEdgePrep] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'edgePreparation');
    const [rollingCostPerCylinder, setRollingCostPerCylinder] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'rollingCostPerCylinder');
    const [freightChargeToCustomer, setFreightChargeToCustomer] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'freightChargeToCustomerPer');
    const [freightChargeToRoller, setFreightChargeToRoller] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'freightChargeToRollerPer');
    const [transitTimeToRollerWeeks, setTransitTimeToRollerWeeks] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'transitTimeToRollerWeeks');
    const [timeToRollWeeks, setTimeToRollWeeks] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'timeToRollWeeks');
    const [quantityPerCylinder, setQuantityPerCylinder] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'quantityPerCylinder');
    const [length, setLength] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'plateLengthSupplyToFormer');
    const [width, setWidth] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'plateWidthSupplyToFormer');
    const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'cylinderNotes');
    const [vendor, setVendor] = useMapSetState<string>(updateMap, displayedQuoteDetailId, 'cylinderVendor');

    return (
      <>
        <IonGrid>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Cylinder Type:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select type'
                options={
                  itemType?.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.CylinderType)
                    ?.options
                }
                style={{ width: '200px', marginRight: '20px' }}
                onChange={(cylinderType) => setCylinderType(cylinderType.label)}
                value={cylinderType}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Diameter</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='1'
                regex={Regexes.IntegerAboveZero}
                min='1'
                style={{ width: '78px', marginRight: '10px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(diameter) => setDiameter(diameter)}
                value={diameter}
              />
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                itemStyle={{ ['--background']: 'transparent' }}
                options={itemType?.selections.find((selection) => selection.valueType === QuoteLineItemValueTypes.IDorOD)?.options}
                onIonChange={(location) => setDiameterLocation(location)}
                value={diameterLocation}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Edge Preparation:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                placeholder='Select type'
                options={
                  itemType?.selections.find(
                    (selection) => selection.valueType === QuoteLineItemValueTypes.EdgePreparation
                  )?.options
                }
                style={{ width: '200px', marginRight: '20px' }}
                onChange={(cylinderEdgePrep) => setCylinderEdgePrep(cylinderEdgePrep.label)}
                value={cylinderEdgePrep}
              />
            </IonCol>
          </IonRow>
          <IonRow style={{ marginTop: '20px' }}>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os bold'>{'Cost & Shipping'}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Rolling Cost Per Cylinder:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.DecimalNumber}
                style={{ width: '134px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(rollingCost: React.SetStateAction<string>) => setRollingCostPerCylinder(rollingCost)}
                value={rollingCostPerCylinder}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Freight Charge to Customer:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.DecimalNumber}
                style={{ width: '134px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(freightCharge: React.SetStateAction<string>) => setFreightChargeToCustomer(freightCharge)}
                value={calculateUOMValue(displayedQuoteDetail.AdvancedSpecUOM__c, uom, +freightChargeToCustomer, Units.PricePerKilogram, Units.PricePerPound)}
              />
              <IonLabel class='selection-input-sub-label'>Per {kgOrLbString}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Freight Charge to Roller:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.DecimalNumber}
                style={{ width: '134px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(freightCharge: React.SetStateAction<string>) => setFreightChargeToRoller(freightCharge)}
                value={calculateUOMValue(displayedQuoteDetail.AdvancedSpecUOM__c, uom, +freightChargeToRoller, Units.PricePerKilogram, Units.PricePerPound)}
              />
              <IonLabel class='selection-input-sub-label'>Per {kgOrLbString}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Transit Time to Roller:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.PositiveInteger}
                min='0'
                style={{ width: '78px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(time: React.SetStateAction<string>) => setTransitTimeToRollerWeeks(time)}
                value={transitTimeToRollerWeeks}
              />
              <IonLabel class='selection-input-sub-label'>weeks</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Time to Roll:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.PositiveInteger}
                min='0'
                style={{ width: '78px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(time: React.SetStateAction<string>) => setTimeToRollWeeks(time)}
                value={timeToRollWeeks}
              />
              <IonLabel class='selection-input-sub-label'>weeks</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow style={{ marginTop: '20px' }}>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os bold'>Plate Sizes Supply Former</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Vendor</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                noneFoundText='No vendors found'
                placeholder='Select Vendor'
                options={getVendors(vendors, manufacturingSite, OutsideServices.CanRolling).map((v) => v.vendorName)}
                style={{ width: '200px' }}
                onChange={(vendorSelection) => setVendor((vendors.find((v) => v.vendorName === vendorSelection.label))?.vendorId)}
                value={vendors?.find((v) => v.vendorId === vendor)?.vendorName}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Quantity Per Cylinder:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='Quantity'
                regex={Regexes.PositiveInteger}
                min='0'
                style={{ width: '200px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(quantity: React.SetStateAction<string>) => setQuantityPerCylinder(quantity)}
                value={quantityPerCylinder}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os'>Width, Length:</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.DecimalNumber}
                min='1'
                style={{ width: '78px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(w: React.SetStateAction<string>) => setWidth(w)}
                value={width}
              />
              <IonLabel class='selection-input-sub-label'>x</IonLabel>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0'
                regex={Regexes.DecimalNumber}
                min='1'
                style={{ width: '78px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(l: React.SetStateAction<string>) => setLength(l)}
                value={length}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label-os' style={{ marginTop: '10px' }}>
                Additional Notes
              </IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextArea}
                placeholder='Comments...'
                style={{ width: '720px', marginTop: 'auto', marginBottom: 'auto' }}
                rows={6}
                onBlur={(additionalNotesValue: React.SetStateAction<string>) =>
                  setAdditionalNotes(additionalNotesValue)
                }
                value={additionalNotes}
                textAreaType='Additional Notes'
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  };

  const HeadFormingSection = (displayedQuoteLine: QuoteLineItem, displayedQuoteDetail: QuoteLineItemDetail) => {
    const displayedQuoteDetailId = displayedQuoteDetail.Id || displayedQuoteDetail.associatedLineItem;

    const headFormingSupplierOptions = getSelectionOptionsWithNameAndValue(selections, manufacturingSite, QuoteLineItemValueTypes.HeadFormingSupplier).options;
    const [headFormingVendorName, setHeadFormingVendorName ] = useState<string>(headFormingSupplierOptions.find((o) => o.value === assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headFormingVendor)?.name);

    const itemType: FinishedGood = finishedGoods.find(finishedGood => finishedGood.name === displayedQuoteLine.Item_Type__c && finishedGood.dataAreaId === manufacturingSite);
    const [headFormingType, setHeadFormingType] = useState<HeadFormingTypes>(assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headFormingType);
    const [popoverState, setShowPopover] = React.useState({ showPopover: false, event: undefined });
    const [cycles, setCycles] = useState<string[]>(assemblyGroupOutsideServiceItemFieldsToUpdate?.get(displayedQuoteDetailId)?.simulationHTCycle1 ?
      [
        assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulationHTCycle1,
        assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulationHTCycle2,
        assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulationHTCycle3,
        assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulationHTCycle4,
        assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulationHTCycle5
      ] : []
    );

    interface CycleData {
      cycleNum: number;
      numMins: number;
      numDeg: number;
      degreeType: string;
      airCooled: boolean;
      waterQuenched: boolean;
    }

    const extractCycleData = (input: string): CycleData => {
      const cycleNum = Number(input?.match(/^Cycle (\d+):/)?.[1] ?? 0);
      const numMins = Number(input?.match(/(\d+) minutes at/)?.[1] ?? 0);
      const numDeg = Number(input?.match(/at (\d+)°F/)?.[1] ?? 0) || Number(input?.match(/at (\d+)°C/)?.[1] ?? 0);
      const degreeType = String(input?.match(/(?<=°).{1}/)?.[0] ?? '');
      const airCooled = input?.includes('Air Cooled: True');
      const waterQuenched = input?.includes('Water Quenched: True');

      return {
        cycleNum,
        numMins,
        numDeg,
        degreeType,
        airCooled,
        waterQuenched,
      };
    };

    const parseCyclesForPONotes = () => {
      return cycles?.map((cycleString, index) => {
        console.log('cycles: ', cycles);
        const cycleData = extractCycleData(cycleString);

        if(index === 0) {
          return `Total time and temperature allowed for Forming Cycle(s): ${cycleData.numDeg} Deg. ${cycleData.degreeType}, Hold ${cycleData.numMins} minutes. ${cycleData.airCooled ? 'Air Cool.' : ''} ${cycleData.waterQuenched ? 'Water Quench.' : ''}`;
        } else {
          return `Post forming Heat Treatment ${cycleData.cycleNum - 1}: ${cycleData.numDeg} Deg. ${cycleData.degreeType}, Hold ${cycleData.numMins} minutes. ${cycleData.airCooled ? 'Air Cool.' : ''} ${cycleData.waterQuenched ? 'Water Quench.' : ''}`;
        }
      });
    };

    const [localHeadQuantity, setLocalHeadQuantity] = React.useState(undefined);
    const [localHeadFormingCostPerHead, setLocalHeadFormingCostPerHead] = React.useState(undefined);
    const [localNobelCladProcedure, setLocalNobelCladProcedure] = React.useState(undefined);
    const [localBlankOD, setLocalBlankOD] = React.useState(undefined);
    const [localHeadNumberOfPieces, setLocalHeadNumberOfPieces] = React.useState(undefined);
    const [localStraightFlange, setLocalStraightFlange] = React.useState(undefined);
    const [localOpenEnd, setLocalOpenEnd] = React.useState(undefined);
    const [localHeadContour, setLocalHeadContour] = React.useState(undefined);
    const [localCycles, setLocalCycles] = React.useState(parseCyclesForPONotes());
    const [localMaxCenterHole, setLocalMaxCenterHole] = React.useState(undefined);
    const [localCladMetalPreference, setLocalCladMetalPreference] = React.useState(undefined);

    React.useEffect(() => {
      assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headFormingType = headFormingType;
    }, [headFormingType]);

    React.useEffect(() => {
      if(displayedQuoteDetailId && assemblyGroupHeadFieldsToUpdate) {
        setLocalHeadQuantity(assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headQuantity);
        setLocalHeadFormingCostPerHead(assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headFormingCostPerHead);
        setLocalNobelCladProcedure(assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).nobelCladProcedure);
        setLocalBlankOD(assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).blankSize);
        setLocalHeadNumberOfPieces(assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headNumberOfPieces);
        setLocalStraightFlange(assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).straightFlange);
        setLocalOpenEnd(`${assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).openEnd} ${assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).openEndNotes}`);
        setLocalMaxCenterHole(assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headMaxCenterHole);
      }
    }, [displayedQuoteDetailId, assemblyGroupHeadFieldsToUpdate]);

    React.useEffect(() => {
      if(displayedQuoteDetailId && assemblyGroupOutsideServiceItemFieldsToUpdate) {
        setLocalHeadContour(assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headContourToleranceHTOptions || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.HeatContourTolerancesAndHeatTreatmentOptions)?.default);
        setLocalCladMetalPreference(assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headFormingIDvsOD);
      }
    }, [displayedQuoteDetailId, assemblyGroupOutsideServiceItemFieldsToUpdate]);

    React.useEffect(() => {
      setLocalCycles(parseCyclesForPONotes());
    }, [cycles]);

    React.useEffect(() => {
      if(displayedQuoteLine && displayedQuoteDetail && displayedQuoteDetailId && metalComboDetailFieldsToUpdate) {
        const isQuoteMetric = displayedQuoteLine.Unit_of_Measure__c === MeasurementSystems.Metric ? true : false;
        const currencyCode = displayedQuoteLine.CurrencyIsoCode ?? 'USD';

        const updatedPONotesVariables: PONotesVariables = {
          quoteName: quote.Name,
          cladMetal: cladMetal?.metalLongName,
          baseMetal: baseMetal?.metalLongName,
          cladNom: isQuoteMetric ? displayedQuoteLine.Clad_Nom_mm__c.toFixed(3) : displayedQuoteLine.Clad_Nom_in__c.toFixed(3),
          cladMin: isQuoteMetric ? displayedQuoteLine.Clad_Min_mm__c.toFixed(3) : displayedQuoteLine.Clad_Min_in__c.toFixed(3),
          baseNom: isQuoteMetric ? displayedQuoteLine.Base_Nom_mm__c.toFixed(3) : displayedQuoteLine.Base_Nom_in__c.toFixed(3),
          baseMin: isQuoteMetric ? displayedQuoteLine.Base_Min_mm__c.toFixed(3) : displayedQuoteLine.Base_Min_in__c.toFixed(3),
          baseMetalFinishedGoodHeatTreatment: displayedQuoteDetail.FinishedGoodHeatTreatment__c,
          postBondUTSpecification: displayedQuoteDetail.PostCladBondUTSpecification__c,
          shipWeight: isQuoteMetric ? `${displayedQuoteLine.Ship_Weight_KG__c.toFixed(0)} KG` : `${displayedQuoteLine.Ship_Weight_LB__c.toFixed(0)} LB`,
          headDescription: isQuoteMetric ? displayedQuoteLine.ItemDescriptionImp__c : displayedQuoteLine.ItemDescriptionMetric__c,
          postCladBaseMetalUTSpecification: displayedQuoteDetail.PostCladBaseMetalUTSpecification__c,
          cladProductionSpecification: metalComboDetailFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladProductionSpecification === CladProductionSpecifications.OtherManualEntry ? metalComboDetailFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladProductionSpecificationNotes : metalComboDetailFieldsToUpdate[displayedQuoteGroup.metalComboKey].cladProductionSpecification,
          headQuantity: `${localHeadQuantity}`,
          headFormingCostPerHead: `${formatCurrency(localHeadFormingCostPerHead as number, currencyCode, 2)}`,
          headContour: localHeadContour,
          nobelCladProcedure: localNobelCladProcedure,
          heatTreatCycles: localCycles,
          blankOD: `${localBlankOD} ${isQuoteMetric ? 'MM' : 'IN'} O.D.`,
          numPiecesDescription: localHeadNumberOfPieces === 1 ? NumberOfPiecesInHeadOptions.SinglePieceHead : NumberOfPiecesInHeadOptions.TwoPieceHead,
          straightFlange: `${localStraightFlange}`,
          openEnd: localOpenEnd,
          maxCenterHole: localMaxCenterHole,
          cladMetalPreference: localCladMetalPreference
        };

        const notes = getPONotesForSalesforce(updatedPONotesVariables);
        assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).poNotes = notes;
      }
    }, [displayedQuoteLine, displayedQuoteDetail, displayedQuoteDetailId, metalComboDetailFieldsToUpdate, localHeadQuantity, localHeadFormingCostPerHead, localNobelCladProcedure, localBlankOD, localHeadNumberOfPieces, localStraightFlange, localOpenEnd, localHeadContour, localCycles, localMaxCenterHole, localCladMetalPreference]);

    const CyclesSection = () => {
      const cycleLabel = 'Cycle';
      const cycleNums = [1,2,3,4,5];
      const [cyclesMap, setCyclesMap] = useState<Map<number, { minutes: number, temperature: number, tempUOM: TempUOMs, type: CycleTypes}>>(new Map());

      React.useEffect(() => {
        try {
          const newCyclesMap = new Map();

          (cycles).forEach((cycle) => {
            if(cycle) {
              let cycleSpaceIndex = cycle.indexOf(' ');
              let cycleColonIndex = cycle.indexOf(':');

              const cycleNumber = +cycle.substring(cycleSpaceIndex + 1, cycleColonIndex);

              cycleSpaceIndex = cycle.indexOf(' ', cycleColonIndex);
              let cycleSecondSpaceIndex = cycle.indexOf(' ', cycleSpaceIndex + 1);

              const cycleMinutes = +cycle.substring(cycleSpaceIndex + 1, cycleSecondSpaceIndex);

              const atIndex = cycle.indexOf('at');
              cycleSpaceIndex = cycle.indexOf(' ', atIndex);
              const cycleDegreeIndex = cycle.indexOf('°');

              const cycleTemperature = +cycle.substring(cycleSpaceIndex + 1, cycleDegreeIndex);

              cycleSecondSpaceIndex = cycle.indexOf(' ', cycleDegreeIndex);
              const cycleTempUOM = cycle.substring(cycleDegreeIndex + 1, cycleSecondSpaceIndex);

              cycleColonIndex = cycle.indexOf(':', cycleDegreeIndex);
              cycleSpaceIndex = cycle.indexOf(' ', cycleColonIndex);
              cycleSecondSpaceIndex = cycle.indexOf(' ', cycleSpaceIndex + 1);

              let cycleType;
              if(cycle.substring(cycleSpaceIndex + 1, cycleSecondSpaceIndex) === BooleanStrings.True) {
                cycleType = CycleTypes.AirCool;
              }

              cycleColonIndex = cycle.indexOf(':', cycleSecondSpaceIndex);
              cycleSpaceIndex = cycle.indexOf(' ', cycleColonIndex);

              if(cycle.substring(cycleSpaceIndex + 1) === BooleanStrings.True) {
                cycleType = CycleTypes.WaterQuench;
              }

              newCyclesMap.set(cycleNumber, { minutes: cycleMinutes, temperature: cycleTemperature, tempUOM: cycleTempUOM as TempUOMs, type: cycleType });
            }
          });

          setCyclesMap(newCyclesMap);

          cycleNums.forEach(cycleNum => {
            const cycleString = (cycles)[cycleNum - 1] || '';

            switch(cycleNum) {
              case 1:
                assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulationHTCycle1 = cycleString;
                break;
              case 2:
                assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulationHTCycle2 = cycleString;
                break;
              case 3:
                assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulationHTCycle3 = cycleString;
                break;
              case 4:
                assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulationHTCycle4 = cycleString;
                break;
              case 5:
                assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulationHTCycle5 = cycleString;
                break;
            }
          });
        } catch(error) {
          console.log(error);
        }
      }, [cycles]);

      const updateCyclesRequired = (cycleNumUpdated: number, selected: boolean) => {
        const newCyclesArray = [];

        cycleNums.filter(cycleNum => selected ? cycleNum <= cycleNumUpdated : cycleNum < cycleNumUpdated).forEach(cycleNumToAdd => {
          const cycle = cyclesMap.get(cycleNumToAdd);

          newCyclesArray.push(`${cycleLabel} ${cycleNumToAdd}: ${cycle?.minutes || 0} minutes at ${cycle?.temperature || 0}°${cycle?.tempUOM || TempUOMs.F} Air Cooled: ${cycle?.type === CycleTypes.AirCool ? BooleanStrings.True : BooleanStrings.False} Water Quenched: ${cycle?.type === CycleTypes.WaterQuench ? BooleanStrings.True : BooleanStrings.False}`);
        });

        setCycles([...newCyclesArray]);
      };

      const updateCycleMinutes = (cycleNumUpdated: number, minutes: number) => {
        const newCyclesArray = [];

        [...cyclesMap.keys()].forEach(cycleNum => {
          const cycle = cyclesMap.get(cycleNum);

          newCyclesArray.push(`${cycleLabel} ${cycleNum}: ${(cycleNum !== cycleNumUpdated ? cycle?.minutes : minutes)} minutes at ${cycle?.temperature}°${cycle?.tempUOM} Air Cooled: ${cycle?.type === CycleTypes.AirCool ? BooleanStrings.True : BooleanStrings.False} Water Quenched: ${cycle?.type === CycleTypes.WaterQuench ? BooleanStrings.True : BooleanStrings.False}`);
        });

        setCycles([...newCyclesArray]);
      };

      const updateCycleTemperatures = (cycleNumUpdated: number, temperature: number) => {
        const newCyclesArray = [];

        [...cyclesMap.keys()].forEach(cycleNum => {
          const cycle = cyclesMap.get(cycleNum);

          newCyclesArray.push(`${cycleLabel} ${cycleNum}: ${cycle?.minutes} minutes at ${(cycleNum !== cycleNumUpdated ? cycle?.temperature : temperature)}°${cycle?.tempUOM} Air Cooled: ${cycle?.type === CycleTypes.AirCool ? BooleanStrings.True : BooleanStrings.False} Water Quenched: ${cycle?.type === CycleTypes.WaterQuench ? BooleanStrings.True : BooleanStrings.False}`);
        });

        setCycles([...newCyclesArray]);
      };

      const updateCycleTempUOMs = (cycleNumUpdated: number, tempUOM: string) => {
        const newCyclesArray = [];

        [...cyclesMap.keys()].forEach(cycleNum => {
          const cycle = cyclesMap.get(cycleNum);

          newCyclesArray.push(`${cycleLabel} ${cycleNum}: ${cycle?.minutes} minutes at ${cycle?.temperature}°${(cycleNum !== cycleNumUpdated ? cycle?.tempUOM : tempUOM)} Air Cooled: ${cycle?.type === CycleTypes.AirCool ? BooleanStrings.True : BooleanStrings.False} Water Quenched: ${cycle?.type === CycleTypes.WaterQuench ? BooleanStrings.True : BooleanStrings.False}`);
        });

        setCycles([...newCyclesArray]);
      };

      const updateCycleTypes = (cycleNumUpdated: number, cycleType: string) => {
        const newCyclesArray = [];

        [...cyclesMap.keys()].forEach(cycleNum => {
          const cycle = cyclesMap.get(cycleNum);

          newCyclesArray.push(`${cycleLabel} ${cycleNum}: ${cycle?.minutes} minutes at ${cycle?.temperature}°${cycle?.tempUOM} Air Cooled: ${(cycleNum !== cycleNumUpdated ? cycle?.type : cycleType) === CycleTypes.AirCool ? BooleanStrings.True : BooleanStrings.False} Water Quenched: ${(cycleNum !== cycleNumUpdated ? cycle?.type : cycleType) === CycleTypes.WaterQuench ? BooleanStrings.True : BooleanStrings.False}`);
        });

        setCycles([...newCyclesArray]);
      };

      const CycleRow = (props: {
        cycleNum: number
      }) => {

        return (
          <IonRow>
            <IonCol size='2.8'>
              <CheckboxItem
                label={`Cycle ${props.cycleNum}`}
                style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
                onIonChange={checked => {
                  try {
                    updateCyclesRequired(props.cycleNum, checked);
                  } catch(error) {
                    console.log(error);
                  }
                }}
                checked={[...cyclesMap.keys()].includes(props.cycleNum)}
              />
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='1'
                regex={Regexes.IntegerAboveZero}
                min='1'
                style={{ width: '90px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={cycleMinutes => {
                  try {
                    updateCycleMinutes(props.cycleNum, cycleMinutes);
                  } catch(error) {
                    console.log(error);
                  }
                }}
                value={cyclesMap.get(props.cycleNum)?.minutes}
                isDisabled={![...cyclesMap.keys()].includes(props.cycleNum)}
              />
              <IonLabel class='selection-input-sub-label'>minutes at</IonLabel>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='1'
                regex={Regexes.IntegerAboveZero}
                min='1'
                style={{ width: '90px', marginRight: '10px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={cycleTemperature => {
                  try {
                    updateCycleTemperatures(props.cycleNum, cycleTemperature);
                  } catch(error) {
                    console.log(error);
                  }
                }}
                value={cyclesMap.get(props.cycleNum)?.temperature}
                isDisabled={![...cyclesMap.keys()].includes(props.cycleNum)}
              />
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                itemStyle={{ ['--background']: 'transparent' }}
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CycleTempUOM)?.options}
                onIonChange={cycleTempUOM => {
                  try {
                    updateCycleTempUOMs(props.cycleNum, cycleTempUOM);
                  } catch(error) {
                    console.log(error);
                  }
                }}
                value={cyclesMap.get(props.cycleNum)?.tempUOM || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.CycleTempUOM).default}
                isDisabled={![...cyclesMap.keys()].includes(props.cycleNum)}
              />
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                itemStyle={{ ['--background']: 'transparent' }}
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CycleType)?.options}
                onIonChange={cycleType => {
                  try {
                    updateCycleTypes(props.cycleNum, cycleType);
                  } catch(error) {
                    console.log(error);
                  }
                }}
                value={cyclesMap.get(props.cycleNum)?.type}
                isDisabled={![...cyclesMap.keys()].includes(props.cycleNum)}
              />
            </IonCol>
          </IonRow>
        );
      };

      return (
        <>
            {cycleNums.map((cycleNum, index) => (
              <CycleRow cycleNum={cycleNum} key={index}/>
            ))}
        </>
      );
    };

    const hasHeadError = (section: HeadAssemblyErrorSections) => {
      const id = displayedQuoteDetail.Id || displayedQuoteDetail.associatedLineItem;

      return headSectionErrorMap?.has(id) && headSectionErrorMap?.get(id)?.includes(section);
    };

    return (
      <IonGrid>
        <IonPopover
          cssClass='po-notes-popover'
          isOpen={popoverState.showPopover}
          animated={ false }
          onDidDismiss={async () => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
        >
          <HeadFormingPONotesPopover
            poNotes={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).poNotes}
            setShowPopover={setShowPopover}
          />
        </IonPopover>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Head Quantity</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='1'
              regex={Regexes.IntegerAboveZero}
              min='1'
              style={{ width: '78px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={headQuantity => {
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headQuantity = headQuantity;
                setLocalHeadQuantity(headQuantity);
              }}
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headQuantity}
              errorExists={hasHeadError(HeadAssemblyErrorSections.HeadQuantity)}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Blank Size</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='0.00'
              regex={Regexes.DecimalNumber}
              min='0.01'
              step='0.01'
              style={{ width: '200px' }}
              onBlur={blankSize => {
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).blankSize = blankSize;
                setLocalBlankOD(blankSize);
              }}
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).blankSize}
              errorExists={hasHeadError(HeadAssemblyErrorSections.BlankSize)}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Head Diameter</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='0.00'
              regex={Regexes.DecimalNumber}
              min='0.01'
              step='0.01'
              style={{ width: '200px' }}
              onBlur={headDiameter =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headDiameter = headDiameter
              }
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headDiameter}
              errorExists={hasHeadError(HeadAssemblyErrorSections.HeadDiameter)}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Head Forming Type</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select type'
              options={itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.HeadFormingType)?.options}
              style={{ width: '200px', marginTop: '0px' }}
              onChange={headFormingType =>
                setHeadFormingType(headFormingType.label)
              }
              value={headFormingType}
              errorExists={hasHeadError(HeadAssemblyErrorSections.HeadFormingType)}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Straight Flange</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='0.000'
              regex={Regexes.DecimalNumber}
              min='0.001'
              step='0.001'
              style={{ width: '200px' }}
              onBlur={straightFlange => {
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).straightFlange = straightFlange;
                setLocalStraightFlange(straightFlange);
              }}
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).straightFlange}
              errorExists={hasHeadError(HeadAssemblyErrorSections.StraightFlange)}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Max Center Hole</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='0.000'
              regex={Regexes.DecimalNumber}
              min='0.001'
              step='0.001'
              style={{ width: '80px' }}
              onBlur={maxCenterHole => {
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headMaxCenterHole = maxCenterHole;
                setLocalMaxCenterHole(maxCenterHole);
              }}
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headMaxCenterHole}
            />
          </IonCol>
        </IonRow>
        {headFormingType === HeadFormingTypes.Hemi || headFormingType === HeadFormingTypes.DOH || headFormingType === HeadFormingTypes.FandD ?
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Inside Dish Radius</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='0.000'
              regex={Regexes.DecimalNumber}
              min='0.001'
              step='0.001'
              style={{ width: '80px' }}
              onBlur={insideDishRadius =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).insideDishRadius = insideDishRadius
              }
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).insideDishRadius}
            />
          </IonCol>
        </IonRow> : null}
        {headFormingType === HeadFormingTypes.Hemi ?
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Cutback</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='0.000'
              regex={Regexes.DecimalNumber}
              min='0.001'
              step='0.001'
              style={{ width: '80px' }}
              onBlur={cutback =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).cutback = cutback
              }
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).cutback}
            />
          </IonCol>
        </IonRow> : null}
        {headFormingType === HeadFormingTypes.FandD ?
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Inside Knuckle Radius</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='0.000'
              regex={Regexes.DecimalNumber}
              min='0.001'
              step='0.001'
              style={{ width: '80px' }}
              onBlur={insideKnuckleRadius =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).insideKnuckleRadius = insideKnuckleRadius
              }
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).insideKnuckleRadius}
            />
          </IonCol>
        </IonRow> : null}
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='section-label-subheader'>Additional Bevel Details</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Open End</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select Open End Edge Preparation'
              options={itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.EdgePreparation)?.options}
              style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
              onChange={openEnd => {
                const updatedNotesRequired = new Map(openEndNotesRequired);
                openEnd.label === EdgePreps.SquareCut ? updatedNotesRequired.set(displayedQuoteDetailId, false) : updatedNotesRequired.set(displayedQuoteDetailId, true);
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).openEnd = openEnd.label;
                setOpenEndNotesRequired(updatedNotesRequired);
                setLocalOpenEnd(`${openEnd.label} ${assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).openEndNotes}`);
              }}
              defaultValue={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).openEnd}
            />
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Specify Other Details...'
              inputType='text'
              style={{ width: '428px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={newOpenEndNotes => {
                const updatedNotes = new Map(openEndNotes);
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).openEndNotes = newOpenEndNotes;
                updatedNotes.set(displayedQuoteDetailId, newOpenEndNotes);
                setOpenEndNotes(updatedNotes);
                setLocalOpenEnd(`${assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).openEnd} ${newOpenEndNotes}`);
              }}
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).openEndNotes}
              errorExists={hasHeadError(HeadAssemblyErrorSections.OpenEnd)}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Petal to Petal</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select Petal to Petal Edge Preparation'
              options={itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.EdgePreparation)?.options}
              style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
              onChange={petalToPetal =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).petalToPetal = petalToPetal.label
              }
              defaultValue={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).petalToPetal}
            />
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Specify Other Details...'
              inputType='text'
              style={{ width: '428px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={petalToPetalNotes =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).petalToPetalNotes = petalToPetalNotes
              }
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).petalToPetalNotes}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Crown to Petals</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select Crown to Petals Edge Preparation'
              options={itemType?.selections.find(selection => selection.valueType === QuoteLineItemValueTypes.EdgePreparation)?.options}
              style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
              onChange={crownToPetals =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).crownToPetals = crownToPetals.label
              }
              defaultValue={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).crownToPetals}
            />
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Specify Other Details...'
              inputType='text'
              style={{ width: '428px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={crownToPetalsNotes =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).crownToPetalsNotes = crownToPetalsNotes
              }
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).crownToPetalsNotes}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Number of Pieces in Head</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              itemStyle={{ ['--background']: 'transparent' }}
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.HeadNumberOfPieces)?.options}
              onIonChange={headNumberOfPieces => {
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headNumberOfPieces = (headNumberOfPieces === NumberOfPiecesInHeadOptions.SinglePieceHead ? 1 : 2);
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).numberOfPiecesInHead = headNumberOfPieces;
                setLocalHeadNumberOfPieces(headNumberOfPieces === NumberOfPiecesInHeadOptions.SinglePieceHead ? 1 : 2);
              }}
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headNumberOfPieces ?

                (assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headNumberOfPieces === 1 ? NumberOfPiecesInHeadOptions.SinglePieceHead : (assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headNumberOfPieces === 2 ? NumberOfPiecesInHeadOptions.TwoPieceHead : NumberOfPiecesInHeadOptions.SegmentalHead)) : undefined}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <CheckboxItem
              label='Blank/Crown Cut by NC'
              style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
              onIonChange={checked =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).blankCrownCutByNC = checked
              }
              checked={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).blankCrownCutByNC}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <CheckboxItem
              label='Petals Cut by NC'
              style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
              onIonChange={checked =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).petalsCutByNC = checked
              }
              checked={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).petalsCutByNC}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Other Clad Groups Combined in this head</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Specify Other Clad Groups...'
              inputType='text'
              style={{ width: '758px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={otherCladGroupsCombinedInThisHead =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).otherCladGroupsCombinedInThisHead = otherCladGroupsCombinedInThisHead
              }
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).otherCladGroupsCombinedInThisHead}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>NobelClad Procedure</IonLabel>
          </IonCol>
          <IonCol size='2.8'>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select'
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NobelCladProcedure)?.options}
              style={{ width: '320px', marginTop: '0px' }}
              onChange={nobelCladProcedure => {
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).nobelCladProcedure = nobelCladProcedure.label;
                setLocalNobelCladProcedure(nobelCladProcedure.label);
              }}
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).nobelCladProcedure}
              errorExists={hasHeadError(HeadAssemblyErrorSections.NobelCladProcedure)}
            />
          </IonCol>
          <IonCol style={{ paddingLeft: '7px' }}>
            <PONotesButton onClick={() => setShowPopover({ showPopover: true, event: undefined })}/>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>NC Chemistry Restrictions for Bonding</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select Chemistry Restrictions'
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.NCChemistryRestrictionsForBonding)?.options}
              style={{ width: '320px', marginRight: '10px', marginTop: '0px' }}
              onChange={ncChemistryRestrictionsForBonding =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).ncChemistryRestrictionsForBonding = ncChemistryRestrictionsForBonding.label
              }
              defaultValue={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).ncChemistryRestrictionsForBonding}
            />
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='Specify Other Details...'
              inputType='text'
              style={{ width: '428px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={ncChemistryRestrictionsForBondingNotes =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).ncChemistryRestrictionsForBondingNotes = ncChemistryRestrictionsForBondingNotes
              }
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).ncChemistryRestrictionsForBondingNotes}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Head Forming Supplier</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select Supplier'
              options={headFormingSupplierOptions.map(o => o.name)}
              style={{ width: '320px', marginTop: '0px' }}
              onChange={headFormingSupplier => {
                const vendorValue = headFormingSupplierOptions.find((o) => o.name === headFormingSupplier.label)?.value;
                assemblyGroupOutsideServiceItemFieldsToUpdate.set(displayedQuoteDetailId, {
                  ...assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId),
                  headFormingVendor: vendorValue
                });
                setHeadFormingVendorName(headFormingSupplier.label);
              }}
              value={headFormingVendorName}
              errorExists={hasHeadError(HeadAssemblyErrorSections.HeadFormingSupplier)}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Clad Metal Preference</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              itemStyle={{ ['--background']: 'transparent' }}
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CladMetalPreference)?.options}
              onIonChange={cladMetalPreference => {
                assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headFormingIDvsOD = cladMetalPreference;
                setLocalCladMetalPreference(cladMetalPreference);
              }}
              value={assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headFormingIDvsOD}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='section-label-subheader'>Simulation Heat Treatments</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
            <IonCol size='2.8'>
              <CheckboxItem
                label='Simulate at Vendor'
                style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
                onIonChange={checked =>
                  assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulateHeatTreatmentAtVendor = checked
                }
                checked={assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).simulateHeatTreatmentAtVendor}
              />
            </IonCol>
          </IonRow>
        <CyclesSection/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Head Forming Cost Per Head</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='0.00'
              inputType='number'
              regex={Regexes.DecimalNumber}
              min='0.00'
              style={{ width: '90px' }}
              onBlur={headFormingCostPerHead => {
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headFormingCostPerHead = headFormingCostPerHead;
                setLocalHeadFormingCostPerHead(headFormingCostPerHead);
              }}
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headFormingCostPerHead}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Head Freight Cost to Former</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              placeholder='0.00'
              inputType='number'
              regex={Regexes.DecimalNumber}
              min='0.00'
              style={{ width: '90px' }}
              onBlur={headFreightCostToFormer => {
                assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headFreightCostToFormer = headFreightCostToFormer;
              }}
              value={calculateUOMValue(displayedQuoteDetail.AdvancedSpecUOM__c, uom, assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headFreightCostToFormer, Units.PricePerKilogram, Units.PricePerPound)}
            />
            <IonLabel class='selection-input-sub-label'>Per {kgOrLbString}</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Time to Ship to Former</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='1'
              regex={Regexes.IntegerAboveZero}
              min='1'
              style={{ width: '90px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={timeToShipToFormer =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headTimeToFormer = timeToShipToFormer
              }
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headTimeToFormer}
            />
            <IonLabel class='selection-input-sub-label'>Weeks</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label'>Time to Form Head</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='1'
              regex={Regexes.IntegerAboveZero}
              min='1'
              style={{ width: '90px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={timeToFormHead =>
                assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headTimeToFormHead = timeToFormHead
              }
              value={assemblyGroupHeadFieldsToUpdate.get(displayedQuoteDetailId).headTimeToFormHead}
            />
            <IonLabel class='selection-input-sub-label'>Weeks</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label' style={{ marginTop: '10px' }}>{'Head Contour, Tolerances & Heat Treatment Options'}</IonLabel>
          </IonCol>
          <IonCol class='flex-column'>
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              listStyle={{ background: 'transparent' }}
              itemStyle={{ ['--background']: 'transparent' }}
              groupStyle={{ display: 'unset' }}
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.HeatContourTolerancesAndHeatTreatmentOptions).options}
              onIonChange={headContourTolerancesAndHeatTreatmentOptions => {
                (headContourTolerancesAndHeatTreatmentOptions !== HeatContourTolerancesAndHeatTreatmentOptions.Other ? assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headContourToleranceHTOptions = headContourTolerancesAndHeatTreatmentOptions : '');
                if(headContourTolerancesAndHeatTreatmentOptions === HeatContourTolerancesAndHeatTreatmentOptions.PerASMEVIIIUG79UG81AndUCS79) {
                  setLocalHeadContour(headContourTolerancesAndHeatTreatmentOptions);
                }
              }}
              value={assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headContourToleranceHTOptions &&
                assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headContourToleranceHTOptions !== HeatContourTolerancesAndHeatTreatmentOptions.PerASMEVIIIUG79UG81AndUCS79 ? HeatContourTolerancesAndHeatTreatmentOptions.Other : getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.HeatContourTolerancesAndHeatTreatmentOptions)?.default}
            />
            <SelectionContainer
              type={SelectionTypes.TextInput}
              style={{ width: '758px', marginTop: '11px' }}
              placeholder='Specify Other...'
              value={assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headContourToleranceHTOptions !== HeatContourTolerancesAndHeatTreatmentOptions.PerASMEVIIIUG79UG81AndUCS79 ? assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headContourToleranceHTOptions : ''}
              onBlur={detail => {
                (assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).headContourToleranceHTOptions = detail);
                setLocalHeadContour(detail);
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label' style={{ marginTop: '10px' }}>Additional Head Information</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextArea}
              placeholder='Specify Additional Head Information...'
              style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto' }}
              rows={6}
              onBlur={additionalHeadInformation =>
                assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).additionalHeadInformation = additionalHeadInformation
              }
              value={assemblyGroupOutsideServiceItemFieldsToUpdate.get(displayedQuoteDetailId).additionalHeadInformation}
              textAreaType='Additional Head Information'
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  const OutsideServicesSection = (displayedQuoteLine: QuoteLineItem, displayedQuoteDetail: QuoteLineItemDetail) =>
    OutsideServicesSectionList(
      uom,
      isDisplayMetric,
      assemblyGroupOutsideServiceItemFieldsToUpdate,
      displayedQuoteLine,
      displayedQuoteDetail
    );

  const FreightSection = (displayedQuoteLine: QuoteLineItem) => {
    const updateMap = assemblyGroupLineFieldsToUpdate[displayedQuoteLine.Id || displayedQuoteLine.displayId];
    const [freightOut, setFreightOut] = useState<number>(
      isDisplayMetric ? updateMap?.freightCostPerKG : updateMap?.freightCostPerLB
    );

    return (
      <>
        <IonGrid>
          <IonRow>
            <IonCol size='2.8'>
              <IonLabel class='selection-label'>Total Shipping Cost (Per Lot):</IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='0.000'
                regex={Regexes.DecimalNumber}
                min='0'
                style={{ width: '90px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={(totalShippingCostValue: number) => {
                  updateMap.freightCostPerLB = calculateUOMValue(uom, MeasurementSystems.Imperial, totalShippingCostValue, Units.Kilograms, Units.Pounds);
                  updateMap.freightCostPerKG = calculateUOMValue(uom, MeasurementSystems.Metric, totalShippingCostValue, Units.Kilograms, Units.Pounds);
                  setFreightOut(isDisplayMetric ? updateMap?.freightCostPerKG : updateMap?.freightCostPerLB);
                }}
                value={freightOut}
              />
              <IonLabel class='selection-input-sub-label'>Per {kgOrLbString}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    );
  };

  const Section = (props: {
    section: string,
    displayedQuoteLine: QuoteLineItem,
    displayedQuoteDetail: QuoteLineItemDetail
  }) => {
      return (
        <IonRow>
          <IonCol class='flex-column'>
            <IonLabel class='subheader' data-testid={'QAssemblyGroupsSection_' + props.section}>
              {props.section}
            </IonLabel>
            <hr className='section-rule' />
            <IonGrid>
              {sectionsToDisplay[props.section].map(component => {
                return component({
                  hideSpecifyPerAssembly: true,
                  quoteLineIdForRow: props.displayedQuoteLine.Id || props.displayedQuoteLine.displayId,
                  quoteDetailForRow: props.displayedQuoteDetail, assemblyGroupOptionsRequired: assemblyGroupOptionsRequired,
                  assemblyGroupLineFieldsToUpdate: assemblyGroupLineFieldsToUpdate,
                  assemblyGroupDetailFieldsToUpdate: assemblyGroupDetailFieldsToUpdate
                });
              })}
            </IonGrid>
          </IonCol>
        </IonRow>
      );
  };

  // sections - directory of Section components to map over
  const sectionsMap = (quoteLines: QuoteLineItem[], assemblyGroup: QuoteLineItemDetail) => {
    const parentLine = quoteLines.find(qli => !qli.Parent_Line__c);

    return new Map([
      [AssemblyGroupsHeaderTabs.AssemblyGroup, () => AssemblyGroupSection(quoteLines)],
      [AssemblyGroupsHeaderTabs.AdvancedSpec, () => AdvancedSpecSection(parentLine, assemblyGroup)],
      [AssemblyGroupsHeaderTabs.PostCladProcessing, () => PostCladProcessingSection(parentLine, assemblyGroup)],
      [AssemblyGroupsHeaderTabs.CanRolling, () => CanRollingSection(quoteLines.find(qli => qli.Item_Type__c === FinishedProductTypes.Cylinder), assemblyGroup)],
      [AssemblyGroupsHeaderTabs.HeadForming, () => HeadFormingSection(quoteLines.find(qli => qli.Item_Type__c === FinishedProductTypes.Head), assemblyGroup)],
      [AssemblyGroupsHeaderTabs.OutsideServices, () => OutsideServicesSection(parentLine, assemblyGroup)],
      [AssemblyGroupsHeaderTabs.Freight, () => FreightSection(parentLine)],
    ]);
  };

  const AssemblyGroupsList = () => {
    const [expandedMap, setExpandedMap] = React.useState<Map<string, boolean>>(new Map(detailsAssociatedWithMetalCombo
      .filter(qlid => !getQuoteLineItemFromId(displayedQuoteGroup.allQlisMappedToMetalCombo, qlid.NC_Quote_Line_Item__c || qlid.associatedLineItem).Parent_Line__c)
      .map((_, index) => [`Assembly Group ${index + 1}`, index + 1 == 1 ? true : false])));

    const noGroupsExpanded = () => {
      return ![...expandedMap.values()].some(value => value);
    };

    const expandOrCollapseAllGroups = () => {
      const updatedExpandedMap = new Map(expandedMap);
      [...updatedExpandedMap.keys()].forEach(key => updatedExpandedMap.set(key, noGroupsExpanded()));
      setExpandedMap(updatedExpandedMap);
    };

    return (
    <>
      <div className='expand-collapse-label-container'>
        <IonLabel onClick={() => expandOrCollapseAllGroups()} class='expand-collapse-label'>{noGroupsExpanded() ? 'Expand All' : 'Collapse All'}</IonLabel>
      </div>
      {detailsAssociatedWithMetalCombo
      .filter(qlid => !getQuoteLineItemFromId(displayedQuoteGroup.allQlisMappedToMetalCombo, qlid.NC_Quote_Line_Item__c || qlid.associatedLineItem).Parent_Line__c)
      .map((assemblyGroup, idx) => {
        const assemblyGroupId = assemblyGroup.Id || assemblyGroup.associatedLineItem;
        const quoteLines: QuoteLineItem[] = [];
        let quoteLineForAssemblyGroup: QuoteLineItem;

        displayedQuoteGroup.qlid2qliMap.forEach((value, key) => {
          if(key.Id === assemblyGroupId || key.associatedLineItem === assemblyGroupId) {
            quoteLineForAssemblyGroup = displayedQuoteGroup.qlid2qliMap.get(key);
            quoteLines.push(quoteLineForAssemblyGroup);
          }
        });

        quoteLines.push(...quoteLineItems.filter(qli => qli.Parent_Line__c === quoteLineForAssemblyGroup.Line__c));

        let hasError = false;

        if(quoteLines.some(qli => qli.Item_Type__c === FinishedProductTypes.Head)) {
          hasError = headSectionErrorMap.get(assemblyGroupId) ? Array.from(headSectionErrorMap.get(assemblyGroupId).values()).length > 0 : false;
        }

        if(quoteLines.some(qli => qli.Item_Type__c === FinishedProductTypes.Cylinder) && !hasError) {
          hasError = !assemblyGroupCylinderDetailFieldsToUpdate.get(assemblyGroupId).cylinderVendor;
        }

        //Only render head forming if finished product type is a head
        const assemblyGroupSections = () => {
          return (
            <>
              {/* Map over the sections and render the section's components, only render head forming if finished product type is a head,
              and only render cylinder + can rolling if finished product type is a cylinder */}
              {Array.from(sectionsMap(quoteLines, assemblyGroup).keys())
              .filter((tab) => {
                if (tab === AssemblyGroupsHeaderTabs.HeadForming) {
                  return quoteLines.some(qli => qli.Item_Type__c === FinishedProductTypes.Head);
                }
                if (tab === AssemblyGroupsHeaderTabs.CanRolling) {
                  return quoteLines.some(qli => qli.Item_Type__c === FinishedProductTypes.Cylinder);
                }
                return true;
              })
              .map((tab, idx) => (
                <MappedSection component={sectionsMap(quoteLines, assemblyGroup).get(tab)} label={tab} sectionRefMap={sectionRefMap} QALabel={'OrderEntryPopover'} index={idx} key={tab} />
              ))}
            </>
          );
        };

        return <MappedSection
            component={assemblyGroupSections}
            label={`Assembly Group ${idx + 1}`}
            QALabel={'AssemblyGroups_AssemblyGroup'}
            index={idx}
            showFirstHeader
            key={idx}
            expandedMap={expandedMap}
            setExpandedMap={setExpandedMap}
            isCollapsible
            largeHeader
            blockDisplay
            hasError={hasError}
               />;
        })}
    </>);
  };

  return (
    <PageLayout>
      {loadingComplete() ?
      <div className='assembly-groups'>
        <div className='floating-actions-container'>
          <FloatingAction
            icon={advancedSpecsTab}
            darkModeIcon={advancedSpecsTabDarkMode}
            hoveredIcon={advancedSpecsTabHovered}
            onClick={() => {
              if(validateAssemblyGroups()) {
                updateAssGrp();
                dispatchAdvSpecState({ type: AdvSpecActionTypes.setHasInitialized, payload: false });
                dispatchAdvSpecState({ type: AdvSpecActionTypes.setSelectedLineItemId, payload: lineItemId });
                navigateToRoute(PageRoutes.AdvancedSpecifications, history, quote.Id);
              }
            }}
            action='advanced-specifications'
          />
        </div>
        <div className='title-container'>
          <IonLabel class='title' data-testid={'QAAssemblyGroupHeader'}>
            <span className="quotes-title" onClick={() => navigateToRoute(PageRoutes.Quotes, history, quote.Id, routeChangeAlert)} data-testid="QAAssemblyGroupsPageQuotesTitle">Quotes </span>
            /
            <span className='quote-number-title' onClick={() => {
              if(validateAssemblyGroups()) {
                updateAssGrp();
                navigateToRoute(PageRoutes.QuoteDetail, history, quote.Id);
              }
            }} data-testid="QAAssemblyGroupsPageQuoteNumberTitle"
            > {quote?.Name} </span>
            /
            <span
              className="advanced-specifications-title"
              onClick={() => {
                if(validateAssemblyGroups()) {
                  updateAssGrp();
                  dispatchAdvSpecState({ type: AdvSpecActionTypes.setHasInitialized, payload: false });
                  dispatchAdvSpecState({ type: AdvSpecActionTypes.setSelectedLineItemId, payload: lineItemId });
                  navigateToRoute(PageRoutes.AdvancedSpecifications, history, quote.Id);
                }
              }}
              data-testid="QAAssemblyGroupsPageAdvancedSpecificationsTitle"
            >
                Advanced Specifications
              </span>
            / Assembly Groups
          </IonLabel>
          {/* Commenting metal combo out for now, we all know it will come back later!
            <div className="metals-scroller-container">
              <MetalComboScroller
                displayedQuoteGroup={props.displayedQuoteGroup}
                style={MetalComboScrollerStyles.Horizontal}
                hideArrows={true}
              />
            </div> */}
          {/* adjustment container for uom toggler */}
          <div style={{margin: '100px 0 0 138px'}}>
            <UomDisplayToggle />
          </div>
        </div>
        <hr />
        <div className='assembly-group-selections'>
          <IonGrid>
            <AssemblyGroupsList/>
          </IonGrid>
        </div>
        <hr className='msto-header-rule' />
        <div className='cta-button-container'>
          <UpdateButton />
        </div>
      </div>:
      <div className="assembly-groups-loading-container">
        <Loading message='Loading Assembly Groups...' data-testId="'QAAssemblyGroupsLoading'"/>
      </div>}
    </PageLayout>
  );
};

export default AssemblyGroupsPage;