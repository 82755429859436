import * as React from 'react';
import { BehaviorSubject } from 'rxjs';
import { userType } from '@services/environment-service';

const { createContext, useContext } = React;

const NavigationContext = createContext(null);

export const NavigationProvider = (props) => {
  const value = {
    getNavigationOptions: props.getNavigationOptions || getNavigationOptions,
    getFooterNavOptions: props.getFooterNavOptions || getFooterNavOptions,
  };

  return (
    <NavigationContext.Provider value={value}>
      {props.children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  return useContext(NavigationContext);
};

type Tab = {
  path?: string,
  redirectTo?: string,
  pathMatch?: string,
  label?: string,
  parentLabel?: string,
  subLabel?: string,
  icon?: string,
  data?: { [name: string]: any }
};

export const NavOptions: Tab[] = [
  {
    path: 'dashboard',
    label: 'MyClad™',
    icon: 'assets/icon/nc-logo.svg',
    data: { roles: ['sales', 'external', 'anonymous'] }
  },
  /*{
    path: 'quotes',
    label: 'Quotes',
    icon: 'assets/icon/nc-logo.svg',
    data: { roles: ['sales', 'external', 'anonymous'] }
  },*/
  {
    path: 'orders',
    label: 'Orders',
    icon: 'assets/icon/nc-logo.svg',
    data: { roles: ['sales', 'external', 'anonymous'] }
  },
  {
    path: 'invoices',
    label: 'Invoices',
    icon: 'assets/icon/nc-logo.svg',
    data: { roles: ['sales', 'external', 'anonymous'] }
  },
  {
    path: 'certifications',
    label: 'Certifications',
    icon: 'assets/icon/nc-logo.svg',
    data: { roles: ['sales', 'external', 'anonymous'] }
  }
];

const navOptions = ['dashboard', 'quotes', 'orders', 'invoices', 'certifications'];

const footerLinks = new Map([
  ['About Us', [{
    displayName: 'Jobs',
    href: 'https://www.dmcglobal.com/jobs'
  }, {
    displayName: 'Investors',
    href: 'https://www.dmcglobal.com/investors'
  }, {
    displayName: 'Request a Quote',
    href: 'https://www.nobelclad.com/general-inquiry/get-a-quote'
  }, {
    displayName: 'Contact Us',
    href: 'https://www.nobelclad.com/about/global-offices'
  }]],
  ['Legal', [{
    displayName: 'Corporate Information',
    href: 'https://www.nobelclad.com/corporate-information'
  }, {
    displayName: 'Privacy Policy',
    href: 'https://www.nobelclad.com/privacy-policy'
  }, {
    displayName: 'Terms & Conditions',
    href: 'https://www.dmcglobal.com/terms-and-conditions'
  }, {
    displayName: 'Terms & Conditions of Purchase',
    href: 'https://www.nobelclad.com/-/media/Project/DMC/NobelClad-V2/resources-files/DMC-Terms-and-Conditions-of-Purchase.pdf'
  }, {
    displayName: 'Terms & Conditions of Sale',
    href: 'https://www.nobelclad.com/-/media/Project/DMC/NobelClad-V2/resources-files/NobelClad-Terms-and-Conditions-of-Sale_21.pdf'
  }]],
  ['Site Map', [{
    displayName: 'Welding Techniques',
    href: 'https://www.nobelclad.com/process'
  }, {
    displayName: 'Products',
    href: 'https://nobelclad.com/products'
  }, {
    displayName: 'Industries',
    href: 'https://nobelclad.com/industries'
  }, {
    displayName: 'Resources',
    href: 'https://nobelclad.com'
  }]]
]);

export const navOptions$ = new BehaviorSubject(null);

// Retrieves available topbar navigation options
const getNavigationOptions = async(): Promise<any> => {

  const localUserType = userType() || 'anonymous';
  const tabs = NavOptions.filter(
    opt => navOptions.includes(opt.path) &&
      opt.data.roles.includes(localUserType)
  );

  const results = {
    tabs
  };

  navOptions$.next(results);
  return navOptions;
};

export function getFooterNavOptions(): any {
  return footerLinks;
}
