import * as React from 'react';
import { useState } from 'react';
import { syncOutline, checkmarkCircleOutline, refreshOutline, checkmarkOutline } from 'ionicons/icons';
import { Icon } from './icon';
import './sync-indicator.scss';
import Tooltip from '@components/tooltip/tooltip';
import { TooltipPositions } from '@constants';
import { useSync } from '@context/useSync';

type Props = {
  spin?: boolean;
};

const SyncIndicator = ({ spin = true }: Props): React.ReactElement => {
  const { isSyncing, isLoading, color, syncMsg } = useSync();

  const [iconSyncSrc, setIconSyncSrc] = useState(syncOutline);
  const [isSyncSpinning, setIsSyncSpinning] = useState(spin);

  const [iconLoadSrc, setIconLoadSrc] = useState(refreshOutline);
  const [isLoadSpinning, setIsLoadSpinning] = useState(spin);
 
  React.useEffect(() => {
    if (isSyncing) {
      setIconSyncSrc(syncOutline);
      setIsSyncSpinning(true);
    } else {
      setIconSyncSrc(checkmarkCircleOutline);
      setIsSyncSpinning(false);
    }
  }, [isSyncing]);
  
  React.useEffect(() => {
    if (isLoading) {
      setIconLoadSrc(refreshOutline);
      setIsLoadSpinning(true);
    } else {
      setIconLoadSrc(checkmarkOutline);
      setIsLoadSpinning(false);
    }
  }, [isLoading]);

  return (
    <div style={{ textAlign: 'center' }}>
      {/* <Tooltip
        position={TooltipPositions.Bottom}
        text={<div className='tool-tip-text'>{isLoading ? 'Loading...' : 'Loaded'}</div>}
        offset={{ x: '0px', y: '0px' }}
      >
        <Icon src={iconLoadSrc} isSpinning={isLoadSpinning} />
      </Tooltip> */}
      <Tooltip
        position={TooltipPositions.Bottom}
        text={<div className='tool-tip-text'>{syncMsg}</div>}
        offset={{ x: '0px', y: '0px' }}
      >
        <Icon src={iconSyncSrc} isSpinning={isSyncSpinning} color={color} />
      </Tooltip>
    </div>
  );
};

export default SyncIndicator;