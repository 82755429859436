import { ManufacturingSites, MeasurementSystems, RawMaterialUsage } from '@constants';
import { RawMaterial } from '@interfaces/raw-material';
import * as React from 'react';
import MultiShotTable, { MultiShotSelectDropdown } from './multi-shot';
import { MultiShotState, MultiShotVariants } from './useMultiShotReducer';

interface MultiShotSectionProps {
  multiShotType: MultiShotVariants;
  setMultiShotType: (type: MultiShotVariants) => void;
  uom: MeasurementSystems;
  manufacturingSite: ManufacturingSites;
  multiShotMetalsStateRef: React.MutableRefObject<MultiShotState>;
  handleMetalChange: (metal: RawMaterial, usage: RawMaterialUsage) => void;
  setBaseMetalProposalType: (proposalType: string) => void;
  setCladMetalProposalType: (proposalType: string) => void;
  setFinishedBaseMetalThickness: (thickness: number) => void;
  setFinishedCladMetalThickness: (thickness: number) => void;
  setBaseMetalCustomerProvided: (customerProvided: boolean) => void;
  setCladMetalCustomerProvided: (customerProvided: boolean) => void;
  setBaseMetalType: (metalType: string) => void;
  setCladMetalType: (metalType: string) => void;
}

const MultiShotSection = ({
  multiShotType,
  setMultiShotType,
  uom,
  manufacturingSite,
  multiShotMetalsStateRef,
  handleMetalChange,
  setBaseMetalProposalType,
  setCladMetalProposalType,
  setFinishedBaseMetalThickness,
  setFinishedCladMetalThickness,
  setBaseMetalCustomerProvided,
  setCladMetalCustomerProvided,
  setBaseMetalType,
  setCladMetalType,
}:
MultiShotSectionProps) => {
  // This is the state of the dropdown that allows the user to select the type of multi-shot
  return (
    <>
      <MultiShotSelectDropdown
        multiShotType={multiShotType}
        setMultiShotType={setMultiShotType}
        multiShotState={multiShotMetalsStateRef.current}
      />
      <MultiShotTable
        manufacturingSite={manufacturingSite}
        multiShotMetalsStateRef={multiShotMetalsStateRef}
        multiShotType={multiShotType}
        uom={uom}
        handleMetalChange={handleMetalChange}
        setBaseMetalProposalType={setBaseMetalProposalType}
        setCladMetalProposalType={setCladMetalProposalType}
        setFinishedBaseMetalThickness={setFinishedBaseMetalThickness}
        setFinishedCladMetalThickness={setFinishedCladMetalThickness}
        setBaseMetalType={setBaseMetalType}
        setCladMetalType={setCladMetalType}
        setBaseMetalCustomerProvided={setBaseMetalCustomerProvided}
        setCladMetalCustomerProvided={setCladMetalCustomerProvided}
      />
    </>
  );
};

export default MultiShotSection;
