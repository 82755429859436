import { QuoteVariableConfiguration } from '@interfaces/quote-variable-configurations';
import * as React from 'react';
import { getQuoteVariableConfigurations } from './quote-variable-configurations-api';

const { createContext, useContext } = React;

const QuoteVariableConfigurationsContext = createContext(null);

export const QuoteVariableConfigurationsProvider = (props) => {
  const value = {
    getAllQuoteVariableConfigurations: props.getAllQuoteVariableConfigurations || getAllQuoteVariableConfigurations,
  };

  return (
    <QuoteVariableConfigurationsContext.Provider value={value}>
      {props.children}
    </QuoteVariableConfigurationsContext.Provider>
  );
};

export const useQuoteVariableConfigurations = () => {
  return useContext(QuoteVariableConfigurationsContext);
};

// Calls api to get all the quote variable configurations from cosmos
export const getAllQuoteVariableConfigurations = async(): Promise<QuoteVariableConfiguration[]> => {
  try {
    return await getQuoteVariableConfigurations();
  } catch (error) {
    console.error(error);
    return null;
  }
};
