import React, { useState } from 'react';
import { IonButton, IonCol, IonGrid, IonImg, IonLabel, IonRow } from '@ionic/react';
import './customer-specified-spwht-popover.scss';
import { SelectionTypes, QuoteLineItemValueTypes, TempUOMs, Regexes, SelectionLabelPositions } from '@constants';
import SelectionContainer from '@components/selection-container/selection-container';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import { useDarkMode } from '@hooks/useDarkMode';
import { getSelectionOptions, getSelections } from '@shared/quote-utils';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { AdvSpecsMetalComboDetailFieldsToUpdate, AdvSpecsAssemblyGroupDetailFieldsToUpdate } from '@shared/types';

const CustomerSpecifiedSPWHTPopover = (props: {
  setShowPopover: React.Dispatch<React.SetStateAction<{
    showPopover: boolean;
    event: any;
  }>>,
  detailFieldsToUpdate: AdvSpecsMetalComboDetailFieldsToUpdate | AdvSpecsAssemblyGroupDetailFieldsToUpdate,
}) => {
  const { dataState } = useDataState();
  const { selections } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;

  const { darkMode } = useDarkMode();
  const cycleNums = [1,2,3];

  const [detailFieldsToUpdate] = React.useState({
    SPWHTGroupACycle1: props.detailFieldsToUpdate.SPWHTGroupACycle1,
    SPWHTGroupACycle2: props.detailFieldsToUpdate.SPWHTGroupACycle2,
    SPWHTGroupACycle3: props.detailFieldsToUpdate.SPWHTGroupACycle3,
    SPWHTGroupAToBePerformed: props.detailFieldsToUpdate.SPWHTGroupAToBePerformed,
    SPWHTGroupBCycle1: props.detailFieldsToUpdate.SPWHTGroupBCycle1,
    SPWHTGroupBCycle2: props.detailFieldsToUpdate.SPWHTGroupBCycle2,
    SPWHTGroupBCycle3: props.detailFieldsToUpdate.SPWHTGroupBCycle3,
    SPWHTGroupBToBePerformed: props.detailFieldsToUpdate.SPWHTGroupBToBePerformed,
    SPWHTGroupCCycle1: props.detailFieldsToUpdate.SPWHTGroupCCycle1,
    SPWHTGroupCCycle2: props.detailFieldsToUpdate.SPWHTGroupCCycle2,
    SPWHTGroupCCycle3: props.detailFieldsToUpdate.SPWHTGroupCCycle3,
    SPWHTGroupCToBePerformed: props.detailFieldsToUpdate.SPWHTGroupCToBePerformed,
    SPWHTHeatingAndCoolingRateAbove: props.detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAbove,
    SPWHTHeatingAndCoolingRateAboveUOM: props.detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAboveUOM,
    SPWHTMaximumCoolingRateHour: props.detailFieldsToUpdate.SPWHTMaximumCoolingRateHour,
    SPWHTMaximumCoolingRateHourUOM: props.detailFieldsToUpdate.SPWHTMaximumCoolingRateHourUOM,
    SPWHTMaximumHeatingRateHour: props.detailFieldsToUpdate.SPWHTMaximumHeatingRateHour,
    SPWHTMaximumHeatingRateHourUOM: props.detailFieldsToUpdate.SPWHTMaximumHeatingRateHourUOM,
  });

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const updateDetails = () => {
    const heatingCoolingRatesString = `, Max HR/CR: ${detailFieldsToUpdate.SPWHTMaximumHeatingRateHour || 0} ${detailFieldsToUpdate.SPWHTMaximumHeatingRateHourUOM || 'F'} / ${detailFieldsToUpdate.SPWHTMaximumCoolingRateHour || 0} ${detailFieldsToUpdate.SPWHTMaximumCoolingRateHourUOM || 'F'} from/to: ${detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAbove || 0} ${detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAboveUOM || 'F'}`;

    ['A', 'B', 'C'].forEach(group => {
      const groupToBePerformedField = `SPWHTGroup${group}ToBePerformed`;

      cycleNums.forEach(cycleNum => {
        const cycleField = `SPWHTGroup${group}Cycle${cycleNum}`;
        const cycleString = detailFieldsToUpdate[cycleField].split(', Max')[0];

        props.detailFieldsToUpdate[cycleField] = detailFieldsToUpdate[cycleField] ? `${cycleString}${heatingCoolingRatesString}` : null;
      });

      props.detailFieldsToUpdate[groupToBePerformedField] = detailFieldsToUpdate[groupToBePerformedField];
    });

    props.detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAbove = detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAbove;
    props.detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAboveUOM = detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAboveUOM;
    props.detailFieldsToUpdate.SPWHTMaximumCoolingRateHour = detailFieldsToUpdate.SPWHTMaximumCoolingRateHour;
    props.detailFieldsToUpdate.SPWHTMaximumCoolingRateHourUOM = detailFieldsToUpdate.SPWHTMaximumCoolingRateHourUOM;
    props.detailFieldsToUpdate.SPWHTMaximumHeatingRateHour = detailFieldsToUpdate.SPWHTMaximumHeatingRateHour;
    props.detailFieldsToUpdate.SPWHTMaximumHeatingRateHourUOM = detailFieldsToUpdate.SPWHTMaximumHeatingRateHourUOM;
  };

  const CyclesSection = (props: {
    group: string,
    groupRequired: boolean
  }) => {
    const { groupRequired } = props;
    const cycleLabel = 'Cycle';
    
    const [cycles, setCycles] = useState<string[]>(
      [
        detailFieldsToUpdate[`SPWHTGroup${props.group}Cycle1`],
        detailFieldsToUpdate[`SPWHTGroup${props.group}Cycle2`],
        detailFieldsToUpdate[`SPWHTGroup${props.group}Cycle3`],
      ]
    );

    const [cyclesMap, setCyclesMap] = useState<Map<number, { minutes: number, temperature: number, tempUOM: TempUOMs, fullString: string}>>(new Map());

    React.useEffect(() => {
      if(!groupRequired) {
        setCycles([]);
      }
    }, [groupRequired]);

    React.useEffect(() => {
      const newCyclesMap = new Map();

      (cycles).forEach((cycle) => {
        if(cycle) {
          let cycleSpaceIndex = cycle.indexOf(' ');
          const cycleHyphenIndex = cycle.indexOf('-');

          const cycleNumber = +cycle.substring(cycleSpaceIndex + 1, cycleHyphenIndex - 1);

          cycleSpaceIndex = cycle.indexOf(' ', cycleHyphenIndex + 2);
          let cycleSecondSpaceIndex = cycle.indexOf(' ', cycleSpaceIndex + 1);

          const cycleTemperature = +cycle.substring(cycleSpaceIndex + 1, cycleSecondSpaceIndex);

          const cycleCommaIndex = cycle.indexOf(',', cycleSecondSpaceIndex);

          const cycleTempUOM = cycle.substring(cycleSecondSpaceIndex + 1, cycleCommaIndex);

          cycleSpaceIndex = cycle.indexOf(' ', cycleCommaIndex + 7);
          cycleSecondSpaceIndex = cycle.indexOf(' ', cycleSpaceIndex + 1);

          const cycleMinutes = +cycle.substring(cycleSpaceIndex + 1, cycleSecondSpaceIndex);

          newCyclesMap.set(cycleNumber, { minutes: cycleMinutes, temperature: cycleTemperature, tempUOM: cycleTempUOM as TempUOMs, fullString: cycle });
        }
      });

      setCyclesMap(newCyclesMap);

      cycleNums.forEach(cycleNum => {
        const cycleString = newCyclesMap.get(cycleNum)?.fullString || '';
        
        switch(cycleNum) {
          case 1:
            detailFieldsToUpdate[`SPWHTGroup${props.group}Cycle1`] = cycleString;
            break;
          case 2:
            detailFieldsToUpdate[`SPWHTGroup${props.group}Cycle2`] = cycleString;
            break;
          case 3:
            detailFieldsToUpdate[`SPWHTGroup${props.group}Cycle3`] = cycleString;
            break;
        }
      });

    }, [cycles]);

    const updateCycleMinutes = (cycleNumUpdated: number, minutes: number) => {
      const newCyclesArray = [];

      [...cyclesMap.keys()].forEach(cycleNum => {
        const cycle = cyclesMap.get(cycleNum);

        if(cycleNum === cycleNumUpdated) {
          if(minutes) {
            newCyclesArray.push(`${cycleLabel} ${cycleNum} - Temp: ${cycle?.temperature} ${cycle?.tempUOM}, Hold Time: ${(minutes)} mins`);
          }
        } else {
          newCyclesArray.push(`${cycleLabel} ${cycleNum} - Temp: ${cycle?.temperature} ${cycle?.tempUOM}, Hold Time: ${(cycle?.minutes)} mins`);
        }
      });

      if(!cyclesMap.get(cycleNumUpdated) && minutes) {
        newCyclesArray.push(`${cycleLabel} ${cycleNumUpdated} - Temp: 0 F, Hold Time: ${minutes} mins`);
      }

      setCycles([...newCyclesArray]);
    };

    const updateCycleTemperatures = (cycleNumUpdated: number, temperature: number) => {
      const newCyclesArray = [];

      [...cyclesMap.keys()].forEach(cycleNum => {
        const cycle = cyclesMap.get(cycleNum);

        newCyclesArray.push(`${cycleLabel} ${cycleNum} - Temp: ${(cycleNum !== cycleNumUpdated ? cycle?.temperature : temperature)} ${cycle?.tempUOM}, Hold Time: ${cycle?.minutes} mins`);
      });

      setCycles([...newCyclesArray]);
    };

    const updateCycleTempUOMs = (cycleNumUpdated: number, tempUOM: string) => {
      const newCyclesArray = [];

      [...cyclesMap.keys()].forEach(cycleNum => {
        const cycle = cyclesMap.get(cycleNum);

        newCyclesArray.push(`${cycleLabel} ${cycleNum} - Temp: ${cycle?.temperature} ${(cycleNum !== cycleNumUpdated ? cycle?.tempUOM : tempUOM)}, Hold Time: ${cycle?.minutes} mins`);
      });

      setCycles([...newCyclesArray]);
    };

    const CycleRow = (props: {
      cycleNum: number
    }) => {

      return (
        <IonRow>
          <IonCol size='1'>
            <IonLabel class='selection-label'>{`Cycle ${props.cycleNum}:`}</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='1'
              regex={Regexes.IntegerAboveZero}
              min='1'
              style={{ width: '68px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={cycleMinutes => {
                updateCycleMinutes(props.cycleNum, cycleMinutes);
              }}
              value={cyclesMap.get(props.cycleNum)?.minutes}
              isDisabled={!groupRequired}
            />
            <IonLabel class='selection-input-sub-label'>minutes at</IonLabel>
            <SelectionContainer
              type={SelectionTypes.TextInput}
              inputType='number'
              placeholder='1'
              regex={Regexes.Temperature}
              min='1'
              style={{ width: '68px', marginRight: '10px' }}
              containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              onBlur={cycleTemperature =>
                updateCycleTemperatures(props.cycleNum, cycleTemperature)
              }
              value={cyclesMap.get(props.cycleNum)?.temperature}
              isDisabled={!groupRequired || !cyclesMap.get(props.cycleNum)?.minutes}
            />
            <SelectionContainer
              type={SelectionTypes.RadioButtons}
              itemStyle={{ ['--background']: 'transparent' }}
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CycleTempUOM).options}
              onIonChange={cycleTempUOM =>
                updateCycleTempUOMs(props.cycleNum, cycleTempUOM)
              }
              value={cyclesMap.get(props.cycleNum)?.tempUOM || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.CycleTempUOM).default}
              isDisabled={!groupRequired || !cyclesMap.get(props.cycleNum)?.minutes}
            />
          </IonCol>
        </IonRow>
      );
    };

    return (
      <>
          {cycleNums.map((cycleNum, index) => (
            <CycleRow cycleNum={cycleNum} key={index}/>
          ))}
      </>
    );
  };


  const SPHWTGroup = (props: {
    group: string
  }) => {
    const [groupRequired, setGroupRequired] = React.useState(
      !!detailFieldsToUpdate[`SPWHTGroup${props.group}Cycle1`] ||
      !!detailFieldsToUpdate[`SPWHTGroup${props.group}Cycle2`] ||
      !!detailFieldsToUpdate[`SPWHTGroup${props.group}Cycle3`]
    );

    const valueType: QuoteLineItemValueTypes = `SPWHTGroup${props.group}ToBePerformed` as QuoteLineItemValueTypes;

    return (
      <div className='sphwt-group-container'>
        <div className='sphwt-group-header-container'>
          <CheckboxItem
            style={{ width: 'fit-content' }}
            label={`SPWHT Group ${props.group}`}
            onIonChange={groupSelected => setGroupRequired(groupSelected)}
            checked={groupRequired}
          />
          <SelectionContainer
            type={SelectionTypes.DropDown}
            placeholder='Select Time to be Performed...'
            options={getSelectionOptions(selections, manufacturingSite, valueType).options}
            style={{ width: '161px', marginTop: 'unset' }}
            containerStyle={{ display: 'flex' }}
            onChange={spwhtGroupToBePerformed =>
              (detailFieldsToUpdate[valueType] = spwhtGroupToBePerformed.label)
            }
            defaultValue={detailFieldsToUpdate[valueType]}
            labelPosition={SelectionLabelPositions.Left}
            selectionLabel='To Be Performed'
          />
        </div>
        <hr className='section-rule'/>
        <CyclesSection group={props.group} groupRequired={groupRequired}/>
      </div>
    );
  };

  return (
    <div className="popover customer-specified-spwht">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='customer-specified-spwht-title' data-testid={'QACustomerSpecifiedSPWHTPopoverCustomerSpecifiedSPWHTHeader'}>Customer Specified SPWHT</IonLabel>
      </div>
      <hr/>
      <div className='selections'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <SPHWTGroup group='A'/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <SPHWTGroup group='B'/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <SPHWTGroup group='C'/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="3.63">
              <IonLabel class='selection-label'>
                Heating and Cooling Rate Above
              </IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='1'
                regex={Regexes.Temperature}
                min='1'
                style={{ width: '68px', marginRight: '10px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={SPWHTHeatingAndCoolingRateAbove =>
                  detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAbove = SPWHTHeatingAndCoolingRateAbove
                }
                value={detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAbove}
              />
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                itemStyle={{ ['--background']: 'transparent' }}
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CycleTempUOM).options}
                onIonChange={SPWHTHeatingAndCoolingRateAboveUOM =>
                  detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAboveUOM = SPWHTHeatingAndCoolingRateAboveUOM
                }
                value={detailFieldsToUpdate.SPWHTHeatingAndCoolingRateAboveUOM || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.CycleTempUOM).default}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="3.63">
              <IonLabel class='selection-label'>
                Maximum Heating Rate/Hour
              </IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='1'
                regex={Regexes.Temperature}
                min='1'
                style={{ width: '68px', marginRight: '10px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={SPWHTMaximumHeatingRateHour =>
                  detailFieldsToUpdate.SPWHTMaximumHeatingRateHour = SPWHTMaximumHeatingRateHour
                }
                value={detailFieldsToUpdate.SPWHTMaximumHeatingRateHour}
              />
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                itemStyle={{ ['--background']: 'transparent' }}
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CycleTempUOM).options}
                onIonChange={SPWHTMaximumHeatingRateHourUOM =>
                  detailFieldsToUpdate.SPWHTMaximumHeatingRateHourUOM = SPWHTMaximumHeatingRateHourUOM
                }
                value={detailFieldsToUpdate.SPWHTMaximumHeatingRateHourUOM || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.CycleTempUOM).default}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="3.63">
              <IonLabel class='selection-label'>
                Maximum Cooling Rate/Hour
              </IonLabel>
            </IonCol>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='number'
                placeholder='1'
                regex={Regexes.Temperature}
                min='1'
                style={{ width: '68px', marginRight: '10px' }}
                containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
                onBlur={SPWHTMaximumCoolingRateHour =>
                  detailFieldsToUpdate.SPWHTMaximumCoolingRateHour = SPWHTMaximumCoolingRateHour
                }
                value={detailFieldsToUpdate.SPWHTMaximumCoolingRateHour}
              />
              <SelectionContainer
                type={SelectionTypes.RadioButtons}
                itemStyle={{ ['--background']: 'transparent' }}
                options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.CycleTempUOM).options}
                onIonChange={SPWHTMaximumCoolingRateHourUOM =>
                  detailFieldsToUpdate.SPWHTMaximumCoolingRateHourUOM = SPWHTMaximumCoolingRateHourUOM
                }
                value={detailFieldsToUpdate.SPWHTMaximumCoolingRateHourUOM || getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.CycleTempUOM).default}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <hr className='bottom-rule'/>
      <div className="cta-button-container">
        <IonButton color="primary" class="save-button" data-testid="QACustomerSpecifiedImpactTestingPopoverSaveButton" onClick={() => {
          updateDetails();
          dismissModal();
        }}
        >
          Save
        </IonButton>
        <IonButton color="secondary" class="cancel-button" onClick={() => dismissModal()}>Cancel</IonButton>
      </div>
    </div>
  );
};

export default CustomerSpecifiedSPWHTPopover;