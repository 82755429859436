import * as React from 'react';
import { useState, useEffect } from 'react';

import { IonLabel } from '@ionic/react';
import classNames from 'classnames';

import { HeaderTabTypes } from '@shared/types';
import { useOnScreen } from '@hooks/useOnScreen';
import { scrollTo } from '@shared/ui-utils';
import './header-tab.scss';

type Props = {
  tab: HeaderTabTypes;
  sectionRefMap: Map<HeaderTabTypes, React.MutableRefObject<HTMLDivElement>>;
  QALabel?: string;
  threshold? : number;
};

// HeaderTab - provides a mappable, active, highlighted header tab that is active when a reference to an observable section is on screen.
  const HeaderTab = ({tab, sectionRefMap, QALabel='Testing', threshold = 0.33}: Props): JSX.Element => {
    const [ tabRef, isOnScreen ] = useOnScreen(threshold);

    const [isActive, setIsActive] = useState<boolean>(undefined);

    sectionRefMap.set(tab, tabRef);

    useEffect(() => {
      if(isOnScreen) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }, [isOnScreen, tabRef]);

    return (
      <IonLabel
        class={classNames({
          'line-items-label': true,
          'section-tab': true,
          active: isActive
        })}
        data-testid={`QA${QALabel}Tab_${tab}`}
        onClick={() => {
          scrollTo(sectionRefMap.get(tab));
        }}
      >
        {tab}
      </IonLabel>
    );
  };

export default HeaderTab;
