import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import * as React from 'react';

import MappedSection from '@components/mapped-section/mapped-section';
import {
  ManufacturingSites,
  MeasurementSystems,
  OutsideServices,
  QuoteLineItemValueTypes,
  Regexes,
  SearchFields,
  SelectionTypes,
  TextAreaMax,
  Units,
} from '@constants';
import { AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate, HeaderTabTypes } from '@shared/types';
import SelectionContainer from '@components/selection-container/selection-container';
import { QuoteLineItemDetail } from '@interfaces/quote-line-item-detail';
import { useMapSetState } from '@hooks/useMapSetState';
import { calculateCostPerWeightUnit, calculateTotalShippingCost, calculateUOMValue, getDefaultVendorId, getSelectionOptions, getSelections, getVendors } from '@shared/quote-utils';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
import './outside-services-section-list.scss';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { getCurrencySymbol } from '@shared/utils';
import { Vendor } from '@interfaces/vendor';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';


const DurationOfEventRow = (props: {
  eventDuration: number,
  setEventDuration: (value: React.SetStateAction<number>) => void,
  label: string
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <IonLabel class='selection-label-os'>{props.label}</IonLabel>
      </IonCol>
      <IonCol>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          inputType='number'
          placeholder='0'
          regex={Regexes.PositiveInteger}
          style={{ width: '320px' }}
          containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
          onBlur={(eventDurationValue: React.SetStateAction<number>) => props.setEventDuration(eventDurationValue)}
          value={props.eventDuration}
        />
      </IonCol>
    </IonRow>
  );
};

const TotalTransitTimeRow = (props: {
  transitTime: number,
  setTransitTime: (value: React.SetStateAction<number>) => void
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <IonLabel class='selection-label-os'>Total Transit Time (days):</IonLabel>
      </IonCol>
      <IonCol>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          inputType='number'
          placeholder='0'
          regex={Regexes.PositiveInteger}
          style={{ width: '320px' }}
          containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
          onBlur={(transitTimeValue: React.SetStateAction<number>) => props.setTransitTime(transitTimeValue)}
          value={props.transitTime}
        />
      </IonCol>
    </IonRow>
  );
};

const CostOfEventPerLotRow = (props: {
  eventCost: number,
  setEventCost: (value: React.SetStateAction<number>) => void,
  label: string,
  disabled?: boolean
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <IonLabel class='selection-label-os'>{props.label}</IonLabel>
      </IonCol>
      <IonCol>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          inputType='number'
          placeholder='0'
          regex={Regexes.DecimalNumber}
          min='0'
          style={{ width: '320px' }}
          containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
          onBlur={(eventCostValue: React.SetStateAction<number>) => props.setEventCost(eventCostValue)}
          value={props.eventCost}
          formatter={(value) => {
            return value !== '' ? value.toFixed(2) : '';
          }}
          isDisabled={props.disabled}
        />
      </IonCol>
    </IonRow>
  );
};

const CostOfEventPerUnitRow = (props: {
  eventCost: number,
  setEventCost: (value: React.SetStateAction<number>) => void,
  label: string,
  disabled?: boolean
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <IonLabel class='selection-label-os'>{props.label}</IonLabel>
      </IonCol>
      <IonCol>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          inputType='number'
          placeholder='0'
          regex={Regexes.DecimalNumber}
          min='0'
          style={{ width: '320px' }}
          containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
          onBlur={(eventCostValue: React.SetStateAction<number>) => props.setEventCost(eventCostValue)}
          value={props.eventCost}
          formatter={(value) => {
            return value !== '' ? value.toFixed(2) : '';
          }}
          isDisabled={props.disabled}
        />
      </IonCol>
    </IonRow>
  );
};

const OutboundShippingCostPerUnitRow = (props: {
  outboundShippingCost: number,
  setOutboundShippingCost: (value: React.SetStateAction<number>) => void,
  setTotalOutboundShippingCost: (value: React.SetStateAction<number>) => void,
  label: string,
  isMetricUOM: boolean,
  qli: QuoteLineItem,
  advSpecUOM: MeasurementSystems,
  disabled?: boolean
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <IonLabel class='selection-label-os'>{props.label}</IonLabel>
      </IonCol>
      <IonCol>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          inputType='number'
          placeholder='0.000'
          regex={Regexes.DecimalNumber}
          min='0.000'
          style={{ width: '320px' }}
          containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
          onBlur={(outboundShippingCostValue: React.SetStateAction<number>) => {
            try {
              props.setOutboundShippingCost(outboundShippingCostValue);
              props.setTotalOutboundShippingCost(calculateTotalShippingCost(!props.isMetricUOM ? props.qli.Base_LB_PC__c : props.qli.Base_KG_PC__c, props.qli.Quantity__c, +outboundShippingCostValue));
            } catch(error) {
              console.log(error);
            }
          }}
          value={calculateUOMValue(props.advSpecUOM, !props.isMetricUOM ? MeasurementSystems.Imperial : MeasurementSystems.Metric, props.outboundShippingCost, Units.PricePerKilogram, Units.PricePerPound)}
          formatter={(value) => {
            return value !== '' ? value.toFixed(2) : '';
          }}
          isDisabled={props.disabled}
        />
      </IonCol>
    </IonRow>
  );
};

const OutboundShippingCostPerLotRow = (props: {
  totalOutboundShippingCost: number,
  setTotalOutboundShippingCost: (value: React.SetStateAction<number>) => void,
  setOutboundShippingCost: (value: React.SetStateAction<number>) => void,
  isMetricUOM: boolean,
  qli: QuoteLineItem,
  disabled?: boolean
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <IonLabel class='selection-label-os'>Total Shipping Cost ({getCurrencySymbol(props.qli.CurrencyIsoCode)} Per Lot):</IonLabel>
      </IonCol>
      <IonCol>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          inputType='number'
          placeholder='0.000'
          regex={Regexes.DecimalNumber}
          min='0.000'
          style={{ width: '320px' }}
          containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
          onBlur={(totalOutboundShippingCostValue: React.SetStateAction<number>) => {
            try {
              props.setTotalOutboundShippingCost(totalOutboundShippingCostValue);
              props.setOutboundShippingCost(calculateCostPerWeightUnit(!props.isMetricUOM ? props.qli.Base_LB_PC__c : props.qli.Base_KG_PC__c, props.qli.Quantity__c, +totalOutboundShippingCostValue));
            } catch(error) {
              console.log(error);
            }
          }}
          value={props.totalOutboundShippingCost}
          formatter={(value) => {
            return value !== '' ? value.toFixed(2) : '';
          }}
          isDisabled={props.disabled}
        />
      </IonCol>
    </IonRow>
  );
};

const InboundShippingCostPerUnitRow = (props: {
  inboundShippingCost: number,
  setInboundShippingCost: (value: React.SetStateAction<number>) => void,
  setTotalInboundShippingCost: (value: React.SetStateAction<number>) => void,
  label: string,
  isMetricUOM: boolean,
  qli: QuoteLineItem,
  advSpecUOM: MeasurementSystems,
  disabled?: boolean
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <IonLabel class='selection-label-os'>{props.label}</IonLabel>
      </IonCol>
      <IonCol>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          inputType='number'
          placeholder='0.000'
          regex={Regexes.DecimalNumber}
          min='0.000'
          style={{ width: '320px' }}
          containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
          onBlur={(inboundShippingCostValue: React.SetStateAction<number>) => {
            try {
              props.setTotalInboundShippingCost(calculateTotalShippingCost(!props.isMetricUOM ? props.qli.Base_LB_PC__c : props.qli.Base_KG_PC__c, props.qli.Quantity__c, +inboundShippingCostValue));
              props.setInboundShippingCost(inboundShippingCostValue);
            } catch(error) {
              console.log(error);
            }
          }}
          value={calculateUOMValue(props.advSpecUOM, !props.isMetricUOM ? MeasurementSystems.Imperial : MeasurementSystems.Metric, props.inboundShippingCost, Units.PricePerKilogram, Units.PricePerPound)}
          formatter={(value) => {
            return value !== '' ? value.toFixed(2) : '';
          }}
          isDisabled={props.disabled}
        />
      </IonCol>
    </IonRow>
  );
};

const InboundShippingCostPerLotRow = (props: {
  totalInboundShippingCost: number,
  setTotalInboundShippingCost: (value: React.SetStateAction<number>) => void,
  setInboundShippingCost: (value: React.SetStateAction<number>) => void,
  isMetricUOM: boolean,
  qli: QuoteLineItem,
  disabled?: boolean
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <IonLabel class='selection-label-os'>Total Shipping Cost ({getCurrencySymbol(props.qli.CurrencyIsoCode)} Per Lot):</IonLabel>
      </IonCol>
      <IonCol>
        <SelectionContainer
          type={SelectionTypes.TextInput}
          inputType='number'
          placeholder='0.000'
          regex={Regexes.DecimalNumber}
          min='0.000'
          style={{ width: '320px' }}
          containerStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
          onBlur={(totalInboundShippingCostValue: React.SetStateAction<number>) => {
            try {
              props.setTotalInboundShippingCost(totalInboundShippingCostValue);
              props.setInboundShippingCost(calculateCostPerWeightUnit(!props.isMetricUOM ? props.qli.Base_LB_PC__c : props.qli.Base_KG_PC__c, props.qli.Quantity__c, +totalInboundShippingCostValue));
            } catch(error) {
              console.log(error);
            }
          }}
          value={props.totalInboundShippingCost}
          formatter={(value) => {
            return value !== '' ? value.toFixed(2) : '';
          }}
          isDisabled={props.disabled}
        />
      </IonCol>
    </IonRow>
  );
};

const VendorRow = (props: {
  vendor: string,
  setVendor: (value: React.SetStateAction<string>) => void,
  vendors: Vendor[]
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <IonLabel class='selection-label-os'>Vendor</IonLabel>
      </IonCol>
      <IonCol>
        <SelectionContainer
          type={SelectionTypes.SearchableDropdown}
          noneFoundText='No vendors found'
          placeholder='Select Vendor'
          options={props.vendors}
          style={{ width: '320px', marginTop: '0px' }}
          suggestionDisplayFields={[SearchFields.VendorName]}
          onChange={(vendorSelection) => props.setVendor(vendorSelection.vendorId)}
          value={props.vendors?.find(vendor => vendor.vendorId === props.vendor)?.vendorName}
        />
      </IonCol>
    </IonRow>
  );
};

const ProductShipsToCustomerRow = (props: {
  shouldShipToCustomer: boolean,
  setShouldShipToCustomer: (value: React.SetStateAction<boolean>) => void,
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <CheckboxItem
          label='Product Ships to Customer'
          style={{ width: 'fit-content', marginTop: 'auto', marginBottom: 'auto', ['--background']: 'transparent' }}
          onIonChange={(checked) => props.setShouldShipToCustomer(checked)}
          checked={props.shouldShipToCustomer}
        />
      </IonCol>
    </IonRow>
  );
};

const AdditionalNotesRow = (props: {
  additionalNotes: string,
  setAdditionalNotes: (value: React.SetStateAction<string>) => void,
}) => {
  return (
    <IonRow>
      <IonCol size='2.8'>
        <IonLabel class='selection-label-os' style={{ marginTop: '10px' }}>
          Additional Notes
        </IonLabel>
      </IonCol>
      <IonCol>
        <SelectionContainer
          type={SelectionTypes.TextArea}
          placeholder='Comments...'
          style={{ width: '758px', marginTop: 'auto', marginBottom: 'auto' }}
          rows={6}
          maxLength={TextAreaMax.FiveThousand}
          onBlur={(additionalNotesValue: React.SetStateAction<string>) => props.setAdditionalNotes(additionalNotesValue)}
          value={props.additionalNotes}
          textAreaType='Additional Notes'
        />
      </IonCol>
    </IonRow>
  );
};

/** Individual Outside Services Sections to map over */
/** Outside Services (Testing - Backer) */
const OutsideServicesTestingBackerSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [eventDuration, setEventDuration] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingBackerEventDuration'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingBackerTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'testingBackerEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingBackerOutboundShippingCost'
  );

  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingBackerTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingBackerInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingBackerTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'testingBackerVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.TestingServicesBacker));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'testingBackerShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'testingBackerAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={eventDuration} setEventDuration={setEventDuration} label={'Duration of Event (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c} />
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.TestingServicesBacker)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Testing - Cladder) */
const OutsideServicesTestingCladderSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [eventDuration, setEventDuration] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladderEventDuration'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladderTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'testingCladderEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladderOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladderTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladderInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladderTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'testingCladderVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.TestingServicesCladder));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'testingCladderShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'testingCladderAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={eventDuration} setEventDuration={setEventDuration} label={'Duration of Event (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.TestingServicesCladder)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Testing - Clad Product) */
const OutsideServicesTestingCladProductSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [eventDuration, setEventDuration] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladProductEventDuration'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladProductTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'testingCladProductEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladProductOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladProductTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladProductInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'testingCladProductTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'testingCladProductVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.TestingServicesCladProduct));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'testingCladProductShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'testingCladProductAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={eventDuration} setEventDuration={setEventDuration} label={'Duration of Event (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.TestingServicesCladProduct)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Heat Treat: Backer) */
const OutsideServicesHeatTreatBackerSection = (
  uom: MeasurementSystems,
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors, selections } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [heatTreatmentTime, setHeatTreatmentTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatBackerTime'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatBackerTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'heatTreatBackerEventCost');
  const [totalEventCost, setTotalEventCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatBackerTotalEventCost'
  );
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatBackerOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatBackerTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatBackerInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatBackerTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'heatTreatBackerVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.BackerMetalHeatTreatment));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'heatTreatBackerShouldShipToCustomer'
  );
  const [heatTreatmentType, setHeatTreatmentType] = useMapSetState<string>(
    updateMap,
    qlidId,
    'heatTreatBackerHeatTreatmentType'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'heatTreatBackerAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={heatTreatmentTime} setEventDuration={setHeatTreatmentTime} label={'Time for Heat Treatment (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerUnitRow eventCost={calculateUOMValue(qlid.AdvancedSpecUOM__c, uom, eventCost, Units.Kilograms, Units.Pounds)} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`}/>
        <CostOfEventPerLotRow eventCost={totalEventCost} setEventCost={setTotalEventCost} label={`Total Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.BackerMetalHeatTreatment)} setVendor={setVendor}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os'>Heat Treatment Type</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select Heat Treatment Type'
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.PostbondHTType)?.options}
              style={{ width: '320px', marginTop: '0px' }}
              onChange={(heatTreatmentTypeSelection: { label: React.SetStateAction<string> }) =>
                setHeatTreatmentType(heatTreatmentTypeSelection.label)
              }
              defaultValue={
                getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.PostbondHTType)?.default
              }
              value={heatTreatmentType}
            />
          </IonCol>
        </IonRow>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Heat Treat: Cladder) */
const OutsideServicesHeatTreatCladderSection = (
  uom: MeasurementSystems,
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors, selections } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;
  
  const [heatTreatmentTime, setHeatTreatmentTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladderTime'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladderTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'heatTreatCladderEventCost');
  const [totalEventCost, setTotalEventCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladderTotalEventCost'
  );
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladderOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladderTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladderInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladderTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'heatTreatCladderVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.CladderMetalHeatTreatment));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'heatTreatCladderShouldShipToCustomer'
  );
  const [heatTreatmentType, setHeatTreatmentType] = useMapSetState<string>(
    updateMap,
    qlidId,
    'heatTreatCladderHeatTreatmentType'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'heatTreatCladderAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={heatTreatmentTime} setEventDuration={setHeatTreatmentTime} label={'Time for Heat Treatment (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerUnitRow eventCost={calculateUOMValue(qlid.AdvancedSpecUOM__c, uom, eventCost, Units.Kilograms, Units.Pounds)} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`}/>
        <CostOfEventPerLotRow eventCost={totalEventCost} setEventCost={setTotalEventCost} label={`Total Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.CladderMetalHeatTreatment)} setVendor={setVendor}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os'>Heat Treatment Type</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select Heat Treatment Type'
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.PostbondHTType)?.options}
              style={{ width: '320px', marginTop: '0px' }}
              onChange={(heatTreatmentTypeSelection: { label: React.SetStateAction<string> }) =>
                setHeatTreatmentType(heatTreatmentTypeSelection.label)
              }
              defaultValue={
                getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.PostbondHTType)?.default
              }
              value={heatTreatmentType}
            />
          </IonCol>
        </IonRow>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Heat Treat: Clad Product) */
const OutsideServicesHeatTreatCladProductSection = (
  uom: MeasurementSystems,
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors, selections } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [heatTreatmentTime, setHeatTreatmentTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladProductTime'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladProductTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladProductEventCost'
  );
  const [totalEventCost, setTotalEventCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladProductTotalEventCost'
  );
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladProductOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladProductTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladProductInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'heatTreatCladProductTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'heatTreatCladProductVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.PostbondHeatTreatment));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'heatTreatCladProductShouldShipToCustomer'
  );
  const [heatTreatmentType, setHeatTreatmentType] = useMapSetState<string>(
    updateMap,
    qlidId,
    'heatTreatCladProductHeatTreatmentType'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'heatTreatCladProductAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={heatTreatmentTime} setEventDuration={setHeatTreatmentTime} label={'Time for Heat Treatment (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerUnitRow disabled={manufacturingSite === ManufacturingSites.DE01} eventCost={calculateUOMValue(qlid.AdvancedSpecUOM__c, uom, eventCost, Units.Kilograms, Units.Pounds)} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`}/>
        <CostOfEventPerLotRow disabled={manufacturingSite === ManufacturingSites.DE01} eventCost={totalEventCost} setEventCost={setTotalEventCost} label={`Total Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow disabled={manufacturingSite === ManufacturingSites.DE01} outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow disabled={manufacturingSite === ManufacturingSites.DE01} totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow disabled={manufacturingSite === ManufacturingSites.DE01} inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow disabled={manufacturingSite === ManufacturingSites.DE01} totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.PostbondHeatTreatment)} setVendor={setVendor}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os'>Heat Treatment Type</IonLabel>
          </IonCol>
          <IonCol>
            <SelectionContainer
              type={SelectionTypes.DropDown}
              placeholder='Select Heat Treatment Type'
              options={getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.PostbondHTType)?.options}
              style={{ width: '320px', marginTop: '0px' }}
              onChange={(heatTreatmentTypeSelection: { label: React.SetStateAction<string> }) =>
                setHeatTreatmentType(heatTreatmentTypeSelection.label)
              }
              defaultValue={
                getSelections(selections, manufacturingSite, QuoteLineItemValueTypes.PostbondHTType)?.default
              }
              value={heatTreatmentType}
            />
          </IonCol>
        </IonRow>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Welding) */
const OutsideServicesWeldingSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [weldingTime, setWeldingTime] = useMapSetState<string, number>(updateMap, qlidId, 'weldingTime');
  const [transitTime, setTransitTime] = useMapSetState<string, number>(updateMap, qlidId, 'weldingTransitTime');
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'weldingEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'weldingOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'weldingTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'weldingInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'weldingTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'weldingVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.Welding));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'weldingShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'weldingAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={weldingTime} setEventDuration={setWeldingTime} label={'Time for Welding (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.Welding)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (X-Ray) */
const OutsideServicesXRaySection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [xRayTime, setXRayTime] = useMapSetState<string, number>(updateMap, qlidId, 'xRayTime');
  const [transitTime, setTransitTime] = useMapSetState<string, number>(updateMap, qlidId, 'xRayTransitTime');
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'xRayEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'xRayOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'xRayTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'xRayInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'xRayTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'xRayVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.XRayServices));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'xRayShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'xRayAdditionalNotes'
  );

    const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={xRayTime} setEventDuration={setXRayTime} label={'Time for X-Raying (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow
        disabled={manufacturingSite === ManufacturingSites.DE01}
        eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}
        />
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow disabled={manufacturingSite === ManufacturingSites.DE01}
 outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}
        />
        <OutboundShippingCostPerLotRow disabled={manufacturingSite === ManufacturingSites.DE01}
 totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}
        />
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow disabled={manufacturingSite === ManufacturingSites.DE01}
 inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}
        />
        <InboundShippingCostPerLotRow disabled={manufacturingSite === ManufacturingSites.DE01}
 totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}
        />
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.XRayServices)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Flattening (Before Cutting)) */
const OutsideServicesPreCutFlatteningSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [flatteningTime, setFlatteningTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'preCutFlatteningTime'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'preCutFlatteningTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'preCutFlatteningEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'preCutFlatteningOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'preCutFlatteningTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'preCutFlatteningInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'preCutFlatteningTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'preCutFlatteningVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.FlatteningBeforeCutting));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'testingBackerEventDescription'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'preCutFlatteningAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={flatteningTime} setEventDuration={setFlatteningTime} label={'Time for Flattening (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.FlatteningBeforeCutting)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Cutting) */
const OutsideServicesCuttingSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [cuttingTime, setCuttingTime] = useMapSetState<string, number>(updateMap, qlidId, 'cuttingTime');
  const [transitTime, setTransitTime] = useMapSetState<string, number>(updateMap, qlidId, 'cuttingTransitTime');
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'cuttingEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'cuttingOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'cuttingTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'cuttingInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'cuttingTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'cuttingVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.Cutting));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'cuttingShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'cuttingAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={cuttingTime} setEventDuration={setCuttingTime} label={'Time for Cutting (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.Cutting)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Waterjet Cutting) */
const OutsideServicesWaterjetCuttingSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [cuttingTime, setCuttingTime] = useMapSetState<string, number>(updateMap, qlidId, 'waterjetCuttingTime');
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'waterjetCuttingTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'waterjetCuttingEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'waterjetCuttingOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'waterjetCuttingTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'waterjetCuttingInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'waterjetCuttingTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'waterjetCuttingVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.WaterjetCutting));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'waterjetCuttingShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'waterjetCuttingAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={cuttingTime} setEventDuration={setCuttingTime} label={'Time for Cutting (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.WaterjetCutting)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Flattening (After Cutting)) */
const OutsideServicesPostCutFlatteningSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [flatteningTime, setFlatteningTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'postCutFlatteningTime'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'postCutFlatteningTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'postCutFlatteningEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'postCutFlatteningOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'postCutFlatteningTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'postCutFlatteningInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'postCutFlatteningTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'postCutFlatteningVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.FlatteningAfterCutting));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'postCutFlatteningShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'postCutFlatteningAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={flatteningTime} setEventDuration={setFlatteningTime} label={'Time for Flattening (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.FlatteningAfterCutting)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Machining) */
const OutsideServicesMachiningSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [machiningTime, setMachiningTime] = useMapSetState<string, number>(updateMap, qlidId, 'machiningTime');
  const [transitTime, setTransitTime] = useMapSetState<string, number>(updateMap, qlidId, 'machiningTransitTime');
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'machiningEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'machiningOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'machiningTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'machiningInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'machiningTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'machiningVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.Machining));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'machiningShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'machiningAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={machiningTime} setEventDuration={setMachiningTime} label={'Time for Machining (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.Machining)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Inspection - Backer) */
const OutsideServicesInspectionBackerSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [inspectionTime, setInspectionTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionBackerInspectionTime'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionBackerTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'inspectionBackerEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionBackerOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionBackerTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionBackerInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionBackerTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'inspectionBackerVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.InspectionBacker));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'inspectionBackerShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'inspectionBackerAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={inspectionTime} setEventDuration={setInspectionTime} label={'Time for Inspection (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.InspectionBacker)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Inspection - Cladder) */
const OutsideServicesInspectionCladderSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [inspectionTime, setInspectionTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladderInspectionTime'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladderTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'inspectionCladderEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladderOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladderTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladderInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladderTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'inspectionCladderVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.InspectionCladder));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'inspectionCladderShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'inspectionCladderAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={inspectionTime} setEventDuration={setInspectionTime} label={'Time for Inspection (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.InspectionCladder)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Inspection - Clad Product) */
const OutsideServicesInspectionCladProductSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [inspectionTime, setInspectionTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladProductInspectionTime'
  );
  const [transitTime, setTransitTime] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladProductTransitTime'
  );
  const [eventCost, setEventCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladProductEventCost'
  );
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladProductOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladProductTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladProductInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'inspectionCladProductTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'inspectionCladProductVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.InspectionCladProduct));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'inspectionCladProductShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'inspectionCladProductAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={inspectionTime} setEventDuration={setInspectionTime} label={'Time for Inspection (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.InspectionCladProduct)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** Outside Services (Packaging) */
const OutsideServicesPackagingSection = (
  isMetricUOM: boolean,
  updateMap: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  qli: QuoteLineItem,
  qlid: QuoteLineItemDetail
) => {
  const { dataState } = useDataState();
  const { vendors } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { Manufacturing_Site__c: manufacturingSite } = quote;
  const qlidId = qlid.Id || qlid.associatedLineItem;

  const [packagingTime, setPackagingTime] = useMapSetState<string, number>(updateMap, qlidId, 'packagingTime');
  const [transitTime, setTransitTime] = useMapSetState<string, number>(updateMap, qlidId, 'packagingTransitTime');
  const [eventCost, setEventCost] = useMapSetState<string, number>(updateMap, qlidId, 'packagingEventCost');
  const [outboundShippingCost, setOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'packagingOutboundShippingCost'
  );
  const [totalOutboundShippingCost, setTotalOutboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'packagingTotalOutboundShippingCost'
  );
  const [inboundShippingCost, setInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'packagingInboundShippingCost'
  );
  const [totalInboundShippingCost, setTotalInboundShippingCost] = useMapSetState<string, number>(
    updateMap,
    qlidId,
    'packagingTotalInboundShippingCost'
  );
  const [vendor, setVendor] = useMapSetState<string>(updateMap, qlidId, 'packagingVendor', getDefaultVendorId(vendors, manufacturingSite, OutsideServices.Packaging));
  const [shouldShipToCustomer, setShouldShipToCustomer] = useMapSetState<string, boolean>(
    updateMap,
    qlidId,
    'packagingShouldShipToCustomer'
  );
  const [additionalNotes, setAdditionalNotes] = useMapSetState<string>(
    updateMap,
    qlidId,
    'packagingAdditionalNotes'
  );

  // returns 'Kg' or 'Lb' depending on whether metric or imperial
  const kgOrLbString = isMetricUOM ? 'Kg' : ' Lb';

  return (
    <>
      <IonGrid>
        <DurationOfEventRow eventDuration={packagingTime} setEventDuration={setPackagingTime} label={'Time for Packaging (days):'}/>
        <TotalTransitTimeRow transitTime={transitTime} setTransitTime={setTransitTime}/>
        <CostOfEventPerLotRow eventCost={eventCost} setEventCost={setEventCost} label={`Cost of Event (${getCurrencySymbol(qli.CurrencyIsoCode)} Per Lot):`}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Outbound Shipping [NC to Vendor]</IonLabel>
          </IonCol>
        </IonRow>
        <OutboundShippingCostPerUnitRow outboundShippingCost={outboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <OutboundShippingCostPerLotRow totalOutboundShippingCost={totalOutboundShippingCost} setTotalOutboundShippingCost={setTotalOutboundShippingCost} setOutboundShippingCost={setOutboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <IonRow>
          <IonCol size='2.8'>
            <IonLabel class='selection-label-os bold'>Inbound Shipping [From Vendor to NC]</IonLabel>
          </IonCol>
        </IonRow>
        <InboundShippingCostPerUnitRow inboundShippingCost={inboundShippingCost} setInboundShippingCost={setInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} label={`Shipping Cost (${getCurrencySymbol(qli.CurrencyIsoCode)} Per ${kgOrLbString}):`} isMetricUOM={isMetricUOM} qli={qli} advSpecUOM={qlid.AdvancedSpecUOM__c}/>
        <InboundShippingCostPerLotRow totalInboundShippingCost={totalInboundShippingCost} setTotalInboundShippingCost={setTotalInboundShippingCost} setInboundShippingCost={setInboundShippingCost} isMetricUOM={isMetricUOM} qli={qli}/>
        <VendorRow vendor={vendor} vendors={getVendors(vendors, manufacturingSite, OutsideServices.Packaging)} setVendor={setVendor}/>
        <ProductShipsToCustomerRow shouldShipToCustomer={shouldShipToCustomer} setShouldShipToCustomer={setShouldShipToCustomer}/>
        <AdditionalNotesRow additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}/>
      </IonGrid>
    </>
  );
};

/** OutsideServicesSectionList contains a number of collapsable, outside services sections to map over  */
const OusideServicesSectionList = (
  uom: MeasurementSystems,
  isMetricUOM: boolean,
  assemblyGroupOutsideServiceItemFieldsToUpdate: Map<string, AdvSpecsAssemblyGroupsOutsideServiceItemFieldsToUpdate>,
  displayedQuoteLineItem: QuoteLineItem,
  displayedQuoteLineItemDetail: QuoteLineItemDetail
): JSX.Element => {
  
  // sections - directory of Outside Services Section components to map over
  // prettier-ignore
  const outsideServicesSectionsMap: Map<HeaderTabTypes, () => JSX.Element> = new Map([
    [OutsideServices.TestingServicesBacker, () => OutsideServicesTestingBackerSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.TestingServicesCladder, () => OutsideServicesTestingCladderSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.TestingServicesCladProduct, () => OutsideServicesTestingCladProductSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.BackerMetalHeatTreatment, () => OutsideServicesHeatTreatBackerSection(uom, isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.CladderMetalHeatTreatment, () => OutsideServicesHeatTreatCladderSection(uom, isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.PostbondHeatTreatment, () => OutsideServicesHeatTreatCladProductSection(uom, isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.Welding, () => OutsideServicesWeldingSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.XRayServices, () => OutsideServicesXRaySection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.FlatteningBeforeCutting, () => OutsideServicesPreCutFlatteningSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.Cutting, () => OutsideServicesCuttingSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.WaterjetCutting, () => OutsideServicesWaterjetCuttingSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.FlatteningAfterCutting, () => OutsideServicesPostCutFlatteningSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.Machining, () => OutsideServicesMachiningSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.InspectionBacker, () => OutsideServicesInspectionBackerSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.InspectionCladder, () => OutsideServicesInspectionCladderSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.InspectionCladProduct, () => OutsideServicesInspectionCladProductSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
    [OutsideServices.Packaging, () => OutsideServicesPackagingSection(isMetricUOM, assemblyGroupOutsideServiceItemFieldsToUpdate, displayedQuoteLineItem, displayedQuoteLineItemDetail),],
  ]);

  return (
    <>
      <div className='assembly-group-outside-services-section'>
        <IonGrid>
          {/* Map over the section headers and render the section's components */}
          {Array.from(outsideServicesSectionsMap.keys())
          .map((tab, idx) => {
            return <MappedSection
                    component={outsideServicesSectionsMap.get(tab)}
                    label={tab}
                    QALabel={'OrderEntryPopover_OutsideServicesSection'}
                    index={idx}
                    showFirstHeader
                    key={tab}
                    isCollapsible
                   />;
          })}
        </IonGrid>
      </div>
    </>
  );
};

export default OusideServicesSectionList;
