import { DocumentData } from '@interfaces/document-data';
import { getBadgeText } from './utils';

// Maps some document properties so that the UI can consume the document
export const mapDocumentData = async (documentData: DocumentData, lastViewed?: any) => {
  return {
    ...documentData.Document__r,
    CreatedDate: documentData.LastModifiedDate || '',
    badgeText: await getBadgeText(documentData.Document__r.Id, new Date(documentData.Document__r.CreatedDate), undefined, lastViewed),
    NC_Line_Item__c: documentData.NC_Line_Item__c
  };
};

// Returns the unique documents by id
export const uniqueDocuments = (documentsList) => {
  const uniqueDocuments = [];
  const documentIdsIncluded = [];
  
  for (const document of documentsList) {
    if(!documentIdsIncluded.includes(document.Id)){
      documentIdsIncluded.push(document.Id);
      uniqueDocuments.push(document);
    }
  }

  return uniqueDocuments;
};