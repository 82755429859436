import { useAppAccordionGroup } from '@components/app-accordion-group/app-accordion-context';
import AppAccordionGroup from '@components/app-accordion-group/app-accordion-group';
import AppAccordion from '@components/app-accordion-group/app-accordion/app-accordion';
import { ComboGroupActionTypes, useComboGroup } from '@context/combo-group-context';
import { useQuoteState } from '@context/quote-context';
import { useQuoteFormContext } from '@context/quote-form-context';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PanelItem from '../panel-item/panel-item';
import _ from 'lodash-es';
import { MergeFormKeyHandler, toDotNotation } from '../assembly-group-form-util';

const ExpandLink = () => {
  const { isAllExpanded, expandAll, removeAll } = useAppAccordionGroup();

  const onClick = (e) => {
    e.preventDefault();
    if (isAllExpanded) {
      return removeAll();
    }

    return expandAll();
  };

  return <div className='ass-grp--expand'>
    <a href="#" onClick={onClick}>
      {isAllExpanded ? 'Collapse All' : 'Expand All'}
    </a>
  </div>
};

const PanelList = () => {
  const { lineItemId } = useParams<{lineItemId: string}>();
  const {
    quoteState,
  } = useQuoteState();
  const {
    state: { comboGroup },
    dispatch: comboGroupDispatch,
  } = useComboGroup();
  const {
    quoteLineItems: formQuoteLineItems,
    onUpdateQuoteLineItemById,
    onUpdateQuoteLineItemsByArray,
  } = useQuoteFormContext();
  const { quote } = quoteState;
  const areaId = quote?.Manufacturing_Site__c;
  const activeComboKey = Object.keys(comboGroup).find((key) => {
    return comboGroup[key].includes(lineItemId);
  });
  const formGroup = formQuoteLineItems
    .filter(qli => (comboGroup[activeComboKey] || []).includes(qli.Id || qli.displayId))
    .map((qli) => {
      return {
        ...toDotNotation(qli),
        Id: qli.Id,
        displayId: qli.displayId,
        Parent_Line__c: qli.Parent_Line__c,
      };
    });

  useEffect(() => {
    comboGroupDispatch({
      type: ComboGroupActionTypes.updateOnlyShowSpecifyPerAssemblyField,
      payload: true,
    });

    comboGroupDispatch({
      type: ComboGroupActionTypes.setSelectedKey,
      payload: activeComboKey
    });
  }, []);

  const onUpdateFormValue = (id, key, value) => {
    const form: any = formGroup.find(f => (f.Id || f.displayId) === id);
    const associatedForms = formGroup.filter((f) => {
      return f.Parent_Line__c === form.Line__c;
    });

    if (associatedForms.length) {
      const payload = [
        form,
        ...associatedForms
      ].map(f => {
        return {
          ...f,
          [key]: value,
        };
      });

      onUpdateQuoteLineItemsByArray(payload);
    } else {
      form[key] = value;
      onUpdateQuoteLineItemById(id, form)
    }
  }

  const onUpdateFormByPayload = (id, obj) => {
    const form: any = formGroup.find(f => (f.Id || f.displayId) === id);
    const associatedForms = formGroup.filter((f) => {
      return f.Parent_Line__c === form.Line__c;
    });

    const newItems = Object.keys(obj).reduce((prev, key) => {
      const handler = MergeFormKeyHandler[key];
      const value = obj[key];
      let newItem = {
        ...prev,
        [key]: value,
      };

      if (handler) {
        const additionalUpdate = handler(value, form);
        newItem = {
          ...newItem,
          ...additionalUpdate,
        };
      }

      return newItem;
    }, obj);


    if (associatedForms.length) {
      const payload = [
        form,
        ...associatedForms
      ].map(f => {
        return {
          ...f,
          ...newItems,
        };
      });

      onUpdateQuoteLineItemsByArray(payload);
    } else {
      const result = {
        ...form,
        ...newItems,
      };

      onUpdateQuoteLineItemById(id, result);
    }
  }

  return <AppAccordionGroup multiple={true}>
    <ExpandLink/>
    {
      formGroup
        .filter(qli => !qli.Parent_Line__c)
        .sort((a: any, b: any) => {
          return a.Line__c - b.Line__c;
        })
        .map((form: any, index) =>
          <AppAccordion title={`Assembly Group ${(index + 1)}`} value={(index + 1).toString()} key={form.Line__c}>
            <PanelItem
              updateForm={(key: string, value: any) => onUpdateFormValue((form.Id || form.displayId), key, value)}
              updateFormByObject={(payload: any) => onUpdateFormByPayload((form.Id || form.displayId), payload)}
              form={form}
              quote={quote}
              areaId={areaId}
            />
          </AppAccordion>
      )
    }
  </AppAccordionGroup>
};

export default PanelList;