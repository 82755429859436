import * as React from 'react';
import { useState } from 'react';
import { IonCol, IonGrid, IonImg, IonLabel, IonRow } from '@ionic/react';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import './sizing-statistics-popover.scss';
import { useDarkMode } from '@hooks/useDarkMode';
import { MetalTypes, QuoteLineItemAdditionalOptions, RawMaterialUsage, SizingDescriptions } from '@constants';
import { formatNumberToUOMString } from '@shared/sizing-utils';
import { calculateCosmeticGrindingCladThicknessAddition, calculateThicknessAddition, getFlatteningThinningGroup } from '@shared/quote-utils';
import { useDataState } from '@context/data-context';
import { useQuoteState } from '@context/quote-context';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { LocalUomDisplayToggle } from '@components/uom-toggle/uom-toggle';
import { useLocalUom } from '@hooks/useLocalUom';

type Props = {
  setShowPopover: (arg0: { showPopover: boolean; event: any }) => void;
  lineItemId?: string;
};

const SizingStatisticsPopover = ({
  lineItemId,
  setShowPopover,
}: Props) => {
  const { dataState } = useDataState();
  const { rawMaterials } = dataState;

  const { quoteState } = useQuoteState();
  const { quote } = quoteState;
  const { quoteLineItems } = quote;
  const { Manufacturing_Site__c: manufacturingSite } = quote;

  const { darkMode } = useDarkMode();
  
  const { localUom, setLocalUom, isDisplayMetric } = useLocalUom();
  
  const qliDisplayed = quoteLineItems?.find((qli: QuoteLineItem) =>
    qli.Id ? qli.Id === lineItemId : qli.displayId === lineItemId
  ) || (quoteLineItems || [])[0];

  const baseMetal = rawMaterials.find(
    rawMaterial =>
      rawMaterial.name === qliDisplayed.BaseMetal__c &&
      rawMaterial.usage === RawMaterialUsage.Base &&
      rawMaterial.dataAreaId.includes(manufacturingSite)
  );
  const cladMetal = rawMaterials.find(
    rawMaterial =>
      rawMaterial.name === qliDisplayed.CladMetal__c &&
      rawMaterial.usage === RawMaterialUsage.Clad &&
      rawMaterial.dataAreaId.includes(manufacturingSite)
  );

  const baseType = qliDisplayed.Base_Type__c;
  const cladType = qliDisplayed.Clad_Type__c;
  const cosmeticGrinding = (qliDisplayed.Additional_Options__c?.split(';') || []).includes(QuoteLineItemAdditionalOptions.CosmeticGrinding);
  const cosmeticGrindingCladThicknessAddition = cosmeticGrinding ? calculateCosmeticGrindingCladThicknessAddition(cladMetal, manufacturingSite, localUom) : 0;

  const SectionHeader = ({ label }: { label: string }) => {
    return (
      <IonRow>
        <IonCol>
          <IonLabel class='label-header' data-testid={'QASizingStatisticsPopoverSection_' + label}>
            {label}
          </IonLabel>
        </IonCol>
      </IonRow>
    );
  };

  const SectionsFooter = ({ footerLabel }) => (
    <>
      <IonRow>
        <IonCol>
          <IonLabel class='sections-footer-header-label'>{footerLabel.label}</IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel class='sections-footer-label'>{footerLabel.description}</IonLabel>
        </IonCol>
      </IonRow>
    </>
  );

  const Section = ({
    label,
    description,
    value,
    setFooterLabel,
  }: {
    label: string;
    description: string;
    value: string;
    setFooterLabel: React.Dispatch<React.SetStateAction<{ label: string; description: string }>>;
  }) => {
    return (
      <>
        <IonRow onMouseOver={() => setFooterLabel({ label, description })}>
          <IonCol>
            <IonLabel class='section-label'>{label}</IonLabel>
          </IonCol>
          <IonCol>
            <IonLabel class='section-label'>{value}</IonLabel>
          </IonCol>
        </IonRow>
      </>
    );
  };

  const Sections = () => {
    const [footerLabel, setFooterLabel] = useState({ label: '', description: '' });

    return (
      <>
        <div className='sections'>
          <IonGrid>
            <div className='fields-container'>
              <SectionHeader label={'Adders'} />
              <Section
                label={'Specification Adder'}
                description={SizingDescriptions.SpecificationAdder}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Specification_Adder_MM__c, true)
                    : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Specification_Adder_IN__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Edge Allowance'}
                description={SizingDescriptions.EdgeAllowance}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Edge_Allowance_MM__c, true)
                    : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Edge_Allowance_IN__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Booster Allowance'}
                description={SizingDescriptions.BoosterAllowance}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Booster_Allowance_MM__c, true)
                    : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Booster_Allowance_IN__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Kerf Allowance'}
                description={SizingDescriptions.KerfAllowance}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Kerf_Allowance_MM__c, true)
                    : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Kerf_Allowance_IN__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Clad Overhang'}
                description={SizingDescriptions.CladOverhang}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Clad_Overhang_MM__c, true)
                    : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Clad_Overhang_IN__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Clad Overhang Thin'}
                description={SizingDescriptions.CladOverhangThin}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Clad_Overhang_Thin_MM__c, true)
                    : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Clad_Overhang_Thin_IN__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Base Metal Length Adder'}
                description={SizingDescriptions.BaseMetalLengthAdder}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Base_Metal_Length_Adder_MM__c, true)
                    : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Base_Metal_Length_Adder_IN__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Anvil Edge Allowance Adder'}
                description={SizingDescriptions.AnvilEdgeAllowanceAdder}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Anvil_Edge_Allowance_Adder_MM__c, true)
                    : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Anvil_Edge_Allowance_Adder_IN__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <SectionHeader label={'Base Information'} />
              <Section
                label={'Base Width'}
                description={SizingDescriptions.BaseWidth}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Base_Width_mm__c, true)
                    : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Base_Width_in__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Base Length'}
                description={SizingDescriptions.BaseLength}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Base_Length_mm__c, true)
                    : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Base_Length_in__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Base Nominal Value'}
                description={SizingDescriptions.BaseNominalValue}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed?.Base_Nom_mm__c, true)
                    : formatNumberToUOMString(qliDisplayed?.Base_Nom_in__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section label={'Base Thinning Value'} description={SizingDescriptions.BaseThinningValue} value={isDisplayMetric
                ? formatNumberToUOMString(calculateThicknessAddition(baseMetal, manufacturingSite, localUom, qliDisplayed?.Base_TK_mm__c), true)
                : formatNumberToUOMString(calculateThicknessAddition(baseMetal, manufacturingSite, localUom, qliDisplayed?.Base_TK_in__c))}
                setFooterLabel={setFooterLabel}
              />
              <Section label={'Base FT Value'} description={SizingDescriptions.BaseFTValue} value={isDisplayMetric
                ? formatNumberToUOMString(baseType === MetalTypes.FandT ? getFlatteningThinningGroup(baseMetal, manufacturingSite, localUom, qliDisplayed?.Total_TK_MM__c, qliDisplayed?.Width_mm__c).metric.ftFactorWidth || qliDisplayed?.Base_FT_Value_mm__c : 0, true)
                : formatNumberToUOMString(baseType === MetalTypes.FandT ? getFlatteningThinningGroup(baseMetal, manufacturingSite, localUom, qliDisplayed?.Total_TK_IN__c, qliDisplayed?.Width_in__c).imperial.ftFactorWidth || qliDisplayed?.Base_FT_Value_in__c : 0)}
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Base Thickness'}
                description={SizingDescriptions.BaseThickness}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed?.Base_Min_mm__c, true)
                    : formatNumberToUOMString(qliDisplayed?.Base_Min_in__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <SectionHeader label={'Clad Information'} />
              <Section label={'Clad Width'} description={SizingDescriptions.CladWidth} value={isDisplayMetric
                ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Clad_Width_mm__c, true)
                : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Clad_Width_in__c)}
                setFooterLabel={setFooterLabel}
              />
              <Section label={'Clad Length'} description={SizingDescriptions.CladLength} value={isDisplayMetric
                ? formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Clad_Length_mm__c, true)
                : formatNumberToUOMString(qliDisplayed.quoteLineItemDetail?.Clad_Length_in__c)}
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Clad Nominal Value'}
                description={SizingDescriptions.CladNominalValue}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed?.Clad_Nom_mm__c, true)
                    : formatNumberToUOMString(qliDisplayed?.Clad_Nom_in__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <Section label={'Clad Thinning Value'} description={SizingDescriptions.CladThinningValue} value={isDisplayMetric ? formatNumberToUOMString(calculateThicknessAddition(cladMetal, manufacturingSite, localUom, qliDisplayed?.Clad_TK_mm__c) + cosmeticGrindingCladThicknessAddition, true) : formatNumberToUOMString(calculateThicknessAddition(cladMetal, manufacturingSite, localUom, qliDisplayed.Clad_TK_in__c) + cosmeticGrindingCladThicknessAddition)} setFooterLabel={setFooterLabel}/>
              <Section label={'Clad FT Value'} description={SizingDescriptions.CladFTValue}
                value={isDisplayMetric ?
                  formatNumberToUOMString(cladType === MetalTypes.FandT ? getFlatteningThinningGroup(cladMetal, manufacturingSite, localUom, qliDisplayed?.Total_TK_MM__c, qliDisplayed?.Width_mm__c).metric.ftFactorWidth || qliDisplayed?.Clad_FT_Value_mm__c : 0, true) :
                  formatNumberToUOMString(cladType === MetalTypes.FandT ? getFlatteningThinningGroup(cladMetal, manufacturingSite, localUom, qliDisplayed?.Total_TK_IN__c, qliDisplayed?.Width_in__c).imperial.ftFactorWidth || qliDisplayed?.Clad_FT_Value_in__c : 0)}
                  setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Clad Thickness'}
                description={SizingDescriptions.CladThickness}
                value={
                  isDisplayMetric
                    ? formatNumberToUOMString(qliDisplayed?.Clad_Min_mm__c, true)
                    : formatNumberToUOMString(qliDisplayed?.Clad_Min_in__c)
                }
                setFooterLabel={setFooterLabel}
              />
              <SectionHeader label={'Plate Information'} />
              <Section
                label={'Method'}
                description={SizingDescriptions.Method}
                value={qliDisplayed?.PlateMethod__c}
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Line Number'}
                description={SizingDescriptions.LineNumber}
                value={qliDisplayed?.Line__c}
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Lot Quantity'}
                description={SizingDescriptions.LotQuantity}
                value={String(qliDisplayed.quoteLineItemDetail?.Clads__c)}
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Pieces In Width'}
                description={SizingDescriptions.PiecesInWidth}
                value={String(qliDisplayed.quoteLineItemDetail?.Wide__c)}
                setFooterLabel={setFooterLabel}
              />
              <Section
                label={'Pieces In Length'}
                description={SizingDescriptions.PiecesInLength}
                value={String(qliDisplayed.quoteLineItemDetail?.Long__c)}
                setFooterLabel={setFooterLabel}
              />
            </div>
            <SectionsFooter footerLabel={footerLabel} />
          </IonGrid>
        </div>
      </>
    );
  };

  return (
    <div className='popover sizing-statistics'>
      <IonImg
        src={!darkMode ? closeIcon : closeIconWhite}
        class='close-icon'
        onClick={() => setShowPopover({ showPopover: false, event: undefined })}
      />
      <div className='title-container'>
        <IonLabel class='title' data-testid={'QASizingStatisticsPopoverSizingStatisticsHeader'}>
          Sizing Statistics
        </IonLabel>
        {/* adjustment container for uom toggler */}
        <div style={{margin: '0px 44px 15px 0'}}>
          <LocalUomDisplayToggle uom={localUom} setUom={setLocalUom} />
        </div>
      </div>
      <hr className='mid-rule' />
      <div className='sections-container'>
        <Sections />
      </div>
    </div>
  );
};

export default SizingStatisticsPopover;
