import React from 'react';
import { IonButton, IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import './quote-setup-popover.scss';
import { SelectionTypes, ManufacturingSites, QuoteLineItemValueTypes, MeasurementSystems } from '@constants';
import SelectionContainer from '@components/selection-container/selection-container';
import { environment } from '@environment';
import { getFreightZones, getSelectionOptions } from '@shared/quote-utils';
import CheckboxItem from '@components/checkbox-item/checkbox-item';
import { useDataState } from '@context/data-context';
import { QuoteActionTypes, useQuoteDispatch, useQuoteState } from '@context/quote-context';
import { useDisplayUomWithInit } from '@context/useDisplayUomWithInit';

const QuoteSetupPopover = (props) => {
  const { dataState } = useDataState();
  const { freight,
    selections,
    paymentTermsContent,
    contractTermsContent,
    cancellationTermsContent,
    validityContent } = dataState;
  const { dispatchQuoteState } = useQuoteDispatch();
  const { quoteState } = useQuoteState();
  const { quote } = quoteState;

  const { setDisplayUom } = useDisplayUomWithInit();

  const [manufacturingSite, setManufacturingSite] = React.useState<ManufacturingSites>(quote.Manufacturing_Site__c);
  const [uom, setUOM] = React.useState<MeasurementSystems>(quote.DocumentUOM__c as MeasurementSystems);
  const [freightZone, setFreightZone] = React.useState(quote.Freight_Zone__c?.replace('_', ' '));
  const [incoterm, setIncoterm] = React.useState(quote.Incoterms__c);
  const [freightZoneOptions, setFreightZoneOptions] = React.useState(getFreightZones(freight, manufacturingSite));
  const [incotermOptions, setIncotermOptions] = React.useState(getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.Incoterms)?.options);
  const [manufacturingSiteError, setManufacturingSiteError] = React.useState(false);
  const [freightZoneError, setFreightZoneError] = React.useState(false);
  const [incotermError, setIncotermError] = React.useState(false);
  const [exportPackaging, setExportPackaging] = React.useState<boolean>(quote.Export_Packaging__c);
  const [revisionNotes, setRevisionNotes] = React.useState<string>(quote.RevisionNotes__c);
  const [destinationNotes, setDestinationNotes] = React.useState<string>(quote.DestinationNotes__c);

  const city = quote.Account__r?.ShippingCity ?? '';
  const state = quote.Account__r?.ShippingState ?? '';
  const country = quote.Account__r?.ShippingCountry ?? '';

  const standardPaymentTerms = paymentTermsContent?.[manufacturingSite];
  const standardContractTerms = contractTermsContent?.[manufacturingSite];
  const standardCancellationTerms = cancellationTermsContent?.[manufacturingSite];
  const standardValidityTerms = validityContent?.[manufacturingSite];

  React.useEffect(() => {
    setFreightZoneOptions(getFreightZones(freight, manufacturingSite));
    setIncotermOptions(getSelectionOptions(selections, manufacturingSite, QuoteLineItemValueTypes.Incoterms)?.options);

    if (manufacturingSite) {
      // remove comma if city or country/state is undefined
      const comma = manufacturingSite === ManufacturingSites.DE01 ? (city && country ? ',' : '') : city && state ? ',' : '';
      setDestinationNotes(manufacturingSite === ManufacturingSites.DE01 ? `${city}${comma} ${country}` : `${city}${comma} ${state}`);
    }
  }, [manufacturingSite]);

  React.useEffect(() => {
    if (!freightZoneOptions?.includes(freightZone)) {
      setFreightZone(null);
    }
  }, [freightZoneOptions]);

  React.useEffect(() => {
    if (!incotermOptions?.includes(incoterm)) {
      setIncoterm(null);
    }
  }, [incotermOptions]);

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const updateQuote = () => {
    const updatedQuote = {
      ...quote,
      Manufacturing_Site__c: manufacturingSite,
      Freight_Zone__c: freightZone,
      Incoterms__c: incoterm,
      Export_Packaging__c: exportPackaging,
      RevisionNotes__c: revisionNotes,
      DestinationNotes__c: destinationNotes,
      IncotermLocation__c: destinationNotes,
      DocumentUOM__c: uom,
      Payment_Terms__c: standardPaymentTerms,
      Cancelation_Terms__c: standardCancellationTerms,
      Contract_Terms__c: standardContractTerms,
      Validity__c: standardValidityTerms
    };

    dispatchQuoteState({ type: QuoteActionTypes.loadQuote, payload: updatedQuote });

    // set the default context uom based on the manufacturing site
    const displayUom = manufacturingSite === ManufacturingSites.US01 ? MeasurementSystems.Imperial : MeasurementSystems.Metric;
    setDisplayUom(uom ?? displayUom);
  };

  // set the default uom based on the manufacturing site, when the manufacturing site is changed
  const manufacturingSiteHandler = (site: ManufacturingSites) => {
    setManufacturingSite(site);
    // set the default uom based on the manufacturing site
    if (site === ManufacturingSites.DE01) {
      setUOM(MeasurementSystems.Metric);
    } else {
      setUOM(MeasurementSystems.Imperial);
    }
  };

  return (
    <div className='popover quote-setup'>
      <div className='title-container'>
        <IonLabel class='quote-setup-title' data-testid={'QAQuoteSetupPopoverQuoteSetupHeader'}>
          Quote Setup
        </IonLabel>
      </div>
      <hr />
      <div className='selections'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='text'
                  selectionLabel='Quote Number'
                  style={{ width: '178px', marginRight: '16px' }}
                  isDisabled={true}
                  value={quote.Name}
                />
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='text'
                  selectionLabel='Notes'
                  placeholder='Notes'
                  style={{ width: '332px' }}
                  containerStyle={{ marginLeft: '16px' }}
                  onBlur={(notes) => setRevisionNotes(notes)}
                  value={revisionNotes}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.TextInput}
                  inputType='text'
                  selectionLabel='Customer Reference'
                  style={{ width: '178px', marginRight: '32px' }}
                  isDisabled={true}
                  value={quote.Customer_Reference__c}
                />
                <div className='project-reference-container'>
                  <IonLabel class='project-reference-label'>Project Reference</IonLabel>
                  <IonLabel
                    class='project-reference-link'
                    data-testid='QAQuoteSetupPopoverProjectReference'
                    onClick={() => {
                      window.open(
                        `${environment.SALESFORCE_URL}lightning/r/NC_Project__c/${quote?.NC_Project__c}/view`,
                        '_blank'
                      );
                    }}
                  >
                    {quote?.NC_Project__r?.Name || '- -'}
                  </IonLabel>
                </div>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className='flex-row'>
                <SelectionContainer
                  type={SelectionTypes.DropDown}
                  selectionLabel='Manufacturing Site'
                  placeholder='Select site'
                  options={[ManufacturingSites.US01, ManufacturingSites.DE01]}
                  style={{ width: '193px', marginRight: '16px' }}
                  value={manufacturingSite}
                  onChange={(manufacturingSite) => manufacturingSiteHandler(manufacturingSite.label)}
                  errorExists={manufacturingSiteError}
                />
                <SelectionContainer
                  type={SelectionTypes.DropDown}
                  selectionLabel='Freight Zone'
                  placeholder='Zone'
                  options={freightZoneOptions}
                  style={{ width: '90px', menuListHeight: '125px', marginRight: '16px' }}
                  onChange={(freightZone) => setFreightZone(freightZone.label)}
                  value={freightZone}
                  isDisabled={!manufacturingSite}
                  errorExists={freightZoneError}
                />
                <SelectionContainer
                  type={SelectionTypes.DropDown}
                  selectionLabel='Quote Unit of Measure'
                  placeholder='Select UOM'
                  options={[MeasurementSystems.Imperial, MeasurementSystems.Metric]}
                  style={{ width: '193px' }}
                  value={uom}
                  onChange={(uom: { label: React.SetStateAction<MeasurementSystems>; }) => setUOM(uom.label)}
                  isDisabled={!manufacturingSite}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <SelectionContainer
                type={SelectionTypes.DropDown}
                selectionLabel='Incoterm'
                placeholder='Select incoterm'
                options={incotermOptions}
                style={{ width: '193px', menuListHeight: '125px' }}
                onChange={(incoterm) => setIncoterm(incoterm.label)}
                value={incoterm}
                isDisabled={!manufacturingSite}
                errorExists={incotermError}
              />
              <SelectionContainer
                type={SelectionTypes.TextInput}
                inputType='text'
                placeholder='Destination Notes...'
                selectionLabel='Destination Notes'
                style={{ width: '332px' }}
                containerStyle={{ marginLeft: '16px' }}
                onBlur={(notes) => setDestinationNotes(notes)}
                value={destinationNotes}
                isDisabled={!manufacturingSite}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class='flex-column'>
              <CheckboxItem
                label='Export Packaging'
                style={{ width: 'fit-content', marginTop: '10px' }}
                onIonChange={(exportPackaging) => setExportPackaging(exportPackaging)}
                checked={exportPackaging}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <hr className='bottom-rule' />
      <div className='cta-button-container'>
        <IonButton
          color='primary'
          class='finish-setup-button'
          data-testid='QAQuoteSetupPopoverFinishSetupButton'
          onClick={() => {
            if (manufacturingSite && freightZone && incoterm) {
              setManufacturingSiteError(false);
              setFreightZoneError(false);
              setIncotermError(false);
              updateQuote();
              dismissModal();
            } else {
              if (!manufacturingSite) {
                setManufacturingSiteError(true);
              } else {
                setManufacturingSiteError(false);
                setFreightZoneError(!freightZone ? true : false);
                setIncotermError(!incoterm ? true : false);
              }
            }
          }}
        >
          Finish Setup
        </IonButton>
      </div>
    </div>
  );
};

export default QuoteSetupPopover;
