import LineItemField from '@components/line-item-field/line-item-field';
import {
  MeasurementSystems,
  QuoteItemSelectionTabs,
  QuoteLineItemFieldUnitTypes,
  QuoteLineItemFields,
  QuoteLineItemValueTypes,
  QuoteLineTypes,
  Units,
} from '@constants';
import { Action, QuoteActionTypes } from '@context/quote-context';
import { QuoteLineItem } from '@interfaces/quote-line-item';
import { calculateUOMValue } from '@shared/quote-utils';
import * as React from 'react';

export const getLineItemFieldCladTK = (
  uom: MeasurementSystems,
  quoteLineItem: QuoteLineItem,
  dispatchQuoteState: (value: Action) => void,
  runFrankie = true
): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  const isImperial = uom === MeasurementSystems.Imperial;

  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.CladTK}
      uom={uom}
      imperialValue={quoteLineItem.quoteLineItemDetail.Clad_TK_in__c}
      metricValue={quoteLineItem.quoteLineItemDetail.Clad_TK_mm__c}
      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
      imperialField={QuoteLineItemFields.CladTKIn}
      metricField={QuoteLineItemFields.CladTKMm}
      itemId={quoteLineItem.quoteLineItemDetail.Id || quoteLineItem.quoteLineItemDetail.associatedLineItem}
      lineId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Detail}
      calculatedField={true}
      ignoreRecalculateFields={!runFrankie} // if true - we need a callback, if false - run frankie and cb doesnt run
      fieldChangedCB={(val) => {
        quoteLineItem.quoteLineItemDetail.Clad_TK_in__c = isImperial
          ? getImperialValue(val, uom)
          : getMetricValue(val, uom);
        quoteLineItem.quoteLineItemDetail.Clad_TK_mm__c = isImperial
          ? getMetricValue(val, uom)
          : getImperialValue(val, uom);
        dispatchQuoteState({ type: QuoteActionTypes.updateLineToDuplicate, payload: undefined });
      }}
    />
  );
};

export const getLineItemFieldBaseTK = (
  uom: MeasurementSystems,
  quoteLineItem: QuoteLineItem,
  dispatchQuoteState: (value: Action) => void,
  runFrankie = true
): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  const isImperial = uom === MeasurementSystems.Imperial;

  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.BaseTK}
      uom={uom}
      imperialValue={quoteLineItem.quoteLineItemDetail.Base_TK_in__c}
      metricValue={quoteLineItem.quoteLineItemDetail.Base_TK_mm__c}
      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceTK}
      imperialField={QuoteLineItemFields.BaseTKIn}
      metricField={QuoteLineItemFields.BaseTKMm}
      itemId={quoteLineItem.quoteLineItemDetail.Id || quoteLineItem.quoteLineItemDetail.associatedLineItem}
      lineId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Detail}
      calculatedField={true}
      ignoreRecalculateFields={!runFrankie} // if true - we need a callback, if false - run frankie and cb doesnt run
      fieldChangedCB={(val) => {
        quoteLineItem.quoteLineItemDetail.Base_TK_in__c = isImperial
          ? getImperialValue(val, uom)
          : getMetricValue(val, uom);
        quoteLineItem.quoteLineItemDetail.Base_TK_mm__c = isImperial
          ? getMetricValue(val, uom)
          : getImperialValue(val, uom);
        dispatchQuoteState({ type: QuoteActionTypes.updateLineToDuplicate, payload: undefined });
      }}
    />
  );
};

export const getLineItemFieldCladLength = (
  uom: MeasurementSystems,
  quoteLineItem: QuoteLineItem,
  dispatchQuoteState: (value: Action) => void,
  runFrankie = true
): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  const isImperial = uom === MeasurementSystems.Imperial;

  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.CladLength}
      uom={uom}
      imperialValue={quoteLineItem.quoteLineItemDetail.Clad_Length_in__c}
      metricValue={quoteLineItem.quoteLineItemDetail.Clad_Length_mm__c}
      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
      imperialField={QuoteLineItemFields.CladLengthIn}
      metricField={QuoteLineItemFields.CladLengthMm}
      itemId={quoteLineItem.quoteLineItemDetail.associatedLineItem ?? quoteLineItem.quoteLineItemDetail.Id}
      lineId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Detail}
      calculatedField={true}
      ignoreRecalculateFields={!runFrankie} // if true - we need a callback, if false - run frankie and cb doesnt run
      fieldChangedCB={(val) => {
        quoteLineItem.quoteLineItemDetail.Clad_Length_in__c = isImperial
          ? getImperialValue(val, uom)
          : getMetricValue(val, uom);
        quoteLineItem.quoteLineItemDetail.Clad_Length_mm__c = isImperial
          ? getMetricValue(val, uom)
          : getImperialValue(val, uom);
        dispatchQuoteState({ type: QuoteActionTypes.updateLineToDuplicate, payload: undefined });
      }}
    />
  );
};

const getImperialValue = (value: number, uom: MeasurementSystems) => {
  return calculateUOMValue(uom, MeasurementSystems.Imperial, value, Units.Millimeters, Units.Inches);
};

const getMetricValue = (value: number, uom: MeasurementSystems) => {
  return calculateUOMValue(uom, MeasurementSystems.Metric, value, Units.Millimeters, Units.Inches);
};

export const getLineItemFieldBaseLength = (
  uom: MeasurementSystems,
  quoteLineItem: QuoteLineItem,
  dispatchQuoteState: (value: Action) => void,
  runFrankie = true
): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  const isImperial = uom === MeasurementSystems.Imperial;

  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.BaseLength}
      uom={uom}
      imperialValue={quoteLineItem.quoteLineItemDetail.Base_Length_in__c}
      metricValue={quoteLineItem.quoteLineItemDetail.Base_Length_mm__c}
      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
      imperialField={QuoteLineItemFields.BaseLengthIn}
      metricField={QuoteLineItemFields.BaseLengthMm}
      itemId={quoteLineItem.quoteLineItemDetail.associatedLineItem ?? quoteLineItem.quoteLineItemDetail.Id}
      lineId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Detail}
      calculatedField={true}
      ignoreRecalculateFields={!runFrankie} // if true - we need a callback, if false - run frankie and cb doesnt run
      fieldChangedCB={(val) => {
        quoteLineItem.quoteLineItemDetail.Base_Length_in__c = isImperial
          ? getImperialValue(val, uom)
          : getMetricValue(val, uom);
        quoteLineItem.quoteLineItemDetail.Base_Length_mm__c = isImperial
          ? getMetricValue(val, uom)
          : getImperialValue(val, uom);
        dispatchQuoteState({ type: QuoteActionTypes.updateLineToDuplicate, payload: undefined });
      }}
      // displayedValue={isImperial ? quoteLineItem.quoteLineItemDetail.Base_Length_in__c : quoteLineItem.quoteLineItemDetail.Base_Length_mm__c}
    />
  );
};

export const getLineItemFieldCladWidth = (
  uom: MeasurementSystems,
  quoteLineItem: QuoteLineItem,
  dispatchQuoteState: (value: Action) => void,
  runFrankie = true
): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  const isImperial = uom === MeasurementSystems.Imperial;

  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.CladWidth}
      uom={uom}
      imperialValue={quoteLineItem.quoteLineItemDetail.Clad_Width_in__c}
      metricValue={quoteLineItem.quoteLineItemDetail.Clad_Width_mm__c}
      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
      imperialField={QuoteLineItemFields.CladWidthIn}
      metricField={QuoteLineItemFields.CladWidthMm}
      itemId={quoteLineItem.quoteLineItemDetail.associatedLineItem ?? quoteLineItem.quoteLineItemDetail.Id}
      lineId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Detail}
      calculatedField={true}
      ignoreRecalculateFields={!runFrankie} // if true - we need a callback, if false - run frankie and cb doesnt run
      fieldChangedCB={(val) => {
        quoteLineItem.quoteLineItemDetail.Clad_Width_in__c = isImperial
          ? getImperialValue(val, uom)
          : getMetricValue(val, uom);
        quoteLineItem.quoteLineItemDetail.Clad_Width_mm__c = isImperial
          ? getMetricValue(val, uom)
          : getImperialValue(val, uom);
        dispatchQuoteState({ type: QuoteActionTypes.updateLineToDuplicate, payload: undefined });
      }}
    />
  );
};

export const getLineItemFieldBaseWidth = (
  uom: MeasurementSystems,
  quoteLineItem: QuoteLineItem,
  dispatchQuoteState: (value: Action) => void,
  runFrankie = true
): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  const isImperial = uom === MeasurementSystems.Imperial;

  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.BaseWidth}
      uom={uom}
      imperialValue={quoteLineItem.quoteLineItemDetail.Base_Width_in__c}
      metricValue={quoteLineItem.quoteLineItemDetail.Base_Width_mm__c}
      unitType={QuoteLineItemFieldUnitTypes.LinearDistanceLW}
      imperialField={QuoteLineItemFields.BaseWidthIn}
      metricField={QuoteLineItemFields.BaseWidthMm}
      itemId={quoteLineItem.quoteLineItemDetail.associatedLineItem ?? quoteLineItem.quoteLineItemDetail.Id}
      lineId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Detail}
      calculatedField={true}
      ignoreRecalculateFields={!runFrankie} // if true - we need a callback, if false - run frankie and cb doesnt run
      fieldChangedCB={(val) => {
        quoteLineItem.quoteLineItemDetail.Base_Width_in__c = isImperial
          ? getImperialValue(val, uom)
          : getMetricValue(val, uom);
        quoteLineItem.quoteLineItemDetail.Base_Width_mm__c = isImperial
          ? getMetricValue(val, uom)
          : getImperialValue(val, uom);
        dispatchQuoteState({ type: QuoteActionTypes.updateLineToDuplicate, payload: undefined });
      }}
    />
  );
};

export const getLineItemFieldWide = (quoteLineItem: QuoteLineItem): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.Wide}
      value={quoteLineItem.quoteLineItemDetail.Wide__c}
      field={QuoteLineItemFields.Wide}
      itemId={quoteLineItem.quoteLineItemDetail.associatedLineItem ?? quoteLineItem.quoteLineItemDetail.Id}
      lineId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Detail}
    />
  );
};

export const getLineItemFieldLong = (quoteLineItem: QuoteLineItem): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.Long}
      value={quoteLineItem.quoteLineItemDetail.Long__c}
      field={QuoteLineItemFields.Long}
      itemId={quoteLineItem.quoteLineItemDetail.associatedLineItem ?? quoteLineItem.quoteLineItemDetail.Id}
      lineId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Detail}
    />
  );
};
export const getWeldingCost = (quoteLineItem: QuoteLineItem, activeTab: QuoteItemSelectionTabs): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  return (
    <LineItemField
      datatestid='QAQuoteDetailPageCostQuoteLineItemWelding'
      valueType={QuoteLineItemValueTypes.Welding}
      displayedValue={quoteLineItem.Welding__c || '- -'}
      currency={quoteLineItem.CurrencyIsoCode}
      value={quoteLineItem.Welding__c || '0'}
      unitType={QuoteLineItemFieldUnitTypes.Currency}
      field={QuoteLineItemFields.Welding}
      itemId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineToEdit={quoteLineItem}
      lineType={QuoteLineTypes.Master}
      calculatedField={true}
      extraOptions={true}
      activeTab={activeTab}
    />
  );
};

export const getTotalFreightPerWeightCost = (
  quoteLineItem: QuoteLineItem,
  uom: MeasurementSystems,
  activeTab: QuoteItemSelectionTabs
): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  return (
    <LineItemField
      datatestid='QAQuoteDetailPageQuoteLineItemSublineTotalFreight/Weight'
      valueType={QuoteLineItemValueTypes.TotalFreightPerWeight}
      currency={quoteLineItem.CurrencyIsoCode}
      displayedImperialValue={quoteLineItem.Total_Freight_LB__c}
      displayedMetricValue={quoteLineItem.Total_Freight_KG__c}
      imperialValue={quoteLineItem.Total_Freight_LB__c}
      metricValue={quoteLineItem.Total_Freight_KG__c}
      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
      uom={uom}
      imperialField={QuoteLineItemFields.TotalFreightLB}
      metricField={QuoteLineItemFields.TotalFreightKG}
      itemId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Master}
      calculatedField={true}
      activeTab={activeTab}
    />
  );
};

export const getCladFreightCost = (quoteLineItem: QuoteLineItem, activeTab: QuoteItemSelectionTabs): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  return (
    <LineItemField
      datatestid='QAQuoteDetailPageCostQuoteLineItemCladFrt'
      valueType={QuoteLineItemValueTypes.CladFreight}
      displayedValue={quoteLineItem.Clad_Freight__c}
      currency={quoteLineItem.CurrencyIsoCode}
      value={
        quoteLineItem.Customer_Provided_Clad__c
          ? quoteLineItem.Clad_Freight_Customer_Supplied__c
          : quoteLineItem.Clad_Freight__c
      }
      unitType={QuoteLineItemFieldUnitTypes.Currency}
      field={QuoteLineItemFields.CladFreight}
      itemId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Master}
      calculatedField={true}
      isCustomerProvidedClad={!!quoteLineItem.Customer_Provided_Clad__c}
      activeTab={activeTab}
    />
  );
};

export const getBaseFreightCost = (quoteLineItem: QuoteLineItem, activeTab: QuoteItemSelectionTabs): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  return (
    <LineItemField
      datatestid='QAQuoteDetailPageCostQuoteLineItemBaseFrt'
      valueType={QuoteLineItemValueTypes.BaseFreight}
      displayedValue={quoteLineItem.Base_Freight__c}
      currency={quoteLineItem.CurrencyIsoCode}
      value={
        quoteLineItem.Customer_Provided_Base__c
          ? quoteLineItem.Base_Freight_Customer_Supplied__c
          : quoteLineItem.Base_Freight__c
      }
      unitType={QuoteLineItemFieldUnitTypes.Currency}
      field={QuoteLineItemFields.BaseFreight}
      itemId={quoteLineItem.displayId ?? quoteLineItem.Id}
      lineType={QuoteLineTypes.Master}
      calculatedField={true}
      isCustomerProvidedBase={!!quoteLineItem.Customer_Provided_Base__c}
      // readOnly={isQuoteLocked}
      activeTab={activeTab}
    />
  );
};

export const getCladCost = (quoteLineItem: QuoteLineItem, activeTab: QuoteItemSelectionTabs): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.CladPricePerWeight}
      currency={quoteLineItem.CurrencyIsoCode}
      displayedImperialValue={quoteLineItem.Clad_Price_LB__c}
      displayedMetricValue={quoteLineItem.Clad_Price_KG__c}
      imperialValue={quoteLineItem.Clad_Price_LB__c}
      metricValue={quoteLineItem.Clad_Price_KG__c}
      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
      uom={quoteLineItem.Unit_of_Measure__c}
      imperialField={QuoteLineItemFields.CladPriceLB}
      metricField={QuoteLineItemFields.CladPriceKG}
      itemId={quoteLineItem.Id || quoteLineItem.displayId}
      lineType={QuoteLineTypes.Master}
      calculatedField={true}
      // readOnly={isQuoteLocked}
      activeTab={activeTab}
    />
  );
};

export const getCladLayerCost = (quoteLineItem: QuoteLineItem, activeTab: QuoteItemSelectionTabs): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  const isCustomerProvidedClad = !!quoteLineItem.Customer_Provided_Clad__c;
  const value = isCustomerProvidedClad ? quoteLineItem.Clad_Cost_Customer_Supplied__c : quoteLineItem.Clad_Cost__c;

  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.CladCost}
      displayedValue={quoteLineItem.Clad_Cost__c}
      currency={quoteLineItem.CurrencyIsoCode}
      value={value}
      unitType={QuoteLineItemFieldUnitTypes.Currency}
      field={QuoteLineItemFields.CladCost}
      itemId={quoteLineItem.Id || quoteLineItem.displayId}
      lineType={QuoteLineTypes.Master}
      calculatedField={true}
      // tabIndex={quoteLineItemIndex + '.0'}
      // nextTabIndex={quoteLineItemIndex + '.1'}
      // setTabIndex={setTabIndex}
      // selectedTabIndex={tabIndex}
      isCustomerProvidedClad={isCustomerProvidedClad}
      // readOnly={isQuoteLocked}
      activeTab={activeTab}
    />
  );
};

export const getBaseCost = (quoteLineItem: QuoteLineItem, activeTab: QuoteItemSelectionTabs): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.BasePricePerWeight}
      currency={quoteLineItem.CurrencyIsoCode}
      displayedImperialValue={quoteLineItem.Base_Price_LB__c}
      displayedMetricValue={quoteLineItem.Base_Price_KG__c}
      imperialValue={quoteLineItem.Base_Price_LB__c}
      metricValue={quoteLineItem.Base_Price_KG__c}
      unitType={QuoteLineItemFieldUnitTypes.CurrencyPerWeight}
      uom={quoteLineItem.Unit_of_Measure__c}
      imperialField={QuoteLineItemFields.BasePriceLB}
      metricField={QuoteLineItemFields.BasePriceKG}
      itemId={quoteLineItem.Id || quoteLineItem.displayId}
      lineType={QuoteLineTypes.Master}
      calculatedField={true}
      // readOnly={isQuoteLocked}
      activeTab={activeTab}
    />
  );
};

export const getBaseLayerCost = (quoteLineItem: QuoteLineItem, activeTab: QuoteItemSelectionTabs): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;

  const isCustomerProvidedBase = !!quoteLineItem.Customer_Provided_Base__c;
  const value = isCustomerProvidedBase ? quoteLineItem.Base_Cost_Customer_Supplied__c : quoteLineItem.Base_Cost__c;

  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.BaseCost}
      displayedValue={quoteLineItem.Base_Cost__c}
      currency={quoteLineItem.CurrencyIsoCode}
      value={value}
      unitType={QuoteLineItemFieldUnitTypes.Currency}
      field={QuoteLineItemFields.BaseCost}
      itemId={quoteLineItem.Id || quoteLineItem.displayId}
      lineType={QuoteLineTypes.Master}
      calculatedField={true}
      isCustomerProvidedBase={isCustomerProvidedBase}
      // readOnly={isQuoteLocked}
      activeTab={activeTab}
    />
  );
};

export const getBaseWeightPerCost = (quoteLineItem: QuoteLineItem, uom: MeasurementSystems): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.BaseWeightPerPC}
      unitType={QuoteLineItemFieldUnitTypes.Weight}
      imperialValue={quoteLineItem.Base_LB_PC__c}
      metricValue={quoteLineItem.Base_KG_PC__c}
      uom={uom}
    />
  );
};

export const getCladWeightPerCost = (quoteLineItem: QuoteLineItem, uom: MeasurementSystems): JSX.Element => {
  if (!quoteLineItem?.quoteLineItemDetail) return <></>;
  return (
    <LineItemField
      valueType={QuoteLineItemValueTypes.CladWeightPerPC}
      unitType={QuoteLineItemFieldUnitTypes.Weight}
      imperialValue={quoteLineItem.Clad_LB_PC__c}
      metricValue={quoteLineItem.Clad_KG_PC__c}
      uom={uom}
    />
  );
};

