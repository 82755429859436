import React from 'react';

import { IonLabel,IonSegment, IonSegmentButton } from '@ionic/react';
import { useSegmentContext } from './segment-context';

export const SEGMENTS: {
  [key: string]: { value: string, label: string };
} = {
  CLAD_PRODUCT_TESTING: { value: 'clad-product-testing', label: 'Clad Product testing' },
  CLAD_METAL: { value: 'clad-metal', label: 'Cladding' },
  PREWELDING: { value: 'prewelding', label: 'Prewelding' },
  BASE_METAL: { value: 'base-metal', label: 'Base Metal' },
  EXCEPTIONS_CLARIFICATIONS: { value: 'exceptions-clarifications', label: 'Exceptions & Clarifications' },
};

const PageSegmentBar = () => {
  const { segment, setSegment } = useSegmentContext();

  const segments = Object.keys(SEGMENTS).map((key) => {
    return SEGMENTS[key];
  });

  return <IonSegment value={segment} onIonChange={(ev: any) => setSegment(ev.target.value)}>
    {
      segments.map((segment) => {
        return <IonSegmentButton value={segment.value} key={segment.value}>
          <IonLabel>{segment.label}</IonLabel>
        </IonSegmentButton>
      })
    }
  </IonSegment>
}

export default PageSegmentBar;