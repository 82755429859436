import { IonLabel } from '@ionic/react';
import React from 'react';
import './badge.scss';

const Badge = (props) => {
  return ( props.text ?
    <div className='badge' data-testid={'QABadge_' + props.text}>
      <IonLabel class='badge-text'>{ props.text }</IonLabel>
      <div className="badge-edge"></div>
    </div> : null
  );
};

export default Badge;