import { getSpecificationTestOptions } from './specification-test-options-api';
import * as React from 'react';
import { SpecificationTestOptions } from '@interfaces/specification-test-options';

const { createContext, useContext } = React;

const SpecificationTestOptionsContext = createContext(null);

export const SpecificationTestOptionsProvider = (props) => {
  const value = {
    getAllSpecificationTestOptions: props.getAllSpecificationTestOptions || getAllSpecificationTestOptions,
  };

  return (
    <SpecificationTestOptionsContext.Provider value={value}>
      {props.children}
    </SpecificationTestOptionsContext.Provider>
  );
};

export const useSpecificationTestOptions = () => {
  return useContext(SpecificationTestOptionsContext);
};

// Calls api to get all the specification test options from cosmos
export const getAllSpecificationTestOptions = async(): Promise<SpecificationTestOptions[]> => {
  try {
    return await getSpecificationTestOptions();
  } catch (error) {
    console.error(error);
    return null;
  }
};
