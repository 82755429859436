import React from 'react';
import { IonButton, IonCard, IonCardHeader, IonCol, IonGrid, IonIcon, IonImg, IonLabel, IonRow } from '@ionic/react';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import './order-line-item-details-popover.scss';
import { LineItemDetailsColumnHeadings, LineItemOtherDescriptionHeadings } from '@constants';
import { chevronDown, chevronUp } from 'ionicons/icons';
import classNames from 'classnames';
import { formatCurrency, getStatusImageFromAPIStatus } from '@shared/utils';
import { getStatusSortingOrderFromAPIStatus } from '@shared/utils';
import moment from 'moment';
import { usePlatform } from '@services/platform-service';
import { useLastViewed } from '@services/last-viewed/last-viewed-service';
import Documents from '@components/documents/documents';
import { attachOutline } from 'ionicons/icons';
import { useDarkMode } from '@hooks/useDarkMode';
import { MappedNCLineItem, MappedNCLineItemDetails } from '@shared/types';

const OrderLineItemDetailsPopover = (props) => {
  const { order } = props;
  const lastViewed = useLastViewed();
  const platform = usePlatform();
  const { darkMode } = useDarkMode();
  const isDeviceMobileOrTablet = platform.isMobileOrTablet();
  const isDeviceTablet = platform.isTablet();

  const [sortColumnHeading, setSortColumnHeading] = React.useState(undefined);
  const [sortColumnId, setSortColumnId] = React.useState(undefined);
  const [sortAsc, setSortAsc] = React.useState(true);
  const [headings] = React.useState(LineItemDetailsColumnHeadings);
  const [displayedLineItemDetails, setDisplayedLineItemDetails] = React.useState<MappedNCLineItemDetails[]>(undefined);

  React.useEffect(() => {
    loadData();
    lastViewed.updateItemLastViewed({ id: props.orderLineItem.Id, lastViewed: new Date().toLocaleString() });
  }, []);

  React.useEffect(() => {
    sortLineItemDetails();
  }, [sortColumnId, sortAsc]);

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  // Loads the line item details
  const loadData = async () => {
    try {
      setDisplayedLineItemDetails(props.lineItemDetails.map((lineItemDetail) => mapLineItemDetail(lineItemDetail)));

      const databaseColumnId = getColumnIdFromName(LineItemDetailsColumnHeadings.Item);
      setSortColumnId(databaseColumnId);
    } catch (error) {
      console.log(error);
    }
  };

  // Maps some line item detail properties so that the UI can consume the line item detail
  const mapLineItemDetail = (lineItemDetail: MappedNCLineItem) => {
    const shipDate = lineItemDetail[getShipDateField(lineItemDetail)];

    const buildAddress = () => {
      const {
        Ship_To_Street__c: street,
        Ship_To_City__c: city,
        Ship_To_State__c: state,
        Ship_To_Zip_Postal_Code__c: postal,
        Ship_To_Country__c: country,
      } = lineItemDetail;

      let address = street || '';

      if (city) {
        address = `${address}\n${city}, ${state} ${postal}`;
      }

      if (country) {
        address = `${address}\n${country}`;
      }

      return address;
    };

    return {
      ...lineItemDetail,
      item: `${lineItemDetail.Line_Number__c}-${lineItemDetail.Line_Item_Detail_Number__c}`,
      statusImgSrc: getStatusImageFromAPIStatus(lineItemDetail.Status__c, darkMode),
      statusSortingOrder: getStatusSortingOrderFromAPIStatus(lineItemDetail.Status__c),
      shipDate: shipDate ? moment(shipDate).format('MM/DD/YYYY') : '',
      shipAddress: buildAddress(),
    };
  };

  // Handler for when a line item details column is selected
  const selectColumnHandler = (column: LineItemDetailsColumnHeadings) => {
    setSortColumnHeading(column);

    const databaseColumnId = getColumnIdFromName(column as LineItemDetailsColumnHeadings);

    if(sortColumnId === databaseColumnId) {
      setSortAsc(!sortAsc);
    } else {
      setSortColumnId(databaseColumnId);
    }
  };

  // Returns the correct salesforce order line item property based on the column header
  const getColumnIdFromName = (colName: LineItemDetailsColumnHeadings) => {
    return {
      [LineItemDetailsColumnHeadings.Item]: 'item',
      [LineItemDetailsColumnHeadings.Status]: 'Status__c',
      [LineItemDetailsColumnHeadings.Description]: 'Description__c',
      [LineItemDetailsColumnHeadings.ShipDate]: getShipDateField(props.orderLineItem),
      [LineItemDetailsColumnHeadings.UnitPrice]: 'Unit_Price__c',
    }[colName];
  };

  // Returns correct ship date field for the line item detail
  const getShipDateField = (lineItemDetail: MappedNCLineItem) => {
    return lineItemDetail.Rescheduled_Customer_Ship_Date__c ? 'Rescheduled_Customer_Ship_Date__c' : (lineItemDetail.Actual_Ship_Date__c ? 'Actual_Ship_Date__c' : 'Customer_Request_Date__c');
  };

  // Returns proper column header class based on whether the column is sorted or not
  const getColHeaderClass = (column: LineItemDetailsColumnHeadings) => {
    return (column === sortColumnHeading) ? 'line-item-details-text text-bold' : 'line-item-details-text';
  };

  // Returns proper column header icon based on whether the column is sorted or not
  const getColHeaderIcon = (column: LineItemDetailsColumnHeadings) => {
    return (column === sortColumnHeading && sortAsc) ? chevronUp : chevronDown;
  };

  // Sorts the order line item details according to the sort column
  const sortLineItemDetails = async () => {
    let updatedItems;

    if(displayedLineItemDetails) {
      updatedItems = [...displayedLineItemDetails];
    }

    if(updatedItems?.length) {
      switch(sortColumnId) {
      case getColumnIdFromName(LineItemDetailsColumnHeadings.Status):
        updatedItems = updatedItems?.orderBy(item => +item.statusSortingOrder, sortAsc);
        break;
      case getColumnIdFromName(LineItemDetailsColumnHeadings.ShipDate):
        updatedItems = updatedItems?.orderByDate(item => (item.shipDate ? moment(item.shipDate).format('MM/DD/YYYY') : item.shipDate), sortAsc);
        break;
      default:
        updatedItems = updatedItems?.orderBy(item => item[sortColumnId], sortAsc);
        break;
      }

      setDisplayedLineItemDetails(updatedItems);
    }
  };

  const LineItemOtherDescription = ({ lineItemDetail, order }) => {
    const { item, Base_Metal__c, Clad_Metal__c, shipDate, PO__c, Tag__c, shipAddress } = lineItemDetail;
    const { NC_Project__c } = order;

    const getLabelSize = () => {
      if (!isDeviceMobileOrTablet) {
        return '3';
      }

      if (isDeviceTablet) {
        return '1.5';
      }

      return '3';
    };

    const getValueSize = () => {
      if (!isDeviceMobileOrTablet) {
        return '9';
      }

      if (isDeviceTablet) {
        return '10.5';
      }

      return '9';
    };

    const labelSize = getLabelSize();
    const valueSize = getValueSize();

    const items = [
      {
        label: LineItemOtherDescriptionHeadings.Base,
        value: Base_Metal__c
      },
      {
        label: LineItemOtherDescriptionHeadings.Clad,
        value: Clad_Metal__c
      },
      {
        label: LineItemOtherDescriptionHeadings.ShipDate,
        value: shipDate ? moment(shipDate).format('DD-MMMM-YYYY') : ''
      },
      {
        label: LineItemOtherDescriptionHeadings.PO,
        value: PO__c
      },
      {
        label: LineItemOtherDescriptionHeadings.Tag,
        value: Tag__c
      },
      {
        label: LineItemOtherDescriptionHeadings.Project,
        value: NC_Project__c || ''
      },
      {
        label: LineItemOtherDescriptionHeadings.ShipTo,
        value: shipAddress
      }
    ];

    return (
      <IonGrid class="ion-no-padding">
        {
          items.map(({label, value}, index) =>
            <IonRow class="line-item-detail-other-row" key={`${item}-other-${index}`}>
                <IonCol size={labelSize}>
                  <span className="line-item-detail-other-label">{label}:</span>
                </IonCol>
                <IonCol size={valueSize}>
                  <span className="line-item-detail-other-text">{value}</span>
                </IonCol>
            </IonRow>
          )
        }
      </IonGrid>
    );
  };

  const LineItemDetail = (props) => {
    const [active, setActive] = React.useState(false);

    const LineItemAttachmentIndicator = () => {
      return props.documents.some(document => document.Name.contains(props.lineItemDetail.item)) ?
        <IonIcon data-testid="QAOrderLineItemDetailsPopoverAttachmentIndicator" class='paperclip-icon' icon={attachOutline}></IonIcon> : null;
    };

    const LineItemAccordion = () => {
      return <IonIcon class="cursor-pointer line-item-details-accordion" icon={active ? chevronUp : chevronDown} onClick={() => setActive(active => !active)}></IonIcon>;
    };

    const LineItemValue = ({
      item = '',
      status = '',
      testId = '',
      accordion = false,
      className = '',
      value = ''
    }) => {
      const getValueSize = () => {
        if (!isDeviceMobileOrTablet) {
          return '12';
        }

        if (isDeviceTablet) {
          return '10.5';
        }

        return '9';
      };
      const labelSize = isDeviceTablet ? '1.5' : '3';
      const valueSize = getValueSize();

      return <>
        <IonGrid class="line-item-detail-grid">
          <IonRow>
            { isDeviceMobileOrTablet &&
              <IonCol size={labelSize} class="line-item-detail-mobile-label">
                {item}
              </IonCol>
            }

            <IonCol size={valueSize}>
              <IonGrid class="ion-no-padding">
                <IonRow>
                  <IonCol size="11" class='line-item-detail-value mobile-ellipsis'>
                    { status && <img className="status-icon" src={ status }/> }
                    <span data-testid={testId} className={className}> {value}</span>
                  </IonCol>

                  { accordion && <IonCol size="1">
                      <LineItemAccordion/>
                  </IonCol> }
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </>;
    };

    const LineItemCol = (props) => {
      const size = isDeviceMobileOrTablet ? '12' : props.size;

      return <>
        <IonCol size={size} class="ion-no-padding d-flex">
          {props.children}
        </IonCol>
      </>;
    };

    return (
      <IonCard class="line-item-detail-card reset-width" data-testid={'QAOrderLineItemDetailsPopoverLineItemDetail_' + props.lineItemDetailIndex}>
        <IonCardHeader class={classNames({ 'line-item-detail-card-header': true, 'even': props.lineItemDetailIndex % 2 === 0, 'odd': props.lineItemDetailIndex % 2 !== 0 })}>
          <IonGrid>
            <IonRow>
              <LineItemCol size='0.5'>
                { !isDeviceMobileOrTablet && <LineItemAccordion/> }
              </LineItemCol>

              <LineItemCol size="1">
                <LineItemValue
                  item={headings.Item}
                  value={props.lineItemDetail.item}
                  testId={'QAOrderLineItemDetailsPopoverLineItemItem_' + props.lineItemDetail.item }
                />
              </LineItemCol>

              <LineItemCol size="2">
                <LineItemValue
                  item={headings.Status}
                  value={props.lineItemDetail.Status__c}
                  status={props.lineItemDetail.statusImgSrc}
                  testId={'QAOrderLineItemDetailsPopoverLineItemStatus_' + props.lineItemDetail.Status__c }
                />
              </LineItemCol>

              <LineItemCol size="5">
                <IonGrid class="line-item-details-description ion-no-padding">
                  <IonRow>
                    <IonCol>
                      <LineItemValue
                        item={headings.Description}
                        value={props.lineItemDetail.Description__c}
                        testId="QAOrderLineItemDetailsPopoverLineItemDescription"
                        accordion={isDeviceMobileOrTablet}
                      />
                    </IonCol>
                  </IonRow>

                  { active &&
                    <IonRow>
                      <IonCol>
                        <LineItemOtherDescription
                          lineItemDetail={props.lineItemDetail}
                          order={props.order}
                        />
                      </IonCol>
                    </IonRow>
                  }
                </IonGrid>
              </LineItemCol>

              <LineItemCol size="1.5">
                <LineItemValue item={headings.ShipDate} value={props.lineItemDetail.shipDate} testId="QAOrderLineItemDetailsPopoverLineItemShipDate"/>
              </LineItemCol>

              <LineItemCol size="2">
                <LineItemValue
                  item={headings.UnitPrice}
                  value={formatCurrency(props.lineItemDetail.Unit_Price__c || 0, props.lineItemDetail.CurrencyIsoCode)}
                  testId="QAOrderLineItemDetailsPopoverLineItemUnitPrice"
                  className="unit-price"
                />
              </LineItemCol>
            </IonRow>
          </IonGrid>

          <LineItemAttachmentIndicator/>
        </IonCardHeader>
      </IonCard>
    );
  };

  return (
    <div data-testid="QAOrderLineItemDetailsPopover" className="popover">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='items-title' data-testid="QAOrderLineItemDetailsPopoverItemsHeader">{props.orderLineItem.Portal_Quantity__c} Items</IonLabel>
      </div>
      <hr/>
      <div className='line-items-container'>
        <IonCard class="line-item-details-card">
          <IonCardHeader class="line-item-details-card-header">
            <IonGrid>
              <IonRow class="line-item-details-row">
                <IonCol class="line-item-details-col" size={ !isDeviceMobileOrTablet ? '0.5' : '11' }></IonCol>
                <IonCol class="line-item-details-col" size={ !isDeviceMobileOrTablet ? '1' : '11' } onClick={() => selectColumnHandler(headings.Item)} data-testid="QAOrderLineItemDetailsPopoverLineItemItemColumnHeader">
                  <span className={getColHeaderClass(headings.Item)}>{ headings.Item }</span>
                  <IonIcon icon={getColHeaderIcon(headings.Item)}></IonIcon>
                </IonCol>
                <IonCol class="line-item-details-col" size={ !isDeviceMobileOrTablet ? '2' : '11' } onClick={() => selectColumnHandler(headings.Status)} data-testid="QAOrderLineItemDetailsPopoverLineItemStatusColumnHeader">
                  <span className={getColHeaderClass(headings.Status)}>{ headings.Status }</span>
                  <IonIcon icon={getColHeaderIcon(headings.Status)}></IonIcon>
                </IonCol>
                <IonCol class="line-item-details-col non-sorting" size={ !isDeviceMobileOrTablet ? '5' : '11' } data-testid="QAOrderLineItemDetailsPopoverLineItemDescriptionColumnHeader">
                  <span className="line-item-details-text">{ headings.Description }</span>
                </IonCol>
                <IonCol class="line-item-details-col" size={ !isDeviceMobileOrTablet ? '1.5' : '11' } onClick={() => selectColumnHandler(headings.ShipDate)} data-testid="QAOrderLineItemDetailsPopoverLineItemShipDateColumnHeader">
                  <span className={getColHeaderClass(headings.ShipDate)}>{ headings.ShipDate }</span>
                  <IonIcon icon={getColHeaderIcon(headings.ShipDate)}></IonIcon>
                </IonCol>
                <IonCol class="line-item-details-col non-sorting" size={ !isDeviceMobileOrTablet ? '2' : '11' } onClick={() => selectColumnHandler(headings.UnitPrice)} data-testid="QAOrderLineItemDetailsPopoverLineItemUnitPriceColumnHeader">
                  <span className="line-item-details-text">{ headings.UnitPrice }</span>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        {displayedLineItemDetails?.map((lineItemDetail, lineItemIndex) => (
          <LineItemDetail lineItemDetail={lineItemDetail} lineItemDetailIndex={lineItemIndex} documents={props.documents} order={order}/>
        ))}
        <hr/>
      </div>
      <Documents displayedDocuments={props.documents} setDocumentUpdated={props.setDocumentUpdated} showDocumentActions={props.showDocumentActions} />
      <div className="cta-button-container">
        <IonButton color="primary" class="close-button" onClick={() => dismissModal()} data-testid="QAOrderLineItemDetailsPopoverCloseButton">Close</IonButton>
      </div>
    </div>
  );
};

export default OrderLineItemDetailsPopover;