import React from 'react';
import { IonButton, IonCard, IonCardHeader, IonCol, IonGrid, IonIcon, IonImg, IonLabel, IonRow } from '@ionic/react';
import closeIcon from '@assets/icon/cancel_icon.svg';
import closeIconWhite from '@assets/icon/cancel_icon_white.svg';
import './view-all-documents-popover.scss';
import { useDarkMode } from '@hooks/useDarkMode';
import backArrow from '@assets/icon/arrow_back.svg';
import forwardArrow from '@assets/icon/arrow_forward.svg';
import { usePlatform } from '@services/platform-service';
import EmptyTableRow from '@components/empty-table-row/empty-table-row';
import DocumentLine from '@components/document-line/document-line';
import { DocumentsColumnHeadings } from '@constants';

const ViewAllDocumentsPopover = (props) => {
  const { darkMode } = useDarkMode();
  const platform = usePlatform();

  const [documentHeadings] = React.useState(DocumentsColumnHeadings);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage] = React.useState(10);
  const [firstRowIndex, setFirstRowIndex] = React.useState((page - 1) * rowsPerPage + 1);
  const [numberOfRows, setNumberOfRows] = React.useState(undefined);
  const [lastRowIndex, setLastRowIndex] = React.useState(undefined);
  const [pageItems, setPageItems] = React.useState(undefined);
  const [maxPages, setMaxPages] = React.useState(undefined);
  const [canGoToPreviousPage, setCanGoToPreviousPage] = React.useState(page > 1);
  const [canGoToNextPage, setCanGoToNextPage] = React.useState(undefined);

  React.useEffect(() => {
    setLastRowIndex(Math.min(numberOfRows, (rowsPerPage * page)));
  }, [numberOfRows, rowsPerPage, page]);

  React.useEffect(() => {
    setPageItems(props.documents?.slice(firstRowIndex - 1, lastRowIndex));
  }, [props.documents, firstRowIndex, lastRowIndex]);

  React.useEffect(() => {
    setFirstRowIndex((page - 1) * rowsPerPage + (props.documents?.length ? 1 : 0));
  }, [page, rowsPerPage, props.documents]);

  React.useEffect(() => {
    setCanGoToNextPage(page < maxPages);
  }, [page, maxPages]);

  React.useEffect(() => {
    setCanGoToPreviousPage(page > 1);
  }, [page]);

  React.useEffect(() => {
    setMaxPages(Math.ceil(numberOfRows / rowsPerPage));
  }, [numberOfRows, rowsPerPage]);

  React.useEffect(() => {
    setNumberOfRows(props.documents?.length);
  }, [props.documents]);

  // Navigates to previous page of the documents list popover screen
  const goToPreviousPage = () => {
    setPage(page - 1);
  };

  // Navigates to next page of the documents list popover screen
  const goToNextPage = () => {
    setPage(page + 1);
  };

  const dismissModal = () => {
    props.setShowPopover({ showPopover: false, event: undefined });
  };

  const DocumentsList = () => {
    return (
      pageItems?.length ?
        <div>
          {pageItems?.map((document, index) => (
            <DocumentLine document={document} documentIndex={index} key={index} setDocumentUpdated={props.setDocumentUpdated} showDocumentActions={props.showDocumentActions}/>
          ))}
        </div> :
        <EmptyTableRow text={'No Documents Available'}/>
    );
  };

  return (
    <div data-testid="QAViewAllDocumentsPopover" className="popover view-all-documents">
      <IonImg src={!darkMode ? closeIcon : closeIconWhite} class="close-icon" onClick={() => dismissModal()}></IonImg>
      <div className="title-container">
        <IonLabel class='documents-title' data-testid="QAViewAllDocumentsPopoverItemsHeader">Documents</IonLabel>
      </div>
      <hr/>
      <div className='popover-documents-container'>
        <IonCard class='documents-card'>
          <IonCardHeader class='documents-card-header'>
            <IonGrid>
              <IonRow class='documents-row'>
                <IonCol class='documents-col' size='10' data-testid='QAViewAllDocumentsPopoverNameColumnHeader'>
                  <span className='documents-text'>{ documentHeadings.Name }</span>
                </IonCol>
                <IonCol class='documents-col' size='2' data-testid='QAViewAllDocumentsPopoverDateAddedColumnHeader'>
                  <span className='documents-text'>{ documentHeadings.DateAdded }</span>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        </IonCard>
        <DocumentsList />
        <hr className='bottom-rule'/>
      </div>
      <div className="pagination-parent-container">
        <div className="pagination-container" data-testid="QAPaginationContainer">
          <IonLabel class="pagination-label">Viewing <span className="bold">{firstRowIndex}-{lastRowIndex}</span> of <span className="bold">{numberOfRows}</span></IonLabel>
          <div className="pagination-buttons-container">
            <IonButton disabled={!canGoToPreviousPage} class="change-page-button" color="secondary" onClick={() => goToPreviousPage()} data-testid="QAViewAllDocumentsPopoverPreviousPageButton">{!platform.isMobile() ? <IonIcon class="arrow-icon" src={backArrow}></IonIcon> : 'Previous'}</IonButton>
            <IonButton disabled={!canGoToNextPage} class="change-page-button" color="secondary" onClick={() => goToNextPage()} data-testid="QAViewAllDocumentsPopoverNextPageButton">{!platform.isMobile() ? <IonIcon class="arrow-icon" src={forwardArrow}></IonIcon> : 'Next'}</IonButton>
          </div>
        </div>
      </div>
      <div className="cta-button-container">
        <IonButton color="primary" class="close-button" onClick={() => dismissModal()} data-testid="QAViewAllDocumentsPopoverCloseButton">Close</IonButton>
      </div>
    </div>
  );
};

export default ViewAllDocumentsPopover;