import * as React from 'react';
import { BehaviorSubject } from 'rxjs';
import { postCall } from '@services/rest-service';
import { setToken as importedSetToken, removeRefresh, removeToken } from '@services/storage-service';
import { handleLogout } from '../guards/auth-guard-service';
import AuthConnectService from '@services/authconnect-service';

const { createContext, useContext } = React;

const AuthContext = createContext(null);

export const AuthProvider = (props) => {
  const value = {
    session$: props.session$ || session$,
    setIsLoggedIn: props.setIsLoggedIn || setIsLoggedIn,
    generateTokenSSO: props.generateTokenSSO || generateTokenSSO,
    setToken: props.setToken || setToken,
    generateToken: props.generateToken || generateToken,
    generateTokenForAutomation: props.generateTokenForAutomation || generateTokenForAutomation,
    checkToken: props.checkToken || checkToken,
    generateTokenForExternal: props.generateTokenForExternal || generateTokenForExternal,
    logout: props.logout || logout,
    authConnectService: props.authConnectService ||  new AuthConnectService()
  };

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const _sessionToken = new BehaviorSubject<any>(null);
const _isLoggedIn = new BehaviorSubject<boolean>(false);

export const session$ = () => {
  return _sessionToken.asObservable();
};

export const setIsLoggedIn = (value: boolean) => {
  _isLoggedIn.next(value);
};

export function generateTokenSSO(access_token: string) {
  return postCall<{token: string}>('session', {access_token});
}

export async function setToken(token) {
  await importedSetToken(token);
  await removeRefresh();
  _sessionToken.next(token);
}

export function generateToken(sessionId: string, serverUrl: string) {
  return postCall<{token: string}>('session', {sessionId, serverUrl});
}

export function generateTokenForAutomation(automation: string) {
  return postCall<{token: string}>('session', {automation});
}

export async function checkToken(token) {
  try {
    await postCall<{token: string}>('session', {token});
    return true;
  } catch (error) {
    return false;
  }
}

export function generateTokenForExternal() {
  return postCall<{token: string}>('session', {});
}

export async function logout(logout) {
  await removeToken();
  handleLogout(logout);
}
