import * as React from 'react';
import { IonPopover } from '@ionic/react';
import AddDocumentsPopover from '@components/popover/add-documents-popover/add-documents-popover';

const InvoiceLineItemDocUploadPopover = ({payload, isOpen, onDismiss}) => {
  const { invoice, invoiceLineItem, mappedInvoiceLineItems } = payload || {};

  const onClickClose = async ({ payload }) => {
    const { added } = payload;
    if (onDismiss) {
      onDismiss({documentAdded: added || false });
    }
  };

  const linesMap = new Map<string, string[]>();

  if (invoiceLineItem) {
    linesMap.set(
      invoiceLineItem.Line_Number__c,
        mappedInvoiceLineItems
          .filter(lineItemDetail => lineItemDetail.Line_Number__c === invoiceLineItem.Line_Number__c)
          .orderBy(lineItemDetail => lineItemDetail.Line_Item_Detail_Number__c, true)
          .map(lineItemDetail => lineItemDetail.item)
    );
  }

  return <>
    {/* order line level document upload */}
    <IonPopover
      cssClass='add-documents-popover'
      isOpen={isOpen}
      animated={ false }
    >
      <AddDocumentsPopover
        setShowPopover={onClickClose}
        setDocumentsAdded={() => {}}
        salesOrderNumber={invoice?.NC_Order__r?.AX_Order_Number__c}
        poNumber={invoice?.PO_Number__c}
        invoiceNumber={invoice?.Name}
        linesMap={linesMap}
      />
    </IonPopover>
  </>;
};

export default InvoiceLineItemDocUploadPopover;