import { azureNativeConfig, azureWebConfig } from '@environment';
import { AuthResult, IonicAuth } from '@ionic-enterprise/auth';
import { isPlatform } from '@ionic/react';
import { Observable, Subject } from 'rxjs';

export default class AuthConnectService extends IonicAuth {
  public isLoggedIn: boolean;
  public accessToken?: string;
  public idToken?: any;
  
  // Emit event when login status changes
  private _loginStatusChanged: Subject<boolean>;
  get loginStatusChanged(): Observable<boolean> {
    return this._loginStatusChanged.asObservable();
  }

  constructor() {
    const selectedConfig = isPlatform('hybrid') ? azureNativeConfig : azureWebConfig;
    super(selectedConfig);
    
    this.isLoggedIn = false;

    this._loginStatusChanged = new Subject();
  }

  async setLoginState(authResult: AuthResult = {}) {
    const { accessToken } = authResult;

    try {
      const [accessTokenString, idTokenInfo] = await Promise.all([
        accessToken ? Promise.resolve(accessToken) : this.getAccessToken(),
        this.getIdToken()
      ]);

      this.accessToken = accessTokenString;
      this.isLoggedIn = true;
      this.idToken = idTokenInfo;
    } catch (err) {
      console.log(err);
    }
  }

  onLoginSuccess(authResult: AuthResult) {
    this.setLoginState(authResult);
    this._loginStatusChanged.next(true);
  }

  onLogout() {
    this._loginStatusChanged.next(false);
  }
}