import { getWeldingPatterns } from './welding-patterns-api';
import * as React from 'react';
import { WeldingPattern } from '@interfaces/welding-patterns';

const { createContext, useContext } = React;

const WeldingPatternsContext = createContext(null);

export const WeldingPatternsProvider = (props) => {
  const value = {
    getAllWeldingPatterns: props.getAllWeldingPatterns || getAllWeldingPatterns,
  };

  return (
    <WeldingPatternsContext.Provider value={value}>
      {props.children}
    </WeldingPatternsContext.Provider>
  );
};

export const useWeldingPatterns = () => {
  return useContext(WeldingPatternsContext);
};

// Calls api to get all the welding patterns from cosmos
export const getAllWeldingPatterns = async(): Promise<WeldingPattern[]> => {
  try {
    return await getWeldingPatterns();
  } catch (error) {
    console.error(error);
    return null;
  }
};
